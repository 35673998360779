<template>
  <VisibilitySensor @change="onChange">
    <div class="video-box">
      <div
        v-if="videoTrack=== undefined"
        class="participants-avatar"
      >
        <span>{{ name.charAt(0) }}</span>
      </div>
      <div
        v-if="audioTrack === undefined"
        class="status-icon"
      >
        <feather-icon
          icon="MicOffIcon"
          class="cursor-pointer text-white"
          size="15"
        />
        <lottie-animation
          v-show="audioTrack !== undefined"
          ref="anim"
          class="ml-1"
          style="width:22px;height:22px"
          :animation-data="require('../../../../assets/images/icons/hark.json')"
        />
      </div>
      <div class="name">
        <p>{{ name }}</p>
      </div>
      <video
        v-show="videoTrack!== undefined"
        ref="videoPlayer"
        class="video"
        muted
        playsInline
        autoplay
      />
      <audio
        v-if="!isLocalTrack"
        ref="audioPlayer"
        autoplay
      />
    </div>
  </VisibilitySensor>
</template>

<script>
import {
  VaniEvent,
} from 'vani-meeting-client'
import { TrackKind } from 'vani-meeting-client/lib/model/Track'
import VisibilitySensor from 'vue-visibility-sensor'
import LottieAnimation from 'lottie-web-vue'
import VideoHandler from '../VideoHandler'

import * as animationData from '../../../../assets/images/icons/hark.json'

export default {
  name: 'VideoPlayer',

  components: {
    VisibilitySensor,
    LottieAnimation,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    isFullScreenView: {
      type: Boolean,
      require: true,
    },
    videoHolderModel: {
      type: Object,
      require: true,
      default: undefined,
    },
  },
  data() {
    return {
      videoTrack: undefined,
      audioTrack: undefined,
      isLocalTrack: false,
      defaultOptions: { animationData },
      animationSpeed: 1,
      name: 'Unknown',
    }
  },
  mounted() {
    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnTrack, this.onTrack)
    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnTrackEnded, this.onTrackEnded)
    const tracks = VideoHandler.getInstance()
      .getMeetingHandler()
      .getTracksByParticipantId(this.videoHolderModel.participant.userId)

    tracks.forEach(track => {
      this.onTrack(track)
    })
    if (this.videoHolderModel.participant.userData.fullname) {
      this.name = this.videoHolderModel.participant.userData.fullname
    } else {
      this.name = `${this.videoHolderModel.participant.userData.firstname} ${this.videoHolderModel.participant.userData.lastname}`
    }
    this.$emit('updateName', this.name)
  },
  onUnmounted() {
    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnTrack, this.onTrack)
    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnTrackEnded, this.onTrackEnded)
  },
  methods: {
    onChange(isVisible) {
      if (!isVisible) {
        if (this.videoTrack) {
          VideoHandler.getInstance().getMeetingHandler().pauseIncomingTrack(this.videoTrack)
        }
      } else if (this.videoTrack) {
        VideoHandler.getInstance().getMeetingHandler().resumeIncomingTrack(this.videoTrack)
      }
    },
    onTrack(track) {
      if (
        track.participant.userId === this.videoHolderModel.participant.userId
      ) {
        this.isLocalTrack = track.isLocalTrack
        if (
          this.videoHolderModel.videoHolderModelType
        === 'Main'
        && (track.trackKind === TrackKind.Video
          || track.trackKind === TrackKind.Audio)
        ) {
          if (track.trackKind === TrackKind.Audio) {
            this.audioTrack = track
            this.onAudioTrackUpdated()
          } else if (track.trackKind === TrackKind.Video) {
            this.videoTrack = track
            this.onVideoTrackUpdated()
            if (!track.isLocalTrack) {
              if (this.isFullScreenView) {
                VideoHandler.getInstance().getMeetingHandler().updateSpatialForTrack(track, 1)
              } else {
                VideoHandler.getInstance().getMeetingHandler().updateSpatialForTrack(track, 0)
              }
            }
          }
        } else if (
        this.videoHolderModel?.videoHolderModelType
        === 'ScreenShare'
        && (track.trackKind === TrackKind.ScreenshareVideo
          || track.trackKind === TrackKind.ScreenshareAudio)
        ) {
          if (
            !track.isLocalTrack
          && track.trackKind === TrackKind.ScreenshareAudio
          ) {
            this.audioTrack = track
            this.onAudioTrackUpdated()
          } else if (track.trackKind === TrackKind.ScreenshareVideo) {
            this.videoTrack = track
            this.onVideoTrackUpdated()
          }
        }
      }
    },
    onTrackEnded(track) {
      if (
        track.participant.userId === this.videoHolderModel.participant.userId
      ) {
        if (
          this.videoHolderModel.videoHolderModelType
        === 'Main'
        ) {
          if (track.trackKind === TrackKind.Video) {
            this.videoTrack = undefined
            this.onVideoTrackUpdated()
          } else if (track.trackKind === TrackKind.Audio) {
            this.audioTrack = undefined
            this.onAudioTrackUpdated()
          }
        }
      }
    },
    onVideoTrackUpdated() {
      if (this.$refs.videoPlayer) {
        if (this.videoTrack) {
          this.$refs.videoPlayer.srcObject = new MediaStream([this.videoTrack.track])
        } else {
          // this.$refs.videoPlayer.stop()
        }
      }
    },
    onAudioTrackUpdated() {
      if (this.$refs.audioPlayer) {
        if (!this.isLocalTrack) {
          if (this.audioTrack) {
            this.$refs.audioPlayer.srcObject = new MediaStream([this.audioTrack.track])
          } else {
            // this.$refs.audioPlayer.stop()
          }
        }
      }
    },

  },
}

</script>

<style>
.video{
    width: 100%;
    height: 100%;
}
.name{
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 100;
}
.name p{
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
