<template>
  <b-card class="hotspot-card">
    <canvas
      id="myCanvas"
      :height="canvasHeight"
      :width="canvasWidth"
      :style="'background:url('+image+'); border:2px solid black;'"
    />
    <input
      ref="imageInput"
      type="file"
      style="display:none;"
      @change="previewImage"
    >

    <div class="pt-2 text-right">
      <b-button
        variant="outline-primary"
        @click="$refs.imageInput.click()"
      >
        <feather-icon icon="UploadIcon" />
        {{ $t('actions.upload-image') }}
      </b-button>
    </div>

    <!-- <div class="d-flex justify-content-end p-0">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="btn-icon mt-3 ml-auto"
        @click="toggle = true"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t("Add-Points") }}
      </b-button>
    </div> -->
  </b-card>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BButton, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

let defaultWidth = 400
export default {
  components: {
    BButton,
    BCard,

  },
  directives: {
    Ripple,
  },
  props: {
    hotspotPoint: {
      type: [Object, Array],
      default: () => {

      },
    },
  },
  data: () => ({
    hotspotName: '',
    canvasHeight: 350,
    canvasWidth: 450,
    preview: null,
    image: null,
    canvas: null,
    radius: 8,
    imageWidth: null,
    imageHeight: null,
    isMouseDown: null,
    mousePosition: null,
    pointBeingDragged: null,
    activePointIndex: null,
    existingHotspot: null,
    columns: [
      {
        label: 'Points',
        field: 'point',
      },
      {
        label: 'Actions',
        field: 'action',
        category: false,
        thClass: 'text-center',
      },
    ],
    toggle: false,
    points: [],
    isUpdating: false,
  }),

  watch: {
    hotspotPoint: {
      handler() {
        const myCanvas = document.getElementById('myCanvas')
        const context = myCanvas.getContext('2d')
        context.clearRect(0, 0, myCanvas.width, myCanvas.height)
        this.updatePoints()
      },
      deep: true,
    },
  },

  mounted() {
    this.updatePoints()
  },

  methods: {
    updatePoints() {
      const myCanvas = document.getElementById('myCanvas')
      this.canvas = myCanvas.getContext('2d')
      this.image = this.hotspotPoint.hotspot.image
      const img = new Image()
      img.src = this.image
      img.onload = () => {
        this.changeDimension(img)
      }
      myCanvas.addEventListener('click', e => { if (this.image !== null) this.getCordinates(e) })

      myCanvas.addEventListener('mousedown', e => { this.setDraggable(e, true) })
      myCanvas.addEventListener('mouseup', e => { this.setDraggable(e, false) })
      myCanvas.addEventListener('mousemove', this.mouseMove)
      window.addEventListener('resize', this.resizeWindow)
      this.resizeWindow()
      setTimeout(() => {
        this.points = { x: this.hotspotPoint.coordinates.x * this.imageWidth, y: this.hotspotPoint.coordinates.y * this.imageHeight }
        this.drawCircle(this.points.x, this.points.y)
      }, 1000)
    },
    previewImage(event) {
      const reader = new FileReader()
      reader.onload = e => {
        const img = new Image()
        img.onload = () => {
          this.changeDimension(img)
          // this.canvas.drawImage(img, 0, 0)
        }
        img.src = e.target.result
        this.image = e.target.result
      }
      reader.readAsDataURL(event.target.files[0])
      this.updateHotspot()
    },

    getCordinates(e) {
      const rect = document.getElementById('myCanvas').getBoundingClientRect()
      const x = e.clientX - rect.left
      const y = e.clientY - rect.top
      this.points = { x, y }
      this.draw()
      this.updateHotspotPoint({ x, y })
    },

    changeDimension(img) {
      const aspectRatio = img.width / img.height
      this.canvasWidth = (aspectRatio) * defaultWidth
      const resizeFactor = this.canvasWidth > defaultWidth ? this.canvasWidth / defaultWidth : 1
      this.canvasWidth /= resizeFactor
      this.canvasHeight = defaultWidth / resizeFactor
      this.imageWidth = this.canvasWidth
      this.imageHeight = this.canvasHeight
    },

    drawCircle(x, y) {
      this.canvas.beginPath()
      this.canvas.fillStyle = 'green'
      this.canvas.font = '12px '
      this.canvas.lineWidth = 20
      this.canvas.arc(x, y, this.radius, 0, 2 * Math.PI, false)
      this.canvas.fill()
    },
    draw() {
      this.canvas.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
      this.drawCircle(this.points.x, this.points.y)
    },
    checkPoints(x, y) {
      let isDragging = false
      let matchedPoint
      const dx = this.points.x - x
      const dy = this.points.y - y
      if (dx * dx + dy * dy < this.radius * this.radius) {
        isDragging = true
        matchedPoint = this.points
      }
      return { isDragging, matchedPoint }
    },
    setDraggable(e, isDown) {
      this.isMouseDown = isDown
      const rect = document.getElementById('myCanvas').getBoundingClientRect()
      const x = e.clientX - rect.left
      const y = e.clientY - rect.top
      if (isDown) {
        const checkPoint = this.checkPoints(x, y)
        if (checkPoint.isDragging) {
          this.pointBeingDragged = checkPoint.matchedPoint
        }
      } else {
        this.updateHotspotPoint({ x, y })
        this.pointBeingDragged = null
      }
    },
    mouseMove(e) {
      if (this.isMouseDown) {
        const rect = document.getElementById('myCanvas').getBoundingClientRect()
        const x = e.clientX - rect.left
        const y = e.clientY - rect.top
        this.points = { x, y }
        this.draw()
      }
    },
    setActivePoint(point) {
      this.activeCoordinate = { x: point.x, y: point.y }
    },

    updateHotspot() {
      const data = new FormData()
      data.append('image', this.$refs.imageInput.files[0])
      data.append('_method', 'put')
      useJwt.updateHotspot(this.hotspotPoint.hotspot.id, data).then(res => {
        this.showSuccessMessage(res)
        this.draw()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.$emit('pointUpdated')
      })
    },

    resizeWindow() {
      if (window.innerWidth > 1745) defaultWidth = 400
      if (window.innerWidth <= 1745) defaultWidth = 320
      if (window.innerWidth <= 1280) {
        defaultWidth = 260
      }
      if (window.innerWidth <= 720) {
        defaultWidth = 250
      }
    },

    updateHotspotPoint(coordinates) {
      if (!this.isUpdating) {
        const data = new FormData()
        const coordinate = { x: (coordinates.x / this.imageWidth), y: (coordinates.y / this.imageHeight) }
        data.append('coordinates', JSON.stringify(coordinate))
        data.append('_method', 'put')
        this.isUpdating = true
        useJwt.updateHotspotPoint(this.hotspotPoint.id, data).then(res => {
          this.showSuccessMessage(res)
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.$emit('pointUpdated')
          this.isUpdating = false
        })
      }
    },
  },

}
</script>
<style lang="css">
.active-column {
  background-color: rgba(0, 0, 0, 0.15);
}
#myCanvas{
  background-size:contain !important;
  background-repeat:no-repeat !important;
}
</style>
