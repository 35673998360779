<template>
  <b-modal
    id="modal-primary"
    ok-only
    :ok-title="$t('ok')"
    modal-class="modal-primary"
    centered
    :title="$t('points-setting-module.points-setting')"
    :visible="visible"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <global-setting
      :required-settings="[
        'conversion_rate',
        'point_to_coin_conversion_rate',
        'point_conversion_limit',
      ]"
    />
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue'
import GlobalSetting from '@/views/super/global-settings/index.vue'

export default {
  components: {
    BModal,
    GlobalSetting,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
