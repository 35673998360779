<template>
  <div>
    <div>
      <div
        class="floating-icon btn btn-icon"
        @click="setChatOpened"
      >
        <feather-icon
          v-if="!chatOpened"
          size="18"
          class="text-white"
          icon="MessageCircleIcon"
        />
        <feather-icon
          v-if="chatOpened"
          size="18"
          class="text-white"
          icon="XIcon"
        />
      </div>
      <div
        v-if="chatOpened"
        class="col-lg-3 col-xs-3 col-md-5 col-sm-6 floating-chat-section"
      >
        <b-card
          class="chat-widget"
          no-body
        >
          <b-card-header>
            <div class="d-flex align-items-center">
              <b-avatar
                v-if="
                  (chatData.contact.contact_type != 'class' ||
                    !chatData.contact.users) &&
                    chatData.contact.avatar
                "
                size="34"
                :src="chatData.contact.avatar"
                class="mr-50 badge-minimal"
                badge
                badge-variant="success"
              />
              <group-avatar
                v-if="
                  chatData.contact.contact_type == 'class' &&
                    chatData.contact.users
                "
                :contact="chatData.contact"
                style="cursor: pointer"
              />
              <h5 class="mb-0">
                {{ chatData.contact.fullName }}
              </h5>
            </div>
            <b-nav-item
              :to="{ name: 'chat' }"
              @click="setMinimized()"
            >
              <feather-icon
                icon="ArrowUpLeftIcon"
                style="cursor: pointer"
                size="17"
              />
            </b-nav-item>
          </b-card-header>

          <section class="chat-app-window">
            <!-- User Chat Area -->
            <vue-perfect-scrollbar
              ref="refChatLogPS"
              :settings="perfectScrollbarSettings"
              class="user-chats scroll-area"
            >
              <chat-log
                :chat-data="allChatData"
                :profile-user-avatar="AUTH_USER()?.avatar"
                :profile-user-id="AUTH_USER()?.id"
              />

            </vue-perfect-scrollbar>
            <div class="row chat-left">
              <!-- Message Input -->
              <div v-for="sChat,index of snapShotImages"
                   :key="index"
                   class="col-3"
              >
                <span style="position: absolute;right: 0;margin-right: -10px;margin-top: -20px;zoom: 1.5;"
                      @click="snapImage(index)"
                >
                  <b-button
                    variant="flat-danger"
                    class="btn-icon"
                  >
                    <feather-icon icon="XCircleIcon" />
                  </b-button>
                </span>
                <img :src="sChat"
                     style="height: 100%; width: 100%;"
                >
              </div>
            </div>
            <!-- Message Input -->
            <b-form
              class="chat-app-form"
              @submit.prevent="sendMessage"
            >
              <div class="d-flex w-100">
                <b-input-group class="input-group-merge form-send-message mr-1">
                  <ChatBoxInput
                    v-model="chatInputMessage"
                    @updateSnap="updateSnapShot"
                    @sendMessage="sendMessage(); sendSnapChat()"
                    @onTyping="onTyping"
                  />
                </b-input-group>
                <b-button
                  variant="primary"
                  type="submit"
                >
                  Send
                </b-button>
              </div>
            </b-form>
            <div class="mb-1">
              <Recorder
                ref="recordComponent"
                class="ml-2"
                :url="''"
                @input="setRecording"
                @emoji="insertEmoji"
                @sticker="sendSticker"
                @recordingNow="setRecordingNow"
              />
            </div>
          </section>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import store from '@/store'
import {
  BCard,
  BNavItem,
  BCardHeader,
  BAvatar,
  BForm,
  BInputGroup,
  BButton,
} from 'bootstrap-vue'
import { getUserToken } from '@/auth/utils'
import Echo from 'laravel-echo'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import GEC_EVENT_BUS from '@/utils/eventBus'
import ChatLog from './ChatLog.vue'
import Recorder from '../components/recorder.vue'
import GroupAvatar from '../components/GroupAvatar.vue'
import ChatBoxInput from './ChatBoxInput.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BAvatar,
    BForm,
    BInputGroup,
    BButton,
    BNavItem,
    Recorder,
    ChatBoxInput,
    // SFC
    ChatLog,

    // 3rd party
    VuePerfectScrollbar,
    GroupAvatar,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    chatData: {
      type: [Array, Object],
      require: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    profile: {
      type: [Array, Object],
      require: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    contacts: {
      type: [Array, Object],
      require: false,
    },
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
      chatInputMessage: '',
      chatOpened: false,
      recordingNow: false,
      audio: '',
      audioUrl: '',
      sticker: '',
      stickerCode: '',
      messageType: 'text',
      snapShotImages: [],
    }
  },
  computed: {
    allChatData() {
      return this.chatData
    },
    receiverId() {
      return this.profile?.id
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.psToBottom()
    })
    GEC_EVENT_BUS.$off('pushNewlySentChatMessage')
    GEC_EVENT_BUS.$on('pushNewlySentChatMessage', ({ message }) => {
      // eslint-disable-next-line eqeqeq
      if (message.receiverId == this.receiverId) {
        this.chatData.chat.push(message)
      }
    })
    this.listenForEvents()
  },
  methods: {
    snapImage(index) {
      this.snapShotImages.splice(index, 1)
    },
    updateSnapShot(img) {
      this.snapShotImages.push(img)
    },
    setFile(file) {
      setTimeout(() => {
        const payload = {
          contactId: this.allChatData.contact.id,
          // eslint-disable-next-line no-use-before-define
          senderId: this.profile.id,
          message: '',
          audio: file.file,
          audioUrl: file.fileURL,
          type: 'file',
          code: this.sticker !== '' ? this.stickerCode : '',
          isStudentFloating: true,
        }

        store.dispatch('app-chat/sendMessage', payload).then(() => {
        })
      }, 500)
    },
    sendSnapChat() {
      this.snapShotImages.forEach(snap => {
        const file = this.covertBase64ToFile(snap, 'image/png')
        // eslint-disable-next-line no-use-before-define
        this.setFile({ file })
      })
      this.snapShotImages = []
    },
    listenForEvents() {
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_WEBSOCKETS_KEY,
        forceTLS: false,
        wsHost: process.env.VUE_APP_WEBSOCKETS_HOST,
        wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
        disableStats: true,
        authEndpoint: process.env.VUE_APP_WEBSOCKETS_AUTH_ENDPOINT,
        auth: {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
            Accept: 'Application/json',
          },
        },
      })
      window.Echo.channel('chat-room').listen('ChatStoreEvent', () => {
        if (this.allChatData && this.allChatData.contact && this.chatOpened) this.openChatOfContact(this.allChatData.contact.id)
      })
    },

    openChatOfContact(userId) {
      // Reset send message input value
      this.chatInputMessage = ''
      store.dispatch('app-chat/getChat', { userId }).then(response => {
        store.commit('app-chat/FETCH_CHAT_CONTACTS', {
          activeChat: response.data,
          profileUserDataMinimal: this.profile,
          chatsContacts: this.contacts,
        })
        // Scroll to bottom
        this.$nextTick(() => {
          this.psToBottom()
        })
      })
    },

    sendMessage() {
      if (!this.chatInputMessage && !this.recordingNow && !this.sticker) return
      setTimeout(() => {
        const payload = {
          contactId: this.allChatData.contact.id,
          // eslint-disable-next-line no-use-before-define
          senderId: this.profile.id,
          message: this.chatInputMessage ? this.chatInputMessage : this.sticker,
          audio: this.audio,
          audioUrl: this.audioUrl,
          type: this.audio !== '' ? 'file' : this.messageType,
          code: this.sticker !== '' ? this.stickerCode : '',
          isStudentFloating: true,
        }

        store.dispatch('app-chat/sendMessage', payload).then(() => {
          // Reset send message input value
          this.chatInputMessage = ''
          if (this.recordingNow) {
            this.recordingNow = !this.recordingNow
          }
          this.$refs.recordComponent.setRecorded(false)
          this.audio = ''
          this.audioUrl = ''
          this.sticker = ''
          this.stickerCode = ''
          this.messageType = 'text'
        })
      }, 500)
    },
    psToBottom() {
      if (this.$refs.refChatLogPS) {
        const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS
        scrollEl.scrollTop = scrollEl.scrollHeight
      }
    },
    setChatOpened() {
      if (!this.chatOpened) {
        this.openChatOfContact(this.allChatData.contact.id)
      }
      this.chatOpened = !this.chatOpened
    },
    setMinimized() {
      store.commit('app-chat/SET_MINIMIZED', false)
    },
    setRecordingNow() {
      this.recordingNow = !this.recordingNow
    },
    setRecording(ad) {
      this.audio = ad.audio
      this.audioUrl = ad.url
    },
    // sends stickers
    sendSticker(data) {
      this.sticker = data.icon
      this.stickerCode = data.code
      this.messageType = 'sticker'
      this.sendMessage()
    },
    // inserts emoji to the textbox
    insertEmoji(emoji) {
      this.chatInputMessage += emoji
    },
  },
}
</script>

  <style lang="scss">
  @import "@core/scss/base/pages/app-chat-list.scss";
  li {
    list-style: none;
  }
  .floating-icon{
    width: 40px;
    height: 40px;
    border: 2px solid white !important;
    right:1% !important;
  }
  .floating-chat-section {
    right:5% !important;
    bottom: 4% !important;
  }
  </style>
