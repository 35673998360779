<template>

  <b-col

    md="12"
    class="mb-1"
  >
    <b-card
      no-body
      class="mb-0"
    >
      <h5 class="p-1 pb-0">
        {{ $t('network-module.coleaders') }}
      </h5>

      <b-table
        v-if="coLeaders.length>0"
        small
        responsive
        :items="getColeadersForTable"
        :fields="columns"
      >
        <template #cell(action)="member">
          <span>
            <b-dropdown
              variant="link"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="deleteMember(member.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t("delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>

        </template>
      </b-table>
      <p
        v-else
        class="px-1"
      >
        {{ $t('network-module.no-coleaders') }}
      </p>
    </b-card>
  </b-col>
</template>
<script>
import {
  BCol, BCard, BDropdown, BDropdownItem, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCol, BCard, BDropdown, BDropdownItem, BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isProcessing: false,
      columns: [
        {
          key: 'name',
          label: this.$i18n.tc('messages.tableHeader.name'),
        },
        { key: 'action', label: this.$i18n.tc('action') },

      ],
    }
  },
  computed: {
    ...mapGetters({
      coLeaders: 'network/coLeaders',
      network: 'network/network',
    }),
    getColeadersForTable() {
      return this.coLeaders.map(e => ({
        name: `${e.firstname} ${e.lastname}`,
        id: e.id,
      }))
    },
  },
  created() {
    this.$store.dispatch('network/getCoLeaders')
  },
  methods: {
    deleteMember(id) {
      useJwt.removeUserFromNetwork(this.network.id, id).then(response => {
        this.$store.dispatch('network/getNetworkMembers')
        this.$store.dispatch('network/getCoLeaders')
        this.showSuccessMessage(response)
      })
    },
  },

}
</script>
