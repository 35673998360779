<template>
  <b-overlay :show="isProcessing">
    <create-scorm
      v-if="isASuperAdmin"
      @reFetch="getScormList"
    />
    <b-card>
      <b-table-simple>
        <thead>
          <tr>
            <th>{{ $t('scorm-module.title') }}</th>
            <th>
              {{ $t('scorm-module.identifier') }}
            </th>
            <th>
              {{ $t('scorm-module.version') }}
            </th>
            <th>
              {{ $t('messages.tableHeader.action') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="scorm of scormList"
            :key="scorm.uuid"
          >
            <td>
              {{ scorm.scos[0] && scorm.scos[0].title }}
            </td>
            <td>
              {{ scorm.scos[0] && scorm.scos[0].identifier }}
            </td>
            <td>
              {{ scorm.version }}
            </td>
            <td>

              <b-button
                variant="outline-primary"
                class="btn-icon rounded-circle"
                @click="$router.push({ name: 'super-scorm-player', params: { uuid: scorm.scos[0].uuid }})"
              >
                <feather-icon icon="PlayIcon" />
              </b-button>
              <b-button
                variant="outline-success"
                class="btn-icon rounded-circle ml-2"
                @click="$router.push({ name: 'super-scorm-player-report', params: { uuid: scorm.scos[0].uuid }})"
              >
                <feather-icon icon="TwitchIcon" />
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon rounded-circle ml-2"
                @click="scormToDelete = scorm"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </td>
          </tr>
        </tbody>
      </b-table-simple>
    </b-card>
    <ConfirmDelete
      :show="!!scormToDelete"
      :is-processing-delete="isDeleting"
      :on-delete="handleDelete"
      @close="scormToDelete = null"
    />
  </b-overlay>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BTableSimple, BCard, BButton, BOverlay,
} from 'bootstrap-vue'
import ConfirmDelete from '@/views/common/components/DeleteModal.vue'
import CreateScorm from './components/Create.vue'

export default {
  components: {
    CreateScorm,
    BTableSimple,
    BCard,
    BButton,
    BOverlay,
    ConfirmDelete,
  },
  data() {
    return {
      scormList: [],
      isProcessing: false,
      scormToDelete: null,
      isDeleting: false,
    }
  },
  computed: {
    isASuperAdmin() {
      return this.AUTH_USER().usertype === 'super'
    },
  },
  created() {
    this.getScormList()
  },
  methods: {
    getScormList() {
      this.isProcessing = true
      useJwt.getScormList().then(response => {
        this.scormList = response.data.data.data
      }).catch(error => {
        this.showError(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    handleDelete() {
      this.isDeleting = true
      useJwt.deleteScorm(this.scormToDelete.id).then(response => {
        this.getScormList()
        this.showSuccessMessage(response)
        this.scormToDelete = null
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isDeleting = false
      })
    },
  },
}
</script>
