<template>
  <li
    v-if="isVisible && (note.problem_name || problemId)"
  >
    <div
      class="d-flex flex-column align-items-end"
    >
      <div class="">
        <div
          class="btn d-flex align-items-center justify-content-center text-white btn-icon rounded-circle shadow toggle-button"
          :class="{'has-notes':'id' in note && note.id}"
          @click="toggleMessage"
        >
          <feather-icon
            style="height: 18px; width: 18px"
            icon="ClipboardIcon"
          />
          <b-button
            v-if="isClosable"
            variant="outline-danger"
            size="sm"
            class="btn-icon rounded-circle shadow close-button"
            @click="closeFloatingNotes"
          >
            <feather-icon
              style="
              height: 12px;
              width: 12px;
              margin-top: -3px;
              margin-left: -3px;
            "
              icon="XIcon"
            />
          </b-button>
        </div>
      </div>
      <b-sidebar
        v-if="isExpanded"
        :visible="true"
        bg-variant="white"
        width="500px"
        right
        shadow
        no-header
        no-close-on-esc
      >
        <template #default="{ hide }">
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5
              class="mb-0"
            >
              Manage Notes
            </h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide();closeModal()"
            />
          </div>
          <notes
            ref="noteArea"
            key="studentNote"
            :note="note"
            :is-processing="isProcessing"
            :parent-source="`floating-button`"
            @close="closeModal()"
            @onDelete="deleteNote"
            @onUpdate="updateNote"
          />
        </template>
      </b-sidebar>
    </div>
  </li>

</template>

<script>
/* eslint-disable */
import { BButton, BFormTextarea, BSpinner, BSidebar } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Notes from './notes/index.vue'

export default {
  components: {
    BButton, BFormTextarea, BSpinner, Notes, BSidebar
  },
  computed: {
    ...mapGetters({
      isVisible: 'studentNotes/isVisible',
      isExpanded: 'studentNotes/isExpanded',
      isProcessing: 'studentNotes/isProcessing',
      isAStudent: 'studentNotes/isAStudent',
      note: 'studentNotes/activeNote',
    }),
    isClosable() {
      return this.$route.name !== 'student-problems'
    },
    problemId() {
      return this.$store.state.studentNotes.problemId
    }
  },
  data() {
    return {
      noteContent: '',
    }
  },
  watch: {
    note() {
      this.noteContent = this.note.content
    },
    isClosable(val) {
      if (!val) {
        this.$store.dispatch('studentNotes/showFloatingNotes')
      } else {
        this.$store.dispatch('studentNotes/hideFloatingNotes')
      }
    },
  },
  methods: {
    deleteNote() {
      this.$store.dispatch('studentNotes/deleteNote', this.note.id).then(() => {
        this.showSuccess('Notes Successfully deleted')
      })
    },
    updateNote(content) {
      this.$store.dispatch('studentNotes/updateNote', content)
      // this.$refs?.noteArea.getNotesByLesson()
    },
    closeFloatingNotes() {
      this.$store.dispatch('studentNotes/closeFloatingNotes')
    },
    toggleMessage() {
      this.$store.dispatch('studentNotes/toggleExpanded')
    },
    closeModal() {
      this.$store.dispatch('studentNotes/toggleExpanded')
    }
  },
}
</script>

<style scoped>
.floating {
  position: fixed;
  top: 18%;
  right: 2%;
  z-index: 1000;
}
.message-box {
  height: 0;
  width: 0;
  transition: all 200ms ease;
  display: none;
}
.expanded {
  height: auto !important;
  width: auto !important;
  display: block !important;
}
.has-notes{
  background-color: #e67e22 !important;
}
.toggle-button {
  height: 40px;
  width: 40px;
  background-color: #e74c3c;
  border: 2px solid white !important;
}
.close-button {
  height: 22px;
  width: 22px;
  position: absolute;
  right: -12px;
  top: -12px;
}
.note-field{
  white-space: pre-line
}
</style>
