<template>
  <b-row>
    <div
      v-for="(step, i) in steps"
      :key="i"
      class="mr-3"
      @click="$emit('activeStep', i)"
    >
      <b-button
        :variant="i === activeStep ?'primary':'secondary'"
        class="mr-1"
      >
        {{ i+1 }}
      </b-button>
      {{ step }}
    </div>
  </b-row>
</template>

<script>
import { BButton, BRow } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BRow,
  },
  props: {
    activeStep: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({
    steps: ['Problem Detail', 'Questions and Answers'],
  }),
}
</script>
