<template>
  <!-- basic modal -->
  <b-row>
    <b-col
      class="problem-card-title text-center"
      md="12"
    >
      <h4 class="mb-0">
        {{ $t("student-test-module.match-the-following") }}
      </h4>
    </b-col>

    <b-col
      md="12"
      class="problem-card-mid-content mtf-mid-content"
    >
      <p class="mb-0">
        {{
          unPlacedAnswers.length > 0
            ? getProblemHintsDescription($t("student-test-module.match-problem-hint"))
            : $t("student-test-module.completed")
        }}
      </p>

      <!-- draggable answer buttons -->
      <div
        v-if="unPlacedAnswers.length > 0"
        class="mt-1"
      >
        <div
          v-for="answer,ansIndex in unPlacedAnswers"
          :key="'answer_' + answer.id"
          :class="`btn btn-primary mr-1 mb-1 draggable-type-${
            answer.data_type
          } ${selectedAnswer === answer.id ? 'selected-answer' : ''}`"
          :draggable="mode === 'question'"
          style="cursor: grab;"
          :data-id="`match-problem-answer-${answer.id}`"
          @dragstart="startDrag($event, answer.id)"
          @touchstart="selectedAnswer = answer.id"
        >
          <!-- image type -->
          <div
            v-if="answer.data_type === 'image'"
            class="rounded-top text-center"
            :set="caption = getCaption(answer)"
          >
            <figure>
              <b-img
                :src="answer.file"
                style="height: 150px; width: auto"
                fluid
                @click="() => {toggler = !toggler; slide = ansIndex + 1 }"
              />
              <figcaption
                v-if="caption"
                class="mt-1 text-center text-white"
              >
                {{ caption }}
              </figcaption>
            </figure>
          </div>
          <!-- image type -->
          <!-- text type -->
          <p
            v-else-if="answer.data_type === 'text'"
            v-text-to-speech
            class="m-0 p-0 text-white"
            style="color: white !important;"
            v-html=" answer.text "
          />
          <!-- text type -->
          <!-- audio type -->
          <audio-player
            v-else-if="answer.data_type === 'audio'"
            :ref="'player'"
            :src="answer.file"
          />
        </div>
      </div>
      <!-- draggable answer buttons -->
    </b-col>
    <!--decoration image-->
    <b-col v-if="decorationImage"
           class="problem-card-mid-content"
           md="12"
    >
      <div class="rounded-top text-center">
        <b-img
          v-image-preview
          :src="decorationImage"
          fluid
        />
      </div>
    </b-col>
    <b-col
      md="12"
      class="mtf-main-content problem-overview-content m-0 p-0"
    >
      <b-row class="m-0 p-0">
        <b-col
          v-for="(question, index) in localQuestions"
          :key="'question_' + question.id"
          :md="answerCluster"
          class="match-question-item py-1 px-0"
        >
          <b-row class="m-0 p-0 d-flex align-items-center">
            <!-- question -->
            <b-col
              md="6"
              sm="12"
              :class="`d-flex mb-0 align-items-center question-type-${question.data_type}`"
            >
              <div class="media h-100 w-100">
                <div class="mtf-number">
                  <span>{{ index + 1 }}</span>
                </div>
                <!-- image type -->
                <div
                  v-if="question.data_type === 'image'"
                  class="media-body"
                  :set="caption = getCaption(question)"
                >
                  <img
                    :src="question.file"
                    alt=""
                    style="float: left"
                    @click="() => {togglerQuestion = !togglerQuestion; slideQuestion = index + 1 }"
                  >
                  <div style="text-align: center;"
                       class="image-caption"
                  >
                    <span
                      v-if="caption"
                      class="ml-2"
                    >{{ caption }}</span>
                  </div>
                </div>
                <!-- image type -->
                <!-- text type -->
                <h4
                  v-else-if="question.data_type === 'text'"
                  v-text-to-speech
                  class="d-inline-block match-text"
                >
                  {{ question.text }}
                </h4>
                <!-- text type -->
                <!-- audio type -->

                <audio-player
                  v-else-if="question.data_type === 'audio'"
                  :alignment="'left'"
                  :src="question.file"
                />
              </div>

              <!-- audio type -->
            </b-col>
            <!-- question -->

            <!-- place answers -->
            <b-col
              md="6"
              sm="12"
            >
              <div
                :class="`drop-zone mtf-box drop-zone-${answerDataType}`"
                :data-questionId="question.id"
                @drop="onDrop($event, question.id)"
                @touchstart="selectAnswerForQuestion(question.id)"
                @dragover.prevent
                @dragenter.prevent
              >
                <div
                  v-if="isQuestionAnswered(question.id)"
                  :key="'user_anser_for_' + question.id"
                  class="
                    btn btn-sm btn-secondary
                    w-100
                    h-100
                    d-flex
                    justify-content-between
                  "
                  :draggable="mode === 'question'"
                  style="cursor: grab"
                  @dragstart="startDrag($event, questionAnswer[question.id])"
                >
                  <span>
                    <div
                      v-if="
                        userAnswerForGivenQuestion(question.id).data_type ===
                          'image'
                      "
                      class="d-inline-block rounded-top text-center"
                    >
                      <b-img
                        :src="userAnswerForGivenQuestion(question.id).file"
                        style="
                          max-height: 100px;
                          max-width: 200px;
                          object-fit: cover;
                        "
                        fluid
                      />
                    </div>
                    <p
                      v-else-if="
                        userAnswerForGivenQuestion(question.id).data_type ===
                          'text'
                      "
                      class="m-0 p-0"
                    >
                      {{
                        userAnswerForGivenQuestion(question.id).text
                      }}
                    </p>

                    <audio-player
                      v-else-if="
                        userAnswerForGivenQuestion(question.id).data_type ===
                          'audio'
                      "
                      :ref="'player'"
                      :src="userAnswerForGivenQuestion(question.id).file"
                    />
                  </span>
                  <span v-show="mode === 'question'">
                    <feather-icon
                      icon="XIcon"
                      class="m-0 p-0"
                      style="cursor: pointer"
                      @click="removeAnswerFromQuestion(question.id)"
                    />
                  </span>
                </div>
              </div>
            </b-col>
            <!-- place answers -->
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <portal
      to="problemFeedBack"
    >
      <b-row>
        <b-col md="12"
               class="p-0"
        >
          <feedback
            :feedback="feedback"
            :problem-statement-id="problemStatementId"
          />
        </b-col>
        <b-col
          v-if="!isPreview"
          class="m-0 p-0"
          md="12"
        >
          <actions
            :is-processing="isProcessing"
            :on-submit="submit"
            :level="level ? level : ''"
            :disabled="!isValidated"
            :has-minimal-view="hasMinimalView"
            :has-feedback="hasFeedback"
            :tooltip-text="
              $t('student-test-module.match-problem-validation-message')
            "
            :display-result="displayResult"
            :button-title="hasFeedback ? $t('next') : $t('submit')"
            :engine="engine"
            :school-settings="schoolSettings"
            :district-settings="districtSettings"
            v-on="$listeners"
          />
        </b-col>
      </b-row>
    </portal>
    <FsLightbox
      v-if="answerImages.length"
      :toggler="toggler"
      :slide="slide"
      :sources="answerImages"
      :types="[...new Array(answerImages.length).fill('image')]"
    />
    <FsLightbox
      v-if="questionImages.length"
      :toggler="togglerQuestion"
      :slide="slideQuestion"
      :sources="questionImages"
      :types="[...new Array(questionImages.length).fill('image')]"
    />
  </b-row>
</template>

<script>
import { BRow, BCol, BImg } from 'bootstrap-vue'
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'
import { mapGetters } from 'vuex'
import FsLightbox from 'fslightbox-vue'
import Actions from './Actions.vue'
import Feedback from './Feedback.vue'

export default {
  components: {
    BRow,
    BCol,
    Actions,
    Feedback,
    BImg,
    AudioPlayer,
    FsLightbox,
  },
  props: {
    questions: {
      type: Array,
      required: true,
    },
    previewAnswers: {
      type: Array,
      default: () => [],
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Object,
      default: () => {},
    },
    displayResult: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      default: 1,
    },
    problemStatementId: {
      type: [Number, String],
      required: false,
    },
    startTime: {
      type: [String, Date],
      required: false,
      default: () => null,
    },
    engine: {
      type: String,
      default: () => '',
    },
    hasMinimalView: {
      type: Boolean,
      default: false,
    },
    schoolSettings: {
      type: Array,
      default: () => [],
    },
    districtSettings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localQuestions: [],
      localAnswers: [],
      questionAnswer: {},
      selectedAnswer: null,
      questionDataType: 'text',
      answerDataType: 'text',
      toggler: false,
      slide: 1,
      togglerQuestion: false,
      slideQuestion: 1,
    }
  },
  computed: {
    ...mapGetters('problem', {
      validations: 'getValidations',
    }),
    hasFeedback() {
      return !!(
        this.feedback?.text?.length > 0
        || this.feedback?.image
        || this.feedback?.audio
        || this.feedback?.template
      )
    },
    answerCluster() {
      return this.$store.getters['problem/getAnswerCluster'] || 6
    },
    isValidated() {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.questionAnswer) {
        if (Object.hasOwnProperty.call(this.questionAnswer, key)) {
          const element = this.questionAnswer[key]
          if (element === null) return false
        }
      }
      return true
    },
    unPlacedAnswers() {
      return this.localAnswers.filter(item => !this.isAnswerPlaced(item.id))
    },
    answerImages() {
      return this.unPlacedAnswers?.filter(item => item.data_type === 'image').map(item => item.file)
    },
    questionImages() {
      return this.questions?.filter(item => item.data_type === 'image').map(item => item.file)
    },
    decorationImage() {
      const { activeProblem } = this.$store.state.problem
      return activeProblem?.file
    },
  },
  watch: {
    questions() {
      this.initData()
    },
    validations() {
      this.setValidationClasses()
    },
  },
  created() {
    // this is to prevent the default behaviour
    // in mozilla on drap the image
    // is opened in new tab
    window.addEventListener(
      'dragover',
      e => {
        e.preventDefault()
      },
      false,
    )
    window.addEventListener(
      'drop',
      e => {
        e.preventDefault()
      },
      false,
    )

    this.initData()
    if (this.previewAnswers && this.previewAnswers.length) {
      this.previewAnswers.forEach(ans => {
        this.questionAnswer[ans.question] = ans.input
      })
    }
  },
  methods: {
    getCaption(item) {
      return this.JSON_PARSE(item.extra_file)?.caption || null
    },
    initData() {
      this.localQuestions = JSON.parse(JSON.stringify(this.questions))
      this.localAnswers = this.questions.map(e => e.answers[0])
      this.localAnswers.forEach(e => {
        e.id = e.id.toString()
      })
      this.randomizeArray(this.localAnswers)
      this.questionAnswer = {}
      this.localQuestions.forEach(e => {
        this.questionAnswer[e.id] = null
      })
      this.questionDataType = this.questions[0]?.data_type
      this.answerDataType = this.localAnswers[0]?.data_type
    },
    isAnswerPlaced(id) {
      return Object.values(this.questionAnswer).indexOf(id) > -1
    },
    isQuestionAnswered(id) {
      return this.questionAnswer[id] != null
    },

    randomizeArray(array) {
      let currentIndex = array.length
      let temporaryValue
      let randomIndex
      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1
        temporaryValue = array[currentIndex]
        // eslint-disable-next-line no-param-reassign
        array[currentIndex] = array[randomIndex]
        // eslint-disable-next-line no-param-reassign
        array[randomIndex] = temporaryValue
      }

      return array
    },

    answerPlacedIn(id) {
      if (!this.isAnswerPlaced(id)) return null
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.questionAnswer) {
        if (Object.hasOwnProperty.call(this.questionAnswer, key)) {
          const element = this.questionAnswer[key]
          if (element === id) return key
        }
      }
      return null
    },
    submit() {
      this.$emit('submitQuestion', {
        lang_level: this.localQuestions[0].lang_level,
        answer: this.questionAnswer,
      })
    },
    userAnswerForGivenQuestion(questionId) {
      return this.localAnswers.find(
        e => e.id === this.questionAnswer[questionId],
      )
    },

    removeAnswerFromQuestion(questionId) {
      this.$set(this.questionAnswer, questionId, null)
    },

    startDrag(evt, answerId) {
      // eslint-disable-next-line no-param-reassign
      evt.dataTransfer.dropEffect = 'move'
      // eslint-disable-next-line no-param-reassign
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('itemID', answerId)
    },
    selectAnswerForQuestion(questionId) {
      this.setAnswerForQuestion(this.selectedAnswer, questionId)
    },
    onDrop(evt, questionId) {
      const answerId = evt.dataTransfer.getData('itemID')
      this.setAnswerForQuestion(answerId, questionId)
    },

    setAnswerForQuestion(answerId, questionId) {
      // this answer was already placed in another Question?
      const thisAnsweredPlacedIn = this.answerPlacedIn(answerId)
      if (thisAnsweredPlacedIn != null) {
        this.$set(this.questionAnswer, thisAnsweredPlacedIn, null)
      }
      // checking if destination question is already answered
      if (this.isQuestionAnswered(questionId)) {
        const oldAnswerId = this.questionAnswer[questionId]
        if (thisAnsweredPlacedIn != null) this.$set(this.questionAnswer, thisAnsweredPlacedIn, oldAnswerId)
      }

      const { questionAnswer } = this
      questionAnswer[questionId] = answerId
      this.questionAnswer = JSON.parse(JSON.stringify(questionAnswer))

      // audio doesnot reload on changing the src alone
      // hence need to explicitely load
      // when the audio changes

      if (this.answerDataType === 'audio') {
        this.$nextTick(() => {
          this.$refs.player.forEach(e => e.$refs.audio.load())
        })
      }
    },
    setValidationClasses() {
      const answers = document.querySelectorAll('.drop-zone')
      answers.forEach((answer, index) => {
        answer.classList.add(`answer-${this.validations[index].is_correct}`)
      })
    },
  },
}
</script>

<style scoped>
.selected-answer {
  box-sizing: border-box !important;
  border: 5px #f1c40f solid !important;
}
.drop-zone {
  border: 1px solid grey;
  padding: 4px;
  box-sizing: border-box;
}
.drop-zone-image {
  height: 150px;
}
.drop-zone-audio {
  height: 90px;
}
.drop-zone-audio audio {
  max-width: 180px;
}
.drop-zone-text {
  min-height: 50px;
}
.draggable-type-audio audio {
  max-width: 180px;
}
.media audio {
  width: 100%;
}

.match-question-item {
  border: 0px solid white;
  /* border-top-width: 0.1px; */
  /* border-bottom-width: 0.1px; */
}
.match-question-item:nth-child(odd) {
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.match-question-item:nth-child(even) {
  /* border-right-width: 1px; */
  border-bottom-width: 1px;
}
.answer-1 {
  border: 2px dashed #2eb82e !important;
}
.answer-0 {
  border: 2px dashed #df0000 !important;
}

@media (max-width: 1700px) {
  .image-caption {
    clear: both;
  }
}

</style>
