<template>
  <b-overlay :show="isProcessing">
    <template v-if="noReport">
      <no-report :lesson-status="overallReport ? overallReport.lesson_status : ''" />
    </template>
    <template v-else>
      <div class="f-flex">
        <b-button
          variant="flat-secondary"
          @click="$router.go(-1)"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            class="mr-50"
          />
          <span class="align-middle"> {{ $t('actions.back') }}</span>
        </b-button>
      </div>
      <overall-summary
        :interaction-report="interactionReport"
        :scorm-info="scormInfo"
        :overall-report="overallReport"
        :user-info="userInfo"
      />
      <b-row>
        <b-col
          md="6"
          class="d-flex align-items-stretch"
        >
          <individual-report
            class="w-100"
            :interaction-report="interactionReport"
          />
        </b-col>
        <b-col
          md="6"
          class="d-flex align-items-stretch"
        >
          <!-- <b-card> -->
          <report-pie-chart
            v-if="interactionReport.length > 0"
            class="w-100"
            :interaction-report="interactionReport"
            :scorm-info="scormInfo"
          />
          <!-- </b-card> -->
        </b-col>
      </b-row>
    </template>
  </b-overlay>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BButton, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import IndividualReport from './components/individualReport.vue'
import OverallSummary from './components/OverallSummary.vue'
import NoReport from './components/NoReport.vue'
import ReportPieChart from './components/ReportPieChart.vue'

export default {
  components: {
    IndividualReport,
    OverallSummary,
    ReportPieChart,
    NoReport,
    BButton,
    BRow,
    BCol,
    BOverlay,
    // BCard,
  },
  data() {
    return {
      scormSco: this.$route.params.uuid,
      interactionReport: [],
      scormInfo: null,
      userInfo: null,
      noReport: false,
      isProcessing: true,
      overallReport: null,
    }
  },
  created() {
    this.getReport()
  },
  methods: {
    getReport() {
      this.isProcessing = true
      useJwt.getScormReport(this.scormSco).then(response => {
        if (!response.data.report.success) {
          this.noReport = true
        }
        this.interactionReport = response.data.report.interaction_report
        this.overallReport = response.data.report
        this.scormInfo = response.data.report.scormInfo
        this.userInfo = response.data.userInfo
      }).catch(error => {
        this.showError(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
