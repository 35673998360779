<template>
  <b-modal id="buy-trading-animation-modal"
           hide-footer
           hide-header
           centered
  >
    <div v-if="product.animated_item"
         class=""
    >
      <h3 class="text-center">
        {{ $t('engagement-setting-module.buy-confirmation') }}
      </h3>
      <hr>
      <b-row
        class="d-flex align-items-center product-row"
      >

        <img
          height="100"
          width="100"
          :src="product.animated_item.image"
        >

        <b-col cols="8">
          <h4>
            {{ product.animated_item.name }}
          </h4>
          <h5 class="text-dark price-info">
            <strong>${{ product.animated_item.price }}</strong>
          </h5>
          <p>
            {{ product.animated_item.description }}
          </p>
          <h5>
            {{ $t('engagement-setting-module.buy-quanitity') }}: {{ quantity }}
            <feather-icon
              v-if="quantity < product.selling_quantity"
              v-b-tooltip="'Increase Quantity'"
              icon="PlusIcon"
              class="totalIcons cursor-pointer text-primary"
              size="18"
              @click="quantity++"
            />
            <feather-icon v-if="quantity>1"
                          v-b-tooltip="'Decrease Quantity'"
                          icon="MinusIcon"
                          class="totalIcons cursor-pointer text-danger ml-1"
                          size="18"
                          @click="quantity--"
            />

          </h5>
          <h6>
            {{ $t('engagement-setting-module.price-per-item') }} ${{ product.selling_price }}
          </h6>
          <span v-if="product.selling_price>0"
                class="mb-1"
          >
            {{ quantity }} x ${{ product.selling_price }} = Total ${{ product.selling_price*quantity }}
          </span>
        </b-col>
      </b-row>
      <div class="text-center">

        <div class="pt-1">
          <b-button variant="outline-primary"
                    @click="buyItem()"
          >
            {{ $t('engagement-setting-module.buy-now') }}
          </b-button>
          <b-button variant="outline-danger"
                    class="ml-2"
                    @click="$bvModal.hide('buy-trading-animation-modal')"
          >
            {{ $t('engagement-setting-module.cancel') }}
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BRow, BCol,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BModal,
    BButton,
    BRow,
    BCol,
  },
  props: {
    product: {
      type: [Object, Array],
      default: () => {},
    },
  },

  data() {
    return {
      quantity: 1,
    }
  },
  methods: {
    buyItem() {
      const data = {
        quantity: this.quantity,
      }
      useJwt.buyTradingAnimatedItems(this.product.id, data).then(() => {
        this.$emit('onBuy')
        this.$swal('Congratulation', 'Item bought from trading board', 'success')
      }).catch(err => {
        this.$swal('Oops!', err.response.data.errors, 'error')
      }).finally(() => {
        this.$bvModal.hide('buy-trading-animation-modal')
      })
    },
  },
}
</script>
