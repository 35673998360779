/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import useJwt from '@/auth/jwt/useJwt'
import { getUserData, getUserToken } from '@/auth/utils'
// import { getUserData } from '@/auth/utils'
import Echo from 'laravel-echo'
import { NOTIFICATION_TYPE_ESSAY_EDIT_PERMISSION_REQUESTED, NOTIFICATION_TYPE_NETWORK_ARTICLE_REQUEST } from '@/const/notification'
import Toast from '@/utils/toast'
import ChatHandler from '../../views/common/chat/ChatHandler'

window.Pusher = require('pusher-js')

const actions = {
  async getNotifications({ commit, state }, hasPaginated = false) {
    state.self = await getUserData()
    // console.log('getNotifications here', state)
    if (!state.self) return
    useJwt.getNotifications({
      params: {
        page: state.paginationData.current_page,
      },
    }).then(response => {
      if (!hasPaginated)commit('SET_NOTIFICATIONS', response.data.data.lists.data)
      else commit('UPDATE_NOTIFICATIONS', response.data.data.lists.data)
      commit('SET_NOT_READ_COUNT', response.data.data.not_read)
      commit('SET_PAGINATION_DATA', response.data.data.lists.pagination)
    })
  },
  goToNextPage({ commit, dispatch }) {
    commit('INCREMENT_CURRENT_PAGE')
    dispatch('getNotifications', true)
  },
  deleteNotification({ dispatch }, payload) {
    useJwt.deleteNotification(payload.id).then(response => {
      Toast.showSuccessMessage(response)
      dispatch('getNotifications')
    })
  },
  acceptRequest({ dispatch }, payload) {
    // essay edit request has a different api
    if (payload.notificationType === NOTIFICATION_TYPE_ESSAY_EDIT_PERMISSION_REQUESTED) {
      useJwt.acceptEssayEditRequestPermission({
        lesson_id: payload.requestId,
        status: 'approved',
      }).then(res => {
        Toast.showSuccessMessage(res)
        dispatch('markAsRead', payload.notificationId)
      })
    } else {
      useJwt.acceptRequest({
        request_id: payload.requestId,
      }).then(res => {
        Toast.showSuccessMessage(res)
        dispatch('markAsRead', payload.notificationId)
      })
    }
  },
  declineRequest({ dispatch }, payload) {
    // essay edit request has a different api
    if (payload.notificationType === NOTIFICATION_TYPE_ESSAY_EDIT_PERMISSION_REQUESTED) {
      useJwt.acceptEssayEditRequestPermission({
        lesson_id: payload.requestId,
        status: 'reject',
      }).then(res => {
        Toast.showSuccessMessage(res)
        dispatch('markAsRead', payload.notificationId)
      })
    } else if (payload.notificationType === NOTIFICATION_TYPE_NETWORK_ARTICLE_REQUEST) {
      useJwt.rejectArticleRequest({ request_id: payload.requestId })
        .then(res => {
          Toast.showSuccessMessage(res)
          dispatch('markAsRead', payload.notificationId)
        })
      return
    }

    useJwt.rejectRequest({
      request_id: payload.requestId,
    }).then(res => {
      Toast.showSuccessMessage(res)
      dispatch('markAsRead', payload.notificationId)
    })
  },
  resetNotifications({ commit }) {
    commit('RESET_PAGINATION_DATA')
  },
  markAllAsRead({ commit, state }) {
    useJwt.markNotificationAsRead({
      id: state.notifications.reduce((values, val) => { values.push(val.id); return values }, []),
    }).then(response => {
      commit('SET_NOTIFICATIONS', response.data.data.lists.data)
      commit('SET_NOT_READ_COUNT', response.data.data.not_read)
      commit('SET_PAGINATION_DATA', response.data.data.lists.pagination)
    })
  },
  markAsRead({ commit }, data) {
    useJwt.markNotificationAsRead({
      id: [data],
    }).then(response => {
      commit('SET_NOTIFICATIONS', response.data.data.lists.data)
      commit('SET_NOT_READ_COUNT', response.data.data.not_read)
      commit('SET_PAGINATION_DATA', response.data.data.lists.pagination)
    })
  },
  listenForEvents({ dispatch }) {
    if (!getUserData()) return
    const userData = getUserData()
    ChatHandler.getInstance().setup(`${new Date().getTime()}_${userData.id}`, userData)
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.VUE_APP_WEBSOCKETS_KEY,
      forceTLS: false,
      wsHost: process.env.VUE_APP_WEBSOCKETS_HOST,
      wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
      disableStats: true,
      authEndpoint: process.env.VUE_APP_WEBSOCKETS_AUTH_ENDPOINT,
      auth: {
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
          Accept: 'Application/json',
        },
      },
    })
    window.Echo.private(`Gec.Users.Models.User.${getUserData().id}`).notification(() => {
      dispatch('getNotifications')
      const notification = new Audio('/sfx/notfication.mp3')
      notification.play()
    })
  },
}
const state = {
  notifications: [],
  notReadCount: 0,
  self: null,
  paginationData: {
    current_page: 1,
    last_page: 1,
  },
}
const getters = {
  notifications: state => state.notifications,
  notReadCount: state => state.notReadCount,
  paginationData: state => state.paginationData,
  hasMoreNotifications: state => state.paginationData.current_page < state.paginationData.last_page,
}

const mutations = {
  UPDATE_NOTIFICATIONS(state, data) {
    const { notifications } = state
    notifications.push(...data)
    state.notifications = notifications
  },
  SET_NOTIFICATIONS(state, data) {
    state.notifications = data
  },
  SET_NOT_READ_COUNT(state, data) {
    state.notReadCount = data
  },
  SET_PAGINATION_DATA(state, data) {
    state.paginationData = data
  },
  RESET_PAGINATION_DATA(state) {
    state.paginationData = {
      current_page: 1,
      last_page: 1,
    }
  },
  INCREMENT_CURRENT_PAGE(state) {
    state.paginationData.current_page += 1
  },
  MARK_ALL_AS_READ(state) {
    const { notifications } = state
    notifications.forEach(element => {
      // eslint-disable-next-line no-param-reassign
      element.is_read = true
    })
    state.notifications = JSON.parse(JSON.stringify(notifications))
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
