<template>
  <b-modal
    :visible="show"
    :title="title"
    size="lg"
    no-close-on-backdrop
    @hide="$emit('close')"
    @close="$emit('close')"
  >
    <validation-observer ref="formProvider">
      <b-form-group :label="$t('animateditem-module.name')">
        <validation-provider
          v-slot="{ errors }"
          name="Item Name"
          rules="required"
        >
          <b-form-input
            v-model="form.name"
            :placeholder="$t('animateditem-module.name')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group :label="$t('animateditem-module.code_name')">
        <validation-provider
          v-slot="{ errors }"
          name="Item Code"
          rules="required"
        >
          <b-form-input
            v-model="form.code_name"
            :placeholder="`${$t('animateditem-module.code_name')} (01,22)`"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-row>
        <b-col>
          <b-col
            md="12"
            class="p-0 m-0"
          >
            <b-form-group :label="$t('animateditem-module.access_type')">
              <validation-provider
                v-slot="{ errors }"
                name="Item Type"
                rules="required"
              >
                <b-form-select
                  v-model="form.access_type"
                  :options="itemTypes"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="form.access_type=='full_access'"
            md="12"
            class="p-0 m-0"
          >
            <b-form-group :label="`${$t('animateditem-module.price')} ($)`">
              <validation-provider
                v-slot="{ errors }"
                name="Price"
                rules="required"
              >
                <b-form-input
                  v-model="form.price"
                  placeholder="40000"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="12"
            class="p-0 m-0"
          >
            <b-form-group :label="$t('animateditem-module.wear_type')">
              <validation-provider
                v-slot="{ errors }"
                name="Wearable Type"
                rules="required"
              >
                <b-form-select
                  v-model="form.wear_type"
                  :options="wearableTypes"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="12"
            class="py-2 m-0 p-0"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="form.is_public"
                :value="true"
                :unchecked-value="false"
              >
                {{ $t('animateditem-module.is-public') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>

        </b-col>
        <b-col>
          <label class="">
            {{ $t('animateditem-module.product-image') }}
          </label>
          <image-upload
            ref="imageUploader"
            :url="form.image"
            style="height:180px;"
            @imageUploaded="
              (file, url) => {
                updateImage(file, url)
              }
            "
            @imageRemoved="handleImageRemoved"
          />

        </b-col>
        <!-- <b-col>
          <b-form-group>
            <b-form-file />
          </b-form-group>
        </b-col> -->
      </b-row>
      <b-row>
        <b-col>
          <label>{{ $t('animateditem-module.item-for') }}</label>
          <v-select
            v-model="form.animal_type"
            :options="animalList"
            label="name"
            :reduce="animal => animal.value"
            multiple
          />
        </b-col>
      </b-row>
      <b-row class="pt-1">
        <b-col>
          <b-form-group :label="$t('animateditem-module.descriptions')">
            <b-form-textarea
              v-model="form.description"
              :placeholder="$t('animateditem-module.product-desc')"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{}">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            class="mr-2"
            @click="$emit('close')"
          >
            {{ $t('cancel') }}
          </b-button>
          <b-button
            variant="success"
            :disabled="isProcessing"
            @click="handleForm"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('actions.save') }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal, BFormGroup, BFormInput, BRow, BCol, BSpinner, BButton, BFormTextarea, BFormSelect, BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import i18n from '@/libs/i18n'
import ImageUpload from '@views/common/components/CustomImageUpload.vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import { USER_TYPE_SUPER, USER_TYPE_SCHOOL } from '@/const/userType'
import { getUserData } from '@/auth/utils'
import useApollo from '@/@core/graphql/useApollo'
import { wearableTypes } from '@/const/theme'

const initialForm = () => ({
  name: '',
  image: null,
  code_name: '',
  access_type: null,
  wear_type: null,
  animal_type: [],
  price: '',
  description: '',
  is_public: true,
})
export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    ImageUpload,
    vSelect,
    BSpinner,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormTextarea,
    BFormCheckbox,
    // BFormFile,

  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    animatedItem: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isProcessing: false,
      form: {
        ...initialForm(),
      },
      required,
      self: getUserData(),
      itemTypes: [
        { value: null, text: i18n.tc('animateditem-module.select-item-type') },
        { value: 'free', text: i18n.tc('animateditem-module.free') },
        { value: 'full_access', text: i18n.tc('animateditem-module.full-access') },

      ],
      wearableTypes,
      animalList: [
        { value: 'all', name: i18n.tc('animateditem-module.characters.all') },
        { value: 'all-animals', name: i18n.tc('animateditem-module.characters.all-animals') },
        { value: 'all-humans', name: i18n.tc('animateditem-module.characters.all-humans') },
        { value: 'elephant', name: i18n.tc('animateditem-module.characters.elephant') },
        { value: 'lion', name: i18n.tc('animateditem-module.characters.lion') },
        { value: 'girrafe', name: i18n.tc('animateditem-module.characters.girrafe') },
        { value: 'tiger', name: i18n.tc('animateditem-module.characters.tiger') },
        { value: 'karen', name: i18n.tc('animateditem-module.characters.karen') },
        { value: 'bill', name: i18n.tc('animateditem-module.characters.bill') },
        { value: 'alice', name: i18n.tc('animateditem-module.characters.alice') },
        { value: 'julie', name: i18n.tc('animateditem-module.characters.julie') },
        { value: 'kevin', name: i18n.tc('animateditem-module.characters.kevin') },
        { value: 'mike', name: i18n.tc('animateditem-module.characters.mike') },
      ],
    }
  },
  computed: {
    title() {
      return this.animatedItem ? i18n.tc('animateditem-module.update-item') : i18n.tc('animateditem-module.add-item')
    },
    isSuperUser() {
      return this.self.usertype === USER_TYPE_SUPER
    },
    isSchoolUser() {
      return this.self.usertype === USER_TYPE_SCHOOL
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    animatedItem(newValue) {
      if (newValue) {
        this.form = newValue
      } else {
        this.form = {
          ...initialForm(),
        }
      }
    },
  },
  created() {
    this.getSchoolUsers()
  },
  methods: {
    getSchoolUsers() {
      useApollo
        .getSchoolUsers()
        .then(response => {
          const res = response.data?.schools?.data
          this.schoolLists = res.map(item => ({ ...item, id: Number(item.id) }))
        })
        .finally(() => {
          this.schoolProcessing = false
        })
    },
    create() {
      this.isProcessing = true
      useJwt.storeAnimatedItems(this.form).then(response => {
        this.$emit('onUpdate')
        this.$emit('close')
        this.showSuccessMessage(response)
        this.form = {
          ...initialForm(),
        }
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    update() {
      this.isProcessing = true
      useJwt.updateAnimatedItems(this.animatedItem.id, this.form).then(response => {
        this.$emit('onUpdate')
        this.$emit('close')
        this.showSuccessMessage(response)
        this.form = {
          ...initialForm(),
        }
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    handleForm() {
      this.$refs.formProvider.validate().then(success => {
        if (success) {
          if (this.animatedItem) this.update()
          else this.create()
        }
      })
    },
    updateImage(file, url) {
      this.form.image = url
    },
    handleImageRemoved() {

    },
  },
}
</script>
