<template>
  <b-sidebar
    :visible="true"
    right
    shadow
    backdrop
    class="history-sidebar"
    @close="$emit('close')"
    @hidden="$emit('close')"
  >
    <template #header>
      <div class="d-flex justify-content-between align-items-center w-100 border-bottom pb-1">
        <div class="mb-0 text-left">
          <feather-icon icon="ClockIcon" /> History
        </div>
        <div class="text-right">
          <b-button
            v-b-tooltip.hover
            variant="outline-danger"
            size="sm"
            title="Clear all history"
            class="mr-1"
            @click="showClearConfirm"
          >
            <feather-icon icon="TrashIcon"
                          class="text-danger"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover
            variant="outline-secondary"
            size="sm"
            title="Close"
            @click="$emit('close')"
          >
            <feather-icon icon="XIcon"
                          class="text-secondary"
            />
          </b-button>
        </div>
      </div>
    </template>

    <div class="sidebar-content p-1">
      <div v-if="!histories.length"
           class="empty-state text-center p-4"
      >
        <i class="fas fa-history fa-2x mb-2 text-muted" />
        <p class="text-muted">
          No chat history available
        </p>
      </div>

      <b-list-group v-else>
        <b-list-group-item
          v-for="(history, index) in histories"
          :key="index"
          class="history-item cursor-pointer mb-2"
          @click="openUrl(history.url)"
        >
          <span>{{ history.url_name || 'Untitled' }}</span>
          <small class="text-muted ml-2">
            <feather-icon icon="ClockIcon" /> {{ formatDate(history.created_at) }}
          </small>
        </b-list-group-item>
      </b-list-group>
    </div>

    <!-- Clear History Confirmation Modal -->
    <b-modal
      v-model="showConfirmModal"
      title="Clear History"
      ok-variant="danger"
      ok-title="Clear"
      cancel-variant="outline-secondary"
      @ok="clearHistory"
    >
      <template #default>
        <div class="text-center py-3">
          <i class="fas fa-exclamation-triangle text-danger fa-3x mb-3" />
          <p>Are you sure you want to clear all chat history?</p>
          <small class="text-muted">This action cannot be undone.</small>
        </div>
      </template>
    </b-modal>
  </b-sidebar>
</template>

<script setup>
import {
  BSidebar,
  BListGroup,
  BListGroupItem,
  BButton,
  BModal,
  VBTooltip,
} from 'bootstrap-vue'
import { ref, onMounted, getCurrentInstance } from 'vue'
import useJwt from '@/auth/jwt/useJwt'

const root = getCurrentInstance().proxy.$root
const histories = ref([])
const showConfirmModal = ref(false)

const formatDate = date => {
  if (!date) return ''
  return new Date(date).toLocaleDateString()
}

defineEmits(['close', 'select-history'])

onMounted(() => {
  useJwt.getReaHistory().then(response => {
    histories.value = response.data.data
  })
})

const openUrl = url => {
  window.open(url, '_self')
}

const showClearConfirm = () => {
  showConfirmModal.value = true
}

const clearHistory = () => {
  useJwt.removeReaHistory().then(response => {
    histories.value = []
    showConfirmModal.value = false
    root.showSuccessMessage(response)
  }).catch(error => {
    root.showErrorMessage(error)
    showConfirmModal.value = false
  })
}

</script>

<style lang="scss" scoped>
.history-sidebar {
  .sidebar-content {
    height: calc(100vh - 60px);
    overflow-y: auto;
  }

  .empty-state {
    i {
      opacity: 0.5;
    }
  }

  .history-item {
    transition: all 0.2s ease;
    border-radius: 8px;
    border: 1px solid #eee;
    margin-bottom: 0.5rem;

    &:hover {
      background-color: #f8f9fa;
      transform: translateY(-1px);
      box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    }

    small {
      font-size: 0.85rem;
    }

    .fas {
      opacity: 0.7;
    }
  }
}

// Custom scrollbar
.sidebar-content {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 3px;

    &:hover {
      background: #999;
    }
  }
}
</style>
