<template>
  <b-modal :visible="true"
           size="lg"
           title="Template Lists"
           hide-footer
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <b-row class="mt-1">
      <b-col v-for="template, index of templates"
             :key="index"
             cols="4"
      >
        <template-preview-card :template="template"
                               :editable="false"
                               @apply="$emit('applyNoteTemplate', template.template)"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script setup>
import { BModal, BRow, BCol } from 'bootstrap-vue'
import { reactive, ref, getCurrentInstance } from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import TemplatePreviewCard from '@/views/school/note-template/components/template-preview-card.vue'

const state = reactive({
  isProcessing: false,
})

const root = getCurrentInstance().proxy.$root
const templates = ref([])

const getNoteTemplates = () => {
  state.isProcessing = true
  useJwt.getNoteTemplates().then(response => {
    templates.value = response.data.data
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    state.isProcessing = false
  })
}
getNoteTemplates()
</script>
