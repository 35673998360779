<template>
  <div>
    <course-report
      v-show="tableMode === 'course'"
      :room-id="parseInt($route.params.id, 10)"
      @next="changeMode('lesson', $event)"
      @showStat="fetchStudentLessonReport"
    />
    <lesson-report
      v-if="tableMode === 'lesson'"
      :room-id="parseInt($route.params.id, 10)"
      :course-id="parseInt(activeCourseId, 10)"
      @back="changeMode('course', null)"
    />

    <dialog-drag
      v-for="(dlg, index) in dialogs"
      :id="dlg.id"
      :key="index"
      :dialog="`dialog-${index}`"
      :style="`z-index:${activeDialogIndex === index ? 101:100} !important`"
      :options="{ top: `${dlg.options.top}`, left: `${dlg.options.left}` }"
      @close="closeModal(index)"
      @drag-start="activeDialogIndex = index"
      @drag-end="dialogDragEnd($event, index)"
      @move="dialogDragEnd($event, index)"
    >
      <h5
        slot="title"
        class="m-0 text-capitalize"
      >
        <h5
          slot="title"
          class="m-0 text-capitalize"
          @click="setActiveDialog(dlg.id)"
        >{{ activeStudent.fullname }}
        </h5>
        <lesson-table
          v-if="dlg.type === DIALOG_TYPE_STUDENT_LESSON_REPORT"
          :items="dlg.dialog"
          @showStat="getStudentLessonStats"
        />
        <student-problems-report
          v-else-if="dlg.type === DIALOG_TYPE_STUDENT_PROBLEMS_REPORT"
          :problem="dlg.dialog"
          :is-processing="isProcessing"
          @showPreview="showProblemPreview"
        />
      </h5>
    </dialog-drag>

    <question-preview
      ref="questionPreview"
    />
  </div>
</template>

<script>
import useApollo from '@/@core/graphql/useApollo'
import useJwt from '@/auth/jwt/useJwt'
import DialogDrag from 'vue-dialog-drag'
import LessonReport from './LessonReport.vue'
import CourseReport from './CourseReport.vue'
import LessonTable from './LessonTable.vue'
import StudentProblemsReport from '../components/ProblemsReport.vue'
import QuestionPreview from './QuestionPreview.vue'

export default {
  components: {
    CourseReport,
    LessonReport,
    DialogDrag,
    LessonTable,
    StudentProblemsReport,
    QuestionPreview,
  },
  data: () => ({
    tableMode: 'course',
    activeCourseId: null,
    dialogs: [],
    activeStudent: {},
    isProcessing: false,
    activeDialogIndex: 0,
    DIALOG_TYPE_STUDENT_LESSON_REPORT: 'student-lessons-report',
    DIALOG_TYPE_STUDENT_PROBLEMS_REPORT: 'student-problems-report',
  }),
  methods: {
    changeMode(mode, value) {
      this.tableMode = mode
      this.activeCourseId = value
    },
    fetchStudentLessonReport(student) {
      this.activeStudent = student
      useApollo.fetchStudentLessonReport(parseInt(student.id, 10), parseInt(this.$route.params.id, 10))
        .then(res => {
          this.dialogs.push({
            type: this.DIALOG_TYPE_STUDENT_LESSON_REPORT,
            dialog: res.data.loaders.data.map(d => ({
              ...d,
              id: this.rndText(),
              lesson: d.lesson.name,
              lesson_id: d.lesson.id,
            })),
            options: { top: this.dialogs.length * 30, left: this.dialogs.length * 30 },
          })
        })
    },
    getStudentLessonStats(index) {
      this.isProcessing = true
      useJwt.getStudentLessonStats(parseInt(this.$route.params.id, 10), parseInt(this.activeStudent.id, 10), this.dialogs[0].dialog[index].lesson_id).then(res => {
        this.dialogs.push({
          type: this.DIALOG_TYPE_STUDENT_PROBLEMS_REPORT,
          dialog: res.data.data,
          options: { top: this.dialogs.length * 30, left: this.dialogs.length * 30 },
        })
        this.activeDialogIndex = this.dialogs.length - 1
      }).finally(() => {
        this.isProcessing = false
      })
    },
    closeModal(index) {
      this.dialogs.splice(index, 1)
    },
    rndText() {
      return Math.random().toString(36).substring(7)
    },
    dialogDragEnd(obj, index) {
      this.$set(this.dialogs[index].options, 'left', obj.left)
      this.$set(this.dialogs[index].options, 'top', obj.top)
    },
    showProblemPreview(prob) {
      this.$refs.questionPreview.showProblemPreview(prob)
    },
  },
}
</script>
