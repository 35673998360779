<template>
  <div>
    <b-button
      variant="flat-secondary"
      class="mb-2"
      @click="$router.push({ name: `${AUTH_USER().usertype}-scorm-manager` })"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      <span class="align-middle">{{ $t('actions.back') }}</span>
    </b-button>
    <iframe
      id="scormFrameContent"
      style="height: 100vh;width: 100%;"
      frameBorder="0"
    />
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'

import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  data() {
    return {
      scormSco: this.$route.params.uuid,
      settings: null,
    }
  },
  mounted() {
    this.getScorm()
  },
  methods: {
    getScorm() {
      useJwt.getScormPlayer(this.scormSco).then(response => {
        const settings = response.data.data
        setTimeout(() => {
          this.settings = settings
          const iframe = document.querySelector('#scormFrameContent').contentWindow.document
          iframe.open('text/html', 'replace')
          iframe.write(response.data.html)
          iframe.close()
        }, 100)
      })
    },
  },
}
</script>
