/* eslint-disable global-require */
/* eslint-disable global-require */
<template>
  <div
    class="call-modal-wrap"
  >
    <div class="call-modal">
      <div class="call-info">

        <img
          :src="userImage"
        >
        <p>{{ incomingCallMessagePayload.extraData.callerName }}</p>
      </div>
      <div>
        <b-button
          variant="success"
          class="btn-icon rounded-circle call-icon"
          @click="acceptVideoCall"
        >
          <feather-icon icon="VideoIcon" />
        </b-button>
        <b-button
          variant="primary"
          class="ml-2 btn-icon rounded-circle call-icon"
          @click="acceptAudioCall"
        >
          <feather-icon icon="PhoneIcon" />
        </b-button>
        <b-button
          class="btn-icon ml-2 rounded-circle call-icon"
          variant="danger"
          @click="declineTheCall"
        >
          <feather-icon icon="PhoneOffIcon" />
        </b-button>
      </div>

    </div>
  </div>
</template>

<script>

import { BButton } from 'bootstrap-vue'
import VideoHandler from '@/views/common/chat/VideoHandler'
// import router from '@/router'
import ChatHandler from '@/views/common/chat/ChatHandler'
import {
  MessagePayload,
  VaniEvent,
} from 'vani-meeting-client'
import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import ringtone from './ring.mp3'

export default {
  name: 'CallInfoModal',
  components: {
    BButton,
  },
  props: {
    incomingCallMessagePayload: {
      type: Object,
      require: true,
      default: undefined,
    },
  },
  data() {
    return {
      userImage: undefined,
      ring: undefined,
      self: getUserData(),
      showCall: false,
    }
  },
  mounted() {
    if (VideoHandler.getInstance().getMeetingRequest() && VideoHandler.getInstance().getMeetingRequest().roomId === this.incomingCallMessagePayload.extraData.roomId) {
      ChatHandler.getInstance().eventEmitter.emit('HideVideoLayout', {})
    } else {
      ChatHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnNewChatMessageReceived, this.onNewChatMessageReceived)
      if (this.incomingCallMessagePayload && this.incomingCallMessagePayload.sender && this.incomingCallMessagePayload.sender && this.incomingCallMessagePayload.sender.userData.avatar && this.incomingCallMessagePayload.sender.userData.avatar.length > 0) {
        this.userImage = encodeURI(this.incomingCallMessagePayload.sender.userData.avatar)
      }
      this.ring = new Audio(ringtone)
      this.ring.loop = true
      this.ring.play()
    }
    ChatHandler.getInstance().eventEmitter.on('HideCallLayout', () => {
      this.ring.pause()
      this.ring.currentTime = 0
    })
  },
  methods: {
    acceptAudioCall() {
      this.acceptTheCall('audio')
    },
    acceptVideoCall() {
      this.acceptTheCall('video')
    },
    notifyCallAccepted() {
      const userId = this.incomingCallMessagePayload.sender?.userId
      const chatPayload = new MessagePayload('CallAccepted', userId)
      chatPayload.type = 'CallAccepted'
      ChatHandler.getInstance().sendMessage(chatPayload)
    },
    acceptTheCall(callType) {
      VideoHandler.getInstance().cleanUp()
      this.closeCallInOtherTab()
      this.ring.pause()
      this.ring.currentTime = 0
      VideoHandler.getInstance().setup(this.incomingCallMessagePayload.extraData.roomId, ChatHandler.getInstance().getMeetingRequest().userData, false)
      VideoHandler.getInstance().isVideoCall = (callType === 'video')
      // router.push('/call/video')
      ChatHandler.getInstance().eventEmitter.emit('ShowAudioCall', {})
      // this.showCall = true
      ChatHandler.getInstance().eventEmitter.emit('HideVideoLayout', {})
      ChatHandler.getInstance().eventEmitter.emit('AudioVideoCallAccepted', {})
    },

    closeCallInOtherTab() {
      useJwt.sendCancelCallEvent({
        receiver: this.self.id,
      }).then(() => {
        ChatHandler.getInstance().eventEmitter.emit('HideVideoLayout', {})
      })
    },
    declineTheCall() {
      this.ring.pause()
      this.ring.currentTime = 0
      ChatHandler.getInstance().eventEmitter.emit('UserIsVacentOnCall', this.incomingCallMessagePayload)
      ChatHandler.getInstance().eventEmitter.emit('HideVideoLayout', {})
    },
    onDeactivated() {
      ChatHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnNewChatMessageReceived, this.onNewChatMessageReceived)
    },
    onNewChatMessageReceived(messagePayload) {
      ChatHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnNewChatMessageReceived, this.onNewChatMessageReceived)
      if (messagePayload.type === 'VideoCallEnded') {
        this.ring.pause()
        this.ring.currentTime = 0
        ChatHandler.getInstance().eventEmitter.emit('HideVideoLayout', {})
      }
    },
  },
}
</script>
<style scoped>
.call-modal-wrap{
  z-index:1034;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
 .div2 {
  width:300px;
  height: 100px;
  padding: 50px;
  border: 1px red;
  box-sizing: border-box;
 }
 #center {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
 }
 .call-modal{
   position: relative;
   width: 320px;
   height: 280px;
   background-color: #fff;
   border-radius: 6px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   padding: 25px 0;
   box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
 }

 .call-info{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
 }
 .call-info img{
   width: 110px;
   height: 110px;
   border: 2px solid lightgrey;
   border-radius: 50%;
 }

 .call-info p{
   font-size: 25px;
   font-weight: 600;
   margin-top: 10px;
   max-width: 250px;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
 }
 .close{
   position: absolute;
   right: 10px;
   top: 10px;
   z-index: 101;
   cursor: pointer;
 }
 .call-icon{
   width: 42px;
   height:42px;
 }
</style>
