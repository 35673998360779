<template>
  <div>
    <!-- Dropdown Switch  -->
    <b-form-group
      :label="$t('labels.create-problem.dropdown')"
      label-for="fillBlanksType"
    >
      <b-form-checkbox
        v-model="fillBlanksType"
        name="fillBlanksType"
        value="1"
        unchecked-value="0"
        inline
        switch
        size="16"
      />
    </b-form-group>
    <b-row>
      <b-col md="2">
        <b-form-group
          label="Hide Hints"
          label-for="hideAnswerHints"
        >
          <b-form-checkbox
            v-model="hideAnswerHints"
            name="hideAnswerHints"
            value="1"
            unchecked-value="0"
            inline
            switch
            size="16"
          />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group
          label="Hide Text Hints"
          label-for="hideAnswerTextHints"
        >
          <b-form-checkbox
            v-model="hideAnswerTextHints"
            name="hideAnswerTextHints"
            value="1"
            unchecked-value="0"
            inline
            switch
            size="16"
          />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group
          label="Hide Image Hints"
          label-for="hideAnswerImageHints"
        >
          <b-form-checkbox
            v-model="hideAnswerImageHints"
            name="hideAnswerImageHints"
            value="1"
            unchecked-value="0"
            inline
            switch
            size="16"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <Recorder
          :url="extraFile"
          @input="onAddedQuestionAudio($event)"
          @onRemove="onRemovedQuestionAudio"
        />
      </b-col>

    </b-row>
    <validation-observer ref="blankForm">
      <!-- Question -->
      <b-form-group
        :label="$t('labels.create-problem.question')"
        label-for="question"
      >
        <!-- create edit template -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          block
          @click="showTemplate = true"
        >
          {{ templateData ? "Update Template" : "Create Template" }}
        </b-button>
        <create-template
          :show="showTemplate"
          :template="templateData"
          title="Template"
          :avoid-save="true"
          problem-type="fib_problem"
          :initial-template-options="[
            { value: 'none', text: 'None' },
            { value: 'paragraph', text: 'Paragraph' },
            { value: 'image-paragraph', text: 'Image & Paragraph' },
            { value: 'shapes', text: 'Shapes' },
            { value: 'image-qa', text: 'Question and Answer with Image' },
            { value: 'cards', text: 'Cards' },
            { value: 'centered-cards', text: 'Centered Cards' },
            { value: 'side-cards', text: 'Side Cards' },
          ]"
          @close="showTemplate = false"
          @templateStored="updateTemplate"
        />
        <!-- create edit template -->
      </b-form-group>
    </validation-observer>
  </div>
</template>

<script>
import {
  BFormGroup, BButton, BFormCheckbox, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import CreateTemplate from '@views/common/templates/CreateEdit.vue'
import Recorder from '@/views/super/problem/components/recorder.vue'

export default {
  components: {
    ValidationObserver,
    CreateTemplate,
    BFormGroup,
    BButton,
    BFormCheckbox,
    BRow,
    BCol,
    Recorder,
  },
  directives: {
    Ripple,
  },
  props: {
    level: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({
    showTemplate: false,
  }),
  computed: {
    hideAnswerHints: {
      get() {
        return this.$store.state.problem.create.blank.hideAnswerHints
      },
      set(value) {
        this.$store.state.problem.create.blank.hideAnswerHints = value
      },
    },
    hideAnswerTextHints: {
      get() {
        return this.$store.state.problem.create.blank.hideTextHints
      },
      set(value) {
        this.$store.state.problem.create.blank.hideTextHints = value
      },
    },
    hideAnswerImageHints: {
      get() {
        return this.$store.state.problem.create.blank.hideImageHints
      },
      set(value) {
        this.$store.state.problem.create.blank.hideImageHints = value
      },
    },
    extraFile() {
      return this.$store.state.problem.create.blank.questions.extraFile[this.level][0]
    },
    question: {
      get() {
        return this.$store.state.problem.create.blank.questions.text[this.level][0]
      },
      set(value) {
        const payload = {
          type: 'blank', level: this.level, index: 0, question: value,
        }
        this.$store.commit('problem/SET_QUESTION_FEILD', payload)
      },
    },
    showError: {
      get() {
        return this.$store.state.problem.showError
      },
      set() {},
    },
    templateData: {
      get() {
        return this.$store.state.problem.create.blank.template.templateData[this.level]
      },
      set(value) {
        this.$store.commit('problem/SET_BLANK_TEMPLATE', { value, level: this.level })
      },
    },
    fillBlanksType: {
      get() {
        return (this.$store.state.problem.create.blank.type === 'fib_dropdown') * 1
      },
      set(value) {
        this.$store.commit('problem/SET_BLANK_TYPE', value)
      },
    },
  },
  watch: {
    showError(value) {
      if (value) {
        this.$refs.blankForm.validate()
      }
    },
  },
  methods: {
    updateTemplate(data) {
      this.templateData = data
      this.showTemplate = false
    },
    onRemovedQuestionAudio() {
      const payload = {
        type: 'blank', level: this.level, index: 0, question: this.question, extraFile: '',
      }
      this.$store.commit('problem/SET_QUESTION_FEILD', payload)
    },
    onAddedQuestionAudio({ url }) {
      if (url) {
        this.blobUrlToBase64(url)
          .then(base64String => {
            const payload = {
              type: 'blank', level: this.level, index: 0, question: this.question, extraFile: base64String,
            }
            this.$store.commit('problem/SET_QUESTION_FEILD', payload)
          })
          .catch(error => {
            console.error('Error:', error)
          })
      }
    },
  },
}
</script>

<style scoped>
kbd {
  color: #333;
}
.dark-layout kbd {
  color: #bbb;
}
</style>
