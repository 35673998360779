<template>
  <b-modal
    :id="`self-evaluation-modal-${mode}`"
    :title="$t('self-evaluation.personal-assessment')"
    size="lg"
    hide-footer
    no-close-on-backdrop
  >
    <personality-evaluation-form
      v-if="needEvaluation"
      :student="user.id"
      :user="user"
      @close="closeModal"
    />
  </b-modal>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import useApollo from '@/@core/graphql/useApollo'

import {
  BModal,
} from 'bootstrap-vue'

import PersonalityEvaluationForm from '@/views/common/personality-evaluation/components/PersonalityEvaluationForm.vue'

export default {
  components: {
    BModal,
    PersonalityEvaluationForm,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      needEvaluation: false,
      school: null,
    }
  },
  mounted() {
    this.getUserSchool()
    if (this.mode && this.mode === 'edit') this.needEvaluation = true
  },
  methods: {
    getUserSchool() {
      useApollo.getParentUser('school').then(response => {
        this.school = response.data?.parentUsers?.data?.[0]
      }).finally(() => {
        if (this.school) this.checkPopupSetting()
        else this.checkPersonality()
      })
    },
    checkPopupSetting() {
      let popupSetting
      const params = {
        id: this.school.id,
      }
      useJwt.checkPopupSetting({ params }).then(response => {
        popupSetting = response.data?.data?.value
      }).finally(() => {
        if (Number(popupSetting)) this.checkPersonality()
      })
    },
    checkPersonality() {
      useJwt.checkPersonality().then(response => {
        if (!response.data) {
          this.needEvaluation = true
          this.$nextTick(() => {
            this.$bvModal.show(`self-evaluation-modal-${this.mode}`)
          })
        }
      })
    },
    closeModal() {
      this.$bvModal.hide(`self-evaluation-modal-${this.mode}`)
    },
  },
}
</script>
