<template>
  <div style="max-width:65px; margin-left:-20px;">
    <div
      class="group-icon"
      :style="{'width': size}"
    >
      <div
        v-for="user in contact.members"
        :key="user.id"
        :style="{'background-image': 'url('+user.avatar+')'}"
      />
    </div>
  </div></template>
<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    contact: {
      type: Object,
      require: false,
    },
    size: {
      type: [String, Number],
      require: false,
      default: 42,
    },
  },
}
</script>
