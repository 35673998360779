const neuralVoices = [
  'Danielle',
  'Gregory',
  'Ivy',
  'Joanna',
  'Kendra',
  'Kimberly',
  'Salli',
  'Joey',
  'Justin',
  'Kevin',
  'Matthew',
  'Ruth',
]

const standardVoices = [
  'Ivy',
  'Joanna',
  'Kendra',
  'Kimberly',
  'Salli',
  'Joey',
  'Kevin',
]

const longTerms = [
  'Danielle',
  'Gregory',
  'Ruth',
]

const combinedVoices = [
  {
    text: 'neural',
    data: neuralVoices,
  },
  {
    text: 'standard',
    data: standardVoices,
  },
  {
    text: 'long-form',
    data: longTerms,
  },
]

export default combinedVoices
