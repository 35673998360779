<template>
  <b-row :class="'theme-' + statement.theme">
    <b-col
      v-if="showBeforeText"
      class="problem-card-title"
      md="12"
    >
      <h3 class="mb-0">
        {{ $t("student-test-module.before-we-begin") }}
      </h3>
    </b-col>

    <b-col
      v-if="statement.image != null && statement.image != ''"
      md="12"
      class="problem-card-mid-content"
    >
      <div class="rounded-top text-center">
        <b-img
          :src="statement.image"
          style="max-height: 200px"
          fluid
        />
      </div>
    </b-col>
    <b-col
      v-if="statement.audio != null"
      md="12"
      class="problem-card-mid-content"
    >
      <audio-player
        :key="statement.audio"
        :src="statement.audio"
      />
    </b-col>
    <b-col class="problem-overview-content">
      <game-component
        v-if="statement.game && showGame"
        :code="statement.game.game_code"
        :loader-id="loaderId"
        @gameOver="$emit('continueToProblem')"
      />
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-else-if="!statement.template && statement.text != 'null'"
        v-text-to-speech
        class="ck-content"
        @click="handleClick"
        v-html="wordnetWords"
      />
      <!-- eslint-enable vue/no-v-html -->

      <show-template
        v-else-if="statement.template"
        :template="statement.template"
      />
    </b-col>
    <portal
      to="statementActionForAnimationLayout"
    >
      <b-col
        v-if="showLevel"
        class="
        d-flex
        col-12
        p-0
        justify-content-between
        align-content-center
        problem-card-footer
      "
      >
        <actions
          :level="level"
          mode="statement"
          :on-submit="nextStatement"
          :action-visible="!statement.game"
          :button-title="isLastStatement ? $t('continue') : $t('next')"
          :engine="engine"
          :school-settings="schoolSettings"
          :district-settings="districtSettings"
        />
      </b-col>
    </portal>
    <translation-section
      ref="translationModal"
      :active-translation="activeTranslation"
      :active-word-nets="activeWordNets"
      :active-word="activeWord"
      @set-active-language="setActiveLanguage"
    />
  </b-row>
</template>
<script>
import {
  BRow, BCol, BImg,
} from 'bootstrap-vue'
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'
import ShowTemplate from '@views/common/templates/Show.vue'
import TranslationSection from '@views/common/lessons/components/TranslationSection.vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import { getClassroomSettings } from '@/utils/visibilitySettings'
import GEC_EVENT_BUS from '@/utils/eventBus'
import Actions from './Actions.vue'
import GameComponent from './Game.vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    AudioPlayer,
    GameComponent,
    ShowTemplate,
    Actions,
    TranslationSection,
  },

  props: {
    statement: {
      type: Object,
      required: true,
    },
    level: {
      type: [Number, String],
      default: 1,
    },
    problemStatementId: {
      type: [Object, Number, String],
    },
    showLevel: {
      type: Boolean,
      default: () => true,
    },
    showBeforeText: {
      type: Boolean,
      default: () => true,
    },
    isLastStatement: {
      type: Boolean,
      default: () => false,
    },
    engine: {
      type: String,
      default: () => '',
    },
    loaderId: {
      type: [Object, Number, String],
      default: () => null,
    },
    schoolSettings: {
      type: Array,
      default: () => [],
    },
    districtSettings: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    activeTranslation: {},
    activeWord: '',
    activeLanguage: 'Korean',
    activeWordNets: '',
    activeOffset: null,
    words: [],
    self: getUserData(),
    showGame: false,
  }),
  computed: {
    activeLanguageShort() {
      if (this.activeLanguage === 'Korean') {
        return 'kor'
      }

      return 'jpn'
    },
    wordnetWords() {
      if (this.words?.length > 0) {
        this.words.forEach(word => {
          // const link = `<a style="cursor:pointer; color:#99cc00; text-decoration:none;" class="wordnet_popup" data-word="${word}">${word}</a>`
          const regEx = new RegExp(`(${word})(?!([^<]+)?>)`, 'gi')
          this.statement.text = this.statement.text.replace(regEx, "<a style='cursor:pointer; color:#99cc00; text-decoration:none;' class='wordnet_popup' data-word='$1'>$1</a>")
          // this.statement.text = this.statement.text.replace(word, link)
        })
      }
      return this.statement.text
    },
  },
  watch: {
    problemStatementId() {
      this.getWordNets()
    },
  },
  beforeDestroy() {
    GEC_EVENT_BUS.$emit('stopTextToSpeech')
  },
  created() {
    if (this.self) this.getWordNets()
  },
  mounted() {
    getClassroomSettings({ getSchoolAndDistrict: true }).then(setting => {
      const { district, school } = setting
      const schoolSetting = school?.find(s => s.setting_name === 'game')?.value ?? null
      const distSetting = district?.find(s => s.setting_name === 'game')?.value ?? 0

      if (distSetting) {
        this.showGame = Number(schoolSetting ?? distSetting)
      } else this.showGame = Number(distSetting)
    })
  },
  methods: {
    handleClick(e) {
      console.log(e)
      if (e.target.matches('.wordnet_popup, .wordnet_popup *')) {
        const word = e.target.getAttribute('data-word')
        this.openTranslationModal(word)
      }
    },
    openTranslationModal(word) {
      this.activeWord = word
      this.activeWordNets = ''
      useJwt.getCourseWordNetsForPage({
        problem_statement_id: this.problemStatementId,
        word,
      }).then(res => {
        if (res.status === 200) {
          this.activeWordNets = res.data.data
          const selectedWordnets = []
          this.activeWordNets.wordnet.forEach(w => {
            if (w.selected === 1) {
              selectedWordnets.push(w)
            }
          })
          if (selectedWordnets?.length > 0) {
            this.activeWordNets.wordnet = selectedWordnets
          }
          const filteredWordNet = this.activeWordNets.wordnet.find(w => w.selected === 1) ? this.activeWordNets.wordnet.find(w => w.selected === 1) : this.activeWordNets.wordnet[0]
          const { offset } = filteredWordNet
          const translatedWord = filteredWordNet[this.activeLanguageShort] ? [filteredWordNet[this.activeLanguageShort]] : filteredWordNet[`_${this.activeLanguageShort}`]
          this.activeOffset = offset
          if (translatedWord) {
            this.activeTranslation.words = translatedWord
          }
          this.$refs.translationModal.showModal()
        }
      })
    },
    getWordNets() {
      // if (!this.problemStatementId) return
      // useJwt.getCourseWordNetsArray(this.problemStatementId).then(res => {
      //   Object.values(res.data.data).forEach(word => {
      //     this.words.push(word.word)
      //   })
      // })
    },
    setActiveLanguage(language) {
      this.openTranslationModal(this.activeWord)
      this.activeLanguage = language
    },
    nextStatement() {
      if (this.isLastStatement) this.$emit('continueToProblem')
      else this.$emit('nextStatement')
    },
  },
}
</script>
<style scoped>
@media (max-width: 576px) {
  .problem-card-title h3{
    font-size: 20px !important;
    font-weight: 500 !important;
  }
}
</style>
