<template>
  <div class="mt-5">
    <div v-if="!isReservationSent">
      <div class="d-flex justify-content-center">
        <reservation-card
          v-if="reservationForm?.name"
          :class="'col-md-4'"
          :value="reservationForm"
          class="reservation-card"
          @onReserve="openStudentModal"
        />
        <div v-else
             class="card"
        >
          <div class="card-body">
            <h5 class="card-title">
              Sorry, No Reservation
            </h5>
            <p class="card-text">
              Please contact the school to get a reservation form.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else
         class="text-center"
    >
      <form-success v-if="isVerifiedFromShopify"
                    :school-reservation="true"
                    message="Payment Verified please check your email for further info"
      />
      <form-success v-else
                    :school-reservation="true"
                    @addMore="()=>{reserved=false; getSchoolReservations()}"
      />
    </div>
    <b-modal id="email-modal"
             ref="emailModal"
             title="Student Email"
             hide-footer
    >
      <validation-observer ref="StudentEmail">
        <b-form-group label="Student Email"
                      label-for="student-email"
        >
          <validation-provider v-slot="{ errors }"
                               name="Email"
                               vid="email"
                               rules="required|email|max:256"
          >
            <b-form-input id="student-email"
                          v-model="studentEmail"
                          placeholder="Student Email"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
      <div class="col text-right">
        <b-button variant="outline-success"
                  :disabled="studentEmail.length <= 0 || $refs.StudentEmail?.errors['email'].length > 0 || isProcessing"
                  @click="handleReserve"
        >
          <feather-icon v-if="!isProcessing"
                        icon="ShareIcon"
          /> <b-spinner v-else
                        small
          /> Reserve
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal,
  BFormInput,
  BFormGroup,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import ChildrenPicker from './components/NumberOfChildrenPicker.vue'
import { getUserData } from '@/auth/utils'
import getLandingPageFor from '@/router/utils'
import moment from 'moment'
import { USER_TYPE_STUDENT } from '@/const/userType'
import GEC_EVENT_BUS from '@/utils/eventBus'
import FormSuccess from './components/FormSuccess.vue'
import ReservationCard from './components/ReservationCard.vue'

export default {
  components: {
    BFormInput,
    BModal,
    BFormGroup,
    FormSuccess,
    ValidationProvider,
    ValidationObserver,
    ReservationCard,
    BButton,
    BSpinner,
  },
  data() {
    return {
      numberOfChildren: 0,
      reservationForm: {},
      parentEmail: null,
      isProcessing: false,
      activeTab: 0,
      savedForms: [],
      studentEmail: '',
      schoolLists: [],
      reserved: false,
      isVerifiedFromShopify: false,
    }
  },
  computed: {
    isReservationSent() {
      return (
        this.reserved
      )
    },
    orderId() {
      return this.$route.query.order_id
    },
  },
  mounted() {
    // this.getSchools()
    this.getSchoolReservations()
  },
  methods: {
    afterLogin(response) {
      const userData = response.data.data.user
      useJwt.setToken(response.data.data.accessToken)
      localStorage.setItem('userData', JSON.stringify(userData))
      this.$store.commit('app/SET_COMPANY_INFORMATION')
      this.$router.replace({ name: getLandingPageFor(userData.usertype) }).then(() => {
        this.$store.dispatch('authEvents/onUserLogin')
        this.showSuccessMessage(response)
        if (getUserData() && getUserData().usertype === USER_TYPE_STUDENT) this.getCurrentStudentCharacter()
        if (parseInt(moment().diff(getUserData().dob, 'years'), 10) < 13) {
          this.$store.commit('appConfig/UPDATE_THEME_ON', true)
          this.$store.commit('appConfig/UPDATE_IS_STUDENT_YOUNG', true)
        }
        GEC_EVENT_BUS.$emit('loggedin')
      })
    },
    bookReservationWithShopify() {
      useJwt.verifyShopifyReservation({
        order_id: this.orderId,
        school_id: this.reservationForm.school.id,
        reservation_id: this.reservationForm.id,
        is_testing: this.$route.query.is_testing,
      }).then(response => {
        this.showSuccessMessage(response)
        this.isVerifiedFromShopify = true
        this.reserved = !response.data.data.redirect_url
        if (response.data.data.redirect_url) {
          window.open(response.data.data.redirect_url, '_self')
        } else {
          this.afterLogin(response)
        }
      })
    },
    getSchools() {
      useJwt.getPublicSchools().then(response => {
        this.schoolLists = [
          { text: 'Any', value: null },
          ...response.data.data.schools.map(item => ({
            text: `${item.firstname} ${item.lastname}`,
            value: item.id,
          })),
        ]
      })
    },
    openStudentModal() {
      this.$bvModal.show('email-modal')
    },
    formatEmailWithPlusOne(email) {
      const parts = email.split('@')
      const username = parts[0]
      const domain = parts[1]

      // Creating the modified email address
      return `${username}+1@${domain}`
    },
    handleReserve() {
      this.$refs.StudentEmail.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt
            .storePublicReservation({
              school_id: this.$route.params.schoolId,
              parent_email: this.studentEmail,
              reservation_form: [
                {
                  dob: null,
                  student_email: this.formatEmailWithPlusOne(this.studentEmail),
                  reservation_id: this.reservationForm.id,
                  selected_class: null,
                },
              ],
            })
            .then(response => {
              this.showSuccessMessage(response)
              this.savedForms.push(this.activeTab)
              this.reserved = true
              this.$bvModal.hide('email-modal')
            })
            .catch(error => {
              this.showErrorMessage(error)
              if (error.response?.data?.errors) this.$refs.StudentEmail.setErrors(error.response.data.errors)
            })
            .finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
    getSchoolReservations() {
      const { schoolId, reservationId } = this.$route.params
      useJwt.getAvailableReservation({ params: { school_id: schoolId, type: 'non-scheduled', reservation_id: reservationId } }).then(res => {
        // eslint-disable-next-line prefer-destructuring
        this.reservationForm = res.data?.data?.reservation[0]
        if (this.orderId) {
          this.bookReservationWithShopify()
        }
      })
    },
    moveToNextChild(index) {
      this.activeTab = index + 1
    },
    resetForm() {
      this.savedForms = []
      Object.assign(this.$data, this.$options.data())
    },
  },
}
</script>
