<template>
  <div class="row">
    <div class="col-6">
      <b-tabs>
        <b-tab title="English">
          <EditorView v-if="localeJson"
                      :json="localeJson"
                      lang="en"
                      class="json-editor"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <b-button variant="outline-primary"
                      :disabled="isMerging"
                      @click.stop="mergeJson"
            >
              <b-spinner v-if="isMerging"
                         small
              /> Merge Data
            </b-button>
          </template>
        </b-tab>
      </b-tabs>
    </div>
    <div class="col-6">
      <b-tabs v-model="selectedTab">
        <b-tab v-for="other,index of otherLocale"
               :key="index"
               :title="other.text"
               lazy
        >
          <EditorView v-if="other.data"
                      :json="other.data"
                      :lang="other.lang"
                      class="json-editor"
          />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script setup>
import {
  BTab, BTabs, BButton, BSpinner,
} from 'bootstrap-vue'
import {
  ref,
} from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import EditorView from './EditorView.vue'

const localeJson = ref(null)
const otherLocale = ref([])
const isMerging = ref(false)
const selectedTab = ref(0)

const getSystemLocale = () => {
  localeJson.value = null
  otherLocale.value = []
  useJwt.getSystemLocale().then(response => {
    localeJson.value = response.data.en
    otherLocale.value = [
      {
        lang: 'vn',
        text: 'Vietnamese',
        data: response.data.vn,
      },
      {
        lang: 'jp',
        text: 'Japanese',
        data: response.data.jp,
      },
      {
        lang: 'cn',
        text: 'Chinese',
        data: response.data.cn,
      },
      {
        lang: 'kr',
        text: 'Korean',
        data: response.data.kr,
      },
      {
        lang: 'in',
        text: 'Indian',
        data: response.data.in,
      },
      {
        lang: 'tr',
        text: 'Turkish',
        data: response.data.tr,
      },
    ]
  })
}

const mergeJson = () => {
  isMerging.value = true
  useJwt.mergeSystemLocale({
    to_lang: otherLocale.value[selectedTab.value].lang,
  }).then(() => {
    getSystemLocale()
  }).finally(() => {
    isMerging.value = false
  })
}
getSystemLocale()
</script>
<style>
.json-editor {
  height: calc(100vh - 170px);
  overflow-y: auto;
}
</style>
