<template>
  <b-modal
    id="modal-article-create"
    ref="modal-article-create"
    ok-only
    size="lg"
    ok-variant="primary"
    :ok-title="$t('confirm')"
    modal-class="modal-primary"
    centered
    title="Delete Article"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-col md="12">
      <p>{{ $t('questions.delete-article') }}</p>
    </b-col>
    <template #modal-footer="{}">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="tertiary"
            @click="$emit('close')"
          >
            {{ $t('actions.cancel') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="danger"
            :disabled="isProcessing"
            @click="deleteArticle()"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('actions.delete-article') }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BSpinner, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    toggle: {
      type: Boolean,
      default: () => false,
    },
    articleData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isProcessing: false,
    article: {},
  }),
  watch: {
    toggle(value) {
      if (value === true) {
        this.$refs['modal-article-create'].show()
      } else {
        this.$refs['modal-article-create'].hide()
      }
    },
    articleData(value) {
      this.article = { ...value }
    },
  },
  methods: {
    deleteArticle() {
      this.isProcessing = true

      useJwt
        .deleteArticle(this.article.id)
        .then(response => {
          this.$emit('deleted', this.article.id)
          this.article = {}
          this.showSuccessMessage(response)
        })
        .finally(() => {
          this.isProcessing = false
          this.$emit('close')
        })
    },
  },
}
</script>
