/* eslint-disable import/no-cycle */
import isAuthenticated from '@/middlewares/isAuthenticated'
import nationalUsersOnly from '@/middlewares/nationalUsersOnly'

export default [
  // {
  //   path: '/national/home',
  //   meta: {
  //     middleware: [isAuthenticated, nationalUsersOnly],
  //   },
  //   name: 'national-home',
  //   component: () => import('@/views/national/Home.vue'),
  // },
  {
    path: '/national/users',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
    },
    name: 'national-users',
    component: () => import('@/views/common/user/Index.vue'),
  },
  {
    path: '/national/networks',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
    },
    name: 'national-networks',
    component: () => import('@/views/common/network/Index.vue'),
  },
  {
    path: '/national/networks/:id',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
      navActiveLink: 'national-networks',
    },
    name: 'national-networks-show',
    component: () => import('@/views/common/network/Show.vue'),
  },
  {
    path: '/national/classes',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
    },
    name: 'national-classes',
    component: () => import('@/views/common/class/Handler.vue'),
  },
  {
    path: '/national/classes/:id/:courseId?',
    name: 'national-class',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
      navActiveLink: 'national-classes',
    },
    component: () => import('@/views/common/class/Show.vue'),
  },
  {
    path: '/national/student-diary/:id',
    name: 'national-student-diary',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
    },
    component: () => import('@/views/common/student-diary/Index.vue'),
  },
  {
    path: '/student/dashboard/:id',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
    },
    name: 'student-dashboard-for-national',
    component: () => import('@/views/common/dashboard/Index.vue'),
  },
  // {
  //   path: '/national/courses/:id',
  //   name: 'national-course',
  //   meta: {
  //     middleware: [isAuthenticated, nationalUsersOnly],
  //     navActiveLink: 'national-classes',
  //   },
  //   component: () => import('@/views/common/class/components/ShowCourse.vue'),
  // },
  {
    path: '/national/view-students/:id',
    name: 'national-view-students',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
      navActiveLink: 'national-classes',
    },
    component: () => import('@/views/v2/common/class/components/StudentTable.vue'),
  },
  {
    path: '/national/library',
    name: 'national-library',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
    },
    component: () => import('@/views/super/library/index.vue'),
  },
  {
    path: '/national/courses/:library',
    name: 'national-courses',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
    },
    component: () => import('@/views/common/course/Index.vue'),
  },
  {
    path: '/national/problem',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
    },
    name: 'national-problem',
    component: () => import('@/views/super/problem/Index.vue'),
  },
  {
    path: '/national/curriculum-builder/:library',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
      navActiveLink: 'national-curriculum-builder',
    },
    name: 'national-curriculum-builder',
    component: () => import('@/views/common/curriculum/Index.vue'),
  },
  {
    path: '/national/error-reports',
    name: 'national-error-reports',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
    },
    component: () => import('@/views/common/report-issue/Index.vue'),
  },
  {
    path: '/national/class/:classId/course/:id/lessons',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
      navActiveLink: 'national-classes',
    },
    name: 'national-lessons',
    component: () => import('@/views/student/lesson/Index.vue'),
  },
  {
    path: '/national/higher-level-report',
    name: 'national-higher-level-report',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
    },
    component: () => import('@/views/common/higher-level-report/index.vue'),
  },
  {
    path: '/national/individual-report/:studentId',
    name: 'national-individual-report',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
    },
    component: () => import('@/views/common/individual-report/index.vue'),
  },
  {
    path: '/national/class/:classId/course/:id/result',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
      navActiveLink: 'national-classes',
    },
    name: 'national-course-result',
    component: () => import('@/views/student/course/Result.vue'),
  },
  {
    path: '/national/higher-level-report',
    name: 'national-higher-level-report',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
    },
    component: () => import('@/views/common/higher-level-report/index.vue'),
  },
  {
    path: '/national/class/:classId/lesson/:id',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
      navActiveLink: 'national-classes',
    },
    name: 'national-lesson',
    component: () => import('@/views/student/lesson/Show.vue'),
  },
  {
    path: '/national/class/:classId/lesson/:id/problems',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
      navActiveLink: 'national-classes',
    },
    name: 'national-problems',
    component: () => import('@/views/student/problem/Index1.vue'),
  },
  {
    path: '/national/classes/:classId/calendar',
    name: 'national-class-calendar',
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
      navActiveLink: 'teacher-classes',
    },
    component: () => import('@/views/teacher/class/components/calendar/Calendar.vue'),
  },
  {
    path: '/national/standards',
    name: 'national-standards',
    component: () => import('@/views/super/standard/Index.vue'),
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
    },
  },
  {
    path: '/national/courses',
    name: 'national-courses',
    component: () => import('@/views/common/course/Index.vue'),
    meta: {
      middleware: [isAuthenticated, nationalUsersOnly],
    },
  },
]
