<template>
  <div>
    <h3 class="mb-2">
      {{ $t('setting-module.ai-setting-title') }}
    </h3>
    <b-table-simple
      responsive
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th
            width="40%"
          >
            {{ $t('setting-module.options') }}
          </b-th>
          <b-th
            width="40%"
          >
            {{ $t('setting-module.switch') }}
          </b-th>
          <b-th
            width="20%"
          >
            {{ $t('setting-module.help-guide') }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>{{ $t('setting-module.ai-on-feedback-title') }}</b-td>
          <b-td v-if="showSettings && allSettings.enable_open_ai_check">
            <span class="d-flex align-items-baseline">
              <b-form-checkbox id="open-ai-check"
                               v-model="allSettings.ai_feedback"
                               value="1"
                               unchecked-value="0"
                               switch
                               @change="updateClassRoomSetting('ai_feedback')"
              />
              <span class="ml-2">
                {{ $t('setting-module.off-on') }}
              </span>
            </span>
          </b-td>
          <b-td v-else>
            {{ $t('setting-module.disabled') }}
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('ai_feedback')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>{{ $t('setting-module.learning-coach-title') }}</b-td>
          <b-td v-if="showSettings && allSettings.enable_open_ai_check">
            <span class="d-flex align-items-baseline">
              <b-form-checkbox v-model="allSettings.has_chat_bot"
                               value="1"
                               unchecked-value="0"
                               switch
                               @change="updateClassRoomSetting('has_chat_bot')"
              />
              <span class="ml-2">
                {{ $t('setting-module.off-on') }}
              </span>
            </span>
          </b-td>
          <b-td v-else>
            {{ $t('setting-module.disabled') }}
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('has_chat_bot')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>{{ $t('setting-module.speech-accuracy-title') }}</b-td>
          <b-td v-if="showSettings && allSettings.enable_open_ai_check">
            <span class="d-flex align-items-baseline">
              <b-form-checkbox v-model="allSettings.ai_speech"
                               value="1"
                               unchecked-value="0"
                               switch
                               @change="updateClassRoomSetting('ai_speech')"
              />
              <span class="ml-2">
                {{ $t('setting-module.off-on') }}
              </span>
            </span>
          </b-td>
          <b-td v-else>
            {{ $t('setting-module.disabled') }}
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('ai_speech')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>

    </b-table-simple>
    <setting-info-modal
      :show="showModal"
      :modal-data="modalData"
      @close="showModal = false"
    />
  </div>
</template>
<script>
import {
  BFormCheckbox, BButton,
  BTableSimple,
  BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import SettingInfoModal from '@/views/common/components/SettingInfoModal.vue'
import { checkSettings } from '@/utils/visibilitySettings'

export default {
  components: {
    BFormCheckbox,
    BButton,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    SettingInfoModal,
  },
  props: {
    allSettings: {
      type: Object,
      default: () => {},
    },
    classId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      modalData: {
        title: '',
        imgSrc: '',
        description: '',
        steps: '',
      },
      modalInfo: {
        ai_feedback: {
          title: i18n.tc('setting-module.ai-on-feedback-title'),
          description: i18n.tc('setting-module.ai-on-feedback'),
          imageSrc: '',
          steps: '',
        },
        has_chat_bot: {
          title: i18n.tc('setting-module.learning-coach-title'),
          description: i18n.tc('setting-module.learning-coach'),
          imageSrc: '',
          steps: '',
        },
        // ai_speech: {
        //   title: i18n.tc('setting-module.speech-accuracy-title'),
        //   description: i18n.tc('setting-module.learning-coach'),
        //   imageSrc: '',
        //   steps: '',
        // },
      },
      // showSettings: false,
    }
  },
  computed: {
    showSettings() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const setting = checkSettings('open_ai', schoolSetting, distSetting)
      return Number(setting.value)
    },
  },
  methods: {
    updateClassRoomSetting(type) {
      useJwt.updateClassroomSetting(this.classId, type, {
        value: this.allSettings[type],
      }).then(res => {
        this.showSuccessMessage(res)
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    showHelpGuide(type) {
      this.showModal = true
      this.modalData = {
        title: this.modalInfo[type].title,
        imgSrc: this.modalInfo[type].imageSrc,
        description: this.modalInfo[type].description,
        steps: this.modalInfo[type].steps,
      }
    },
  },
}
</script>
