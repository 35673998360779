<template>
  <b-card class="p-1">
    <b-overlay :show="state.isLoading">
      <h2 class="mb-2">
        Do You Need New Library ?
      </h2>
      <hr class="w-100">
      <p class="mb-2 text-warning italic">
        A library is a collection of courses and lessons. Easily create your own courses within the library. To request your personal library
        Please provide the library name, and the administrator will create it for you. You will be contacted shortly after your message is received.
      </p>

      <validation-observer ref="requestLibraryForm">
        <validation-provider
          v-slot="{errors}"
          name="library name"
          rules="required"
        >
          <b-form-group label="">
            <b-form-input
              v-model="state.form.name"
              placeholder="Please Type Your Desired Library Name"
              @keypress.enter="requestLibrary"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <b-button
          class="float-right mt-1"
          variant="primary"
          :disabled="state.isLoading"
          @click="requestLibrary"
        >
          Send Request
        </b-button>
      </validation-observer>
    </b-overlay>
  </b-card>
</template>

<script setup>
import {
  BCard, BFormGroup, BFormInput, BButton, BOverlay,
} from 'bootstrap-vue'
import { getCurrentInstance, reactive, ref } from 'vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import jwt from '@/auth/jwt/useJwt'

const root = getCurrentInstance().proxy.$root
const state = reactive({
  form: {},
  isLoading: false,
})
const requestLibraryForm = ref(null)

const requestLibrary = () => {
  requestLibraryForm.value.validate().then(success => {
    if (success) {
      state.isLoading = true
      jwt.requestLibrary(state.form).then(response => {
        requestLibraryForm.value.reset()
        state.form = {}
        root.showSuccessMessage(response)
      }).catch(error => {
        root.showErrorMessage(error)
      }).finally(() => {
        state.isLoading = false
      })
    }
  })
}
</script>
