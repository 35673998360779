<template>
  <div>
    <b-row>
      <b-col md="10">
        <b-row>
          <b-col>
            <p class="text-capitalize">
              <strong>{{ $t('third-party-module.list-of') }} {{ provider }}</strong>
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="2"
             xs="2"
             class="px-0"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="transparent"
          :disabled="isProcessing"
          @click="$router.push({ name: `super-thirdparty-accounts-list` })"
        >
          <feather-icon icon="ArrowLeftIcon" />
          {{ $t('actions.back') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-overlay
            id="overlay-background"
            :show="isProcessing"
            variant="transparent"
            opacity="0.8"
            blur="0.8"
            rounded="sm"
          >
            <b-table
              striped
              hover
              responsive
              :fields="columns"
              :items="userList"
            >
              <template #cell(fullname)="data">
                {{ data.item.user?.firstname?? `N/A` }} {{ data.item.user?.lastname?? `N/A` }}
              </template>
              <template #cell(email)="data">
                {{ data.item.user.email }}
              </template>
              <template #cell(expire_at)="data">
                {{ data.item.expire_at }}
              </template>
            </b-table>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BOverlay,
  BTable,
  BButton,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'UserList',
  components: {
    BRow,
    BCol,
    BCard,
    BOverlay,
    BTable,
    BButton,
  },
  data() {
    return {
      isProcessing: false,
      userList: [],
      columns: [
        { key: 'user_id', label: 'Id' },
        { key: 'fullname', label: i18n.tc('labels.fullname') },
        { key: 'email', label: i18n.tc('actions.email') },
        { key: 'expire_at', label: i18n.tc('labels.expire_at') },
        // { key: 'action', label: i18n.tc('help-videos-moduel.actions'), thStyle: { width: '150px' } },
      ],
      provider: this.$route.params.provider,
    }
  },
  created() {
    this.getUsersByProvider(this.$route.params.provider)
  },
  methods: {
    getUsersByProvider(provider) {
      this.isProcessing = true
      useJwt.getAllUsersByProvider(provider)
        .then(res => {
          this.userList = res.data.data
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
  },
}
</script>

<style scoped>

</style>
