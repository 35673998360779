<template>
  <b-card
    no-body
    class="mb-0"
  >
    <b-row class="pr-2 d-flex align-items-center justify-content-between">
      <b-col md="6">
        <h5 class="p-1 pb-0 mb-0">
          {{ $t("student-report-module.list-of-students") }}
        </h5>
      </b-col>

      <b-col
        md="6"
        style="max-width: 400px"
        class="mb-1 ml-1 mt-1 sm-mt-0 md-ml-0 md-mb-0"
      >
        <b-form-input
          id="filterInput"
          v-model="filter"
          class="mr-1"
          placeholder="Type to Search"
          @dragover.prevent
          @dragenter.prevent
        />
      </b-col>
    </b-row>

    <b-table
      responsive
      :items="studentsForTable"
      :fields="columns"
      :filter="filter"
      :per-page="perPage"
      hover
      :current-page="currentPage"
      show-empty
      @filtered="onFiltered"
    >
      <template #empty="">
        <div class="w-100 d-flex justify-content-center align-items-centert">
          <p class="p-0 m-0">
            {{ $t("messages.no-record-found") }}
          </p>
        </div>
      </template>
      <template #emptyfiltered="">
        <div class="w-100 d-flex justify-content-center align-items-centert">
          <p class="p-0 m-0">
            {{ $t("messages.no-record-found") }}
          </p>
        </div>
      </template>
      <template #cell(cefr_rate)="data">
        <span
          class="badge"
          :style="{ background: data.item.color, padding: '6px', color:!data.item.color ? '#6e6b7b' : '' }"
        >
          <span v-if="(isAParent || isAStudent)">
            {{ data.item.lang_level!=='invalid' ? data.item.lang_level : '---' }}
          </span>
          <span v-else>
            {{ data.item.cefr_rate }}
          </span>
        </span>
      </template>

      <template #cell(action)="data">
        <div class="d-flex ">
          <span>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="
                $t('student-report-module.view-lesson-report')
              "
              :disabled="isProcessing"
              variant="outline-primary"
              class="btn-icon mr-1"
              @click.stop="
                $emit('showStat', (currentPage - 1) * perPage + data.index)
              "
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </span>
          <!-- <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.top="$t('student-report-module.view-student-performance')"
            :disabled="isProcessing || data.item.total_attempts==0"
            variant="outline-primary"
            class="btn-icon mr-1"
            @click.stop="$emit('showChart',(currentPage-1)*perPage+data.index)"
          >
            <feather-icon icon="BarChart2Icon" />
          </b-button> -->

          <!-- <span>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-delete-user
              :disabled="isProcessing"
              variant="outline-primary"
              class="btn-icon mr-1"
              @click.stop="$emit('onClassDelete',(currentPage-1)*perPage+data.index)"
            >
              <feather-icon icon="MessageSquareIcon" />
            </b-button>
          </span> -->

          <span>
            <!-- <b-button
              v-if="isATeacher"
              v-b-tooltip.hover.top="
                $t('student-report-module.view-student-calendar')
              "
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :disabled="isProcessing"
              variant="outline-primary"
              class="btn-icon"
              @click.stop="$emit('navigateToTeacherCalendar', data.item.id)"
            >
              <feather-icon icon="CalendarIcon" />
            </b-button> -->
          </span>
        </div>
      </template>
    </b-table>
    <b-col
      v-if="totalRows > perPage"
      cols="12 d-flex justify-content-between"
    >
      <b-form-select
        id="perPageSelect"
        v-model="perPage"
        size="sm"
        :options="pageOptions"
        style="width: 80px"
      />
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        :disabled="isProcessing"
        align="center"
        class="my-1"
        @change="currentPage = $event"
      />
    </b-col>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
  BCol,
  BFormInput,
  BRow,
  BPagination,
  BFormSelect,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import i18n from '@/libs/i18n'
import { getUserData } from '@/auth/utils'
import {
  USER_TYPE_PARENT,
  USER_TYPE_STUDENT,
  USER_TYPE_TEACHER,
} from '@/const/userType'
import { getColorForLevel, getLevelFromScore } from '@/const/langLevel'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BCol,
    BFormInput,
    BRow,
    BPagination,
    BFormSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    students: {
      type: Array,
      required: true,
    },
    classDetails: {
      type: [Array, Object],
      required: false,
      default: () => {},
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    showRubric: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filter: null,
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      self: getUserData(),
      columns: [
        {
          key: 'name',
          label: i18n.tc('student-report-module.student-table.student-name'),
        },
        {
          key: 'total_attempts',
          label: i18n.tc('student-report-module.student-table.attempts'),
        },
        { key: 'cefr_rate', label: 'CEFR Rate' },
        {
          key: 'lessons_passed',
          label: i18n.tc('student-report-module.student-table.lessons-passed'),
        },
        // { key: 'standards_passed', label: 'Standards' },
        {
          key: 'problems_correct',
          label: i18n.tc('student-report-module.student-table.problems-passed'),
        },
        // { key: 'total_time', label: i18n.tc('student-report-module.student-table.time-spent') },
        // { key: 'efficiency', label: 'Efficiency' },
        {
          key: 'action',
          label: i18n.tc('action'),
          thStyle: { minWidth: '200px' },
        },
      ],
    }
  },

  computed: {
    isATeacher() {
      return getUserData().usertype === USER_TYPE_TEACHER
    },
    isAStudent() {
      return getUserData().usertype === USER_TYPE_STUDENT
    },
    isAParent() {
      return getUserData().usertype === USER_TYPE_PARENT
    },
    studentsForTable() {
      return this.students.map(e => ({
        name: e.name,
        id: e.student_id,
        lessons_passed: `${e.lesson_passed} of ${e.total_lessons}`,
        // standards_passed: 'to:do of to:do',
        problems_correct: `${e.problem_passed} of ${e.total_problems}`,
        total_time: `${e.total_time_spend}`,
        total_attempts: e.total_attempt,
        cefr_rate: e.cefr_rate,
        lang_level: e.lang_level,
        color: getColorForLevel(getLevelFromScore(e.cefr_rate)),
        // efficiency: 'to:do',
      }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.students.length
  },

  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style>
.class-table .active-column {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.class-table tr {
  cursor: pointer;
}
.class-table {
  margin-bottom: 0;
}
</style>
