<template>
  <b-row>
    <b-col
      md="12"
      sm="12"
      class="mx-auto"
    >
      <b-card
        v-if="course"
        text-variant="center"
        class="card"
      >
        <b-col
          md="12"
          class="p-0 d-flex justify-content-start pb-1"
        >
          <div>
            <b-button
              variant="flat-secondary"
              class="btn-icon"
              @click="GO_BACK()"
            >
              <feather-icon
                size="10"
                icon="ArrowLeftIcon"
              />
            </b-button>

            <span
              role="button"
              @click="
                $router.push({
                  name: `${self.usertype}-view-students`,
                  params: { id: classId },
                })
              "
            >
              {{ $t("student-test-module.active-class") }}
            </span>
            |
            <span
              role="button"
              @click="$router.push({ name: `${self.usertype}-classes` })"
            >
              {{ $t("student-test-module.list-of-classes") }}
            </span>
          </div>
        </b-col>
        <!-- message -->
        <div>
          <!-- <b-avatar
            variant="primary"
            size="70"
            class="shadow mb-2"
            :src="data.avatar"
          /> -->
          <h1 class="mb-1 mt-50">
            {{ $t("student-test-module.course-completed") }} -
            {{ getResultRows ? getResultRows.totalCompletedPercentage : 0 }} <span v-if="!isScorm">%</span>
          </h1>
        </div>
        <div class="text-center mt-2">
          <p>
            {{ $t("student-test-module.course-name") }}
          </p>
          <h1 class="font-large-1 font-weight-bold text-uppercase">
            {{ course }}
          </h1>
        </div>
        <!-- <div class="d-flex justify-content-around mt-2">
          <div class="text-center">
            <p>
              {{ $t("student-test-module.course-score") }}
            </p>
            <h1
              class="font-large-1 font-weight-bold"
            >{{ getCoursePercentage }}%</h1>
          </div>
        </div> -->
        <!-- message -->

        <!-- stats -->
        <div class="mt-4">
          <b-table-simple
            responsive
            class="table table-hover table-striped table-bordered"
          >
            <b-thead>
              <b-tr
                class="text-white text-bold"
                tag="tr"
              >
                <b-th class="bg-primary">
                  {{ $t('student-test-module.table-head.standard-code') }}
                </b-th>
                <b-th class="bg-primary">
                  {{ $t('student-test-module.table-head.standard-group') }}
                </b-th>
                <b-th class="bg-primary">
                  {{ $t('student-test-module.table-head.lesson-name') }}
                </b-th>
                <b-th class="bg-primary">
                  {{ $t('student-test-module.table-head.points') }}
                </b-th>
                <b-th class="bg-primary">
                  {{ $t('student-test-module.table-head.problem-passed') }}
                </b-th>
                <b-th class="bg-primary">
                  {{ $t('student-test-module.table-head.time-spent') }}
                </b-th>
                <b-th class="bg-primary">
                  {{ $t('student-test-module.table-head.complated-at') }}
                </b-th>
                <b-th class="bg-primary">
                  {{ $t('student-test-module.table-head.status') }}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-id="getResultRows">
              <template v-for="group of getResultRows.data">
                <template v-for="(r, index) of group">
                  <b-tr
                    :key="index"
                    :set="
                      (highlight =
                        $route.query.lesson_id == r.lesson_id
                          ? 'highlight'
                          : '')
                    "
                  >
                    <b-td
                      v-if="index === 0"
                      :rowspan="group.length"
                    >
                      {{ r.standard_code }}
                    </b-td>
                    <b-td
                      v-if="index === 0"
                      :rowspan="group.length"
                    >
                      {{ r.standard_name }}
                    </b-td>
                    <b-td :class="[highlight]">
                      {{ r.lesson }} </b-td>
                    <b-td :class="[highlight]">
                      {{ r.point }}
                    </b-td>
                    <b-td :class="[highlight]">
                      <span :class="[getStatus(r.status)]">
                        {{ r.problems_passed }}
                      </span>
                    </b-td>
                    <b-td :class="[highlight]">
                      {{ r.time_spent || "N/A" }}
                    </b-td>
                    <b-td :class="[highlight]">
                      {{ FROM_NOW(r.completed_at) }}
                    </b-td>
                    <b-td :class="[highlight]">
                      <span>
                        <feather-icon
                          v-if="r.status === 'passed'"
                          size="20"
                          icon="CheckIcon"
                          class="text-success"
                        />
                        <b-button
                          v-else-if="r.status === 'in_progress'"
                          size="sm"
                          variant="outline-primary"
                          :disabled="selectedStudentId != self.id"
                          @click="
                            $router.push({
                              name: `${self.usertype}-lesson`,
                              params: { id: r.lesson_id },
                              query: { event_id: r.event_id }
                            })
                          "
                        >
                          {{ $t('student-test-module.table-head.continue') }}
                        </b-button>
                        <b-badge v-if="r.loaderType == 'follow_up'"
                                 pill
                                 variant="danger"
                        >
                          {{ $t('student-test-module.table-head.follow-up') }}
                        </b-badge>
                      </span>
                    </b-td>
                  </b-tr>
                </template>
              </template>
            </b-tbody>
            <b-tfoot v-if="getResultRows">
              <b-tr>
                <b-td
                  colspan="3"
                  class="text-bold"
                >
                  {{ $t('student-test-module.table-head.total') }}
                </b-td>
                <b-td class="text-bold">
                  {{ getResultRows.total }}
                </b-td>
                <b-td />
                <b-td class="text-bold">
                  {{ getTotalTime }}
                </b-td>
                <b-td />
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </div>

        <b-row>
          <b-col class="mt-4 d-flex justify-content-between">
            <b-button
              variant="outline-primary"
              class="d-flex align-items-center"
              @click="$router.push({ name: self.usertype + '-classes' })"
            >
              {{ $t("student-test-module.to-class-list") }}
            </b-button>
            <b-button
              variant="outline-primary"
              class="d-flex align-items-center"
              @click="showTeacherRating"
            >
              {{ $t("student-test-module.rate-teacher") }}
            </b-button>
            <b-button
              variant="outline-primary"
              class="d-flex align-items-center"
              @click="showCourseRating"
            >
              {{ $t("student-test-module.rate-course") }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <course-rating
      ref="courseRating"
      :criteria="criteria"
      :is-processing="isProcessing"
      @submit="submitRating($event)"
      @showAll="showAllRatings"
    />
    <teacher-rating
      ref="teacherRating"
      :criteria="criteria"
      :is-processing="isProcessing"
      :is-teacher-available="isTeacherAvailable"
      @submit="submitRating($event)"
      @showAll="showAllRatings"
    />
    <all-ratings
      ref="showAllRatingsModal"
      :all-ratings="allRatings"
      :is-processing="isProcessing"
    />
  </b-row>
</template>

<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BTableSimple,
  BThead,
  BTr,
  BTd,
  BTh,
  BTbody,
  BTfoot,
  BBadge,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import useApollo from '@/@core/graphql/useApollo'
import courseRating from '../rating/CourseRatingModal.vue'
import allRatings from '../rating/ShowAllRatingModal.vue'
import teacherRating from '../rating/TeacherRatingModal.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTableSimple,
    BButton,
    courseRating,
    allRatings,
    teacherRating,
    BThead,
    BTr,
    BTd,
    BTh,
    BTbody,
    BTfoot,
    BBadge,
  },
  data() {
    return {
      courseId: null,
      isProcessing: true,
      result: [],
      course: null,
      activeType: null,
      activeTypeId: null,
      criteria: [],
      allRatings: {
        criteria: [],
        comments: [],
        totalRating: null,
      },
      teacherId: null,
      lessons: [],
      classId: null,
      isTeacherAvailable: false,
      self: getUserData(),
      totalPoint: 0,
      totalCompletedPercentage: 0,
      selectedStudentId: null,
    }
  },
  computed: {
    isScorm() {
      return !!this.getResultRows.normal_data.find(i => i.loaderType === 'scorm')
    },
    getCoursePercentage() {
      if (!this.result) return ''
      const total = this.result.reduce((t, each) => {
        // eslint-disable-next-line no-param-reassign
        t += each.score_percent
        return t
      }, 0)
      return parseInt(total / this.result.length, 10)
    },
    getTotalTime() {
      const arr = this.getResultRows.normal_data.map(a => a.time_spent)
      let sum = ''
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < arr.length; i++) {
        try {
          if (i === 0) {
            sum = arr[i]
          } else {
            const a = sum.split(':')
            const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2]
            const b = arr[i].split(':')
            const seconds2 = +b[0] * 60 * 60 + +b[1] * 60 + +b[2]
            const date = new Date(1970, 0, 1)
            date.setSeconds(seconds + seconds2)
            sum = date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, '$1')
          }
        } catch {
          console.error('time not formatted')
        }
      }
      return sum
    },
    getResultRows() {
      const formattedResult = this.result.map(e => ({
        id: e.id,
        lesson: e.lesson.name,
        lesson_id: e.lesson_id,
        standard_id: e.lesson.lessonStandards[0]?.id,
        standard_code: e.lesson.lessonStandards[0]?.code,
        standard_name: e.lesson.lessonStandards[0]?.name,
        point: e.points,
        problems_passed: `${e.totalPass} / ${e.totalProblems}`,
        time_spent: e.time_spent,
        status: e.status,
        completed_at: e.completed_at,
        loaderType: e.loaderType,
        event_id: e.event_id,
      }))
      const totalCompleted = formattedResult.filter(
        fr => fr.status === 'passed',
      ).length
      const groupedResult = formattedResult.reduce((r, a) => {
        // eslint-disable-next-line no-param-reassign
        r[a.standard_id] = r[a.standard_id] || []
        r[a.standard_id].push(a)
        return r
      }, Object.create(null))
      // eslint-disable-next-line no-unused-expressions
      return {
        total: formattedResult.reduce(
          (partialSum, a) => partialSum + a.point,
          0,
        ),
        data: groupedResult,
        normal_data: formattedResult,
        totalCompletedPercentage:
          formattedResult.length > 0
            ? ((totalCompleted / formattedResult.length) * 100).toFixed(2)
            : 0,
      }
    },
  },

  watch: {
    '$route.params.id': {
      handler(id) {
        this.courseId = parseInt(id, 10)
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.courseId = parseInt(this.$route.params.id, 10)
    this.classId = parseInt(this.$route.params.classId, 10)
    this.getCoursesInfo()
    this.getCourseResult()
  },
  methods: {
    getStatus(status) {
      switch (status) {
        case 'passed':
          return 'text-success'
        case 'in_progress':
          return 'text-warning'
        default:
          return 'text-danger'
      }
    },
    getCoursesInfo() {
      useApollo
        .getCourseInfo({
          id: this.courseId,
        })
        .then(response => {
          this.course = response.data.course.name
        })
    },
    getCourseResult() {
      this.isProcessing = true
      const studentId = this.$route.query.student_id
        ? Number(this.$route.query.student_id)
        : this.self.id
      this.selectedStudentId = studentId
      useApollo
        .getLessonCourseResult({
          user_id: studentId,
          group_id: this.classId,
          course_id: this.courseId,
        })
        .then(response => {
          this.result = response.data.loaders.data
        })
        .finally(() => {
          this.isProcessing = false
        })
      // useJwt.getCourseResult(this.courseId, { params: { cid: this.classId } }).then(res => {
      //   this.teacherId = res.data.data.tid
      //   this.course = res.data.data.course
      //   this.result = this.processResult(res.data.data.result)
      // }).finally(() => {
      //   this.isProcessing = false
      //   if (this.teacherId === null) this.isTeacherAvailable = false
      //   else this.isTeacherAvailable = true
      // })
    },
    getCriteria() {
      this.isProcessing = true
      this.criteria = []
      useJwt
        .getCriteria(this.activeType)
        .then(response => {
          this.criteria = response.data.data.data.map(c => ({
            id: c.id,
            criteria_id: c.id,
            title: c.title,
          }))
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    showCourseRating() {
      this.activeType = 'course'
      this.activeTypeId = this.courseId
      this.getCriteria()
      this.$refs.courseRating.showModal()
    },
    showTeacherRating() {
      this.activeType = 'teacher'
      this.activeTypeId = this.teacherId
      this.getCriteria()
      this.$refs.teacherRating.showModal()
    },
    showAllRatings() {
      this.$refs.showAllRatingsModal.showModal()
      this.getRatings()
    },
    getRatings() {
      this.isProcessing = true
      this.allRatings = {
        criteria: [],
        comments: [],
        totalRating: null,
      }
      if (this.activeType === 'course') {
        useJwt
          .getCourseRating(this.courseId)
          .then(response => {
            this.makeRatings(response)
          })
          .catch(error => this.showErrorMessage(error))
          .finally(() => {
            this.isProcessing = false
          })
      } else if (this.activeType === 'teacher') {
        if (this.teacherId !== null) {
          useJwt
            .getTeacherRating(this.teacherId)
            .then(response => {
              this.makeRatings(response)
            })
            .catch(error => this.showErrorMessage(error))
            .finally(() => {
              this.isProcessing = false
            })
        }
      }
    },
    makeRatings(response) {
      this.allRatings.totalRating = response.data.data.total_rating
      this.allRatings.criteria = response.data.data.criteria.map(c => ({
        title: c.criteria_name,
        avgRating: Math.round(c.average_rating * 100) / 100,
        id: c.id,
      }))
      this.allRatings.comments = response.data.data.user_lists.map(item => ({
        description: item.comment,
        userName: `${item.firstname} ${item.lastname}`,
        userAvatar: item.avatar,
      }))
    },
    submitRating(ratingsData) {
      const { criteriaIds, criteriaRatings, comment } = ratingsData
      const payload = {
        criteria_id: criteriaIds,
        rating_in: this.activeType,
        rating: criteriaRatings,
        rated_to: this.activeTypeId,
        comment,
      }
      useJwt
        .submitRating(payload)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .finally(() => {
          this.$refs.courseRating.clearFields()
          this.$refs.teacherRating.clearFields()
        })
    },
    processResult(data) {
      return data.reduce((r, a) => {
        const existingEntryIndex = r.findIndex(e => e.lesson === a.lesson)
        let existingEntry = {}

        if (existingEntryIndex < 0) {
          existingEntry = {
            ...a,
            ...{ passed_standards: a.status === 'pass' ? 1 : 0 },
          }
          r.push(existingEntry)
        } else {
          existingEntry = r[existingEntryIndex]
          existingEntry.passed_standards += a.status === 'pass' ? 1 : 0
          // eslint-disable-next-line no-param-reassign
          r[existingEntryIndex] = existingEntry
        }
        return r
      }, [])
    },
  },
}
</script>
<style scoped>
.text-bold {
  font-weight: bold !important;
}
.highlight {
  background: #eaea9b;
}
</style>
