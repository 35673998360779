<template>
  <b-modal
    id="question-preview-modal"
    ref="question-preview-modal"
    v-model="isVisible"
    ok-only
    size="xl"
    ok-variant="outline-secondary"
    :ok-title="$t('close')"
    modal-class="modal-primary"
    centered
    :title="$t('labels.question-preview')"
    @close="resetAndClose"
    @hide="resetAndClose"
  >
    <div :class="`student-problem theme-${getTheme}`">
      <b-col
        v-if="mode==='statement'"
        md="12"
      >
        <div v-if="showHotSpot">
          <AnimatedLayoutNavManger :animated-template-id="data.animatedTemplateId"
                                   :show-edit-mode="showEditMode"
                                   :has-animated-layout="hasAnimatedLayout"
                                   :is-feed-back-mode-enabled="isFeedBackModeEnabled"
                                   :is-animated-editor-mode-enabled="isAnimatedEditorModeEnabled"
                                   @updateHasAnimatedLayout="val => hasAnimatedLayout = val"
                                   @updateIsAnimatedEditorModeEnabled="val => isAnimatedEditorModeEnabled = val"
                                   @updateIsFeedBackModeEnabled="val => isFeedBackModeEnabled = val"
          />
          <animated-layout
            :enabled="hasAnimatedLayout"
            :is-edit-mode="showEditMode && isAnimatedEditorModeEnabled"
            :template-id="data.animatedTemplateId"
            :is-problem-body-preview="true"
          >
            <hot-spot-statement

              :hotspot="data ? data.hotspot : null"
              :level="data ? data.level : null"
              :preview-answers="data ? data.answers : []"
              @proceedToQuestion="mode='question'"
            />
          </animated-layout>
        </div>

        <video-statement
          v-if="data && data.statement && data.statement.video && !showHotSpot"
          :video="data.statement.video"
          :level="data ? data.level : null"
          :is-last-statement="true"
          @continueToProblem="mode='question'"
        />

        <AnimatedLayout v-else-if="!showHotSpot && data"
                        teleport-target="statementActionForAnimationLayout"
                        :enabled="data.statement && data.statement.template && data.statement.template.animated_layout == 1 && data.statement.template.animated_template_id"
                        :is-edit-mode="false"
                        :template-id="data.statement && data.statement.template && data.statement.template.animated_layout == 1 && data.statement.template.animated_template_id"
        >
          <statement
            :statement="data && data.statement ? data.statement : {}"
            :problem-statement-id="1"
            :level="data ? data.level : null"
            :is-last-statement="true"
            @continueToProblem="mode='question'"
          />
        </AnimatedLayout>

      </b-col>
      <b-col
        v-if="mode==='question'"
        md="12"
      >
        <AnimatedLayoutNavManger v-if="!data.hideLayoutManager"
                                 :animated-template-id="data.animatedTemplateId"
                                 :show-edit-mode="showEditMode"
                                 :has-animated-layout="hasAnimatedLayout"
                                 :is-feed-back-mode-enabled="isFeedBackModeEnabled"
                                 :is-animated-editor-mode-enabled="isAnimatedEditorModeEnabled"
                                 @updateHasAnimatedLayout="val => hasAnimatedLayout = val"
                                 @updateIsAnimatedEditorModeEnabled="val => isAnimatedEditorModeEnabled = val"
                                 @updateIsFeedBackModeEnabled="val => isFeedBackModeEnabled = val"
        />
        <animated-layout
          :enabled="hasAnimatedLayout"
          :is-edit-mode="showEditMode && isAnimatedEditorModeEnabled"
          :template-id="data.animatedTemplateId"
          :is-problem-body-preview="true"
        >
          <component
            :is="getProblemComponent"
            mode="question"
            class="m-0"
            :rules="data.rules"
            :questions="data.questions"
            :preview-answers="data.answers"
            :feedback="isFeedBackModeEnabled ? data.feedback : {}"
            :is-preview="true"
            :is-processing="false"
            :test-id="data && data.testId"
            :display-result="false"
            :level="data ? data.level : null"
            :start-time="getProblemComponent === 'FillProblem' ? `${new Date()}`: new Date()"
            :problem-statement-id="1"
            @submitQuestion="()=>{}"
            @close="$emit('close')"
            @nextQuestion="()=>{}"
          />
        </animated-layout>
      </b-col>

      <div v-if="mode==='feedback'">
        <hot-spot-feedback
          v-if="showHotSpot"
          :level="data ? data.level : null"
          :hotspot="data ? data.hotspot : null"
        />
      </div>

    </div>

  </b-modal>
</template>

<script>
import {
  BModal, BCol, BFormGroup, BFormCheckbox, BButton, BSpinner,
} from 'bootstrap-vue'
import Statement from '@views/student/problem/compoments/Statement.vue'
import VideoStatement from '@views/student/problem/compoments/VideoStatement.vue'
import MatchProblem from '@views/student/problem/compoments/MatchProblem.vue'
import FillProblem from '@views/student/problem/compoments/FillProblem.vue'
import FillProblemDropdown from '@views/student/problem/compoments/FillProblemDropdown.vue'
import MultipleProblem from '@views/student/problem/compoments/MultipleProblem.vue'
import SpeechProblem from '@views/student/problem/compoments/SpeechProblem/index.vue'
import HotSpotStatement from '@views/student/problem/hotspot/HotSpotStatement.vue'
import HotSpotBody from '@views/student/problem/hotspot/HotSpotBody.vue'
import HotSpotFeedback from '@views/student/problem/hotspot/HotSpotFeedback.vue'
import DragPosition from '@views/student/problem/compoments/DragPosition/index.vue'
import {
  PROBLEM_TYPE_MATCH,
  PROBLEM_TYPE_FILL_BLANK,
  PROBLEM_TYPE_FILL_DROPDOWN,
  PROBLEM_TYPE_MULTIPLE,
  PROBLEM_TYPE_SPEECH,
  PROBLEM_TYPE_CATEGORY,
  OPEN_END_WRITING,
  TEXT_SELECTION,
  PROBLEM_DRAG_POSITION,
  PROBLEM_TYPE_MULTIPLE_LISTENING,
  PROBLEM_TYPE_MATCH_LISTENING,
  OPEN_END_WRITING_SPEECH,
} from '@/const/problemTypes'
import CategoryProblem from '@views/student/problem/compoments/CategoryProblem.vue'
import OpenEndWriting from '@views/student/problem/compoments/OpenEndWriting.vue'
import TextSelection from '@views/student/problem/compoments/TextSelection.vue'
import AnimatedLayout from '@/views/super/problem/body-template/manager.vue'
import vSelect from 'vue-select'

import AnimatedLayoutNavManger from './AnimatedLayoutNav.vue'

export default {
  components: {
    BModal,
    BCol,
    Statement,
    VideoStatement,
    MatchProblem,
    FillProblem,
    FillProblemDropdown,
    MultipleProblem,
    SpeechProblem,
    HotSpotStatement,
    HotSpotBody,
    HotSpotFeedback,
    CategoryProblem,
    OpenEndWriting,
    DragPosition,
    AnimatedLayout,
    BFormGroup,
    BFormCheckbox,
    TextSelection,
    BButton,
    BSpinner,
    vSelect,
    AnimatedLayoutNavManger,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    showHotSpot: {
      type: Boolean,
      default: false,
    },
    showEditMode: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    mode: 'statement',
    isVisible: false,
    isAnimatedEditorModeEnabled: true,
    isFeedBackModeEnabled: true,
  }),
  computed: {
    hasAnimatedLayout: {
      get() {
        return this.data.hasAnimatedLayout ? 1 : 0
      },
      set(value) {
        this.$root.$emit('toggleAnimatedLayout', value)
        this.data.hasAnimatedLayout = value
      },
    },
    getTheme() {
      const animatedConfig = this.$store.state.problem.animatedLayoutConfig

      if (animatedConfig && animatedConfig.bodyTheme) {
        return animatedConfig.bodyTheme
      }
      return (this.data && this.data.theme) ? this.data.theme : '0'
    },
    getProblemComponent() {
      if (this.data.problemType === PROBLEM_TYPE_MATCH) {
        return 'MatchProblem'
      }
      if (this.data.problemType === PROBLEM_TYPE_MATCH_LISTENING) {
        return 'MultipleProblem'
      }
      if (this.data.problemType === PROBLEM_TYPE_FILL_BLANK) {
        return 'FillProblem'
      }
      if (this.data.problemType === PROBLEM_TYPE_FILL_DROPDOWN) {
        return 'FillProblemDropdown'
      }
      if (this.data.problemType === PROBLEM_TYPE_MULTIPLE) {
        return 'MultipleProblem'
      }
      if (this.data.problemType === PROBLEM_TYPE_MULTIPLE_LISTENING) {
        return 'MultipleProblem'
      }
      if (this.data.problemType === PROBLEM_TYPE_SPEECH) {
        return 'SpeechProblem'
      }
      if (this.data.problemType === PROBLEM_TYPE_CATEGORY) {
        return 'CategoryProblem'
      }
      if (this.data.problemType === OPEN_END_WRITING) {
        return 'OpenEndWriting'
      }
      if (this.data.problemType === OPEN_END_WRITING_SPEECH) {
        return 'OpenEndWriting'
      }
      if (this.data.problemType === TEXT_SELECTION) {
        return 'TextSelection'
      }
      if (this.data.problemType === PROBLEM_DRAG_POSITION) {
        return 'DragPosition'
      }
      return ''
    },
  },
  watch: {
    show: {
      handler(value) {
        this.isVisible = this.show
        if (value) {
          this.hasAnimatedLayout = this.data.hasAnimatedLayout
          if (this.data.hideStatement) {
            this.mode = 'question'
          }
        }
      },
      immediate: true,
    },
    // eslint-disable-next-line func-names
    'data.hideStatement': function (value) {
      if (value) {
        this.mode = 'question'
      }
    },
  },
  mounted() {
    this.$root.$off('reSyncAnimationTemplateId')
    this.$root.$on('reSyncAnimationTemplateId', id => {
      this.data.animatedTemplateId = id
    })
  },
  methods: {
    resetAndClose() {
      this.mode = 'statement'
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
  @import "/src/assets/scss/custom/problem-themes/main.scss";
</style>

<style>
@media (min-width: 992px){
  #question-preview-modal .modal-lg {
    max-width: 1200px;
  }
}
</style>
