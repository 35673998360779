export default function PlayBase64Audio(base64Data, onEndedCallBack, onPauseCallBack) {
  let audioContext
  let audioSource

  // Decode the Base64 data
  function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64)
    const { length } = binaryString
    const bytes = new Uint8Array(length)

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }

    return bytes.buffer
  }

  // Play the decoded audio
  function playAudio() {
    const audioData = base64ToArrayBuffer(base64Data)
    audioContext = new (window.AudioContext || window.webkitAudioContext)()
    audioSource = audioContext.createBufferSource()

    audioContext.decodeAudioData(
      audioData,
      buffer => {
        audioSource.buffer = buffer
        audioSource.connect(audioContext.destination)
        audioSource.start(0)
      },
      error => {
        console.error('Error decoding audio data:', error)
      },
    )

    // Event handler for when audio playback ends
    audioSource.onended = function () {
      if (onEndedCallBack) {
        onEndedCallBack()
      }
      // You can perform actions here when the audio ends.
    }

    // Event handler for when audio playback is paused
    audioSource.onpause = function () {
      if (onPauseCallBack) {
        onPauseCallBack()
      }
      // You can perform actions here when the audio is paused.
    }
  }

  // Method to stop the audio
  function stopAudio() {
    if (audioSource) {
      audioSource.stop()
      audioContext.close()
    }
  }

  // Call the playAudio function to play the audio
  playAudio()

  // Return the stopAudio method for external use
  return {
    stopAudio,
  }
}
