<template>
  <div class="mt-2">
    <a
      class="d-flex mb-2"
      @click="$emit('back')"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        size="16"
      />
      <span style="padding-left: 4px">Back</span>
    </a>
    <b-overlay :show="isProcessing">
      <b-row>
        <b-col md="4">
          <b-card no-body>
            <!-- lesson list table -->
            <b-table
              :sticky-header="true"
              hover
              responsive
              :items="lessons"
              :fields="['name']"
              @row-clicked="setActiveLesson"
            >
              <template #cell(name)="data">
                <div class="d-flex items-center">
                  <feather-icon
                    icon="BookOpenIcon"
                    size="17"
                  />
                  <span class="ml-1">{{ data.item.name }}</span>
                </div>
              </template>
            </b-table>
            <b-card-text
              v-if="!lessons.length"
              class="text-center pb-1"
            >
              {{ $t('messages.no-record-found') }}
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="8">
          <report-table
            v-if="activeLesson"
            :room-id="roomId"
            :lesson-ids="[parseInt(activeLesson, 10)]"
            view-mode="lesson"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
// BBadge,
// BTooltip,
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BOverlay,
  BCardText,
} from 'bootstrap-vue'
import useApollo from '@/@core/graphql/useApollo'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BOverlay,
    BCardText,
  },
  props: {
    roomId: {
      type: Number,
      default: () => 0,
    },
    courseId: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({
    lessons: [],
    activeLesson: null,
    isProcessing: false,
  }),
  created() {
    this.isProcessing = true
    useApollo.fetchLessonList(this.courseId).then(response => {
      const res = response?.data?.course?.lessons
      if (res) this.lessons = res

      // eslint-disable-next-line prefer-destructuring
      if (!this.activeLesson && this.lessons.length) this.setActiveLesson(this.lessons[0])
    }).finally(() => {
      this.isProcessing = false
    })
  },
  methods: {
    setActiveLesson(item) {
      this.activeLesson = item.id

      this.lessons.forEach((l, i) => {
        // eslint-disable-next-line dot-notation
        if (l.id === item.id) this.lessons[i]['_rowVariant'] = 'secondary'
        // eslint-disable-next-line dot-notation
        else this.lessons[i]['_rowVariant'] = ''
      })
    },
  },
}

</script>
