<template>
  <b-row
    class="my-networks mb-2"
  >
    <edit-create
      v-if="self"
      mode="create"
      @onNetworkCreated="onNetworkCreated"
    />
    <b-col
      md="12"
      sm="12"
      class="d-flex justify-content-between"
    >
      <div>
        <b-button
          v-if="showBackButton"
          variant="flat-secondary"
          class="btn-icon"
          @click="GO_BACK()"
        >
          <feather-icon
            size="20"
            icon="ArrowLeftIcon"
          />
        </b-button>
      </div>

      <b-dropdown
        v-if="self"
        id="dropdown-1"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :text="$t('network-module.my-networks')"
        variant="primary"
      >
        <b-dropdown-item v-b-modal.modal-create-network>
          {{ $t('network-module.add-new-network') }}
        </b-dropdown-item>
        <b-dropdown-item
          v-for="(network,index) in myNetworks"
          :key="'mynetwork_'+index"
          @click="navigateToNetworkShowPage(network.id)"
        >{{ network.name }}
        </b-dropdown-item>
      </b-dropdown>
    </b-col>

  </b-row>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import EditCreate from '@/views/common/network/components/CreateEdit.vue'

import {
  BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BRow,
    BButton,
    BCol,
    EditCreate,
  },
  directives: {
    Ripple,
  },
  props: {
    showBackButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myNetworks: [],
      isProcessing: false,
      self: getUserData(),
    }
  },
  mounted() {
    if (this.self) this.getMyNetworks()
  },
  methods: {
    onNetworkCreated(response) {
      this.getMyNetworks()
      this.$emit('onNetworkCreated', response.data.data)
    },
    navigateToNetworkShowPage(id) {
      this.$router.replace({ name: `${getUserData().usertype}-networks-show`, params: { id } })
    },
    getUserData() {
      return getUserData()
    },
    getMyNetworks() {
      useJwt.getMyNetworks().then(response => {
        this.myNetworks = response.data.data
      })
    },
  },
}
</script>

<style>

  .my-networks .dropdown-menu {
    transform: translate3d(-68px, 40px, 0px) !important;
  }
  .my-networks .dropdown-menu {
    transform: translate3d(-68px, 40px, 0px) !important;
  }
  .my-networks ul {
    max-height: 300px !important;
    overflow-y: auto !important;
  }

</style>
