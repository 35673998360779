<template>
  <div class="p-4">
    <h1>{{ $t('navigation-menu.privacy-policy') }}</h1>
    <p>
      Your privacy is important to us. It is Global English Campus's policy to respect your privacy regarding any information we may collect from you across our website,  https://globalenglishcampus.com/, and other sites we own and operate.
      We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.
      We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.
      We don’t share any personally identifying information publicly or with third-parties, except when required to by law.
      Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.
      You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.
      Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information as well as our Terms and Conditions. If you have any questions about how we handle user data and personal information, feel free to contact us.
      This policy is effective as of 05 July 2021.
    </p>

    <h1>Terms and Conditions</h1>
    <p>
      This Membership Agreement, when accepted by the applicant and accepted by Global English Campus constitutes a binding contract between Global English Campus and the individual. Membership rights, privileges and obligations commence when the Agreement is accepted by both parties.
      Membership
      Any and all membership privileges obtained through this membership agreement may be revoked or changed at any time with prior notice to member.
      Member agrees they meet all membership requirements and will notify company if any requirements are not met during the time of the agreement term. Client’s information will be documented as below but may be updated or changed at any point of agreement term.
    </p>
    <ul>
      <li>
        Member first name
      </li>
      <li>
        Member last name
      </li>
      <li>
        Member email
      </li>
      <li>Member mobile number</li>
      <li>Member city</li>
      <li>Member country/region</li>
      <li> Member D.O.B.</li>
      <li> Member photo</li>
    </ul>

    <h1>Term and Termination</h1>
    <p>
      This agreement shall remain in effect until one or more of the following occur:
    </p>
    <ul>
      <li>
        Member fails to meet any requirements of membership.
      </li>
      <li>
        Agreement term lapses without further terms or agreements added.
      </li>
      <li>
        Company files for bankruptcy or ceases to do business.

      </li>
      <li>

        Member violates Community Guidelines
      </li>

    </ul>
    <h1>
      Notices
    </h1>
    <p>Except under extenuating circumstances, any and all communications in regard to this contract shall be delivered by email to Global English Campus. All member notices will be delivered by forms deemed necessary by Global English Campus.
      Assignment</p>
    <p>Without prior written approval no portion of this contract may be transferred or sold to any party not participating in this membership agreement.
      No Warranties</p>
    <p>No warranties are given or implied by this membership agreement.</p>
    <p>Effectiveness and Interpretation of Agreement</p>
    <p>    Any and all legal proceedings sustained from this agreement shall be set in the jurisdiction of Global English Campus as agreed between parties. This membership agreement sets forth the entire understanding of above mentioned agreement and unless otherwise stated is not inclusive of any oral or prior written agreements. Any modifications or changes to this agreement must be in written form and signed by both parties.
    </p>
  </div>
</template>

<script>

export default ({
})
</script>
