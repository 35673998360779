<template>
  <b-modal :visible="true"
           centered
           size="lg"
           title="Mgp Creation"
           body-class="p-0"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <table class="table table-hover table-striped table-bordered mb-0">
      <thead>
        <tr>
          <th>
            Problem
          </th>
          <th>
            Type
          </th>
          <th>
            Decoration Type
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="problem,index of applicableProblems"
            :key="index"
        >
          <td>
            {{ problem.label }}
          </td>
          <td>
            <select v-model="problem.problem_type"
                    class="form-control"
            >
              <option v-for="sp of problemTypes"
                      :key="sp.value"
                      :value="sp.value"
              >
                {{ sp.text }}
              </option>
            </select>
          </td>
          <td>
            <select v-if="problem.problem_type === 'open-end-writing' "
                    v-model="problem.decoration_type"
                    class="form-control"
            >
              <option v-for="sp of decorationTypeList"
                      :key="sp.value"
                      :value="sp.value"
              >
                {{ sp.text }}
              </option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>
    <template #modal-footer>
      <div>
        <b-button variant="outline-primary"
                  class="mr-1"
                  @click="generateMgpProblem"
        >
          <b-spinner v-if="isProcessing"
                     small
          />  Save
        </b-button>
        <b-button variant="outline-secondary"
                  @click="$emit('close')"
        >
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>

</template>

<script setup>
import { BModal, BButton, BSpinner } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { getCurrentInstance, ref } from 'vue'

const props = defineProps({
  selectedProblems: {
    type: Array,
    required: true,
  },
  failedProblems: {
    type: Array,
    required: true,
  },
})

const emits = defineEmits(['close', 'onSuccess'])

const problemTypes = [
  {
    text: 'Matching',
    value: 'matching',
  },
  {
    text: 'Multiple Choice',
    value: 'multiple',
  },
  {
    text: 'Open Ended Question',
    value: 'open-end-writing',
  },

]
const root = getCurrentInstance().proxy.$root
const applicableProblems = ref([])
const isProcessing = ref(false)
const decorationTypeList = [
  {
    text: 'None',
    value: null,
  },
  {
    text: 'Image',
    value: 'image',
  },
  {
    text: 'Audio',
    value: 'audio',
  },
]

props.selectedProblems.forEach(item => {
  const testStat = props.failedProblems.find(i => i.problem_id === item.code)
  if (testStat) {
    applicableProblems.value.push({
      problem_id: item.code,
      label: item.label,
      decoration_type: null,
      problem_type: 'open-end-writing',
      test_stat: testStat.test_id,
    })
  }
})

const generateMgpProblem = () => {
  const payload = {
    problems: applicableProblems.value,
  }
  isProcessing.value = true
  useJwt.generateMachineGeneratedProblem(payload).then(response => {
    if (response.aiError) {
      root.showError(response.aiError)
    } else {
      emits('close')
      emits('onSuccess')
    }
    root.showSuccessMessage(response)
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    isProcessing.value = false
  })
}

</script>
