<template>
  <b-modal
    :id="modalId"
    :ref="modalId"
    size="lg"
    @show="modalId !== 'classroom-rewards' ? loadData() : ''"
  >
    <!-- Modal Title -->
    <template #modal-title>
      <!-- Tabs -->
      <b-tabs class="mx-auto award-stat-modal">
        <b-tab
          v-for="item, group, index in awardStatsGroup"
          :key="index"
          :active="group === currentItem"
          :title="$tc(`labels.${group}`)"
          @click="setCurrentItem(group)"
        >
          <b-overlay
            :show="isLoading"
            variant="light-secondary"
          >
            <!-- Rules -->
            <div
              v-if="currentItem === 'rules'"
              class="mb-1"
            >
              <b-list-group>
                <b-list-group-item
                  v-if="awardStatsGroup.rules"
                  id="award-statistics-rules"
                >
                  <div v-html="awardStatsGroup.rules" />
                </b-list-group-item>

                <p
                  v-else
                  class="text-center"
                >
                  {{ $tc('messages.no-reward-rules') }}
                </p>
              </b-list-group>
            </div>

            <!-- Coins -->
            <div v-else-if="currentItem === 'coins'">
              <coins
                v-if="(awardStatsGroup.coins && awardStatsGroup.coins.coins)
                  || ( pendingCoins && pendingCoins.coins)"
                :coins="awardStatsGroup.coins"
                :pending-coins="pendingCoins"
              />
              <div v-else>
                <reward-instructions
                  reward="coin"
                  :threshold="pendingCoins && pendingCoins.threshold ? pendingCoins.threshold : 1000"
                  :student-name="self.id !== studentId ? studentName : null"
                  :self="self"
                  :type="type"
                />
              </div>
            </div>

            <!-- No Rewards -->
            <div
              v-else-if="!Object.values(awardStatsGroup[group]).length"
              class="mx-auto"
            >
              <reward-instructions
                :reward="getCurrentItem()"
                :threshold="pendingCoins && pendingCoins.threshold ? pendingCoins.threshold : 1000"
                :student-name="self.id !== studentId ? studentName : null"
                :type="type"
              />
            </div>

            <!-- Points -->
            <points
              v-else-if="currentItem === 'points'"
              :points="awardStatsGroup.points"
              :pending-points="pendingPoints"
            />

            <!-- Badges and Vibes -->
            <b-row v-else>
              <b-col
                v-for="reward in awardStatsGroup[currentItem]"
                :key="reward.code"
                lg="6"
              >
                <b-card no-body>
                  <div v-if="reward.description"
                       v-b-tooltip.hover.bottom="reward.description"
                       class="info-icon"
                  >
                    <b-button variant="flat-success">
                      <feather-icon icon="AlertCircleIcon"
                                    class="text-primary"
                                    size="20"
                      />
                    </b-button>
                  </div>
                  <b-card-header class="reward-card">

                    <div class="d-flex justify-content-start align-items-start">
                      <!-- Vibe or Badge Icon -->
                      <div>
                        <!-- if there is an icon, show the icon  -->
                        <img
                          v-if="reward.icon"
                          :src="reward.icon"
                          :alt="reward.code"
                          width="70px"
                          height="70px"
                        >

                        <!-- show default icon, if no icon found -->
                        <img
                          v-else
                          :src="currentItem === 'badge' ?
                            require(`@/assets/images/${badges[reward.code]}`) :
                            getVibeIcon(reward.code)"
                          :alt="reward.code"
                          width="70px"
                          height="70px"
                        >
                      </div>

                      <!-- Reward Details -->
                      <div class="ml-1 reward-details">
                        <b-card-title class="reward-title">
                          <h3>x {{ reward.total }}</h3>
                          <strong>{{ formatText(reward.name) }} </strong>
                        </b-card-title>
                        <b-card-text>
                          <p class="mb-0">
                            <span class="font-weight-bold">{{ $tc('labels.value') }}:</span> {{ reward.value }}
                          </p>
                        </b-card-text>
                      </div>
                    </div>
                  </b-card-header>
                </b-card>
              </b-col>
            </b-row>
          </b-overlay>
        </b-tab>
      </b-tabs>
    </template>
    <!-- Modal Footer -->
    <template #modal-footer>
      <div
        class="w-100 d-flex align-items-center"
        :class="currentItem === 'rules' ? 'justify-content-end' : 'justify-content-between'"
      >
        <div v-if="currentItem !== 'rules'">
          <h5 class="float-left">
            <b-overlay :show="isNameLoading">
              <!-- {{ !studentId || studentId === self.id ? 'Your' : `${studentName}'s` }}
              {{ classId ? $t('labels.class-reward-stats') : $t('labels.reward-stats') }} -->

              <span v-if="!studentId || studentId === self.id">
                {{ classId ? $t('badge-reward.self-reward-classroom-stats') : $t('badge-reward.self-reward-stats') }}
              </span>

              <span v-else>
                {{ classId ? $t('badge-reward.student-reward-classroom-stats',{studentName}) : $t('badge-reward.student-reward-stats',{studentName}) }}
              </span>
            </b-overlay>
          </h5>
        </div>
        <b-button
          v-if="!shouldHideFooter()"
          variant="primary"
          class="float-right"
          :disabled="isLoading"
          @click="goToDetails()"
        >
          {{ $t('labels.more-details') }}
        </b-button>
        <b-button
          v-else
          variant="primary"
          class="float-right"
          :disabled="isLoading"
          @click="$bvModal.hide(modalId)"
        >
          {{ $t('actions.close') }}
        </b-button>
      </div>
    </template>

    <!-- Badges Info Modal  -->
    <badges-info-modal
      modal-id="badges-info-1"
      :badges-icons="badgesInfo"
    />
  </b-modal>
</template>

<script>
import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import { mapGetters } from 'vuex'
import {
  BCard, BCardHeader, BCardTitle, BCardText, BRow, BCol, BListGroup, BListGroupItem, BModal, BTabs, BTab, BOverlay, BButton, VBTooltip,
} from 'bootstrap-vue'
import {
  HIGHEST_POINTS_GAINER_BADGE, FIRST_LESSON_ACHEIVER_BADGE, EXTRAORDINARY_BADGE,
} from '@/const/badges'
import BadgesInfoModal from '@/views/v2/common/class/components/BadgesInfoModal.vue'
// import { checkSettings } from '@/utils/visibilitySettings'
import RewardInstructions from './RewardInstructions.vue'
import Coins from './Coins.vue'
import Points from './Points.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard, BCardHeader, BCardTitle, BCardText, BRow, BCol, BListGroup, BListGroupItem, BModal, BTabs, BTab, BOverlay, Coins, Points, BButton, RewardInstructions, BadgesInfoModal,
  },
  props: {
    studentId: {
      type: (Object, Number),
      default: () => null,
    },
    item: {
      type: String,
      default: () => '',
    },
    totalPoints: {
      type: Number,
      default: () => null,
    },
    modalId: {
      type: String,
      default: 'award-statistics-modal',
    },
    classId: {
      type: Number,
      default: () => null,
    },
    type: {
      type: String,
      default: 'global',
    },
  },
  data() {
    return {
      self: getUserData(),
      currentItem: '',
      awardStatsGroup: {
        badge: [],
        vibe: [],
        points: [],
        coins: [],
        rules: '',
      },
      badges: {
        'BADGE-HPG-L': HIGHEST_POINTS_GAINER_BADGE,
        'BADGE-HPG-C': HIGHEST_POINTS_GAINER_BADGE,
        'BADGE-FCD': FIRST_LESSON_ACHEIVER_BADGE,
        'BADGE-FLD': FIRST_LESSON_ACHEIVER_BADGE,
        'BADGE-MLD': EXTRAORDINARY_BADGE,
      },
      badgesInfo: {
        highestPointGainerBadge: HIGHEST_POINTS_GAINER_BADGE,
        firstLessonAchieverBadge: FIRST_LESSON_ACHEIVER_BADGE,
        extraOrdinaryBadge: EXTRAORDINARY_BADGE,
      },
      vibes: [],
      isLoading: false,
      isNameLoading: false,
      pendingPoints: null,
      pendingCoins: null,
      studentName: '',
      // hideBadgesInfo: '0',
    }
  },
  computed: {
    ...mapGetters('app-chat', {
      defaultVibes: 'get_default_vibes',
    }),
    student_id() {
      return this.studentId
    },
  },
  watch: {
    student_id() {
      this.isNameLoading = true
      this.currentItem = this.item
      this.getAwardStatsGroup()
      this.getStudentName()
    },
  },

  mounted() {
    // Don't show vibes in the classroom rewards stats modal
    if (this.modalId === 'classroom-rewards') delete this.awardStatsGroup.vibe
    // checkSettings('streak_persistence', {}).then(response => {
    //   this.hideBadgesInfo = response?.value || 0
    // })
  },
  methods: {
    loadData() {
      this.setCurrentItem(this.item)
      if (this.studentId) {
        this.isNameLoading = true
        this.getStudentName()
      }
    },
    setCurrentItem(group) {
      this.currentItem = group

      this.$nextTick(() => {
        if (!Object.values(this.awardStatsGroup[group]).length) {
          switch (group) {
            case 'badge':
            case 'points':
            case 'coins':
              if (!this.pendingCoins || !Object.values(this.pendingCoins).length) this.getAwardStatsGroup()
              break

            case 'vibe':
              if (!this.vibes.length) this.getVibes()
              if (!this.pendingCoins) this.getAwardStatsGroup()
              break

            case 'rules':
              this.getAwardRules()
              break

            default:
              break
          }
        }
      })
    },
    getAwardStatsGroup() {
      this.isLoading = true
      const uid = this.studentId ? this.studentId : this.self.id

      const params = {
        uid,
        group: 1,
        classroomId: this.classId,
      }
      useJwt.getStudentRewardCount({
        params,
      }).then(response => {
        const res = response.data?.data?.award_stats

        Object.keys(this.awardStatsGroup).forEach(key => {
          if (key !== 'rules') this.awardStatsGroup[key] = res[key]
        })
        this.pendingPoints = res.pendingPoints
        this.pendingCoins = res.pendingCoins
      }).finally(() => { this.isLoading = false })
    },
    getVibeIcon(code) {
      const vibe = this.vibes?.filter(v => v.code === code)
      return vibe.icon ? vibe.icon : this.defaultVibes[code]
    },
    getVibes() {
      useJwt.getVibes().then(res => {
        this.vibes = res.data?.data
      })
    },
    shouldHideFooter() {
      if (this.$route.params.reward || this.currentItem === 'rules') return true

      /* eslint-disable no-unneeded-ternary */
      return this.awardStatsGroup[this.currentItem]
        && Object.values(this.awardStatsGroup[this.currentItem]).length ? false : true
    },
    formatText(text) {
      const textSplit = text.split(' ')
      for (let i = 0; i < textSplit.length; i += 1) {
        textSplit[i] = textSplit[i][0].toUpperCase() + textSplit[i].substr(1)
      }
      return textSplit.join(' ')
    },
    getCurrentItem() {
      if (this.currentItem === 'points') return 'point'
      if (this.currentItem === 'coins') return 'coin'
      return this.currentItem
    },
    getAwardRules() {
      this.isLoading = true
      useJwt.getRulesSetting().then(response => {
        const res = response.data?.data
        if (res) {
          this.awardStatsGroup.rules = res.value
        }
      }).finally(() => { this.isLoading = false })
    },
    goToDetails() {
      this.$bvModal.hide(this.modalId)
      this.$router.push({ name: 'rewards-detail', params: { reward: this.getCurrentItem(), uid: this.studentId } })
    },
    getStudentName() {
      if (this.classId || !this.studentName) {
        useJwt.getStudentById(this.studentId).then(response => {
          this.studentName = response.data?.data?.firstname
        }).finally(() => { this.isNameLoading = false })
      } else this.isNameLoading = false
    },
  },
}
</script>

<style scoped>
.list-group .list-group-item:hover {
  background-color: #fff;
}
::v-deep .modal-header {
  align-items: start;
}
::v-deep .modal-title {
  width: 100%;
}
.reward-title {
  font-size: 16px;
}
.reward-card {
  min-height: 135px;
}
.dark-layout .list-group .list-group-item:hover,
.dark-layout .list-group .list-group-item:active {
  background-color: #283046;
  color: #b4b7bd;
}
::v-deep #award-statistics-modal .modal-content {
  min-height: 310px;
}
::v-deep  #award-statistics-rules li {
  /* override default */
  list-style: auto !important;
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  ::v-deep .modal-lg {
    width: 620px !important;
    max-width: 620px !important;
  }
}

@media screen and (max-width: 768px) and (min-width: 576px) {
  ::v-deep .modal-lg {
    width: 480px !important;
    max-width: 480px !important;
  }
}

@media screen and (max-width: 500px) {
  ::v-deep .modal-lg {
    width: 340px !important;
    max-width: 340px !important;
  }
}
</style>

<style>
.award-stat-modal .info-icon {
  position: absolute;
  right: 0;
  z-index: 1000;
  margin-right: 4px;
  margin-top: 4px;
}
</style>
