<template>
  <div>
    <b-modal
      ok-only
      ok-variant="secondary"
      ok-title="Accept"
      modal-class="modal-secondary"
      centered
      :visible="visible"
      title="Game Board"
      size="lg"
      hide-footer
      @close="$emit('close')"
      @hide="$emit('close')"
    >
      <b-overlay :show="isProcessing">
        <b-row class="ecommerce-application match-height pt-2">
          <b-col
            v-for="game of games"
            :key="game.code"
            md="4"
            role="button"
            @click="SELECT_GAME(game.code)"
          >
            <b-card
              class="ecommerce-card"
              no-body
            >
              <img
                class="card-img-top h-100"
                :src="game.image"
                style="max-height: 200px"
              >
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
    <game
      v-if="selectedGame"
      :code="selectedGame"
      :loader-id="loaderId"
      @gameOver="gameOver"
    />
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BModal, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import Game from './Game.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    // BCardBody,
    // BButton,
    BModal,
    Game,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    loaderId: {
      type: [Object, Number, String],
      default: () => null,
    },
  },
  data() {
    return {
      games: [],
      selectedGame: null,
      visible: this.show,
      isProcessing: false,
    }
  },
  watch: {
    show(value) {
      this.visible = value
    },
  },
  created() {
    this.isProcessing = true
    useJwt.getGameLists({
      params: {
        all: null,
      },
    }).then(response => {
      this.games = response.data.data?.game_lists
    }).catch(error => {
      this.showErrorMessage(error)
    }).finally(() => { this.isProcessing = false })
  },
  methods: {
    SELECT_GAME(game) {
      this.selectedGame = game
      if (this.getStoreKeyForPlayedGame()) {
        localStorage.setItem(this.getStoreKeyForPlayedGame(), game)
      }
      this.$emit('close')
    },
    gameOver() {
      this.visible = false
      this.selectedGame = null
    },
  },
}
</script>
<style>
     .ecommerce-application .ecommerce-card:hover {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
      -webkit-box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
      box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
  }
</style>
