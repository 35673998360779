<template>
  <div class="wrapper">
    <!-- Sticker Categories  -->
    <div
      v-if="showTopCategories || currentStickerCategory || searchable"
      class="topbar"
    >
      <div
        v-if="showTopCategories"
        class="categories border-bottom"
      >
        <b-row class="justify-content-start px-1">
          <div
            v-for="stickers, category, index in vibes[0]"
            :id="`category-${index}`"
            :key="category"
            class="category-icon-wrapper"
            :class="{active: (currentStickerCategory == index)}"
          >
            <a
              href="#"
              class="category-icon"
              @click="goto(category)"
            >

              <img
                :src="stickers[0].icon ? stickers[0].icon : defaultVibes[stickers[0].code]"
                height="32px"
                width="32px"
                alt=""
              >
              <b-tooltip
                :target="'category-' + index"
                triggers="hover"
                :placement="categoryTooltipPlacement"
              >
                {{ category }}
              </b-tooltip>
            </a>
          </div>
        </b-row>
      </div>

      <!-- Search Bar  -->
      <div
        v-if="searchable"
        class="search-wrapper"
      >
        <input
          v-model="searchQuery"
          type="text"
          placeholder="Search"
          class="search-box"
        >
        <span
          v-if="searchQuery"
          class="clear"
          @click="resetQuery"
        />
      </div>

      <p
        v-if="showCurrentCategory"
        class="current-category font-weight-bold"
      >
        {{ searchQuery ? 'Search Results' : getCurrentCategory(currentStickerCategory) }}
      </p>
    </div>

    <!-- Stickers from Search Result -->
    <div
      v-if="searchResult.length"
      class="sticker-container-wrapper"
      :style="`height: ${Height}; width: ${Width};`"
    >
      <div class="sticker-container mt-2">
        <b-row class="stickers-row">
          <b-col
            v-for="sticker, index in searchResult"
            :key="sticker.id"
            :md="md"
            class="sticker-wrapper"
            @click="selectSticker(index, sticker.id, $event)"
          >
            <img
              :src="sticker.icon ? sticker.icon : defaultVibes[stickers[0].code]"
              class="mb-1 sticker"
              alt=""
            >
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- Stickers  -->
    <div
      v-else
      class="sticker-container-wrapper"
      :style="`height: ${height}; width: ${width};`"
    >
      <div
        v-for="stickers, category, index in vibes[0]"
        :key="index"
        class="sticker-container"
      >
        <p
          v-if="showCategories"
          :id="category"
          class="mb-0 pl-1 font-weight-bold"
        >
          {{ category }}
        </p>
        <b-row class="stickers-row">
          <b-col
            v-for="sticker in stickers"
            :key="sticker.id"
            :md="md"
            cols="6"
            sm="6"
            class="sticker-wrapper"
            @click="selectSticker(index, sticker.id, $event)"
          >
            <img
              v-b-tooltip.hover="sticker.description || ''"
              :src="sticker.icon ? sticker.icon : defaultVibes[sticker.code]"
              class="mb-1 sticker"
              alt=""
            >
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BTooltip,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BTooltip,
  },
  props: {
    height: {
      type: String,
      default: '322px',
    },
    width: {
      type: String,
      default: '100%',
    },
    md: {
      type: Number,
      default: 3,
    },
    categoryTooltipPlacement: {
      type: String,
      default: 'top',
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    showTopCategories: {
      type: Boolean,
      default: true,
    },
    showCategories: {
      type: Boolean,
      default: true,
    },
    showCurrentCategory: {
      type: Boolean,
      default: true,
    },
    vibes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: '',
      searchResult: [],
      currentStickerCategory: 0,
    }
  },
  computed: {
    ...mapGetters('app-chat', {
      defaultVibes: 'get_default_vibes',
    }),
    query() {
      return this.searchQuery
    },
  },
  watch: {
    query(val) {
      if (val === '') this.searchResult = []
      else this.filterSticker()
    },
  },
  mounted() {
    if (this.showCurrentCategory) {
      const container = document.querySelector('.sticker-container-wrapper')
      const sContainers = document.querySelectorAll('.sticker-container')

      if (sContainers) {
        container.addEventListener('scroll', () => {
          for (let i = 0; i < sContainers.length; i += 1) {
            if (container.scrollTop > sContainers[i].offsetTop - 50 && container.scrollTop < sContainers[i].offsetTop + sContainers[i].clientHeight - 50) {
              this.currentStickerCategory = i
            }
          }
        })
      }
    }
  },
  methods: {
    selectSticker(index, id, event) {
      const data = []
      Object.values(this.vibes[0])[index].forEach(sticker => {
        if (id === sticker.id) {
          data.code = sticker.code
          data.icon = sticker.icon ? sticker.icon : this.defaultVibes[sticker.code]
          this.$emit('select', data, event.target)
        }
      })
    },
    filterSticker() {
      this.searchResult = []
      const len = Object.keys(this.stickers[0]).length
      for (let i = 0; i < len; i += 1) {
        Object.values(this.vibes[0])[i].forEach(sticker => {
          if (sticker.name.toLowerCase().indexOf(this.searchQuery) !== -1) {
            this.searchResult.push({
              name: sticker.name,
              id: sticker.id,
              icon: sticker.icon,
            })
          }
        })
      }
    },
    async goto(id) {
      if (this.searchResult.length || this.searchQuery) {
        await this.resetQuery()
      }
      const element = document.getElementById(id)
      element.scrollIntoView()
    },
    resetQuery() {
      this.searchResult = []
      this.searchQuery = ''
    },
    getCurrentCategory(index) {
      return Object.keys(this.vibes[0])[index]
    },
  },
}
</script>

<style scoped>
  .wrapper {
    position: relative;
  }
  .topbar{
    background-color: #fff;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 4px 8px;
    z-index: 1;
  }
  .categories {
    border-color: #e6e6e6;
    border-radius: 5px;
  }
  .category-icon-wrapper {
    padding: 3px 6px;
  }
  .category-icon {
    cursor: pointer;
  }
  .search-wrapper {
    position: relative;
  }
  .search-box {
    width: 100%;
    margin: 8px 0;
    padding: 4px 12px;
    border: 1px solid #c6c6c6;
    border-radius: 18px;
    font-size: 14px;
  }
  .search-box:focus{
    outline: none;
  }
  .clear::after{
    position: absolute;
    top: 5px;
    right: 5px;
    content: 'x';
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    padding: 6px;
    transition: color 0.3s ease-in;
  }
  .clear:hover::after {
    color: #aaa;
  }
  .sticker-container-wrapper {
    position: relative;
    top: 96px;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
  .current-category {
    margin: 0;
    padding-left: 6px;
  }
  .stickers-row {
    padding: 3px 6px;
  }
  .sticker-wrapper {
    cursor: pointer;
    text-align: center;
  }
  .sticker {
    width: 65px;
    transition: all 0.3s ease-out;
  }
  .sticker:hover {
    transform: scale(1.1);
  }
  .sticker-wrapper:hover .sticker-wrapper .sticker {
    background-color: rgb(230, 230, 230, 0.3);
  }
  .active {
    border-bottom: 3px solid rgb(174, 101, 197);
  }
</style>
