<template>
  <div class="row">
    <div v-for="item,index of items"
         :key="index"
         class="col-md-3"
    >

      <div class="card w-100">
        <div class="card-body">
          <h5 class="card-title">
            {{ item.name }}
          </h5>
          <ReadMore :text="item.description"
                    :max-lines="3"
                    class="card-text"
          />

          <a :href="item.link"
             class="btn btn-primary"
             target="blank"
          >  <feather-icon icon="ExternalLinkIcon" />
            {{ $t('page-link-module.visit-link') }}
          </a>
          <div class="lesson-badges">
            <span v-for="lesson in sliceArray(item.lessons_info)"
                  :key="lesson.id"
                  class="badge badge-primary  mb-1"
            >{{ lesson.name }}</span>
            <span v-if="item.lessons_info.length > 3"
                  class="badge badge-secondary  mb-1"
                  role="button"
                  @click="allLessons = item.lessons_info"
            >
              {{ $t('page-link-module.view-all') }}
            </span>
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-sm btn-outline-primary"
                  @click="$emit('onEdit', item)"
          >
            <feather-icon icon="EditIcon" /> {{ $t('actions.edit') }}
          </button>
          <button class="btn btn-sm btn-outline-danger"
                  @click="$emit('onDelete', item)"
          >
            <feather-icon icon="TrashIcon" /> {{ $t('delete') }}
          </button>
        </div>
      </div>
    </div>
    <b-modal v-if="allLessons.length"
             :visible="true"
             title="Lessons"
             @close="allLessons = []"
             @hide="allLessons = []"
    >
      <div class="row">
        <div v-for="lesson of allLessons"
             :key="lesson.id"
             class="col-sm-6"
        >
          <button
            rippleEffect
            type="button"
            class="btn btn-primary  mb-1 ml-1"
          >
            {{ lesson.name }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import ReadMore from '@/views/common/components/ReadMore.vue'

export default {
  components: {
    BModal,
    ReadMore,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      allLessons: [],
    }
  },
  methods: {
    sliceArray(array) {
      return array.slice(0, 3)
    },
  },
}
</script>

<style>
.card {
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.card-text {
  color: #555;
  margin-bottom: 1rem;
}

.lesson-badges {
  margin-top: 1rem;
}

.badge {
  margin-right: 0.5rem;
  font-size: 0.8rem;
  padding: 0.4rem 0.6rem;
}

.card-footer {
  background-color: #f8f9fa;
  border-top: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
}

.card-footer button {
  font-size: 0.9rem;
}

.btn-outline-primary:hover,
.btn-outline-danger:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
