var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.templateOptions.shape)?_c('div',[_c('div',{class:`
      ${ _vm.templateOptions.shape.size === 'lg-t-20' ? 'text-center' : `text-${_vm.templateOptions.textAlign}` }
      shape-container mx-auto
    `,style:(_vm.templateOptions.shape.size === 'xl' ? `background-color: ${_vm.templateOptions.bgColor}` : '')},[_c('div',{class:`container container-${_vm.templateOptions.shape.size}`},[_c('div',{staticClass:"mx-auto pl-1 py-1",class:`template-${_vm.templateOptions.shape.size}`,style:(_vm.templateOptions.shape.size === 'md' ? `background-color: ${_vm.templateOptions.bgColor}` : '')},[_vm._m(0)],1)]),_c('img',{attrs:{"src":require(`@/assets/images/shapes/${_vm.templateOptions.shape.value}`),"width":"100%","height":"auto","alt":""}})])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('vue-perfect-scrollbar',{staticClass:"scrollbar",attrs:{"settings":_vm.scrollbarSettings}},[_c('div',[_c('div',{staticClass:"before-template w-100"},[_vm._t("default")],2),(_vm.templateOptions.type !== 'fib')?_c('div',_vm._l((_vm.data),function(section,index){return _c('div',{key:index,class:`
                  question-card
                  question-card-${_vm.templateOptions.shape.size}
                  ${_vm.templateOptions.shape.size === 'lg-t-20' ? 'mx-auto' : `float-${_vm.templateOptions.textAlign}`}
                  ${_vm.templateOptions.textAlign === 'center' ? 'mx-auto' : 'mx-2'}`},[_c('h2',{directives:[{name:"text-to-speech",rawName:"v-text-to-speech"}],staticClass:"question text-warning",domProps:{"innerHTML":_vm._s(_vm.isAProblem ? _vm.formatQuestion(section.title,'title',index):section.title)},on:{"click":function($event){return _vm.$emit('onClick', $event)}}}),_c('p',{directives:[{name:"text-to-speech",rawName:"v-text-to-speech"}],domProps:{"innerHTML":_vm._s(_vm.isAProblem ? _vm.formatQuestion(section.subtitle,'subtitle',index):section.subtitle)},on:{"click":function($event){return _vm.$emit('onClick', $event)}}})])}),0):_vm._e()])])
}]

export { render, staticRenderFns }