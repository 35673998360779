<template>
  <section>
    <h4>
      {{ $t('engagement-setting-module.animated-marketplace') }}

    </h4>
    <hr>
    <template v-if="products.length">
      <b-row v-for="product in products"
             :key="product.id"
             class="d-flex align-items-center product-row bg-white p-1 rounded"
      >

        <img
          height="70"
          width="70"
          :src="product.image"
        >

        <b-col cols="8">
          <h4>
            {{ product.name }}
          </h4>
          <a href="#!"
             class="text-muted"
          >{{ getWearType(product.wear_type) }}</a>
          <p>
            {{ $t('engagement-setting-module.fits-for') }} :  <b-badge v-for="animal,i in product.animal_type"
                                                                       :key="i"
                                                                       variant="primary"
                                                                       :class="i>0 && 'ml-1'"
                                                                       style="text-transform: capitalize;"
            >
              {{ animal }}
            </b-badge>

          </p>
          <h5 class="text-dark price-info">
            <strong>{{ product.access_type=='free' ? $t('engagement-setting-module.free'): `$ ${product.price}` }}</strong>
          </h5>
        </b-col>
        <b-col cols="2">
          <b-badge :variant="product.access_type=='free' ? 'warning' : 'success'"
                   class="cursor-pointer"
                   @click="buyItem(product)"
          >
            <feather-icon icon="ShoppingBagIcon" />
            {{ product.access_type=='free' ? $t('engagement-setting-module.get') : $t('engagement-setting-module.buy') }}
          </b-badge>
        </b-col>
      </b-row>
    </template>
    <b-row v-else
           class="bg-white p-1 rounded"
    >
      <b-col cols="12">
        <h5 class="text-muted">
          {{ $t('engagement-setting-module.item-not-found-on-animation-marketplace') }}

        </h5>
      </b-col>
    </b-row>

    <buy-modal :product="buyingProduct"
               @onBuy="$emit('onBuy')"
    />
  </section>
</template>

<script>

import { BBadge, BRow, BCol } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { wearableTypes } from '@/const/theme'
import BuyModal from './components/BuyModal.vue'

export default {
  components: {
    // BButton,
    BBadge,
    BRow,
    BCol,
    BuyModal,
  },
  data() {
    return {
      products: [],
      isProcessing: false,
      buyingProduct: {},
      wearableTypes,
    }
  },

  mounted() {
    this.getAnimatedItems()
  },

  methods: {
    getAnimatedItems() {
      this.isProcessing = true
      useJwt.getAnimatedMarketplace().then(response => {
        this.products = response.data.data
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },

    buyItem(product) {
      this.$bvModal.show('buy-animation-modal')
      this.buyingProduct = product
    },

    getWearType(type) {
      return this.wearableTypes.find(wearableType => wearableType.value === type).text
    },
  },
}

</script>

<style lang="scss">
.product-row{
  border-bottom: 1px solid rgb(204, 201, 201);
  margin-bottom:10px;
  padding-bottom:10px;
}

.trade-button{
  border-radius:50px;
}

.price-info{
  padding-top:2px;
}
</style>
