<template>
  <div>
    <div class="row mb-2">
      <div class="col-4 text-left">
        <v-select
          v-model="selectedSchool"
          :placeholder="$t('marketplace-module.all-schools')"
          :options="schoolLists"
          :clearable="true"
          :label="$t('labels.fullname')"
          :disabled="isProcessing"
          :reduce="item => item.id"
          @input="getReservations()"
        />
      </div>
      <div class="col-8 text-right">
        <b-button
          variant="outline-primary"
          @click="showReservationCreate = true"
        >
          <feather-icon icon="PlusIcon" /> {{ $t('reservation-moduel.add-program') }}
        </b-button>
      </div>
    </div>
    <reservation-update
      :show="showReservationCreate || !!reservationToEdit"
      :reservation="reservationToEdit"
      :age-groups="ageGroups"
      :school-lists="schoolLists"
      @onUpdate="getReservations"
      @close="showReservationCreate = false;reservationToEdit = null"
    />
    <b-overlay
      :show="isProcessing"
    >
      <b-tabs pills>
        <b-tab
          v-for="tab of tabs"
          :key="tab.value"
          :title="tab.name"
          @click="getReservations(tab.value)"
        >
          <b-card no-body>
            <b-table
              :fields="columns"
              :items="rows"
              striped
              show-empty
              :empty-text="$t('messages.no-data-available')"
            >
              <template #cell(name)="data">
                {{ data.item.name }} <small>{{ capitalizeFirstLetter(data.item.type) }}</small>
              </template>
              <template #cell(age_group)="data">
                {{ getAgeGroup(data.item.age_group) }}
              </template>
              <template #cell(days)="data">
                {{ data.item.days }} days
              </template>
              <template #cell(school)="data">
                {{ data.item.school.firstname }} {{ data.item.school.lastname }}
              </template>
              <template #cell(action)="data">
                <b-button
                  variant="outline-primary"
                  class="btn-icon"
                  @click="$router.push({ name: `${AUTH_USER().usertype}-public-reservations`, params: { id: data.item.id } })"
                >
                  <b-badge
                    v-if="data.item.reservation_classes_count"
                    variant="light-danger"
                    class="badge-pill"
                  >
                    {{ data.item.reservation_classes_count }}
                  </b-badge>{{ $t('reservation-moduel.manage-classroom') }}
                </b-button>
                <b-button
                  variant="flat-primary"
                  class="btn-icon"
                  @click="handleEdit(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  variant="flat-danger"
                  class="btn-icon"
                  @click="reservationToDelete = data.item.id"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-overlay>
    <ConfirmDelete
      :show="!!reservationToDelete"
      :is-processing-delete="isDeleting"
      :on-delete="handleDelete"
      @close="reservationToDelete = null"
    />
  </div>
</template>
<script>
import {
  BButton, BTab, BTabs, BTable, BCard, BOverlay, BBadge,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ConfirmDelete from '@/views/common/components/DeleteModal.vue'
import ReservationUpdate from '@/views/reservations/internal/components/add-update.vue'
import useApollo from '@/@core/graphql/useApollo'
import vSelect from 'vue-select'
import i18n from '@/libs/i18n'

export default {
  components: {
    BButton,
    ReservationUpdate,
    BTab,
    BTabs,
    BTable,
    BCard,
    BOverlay,
    BBadge,
    ConfirmDelete,
    vSelect,
  },
  data() {
    return {
      schoolLists: [],
      selectedSchool: null,
      showReservationCreate: false,
      columns: [
        {
          key: 'name',
          label: i18n.tc('reservation-moduel.name'),
        },
        {
          key: 'age_group',
          label: i18n.tc('reservation-moduel.age_group'),
        },
        {
          key: 'student_limit',
          label: i18n.tc('reservation-moduel.student_limit'),
        },
        {
          key: 'time_interval',
          label: i18n.tc('reservation-moduel.time_interval'),
        },
        {
          key: 'school',
          label: i18n.tc('reservation-moduel.school'),
        },
        {
          key: 'action',
          label: i18n.tc('reservation-moduel.action'),
        },
      ],
      tabs: [
        {
          name: i18n.tc('reservation-moduel.open'),
          value: 'open',
        },
        {
          name: i18n.tc('reservation-moduel.closed'),
          value: 'closed',
        },
        {
          name: i18n.tc('reservation-moduel.all'),
          value: 'all',
        },
      ],
      rows: [],
      isProcessing: false,
      reservationToEdit: null,
      status: null,
      isDeleting: false,
      reservationToDelete: null,
      ageGroups: [
        {
          text: 'Grade 2',
          value: 'g-k-2',
        },
        {
          text: 'Grade 3 -5',
          value: 'g-3-5',
        },
        {
          text: 'Grade 6-8',
          value: 'g-6-8',
        },
        {
          text: 'Grade 9-12',
          value: 'g-9-12',
        },
        {
          text: 'University',
          value: 'university',
        },
      ],
    }
  },
  created() {
    this.getSchoolUsers()
    this.getReservations()
  },
  methods: {
    getAgeGroup(group) {
      return this.ageGroups.find(item => item.value === group)?.text || group
    },
    getReservations(status = this.status) {
      this.isProcessing = true
      this.status = status
      useJwt.getReservations({
        params: {
          status,
          school: this.selectedSchool ? this.selectedSchool : null,
        },
      }).then(response => {
        this.rows = response.data.data
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    handleEdit(reservation) {
      this.reservationToEdit = { ...reservation }
    },
    handleDelete() {
      this.isDeleting = true
      useJwt.deleteReservations(this.reservationToDelete).then(response => {
        this.getReservations()
        this.reservationToDelete = null
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isDeleting = false
      })
    },
    getSchoolUsers() {
      useApollo
        .getSchoolUsers()
        .then(response => {
          const res = response.data?.schools?.data
          this.schoolLists = res.map(item => ({ ...item, id: Number(item.id) }))
          // if (!this.form.school_id) {
          //   this.form.school_id = this.schoolLists[0]?.id
          // }
        })
        .finally(() => {
          this.schoolProcessing = false
        })
    },
  },
}
</script>
