<template>
  <b-card
    class="profile-header mb-2"
    :img-src="banner"
    img-top
    alt="cover photo"
    body-class="p-0"
  >

    <!-- profile picture -->
    <div class="position-relative">
      <div class="profile-img-container d-flex align-items-center">
        <div
          class="profile-img d-flex align-items-center justify-content-center position-relative"
        >
          <avatar
            :image="avatar"
            size="60"
            variant="light-primary"
            class="badge-minimal"
          />

          <template v-if="isViewingOwnProfile">
            <feather-icon
              icon="CameraIcon"
              size="25"
              class="profile-edit-icon d-none text-primary"
              style="position:absolute; bottom:0; right:0;"
              @click="$refs.profilePicUpload.click()"
            />

            <input
              ref="profilePicUpload"
              type="file"
              hidden
              accept="image/jpeg, image/png, image/gif"
              @input="profileImageChanged"
            >
          </template>

        </div>
        <!-- profile title -->
        <div class="profile-title ml-3">
          <h2 class="text-white">
            {{ headerData.username }}
          </h2>
          <p class="text-white">
            {{ $t(headerData.designation) }}
          </p>
        </div>
        <!--/ profile title -->
      </div>
    </div>
    <!--/ profile picture -->
    <!-- banner upload -->
    <template v-if="isViewingOwnProfile">
      <span class="banner-edit-button">
        <feather-icon
          v-b-tooltip.hover="$t('upload-banner')"
          icon="CameraIcon"
          size="25"
          class="banner-edit-icon text-primary"
          @click="$refs.coverPicUpload.click()"
        />
        <input
          ref="coverPicUpload"
          type="file"
          hidden
          accept="image/jpeg, image/png, image/gif"
          @input="coverImageChanged"
        >
      </span>
    </template>
    <!-- banner upload -->
    <!-- profile navbar -->
    <div class="profile-header-nav">
      <b-navbar
        toggleable="lg"
        type="light"
      >
        <!-- toggle button -->
        <b-navbar-toggle
          class="ml-auto"
          target="nav-text-collapse"
        >
          <feather-icon
            icon="AlignJustifyIcon"
            size="21"
          />
        </b-navbar-toggle>
        <!--/ toggle button -->

        <!-- collapse -->
        <b-collapse
          id="nav-text-collapse"
          is-nav
        >
          <b-tabs
            pills
            class="profile-tabs mt-1 mt-md-0"
            nav-class="mb-0"
          >
            <template #tabs-start>
              <b-nav-item
                role="presentation"
                :active="headerData.active == 'academic'"
                class="font-weight-bold"
                @click="$emit('changeActive', 'academic')"
              >
                <span class="d-md-block">{{ $t('student-profile.academic') }}</span>
                <!-- <feather-icon
                  icon="RssIcon"
                  class="d-block d-md-none"
                /> -->
              </b-nav-item>
              <b-nav-item
                role="presentation"
                :active="headerData.active == 'philanthropic'"
                class="font-weight-bold"
                @click="$emit('changeActive', 'philanthropic')"
              >
                <span class="d-md-block">{{ $t('student-profile.philanthropic') }}</span>
                <!-- <feather-icon
                  icon="InfoIcon"
                  class="d-none d-md-none"
                /> -->
              </b-nav-item>
              <b-nav-item
                role="presentation"
                :active="headerData.active == 'project'"
                class="font-weight-bold"
                @click="$emit('changeActive', 'project')"
              >
                <span class="d-md-block">{{ $t('student-profile.project') }}</span>
                <!-- <feather-icon
                  icon="ImageIcon"
                  class="d-block d-md-none"
                /> -->
              </b-nav-item>
            </template>

            <!-- edit buttons -->
            <template
              v-if="isViewingOwnProfile"
              #tabs-end
            >
              <b-button
                v-if="headerData.active == 'academic'"
                variant="primary"
                class="ml-auto"
                @click="$emit('grade')"
              >
                <feather-icon
                  icon="EditIcon"
                  class="d-block d-md-none"
                />
                <span class="font-weight-bold d-none d-md-block">{{ $t('student-profile.add-grade') }}</span>
              </b-button>
              <b-button
                variant="primary"
                :class="`${headerData.active == 'academic' ? 'ml-1':'ml-auto'}`"
                @click="$emit('addArticle')"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="d-block d-md-none"
                />
                <span class="font-weight-bold d-none d-md-block">{{ $t('student-profile.add-article') }}</span>
              </b-button>
            </template>
            <!-- edit buttons -->
          </b-tabs>

        </b-collapse>
        <!--/ collapse -->
      </b-navbar>
    </div>
    <!--/ profile navbar -->
  </b-card>
</template>

<script>
import {
  BCard, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Avatar from '@/views/common/components/Avatar.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    Avatar,
  },
  directives: {
    Ripple,
  },
  props: {
    isViewingOwnProfile: {
      type: Boolean,
      default: false,
    },
    headerData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      avatar: '',
      banner: '',
    }
  },
  mounted() {
    this.avatar = this.headerData.avatar
    this.banner = this.headerData.coverImg
  },
  created() {
    this.getBannerImage()
  },
  methods: {
    profileImageChanged(e) {
      const file = e.target.files[0]
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
      const data = new FormData()
      data.append('avatar', file)
      useJwt.axiosIns.post('/api/v1/user/avatar/update', data, config)
        .then(response => {
          this.$root.$emit('userUpdate', response.data.data)
          localStorage.setItem('userData', JSON.stringify(response.data.data))
          this.avatar = response.data.data.avatar
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {

        })
    },
    coverImageChanged(e) {
      const file = e.target.files[0]
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
      const data = new FormData()
      data.append('banner', file)
      useJwt.updateUserBannerImage(data, config)
        .then(response => {
          this.banner = response.data.data.value
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {

        })
    },
    getBannerImage() {
      useJwt.getUserBannerImage(this.headerData.id)
        .then(response => {
          if (response.data) {
            this.banner = response.data
          }
        }).catch(error => {
          this.showErrorMessage(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-profile.scss';
.nav-pills .nav-link {
  padding: 0.786rem 0.5rem !important;
}
.profile-img:hover{
  .profile-edit-icon{
    display: block !important;
    cursor: pointer;
  }
}
.banner-edit-icon {
  cursor: pointer;
}
.banner-edit-button{
  position: absolute;
  bottom: 68px;
  right: 6px;
  border: 1px solid #fff;
  padding: 5px;
  border-radius: 10px
}
</style>
