<template>
  <!-- disable animation-->
  <b-overlay
    class="dialogs"
    :show="isLoading"
  >
    <b-table
      :items="items"
      :fields="fields"
      responsive
    >
      <template #head(student)="data">
        <feather-icon
          :icon="data.field.icon"
        />
        <p class="mb-0">
          {{ data.label }}
        </p>
      </template>
      <template #head(conductor)="data">
        <feather-icon
          :icon="data.field.icon"
        />
        <p class="mb-0">
          {{ data.label }}
        </p>
      </template>
      <template #cell(conductor)="{ item }">
        <b-input-group size="sm">
          <b-input-group-prepend>
            <b-button
              size="sm"
              variant="danger"
              :disabled="isProcessing || item.today.conductor === 0"
              @click="updateConductor(item, 'minus')"
            >
              <feather-icon icon="MinusIcon" />
            </b-button>
          </b-input-group-prepend>
          <b-form-input
            v-model="item.today.conductor"
            placeholder="Conductor"
            disabled
            size="sm"
          />
          <b-input-group-append>
            <b-button
              :disabled="isProcessing"
              size="sm"
              variant="success"
              @click="updateConductor(item, 'plus')"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </template>
      <template #head(read)="data">
        <feather-icon
          :icon="data.field.icon"
        />
        <p class="mb-0">
          {{ data.label }}
        </p>
      </template>
      <template #cell(read)="{ item }">
        <b-input-group size="sm">
          <b-input-group-prepend>
            <b-button
              size="sm"
              variant="danger"
              :disabled="isProcessing || item.today.read === 0"
              @click="updateRead(item, 'minus')"
            >
              <feather-icon icon="MinusIcon" />
            </b-button>
          </b-input-group-prepend>
          <b-form-input
            v-model="item.today.read"
            placeholder="Read"
            disabled
            size="sm"
          />
          <b-input-group-append>
            <b-button
              :disabled="isProcessing"
              size="sm"
              variant="success"
              @click="updateRead(item, 'plus')"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </template>
      <template #head(answer)="data">
        <feather-icon
          :icon="data.field.icon"
        />
        <p class="mb-0">
          {{ data.label }}
        </p>
      </template>
      <template #cell(answer)="{ item }">
        <b-input-group size="sm">
          <b-input-group-prepend>
            <b-button
              :disabled="isProcessing || item.today.answer === 0"
              size="sm"
              variant="danger"
              @click="updateAnswer(item, 'minus')"
            >
              <feather-icon icon="MinusIcon" />
            </b-button>
          </b-input-group-prepend>
          <b-form-input
            v-model="item.today.answer"
            placeholder="Read"
            size="sm"
            disabled
          />
          <b-input-group-append>
            <b-button
              :disabled="isProcessing"
              size="sm"
              variant="success"
              @click="updateAnswer(item, 'plus')"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </template>

      <template #cell(student)="{ item }">
        <b-card
          no-body
          class="m-0 py-2 pl-1"
          :bg-variant="item.role === 'conductor' ? 'primary' : ''"
          :text-variant="item.role === 'conductor' ? 'light' : 'dark'"
        >
          <p class="mb-0">
            {{ item.student }}
          </p>
          <div class="d-flex">
            <span>
              <feather-icon
                icon="UserCheckIcon"
              />
              {{ item.conductor }}
            </span>

            <span class="mx-1">
              <feather-icon
                icon="BookOpenIcon"
              />
              {{ item.read }}
            </span>

            <span>
              <feather-icon
                icon="MicIcon"
              />
              {{ item.answer }}
            </span>
          </div>
        </b-card>
      </template>

    </b-table>
  </b-overlay>
</template>
<script>
import {
  BTable, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BButton, BOverlay, BCard,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BTable,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BOverlay,
    BCard,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    students: {
      type: Array,
      default: () => [],
    },
    defaultClassRoomId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'student',
          label: this.$i18n.tc('actions.student'),
          tdClass: 'col-3 px-0',
          thClass: 'text-center',
          icon: 'UsersIcon',
        },
        {
          key: 'conductor',
          label: this.$i18n.tc('class-module.student-role.conductor'),
          tdClass: 'text-center col-1',
          thClass: 'text-center',
          icon: 'UserCheckIcon',
        },
        {
          key: 'read',
          label: this.$i18n.tc('student-report-module.report-pages.role-type.read'),
          thClass: 'text-center',
          tdClass: 'text-center col-1',
          icon: 'BookOpenIcon',
        },
        {
          key: 'answer',
          label: this.$i18n.tc('actions.answer'),
          thClass: 'text-center',
          tdClass: 'text-center col-1',
          icon: 'MicIcon',
        },
      ],
      items: [],
      today: [],
      scoreboard: [],
      isProcessing: false,
      isLoading: false,
      classId: this.$route.params.classId || this.defaultClassRoomId,
    }
  },
  created() {
    this.getItems()
  },
  methods: {
    getItems() {
      useJwt.getStudentsWithScoreboards(this.classId).then(response => {
        const data = response.data?.data
        this.items = Object.values(data.total).map(student => ({
          id: student.user_id,
          student: student.username,
          role: student.role,
          conductor: student.conductor ? student.conductor : 0,
          read: student.read ? student.read : 0,
          answer: student.answer ? student.answer : 0,
          today: data.today[student.user_id]
            ? {
              conductor: data.today[student.user_id][0].conductor ? data.today[student.user_id][0].conductor : 0,
              read: data.today[student.user_id][0].read ? data.today[student.user_id][0].read : 0,
              answer: data.today[student.user_id][0].answer ? data.today[student.user_id][0].answer : 0,
            }
            : {
              conductor: 0,
              read: 0,
              answer: 0,
            },
        }))
        this.today = data.today
      })
    },
    updateValueToServer(type, value, studentId) {
      this.isProcessing = true
      useJwt.updateStudentRoleScore(this.classId, type, {
        value,
        student_id: studentId,
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    updateRead(item, action) {
      if (action === 'plus') {
        // eslint-disable-next-line no-param-reassign
        item.read += 1
        // eslint-disable-next-line no-param-reassign
        item.today.read += 1
      }
      if (action === 'minus') {
        // eslint-disable-next-line no-param-reassign
        item.read -= 1
        // eslint-disable-next-line no-param-reassign
        item.today.read -= 1
      }
      this.updateValueToServer('read', item.today.read, item.id)
    },
    updateAnswer(item, action) {
      if (action === 'plus') {
        // eslint-disable-next-line no-param-reassign
        item.answer += 1
        // eslint-disable-next-line no-param-reassign
        item.today.answer += 1
      }
      if (action === 'minus') {
        // eslint-disable-next-line no-param-reassign
        item.answer -= 1
        // eslint-disable-next-line no-param-reassign
        item.today.answer -= 1
      }
      this.updateValueToServer('answer', item.today.answer, item.id)
    },
    updateConductor(item, action) {
      if (action === 'plus') {
        // eslint-disable-next-line no-param-reassign
        item.conductor += 1
        // eslint-disable-next-line no-param-reassign
        item.today.conductor += 1

        this.items.forEach(student => {
          /* eslint-disable no-param-reassign */
          student.role = 'student'
        })
        /* eslint-disable no-param-reassign */
        item.role = 'conductor'
      }
      if (action === 'minus') {
        // eslint-disable-next-line no-param-reassign
        item.conductor -= 1
        // eslint-disable-next-line no-param-reassign
        item.today.conductor -= 1
      }
      this.updateValueToServer('conductor', item.today.conductor, item.id)
    },
  },
}
</script>
<style src='vue-dialog-drag/dist/vue-dialog-drag.css'></style>
<style scoped>
.border-card {
  border: 2px solid black;
}
.main-menu {
  z-index: 500;
}
::v-deep .buttons .close {
  font-size: 1.5em;
}
.dialogs .input-group {
  flex-flow: column-reverse;
  align-items: center;
}
.dialogs .input-group .btn {
  border-radius: 0;
  height: 28px;
}
.dialogs .input-group .form-control {
  width: 40px;
}
.table td .col-3 {
  padding: 0 !important;
}
</style>
