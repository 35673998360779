<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="FileTextIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('general') }}</span>
      </template>

      <account-setting-general
        v-if="options.general"
        :general-data="options.general"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('actions.change-password') }}</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('information') }}</span>
      </template>

      <account-setting-information
        v-if="options.info"
        :information-data="options.info"
      />
    </b-tab>

    <!-- social links -->
    <!-- <b-tab>
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('social') }}</span>
      </template>

      <account-setting-social
        v-if="options.social"
        :social-data="options.social"
      />
    </b-tab> -->

    <!-- notification -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('notification') }}</span>
      </template>

      <account-setting-notification
        v-if="options.notification"
        :notification-data="options.notification"
      />
    </b-tab>

    <!-- Profile -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('profile') }}</span>
      </template>

      <account-setting-profile
        :profile-data="options.profile"
      />
    </b-tab>
    <!--/ general tab -->

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingProfile from './AccountSettingProfile.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
// import AccountSettingSocial from './AccountSettingSocial.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingProfile,
    // AccountSettingSocial,
    AccountSettingNotification,
  },
  data() {
    return {
      options: {},
    }
  },
  computed: {
    usertype() {
      return JSON.parse(localStorage.getItem('userData')).usertype
    },
  },
  created() {
    useJwt
      .userShow()
      .then(response => {
        this.options = {
          general: {
            avatar: '/img/13-small.d796bffd.png',
            username: 'johndoe',
            fullName: 'John Doe',
            email: 'granger007@hogward.com',
            company: 'Crystal Technologies',
          },
          info: {
            bio: '',
            dob: null,
            country: 'USA',
            website: '',
            phone: 6562542568,
          },
          social: {
            socialLinks: {
              twitter: 'https://www.twitter.com',
              facebook: '',
              google: '',
              linkedIn: 'https://www.linkedin.com',
              instagram: '',
              quora: '',
            },
            connections: {
              facebook: {
                profileImg: '/img/13-small.d796bffd00.png',
                id: 'johndoe',
              },
              google: {
                profileImg: '/img/13-small.d796bffd.png',
                id: 'luraweber',
              },
            },
          },
          notification: {
            commentOnArticle: true,
            AnswerOnForm: true,
            followMe: false,
            newAnnouncements: true,
            productUpdates: true,
            blogDigest: false,
          },
        }

        this.options.general = response.data.data
      })
  },
}
</script>
