<template>
  <div>
    <div v-if="isVideo">
      <video-statement
        :video-timestamp="problemStatement[activeIndex].video"
        :is-last-statement="isLastStatement"
        @nextStatement="activeIndex += 1"
        @continueToProblem="$emit('nextProblem')"
      />
    </div>
    <AnimatedLayout v-else
                    :enabled="problem.st_template && !!problem.st_template.animated_layout"
                    :template-id="problem.st_template && problem.st_template.animated_template_id"
                    :is-edit-mode="false"
                    teleport-target="statementActionForAnimationLayout"
    >
      <statement

        :statement="{
          text: problemStatements[activeIndex].statement,
          image: problemStatements[activeIndex].statement_image,
          audio: problemStatements[activeIndex].statement_audio,
          template: problemStatements[activeIndex].st_template,
          theme: problemStatements[activeIndex].theme,
          game:
            problemStatements[activeIndex].game &&
            problemStatements[activeIndex].game.length > activeIndex
              ? problemStatements[activeIndex].game[activeIndex]
              : null,
        }"
        :is-last-statement="isLastStatement"
        :level="level"
        @nextStatement="activeIndex += 1"
        @continueToProblem="$emit('nextProblem')"
      />
    </AnimatedLayout>
  </div>
</template>

<script>
import AnimatedLayout from '@/views/super/problem/body-template/manager.vue'
import Statement from './compoments/Statement.vue'
import VideoStatement from './compoments/VideoStatement.vue'

export default {
  components: {
    AnimatedLayout,
    Statement,
    VideoStatement,
  },
  props: {
    problem: {
      type: Object,
      default: () => {},
    },
    problemStatements: {
      type: Array,
      default: () => [],
    },
    level: {
      type: [Number, String],
      default: 1,
    },
  },
  data: () => ({
    activeIndex: 0,
  }),
  computed: {
    isLastStatement() {
      return this.problemStatements?.length - 1 === this.activeIndex
    },
    isVideo() {
      return !!(this.problemStatements[this.activeIndex]?.video_time_stamp)
    },
  },
}
</script>
