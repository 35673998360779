<template>

  <b-row>
    <b-col
      md="3"
      sm="6"
      class="mb-2"
    >
      <image-upload
        :url="images.vocab_bg_image.content"
        :title="$t('lesson-pages-module.vocab-bg-image')"
        :generator-text="curriculum.general_statement"
        @onSelectedFromLibrary="value => images.vocab_bg_image.content = value"
        @imageUploaded="
          (file, url) => {
            images.vocab_bg_image.content = url;
          }
        "
        @imageRemoved="removeImage('vocab_bg_image')"
        @onImageSelected="v => $emit('onImageSelected', v, 'vocab_bg_image')"
      />
    </b-col>
    <b-col
      md="3"
      class="mb-2 mb-md-0"
      sm="6"
    >
      <image-upload
        :url="images.question_bg_image.content"
        :title="$t('lesson-pages-module.question-bg-image')"
        :generator-text="curriculum.extra_question"
        @imageRemoved="removeImage('question_bg_image')"
        @onSelectedFromLibrary="value => images.question_bg_image.content = value"
        @imageUploaded="
          (file, url) => {
            images.question_bg_image.content = url;
          }
        "
        @onImageSelected="v => $emit('onImageSelected', v, 'question_bg_image')"
      />
    </b-col>
    <b-col
      md="3"
      sm="6"
    >
      <image-upload
        :url="images.top_image.content"
        :title="$t('lesson-pages-module.question-header-image')"
        :generator-text="curriculum.thesis_statement"
        @imageRemoved="removeImage('top_image')"
        @onSelectedFromLibrary="value => images.top_image.content = value"
        @imageUploaded="
          (file, url) => {
            images.top_image.content = url;
          }
        "
        @onImageSelected="v => $emit('onImageSelected', v, 'top_image')"
      />
    </b-col>
    <b-col
      md="3"
      sm="6"
    >
      <image-upload
        :url="images.question_radius_image.content"
        :title="$t('lesson-pages-module.question-radius-image')"
        :generator-text="curriculum.bottom_question"
        @imageRemoved="removeImage('question_radius_image')"
        @onSelectedFromLibrary="value => images.question_radius_image.content = value"
        @imageUploaded="
          (file, url) => {
            images.question_radius_image.content = url;
          }
        "
        @onImageSelected="v => $emit('onImageSelected', v, 'question_radius_image')"
      />
    </b-col>

  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ImageUpload from '@views/common/components/CustomImageUpload.vue'

export default {
  components: {
    BRow, BCol, ImageUpload,
  },
  props: {
    images: {
      type: Object,
      required: true,
    },
    curriculum: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    removeImage(type) {
      this.$emit('removeImage', type)
    },
  },
}
</script>

<style>

</style>
