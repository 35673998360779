<template>
  <validation-observer ref="studentLabIssueReport">
    <b-card
      v-if="showModal"
      class="report-modal not-student-card p-1 mb-0"
      no-body
    >
      <h4>{{ $t('issue-report-module.report-issue') }}</h4>

      <b-overlay>
        <!-- Error Type -->
        <validation-provider
          v-slot="{ errors }"
          name="error type"
          rules="required"
        >
          <b-form-group
            class="mt-1 mb-0"
            :label="$t('issue-report-module.error-type')"
          >
            <b-form-select
              v-model="errorType"
              :options="errorTypeOptions"
            />
          </b-form-group>
          <small class="text-danger mb-1">{{ errors[0] }}</small>
        </validation-provider>
        <!-- Issue Description -->
        <validation-provider
          v-slot="{ errors }"
          name="description"
          rules="required"
        >
          <b-form-group
            class="mt-1 mb-0"
            :label="$t('issue-report-module.issue-description')"
          >
            <b-form-textarea
              v-model="description"
              rows="5"
              :placeholder="$t('issue-report-module.issue-description')"
              @focus="showQuickReportBtn = false"
            />
          </b-form-group>
          <small class="text-danger mb-1">{{ errors[0] }}</small>
        </validation-provider>

        <!-- Image -->
        <b-form-group
          :label="$t('labels.image')"
          class="mt-1"
        >
          <b-form-file
            v-model="formImage"
            accepts="image/*"
            :browse-text="$t('browseButton')"
            :placeholder="$t('issue-report-module.choose-a-file')"
            @input="getImage"
          />
        </b-form-group>
      </b-overlay>
    </b-card>

    <!-- Buttons     -->
    <div class="w-100">
      <!-- Modal Toggle Button -->
      <b-button
        v-if="!hideReportButton"
        v-b-tooltip.bottom.hover
        variant="danger"
        class="report-btn"
        :class="{ active: showModal }"
        :title="showModal ? $t('issue-report-module.close-popup') : $t('issue-report-module.report-issue')"
        @click="showModal = !showModal"
      >
        <feather-icon
          v-if="!showModal"
          class="close-issue-report-btn"
          icon="XCircleIcon"
          size="24"
          role="button"
          @click.stop="hideReportButton = true"
        />
        <feather-icon
          :icon="showModal ? 'XIcon' : 'AlertTriangleIcon'"
          size="24"
        />
      </b-button>
      <!-- Submit Button -->
      <b-button
        v-if="showModal"
        v-b-tooltip.bottom.hover
        class="report-btn"
        variant="success"
        :title="$t('actions.submit')"
        @click="submitIssue"
      >
        <feather-icon
          icon="CheckIcon"
          size="24"
        />
      </b-button>
      <b-button
        v-if="showModal"
        v-b-tooltip.bottom.hover
        class="report-btn-quick"
        variant="warning"
        title="Quick Submit(Image+URL)"
        @click="sendQuickReport"
      >
        <b-spinner v-if="isProcessing" />
        <feather-icon
          v-else
          icon="ZapIcon"
          size="24"
        />
      </b-button>
      <audio
        ref="stickerAudio"
        src="/sfx/Shutter-sound.mp3"
      />
    </div>
  </validation-observer>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BButton, VBTooltip, BFormTextarea, BFormGroup, BFormSelect, BCard, BOverlay, BFormFile, BSpinner,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import html2canvas from 'html2canvas'

export default {
  components: {
    BSpinner,
    BButton,
    BFormTextarea,
    BFormGroup,
    BFormSelect,
    BCard,
    BFormFile,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    userId: {
      type: Number,
      required: true,
      default: () => null,
    },
    defaultIssueTitle: {
      type: String,
      default: 'Student Lab Issue',
    },
    problemId: {
      type: Number,
      default: 0,
    },
    lessonId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showQuickReportBtn: true,
      formImage: null,
      image: null,
      errorType: '',
      description: '',
      errorTypeOptions: [
        { text: 'Select an Error Type', value: '', disabled: true },
        'Grammar', 'Spelling Mistake', 'System Error', 'Other',
      ],
      showModal: false,
      isProcessing: false,
      hideReportButton: false,
    }
  },
  watch: {
    showModal(value) {
      if (!value) {
        this.showQuickReportBtn = true
      }
    },
  },
  methods: {
    submitIssue() {
      this.$refs.studentLabIssueReport.validate()
        .then(success => {
          if (success) {
            this.isProcessing = true

            const link = window.location.pathname

            const params = {
              title: this.defaultIssueTitle,
              description: this.description,
              error_type: this.errorType,
              reported_by: this.userId,
              status: 'pending',
              image: this.image,
              video_link: link,
              problem_id: this.problemId > 0 ? this.problemId : '',
              lesson_id: this.lessonId,
            }
            useJwt.storeIssues([params]).then(response => {
              this.showSuccessMessage(response)
            }).finally(() => {
              this.isProcessing = false
              this.showModal = false
              this.clearForm()
            })
          }
        })
    },
    getImage() {
      if (this.formImage) {
        const reader = new FileReader()
        reader.readAsDataURL(this.formImage)

        reader.onload = event => {
          this.image = event.target.result
        }
      }
    },
    clearForm() {
      this.formImage = null
      this.image = null
      this.description = ''
      this.errorType = ''
    },
    hideQuickReportBtn() {
      this.showQuickBtn = false
    },
    sendQuickReport() {
      this.isProcessing = true
      html2canvas(document.body, {
        useCORS: true,
        width: window.screen.availWidth,
        height: window.screen.availHeight,
        windowWidth: document.body.scrollWidth,
        windowHeight: document.body.scrollHeight,
        x: 0,
        y: window.pageYOffset,
      }).then(screenshot => {
        this.$refs.stickerAudio.play()
        const canvas = screenshot.toDataURL()

        const link = window.location.pathname

        const params = {
          title: this.defaultIssueTitle,
          description: this.description,
          error_type: 'quick',
          reported_by: this.userId,
          status: 'pending',
          image: canvas,
          video_link: link,
          problem_id: this.problemId > 0 ? this.problemId : '',
          lesson_id: this.lessonId,
        }
        useJwt.storeIssues([params]).then(response => {
          this.showSuccessMessage(response)
        }).finally(() => {
          this.isProcessing = false
          this.showModal = false
          this.clearForm()
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.report-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 0 12px;
  bottom:30px;
  z-index: 1000;
  position: fixed;
  border: 2px solid white !important;
  .close-issue-report-btn {
    position: absolute;
    margin-left: 10px;
    margin-top: -24px;
    color: #2a658f;
    display: none;
  }

}

.report-btn:hover {
  .close-issue-report-btn {
    display: inline-block;
  }
}

.report-btn.btn-success{
  left:120px;
  bottom:48px;
}

.report-modal{
  position:fixed;
  bottom:120px;
  left:20px;
  z-index: 100;
}
.report-btn-quick{
  margin-right: 14px;
    position: fixed;
    left: 192px;
    bottom: 48px;
    width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 0 12px;
  :hover{
    color: rgb(255, 0, 0);
  }
}

.btn-info,
.btn-danger {
  left: 48px;
  transition: all 0.3s ease-out;
}
.btn-success {
  left: 56px;
  bottom: 16px;
  transition: all 0.2s ease;
}
.active {
  transform: translateY(-16px);
}
.dark-layout {
  .card {
    background-color: #161E31 !important;
  }
}
</style>
