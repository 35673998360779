<template>
  <div>
    <div class="row problem-header-top d-flex justify-content-between px-1">
      <div>
        <p
          v-if="problemData.question && problemData.question.length > 0"
          v-text-to-speech
          class="text-black text-transparent-theme h3"
        >
          <word-with-popover
            :text="problemData.question"
            meta-type="question"
            popover="question"
          />
        </p>
      </div>
      <div v-if="problemData.page_link && problemData.page_link.length > 0 && $store.getters['problem/canShowEbook'] && !hideEbook">
        <span
          class="badge badge-primary mr-3"
          role="button"
          @click="showModal = true"
        >
          <feather-icon
            icon="LinkIcon"
            class="mr-25"
          />
          {{ $t("common.e-book") }}
        </span>
      </div>
    </div>
    <frame-link
      :url="pageLinkWithDomain"
      :show="showModal"
      :title="$t('common.e-book')"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import FrameLink from '@/views/common/components/FrameLinks.vue'
import { isVisible } from '@/utils/visibilitySettings'
import WordWithPopover from './WordWithPopover.vue'

export default {
  components: {
    FrameLink,
    WordWithPopover,
  },
  props: {
    problemData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
    }
  },
  computed: {
    pageLinkWithDomain() {
      let link = this.problemData.page_link

      const baseUrl = 'http://127.0.0.1'

      if (link?.startsWith(baseUrl)) {
        link = link.replace(baseUrl, window.location.origin)
      }

      return link?.indexOf('://') === -1 ? `http://${link}` : link
    },
    schoolSetting() {
      return this.$store.state.appConfig?.schoolInfo?.user_meta
    },
    distSetting() {
      return this.$store.state.appConfig?.districtInfo?.user_meta
    },
    hideEbook() {
      return !isVisible('ebook', this.schoolSetting, this.distSetting)
    },
  },
}
</script>
<style src='vue-dialog-drag/dist/vue-dialog-drag.css'></style>
