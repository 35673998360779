<template>
  <div
    v-if="Object.keys(profileData).length"
    id="user-profile"
  >
    <teacher-header
      :header-data="profileData.header"
      :is-viewing-own-profile="isViewingOwnProfile"
    />
    <section id="profile-info">
      <b-row>
        <b-col
          lg="3"
          cols="12"
          order="2"
          order-lg="1"
        >
          <teacher-about
            :is-viewing-own-profile="isViewingOwnProfile"
            :data="userData"
          />
          <teacher-courses
            :data="userData"
            :is-viewing-own-profile="isViewingOwnProfile"
          />
        </b-col>
      </b-row>
    </section>
  </div>

</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import TeacherHeader from '@/views/teacher/profile/components/Header.vue'
import TeacherAbout from '@/views/teacher/profile/components/Description.vue'
import TeacherCourses from '@/views/teacher/profile/components/Courses.vue'
import { getUserData } from '@/auth/utils'
import string from '@/utils/string'

export default {
  components: {
    BRow,
    BCol,
    TeacherCourses,
    TeacherHeader,
    TeacherAbout,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      profileData: {},
      allCourses: [],
      teacherProfile: {},
      self: getUserData(),
    }
  },
  computed: {
    isViewingOwnProfile() {
      return this.self && this.self.id === this.userData.id
    },
  },
  created() {
    this.profileData = {
      header: {
        avatar: this.userData.avatar,
        coverImg: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/timeline.aa03c008.jpg',
        username: string.capitalizeFirstLetter(`${this.userData.firstname} ${this.userData.lastname}`),
        designation: string.capitalizeFirstLetter(this.userData.usertype),
      },
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-profile.scss';
</style>
