<template>
  <div>
    <b-modal
      :visible="!!lesson"
      modal-class="modal-dark"
      centered
      :title="$t('common.e-book')"
      hide-footer
      @close="$emit('close')"
      @hide="$emit('close')"
    >
      <b-overlay
        :show="isProcessing"
        class="text-center"
      >
        <b-button
          v-if="!isProcessing && lesson && lesson.live_link && !ebooks.filter(item => item.pageId).length"
          tag="a"
          variant="relief-primary"
          :href="lesson.live_link"
          target="_blank"
          @click.stop
        >
          {{
            $t("common.e-book")
          }}
        </b-button>
        <b-alert
          v-else-if="!isProcessing && !ebooks.length"
          variant="warning"
          show
        >
          <b-card-text
            class="p-2"
          >
            {{ $t("student-lab-module.no-ebooks") }}
          </b-card-text>
        </b-alert>

        <div v-else>
          <p>{{ $t("student-lab-module.please-click-below-read") }}</p>
          <div class="d-flex justify-content-center p-2">
            <template v-for="book,index of ebooks">
              <b-button
                v-if="book.pageId"
                :key="index"
                class="ml-1"
                variant="relief-primary"
                @click="openCurriculumPage(book.curriculumId, book.pageId, book.type)"
              >
                {{ getVersionLabel(book.version) }}
              </b-button>
            </template>
          </div>
          <div :set="essays = ebooks.filter(item => item.type !== 'lesson_pages')">
            <div v-if="essays.length">
              <hr>
              <p>{{ $t("student-lab-module.please-click-below-write") }}</p>
              <template v-for="book,index of essays">
                <b-button
                  v-if="book.pageId"
                  :key="index"
                  class="ml-1"
                  variant="relief-primary"
                  @click="openEssayPage(book.curriculumId)"
                >
                  {{ getVersionLabel(book.version) }}
                </b-button>
              </template>
            </div>
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BCardText, BOverlay, BAlert, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BModal,
    BCardText,
    BOverlay,
    BAlert,
    BButton,
  },
  props: {
    lesson: {
      type: Object,
      default: () => null,
    },
    eventId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      isProcessing: false,
      ebooks: [],
      classId: this.$route.params.classId,
    }
  },
  mounted() {
    if (this.lesson) {
      this.getEbookByVersions()
    }
  },
  methods: {
    getEbookByVersions() {
      this.isProcessing = true
      useJwt.getEbookByVersions(this.lesson.lesson_id).then(response => {
        this.ebooks = response.data?.data
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    openEssayPage(curriculumId) {
      const routeData = this.$router.resolve({
        name: 'essay-builder',
        params: {
          classId: this.classId,
          lessonId: this.lesson.lesson_id,
          curriculumId,
        },
      })
      window.open(routeData.href, '_blank')
    },
    openLessonPage(lessonId, pageId) {
      const query = { classId: this.classId }
      if (this.eventId) {
        query.eventId = this.eventId
      }
      const routeData = this.$router.resolve({ name: 'lesson-pages', params: { lessonId, pageId }, query })
      window.open(routeData.href, '_blank')
    },
    openCurriculumPage(curriculumId, pageId, type) {
      if (type === 'lesson_pages') {
        this.openLessonPage(curriculumId, pageId)
        return
      }
      const query = { classId: this.classId }
      if (this.eventId) {
        query.eventId = this.eventId
      }
      const routeData = this.$router.resolve({ name: 'curriculum-pages', params: { curriculumId, pageId }, query })
      window.open(routeData.href, '_blank')
    },
    getVersionLabel(version) {
      switch (version) {
        case '1': {
          return 'A1'
        }
        case '2': {
          return 'A2'
        }
        case '3': {
          return 'B1'
        }
        case '4': {
          return 'B2'
        }
        default: {
          return ''
        }
      }
    },
  },

}
</script>
