export const LANG_LEVELS = [
  'A1', 'A2', 'B1', 'B2', 'C1', 'C2',
]
export const LANG_LEVELS_COLORS = [
  '#81C784', '#66BB6A', '#43A047', '#388E3C', '#2E7D32', '#1B5E20',
]
export const getLevelFromIndex = level => LANG_LEVELS[parseInt(level, 10) - 1]
export const getColorForLevel = level => LANG_LEVELS_COLORS[LANG_LEVELS.includes(level) ? LANG_LEVELS.indexOf(level) : parseInt(level, 10) - 1]
export const getLevelFromScore = score => {
  if (!score) {
    return null
  }
  if (score <= 16.67) {
    return 'A1'
  } if (score <= 33.33) {
    return 'A2'
  } if (score <= 50) {
    return 'B1'
  } if (score <= 66.67) {
    return 'B2'
  } if (score <= 83.33) {
    return 'C1'
  }
  return 'C2'
}

export const getColorFromScore = score => {
  // eslint-disable-next-line no-param-reassign
  score = Number(score, 10)
  const level = getLevelFromScore(score)
  return getColorForLevel(level)
}

export const getColorBasedOnProficiency = score => {
  // eslint-disable-next-line no-param-reassign
  score = parseInt(score, 10)
  if (!score) {
    return 'invalid'
  }
  if (score <= 16.67) {
    return '#FF0000'
  } if (score <= 33.33) {
    return '#FF8000'
  } if (score <= 50) {
    return '#FFBF00'
  } if (score <= 66.67) {
    return '#FFFF00'
  } if (score <= 83.33) {
    return '#80FF00'
  }
  return '#00FF00'
}

export const getLanguageLevel = level => {
  const langLevel = Number(level)
  switch (langLevel) {
    case 1:
      return 'A1'
    case 2:
      return 'A2'
    case 3:
      return 'B1'
    case 4:
      return 'B2'
    case 5:
      return 'C1'
    case 6:
      return 'C2'
    default:
      return 'invalid'
  }
}

export const LANG_LEVEL_INDICATOR = {
  C2: {
    label: 'C2',
  },
  C1: {
    label: 'C1',
  },
  B2: {
    label: 'B2',
  },
  B1: {
    label: 'B1',
  },
  A2: {
    label: 'A2',
  },
  A1: {
    label: 'A1',
  },
}

export const cefrLangLevel = {
  A1: {
    label: '1',
  },
  A2: {
    label: '2',
  },
  B1: {
    label: '3',
  },
  B2: {
    label: '4',
  },
  C1: {
    label: '5',
  },
  C2: {
    label: '6',
  },
}

export const measureTypes = [
  { text: 'iTEP', value: 'itep' },
  { text: 'TOEFEL Essentials', value: 'toefel_essentials' },
  { text: 'IELTS', value: 'ielts' },
  { text: 'PTE', value: 'pte' },
]
