<template>
  <b-modal
    id="modal-no-backdrop"
    ref="my-modal"
    :visible="true"
    :title="$t('on-progress')"
    :ok-title="$t('ok')"
    ok-only
    size="xl"
    no-close-on-backdrop
    content-class="shadow"
    @close="$emit('close')"
    @hide="$emit('close')"
    @ok="$emit('close')"
  >
    <b-overlay :show="isProcessing">
      <div
        class="mb-2"
      >
        <b-button :disabled="isBulkImageSynching"
                  variant="outline-primary"
                  class="mr-1"
                  @click="handleSelectAll"
        >
          Select All
        </b-button>
        <b-button v-if="selectedImageLibraryForImage.length"
                  :disabled="isBulkImageSynching"
                  variant="outline-primary"

                  @click="handleBulkImageUpdate"
        >
          <b-spinner v-if="isBulkImageSynching"
                     small
          />
          Regenerate Selected Images
        </b-button>
      </div>
      <b-tabs
        v-model="tabsModel"
        pills
      >
        <b-tab :title="$t('on-progress')"
               lazy
        >
          <b-row>
            <b-col v-for="onProgressImage,progindex of onProgressImages"
                   :key="progindex"
                   md="3"
            >
              <library-card :item="onProgressImage"
                            :is-selected="!!selectedImageLibraryForImage.find(i => i == onProgressImage.id)"
                            @onEdit="value => handleEdit(value)"
                            @onDelete="value => handleDelete(value)"
                            @onTranslate="handleLanguageTranslate"
                            @handleSelect="v => handleSelect(v, onProgressImage.id)"
              />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab :title="$t('labels.failed')"
               lazy
        >
          <b-row>
            <b-col v-for="failedImage,failedindex of failedImages"
                   :key="failedindex"
                   md="3"
            >
              <library-card :item="failedImage"
                            :is-selected="!!selectedImageLibraryForImage.find(i => i == failedImage.id)"
                            @onEdit="value => handleEdit(value)"
                            @onDelete="value => handleDelete(value)"
                            @onTranslate="value => handleLanguageTranslate(value)"
                            @handleSelect="v => handleSelect(v, failedImage.id)"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </b-modal>
</template>
<script>
import {
  BModal, BTabs, BTab, BRow, BCol, BButton, BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import LibraryCard from './LibraryCard.vue'

export default {
  name: 'BulkUploadModal',
  components: {
    BModal,
    BTabs,
    BTab,
    BRow,
    BCol,
    LibraryCard,
    BButton,
    BOverlay,
  },
  data() {
    return {
      onProgressImages: [],
      failedImages: [],
      selectedImageLibraryForImage: [],
      isBulkImageSynching: false,
      isProcessing: false,
      tabsModel: 0,
    }
  },
  mounted() {
    this.getOnProgressImages()
  },
  methods: {
    handleSelectAll() {
      this.selectedImageLibraryForImage = []
      if (this.tabsModel === 1) {
        this.selectedImageLibraryForImage = this.failedImages.map(i => i.id)
      } else {
        this.selectedImageLibraryForImage = this.onProgressImages.map(i => i.id)
      }
    },
    getOnProgressImages() {
      this.isProcessing = true
      useJwt.getOnProgressFailedImages().then(response => {
        this.onProgressImages = response.data.data['on-progress']
        this.failedImages = response.data.data.failed
      }).finally(() => {
        this.isProcessing = false
      })
    },
    handleEdit(value) {
      this.$emit('onEdit', value)
    },
    handleDelete(value) {
      this.$emit('onDelete', value)
    },
    handleLanguageTranslate(value) {
      this.$emit('onTranslate', value)
    },
    handleSelect(v, imgId) {
      if (v) {
        this.selectedImageLibraryForImage.push(imgId)
      } else {
        this.selectedImageLibraryForImage = this.selectedImageLibraryForImage.filter(i => i !== imgId)
      }
      this.$emit('handleProgressSelect', this.selectedImageLibraryForImage)
    },
    handleBulkImageUpdate(forceUpdate = null) {
      this.isBulkImageSynching = true
      useJwt.regenerateImgLibrary({
        img_ids: this.selectedImageLibraryForImage,
        force_update: forceUpdate,
      }).then(response => {
        if (response.data.data.limit_crossed) {
          this.$swal.fire({
            title: response.data.data.message,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            allowOutsideClick: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.handleBulkImageUpdate(true)
            } else {
              this.selectedImageLibraryForImage = []
            }
          })
          return
        }
        this.selectedImageLibraryForImage = []
        this.showSuccessMessage(response)
        this.getOnProgressImages()
        this.$emit('close')
      }).finally(() => {
        this.isBulkImageSynching = false
      })
    },
  },
}
</script>
