<template>
  <div>
    <b-overlay :show="isProcessing">
      <BTable :fields="columns"
              :items="items"
      >
        <template #cell(classroom)="data">
          {{ data?.item?.class_room?.name }}
        </template>
        <template #cell(action)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon"
            @click="scriptToApply = data.item?.id"
          >
            <feather-icon icon="CodepenIcon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon"
            @click="scriptDescriptionToShow = data.item"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
        </template>
      </BTable>
    </b-overlay>
    <ConfirmModal
      :show="!!scriptToApply"
      varient="success"
      :title="$t('confirm')"
      :sub-title="$t('sure-want-apply-template')"
      :on-delete="applyClassScript"
      :is-processing-delete="isProcessing"
    />
    <b-modal v-if="!!scriptDescriptionToShow"
             :visible="true"
             :title="scriptDescriptionToShow?.script_name"
             hide-footer
             @close="scriptDescriptionToShow = null"
             @hide="scriptDescriptionToShow = null"
    >
      <p v-html="scriptDescriptionToShow?.description" />
    </b-modal>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BTable, BOverlay, BButton, BModal,
} from 'bootstrap-vue'
import ConfirmModal from '@/views/common/components/DeleteModal.vue'

export default {
  components: {
    BTable,
    BOverlay,
    BButton,
    BModal,
    ConfirmModal,
  },
  props: {
    classId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isProcessing: false,
      items: [],
      scriptToApply: null,
      scriptDescriptionToShow: null,
      columns: [
        {
          key: 'script_name',
          label: this.$i18n.tc('script-name'),
        },
        {
          key: 'classroom',
          label: this.$i18n.tc('class-room'),
        },
        {
          key: 'Action',
          label: this.$i18n.tc('action'),
        },
      ],
    }
  },
  mounted() {
    this.isProcessing = true
    useJwt.getClassroomPublicScript().then(response => {
      // eslint-disable-next-line eqeqeq
      this.items = response.data.data.filter(item => item.class_room_id != this.classId)
    }).catch(error => {
      this.showErrorMessage(error)
    }).finally(() => {
      this.isProcessing = false
    })
  },
  methods: {
    applyClassScript() {
      this.isProcessing = true
      useJwt.applyClassroomPublicScript(this.classId, {
        script_id: this.scriptToApply,
      }).then(response => {
        this.showSuccessMessage(response)
        this.scriptToApply = null
        this.$emit('refetch')
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
