/* eslint-disable import/no-cycle */
import isAuthenticated from '@/middlewares/isAuthenticated'
import teacherUsersOnly from '@/middlewares/teacherUsersOnly'

export default [
  // {
  //   path: '/teacher/home',
  //   meta: {
  //     middleware: [isAuthenticated, teacherUsersOnly],
  //   },
  //   name: 'teacher-home',
  //   component: () => import('@/views/teacher/Home.vue'),
  // },
  // {
  //   path: '/teacher/dashboard',
  //   meta: {
  //     middleware: [isAuthenticated, teacherUsersOnly],
  //   },
  //   name: 'teacher-dashboard',
  //   component: () => import('@/views/common/dashboard/Index.vue'),
  // },
  {
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    path: '/teacher/users',
    name: 'teacher-users',
    component: () => import('@/views/common/user/Index.vue'),
  },
  {
    path: '/teacher/classes',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    name: 'teacher-classes',
    component: () => import('@/views/common/class/Handler.vue'),
  },
  {
    path: '/teacher/classes/:classId/calendar',
    name: 'teacher-class-calendar',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
      navActiveLink: 'teacher-classes',
    },
    component: () => import('@/views/teacher/class/components/calendar/Calendar.vue'),
  },
  {
    path: '/teacher/overall-classroom-summary',
    name: 'teacher-report-overall-classroom-summary',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    component: () => import('@/views/v2/common/class/overall-report-summary/overall-summary.vue'),
  },
  {
    path: '/teacher/classes/:classId/overall-report',
    name: 'teacher-report-overall-summary',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    component: () => import('@/views/v2/common/class/overall-report-summary/single-classroom-summary.vue'),
  },
  {
    path: '/teacher/classes/:id/:courseId?/:reporting?',
    name: 'teacher-class',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
      navActiveLink: 'teacher-classes',
    },
    component: () => import('@/views/common/class/Show.vue'),
  },

  {
    path: '/teacher/courses/:id',
    name: 'teacher-course',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
      navActiveLink: 'teacher-classes',
    },
    component: () => import('@/views/common/class/components/ShowCourse.vue'),
  },

  {
    path: '/teacher/networks',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    name: 'teacher-networks',
    component: () => import('@/views/common/network/Index.vue'),
  },
  {
    path: '/teacher/class/:classId/lesson/:id',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
      navActiveLink: 'teacher-classes',
    },
    name: 'teacher-lesson',
    component: () => import('@/views/student/lesson/Show.vue'),
  },
  {
    path: '/teacher/class/:classId/course/:id/lessons',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
      navActiveLink: 'teacher-classes',
    },
    name: 'teacher-lessons',
    component: () => import('@/views/student/lesson/Index.vue'),
  },
  {
    path: '/teacher/class/:classId/course/:id/result',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
      navActiveLink: 'teacher-classes',
    },
    name: 'teacher-course-result',
    component: () => import('@/views/student/course/Result.vue'),
  },
  {
    path: '/teacher/class/:classId/lesson/:id/problems',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
      navActiveLink: 'teacher-classes',
    },
    name: 'teacher-problems',
    component: () => import('@/views/student/problem/Index1.vue'),
  },
  {
    path: '/teacher/networks/:id',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
      navActiveLink: 'teacher-networks',
    },
    name: 'teacher-networks-show',
    component: () => import('@/views/common/network/Show.vue'),
  },
  {
    path: '/teacher/student-diary/:id',
    name: 'teacher-student-diary',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    component: () => import('@/views/common/student-diary/Index.vue'),
  },

  {
    path: '/teacher/classes/:classId/students/:studentId/calendar',
    name: 'teacher-student-calendar',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
      navActiveLink: 'teacher-classes',
    },
    component: () => import('@/views/teacher/class/components/calendar/Calendar.vue'),
  },

  {
    path: '/teacher/class/:classId/student/:id/rubrics',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
      navActiveLink: 'teacher-classes',
    },
    name: 'teacher-rubrics',
    component: () => import('@/views/common/class/components/rubric/Show.vue'),
  },

  {
    path: '/teacher/view-students/:id',
    name: 'teacher-view-students',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
      navActiveLink: 'teacher-classes',
    },
    component: () => import('@/views/v2/common/class/components/StudentTable.vue'),
  },
  {
    path: '/teacher/schedular',
    name: 'teacher-schedular',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    component: () => import('@/views/teacher/class/availability-schedular/index.vue'),
  },
  {
    path: '/teacher/pair/:classId/student',
    name: 'teacher-pair-student',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    component: () => import('@/views/v2/common/class/paring/index.vue'),
  },
  {
    path: '/view-notes-all',
    name: 'view-notes-all',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    component: () => import('@/views/v2/common/class/Notes/index.vue'),
  },
  {
    path: '/teacher/student/dashboard/:id',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    name: 'student-dashboard-for-teacher',
    component: () => import('@/views/common/dashboard/Index.vue'),
  },
  {
    path: '/teacher/view-student-time',
    name: 'teacher-view-student-time',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
      navActiveLink: 'teacher-view-student-time',
    },
    component: () => import('@/views/student/timespent/index.vue'),
  },

  {
    path: '/teacher/view-attendance/:id?',
    name: 'teacher-view-attendance',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
      navActiveLink: 'teacher-view-attendance',
    },
    component: () => import('@/views/student/attendance/index.vue'),
  },
  {
    path: '/teacher/monitor/:classId?',
    name: 'teacher-monitor-class',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    component: () => import('@/views/v2/common/class/Monitor/index.vue'),
  },
  {
    path: '/teacher/student/personality-evaluation',
    name: 'student-personality-evaluation-for-teacher',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
      navActiveLink: 'student-personality-evaluation-for-teacher',
    },
    component: () => import('@/views/common/personality-evaluation/Index.vue'),
  },
  {
    path: '/teacher/class-test/:classId',
    name: 'teacher-class-test',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    component: () => import('@/views/student/lesson/ClassTest/index.vue'),
  },
  {
    path: '/teacher/courses',
    name: 'teacher-courses',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    component: () => import('@/views/common/courses/Index.vue'),
  },
  {
    path: '/teacher/game-report',
    name: 'game-report',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    component: () => import('@/views/common/class/game-report/index.vue'),
  },
  {
    path: '/teacher/report-issue',
    name: 'report-issue',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    component: () => import('@/views/common/report-issue/Index.vue'),
  },
  {
    path: '/teacher/market/:marketId',
    name: 'market-details',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    component: () => import('@views/common/dashboard/components/MarketDetails.vue'),
  },
  {
    path: '/teacher/higher-level-report',
    name: 'teacher-higher-level-report',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    component: () => import('@/views/common/higher-level-report/index.vue'),
  },
  {
    path: '/teacher/individual-report/:studentId',
    name: 'teacher-individual-report',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    component: () => import('@/views/common/individual-report/index.vue'),
  },
  {
    path: '/teacher/report-calibration',
    name: 'teacher-report-calibration',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    component: () => import('@/views/common/report-calibration/index.vue'),
  },
  {
    path: '/teacher/reservation-schedules',
    name: 'teacher-reservation-schedules',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    component: () => import('@/views/reservations/internal/teacher-schedule/index.vue'),
  },
  {
    path: '/teacher-demo-lesson',
    name: 'teacher-demo-lesson',
    meta: {
      layout: 'full',
      // middleware: [isNotAuthenticated],
    },
    component: () => import('@/views/GuestLesson/index.vue'),
  },
  {
    path: '/teacher/class/:classId/scorm-lesson/:lessonId',
    name: 'teacher-scorm-lesson',
    meta: {
      middleware: [isAuthenticated, teacherUsersOnly],
    },
    component: () => import('@/views/student/problem/scorm-problem/index.vue'),
  },
]
