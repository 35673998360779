import { ref, getCurrentInstance } from 'vue'
import useJwt from '@/auth/jwt/useJwt'

export default function useChatIde() {
  const root = getCurrentInstance().proxy.$root
  const messages = ref([])
  const isBotTyping = ref(false)
  const typedMessage = ref('')

  const sendMessage = () => {
    isBotTyping.value = true
    messages.value.push({
      from: 'human',
      message: typedMessage.value,
    })
    typedMessage.value = ''

    useJwt.getBotResponse({
      chat_history: messages.value.map(item => {
        if (item.from === 'bot') {
          return {
            chatbot: item.message,
          }
        }
        return {
          human: item.message,
        }
      }),
    }).then(response => {
      messages.value.push({
        from: 'chatbot',
        message: response.data.data.response,
      })
    }).catch(error => {
      root.showErrorMessage(error)
    }).finally(() => {
      isBotTyping.value = false
    })
  }

  return {
    typedMessage,
    sendMessage,
    messages,
    isBotTyping,
  }
}
