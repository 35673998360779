<template>
  <body>
    <header>
      <div />
      <div>
        <h3>GEC App Changelogs</h3>
      </div>
    </header>

    <div id="page-container">
      <h1>Changelogs</h1>
      <section>
        <div id="changelogs">
          <div id="items">
            <div
              v-for="release in releases"
              :key="release.version"
              class="item"
            >
              <div class="timeline">
                <div>
                  <div class="meta">
                    <div class="version">
                      Version {{ release.version }}
                    </div>
                    <div class="release-date">
                      {{ release.date }}
                    </div>
                  </div>
                  <div class="connector">
                    <div />
                  </div>
                </div>
              </div>
              <div class="main-content">
                <h3>A new version release! 🎉</h3>
                <p>New release includes a tons of features and bug fixes!</p>
                <div
                  v-for="(feature,index) in release.features"
                  :key="index"
                  class="feature"
                >
                  <div :class="`tag text-capitalize ${feature.type}`">
                    {{ feature.type }}
                  </div>
                  <p>{{ feature.details }}</p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="timeline has-next">
                <div>
                  <div class="meta">
                    <div class="version">
                      Version 1.0.0
                    </div>
                    <div class="release-date">
                      2021-12-19
                    </div>
                  </div>
                  <div class="connector">
                    <div />
                  </div>
                </div>
              </div>
              <div class="main-content">
                <h3>Welcome to our new way of sharing release notes!</h3>
                <p>From here on out, this will be the go to place to see new changes!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>Copyright <span v-text="new Date().getFullYear()" />, All Rights Reserved.</footer>
    </div>
  </body>

</template>

<script>
export default {
  data() {
    return {
      releases: [
        {
          version: '2.0.0',
          releaseData: '2021-12-29',
          features: [
            {
              // types can be enhancements, fixes or new
              type: 'new',
              details: 'Changelogs added',
            },
          ],
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
// Reset
*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// Variables
$tertiary: #25204C;
$grey:     #f2f2f2;
$purple:   #7769E4;
$cyan:     #64D4D6;
$red:      #FF7A86;

$column-width: 448px;
$center-column-width: 640px;

// Misc.
h1, h2, h3, h4 {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: normal;
}

h1, h2 {
  font-weight: 900;
}

h1 {
  font-size: 5vw;
}

h2 {
  font-size: 32px;
}

h3 {
  font-weight: bold;
  font-size: 24px;
}

p {
  /* Allow whitespace */
  white-space: pre-line;
  line-height: 32px;
}

html, body {
  font-family: 'Open Sans', sans-serif;
  color: $tertiary;
  font-size: 16px;
  height: 100%;
  width: 100%;
  overflow: auto;
}

// Content
body {
  display: flex;
  flex-direction: column;

  header {
    height: 80px;
    background: white;
    border-bottom: 2px solid $grey;
    display: flex;
    flex-direction: column;
    z-index: 1;

    > div:first-child {
      background: $purple; /* Backup */
      background: linear-gradient(to right, $purple 0%, $cyan 50%, $red 100%);
      background-size: 200% 100%;
      animation: gradient 30s ease infinite;
      min-height: 8px;
      width: 100%;
    }

    > div:last-child {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 16px;
    }
  }

  #page-container {
    text-align: center;
    overflow-y: auto;
    height: 100%;
    padding: 64px 0;

    section {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 64px;
      text-align: left;

      > #changelogs {
        max-width: $column-width + $center-column-width;
        width: 100%;

        > #items {
          > .item {
            display: flex;
            justify-content: flex-end;

            .timeline {
              display: flex;
              justify-content: flex-end;
              padding: 0 16px;
              max-width: $column-width;
              width: 100%;

              > div {
                display: flex;
                align-items: center;
                flex-direction: column;

                .meta {
                  text-align: center;

                  .version, .release-date {
                    // Show ellipsis on overflow
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }

                  .version {
                    font-weight: bold;
                  }

                  .release-date {
                    margin-top: 8px;
                    font-size: 12px;
                  }
                }

                .connector {
                  position: relative;
                  height: 100%;

                  > div {
                    position: absolute;
                    margin-top: 16px;
                    width: 2px;
                    background: $tertiary;
                    height: calc(100% + 24px);

                    &:before, &:after {
                      content: '';
                      position: absolute;
                      right: -3px;
                      display: block;
                      width: 8px;
                      height: 8px;
                      background: $tertiary;
                      border-radius: 100%
                    }

                    &:before {
                      top: -4px;
                    }

                    &:after {
                      bottom: -4px;
                    }
                  }
                }
              }
            }

            .main-content {
              padding: 0 16px;
              width: 100%;

              .feature {
                display: flex;

                .tag {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 24px;
                  /* Stop tag from strinking or stretching */
                  min-width: 80px;
                  max-width: 80px;
                  border-radius: 12px;
                  color: white;
                  font-size: 9px;
                  margin-top: 4px;

                  &.new {
                    background: $purple;
                  }

                  &.enhancements {
                    background: $cyan;
                  }

                  &.fixes {
                    background: $red;
                  }
                }

                p {
                  margin-left: 16px;
                }
              }

              > *:not(:last-child) {
                margin-bottom: 16px;
              }
            }

            // Hide last item's timeline connector
            &:last-child .timeline .connector {
              opacity: 0;
            }

            > * {
              margin-bottom: 64px;
            }
          }
        }

        > #pagination {
          padding: 0 16px;
          cursor: pointer;
          font-weight: bold;
          text-align: center;
          opacity: .25;
          transition: opacity .2s ease-in-out;
          margin-bottom: 64px;
          outline: none;
          margin-left: $column-width;

          &:hover, &:focus {
            opacity: 1;
          }
        }
      }

      > #subscribe {
        max-width: $column-width;
        width: 100%;
        margin-bottom: 64px;
        position: sticky;
        display: table;
        top: 0;

        > div {
          margin: 0 16px;
          background: $grey;
          border-radius: 12px;
          padding: 32px;

          > *:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }

    footer {
      font-size: 14px;
      margin-top: auto;
      padding: 0 16px;
    }
  }
}

// Wrap content
@media only screen and (max-width: $center-column-width + ($column-width * 2)) {
  #page-container > section {
    // Move subscribe below
    flex-direction: column;
    align-items: center;

    // Reset to defaults
    .timeline {
      max-width: none !important;
      width: auto !important;
    }

    #pagination {
      margin-left: 0 !important;
    }
  }
}

// Mobile Responsiveness
@media only screen and (max-width: 768px) {
  h1 {
    font-size: 40px;
  }

  #changelogs .item {
    flex-direction: column;

    .timeline {
      justify-content: flex-start !important;
      margin-bottom: 16px !important;

      .meta {
        display: flex;
        align-items: center;

        .release-date {
          margin-top: 0 !important;
          margin-left: 8px;
        }

        .version, .release-date {
          font-size: 14px !important;
        }
      }

      .connector {
        display: none;
      }
    }

    .main-content .meta {
      display: flex !important;
    }
  }
}

@media only screen and (max-width: 384px) {
  #changelogs .item .main-content .feature {
    flex-direction: column;

    .tag {
      margin-top: 0 !important;
      margin-bottom: 8px;
    }

    p {
      margin: 0 !important;
    }
  }
}

// Other
input, .button {
  height: 32px;
  width: 100%;
  border-radius: 16px;
  border: 0;
  outline: none;
}

input {
  padding: 0 16px;
  margin-bottom: 16px;
  overflow: hidden;
  font-size: 14px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $tertiary;
  color: white;
  font-size: inherit;
  font-weight: bold;
  cursor: pointer;
  transition: opacity .2s ease-in-out, transform .2s ease-in-out;

  &:hover, &:focus {
    opacity: .5;
  }

  &:active {
    transform: translateY(2px);
  }
}

@keyframes gradient {
  0%   { background-position: 0% 50%; }
  50%  { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

</style>
