export default [
  {
    key: 'q',
    color: '#34ccff',
  },
  {
    key: 'w',
    color: '#66ff99',
  },
  {
    key: 'e',
    color: '#ffff80',
  },
  {
    key: 'r',
    color: '#ffb84d',
  },
  {
    key: 't',
    color: '#ffb84d',
  },
  {
    key: 'y',
    color: '#ffb84d',
  },
  {
    key: 'u',
    color: '#ffb84d',
  },
  {
    key: 'i',
    color: '#ffff80',
  },
  {
    key: 'o',
    color: '#66ff99',
  },
  {
    key: 'p',
    color: '#34ccff',
  },
  // {
  //   key: 'caps',
  //   color: '#EE82EE',
  // },
  {
    key: 'a',
    color: '#34ccff',
  },
  {
    key: 's',
    color: '#66ff99',
  },
  {
    key: 'd',
    color: '#ffff80',
  },
  {
    key: 'f',
    color: '#ffb84d',
  },
  {
    key: 'g',
    color: '#ffb84d',
  },
  {
    key: 'h',
    color: '#ffb84d',
  },
  {
    key: 'j',
    color: '#ffff80',
  },
  {
    key: 'k',
    color: '#66ff99',
  },
  {
    key: 'l',
    color: '#34ccff',
  },
  // {
  //   key: 'enter',
  //   color: '#CD853F',
  // },
  // {
  //   key: 'done',
  //   color: '#FFD700',
  // },
  {
    key: 'z',
    color: '#34ccff',
  },
  {
    key: 'x',
    color: '#66ff99',
  },
  {
    key: 'c',
    color: '#ffff80',
  },
  {
    key: 'v',
    color: '#ffb84d',
  },
  {
    key: 'b',
    color: '#ffff80',
  },
  {
    key: 'n',
    color: '#66ff99',
  },
  {
    key: 'm',
    color: '#34ccff',
  },
  {
    key: 'A/a',
    color: '#EE82EE',
  },
  {
    key: 'space',
    color: '#34ccff',
  },
  {
    key: '123',
    color: '#EE82EE',
  },

]
