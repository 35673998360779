export default {
  props: {
    elementData: {
      type: Array,
      default: () => [],
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    canStartAnimation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      animatingData: [],
      isAnimating: false,
      canAnimate: this.canStartAnimation,
    }
  },
  watch: {
    elementData: {
      handler() {
        this.renderWithAnimation()
        this.$forceUpdate()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    playAudio(audio) {
      return new Promise(res => {
        audio.play()
        // eslint-disable-next-line no-param-reassign
        audio.onended = res
      })
    },
    async playTextAudio(item) {
      if (item.audioUrl) {
        const audio = new Audio(item.audioUrl)
        // eslint-disable-next-line no-await-in-loop
        await this.playAudio(audio)
      }
    },
    // animation starts from parent elements
    async renderWithAnimation(proceed = false) {
      if (this.isAnimating || (!this.canAnimate && !proceed)) {
        return
      }
      this.animatingData = []
      // eslint-disable-next-line no-param-reassign
      this.isAnimating = true
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.elementData) {
        this.animatingData.push({ ...item })
        if (item.audioUrl && item.playAudioAutomatically) {
          const audio = new Audio(item.audioUrl)
          // eslint-disable-next-line no-await-in-loop
          await this.playAudio(audio)
        }
      }
      // eslint-disable-next-line no-param-reassign
      this.isAnimating = false
      this.canAnimate = true
    },
  },
}
