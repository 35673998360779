var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-col',{attrs:{"md":"12"}},[_c('b-tabs',{staticClass:"lang-level-tabs",attrs:{"pills":"","align":"center"}},_vm._l((_vm.levels),function(level,index){return _c('b-tab',{key:index + level,attrs:{"title":_vm.getLangLevelTransText(level),"title-item-class":_vm.showLangLevel ? '' : 'd-none',"active":_vm.activeTabIndex === index},on:{"click":function($event){return _vm.tabChange(index)}}},[_c('b-row',[_c('b-col',{staticClass:"p-0 pr-1",attrs:{"md":"12","lg":_vm.problemGroupType === 'video' ? 12:4}},[_c('b-card',[(_vm.problemGroupType==='hot-spot')?_c('div',[_c('HotSpotStatementFeedbackForm',{key:index,attrs:{"index":index}})],1):_c('statement-feedback-form',{attrs:{"level":index,"video-type":_vm.problemGroupType === 'video'}})],1)],1),_c('b-col',{staticClass:"p-0"},[_c('b-card',[_c('cefr-form',{attrs:{"level":index}}),(_vm.isDragHotSpot)?_c('HotSpotDragChoice',{attrs:{"level":index},on:{"answers":function($event){return _vm.setAnswers($event, index)},"questions":function($event){return _vm.setQuestions($event, index)},"delete":function($event){return _vm.deleteQA(index)}}}):(_vm.problemType === 'multiple')?_c('multiple-choice',{attrs:{"level":index},on:{"answers":function($event){return _vm.setAnswers($event, index)},"questions":function($event){return _vm.setQuestions($event, index)},"delete":function($event){return _vm.deleteQA(index)}}}):_vm._e()],1),_c('b-card',[_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2 md-mb-0 d-flex justify-content-start",attrs:{"md":"6","sm":"12","xs":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"outline-success"},on:{"click":function($event){_vm.$emit(
                        'showQuestionPreview',
                        _vm.getDataForQuestionPreview()
                      )}}},[_vm._v(" "+_vm._s(_vm.$t("actions.preview"))+" ")])],1),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"md":"6","sm":"6","xs":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$router.push({
                        path: '/super/problem',
                        query: { lesson_id: _vm.$route.query.lesson_id },
                      })}}},[_vm._v(" "+_vm._s(_vm.$t("actions.back"))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","disabled":_vm.isProcessing},on:{"click":_vm.createQuestions}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isProcessing),expression:"isProcessing"}],attrs:{"small":""}}),_vm._v(" "+_vm._s(_vm.$t("actions.create-problem"))+" ")],1)],1)],1)],1)],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }