<template>
  <div class="image-container">
    <img v-image-preview
         :src="src"
         :alt="alt"
         :style="imageStyle"
         @load="updateImageSize"
    >
    <div v-if="imageSize.width && imageSize.height"
         class="size-overlay"
    >
      {{ imageSize.width }} x {{ imageSize.height }} px
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageSizeViewer',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: 'Image',
    },
    imageStyle: {
      type: Object,
      default: () => {},
    },

  },
  data() {
    return {
      imageSize: {
        width: null,
        height: null,
      },
    }
  },
  methods: {
    updateImageSize(event) {
      const { naturalWidth, naturalHeight } = event.target
      this.imageSize.width = naturalWidth
      this.imageSize.height = naturalHeight
    },
  },
}
</script>

<style scoped>
.image-container {
  position: relative;
  display: inline-block;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.size-overlay {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}
</style>
