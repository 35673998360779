<template>
  <b-row
    class="m-0 text-selection"
    :class="{'fill-row': !copyPasteEnabled}"
  >
    <b-col
      class="problem-card-title text-center"
      md="12"
    >
      <h4 class="mb-0">
        {{ getProblemHintsDescription($t("problem-types.text-selection")) }}
      </h4>
    </b-col>

    <b-col
      md="12"
      class="problem-overview-content"
    >
      <validation-observer ref="submitQuestionForm">
        <div class="ck-content">
          <!-- eslint-disable vue/no-v-html -->
          <span
            v-if="fullHtml"
            @click="handleClick"
            v-html="fullHtml"
          />
          <!-- eslint-enable vue/no-v-html -->
          <!-- <span v-html="fullHtml" /> -->
          <div
            v-else
            class="ck-content"
          >
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="template && template.before_template && template.before_template.length>0
                &&
                ![
                  'Shapes',
                  'SideCard',
                ].includes(getTemplateComponent)
                ||
                (getTemplateComponent === 'Shapes' && template.template[0].type !== 'fib')
              "
              @click="handleClick"
              v-html="beforeHtml"
            />
            <!-- eslint-enable vue/no-v-html -->
            <component
              :is="getTemplateComponent"
              v-if="template && template.template"
              key="templateComponent"
              :place-holder="placeHolder"
              :data="template.template"
              :is-a-problem="true"
              :question-formatter="generateQuestion"
              :template-options="getTemplateOptions"
              @onClick="handleClick"
            >
              <div
                v-if="
                  [
                    'Shapes',
                    'SideCard',
                  ].includes(getTemplateComponent)
                    ||
                    (getTemplateComponent === 'Shapes' && template.template[0].type !== 'fib')"
                @click="handleClick"
                v-html="beforeHtml"
              />
            </component>
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="
                template &&
                  template.after_template &&
                  template.after_template.length > 0
              "
              v-text-to-speech
              v-html="afterHtml"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </div>
      </validation-observer>
    </b-col>
    <portal
      to="problemFeedBack"
    >
      <b-row class="m-0">
        <b-col md="12"
               class="p-0"
        >
          <feedback
            :feedback="feedback"
            :problem-statement-id="problemStatementId"
          />
        </b-col>
        <b-col
          v-if="!isPreview"
          class="m-0 p-0"
          md="12"
        >
          <actions
            :has-feedback="hasFeedback"
            :is-processing="isProcessing"
            :level="level ? level : ''"
            :has-minimal-view="hasMinimalView"
            :on-submit="submit"
            :tooltip-text="
              $t('student-test-module.fill-problem-validation-message')
            "
            :display-result="displayResult"
            :button-title="hasFeedback ? $t('next') : $t('submit')"
            :engine="engine"
            :school-settings="schoolSettings"
            :district-settings="districtSettings"
            v-on="$listeners"
          />
        </b-col>
      </b-row>
    </portal>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormInput, BImg, BCard,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import { mapGetters } from 'vuex'
import { checkSettings } from '@/utils/visibilitySettings'
import Paragraph from '@/views/common/templates/components/Paragraph.vue'
import ImageQA from '@/views/common/templates/components/ImageQA.vue'
import Shapes from '@/views/common/templates/components/Shapes.vue'
import ImageParagraph from '@/views/common/templates/components/ImageParagraph.vue'
import CenteredCard from '@/views/common/templates/components/CenteredCard.vue'
import SideCard from '@/views/common/templates/components/SideCard.vue'
import Cards from '@/views/common/templates/components/Cards.vue'
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'
import Actions from './Actions.vue'
import Feedback from './Feedback.vue'
// declare to free up infinite loop from parent
let correctAnswers = []
let incorrectAnswers = []
export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    Feedback,
    Actions,
    Paragraph,
    ImageParagraph,
    ImageQA,
    CenteredCard,
    Cards,
    Shapes,
    BImg,
    BCard,
    AudioPlayer,
    SideCard,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    questions: {
      type: Array,
      required: true,
    },
    previewAnswers: {
      type: Array,
      default: () => [],
    },
    isProcessing: {
      type: Boolean,
      required: true,
    },
    feedback: {
      type: Object,
      default: () => { },
    },
    displayResult: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      default: 1,
    },
    feedbackQuestion: {
      type: Array,
      default: () => [],
    },
    problemStatementId: {
      type: [Number, String],
      required: false,
    },
    rules: {
      type: Object,
      default: () => null,
    },
    startTime: {
      type: [String, Date],
      required: false,
      default: () => null,
    },
    engine: {
      type: String,
      default: () => '',
    },
    hasMinimalView: {
      type: Boolean,
      default: false,
    },
    schoolSettings: {
      type: Array,
      default: () => [],
    },
    districtSettings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      placeHolder: '[ ]',
      localQuestions: [],
      answers: {},
      fullHtml: '',
      template: null,
      beforeHtml: '',
      afterHtml: '',
      centralHtml: '',
      defaultShape: 'hexagon.png',
      fibType: 'fib',
      // text selection
      selectedAnswers: [],
    }
  },
  computed: {
    ...mapGetters('problem', {
      shapes: 'getShapes',
    }),
    bodyAudio() {
      return this.questions[0]?.extra_file
    },
    feedbackQuestionComputed() {
      if (this.feedback?.template?.template) {
        return this.feedback?.template?.template
      }
      return this.feedbackQuestion
    },
    getTemplateComponent() {
      if (this.template?.type === 'paragraph') return 'Paragraph'
      if (this.template?.type === 'image-paragraph') return 'ImageParagraph'
      if (this.template?.type === 'image-qa') return 'ImageQA'
      if (this.template?.type === 'shapes') return 'Shapes'
      if (this.template?.type === 'centered-cards') return 'CenteredCard'
      if (this.template?.type === 'cards') return 'Cards'
      if (this.template?.type === 'side-cards') return 'SideCard'
      return ''
    },
    shouldShowHints() {
      // eslint-disable-next-line eqeqeq
      const image = this.rules?.hide_image_hints != '1'
      // eslint-disable-next-line eqeqeq
      const text = this.rules?.hide_text_hints != '1'
      return {
        // eslint-disable-next-line eqeqeq
        hints: this.rules?.hide_hints != '1' && (image || text),
        image_hints: image,
        text_hints: text,
      }
    },
    hasFeedback() {
      return !!(this.feedback?.text?.length > 0 || this.feedback?.image || this.feedback?.audio || this.feedback?.template)
    },
    getShape() {
      if (this.template?.type !== 'shapes') return ''
      const sh = this.template?.template[0]?.shape ? this.template?.template[0]?.shape : this.defaultShape
      return this.shapes.filter(shape => shape.value === sh)[0]
    },
    getFibType() {
      if (this.template?.type !== 'shapes') return ''
      return this.template?.template[0]?.type ? this.template?.template[0]?.type : this.fibType
    },
    getBgColor() {
      if (this.template?.type !== 'shapes') return ''
      return this.template?.template[0]?.bgColor
    },
    getTemplateOptions() {
      if (this.template?.type !== 'shapes') return ''
      const sh = this.template?.template[0]?.shape ? this.template?.template[0]?.shape : this.defaultShape

      return {
        shape: this.shapes.filter(shape => shape.value === sh)[0],
        type: this.template?.template[0]?.type ? this.template?.template[0]?.type : this.fibType,
        bgColor: this.template?.template[0]?.bgColor,
        textAlign: this.template?.template[0]?.textAlign ? this.template?.template[0]?.textAlign : 'left',
      }
    },
    defaultAnswers() {
      let answers = []
      // eslint-disable-next-line no-unused-expressions
      this.questions?.forEach(question => {
        answers = [...answers, ...question.answers.map(ans => ans.text)]
      })
      // eslint-disable-next-line eqeqeq
      if (this.rules?.disable_random_hints != '1') {
        answers.sort(() => Math.random() - 0.5)
      }
      return answers
    },
    autoPlay() {
      return this.$route.query.autoPlay
    },
    copyPasteEnabled() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const setting = checkSettings('copy_paste', schoolSetting, distSetting)
      if (setting.value === '1') {
        return true
      }
      return false
    },
  },

  mounted() {
    this.initData().then(() => {
      this.changeInputSize()
    })
  },

  methods: {
    // text selection functions
    generateCorrectionHtml(paragraph, identifier, answers, isCorrect = true) {
      const items = paragraph.split(identifier)
      let string = paragraph
      const generatedWords = []
      items.forEach(item => {
        const requiredWord = `${identifier}${item}${identifier}`
        if (string.includes(requiredWord)) {
          generatedWords.push(item)
        }
        let className = 'selectable-answer'
        if (answers.includes(item)) {
          className = isCorrect ? 'correct-answer' : 'incorrect-answer'
        }
        string = string.replace(requiredWord, `<b class="${className}">${item}</b>`)
      })
      return {
        string,
        generatedWords,
      }
    },
    generateQuestion(text) {
      const question = text
      if (question) {
        const correctPatterns = this.generateCorrectionHtml(text, '**', this.previewAnswers?.correct || [])
        const finalPatterns = this.generateCorrectionHtml(correctPatterns.string, '##', this.previewAnswers?.incorrect || [], false)
        correctAnswers = [...correctAnswers, ...correctPatterns.generatedWords]
        incorrectAnswers = [...incorrectAnswers, ...finalPatterns.generatedWords]
        return finalPatterns.string
      }
      return ''
    },
    handleClick(e) {
      const elt = e.target.closest('.selectable-answer')
      if (elt) {
        const className = correctAnswers.includes(elt.innerText) ? 'correct-answer' : 'incorrect-answer'
        elt.className = className
        this.selectedAnswers.push(elt.innerText)
      }
    },
    // template functions

    initData() {
      return new Promise(resolve => {
        try {
          this.template = JSON.parse(this.questions[0].text)

          if (this.template?.before_template) {
            this.beforeHtml += this.generateQuestion(this.template?.before_template)
          }

          if (this.template?.after_template) {
            this.afterHtml += this.generateQuestion(this.template?.after_template)
          }
        } catch {
          this.answers = {}
          const question = this.questions[0].text
          this.fullHtml = this.generateQuestion(question)
        } finally {
          resolve()
        }
      })
    },
    validate() {
      const keys = Object.keys(this.answers)
      for (let i = 0; i < keys.length; i += 1) {
        if (this.answers[keys[i]] === '' && keys[i] !== 'subtitle-0-0') return false
      }
      return true
    },
    submit() {
      if (this.autoPlay) {
        this.$emit('submitQuestion', { lang_level: this.questions[0].lang_level, answer: this.answers })
      } else {
        this.$emit('submitQuestion', {
          lang_level: this.level, answer: this.selectedAnswers,
        })
      }
    },
    setValidationClasses() {
      const answers = document.querySelectorAll('.answer')
      answers.forEach((answer, index) => {
        let isCorrect
        if (this.validation.length) isCorrect = this.validation[index]?.is_correct
        else isCorrect = this.previewAnswers[index]?.is_correct
        answer.classList.add(`answer-${isCorrect}`)
      })
    },
    clearValidationClasses() {
      const answers = document.querySelectorAll('.answer')
      answers.forEach(answer => {
        if (answer.classList.includes('answer-0')) answer.classList.remove('answer-0')
        if (answer.classList.includes('answer-1')) answer.classList.remove('answer-1')
      })
    },
    changeInputSize() {
      const questions = document.querySelectorAll('.answer-input')
      const [{ answers }] = this.questions
      const selectedAnswers = [...answers]
      selectedAnswers.sort((a, b) => a.id - b.id)
      questions.forEach((question, index) => {
        const maxSize = selectedAnswers[index]?.text?.length
        /* eslint-disable no-param-reassign */
        question.style.width = maxSize < 5 ? `${maxSize + 2.2}vw` : `${maxSize / 1.2 + 1.2}vw`
      })
    },
  },
}
</script>

<style lang="scss">
.inline-p {
  display: inline-block;
}
.answer-1 {
  border: 2px solid #2eb82e !important;
}
.answer-0 {
  border: 2px solid #df0000 !important;
}
.fill-row {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.text-selection {
  .selectable-answer{
    cursor: pointer;
    /* color: #3498db !important; */
    text-decoration: underline;
    transition: all 200ms ease-in-out;
  }
  .selectable-answer:hover {
    text-decoration: none;
  }
  .text-content p {
    line-height: 1.5;
  }
  .correct-answer {
    color: green !important;
  }
  .incorrect-answer {
    color: red !important;
  }
}
</style>
