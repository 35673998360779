<template>
  <b-modal
    v-if="showModal"
    id="modal-teacher-rubrics"
    ref="modal-teacher-rubrics"
    v-model="showModal"
    ok-only
    size="md"
    ok-variant="primary"
    :ok-title="$t('confirm')"
    modal-class="modal-primary"
    centered
    :title="`${$t('student-diary-module.rubrics')}`"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <div>
      <teacher-rubric-create-edit
        :rubric="rubricBeingEdited"
        @onRubricUpdated="$emit('refreshRubrics')"
        @clear="clearRubric"
      />
      <teacher-rubric-table
        :rubrics="rubrics"
        @onEditRubric="editRubric"
        @onDeleteRubric="(data)=>idOfRubricBeingDeleted=data"
      />
      <delete-modal
        name="modal-delete-teacher-rubric"
        :on-delete="deleteRubric"
        :title="$t('student-diary-module.delete-rubric-title')"
        :sub-title="$t('student-diary-module.delete-rubric-question')"
        :is-processing="isProcessing"
        :show="idOfRubricBeingDeleted!=null"
        @close="idOfRubricBeingDeleted=null"
      />
    </div>
    <template #modal-footer="{}">
      <span />
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import TeacherRubricTable from './TeacherRubricTable.vue'
import TeacherRubricCreateEdit from './TeacherRubricCreateEdit.vue'

export default {
  components: {
    BModal, TeacherRubricTable, TeacherRubricCreateEdit, DeleteModal,

  },
  directives: {
    Ripple,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    rubrics: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rubricBeingEdited: null,
      self: getUserData(),
      idOfRubricBeingDeleted: null,
      rubricForSelect: [],
      isProcessing: false,
      showModal: false,
    }
  },

  watch: {
    show() {
      this.showModal = this.show
    },
  },
  methods: {
    clearRubric() {
      this.rubricBeingEdited = null
    },
    editRubric(data) {
      this.rubricBeingEdited = data
    },
    deleteRubric() {
      this.isProcessing = true
      useJwt.deleteTeacherRubric(this.idOfRubricBeingDeleted).then(res => {
        this.idOfRubricBeingDeleted = null
        this.showSuccessMessage(res)
        this.$emit('refreshRubrics')
      }).finally(() => {
        this.isProcessing = false
      }).catch(error => {
        this.showError(error)
      })
    },
  },
}
</script>
