<template>
  <b-modal
    id="report-issue-modal"
    :title="$t('issue-report-module.report-an-issue')"
    :ok-title="$t('submit')"
    :cancel-title="$t('labels.cancel')"
    @ok="submitIssue"
    @hide="image = null"
  >
    <p class="text-secondary">
      * {{ $t('issue-report-module.required-fields') }}
    </p>

    <validation-observer ref="reportIssue">
      <b-overlay :show="isProcessing">
        <!-- Title -->
        <b-form-group :label="`${$t('issue-report-module.title')} *`">
          <validation-provider
            v-slot="{ errors }"
            name="title"
            rules="required"
          >
            <b-form-input
              v-model="form.title"
              type="text"
              :placeholder="$t('issue-report-module.title')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Description -->
        <b-form-group :label="`${$t('issue-report-module.description')} *`">
          <validation-provider
            v-slot="{ errors }"
            name="description"
            rules="required"
          >
            <b-form-textarea
              v-model="form.description"
              rows="5"
              :placeholder="$t('issue-report-module.description')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Error Type -->
        <b-form-group :label="`${$t('issue-report-module.error-type')} *`">
          <validation-provider
            v-slot="{ errors }"
            name="error type"
            rules="required"
          >
            <b-form-select
              v-model="errorType"
              :options="errorTypeOptions"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Video Link -->
        <b-form-group :label="$t('issue-report-module.video-link')">
          <b-form-input
            v-model="form.video_link"
            type="text"
            placeholder="https://youtu.be/kDT-SPq-RqM"
          />
        </b-form-group>

        <!-- Image -->
        <b-form-group :label="$t('issue-report-module.image')">
          <b-form-file
            v-model="form.image"
            accept="image/*"
            :browse-text="$t('browseButton')"
            :placeholder="$t('issue-report-module.choose-a-file')"
            @change="previewImage()"
          />
        </b-form-group>

        <!-- Image Preview -->
        <div
          v-if="image"
          class="img-wrapper"
        >
          <img
            :src="image"
            alt=""
          >
        </div>
      </b-overlay>
    </validation-observer>
  </b-modal>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BModal, BFormGroup, BFormInput, BFormTextarea, BFormFile, BOverlay, BFormSelect,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BOverlay,
    BFormSelect,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    userId: {
      type: Number,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      errorType: null,
      image: null,
      errorTypeOptions: [
        { text: 'Select an Error Type', value: null, disabled: true },
        'Grammar', 'Spelling Mistake', 'System Error', 'Other',
      ],
      form: [],
      isProcessing: false,
    }
  },
  methods: {
    previewImage() {
      setTimeout(() => {
        if (this.form.image) {
          const reader = new FileReader()
          reader.readAsDataURL(this.form.image)

          reader.onload = event => {
            this.image = event.target.result
          }
        }
      }, 100)
    },
    submitIssue(bvModal) {
      /* eslint-disable prefer-destructuring */
      const image = this.image
      bvModal.preventDefault()
      this.$refs.reportIssue.validate()
        .then(success => {
          if (success) {
            this.isProcessing = true
            const params = {
              ...this.form,
              error_type: this.errorType,
              reported_by: this.userId,
              status: 'pending',
            }
            params.image = image
            useJwt.storeIssues([params]).then(response => {
              this.showSuccessMessage(response)
              this.$emit('add-issues', params)
            }).finally(() => {
              this.isProcessing = false
              this.form = []
              this.$bvModal.hide('report-issue-modal')
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
.img-wrapper {
  width: 100%;
  img {
    width: 100%;
    object-fit: 'cover';
  }
}
</style>
