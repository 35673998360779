<template>
  <div
    :style="{
      position: 'relative',
      height: adjustable && height + 'px',
      backgroundColor: settings && settings.background,
      ...borderRadius
    }"
  >
    <div
      class="resizer mb-2"
    >
      <template v-if="isEditMode && adjustable">
        <b-button
          v-b-tooltip.hover.v-info
          variant="flat-secondary"
          class="btn-icon text-white"
          title="Increase height"
          @click="height = height + 20"
          @mousedown="addHeight"
          @mouseup="removeEvent"
          @mouseleave="removeEvent"
        >
          <feather-icon
            icon="PlusIcon"
            size="15"
            role="button"
          />
        </b-button>
        <b-button
          v-b-tooltip.hover.v-info
          variant="flat-secondary"
          class="btn-icon text-white"
          title="Decrease Height"
          @click="reduceHight"
          @mousedown="removeHeight"
          @mouseup="removeEvent"
          @mouseleave="removeEvent"
        >
          <feather-icon
            icon="MinusIcon"
            size="15"
            role="button"
          />
        </b-button>
        <b-button
          v-b-tooltip.hover.v-info
          variant="flat-secondary"
          class="btn-icon text-white"
          title="Remove Layout"
          @click="$emit('handleRemove')"
        >
          <feather-icon
            icon="XIcon"
            size="15"
            role="button"
          />
        </b-button>
      </template>
      <b-button
        v-if="isEditMode"
        v-b-tooltip.hover.v-info
        variant="flat-secondary"
        class="btn-icon text-white"
        title="Setting for layout"
        @click="showSetting = true"
      >
        <feather-icon
          icon="SettingsIcon"
          size="15"
          role="button"
        />
      </b-button>
    </div>
    <div :style="{ ...getTransformation(settings.alignment), position: adjustable && 'absolute',width: '100%' }">
      <slot />
    </div>
    <layout-setting
      :settings="settings"
      :show="showSetting"
      :disabled-features="getDisabledFeatures"
      :enabled-features="getEnabledFeatures"
      @updateLayout="value => $emit('updateLayout', value)"
      @close="showSetting = false"
    />
  </div>
</template>
<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import LayoutSetting from '../components/LayoutSetting.vue'

const MIN_HEIGHT = 400
export default {
  components: {
    BButton,
    LayoutSetting,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    defaultHeight: {
      type: Number,
      default: MIN_HEIGHT,
    },
    adjustable: {
      type: Boolean,
      default: true,
    },
    settings: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {
      height: this.defaultHeight,
      events: null,
      showSetting: false,
    }
  },
  computed: {
    getDisabledFeatures() {
      if (!this.adjustable) {
        return ['background', 'border-radius']
      }
      return []
    },
    getEnabledFeatures() {
      if (!this.adjustable) {
        return ['textColor']
      }
      return ['alignment']
    },
    borderRadius() {
      return {
        borderTopLeftRadius: `${this.settings?.borderTopLeftRadius}px`,
        borderTopRightRadius: `${this.settings?.borderTopRightRadius}px`,
        borderBottomLeftRadius: `${this.settings?.borderBottomLeftRadius}px`,
        borderBottomRightRadius: `${this.settings?.borderBottomRightRadius}px`,
      }
    },
  },
  watch: {
    defaultHeight: {
      handler(value) {
        this.height = value
      },
      immediate: true,
    },
  },
  methods: {
    removeEvent() {
      this.$emit('updateHeight', this.height)
      clearInterval(this.events)
    },
    addHeight() {
      // eslint-disable-next-line no-return-assign
      this.events = setInterval(() => this.height += 20, 100)
    },
    reduceHight() {
      this.height -= 20
      // if (this.height > MIN_HEIGHT) {
      //   this.height -= 20
      // }
    },
    removeHeight() {
      // eslint-disable-next-line no-return-assign
      this.events = setInterval(() => {
        this.reduceHight()
      }, 100)
    },
    getTransformation(alignment) {
      switch (alignment) {
        case 'top':
          return {
            top: '0%',
            transform: 'translateY(0%)',
          }
        case 'middle':
          return {
            top: '50%',
            transform: 'translateY(-50%)',
          }
        case 'bottom':
          return {
            top: '100%',
            transform: 'translateY(-100%)',
          }
        default:
          return {
            top: '0%',
            transform: 'translateY(0%)',
          }
      }
    },
  },
}
</script>
<style scoped>
  .resizer {
    position: absolute;
    right: 0;
    z-index: 1000;
    margin-right: 10px;
    margin-right: 10px;
    margin-top: -30px;
    background: #00000082;
  }
</style>
