<template>
  <b-nav-item
    :to="{name:'chat'}"
    @click="totalUnseen=0"
  >
    <feather-icon
      size="21"
      icon="MessageSquareIcon"
      :badge="totalUnseen"
      class="text-primary"
      badge-classes="bg-danger"
    />
  </b-nav-item>
  <!-- <router-view @get-total-unseen="getTotalUnseen" /> -->
</template>

<script>
import { BNavItem } from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BNavItem,
  },
  computed: {
    totalUnseen: {
      get() {
        return store.state['app-chat'].totalUnseen
      },
      set(value) {
        store.commit('app-chat/SET_TOTAL_UNSEEN', value)
      },
    },
  },
  mounted() {
    this.$store.dispatch('app-chat/fetchTotalUnseen')
    this.$store.dispatch('app-chat/listenForEvents')
  },
}
</script>
