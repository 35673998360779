<template>
  <div class="w-100 reporting-table">
    <b-table-simple id="individualStudentTable"
                    striped
                    :sticky-header="false"
    >
      <b-thead class="">
        <b-tr style="background: #2a2e3021;">
          <b-th>
            {{ $t('labels.lesson') }}
          </b-th>
          <template v-if="showThead && !hideLessonDetail">
            <b-th v-if="reportType === 'regular'"
                  class="text-center"
            >
              {{ $t('student-report-module.lesson-table.cefr-rate') }}
            </b-th>
            <b-th v-if="reportType === 'regular'">
              {{ $t('individual-report-module.skill_score') }}
            </b-th>
            <b-th>
              {{ $t('individual-report-module.problems') }}
            </b-th>
            <b-th>
              {{ $t('individual-report-module.pass_score') }}
            </b-th>
            <b-th>
              {{ $t('student-report-module.total-time') }}
            </b-th>
            <b-th>
              {{ $t('engine-page.points') }}
            </b-th>
            <b-th>
              {{ $t('actions.status') }}
            </b-th>
            <b-th data-html2canvas-ignore="true">
              {{ $t('messages.tableHeader.action') }}
            </b-th>
          </template>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="report,index in reportData">
          <b-tr
            :key="index"
          >
            <b-th colspan="100%"
                  style="background: #fafafc"
            >
              <div class="d-flex justify-content-between">
                <div>
                  <span class="badge badge-success text-white">{{ $t('course-completion-module.course-report') }} : </span>
                  {{ report.course_name }}
                  <template v-if="showHideSummaryScore">
                    | {{ $t('individual-report-module.course_competition') }} = {{ number_format(getLessonCompetition(report), 2) }} %
                    | {{ $t('individual-report-module.avg_pass_score') }} = {{ number_format(getAverage(report, 'pass_score'), 2) }} %
                  </template>
                </div>
                <div class="text-right">
                  <b-button class="btn-icon"
                            variant="flat-primary"
                            @click="() => {
                              report.showSummary = !report.showSummary;
                              report.visible = false;
                              report.showGraph = false;
                              showThead = report.showSummary? false : true;
                            }"
                  >
                    <feather-icon :icon="report.showSummary ? 'MinusIcon' : 'PlusIcon'" /> {{ $t('student-report-module.summary-per-lesson') }}
                  </b-button>
                  <b-button class="btn-icon"
                            variant="flat-primary"
                            @click="() => {
                              report.visible = !report.visible;
                              report.showGraph = false;
                              report.showSummary = false;
                              showThead = true;
                            }"
                  >
                    <feather-icon :icon="report.visible ? 'MinusIcon' : 'PlusIcon'" /> {{ $t('student-report-module.show-detail') }}
                  </b-button>
                  <b-button
                    v-if="!hideCourseGraph"
                    class="btn-icon"
                    variant="flat-primary"
                    @click="() => {
                      report.showGraph = !report.showGraph;
                      report.visible = false;
                      report.showSummary = false;
                      showThead = report.showGraph? false : true;
                    }"
                  >
                    <feather-icon :icon="report.showGraph ? 'MinusIcon' : 'PlusIcon'" /> {{ $t('student-report-module.show-graph') }}
                  </b-button>
                  {{ report.lessons.length }} {{ $t('class-module.lessons') }}
                </div>
              </div>
            </b-th>
          </b-tr>
          <template v-if="report.visible">
            <template v-for="lessonGroup, groupId in getLessonGroupWiseReport(report.lessons)">
              <b-tr :key="groupId">
                <b-th colspan="100%"
                      style="background: #fafafc"
                >
                  <span class="badge badge-secondary">{{ $t('lesson-group-report') }} :</span>
                  {{ lessonGroup.name }}
                  <template v-if="showHideSummaryScore">
                    | {{ $t('individual-report-module.status.in_progress') }} = {{ lessonGroup.statuses.in_progress ?? 0 }}
                    | {{ $t('individual-report-module.status.not_done') }} ={{ lessonGroup.statuses['Not Started Yet'] ?? 0 }}
                    | {{ $t('individual-report-module.status.failed') }} ={{ lessonGroup.statuses.failed ?? 0 }}
                    | {{ $t('individual-report-module.status.passed') }} = {{ lessonGroup.statuses.passed ?? 0 }}
                    | {{ $t('course-completion-module.table-header.cefr-rate') }} = {{ lessonGroup.avgCefrRate }}
                    | {{ $t('average-time') }} = {{ lessonGroup.avgTotalTime }}
                    | {{ $t('individual-report-module.avg_pass_score') }} = {{ lessonGroup.avgPassScore }}
                  </template>
                </b-th>
              </b-tr>
              <b-tr :key="`graphReport-${groupId}`">
                <b-td colspan="100%">
                  <LessonGroupGraphReport :group-id="groupId" />
                </b-td>
              </b-tr>
              <template v-if="!hideLessonDetail">
                <b-tr v-for="lesson,j in lessonGroup.lessons"
                      :key="j"
                >
                  <b-td>
                    {{ lesson.lesson_name }}
                    <div v-if="lesson.completed_at">
                      <small>
                        <i>{{ $t('student-report-module.lesson-table.completed_at') }} {{ FORMAT_DATE(lesson.completed_at, 'MMM Do YYYY') }}</i>
                      </small>
                    </div>
                    <div v-else>
                      <div>
                        <small>
                          <i>{{ $t('labels.due-date') }} {{ FORMAT_DATE(lesson.due_date, 'MMM Do YYYY') }}</i>
                        </small>
                      </div>
                      <small><i>{{ lesson.status }}</i></small>
                    </div>
                  </b-td>

                  <b-td v-if="reportType === 'regular'">
                    <div class="font-weight-bold text-center">
                      <div>
                        <span
                          class="badge text-white"
                          :style="{ background: getColorForLevel(lesson.lang_level), padding: '6px' }"
                        >{{
                          lesson.lang_level === "invalid" ? "-" : getLangLevelTransText(lesson.lang_level)
                        }}</span>
                      </div>
                      <div class="mt-1">
                        {{ number_format(lesson.average, 2) }} %
                      </div>
                    </div>
                  </b-td>
                  <b-td
                    v-if="reportType === 'regular'"
                    style="min-width:250px;"
                  >
                    <div class="text-left mt-1 row">
                      <div v-for="score,j in lesson.skills_score"
                           :key="j"
                           :set="icon =getSkillIcon(score) "
                           class="col-6 mb-1 text-nowrap "
                      >
                        <feather-icon :icon="icon.icon"
                                      :class="icon.color"
                        />  {{ number_format(score.cefr_rate, 2) }}
                      </div>
                    </div>
                  </b-td>

                  <b-td class="col-1">
                    <div class="d-flex justify-content-between">
                      <div>
                        {{ lesson.passed_problems }}
                        <div>
                          <feather-icon icon="CheckIcon"
                                        class="text-success"
                          />
                        </div>
                      </div>
                      <div>
                        {{ lesson.failed_problems }}
                        <div>
                          <feather-icon icon="XIcon"
                                        class="text-danger"
                          />
                        </div>
                      </div>
                    </div>
                  </b-td>
                  <b-td>
                    {{ number_format(lesson.pass_score, 2) }}
                  </b-td>
                  <b-td>
                    {{ lesson.total_time }}
                  </b-td>
                  <b-td>
                    {{ lesson.points }}
                  </b-td>
                  <b-td>
                    <div>
                      <span class="badge"
                            :class="getBadgeColorByStatus(lesson.status).color"
                      >
                        {{ getBadgeColorByStatus(lesson.status).text }}
                      </span>
                    </div>
                    <div class="mt-1">
                      <span class="badge"
                            :class="getBadgeColorByStatus(lesson.homework_due_status).color"
                      >
                        <!--{{ capitalizeFirstLetter(getBadgeColorByStatus(lesson.homework_due_status).text) }}-->
                        {{ getBadgeColorByStatus(lesson.homework_due_status).text }}
                      </span>
                    </div>
                  </b-td>
                  <b-td data-html2canvas-ignore="true">
                    <b-button variant="flat-primary"
                              class="btn-icon"
                              @click="$emit('openThirdLevelReport', lesson.loader_id)"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                  </b-td>
                </b-tr>
              </template>
            </template>
          </template>
          <template v-else-if="report.showGraph && !hideCourseGraph">
            <b-tr :key="index + '_graph'">
              <b-td colspan="100%">
                <PassScore :lessons="report.lessons" />
              </b-td>
            </b-tr>
          </template>
          <template v-else-if="report.showSummary">
            <b-tr :key="`${report.course_id}-summary`">
              <b-td colspan="9">
                <summary-per-lesson
                  v-if="summaryGraph[report.course_id]"
                  :key="`${report.course_id}-summary`"
                  :summary-graph="summaryGraph[report.course_id]"
                />
                <p v-else
                   class="mb-0 text-center"
                >
                  {{ $t(('student-report-module.no-records-to-display')) }}
                </p>
              </b-td>
            </b-tr>
          </template>
          <b-tr v-if="showHideSummary"
                :key="report.course_id"
                class="bg-dark text-white"
          >
            <b-th>{{ $t('student-report-module.summary') }}</b-th>
            <b-td
              v-if="reportType === 'regular'"
              class="font-weight-bold text-white"
              :set="lessonAverage = getLessonAverage(report.lessons, 'average')"
            >
              <span
                class="badge text-white mr-2"
                :style="{ background: getColorForLevel(getLevelFromScore(lessonAverage)), padding: '6px' }"
              >{{
                getLevelFromScore(lessonAverage) === "invalid" ? "-" : getLangLevelTransText(getLevelFromScore(lessonAverage))
              }}</span>
              {{ number_format(lessonAverage, 2) }}%
            </b-td>
            <b-td v-if="reportType === 'regular'">
              -
            </b-td>
            <b-td>
              <div class="d-flex justify-content-between text-white">
                <div>
                  {{ number_format(getLessonSum(report.lessons, 'passed_problems'), 0) }}
                </div>
                <div>
                  {{ number_format(getLessonSum(report.lessons, 'failed_problems'), 0) }}
                </div>
              </div>
            </b-td>
            <b-td class="text-white">
              {{ number_format(getLessonAverage(report.lessons, 'pass_score'), 2) }}
            </b-td>
            <b-td class="font-weight-bold text-white">
              {{ getTotalTime(report.lessons) }}
            </b-td>
            <b-td class="text-white">
              {{ number_format(getLessonSum(report.lessons, 'points'), 0) }}
            </b-td>
            <b-td class="text-white">
              -
            </b-td>
            <b-td class="text-white">
              -
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import {
  BTableSimple, BTr, BTh, BTd, BButton, BThead, BTbody,
} from 'bootstrap-vue'
import { getColorForLevel, getLevelFromScore } from '@/const/langLevel'
import i18n from '@/libs/i18n'
import {
  isVisible,
} from '@/utils/visibilitySettings'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_STUDENT } from '@/const/userType'
import PassScore from './PassScore.vue'
import SummaryPerLesson from './SummaryPerLesson.vue'
import LessonGroupGraphReport from './LessonGroupReportGraph/index.vue'

export default {
  components: {
    BTableSimple, BTr, BTh, BTd, BButton, BThead, BTbody, PassScore, SummaryPerLesson, LessonGroupGraphReport,
  },
  props: {
    reportData: {
      type: Array,
      default: () => [],
    },
    getLessonCompetition: {
      type: Function,
      required: true,
    },
    getAverage: {
      type: Function,
      required: true,
    },
    summaryGraph: {
      type: Object,
      default: () => {},
    },
    reportType: {
      type: String,
      default: () => 'regular',
    },
  },
  data() {
    return {
      getColorForLevel,
      getLevelFromScore,
      showThead: true,
    }
  },
  computed: {
    isAStudent() {
      return getUserData() && getUserData().usertype === USER_TYPE_STUDENT
    },
    hideCourseGraph() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('course_graph', schoolSetting, distSetting)
    },
    hideLessonDetail() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('lesson_details', schoolSetting, distSetting)
    },
    showHideSummaryScore() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const summaryScore = isVisible('summary_score', schoolSetting, distSetting)
      if (this.isAStudent && !summaryScore) {
        return false
      }
      return true
    },
    showHideSummary() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const summaryScore = isVisible('summary', schoolSetting, distSetting)
      if (this.isAStudent && !summaryScore) {
        return false
      }
      return true
    },
  },
  methods: {
    getBadgeColorByStatus(status) {
      switch (status) {
        case 'passed':
        case 'early':
          return {
            color: 'badge-success',
            text: i18n.t(`individual-report-module.status.${status}`),
          }
        case 'failed':
        case 'late':
        case 'not_done':
          return {
            color: 'badge-danger',
            text: i18n.t(`individual-report-module.status.${status}`),
          }
        case 'in_progress':
        case 'on_time':
          return {
            color: 'badge-warning',
            text: i18n.t(`individual-report-module.status.${status}`),
          }
        default: {
          return {}
        }
      }
    },
    getLessonAverage(lessons, key) {
      const sum = lessons.reduce((p, c) => p + Number(c[key]), 0)
      return sum / lessons.length
    },
    getLessonSum(lessons, key) {
      const sum = lessons.reduce((p, c) => p + Number(c[key]), 0)
      return sum
    },
    timestrToSec(timestr) {
      if (!timestr) {
        return 0
      }
      const parts = timestr?.split(':')
      return (parts[0] * 3600)
            + (parts[1] * 60)
            + (+parts[2])
    },
    formatTime(seconds) {
      function pad(num) {
        if (num < 10) {
          return `0${num}`
        }
        return `${num}`
      }
      return [pad(Math.floor(seconds / 3600)),
        pad(Math.floor(seconds / 60) % 60),
        pad(seconds % 60),
      ].join(':')
    },
    getTotalTime(lessons) {
      let initialTime = this.timestrToSec('00:00:00')
      lessons.forEach(item => {
        initialTime += this.timestrToSec(item.total_time)
      })
      return this.formatTime(initialTime)
    },
    getSkillIcon(value) {
      switch (value.skill_type) {
        case 'listening':
          return {
            icon: 'Volume2Icon',
            color: 'text-warning',
          }
        case 'reading':
          return {
            icon: 'BookOpenIcon',
            color: 'text-success',
          }
        case 'writing':
          return {
            icon: 'PenToolIcon',
            color: 'text-primary',
          }
        case 'speaking':
          return {
            icon: 'MicIcon',
            color: 'text-danger',
          }
        default:
          return ''
      }
    },
    getLessonGroupWiseReport(lessons) {
      const lessonsArray = Object.values(lessons)
      const lessonGroupReport = lessonsArray.reduce((acc, lesson) => {
        const groupId = lesson?.lesson_group_id
        const status = lesson?.status

        if (!acc[groupId]) {
          acc[groupId] = {
            total: 0,
            statuses: {},
            // totalCefrRate: 0,
            // totalSkills: 0,
            totalAverage: 0,
            name: lesson?.lesson_group_name,
            lessons: [],
            totalPassScore: 0,
            totalTime: 0,
            avgPassScore: 0,
            avgTotalTime: 0,
          }
        }
        acc[groupId].lessons.push(lesson)
        acc[groupId].total += 1

        if (!acc[groupId].statuses[status]) {
          acc[groupId].statuses[status] = 0
        }
        acc[groupId].statuses[status] += 1

        if (status === 'passed') {
          acc[groupId].totalAverage += lesson.average
          // acc[groupId].total += 1
        }
        acc[groupId].avgCefrRate = (acc[groupId].totalAverage) ? `${(acc[groupId].totalAverage / acc[groupId].statuses.passed).toFixed(2)} %` : '0.0%'
        // Accumulate total pass score
        acc[groupId].totalPassScore += parseFloat(lesson.pass_score)
        acc[groupId].avgPassScore = (acc[groupId].totalPassScore / acc[groupId].total).toFixed(2)

        // Convert total_time to seconds and accumulate
        acc[groupId].totalTime += this.timeToSeconds(lesson.total_time)

        acc[groupId].avgTotalTime = acc[groupId].total > 0 ? this.secondsToTime(Math.floor(acc[groupId].totalTime / acc[groupId].total)) : '00:00:00'

        return acc
      }, {})
      return lessonGroupReport
    },

    // Helper function to convert HH:MM:SS to seconds
    timeToSeconds(time) {
      const [hours, minutes, seconds] = time.split(':').map(Number)
      return hours * 3600 + minutes * 60 + seconds
    },

    // Helper function to convert seconds to HH:MM:SS
    secondsToTime(seconds) {
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      const secs = seconds % 60
      return [hours, minutes, secs].map(v => String(v).padStart(2, '0')).join(':')
    },
  },
}
</script>
<style lang="scss">
.reporting-table{
  height:75vh;
  overflow:scroll;
  table{
    thead{
      th{
      position:sticky;
      top:0;
      z-index:999;
    }
    }

  }
}
</style>
