<template>
  <b-row>
    <b-col md="4">
      <b-card class="h-100">
        <h3>{{ $t('student-report-module.lesson-studied') }}</h3>
        <apexchart v-if="expectedReport && actualReport"
                   type="radialBar"
                   height="400"
                   :options="lessonDoneReport.chartOptions"
                   :series="lessonDoneReport.series"
        />
      </b-card>
    </b-col>
    <b-col md="8">
      <b-card class="h-100">
        <b-row>
          <b-col class="summary-post"
                 cols="6"
          >
            <h4>
              {{ $t('student-report-module.total-studying-time') }}
            </h4>
            {{ getTotalTime }}
          </b-col>
          <b-col cols="6"
                 class="summary-post"
          >
            <h4>
              {{ $t('student-report-module.average-per-study-time') }}
            </h4>
            {{ SECOND_TO_TIME(averagePreStudyTime.toFixed(0)) }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6"
                 class="summary-post"
          >
            <h4>
              {{ $t('student-report-module.average-studying-time') }}
            </h4>
            {{ SECOND_TO_TIME(averageTotalStudyTime.toFixed(0)) }}
          </b-col>
          <b-col cols="6"
                 class="summary-post"
          >
            <h4>
              {{ $t('student-report-module.average-post-study-time') }}
            </h4>
            {{ SECOND_TO_TIME(averagePostStudyTime.toFixed(0)) }}
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import i18n from '@/libs/i18n'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    apexchart: VueApexCharts,
  },
  props: {
    expectedReport: {
      type: Object,
      default: null,
    },
    actualReport: {
      type: Object,
      default: null,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    averageStudyTime: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    averageTotalStudyTime() {
      return (this.averagePreStudyTime + this.averagePostStudyTime) / 2
    },
    averagePreStudyTime() {
      return +this.averageStudyTime.find(item => item.type === 'homework')?.average_study_time || 0
    },
    averagePostStudyTime() {
      return +this.averageStudyTime.find(item => item.type === 'follow_up')?.average_study_time || 0
    },
    lessonDoneReport() {
      const percentage = ((this.actualReport?.total_lesson / this.expectedReport?.total_lessons) * 100).toFixed(2)
      return {
        chartOptions: {
          chart: {
            type: 'radialBar',
          },
          colors: ['#4CBB18'],
          labels: [i18n.tc('student-report-module.lesson-studied')],
        },
        series: [percentage],
      }
    },
    getTotalTime() {
      const times = []
      this.tableData.forEach(item => {
        item.lessons.forEach(k => {
          if (k.total_time) {
            times.push(k.total_time)
          }
        })
      })
      return this.addMultipleTimesAndGetTotalTime(times)
    },
  },
}
</script>
<style>
  .summary-post {
    text-align: center;
    height: calc(100vh - 950px);
    margin-top: 60px;
    margin-bottom: 60px;
  }
</style>
