<template>
  <swiper :options="swiperOption"
          class="ebook-vocab-card"
  >
    <swiper-slide v-for="chunk,idx of vocabChunks"
                  :key="idx"
    >
      <div class="row w-100"
           :class="{
             'pb-2': canHaveChunk,
             'justify-content-center': isNoteOpen
           }"
      >
        <div
          v-for="(vocab, index) in chunk"
          :key="index"
          :class="isNoteOpen ? 'col-md-5': 'col-md-6'"
          style="margin-bottom: 30px"
          :style="`margin-top:${index >= 2 ? '30px' : '0'}`"
        >
          <div class="list-style">
            <div class="list-style-top">

              <WordWithPopover
                :id="`vocab-${index}-${idx}`"
                :text="vocab.word_in_eng"
                :with-slot="true"
                :word-power-enabled="true"
                class="mb-0 cursor-pointer on-hover-library"
              >
                <template #default="{ formattedText }">
                  <p v-html="formattedText" />
                </template>
              </WordWithPopover>
            </div>
            <div class="list-style-bottom">
              <p>{{ vocab.synonym_1 }} <br>{{ vocab.synonym_2 }}</p>
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
    <div
      slot="pagination"
      class="swiper-pagination"
    />
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import _ from 'lodash'
import useJwt from '@/auth/jwt/useJwt'
import locales from '@/const/locales'
import 'swiper/css/swiper.css'
import WordWithPopover from '@/views/common/templates/components/WordWithPopover.vue'

export default {
  components: {
    Swiper,
    WordWithPopover,
    SwiperSlide,
  },
  props: {
    vocabs: {
      type: Array,
      default: () => [],
    },
    isNoteOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      perChunks: 6,
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: false,
        },
      },
    }
  },
  computed: {
    vocabChunks() {
      return _.chunk(this.vocabs, this.perChunks)
    },
    canHaveChunk() {
      return this.vocabs.length > this.perChunks
    },
  },
  methods: {
    getImageLibrary(id) {
      const index = this.vocabs.findIndex(i => i.imageLibraryId === id)
      const locale = localStorage.getItem('locale')
      const language = locales.find(l => l.locale === locale).value
      if (!this.vocabs[index].data.id) {
        this.isLoading = true
        useJwt.getImageLibraryById(this.vocabs[index].imageLibraryId, {
          params: {
            word: this.vocabs[index].word_in_eng,
            exact: true,
          },
        }).then(response => {
          const vocabWord = this.vocabs.find(vocab => vocab.word_in_eng?.toLowerCase() === response.data.data.vocab_word?.toLowerCase())
          const res = response.data.data
          const matchingVersion = res?.other_version?.find(otherVersion => otherVersion.lang === language)?.lang
          vocabWord.data = response.data.data
          vocabWord.selectedLang = matchingVersion
        }).finally(() => {
          this.isLoading = false
        })
      }
    },
    closePopover(index, idx) {
      this.$refs[`popoverRef-${index}-${idx}`][0].$emit('close')
    },
  },
}
</script>

<style lang="scss">
 .w-480 {
  max-width: 480px !important;
}
.top-0 {
  top: -8px;
}
.right-0 {
  right: -12px;
}
.list-style-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
}
.list-style-wrapper p {
  font-weight: 600;
}
.list-style {
  text-align: center;
  width: 100%;
  float: left;
}
.list-style-top  {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background: #29658f;
  padding: 10px;
}
.list-style-bottom {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background: #e8937d;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 1px;
}
.list-style-top .clickable-word {
  font-size: 16px;
  line-height: normal;
  margin-bottom: 0px;
}
.list-style-bottom p {
  color: #000000;
}
.list-style-2 {
  float: right;
}
.list-style-box-2 {
  margin-top: 60px;
  margin-bottom: 60px;
}
.swiper-pagination-bullets {
  bottom: 0px;
}
</style>
