export default {
  // Endpoints
  apiEndPoint: '/api/v1',
  loginEndpoint: '/api/login',
  guestLoginEndpoint: '/api/v1/guest-login',
  registerEndpoint: '/api/register',
  refreshEndpoint: '/api/refresh-token',
  logoutEndpoint: '/api/v1/logout',
  verifyEmailEndpoint: '/api/v1/verify-email',
  forgotPasswordEndpoint: '/api/forgot-password',
  resetPasswordEndpoint: '/api/reset-password',
  resendVerifyEmail: '/api/v1/resend-verify-email',
  meShow: '/api/v1/me/show',
  meUpdate: '/api/v1/me/update',
  changePassword: '/api/v1/change-password',
  avatarUpdate: '/api/v1/user/avatar/update',
  setLanguage: '/api/v1/user/language/update',
  usersEndpoint: '/api/v1/users',
  childUsersEndpoint: '/api/v1/user/type',
  attachChildUsersEndpoint: '/api/v1/user/link/parent',
  subUserEndpoint: '/api/v1/subusers',
  userEndpoint: '/api/v1/user',
  childUserEndpoint: '/api/v1/user/children/types',
  listStudentsEndpoint: '/api/v1/school?user=student',
  listTeachersEndpoint: '/api/v1/school?user=teacher',
  coursesEndpoint: '/api/v1/course',
  getAllLessonsEndpoint: '/api/v1/get-all-lesson',
  classEndpoint: '/api/v1/classroom',
  classRubricEndpoint: '/api/v1/rubric',
  studentRubricEndpoint: '/api/v1/rubriclists',
  lessonsEndpoint: '/api/v1/lesson',
  questionEndpoint: '/api/v1/question',
  answerEndpoint: '/api/v1/answer',
  answerCorrectionEndpoint: '/api/v1/answer-correction-update',
  lessonGroupEndpoint: '/api/v1/lesson-group',
  inviteParentEndpoint: '/api/v1/student/parent/invite',
  teacherCourseEndpoint: '/api/v1/teacher/course',
  teacherProfileEndpoint: '/api/v1/teacher/profile',
  profileEndpoint: '/api/v1/profile',
  networkEndpoint: '/api/v1/network',
  lessonPagesEndpoint: '/api/v1/lesson-pages',
  joinRequestEndpoint: '/api/v1/user/request/join/network',
  requestAcceptEndpoint: '/api/v1/request/accept',
  requestRejectEndpoint: '/api/v1/request/reject',
  articleEndpoint: '/api/v1/article',
  changeFeaturedArticle: '/api/v1/article/feature/update',
  getMyInvitationsEndpoint: '/api/v1/user/requests/my',
  cancelRequestEndoint: '/api/v1/request/cancel',
  problemEndpoint: '/api/v1/problem',
  checkEndpoint: '/api/v1/check',
  copyProblemEndpoint: '/api/v1/copy/problem',
  criteria: '/api/v1/criteria',
  studentRating: '/api/v1/user/rating',
  rating: '/api/v1/rating',
  statementEndpoint: '/api/v1/statement',
  notificationEndpoint: '/api/v1/notification',
  commonEndpoint: '/api/v1/common',
  // game
  gameEndpoint: '/api/v1/game-wrapper',
  gecPartnerEndPoint: '/api/v1/gec-partners',

  article: '/api/v1/article',
  grade: '/api/v1/grades',
  gradeLists: '/api/v1/grades-lists',
  subject: '/api/v1/subject',
  standard: '/api/v1/standard',
  standardSubject: '/api/v1/standard-subject',
  getDomainStandard: '/api/v1/get-domain-standard',
  domainStandard: '/api/v1/domain-standard',
  standardGrade: '/api/v1/standard-grade',
  gradeSubject: '/api/v1/grade/subjects',
  gradeStandardSubject: '/api/v1/grade/standard/subjects',
  domain: '/api/v1/domain',
  getDomainByLesson: '/api/v1/lesson',
  calendarEventEndpoint: '/api/v1/calendar-event',
  calendarEventTemplateEndpoint: '/api/v1/calendar-event-template',
  studentEventEndpoint: '/api/v1/student/events',
  allLessonsEndpoint: '/api/v1/lesson/lists',
  problemVisibilityEndpoint: '/api/v1/change/visibility',
  cefrScopeEndpoint: '/api/v1/change/cefr_scope',
  studentActivitiesEndpoint: '/api/v1/student-activities',
  getStudentActivitiesEndpoint: '/api/v1/get-all-student-activities',
  studentAttentionApi: '/face-score/api',
  feedbackTimeEndpoint: '/api/v1/fb-time/update',
  studentAttendanceEndpoint: '/api/v1/student-attendance',
  // dashboard api
  studentDashboardEndpoint: '/api/v1/student/dashboard',
  studentEndpoint: '/api/v1/student',

  classStatsEndpoint: '/api/v1/stats/room',
  // Chat room APi
  chatRoomEndpoint: '/api/v1/chat-room',
  chatGroupRoomEndpoint: '/api/v1/group-all-message',
  // Total Unseen
  totalUseenMsgs: '/api/v1/total-unseen-message',
  // Vibes
  vibes: '/api/v1/vibes',

  // Message Typing
  messageTyping: '/api/v1/user-typing',

  updateOrderEndpoint: '/api/v1/change/order',
  standardGradeEndpoint: '/api/v1/standard-grade',
  adaptiveLearningEndpoint: '/api/v1/adaptive-learning-active',

  // curriculumn
  curriculumPagesEndpoint: '/api/v1/curriculum-pages',
  wordsForParagraphEndPoint: '/api/v1/word-selection',

  // Wordnet
  getWordNetEndpoint: '/api/v1/wordnet-data',
  storeWordNetEndpoint: '/api/v1/get-wordnet',
  getWordsForPage: '/api/v1/get-words',
  setWordNetForPage: '/api/v1/update-wordnet',
  deleteWordNet: '/api/v1/delete-wordnet',
  getTranslatedWords: '/api/v1/fetchUMN',

  // WOrdent for Course
  getCourseWordnet: '/api/v1/get-course-wordnet',
  getCourseWordnetArray: '/api/v1/get-words-course-wordnet',
  storeCourseWordnet: '/api/v1/course-wordnet',
  deleteCourseWordnet: '/api/v1/delete-course-wordnet',
  getCourseWordsForPage: '/api/v1/get-course-wordnet-frontend',
  setCourseWordNet: '/api/v1/update-course-wordnet',

  ajaxAutoComplete: 'https://beta.globalenglishcampus.com/ajaxautocomplete',
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  problemTemplatesEndpoint: '/api/v1/templates',
  problemVideoEndpoint: '/api/v1/video',
  problemVideoTimestampEndpoint: '/api/v1/video-time',
  answerPreviewEndpoint: '/api/v1/preview',

  lessonTypeEndpoint: '/api/v1/usertype',

  notesEndpoint: '/api/v1/note',
  notesCommentEndPoint: '/api/v1/notes-comments',
  notesByLesson: '/api/v1/notes-by-lesson',
  notesByProblem: '/api/v1/notes-by-problem',
  hintsEndpoint: '/api/v1/hints',
  generateProblemsEndpoint: '/api/v1/generate/problems',
  pageMetaEndpoint: '/api/v1/page-meta',
  vocabEndpoint: '/api/v1/vocab',
  updateCurriculumPagesApi: '/api/v1/update-curriculum-pages',
  curriculumPageSetEndpoint: '/api/v1/page/set',
  curriculumEndpoint: '/api/v1/curriculum',
  essayEndpoint: '/api/v1/essay',

  siteMetaEndpoint: '/api/v1/site-meta',

  // Hotspot Endpoints
  hotspotEndpoint: '/api/v1/hotspot',
  hotspotPointEndpoint: '/api/v1/hotspotpoint',
  sendNotificationsEndpoint: '/api/v1/send-notifications',
  updateUserSettingsEndpoint: '/api/v1/user/meta',

  // Permissions Endpoint
  permissionsEndpoint: '/api/v1/permission',
  rolesEndpoint: '/api/v1/roles',
  imageLibraryEndpoint: '/api/v1/image-library',
  failedOnprogressImageLibrary: '/api/v1/failed-onprogress-image-library',
  imageLibraryBulkUploadEndpoint: '/api/v1/image-library-bulk-upload',
  // Courses For Classroom Endpoints
  courseForClassEndpoint: '/api/v1/classroom/courses',
  classCourseEndpoint: '/api/v1/class',
  // Points Settings
  pointsSettingEndpoint: '/api/v1/points-settings',
  rewardEndpoint: '/api/v1/reward',

  // Student Character Endpoint
  studentCharacterEndpoint: '/api/v1/user/character',
  studentCharacterUpdateEndpoint: '/api/v1/user/update-character',
  // For Multiple Filter Course and Lessons
  getCoursesFromSubjectEndpoint: '/api/v1/subject/courses',
  getGradeStandardSubjectEndpoint: '/api/v1/grade/standard/subjects',
  getStandardLessons: '/api/v1/subject/lessons',
  getStandardOfDomains: '/api/v1/domain/standard',
  demoStudentLabProblemEndPoint: 'api/v1/demo/problem',
  getDemoEndpoint: '/api/v1/demo',
  teacherSchedularEndpoint: '/api/v1/teacher-schedular',

  earningsEndpoint: '/api/v1/earning/rewards',
  globalSettingEndpoint: '/api/v1/global-settings',
  paringEndpoint: '/api/v1/paring',
  gameEndPoint: '/api/v1/game',
  realTimeEndPoint: '/api/v1/realtime',
  personalityEndpoint: '/api/v1/personality',
  studentPersonalityEndpoint: '/api/v1/student-personality',
  shareEndPoint: '/api/v1/share',
  sdpLessonEndPoint: '/api/v1/sdp',
  scormEndPoint: '/api/scorm',
  libraryEndPoint: '/api/v1/library',
  animationTemplateEndPoint: '/api/v1/animation-template',
  problemSetsEndPoint: '/api/v1/problem-sets',
  verfiyWorkzoneLogin: '/api/ljdflkjsdflk/',

  issuesEndPoint: '/api/v1/issues',
  reservationEndPoint: '/api/v1/reservations',

  marketPlaceEndPoint: '/api/v1/marketplaces',

  animatedItemsEndpoint: '/api/v1/animated-items',
  animatedItemsTradingEndpoint: '/api/v1/animated-items/trading',
  calibratedReportEndPoint: '/api/v1/calibrated-report/classroom',
  higherLevelReportingEndPoint: '/api/v1/higher-level-report',

  //   Classroom for school
  classRoomEndpoint: '/api/v1/classroom/user',

  //   School Endpoints
  schoolSettingsEndpoint: '/api/v1/school/settings',

  //   User Permission
  userPermissionEndpoint: '/api/v1/user-permission',

  //  Lesson Planner
  lessonPlannerEndpoint: '/api/v1/lesson_planners',

  aiIdeEndPoint: '/api/v1/ai',

  lessonLinkEndpoints: '/api/v1/lesson-links',

  // video
  videosEndpoint: '/api/v1/help-videos',

  thirdPartyEndpoint: '/api/v1/third-party-accounts',

  paymentApiEndPoint: '/api/v1/payments',

  // assets
  assetsEndpoint: '/api/v1/asset',

  // follow up
  followUpEndpoint: '/api/v1/follow-up',
  noteTemplateEndPoint: '/api/v1/note-template',

  // Lesson Mapper
  lessonMapperEndpoint: '/api/v1/lesson-mapper',

  // Lesson wise scorm
  lessonsScormEndpoint: '/api/v1/lesson-scorm',
  localeEndPoint: '/api/v1/locale',

  automateProblemEndPoint: '/api/v1/problem-automate',
  // detail report
  detailReportEndpoint: '/api/v1/detail-report',

  // conversational mode student lab
  conversationalMode: '/api/v1/conversational-mode',

  vocabEventEndPoint: '/api/v1/vocab_event',

  // vocab classroom endpoint
  vocabClassRoomEndPoint: '/api/v1/classroom/vocab',

  // vocab words endpoint
  vocabWordEndPoint: '/api/v1/vocab-words',

  cbBatchEndPoint: '/api/v1/cb-batch-upload',
  // datablock endpoint
  datablockEndpoint: 'https://api.datablockai.com/api/v1',

  cefrFormulaEndPoint: '/api/v1/cefr-formula',

  lessonWordEndPoint: '/api/v1/lesson-words',

  lessonPromptEndPoint: '/api/v1/lesson-prompt',

  progressReportEndPoint: '/api/v1/progress-report',

  labMetaEndPoint: '/api/v1/lab-meta',

  reaAssignmentEndPoint: '/api/v1/rea-assignment',
}
