<template>
  <div>
    <article class="article">
      <div class="article__body">
        <section class="header">
          <span class="header__cat">Details</span>
          <h1 class="header__title">
            {{ marketplace.title }}
          </h1>
        </section>
        <section class="other-details d-flex justify-content-between align-items-center">
          <h4>
            $ {{ marketplace.price }}
          </h4>
          <h6 v-if="!DATE_IS_PASSED(marketplace.end_time)">
            Ends {{ FROM_NOW(marketplace.end_time) }}
          </h6>
          <h6 v-else>
            Ended Already
          </h6>
        </section>

        <section class="text-block rich-text"
                 v-html="marketplace.description "
        />
        <div class="text-right py-1">
          <b-button v-if="marketplace.showUrl"
                    variant="primary"
          >
            <a :href="marketplace.url"
               target="__blank"
               class="text-white"
            >
              Visit Link
            </a>
          </b-button>
        </div>
      </div>

      <div class="article__image">
        <div class="article__image-wrapper">
          <img :src="marketplace.image"
               alt=""
          >
        </div>
      </div>
    </article>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,

  },
  data() {
    return {
      marketplace: {},
    }
  },
  computed: {
    marketId() {
      return this.$route.params.marketId
    },
  },
  mounted() {
    this.fetchMarket()
  },
  methods: {
    fetchMarket() {
      useJwt.showMarketPlace(this.marketId).then(res => {
        this.marketplace = res.data
      })
    },
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Prata&display=swap');

:root {
  --f-headline: 'Prata', serif;
  --f-body: 'Open Sans', sans-serif;

  --c-primary: #e24630;
  --c-darkest: #4c4f55;
  --c-lightest: #ffffff;
}

.article {
  display: flex;
  align-items: flex-start;
  min-height: 100vh;
}

.article__body {
  width: 50%;
  padding: 2vh 2%;
  max-width: 70rem;
  margin-left: auto;
  margin-right: auto;
}

.article__image {
  position: sticky;
  top: 0;
  width: 50%;
}

.article__image-wrapper {
    position: relative;
    min-height: 100vh;
}

.article__image-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header {
    margin-bottom: 1rem;
}

.other-details{
  margin-bottom: 5rem;
}

.header__cat {
    display: block;
    text-transform: uppercase;
    font-size: 1.1rem;
    margin-bottom: 1rem;
    letter-spacing: 0.1rem;
    font-family: var(--f-body);
    font-weight: bold;
    opacity: .6;
}

.header__title {
    font-size: 3.2rem;
    font-family: var(--f-headline);
    color: var(--c-primary);
    line-height: 1.15;
}

</style>
