<template>
  <b-col
    cols="12"
    order="3"
    class="profile_grade_subject"
  >
    <div>
      <b-tabs
        pills
        vertical
        class="pt-1"
      >
        <b-tab
          v-for="(gd, index) in gradeData"
          :key="gd.id"
          :title="gd.name"
          :active="index === 0"
          @click="getSubjects(gd.id)"
        >
          <b-card-text>
            <b-card no-body>
              <b-card-body class="p-0">
                <div>
                  <b-table
                    striped
                    responsive
                    :fields="['subject', 'score']"
                    :items="
                      subjects.map((sub) => ({
                        subject: sub.name,
                        score: sub.score,
                      }))
                    "
                  >
                    <template
                      #cell(subject)="data"
                      class="d-flex"
                    >
                      <b-img
                        src="@/assets/images/icons/education.png"
                        width="16"
                        height="16"
                      />
                      <span class="pl-1">{{ data.item.subject }}</span>
                    </template>
                  </b-table>
                </div>
              </b-card-body>
            </b-card>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </div>
  </b-col>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BCol, BTabs, BTab, BCard, BCardText, BCardBody, BTable, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BTabs,
    BTab,
    BCard,
    BCardText,
    BCardBody,
    BTable,
    BImg,
  },
  props: {
    gradeData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      subjects: [],
    }
  },
  watch: {
    gradeData(value) {
      this.getSubjects(value[0].id)
    },
  },
  created() {
    this.getSubjects(this.gradeData[0].id)
  },
  methods: {
    getSubjects(id) {
      useJwt
        .getSubjectsOfGrade(id)
        .then(res => {
          this.subjects = res.data.data.map(subject => ({
            id: subject.id,
            name: subject.name,
            score: subject.score,
          }))
        })
    },
  },
}
</script>

<style lang="scss">
.profile_grade_subject {
  .tabs ul {
    @media (max-width: 480px) {
      flex-direction: row !important;
    }
  }
}
</style>
