<template>
  <div class="download-page d-flex align-items-center justify-content-center">
    <b-container fluid
                 class="p-0"
    >
      <b-row class="justify-content-center m-0">
        <b-col lg="6"
               md="8"
               sm="10"
               class="text-center"
        >
          <!-- App Logo -->
          <div class="app-logo mb-4">
            <feather-icon icon="SmartphoneIcon"
                          size="48"
                          class="text-primary"
            />
          </div>

          <!-- Title and Description -->
          <h1 class="display-4 text-primary font-weight-bold mb-3">
            Download Our App
          </h1>
          <p class="lead text-body mb-5">
            Experience the power of our app on your mobile device.
            <br>Available for both iOS and Android platforms.
          </p>

          <!-- Download Buttons -->
          <div class="download-buttons d-flex justify-content-center gap-4 flex-wrap">
            <!-- App Store Button -->
            <b-button
              variant="primary"
              class="download-btn ios-btn d-flex align-items-center px-4 py-3 mb-3"
              :href="iosLink"
              target="_blank"
            >
              <div class="btn-icon">
                <feather-icon icon="PackageIcon"
                              size="32"
                />
              </div>
              <div class="btn-text text-left">
                <small class="d-block">Download on the</small>
                <strong class="font-weight-bold">App Store</strong>
              </div>
            </b-button>

            <!-- Play Store Button -->
            <b-button
              variant="primary"
              class="download-btn android-btn d-flex align-items-center px-4 py-3 mb-3"
              :href="androidDownload"
              target="_blank"
            >
              <div class="btn-icon">
                <feather-icon icon="PlayIcon"
                              size="32"
                />
              </div>
              <div class="btn-text text-left">
                <small class="d-block">Get it on</small>
                <strong class="font-weight-bold">Google Play</strong>
              </div>
            </b-button>
          </div>

          <!-- QR Code Section -->
          <div class="qr-section mt-0 d-flex justify-content-center gap-5 flex-wrap">
            <!-- iOS QR Code -->
            <b-card
              class="qr-wrapper ios-qr p-3 bg-white"
              no-body
            >
              <b-img
                src="@/assets/images/qr/ios.png"
                alt="iOS QR Code"
                class="img-fluid mb-2"
              />
              <p class="small text-muted mb-0">
                Scan for iOS
              </p>
            </b-card>

            <!-- Android QR Code -->
            <b-card
              class="qr-wrapper android-qr p-3 bg-white"
              no-body
            >
              <b-img
                src="@/assets/images/qr/android.png"
                alt="Android QR Code"
                class="img-fluid mb-2"
              />
              <p class="small text-muted mb-0">
                Scan for Android
              </p>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BButton,
  BCard,
  BImg,
} from 'bootstrap-vue'

export default {
  name: 'DownloadPage',

  components: {
    BContainer,
    BRow,
    BCol,
    BButton,
    BCard,
    BImg,
  },

  data() {
    return {
      iosLink: 'https://apps.apple.com/np/app/gec-web-mobile-app/id6740242456',
      androidDownload: 'https://edu.globalenglishcampus.com/storage/gec-app.apk',
      // Add your data properties here if needed
    }
  },

  methods: {
    // Add your methods here if needed
  },
}
</script>

  <style lang="scss" scoped>
  .download-page {
    min-height: 100vh;
    background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
    padding-top: 0;
    padding: 2rem 0;

    .app-logo {
      .feather-icon {
        filter: drop-shadow(0 4px 12px rgba(115, 103, 240, 0.3));
      }
    }

    .download-btn {
      transition: all 0.3s ease;
      border-radius: 16px;
      min-width: 220px;
      border: none;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      gap: 1rem;

      .btn-icon {
        background: rgba(255, 255, 255, 0.1);
        padding: 0.5rem;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
      }

      &.ios-btn {
        background: linear-gradient(145deg, #007bff 0%, #0056b3 100%);

        &:hover {
          background: linear-gradient(145deg, #0056b3 0%, #004085 100%);
          .btn-icon {
            background: rgba(255, 255, 255, 0.2);
          }
        }
      }

      &.android-btn {
        background: linear-gradient(145deg, #01875f 0%, #00684a 100%);

        &:hover {
          background: linear-gradient(145deg, #019668 0%, #015c42 100%);
          .btn-icon {
            background: rgba(255, 255, 255, 0.2);
          }
        }
      }

      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
      }

      .btn-text {
        small {
          opacity: 0.9;
          font-size: 0.75rem;
          font-weight: 400;
        }

        strong {
          font-size: 1.1rem;
          letter-spacing: 0.5px;
          display: block;
          margin-top: 2px;
        }
      }
    }

    .qr-wrapper {
      transition: all 0.3s ease;
      border: 2px solid rgba(115, 103, 240, 0.1);
      backdrop-filter: blur(10px);
      width: 340px;
      border-radius: 16px;

      .qr-platform-icon {
        background: rgba(0, 0, 0, 0.05);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        transition: all 0.3s ease;
      }

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 25px rgba(115, 103, 240, 0.15);

        .qr-platform-icon {
          background: rgba(0, 0, 0, 0.1);
        }
      }

      img {
        width: 250px;
        height: 200px;
        object-fit: contain;
      }
    }

    // Custom spacing utilities
    .gap-4 {
      gap: 1.5rem;
    }

    .gap-5 {
      gap: 2rem;
    }

    // Responsive adjustments
    @media (max-width: 576px) {
      .display-4 {
        font-size: 2.5rem;
      }

      .download-btn {
        min-width: 200px;
      }

      .qr-section {
        gap: 1rem !important;
      }

      .qr-wrapper {
        width: 160px;

        img {
          width: 120px;
          height: 120px;
        }
      }
    }
  }

  // Dark mode styles
  .dark-layout {
    .download-page {
      background: linear-gradient(135deg, #283046 0%, #1e1e1e 100%);

      .qr-wrapper {
        background: #283046;
        border-color: rgba(115, 103, 240, 0.2);

        .qr-platform-icon {
          background: rgba(255, 255, 255, 0.1);
        }

        &:hover .qr-platform-icon {
          background: rgba(255, 255, 255, 0.15);
        }
      }

      .text-body {
        color: #b4b7bd !important;
      }

      .text-muted {
        color: #676d7d !important;
      }
    }
  }
  </style>
