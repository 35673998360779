<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="9">
          <b-form-group :label="$t('upload-file')">
            <b-form-file
              ref="fileUploader"
              v-model="zip"
              :browse-text="$t('browseButton')"
              :placeholder="$t('issue-report-module.choose-a-file')"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-button
            variant="primary"
            :disabled="!zip || isProcessing"
            style="position: absolute;margin-bottom: 16px;bottom: 0;"
            @click="uploadFile"
          >
            <b-spinner
              v-show="isProcessing"
              small
            /> {{ $t('actions.submit') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BFormGroup, BFormFile, BButton, BCard, BSpinner, BRow, BCol,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'

export default {
  components: {
    BFormGroup, BFormFile, BButton, BCard, BSpinner, BRow, BCol,
  },
  data() {
    return {
      zip: null,
      isProcessing: false,
    }
  },
  methods: {
    uploadFile() {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
      const data = new FormData()
      if (this.zip) data.append('zip', this.$refs.fileUploader.files[0] || null)
      this.isProcessing = true
      useJwt.axiosIns.post(`${jwtDefaultConfig.scormEndPoint}/upload`, data, config).then(response => {
        this.showSuccess('Scorm Updated')
        this.zip = null
        this.$emit('reFetch', response.data.data.selected)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
