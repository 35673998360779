/* eslint-disable import/no-cycle */
import isAuthenticated from '@/middlewares/isAuthenticated'
import isNotAuthenticated from '@/middlewares/isNotAuthenticated'
// import redirectToHome from '@/middlewares/redirectToHome'
import getLandingPageFor from '@/router/utils'
import { getUserData, isUserLoggedIn } from '@/auth/utils'
import store from '@/store'
import {
  USER_TYPE_PARENT, USER_TYPE_SCHOOL, USER_TYPE_COMPANY, USER_TYPE_SUPER,
} from '@/const/userType'
import i18n from '@/libs/i18n'

i18n.locale = localStorage.getItem('locale') || 'en'
// const usertype = getUserData() ? getUserData().usertype : null

// const hasStudentLabRoute = usertype && (usertype === USER_TYPE_TEACHER || usertype === USER_TYPE_STUDENT)

const commonStudentLabRoute = () => {
  let dynamicRoute = []
  const roles = [USER_TYPE_SCHOOL, USER_TYPE_COMPANY, USER_TYPE_SUPER]
  roles.forEach(usertype => {
    dynamicRoute = [...dynamicRoute, ...[
      {
        path: `/${usertype}/class/:classId/lesson/:id`,
        meta: {
          middleware: [isAuthenticated],
          navActiveLink: `${usertype}-classes`,
        },
        name: `${usertype}-lesson`,
        component: () => import('@/views/student/lesson/Show.vue'),
      },
      {
        path: `/${usertype}/class/:classId/course/:id/lessons`,
        meta: {
          middleware: [isAuthenticated],
          navActiveLink: `${usertype}-classes`,
        },
        name: `${usertype}-lessons`,
        component: () => import('@/views/student/lesson/Index.vue'),
        // eslint-disable-next-line consistent-return
        beforeEnter: (to, from, next) => {
          if (usertype === USER_TYPE_PARENT) {
            return next('/not-authorized')
          }
          return next()
        },
      },
      {
        path: `/${usertype}/class/:classId/lesson/:id/problems`,
        meta: {
          middleware: [isAuthenticated],
          navActiveLink: `${usertype}-classes`,
        },
        name: `${usertype}-problems`,
        component: () => import('@/views/student/problem/Index1.vue'),
      },
      {
        path: `/${usertype}/view-attendance/:id?`,
        name: `${usertype}-view-attendance`,
        meta: {
          middleware: [isAuthenticated],
          navActiveLink: `${usertype}-classes`,
        },
        component: () => import('@/views/student/attendance/index.vue'),
      },
      {
        path: `/${usertype}/dashboard/:id`,
        meta: {
          middleware: [isAuthenticated],
        },
        name: `student-dashboard-for-${usertype}`,
        component: () => import('@/views/common/dashboard/Index.vue'),
      },
      {
        path: `/${usertype}/student-diary/:id`,
        name: `${usertype}teacher-student-diary`,
        meta: {
          middleware: [isAuthenticated],
        },
        component: () => import('@/views/common/student-diary/Index.vue'),
      },
      {
        path: `/${usertype}/classes/:classId/calendar`,
        name: `${usertype}-class-calendar`,
        meta: {
          middleware: [isAuthenticated],
          navActiveLink: `/${usertype}-classes`,
        },
        component: () => import('@/views/teacher/class/components/calendar/Calendar.vue'),
      },
      {
        path: `/${usertype}/view-student-time`,
        name: `${usertype}-view-student-time`,
        meta: {
          middleware: [isAuthenticated],
          navActiveLink: `${usertype}-view-student-time`,
        },
        component: () => import('@/views/student/timespent/index.vue'),
      },
      {
        path: `/${usertype}/pair/:classId/student`,
        name: `${usertype}-pair-student`,
        meta: {
          middleware: [isAuthenticated],
        },
        component: () => import('@/views/v2/common/class/paring/index.vue'),
      },
      {
        path: `/${usertype}/view-students/:id`,
        name: `${usertype}-view-students`,
        meta: {
          middleware: [isAuthenticated],
          navActiveLink: `${usertype}-classes`,
        },
        component: () => import('@/views/v2/common/class/components/StudentTable.vue'),
      },
      {
        path: `/${usertype}/monitor/:classId?`,
        name: `${usertype}-monitor-class`,
        meta: {
          middleware: [isAuthenticated],
        },
        component: () => import('@/views/v2/common/class/Monitor/index.vue'),
      },
      {
        path: `/${usertype}/class-test/:classId`,
        name: `${usertype}-class-test`,
        meta: {
          middleware: [isAuthenticated],
        },
        component: () => import('@/views/student/lesson/ClassTest/index.vue'),
      },
    ]]
  })
  return dynamicRoute
}

export default [
  {
    path: '/',
    name: 'landing',
    redirect: '/login',
    meta: {
      layout: 'full',
    },
    component: () => import('@/views/common/landing/Index.vue'),
    beforeEnter(to, from, next) {
      store.commit('appConfig/UPDATE_SKIN', 'light')
      if (isUserLoggedIn()) {
        next({ name: getLandingPageFor(getUserData?.usertype) })
      } else next()
    },
  },
  {
    path: '/change-logs',
    name: 'change-logs',
    meta: {
      layout: 'full',
    },
    component: () => import('@/views/common/change-log/Index.vue'),
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    meta: {
      layout: 'full',
    },
    component: () => import('@/views/TOS.vue'),
  },
  {
    path: '/lesson-page/:id',
    name: 'external-lesson-page',
    meta: {
      layout: 'full',
    },
    component: () => import('@/views/super/lesson-link/LessonPageFrame.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    meta: {
      layout: 'full',
    },
    component: () => import('@/views/Privacy.vue'),
  },
  {
    path: '/networks',
    name: 'networks',
    meta: {
      layout: 'guest',
      // middleware: [isNotAuthenticated],
    },
    component: () => import('@/views/common/network/Index.vue'),
  },
  {
    path: '/demo-lesson',
    name: 'demo-lesson',
    meta: {
      layout: 'guest',
      // middleware: [isNotAuthenticated],
    },
    component: () => import('@/views/GuestLesson/index.vue'),
  },
  {
    path: '/ai-demo',
    name: 'ai-demo',
    meta: {
      layout: 'guest',
      // middleware: [isNotAuthenticated],
    },
    component: () => import('@/views/AiDemo/Index.vue'),
  },
  {
    path: '/adv-ai-demo',
    name: 'adv-ai-demo',
    meta: {
      layout: 'full',
      // middleware: [isNotAuthenticated],
    },
    component: () => import('@/views/AiDemo/advance-bot/index.vue'),
  },
  {
    path: '/update-subscription',
    name: 'update-subscription',
    meta: {
      layout: 'guest',
    },
    component: () => import('@/views/subscription/update-plan.vue'),
  },
  {
    path: '/reservation/payment_success',
    name: 'reservation-payment_success',
    meta: {
      layout: 'full',
    },
    component: () => import('@/views/subscription/reservation-payment-success.vue'),
  },
  {
    path: '/reservation/schedule/payment_success',
    name: 'reservation-schedule-payment_success',
    meta: {
      layout: 'full',
    },
    component: () => import('@/views/subscription/reservation-schedule-payment-success.vue'),
  },
  {
    path: '/reservation/update-to-paid/payment_success',
    name: 'reservation-paid-update-payment_success',
    meta: {
      layout: 'full',
    },
    component: () => import('@/views/subscription/reservation-paid-update-success.vue'),
  },
  {
    path: '/reservations',
    name: 'reservation',
    meta: {
      layout: 'guest',
      // middleware: [isNotAuthenticated],
    },
    component: () => import('@/views/reservations/public/index.vue'),
  },

  {
    path: '/reservations/book/:schoolId',
    name: 'reservation',
    meta: {
      layout: 'guest',
      // middleware: [isNotAuthenticated],
    },
    component: () => import('@/views/reservations/public/school-reservation/index.vue'),
  },

  {
    path: '/reservations/:schoolId/:reservationId?',
    name: 'reservation',
    meta: {
      layout: 'guest',
      // middleware: [isNotAuthenticated],
    },
    component: () => import('@/views/reservations/public/ShopifyReservation.vue'),
  },

  {
    path: '/networks/:id',
    name: 'network-show',
    meta: {
      layout: 'guest',
      middleware: [isNotAuthenticated],
    },
    component: () => import('@/views/common/network/Show.vue'),
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings',
    name: 'general-settings',
    component: () => import('@/views/account-setting/AccountSettingGeneral.vue'),
    meta: {
      middleware: [isAuthenticated],
      pageTitle: i18n.tc('actions.general-settings'),
    },
  },

  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('@/views/account-setting/AccountSettingPassword.vue'),
    meta: {
      middleware: [isAuthenticated],
      pageTitle: i18n.tc('actions.change-password'),
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/account-setting/AccountSettingProfile.vue'),
    meta: {
      middleware: [isAuthenticated],
      pageTitle: i18n.tc('navigation-menu.profile'),
    },
  },
  {
    path: '/account-setting',
    name: 'account-setting',
    component: () => import('@/views/account-setting/AccountSetting.vue'),
    meta: {
      middleware: isAuthenticated,
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import('@/views/auth/VerifyEmail.vue'),
    meta: {
      pageTitle: 'Verify email',
      breadcrumb: [
        {
          text: 'Verify email',
          active: true,
        },
      ],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      middleware: [isNotAuthenticated],
      layout: 'full',
    },
    props: { isRegistered: false },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      middleware: [isNotAuthenticated],
      layout: 'full',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      middleware: [isNotAuthenticated],
      layout: 'full',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      middleware: [isNotAuthenticated],
      layout: 'full',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/not-authorized',
    name: 'unauthorized',
    component: () => import('@/views/Unauthorized.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/lessons/:lessonId/pages/:pageId',
    name: 'lesson-pages',
    component: () => import('@/views/common/lessons/PageShow.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/users/:name/profile',
    name: 'guest-user-profile',
    component: () => import('@/views/account-setting/AccountSettingProfile.vue'),
    meta: {
      layout: 'guest',
      middleware: [isNotAuthenticated],
    },
  },
  {
    path: '/user/:name/profile',
    name: 'user-profile',
    component: () => import('@/views/account-setting/AccountSettingProfile.vue'),
    meta: {
      middleware: [isAuthenticated],
    },
  },
  {
    path: '/curriculum/:curriculumId/pages/:pageId',
    name: 'curriculum-pages',
    component: () => import('@/views/common/curriculum/components/CurriculumPageShow.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/classes/:classId/lessons/:lessonId/curriculum/:curriculumId/pages/:pageId',
    name: 'student-curriculum-pages',
    component: () => import('@/views/common/curriculum/components/CurriculumPageShow.vue'),
    meta: {
      middleware: [isAuthenticated],
      layout: 'full',
    },
  },
  {
    path: '/classes/:classId/lessons/:lessonId/curriculum/:curriculumId/essay-builder',
    name: 'essay-builder',
    component: () => import('@/views/student/essay/Index.vue'),
    meta: {
      middleware: [isAuthenticated],
      layout: 'full',
    },
  },
  {
    path: '*',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('@/views/common/chat/Chat.vue'),
    meta: {
      middleware: [isAuthenticated],
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  {
    path: '/call/video',
    name: 'videocall',
    component: () => import('@/views/common/chat/VideoCall.vue'),
    meta: {
      middleware: [isAuthenticated],
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  {
    path: '/send-notifications',
    name: 'send-notifications',
    component: () => import('@/views/common/notifications/Index.vue'),
    meta: {
      middleware: [isAuthenticated],
    },
  },
  {
    path: '/:reward/detail/:uid?',
    name: 'rewards-detail',
    meta: {
      middleware: [isAuthenticated],
    },
    props: true,
    component: () => import('@/views/common/dashboard/RewardDetails.vue'),
  },
  {
    path: '/student-role/:classId',
    name: 'student-roles',
    component: () => import('@/views/v2/common/class/components/StudentScoreBoard.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/student-essay/:classId',
    name: 'student-essay',
    component: () => import('@/views/v2/common/class/class-essay/index.vue'),
  },
  {
    path: '/student-essay/:classId',
    name: 'student-essay',
    component: () => import('@/views/v2/common/class/class-essay/index.vue'),
  },
  {
    path: '/gecBearerToken/callback',
    name: 'student-bearer-login',
    component: () => import('@/views/auth/ProviderLogin.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/downloads',
    name: 'app-downloads',
    meta: {
      layout: 'guest',
    },
    component: () => import('@/views/common/downloads/mobile-app.vue'),
  },
  ...commonStudentLabRoute(),
]
