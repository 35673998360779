import { IDLE, SPEAKING } from '@/const/chatbot'
import useJwt from '@/auth/jwt/useJwt'
import PlayBase64Audio from '@/utils/base64-audio-player'
import { streamAudio } from './streamAudio'

const synth = window.speechSynthesis

export default {
  data() {
    return {
      isProcessing: false,
      speechSynthesisUtterance: null,
      isPlaying: false,
      messages: [],
      base64AudioStopper: null,
    }
  },
  created() {
    setTimeout(() => {
      this.initializeSpeech()
    }, 2000)
    window.onbeforeunload = () => {
      this.stop()
    }
  },
  beforeDestroy() {
    this.stop()
  },
  methods: {
    openChatbotSetting() {
      this.$bvModal.show('bot-setting-modal')
    },
    initializeSpeech() {
      this.stop()
      const speechSynthesisUtterance = new SpeechSynthesisUtterance()
      speechSynthesisUtterance.lang = 'en-US'
      this.speechSynthesisUtterance = speechSynthesisUtterance
      speechSynthesisUtterance.addEventListener('end', () => {
        this.isPlaying = false
      })
    },
    pause() {
      if (synth?.speaking) {
        synth.pause()
        this.isPlaying = false
      }
    },
    stop() {
      if (this.base64AudioStopper) {
        this.base64AudioStopper()
        this.isPlaying = false
        this.isProcessing = false
      }
      if (synth?.speaking) {
        this.isPlaying = false
        synth.cancel()
      }
    },
    async getBase64FromTextAndPlay(message) {
      try {
        let response
        if (this.voiceType === 'open-ai') {
          this.isProcessing = true
          const audioStream = streamAudio(message, '/api/tts-ai-version', () => {
            this.isPlaying = true
          }, () => {
            this.isProcessing = false
            this.isPlaying = false
          })
          this.base64AudioStopper = () => audioStream.stop()
        } else {
          response = await useJwt.convertTextToBase64({
            text: message,
            gender: this.gender?.includes('female') ? 'female' : 'male',
          })
          this.play(response.data.audio_file)
        }
      } catch (err) {
        this.isPlaying = false
        this.showErrorMessage(err)
      }
    },
    play(base64) {
      this.isPlaying = true
      this.isProcessing = false
      const { stopAudio } = PlayBase64Audio(base64, () => {
        this.isPlaying = false
      })
      this.base64AudioStopper = stopAudio
    },
  },
  computed: {
    activeImage() {
      return this.isPlaying ? SPEAKING[this.gender] : IDLE[this.gender]
    },
    gender() {
      return this.$store.getters['appConfig/currentChatbotCharacter']
    },
  },
}
