<template>
  <b-modal :visible="true"
           title="Choose Template"
           no-close-on-backdrop
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <b-overlay :show="state.isProcessing">
      <!-- Tabs inside modal -->
      <b-tabs>
        <b-tab v-for="lang of langTemplateOptions"
               :key="lang.lang_level"
               :title="lang.lang_level"
        >
          <b-form-group :label="`Select Option for version ${lang.lang_level}`">
            <v-select v-model="lang.template"
                      :options="templatesData"
                      label="text"
                      :reduce="i => i.value"
                      @input="handleTemplateChange"
            />
          </b-form-group>
        </b-tab>
      </b-tabs>
    </b-overlay>
    <template #modal-footer>
      <b-button variant="outline-primary"
                @click="$emit('close')"
      >
        {{ $t('labels.ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script setup>
import {
  ref, onMounted, getCurrentInstance, reactive,
} from 'vue'
import {
  BModal, BFormGroup, BTabs, BTab, BButton, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'

const props = defineProps({
  eventId: {
    type: [Number, String],
    default: 0,
  },
})

const emit = defineEmits(['onChange'])

const state = reactive({
  isProcessing: false,
})

const templatesData = ref([])
const root = getCurrentInstance().proxy.$root
const langTemplateOptions = ref([
  {
    lang_level: '4',
    template: null,
  },
  {
    lang_level: '3',
    template: null,
  },
  {
    lang_level: '2',
    template: null,
  },
  {
    lang_level: '1',
    template: null,
  },
])

const getCalendarTemplate = () => {
  useJwt.getCalendarTemplate({
    params: {
      type: 'isolated',
    },
  }).then(response => {
    const { template } = response.data.data
    templatesData.value = template.map(item => ({
      text: item.name,
      value: item.id,
    }))
  }).catch(error => {
    root.showErrorMessage(error)
  })
}

const getEventTemplateInfo = () => {
  state.isProcessing = true
  useJwt.getEventTemplate(props.eventId).then(response => {
    const { data } = response
    if (data.data.length) {
      const newTemplate = data.data.map(it => ({
        lang_level: it.lang_level.toString(),
        template: it.template_id,
      }))
      langTemplateOptions.value = newTemplate
    }
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    state.isProcessing = false
  })
}

const handleTemplateChange = () => {
  emit('onChange', langTemplateOptions.value)
}

onMounted(() => {
  getCalendarTemplate()
  if (props.eventId) {
    getEventTemplateInfo()
  }
})

</script>
