<template>
  <div>
    <div v-if="showFrameError"
         class="error-message"
    >
      Redirecting...
    </div>
    <iframe v-else
            :src="frameLink"
            width="100%"
            style="height: 100vh;"
            frameborder="0"
            scrolling="no"
    />
  </div>
</template>

<script>
export default {
  props: {
    frameLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showFrameError: false,
    }
  },
  created() {
    this.checkFrameAccess()
  },
  methods: {
    checkFrameAccess() {
      fetch(this.frameLink)
        .then(response => {
          if (!response.ok) {
            this.showFrameError = true
            window.open(this.frameLink, '_self')
          }
        })
        .catch(() => {
          this.showFrameError = true
          window.open(this.frameLink, '_self')
        })
    },
  },
}
</script>

  <style>
  .error-message {
    padding: 20px;
    background-color: #ffcccc;
    border: 1px solid #ff0000;
  }
  </style>
