const DECISION_TREE = {
  EXTRA_VERSION: [
    'Warm',
    'Open',
    'Leader',
    'Relaxed',
    'Distracted',
    'Liberal',
    'Tolerant',
  ],
  INTRO_VERSION: [
    'Formal',
    'Private',
    'Follower',
    'Stressed',
    'Focused',
    'Conservative',
    'Strict',
  ],
  LOW_ANXIETY: [
    'Warm',
    'Open',
    'Follower',
    'Relaxed',
    'Distracted',
    'Liberal',
    'Tolerant',
  ],
  HIGH_ANXIETY: [
    'Formal',
    'Private',
    'Leader',
    'Intense',
    'Focused',
    'Conservative',
    'Detailed',
  ],
  RECEPTIVE: [
    'Warm',
    'Open',
    'Follower',
    'Relaxed',
    'Distracted',
    'Liberal',
    'Tolerant',
  ],
  TOUGH_MINDED: [
    'Formal',
    'Private',
    'Leader',
    'Stressed',
    'Focused',
    'Conservative',
    'Strict',
  ],
  ACCOMMODATING: [
    'Warm',
    'Open',
    'Follower',
    'Relaxed',
    'Distracted',
    'Liberal',
    'Tolerant',
  ],
  INDEPENDENT: [
    'Formal',
    'Private',
    'Leader',
    'Stressed',
    'Focused',
    'Conservative',
    'Strict',
  ],
  UNRESTRAINED: [
    'Warm',
    'Open',
    'Follower',
    'Relaxed',
    'Distracted',
    'Liberal',
    'Tolerant',
  ],
  SELF_CONTROLLED: [
    'Formal',
    'Private',
    'Leader',
    'Stressed',
    'Focused',
    'Conservative',
    'Strict',
  ],
}

const getPersonalityRank = (personalities, type) => {
  let personalityA = []
  let personalityB = []
  let typeA = ''
  let typeB = ''
  switch (type) {
    case 'extra_version': {
      personalityA = DECISION_TREE.EXTRA_VERSION
      personalityB = DECISION_TREE.INTRO_VERSION
      typeA = 'Extraversion'
      typeB = 'Introversion'
      break
    }
    case 'anxiety': {
      personalityA = DECISION_TREE.LOW_ANXIETY
      personalityB = DECISION_TREE.HIGH_ANXIETY
      typeA = 'Low Anxiety'
      typeB = 'High Anxiety'
      break
    }
    case 'tough_minded': {
      personalityA = DECISION_TREE.RECEPTIVE
      personalityB = DECISION_TREE.TOUGH_MINDED
      typeA = 'Receptive'
      typeB = 'Tough Minded'
      break
    }
    case 'independence': {
      personalityA = DECISION_TREE.ACCOMMODATING
      personalityB = DECISION_TREE.INDEPENDENT
      typeA = 'Accommodating'
      typeB = 'Independent'
      break
    }
    case 'self_control': {
      personalityA = DECISION_TREE.UNRESTRAINED
      personalityB = DECISION_TREE.SELF_CONTROLLED
      typeA = 'Unrestrained'
      typeB = 'Self Controlled'
      break
    }
    default: {
      return []
    }
  }
  const countA = personalityA.filter(person => personalities.includes(person)).length
  const countB = personalityB.filter(person => personalities.includes(person)).length
  return [
    {
      name: typeA,
      checked: !!(countA > 0 && countA > countB),
    },
    {
      name: typeB,
      checked: !!(countB > 0 && countB > countA),
    },
  ]
}

export {
  getPersonalityRank,
  DECISION_TREE,
}
