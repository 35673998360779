<template>
  <b-card class="sidebar">
    <!-- Sidebar Header -->
    <b-card-header class="p-0 mb-2">
      <b-card-title>{{ $t('courses-module.courses') }}</b-card-title>
      <feather-icon
        :icon="searchBarIcon"
        class="search-bar-icon cursor-pointer"
        :class="`${searchBarIcon=='XIcon'?'mr-1':''}`"
        @click="toggleSearchBar"
      />

      <!-- Search Bar -->
      <b-form-input
        ref="search"
        v-model="searchTerm"
        :style="`width: ${searchBarWidth}; ${searchBarIcon=='SearchIcon'?'border:none':''}`"
        class="search pl-2"
        type="text"
        :placeholder="$t('messages.SearchPlaceholder')"
        :disabled="!searchBarWidth"
        @input="debounceSearch"
      />
    </b-card-header>

    <!-- Sidebar Contents -->
    <!-- By Grades -->
    <courses-by
      id="grades"
      :title="$t('by-grades')"
      :items="grades"
      :is-processing="isProcessing"
      :default-selection="defaultSelection"
      :expanded-type="defaultType"
      :selected-type="selectedType"
      @getData="getData"
      @getCourses="getCourses"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BFormInput,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import _ from 'lodash'
import CoursesBy from './CoursesBy.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BFormInput,
    CoursesBy,
  },
  data() {
    return {
      grades: null,
      searchBarWidth: 0,
      isProcessing: false,
      searchBarIcon: 'SearchIcon',
      defaultSelection: null,
      defaultType: '',
      selectedType: 'grades',
      searchTerm: '',
    }
  },
  mounted() {
    this.getData('grades')
  },
  methods: {
    getData(id) {
      this.defaultType = this.defaultType === id ? null : id
      switch (id) {
        case 'grades':
          if (!this.grades) {
            this.isProcessing = true
            this.getGrades()
          }
          break
        default:
          break
      }
    },
    getGrades() {
      useJwt.getStandardGrades({ per_page: 999 }).then(response => {
        const res = response.data?.data
        this.grades = res.data
        this.defaultSelection = this.grades[0].id
      }).finally(() => {
        this.isProcessing = false
        if (this.$route.params.data) {
          if (this.$route.params.data.searchTerm) {
            this.searchTerm = this.$route.params.data.searchTerm
            this.$nextTick(() => {
              this.searchBarWidth = '100%'
              this.searchBarIcon = 'XIcon'
              this.search()
            })
          } else this.getCourses('grades', this.$route.params.data.grade)
        }
      })
    },
    toggleSearchBar() {
      this.searchTerm = ''
      this.searchBarWidth = isNaN(+this.searchBarWidth) ? 0 : '100%'
      this.searchBarIcon = isNaN(+this.searchBarWidth) ? 'XIcon' : 'SearchIcon'
    },
    getCourses(type, id) {
      if (isNaN(+this.searchBarWidth)) this.toggleSearchBar()
      this.selectedType = type
      const subjects = this.grades.find(grade => grade.id === id)
        .subjects.map(subject => subject.id)
      this.$nextTick(() => {
        this.$emit('selected-grade', id)
        this.$emit('getCourses', type, subjects)
      })
    },
    debounceSearch: _.debounce(function () {
      this.search()
    }, 800),
    search() {
      useJwt.searchCourses(this.searchTerm).then(response => {
        this.$emit('searchResult', response.data?.data, this.searchTerm)
        this.selectedType = 'search'
        this.defaultSelection = null
      })
    },
  },
}
</script>

<style scoped>
.sidebar {
  height: 85vh;
  overflow-y: scroll;
}
.card {
  overflow-x: hidden;
}
.card-header {
  position: relative;
}
.search {
  position: absolute;
  right:0px;
  top: -8px;
  transition: 0.5s;
  z-index: 1;
}
.search-bar-icon {
  z-index: 10;
}
.close {
  position: absolute;
  top: 12px;
  right: 0;
  z-index: 1;
}
.card-title {
  font-size: 16px;
}
.not-collapsed {
  font-weight: 700;
}
</style>
