<template>
  <b-modal :visible="true"
           title="Edit Meta"
           size="lg"
           :hide-footer="isFetchingMeta"
           @close="$emit('close')"
           @hide="$emit('close')"
  >

    <b-overlay :key="updateHook"
               :show="isFetchingMeta"
    >
      <div v-if="generatedWord"
           class="card text-center p-2"
      >
        <GeneratedWord :word="generatedWord"
                       :image-selected="generatedWordHandler.imageSelected"
                       :set-word-selector-audio="generatedWordHandler.setWordSelectorAudio"
        />
      </div>
      <div v-else>
        <editor v-if="isHtml"
                v-model="editableContent"
        />
        <BFormTextarea v-else
                       v-model="editableContent"
        />
      </div>

    </b-overlay>
    <template #modal-footer>
      <div>
        <b-button variant="secondary"
                  @click="$emit('close')"
        >
          Cancel
        </b-button>
        <b-button class="ml-1"
                  variant="success"
                  :disabled="state.isProcessing"
                  @click="handleUpdate"
        >
          <b-spinner v-if="state.isProcessing"
                     small
          /> Submit
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script setup>
import {
  BModal, BButton, BSpinner, BFormTextarea, BOverlay,
} from 'bootstrap-vue'
import {
  ref, reactive, computed, getCurrentInstance, onMounted,
} from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import EventBus from '@/utils/eventBus'
import Editor from '@views/common/components/QuillEditorCustom.vue'
import GeneratedWord from '@/views/common/curriculum/components/Tools/GeneratedWord.vue'
import store from '@/store'

const root = getCurrentInstance().proxy.$root

const props = defineProps({
  metaId: {
    type: Number,
    required: true,
  },
  content: {
    type: String,
    required: true,
  },
  metaType: {
    type: String,
    default: '',
  },
})

const updateHook = ref(0)
const isFetchingMeta = ref(false)
const editableContent = ref(props.content)
const pageMeta = ref(null)
const generatedWord = ref(null)
const state = reactive({
  isProcessing: false,
})

const emits = defineEmits(['close', 'updateText'])
const elementToCheck = 'p'
const regex = new RegExp(`<${elementToCheck}(\\s|>)`, 'i')
const isHtml = ref(regex.test(props.content))

const isCbEditMode = computed(() => store.state.studentLab?.isCbEditMode)

const handleCbUpdate = () => {
  state.isProcessing = true
  if (generatedWord.value) {
    useJwt.updateLabGeneratedWord(generatedWord.value.id).then(response => {
      root.showSuccessMessage(response)
      EventBus.$emit('reAfterCBEditedMeta')
    }).catch(error => {
      root.showErrorMessage(error)
    }).finally(() => {
      state.isProcessing = false
    })
  } else {
    useJwt.updateLabMetaInfo(pageMeta.value.id, {
      content: editableContent.value,
      voice: root.$route.query.voice,
    }).then(response => {
      root.showSuccessMessage(response)
      EventBus.$emit('reAfterCBEditedMeta')
    }).catch(error => {
      root.showErrorMessage(error)
    }).finally(() => {
      state.isProcessing = false
    })
  }
}

const handleUpdate = () => {
  if (isCbEditMode.value) {
    handleCbUpdate()
    return
  }
  state.isProcessing = true
  useJwt.updateEbookBookContent(props.metaId, {
    content: editableContent.value,
  }).then(response => {
    EventBus.$emit('refetchPageData')
    emits('close')
    root.showSuccessMessage(response)
  }).catch(error => {
    root.showErrorMessage(error)
  })
    .finally(() => {
      state.isProcessing = false
    })
}

onMounted(async () => {
  if (isCbEditMode.value) {
    isFetchingMeta.value = true
    const response = await useJwt.getLabMetaInfo({
      params: {
        problem_id: store.state.problem.activeProblem.id,
        meta_type: props.metaType,
        lang_level: root.$route.query.lang_level,
        mode: root.$route.query.layout,
        content: props.content,
      },
    })
    updateHook.value += 1
    if (response.data.data.type === 'GW') {
      generatedWord.value = response.data.data.meta
    } else {
      pageMeta.value = response.data.data.meta
      editableContent.value = pageMeta.value.content
      isHtml.value = regex.test(pageMeta.value.content) || pageMeta.value.content.includes('**')
    }
    isFetchingMeta.value = false
  }
})

const generatedWordHandler = {
  imageSelected(e, k, i, wordId, type = null, otherParm = {}) {
    if (type === 'picked') {
      useJwt.imageUploadForParagraphWords(wordId, { image: e, ...otherParm }).then(() => {
      }).catch(err => {
        console.log(err)
      })
      return
    }
    if (!e.target.files[0]) return
    // eslint-disable-next-line prefer-destructuring
    root.fileToBase64(e.target.files[0]).then(result => {
      useJwt.imageUploadForParagraphWords(wordId, { image: result }).then(response => {
        generatedWord.value = {
          ...response.data.data.word,
          image: response.data.data.word.image_url,
          audio: response.data.data.word.audio_url,
        }
      }).catch(err => {
        console.log(err)
      })
    })
  },
  setWordSelectorAudio(word, { audio }, type = null) {
    if (type === 'picked') {
      useJwt.imageUploadForParagraphWords(word.id, { audio }).then(() => {
      }).catch(err => {
        console.log(err)
      })
      return
    }
    root.fileToBase64(audio).then(result => {
      // eslint-disable-next-line no-param-reassign
      generatedWord.value.audio = result
      useJwt.imageUploadForParagraphWords(word.id, { audio: result }).then(() => {
      }).catch(err => {
        console.log(err)
      })
    })
  },
}

</script>
