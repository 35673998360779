<template>
  <b-row>
    <h4 class="mb-2 ml-0">
      <b-button
        variant="flat-secondary"
        class="btn-icon ml-0"
        @click="$router.push({ name: 'super-adaptive-learning' })"
      >
        <feather-icon
          size="20"
          icon="ArrowLeftIcon"
        />
      </b-button>
      {{ $t(`adaptive-learning-module.${mode}-algorithm`) }}
    </h4>
    <b-row
      class="match-height mb-2"
      style="display: block; overflow-x: auto; white-space: nowrap"
    >
      <b-col
        v-for="(name, index) in problems"
        :key="'piller_' + name"
        md="2"
        sm="2"
        style="display: inline-block; max-width: 200px"
      >
        <problem-piller
          :index="index"
          :levels="levels"
          :is-last-problem="
            index === problems.length - 1 &&
              activeProblem === problems.length - 1
          "
          :active-level="activeLevel"
          :is-active="index === activeProblem"
          :actions="actions[index]"
        />
      </b-col>
    </b-row>
    <b-col md="12">
      <controls
        :is-last-problem="activeProblem === problems.length - 1"
        :is-first-problem="activeProblem === 0"
        :possible-steps="possibleSteps"
        :current-run-string="currentRunString"
        :is-processing="isProcessing"
        :total-steps="totalSteps"
        :algorithm="localAlgorithm"
        :mode="mode"
        @passedOrFailed="passedOrFailed"
        @reset="reset"
        @save="save"
        @activeLevelChanged="activeLevelChanged"
        @totalStepsChanged="totalStepsChanged"
        @algorithmDataUpdated="algorithmDataUpdated"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ProblemPiller from './ProblemPiller.vue'
import Controls from './Controls.vue'

export default {
  components: {
    BRow, BCol, ProblemPiller, Controls, BButton,
  },
  props: {
    mode: {
      type: String,
      default: 'create',
    },
    algorithm: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      activeProblem: 0,
      levels: [
        {
          text: 'B2',
          index: 4,
        },
        {
          text: 'B1',
          index: 3,
        },
        {
          text: 'A2',
          index: 2,
        },
        {
          text: 'A1',
          index: 1,
        },
      ],
      problems: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      activeLevel: 0,
      actions: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      totalSteps: 1,
      possibleSteps: {},
      isProcessing: false,
      currentRunString: '',
      localAlgorithm: {
        name: 'Example Algorithm',
        start_level: 0,
        is_default: false,
        is_active: false,
      },
    }
  },
  watch: {
    algorithm() {
      this.resetAlgorithm()
    },
  },
  created() {
    this.resetAlgorithm()
  },
  methods: {
    resetAlgorithm() {
      this.reset()
      if (!this.algorithm) {
        this.calculatePossibleSteps()
      } else {
        this.totalSteps = this.algorithm.total_steps
        this.localAlgorithm = {
          name: this.algorithm.name,
          is_default: this.algorithm.is_default === 1,
          is_active: this.algorithm.is_active === 1,
          start_level: 'start_level' in this.algorithm && this.algorithm.start_level ? this.algorithm.start_level : 1,
        }
        this.activeLevel = this.localAlgorithm.start_level
        this.possibleSteps = JSON.parse(JSON.stringify(this.algorithm.algorithm))
      }
    },
    algorithmDataUpdated(key, value) {
      this.localAlgorithm[key] = value
    },
    calculatePossibleSteps() {
      const combinations = this.allPossibleCombinations('PF', this.totalSteps, '')
      const oldPossibleSteps = this.possibleSteps
      const newPossibleSteps = {}
      combinations.forEach(element => {
        if (element in oldPossibleSteps) newPossibleSteps[element] = oldPossibleSteps[element]
        else {
          newPossibleSteps[element] = {
            type: element,
            level: element[element.length - 1] === 'P' ? 'up' : 'down',
            problem: element[element.length - 1] === 'P' ? 'next' : 'same',
            // reset: element.length === this.totalSteps,
          }
        }
      })
      this.possibleSteps = JSON.parse(JSON.stringify(newPossibleSteps))
    },
    allPossibleCombinations(input, length, curstr) {
      if (curstr.length === length) return [curstr]
      let ret = []
      for (let i = 0; i < input.length; i += 1) {
        if (!ret.includes(curstr + input[i]))ret.push(curstr + input[i])
        const returnStr = this.allPossibleCombinations(input, length, curstr + input[i])
        // eslint-disable-next-line no-loop-func
        ret = ret.concat(returnStr.filter(item => ret.indexOf(item) < 0))
      }
      // eslint-disable-next-line prefer-const

      return ret
    },

    reset() {
      this.actions = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
      this.activeProblem = 0
      this.activeLevel = this.localAlgorithm.start_level
      this.currentRunString = ''
    },
    save() {
      this.isProcessing = true
      const data = {
        total_steps: this.totalSteps,
        algorithm: this.possibleSteps,
        ...this.localAlgorithm,
        start_level: this.localAlgorithm.start_level,
      }
      if (this.mode === 'create') {
        useJwt.createLearningAlgorithm(data).then(res => {
          this.showSuccessMessage(res)
          this.$router.replace({ name: 'super-adaptive-learning' })
        }).finally(() => {
          this.isProcessing = false
        })
      } else {
        useJwt.updateLearningAlgorithm(this.algorithm.id, data).then(res => {
          this.showSuccessMessage(res)
        }).finally(() => {
          this.isProcessing = false
        })
      }
    },
    passedOrFailed(string) {
      if (this.currentRunString.length !== this.totalSteps) this.currentRunString += string
      else this.currentRunString = string
      const actionToBeUsed = this.possibleSteps[this.currentRunString]

      const oldLevel = this.activeLevel
      const oldProblem = this.activeProblem

      if (actionToBeUsed.level === 'up') {
        if (this.activeLevel === this.levels.length) this.activeProblem += 1
        else this.activeLevel += 1
      }
      if (actionToBeUsed.level === 'down') {
        if (this.activeLevel === 1) this.activeProblem += 1
        else this.activeLevel -= 1
      }

      if (actionToBeUsed.problem === 'next' && oldProblem === this.activeProblem) this.activeProblem += 1
      if (actionToBeUsed.problem === 'previous' && this.activeProblem !== 1 && oldProblem === this.activeProblem) this.activeProblem -= 1

      this.actions[oldProblem][oldLevel] = { ...actionToBeUsed, ...{ result: string === 'P' ? 'pass' : 'fail', icon: this.getArrowIcon(this.activeProblem, oldProblem, this.activeLevel, oldLevel) } }
    },

    getArrowIcon(newProblem, activeProblem, newLevel, activeLevel) {
      if (newProblem > activeProblem && newLevel === activeLevel) return 'ArrowRightIcon'
      if (newProblem === activeProblem && newLevel > activeLevel) return 'ArrowUpIcon'
      if (newProblem > activeProblem && newLevel > activeLevel) return 'ArrowUpRightIcon'
      if (newProblem === activeProblem && newLevel < activeLevel) return 'ArrowDownIcon'
      if (newProblem > activeProblem && newLevel < activeLevel) return 'ArrowDownRightIcon'
      return 'ArrowDownRightIcon'
    },

    totalStepsChanged(steps) {
      this.totalSteps = steps
      this.calculatePossibleSteps()
    },

    activeLevelChanged(level) {
      this.localAlgorithm.start_level = level
      this.activeLevel = level
    },
  },
}
</script>

<style>
</style>
