<template>
  <div>
    <b-row>
      <b-col
        md="4"
      >
        <b-form-group>
          <b-form-input
            :placeholder="$t('search')"
            type="text"
            class="d-inline-block"
            @input="searchNetworksTable"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="self"
        md="4"
      >
        <div class="d-flex">
          <b-button
            :variant="[networkType === 'active' ? 'primary': 'outline-primary' ]"
            @click="networkType = 'active'"
          >
            {{ $t('network-module.active') }}
          </b-button>
          <b-button
            :variant="[networkType === 'archived' ? 'primary': 'outline-primary' ]"
            class="ml-1"
            @click="networkType = 'archived'"
          >
            {{ $t('network-module.archived') }}
          </b-button>
        </div>
      </b-col>
      <b-col
        md="4"
        class="d-flex justify-content-end"
      >
        <Header
          @onNetworkCreated="networkCreated"
        />
      </b-col>
      <!-- filters and create new button end -->

      <!-- list of users table -->
      <b-col md="12 mt-2">
        <b-row>
          <b-col>
            <p class="text-capitalize">
              <strong> {{ $t('network-module.list-of-networks') }}</strong>
            </p>
          </b-col>
        </b-row>

        <b-overlay
          id="overlay-background"
          :show="isProcessing"
          variant="transparent"
          opacity="0.8"
          blur="0.8"
          rounded="sm"
        >
          <b-row>
            <b-col
              v-for="(network,ind) in networks"
              :key="ind"
              md="4"
              sm="6"
              xs="12"
              class="align-items-stretch"
            >

              <b-card>
                <b-row>
                  <b-col class="col-4">
                    <b-media no-body>
                      <b-media-aside>
                        <avatar
                          :image="network.logo"
                          default-icon="network"
                          size="70px"
                          :rounded="true"
                        />
                      </b-media-aside>
                    </b-media>
                  </b-col>
                  <b-col class="col-8">
                    <b-row>
                      <b-col class="col-12">
                        <b-card-text>
                          <h5>{{ network.name }}</h5>
                          <div>
                            <h5>{{ $t('network-module.min-rating') }}: <strong>{{ network.rating }}</strong></h5>
                            <h5>{{ $t('network-module.language') }}: <strong>{{ network.language }}</strong></h5>
                            <h5>{{ $t('network-member-types.leader') }}: <strong>{{ getLeaderName(network.owner) }}</strong></h5>
                          </div>
                        </b-card-text>

                      </b-col>
                      <b-col class="col-12 d-flex justify-content-end">
                        <b-button
                          v-if="self"
                          v-b-tooltip.hover.top="networkType === 'active' ? $t('network-module.delete') : 'Delete'"
                          class="mt-2 mr-1"
                          size="sm"
                          variant="outline-danger"
                          @click="networkIdToArchive = network.id"
                        >
                          <feather-icon :icon="networkType === 'active' ? 'DeleteIcon' : 'TrashIcon' " />
                        </b-button>
                        <b-button
                          v-if="!isArchived"
                          v-b-tooltip.hover.top="$t('network-module.view-details')"
                          class="mt-2"
                          size="sm"
                          variant="outline-primary"
                          @click="onNetworkRowClick(network.id)"
                        >
                          <feather-icon icon="EyeIcon" />
                        </b-button>
                        <b-button
                          v-else-if="self"
                          v-b-tooltip.hover.top="$t('network-module.restore')"
                          class="mt-2"
                          size="sm"
                          variant="outline-secondary"
                          @click="networkIdToRestore = network.id"
                        >
                          <feather-icon icon="ShareIcon" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>

            </b-col>
          </b-row>
          <div v-if="networks.length === 0">
            <b-card>
              {{ $t('student-report-module.no-data-available') }}
            </b-card>
          </div>

          <b-skeleton-wrapper v-if="isProcessing && !networks.length">
            <b-row>
              <b-col
                v-for="i in 12"
                :key="i"
                md="4"
                sm="6"
                xs="12"
              >
                <b-card>
                  <b-skeleton
                    width="100%"
                    height="150px"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-skeleton-wrapper>

          <div
            v-if="networks.length > 0"
            class="d-flex justify-content-between flex-wrap"
          >
            <div class="d-flex align-items-center mb-0 mt-1">
              <b-form-select
                v-model="paginationData.perPage"
                :options="[3,6,12,15,24]"
                :disabled="isProcessing"
                class="mr-1"
                @input="getNetworks()"
              />
              <span class="text-nowrap"> {{ $t('of') }} {{ paginationData.totalItems }} {{ $t('network-module.networks') }} </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="paginationData.totalItems"
                :per-page="paginationData.perPage"
                :disabled="isProcessing"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>{getNetworks(value)}"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-overlay>
        <!-- list of users table -->

      </b-col>
    </b-row>
    <confirm-modal
      :title="networkType == 'active' ? $t('network-module.archive') : $t('network-module.delete')"
      :show="!!networkIdToArchive"
      :sub-title="networkType == 'active' ? $t('network-module.archive-title') : $t('network-module.delete-title')"
      :on-delete="destroyNetwork"
      :is-processing="deletingNetwork"
      @close="networkIdToArchive = null"
    />
    <confirm-modal
      :title="$t('network-module.restore-network')"
      varient="secondary"
      :show="!!networkIdToRestore"
      :sub-title="$t('network-module.restore-confirmation')"
      :on-delete="restoreNetwork"
      :is-processing="deletingNetwork"
      @close="networkIdToRestore = null"
    />
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BRow, BCol, BFormInput, BFormGroup, BSkeletonWrapper, BSkeleton, BPagination, BOverlay, BFormSelect, BMedia, BMediaAside, BCard, BButton, BCardText, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import Header from '@/views/common/network/components/Header.vue'
import { USER_TYPE_STUDENT } from '@/const/userType'
import Avatar from '@/views/common/components/Avatar.vue'
import i18n from '@/libs/i18n'
import ConfirmModal from '@/views/common/components/DeleteModal.vue'

export default {
  components: {
    BRow,
    BPagination,
    Avatar,
    BMedia,
    BMediaAside,
    VueGoodTable,
    BFormSelect,
    BFormInput,
    BCol,
    BFormGroup,
    BOverlay,
    BSkeletonWrapper,
    BSkeleton,
    Header,
    BCard,
    BButton,
    BCardText,
    VBTooltip,
    ConfirmModal,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isProcessing: false,
      searchTerm: '',
      searchTimeout: null,
      columnsForNetworksTable: [
        {
          label: this.$i18n.tc('network-module.network-table.image'),
          field: 'image',
          width: '100px',
        },
        {
          label: this.$i18n.tc('network-module.network-table.name'),
          field: 'name',
        },
        {
          label: this.$i18n.tc('network-module.network-table.rating'),
          field: 'rating',
        },
      ],
      networks: [],
      myNetworks: [],
      paginationData: {
        currentPage: 1,
        perPage: 12,
        totalItems: 0,
        lastPage: 0,
      },
      self: getUserData(),
      networkIdToArchive: null,
      deletingNetwork: false,
      networkType: 'active',
      networkIdToRestore: null,
    }
  },
  computed: {
    USER_TYPE_STUDENT() {
      return USER_TYPE_STUDENT
    },
    isArchived() {
      return this.networkType === 'archived'
    },
  },
  watch: {
    networkType() {
      this.$nextTick(() => {
        this.getNetworks()
      })
    },
  },

  mounted() {
    this.getNetworks()
  },

  methods: {
    getLeaderName(leader) {
      if (!leader) return ''
      if (this.self?.id === leader.id) return i18n.tc('you')
      return `${leader.firstname} ${leader.lastname}`
    },
    networkCreated() {
      this.getNetworks()
    },
    searchNetworksTable(term) {
      this.searchTerm = term
      if (this.searchTimeout) { clearTimeout(this.searchTimeout) }

      this.searchTimeout = setTimeout(() => {
        this.getNetworks(1)
      }, 400) // delay
    },
    assignPaginationData(data) {
      this.paginationData = {
        currentPage: data.current_page,
        perPage: parseInt(data.per_page, 10),
        totalItems: data.total,
        lastPage: data.total_pages,
      }
    },
    getNetworks(page = this.paginationData.currentPage) {
      this.isProcessing = true
      const params = {
        page,
        perPage: this.paginationData.perPage,
        search: this.searchTerm,
        type: this.networkType,
      }
      useJwt.getNetworks({
        params,
      }).then(response => {
        this.isProcessing = false
        this.networks = response.data.data.data
        this.assignPaginationData(response.data.data)
      })
    },
    onNetworkRowClick(id) {
      this.navigateToNetworkShowPage(id)
    },
    navigateToNetworkShowPage(id) {
      if (this.self) this.$router.push({ name: `${this.self.usertype}-networks-show`, params: { id } })
      else this.$router.push({ name: 'network-show', params: { id } })
    },
    destroyNetwork() {
      this.deletingNetwork = true
      if (this.networkType === 'archived') {
        useJwt.forceDeleteNetwork(this.networkIdToArchive).then(response => {
          this.showSuccessMessage(response)
          this.getNetworks()
          this.networkIdToArchive = null
        }).catch(err => {
          this.showErrorMessage(err)
        }).finally(() => { this.deletingNetwork = false })
        return
      }
      useJwt.deleteNetwork(this.networkIdToArchive).then(response => {
        this.showSuccessMessage(response)
        this.getNetworks()
        this.networkIdToArchive = null
      }).catch(err => {
        this.showErrorMessage(err)
      }).finally(() => { this.deletingNetwork = false })
    },
    restoreNetwork() {
      this.deletingNetwork = true
      useJwt.restoreNetwork(this.networkIdToRestore).then(response => {
        this.showSuccessMessage(response)
        this.getNetworks()
        this.networkIdToRestore = null
      }).catch(err => {
        this.showErrorMessage(err)
      }).finally(() => { this.deletingNetwork = false })
    },
  },
}
</script>
