<template>

  <div class="dialogs">
    <dialog-drag
      v-for="(graph, index) of graphData"
      :key="index"
      @close="removeDialog(index)"
    >
      <h5
        slot="title"
        v-b-tooltip.hover
        class="m-0 text-capitalize text-truncate"
        :title="graph.courseInfo.name + '/' + graph.studentInfo.fullname "
        style="width: 80%"
        @click="setActiveDialog(dlg.id)"
      >
        {{ graph.courseInfo.name }}/{{ graph.studentInfo.fullname }}
      </h5>
      <b-card>
        <skill-summary-chart
          v-if="Object.entries(graph.data).length > 0"
          :series="graph.data.series"
          :categories="graph.data.category"
        />
      </b-card>
    </dialog-drag>
  </div>
</template>
<script>
import DialogDrag from 'vue-dialog-drag'
import useJwt from '@/auth/jwt/useJwt'
import SkillSummaryChart from '@/views/common/Charts/SkillSummaryChart.vue'
import { BCard, VBTooltip } from 'bootstrap-vue'

export default {

  components: {
    DialogDrag,
    SkillSummaryChart,
    BCard,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    studentIdToAppend: {
      type: [Number, Object, String],
      default: null,
    },
    classRoom: {
      type: [Number, Object, String],
      default: null,
    },
    courseInfo: {
      type: [Object],
      default: null,
    },
    loaderInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      graphData: [],
    }
  },
  watch: {
    studentIdToAppend(newValue) {
      if (newValue && !this.graphData.find(graph => graph.studentId !== newValue && graph.courseId !== this.courseInfo.id)) {
        this.fetchData()
      }
    },
  },
  methods: {
    removeDialog(index) {
      this.graphData.splice(index, 1)
    },
    drawChart(dataWithScores) {
      dataWithScores.sort((a, b) => a.percent - b.percent)
      const category = dataWithScores.map(chart => this.capitalizeFirstLetter(chart.name))
      const series = [
        {
          name: 'Score',
          data: dataWithScores.map(chart => chart.percent),
        },
      ]
      return {
        category,
        series,
      }
    },
    fetchData() {
      useJwt.getClassroomSkillChartForCourses(this.classRoom, this.courseInfo.actualCourseId || this.courseInfo.id, this.studentIdToAppend, {
        params: {
          // when there is actualCourseId then id refers to class test id
          class_test_id: this.courseInfo.actualCourseId ? this.courseInfo.id : null,
        },
      }).then(response => {
        const data = response.data?.data
        const dataWithScores = []
        data.forEach(value => {
          const percent = value?.total ? this.RoundNumber(value.pass, 2) : 0
          dataWithScores.push({
            name: value.name,
            percent,
          })
        })
        this.graphData.push({
          studentId: this.studentIdToAppend,
          courseId: this.courseInfo.id,
          courseInfo: this.courseInfo,
          studentInfo: this.loaderInfo,
          data: this.drawChart(dataWithScores),
        })
        this.$emit('resetStudentId')
      })
    },
  },
}
</script>
