const themes = [
  { value: 1, text: 'Theme-1' },
  { value: 2, text: 'Theme-2' },
  { value: 3, text: 'Theme-3' },
  { value: 4, text: 'Theme-4' },
  { value: 5, text: 'Theme-5' },
  { value: 6, text: 'Theme-6' },
  { value: 7, text: 'Theme-7' },
  { value: 8, text: 'Theme-8' },
  { value: 9, text: 'Theme-9' },
]
export default themes
