<template>
  <validation-observer ref="simpleRules">
    <b-card body-class="p-0">
      <table class="table table-hover table-striped">
        <thead>
          <tr>
            <th />

            <th>Student</th>
            <th>User Input</th>
            <th>
              <div class="d-flex">
                <div style="padding: 0.438rem 1rem"
                     class="input-width text-nowrap text-center"
                >
                  Reading
                </div>
                <div style="padding: 0.438rem 1rem"
                     class="input-width text-nowrap text-center"
                >
                  Listening
                </div>
                <div style="padding: 0.438rem 1rem"
                     class="input-width text-nowrap text-center"
                >
                  Writing
                </div>
                <div style="padding: 0.438rem 1rem"
                     class="input-width text-nowrap text-center"
                >
                  Speaking
                </div>
              </div>
            </th>
            <th>Result (R/L/W/S)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="student,index of studentsLists"
              :key="index"
          >
            <td>
              <b-form-checkbox v-model="student.selected"
                               role="button"
                               :value="1"
                               @change="getCalibratedReport(student)"
              />
            </td>
            <td>
              {{ student.fullName }}
            </td>
            <td>
              <div>
                Outside
              </div>
              <div>
                Inside
              </div>
            </td>
            <td>
              <div class="d-flex">
                <div>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="`Reading ${index}`"
                    :rules="{ required: !!student.selected} "
                  >
                    <input v-model.number="student.R"
                           placeholder="R"
                           type="number"
                           :disabled="!student.selected"
                           class="form-control mr-1 input-width"
                           @keyup="computeCalibration(student)"
                    >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <div class="text-left ml-1 font-weight-bold">
                    {{ number_format(student.reading, 2) }}
                  </div>

                </div>
                <div>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="`Listening ${index}`"
                    :rules="{ required: !!student.selected} "
                  >
                    <input v-model.number="student.L"
                           placeholder="L"
                           :disabled="!student.selected"
                           type="number"
                           class="form-control  mr-1 input-width"
                           @keyup="computeCalibration(student)"
                    >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <div class="text-left ml-1 font-weight-bold">
                    {{ number_format(student.listening, 2) }}
                  </div>
                </div>
                <div>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="`Writing ${index}`"
                    :rules="{ required: !!student.selected} "
                  >
                    <input v-model.number="student.W"
                           placeholder="W"
                           :disabled="!student.selected"
                           type="number"
                           class="form-control  mr-1 input-width"
                           @keyup="computeCalibration(student)"
                    >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <div class="text-left ml-1 font-weight-bold">
                    {{ number_format(student.writing, 2) }}
                  </div>
                </div>
                <div>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="`Speaking ${index}`"
                    :rules="{ required: !!student.selected} "
                  >
                    <input v-model.number="student.S"
                           placeholder="S"
                           :disabled="!student.selected"
                           type="number"
                           class="form-control  mr-1 input-width"
                           @keyup="computeCalibration(student)"
                    >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <div class="text-left ml-1 font-weight-bold">
                    {{ number_format(student.speaking, 2) }}
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex">
                <feather-icon :icon="student.R_computed > 0 ? 'ArrowUpIcon' : 'ArrowDownIcon' "
                              :color="student.R_computed > 0 ? 'green' : 'red' "
                              class="mt-1"
                />
                <input v-model="student.R_computed"
                       placeholder="R"
                       class="form-control  mr-1 input-width"
                       readonly
                >
                <feather-icon :icon="student.L_computed > 0 ? 'ArrowUpIcon' : 'ArrowDownIcon' "
                              :color="student.L_computed > 0 ? 'green' : 'red' "
                              class="mt-1"
                />
                <input v-model="student.L_computed"
                       placeholder="L"
                       class="form-control  mr-1 input-width"
                       readonly
                >
                <feather-icon :icon="student.W_computed > 0 ? 'ArrowUpIcon' : 'ArrowDownIcon' "
                              :color="student.W_computed > 0 ? 'green' : 'red' "
                              class="mt-1"
                />
                <input v-model="student.W_computed"
                       placeholder="W"
                       class="form-control  mr-1 input-width"
                       readonly
                >
                <feather-icon :icon="student.S_computed > 0 ? 'ArrowUpIcon' : 'ArrowDownIcon' "
                              :color="student.S_computed > 0 ? 'green' : 'red' "
                              class="mt-1"
                />
                <input v-model="student.S_computed"
                       placeholder="S"
                       class="form-control  mr-1 input-width"
                       readonly
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class=" font-weight-bold">
              Average
            </td>
            <td colspan="3" />
            <td>
              <div class="d-flex">
                <div style="padding: 0.438rem 1rem"
                     class="input-width text-nowrap text-center  font-weight-bold large"
                >
                  {{ 'R ' + number_format(calibrationAverage.reading, 2) }}
                </div>
                <div style="padding: 0.438rem 1rem"
                     class="input-width text-nowrap text-center  font-weight-bold"
                >
                  {{ 'L ' + number_format(calibrationAverage.listening, 2) }}
                </div>
                <div style="padding: 0.438rem 1rem"
                     class="input-width text-nowrap text-center  font-weight-bold"
                >
                  {{ 'W ' + number_format(calibrationAverage.writing, 2) }}
                </div>
                <div style="padding: 0.438rem 1rem"
                     class="input-width text-nowrap text-center font-weight-bold"
                >
                  {{ 'S ' + number_format(calibrationAverage.speaking, 2) }}
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </b-card>
    <div class="text-right">
      <b-button variant="success"
                :disabled="isProcessing"
                @click="saveForm"
      >
        Next <b-spinner v-show="isProcessing"
                        small
        />
      </b-button>
    </div>
    <div v-if="lessonId"
         class="p-2"
    >
      <div class="row">
        <AverageCard :average-cefr="overallLessonCefr"
                     :lesson-id="lessonId"
                     :lesson="lesson"
                     @updateAverageCefr="value => overallLessonCefr = value"
        />
      </div>
    </div>
  </validation-observer>
</template>
<script>
import {
  BCard, BFormCheckbox, BButton, BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import AverageCard from './AverageCard.vue'

export default {
  components: {
    BCard,
    BButton,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    AverageCard,
  },
  props: {
    classId: {
      type: Number,
      default: 0,
    },
    lessonId: {
      type: Number,
      default: 0,
    },
    studentsLists: {
      type: Array,
      default: () => [],
    },
    defaultCalibrationAverage: {
      type: Object,
      default: () => {},
    },
    cefrClassRoomReports: {
      type: Array,
      default: () => [],
    },
    lesson: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      calibrationAverage: this.defaultCalibrationAverage || {},
      isProcessing: false,
      required,
      overallLessonCefr: [],
    }
  },
  watch: {
    defaultCalibrationAverage(value) {
      this.calibrationAverage = value
    },
  },
  mounted() {
    this.studentsLists.forEach(student => {
      // eslint-disable-next-line no-param-reassign
      student.selected = 1
      this.getCalibratedReport(student)
    })
  },
  methods: {
    saveForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (!success) {
          return
        }
        const formParms = {
          lesson_id: this.lessonId,
          classroom_id: this.classId,
          average_reading: this.calibrationAverage.reading,
          average_listening: this.calibrationAverage.listening,
          average_writing: this.calibrationAverage.writing,
          average_speaking: this.calibrationAverage.speaking,
        }
        const studentCalibrations = this.studentsLists.map(item => ({
          student_id: item.student_id,
          reading_output: item.R || '',
          listening_output: item.L || '',
          speaking_output: item.S || '',
          writing_output: item.W || '',
          reading_inside: item.reading || '',
          listening_inside: item.listening || '',
          speaking_inside: item.speaking || '',
          writing_inside: item.writing || '',
        }))
        this.isProcessing = true
        useJwt.saveStudentCalibration({
          calibration: formParms,
          student_calibration: studentCalibrations,
        }).then(response => {
          this.showSuccessMessage(response)
          this.overallLessonCefr = response.data.data.average_lesson_calibration
        }).catch(error => {
          this.showError(error)
        }).finally(() => {
          this.isProcessing = false
        })
      })
    },
    getCalibrationAverage() {
      const applicable = this.studentsLists.filter(item => !!item.selected)
      const total = applicable.length
      const reading = applicable.reduce((p, c) => p + c.R_computed, 0) / total
      const listening = applicable.reduce((p, c) => p + c.L_computed, 0) / total
      const writing = applicable.reduce((p, c) => p + c.W_computed, 0) / total
      const speaking = applicable.reduce((p, c) => p + c.S_computed, 0) / total
      this.calibrationAverage = {
        reading,
        listening,
        writing,
        speaking,
      }
    },
    computeCalibration(student, start = true) {
      const {
        reading, listening, writing, speaking,
      } = student
      // eslint-disable-next-line no-param-reassign
      student.canStartCalculation = true

      if (start) {
        // eslint-disable-next-line no-param-reassign
        student.R_computed = student.R ? student.R - reading : ''
        // eslint-disable-next-line no-param-reassign
        student.L_computed = student.L ? student.L - listening : ''
        // eslint-disable-next-line no-param-reassign
        student.W_computed = student.W ? student.W - writing : ''
        // eslint-disable-next-line no-param-reassign
        student.S_computed = student.S ? student.S - speaking : ''
        this.$forceUpdate()
        this.getCalibrationAverage()
      }
    },
    getCalibratedReport(student) {
      // eslint-disable-next-line no-param-reassign
      student.isProcessing = true
      // eslint-disable-next-line eqeqeq
      const calibration = this.cefrClassRoomReports.filter(item => item.user_id == student.student_id).map(item => ({
        pass: item.cefr_rate,
        name: item.skill_type,
        id: item.user_id,
      }))
      this.$forceUpdate()
      // eslint-disable-next-line no-param-reassign
      student.reading = Number(calibration.find(item => item.name === 'reading')?.pass || 0)
      // eslint-disable-next-line no-param-reassign
      student.listening = Number(calibration.find(item => item.name === 'listening')?.pass || 0)
      // eslint-disable-next-line no-param-reassign
      student.writing = Number(calibration.find(item => item.name === 'writing')?.pass || 0)
      // eslint-disable-next-line no-param-reassign
      student.speaking = Number(calibration.find(item => item.name === 'speaking')?.pass || 0)
      this.computeCalibration(student, false)
    },
  },
}
</script>
<style>
  .input-width {
    width: 80px;
  }
</style>
