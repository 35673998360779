<template>
  <div>
    <b-card
      no-body
      no-footer
    >
      <b-card-body class="p-0">
        <b-table-simple
          class="table-bordered table-hover table-striped mb-0"
          responsive=""
        >
          <b-thead>
            <b-tr>
              <b-th class="bg-primary text-white f-16">
                {{ $t('class-module.name') }}
              </b-th>
              <b-th class="bg-primary text-white f-16">
                {{ $t('student-report-module.problem-table.total') }}
              </b-th>
              <b-th
                v-for="wa of weekArray"
                :key="wa.key"
                class="bg-primary text-white text-nowrap"
              >
                {{ wa.text }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="row of rows"
              :key="row.id"
            >
              <b-td>
                <div class="d-flex">
                  <b-avatar
                    v-if="row.avatar"
                    badge
                    :badge-variant="row.is_online ? 'success' : 'secondary'"
                    badge-offset="-0.2em"
                    :src="props.row.avatar"
                    class="ml-1 mt-1"
                    square
                    size="42"
                  />

                  <b-avatar
                    v-else
                    class="ml-1 mt-1"
                    badge
                    :badge-variant="row.is_online ? 'success' : 'secondary'"
                    badge-offset="-0.2em"
                    :text="getInitials(row.full_name)"
                    :size="42"
                    :variant="getRandomVariant()"
                    square
                  />
                  <div class="mt-1">
                    <span class="text-nowrap m-0 ml-1 p-0">{{ row.full_name }}</span>
                    <h5
                      v-if=" row.last_tracked"
                      class="pl-1 nickname text-success mt-0"
                    >
                      {{ row.last_tracked }}
                    </h5>
                    <h5
                      v-else
                      class="pl-1 nickname text-danger mt-0"
                    >
                      &nbsp; {{ $t('student-report-module.never-tracked-time') }}
                    </h5>
                  </div>
                </div>
              </b-td>
              <b-td class="text-nowrap">
                {{ getTotalHours(row.weeks_rec) }} {{ $t('setting-module.hours') }}
              </b-td>
              <b-th
                v-for="wa of weekArray"
                :key="wa.key"
                :set="day_rec = getDayRec(row.weeks_rec,wa.key)"
              >
                <b-progress
                  v-b-tooltip.hover
                  :title="`${day_rec} Hours / ${schoolTime} Hours`"
                  :max="schoolTime"
                  variant="success"
                  class="progress-bar-primary"
                  height="1.5rem"
                >
                  <b-progress-bar
                    :value="day_rec"
                    class="text-dark"
                  />
                </b-progress>
              </b-th>
            </b-tr>
          </b-tbody>
          <b-tfoot v-if="rows.length === 0">
            <b-td
              class="p-2 text-center"
              colspan="100%"
            >
              {{ $t('no-record-found') }}
            </b-td>
          </b-tfoot>
        </b-table-simple>
      </b-card-body>
    </b-card>
    <div>
      <b-pagination
        :value="1"
        :total-rows="paginationData.totalItems"
        :per-page="paginationData.perPage"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        @input="getUsers"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import {
  BTableSimple,
  BTr,
  BTd,
  BTh,
  BThead,
  BTbody,
  BCard,
  BCardBody,
  BAvatar,
  BProgress,
  BProgressBar,
  VBTooltip,
  BPagination,
  BTfoot,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

const moment = require('moment')

const SECOND_RATIO_DAY = 0.000277778
export default {
  components: {
    BTableSimple,
    BTr,
    BTd,
    BThead,
    BTbody,
    BTh,
    BCard,
    BCardBody,
    BAvatar,
    BProgress,
    BProgressBar,
    BPagination,
    BTfoot,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    searchDate: {
      type: String,
      required: true,
    },
    getRandomVariant: {
      type: Function,
      required: true,
    },
    getInitials: {
      type: Function,
      required: true,
    },
    selectedTimezone: {
      type: [String, Object],
      default: null,
    },
    searchTerm: {
      type: String,
      default: '',
    },
    classRoom: {
      type: [Number, String, Object],
      default: () => null,
    },
    schoolTime: {
      type: Number,
      default: 24,
    },
  },
  data() {
    return {
      rows: [],
      paginationData: {},
    }
  },
  computed: {
    weekArray() {
      const startOfWeek = moment(this.searchDate).startOf('week')
      const endOfWeek = moment(this.searchDate).endOf('week')

      const days = []
      let day = startOfWeek

      while (day <= endOfWeek) {
        days.push({
          text: day.format('Y-MM-DD'),
          key: day.format('Y-MM-DD'),
        })
        day = day.clone().add(1, 'd')
      }
      return days
    },
  },
  watch: {
    searchDate: {
      immediate: true,
      handler() {
        this.getUsers()
      },
    },
    selectedTimezone() {
      this.getUsers()
    },
    searchTerm() {
      this.getUsers()
    },
    classRoom() {
      this.getUsers()
    },
  },
  methods: {
    getUsers(page = 1) {
      const params = {
        page,
        date: this.searchDate,
        day_type: 'week',
        timezone: this.selectedTimezone,
        term: this.searchTerm,
        classRoom: this.classRoom,
      }
      this.$emit('toogleOverlay', true)
      useJwt
        .getStudentTime({ params })
        .then(res => {
          this.rows = res.data.data
          this.assignPaginationData(res.data.pagination)
        })
        .finally(() => {
          this.$emit('toogleOverlay', false)
        })
    },
    getDayRec(arr, day) {
      const findDay = arr.find(d => d.day === day)
      return findDay ? (findDay.time_worked * SECOND_RATIO_DAY).toFixed(0) : 0
    },
    getTotalHours(arr) {
      const total = arr.reduce((i, v) => i + v.time_worked, 0)
      return (total * SECOND_RATIO_DAY).toFixed(0)
    },
    assignPaginationData(data) {
      this.paginationData = {
        currentPage: data.current_page,
        perPage: data.per_page,
        totalItems: data.total,
        lastPage: data.last_page,
      }
    },
  },
}
</script>
<style scoped>
  .f-16 {
    font-size: 14px;
  }
</style>
