<template>
  <div
    class="user-profile-sidebar"
    :class="{'show': shallShowActiveChatContactSidebar}"
  >
    <!-- Header -->
    <header
      v-if="contact"
      class="user-profile-header"
    >
      <span class="close-icon">
        <feather-icon
          icon="XIcon"
          @click="$emit('update:shall-show-active-chat-contact-sidebar', false)"
        />
      </span>

      <div class="header-profile-sidebar">
        <div class="avatar box-shadow-1 avatar-xl avatar-border">
          <b-avatar
            v-if="(contact.contact_type!='class' || !contact.users) && contact.avatar"
            size="70"
            :src="contact.avatar"
          />
          <group-avatar
            v-if="contact.contact_type=='class' && contact.users"
            :contact="contact"
            size="70"
          />
          <span
            class="avatar-status-xl"
            :class="`avatar-status-${contact.status}`"
          />
        </div>
        <h4 class="chat-user-name">
          {{ contact.fullName }}
        </h4>
        <span class="user-post text-capitalize">{{ contact.role }}</span>
      </div>
    </header>

    <!-- User Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="user-profile-sidebar-area scroll-area"
    >

      <!-- About -->
      <!-- <h6 class="section-label mb-1">
        About
      </h6>
      <p>{{ contact.about }}</p> -->

      <!--Class Memebers  -->
      <!-- <ol
        v-if="contact.contact_type='class'"
        class="members p-1 text-left"
        style="list-style:none;"
      >
        <h4>Memebers: </h4>
        <li
          v-for="user in contact.users"
          :key="user.id"
          class="py-1"
        >
          <b-avatar
            size="20"
            :src="user.avatar"
          />
          <span class="ml-1">{{ user.fullName }}</span>
        </li>
      </ol> -->
      <!-- Personal Info -->
      <div class="personal-info">
        <h6 class="section-label mb-1 mt-3">
          Personal Information
        </h6>
        <ul class="list-unstyled">
          <li class="mb-1">
            <feather-icon
              icon="GlobeIcon"
              size="16"
              class="mr-75"
            />
            <span
              class="align-middle"
              style="text-transform:capitalize;"
            >{{ contact.country }}</span>
          </li>
          <li class="mb-1">
            <feather-icon
              icon="MailIcon"
              size="16"
              class="mr-75"
            />
            <span class="align-middle">{{ contact.email }}</span>
          </li>
          <li class="mb-1">
            <feather-icon
              icon="PhoneCallIcon"
              size="16"
              class="mr-75"
            />
            <span class="align-middle">{{ contact.phone }}</span>
          </li>
        </ul>
      </div>

      <!-- More Options -->
      <!-- <div class="more-options">
        <h6 class="section-label mb-1 mt-3">
          Options
        </h6>
        <ul class="list-unstyled">
          <li class="cursor-pointer mb-1">
            <feather-icon
              icon="TagIcon"
              size="16"
              class="mr-75"
            />
            <span class="align-middle">Add Tag</span>
          </li>
          <li class="cursor-pointer mb-1">
            <feather-icon
              icon="StarIcon"
              size="16"
              class="mr-75"
            />
            <span class="align-middle">Important Contact</span>
          </li>
          <li class="cursor-pointer mb-1">
            <feather-icon
              icon="ImageIcon"
              size="16"
              class="mr-75"
            />
            <span class="align-middle">Shared Media</span>
          </li>
          <li class="cursor-pointer mb-1">
            <feather-icon
              icon="TrashIcon"
              size="16"
              class="mr-75"
            />
            <span class="align-middle">Delete Contact</span>
          </li>
          <li class="cursor-pointer">
            <feather-icon
              icon="SlashIcon"
              size="16"
              class="mr-75"
            />
            <span class="align-middle">Block Contact</span>
          </li>
        </ul>
      </div> -->
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BAvatar } from 'bootstrap-vue'
import GroupAvatar from '../components/GroupAvatar.vue'

export default {
  components: {
    // BSV
    BAvatar,

    // 3rd Party
    VuePerfectScrollbar,
    GroupAvatar,
  },
  props: {
    shallShowActiveChatContactSidebar: {
      type: Boolean,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    return {
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>

</style>
