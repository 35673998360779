<template>
  <div>
    <vue-apex-charts
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    series: {
      type: [Object, Array],
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    roundBy: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    chartOptions() {
      const self = this
      return {
        colors: ['#F44336', '#FFEB3A', '#3F50B5', '#4CAF4F'],
        chart: {
          height: 340,
          type: 'bar',
        },
        xaxis: {
          categories: this.categories,
          position: 'top',
        },
        dataLabels: {
          formatter() {
            return ''
          },
        },
        yaxis: {
          // min: 0,
          // max: Math.max(...this.series[0]?.data ?? []),
          // tickAmount: 6,
          labels: {
            formatter(val) {
              return `${self.CONVERT_HM(val * 60 * 60)}`
            },
          },
        },
        tooltip: {
          custom({
            series, seriesIndex, dataPointIndex,
          }) {
            const seconds = self.secondsToHms(series[seriesIndex][dataPointIndex] * 3600)
            return `<div class="p-2">${seconds}</div>`
          },
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            dataLabels: {
              position: 'bottom',
            },
          },
        },
      }
    },
  },
  methods: {
    secondsToHms(d) {
      // eslint-disable-next-line no-param-reassign
      d = Number(d)
      const h = Math.floor(d / 3600)
      // eslint-disable-next-line no-mixed-operators
      const m = Math.floor(d % 3600 / 60)
      const s = Math.floor(d % 3600 % 60)

      const hDisplay = h > 0 ? h + (h === 1 ? this.$i18n.tc('hour-tracked.hour') : this.$i18n.tc('hour-tracked.hour')) : ''
      const mDisplay = m > 0 ? m + (m === 1 ? this.$i18n.tc('hour-tracked.minute') : this.$i18n.tc('hour-tracked.minutes')) : ''
      const sDisplay = s > 0 ? s + (s === 1 ? this.$i18n.tc('hour-tracked.second') : this.$i18n.tc('hour-tracked.seconds')) : ''
      return hDisplay + mDisplay + sDisplay
    },
  },
}
</script>
