<template>
  <b-tbody v-if="formattedSummary.length">
    <b-tr>
      <b-th
        colspan="100%"
        class="bg-secondary text-white"
      >
        Class room: {{ classRoom.name }}
      </b-th>
    </b-tr>
    <b-tr
      v-for="student,index of formattedSummary"
      :key="index"
    >
      <b-td>{{ student.studentName }}</b-td>
      <b-td class="academic">
        {{ number_format(student.lesson_completion, 2) }}
      </b-td>
      <b-td class="academic">
        {{ number_format(student.problem_solving_score , 2) }}
      </b-td>
      <b-td>
        {{ number_format(student.average_language_skill, 2) }}
      </b-td>
      <b-td class="study-skill">
        {{ number_format(student.attendance_report, 2) }}
      </b-td>
      <b-td>
        {{ number_format(student.note_report, 2) }}
      </b-td>
    </b-tr>
    <b-tr class="text-bold">
      <b-th class="text-bold">
        Average
      </b-th>
      <b-th class="text-bold academic">
        {{ number_format(averageReport.lesson_completion_average, 2) }}
      </b-th>
      <b-th class="text-bold academic">
        {{ number_format(averageReport.problem_solving_average, 2) }}
      </b-th>
      <b-th>
        {{ number_format(averageReport.average_language_skill_average, 2) }}
      </b-th>

      <b-th class="text-bold study-skill">
        {{ number_format(averageReport.attendance_report_average, 2) }}
      </b-th>
      <b-th class="text-bold">
        {{ number_format(averageReport.note_report_average, 2) }}
      </b-th>
    </b-tr>
  </b-tbody>
</template>
<script>
import {
  BTr, BTd, BTbody, BTh,
} from 'bootstrap-vue'
import {
  LessonCompletingCalc, ClassRoomSkillReport, AttendanceReportCalc, NoteReportCalc,
} from '../../GroupReport/helper/reportHelper'

export default {
  components: {
    BTr,
    BTd,
    BTh,
    BTbody,
  },
  props: {
    overallReport: {
      type: Object,
      required: true,
    },
    students: {
      type: Array,
      default: () => [],
    },
    classRoom: {
      type: Object,
      required: true,
    },
    skills: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      formattedSummary: [],
      averageReport: {},
      summaryReport: {
        lessonCompetition: [],
        problemSolvingScore: [],
        languageSkill: [],
        averageLanguageSkill: [],
        attendanceReport: [],
        noteReport: [],
      },
    }
  },
  watch: {
    overallReport: {
      handler() {
        this.generateSummary()
      },
      deep: true,
    },
  },
  created() {
    this.generateSummary()
  },
  methods: {
    getLanguageSkillAverage() {

    },
    generateSummary() {
      const problemSolvingScore = this.overallReport.academic_skill.problem_solving_skill.map(item => ({
        percent: item.score_percentage,
        id: item.user_id,
      }))
      this.summaryReport = {
        problemSolvingScore,
        lessonCompetition: this.getLessonCompletingReport(this.overallReport.academic_skill.lesson_completion),
        languageSkill: this.getLanguageSkillReport(this.overallReport.language_skill.language_skill),
        attendanceReport: this.getAttendanceReport(this.overallReport.study_skill.attendance_skill),
        noteReport: this.getNoteReport(this.overallReport.social_skill.note_report),
      }
      this.getFormattedData(this.students)
    },
    getLanguageSkillScore(studentId) {
      // eslint-disable-next-line eqeqeq
      const languageSkill = this.summaryReport.languageSkill.find(item => item.id == studentId)?.data || {}
      return this.skills.map(skill => {
        const value = languageSkill[skill]?.cefr_rate
        return Number(value) || 0
      })
    },
    getScore(key, studentId) {
      // eslint-disable-next-line eqeqeq
      const percent = this.summaryReport[key]?.find(item => item.id == studentId)?.percent
      return Number(percent) || 0
    },
    getLessonCompletingReport(report) {
      const lessonDoneReport = report.lessonDone.score
      const expectedLessonDone = report.lessonDone.expected
      const { summary } = LessonCompletingCalc({ lessonDoneReport, expectedLessonDone }, this)
      return summary
    },
    getLanguageSkillReport(report) {
      const groupReport = ClassRoomSkillReport({ skillReport: report }, this)
      return groupReport
    },
    getAttendanceReport(report) {
      const { summary } = AttendanceReportCalc({
        totalExpectedDays: report.totalExpectedDays,
        attendance: report.attendance,
        maxAttendanceByDate: report.maxByDate,
      }, this)
      return summary
    },
    getNoteReport(report) {
      const { summary } = NoteReportCalc({ noteReportData: report }, this)
      return summary
    },
    getFormattedData(students) {
      const summary = []
      students.forEach(student => {
        const languageSkillScore = this.getLanguageSkillScore(student.id)
        summary.push({
          studentName: `${student.firstname} ${student.lastname}`,
          lesson_completion: this.getScore('lessonCompetition', student.id),
          problem_solving_score: this.getScore('problemSolvingScore', student.id),
          languageSkillScore,
          attendance_report: this.getScore('attendanceReport', student.id),
          average_language_skill: languageSkillScore.reduce((p, c) => p + c, 0) / 4,
          note_report: this.getScore('noteReport', student.id),
        })
      })
      this.formattedSummary = summary
      const average = {
        lesson_completion_average: summary.reduce((p, c) => p + Number(c.lesson_completion), 0) / students.length,
        problem_solving_average: summary.reduce((p, c) => p + Number(c.problem_solving_score), 0) / students.length,
        attendance_report_average: summary.reduce((p, c) => p + Number(c.attendance_report), 0) / students.length,
        note_report_average: summary.reduce((p, c) => p + Number(c.note_report), 0) / students.length,
        average_language_skill_average: summary.reduce((p, c) => p + Number(c.average_language_skill), 0) / students.length,
        // index of this.skill array
        languageSkillScoreAverage: [0, 1, 2, 3].map(index => summary.reduce((p, c) => p + Number(c.languageSkillScore[index]), 0) / students.length),
      }
      this.averageReport = average
    },
  },

}
</script>
