<template>
  <div>
    <BotHeaderArea />

    <BOverlay :key="pageKey"
              :show="isFetchingAssignment"
    >
      <div
        v-if="!isFetchingAssignment"
        class="vh-100 d-flex align-items-center"
      >
        <b-row class="w-100 h-100 p-1">
          <b-col md="3"
                 class="border border-warning p-1 domain-filter "
          >
            <GradeCourseFilter
              :event="reaEvent"
              @onDomainChange="(v) => (state.selectedDomain = v.id, activeDomain = v)"
              @onStandardChange="v => state.selectedStandard = v.id"
            />
          </b-col>
          <b-col md="9"
                 class="border border p-1"
          >
            <StandardFilter
              v-if="state.selectedDomain"
              :key="state.selectedDomain"
              :domain-id="state.selectedDomain"
              :domain="activeDomain"
              :rea-standard="reaEvent?.standard_id || 0"
              :default-standard="state.selectedStandard"
              @onMainPointChange="(v) => (state.selectedMainPoint = v)"
            />
            <div class="mt-2">
              <DemoChat
                :messages="state.messages"
                :is-bot-typing="state.isBotTyping"
                @onMessage="handleOnMessage"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <gec-dragger
        v-show="chatOpened"
        id="draggable-chat-bot"
        :height="200"
        :width="300"
        left="70%"
        draggable-section="chatbot-body"
        :style="'inset: auto auto 20% 75%;'"
      >
        <section
          v-show="chatOpened"
          data-v-5e8ea5c2=""
          class="chat-app-window w-100 h-100 mb-2"
          style="background: transparent"
        >
          <div data-v-5e8ea5c2=""
               class="active-chat w-100 h-100"
          >
            <ChatBot
              voice-type="open-ai"
              :selected-main-point="state.selectedMainPoint"
            />
          </div>
        </section>
      </gec-dragger>
    </BOverlay>
  </div>

</template>
<script setup>
import { BRow, BCol, BOverlay } from 'bootstrap-vue'
import {
  reactive, ref, getCurrentInstance, onMounted, computed,
} from 'vue'
import { Converter } from 'showdown'
import axios from 'axios'
import GecDragger from '@/layouts/components/GECDragger.vue'
import useJwt from '@/auth/jwt/useJwt'
import GradeCourseFilter from './components/GradeStandardFilter.vue'
import StandardFilter from './components/StandardFilter.vue'
import DemoChat from './components/DemoChat.vue'
import ChatBot from './components/ChatBody.vue'
import BotHeaderArea from './components/BotHeaderArea.vue'

const chatEndPoint = '/api/v1/ai-demo/get-response'
const chatOpened = ref(true)
const root = getCurrentInstance().proxy.$root
const state = reactive({
  selectedMainPoint: [],
  selectedDomain: null,
  selectedStandard: null,
  messages: [],
  isBotTyping: false,
})

const isFetchingAssignment = ref(false)
const activeDomain = ref(null)
const reaEvent = ref(null)

const pageKey = computed(() => reaEvent.value?.event_id || 0)
const handleOnMessage = msg => {
  state.messages.push({
    content: msg,
    role: 'user',
  })
  state.isBotTyping = true
  axios
    .post(chatEndPoint, {
      main_points: state.selectedMainPoint,
      message: msg,
    })
    .then(response => {
      const convertor = new Converter()
      state.messages.push({
        content: convertor.makeHtml(response.data.data.content),
        role: 'system',
      })
    })
    .catch(error => {
      root.showErrorMessage(error)
    })
    .finally(() => {
      state.isBotTyping = false
    })
}

onMounted(() => {
  if (root.$route.query.event_id) {
    isFetchingAssignment.value = true
    useJwt.showReaAssignment(root.$route.query.event_id).then(async response => {
      const { data } = response.data
      reaEvent.value = data
    }).finally(() => {
      isFetchingAssignment.value = false
    })
  } else if (root.$route.query.standard_id) {
    isFetchingAssignment.value = true
    useJwt.showReaBasedOnStandard(root.$route.query.standard_id).then(async response => {
      const { data } = response.data
      reaEvent.value = data
    }).finally(() => {
      isFetchingAssignment.value = false
    })
  }
})
</script>
<style lang="css">
.floating-bot-icon{
  z-index: 1031;
  background-color: rgb(253, 178, 39);
  padding:10px;
  border-radius:50%;
  cursor:pointer;
  transition: 0.3s;
  position: fixed;
  bottom: 5%;
  right: 5%;
}

.domain-filter {
  max-height: 100vh;
  overflow: auto;
}
</style>
