var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rewards-detail"},[_c('b-overlay',{staticClass:"w-25",attrs:{"show":_vm.nameLoading}},[_c('h4',{staticClass:"font-weight-bold"},[(!_vm.studentId || _vm.studentId === _vm.self.id)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('badge-reward.your-rewards'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('badge-reward.student-rewards',{studentName: _vm.studentName}))+" ")])])]),_c('b-tabs',{staticClass:"mx-auto"},_vm._l((_vm.awardStats),function(item,type,index){return _c('b-tab',{key:index,attrs:{"active":type === _vm.currentItem,"title":_vm.$tc(`labels.${type}`)},on:{"click":function($event){return _vm.setCurrentItem(type)}}},[_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('badge-reward.search-type', {type})},on:{"input":function($event){return _vm.getAwardStats(type)}},model:{value:(_vm.searchTerms[type]),callback:function ($$v) {_vm.$set(_vm.searchTerms, type, $$v)},expression:"searchTerms[type]"}}),_c('vue-good-table',{attrs:{"columns":_vm.columns[type],"rows":_vm.awardStats[type],"pagination-options":{
            enabled: _vm.awardStats[type].length ? true : false,
            perPage: _vm.rppOptions[_vm.rppOptions.length - 1],
          },"search-options":{
            enabled: false,
          },"sort-options":{
            enabled: false,
          },"responsive":true},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label === 'Value')?_c('span',[_vm._v(" "+_vm._s(_vm.getTotal('Value', 'value', type))+" ")]):(props.column.label === 'Points')?_c('span',[_vm._v(" "+_vm._s(_vm.getTotal('Points', 'convertable_value', type))+" ")]):(props.column.label === 'Coins')?_c('span',[_vm._v(" "+_vm._s(_vm.getTotal('Coins', 'coins', type))+" ")]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'id')?_c('span',[_vm._v(" "+_vm._s(_vm.awardStats[_vm.currentItem].from + props.row.originalIndex)+" ")]):(props.column.field === 'reward' &&
                (_vm.currentItem === 'point' || _vm.currentItem === 'badge'))?_c('span',[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(props.row.lesson))]),_c('span',{staticClass:"font-italic small"},[_vm._v(_vm._s(props.row.reward))])]):(props.column.field === 'code' &&
                (_vm.currentItem === 'badge' || _vm.currentItem === 'vibe'))?_c('div',[(props.row.code)?_c('img',{staticClass:"mx-auto",class:_vm.currentItem === 'badge' ? 'badge-icon' : 'vibe-icon',attrs:{"src":_vm.currentItem === 'badge' ?
                  require(`@/assets/images/${_vm.badges[props.row.code]}`) :
                  _vm.getVibeIcon(props.row.code),"alt":props.row.reward}}):_vm._e()]):(props.column.field === 'date')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(props.row.date))+" ")]):_vm._e()]}}],null,true)},[(_vm.awardStats[type].length)?_c('template',{slot:"pagination-bottom"},[_c('div',{staticClass:"table-footer border"},[_c('b-row',{staticClass:"justify-content-between align-items-center px-1"},[_c('b-col',{staticClass:"d-flex align-items-center"},[_c('b-form-select',{attrs:{"options":_vm.rppOptions,"disabled":_vm.awardStats[type].total < 10},on:{"change":function($event){return _vm.getAwardStats(type, _vm.awardStats[type].currentPage, _vm.rowsPerPage[type])}},model:{value:(_vm.rowsPerPage[type]),callback:function ($$v) {_vm.$set(_vm.rowsPerPage, type, $$v)},expression:"rowsPerPage[type]"}}),_c('span',{staticClass:"mx-1"},[_vm._v(_vm._s(_vm.$t('labels.rows-per-page')))])],1),_c('b-col',[_c('div',{staticClass:"right-side float-right"},[_c('b-button',{staticClass:"pagination-button px-0",attrs:{"variant":"light","disabled":!_vm.awardStats[type].prevPage},on:{"click":function($event){return _vm.getAwardStats(type, _vm.awardStats[type].currentPage - 1)}}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$tc('navigations.prev'))+" ")],1),_c('span',{staticClass:"pages p-1"},[_vm._v(" "+_vm._s(_vm.awardStats[type].from)+" - "+_vm._s(_vm.awardStats[type].to)+" of "+_vm._s(_vm.awardStats[type].total)+" ")]),_c('b-button',{staticClass:"pagination-button px-0",attrs:{"variant":"light","disabled":!_vm.awardStats[type].nextPage},on:{"click":function($event){return _vm.getAwardStats(type, _vm.awardStats[type].currentPage + 1)}}},[_vm._v(" "+_vm._s(_vm.$tc('navigations.next'))+" "),_c('feather-icon',{attrs:{"icon":"ChevronRightIcon"}})],1)],1)])],1)],1)]):_vm._e(),_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.$t('badge-reward.no-reward-received',{type}))+" ")])],2)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }