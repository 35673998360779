<template>
  <div>
    <b-row class="ml-1">
      <b-col
        class="border p-1"
        lg="6"
      >
        <h4>
          {{ $t('curriculum-pages-module.fib-drowdown-rules') }}
        </h4>
        <b-row>
          <b-col>
            <b-form-group :label="$t('curriculum-pages-module.hide-hint')">
              <b-form-checkbox
                v-model="setInput.fib_dropdown_rules.hide_hints"
                value="1"
                switch
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('curriculum-pages-module.hide-image-hints')">
              <b-form-checkbox
                v-model="setInput.fib_dropdown_rules.hide_image_hints"
                switch
                value="1"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('curriculum-pages-module.hide-text-hints')">
              <b-form-checkbox
                v-model="setInput.fib_dropdown_rules.hide_text_hints"
                switch
                value="1"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('curriculum-pages-module.disable-random-hints')">
              <b-form-checkbox
                v-model="setInput.fib_dropdown_rules.disable_random_hints"
                switch
                value="1"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        class="border p-1"
        lg="6"
      >
        <h4>
          {{ $t('curriculum-pages-module.fib-rules') }}
        </h4>
        <b-row>
          <b-col>
            <b-form-group :label="$t('curriculum-pages-module.hide-hint')">
              <b-form-checkbox
                v-model="setInput.fib_rules.hide_hints"
                value="1"
                switch
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('curriculum-pages-module.image-hint')">
              <b-form-checkbox
                v-model="setInput.fib_rules.hide_text_hints"
                value="1"
                switch
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('curriculum-pages-module.hide-text-hints')">
              <b-form-checkbox
                v-model="setInput.fib_rules.hide_image_hints"
                value="1"
                switch
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('curriculum-pages-module.disable-random-hints')">
              <b-form-checkbox
                v-model="setInput.fib_rules.disable_random_hints"
                switch
                value="1"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormCheckbox, BFormGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
  },
  props: {
    setInput: {
      type: Object,
      required: true,
    },
  },
}
</script>
