<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button
        variant="outline-primary"
        @click="toggleModal = 'team-modal'"
      >
        <feather-icon icon="PlusIcon" />  {{ $t('partner-module.add') }}
      </b-button>
    </div>
    <b-card class="mt-2">
      <b-card-header>{{ $t('partner-module.company-partner') }}</b-card-header>
      <b-overlay :show="isProcessing">
        <b-table
          responsive
          show-empty
          :fields="fields"
          :items="partners"
        >
          <template #cell(logo)="data">
            <b-img
              :src="data.item.logo"
              fluid
              thumbnail
              style="height: auto; min-width: 100px"
            />
          </template>
          <template #cell(client_url)="data">
            <a
              :href="data.item.client_url"
              target="_blank"
            >
              {{ data.item.displayClientUrl }}
            </a>
          </template>
          <template #cell(action)="data">
            <b-button
              class="icon-only"
              variant="outline-primary"
              size="sm"
              @click="toggleModal= 'team-modal';partnerInfo = {...data.item}"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              class="icon-only ml-1"
              variant="outline-danger"
              size="sm"
              @click="partnerToDelete= data.item.id"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>
          <template #empty="{} ">
            <div>
              {{ $t('messages.no-data-available') }}
            </div>
          </template>
        </b-table>
      </b-overlay>
    </b-card>

    <update-partner
      :show="toggleModal === 'team-modal'"
      :partner-info="partnerInfo"
      @reFetch="getPartners"
      @close="toggleModal = '';partnerInfo = null"
    />
    <delete-modal
      :show="!! partnerToDelete"
      :is-processing-delete="isProcessingDelete"
      name="partner-delete"
      :title="$t('delete')"
      :sub-title="$t('partner-module.remove-partner-sub-title')"
      :on-delete="onDelete"
    />
  </div>
</template>
<script>
import {
  BButton, BTable, BCard, BCardHeader, BImg, BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import UpdatePartner from './Update.vue'

export default {
  components: {
    BButton,
    UpdatePartner,
    BTable,
    BCard,
    BCardHeader,
    BImg,
    BOverlay,
    DeleteModal,
  },
  data() {
    return {
      partnerBeingEdit: '',
      toggleModal: '',
      partnerInfo: null,
      isProcessing: false,
      fields: [
        {
          key: 'logo',
          label: i18n.tc('partner-module.logo'),
        },
        {
          key: 'name',
          label: i18n.tc('partner-module.name'),
        },
        {
          key: 'client_url',
          label: i18n.tc('partner-module.client-url'),
        },
        {
          key: 'address',
          label: i18n.tc('partner-module.address'),
        },
        {
          key: 'partnership_date',
          label: i18n.tc('partner-module.partnership-date'),
        },
        {
          key: 'slogan',
          label: i18n.tc('partner-module.slogan'),
        },
        {
          key: 'email',
          label: i18n.tc('partner-module.email'),
        },
        {
          key: 'action',
          label: i18n.tc('partner-module.action'),
          tdClass: 'text-nowrap',
        },
      ],
      partners: [],
      partnerToDelete: null,
      isProcessingDelete: false,
    }
  },
  created() {
    this.getPartners()
  },
  methods: {
    getPartners() {
      this.isProcessing = false
      useJwt.getGecPartners().then(response => {
        this.partners = response.data.data.partners.map(partner => ({
          ...partner,
          client_url: `/login?company=${partner.client_token}`,
          displayClientUrl: `${partner.domain ? this.checkDomainUrl(partner.domain) : window.location.host}/login?company=${partner.client_token}`,
        }))
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    onDelete() {
      this.isProcessingDelete = false
      useJwt.deleteGecPartner(this.partnerToDelete).then(response => {
        this.showSuccessMessage(response)
        this.this.partnerToDelete = null
        this.getPartners()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessingDelete = false
      })
    },
    checkDomainUrl(domain) {
      return domain && (domain.startsWith('https://') || domain.startsWith('http://')) ? domain : `https://${domain}`
    },
  },
}
</script>
