<template>
  <div>
    <span>{{ $t('send-notification-module.select-teacher') }}</span>
    <b-form-select
      v-model="selected"
      style="margin-top:6px;"
      :options="options"
      @input="$emit('selected',selected)"
    />
  </div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue'

export default {
  components: { BFormSelect },
  data() {
    return {
      selected: null,
      options: [
        {
          value: null, text: 'All',
        },
        {
          value: 1, text: 'Nursery',
        },
        {
          value: 2, text: 'LKG',
        },
        {
          value: 3, text: 'UKG',
        },
        {
          value: 4, text: 'Class 1',
        },
      ],
    }
  },
}
</script>

<style>

</style>
