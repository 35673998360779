<template>

  <div
    class="image-upload-main w-100 bg-light-primary"
    :style="`height:${height}px;`"
  >
    <b-img
      v-if="image"
      :src="image"
      class="w-100 h-100 uploaded-image"
    />
    <input
      :ref="`image-uploader-${id}`"
      type="file"
      style="height:0px;width:0px;opacity:0px"
      @change="imageSelected"
    >
    <div v-if="hasOnMultiImageCheckBox"
         class="select-btn"
    >
      <b-form-checkbox style="zoom: 1.2;"
                       @change="v => $emit('onImageSelected', v)"
      />
    </div>
    <div class="add-button">
      <b-button
        ref="imageUploadButton"
        variant="primary"
        @click="$refs[`image-uploader-${id}`].click()"
      >
        <feather-icon
          icon="UploadIcon"
          class="mr-50"
        />
        <span
          v-if="!smallButton"
          class="align-middle"
        >{{ image ? `${$t('actions.change')} ${title}` :`${$t('actions.upload')} ${title}` }}</span>
      </b-button>
    </div>
    <div
      v-show="image || alwaysShowDelete"
      class="delete-button"
    >
      <b-button
        variant="danger"
        size="sm"
        @click="removeImage"
      >
        <feather-icon
          icon="TrashIcon"
        />
      </b-button>
    </div>
    <div
      v-if="!!generatorText"
      class="mb-1 mr-1 image-generate"
    >
      <b-button
        variant="success"
        size="sm"
        @click="showGenerateImage = true"
      >
        <feather-icon
          icon="RefreshCwIcon"
        />
      </b-button>
    </div>
    <div
      v-if="hasOnSelectedFromLibraryEvent"
      class="image-library-pick mb-1"
    >
      <b-button
        variant="primary"
        size="sm"
        @click="showImageLibrary = true"
      >
        <feather-icon
          icon="ImageIcon"
        />
      </b-button>
    </div>
    <ImageLibraryPicker :show="showImageLibrary"
                        @close="showImageLibrary = false"
                        @pickImage="handlePickFromLibrary"
    />
    <WordImageGenerator v-if="showGenerateImage && generatorText"
                        :is-processing="isSaving"
                        :word="generatorText"
                        :source-from="`cb`"
                        @close="showGenerateImage = false"
                        @pickImage="handlePickFromImageGenerator"
    />
  </div>
</template>

<script>
import {
  BButton, BImg, BFormCheckbox,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import WordImageGenerator from '@/views/super/ai-ide/stable-diffusion/WordImageGenerator.vue'
import ImageLibraryPicker from './ImageLibraryPick.vue'

export default {
  components: {
    BButton, BImg, ImageLibraryPicker, WordImageGenerator, BFormCheckbox,
  },
  props: {
    height: {
      type: Number,
      default: 300,
    },
    title: {
      type: String,
      default: 'Image',
    },
    url: {
      type: String,
      default: null,
    },
    smallButton: {
      type: Boolean,
      default: false,
    },
    alwaysShowDelete: {
      type: Boolean,
      default: false,
    },
    generatorText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      id: Math.random().toString(36).substr(2, 5),
      isUploadingImage: false,
      imageFile: null,
      image: this.url,
      previousImage: null,
      showImageLibrary: false,
      showGenerateImage: false,
      isSaving: false,
    }
  },
  computed: {
    hasOnSelectedFromLibraryEvent() {
      return this.$listeners && this.$listeners.onSelectedFromLibrary
    },
    hasOnMultiImageCheckBox() {
      return this.$listeners && this.$listeners.onImageSelected
    },
  },
  watch: {
    url(url) {
      if (url === 'https://via.placeholder.com/350x150') {
        this.$refs[`image-uploader-${this.id}`].value = null
        this.image = null
      }
      this.image = url
    },
    imageFile() {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          if (this.imageFile.size > 1024 * 1024 * 2) {
            this.showError('Please select images less than 2MB')
            this.$refs[`image-uploader-${this.id}`].value = null
            return
          }
          this.previousImage = this.image
          this.image = reader.result
          this.$emit('imageUploaded', this.imageFile, this.image)
        },
        false,
      )
      if (this.imageFile) {
        if (/\.(jpe?g|png|gif)$/i.test(this.imageFile.name)) {
          reader.readAsDataURL(this.imageFile)
        } else {
          this.showError(i18n.tc('messages.invalid-image'))
        }
      }
    },
  },
  methods: {
    handlePickFromLibrary(item) {
      this.$emit('onSelectedFromLibrary', item.image, item)
      this.showImageLibrary = false
    },
    removeImage() {
      this.imageFile = null
      this.image = null
      this.previousImage = null
      this.$refs[`image-uploader-${this.id}`].value = null
      this.$emit('imageRemoved')
    },
    imageSelected(e) {
      if (!e.target.files[0]) return
      // eslint-disable-next-line prefer-destructuring
      this.imageFile = e.target.files[0]
    },
    handlePickFromImageGenerator(image) {
      this.$emit('onSelectedFromLibrary', image)
      this.showGenerateImage = false
    },
  },
}
</script>

<style lang="css" scoped>
.image-upload-main{
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
}
.uploaded-image{
    position: absolute;
    object-fit: cover;
    z-index: 1;
    opacity: 0.5;
    border-radius: 8px;
}
  .add-button,.delete-button,.image-library-pick, .image-generate {
    z-index: 2;
  }
.delete-button{
  position: absolute;
  left: 8px;
  /* right: 8px; */
  top: 8px;
}
.image-library-pick {
  position: absolute;
  right: 18px;
  top: 8px;
}
.image-generate {
  position: absolute;
  right: 60px;
  top: 8px;
}
.select-btn {
  position: absolute;
  right: 130px;
  top: 8px;
}
</style>
