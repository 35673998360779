<template>
  <b-modal
    id="modal-no-backdrop"
    ref="my-modal"
    v-model="show"
    :title="`${$t('image-library-module.upload-csv-file')}`"
    ok-title="Accept"
    no-close-on-backdrop
    content-class="shadow"
    @close="$emit('close')"
    @hide="$emit('close')"
    @ok="handleOK"
  >
    <validation-observer ref="uploadRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <p class="text-muted"
               style="font-size:13px; font-style: italic;"
            >
              <feather-icon icon="InfoIcon" />
              {{ $t('image-library-module.upload-info') }}
            </p>
          </b-col>
          <b-col md="12">
            <validation-provider
              v-slot="{ errors }"
              name="Csv File"
              rules="required"
            >
              <b-form-file
                ref="uploadInput"
                v-model="excelFile"
                :browse-text="$t('browseButton')"
                :placeholder="$t('issue-report-module.choose-a-file')"
                no-drop
                accept=".csv"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <template #modal-footer="{ }">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="info"
            :disabled="isProcessing"
            @click="downloadCSV"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('image-library-module.download-sample-csv') }}
          </b-button>
          <b-button
            variant="primary"
            :disabled="isProcessing"
            class="ml-1"
            @click="handleOK"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('actions.upload') }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BForm, BRow, BCol, BFormFile, BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'BulkUploadModal',
  components: {
    BModal,
    BForm,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BButton,
    BSpinner,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      excelFile: null,
      isProcessing: false,
    }
  },
  methods: {
    handleOK() {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
      this.$refs.uploadRules.validate().then(success => {
        if (success) {
          this.isProcessing = true
          const data = new FormData()
          data.append('csv_file', this.$refs.uploadInput.files[0])
          useJwt.imageLibraryBulkUpload(data, config).then(response => {
            this.showSuccessMessage(response)
            this.excelFile = null
            this.$emit('close')
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => { this.isProcessing = false })
        }
      })
    },
    sampleCsv() {
      const sampleUsers = [
        {
          image: 'https://edu.globalenglishcampus.com/storage/curriculum/page1163/62ed8c3e55966.png',
          audio: 'https://edu.globalenglishcampus.com/storage/library/64549618d87961683265048.mpeg',
          vocabWord: 'Persuade',
          sentenceText: 'locate',
          sentenceAudio: 'https://edu.globalenglishcampus.com/storage/library/64549618d91d51683265048.mpeg',
          descriptionText: 'locate',
          descriptionAudio: 'https://edu.globalenglishcampus.com/storage/library/64549618d91d51683265048.mpeg',
          synonymsText: 'find',
          synonymsAudio: 'https://edu.globalenglishcampus.com/storage/library/64737dce779cc1685290446.mpeg',
        },

        {
          image: 'https://edu.globalenglishcampus.com/storage/curriculum/page1163/62ed8c3e55966.png',
          audio: 'https://edu.globalenglishcampus.com/storage/library/64549618d87961683265048.mpeg',
          vocabWord: 'Persuade',
          sentenceText: 'locate',
          sentenceAudio: 'https://edu.globalenglishcampus.com/storage/library/64549618d91d51683265048.mpeg',
          descriptionText: 'locate',
          descriptionAudio: 'https://edu.globalenglishcampus.com/storage/library/64549618d91d51683265048.mpeg',
          synonymsText: 'find',
          synonymsAudio: 'https://edu.globalenglishcampus.com/storage/library/64737dce779cc1685290446.mpeg',
        },

      ]

      const JsonFields = ['image', 'audio', 'vocabWord', 'sentenceText', 'sentenceAudio', 'descriptionText', 'descriptionAudio', 'synonymsText', 'synonymsAudio']
      let csvStr = `${JsonFields.join(',')}\n`
      sampleUsers.forEach(element => {
        const { image } = element
        const { audio } = element
        const { vocabWord } = element
        const { sentenceText } = element
        const { sentenceAudio } = element
        const { descriptionText } = element
        const { descriptionAudio } = element
        const { synonymsText } = element
        const { synonymsAudio } = element
        csvStr += `${image},${audio},${vocabWord},${sentenceText},${sentenceAudio},${descriptionText},${descriptionAudio},${synonymsText},${synonymsAudio}\n`
      })
      return csvStr
    },
    downloadCSV() {
      const csvStr = this.sampleCsv()
      const hiddenElement = document.createElement('a')
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvStr)}`
      hiddenElement.target = '_blank'
      const name = 'gec_image_library_sample.csv'
      hiddenElement.download = name
      hiddenElement.click()
    },
  },
}
</script>

<style scoped>

</style>
