import moment from 'moment'
import i18n from '@/libs/i18n'

export default {
  generateGreetings() {
    const currentHour = moment().format('HH')

    if (currentHour >= 0 && currentHour < 12) {
      return i18n.tc('morning')
    } if (currentHour >= 12 && currentHour < 16) {
      return i18n.tc('afternoon')
    } if (currentHour >= 16) {
      return i18n.tc('evening')
    }
    return i18n.tc('day')
  },
}
