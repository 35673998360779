<template>
  <!-- create  -->
  <b-modal
    id="modal-create-edit"
    ref="modal-create-edit"
    v-model="isVisible"
    ok-only
    size="md"
    ok-variant="primary"
    :ok-title="$t('confirm')"
    modal-class="modal-primary"
    centered
    :title="$t(`standard-module.${mode}-subject`)"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-col md="12">
      <!-- form -->
      <validation-observer ref="subjectModalRef">
        <b-form @submit.prevent="submitData">
          <b-row>
            <!-- name -->
            <b-col md="12">
              <b-form-group
                :label="$t('standard-module.name')"
                label-for="tem-name"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="name"
                  rules="required"
                  vid="name"
                >
                  <b-form-input
                    id="item_name"
                    v-model="localSubject.name"
                    name="item_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- name -->
          </b-row>
        </b-form>
      </validation-observer>
    </b-col>
    <template #modal-footer="{}">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="isProcessing"
            @click="submitData()"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t("actions." + mode) }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
  <!-- create  -->
</template>

<script>
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BSpinner,
  BRow,
  BCol,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BFormGroup,
    BRow,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    subject: {
      type: Object,
      default: () => {},
    },
    gradeId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isProcessing: false,
      localSubject: {
        name: '',
      },
      isVisible: this.show,
    }
  },
  computed: {
    mode() {
      return this.subject ? 'update' : 'create'
    },
  },
  watch: {
    show() {
      this.isVisible = this.show
    },
    subject() {
      if (this.subject) {
        this.localSubject = JSON.parse(JSON.stringify(this.subject))
      } else this.localSubject = { name: '' }
    },
  },
  methods: {
    createSubject() {
      this.isProcessing = true
      useJwt
        .createStandardSubject({
          ...{ grade_id: this.gradeId, score: 0 },
          ...this.localSubject,
        })
        .then(res => {
          this.showSuccessMessage(res)
          this.localSubject = { name: '' }
          this.$emit('subjectCreated')
        })
        .catch(e => {
          if (e.response?.data?.errors) this.$refs.subjectModalRef.setErrors(e.response.data.errors)
          else this.showErrorMessage(e)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    updateSubject() {
      this.isProcessing = true
      useJwt
        .updateStandardSubject(this.localSubject.id, {
          ...{ grade_id: this.gradeId },
          ...this.localSubject,
        })
        .then(res => {
          this.showSuccessMessage(res)
          this.$emit('subjectUpdated')
        })
        .catch(e => {
          if (e.response?.data?.errors) this.$refs.subjectModalRef.setErrors(e.response.data.errors)
          else this.showErrorMessage(e)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    submitData() {
      this.$refs.subjectModalRef.validate().then(success => {
        if (success) {
          if (this.mode === 'create') this.createSubject()
          else this.updateSubject()
        }
      })
    },
  },
}
</script>

<style>
</style>
