<template>
  <component
    :is="componentType"
    id="model-change-modal"
    ref="modelChangeModal"
    :title="'Choose Your Outfit'"
    size="xl"
    hide-footer
    @hide="$emit('close')"
    @close="$emit('close')"
  >
    <b-row v-if="animationFile">
      <b-col
        cols="4"
        :class="componentType=='b-modal' && 'h-100'"
      >
        <b-card :style="`min-height:300px;`">
          <h4>{{ $t('engagement-setting-module.outfits') }}:  </h4>
          <template v-if="Object.keys(allOutfits).length && !isProcessing">
            <b-row v-for="outfits,outfitType in allOutfits"
                   :key="outfitType"
                   class="py-1"
            >
              <b-col cols="12"
                     class=""
              >
                <h5>{{ getWearType(outfitType) }}</h5>
              </b-col>
              <b-col v-for="item,i in outfits"
                     :key="item.code_name + i"
                     cols="2"
                     class="mt-1"
              >
                <b-avatar
                  :src="item.image"
                  class="w-100"
                  :class="componentType=='b-modal' && 'h-100'"
                  :badge="selectedOutfits[animationFile.character] &&selectedOutfits[animationFile.character][outfitType]==item.code_name && '✓'"
                  badge-variant="success"
                  button
                  @click="addOrRemoveOutfits(outfitType,item.code_name)"
                /></b-col>
            </b-row>
          </template>
          <b-col v-else-if="isProcessing"
                 cols="12"
                 class="d-flex h-100 align-items-center justify-content-center"
          >
            <b-spinner />
          </b-col>
          <b-row v-else>
            <b-col cols="12">
              <h5 class="text-muted">
                {{ $t('engagement-setting-module.no-outfits') }}

              </h5>
            </b-col>
          </b-row>
        </b-card>

      </b-col>
      <b-col
        v-if="animationFile"
        cols="6"
        class="text-center justify-content-center align-items-center"
      >
        <customization-animation-modal
          ref="customizationAnimationModal"
          :animation-file="animationFile.file"
          :canvas-id="`webgl-customization`"
          :character-name="animationFile.character"
          :animation-types="{idle:0}"
          :active-animation="['idle']"
          :canvas-height="500"
          :canvas-width="500"
          :camera-position="{x:0,y:1,z:2}"
          :character-type="animationFile.characterType"
          :scaling="{x:1.2,y:1.1,z:1.2}"
          :light-intensity="4"
          :rotation="true"
          :selected-outfits="selectedOutfits && selectedOutfits[animationFile.character] ? Object.values(selectedOutfits[animationFile.character]) : {}"
        />
      </b-col>
      <b-col cols="2">
        <b-card class="character-selection">
          <h5 class="text-center">
            {{ $t('engagement-setting-module.characters') }}
          </h5>
          <div v-for="character in allCharacters"
               :key="character.name"
               class="character-card d-flex align-items-center justify-content-center"
          >
            <img v-if="character.image"
                 height="130"
                 width="100"
                 :src="character.image"
                 @click="!isProcessing && selectCharacter(character)"
            >
          </div>
        </b-card>
      </b-col>
      <b-col cols="12">
        <hr>
        <b-row class="d-flex justify-content-around">
          <b-col cols="5">
            <trading @onBuy="fetchMyItems()" />
          </b-col>
          <b-col cols="5">
            <trading-board @onBuy="fetchMyItems()" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </component></template>

<script>
import {
  BRow, BCol, BCard, BAvatar, BSpinner, BModal,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import CustomizationAnimationModal from '@/layouts/components/CustomizationAnimationModal.vue'
import Trading from '@/views/v2/trading/Index.vue'
import TradingBoard from '@/views/v2/trading/components/TradingBoard.vue'
import {
  ALL_FOREST_CHARACTERS, wearableTypes, FOREST_CHARACTERS,
  ALL_KIDS_CHARACTERS, ALL_ANIMALS_CHARACTERES,
  ALL_OCEAN_OUTFIT, ALL_COWBOY_OUTFIT, ALL_SPACE_OUTFIT,
} from '@/const/theme'

export default {
  components: {
    BModal,
    CustomizationAnimationModal,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BSpinner,
    Trading,
    TradingBoard,

  },
  props: {
    toggle: {
      type: [Boolean, String],
      default: false,
    },
    animationCharacter: {
      type: [Array, Object],
      default: () => {},
    },
    editCharacter: {
      type: [Boolean, String],
      default: false,
    },
    currentTheme: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      allOutfits: {},
      allCharacters: ALL_FOREST_CHARACTERS,
      FOREST_CHARACTERS,
      // ALL_SPACE_OUTFIT,
      // ALL_COWBOY_OUTFIT,
      // ALL_OCEAN_OUTFIT,
      // ALL_ANIMALS_CHARACTERES,
      // ALL_KIDS_CHARACTERS,
      animationFile: null,
      wearableTypes,
      componentType: 'b-modal',
      isProcessing: false,
      defultItemsCode: {
        head: '36',
        hair: '37',
        body: '62',
        foot: '63',
      },
      defaultItemForCharacters: [
        'karen',
        'bill',
        'alice',
        'julie',
        'kevin',
        'mike',
      ],
    }
  },

  computed: {
    selectedOutfits() {
      return this.$store.state.appConfig.selectedOutfits
    },
  },

  watch: {
    toggle(value) {
      if (value === true) {
        this.showModal()
        this.$nextTick(() => {
          this.isProcessing = true
          setTimeout(() => {
            this.fetchMyItems()
          }, 3000)
        })
      } else {
        this.hideModal()
      }
    },
    animationCharacter() {
      this.animationFile = this.animationCharacter
    },
    animationFile() {
      this.selectedOutfits = this.$store.state.appConfig.selectedOutfits
      if (!this.selectedOutfits[this.animationFile.character]) {
        this.selectedOutfits[this.animationFile.character] = []
      }
    },
  },
  mounted() {
    this.themeWiseCharacters(this.editCharacter, this.currentTheme)
    if (this.AUTH_USER()) {
      this.$store.dispatch('appConfig/getSelectedOutfits')
      if (this.$route.name === 'animated-items-marketplace') {
        // eslint-disable-next-line prefer-destructuring
        this.animationFile = this.FOREST_CHARACTERS[0]
        this.componentType = 'b-row'
        this.fetchMyItems()
      } else {
        this.animationFile = this.animationCharacter
      }
    }
  },
  methods: {
    fetchMyItems() {
      this.isProcessing = true
      useJwt.myAnimatedItems(this.animationFile.character).then(res => {
        this.allOutfits = res.data.data
        if (this.editCharacter === true || this.editCharacter === 'true') {
          if (this.currentTheme !== 'forest') {
            this.getThemeWiseOutfits()
          }
        }
        this.$forceUpdate()
      }).finally(() => {
        this.isProcessing = false
      })
    },

    selectCharacter(character) {
      if (this.animationFile.character !== character.name) {
        if (this.defaultItemForCharacters.includes(character.name)) {
          this.selectedOutfits[character.name] = { ...this.selectedOutfits[character.name], ...this.defultItemsCode }
          // console.log('updated', this.selectedOutfits[character.name])
        }

        this.animationFile.file = character.file
        this.animationFile.character = character.name
        this.animationFile.sound = character.sound
        this.isProcessing = true
        const audio = new Audio(character.sound)
        audio.play()
        setTimeout(() => {
          this.fetchMyItems()
        }, 3000)
      }

      // localStorage.setItem(this.animationFile.name, character.name)
      // this.$emit('characterChanged')
    },

    showModal() {
      this.$refs.modelChangeModal.show()
    },
    hideModal() {
      this.$refs.modelChangeModal.hide()
    },
    addOrRemoveOutfits(outfitType, value) {
      // if (!this.selectedOutfits[this.animationFile.character].addOrRemoveOutfits.includes(value)) {
      //   this.selectedOutfits[this.animationFile.character].push(value)
      // } else {
      //   const index = this.selectedOutfits[this.animationFile.character].indexOf(value)
      //   this.selectedOutfits[this.animationFile.character].splice(index, 1)
      // }
      const checkTheme = ['cowboy', 'ocean_1', 'space']
      if (checkTheme.includes(this.currentTheme)) {
        this.selectedOutfits.themes[this.currentTheme][this.animationFile.character][outfitType] = value
      }
      if (this.selectedOutfits[this.animationFile.character][outfitType] === value) {
        this.selectedOutfits[this.animationFile.character][outfitType] = ''
      } else {
        this.selectedOutfits[this.animationFile.character][outfitType] = value
      }
      if ((this.currentTheme === 'ocean_1') && outfitType === 'head_wear' && value === '68') {
        if (this.selectedOutfits[this.animationFile.character][outfitType] === '68') {
          this.selectedOutfits.themes[this.currentTheme][this.animationFile.character].hair = ''
          this.selectedOutfits[this.animationFile.character].hair = ''
          console.log('remove ocean hair')
        } else {
          this.selectedOutfits.themes[this.currentTheme][this.animationFile.character].hair = '37'
          this.selectedOutfits[this.animationFile.character].hair = '37'
          console.log('add ocean hair')
        }
      }
      if ((this.currentTheme === 'space') && outfitType === 'head_wear' && value === '71') {
        if (this.selectedOutfits[this.animationFile.character][outfitType] === '71') {
          this.selectedOutfits.themes[this.currentTheme][this.animationFile.character].hair = ''
          this.selectedOutfits[this.animationFile.character].hair = ''
          console.log('remove space hair')
        } else {
          this.selectedOutfits.themes[this.currentTheme][this.animationFile.character].hair = '37'
          this.selectedOutfits[this.animationFile.character].hair = '37'
          console.log('add space hair')
        }
      }
      this.updateSelectedOutfits()
      this.$forceUpdate()
      this.$refs.customizationAnimationModal.changedOutfits()
    },

    getWearType(type) {
      return this.wearableTypes.find(wearableType => wearableType.value === type).text
    },

    updateSelectedOutfits() {
      const data = {
        selected_outfits: JSON.stringify(this.selectedOutfits),
      }
      useJwt.updateSelectedOutfits(data).then(() => {
        this.$store.commit('appConfig/UPDATE_SELECTED_OUTFITS', this.selectedOutfits)
      })
    },

    themeWiseCharacters(editCharacter, currentTheme) {
      if (editCharacter === true || editCharacter === 'true') {
        if (currentTheme === 'forest') {
          this.allCharacters = ALL_ANIMALS_CHARACTERES
        } else {
          this.allCharacters = ALL_KIDS_CHARACTERS
        }
      }
    },
    getThemeWiseOutfits() {
      const filteredData = {}
      if (this.currentTheme === 'cowboy') {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in this.allOutfits) {
        // eslint-disable-next-line no-prototype-builtins
          if (this.allOutfits.hasOwnProperty(key)) {
            filteredData[key] = this.allOutfits[key].filter(item => ALL_COWBOY_OUTFIT.includes(item.code_name))
          }
        }
      }
      if (this.currentTheme === 'ocean_1') {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in this.allOutfits) {
        // eslint-disable-next-line no-prototype-builtins
          if (this.allOutfits.hasOwnProperty(key)) {
            filteredData[key] = this.allOutfits[key].filter(item => ALL_OCEAN_OUTFIT.includes(item.code_name))
          }
        }
      }
      if (this.currentTheme === 'space') {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in this.allOutfits) {
        // eslint-disable-next-line no-prototype-builtins
          if (this.allOutfits.hasOwnProperty(key)) {
            filteredData[key] = this.allOutfits[key].filter(item => ALL_SPACE_OUTFIT.includes(item.code_name))
          }
        }
      }
      this.allOutfits = filteredData
    },

  },
}
</script>

<style lang="scss">
img{
  cursor:pointer;
}

.character-selection{
  max-height: 500px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow:scroll;
}

.character-selection::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.character-card{
  margin:5px;
  border-bottom:1px dotted black;
  img{
  padding:5px;
  &:hover{
    transform: scale(1.05);
  }
}

}

#model-change-modal{
  .modal-content{
    .modal-body{
    max-height:600px !important;
    overflow:scroll;
  }
  .modal-body::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}
  }

}
</style>
