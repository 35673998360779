export const PROBLEM_TYPE_MATCH = 'matching'
export const PROBLEM_TYPE_FILL_BLANK = 'blank'
export const PROBLEM_TYPE_FILL_DROPDOWN = 'fib_dropdown'
export const PROBLEM_TYPE_MULTIPLE = 'multiple'
export const PROBLEM_TYPE_MULTIPLE_LISTENING = 'multiple_listening'
export const PROBLEM_TYPE_MATCH_LISTENING = 'matching_listening'
export const PROBLEM_TYPE_SINGLE = 'single'
export const PROBLEM_TYPE_SPEECH = 'speech'
export const PROBLEM_TYPE_CATEGORY = 'category'
export const OPEN_END_WRITING = 'open-end-writing'
export const OPEN_END_WRITING_SPEECH = 'open-end-writing-speech'
export const LISTENING = 'listening'
export const TEXT_SELECTION = 'text_selection'
export const PROBLEM_DRAG_POSITION = 'drag_position'

export const PROBLEM_TYPES = [
  PROBLEM_TYPE_MATCH,
  PROBLEM_TYPE_FILL_BLANK,
  PROBLEM_TYPE_FILL_DROPDOWN,
  PROBLEM_TYPE_MULTIPLE,
  PROBLEM_TYPE_SINGLE,
  PROBLEM_TYPE_SPEECH,
  OPEN_END_WRITING,
  PROBLEM_DRAG_POSITION,
]

export const CURRICULUM_SET_PROBLEM_TYPES = [
  PROBLEM_TYPE_FILL_BLANK,
  PROBLEM_TYPE_FILL_DROPDOWN,
  TEXT_SELECTION,
  PROBLEM_TYPE_MULTIPLE,
  PROBLEM_TYPE_MATCH,
  // PROBLEM_TYPE_CATEGORY,
  PROBLEM_TYPE_SPEECH,
  // LISTENING,
]

export const CURRICULUM_SET_OUTPUT_PROBLEM_TYPE = [
  PROBLEM_TYPE_SPEECH,
  PROBLEM_TYPE_FILL_BLANK,
]

export const CURRICULUM_SET_READING_PROBLEM_TYPE = [
  PROBLEM_TYPE_FILL_DROPDOWN,
  TEXT_SELECTION,
  PROBLEM_TYPE_MULTIPLE,
  PROBLEM_TYPE_MATCH,
]

export const CURRICULUM_SET_LISTENING_PROBLEM_TYPE = [
  PROBLEM_TYPE_MULTIPLE_LISTENING,
  PROBLEM_TYPE_MATCH_LISTENING,
]

export const CURRICULUM_SET_SPEECH_PROBLEM_TYPE = [
  PROBLEM_TYPE_SPEECH,
  OPEN_END_WRITING_SPEECH,
]

export const CURRICULUM_SET_WRITING_PROBLEM_TYPE = [
  PROBLEM_TYPE_FILL_BLANK,
  OPEN_END_WRITING,
]

export const CURRICULUM_SET_SKILL_PROBLEM_TYPE = [
  PROBLEM_TYPE_MATCH,
  PROBLEM_TYPE_MULTIPLE,
]

export const OPEN_END_AND_OTHER = [
  'open-ended',
  'other',
  'both',
]
