<template>
  <b-modal id="sell-animation-modal"
           hide-footer
           hide-header
           centered
  >
    <validation-observer ref="sellForm">
      <div class="">
        <h3 class="text-center">
          Are you sure you want to sell it?
        </h3>
        <hr>
        <div class="text-center">
          <h4>{{ product.name }}</h4>
          <h5>(Total Available: x{{ (product.total) || 1 }})</h5>
          <img :src="product.image"
               width="150"
               height="150"
          >

          <b-row class="d-flex align-items-center justify-content-center py-2">
            <b-col cols="6"
                   class="text-left"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Expected Price"
                rules="required | min_value:1"
              >
                <label>Price per quantity</label>
                <b-form-input
                  v-model="sellingPrice"
                  placeholder="Price per quantity (10)"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-col>
            <b-col cols="4">
              <h5>Total: {{ quantity }}

                <template>
                  <feather-icon
                    v-if="quantity < ((product.total) || 1)"
                    v-b-tooltip="'Increase Quantity'"
                    icon="PlusIcon"
                    class="totalIcons cursor-pointer text-primary"
                    size="22"
                    @click="quantity++"
                  />
                  <feather-icon v-if="quantity>1"
                                v-b-tooltip="'Decrease Quantity'"
                                icon="MinusIcon"
                                class="totalIcons cursor-pointer text-danger ml-1"
                                size="22"
                                @click="quantity--"
                  />
                </template>

              </h5>
              <span v-if="sellingPrice>0">
                {{ quantity }} x ${{ sellingPrice }} = ${{ sellingPrice*quantity }}
              </span>
            </b-col>
          </b-row>

          <div class="">
            <b-button variant="outline-primary"
                      @click="sellItem()"
            >
              Sell Now
            </b-button>
            <b-button variant="outline-danger"
                      class="ml-2"
                      @click="$bvModal.hide('sell-animation-modal')"
            >
              Cancel
            </b-button>
          </div>
        </div>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,

    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    product: {
      type: [Object, Array],
      default: () => {},
    },
  },

  data() {
    return {
      sellingPrice: null,
      quantity: 1,
      required,
    }
  },

  methods: {
    sellItem() {
      const data = {
        animated_item_id: this.product.id,
        selling_price: this.sellingPrice,
        quantity: this.quantity,
      }
      useJwt.sellAnimatedItems(data).then(() => {
        this.$emit('onSell')
        this.$swal('Trading', 'Your item is added on trading system.', 'success')
      }).catch(err => {
        this.$swal('Oops!', err.response.data.message, 'error')
      }).finally(() => {
        this.$bvModal.hide('sell-animation-modal')
        this.quantity = 1
        this.sellingPrice = null
      })
    },
  },
}
</script>
<style scoped lang="scss">
legend{
  text-align: left !important;
}
</style>
