<template>
  <b-card
    no-body
    class="p-2 student-diary-header"
    style="min-height: 200px;"
  >

    <b-row>
      <b-col
        md="4"
        class="d-flex"
      >
        <div v-if="!isSelf">
          <b-button
            variant="flat-secondary"
            class="btn-icon"
            @click="GO_BACK()"
          >
            <feather-icon
              size="20"
              icon="ArrowLeftIcon"
            />
          </b-button>
        </div>
        <div>
          <b-overlay
            :show="userDetailsProcessing"
            class="d-flex"
          >
            <Avatar
              :image="userDetails.avatar"
              size="80px"
              rounded="circle"
            />
            <div class="ml-2">
              <h3>
                {{ userDetails.fullname }}
              </h3>
              <span style="font-size:14px">{{ currentTime }}</span>
            </div>
          </b-overlay>
        </div>
      </b-col>
      <b-col
        md="4"
        class="d-flex mb-1"
      >
        <div class="d-flex w-100 justify-content-center">
          <h3>
            <strong
              class="text-capitalize"
            >{{ `${$t('student-diary-module.work-diary')} ${$t('student-diary-module.block')} ${$t('student-diary-module.view')}` }}
            </strong>
            <div class="text-center">
              {{ selectedFormattedDate }}
            </div>
          </h3>
        </div>
      </b-col>
      <b-col
        md="4"
        class="d-flex align-items-end justify-content-end diary-calendar"
        style="margin-top: -20px"
      >
        <div class="d-flex align-items-center w-100 justify-content-end">
          <label
            class="btn calendar-buttons btn-sm btn-outline-secondary m-0"
            @click="getPreviousMonth"
          >
            <feather-icon
              size="18"
              icon="ChevronLeftIcon"
            />
          </label>

          <div
            class="mx-1 calendar-input pt-2"
          >
            <b-overlay :show="isProcessing">
              <month-blocks
                :key="today.getMonth()"
                :attendance="attendance"
                :year="today.getFullYear()"
                :user-id="studentId"
                :from-dashboard="true"
                :hide-title="true"
                :selected-date="$route.query.date"
                :month="today.getMonth() + 1"
              />
            </b-overlay>
          </div>
          <label
            v-if="!isCurrentMonth"
            class="btn calendar-buttons btn-sm btn-outline-secondary m-0"
            @click="getNextMonth"
          >
            <feather-icon
              size="18"
              icon="ChevronRightIcon"
            />
          </label>
        </div>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BOverlay,
} from 'bootstrap-vue'
import Avatar from '@views/common/components/Avatar.vue'
import MonthBlocks from '@/views/student/attendance/components/MonthBlocks.vue'
import { getUserData } from '@/auth/utils'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {
    BCol, BRow, Avatar, BCard, BButton, MonthBlocks, BOverlay,
  },
  props: {
    activeView: {
      type: String,
      default: 'block',
    },
    userDetails: {
      type: Object,
      required: true,
    },
    isSelf: {
      type: Boolean,
      default: false,
    },
    userDetailsProcessing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const selectedDate = this.$route.query.date || moment().format('YYYY-MM-DD')
    const studentId = this.$route.params.id ?? getUserData().id
    return {
      currentTime: '',
      fromDate: selectedDate ?? this.getToday(),
      toDate: selectedDate ?? this.getToday(),
      attendance: [],
      today: new Date(selectedDate),
      studentId,
      currentMonth: selectedDate ?? this.getToday(),
      isProcessing: false,
    }
  },
  computed: {
    isCurrentMonth() {
      const current = moment(this.currentMonth)
      const now = moment()
      return current.month() === now.month() && current.year() === now.year()
    },
    selectedFormattedDate() {
      return moment(this.currentMonth).format('MMM,D YYYY')
    },
  },
  watch: {
    '$route.query.date': function (newValue) {
      this.fromDate = this.getPreviousDay(newValue)
      this.currentMonth = newValue
      this.$emit('getStudentActivities', newValue)
    },
  },
  mounted() {
    this.$emit('getStudentActivities', this.fromDate)
    // to display time in the header
    this.getNow()
    setInterval(this.getNow, 1000)
    this.getAttendance()
  },
  methods: {
    setNextDay() {
      this.fromDate = this.getNextDay(this.fromDate)
      this.toDate = this.getNextDay(this.toDate)
      this.$emit('getStudentActivities', this.fromDate)
    },
    setPreviousDay() {
      this.fromDate = this.getPreviousDay(this.fromDate)
      this.toDate = this.getPreviousDay(this.toDate)
      this.$emit('getStudentActivities', this.fromDate)
    },
    getNow() {
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`
      this.currentTime = dateTime
    },
    getToday() {
      return (new Date()).toISOString().split('T')[0]
    },
    getNextDay(currentDate) {
      const tomorrow = new Date(currentDate)
      tomorrow.setDate(tomorrow.getDate() + 1)
      return tomorrow.toISOString().split('T')[0]
    },
    getPreviousDay(currentDate) {
      const yesterday = new Date(currentDate)
      yesterday.setDate(yesterday.getDate() - 1)
      return yesterday.toISOString().split('T')[0]
    },
    getNextMonth() {
      this.currentMonth = moment(this.currentMonth).add(1, 'M').format('YYYY-MM-DD')
      this.getAttendance()
      this.today = new Date(this.currentMonth)
    },
    getPreviousMonth() {
      this.currentMonth = moment(this.currentMonth).subtract(1, 'M').format('YYYY-MM-DD')
      this.getAttendance()
      this.today = new Date(this.currentMonth)
    },
    getAttendance() {
      const params = {
        user_id: this.studentId,
        start_date: moment(this.currentMonth).startOf('month').format('YYYY-MM-DD'),
        end_date: moment(this.currentMonth).endOf('month').format('YYYY-MM-DD'),
      }
      this.isProcessing = true
      useJwt.getStudentAttendance({
        params,
      }).then(response => {
        const attendance = response.data.data.map(day => moment(day).format('YYYY-MM-DD'))
        this.attendance = [...new Set(attendance)]
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>

<style lang="scss">
// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  label#datepicker-placeholder-from__value_{
    padding:4px;
    font-size:10px !important;
  }
  #datepicker-placeholder-from{
    padding: 4px !important;
  }
  .datepicker-placeholder-from__outer_{
    width:100%;
  }
  .calendar-input{
    flex:1;
    margin:0 4px !important;
  }
}
.student-diary-header{
  .b-form-datepicker{
  label{
    line-height: 2.2 !important;
  }
}
}
.calendar-buttons{
  padding:8px;
}

#datepicker-placeholder-from__dialog_{
  z-index: 1111111111 !important;
}
.diary-calendar {
    width: 300px !important;

}
</style>
