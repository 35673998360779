<template>
  <b-row>
    <b-col
      v-for="essay,index of studentEssay"
      :key="index"
      md="4"
    >
      <b-card
        no-body
        @click="lessonIdToShow = essay.lesson.id"
      >
        <b-card-img
          v-if="essay.lesson.image"
          :src="essay.lesson.image"
          style="height: 160px"
          img-top
        />
        <b-card-body>
          <h4 class="mb-2">
            {{ essay.lesson.name }}
            <small>
              <b-badge
                small
                :variant="essayStatus(essay.status).badgeVariant"
              >
                {{ essayStatus(essay.status).title }}
              </b-badge>
            </small>
            <br>
            <small v-if="essay.user">
              <feather-icon icon="UserIcon" /> {{ $t('essay-builder-module.essay-detail.author') }}: {{ essay.user.firstname }} {{ essay.user.lastname }}
            </small><br>
            <small v-if="essay.published_date">
              <feather-icon icon="ClockIcon" /> {{ $t('essay-builder-module.essay-detail.published-date') }}:  {{ FORMAT_DATE(essay.published_date, 'MMM D, YYYY') }}
            </small><br>
            <small v-if="essay.score">
              <feather-icon icon="CircleIcon" />  {{ $t('essay-builder-module.essay-detail.score') }}: {{ essay.score }}
            </small>
            <br v-if="essay.score">
            <small v-if="essay.teacher">
              <feather-icon icon="UserIcon" /> {{ $t('essay-builder-module.essay-detail.reviewed-by') }}: {{ essay.teacher.firstname }} {{ essay.teacher.lastname }}
            </small><br>
            <small v-if="essay.reviewed_at">
              <feather-icon icon="CalendarIcon" />  {{ $t('essay-builder-module.essay-detail.reviewed-on') }}: {{ FORMAT_DATE(essay.reviewed_at, 'MMM D, YYYY') }}
            </small>
          </h4>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col v-if="studentEssay.length === 0">
      <b-card>
        {{ $t('essay-builder-module.essay-detail.no-essay') }}
      </b-card>
    </b-col>
    <lesson-essay-modal
      :show="!!lessonIdToShow"
      :student-id="lessonIdToShow ? studentId : 0"
      :lesson-id="Number(lessonIdToShow)"
      @close="lessonIdToShow = null"
    />
  </b-row>
</template>
<script>
import {
  BRow, BCol, BCardImg, BCardBody, BCard, BBadge,
} from 'bootstrap-vue'
import LessonEssayModal from '@/views/common/class/components/LessonEssayModal.vue'

export default {
  components: {
    BRow, BCol, BCardImg, BCardBody, BCard, LessonEssayModal, BBadge,
  },
  props: {
    studentId: {
      type: Number,
      required: true,
    },
    studentEssay: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      lessonIdToShow: null,
    }
  },
  methods: {
    essayStatus(status) {
      if (status === 'is_draft') {
        return {
          badgeVariant: 'light-primary',
          title: 'Draft',
        }
      }
      if (status === 'for_teacher_review') {
        return {
          badgeVariant: 'light-warning',
          title: 'In Review',
        }
      }
      return {
        badgeVariant: 'light-success',
        title: 'Published',
      }
    },
  },
}
</script>
<style scoped>
.card {
  height: 85%;
}
.card:hover {
  box-shadow: 0 8px 32px 0 rgba(28, 34, 112, 0.37);
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
}
.vue-avatar--wrapper {
  width: 100% !important;
  height: 100% !important;
  font-size: 48px !important;
}
.course-list .card-title {
  font-size: 18px;
  margin-bottom: 8px;
}
.course-img-wrapper {
  height: 160px !important;
}
.course-img {
  height: 100%;
}
.course-description {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
