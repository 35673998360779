<template>
  <b-overlay :show="isLoading">
    <div
      class="card-height d-flex justify-content-center align-items-center"
      no-body
    >
      <div
        v-if="!usersCount.length"
        class="w-100 text-center"
      >
        {{ $t('user-module.no-users-found') }}
      </div>
      <swiper
        v-else
        class="w-100"
        :options="state.swiperOption"
      >
        <swiper-slide
          v-for="user in usersCount"
          :key="user.name"
        >
          <div class="d-flex align-items-center p-3">
            <b-avatar
              :class="user.type === 'deactivated' ? 'bg-light-danger mr-1' : `bg-light-${getRandomColor().color} mr-1`"
              size="48"
            >
              <feather-icon
                :icon="getIcon(user.name)"
                size="24"
              />
            </b-avatar>
            <div class="text-capitalize">
              <h2 class="text-primary mb-0"
                  :class="user.type === 'deactivated' ? 'text-danger' :''"
              >
                {{ user.total_child }}
              </h2>
              <h6 class="text-secondary"
                  :class="user.type === 'deactivated' ? 'text-danger' :''"
              >
                {{ user.name }}
              </h6>
            </div>
          </div>
        </swiper-slide>
        <div
          slot="pagination"
          class="swiper-pagination"
        />
      </swiper>
    </div>
  </b-overlay>
</template>

<script setup>

import { BOverlay, BAvatar } from 'bootstrap-vue'
import { reactive } from 'vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

defineProps({
  usersCount: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})

const state = reactive({
  swiperOption: {
    slidesPerView: 5,
    spaceBetween: 10,
    slidesPerGroup: 5,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  },
})

const getIcon = type => {
  switch (type) {
    case 'school':
    case 'company':
      return 'HomeIcon'
    case 'district':
      return 'ArchiveIcon'
    case 'curriculum':
      return 'ClipboardIcon'
    case 'national':
      return 'FlagIcon'
    case 'international':
      return 'Navigation2Icon'
    default:
      return 'UserIcon'
  }
}
</script>

<style scoped>
.card-height {
  min-height: 96px;
}
</style>
