<template>
  <div>
    <div
      v-for="(section, index) in data"
      :key="index"
      class="row justify-content-between mb-3"
    >
      <div class="col-md-8 pr-2">
        <h2
          v-text-to-speech
          @click="$emit('onClick', $event)"
          v-html="isAProblem ? formatQuestion(section.title,'title',index):section.title"
        />
        <p
          v-text-to-speech
          @click="$emit('onClick', $event)"
          v-html="isAProblem ? formatQuestion(section.subtitle,'subtitle',index):section.subtitle"
        />
      </div>
      <div class="col-md-4">
        <div class="img-wrap">
          <b-avatar
            v-if="section.image_url"
            class="img"
            :src="section.image_url"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import { checkSettings } from '@/utils/visibilitySettings'

export default {
  components: { BAvatar },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    placeHolder: {
      type: String,
      default: '',
    },
    isAProblem: {
      type: Boolean,
      default: false,
    },
    answers: {
      type: Array,
      default: () => null,
    },
    questionFormatter: {
      type: Function,
      default: null,
    },
  },
  computed: {
    copyPasteEnabled() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const setting = checkSettings('copy_paste', schoolSetting, distSetting)
      if (setting.value === '1') {
        return true
      }
      return false
    },
  },
  methods: {
    formatQuestion(question, dataString, ind) {
      if (this.questionFormatter && typeof this.questionFormatter === 'function') {
        return this.questionFormatter(question, dataString, ind)
      }
      let html = ''
      question
        .replaceAll(this.placeHolder, `$${this.placeHolder}$`)
        .split('$')
        .filter(e => e.length > 0)
        .forEach((element, index) => {
          if (element === this.placeHolder) {
            if (this.answers) {
              let answers = []
              this.answers.map(answer => answer.text).forEach(answer => {
                answers.push(answer.split(','))
              })
              answers = answers.reduce((prev, next) => prev.concat(next))

              html += `
                <select
                  :id="'Field_' + ${(index + 1)}"
                  data-id="${dataString}-${ind}-${index}"
                  style="display: inline-block; width: 100% !important;margin-top:2px"
                  class="mr-1 answer form-control"
                  :name="'Field_' + ${(index + 1)}"
                >
                <option selected disabled>Select an answer</option>
              `
              if (answers.length) {
                answers.forEach(answer => {
                  html += `<option value="${answer}">${answer}</option>`
                })
              }
              html += '</select>'
            } else {
              html += `
                <input
                  type="text"
                  :id="'Field_' + ${(index + 1)}"
                  data-id="${dataString}-${ind}-${index}"
                  style="display: inline-block; width: 100% !important;margin-top:2px"
                  placeholder="Answer"
                  class="mr-1 answer form-control"
                  :name="'Field_' + ${(index + 1)}"
                  onpaste="return ${this.copyPasteEnabled};"
                />
              `
            }
          } else {
            html += element
          }
        })
      return html
    },
  },
}
</script>

<style scoped>
.img-wrap {
  width: 100px;
  height: 100px;
  border: 2px solid #333;
  border-radius: 75px;
  background-color: #fff;
  box-shadow: 8px 5px 12px 5px #333;
}
.img-wrap .img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  background-color: #e6e6e6 !important;
}
</style>
