<template>
  <div>
    <div
      v-if="imageUrl"
      class="felx align-items-center"
    >
      <b-img
        rounded
        :src="imageUrl"
        height="120"
        width="120"
        class="my-2 mr-2"
      />
      <b-button
        variant="outline-danger"
        class="btn-icon px-1"
        @click="deleteImage"
      >
        <feather-icon icon="TrashIcon" />
      </b-button>
    </div>
    <div class="media-body">
      <b-form-group
        :label="label"
        :label-for="id"
      >
        <b-form-file
          :id="id"
          ref="fileInput"
          :placeholder="$t('labels.file-placeholder')"
          accept="image/jpeg, image/png, image/gif"
          :browse-text="$t('browseButton')"
          @change="handleStatementImage($event)"
        />
        <small>{{ $t('labels.create-problem.image-allowed') }}</small>
      </b-form-group>
      <b-form-input
        v-if="showCaption"
        v-model="captionValue"
        placeholder="Image Caption"
        @change="emitImage"
      />
    </div>
  </div>
</template>

<script>
import {
  BFormGroup, BImg, BFormFile, BButton, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BImg,
    BFormFile,
    BButton,
    BFormInput,
  },
  props: {
    label: {
      type: String,
      default: () => '',
    },
    url: {
      type: String,
      default: () => '',
    },
    id: {
      type: String,
      default: () => '',
    },
    caption: {
      type: String,
      default: '',
    },
    showCaption: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    imageUrl: '',
    image: '',
    captionValue: '',
    maxMB: 10,
  }),
  watch: {
    url(value) {
      this.imageUrl = value
    },
    caption(value) {
      this.captionValue = value
    },
  },
  created() {
    this.imageUrl = this.url
    this.captionValue = this.caption
  },
  methods: {
    handleStatementImage(event) {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.imageUrl = reader.result
          this.emitImage()
          //   this.problem[type].url.push("");
          //   this.problem[type].url.pop();
        },
        false,
      )
      if (event.target.files[0]) {
        const file = event.target.files[0]
        if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
          if (file.size <= 1024 * 1024 * this.maxMB) {
            this.image = file
            reader.readAsDataURL(file)
          } else {
            this.$emit('fileSizeInvalid', `Image may not be greater that ${this.maxMB} MB`)
            this.$refs.fileInput.reset()
          }
        } else {
          this.$emit('fileTypeInvalid', 'Image must be of file type: jpg, gif and png')
          this.$refs.fileInput.reset()
        }
      }
    },
    deleteImage() {
      this.$refs.fileInput.reset()
      this.imageUrl = null
      this.image = null
      this.emitImage()
    },
    emitImage() {
      this.$emit('input', { image: this.image, url: this.imageUrl, caption: this.captionValue })
    },
  },
}
</script>
