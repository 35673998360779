<template>
  <b-modal id="buy-animation-modal"
           hide-footer
           hide-header
           centered
  >
    <div class="">
      <h3 class="text-center">
        {{ $t('engagement-setting-module.buy-confirmation') }}
      </h3>
      <hr>
      <b-row
        class="d-flex align-items-center product-row"
      >

        <img
          height="100"
          width="100"
          :src="product.image"
        >

        <b-col cols="8">
          <h4>
            {{ product.name }}
          </h4>
          <h5 class="text-dark price-info">
            <strong>${{ product.price }}</strong>
          </h5>
          <p>
            {{ product.description }}
          </p>
        </b-col>
      </b-row>
      <div class="text-center">
        <div class="">
          <b-button variant="outline-primary"
                    @click="buyItem()"
          >
            {{ $t('engagement-setting-module.buy-now') }}
          </b-button>
          <b-button variant="outline-danger"
                    class="ml-2"
                    @click="$bvModal.hide('buy-animation-modal')"
          >
            {{ $t('engagement-setting-module.cancel') }}
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BRow, BCol,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BModal,
    BButton,
    BCol,
    BRow,
  },

  props: {
    product: {
      type: [Object, Array],
      default: () => {},
    },
  },
  methods: {
    buyItem() {
      useJwt.buyAnimatedItems(this.product.id).then(() => {
        this.$emit('onBuy')
        this.$swal('Congratulation', 'Item added on your list.', 'success')
      }).catch(err => {
        this.$swal('Oops!', err.response.data.errors, 'error')
      }).finally(() => {
        this.$bvModal.hide('buy-animation-modal')
      })
    },
  },
}
</script>
