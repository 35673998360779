import Ripple from 'vue-ripple-directive'
import { Fancybox } from '@fancyapps/ui'
import common from '@/utils/common'
import GEC_EVENT_BUS from '@/utils/eventBus'
import store from '../store'
import '@fancyapps/ui/dist/fancybox/fancybox.css'

const synth = window.speechSynthesis

const speak = msg => {
  if (synth.speaking) {
    synth.cancel()
    return
  }
  const sp = new SpeechSynthesisUtterance(msg)
  sp.lang = 'en-US'
  // sp.pitch = 1
  sp.rate = 0.5
  speechSynthesis.speak(sp)
  GEC_EVENT_BUS.$off('stopTextToSpeech')
  GEC_EVENT_BUS.$on('stopTextToSpeech', () => {
    if (synth.speaking) {
      synth.cancel()
    }
  })
}
export default {
  Ripple,
  'extended-html': {
    bind(el, binding) {
      if (binding.value) {
        el.innerHTML = common.prioritizeInCss(binding.value)
      }
    },
    update(el, binding) {
      if (binding.value) {
        el.innerHTML = common.prioritizeInCss(binding.value)
      }
    },
  },
  'image-preview': {
    bind(el) {
      el.addEventListener('click', () => {
        // eslint-disable-next-line no-undef
        Fancybox.show([{
          src: el.getAttribute('src'),
          thumb: 'thumbnail-a.jpeg',
        }], {
          Images: {
            Panzoom: {
              maxScale: 100,
            },
          },
          Toolbar: {
            display: {
              left: ['infobar'],
              middle: [
                'zoomIn',
                'zoomOut',
                'toggle1to1',
                'rotateCCW',
                'rotateCW',
                'flipX',
                'fullscreen',
                'flipY',
              ],
              right: ['slideshow', 'thumbs', 'close'],
            },
          },
        })
      })
    },
  },
  'play-voice': {
    bind(el) {
      /* eslint no-param-reassign: "error" */
      const clickForSpeech = () => {
        speak(el.getAttribute('text'))
      }
      el.style.cursor = 'pointer'
      el.speechEvent = el.addEventListener('click', clickForSpeech)
    },
    unbind(el) {
      el.removeEventListener('click', el.speechEvent)
    },
  },
  'text-to-speech': {
    bind(el) {
      if (!store.state.problem.ttsSupport) {
        return
      }
      /* eslint no-param-reassign: "error" */
      const clickForSpeech = () => {
        speak(el.innerText)
      }
      el.style.cursor = 'pointer'
      el.speechEvent = el.addEventListener('click', clickForSpeech)
    },
    unbind(el) {
      el.removeEventListener('click', el.speechEvent)
    },
  },
  longpress: {
    bind(el, binding, vNode) {
      // Make sure expression provided is a function
      if (typeof binding.value !== 'function') {
        // Fetch name of component
        const compName = vNode.context.name
        // pass warning to console
        let warn = `[longpress:] provided expression '${binding.expression}' is not a function, but has to be`
        if (compName) { warn += `Found in component '${compName}' ` }

        console.warn(warn)
      }

      // Define variable
      let pressTimer = null

      // Define funtion handlers
      // Create timeout ( run function after 1s )
      const start = e => {
        if (e.type === 'click' && e.button !== 0) {
          return
        }

        if (pressTimer === null) {
          pressTimer = setTimeout(() => {
            // Run function
            // eslint-disable-next-line no-use-before-define
            handler()
          }, 1000)
        }
      }

      // Cancel Timeout
      const cancel = () => {
        // Check if timer has a value or not
        if (pressTimer !== null) {
          clearTimeout(pressTimer)
          pressTimer = null
        }
      }
      // Run Function
      const handler = e => {
        binding.value(e)
      }

      // Add Event listeners
      el.addEventListener('mousedown', start)
      el.addEventListener('touchstart', start)
      // Cancel timeouts if this events happen
      el.addEventListener('click', cancel)
      el.addEventListener('mouseout', cancel)
      el.addEventListener('touchend', cancel)
      el.addEventListener('touchcancel', cancel)
    },
  },
}
