// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
// eslint-disable-next-line import/no-cycle
import { getUserData } from '@/auth/utils'
import { USER_TYPE_STUDENT } from '@/const/userType'
import vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    isAStudent: getUserData() && getUserData().usertype === USER_TYPE_STUDENT,
    isVisible: true,
    isExpanded: false,
    problemId: null,
    isProcessing: false,
    activeNote: {},
    notes: [],
    noteKey: 1,
    problemTitle: '',
  },

  getters: {
    isExpanded: state => state.isExpanded,
    isVisible: state => state.isVisible,
    isProcessing: state => state.isProcessing,
    activeNote: state => state.activeNote,
    isAStudent: state => state.isAStudent,
    allNotes: state => state.notes,
  },

  actions: {
    closeFloatingNotes({ state }) {
      state.isVisible = false
    },
    toggleVisibility({ state }) {
      state.isVisible = !state.isVisible
    },
    toggleExpanded({ state }) {
      state.isExpanded = !state.isExpanded
    },
    deleteNote({ state, dispatch }, id) {
      // eslint-disable-next-line no-param-reassign
      state.isProcessing = true
      useJwt.deleteNote(id).then(() => {
        if (state.activeNote && id === state.activeNote.id) {
          const activeNote = JSON.parse(JSON.stringify(state.activeNote))
          activeNote.id = null
          activeNote.content = ''
          state.activeNote = activeNote
        }

        if (!state.activeNote.problem_id) state.activeNote.problem_id = state.problemId
      }).finally(() => {
        state.isProcessing = false
        dispatch('getStudentNotes')
      })
    },
    updateUser({ state }) {
      state.isAStudent = getUserData() && getUserData().usertype === USER_TYPE_STUDENT
    },
    updateNote({ state, dispatch }, content) {
      state.isProcessing = true
      const data = {
        problem_id: state.activeNote.problem_id || state.problemId,
        title: state.activeNote.problem_name || state.problemTitle,
        content,
      }
      if (state.activeNote.id) {
        useJwt.updateNote(state.activeNote.id, data).then(res => {
          dispatch('getStudentNotes')
          vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `${res.data.message}`,
            },
          },
          {
            position: window.innerWidth <= 576 ? 'bottom-left' : 'bottom-left',
          })
        }).finally(() => {
          state.isProcessing = false
        })
        return
      }
      useJwt.createNote(data).then(res => {
        const note = res.data.data
        state.activeNote = {
          id: note.id,
          content: note.content,
          problem_name: note.problem.name,
          problem_id: note.problem.id || state.problemId,
        }
        dispatch('getStudentNotes')
        vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: `${res.data.message}`,
          },
        },
        {
          position: window.innerWidth <= 576 ? 'bottom-left' : 'bottom-left',
        })
      }).finally(() => {
        state.isProcessing = false
      })
    },
    getNotesForProblem({ state }, problemId) {
      state.problemId = problemId
      if (!problemId) return
      useJwt.getNotesForProblem(problemId).then(res => {
        state.activeNote = res.data.data.note ? res.data.data.note : { content: '', id: null }
        state.activeNote.problem_name = res.data.data.name
        state.activeNote.problem_id = res.data.data.id
      })
    },
    // eslint-disable-next-line no-unused-vars
    getStudentNotes({ state }) {
      state.noteKey += 1
      useJwt.getNotes().then(res => {
        const notes = res.data.data.map(e => ({
          id: e.id,
          problem_name: e?.problem?.name,
          problem_id: e?.problem?.id,
          content: e?.content,
          lesson_name: e?.problem?.lesson?.name,
        }))

        state.notes = notes
      })
    },
    showFloatingNotes({ state }) {
      state.isVisible = true
      state.isExpanded = false
    },
    hideFloatingNotes({ state }) {
      state.isVisible = false
      state.isExpanded = false
    },
    displayNote({ state }, data) {
      state.isExpanded = true
      state.isVisible = true
      state.activeNote = data
    },
  },

  mutations: {
    SET_PROBLEM_ID(state, data) {
      state.problemId = data
    },
    SET_PROBLEM_NAME(state, data) {
      state.problemTitle = data
    },
    SET_NOTE(state, data) {
      state.activeNote = data
    },
  },
}
