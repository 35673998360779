<template>

  <div class="col-md-10 offset-sm-1">
    <div
      :id="key"
      class="carousel slide"
    >
      <ol
        class="carousel-indicators ml-auto mr-auto pl-0"
        style="bottom: -10px;"
      >
        <li
          v-for="(image,index) in data"
          :key="index"
          class="active"
          :data-slide-to="index"
          :data-target="'#'+key"
        >
              &nbsp;
        </li>
      </ol>

      <div class="carousel-inner">
        <div
          v-for="(image,index) in data"
          :key="index"
          :class="{'active':index===0}"
          class="carousel-item"
        >
          <img
            v-image-preview
            class="d-block w-100"
            :src="image.image_url"
          >
          <div
            class="carousel-caption"
            style="background-color: rgba(0,0,0,0.50); width: 100%; left: 0; right: 0; padding: 15px; bottom: 0;"
          >
            <h4
              v-text-to-speech
              class="text-white"
            >
              {{ image.title }}
            </h4>

            <p
              v-text-to-speech
              class="text-white"
            >
              {{ image.subtitle }}
            </p>
          </div>
        </div>
        <a
          class="carousel-control-prev"
          data-slide="prev"
          :href="'#'+key"
        ><span class="sr-only">Previous</span></a> <a
          class="carousel-control-next"
          data-slide="next"
          :href="'#'+key"
        > <span class="sr-only">Next</span> </a></div>
    </div>
  </div>
</template>
<script>

export default ({
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      key: Math.random().toString(36).substr(2, 5),
    }
  },
})
</script>
