var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-observer',{ref:"categoryForm"},_vm._l((_vm.categories[_vm.level]),function(category,index){return _c('div',{key:index,staticClass:"border border-info p-2"},[_c('multiple-image-picker',{attrs:{"selected-images":category.images},on:{"updateData":(data) => category.images = data}}),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Preparation Time"}},[_c('cleave',{staticClass:"form-control",attrs:{"raw":false,"options":{
              time: true,
              timePattern: ['m', 's']
            },"placeholder":"mm:ss"},model:{value:(_vm.$store.state.problem.create.openEndWriting.preparationTime),callback:function ($$v) {_vm.$set(_vm.$store.state.problem.create.openEndWriting, "preparationTime", $$v)},expression:"$store.state.problem.create.openEndWriting.preparationTime"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Task Time"}},[_c('cleave',{staticClass:"form-control",attrs:{"raw":false,"options":{
              time: true,
              timePattern: ['m', 's']
            },"placeholder":"mm:ss"},model:{value:(_vm.$store.state.problem.create.openEndWriting.taskTime),callback:function ($$v) {_vm.$set(_vm.$store.state.problem.create.openEndWriting, "taskTime", $$v)},expression:"$store.state.problem.create.openEndWriting.taskTime"}})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Question"}},[_c('QuillEditorCustom',{attrs:{"placeholder":"Questions"},on:{"blur":_vm.updateForAllLevel},model:{value:(category.question),callback:function ($$v) {_vm.$set(category, "question", $$v)},expression:"category.question"}})],1),_c('b-form-group',{attrs:{"label":"Answers"}},[_c('b-form-tags',{staticClass:"mb-2",attrs:{"input-id":"tags-pills","tag-variant":"primary","tag-pills":"","size":"lg","separator":" ","placeholder":_vm.$t('labels.create-problem.placeholder.answer')},on:{"input":_vm.updateForAllLevel},model:{value:(category.answers),callback:function ($$v) {_vm.$set(category, "answers", $$v)},expression:"category.answers"}})],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }