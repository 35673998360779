<template>
  <b-modal :visible="show"
           title="Add Custom Schedule"
           hide-footer
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <h5>Schedule</h5>
    <b-row v-for="time,i of scheduleTimes"
           :key="i"
           class="mb-1"
    >
      <b-col cols="4">
        <select v-model="time.day"
                class="form-control w-100"
                @change="getFilteredSchedules()"
        >
          <option v-for="st,index of weekDays"
                  :key="index"
          >
            {{ st.text }}
          </option>
        </select>
      </b-col>
      <b-col class="d-flex">
        <select class="form-control"
                @change="ev => handleValueChange(ev, time)"
        >
          <option value="">
            Select Time
          </option>
          <option v-for="sInterval,index of scheduleWithInterval"
                  :key="index"
                  :value="sInterval.start_time + '-' + sInterval.end_time "
          >
            {{ FORMAT_DATE('1999-01-01 '+ sInterval.start_time, 'hh:mm A') }} - {{ FORMAT_DATE('1999-01-01 ' + sInterval.end_time, 'hh:mm A') }}
          </option>
        </select>
      </b-col>
    </b-row>
    <div class="text-right">
      <b-button variant="primary"
                :disabled="isProcessing"
                @click="onReserve"
      >
        <b-spinner v-if="isProcessing"
                   small
        /> Reserve
      </b-button>
    </div>
    <div v-if="filteredSchedule.length">
      <h5>Filtered Schedules</h5>
      <div v-for="schedule,index of filteredSchedule"
           :key="index"
      >
        <ScheduleCard :schedule="schedule"
                      @applySchedule="$emit('applySchedule', schedule)"
        />
      </div>
    </div>
  </b-modal>
</template>
<script>
import {
  BModal, BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { createTimeInterval } from '@/@core/utils/utils'
import ScheduleCard from './schedule-card.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    ScheduleCard,
    BSpinner,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    allSchedules: {
      type: Array,
      default: () => [],
    },
    reservationId: {
      type: [Number, String],
      required: true,
    },
    customSchedule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isProcessing: false,
      selectedDays: this.customSchedule.days,
      filteredSchedule: [],
      weekDays: [
        {
          text: 'Choose Day',
          value: null,
        },
        {
          text: 'Sunday',
          value: 'Sunday',
        },
        {
          text: 'Monday',
          value: 'Monday',
        },
        {
          text: 'Tuesday',
          value: 'Tuesday',
        },
        {
          text: 'Wednesday',
          value: 'Wednesday',
        },
        {
          text: 'Thursday',
          value: 'Thursday',
        },
        {
          text: 'Friday',
          value: 'Friday',
        },
        {
          text: 'Saturday',
          value: 'Saturday',
        },
      ],
    }
  },
  computed: {
    numberOfDays() {
      return Array.from({ length: 10 }, (l, i) => i + 1)
    },
    scheduleTimes() {
      return Array(this.selectedDays).fill(undefined).map(() => ({
        day: 'Sunday',
        start_time: '',
        end_time: '',
      }))
    },
    scheduleWithInterval() {
      return createTimeInterval(this.customSchedule.interval)
    },
  },
  mounted() {
    this.getFilteredSchedules()
  },
  methods: {
    handleValueChange(ev, time) {
      const targetValue = ev.target.value.split('-')
      // eslint-disable-next-line prefer-destructuring, no-param-reassign
      time.start_time = targetValue[0]
      // eslint-disable-next-line prefer-destructuring, no-param-reassign
      time.end_time = targetValue[1]
      this.$forceUpdate()
      this.getFilteredSchedules()
    },
    onReserve() {
      this.isProcessing = true
      useJwt.reservationCreateAndReserveSchedule(this.reservationId, {
        class_id: this.$route.params.id,
        schedule_times: this.scheduleTimes,
      }).then(response => {
        this.$emit('afterScheduleReserved', response)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    getFilteredSchedules() {
      const filteredSchedule = []
      this.allSchedules.forEach(item => {
        let canAdd = true
        this.scheduleTimes.forEach(subItem => {
          canAdd = item.schedule_times.some(deepItem => (deepItem.day === subItem.day) && (!subItem.start_time || deepItem.start_time === subItem.start_time) && (!subItem.end_time || deepItem.end_time === subItem.end_time))
        })
        if (canAdd) {
          filteredSchedule.push(item)
        }
      })
      this.filteredSchedule = filteredSchedule
    },
  },
}
</script>
