<template>
  <!-- create/edit class model -->
  <b-modal
    id="modal-invite-member"
    ref="modal-invite-member"
    ok-only
    size="md"
    ok-variant="primary"
    :ok-title="$t('confirm')"
    modal-class="modal-primary"
    centered
    :title="$t('network-module.invite-member')"
  >
    <b-col md="12">
      <validation-observer
        ref="InviteUser"
      >
        <b-form @submit.prevent="inviteMemersToNetwork">
          <button
            type="submit"
            class="d-none"
          />
          <b-form-group :label="$t('user')">
            <validation-provider
              v-slot="{ errors }"
              name="User"
              rules="required"
              vid="requested_to"
            >
              <v-select
                v-model="selectedUser"
                :options="getUsersForSelect"
                :placeholder="$t('network-module.type-to-search')"
                transition=""
                :state="errors.length > 0 ? false:null"
                @search="searchForUsers"
              />
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="selectedUser"
            class="mt-1"
            :label="$t('network-module.member-type')"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Requested for"
              rules="required"
              vid="requested_for"
            >
              <b-form-select
                v-model="selectedType"
                :state="errors.length > 0 ? false:null"

                size="sm"
                :options="getMemberTypes"
              />
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="selectedUser"
            class="mt-1"
            :label="$t('network-module.message')"
          >

            <validation-provider
              v-slot="{ errors }"
              name="Message"
              vid="message"
            >
              <b-form-textarea
                v-model="message"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-col>
    <template #modal-footer="{ }">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        > <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          :disabled="isProcessing || selectedUser==null"
          @click="inviteMemersToNetwork()"
        >
          <b-spinner
            v-show="isProcessing"
            small
          />
          {{ $t('network-module.invite-member') }}
        </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
  <!-- create/edit class model -->
</template>
<script>
import {
  BRow, BCol, BButton, BSpinner, BModal, BFormGroup, BFormSelect, BFormTextarea, BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { MEMBER_TYPES, MEMBER_TYPE_MEMBER } from '@/const/networkMemberType'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import i18n from '@/libs/i18n'

export default {
  components: {
    BButton,
    BModal,
    BFormSelect,
    BSpinner,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BForm,
    // BTable,
    BRow,
    vSelect,
    // BImg,
    BCol,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      isProcessing: false,
      users: [],
      selectedUser: null,
      selectedType: MEMBER_TYPE_MEMBER,
      message: '',
    }
  },
  computed: {
    network() {
      return this.$store.state.network.network
    },
    getMemberTypes() {
      return MEMBER_TYPES.map(e => ({
        text: i18n.tc(`network-member-types.${e}`),
        value: e,
      }))
    },
    getUsersForSelect() {
      return this.users.map(e => ({
        label: e.email,
        code: e.id,
      }))
    },
  },
  methods: {
    searchForUsers(search = '') {
      const params = { email: search }
      useJwt.searchForUsers({ params }).then(response => {
        this.users = response.data.data
      })
    },
    inviteMemersToNetwork() {
      this.$refs.InviteUser.validate().then(success => {
        if (success) {
          this.isProcessing = true

          useJwt.inviteMemberToNetwork({
            requested_to: this.selectedUser.code,
            message: this.message,
            requested_for: this.selectedType,
            requested_from: this.network.id,
          }).then(response => {
            this.selectedUser = null
            this.selectedType = MEMBER_TYPE_MEMBER
            this.message = ''
            this.$refs['modal-invite-member'].hide()
            this.$store.dispatch('network/getInvitations')
            this.showSuccessMessage(response)
          })
            .catch(error => {
              if (error.response?.data) {
                this.$refs.InviteUser.setErrors(error.response.data)
              } else this.showErrorMessage(error)
            })
            .finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
  },
}
</script>
