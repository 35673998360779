// eslint-disable-next-line import/no-cycle
import router from '@/router'
import i18n from '@/libs/i18n'

export const NOTIFICATION_TYPE_TEACHER_REQUEST = 'teacher-request'
export const NOTIFICATION_TYPE_STUDENT_REGISTERED = 'student_registration'
export const NOTIFICATION_TYPE_NETWORK_JOIN_REQUEST = 'join-request'
export const NOTIFICATION_TYPE_NETWORK_INVITATION = 'invite'
export const NOTIFICATION_TYPE_NETWORK_JOIN_REQUEST_ACCEPTED = 'join-request-accepted'
export const NOTIFICATION_TYPE_NETWORK_ARTICLE_REQUEST = 'article-request'
export const NOTIFICATION_TYPE_NETWORK_ARTICLE_ACCEPTED = 'article-accepted'
export const NOTIFICATION_TYPE_ESSAY_SUBMITTED = 'essay-submitted'
export const NOTIFICATION_TYPE_ESSAY_UPDATED = 'essay-updated'
export const NOTIFICATION_TYPE_ESSAY_EDIT_PERMISSION_REQUESTED = 'essay-edit-requested'

export function getMessageForNotification(type, from, purpose) {
  if (type === NOTIFICATION_TYPE_TEACHER_REQUEST) {
    return `${from} has requested a teacher for ${purpose}`
  }
  if (type === NOTIFICATION_TYPE_STUDENT_REGISTERED) { return `<strong>${from}</strong> ${i18n.tc('user-notification-module.registered-as-student')}` }
  if (type === NOTIFICATION_TYPE_NETWORK_INVITATION) { return ` ${i18n.tc('user-notification-module.you-have-been-invited-to-join')} <strong>${from}</strong>` }
  if (type === NOTIFICATION_TYPE_NETWORK_JOIN_REQUEST) { return `<strong>${from}</strong> ${i18n.tc('user-notification-module.wants-to-join-network')}` }
  return 'Message Not Implemented'
}
export function getTitleForNotification(type) {
  if (type === NOTIFICATION_TYPE_TEACHER_REQUEST) return 'Teacher Requested'
  if (type === NOTIFICATION_TYPE_STUDENT_REGISTERED) return 'Student Registered'
  if (type === NOTIFICATION_TYPE_NETWORK_JOIN_REQUEST) return 'Network Join Request'
  if (type === NOTIFICATION_TYPE_NETWORK_INVITATION) return 'Network Join Invitation'

  return 'Title Not Implemented'
}
export function getIconForNotification(type) {
  if (type === NOTIFICATION_TYPE_NETWORK_JOIN_REQUEST) return 'Share2Icon'
  if (type === NOTIFICATION_TYPE_TEACHER_REQUEST) return 'UserIcon'
  if (type === NOTIFICATION_TYPE_STUDENT_REGISTERED) return 'UserPlusIcon'
  if (type === NOTIFICATION_TYPE_NETWORK_INVITATION) return 'Share2Icon'
  if (type === NOTIFICATION_TYPE_NETWORK_JOIN_REQUEST_ACCEPTED) return 'CheckIcon'
  if (type === NOTIFICATION_TYPE_NETWORK_ARTICLE_ACCEPTED) return 'CheckIcon'
  if (type === NOTIFICATION_TYPE_ESSAY_SUBMITTED) return 'FileTextIcon'
  if (type === NOTIFICATION_TYPE_ESSAY_UPDATED) return 'FileTextIcon'
  if (type === NOTIFICATION_TYPE_ESSAY_EDIT_PERMISSION_REQUESTED) return 'Edit2Icon'

  return 'BookIcon'
}
export function commitActionForNotification(notification) {
  if (notification.type === NOTIFICATION_TYPE_TEACHER_REQUEST) router.push(`/school/classes?id=${notification.for_id}`)
}
export function isRequestType(type) {
  const invalidTypes = [
    NOTIFICATION_TYPE_NETWORK_JOIN_REQUEST,
    NOTIFICATION_TYPE_NETWORK_INVITATION,
    NOTIFICATION_TYPE_NETWORK_ARTICLE_REQUEST,
    NOTIFICATION_TYPE_ESSAY_EDIT_PERMISSION_REQUESTED,
  ]

  if (invalidTypes.includes(type)) return true
  return false
}
