export const PROGRESS_COLORS = ['#e54732', '#e39b48', '#fee54d', '#9bb35c', '#387b46']

export const getStudentProgressBarColor = value => {
  // eslint-disable-next-line no-param-reassign
  value = parseInt(value, 10)
  for (let index = 0; index < 5; index += 1) {
    if (value >= index * 20 && value < (index + 1) * 20) {
      return PROGRESS_COLORS[index]
    }
  }
  return PROGRESS_COLORS[4]
}

export const getLessonProgressColor = percentage => {
  let variant = ''
  if (percentage <= 25) {
    variant = 'warning'
  } else if (percentage <= 50) {
    variant = 'primary'
  } else {
    variant = 'success'
  }
  return variant
}

export const getRandomColorArray = total => {
  const colors = []
  while (colors.length < total) {
    do {
      // eslint-disable-next-line vars-on-top, no-var
      var color = Math.floor((Math.random() * 1000000) + 1)
    // eslint-disable-next-line block-scoped-var
    } while (colors.indexOf(color) >= 0)
    // eslint-disable-next-line block-scoped-var
    colors.push(`#${(`000000${color.toString(16)}`).slice(-6)}`)
  }
  return colors
}
