var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"md":"3","sm":"6"}},[_c('image-upload',{attrs:{"url":_vm.images.vocab_bg_image.content,"title":_vm.$t('lesson-pages-module.vocab-bg-image'),"generator-text":_vm.curriculum.general_statement},on:{"onSelectedFromLibrary":value => _vm.images.vocab_bg_image.content = value,"imageUploaded":(file, url) => {
          _vm.images.vocab_bg_image.content = url;
        },"imageRemoved":function($event){return _vm.removeImage('vocab_bg_image')},"onImageSelected":v => _vm.$emit('onImageSelected', v, 'vocab_bg_image')}})],1),_c('b-col',{staticClass:"mb-2 mb-md-0",attrs:{"md":"3","sm":"6"}},[_c('image-upload',{attrs:{"url":_vm.images.question_bg_image.content,"title":_vm.$t('lesson-pages-module.question-bg-image'),"generator-text":_vm.curriculum.extra_question},on:{"imageRemoved":function($event){return _vm.removeImage('question_bg_image')},"onSelectedFromLibrary":value => _vm.images.question_bg_image.content = value,"imageUploaded":(file, url) => {
          _vm.images.question_bg_image.content = url;
        },"onImageSelected":v => _vm.$emit('onImageSelected', v, 'question_bg_image')}})],1),_c('b-col',{attrs:{"md":"3","sm":"6"}},[_c('image-upload',{attrs:{"url":_vm.images.top_image.content,"title":_vm.$t('lesson-pages-module.question-header-image'),"generator-text":_vm.curriculum.thesis_statement},on:{"imageRemoved":function($event){return _vm.removeImage('top_image')},"onSelectedFromLibrary":value => _vm.images.top_image.content = value,"imageUploaded":(file, url) => {
          _vm.images.top_image.content = url;
        },"onImageSelected":v => _vm.$emit('onImageSelected', v, 'top_image')}})],1),_c('b-col',{attrs:{"md":"3","sm":"6"}},[_c('image-upload',{attrs:{"url":_vm.images.question_radius_image.content,"title":_vm.$t('lesson-pages-module.question-radius-image'),"generator-text":_vm.curriculum.bottom_question},on:{"imageRemoved":function($event){return _vm.removeImage('question_radius_image')},"onSelectedFromLibrary":value => _vm.images.question_radius_image.content = value,"imageUploaded":(file, url) => {
          _vm.images.question_radius_image.content = url;
        },"onImageSelected":v => _vm.$emit('onImageSelected', v, 'question_radius_image')}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }