import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    classId: null,
    className: null,
    studentName: null,
    calendarOptions: [
      {
        color: 'danger',
        label: 'Personal',
      },
      {
        color: 'primary',
        label: 'Business',
      },
      {
        color: 'warning',
        label: 'Family',
      },
      {
        color: 'success',
        label: 'Holiday',
      },
      {
        color: 'info',
        label: 'ETC',
      },
    ],
    selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC'],
  },
  getters: {
    className: state => state.className,
    studentName: state => state.studentName,
  },
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
    SET_CLASS_ID(state, val) {
      state.classId = val
    },
    SET_STUDENT_NAME(state, val) {
      state.studentName = val
    },
    SET_CLASS_NAME(state, val) {
      state.className = val
    },
  },
  actions: {
    setClassId({ commit }, data) {
      commit('SET_CLASS_ID', data)
    },
    fetchEvents({ state }) {
      return new Promise((resolve, reject) => {
        useJwt.getStudentEvents({ params: { class_id: state.classId } }).then(res => {
          let { data } = res.data
          data = data.map(e => ({
            id: e.id,
            title: e.message,
            start: e.start_time,
            end: e.end_time,
            allDay: false,
            extendedProps: {
              message: e.message,
              calendar: 'Business',
            },
          }))
          resolve({ data })
        }).catch(err => reject(err))
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        useJwt.createStudentEvent(event).then(res => resolve(res)).catch(err => reject(err))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        useJwt.updateStudentEvent(event.id, event).then(res => resolve(res)).catch(err => reject(err))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        useJwt.deleteStudentEvent(id).then(res => resolve(res)).catch(err => reject(err))
      })
    },
  },
}
