<template>
  <div>
    <b-card :show="isProcessing"
            class="text-center"
    >
      <b-spinner large />
    </b-card>
  </div>
</template>
<script>
import { BCard, BSpinner } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import getLandingPageFor from '@/router/utils'
import { getUserData } from '@/auth/utils'
import moment from 'moment'
import GEC_EVENT_BUS from '@/utils/eventBus'
import { USER_TYPE_STUDENT } from '@/const/userType'

export default {
  components: {
    BCard,
    BSpinner,
  },
  data() {
    return {
      isProcessing: false,
    }
  },
  created() {
    this.verifyPayment()
  },
  methods: {
    afterLogin(response) {
      const userData = response.data.data.user
      useJwt.setToken(response.data.data.accessToken)
      localStorage.setItem('userData', JSON.stringify(userData))
      this.$store.commit('app/SET_COMPANY_INFORMATION')
      this.$router.replace({ name: getLandingPageFor(userData.usertype) }).then(() => {
        this.$store.dispatch('authEvents/onUserLogin')
        this.showSuccessMessage(response)
        if (getUserData() && getUserData().usertype === USER_TYPE_STUDENT) this.getCurrentStudentCharacter()
        if (parseInt(moment().diff(getUserData().dob, 'years'), 10) < 13) {
          this.$store.commit('appConfig/UPDATE_THEME_ON', true)
          this.$store.commit('appConfig/UPDATE_IS_STUDENT_YOUNG', true)
        }
        GEC_EVENT_BUS.$emit('loggedin')
      })
    },
    verifyPayment() {
      useJwt.reservationPaymentSuccess({
        params: {
          refId: this.$route.query.refId,
        },
      }).then(response => {
        window.open('/', '_self')
        this.showSuccessMessage(response)
        this.afterLogin(response)
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
  },
}

</script>
