<template>
  <b-modal
    id="modal-no-animation"
    content-class="shadow"
    :title="`${schedule.day} / (${formatDate(schedule.start_time)}-${formatDate(schedule.end_time)})`"
    no-fade
    ok-only
    :visible="visible"
    ok-title="Accept"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <validation-observer ref="simpleRules">
      <b-overlay :show="isFetchingData">
        <div
          style="max-height: 65vh;overflow-y: scroll;"
          :style="{ minHeight: rows.length > 0 && '180px' }"
        >
          <b-table
            :fields="fields"
            :items="rows"
            responsive="sm"
            :small="true"
          >
            <template
              #cell(start_time)="data"
              slot-scope=""
            >
              <b-form-input
                v-model="rows[data.index].start_time"
                type="time"
                onfocus="this.showPicker()"
                :min="minStartTime.format('YYYY-MM-DD HH:mm:ss')"
                :max="rows[data.index].end_time"
                placeholder="Start"
                size="sm"
                :state="isValidTime(rows[data.index].start_time)"
              />
            </template>
            <template #cell(end_time)="data">
              <b-form-input
                v-model="rows[data.index].end_time"
                type="time"
                onfocus="this.showPicker()"
                :min="rows[data.index].start_time"
                :max="maxEndTime.format('YYYY-MM-DD HH:mm:ss')"
                :state="isValidTime(rows[data.index].end_time)"
                placeholder="End"
                size="sm"
              />
            </template>
            <template #cell(classroom)="data">
              <b-form-select
                v-model="rows[data.index].classroom"
                transition=""
                :clearable="false"
                size="sm"
                :options="[{ value: undefined, text: 'Select Classroom' },...classRooms.filter(cls=> cls.value)]"
                placeholder="Classroom"
              />
            </template>
            <template #cell(action)="data">
              <feather-icon
                icon="TrashIcon"
                role="button"
                class="text-danger"
                @click="removeSchedule(data.index)"
              />
            </template>
          </b-table>
          <b-button
            variant="outline-primary"
            size="sm"
            @click="addMoreTime"
          >
            Add more <feather-icon icon="PlusIcon" />
          </b-button>
        </div>
      </b-overlay>
    </validation-observer>
    <template #modal-footer="{}">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="success"
            size="sm"
            :disabled="isProcessing || !isValid"
            @click="onSubmit()"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            <span>
              Save Schedule
            </span>
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal, VBModal, BTable, BFormInput, BFormSelect, BButton, BRow, BCol, BSpinner, BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'

const moment = require('moment')

export default {
  components: {
    BModal,
    BTable,
    BFormSelect,
    BButton,
    BRow,
    BCol,
    BSpinner,
    BOverlay,
    ValidationObserver,
    BFormInput,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    schedule: {
      type: Object,
      default: () => {},
    },
    classRooms: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        { key: 'start_time', label: 'Start Time', tdClass: 'pl-0' },
        { key: 'end_time', label: 'End Time', tdClass: 'pl-0' },
        { key: 'classroom', label: 'Classroom', tdClass: 'pl-0 col-1' },
        { key: 'action', label: 'Action?', tdClass: 'text-center p-0 col-1' },
      ],
      classroom: [],
      rows: [],
      isProcessing: false,
      isFetchingData: false,
    }
  },
  computed: {
    minStartTime() {
      return moment(`${this.schedule.day} ${this.schedule.start_time}`, 'YYY-MM-DD hh:mm:ss')
    },
    maxEndTime() {
      return moment(`${this.schedule.day} ${this.schedule.end_time}`, 'YYY-MM-DD hh:mm:ss')
    },
    isValid() {
      return this.rows.every(row => this.isValidTime(row.start_time)) && this.rows.length !== 0
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    visible(newValue) {
      if (newValue && this.schedule.id) {
        this.rows = []
        this.isFetchingData = true
        useJwt.getScheduleReserveTime(this.schedule.id).then(response => {
          const schedules = response.data.data.map(s => ({
            start_time: s.start_time, end_time: s.end_time, classroom: s.classroom_id,
          }))
          this.rows = schedules
        }).finally(() => { this.isFetchingData = false })
      }
    },
  },
  methods: {
    addMoreTime() {
      this.rows.push({
        start_time: '',
        end_time: '',
        classroom: null,
        scheduled: false,
      })
    },
    formatDate(date) {
      return moment(`${this.schedule.day} ${date}`).format('h:mm A')
    },
    removeSchedule(index) {
      this.rows.splice(index, 1)
    },
    onSubmit() {
      const { isValid } = this
      if (isValid) {
        this.isProcessing = true
        useJwt.storeScheduleReserveTime(this.schedule.id, {
          details: this.rows,
        }).then(response => {
          this.$emit('close')
          this.showSuccessMessage(response)
        }).finally(() => { this.isProcessing = false })
      }
    },
    isValidTime(value) {
      if (!value) return false
      return moment(`${this.schedule.day} ${value}`, 'YYY-MM-DD hh:mm:ss').isBetween(this.minStartTime, this.maxEndTime, 'minutes', '[]')
    },
  },
}
</script>
