<template>
  <b-modal
    :visible="true"
    title="Standards List"
    ok-only
    ok-title="Close"
    hide-footer
    size="lg"
    body-class="p-0"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <BOverlay :show="isLoading">
      <div class="standard-list">
        <div v-if="standards.length === 0 && !isLoading"
             class="text-center p-3"
        >
          No related topics found
        </div>
        <b-list-group>
          <b-list-group-item
            v-for="(standard, index) in standards"
            :key="standard.id"
            class="p-1"
            role="button"
            @click="openLink(standard)"
          >
            <div class="d-flex">
              <div class="number-col">
                {{ index + 1 }}.
              </div>
              <div class="text-col">
                {{ standard.name }}
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </BOverlay>
  </b-modal>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import {
  BModal, BOverlay, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

const standards = ref([])
const isLoading = ref(false)
const props = defineProps({
  standardId: {
    type: Number,
    required: true,
  },
  level: {
    type: String,
    required: true,
  },
})

onMounted(() => {
  isLoading.value = true
  useJwt.getNextStandard(props.standardId, {
    params: {
      level: props.level,
    },
  }).then(response => {
    standards.value = response.data.data.standard
  }).finally(() => {
    isLoading.value = false
  })
})

const openLink = standard => {
  window.open(`/adv-ai-demo?standard_id=${standard.id}`, '_self')
}

</script>

<style scoped>
.standard-list {
  max-height: 80vh;
  overflow-y: auto;
}

.list-group-item {
  transition: background-color 0.2s;
  font-size: 1.1rem !important;
  line-height: 1.2;
  padding: 10px 10px 8px 10px !important;
}

.list-group-item:hover {
  background-color: #f8c7c7;
}

.number-col {
  width: 30px;
  flex-shrink: 0;
}

.text-col {
  padding-left: 5px;
}
</style>
