<template>
  <div>
    <h5 class="p-1">
      {{ title }}
    </h5>

    <b-row class="px-2 mb-2">
      <b-col v-for="(option, index) in options"
             :key="index"
             md="12"
      >
        <b-form-group>
          <b-form-radio v-model="value"
                        :value="option.value"
                        name="schedule_type"
                        @change="value => $emit('input', value)"
          >
            {{ option.label }}
          </b-form-radio>
          <small class="italic">
            <feather-icon
              icon="InfoIcon"
              size="12"
              fill="#ffe699"
              color="#777"
              class="info-icon cursor-pointer"
            />
            {{ option.info }}
          </small>
        </b-form-group>
        <hr>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormRadio,
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormRadio,
    BRow,
    BCol,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.muted {
  color: #777; /* Define your muted text color here */
}

.italic {
  font-style: italic;
}

.schedule-type{
  // background: #eee;
  border: 1px solid #ddd;
}
.dark-layout {
  .schedule-type {
    background-color: #182034;
  }
}
</style>
