<template>

  <b-row>
    <b-col
      md="3"
      sm="6"
      class="mb-2"
    >
      <image-upload
        :url="lessonTemp.vocab_image || lesson.vocab_image"
        :title="$t('lesson-pages-module.vocab-bg-image')"
        @imageRemoved="lesson.vocab_image=null"
        @imageUploaded="(img, url)=>{lesson.vocab_image=img; lessonTemp.vocab_image = url}"
      />
    </b-col>
    <b-col
      md="3"
      class="mb-2 mb-md-0"
      sm="6"
    >
      <image-upload
        :url="lessonTemp.question_bg_image || lesson.question_bg_image"
        :title="$t('lesson-pages-module.question-bg-image')"
        @imageRemoved="lesson.question_bg_image=null"
        @imageUploaded="(img, url)=>{ lesson.question_bg_image=img; lessonTemp.question_bg_image = url }"
      />
    </b-col>
    <b-col
      md="3"
      sm="6"
    >
      <image-upload
        :url="lessonTemp.top_image || lesson.top_image"
        :title="$t('lesson-pages-module.question-header-image')"
        @imageRemoved="lesson.top_image=null"
        @imageUploaded="(img, url)=>{ lesson.top_image=img; lessonTemp.top_image = url}"
      />
    </b-col>
    <b-col
      md="3"
      sm="6"
    >
      <image-upload
        :url="lessonTemp.question_radius_image || lesson.question_radius_image"
        :title="$t('lesson-pages-module.question-radius-image')"
        @imageRemoved="lesson.question_radius_image=null"
        @imageUploaded="(img, url)=>{ lesson.question_radius_image=img; lessonTemp.question_radius_image = url}"
      />
    </b-col>

  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ImageUpload from '@views/common/components/CustomImageUpload.vue'

export default {
  components: {
    BRow, BCol, ImageUpload,
  },
  props: {
    lesson: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lessonTemp: {},
    }
  },
}
</script>

<style>

</style>
