<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="rows"
    />
  </div>
</template>
<script setup>
import useJwt from '@/auth/jwt/useJwt'
import { onMounted, ref, getCurrentInstance } from 'vue'
import { VueGoodTable } from 'vue-good-table'

const props = defineProps({
  classId: {
    type: Number,
    required: true,
  },
  selectedStudentId: {
    type: Number,
    required: true,
  },
})

const root = getCurrentInstance().proxy.$root
const columns = [
  { label: 'Vocab Word', field: 'vocab_word' },
  { label: 'Total Attempt', field: 'total_attempt' },
  { label: 'Passed', field: 'passed' },
  { label: 'Voca Confidence', field: 'voca_confidence' },
  { label: 'Last Attempted At', field: 'last_attempted_at' },
]

const rows = ref([])

const getReportByProgress = () => {
  useJwt.getVocabLessonReportByProgress(props.classId, {
    params: {
      student_id: props.selectedStudentId,
    },
  }).then(response => {
    rows.value = response.data.data.data
  }).catch(error => {
    root.showErrorMessage(error)
  })
}

onMounted(() => {
  getReportByProgress()
})

</script>
