<template>
  <b-modal :visible="true"
           size="xl"
           title="Please hover the error highlighted in red color to see the error"
           ok-only
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <b-table-simple responsive>
      <b-thead>
        <b-tr>
          <b-th>
            Row No.
          </b-th>
          <b-th>
            {{ $t('user-module.user-table.first-name') }}
          </b-th>
          <b-th>
            {{ $t('user-module.user-table.last-name') }}
          </b-th>
          <b-th>
            {{ $t('user-module.user-table.email') }}
          </b-th>
          <b-th>
            {{ $t('user-module.user-table.phone') }}
          </b-th>
          <th>
            {{ $t('user-module.user-table.user-type') }}
          </th>
          <b-th>
            {{ $t('user-module.user-table.language') }}
          </b-th>

        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="error,index of errorData"
              :key="index"
        >
          <b-td v-for="col,i of columns"
                :key="i"
          >
            <span v-if="error.error_field[col]"
                  v-b-tooltip.hover.right="error.error_field[col].join(',')"
                  class="text-danger"
                  role="button"
            >
              {{ error[col] }}
            </span>
            <span v-else>
              {{ error[col] }}
            </span>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-modal>
</template>
<script>
import {
  BModal, BTableSimple, BTr, BTd, BThead, BTbody, BTh, VBTooltip,
} from 'bootstrap-vue'

export default {

  components: {
    BModal,
    BTableSimple,
    BTr,
    BTd,
    BTh,
    BThead,
    BTbody,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    errorData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columns: [
        'error_row', 'firstname', 'lastname', 'email', 'phone', 'usertype', 'language',
      ],
    }
  },
  mounted() {
    this.showInfo('There is some error in your csv file')
  },

}
</script>
