<template>
  <nav
    id="mainNav"
    class="navbar navbar-expand-md navbar-light bg-light shadow py-1"
    @click="$emit('showSearch',false)"
  >
    <div class="container">
      <a
        class="navbar-brand"
        :href="frontUrl"
      ><img
        :src="$store.state.app.companyLogo"
        style="height: 70px;"
        alt=""
      ><span>Global English Campus in Partnership with JAAC</span></a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarMenu"
        aria-controls="navbarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        MENU
      </button>
      <div
        id="navbarMenu"
        class="collapse navbar-collapse"
      >
        <ul class="navbar-nav row w-100">
          <div
            class="col col-md-9 col-sm-12 d-flex justify-content-center align-items-center"
          >
            <div class="d-inline-block search-container">
              <input
                v-model="searchStudent"
                class="search-box"
                placeholder="Type to search students"
                type="text"
                @input="searchForStudents"
                @click.stop="$emit('showSearch',true)"
              >
              <div
                v-show="showSearchResults && searchStudent.length>0"
                class="search-results"
                @click.stop
              >
                <ul
                  v-if="searchResults.length>0"
                  @click.stop
                >
                  <li
                    v-for="student in searchResults"
                    :key="student.email"
                    @click.stop
                  >
                    <router-link
                      :to="{ name: 'guest-user-profile',params:{name:`${student.fullname.replaceAll('_','-').replaceAll(' ','-')}_${student.id}`}}"
                      target="_blank"
                      @click.stop
                    >
                      {{ student.fullname }}
                      <br>
                      {{ student.email }}
                    </router-link>

                  </li>
                </ul>
                <div
                  v-else-if="searchResults.length == 0 && !isProcessing"
                >
                  <p
                    style="font-size:13px"
                    class="mb-0 text-danger"
                  >
                    No results found!
                  </p>
                </div>
                <div
                  v-else-if="isProcessing"
                >
                  <p
                    style="font-size:13px"
                    class="mb-0 text-success"
                  >
                    Searching...
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            id="action-buttons"
            class="col-md-3 col-sm-12 d-flex justify-content-end"
          >
            <div>
              <router-link
                to="/networks"
                class="nav-link btn-primary mr-1"
              >
                Networks
              </router-link>
            </div>
            <div
              v-if="!isLoggedIn"
            >
              <router-link
                to="/demo-lesson"
                class="nav-link btn-primary mr-1"
              >
                Demo
              </router-link>
            </div>
            <div
              v-if="!isLoggedIn"
            >
              <router-link
                to="/login"
                class="nav-link btn-primary"
              >
                Login
              </router-link>
            </div>
            <div v-else>
              <router-link
                :to="{name:getLandingPageForUser()}"
                class="nav-link btn-primary"
              >
                Dashboard
              </router-link>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { isUserLoggedIn, getUserData } from '@/auth/utils'
import getLandingPageFor from '@/router/utils'
// import FloatCallBar from '@views/common/chat/call/FloatCallBar.vue'

export default {
  components: {
    // FloatCallBar,
  },
  props: {
    showSearchResults: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLoggedIn: isUserLoggedIn(),
      searchStudent: '',
      searchResults: [],
      isProcessing: false,
      self: getUserData(),
      frontUrl: window.location.origin.replace('edu.', ''),
    }
  },
  methods: {
    searchForStudents() {
      if (this.searchStudent.length === 0) {
        this.searchResults = []
        return
      }
      if (this.searchTimeout) clearTimeout(this.searchTimeout)

      this.isProcessing = true
      this.searchTimeout = setTimeout(() => {
        useJwt.publicSearchStudents({ search_input: this.searchStudent }).then(res => {
          this.searchResults = res.data.data
        }).finally(() => {
          this.isProcessing = false
        })
      }, 400)
    },
    getLandingPageForUser() {
      return getLandingPageFor(this.self?.usertype)
    },

  },
}
</script>

<style lang="scss" scoped>
  .btn-primary {
  background-color: #7367f0;
  border-color: #7367f0;
  color: #ffffff;
  font-weight: 600;
  border-radius: 3rem;
  padding: 0.8rem 2rem !important;
}
.btn-primary.focus,
.btn-primary:focus {
  background-color: #7367f0;
  border-color: #7367f0;
  box-shadow: none;
}
.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: #675bdf;
  border-color: #675bdf;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
  max-width: 1140px;
}

@media (max-width: 1366px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (max-width: 991.98px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 860px;
  }
}

/* MainNav */
#mainNav {
  background: #ffffff !important;
}
#mainNav span {
  font-style: 25px;
  font-weight: 700;
  margin-left: 15px;
}
#mainNav .navbar-nav .nav-item .nav-link {
  font-weight: 600;
}
#mainNav .navbar-nav .nav-item:first-child .nav-link {
  color: #6e6e6e;
  margin-right: 30px;
  margin-top: 7px;
}
#mainNav .navbar-nav .nav-item .btn-primary {
  color: #ffffff;
  padding: 0.8rem 3rem;
}
/* Large devices (desktops, 992px and Down) */
@media (max-width: 991.98px) {
  #mainNav .navbar-nav .nav-item:first-child .nav-link {
    line-height: 38px;
  }
}
@media (max-width: 767.98px) {
  #mainNav .navbar-nav .nav-item:first-child .nav-link {
    margin-right: 0px;
  }
}
#mainNav span {
  display: none;
}

/* search */
.search-container {
  position: relative;
  .search-box {
    border-radius: 8px;
    padding: 8px;
    border: 2px solid #ddd;
    width: 300px;
    outline: none;
  }
  .search-results {
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 9%);
    border: 1px solid #ddd;
    width: 300px;
    position: absolute;
    max-height: 400px;
    overflow-y: scroll;
    color: #212529;
    background: white;
    z-index: 100;
    top: 45px;

    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        padding: 8px;
        padding-left: 12px;
        cursor: pointer;
      }
      li:hover {
        background: #ddd;
        border-radius: 4px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .search-box {
    margin-top: 16px;
  }
  #action-buttons {
    margin-top: 8px;
    justify-content: center !important;
    flex-direction: column !important;

    a {
      margin: 0 !important;
    }

    div {
      width: 300px;
      margin: 8px auto;
    }
  }
  .navbar-light .navbar-toggler {
    padding: 10px 15px;
    font-weight: 600;
    color: #000000;
  }
  .navbar-collapse {
    text-align: center;
  }
  #navbarMenu {
    padding-bottom: 25px;
  }
}

</style>
