<template>
  <div class="p-2 border border-info">
    <div class="row">
      <div v-for="(item, index) in items"
           :key="index"
           class="col-md-6"
      >
        <b-card
          class="mb-2"
        >
          <b-card-header class="p-0">
            {{ item.text }}
            <span @click="deleteItem(index)">
              <feather-icon icon="TrashIcon"
                            role="button"
                            class="text-danger"
              />
            </span>
          </b-card-header>
          <b-card-body class="p-0">
            {{ item.description }}
            <AudioPlayer :key="item.audio"
                         :src="item.audio || ''"
            />
          </b-card-body>
        </b-card>
      </div>
    </div>
    <div class="">
      <b-form @submit.prevent="addItem">
        <div class="row">
          <div class="col-6">
            <b-form-group id="itemText"
                          :label="`${$t('image-library-module.text')}:`"
                          label-for="itemTextInput"
                          class="mb-1"
            >
              <b-form-input id="itemTextInput"
                            v-model="newItemText"
                            class="form-control-sm"
              />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group id="itemDescription"
                          :label="`${$t('image-library-module.description')}:`"
                          label-for="itemDescriptionInput"
                          class="mb-1"
            >
              <Recorder :url="newItemAudio"
                        :polly-text="newItemText"
                        @input="setAudio($event)"
              />
            </b-form-group>
          </div>
        </div>
      </b-form></div>
    <b-button type="submit"
              variant="primary"
              size="sm"
              :disabled="!newItemText"
              @click="addItem"
    >
      {{ $t('animateditem-module.add-item') }}
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import Recorder from '@/views/super/problem/components/recorder.vue'
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    Recorder,
    AudioPlayer,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newItemText: '',
      newItemAudio: '',
    }
  },
  methods: {
    async  fileToBase64(file) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function () {
          const base64String = reader.result
          resolve(base64String)
        }
      })
    },
    setAudio({ audio }) {
      let convertedAudio = ''
      this.fileToBase64(audio).then(result => {
        convertedAudio = result
        this.newItemAudio = convertedAudio
      })
    },
    addItem() {
      this.items.push({
        text: this.newItemText,
        audio: this.newItemAudio,
      })
      this.newItemText = ''
      this.newItemAudio = ''
    },
    deleteItem(index) {
      this.items.splice(index, 1)
    },
  },
}
</script>
