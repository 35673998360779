<template>
  <b-modal
    :id="modal"
    :title="title"
    :ok-title="okTitle"
    :cancel-title="$t('labels.cancel')"
    @ok="$emit('on-confirm')"
  >
    <slot />
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    BModal,
  },
  props: {
    modal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: i18n.tc('labels.confirmation'),
    },
    okTitle: {
      type: String,
      default: i18n.tc('confirm'),
    },
  },
}
</script>
