<template>
  <div>
    <div class="row">
      <div v-for="item,index of reservations"
           :key="index"
           class="col-md-4"
      >
        <ReservationCard
          :value="item"
          class="reservation-card w-100"
          @pickReservation="onReservationPicked"
        />
      </div>
    </div>
    <b-modal v-if="!!pickedReservation"
             :visible="true"
             :title="getUserAccessTypeLabel(pickedReservation.name)"
             @close="pickedReservation = null"
             @hide="pickedReservation = null"
    >
      <validation-observer ref="StudentEmail">
        <b-form-group label="Student Email"
                      label-for="student-email"
        >
          <validation-provider v-slot="{ errors }"
                               name="Email"
                               vid="email"
                               rules="required|email|max:256"
          >
            <b-form-input id="student-email"
                          v-model="studentEmail"
                          placeholder="Student Email"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">
            Information
          </h5>
          <ul v-if="pickedReservation.name == 'trial'"
              class="list-group"
          >
            <li class="list-group-item">
              <strong>Trail Type:</strong> {{ getUserAccessTypeLabel(pickedReservation.name) }}
            </li>
            <li class="list-group-item">
              <strong>Trail Days:</strong> {{ pickedReservation.access_days }} days
            </li>
            <li class="list-group-item">
              <strong>Payment Amount:</strong> ${{ pickedReservation.payment_amount }}
            </li>
          </ul>
          <ul v-else
              class="list-group"
          >
            <li class="list-group-item">
              <strong>Access Type:</strong> {{ getUserAccessTypeLabel(pickedReservation.name) }}
            </li>
            <li class="list-group-item">
              <strong>Access Days:</strong> {{ pickedReservation.access_days }} days
            </li>
            <li class="list-group-item">
              <strong>Payment Amount:</strong> ${{ pickedReservation.payment_amount }}
            </li>
          </ul>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <div class="float-left">
            <b-button variant="outline-secondary"
                      @click="pickedReservation = null"
            >
              Cancel
            </b-button>
          </div>
          <div class="float-right">
            <b-button variant="outline-primary"
                      @click="bookReservationNow"
            >
              <b-spinner v-if="isProcessing"
                         small
              /> Get Now
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BModal, BFormInput, BFormGroup, BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import getLandingPageFor from '@/router/utils'
import { getUserData } from '@/auth/utils'
import moment from 'moment'
import { USER_TYPE_STUDENT } from '@/const/userType'
import GEC_EVENT_BUS from '@/utils/eventBus'
import ReservationCard from './ReservationCard.vue'

export default {
  components: {
    ReservationCard,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BButton,
    BSpinner,
  },
  data() {
    return {
      reservations: [],
      pickedReservation: null,
      studentEmail: '',
      isProcessing: false,
    }
  },
  mounted() {
    this.getSchoolReservations()
  },
  methods: {
    onReservationPicked(item) {
      this.pickedReservation = item
    },
    afterLogin(response) {
      const userData = response.data.data.user
      useJwt.setToken(response.data.data.accessToken)
      localStorage.setItem('userData', JSON.stringify(userData))
      this.$store.commit('app/SET_COMPANY_INFORMATION')
      this.$router.replace({ name: getLandingPageFor(userData.usertype) }).then(() => {
        this.$store.dispatch('authEvents/onUserLogin')
        this.showSuccessMessage(response)
        if (getUserData() && getUserData().usertype === USER_TYPE_STUDENT) this.getCurrentStudentCharacter()
        if (parseInt(moment().diff(getUserData().dob, 'years'), 10) < 13) {
          this.$store.commit('appConfig/UPDATE_THEME_ON', true)
          this.$store.commit('appConfig/UPDATE_IS_STUDENT_YOUNG', true)
        }
        GEC_EVENT_BUS.$emit('loggedin')
      })
    },
    bookReservationNow() {
      const form = {
        student_email: this.studentEmail,
        access_type_id: this.pickedReservation.id,
      }
      this.$refs.StudentEmail.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt.verifyReservationPayment(form).then(response => {
            if (response.data.data.accessToken) {
              window.open('/', '_self')
              this.showSuccessMessage(response)
              this.afterLogin(response)
              return
            }
            window.open(response.data.data.payment.checkoutLink, '_self')
            this.showSuccessMessage(response)
          }).finally(() => {
            this.isProcessing = false
          }).catch(error => {
            console.log(error)
            this.showErrorMessage(error)
          })
        }
      })
    },
    getSchoolReservations() {
      const { schoolId } = this.$route.params
      useJwt.getAvailableReservation({ params: { school_id: schoolId, type: 'non-scheduled', withAccessType: true } }).then(res => {
        // eslint-disable-next-line prefer-destructuring
        this.reservations = res.data?.data?.reservation
      })
    },
  },
}
</script>
