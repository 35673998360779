<template>
  <JsonEditorVue
    v-model="locale"
    :debounce="1000"
    :stringified="false"
    mode="tree"
  />
</template>
<script setup>
import JsonEditorVue from 'json-editor-vue'
import {
  ref, watch, getCurrentInstance, onMounted,
} from 'vue'
import useJwt from '@/auth/jwt/useJwt'

const props = defineProps({
  json: {
    type: Object,
    required: true,
  },
  lang: {
    type: String,
    required: true,
  },
})
const root = getCurrentInstance().proxy.$root
const locale = ref(props.json)

function updateLocale() {
  useJwt.updateSystemLocale({
    locale_json: locale.value,
    lang: props.lang,
  }).then(response => {
    root.showSuccessMessage(response)
  }).catch(error => {
    root.showErrorMessage(error)
  })
}

onMounted(() => {
  watch(locale, () => {
    updateLocale()
  })
})
</script>
