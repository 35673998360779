<template>
  <b-modal
    :title="$t('Upload Domain')"
    ok-title="Upload"
    cancel-variant="outline-secondary"
    :visible="props.show"
    size="lg"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-tabs v-model="selectedTab">

      <b-tab title="Text">
        <UploadDomainUsingText ref="uploadDomainByText" />
      </b-tab>
      <b-tab
        title="Json"
      >
        <validation-observer ref="domainForm"
                             v-slot="{ handleSubmit }"
        >
          <b-form @submit.prevent="handleSubmit(handleUpload)">
            <b-form-group
              :label="$t('Select JSON File')"
              label-for="file-input"
              :description="$t('Please select a valid JSON file to upload')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="file"
                rules="required"
              >
                <b-form-file
                  id="file-input"
                  v-model="file"
                  :state="errors.length === 0"
                  :placeholder="$t('Choose a file or drop it here...')"
                  drop-placeholder="Drop file here..."
                  accept=".json"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-tab>
    </b-tabs>
    <template #modal-footer>
      <b-button variant="outline-secondary"
                @click="$emit('close')"
      >
        {{ $t("Cancel") }}
      </b-button>
      <b-button
        variant="primary"
        :disabled="!isReadyToUpload"
        @click="handleUpload"
      >
        <b-spinner v-if="isProcessing"
                   small
        />
        {{ $t("Upload") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script setup>
import { ref, getCurrentInstance, computed } from 'vue'
import {
  BModal,
  BFormGroup,
  BFormFile,
  BButton,
  BForm,
  BSpinner,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import eventBus from '@/utils/eventBus'
import UploadDomainUsingText from './UploadDomainTxt.vue'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  subjectId: {
    type: Number,
    required: true,
  },
})

const root = getCurrentInstance().proxy.$root
const file = ref(null)
const uploadDomainByText = ref(null)
const selectedTab = ref(0)
const domainForm = ref(null)
const isProcessing = ref(false)
const emits = defineEmits(['close'])
const isReadyToUpload = computed(() => {
  if (selectedTab.value === 0) {
    // eslint-disable-next-line no-underscore-dangle
    return !!uploadDomainByText.value?._setupProxy?.formattedJson?.length
  }
  return file && !isProcessing.value
})
const handleUpload = () => {
  if (selectedTab.value === 0) {
    // eslint-disable-next-line no-underscore-dangle
    const jsonData = uploadDomainByText.value._setupProxy.formattedJson
    isProcessing.value = true
    useJwt
      .uploadBulkDomain(props.subjectId, {
        type: 'textJson',
        data: jsonData,
      })
      .then(response => {
        eventBus.$emit('refetchGecDomains')
        emits('close')
        root.showSuccessMessage(response)
      })
      .catch(error => {
        root.showErrorMessage(error)
      })
      .finally(() => {
        isProcessing.value = false
      })
    return
  }
  if (file.value) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }
    domainForm.value.validate().then(success => {
      if (success) {
        isProcessing.value = true
        const data = new FormData()
        data.append('file', file.value)
        useJwt
          .uploadBulkDomain(props.subjectId, data, config)
          .then(response => {
            eventBus.$emit('refetchGecDomains')
            emits('close')
            root.showSuccessMessage(response)
          })
          .catch(error => {
            root.showErrorMessage(error)
          })
          .finally(() => {
            isProcessing.value = false
          })
      }
    })
  }
}
</script>
