<template>
  <div>
    <section>
      <navbar
        :show-search-results="showSearchResults"
        @showSearch="(d)=>showSearchResults=d"
      />
      <div
        class="py-2"
        :class="containerClass"
        style="min-height: calc(100vh - 125px);"
        @click="showSearchResults=false"
      >
        <router-view />
      </div>
    </section>
    <footer>
      <div class="container mb-2">
        <div class="d-flex justify-content-center text-capitalize">
          <p>Ⓒ GEC 2021 all Rights reserved</p>
          <a
            target="_"
            href="/privacy-policy"
            class="ml-2"
          >{{ $t('navigation-menu.privacy-policy') }}</a>

          <div class="ml-2 d-flex align-items-start justify-content-start">

            <img
              v-if="!isAboutUsAudioPlaying"
              src="@/assets/images/landing/play-btn.svg"
              alt=""
              style="height:50px;margin-top:-8px"
              @click="playPauseAboutUsAudio"
            >
            <img
              v-else
              src="@/assets/images/landing/pause-btn.png"
              alt=""
              style="height:50px;margin-top:-8px"
              @click="playPauseAboutUsAudio"
            >

            <audio
              ref="about-audio"
              src="https://mindpocket.com/tenyear.mp3"
              class="d-none"
            />

            <span> About</span>
          </div>
        </div><!-- /.col-md-12 -->
      </div><!-- /.container -->
    </footer><!-- /.footer -->
  </div>

</template>

<script>

import Navbar from './Navbar.vue'

export default {
  components: { Navbar },
  data() {
    return {
      isAboutUsAudioPlaying: false,
      showSearchResults: false,
    }
  },
  computed: {
    containerClass() {
      return (this.$route.name === 'demo-lesson' && this.$route.query.level) || ['adv-ai-demo', 'app-downloads'].includes(this.$route.name) ? 'container-fluid' : 'container'
    },
  },
  mounted() {
    if (this.$refs['about-audio']) {
      this.$refs['about-audio'].addEventListener('ended', () => { this.isAboutUsAudioPlaying = false })
      this.$refs['about-audio'].addEventListener('play', () => { this.isAboutUsAudioPlaying = true })
      this.$refs['about-audio'].addEventListener('pause', () => { this.isAboutUsAudioPlaying = false })
    }
  },
  methods: {
    playPauseAboutUsAudio() {
      if (this.isAboutUsAudioPlaying) { this.$refs['about-audio'].pause() } else this.$refs['about-audio'].play()
    },
  },
}
</script>
