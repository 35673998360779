<template>
  <div>
    <h1>Word Index</h1>
    <b-row>
      <b-col v-for="item, index of words"
             :key="item.id"
             cols="4"
             class="mb-2"
      >
        <div v-b-tooltip.hover
             class="square"
             :title="item.vocab_word"
             :class="{
               'mouse-cursor': !item.isPassed && !item.isFailed
             }"
             @click="handleSelectProblem(item)"
        >
          <div class="square-content"
               :class="{
                 'selected-problem': selectedProblem == item.id,
                 'bg-success text-white' : item.isPassed,
                 'bg-danger text-white' : item.isFailed
               }"
          >
            {{ index + 1 }}
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol, VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    words: {
      type: Array,
      default: () => [],
    },
    selectedProblem: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    handleSelectProblem(item) {
      if (!item.isPassed && !item.isFailed) {
        this.$emit('selectProblem', item.id)
      }
    },
  },
}
</script>
<style scoped>
.mouse-cursor {
  cursor: pointer;
}
.square {
    width: 100%;
    padding-bottom: 100%;
    border: 1px solid black;
    position: relative;
  }
  .square-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
  }
  .selected-problem {
    background-color: #ff9f43;
    color: white !important;
  }
</style>
