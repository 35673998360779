<template>
  <div>
    <b-dropdown
      no-caret
      variant="link"
      toggle-class="p-1 ml-1"
      style="margin-left: -15px;margin-top: -10px;"
      right
    >
      <template
        #button-content
      >
        <feather-icon
          icon="MoreVerticalIcon"
          size="25"
          class="align-middle text-body p-0 m-0"
        />
      </template>
      <b-dropdown-item @click="showSendMailModal = true">
        <span v-if="isHighLevelUser">
          <feather-icon icon="MailIcon" />  {{ $t('actions.send-email') }}
        </span>
      </b-dropdown-item>
      <!-- Delete Student -->
      <b-dropdown-item
        v-if="isHighLevelUser && !isAClassRoomOwner"
        @click.stop="$emit('addLiveLink')"
      >
        <feather-icon
          icon="ExternalLinkIcon"
        /> {{ $t('actions.add-live-link') }}
      </b-dropdown-item>

      <!-- Make Conductor -->
      <b-dropdown-item @click="activeModal = 'make-conductor' ">
        <span v-if="isAConductor">
          <feather-icon icon="UserXIcon" />  {{ $t('actions.remove-conductor') }}
        </span>
        <span v-else>
          <feather-icon icon="UserCheckIcon" />  {{ $t('actions.make-conductor') }}
        </span>
      </b-dropdown-item>

      <!-- Move Students -->
      <b-dropdown-item
        v-if="!isAClassRoomOwner"
        @click.stop="$emit('moveStudent')"
      >
        <feather-icon icon="MoveIcon" /> {{ $t('class-module.student-table.move-student') }}
      </b-dropdown-item>

      <!-- Delete Student -->
      <b-dropdown-item
        v-if="isHighLevelUser && !isAClassRoomOwner"
        @click.stop="$emit('deleteStudent')"
      >
        <feather-icon
          icon="TrashIcon"
        /> {{ $t('actions.delete') }}
      </b-dropdown-item>
    </b-dropdown>
    <confirm-modal
      :show="activeModal === 'make-conductor' "
      :title="isAConductor ? $t('remove-conductor') : $t('make-conductor')"
      :varient="isAConductor ? 'danger' : 'primary'"
      :sub-title="
        isAConductor ? $t('remove-conductor-user', { studentName: student.fullname}):
        $t('make-conductor-user', { studentName: student.fullname})
      "
      :on-delete="makeConductor"
      :is-processing-delete="isProcessing"
      @close="activeModal = ''"
    />
    <send-mail :show-send-mail-modal="showSendMailModal"
               :classroom-id="classId"
               :student="student"
               @close="showSendMailModal = false"
    />
  </div>
</template>
<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import ConfirmModal from '@/views/common/components/DeleteModal.vue'
import useJwt from '@/auth/jwt/useJwt'
import SendMail from '@/views/v2/common/class/components/SendMail.vue'
// import MoveStudentModal from './MoveStudentModal.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    ConfirmModal,
    SendMail,
    // MoveStudentModal,
  },
  props: {
    student: {
      type: Object,
      default: () => null,
    },
    classId: {
      type: [Number, String, Object],
      default: () => null,
    },
    isHighLevelUser: {
      type: Boolean,
      default: false,
    },
    room: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      activeModal: '',
      isProcessing: false,
      showSendMailModal: false,
    }
  },
  computed: {
    isAConductor() {
      return this.student?.role === 'conductor'
    },
    isAClassRoomOwner() {
      return Number(this.student?.id) === Number(this.room?.owner?.id)
    },
  },
  methods: {
    makeConductor() {
      this.isProcessing = true
      useJwt.updateStudentRole(this.classId, 'conductor', {
        student_id: this.student.id,
      }).then(response => {
        this.activeModal = ''
        this.showSuccessMessage(response)
        this.$emit('refetchStudent')
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },

}
</script>
