<template>
  <b-modal
    :visible="show"
    :title="$t('class-test.class-test-alter')"
    hide-footer
    size="lg"
    centered
  >
    <b-alert
      variant="danger"
      show
    >
      <div class="alert-body">
        <span><strong>{{ greeting }}!</strong> You have a test today.</span>
      </div>
    </b-alert>
    <h4 class="text-secondary">
      Test List
    </h4>
    <app-timeline class="mt-2 mb-2">
      <app-timeline-item
        v-for="test of testLists"
        :key="test.id"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>{{ test.name }}</h6>
          <small class="text-muted">{{ test.lessons.length }} lessons</small>
        </div>
        <b-row class="mt-1">
          <b-col
            v-for="lesson of test.lessons"
            :key="lesson.id"
            cols="6"
          >
            <b-card
              role="button"
              class="ecommerce-card"
              @click="openTestLesson(lesson, test.event_id)"
            >
              <b-row class="align-items-start justify-content-left">
                <b-col cols="2">
                  <avatar
                    :image="lesson.image"
                    :rounded="true"
                    :default-icon="'book'"
                    size="50px"
                  />
                </b-col>
                <b-col
                  cols="9"
                  class="ml-2"
                >
                  {{ lesson.name }}
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-modal>
</template>
<script>
import {
  BModal, BAlert, BRow, BCol, BCard,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Avatar from '@/views/common/components/Avatar.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BModal,
    BAlert,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    Avatar,
    BCard,
  },
  props: {
    testLists: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
    classId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      self: getUserData(),
    }
  },
  computed: {
    greeting() {
      return `Good ${this.generateGreetings()}`
    },
  },
  methods: {
    openTestLesson(lesson, eventId) {
      this.$router.push({
        name: `${this.self.usertype}-lesson`,
        params: { id: lesson.id, classId: this.classId },
        query: { event_id: eventId },
      })
    },
  },
}
</script>
<style scoped>
.ecommerce-card:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
  box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
}
</style>
