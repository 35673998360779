<template>
  <div>
    <div class="wrapper">
      <b-row
        class="mx-0 d-flex flex-nowrap"
      >
        <!-- Smiley Container  -->
        <div
          v-if="showSmiley"
          v-on-clickaway="()=>{toggleSmiley()}"
          class="smiley-container"
        >
          <!-- Emoji Container -->
          <Picker
            v-if="smileyContainers.showEmojiContainer"
            class="interactivity-container"
            set="apple"
            :show-preview="false"
            @select="insertEmoji"
          />

          <!-- Sticker Container  -->
          <div
            v-if="smileyContainers.showStickerContainer"
            class="interactivity-container bg-light"
          >
            <!-- Stickers Container  -->
            <sticker-picker
              :vibes="vibes"
              @select="sendSticker"
            />
          </div>
        </div>

        <!-- Smiley Button  -->
        <b-button
          id="smiley"
          variant="background-primary"
          class="btn-primary btn-icon sm-mt-2 mr-1 rounded-circle file-btn file-btn"
          @click="()=>{toggleSmiley(); toggleSmileyContainers('showEmojiContainer')}"
        >

          <b-tooltip
            target="smiley"
            triggers="hover"
            placement="bottom"
          >
            {{ $t('chat-page.smileys') }}
          </b-tooltip>

          <feather-icon
            icon="SmileIcon"
          />
        </b-button>
        <b-button
          id="stickers"
          variant="primary"
          class="btn-primary btn-icon sm-mt-2 mr-1 rounded-circle file-btn file-btn"
          :class="{'interact-btn': !smileyContainers.showStickerContainer}"
          @click="()=>{toggleSmiley('vibes'); toggleSmileyContainers('showStickerContainer')}"
        >
          <b-tooltip
            target="stickers"
            triggers="hover"
          >
            {{ $t('chat-page.vibes') }}
          </b-tooltip>
          <feather-icon
            class="interactivity-icon"
            icon="TwitchIcon"
            size="24"
            :color="smileyContainers.showStickerContainer ? '#F0DB67' : ''"
          />
        </b-button>
        <!-- Send Audio Buton  -->
        <b-button
          v-show="!recorded"
          id="tooltip-target-1"
          variant="background-primary"
          :class="recording ? 'recording-mic' : 'btn-primary btn-icon sm-mt-2 mr-1 rounded-circle file-btn file-btn'"
          @mousedown="record()"
          @touchstart="record()"
          @touchend="stop()"
          @mouseup="stop()"
        >
          <b-tooltip
            target="tooltip-target-1"
            triggers="hover"
            placement="bottom"
          >
            {{ $t('chat-page.hold-the-mic') }}
          </b-tooltip>

          <feather-icon
            icon="MicIcon"
          />
        </b-button>
        <b-row
          v-if="recorded"
          class="row align-items-center pl-2 pr-2"
        >
          <b-col
            md="4"
            sm="4"
          >
            <audio-player
              :src="audioURL"
              class="col-10 pl-0"
            />
          </b-col>
          <b-col
            md="2"
            sm="2"
          >
            <div
              class="rounded-circle btn-primary file-btn bg-white ml-1"
              style="box-shadow:0px 5px 13px rgba(0,0,0,0.3);margin-bottom: 5px;"
            >
              <feather-icon
                size="18"
                icon="TrashIcon"
                @click="cancel()"
              />
            </div>
          </b-col>
        </b-row>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { Picker } from 'emoji-mart-vue'
import { mixin as clickaway } from 'vue-clickaway'
import { getUserData } from '@/auth/utils'
import StickerPicker from './StickerPicker.vue'
import AudioPlayer from './AudioPlayer.vue'
import ChatHandler from '../chat/ChatHandler'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BTooltip,
    AudioPlayer,
    Picker,
    StickerPicker,
  },
  mixins: [clickaway],
  props: {
    url: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    audioURL: '',
    audio: '',
    chunks: [],
    recording: false,
    recorded: false,
    recordingStartedAt: null,
    file: null,
    fileURL: '',
    showSmiley: false,
    activeContainer: null,
    smileyContainers: {
      showEmojiContainer: true,
      showStickerContainer: false,
    },
    vibes: [],
    self: getUserData(),
    mediaRecorder: null,
  }),
  watch: {
    url() {
      this.audioURL = this.url
    },
  },
  created() {
    this.audioURL = this.url
  },
  mounted() {
    if (this.self) {
      this.getVibes()
    }
    ChatHandler.getInstance().eventEmitter.on('fileSent', () => {
      this.loading = false
    })
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia(
        {
          audio: true,
        },
      )
        // Success callback
        .then(stream => {
          this.mediaRecorder = new MediaRecorder(stream)
          this.mediaRecorder.onstop = () => {
            if (((new Date()).getTime()) >= this.recordingStartedAt + 2000) {
              const blob = new Blob(this.chunks, { type: 'audio/ogg; codecs=opus' })

              blob.lastModifiedDate = 'Mon Dec 10 2018 07:46:33 GMT+0000 (GMT)'
              blob.name = 'recorded.mp3'
              this.audio = blob

              this.audioURL = window.URL.createObjectURL(blob)
              this.emitAudio()
              this.$emit('recordingNow')
              this.recorded = true
              this.chunks = []
            } else {
              this.chunks = []
            }
          }
          this.mediaRecorder.ondataavailable = e => {
            this.chunks.push(e.data)
          }
        })
        // Error callback
        .catch(() => {
          this.showInfo('Please allow audio and camera permission')
        })
    } else {
      this.showInfo('Audio and camera  not supported on your browser!')
    }
  },
  methods: {
    record() {
      this.recordingStartedAt = (new Date()).getTime()
      this.mediaRecorder.start()
      this.recording = true
    },
    stop() {
      this.recording = false
      this.mediaRecorder.stop()
    },
    cancel() {
      this.recording = false
      this.recorded = false
      this.deleteAudio()
      this.chunks = []
      this.$emit('recordingNow')
    },
    upload() {
      this.$refs.audioInput.$el.childNodes[0].click()
    },
    handleAudio(event) {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.audioURL = reader.result
          this.emitAudio()
        },
        false,
      )
      if (event.target.files[0]) {
        const file = event.target.files[0]
        if (/\.(mp3)$/i.test(file.name)) {
          this.audio = file
          reader.readAsDataURL(file)
        }
      }
    },
    deleteAudio() {
      this.audioURL = ''
      this.audio = ''
      this.emitAudio()
    },
    emitAudio() {
      this.$emit('input', { audio: this.audio, url: this.audioURL })
    },
    setRecorded(value) {
      this.recorded = value
    },
    toggleSmiley(type = 'emoji') {
      if (this.activeContainer !== type && this.showSmiley) {
        this.activeContainer = type
        return
      }
      this.activeContainer = type
      this.showSmiley = !this.showSmiley
    },
    toggleSmileyContainers(container) {
      if (this.smileyContainers[container] === false) {
        this.hideSmileyContainers()
        this.smileyContainers[container] = true
      }
    },
    hideSmileyContainers() {
      this.smileyContainers = {
        showEmojiContainer: false,
        showStickerContainer: false,
      }
    },
    insertEmoji(e) {
      this.$emit('emoji', e.native)
    },
    sendSticker(sticker) {
      this.showSmiley = false
      this.$emit('sticker', sticker)
    },
    getVibes() {
      useJwt.getVibes().then(res => {
        const vibes = res.data.data.reduce((groups, item) => {
          const category = (groups[item.category] || [])
          category.push(item)
          /* eslint-disable */
          groups[item.category] = category
          return groups
        }, {})
        if (Object.values(vibes).length) this.vibes.push(vibes)
      })
    },
  },
}
</script>

<style lang="scss" >
$primary-color: #6200ee;
.file-btn{
  width: 39px;
  height: 39px;
  min-width: 39px !important;
  min-height: 39px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer;
}
.close{
  .btn-icon {
    padding: 5px 5px !important;
    background-color: #fff;
    top: 5px;
    margin-right: 5px;
  }
}
.iconfont.rate{
  display: none !important;
}
.recording-mic{
   background-color:white !important;
   border:1px solid $primary-color !important;
   color:$primary-color !important;
   border-radius: 50% !important;
   padding:0.715rem 0.736rem !important;
   margin-right: 1rem !important;
   animation: pulsate infinite 1.5s;
     width: 39px;
  height: 39px;
  min-width: 39px !important;
  min-height: 39px !important;
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.3, 1.3);
    opacity: 0.5;
  }
}

#loading-bg {
  width: 100%;
  height: 100%;
  background: #fff;
  display: block;
  z-index: 10;
}
.loading-logo {
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
  z-index: 100;
}
.loading .effect-1,
.loading .effect-2 {
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #fff;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #000;
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.loading .effects {
  transition: all 0.3s ease;
}
.wrapper {
  position: relative;
}
.smiley-container {
  background-color: #7367f0 !important;
  border-radius: 8px;
  position: absolute;
  bottom: 54px;
  right: 0;
  z-index: 1;
}
.emoji-mart-search input[data-v-4ad41bb8]{
  font-family: "Montserrat", Helvetica, Arial, serif;
}
.interactivity-container {
  min-height: 420px;
  min-width: 354px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
}
.emoji-mart-anchor,
.emoji-mart-emoji span {
  cursor: pointer !important;
}
.interactivity-btn {
  background-color: transparent !important;
  border-color: transparent !important;
  cursor: default !important;
}
.interactivity-btn:hover {
  box-shadow: none !important;
}
.interact-btn {
  cursor: pointer !important;
}
.interact-btn:hover {
  background-color: #7425f4 !important;
}
.interactivity-btn:focus {
  background-color: transparent !important;
}
.interactivity-icon {
  transition: color 0.3s ease-out;
}
.interact-btn:hover .interactivity-icon {
  color: #d9d9d9;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}

</style>
