<template>
  <b-modal
    id="modal-create-edit"
    ref="modal-create-edit"
    v-model="isVisible"
    ok-only
    size="lg"
    ok-variant="primary"
    :ok-title="$t('confirm')"
    modal-class="modal-primary"
    centered
    no-close-on-backdrop
    :title="$t(`standard-module.${mode}-standard`)"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-col md="12">
      <b-form @submit.prevent="submitData">
        <validation-observer ref="standardModalRef">
          <b-row>
            <b-col md="12">
              <b-form-group
                :label="$t('standard-module.name')"
                label-for="tem-name"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="name"
                  rules="required"
                  vid="name"
                >
                  <b-form-textarea
                    id="item_name"
                    v-model="localStandard.name"
                    name="item_name"
                    :state="errors.length > 0 ? false : null"
                    :rows="3"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                :label="$t('standard-module.code')"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('standard-module.code')"
                  rules="required"
                  vid="code"
                >
                  <b-form-input
                    v-model="localStandard.code"
                    :state="errors.length > 0 ? false : null"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <MainPoints :main-points="localStandard.main_points" />
        </validation-observer>
      </b-form>
    </b-col>

    <template v-if="standard?.standard_families.length > 0">
      <b-col md="12"
             class="mb-1"
      >
        <h5>Associate Standards</h5>
        <span
          v-for="(associate, index) in standard.standard_families"
          :key="index"
          class="border rounded shadow-sm"
          style="padding: 4px; margin-right: 5px;"
        >
          {{ `${associate.children.name} - ${associate.children.code} ,` }}
        </span>
      </b-col>
    </template>

    <associate-standard ref="associateStandard"
                        :grades="grades"
    />

    <template #modal-footer="{ }">
      <b-row>
        <b-col md="12"
               class="d-flex justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="isProcessing"
            @click="submitData()"
          >
            <b-spinner v-show="isProcessing"
                       small
            />
            {{ $t("actions." + mode) }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BForm, BFormInput, BFormGroup, BButton, BSpinner, BRow, BCol, BFormTextarea,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import MainPoints from './MainPoints.vue'
import AssociateStandard from './AssociateStandard.vue'

const initialStandard = () => ({
  name: '',
  code: '',
  main_points: {
    point_1: '',
    point_2: '',
    point_3: '',
  },
})

export default {
  components: {
    BModal,
    BForm,
    BFormTextarea,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BFormGroup,
    BRow,
    BButton,
    BSpinner,
    MainPoints,
    AssociateStandard,
  },
  directives: {
    Ripple,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    standard: {
      type: Object,
      default: () => {},
    },
    gradeId: {
      type: Number,
      default: null,
    },
    domainId: {
      type: Number,
      default: null,
    },
    subjectId: {
      type: Number,
      default: null,
    },
    grades: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isProcessing: false,
      localStandard: {
        ...initialStandard(),
      },
      isVisible: this.show,
    }
  },
  computed: {
    mode() {
      return this.standard ? 'update' : 'create'
    },
  },
  watch: {
    show() {
      this.isVisible = this.show
      this.$refs.associateStandard.resetData()
    },
    standard(newVal) {
      if (newVal) {
        this.localStandard = JSON.parse(JSON.stringify(newVal))
        this.$refs.associateStandard.setStandard(newVal)
      } else {
        this.localStandard = { ...initialStandard() }
      }
    },
  },
  methods: {
    createStandard() {
      this.isProcessing = true
      useJwt.createStandard({
        ...{
          grade_id: this.gradeId,
          subject_id: this.subjectId,
          standard_domain_id: this.domainId,
        },
        ...this.localStandard,
      }).then(res => {
        this.showSuccessMessage(res)
        this.localStandard = { ...initialStandard() }
        this.$emit('standardCreated')
      }).catch(e => {
        if (e.response?.data?.errors) this.$refs.standardModalRef.setErrors(e.response.data.errors)
        else this.showErrorMessage(e)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    updateStandard() {
      this.isProcessing = true
      useJwt.updateStandard(this.localStandard.id, {
        ...{
          grade_id: this.gradeId,
          subject_id: this.subjectId,
          standard_domain_id: this.domainId,
          associate_standard: this.$refs.associateStandard.getSelectedStandards(),
        },
        ...this.localStandard,
      }).then(res => {
        this.showSuccessMessage(res)
        this.$emit('standardUpdated')
      }).catch(e => {
        if (e.response?.data?.errors) this.$refs.standardModalRef.setErrors(e.response.data.errors)
        else this.showErrorMessage(e)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    submitData() {
      this.$refs.standardModalRef.validate().then(success => {
        if (success) {
          if (this.mode === 'create') this.createStandard()
          else this.updateStandard()
        }
      })
    },
  },
}
</script>
