<template>
  <b-modal
    :visible="show"
    title="Layout Settings"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-form-group v-if="!disabledFeatures.includes('background')">
      <template #label>
        <span class="text-center">Background</span>
      </template>
      <div class="d-flex justify-content-around">
        <verte
          v-model="settingConfig.background"
          picker="wheel"
          model="rgb"
        />
        <EyeDropper v-model="settingConfig.background" />
      </div>
    </b-form-group>
    <b-form-group v-if="enabledFeatures.includes('textColor')">
      <template #label>
        <span class="text-center">Text Color</span>
        <small>(Only works in transparent theme)</small>
      </template>
      <div class="d-flex justify-content-between">
        <verte
          v-model="settingConfig.textColor"
          picker="wheel"
          model="rgb"
        />
        <EyeDropper v-model="settingConfig.textColor" />
      </div>
    </b-form-group>
    <b-form-group label="Padding">
      <div class="row">
        <div class="col">
          <label class="control-label">Top</label>
          <input
            v-model="settingConfig.paddingTop"
            type="number"
            class="form-control"
            placeholder="Top"
          >
        </div>
        <div class="col">
          <label class="control-label">Right</label>
          <input
            v-model="settingConfig.paddingRight"
            type="number"
            class="form-control"
            placeholder="Right"
          >
        </div>
        <div class="col">
          <label class="control-label">Bottom</label>
          <input
            v-model="settingConfig.paddingBottom"
            type="number"
            class="form-control"
            placeholder="Bottom"
          >
        </div>
        <div class="col">
          <label class="control-label">Left</label>
          <input
            v-model="settingConfig.paddingLeft"
            type="number"
            class="form-control"
            placeholder="Left"
          >
        </div>
      </div>
    </b-form-group>
    <b-form-group
      v-if="enabledFeatures.includes('margin')"
      label="Margin"
    >
      <div class="row">
        <div class="col">
          <label class="control-label">Top</label>
          <input
            v-model="settingConfig.marginTop"
            type="number"
            class="form-control"
            placeholder="Top"
          >
        </div>
        <div class="col">
          <label class="control-label">Right</label>
          <input
            v-model="settingConfig.marginRight"
            type="number"
            class="form-control"
            placeholder="Right"
          >
        </div>
        <div class="col">
          <label class="control-label">Bottom</label>
          <input
            v-model="settingConfig.marginBottom"
            type="number"
            class="form-control"
            placeholder="Bottom"
          >
        </div>
        <div class="col">
          <label class="control-label">Left</label>
          <input
            v-model="settingConfig.marginLeft"
            type="number"
            class="form-control"
            placeholder="Left"
          >
        </div>
      </div>
    </b-form-group>
    <b-form-group
      v-if="!disabledFeatures.includes('border-radius')"
      label="Border Radius"
    >
      <div class="row">
        <div class="col">
          <label class="control-label">Top Left</label>
          <input
            v-model="settingConfig.borderTopLeftRadius"
            type="number"
            class="form-control"
            placeholder="Top"
          >
        </div>
        <div class="col">
          <label class="control-label">Top Right</label>
          <input
            v-model="settingConfig.borderTopRightRadius"
            type="number"
            class="form-control"
            placeholder="Right"
          >
        </div>
        <div class="col">
          <label class="control-label">Bottom Left</label>
          <input
            v-model="settingConfig.borderBottomLeftRadius"
            type="number"
            class="form-control"
            placeholder="Bottom"
          >
        </div>
        <div class="col">
          <label class="control-label">Bottom Right</label>
          <input
            v-model="settingConfig.borderBottomRightRadius"
            type="number"
            class="form-control"
            placeholder="Left"
          >
        </div>
      </div>
    </b-form-group>
    <b-form-group
      v-if="enabledFeatures.includes('alignment')"
      label="Alignment"
    >
      <b-form-select
        v-model="settingConfig.alignment"
        :options="alignmentOptions"
      />
    </b-form-group>
    <template #modal-footer="{ }">
      <b-button
        variant="default"
        @click="$emit('close')"
      >
        Close
      </b-button>
      <b-button
        variant="success"
        @click="handleOk"
      >
        Save
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal, BFormGroup, BButton, BFormSelect,
} from 'bootstrap-vue'
import Verte from 'verte'
import 'verte/dist/verte.css'
import EyeDropper from '@/views/common/components/EyeDropper.vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BButton,
    BFormSelect,
    EyeDropper,
    Verte,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: [Object, Array],
      default: () => {},
    },
    disabledFeatures: {
      type: Array,
      default: () => [],
    },
    enabledFeatures: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      settingConfig: { ...this.settings },
      alignmentOptions: [
        'top',
        'middle',
        'bottom',
      ],
    }
  },
  methods: {
    handleOk() {
      this.$emit('updateLayout', { ...this.settingConfig })
    },
  },
}
</script>
