<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ capitalizeFirstLetter(lessonStatus) }} 🕵🏻‍♀️
        </h2><p class="mb-2">
          {{ $t('requested-report-not-found') }}
        </p>
        <div>
          <a
            href="#"
            class="btn mb-2 router-link-active btn-primary"
            target="_self"
            @click.prevent="$router.go(-1)"
          > {{ $t('actions.back') }}</a>
        </div>
        <img
          src="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-2/img/error.700bd717.svg"
          alt="Error page"
          class="img-fluid"
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    lessonStatus: {
      type: String,
      default: '',
    },
  },
}
</script>
