<template>
  <b-modal :visible="true"
           :title="title"
           :ok-title="$t('ok')"
           :cancel-title="$t('cancel')"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <h4 v-if="logs.new_students">
      {{ logs.new_students.length }} {{ $t('new-student-created') }}
    </h4>
    <h4 v-if="logs.active_students">
      {{ logs.active_students }} {{ $t('student-activated') }}
    </h4>
    <h4 v-if="logs.new_schools">
      {{ logs.new_schools.length }} {{ $t('new-school-created') }}
    </h4>
    <h4 v-if="logs.deactivated_students">
      {{ logs.deactivated_students }} {{ $t('student-deactivated') }}
    </h4>
    <div v-if="logs.warnings && logs.warnings.length">
      <h4> {{ $t('Warning') }}:</h4>
      <b-list-group>
        <b-list-group-item v-for="wr,index of logs.warnings"
                           :key="index"
        >
          {{ wr }}
        </b-list-group-item>
      </b-list-group>
    </div>
    <div v-if="logs.errors && logs.errors.length">
      <h4> {{ $t('Error') }}:</h4>
      <b-list-group>
        <b-list-group-item v-for="wr,index of logs.errors"
                           :key="index"
                           class="text-danger"
        >
          {{ wr }}
        </b-list-group-item>
      </b-list-group>
    </div>
    <div v-if="logs.info && logs.info.length">
      <h4> {{ $t('Error') }}:</h4>
      <b-list-group>
        <b-list-group-item v-for="wr,index of logs.info"
                           :key="index"
                           class="text-info"
        >
          {{ wr }}
        </b-list-group-item>
      </b-list-group>
    </div>
  </b-modal>
</template>
<script>
import { BModal, BListGroup, BListGroupItem } from 'bootstrap-vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    BModal,
    BListGroup,
    BListGroupItem,
  },
  props: {
    logInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    logs() {
      return this.logInfo.logs
    },
    title() {
      return i18n.tc('excel-logs')
    },
  },
}
</script>
