import { render, staticRenderFns } from "./VocabCardWithLanguage.vue?vue&type=template&id=799eb83a&scoped=true"
import script from "./VocabCardWithLanguage.vue?vue&type=script&lang=js"
export * from "./VocabCardWithLanguage.vue?vue&type=script&lang=js"
import style0 from "./VocabCardWithLanguage.vue?vue&type=style&index=0&id=799eb83a&prod&scoped=true&lang=css"
import style1 from "vue-dialog-drag/dist/vue-dialog-drag.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "799eb83a",
  null
  
)

export default component.exports