<template>
  <div>
    <h4>{{ $t('report.student-comparative-notes-stats') }}</h4>
    <vue-apex-charts
      v-if="series.length"
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    studentId: {
      type: Number,
      default: 0,
    },
    classId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    const categories = []
    return {
      categories,
      series: [],
    }
  },
  computed: {
    chartOptions() {
      return {
        colors: this.getColors(),
        chart: {
          height: 150,
          type: 'bar',
        },
        xaxis: {
          categories: this.categories.map(cat => this.capitalizeFirstLetter(cat)),
          position: 'top',
        },
        dataLabels: {
          formatter(val) {
            return `${val}`
          },
        },
        yaxis: {
          min: 0,
          max: 100,
          labels: {
            formatter(val) {
              return `${val}`
            },
          },
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            dataLabels: {
              position: 'bottom',
            },
          },
        },
      }
    },
  },
  created() {
    if (this.studentId) {
      useJwt.getStudentNoteStat({
        userId: this.studentId,
        classId: this.classId,
      }).then(response => {
        const categories = []
        const requiredData = []
        const { data } = response.data
        data.notes.forEach(d => {
          if (!categories.find(cat => cat === d.lesson?.name)) {
            const score = data.notes.filter(l => l.lesson_id === d.lesson_id).length
            const maxValue = data.notes_stat.find(stat => stat.lesson_id === d.lesson_id).max_note
            const percentage = ((score / maxValue) * 100)
            categories.push(d.lesson?.name)
            requiredData.push(percentage.toFixed(2))
          }
        })
        this.categories = categories
        this.series = [
          {
            name: 'Student Role',
            data: requiredData,
          },
        ]
      })
    }
  },
  methods: {
    getColors() {
      return ['#4CAF4F', '#FFEB3A', '#F44336']
    },
  },
}
</script>
