/* eslint-disable import/no-cycle */
import isAuthenticated from '@/middlewares/isAuthenticated'
import parentUsersOnly from '@/middlewares/parentUsersOnly'

export default [
  // {
  //   path: '/parent/home',
  //   meta: {
  //     middleware: [isAuthenticated, parentUsersOnly],
  //   },
  //   name: 'parent-home',
  //   component: () => import('@/views/parent/Home.vue'),
  // },
  {
    path: '/parent/users',
    name: 'parent-users',
    component: () => import('@/views/common/user/Index.vue'),
    meta: {
      middleware: [isAuthenticated, parentUsersOnly],
    },
  },
  {
    path: '/parent/networks',
    meta: {
      middleware: [isAuthenticated, parentUsersOnly],
    },
    name: 'parent-networks',
    component: () => import('@/views/common/network/Index.vue'),
  },

  {
    path: '/parent/networks/:id',
    meta: {
      middleware: [isAuthenticated, parentUsersOnly],
      navActiveLink: 'parent-networks',
    },
    name: 'parent-networks-show',
    component: () => import('@/views/common/network/Show.vue'),
  },
  {
    path: '/parent/classes',
    meta: {
      middleware: [isAuthenticated, parentUsersOnly],
    },
    name: 'parent-classes',
    component: () => import('@/views/common/class/Handler.vue'),
  },
  {
    path: '/parent/classes/:id/:courseId?',
    name: 'parent-class',
    meta: {
      middleware: [isAuthenticated, parentUsersOnly],
      navActiveLink: 'parent-classes',
    },
    component: () => import('@/views/common/class/Show.vue'),
  },
  {
    path: '/parent/student-diary/:id',
    name: 'parent-student-diary',
    meta: {
      middleware: [isAuthenticated, parentUsersOnly],
    },
    component: () => import('@/views/common/student-diary/Index.vue'),
  },
  {
    path: '/parent/courses/:id',
    name: 'parent-course',
    meta: {
      middleware: [isAuthenticated, parentUsersOnly],
      navActiveLink: 'parent-classes',
    },
    component: () => import('@/views/common/class/components/ShowCourse.vue'),
  },
  {
    path: '/parent/view-students/:id',
    name: 'parent-view-students',
    meta: {
      middleware: [isAuthenticated, parentUsersOnly],
      navActiveLink: 'parent-classes',
    },
    component: () => import('@/views/v2/common/class/components/StudentTable.vue'),
  },
  {
    path: '/student/dashboard/:id',
    meta: {
      middleware: [isAuthenticated, parentUsersOnly],
    },
    name: 'student-dashboard-for-parent',
    component: () => import('@/views/common/dashboard/Index.vue'),
  },
  {
    path: '/parent/student/personality-evaluation',
    name: 'student-personality-evaluation-for-parent',
    meta: {
      middleware: [isAuthenticated, parentUsersOnly],
      navActiveLink: 'student-personality-evaluation-for-parent',
    },
    component: () => import('@/views/common/personality-evaluation/Index.vue'),
  },
  {
    path: '/parent/view-attendance/:id?',
    name: 'parent-view-attendance',
    meta: {
      middleware: [isAuthenticated],
    },
    component: () => import('@/views/student/attendance/index.vue'),
  },
]
