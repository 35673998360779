<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="pb-1">
          <b-button
            variant="flat-secondary"
            class="btn-icon"
            @click="GO_BACK()"
          >
            <feather-icon
              size="20"
              icon="ArrowLeftIcon"
            />
          </b-button>
          <span>
            <span class="text-capitalize">{{ classDetail.name }}</span>
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col
        md="8"
      >
        <b-card>
          <b-row class="pr-2 d-flex align-items-center justify-content-between">
            <b-col md="6">
              <h5 class="p-1 pb-0 mb-0">
                {{ $t("student-report-module.list-of-courses") }}
              </h5>
            </b-col>
            <b-table
              responsive
              :items="courses"
              :fields="columns"
              hovers
              show-empty
            >
              <template #empty="">
                <div class="w-100 d-flex justify-content-center align-items-centert">
                  <p
                    v-if="!isProcessing"
                    class="p-0 m-0"
                  >
                    {{ $t("messages.no-record-found") }}
                  </p>
                  <b-spinner
                    v-if="isProcessing"
                    class=""
                  />
                </div>
              </template>

              <template #cell(name)="data">
                <span>{{ data.item.name }}</span>
              </template>

              <template #cell(action)="data">
                <div class="d-flex">
                  <span>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-tooltip.hover.top="
                        $t('student-report-module.view-lesson-report')
                      "
                      :disabled="isProcessing"
                      variant="outline-primary"
                      class="btn-icon mr-1"
                      @click.stop="showReport(data.item.id)"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                  </span>
                </div>
              </template>

            </b-table>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import {
  BCard, BTable, BRow, BCol, BButton, VBTooltip, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      columns: [
        {
          key: 'name',
          label: 'Course Name',
        },
        {
          key: 'action',
          label: 'Action',
        },
      ],

      courses: [],
      classDetail: [],
      self: getUserData(),
      isProcessing: true,

    }
  },
  mounted() {
    this.getCourses()
    this.getClassDetail()
  },
  methods: {
    getCourses() {
      useJwt.getCourseForClass(this.$route.params.id).then(response => {
        this.courses = response.data.data
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => { this.isProcessing = false })
    },
    getClassDetail() {
      this.isProcessing = true
      useJwt.getClass(this.$route.params.id).then(response => {
        this.classDetail = response.data.data
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    showReport(id) {
      this.$router.push({
        name: `${this.self.usertype}-class`,
        params: { id: this.$route.params.id, courseId: id },
        query: this.self.id === this.viewingClassesOfId ? {} : { user_id: this.viewingClassesOfId },
      })
    },
  },
}
</script>
