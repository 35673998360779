<template>
  <div>
    <b-card
      v-for="(c,index) of coursesList"
      :key="index"
      class="mb-1"
    >
      <b-card-header
        class="p-0"
      >
        <div class="d-flex align-items-center">
          <feather-icon
            size="24"
            class="mr-1"
            icon="BookOpenIcon"
          />
          <span
            v-b-toggle="`collapse_lesson-${c.id}`"
            class="ml-25"
          >
            {{ c.name }}
          </span>
        </div>
      </b-card-header>
      <b-collapse
        :id="`collapse_lesson-${c.id}`"
        class="ml-0"
      >
        <b-list-group
          class="mt-2"
          style="max-height: 50vh;overflow-y: scroll"
        >
          <b-list-group-item
            v-for="lt of c.lessons"
            :key="lt.id"
            class="p-1"
          >
            <div
              v-b-toggle="`collapse_problem-${lt.id}`"
              class="d-flex align-items-center"
            >
              <feather-icon
                size="15"
                class="mr-1"
                icon="CreditCardIcon"
              />
              <b-card-text class="ml-25">
                {{ lt.name }}
              </b-card-text>
            </div>
            <b-collapse
              :id="`collapse_problem-${lt.id}`"
              class="ml-0"
            >
              <b-list-group
                class="mt-2"
                style="max-height: 50vh;overflow-y: scroll"
              >
                <b-list-group-item
                  v-for="problem of lt.problem_list"
                  :key="problem.id"
                  class="p-1"
                  :class="selectedProblem == problem.id ? 'selected-problem' : ''"
                  role="button"
                  @click="$emit('getNotes', problem.id)"
                >
                  <div
                    class="d-flex align-items-center"
                  >
                    <feather-icon
                      size="15"
                      class="mr-1"
                      icon="OctagonIcon"
                    />
                    <b-card-text class="ml-25 d-flex justify-content-between w-100">
                      <div>
                        {{ problem.name }}
                      </div>
                      <div v-if="countTotalNotes(problem.id) > 0">
                        <b-badge
                          class="float-right badge-pill"
                          variant="light-warning"
                        >
                          {{ countTotalNotes(problem.id) }}
                        </b-badge>
                      </div>
                    </b-card-text>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-collapse>
          </b-list-group-item>

        </b-list-group>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCardHeader, BListGroup, BListGroupItem, BCollapse, BCardText, VBToggle, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BCardHeader, BListGroup, BListGroupItem, BCollapse, BCardText, BBadge,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    coursesList: {
      type: Array,
      default: () => [],
    },
    selectedProblem: {
      type: [Object, Number, String],
      default: () => null,
    },
    problemNotesCounts: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    countTotalNotes(problemId) {
      return this.problemNotesCounts.filter(problem => Number(problem) === Number(problemId)).length
    },
  },
}
</script>
<style scoped>
  .selected-problem {
    background-color: #12141426 !important;
    color: white;
  }
</style>
