<template>
  <b-card>
    <form @submit.prevent="submitForm">
      <b-row>
        <b-col>
          <div class="form-group">
            <label for="linkName">{{ $t('page-link-module.link-name') }}</label>
            <input id="linkName"
                   v-model="filters.linkName"
                   type="text"
                   class="form-control input-sm"
                   debounce="800"
                   :placeholder="$t('page-link-module.link-name')"
                   required
            >
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="district">{{ $t('page-link-module.district') }}</label>
            <v-select
              id="district"
              v-model="filters.selectedDistricts"
              :options="districts"
              label="firstname"
              :reduce="item => item.id"
              multiple
              :placeholder="$t('page-link-module.select-district')"
            />
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="course">{{ $t('page-link-module.course') }}</label>
            <v-select
              id="course"
              v-model="filters.selectedCourse"
              :options="courses"
              :reduce="item => item.id"
              label="name"
              :placeholder="$t('page-link-module.select-course')"
              @input="onCourseChange"
            />
          </div>
        </b-col>
        <b-col>
          <div
            class="form-group"
          >
            <label for="lessonGroup">{{ $t('page-link-module.lesson-group') }}</label>
            <v-select
              id="lessonGroup"
              v-model="filters.selectedLessonGroup"
              :options="lessonGroups"
              label="name"
              :reduce="item => item.id"
              :placeholder="$t('page-link-module.select-lesson-group')"
            />
          </div>
        </b-col>
        <b-col>
          <div
            class="form-group"
          >
            <label for="lesson">{{ $t('page-link-module.lesson') }}</label>
            <v-select
              id="lesson"
              v-model="filters.selectedLesson"
              :reduce="item => item.id"
              :options="lessons"
              label="name"
              :placeholder="$t('page-link-module.select-lesson')"
            />
          </div>
        </b-col>
        <b-col>
          <div
            class="form-group"
          >
            <label for="lesson">&nbsp;</label>
            <b-button class="btn-block"
                      variant="outline-primary"
                      :disabled="isProcessing"
                      @click="$emit('filter')"
            >
              <b-spinner v-if="isProcessing"
                         small
              /> <feather-icon v-else
                               icon="SearchIcon"
              /> {{ $t('page-link-module.filter') }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </form>
  </b-card>
</template>

<script>
import VueSelect from 'vue-select'
import {
  BCard, BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    'v-select': VueSelect,
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
  },
  props: {
    courses: {
      type: Array,
      default: () => [],
    },
    districts: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Object,
      default: () => {},
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    lessonGroups() {
      if (this.filters.selectedCourse) {
        const course = this.courses.find(c => c.id === this.filters.selectedCourse)
        return course ? course.groups : []
      }
      return []
    },
    lessons() {
      if (this.filters.selectedLessonGroup) {
        const group = this.lessonGroups.find(
          g => g.id === this.filters.selectedLessonGroup,
        )
        return group ? group.lessons : []
      }
      return []
    },
  },
  methods: {
    onCourseChange() {
      this.filters.selectedLessonGroup = null
      this.filters.selectedLesson = null
    },
  },
}
</script>

<style scoped>
/* Add custom CSS styles here */
</style>
