<template>
  <div>
    <template v-for="book,index of ebooks">
      <b-button
        v-if="book.pageId"
        :key="index"
        class="ml-1"
        tag="a"
        :variant="(version == book.version || pageId == book.pageId)? 'relief-danger' : 'relief-primary'"
        @click="openCurriculumPage(book.curriculumId, book.pageId, book.type)"
      >
        {{ book.version }}
      </b-button>
    </template>
  </div>
</template>
<script>
import { BButton } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BButton,
  },
  props: {
    pageNo: {
      type: Number,
      default: 1,
    },
    lessonId: {
      type: Number,
      required: true,
    },
    version: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ebooks: [],
    }
  },
  computed: {
    pageId() {
      return this.$route.params.pageId
    },
  },
  mounted() {
    this.getEbookByVersions()
  },
  methods: {
    openLessonPage(lessonId, pageId) {
      const routeData = this.$router.resolve({ name: 'lesson-pages', params: { lessonId, pageId }, query: { ...this.$route.query } })
      window.open(routeData.href, '_self')
    },
    openCurriculumPage(curriculumId, pageId, type) {
      if (type === 'lesson_pages') {
        this.openLessonPage(curriculumId, pageId)
        return
      }
      const routeData = this.$router.resolve({ name: 'curriculum-pages', params: { curriculumId, pageId }, query: { ...this.$route.query } })
      window.open(routeData.href, '_self')
    },
    getEbookByVersions() {
      this.isProcessing = true
      useJwt.getEbookByVersions(this.lessonId, {
        params: {
          page_no: this.pageNo ? this.pageNo : null,
        },
      }).then(response => {
        const ebook = response.data?.data
        if (ebook) {
          this.ebooks = ebook.sort((a, b) => {
            if (a.version === 'M') return -1
            if (b.version === 'M') return 1
            return b.version - a.version
          })
        }
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
