<template>
  <b-modal :visible="true"
           :title="$t('image-library-module.language-translate')"
           size="xl"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col>
          <b-form-group :label="$t('image-library-module.select-language')">
            <validation-provider
              v-slot="{ errors }"
              name="Language"
              rules="required"
            >
              <b-form-select v-model="state.selectedLang"
                             :options="languageOptions"
                             text-field="name"
                             value-field="value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <template #label>
              &nbsp;
            </template>
            <b-button variant="primary"
                      :disabled="state.isProcessing"
                      @click="handleLibrarySave"
            >
              <b-spinner v-if="state.isProcessing"
                         small
              /> {{ $t('actions.save') }}
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-overlay :show="state.fetchingOther">
        <b-row>
          <b-col v-for="image,index of otherVersions"
                 :key="index"
                 md="3"
          >
            <library-card :item="image"
                          @onEdit="handleEdit"
                          @onDelete="value => state.imageLibraryToDelete = value"
            />
          </b-col>
        </b-row>
      </b-overlay>
    </validation-observer>
    <manage-image-library :show="!!state.imageLibraryToEdit"
                          :imagelibrary-info="state.imageLibraryToEdit"
                          @close="state.imageLibraryToEdit = null"
                          @reFetch="getAllLang()"
    />
    <DeleteModal title="Delete?"
                 :show="!!state.imageLibraryToDelete"
                 sub-title="Are you sure you want to delete?"
                 :on-delete="handleDelete"
                 :is-processing-delete="state.isDeleting"
                 @close="imageLibraryToDelete = null"
    />
    <template #modal-footer>
      <div>
        <b-button class="mr-2"
                  @click="$emit('close')"
        >
          {{ $t('actions.close') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script setup>
import {
  BModal, BFormSelect, BFormGroup, BButton, BSpinner, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import locales from '@/const/locales'
import {
  reactive, getCurrentInstance, ref, onMounted,
} from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import LibraryCard from './LibraryCard.vue'
import ManageImageLibrary from './Manage.vue'

const props = defineProps({
  imgId: {
    type: Number,
    required: true,
  },
})

const languageOptions = locales.map(i => ({
  name: i.name,
  value: i.value,
})).filter(item => item.value !== 'english')

const state = reactive({
  isProcessing: false,
  selectedLang: null,
  fetchingOther: false,
  imageLibraryToEdit: null,
  imageLibraryToDelete: null,
  isDeleting: false,
})

const root = getCurrentInstance().proxy.$root
const simpleRules = ref(null)
const otherVersions = ref([])

const getAllLang = () => {
  state.fetchingOther = true
  useJwt.getImageLibrary({
    params: {
      parent_id: props.imgId,
    },
  }).then(response => {
    otherVersions.value = response.data.data
  }).finally(() => {
    state.fetchingOther = false
  })
}

const handleLibrarySave = () => {
  simpleRules.value.validate().then(success => {
    if (success) {
      state.isProcessing = true
      useJwt.translateLibrary(props.imgId, {
        lang: state.selectedLang,
      }).then(response => {
        getAllLang()
        root.showSuccessMessage(response)
      }).catch(error => {
        root.showErrorMessage(error)
      }).finally(() => {
        state.isProcessing = false
      })
    }
  })
}

const handleEdit = library => {
  state.imageLibraryToEdit = { ...library }
}

const handleDelete = () => {
  state.isDeleting = true
  useJwt.deleteImageLibrary(state.imageLibraryToDelete.id).then(response => {
    state.imageLibraryToDelete = null
    root.showSuccessMessage(response)
    getAllLang()
  }).catch(error => {
    this.showErrorMessage(error)
  }).finally(() => {
    state.isDeleting = false
  })
}

onMounted(() => {
  getAllLang()
})
</script>
