<template>
  <b-row>
    <b-col md="12">
      <div class="bg-white mb-1 rounded"
           style="padding-top: 5px; padding-bottom: 5px;"
      >
        <b-button
          variant="flat-secondary"
          class="btn-icon"
          @click="GO_BACK()"
        >
          <feather-icon
            size="20"
            icon="ArrowLeftIcon"
          />
        </b-button>
        <span>
          <span class="text-capitalize">Your Events</span>
        </span>
      </div>
    </b-col>
    <b-col md="12">
      <div class="app-calendar overflow-hidden border">
        <div class="row no-gutters">
          <!-- Sidebar -->
          <!-- <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{'show': isCalendarOverlaySidebarActive}"
      >
        <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
      </div> -->

          <!-- Calendar -->
          <div class="col position-relative">
            <div class="card shadow-none border-0 mb-0 rounded-0">
              <div class="card-body pb-0">
                <full-calendar
                  ref="refCalendar"
                  :options="calendarOptions"
                  class="full-calendar"
                />
              </div>
            </div>
          </div>

          <!-- Sidebar Overlay -->
          <div
            class="body-content-overlay"
            :class="{'show': isCalendarOverlaySidebarActive}"
            @click="isCalendarOverlaySidebarActive = false"
          />
          <calendar-event-handler
            v-model="isEventHandlerSidebarActive"
            :event="event"
            :clear-event-data="clearEventData"
            @remove-event="removeEvent"
            @add-event="(e)=>addEvent({...{class_id:classId,student_id:studentId},...e})"
            @update-event="(e)=>updateEvent({...{class_id:classId,student_id:studentId},...e})"
          />
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow, BButton } from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import { onUnmounted } from 'vue'
import { mapGetters } from 'vuex'
import calendarStoreModule from './calendarStoreModule'
// import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    // CalendarSidebar,
    CalendarEventHandler,
    BButton,
    BCol,
    BRow,
  },

  data() {
    return {
      classId: null,
      studentId: null,
    }
  },
  computed: {
    ...mapGetters({
      studentName: 'calendar/studentName',
      className: 'calendar/className',
    }),
  },

  watch: {
    $route(to, from) {
      if (to !== from) {
        this.initiate()
      }
    },
  },

  created() {
    this.initiate()
  },

  methods: {
    initiate() {
      this.classId = parseInt(this.$route.params.classId, 10)
      this.studentId = this.$route.params.studentId ? parseInt(this.$route.params.studentId, 10) : null

      this.$store.dispatch('calendar/setClassId', this.classId)
    },
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar()

    fetchEvents()

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
