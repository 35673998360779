<template>
  <div>
    <b-row class="justify-content-between">
      <b-col md="2">
        <!-- Add new button -->
        <b-button
          variant="primary"
          class="mb-1"
          @click="showModal()"
        >
          {{ $t('personality-module.add-new') }}
        </b-button>
      </b-col>

      <b-col md="4">
        <!-- Search Bar -->
        <b-form-group
          :label="$t('messages.seachLabel')"
          label-cols="2"
        >
          <b-form-input
            v-model="searchTerm"
            type="text"
            class="mr-2"
            :placeholder="$t('personality-module.search-personality')"
            @input="search"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Content Table -->
    <b-overlay :show="isLoading">
      <b-table
        class="personality_table"
        :items="filteredRows ? filteredRows : personalities"
        :fields="fields"
        :empty-text="$t('messages.no-data-available')"
        sticky-header
        responsive
        show-empty
      >
        <!-- Empty Table -->
        <template #empty="scope">
          {{ scope.emptyText }}
        </template>

        <!-- Serial Number -->
        <template #cell(id)="data">
          {{ data.index + 1 }}
        </template>

        <!-- Vibes -->
        <template #cell(vibes)="data">
          <span
            v-for="vibe, index in data.item.vibes"
            :key="vibe.id"
          >
            <b-badge
              :id="`vibe-${data.item.id}-${index}`"
              variant="primary"
              class="mr-1"
            >
              {{ vibe.name }}
            </b-badge>

            <b-popover
              :target="`vibe-${data.item.id}-${index}`"
              triggers="hover"
              placement="bottom"
            >
              <b-card
                class="mb-0"
                :img-src="vibe.icon ? vibe.icon : defaultVibes[vibe.code]"
                no-body
              />
            </b-popover>
          </span>
        </template>

        <!-- Actions -->
        <template #cell(action)="data">
          <span>
            <span>
              <b-button
                variant="outline-primary"
                class="btn-icon mr-1"
                @click="showModal(data.item.id)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </span>

            <span>
              <b-button
                v-b-modal.personality-delete-modal
                variant="outline-danger"
                class="btn-icon mt-1 mt-md-0"
                @click="() => { personality = data.item }"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </span>
          </span>

        </template>
      </b-table>
    </b-overlay>

    <!-- Modals -->
    <create-edit
      :personality="personality"
      :categories="categories"
      :mode="mode"
      :vibes="vibes"
      :default-vibes="defaultVibes"
      @getPersonalities="getPersonalities"
      @loading="() => isLoading = true"
    />

    <b-modal
      id="personality-delete-modal"
      :title="$t('personality-module.delete-title')"
      :ok-title="$t('delete')"
      :cancel-title="$t('cancel')"
      ok-variant="danger"
      @ok="deletePersonality()"
    >
      <p>
        {{ $t('personality-module.delete-confirmation', { name: personality.name}) }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BRow, BCol, BTable, BButton, BModal, BFormGroup, BFormInput, BOverlay, BBadge, BPopover, BCard,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import i18n from '@/libs/i18n'
import CreateEdit from './components/CreateEdit.vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    BOverlay,
    BBadge,
    BPopover,
    BCard,
    CreateEdit,
  },
  data() {
    return {
      personalities: null,
      fields: [
        { key: 'id', label: i18n.tc('sno') },
        { key: 'name', label: i18n.tc('actions.name') },
        { key: 'category', label: i18n.tc('labels.category') },
        { key: 'value', label: i18n.tc('labels.value') },
        { key: 'vibes', label: i18n.tc('labels.vibes') },
        { key: 'action', label: i18n.tc('action') },
      ],
      categories: [],
      personality: {},
      searchTerm: '',
      filteredRows: null,
      isLoading: false,
      mode: '',
      vibes: [],
    }
  },
  computed: {
    ...mapGetters('app-chat', {
      defaultVibes: 'get_default_vibes',
    }),
  },
  mounted() {
    this.getPersonalities()
    this.getVibes()
  },
  methods: {
    getPersonalities() {
      this.isLoading = true
      useJwt.getPersonalities().then(res => {
        this.personalities = res.data?.data
        Object.values(this.personalities).forEach(personality => {
          if (!this.categories.includes(personality.category)) this.categories.push(personality.category)
        })
      }).finally(() => { this.isLoading = false })
    },
    getVibes() {
      useJwt.getVibes().then(res => {
        this.vibes = res.data?.data
      })
    },
    search() {
      this.filteredRows = this.personalities.filter(personality => personality.name.toLowerCase().includes(this.searchTerm))
    },
    showModal(id = null) {
      this.mode = 'create'
      if (id) {
        this.mode = 'edit'
        this.personality = this.personalities.find(personality => personality.id === id)
      }
      this.$nextTick(() => { this.$bvModal.show('personalityCreateEdit') })
    },
    deletePersonality() {
      useJwt.deletePersonality(this.personality.id).then(response => {
        this.showSuccessMessage(response)
      }).finally(() => { this.getPersonalities() })
    },
  },
}
</script>

<style scoped>
.b-table-sticky-header {
  max-height: 78vh;
}
.badge {
  margin: 4px auto;
  cursor: default;
}
</style>

<style>
.dark-layout .personality_table .table-b-table-default {
  color: #e6e6e6 !important;
}
.personality_table .b-table-empty-row {
  text-align: center;
}
</style>
