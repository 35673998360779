<template>
  <b-card
    class="profile-header mb-2"
    alt="cover photo"
    :img-src="coverImgUrl"
    img-top
    body-class="p-0"
  >
    <!-- profile picture -->
    <div class="position-relative">
      <div class="profile-img-container d-flex align-items-center">
        <div class="profile-img d-flex align-items-center justify-content-center position-relative">
          <avatar
            :image="avatar"
            size="110px"
            variant="light-primary"
            class="badge-minimal"
          />

          <template v-if="isViewingOwnProfile">
            <feather-icon
              icon="CameraIcon"
              size="25"
              class="profile-edit-icon d-none text-primary"
              style="position:absolute; bottom:0; right:0;"
              @click="$refs.profilePicUpload.click()"
            />

            <input
              ref="profilePicUpload"
              type="file"
              hidden
              accept="image/jpeg, image/png, image/gif"
              @input="profileImageChanged"
            >
          </template>
          <!-- <avatar :image="headerData.avatar" /> -->
        </div>
        <!-- profile title -->
        <div class="profile-title ml-3">
          <h2 class="text-white">
            {{ headerData.username }}
          </h2>
          <p class="text-white">
            {{ $t(headerData.designation) }}
          </p>
        </div>
        <!--/ profile title -->
      </div>
    </div>
    <!--/ profile picture -->

    <!-- profile navbar -->
    <div class="profile-header-nav">
      <b-navbar
        toggleable="md"
        type="light"
      >
        <!-- toggle button -->
        <b-navbar-toggle
          class="ml-auto"
          target="nav-text-collapse"
        >
          <feather-icon
            icon="AlignJustifyIcon"
            size="21"
          />
        </b-navbar-toggle>
        <!--/ toggle button -->

        <!-- collapse -->
        <b-collapse
          id="nav-text-collapse"
          is-nav
        >
          <b-tabs
            pills
            class="profile-tabs mt-1 mt-md-0"
            nav-class="mb-0"
          >
            <template #tabs-start>
              <b-nav-item
                :active="activeTab=='home'"
                role="presentation"
                class="font-weight-bold"
                @click="activeTab='home'"
              >
                <span class="d-none d-md-block">{{ $t("profile-module.home") }}</span>
                <feather-icon
                  icon="ImageIcon"
                  class="d-block d-md-none"
                />
              </b-nav-item>
            </template>

            <!-- <template #tabs-end>
              <b-button
                variant="primary"
                class="ml-auto"
              >
                <feather-icon
                  icon="EditIcon"
                  class="d-block d-md-none"
                />
                <span class="font-weight-bold d-none d-md-block">Edit</span>
              </b-button>
            </template> -->
          </b-tabs>

        </b-collapse>
        <!--/ collapse -->
      </b-navbar>
    </div>
    <!--/ profile navbar -->
  </b-card>
</template>

<script>
import {
  BCard, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Avatar from '@/views/common/components/Avatar.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BTabs,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    Avatar,

  },
  directives: {
    Ripple,
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
    isViewingOwnProfile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 'home',
      avatar: '',
      // eslint-disable-next-line global-require
      coverImg: require('@/assets/images/pages/study.jpg'),

    }
  },
  computed: {
    coverImgUrl() {
      return this.coverImg
    },
  },
  mounted() {
    this.avatar = this.headerData.avatar
  },
  methods: {
    profileImageChanged(e) {
      const file = e.target.files[0]
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
      const data = new FormData()
      data.append('avatar', file)
      useJwt.axiosIns.post('/api/v1/user/avatar/update', data, config)
        .then(response => {
          this.$root.$emit('userUpdate', response.data.data)
          localStorage.setItem('userData', JSON.stringify(response.data.data))
          this.avatar = response.data.data.avatar
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {

        })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-profile.scss';
.nav-pills .nav-link {
  padding: 0.786rem 0.5rem !important;
}
.profile-img:hover{
  .profile-edit-icon{
    display: block !important;
    cursor: pointer;
  }
}
</style>
