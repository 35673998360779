<template>
  <div class="courses">
    <b-button
      v-for="subject in subjects"
      :key="subject"
      :variant="(selectedSubject == subject || subjects.length === 1) ? 'primary' : 'outline-primary'"
      class="mr-1 mb-2"
      @click="() => { selectedSubject = subject }"
    >
      {{ subject }}
    </b-button>
    <!-- Title -->
    <div class="d-flex justify-content-between">
      <h3>
        {{ $t(`courses-module.${currentList}`) }}
        <span v-if="currentList === 'search-result'">
          ({{ $t(`courses-module.${type}`) }})
        </span>
      </h3>

      <div
        v-if="currentList === 'search-result'"
        class="d-flex align-items-baseline"
      >
        <label class="mr-1">{{ $t('courses-module.courses') }}</label>
        <b-form-checkbox
          v-model="type"
          switch
          value="lessons"
          unchecked-value="courses"
        >
          {{ $t('courses-module.lessons') }}
        </b-form-checkbox>
      </div>
    </div>

    <!-- Courses List -->
    <b-card v-if="type === 'courses' && !items.length">
      <b-card-title class="text-center">
        {{ $t('courses-module.no-search-courses') }}
      </b-card-title>
    </b-card>

    <b-card v-else-if="type === 'lessons' && !lessons.length">
      <b-card-title class="text-center">
        {{ $t('courses-module.no-search-lessons') }}
      </b-card-title>
    </b-card>

    <b-row
      v-else
      class="course-list"
    >
      <b-col
        v-for="item, index in getItems.slice(start, end)"
        :key="item.id"
        md="4"
      >
        <course-card
          :item="item"
          :is-search-result="currentList === 'search-result'"
          :index="index"
          :type="type"
          @on-card-click="updateClass"
        />
      </b-col>
    </b-row>

    <b-pagination
      v-if="filtered && filtered.length ? filtered.length > perPage : type === 'courses' ? items && items.length > perPage : lessons && lessons.length > perPage"
      v-model="page"
      :total-rows="filtered && filtered.length ? filtered.length : items.length"
      :per-page="perPage"
      align="center"
      @input="onPageChange"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardTitle, BPagination, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import {
  USER_TYPE_STUDENT,
} from '@/const/userType'
import GEC_EVENT_BUS from '@/utils/eventBus'
import CourseCard from './CourseCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BPagination,
    BButton,
    BFormCheckbox,
    CourseCard,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    lessons: {
      type: Array,
      default: () => [],
    },
    currentList: {
      type: String,
      default: () => 'CourseList',
    },
    selectedGrade: {
      type: Number,
      default: () => null,
    },
    searchTerm: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      self: getUserData(),
      page: 1,
      start: 0,
      end: 12,
      perPage: 12,
      subjects: null,
      selectedSubject: '',
      filtered: null,
      currentIndex: null,
      type: 'courses',
    }
  },
  computed: {
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
    getItems() {
      // eslint-disable-next-line no-nested-ternary
      return this.filtered && this.filtered.length
        ? this.filtered
        : this.type === 'courses'
          ? this.items
          : this.lessons
    },
  },
  watch: {
    items: {
      handler(val) {
        this.resetPagination()
        this.selectedSubject = ''
        this.subjects = []
        this.getSubjectsOfGrade()
        if (!val.length && this.lessons.length) this.type = 'lessons'
        else this.type = 'courses'
      },
      immediate: true,
    },
    selectedSubject() {
      this.resetPagination()
      this.filtered = this.items.filter(item => item.subject.name === this.selectedSubject)
    },
  },
  mounted() {
    if (this.items) this.getSubjectsOfGrade()
    GEC_EVENT_BUS.$on('clearCourseFilter', () => {
      this.resetPagination()
      this.selectedSubject = ''
      this.filtered = null
    })
  },
  methods: {
    getSubjectsOfGrade() {
      if (this.items) this.subjects = [...new Set(this.items.map(item => item.subject.name))]
    },
    onPageChange() {
      this.start = (this.page - 1) * this.perPage
      this.end = this.page * this.perPage
    },
    updateClass({ courseId, lessonId }) {
      let classId
      const params = {
        name: `${this.self.firstname} private class`,
        course_id: courseId,
        type: 'personal',
        created_by: this.self.id,
      }

      useJwt.updateDefaultClassroom(
        this.self.id,
        params,
      ).then(response => {
        classId = response.data?.data?.id

        if (this.isAStudent) this.addStudentToClass(classId)
      }).finally(() => {
        if (this.type === 'courses') {
          this.$router.push({
            name: `${this.self.usertype}-lessons`,
            params: {
              classId,
              id: courseId,
              type: 'courses',
              grade: this.selectedGrade,
              searchTerm: this.searchTerm,
            },
          })
        } else {
          this.$router.push({
            name: `${this.self.usertype}-lesson`,
            params: {
              id: lessonId,
              type: 'courses-lesson',
              classId,
              searchTerm: this.searchTerm,
            },
          })
        }
      })
    },
    addStudentToClass(classId) {
      useJwt.addStudentToClass(classId, {
        students: this.self.id,
      })
    },
    resetPagination() {
      this.start = 0
      this.end = 12
      this.page = 1
    },
  },
}
</script>

<style>
 .courses .custom-control-input:not(:checked) ~ .custom-control-label::before {
    border-color: #7367f0 !important;
    background-color: #7367f0 !important;
}
</style>
