<template>
  <div>
    <div class="mb-2">
      <div class="row">
        <div class="col-8">

          <div class="row">
            <div class="col-5">
              <v-select v-model="selectedLibrary"
                        :placeholder="$t('curriculum-pages-module.all-libraries')"
                        :options="cbLibraries"
                        :reduce="item => item.id"
                        label="name"
              />
            </div>
            <div class="col-4">
              <v-select v-model="selectedVersion"
                        :placeholder="$t('curriculum-pages-module.all-libraries')"
                        :options="availableVersions"
                        :reduce="item => item.value"
                        label="text"
              />
            </div>
          </div>

        </div>
        <div class="col-4 text-right">
          <b-button variant="outline-primary"
                    @click="showMisMatchedProblem = true"
          >

            {{ $t('curriculum-pages-module.show-mismatched-problem') }}
          </b-button>
          <MismatchedProblem v-if="showMisMatchedProblem"
                             :selected-library="+selectedLibrary"
                             @close="showMisMatchedProblem = false"
          />
        </div>
      </div>
    </div>
    <b-tabs pills>
      <b-tab
        :title="$t('curriculum-pages-module.four-level')"
      >
        <MainTable :key="selectedLibrary + selectedVersion"
                   type="four_level"
                   :selected-library="Number(selectedLibrary)"
                   :selected-version="selectedVersion"
                   :cb-libraries="cbLibraries"
        />
      </b-tab>
      <b-tab :title="$t('curriculum-pages-module.normal-lesson')">
        <MainTable :key="selectedLibrary"
                   type="normal"
                   :selected-library="Number(selectedLibrary)"
                   :cb-libraries="cbLibraries"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import MainTable from './MainTable.vue'
import MismatchedProblem from './components/MismatchedProblem.vue'

export default {
  components: {
    MainTable,
    BTabs,
    BTab,
    BButton,
    MismatchedProblem,
    vSelect,
  },
  data() {
    return {
      cbLibraries: [],
      selectedLibrary: this.$route.query.library ? Number(this.$route.query.library) : null,
      showMisMatchedProblem: false,
      selectedVersion: null,
      availableVersions: [
        {
          text: 'All Versions',
          value: '',
        },
        {
          text: 'Version 4 (B2)',
          value: '4',
        },
        {
          text: 'Version 3 (B1)',
          value: '3',
        },
        {
          text: 'Version 2 (A2)',
          value: '2',
        },
        {
          text: 'Version 1 (A1)',
          value: '1',
        },
        {
          text: 'Version M (Master)',
          value: 'M',
        },
      ],
    }
  },
  mounted() {
    this.getLibraryInfo()
  },
  methods: {
    getLibraryInfo() {
      useJwt.getCoursesLessonWithLibrary().then(response => {
        this.cbLibraries = response.data.data.libraries
      })
    },
  },

}
</script>
