<template>
  <b-overlay :show="isProcessing">
    <div v-if="lesson">
      <h4>{{ lesson.name }}</h4>
      <div v-if="liveLink">
        <iframe :src="liveLink"
                :title="lesson.name"
                frameborder="0"
                width="100%"
                height="900px"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
        />
      </div>
    </div>
  </b-overlay>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import { BOverlay } from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
  },
  data() {
    return {
      isProcessing: false,
      lesson: null,
      liveLink: null,
      lessonId: this.$route.params.lessonId,
      classId: this.$route.params.classId,
      authUser: this.AUTH_USER(),
    }
  },
  mounted() {
    this.getLessonData()
  },
  methods: {
    getLessonData() {
      this.isProcessing = true
      useJwt.getLessonEntryData(this.lessonId).then(res => {
        this.lesson = res.data.data
        this.liveLink = `${res.data.data.live_link}&userId=${this.authUser.id}&classId=${this.classId}`
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
