<template>
  <b-card>
    <b-card-text>
      <h4>{{ $t('essay-builder-module.select-images') }}</h4>
    </b-card-text>
    <b-row>
      <b-col
        v-for="(img,index) in images"
        :key="index"
        md="3"
        class="mb-1"
      >
        <div
          class="selectable-image"
          style="height:150px"
          :class="{'cant-select':(!isEditable || (selectedImages.length===3 && !selectedImages.includes(img))),'selected-image':selectedImages.includes(img),'not-selected':!selectedImages.includes(img)}"
          @click="toggleImage(img)"
        >
          <b-img
            class="w-100 h-100"
            :src="img"
            style="object-fit:cover"
            alt=""
          />
          <div
            v-if="selectedImages.includes(img)"
            class="check-icon"
          >
            <feather-icon
              size="3x"
              icon="CheckIcon"
            />

          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BImg, BCard, BCardText,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    BRow, BCol, BImg, BCard, BCardText,
  },
  props: {
    images: {
      type: [Array],
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
    essayImages: {
      type: [Array],
      default: () => [],
    },
  },
  data() {
    return {
      selectedImages: this.essayImages,
    }
  },
  watch: {
    essayImages() {
      this.selectedImages = this.essayImages
    },
  },
  methods: {
    toggleImage(img) {
      if (!this.isEditable) return
      if (!this.selectedImages.includes(img)) {
        if (this.selectedImages.length === 3) {
          this.showError(i18n.tc('essay-builder-module.only-select-3-images'))
        } else { this.selectedImages.push(img) }
      } else { this.selectedImages.splice(this.selectedImages.indexOf(img), 1) }

      this.$emit('imagesSelected', this.selectedImages)
    },
  },
}
</script>

<style scoped>
 .selectable-image{
     transition: all 100ms ease-in-out;
     cursor: pointer;
     position: relative;
   border-radius: 0 !important;
 }
 .cant-select{
        cursor:not-allowed;
 }
 .selected-image{
   border-radius: 0 !important;
   border: 4px #27ae60 solid !important;

 }
 .check-icon{
   position: absolute;
   top:20px;
   right:20px;
   color:#27ae60;
 }
</style>
