<template>
  <validation-observer ref="categoryForm">
    <div
      v-for="(category,index) of categories[level]"
      :key="index"
      class="border border-success p-2"
    >
      <b-row>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="answer"
            :vid="`question-${level}`"
          >
            <b-form-group label="Question">
              <b-form-input
                v-model.lazy="category.question"
                placeholder="Enter a category"
                @change="updateCategoryForAllLevel"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col
          v-if="index > 0"
          sm="1"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            class="btn-icon mt-2"
            size="sm"
            @click="removeProblem(index)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-for="(answer, ansIndex) of category.answers"
        :key="ansIndex"
        class="border border-secondary"
      >
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="answer"
            :vid="`answer-text-${level}-${ansIndex}`"
          >
            <b-form-group
              label="Answer"
            >
              <b-form-input
                v-model.lazy="category.answers[ansIndex]"
                class="mb-2"
                placeholder="Enter an answer"
                @change="updateCategoryForAllLevel"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small><br>
          </validation-provider>
          <b-form-group
            v-if="answerType === 'image'"
            :label="$t('labels.create-problem.image')"
            label-for="article-image"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="answer"
              :vid="`answer-image-${level}-${ansIndex}`"
            >
              <image-form
                :url="category.images[ansIndex]"
                @input="handleImage($event,category.images, ansIndex)"
                @fileTypeInvalid="setError([`answer-image-${level}-${ansIndex}`], $event)"
                @fileSizeInvalid="setError([`answer-image-${level}-${ansIndex}`], $event)"
              />
              <input
                v-model="category.images[ansIndex]"
                hidden
              >
              <small class="text-danger">{{ errors[0] }}</small><br>
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-else-if="answerType === 'audio'"
            :label="$t('labels.create-problem.answer')"
            label-for="article-image"
          >
            <Recorder
              :url="category.audio[ansIndex]"
              @input="handleAudio($event,category.audio, ansIndex)"
              @fileTypeInvalid="setError([`answer-audio-${level}-${ansIndex}`], $event)"
              @fileSizeInvalid="setError([`answer-audio-${level}-${ansIndex}`], $event)"
            />
            <validation-provider
              v-slot="{ errors }"
              name="answer"
              rules="required"
              :vid="`answer-audio-${level}-${ansIndex}`"
            >
              <input
                v-model="category.audio[ansIndex]"
                hidden
              >
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          v-if="ansIndex > 0"
          sm="1"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            class="btn-icon mt-2"
            size="sm"
            @click="removeAnswer(category.answers, ansIndex)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-success"
            class="btn-icon mt-2"
            size="sm"
            @click="addAnswers(category.answers)"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-col>
      </b-row>
    </div>
    <hr>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      class="btn-icon"
      @click="addQuestion"
    >
      <feather-icon icon="PlusIcon" />
      {{ $t("Add-New") }}
    </b-button>
  </validation-observer>
</template>
<script>
import {
  BFormInput, BFormGroup, BCol, BRow, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import ImageForm from '../ImageForm.vue'
import Recorder from '../recorder.vue'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BCol,
    BRow,
    BButton,
    // BFormSelect,
    ImageForm,
    ValidationProvider,
    ValidationObserver,
    Recorder,
  },
  directives: {
    Ripple,
  },
  props: {
    level: {
      type: Number,
      default: () => 0,
    },
    answerType: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      categories: this.$store.state.problem.create.category.items,
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.problem.create.category.items': {
      handler(value) {
        this.$nextTick(() => {
          this.categories = value
        })
      },
      deep: true,
    },
  },
  methods: {
    addQuestion() {
      this.categories[this.level].push({
        question: '',
        answers: [''],
        images: [],
        audio: [],
        questionType: 'text',
      })
      this.$nextTick(this.updateCategoryForAllLevel)
    },
    removeProblem(index) {
      this.categories.forEach(category => {
        if (category[index]) {
          category.splice(index, 1)
        }
      })
      this.updateCategoryForAllLevel()
    },
    addAnswers(answers) {
      answers.push('')
      this.updateCategoryForAllLevel()
    },
    removeAnswer(answers, index) {
      answers.splice(index, 1)
      this.updateCategoryForAllLevel()
    },
    handleImage({ url }, object, index) {
      // eslint-disable-next-line no-param-reassign
      object[index] = url
      this.updateCategoryForAllLevel()
    },
    handleAudio({ url }, object, index) {
      // eslint-disable-next-line no-param-reassign
      object[index] = url
      this.updateCategoryForAllLevel()
    },
    updateCategoryForAllLevel() {
      const newValue = [...this.categories]
      const categoryToUpdate = [[], [], [], []]
      const maxLevelCategory = newValue[this.level]
      newValue.forEach((value, index) => {
        maxLevelCategory.forEach((v, i) => {
          // if question is not on other level
          if (newValue[index][i] && !newValue[index][i].question) {
            newValue[index][i].question = v.question
          }

          // if answers is not on other level
          if (newValue[index][i] && newValue[index][i].answers.length) {
            const answers = []
            const audio = []
            const images = []
            v.answers.forEach((ans, ansIndex) => {
              if (!newValue[index][i].answers[ansIndex]) {
                answers.push(ans)
              } else {
                answers.push(newValue[index][i].answers[ansIndex])
              }
            })
            v.images.forEach((ans, ansIndex) => {
              if (!newValue[index][i].images[ansIndex]) {
                images.push(ans)
              } else {
                images.push(newValue[index][i].images[ansIndex])
              }
            })
            v.audio.forEach((ans, ansIndex) => {
              if (!newValue[index][i].audio[ansIndex]) {
                audio.push(ans)
              } else {
                audio.push(newValue[index][i].audio[ansIndex])
              }
            })
            newValue[index][i].answers = answers
            newValue[index][i].audio = audio
            newValue[index][i].images = images
            newValue[index][i].questionType = v.questionType
          } else if (newValue[index][i]) {
            newValue[index][i].answers = [...v.answers]
            newValue[index][i].images = [...v.images]
            newValue[index][i].audio = [...v.audio]
            newValue[index][i].questionType = v.questionType
          }

          if (!newValue[index][i]) {
            newValue[index][i] = { ...v }
          }

          categoryToUpdate[index][i] = { ...newValue[index][i] }
        })
      })
      this.$store.commit('problem/SET_CATEGORY_QUESTION', categoryToUpdate)
    },
  },
}
</script>
