<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <h5 class="p-1 pb-0">
        {{ $t("class-module.list-of-classes") }}
        <span class="ml-1">
          <b-badge
            pill
            variant="primary"
          >{{ classes.length }}</b-badge>
        </span>
      </h5>

      <b-table
        v-if="classes.length > 0"
        responsive
        class="class-table"
        :fields="columnsForClassesTable"
        :items="classes"
        :tbody-tr-class="activeClass"
        :filter="search"
        :empty-filtered-text="$t('messages.no-record-found')"
        show-empty
        @row-clicked="onClassRowClick"
      >
        <template #cell(owner)="data">
          <span>
            {{ data.item.created_by === self.id ? $t("you") : data.item.owner }}
          </span>
        </template>
        <template #cell(teacher_name)="data">
          <span>
            {{
              data.item.teacher_id === self.id
                ? $t("you")
                : data.item.teacher_name
            }}
          </span>
        </template>
        <template #cell(course_name)="data">
          <div v-if="data.item.course_name">
            <b-button
              size="sm"
              style="padding:6px;font-size:10px"
              class="d-inline-block"
              variant="primary"
              @click="(isAStudent || isATeacher) ? viewLessons(data.item.course_id,data.item.id):null"
            >
              {{ data.item.course_name }}
            </b-button>
          </div>
          <div v-else-if="data.item.courses">
            <div
              v-for="(course,index) in data.item.courses.filter(c => c != null)"
              :key="course.name"
            >
              <b-button
                v-if="index<2"
                :style="`padding:6px;font-size:10px;margin-bottom:2px`"
                size="sm"
                variant="primary"
                @click="(isAStudent || isATeacher) ? viewLessons(course.id,data.item.id):null"
              >
                {{ course.name }}
              </b-button>
            </div>
            <b-button
              v-if="data.item.courses && data.item.courses.length>2"
              :style="`padding:6px;font-size:10px;`"
              size="sm"
              variant="secondary"
              @click="$emit('onShowAllCoursesOf',{...{class_id:data.item.id},...data.item})"
            >
              {{ $t('class-module.view-all') }}
            </b-button>
          </div>
        </template>
        <template #cell(action)="data">
          <div class="d-flex justify-content-start">
            <span>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-delete-user
                :disabled="isProcessing"
                variant="outline-primary"
                class="btn-icon"
                @click.stop="$emit('onShowDetails', data.item)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                v-if="isATeacher"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-delete-user
                :disabled="isProcessing"
                variant="outline-primary"
                class="btn-icon"
                style="margin-left: 4px"
                @click.stop="$emit('onEditLink', data.item.id, data.index)"
              >
                <feather-icon icon="LinkIcon" />
              </b-button>
            </span>
            <span v-if="self.id === data.item.created_by">
              <span>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :disabled="isProcessing"
                  variant="outline-primary"
                  class="btn-icon"
                  style="margin-left: 4px"
                  @click.stop="$emit('onClassEdit', data.item.id, data.index)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </span>
              <span>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-delete-user
                  :disabled="isProcessing"
                  variant="outline-danger"
                  class="btn-icon"
                  style="margin-left: 4px"
                  @click.stop="$emit('onClassDelete', data.index)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </span>
            </span>
          </div>
        </template>
      </b-table>
      <p
        v-else
        class="px-1"
      >
        {{ $t('class-module.no-classes') }}
      </p>
    </b-card>
  </div>

</template>
<script>
import {
  BCard, BTable, BButton, BBadge,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import {
  USER_TYPE_TEACHER,
  USER_TYPE_STUDENT,
  USER_TYPE_PARENT,
} from '@/const/userType'
import Ripple from 'vue-ripple-directive'
import i18n from '@/libs/i18n'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BBadge,

  },
  directives: {
    Ripple,
  },
  props: {
    classes: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columnsForClassesTable: [
        { key: 'name', label: i18n.tc('class-module.class-table.name') },
        {
          key: 'course_name',
          label: i18n.tc('class-module.class-table.course'),
        },
        { key: 'owner', label: i18n.tc('class-module.class-table.owner') },
        {
          key: 'teacher_name',
          label: i18n.tc('class-module.class-table.teacher'),
        },
        {
          key: 'action',
          label: i18n.tc('class-module.class-table.actions'),
          thStyle: { minWidth: '230px' },
        },
      ],
      selectedClass: null,
      self: getUserData(),
    }
  },
  computed: {
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
    isAParent() {
      return this.self.usertype === USER_TYPE_PARENT
    },
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
  },
  methods: {
    viewLessons(id, classId) {
      this.$router.push({
        name: `${this.self.usertype}-lessons`,
        params: { id, classId },
      })
    },
    activeClass(item, type) {
      if (!this.selectedClass) return ''
      if (!item || type !== 'row') return ''
      if (item.id === this.selectedClass.id) return 'active-column'
      return ''
    },
    onClassRowClick(params) {
      this.selectedClass = params
      this.$emit('onClassSelected', params)
    },
  },
}
</script>

<style>
.class-table .active-column {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.class-table tr {
  cursor: pointer;
}
.class-table {
  margin-bottom: 0;
}
</style>
