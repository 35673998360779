<template>
  <div>
    <b-modal id="lesson-planner-list-modal"
             ok-only
             :title="$t('calendar-module.my-lesson-plans')"
             size="lg"
             :ok-title="$t('ok')"
             @ok="$emit('closed')"
             @hide="$emit('closed')"
    >
      <b-row>
        <b-col cols="12"
               class="text-right pb-2"
        >
          <b-button variant="outline-primary"
                    @click="()=>{
                      updating = false;
                      $bvModal.show('myLessonPlannerModal')
                    }"
          >
            <feather-icon icon="AddIcon" />
            {{ $t('calendar-module.add-new') }}
          </b-button>
        </b-col>
      </b-row>
      <table v-if="!processing"
             class="table "
      >
        <thead>
          <th>
            {{ $t('actions.name') }}
          </th>
          <th>{{ $t('actions.action') }}</th>
        </thead>
        <tbody>
          <tr v-for="lessonPlanner in lessonPlanners"
              :key="lessonPlanner.id"
          >
            <td>
              {{ lessonPlanner.name }}
            </td>
            <td>
              <div class="d-flex align-items-center"
                   style="gap:10px;"
              >
                <feather-icon class="text-primary cursor-pointer"
                              icon="EditIcon"
                              @click="editLessonPlan(lessonPlanner.lesson_id)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <b-row v-else>
        <b-col class="d-flex justify-content-center align-items-center"
               cols="12"
        >
          <b-spinner />
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="myLessonPlannerModal"
             size="xl"
             hide-footer
    >

      <lesson-planner
        :courses="courses"
        :lesson-planner-id="selectedLessonId"
        :updating="updating"
        @lessonPlannerUpdated="()=>{$bvModal.hide('myLessonPlannerModal'); getLessonPlanners()}"
        @lessonPlannerCreated="()=>{$bvModal.hide('myLessonPlannerModal'); getLessonPlanners()}"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, BSpinner, BRow, BCol, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import LessonPlanner from './CreateUpdate.vue'

export default {
  components: {
    LessonPlanner,
    BModal,
    BSpinner,
    BRow,
    BCol,
    BButton,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    courses: {
      type: [Array, Object],
      required: true,
    },
  },

  data() {
    return {
      lessonPlanners: [],
      processing: false,
      onlineSwitch: false,
      selectedLessonId: null,
      updating: true,
    }
  },
  watch: {
    show() {
      if (this.show) {
        this.getLessonPlanners()
        this.$bvModal.show('lesson-planner-list-modal')
      }
    },
  },
  methods: {
    getLessonPlanners() {
      this.processing = true
      useJwt.getLessonPlanner({ params: { type: 'my-lesson-plan' } }).then(res => {
        const { data } = res.data
        this.lessonPlanners = data
      }).finally(() => {
        this.processing = false
      })
    },

    editLessonPlan(id) {
      this.selectedLessonId = id
      this.updating = true
      this.$bvModal.show('myLessonPlannerModal')
    },
  },
}

</script>
