<template>
  <dialog-drag
    v-show="show"
    class="imageLibrary-dialog"
    :draggable="!dialogLocked"
    :options="dialogPos"
    :style="`min-width:800px; z-index:20; position:fixed; height:700px; max-height:100%;`"
    @move="onMove"
  >
    <div class="header justify-content-between d-flex bg-success p-1">
      <div class="title">
        <h5 class="text-white">
          {{ imagelibraryInfo ? (readonly ? $t('image-library-module.show-image-library') : $t('image-library-module.update-image-library')) : $t('image-library-module.add-image-library') }}
        </h5>
      </div>
      <div class="icons">
        <feather-icon size="22"
                      class="text-white cursor-pointer"
                      :icon="`${!dialogLocked ? 'UnlockIcon' : 'LockIcon'}`"
                      @click="lockOrUnLock"
        />
        <feather-icon size="22"
                      class="text-white cursor-pointer ml-2"
                      icon="XIcon"
                      @click="$emit('close')"
        />
      </div>
    </div>
    <div class="body">
      <validation-observer ref="createItemForm">
        <b-form @submit.prevent>
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('image-library-module.image-library-image')"
                label-for="tem-name"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="image|size:800"
                  :custom-messages="{ image: $t('messages.invalid-image') }"
                  vid="image"
                  :rules="(!imagelibraryInfo && !imagelibrary.image) ? 'required' : ''"
                >
                  <b-media no-body>
                    <b-media-aside>
                      <avatar
                        size="50px"
                        :rounded="true"
                        :image="logoUrl || imagelibrary.image"
                      />
                    <!--/ avatar -->
                    </b-media-aside>
                    <b-media-body class="ml-50">
                      <!-- Plain mode -->
                      <div>
                        <b-form-file
                          ref="refInputEl1"
                          v-model="brandImage"
                          :browse-text="$t('browseButton')"
                          :placeholder="$t('issue-report-module.choose-a-file')"
                          class="mb-1"
                          accept="image/*"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>

                      </div>
                      <div v-if="!brandImage && errors.length==0">
                        <b-card-text> {{ $t('account-setting-general.allowed') }} </b-card-text>
                      </div>
                      <div v-if="imagelibrary.vocab_word"
                           class="mt-1"
                      >
                        <b-button size="sm"
                                  variant="outline-primary"
                                  @click="showGenerateImage = true"
                        >
                          {{ $t('image-library-module.regenerate-image') }}
                        </b-button>
                      </div>
                    </b-media-body>
                  </b-media>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('image-library-module.audio')"
                label-for="tem-name"
              >

                <Recorder :url="libraryAudio || imagelibrary.audio"
                          :polly-text="imagelibrary.vocab_word"
                          @input="setQuestionAudio($event)"
                />

              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group>
            <template #label>
              <div class="d-flex justify-content-between">
                <div>{{ $t('image-library-module.vocab-name') }}</div>
              </div>
            </template>
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Vocab  Name"
            >
              <b-form-input
                v-model="imagelibrary.vocab_word"
                :placeholder="$t('image-library-module.vocab-name')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('image-library-module.synonyms-words')"
          >

            <tag-with-description :items="imagelibrary.synonyms" />

          </b-form-group>
          <b-form-group
            :label="$t('image-library-module.antonym')"
          >

            <tag-with-description :items="imagelibrary.antonym" />

          </b-form-group>
          <b-form-group
            :label="$t('image-library-module.description')"
          >

            <DescriptionWithAudio v-model="imagelibrary.description_json" />
          </b-form-group>
          <b-form-group
            :label="$t('image-library-module.sentence')"
          >

            <DescriptionWithAudio v-model="imagelibrary.sentence_json" />

          </b-form-group>
          <b-form-group
            :label="$t('image-library-module.distractions')"
          >

            <tag-with-description :items="imagelibrary.distractions" />

          </b-form-group>
        </b-form>
      </validation-observer>
    </div>
    <div
      class="footer"
    >
      <b-button @click="$emit('close')">
        {{ $t('labels.cancel') }}
      </b-button>
      <span>
        <ReGenerate v-if="imagelibraryInfo"
                    v-slot="{ handleRegenerate }"
                    class="mr-2"
                    :image-library-id="imagelibraryInfo.id"
                    @reFetch="$emit('reFetch')"
        >
          <b-button
            variant="primary"
            @click="handleRegenerate"
          >
            Regenerate
          </b-button>
        </ReGenerate>
        <b-button
          v-if="imagelibraryInfo"
          variant="primary"
          @click="updateImageLibrary"
        >
          <b-spinner
            v-if="isSaving"
            small
          /> {{ $t('labels.update') }}
        </b-button>
        <b-button
          v-else
          variant="primary"
          @click="saveImageLibrary"
        >
          <b-spinner
            v-if="isSaving"
            small
          /> {{ $t('actions.save') }}
        </b-button>
      </span>
    </div>
    <WordImageGenerator v-if="showGenerateImage"
                        :is-processing="isSaving"
                        :word="imagelibrary.sentence_json?.text || imagelibrary.vocab_word"
                        @saveImage="handleSaveImage"
                        @close="showGenerateImage = false"
    />
  </dialog-drag>
</template>
<script>
import {
  BForm, BFormGroup, BFormInput, BMedia, BMediaBody, BFormFile, BCardText, BMediaAside, BRow, BCol, BSpinner, BButton,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import Avatar from '@/views/common/components/Avatar.vue'
import { required, email } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
import Recorder from '@/views/super/problem/components/recorder.vue'
import DialogDrag from 'vue-dialog-drag'
import WordImageGenerator from '@/views/super/ai-ide/stable-diffusion/WordImageGenerator.vue'
import TagWithDescription from './TagWithDescription.vue'
import DescriptionWithAudio from './DescriptionWithAudio.vue'
import ReGenerate from './regenerate.vue'

const uuid = require('uuid')

const initImageLibraryInfo = () => ({
  vocab_word: '',
  audio: '',
  synonyms: [],
  distractions: [],
  antonym: [],
  description_json: {},
  sentence_json: {},
})

export default {
  components: {
    BForm,
    BFormGroup,
    DialogDrag,
    BFormInput,
    BMedia,
    BMediaBody,
    BFormFile,
    BCardText,
    BMediaAside,
    BSpinner,
    BRow,
    BCol,
    Recorder,
    BButton,
    TagWithDescription,
    Avatar,
    ReGenerate,
    ValidationProvider,
    ValidationObserver,
    DescriptionWithAudio,
    WordImageGenerator,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    imagelibraryInfo: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      brandImage: null,
      navIcon: null,
      required,
      logoUrl: null,
      imagelibrary: {
        ...initImageLibraryInfo(),
      },
      email,
      isSaving: false,
      libraryAudio: null,
      dialogLocked: false,
      showGenerateImage: false,
      dialogPos: { top: 20, left: 500 },
    }
  },
  watch: {
    brandImage() {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.showPreview = true
          this.logoUrl = reader.result
        },
        false,
      )
      if (this.brandImage) {
        if (/\.(jpe?g|png|gif)$/i.test(this.brandImage.name)) {
          reader.readAsDataURL(this.brandImage)
        }
      }
    },
    imagelibraryInfo(newValue) {
      if (newValue) {
        this.imagelibrary = {
          ...newValue,
          synonyms: newValue.synonyms || [],
          distractions: newValue.distractions || [],
          sentence_json: newValue.sentence_json || {},
          description_json: newValue.description_json || {},
        }
        this.logoUrl = newValue.logo
        this.libraryAudio = null
      } else {
        this.imagelibrary = {
          ...initImageLibraryInfo(),
        }
      }
    },
  },
  methods: {
    handleSaveImage(imagePath) {
      this.imagelibrary.image = imagePath
      this.isSaving = true
      useJwt.updateImageLibraryWithGenerateImage({
        id: this.imagelibrary.id,
        img_path: imagePath,
      }).then(response => {
        this.imagelibrary.image = response.data.data.img_path
        this.showGenerateImage = false
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isSaving = false
      })
    },
    async fileToBase64(file) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function () {
          const base64String = reader.result
          resolve(base64String)
        }
      })
    },
    lockOrUnLock() {
      this.dialogLocked = !this.dialogLocked
    },
    onMove(e) {
      this.dialogPos = { top: e.top, left: e.left }
    },
    setQuestionAudio({ audio }) {
      let convertedAudio = ''
      this.fileToBase64(audio).then(result => {
        convertedAudio = result
        this.libraryAudio = convertedAudio
      })
    },
    createFormData() {
      const newForm = {
        ...this.imagelibrary,
      }
      if (this.logoUrl) {
        newForm.image = this.logoUrl
      }
      if (this.libraryAudio) {
        newForm.audio = this.libraryAudio
      }
      return newForm
    },
    showErrors(error) {
      const errors = error.response?.data?.errors
      if (errors) {
        Object.entries(errors).forEach(message => {
          this.showError(message.join(','))
        })
      } else {
        this.showErrorMessage(error)
      }
    },
    updateImageLibrary() {
      this.$refs.createItemForm.validate().then(success => {
        if (success) {
          this.isSaving = true
          useJwt.updateImageLibrary(this.imagelibraryInfo.id, this.createFormData()).then(response => {
            this.showSuccessMessage(response)
            this.$emit('reFetch')
            this.$emit('close')
          }).catch(error => {
            this.showErrors(error)
          }).finally(() => {
            this.isSaving = false
          })
        }
      })
    },
    saveImageLibrary() {
      this.$refs.createItemForm.validate().then(success => {
        if (success) {
          this.isSaving = true
          useJwt.storeImageLibrary(this.createFormData()).then(response => {
            this.showSuccessMessage(response)
            this.$emit('reFetch')
            this.$emit('close')
          }).catch(error => {
            this.showErrors(error)
          }).finally(() => {
            this.isSaving = false
          })
        }
      })
    },
    reGenerateToken() {
      this.imagelibrary = {
        ...this.imagelibrary,
        client_token: uuid.v4(),
      }
    },
  },
}
</script>
<style src='vue-dialog-drag/dist/vue-dialog-drag.css'></style>
<style lang="scss">

// hide scrollbar
.imageLibrary-dialog{
  border:none !important;
  box-shadow: none !important;
  background-color: #f6f6f6 !important;
  .dialog-header{
    display:none !important;
  }
  .dialog-body{
    padding:0;
  }
  .header{
    margin:0;
  }
  .body{
    overflow-y:scroll;
    max-height:590px;
  }
  form{
    padding:20px;
  }
  .footer{
    display: flex;
    justify-content: flex-end;
    gap:20px;
    padding-right:20px;
    padding-top:10px;
    border-top: 1px solid #ffffff;
  }
}
</style>
