<template>
  <div class="lesson-detail-modal">
    <component :is="viewType === 'modal' ? 'b-modal' : 'div'"
               id="lesson-detail"
               ref="lesson-detail"
               :visible="show"
               modal-class="modal-success"
               centered
               hide-footer
               :title="detail&&`${$t('student-lab-module.lesson-model.detail-of')} ${detail.lesson_name}`"
               @close="$emit('close')"
               @hide="$emit('close')"
    >
      <b-overlay :show="isProcessing">
        <b-card-text v-if="detail">
          <table class="table table-responsive">
            <thead>
              <tr>
                <th scope="col">
                  #
                </th>
                <th scope="col">
                  {{ $t('student-lab-module.lesson-model.id') }}
                </th>
                <th scope="col">
                  {{ $t('student-lab-module.lesson-model.name') }}
                </th>
                <th scope="col">
                  {{ $t('student-lab-module.lesson-model.is-public') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  {{ $t('student-lab-module.lesson-model.grade') }}
                </th>
                <td class="text-nowrap">{{ detail.grade_id }}
                  <b-button v-b-tooltip.hover="'Copy Id'"
                            variant="flat-success"
                            class="btn-icon copy-button p-0"
                            @click="copyTextToClipboard(detail.grade_id)"
                  >
                    <feather-icon icon="CopyIcon" />
                  </b-button>
                </td>
                <td>{{ detail.grade_name }}</td>
                <td> - </td>
              </tr>
              <tr>
                <th scope="row">
                  {{ $t('student-lab-module.lesson-model.subject') }}
                </th>
                <td class="text-nowrap">{{ detail.subject_id }}
                  <b-button v-b-tooltip.hover="'Copy Id'"
                            variant="flat-success"
                            class="btn-icon copy-button p-0"
                            @click="copyTextToClipboard(detail.subject_id)"
                  >
                    <feather-icon icon="CopyIcon" />
                  </b-button>
                </td>
                <td> {{ detail.subject_name }}</td>
                <td> - </td>
              </tr>
              <tr>
                <th scope="row">
                  {{ $t('student-lab-module.lesson-model.course') }}
                </th>
                <td class="text-nowrap">
                  {{ detail.course_id }}
                  <b-button v-b-tooltip.hover="'Copy Id'"
                            variant="flat-success"
                            class="btn-icon copy-button p-0"
                            @click="copyTextToClipboard(detail.course_id)"
                  >
                    <feather-icon icon="CopyIcon" />
                  </b-button>
                </td>
                <td>{{ detail.db_course_name }}</td>
                <td>
                  <feather-icon
                    v-if="detail.course_visibility==1"
                    icon="CheckIcon"
                  />
                  <feather-icon
                    v-else
                    icon="XIcon"
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">
                  {{ $t('student-lab-module.lesson-model.group') }}
                </th>
                <td class="text-nowrap">
                  {{ detail.lesson_group_id }}
                  <b-button v-b-tooltip.hover="'Copy Id'"
                            variant="flat-success"
                            class="btn-icon copy-button p-0"
                            @click="copyTextToClipboard(detail.lesson_group_id)"
                  >
                    <feather-icon icon="CopyIcon" />
                  </b-button>
                </td>
                <td> {{ detail.lesson_group_name }}</td>
                <td><feather-icon
                      v-if="detail.lesson_group_visibility==1"
                      icon="CheckIcon"
                    />
                  <feather-icon
                    v-else
                    icon="XIcon"
                  /></td>
              </tr>
              <tr>
                <th scope="row">
                  {{ $t('student-lab-module.lesson-model.lesson') }}
                </th>
                <td class="text-nowrap">
                  {{ detail.lesson_id }}
                  <b-button v-b-tooltip.hover="'Copy Id'"
                            variant="flat-success"
                            class="btn-icon copy-button p-0"
                            @click="copyTextToClipboard(detail.lesson_id)"
                  >
                    <feather-icon icon="CopyIcon" />
                  </b-button>
                </td>
                <td>{{ detail.lesson_name }}</td>
                <td><feather-icon
                      v-if="detail.lesson_visibility==1"
                      icon="CheckIcon"
                    />
                  <feather-icon
                    v-else
                    icon="XIcon"
                  /></td>
              </tr>
            </tbody>
          </table>
          <div class="mt-2"
               style="max-height:300px; overflow-y:scroll"
          >
            <table class="table table-responsive">
              <thead>
                <tr>
                  <th>{{ $t('student-lab-module.lesson-model.id') }}</th>
                  <th>{{ $t('student-lab-module.lesson-model.domain-name') }}</th>
                  <th>{{ $t('student-lab-module.lesson-model.standards') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(domain , key) in detail.domain"
                    :key="key"
                >
                  <td>{{ domain.id }}</td>
                  <td>{{ domain.name }}</td>
                  <td>
                    <b-button
                      variant="outline-success"
                      class="btn-icon mr-1"
                      @click="viewDetail(domain)"
                    >{{ $t('student-lab-module.lesson-model.view') }}</b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </b-card-text>
        <slot />
      </b-overlay>
    </component>
    <b-modal
      id="domain-detail"
      ref="domain-detail"
      :visible="showDomainModal"
      modal-class="modal-success"
      centered
      hide-footer
      :title="domainModalTitle"
      @close="showDomainModal = false"
      @hide="showDomainModal = false"
    >
      <table class="table table-responsive mt-2">
        <thead>
          <tr>
            <th>{{ $t('student-lab-module.lesson-model.id') }}</th>
            <th>{{ $t('student-lab-module.lesson-model.standard-name') }}</th>
            <th>{{ $t('student-lab-module.lesson-model.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(domain , key) in domainArray"
              :key="key"
          >
            <td>{{ domain.id }}</td>
            <td>{{ domain.name }}</td>
            <td>
              <b-button v-b-tooltip.hover="'Copy Id'"
                        variant="flat-success"
                        class="btn-icon copy-button text-nowrap"
                        @click="copyTextToClipboard(domain.id)"
              >
                <feather-icon icon="CopyIcon" /> Id
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BCardText, BButton, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'

export default ({
  directives: {
    Ripple,
  },
  components: {
    BModal, BCardText, BButton, BOverlay,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    lessonId: {
      type: Number,
      default: 0,
    },
    viewType: {
      type: String,
      default: 'modal',
    },
  },
  data() {
    return {
      isVisible: this.show,
      detail: null,
      domainArray: [],
      showDomainModal: false,
      domainModalTitle: '',
      isProcessing: false,
    }
  },
  watch: {
    lessonId: {
      handler(newValue) {
        if (newValue) {
          this.isProcessing = true
          useJwt.getLessonDetailInfo(newValue).then(res => {
            this.detail = res.data
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => {
            this.isProcessing = false
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    viewDetail(domain) {
      useJwt.getStandardsByDomain(domain.id)
        .then(response => {
          this.domainArray = response.data.data
          this.showDomainModal = true
          this.domainModalTitle = domain.name
        }).catch(error => {
          this.showError(error)
        })
    },
  },
})
</script>
