<template>
  <b-modal
    size="lg"
    :visible="true"
    title="Fill the Form"
    centered
    hide-footer
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-form @submit.prevent="submitForm">
      <div class="form-container p-4 rounded shadow-sm">
        <!-- Type -->
        <b-row>
          <b-col>
            <b-form-group label="Type"
                          label-for="type"
                          class="mb-4"
            >
              <b-form-select
                id="type"
                v-model="form.type"
                :options="types"
                required
                size="lg"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Formulas Section (Min/Max Values and Formula) -->
        <b-form-group label="Formulas"
                      label-for="formulas"
                      class="mb-4"
        >
          <div
            v-for="(formula, index) in form.formulas"
            :key="index"
            class="mb-4"
          >
            <b-form-row>
              <b-col>
                <b-form-group label="Min CEFR"
                              class="mb-2"
                >
                  <b-form-input
                    v-model="formula.minValue"
                    type="number"
                    step="any"
                    placeholder="Min CEFR"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Max CEFR"
                              class="mb-2"
                >
                  <b-form-input
                    v-model="formula.maxValue"
                    type="number"
                    placeholder="Max CEFR"
                    step="any"
                    :min="formula.minValue || 0"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Scale"
                              class="mb-2"
                >
                  <b-form-input
                    v-model="formula.scale"
                    placeholder="Scale"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-row>
              <b-col md="6">
                <b-form-group label="Formula"
                              class="mb-2"
                >
                  <b-form-input
                    v-model="formula.formula"
                    type="text"
                    placeholder="Enter formula"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="formula.formula"
                     md="6"
              >
                <b-form-group label="Evaluation (Optional)"
                              class="mb-2"
                >
                  <equivalent-check
                    :formula="formula.formula"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              v-if="form.formulas.length > 1"
              variant="outline-danger"
              class="mt-2"
              size="sm"
              @click="removeFormula(index)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>

          <b-button variant="outline-primary"
                    size="sm"
                    @click="addFormula"
          >
            <feather-icon icon="PlusIcon" /> Add Formula
          </b-button>
        </b-form-group>

        <!-- Submit Button -->
        <div class="text-center mt-4">
          <b-button
            v-if="form.id"
            type="submit"
            variant="primary"
            size="lg"
            class="px-5"
            :disabled="isProcessing"
          >
            <b-spinner v-if="isProcessing"
                       small
            /> Update
          </b-button>
          <b-button
            v-else
            type="submit"
            variant="success"
            size="lg"
            class="px-5"
            :disabled="isProcessing"
          >
            <b-spinner v-if="isProcessing"
                       small
            /> Submit
          </b-button>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BFormRow,
  BCol,
  BSpinner,
  BRow,
} from 'bootstrap-vue'
import { measureTypes } from '@/const/langLevel'
import EquivalentCheck from './EquivalentCheck.vue'

const root = getCurrentInstance().proxy.$root

const props = defineProps({
  editItem: {
    type: Object,
    default: () => {},
  },
})
const isProcessing = ref(false)
const editItem = JSON.parse(JSON.stringify(props.editItem))

const form = ref({
  lang_level: '',
  type: '',
  formulas: [
    {
      minValue: null,
      maxValue: null,
      formula: '',
    },
  ],
  ...editItem,
})

const types = measureTypes

// Add a new formula input (minValue, maxValue, formula)
const addFormula = () => {
  form.value.formulas.push({
    minValue: null,
    maxValue: null,
    formula: '',
  })
}

// Remove a formula input
const removeFormula = formulaIndex => {
  form.value.formulas.splice(formulaIndex, 1)
}

const emits = defineEmits(['close', 'feFetch'])

const updateForm = () => {
  const formattedData = {
    ...form.value,
    formulas: form.value.formulas.map(f => ({
      minValue: f.minValue,
      maxValue: f.maxValue,
      scale: f.scale,
      formula: f.formula.trim(), // Ensure formula is properly trimmed
    })),
  }
  isProcessing.value = true
  useJwt
    .updateCefrFormula(form.value.id, formattedData)
    .then(response => {
      root.showSuccessMessage(response)
      emits('close')
      emits('feFetch')
    })
    .catch(error => {
      root.showErrorMessage(error)
    })
    .finally(() => {
      isProcessing.value = false
    })
}

// Submit the form
const submitForm = () => {
  if (form.value.id) {
    updateForm()
    return
  }
  const formattedData = {
    ...form.value,
    formulas: form.value.formulas.map(f => ({
      minValue: f.minValue,
      maxValue: f.maxValue,
      formula: f.formula.trim(),
    })),
  }
  isProcessing.value = true
  useJwt
    .storeCefrFormula(formattedData)
    .then(response => {
      root.showSuccessMessage(response)
      emits('close')
      emits('feFetch')
    })
    .catch(error => {
      root.showErrorMessage(error)
    })
    .finally(() => {
      isProcessing.value = false
    })
}
</script>

<style scoped>
/* General container styling */
.form-container {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Title styling */
.form-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff;
}

/* Button customizations */
.b-button {
  font-weight: 600;
}
</style>
