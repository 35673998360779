<template>
  <div>
    <div v-if="self.usertype === 'teacher' && !specificStudent"
         class="mx-1"
    >
      <b-form-group
        v-if="!selectedClassrooms"
        :label="$t('class-room')"
      >
        <v-select
          v-model="selectedClassrooms"
          :placeholder="$t('all-classrooms')"
          label="name"
          multiple
          :clearable="true"
          :options="classRooms"
          :reduce="option => option.id"
        />

      </b-form-group>
      <b-form-group
        :label="$t('students')"
      >
        <v-select
          v-model="studentId"
          :placeholder="$t('class-test.all-students')"
          label="fullName"
          :options="students"
          :reduce="option => option.id"
        >
          <template #option="{ fullName, id }">
            <span :set="hasNote = notes.find(n => n.student_id == id)">
              {{ fullName }} <feather-icon v-if="hasNote"
                                           class="text-warning"
                                           icon="ClipboardIcon"
              />
            </span>
          </template>
        </v-select>
      </b-form-group>
    </div>
    <b-tabs :key="teacherNoteForMe.length"
            v-model="activeTab"
    >
      <b-tab v-if="teacherNoteForMe.length"
             :title="$t('for-me')"
      >
        <b-card
          v-for="note of teacherNoteForMe"
          :key="note.id"
          class="card-apply-job mb-1"
        >
          <b-card-header class="p-0">
            <b-media no-body>
              <b-media-aside
                class="mr-1"
              >
                <b-avatar
                  :text="note.teacher ? note.teacher.firstname.charAt(0) : ''"
                  variant="primary"
                />
              </b-media-aside>

              <b-media-body>
                <h5
                  v-if="note.teacher"
                  class="mb-0"
                >
                  {{ note.teacher.firstname }} {{ note.teacher.lastname }}
                </h5>
                <small class="text-muted">
                  {{ FROM_NOW(note.created_at) }}
                </small>
              </b-media-body>
            </b-media>
          </b-card-header>

          <b-card-text
            class="mt-1"
            v-html="note.content"
          />

        </b-card>
      </b-tab>
      <b-tab :title="!onlyNoteView ? $t('for-all') : $t('student-notes-module.notes')">
        <template v-for="note of filteredNotes">
          <b-card
            v-if="note.content && noEmptyNote(note.content)"
            :key="note.id"
            class="card-apply-job mb-1"
          >
            <b-card-header class="p-0">
              <b-media no-body>
                <b-media-aside
                  v-if="!specificStudent"
                  class="mr-1"
                >
                  <b-avatar
                    :text="note.student ? note.student.firstname.charAt(0) : ''"
                    variant="primary"
                  />
                </b-media-aside>

                <b-media-body>
                  <h5
                    v-if="note.student && !specificStudent"
                    class="mb-0"
                  >
                    {{ note.student.firstname }} {{ note.student.lastname }}
                  </h5>
                  <h5 v-else-if="specificStudent">
                    {{ getNoteTitle(note) }}
                  </h5>
                  <small class="text-muted">
                    {{ FROM_NOW(note.created_at) }}
                  </small>
                </b-media-body>
              </b-media>
            </b-card-header>

            <b-card-text
              class="mt-1"
              v-html="note.content"
            />
            <hr>
            <div class="comment">
              <b-media
                v-for="comment of note.comments"
                :key="comment.id"
                no-body
                class="pb-1"
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    :text="comment.user ? comment.user.firstname.charAt(0) : ''"
                    size="20"
                  />
                </b-media-aside>
                <b-media-body class="d-flex justify-content-between">
                  <div>
                    <h5
                      v-if="comment.user"
                      class="mb-0"
                    >
                      {{ comment.user.firstname }} {{ comment.user.lastname }}
                    </h5>
                    <p>
                      {{ comment.comment }}
                    </p>
                  </div>
                  <div v-if="self.id == comment.user.id">
                    <feather-icon
                      icon="TrashIcon"
                      role="button"
                      class="text-danger"
                      @click="deleteComment(note, comment)"
                    />
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <b-form-input
              :placeholder="$t('enter-comment')"
              @keyup.enter="(text) => commentNote(text, note)"
            />
          </b-card>
        </template>
        <b-card
          v-if="filteredNotes.length === 0"
        >
          <h3>
            {{ $t('no-notes') }}
          </h3>
        </b-card>
      </b-tab>
    </b-tabs>
    <follow-up v-if="showFollow"
               :lesson-id="lessonId"
               :class-id="classId"
               :student-id="studentId"
               :student-info="students.find(s => s.id == studentId)"
               @close="showFollow = false"
    />
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BCardText,
  BFormInput,
  BFormGroup,
  BTab,
  BTabs,
  // BButton,
  // BFormRating,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'
import FollowUp from '../components/FollowUp.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BFormInput,
    vSelect,
    BFormGroup,
    FollowUp,
    BTab,
    BTabs,
    // BButton,
  },
  props: {
    notes: {
      type: Array,
      default: () => [],
    },
    lessonId: {
      type: Number,
      default: 0,
    },
    specificStudent: {
      type: Boolean,
      default: false,
    },
    classRooms: {
      type: Array,
      default: () => [],
    },
    parentSelectedClassrooms: {
      type: Array,
      default: () => [],
    },
    allStudents: {
      type: Array,
      default: () => [],
    },
    teacherNoteForMe: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      self: getUserData(),
      studentId: null,
      selectedStudents: [],
      isProcessing: false,
      tempSelectedProblem: [],
      showFollow: false,
      classId: this.$route.params.classId,
      activeTab: 0,
    }
  },
  computed: {
    onlyNoteView() {
      return this.teacherNoteForMe.length === 0
    },
    selectedClassrooms: {
      get() {
        return this.parentSelectedClassrooms
      },
      set(value) {
        this.$emit('getLessonNotesByPage', value)
      },
    },
    students() {
      return this.allStudents.map(item => ({
        id: item.id,
        fullName: `${item?.firstname} ${item?.lastname}`,
      }))
      // this.notes.forEach(note => {
      //   if (!studentLists.find(s => s.id === note.student_id)) {
      //     studentLists.push({
      //       id: note.student_id,
      //       fullName: `${note.student?.firstname} ${note.student?.lastname}`,
      //     })
      //   }
      // })
    },
    filteredNotes() {
      if (!this.studentId) {
        return this.notes
      }
      return this.notes.filter(note => +this.studentId === +note.student_id)
    },
  },
  methods: {
    commentNote(comment, note) {
      useJwt.postNotesComment({
        note_id: note.id,
        comment: comment.target.value,
      }).then(response => {
        this.showSuccessMessage(response)
        note.comments.push(response.data.data)
        // eslint-disable-next-line no-param-reassign
        comment.target.value = ''
      })
        .catch(error => {
          this.showErrorMessage(error)
        })
    },
    deleteComment(note, comment) {
      useJwt.deleteNoteComment(comment.id).then(() => {
        this.showSuccess('Notes Deleted')
        // eslint-disable-next-line no-param-reassign
        note.comments = note.comments.filter(com => com.id !== comment.id)
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    getNoteTitle(note) {
      return note?.lesson?.name || note.title
    },
    noEmptyNote(content) {
      return content !== '&nbsp;'
    },
  },
}
</script>
<style scoped>
  /* Custom CSS to hide the tab title */
  /* ::v-deep .nav-tabs .nav-item:nth-child(1) {
    display: none;
  } */
</style>
