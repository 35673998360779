<template>
  <b-overlay :show="isProcessing">
    <div
      v-if="AUTH_USER().usertype === 'super'"
      class="text-right"
    >
      <b-button
        variant="outline-primary"
        @click="addLibrary = true"
      >
        <feather-icon icon="PlusIcon" /> {{ $t('library-moduel.add') }}
      </b-button>
    </div>
    <b-row class="ecommerce-application match-height pt-2">
      <b-col
        v-if="AUTH_USER().usertype === 'national'"
        :md="6"
      >
        <request-library-card />
      </b-col>
      <b-col
        v-for="item,index of libraries"
        :key="index"
        :md="6"
      >
        <card-design
          :item="item"
          :user="AUTH_USER()"
          :is-super-admin="isSuperAdmin"
          :not-responsive-window="notResponsiveWindow"
          @onEdit="handleEdit"
          @onAddSchoolUser="libraryAddingSchool = item"
          @onManagePermission="managePermission"
        />
      </b-col>
    </b-row>
    <create-edit-library
      :show="addLibrary || !!libraryToEdit "
      :library-info="libraryToEdit"
      @reFetch="getLibrary"
      @close="addLibrary = false; libraryToEdit = null"
    />
    <!-- lesson permissions -->
    <permission-modal
      :id="permissionModalData ? permissionModalData.id:''"
      :module="permissionModalData ? permissionModalData.module: ''"
      :method="permissionModalData ? permissionModalData.method:''"
      :show="permissionModalData!==null"
      :user-type="''"
      :action-type="permissionModalData ? permissionModalData.action: 'block'"
      :modal-varient="permissionModalData ? permissionModalData.varient: ''"
      @close="permissionModalData=null"
    />
    <AddSchoolUsers v-if="!!libraryAddingSchool"
                    :library-id="libraryAddingSchool.id"
                    :added-schools="libraryAddingSchool.owners.filter(i => i.user_type_id != 3).map(i => i.id)"
                    @close="libraryAddingSchool = null"
    />
  </b-overlay>
</template>
<script>
import {
  BButton, BOverlay, BRow, BCol,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import PermissionModal from '@views/super/permissions/components/PermissionModal.vue'
import CreateEditLibrary from './components/CreateEdit.vue'
import CardDesign from './components/CardDesign.vue'
import RequestLibraryCard from './components/RequestLibraryCard.vue'
import AddSchoolUsers from './components/AddSchoolUsers.vue'

export default {
  components: {
    BButton,
    BOverlay,
    BRow,
    BCol,
    CardDesign,
    CreateEditLibrary,
    PermissionModal,
    RequestLibraryCard,
    AddSchoolUsers,
  },
  data() {
    return {
      addLibrary: false,
      libraries: [],
      isProcessing: false,
      libraryToEdit: null,
      permissionModalData: null,
      libraryAddingSchool: false,
    }
  },
  computed: {
    notResponsiveWindow() {
      return window.innerWidth >= 1280 && window.innerWidth < 1600
    },
    isSuperAdmin() {
      return this.AUTH_USER().usertype === 'super'
    },
  },
  created() {
    this.getLibrary()
  },
  methods: {
    handleEdit(item) {
      this.libraryToEdit = item
    },
    managePermission(item) {
      this.permissionModalData = {
        ...item, action: 'block', method: 'show', module: 'library',
      }
    },
    getLibrary() {
      this.isProcessing = true
      useJwt.getLibrary().then(response => {
        this.libraries = response.data.data
      }).catch(error => {
        this.showError(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
