<template>
  <div class="w-100 h-100">
    <section
      id="chatbot-body"
      ref="refChatLogPS"
      class="ps-container position-relative scroll-area ps chatbot"
      style="background-repeat:no-repeat; background-size: 100%;"
      :style="{ 'background-image': `url(${require(`@/assets/images/chatbot/${activeImage}`)})`}"
    >
      <div class="position-absolute setting-icon"
           draggable="false"
           style="right:10px; top:10px; display:none;"
      >
        <feather-icon
          icon="SettingsIcon"
          size="21"
          class="text-primary cursor-pointer"
          @click.stop="openChatbotSetting"
        />
      </div>
      <div class="ps__rail-x"
           style="left: 0px; bottom: 0px"
      >
        <div class="ps__thumb-x"
             tabindex="0"
             style="left: 0px; width: 0px"
        />
      </div>
      <div class="ps__rail-y"
           style="top: 0px; right: 0px"
      >
        <div class="ps__thumb-y"
             tabindex="0"
             style="top: 0px; height: 0px"
        />
      </div>
    </section>
    <chat-footer
      :is-processing="isProcessing"
      :is-playing="isPlaying"
      :tts-not-supported="!isTtsSupport"
      :default-input-type="isTtsSupport ? 'audio' : 'text'"
      @stopAudio="stop"
      @sendMessage="sendMessage"
    />
    <b-modal
      id="bot-setting-modal"
      title="Chatbot Settings"
      hide-footer
    >
      <bot-setting :disable-input-option="true"
                   :show-lang-selector="true"
      />
    </b-modal>
  </div>
</template>
<script>
import ChatFooter from '../../demobot/components/ChatFooter.vue'
import BotSetting from '../../demobot/components/BotSetting.vue'
import chatbodyMixins from '../../demobot/helpers/chatbody-mixins'
import { streamAudioFromGecMl } from '../../demobot/helpers/streamAudio'

export default {
  components: {
    ChatFooter,
    BotSetting,
  },
  mixins: [chatbodyMixins],
  props: {
    selectedMainPoint: {
      type: Array,
      default: () => [],
    },
    voiceType: {
      type: String,
      default: '',
    },
  },
  computed: {
    isTtsSupport() {
      if (!window.SpeechRecognition) {
        if (!window.webkitSpeechRecognition || !!navigator.brave) {
          // browser doesn't support SpeechRecognition API
          return false
        }
      }
      return true
    },
  },
  methods: {
    getAiVoice() {
      const character = this.$store.getters['appConfig/currentChatbotCharacter']
      switch (character) {
        case 'male':
          return 'onyx'
        case 'female':
          return 'alloy'
        case 'jp_male':
          return 'onyx'
        case 'jp_female':
          return 'nova'
        default:
          if (character && character.includes('female')) {
            return 'alloy'
          }
          return 'onyx'
      }
    },
    textToSpeech(gender) {
      const url = `/audio/${gender === 'male' ? 'no-main-point-male.mp3' : 'no-main-point-female.mp3'}`
      const audio = new Audio(url)
      audio.play()
      this.isPlaying = true
      this.base64AudioStopper = () => audio.pause()
      audio.addEventListener('ended', () => {
        this.isPlaying = false
      })
    },
    async sendMessage(msg) {
      const mainPoint = this.selectedMainPoint
      if (!mainPoint?.length) {
        this.textToSpeech(this.$store.getters['appConfig/currentChatbotCharacter']?.includes('female') ? 'female' : 'male')
        return
      }
      this.isProcessing = true
      const language = this.$store.getters['appConfig/currentChatbotLanguage']
      const voice = this.getAiVoice()
      const params = {
        text: msg,
        mainPoint,
        language,
        voice,
      }
      const audioStream = await streamAudioFromGecMl(params, () => {
        this.isPlaying = true
      }, (success, error) => {
        if (!success) {
          this.showErrorMessage(error)
        }
        this.isProcessing = false
        this.isPlaying = false
      })
      this.base64AudioStopper = () => audioStream.stop()
      // this.messageHandler(msg).then(async response => {
      //   const responseMessage = response.data.data.content
      //   await this.getBase64FromTextAndPlay(responseMessage)
      // }).finally(() => {
      //   this.isProcessing = false
      // })
    },
  },
}
</script>
  <style lang="scss">
    .chatbot {
      height: 150px;
      width: 150px;
      margin:auto;

      &:hover{
        .setting-icon {
          display: block !important;
        }
      }
      /* overflow: scroll !important; */
    }
  </style>
