<template>
  <div>
    <validation-observer
      v-if="show"
      ref="formProvider"
    >
      <b-card v-if="showForm">
        <b-row>
          <!-- <b-col>
            <b-form-group label="Date of birth">
              <span v-if="form.dob"
                    style="position: absolute; right: 0;margin-right: 20px;margin-top: 5px;"
                    role="button"
                    @click="form.dob = null"
              >
                <feather-icon icon="XIcon"''
                              size="15"
                />
              </span>
              <flat-pickr
                v-model="form.dob"
                placeholder="Child Dob"
                class="form-control"
                :config="{ altInput: true,altFormat: 'F j, Y', dateFormat: 'Y-m-d', maxDate:maxDate}"
                @input="getReservations"
              />
            </b-form-group>
          </b-col> -->
          <b-col>
            <b-form-group label="School">
              <v-select
                v-model="selectedSchool"
                label="text"
                :reduce="item => item.value"
                :options="schoolLists"
                @input="getReservations"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Student email">
              <b-form-input
                v-model="form.student_email"
                type="email"
                placeholder="Student email (optional)"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-overlay
        :show="isProcessing"
      >
        <div v-if="reservations.length">
          <b-tabs
            v-model="activeTab"
            pills
          >
            <b-tab title="Select Programs">
              <b-row>
                <b-col
                  v-for="reservation,index of reservations"
                  :key="index"
                  md="4"
                >
                  <reservation-card
                    :class="{selected: form.reservation_id == reservation.id } "
                    :value="reservation"
                    class="reservation-card"
                    @onReserve="handleReserve(reservation)"
                  />
                </b-col>
              </b-row>
              <div class="text-right">
                <b-button
                  variant="gradient-success"
                >
                  Next <feather-icon icon="FastForwardIcon" />
                </b-button>
              </div>
            </b-tab>
            <b-tab title="Schedule">
              <custom-schedule
                v-if="selectedReservation"
                :key="selectedReservation && selectedReservation.id"
                :form="form"
                :reservation="selectedReservation"
                @submitForm="$emit('submitForm')"
              />
              <b-card v-else>
                <span>
                  Please select Program first.
                </span>
              </b-card>
            </b-tab>
          </b-tabs>
        </div>
        <b-card v-if="!reservations.length && !isProcessing && form.dob">
          No Reservation found! <br>
        </b-card>
      </b-overlay>
    </validation-observer>
    <slot v-else />
  </div>
</template>
<script>
import {
  BRow, BCol, BOverlay, BCard, BFormGroup, BFormInput, BTabs, BTab, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
// import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import ReservationCard from './ReservationCard.vue'
import CustomSchedule from './CustomSchedule.vue'

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BCard,
    ReservationCard,
    BFormInput,
    BFormGroup,
    ValidationObserver,
    CustomSchedule,
    BTabs,
    BTab,
    BButton,
    vSelect,
    // flatPickr,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    schoolLists: {
      type: Array,
      default: () => [],
    },
    showForm: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      reservations: [],
      maxDate: new Date(),
      filterableReservation: [],
      selectedReservation: null,
      isProcessing: false,
      activeTab: 0,
      selectedSchool: this.$route.query.school || null,
      required,
    }
  },
  created() {
    this.getReservations()
  },
  methods: {
    getDateFormat(item) {
      return `${this.FORMAT_DATE(`2020-03-09 ${item.start_time}`, 'h:mm a')} - ${this.FORMAT_DATE(`2020-03-09 ${item.end_time}`, 'h:mm a')}`
    },
    handleReserve(reservation) {
      this.form.reservation_id = reservation.id
      this.selectedReservation = reservation
      this.activeTab = 1
    },
    getReservations() {
      this.isProcessing = true
      useJwt.getAvailableReservation({
        params: {
          dob: this.form.dob,
          school_id: this.selectedSchool,
          type: 'scheduled',
        },
      }).then(response => {
        this.reservations = response.data.data.reservation.map(item => ({ ...item }))
        this.filterableReservation = [...this.reservations]
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
<style>
  .selected {
    border: 3px solid #10288E;
  }
  .reservation-card:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
    box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
  }
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
