<template>
  <b-modal v-if="!cameraPermissionAllowed"
           ref="cameraPermissionModal"
           :ok-title="$t('camera-permission-module.ok-title')"
           :title="$t('camera-permission-module.title')"
           size="lg"
           class="camera-permission-modal"
           centered
           :cancel-title="$t('camera-permission-module.cancel-title')"
           cancel-variant="danger"
           @ok="onNeverShowAgain"
           @cancel="onLater"
  >
    <b-row>
      <b-col v-if="isSafari"
             cols="12"
      >
        <p>{{ $t('camera-permission-module.when-choose') }}</p>
        <ul class="pl-2">
          <li>
            <b>{{ $t('camera-permission-module.allow-title') }}:</b>
            {{ $t('camera-permission-module.safari.allow-text') }}
          </li>
          <li class="mt-1">
            <b>{{ $t('camera-permission-module.never-for-website-title') }}:</b>
            {{ $t('camera-permission-module.safari.never-for-website-text') }}
          </li>
          <li class="mt-1">
            <b>{{ $t('camera-permission-module.dont-allow-title') }}:</b>
            {{ $t('camera-permission-module.safari.dont-allow-text') }}
          </li>
        </ul>
        <p class="text-info pb-1">
          <b>{{ $t('camera-permission-module.note') }}:</b> {{ $t('camera-permission-module.safarinote') }}
        </p>
        <div class="text-center">
          <img src="@/assets/images/permissions/safari.png"
               alt="Safari"
               class="img-fluid"
          >
        </div>
      </b-col>
      <b-col v-else-if="isFirefox"
             cols="12"
      >
        <p>{{ $t('camera-permission-module.when-choose') }}</p>
        <ul class="pl-2">
          <li><b>{{ $t('camera-permission-module.allow-title') }}:</b>
            {{ $t('camera-permission-module.firefox.allow-text') }}
          </li>
          <li class="mt-1">
            <b>{{ $t('camera-permission-module.block-title') }}:</b>
            {{ $t('camera-permission-module.firefox.block-text') }}
          </li>
        </ul>
        <p class="text-info pb-1">
          <b>{{ $t('camera-permission-module.note') }}:</b> {{ $t('camera-permission-module.firefox.note') }}
        </p>
        <div class="text-center">
          <img src="@/assets/images/permissions/firefox.png"
               alt="Firefox"
               class="img-fluid"
               style="width:400px; height:170px"
          >
        </div>
        <p class="text-info pt-1">
          <b>{{ $t('camera-permission-module.note') }}:</b> {{ $t('camera-permission-module.firefox.note-2') }}.
        </p>
      </b-col>
      <b-col v-else-if="isChrome"
             cols="12"
      >
        <p>{{ $t('camera-permission-module.when-choose') }}</p>
        <ul class="pl-2">
          <li><b>{{ $t('camera-permission-module.allow-title') }}:</b>
            {{ $t('camera-permission-module.chrome.allow-text') }}
          </li>
          <li class="mt-1">
            <b>{{ $t('camera-permission-module.block-title') }}:</b>
            {{ $t('camera-permission-module.chrome.block-text') }}
          </li>
        </ul>
        <p class="text-info pb-1">
          <b>{{ $t('camera-permission-module.note') }}:</b>
          {{ $t('camera-permission-module.chrome.note') }}
        </p>
        <div class="text-center">
          <img src="@/assets/images/permissions/microsoft-edge.png"
               alt="Chrome"
               class="img-fluid"
          >
        </div>
      </b-col>
      <b-col v-else-if="isEdge"
             cols="12"
      >
        <p>{{ $t('camera-permission-module.when-choose') }}</p>
        <ul class="pl-2">
          <li><b>{{ $t('camera-permission-module.allow-title') }}:</b>
            {{ $t('camera-permission-module.edge.allow-text') }}
          </li>
          <li class="mt-1">
            <b>{{ $t('camera-permission-module.block-title') }}:</b>
            {{ $t('camera-permission-module.edge.block-text') }}
          </li>
        </ul>
        <p class="text-info pb-1">
          <b>{{ $t('camera-permission-module.note') }}:</b>
          {{ $t('camera-permission-module.edge.note') }}
        </p>
        <div class="text-center">
          <img src="@/assets/images/permissions/microsoft-edge.png"
               alt="Microsoft Edge"
               class="img-fluid"
          >
        </div>
      </b-col>
      <!-- <b-col v-else-if="isOpera"
             cols="12"
      >
        Opera
      </b-col> -->
      <b-col v-else
             cols="12"
      >
        <p>{{ $t('camera-permission-module.when-choose') }}</p>
        <ul class="pl-2">
          <li><b>{{ $t('camera-permission-module.allow-title') }}:</b>
            {{ $t('camera-permission-module.chrome.allow-text') }}
          </li>
          <li class="mt-1">
            <b>{{ $t('camera-permission-module.block-title') }}:</b>
            {{ $t('camera-permission-module.chrome.block-text') }}
          </li>
        </ul>
        <p class="text-info pb-1">
          <b>{{ $t('camera-permission-module.note') }}:</b>
          {{ $t('camera-permission-module.chrome.note') }}
        </p>
        <div class="text-center">
          <img src="@/assets/images/permissions/microsoft-edge.png"
               alt="Chrome"
               class="img-fluid"
          >
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col cols="12">
        <p class="text-danger">
          {{ $t('camera-permission-module.never-message') }}
        </p>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { BModal, BRow, BCol } from 'bootstrap-vue'
import moment from 'moment'
import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'

const cameraPermissionModal = ref()

const userBrowser = navigator.userAgent.toLowerCase()

const isSafari = userBrowser.indexOf('safari') !== -1 && userBrowser.indexOf('chrome') === -1
const isFirefox = userBrowser.indexOf('firefox') !== -1
const isChrome = userBrowser.indexOf('chrome') !== -1
const isEdge = userBrowser.indexOf('edge') !== -1

const laterDate = localStorage.getItem('cameraPermissionLater')

const isLater = laterDate ? moment().isBefore(laterDate) : false

// const isOpera = userBrowser.indexOf('opera') !== -1
// const isIE = userBrowser.indexOf('msie') !== -1 || !!document.documentMode === true

const cameraPermissionAllowed = ref(true)

const askForPermission = parseInt(getUserData()?.camara_setting, 10) === 1

const checkCameraPermission = async () => {
  await navigator.mediaDevices.getUserMedia({ video: true })
    .then(() => {
      cameraPermissionAllowed.value = true
    })
    .catch(() => {
      cameraPermissionAllowed.value = false
    })
}

onMounted(async () => {
  if (askForPermission) {
    await checkCameraPermission()
    if (!cameraPermissionAllowed.value && !isLater) {
      cameraPermissionModal.value.show()
    }
  }
})

const onLater = () => {
  const date = moment().add(7, 'days').format('YYYY-MM-DD')
  localStorage.setItem('cameraPermissionLater', date)
  cameraPermissionModal.value.hide()
}

const updateUserData = currentUserData => {
  localStorage.setItem('userData', JSON.stringify(currentUserData))
}

const onNeverShowAgain = () => {
  useJwt.updateUserPermission({ permission: 'camera_setting', value: false }).then(() => {
    const currentUserData = getUserData()
    currentUserData.camara_setting = 0
    updateUserData(currentUserData)
  })
  cameraPermissionModal.value.hide()
}

</script>

<style lang="scss">
  .camera-permission-modal{
    .row{
        ul{
        list-style-type: disc !important;

        li{
          list-style-type: disc !important;
        }

      }
    }
  }
</style>
