<template>
  <div
    :style="`margin-top:20px;position:${position};top:${top};left:${left}; width:${width}; height:${height};`"
  >
    <b-col v-if="loading"
           cols="12"
           class="d-flex h-100 align-items-center justify-content-center"
    >
      <b-spinner />
    </b-col>
    <canvas
      :id="canvasId"
      class="cursor-pointer"
    />

  </div>
</template>
<script type="model">
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import { FOREST_CHARACTERS_ALL_OUTFITS } from '@/const/theme'
import { BSpinner, BCol } from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
    BCol,
  },
  props: {
    animationFile: {
      type: String,
      default: '',
    },
    activeAnimation: {
      type: [Array, Object, String],
      default: () => ['idle'],
    },
    animationTypes: {
      type: [Object, Array],
      default: () => { },
    },
    position: {
      type: String,
      default: '',
    },
    top: {
      type: [String, Number],
      default: 0,
    },
    left: {
      type: [String, Number],
      default: 0,
    },
    canvasId: {
      type: String,
      default: 'webgl',
    },
    cameraPosition: {
      type: Object,
      default: () => {},
    },
    scaling: {
      type: [Object, Array],
      default: () => {},
    },
    lightIntensity: {
      type: [String, Number],
      default: 2,
    },
    width: {
      type: [String, Number],
      default: window.innerWidth,
    },
    height: {
      type: [String, Number],
      default: '200',
    },
    canvasWidth: {
      type: [String, Number],
      default: window.innerWidth,
    },
    canvasHeight: {
      type: [String, Number],
      default: window.innerHeight,
    },
    background: {
      type: [String, Boolean],
      default: 'transparent',
    },
    rotation: {
      type: [String, Boolean],
      default: false,
    },

    playAllAnimation: {
      type: [String, Boolean],
      default: false,
    },
    rotationAngle: {
      type: [String, Number],
      default: 0,
    },
    characterType: {
      type: [String, Number],
      default: 0,
    },
    selectedOutfits: {
      type: [Array, Object],
      default: () => {},
    },
    characterName: {
      type: [String],
      default: 'elephant',
    },
  },
  data() {
    return {
      canvas: null,
      scene: null,
      mixer: null,
      loader: null,
      dracoLoader: null,
      light: null,
      clock: new THREE.Clock(),
      renderer: null,
      spotLight: null,
      animations: null,
      root: null,
      loading: false,
      allOutfits: [],

    }
  },

  watch: {
    animationFile() {
      this.filterOutfits()
      this.$nextTick(() => {
        this.clearSceneAndInit()
      })
    },
    rotationAngle() {
      this.updateRotation()
    },
    selectedOutfits() {
      this.changedOutfits()
    },
  },
  mounted() {
    if (this.scene) {
      this.scene.clear()
      this.renderer.clear()
      this.renderer = null
    }
    this.canvas = document.getElementById(this.canvasId)
    this.spotLight = new THREE.SpotLight(0xffa95c, this.lightIntensity)
    this.clearSceneAndInit()
    this.filterOutfits()
  },
  methods: {
    changedOutfits() {
      this.filterOutfits()
      if (this.scene) {
        this.scene.remove(this.root)
      }
      this.setMaterial(true)
    },
    filterOutfits() {
      this.allOutfits = FOREST_CHARACTERS_ALL_OUTFITS()
      if (this.selectedOutfits && this.selectedOutfits.length > 0) {
        this.selectedOutfits.forEach(outfit => {
          this.allOutfits = [...this.allOutfits.filter(e => e !== outfit)]
        })
      }
    },
    async clearSceneAndInit() {
      if (this.scene) {
        this.scene.clear()
        this.renderer.clear()
        this.renderer = null
      }
      this.scene = null
      this.root = null
      this.$nextTick(async () => {
        await this.init()
        this.animate()
      })
    },
    init() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async resolve => {
        this.loading = true
        this.scene = new THREE.Scene()
        this.light = new THREE.HemisphereLight(0xffeeb1, 0x080820, this.lightIntensity)
        this.scene.add(this.light)

        this.spotLight.castShadow = true
        this.spotLight.shadow.bias = -0.0001
        this.spotLight.shadow.mapSize.width = 1024 * 4
        this.scene.add(this.spotLight)

        const point = new THREE.PointLight(0xffffff, 1, 200)
        point.position.set(1, 1, 1)
        this.scene.add(point)

        this.renderer = new THREE.WebGL1Renderer({
          canvas: this.canvas,
        })

        this.renderer.setSize(this.canvasWidth, this.canvasHeight)
        this.renderer.setPixelRatio(window.devicePixelRatio)
        this.renderer.setClearColor(0x000000, 0)
        if (this.background === 'dark') {
        // const backgroundLoader = new THREE.TextureLoader()
        // backgroundLoader.load('/animations/forest/skie.png', texture => {
        //   this.scene.background = texture
        // })
        }
        this.renderer.toneMapping = THREE.ReinhardToneMapping
        this.renderer.toneMappingExposure = 2.5
        this.renderer.shadowMap.enabled = true
        this.renderer.gameOutput = true
        this.loader = new GLTFLoader()
        this.dracoLoader = new DRACOLoader()
        this.dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/') // use a full url path
        this.loader.setDRACOLoader(this.dracoLoader)
        this.camera = new THREE.PerspectiveCamera(75, this.canvasWidth / this.canvasHeight, 1, 10)
        this.camera.zoom = 1
        this.root = null
        await this.loader.load(this.animationFile, glb => {
          this.animations = glb?.animations
          this.root = glb.scene
          const camera = glb?.cameras['0']

          if (camera) {
            const posX = camera.position.x
            const posY = camera.position.y
            const posZ = camera.position.z
            this.camera.fov = camera.fov
            this.camera.near = camera.near
            this.camera.far = camera.far
            this.camera.aspect = camera.aspect
            this.camera.updateProjectionMatrix()
            this.camera.position.set(posX, posY, posZ)
          } else {
            this.camera.position.set(this.cameraPosition.x, this.cameraPosition.y, this.cameraPosition.z)
          }
          const controls = new OrbitControls(this.camera, this.renderer.domElement)
          this.scene.add(this.camera)

          const newSpotlight = new THREE.SpotLight(0xffa95c, this.lightIntensity)
          newSpotlight.position.set(
            this.camera.position.x - 10,
            this.camera.position.y - 10,
            this.camera.position.z - 10,
          )
          this.scene.add(newSpotlight)

          controls.enableZoom = false
          controls.update()
          this.updateRotation()
          this.scene.fog = new THREE.Fog(0xDFE9F3, 1, 1000)

          // const model = root.children[0]
          // model.position.set(0, -5, -25)

          this.root.position.y -= 0.7
          this.root.scale.set(this.scaling.x, this.scaling.y, this.scaling.z)
          this.setMaterial()
          if (this.root) this.scene.add(this.root)
          this.mixer = new THREE.AnimationMixer(this.root)
          this.playAnimation()
          this.loading = false
        })
        resolve(true)
      })
    },

    setMaterial(addRoot = false) {
      if (this.root) {
        this.root.traverse(o => {
          if (this.unselectedOutfits(o.name.toLowerCase())) {
          // eslint-disable-next-line no-param-reassign
            o.visible = false
          } else {
          // eslint-disable-next-line no-param-reassign
            o.visible = true
          }
        })
      }

      if (addRoot && this.scene && this.root) {
        this.scene.add(this.root)
      }
    },

    resetCamera() {
      this.camera.position.set(this.cameraPosition.x, this.cameraPosition.y, this.cameraPosition.z)
    },

    updateRotation() {
      if (this.rotation) {
        this.root.rotation.y = this.rotationAngle
      }
    },

    playAnimation() {
      if (this.mixer) {
        this.mixer.stopAllAction()
      }
      if (this.animations && this.animations.length > 0) {
        if (this.playAllAnimation) {
          for (let i = 0; i < this.animations.length; i += 1) {
            const animation = this.mixer.clipAction(this.animations[i])
            animation.enable = true
            animation.play()
          }
          return
        }
        let animation
        this.activeAnimation.forEach(anim => {
          animation = this.mixer.clipAction(this.animations[this.animationTypes[anim]])
          animation.enable = true
          animation.play()
        })
      }
    },

    unselectedOutfits(name) {
      const filterName = name.replace(`${this.characterName}`, '')
      return this.allOutfits.includes(filterName)
    },

    animate() {
      requestAnimationFrame(this.animate)
      if (this.mixer) {
        const delta = this.clock.getDelta()
        this.mixer.update(delta)
      }
      this.renderer.render(this.scene, this.camera)
      this.spotLight.position.set(
        this.camera.position.x + 10,
        this.camera.position.y + 10,
        this.camera.position.z + 10,
      )
    },

  },
}
</script>
<style lang="scss">
.cursor-pointer{
  cursor:pointer;

}

</style>
