<template>
  <b-modal
    :visible="show"
    size="lg"
    title="Layout Option"
    @hide="$emit('close')"
    @close="$emit('close')"
  >
    <b-row>
      <b-col>
        <b-form-group label="Text">
          <editor
            v-model.lazy="form.text"
            placeholder="Text"
          />
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group
              label="Text Color"
              label-class="text-center"
            >
              <div class="d-flex justify-content-center">
                <verte
                  v-model="form.textColor"
                  picker="wheel"
                  model="rgb"
                />
                <EyeDropper v-model="form.textColor"
                            class="ml-2"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label-class="text-center">
              <template #label>
                <span class="text-center">Text Background</span>
              </template>
              <div class="d-flex justify-content-center">
                <verte
                  v-model="form.textBackground"
                  picker="wheel"
                  model="rgb"
                />
                <EyeDropper v-model="form.textBackground"
                            class="ml-2"
                />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Image">
          <feather-icon
            v-if="form.imageUrl || form.image"
            icon="XIcon"
            @click="form.imageUrl = null; form.image = null"
          />
          <b-form-file
            v-model="form.image"
            :browse-text="$t('browseButton')"
            :placeholder="$t('issue-report-module.choose-a-file')"
            @input="handleFileChange"
          />
          <div v-if="form.imageUrl">
            <img
              v-image-preview
              :src="form.imageUrl"
              :height="form.imageHeight"
              :width="form.imageWidth"
            >
            <b-row>
              <b-col>
                <b-form-group label="Image Width">
                  <input
                    v-model.number="form.imageWidth"
                    type="number"
                    class="form-control"
                    placeholder="Image Width"
                  >
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Image Height">
                  <input
                    v-model.number="form.imageHeight"
                    type="number"
                    class="form-control"
                    placeholder="Image Height"
                  >
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-form-group>
        <b-form-group label="Audio Recorder">
          <div class="d-flex">
            <recorder
              :url="form.audioUrl"
              @onRemove="form.audioUrl = null"
              @input="setAudio($event)"
            />
            <div class="ml-2">
              <label>Play Automatically</label>
              <b-form-checkbox
                v-model="form.playAudioAutomatically"
                :value="1"
                switch
              />
            </div>
          </div>
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group label="Align">
              <b-form-select
                v-model="form.textAlign"
                :options="textAlign"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Enter Action">
              <b-form-select
                v-model="form.action"
                :options="enterActions"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Action Delay">
              <b-form-input
                v-model.number="form.delay"
                placeholder="50 to 3000"
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <template #modal-footer="{ }">
      <b-button
        v-if="indexToEdit != null"
        variant="danger"
        @click="handleDelete"
      >
        Delete
      </b-button>
      <b-button
        variant="success"
        @click="handleOk"
      >
        Save
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal, BRow, BCol, BFormGroup, BFormFile, BFormSelect, BButton, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import Recorder from '@/views/super/problem/components/recorder.vue'
import Verte from 'verte'
import 'verte/dist/verte.css'
import EyeDropper from '@/views/common/components/EyeDropper.vue'
import Editor from '@views/common/components/QuillEditorCustom.vue'

const initialForm = () => ({
  text: '',
  textColor: '',
  textBackground: 'rgba(0,0,0,0)',
  image: null,
  audioUrl: '',
  xPos: null,
  yPos: null,
  spacing: null,
  action: 'fade-up',
  delay: null,
  visible: false,
  imageUrl: '',
  textAlign: 'center',
})
export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    Recorder,
    BFormSelect,
    Verte,
    BButton,
    BFormInput,
    BFormCheckbox,
    EyeDropper,
    Editor,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    editorData: {
      type: Array,
      default: () => [],
    },
    indexToEdit: {
      type: [Number, Object],
      default: null,
    },
  },
  data() {
    return {
      enterActions: [
        'fade-up',
        'fade-down',
        'fade-in',
        'fade-out',
        'flip-up',
        'flip-down',
        'flip-left',
        'flip-right',
        'slide-up',
        'slide-down',
        'slide-left',
        'slide-right',
      ],
      textAlign: [
        'center',
        'left',
        'right',
      ],
      form: {
        ...initialForm(),
      },
    }
  },
  watch: {
    show(value) {
      if (value) {
        if (this.indexToEdit !== null) {
          this.form = { ...this.editorData[this.indexToEdit], visible: false }
        } else {
          this.form = { ...initialForm(), textColor: this.form.textColor }
        }
      }
    },
    // 'form.image': {
    //   handler() {
    //     const reader = new FileReader()
    //     reader.addEventListener(
    //       'load',
    //       () => {
    //         this.form.imageUrl = reader.result
    //       },
    //       false,
    //     )
    //     if (this.form.image) {
    //       reader.readAsDataURL(this.form.image)
    //     }
    //   },
    //   deep: true,
    // },
  },
  methods: {
    handleFileChange() {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.form.imageUrl = reader.result
        },
        false,
      )
      if (this.form.image) {
        reader.readAsDataURL(this.form.image)
      }
    },
    async  fileToBase64(file) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function () {
          const base64String = reader.result
          resolve(base64String)
        }
      })
    },
    setAudio({ audio }) {
      let convertedAudio = ''
      this.fileToBase64(audio).then(result => {
        convertedAudio = result
        this.form.audioUrl = convertedAudio
      })
    },
    handleDelete() {
      this.$emit('close')
      setTimeout(() => {
        this.editorData.splice(this.indexToEdit, 1)
        this.$emit('destroyData')
      }, 100)
    },
    handleOk() {
      this.$emit('close')
      setTimeout(() => {
        if (this.indexToEdit !== null) {
          this.editorData[this.indexToEdit] = { ...this.form }
          this.editorData.push({ })
          this.editorData.pop()
        } else {
          this.editorData.push({ ...this.form })
        }
        this.$emit('destroyData')
      }, 100)
    },
  },
}
</script>
