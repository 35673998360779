<template>
  <span>
    <slot :handle-regenerate="handleRegenerate" />
    <ConfirmModal v-if="state.showGenerate"
                  title="Confirm"
                  sub-title="Are you sure you want to regenerate"
                  :on-delete="onConfirm"
                  :show="true"
                  varient="primary"
                  :is-processing-delete="state.isProcessing"
                  @close="state.showGenerate = false"
    />
  </span>
</template>
<script setup>
import ConfirmModal from '@/views/common/components/DeleteModal.vue'
import { reactive, getCurrentInstance } from 'vue'
import useJwt from '@/auth/jwt/useJwt'

const props = defineProps({
  imageLibraryId: {
    type: Number,
    required: true,
  },
})

const state = reactive({
  isProcessing: false,
  showGenerate: false,
})

const root = getCurrentInstance().proxy.$root

const handleRegenerate = () => {
  state.showGenerate = true
}

const emits = defineEmits(['reFetch'])

const onConfirm = () => {
  state.isProcessing = true
  useJwt.regenerateImageLibraryAssets(props.imageLibraryId).then(response => {
    root.showSuccessMessage(response)
    state.showGenerate = false
    emits('reFetch')
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    state.isProcessing = false
  })
}

</script>
