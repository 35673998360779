<template>
  <div>

    <b-col
      md="12"
      class="d-flex justify-content-end p-0 mb-2"
    >
      <span>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="$router.push({name:'super-adaptive-learning-create'})"
        >
          {{ $t("adaptive-learning-module.create-algorithm") }}
        </b-button>
      </span>
    </b-col>

    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        v-if="algorithms.length>0"
        responsive
        :fields="columns"
        :items="algorithms"
      >
        <template #cell(is_active)="data">
          {{ data.item.is_active ? $t('adaptive-learning-module.yes') : $t('adaptive-learning-module.no') }}
        </template>
        <template #cell(is_default)="data">
          {{ data.item.is_default ? $t('adaptive-learning-module.yes') : $t('adaptive-learning-module.no') }}
        </template>
        <template #cell(action)="data">
          <span>
            <span>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :disabled="isProcessing"
                variant="outline-primary"
                class="btn-icon mr-1"
                @click="$router.push({name:'super-adaptive-learning-edit',params:{id:data.item.id}})"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </span>

            <span>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :disabled="isProcessing"
                variant="outline-danger"
                class="btn-icon mt-1 mt-md-0"
                @click="idOfAlgorithmBeingDeleted = data.item.id"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </span>
          </span>

        </template>
      </b-table>
      <p
        v-else
        class="p-2 mb-0"
      >
        {{ $t('adaptive-learning-module.no-algorithms') }}
      </p>
    </b-card>

    <delete-modal
      name="modal-delete-class"
      :on-delete="deleteAlgorithm"
      :title="$t('adaptive-learning-module.delete-algorithm-title')"
      :sub-title="$t('adaptive-learning-module.delete-algorithm-question')"
      :is-processing="isProcessing"
      :show="idOfAlgorithmBeingDeleted!=null"
      @close="idOfAlgorithmBeingDeleted=null"
    />

  </div>

</template>
<script>

import {
  BCard, BTable, BButton, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import i18n from '@/libs/i18n'
import useJwt from '@/auth/jwt/useJwt'
import DeleteModal from '@/views/common/components/DeleteModal.vue'

export default ({
  components: {
    BCard, BTable, BButton, BCol, DeleteModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      columns: [
        { key: 'name', label: i18n.tc('adaptive-learning-module.algorithms-table.name') },
        { key: 'is_active', label: i18n.tc('adaptive-learning-module.algorithms-table.is_active') },
        { key: 'is_default', label: i18n.tc('adaptive-learning-module.algorithms-table.is_default') },
        { key: 'action', label: i18n.tc('adaptive-learning-module.algorithms-table.actions'), thStyle: { width: '150px' } },
      ],
      searchTerm: '',
      algorithms: [],
      isProcessing: false,
      idOfAlgorithmBeingDeleted: null,
    }
  },
  created() {
    this.getAlgorithms()
  },
  methods: {
    getAlgorithms() {
      this.isProcessing = true
      useJwt.getLearningAlgorithms().then(res => {
        this.algorithms = res.data.data
      }).finally(() => {
        this.isProcessing = false
      })
    },
    deleteAlgorithm() {
      this.isProcessing = true
      useJwt.deleteLearningAlgorithm(this.idOfAlgorithmBeingDeleted).then(res => {
        this.idOfAlgorithmBeingDeleted = null
        this.showSuccessMessage(res)
        this.getAlgorithms()
      }).finally(() => {
        this.isProcessing = false
      })
    },
    searchTable() {},
  },
})
</script>

<style>
.class-table .active-column {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.class-table tr{
  cursor: pointer;
}
.class-table{
  margin-bottom: 0;
}
</style>
