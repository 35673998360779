<template>
  <div>
    <b-row>
      <b-col md="8">
        <b-form-group
          label="Correct Text"
        >
          <b-form-textarea
            v-model="correct_text"
            placeholder="Write Your Text Here"
            @change="onUpdateAnswer"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    // BButton,
  },
  props: {
    type: {
      type: String,
      default: 'create',
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    initialQuestion: {
      type: Array,
      default: () => [],
    },
    level: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      correct_text: '',
      disordered_text: '',
      questionObject: null,
    }
  },
  computed: {
    isEditMode() {
      return this.type === 'edit'
    },
  },
  mounted() {
    this.setData()
  },
  methods: {
    disorderText() {
      const words = this.correct_text.split(' ')

      // Shuffle the array of words
      // eslint-disable-next-line no-plusplus
      for (let i = words.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [words[i], words[j]] = [words[j], words[i]]
      }

      // Join the shuffled words back into a sentence
      this.disordered_text = words.join(' ')
    },
    setData() {
      if (this.isEditMode) {
        let template = JSON.parse(this.initialQuestion[0].text).before_template

        const answers = this.initialQuestion[0].answers.map(item => item.text)
        answers.forEach(item => {
          template = template.replace('[ ]', `***${item}***`)
        })
        // eslint-disable-next-line prefer-destructuring
        this.questionObject = this.initialQuestion[0]
        this.correct_text = template
      }
    },
    getData() {
      return {
        questionAnswers: this.correct_text.trim().split(' '),
        problemType: 'drag_position',
      }
    },
    onUpdateAnswer() {
      if (this.questionObject) {
        const template = {
          type: 'None',
          question: '',
          page_link: '',
          before_template: this.correct_text,
          template: [],
          after_template: '',
        }
        this.$emit('updateQuestion', {
          langLevel: this.questionObject.lang_level,
          questionId: this.questionObject.id,
          question: JSON.stringify(template),
        })
      }
    },
  },
}
</script>
