<template>

  <!-- join button -->
  <div
    class="ml-auto network-actions"
  >

    <div v-if="!isALeader && !isACoLeader">
      <b-button
        v-if="!hasRequested"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="ml-auto small-button"
        @click="requestJoinNetwork()"
      >
        <b-spinner
          v-show="processingAction==='join-network'"
          class="mr-1"
          small
          variant="primary"
        />
        <span>{{ $t("network-module.join-request") }}</span>
      </b-button>
      <b-button
        v-if="hasPendingRequest"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-secondary"
        class="small-button"
        :disabled="processingAction==='cancel-request'"
        @click="showCancelJoinRequest=true"
      >
        <b-spinner
          v-show="processingAction==='cancel-request'"
          class="mr-1"
          small
          variant="primary"
        />
        <span>{{ $t("network-module.cancel") }} {{ $t("network-module.join-request") }}</span>
      </b-button>
      <b-button
        v-if="isInvited"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="small-button"
        @click="acceptInvitation()"
      >
        <b-spinner
          v-show="processingAction==='accept-invitation'"
          class="mr-1"
          small
          variant="primary"
        />
        <span>{{ $t("network-module.accept") }}</span>
      </b-button>
      <b-button
        v-if="isInvited"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="ml-1 small-button"
        variant="outline-secondary"
        @click="rejectInvitation()"
      >
        <b-spinner
          v-show="processingAction==='reject-invitation'"
          class="mr-1"
          small
          variant="primary"
        />
        <span>{{ $t("network-module.decline") }}</span>
      </b-button>
    </div>
    <div
      v-else
      class="mb-2 mb-lg-0"
    >
      <div>
        <b-button
          v-b-modal.modal-invite-member
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="mr-1"
        >
          <feather-icon
            icon="UserPlusIcon"
            class="d-block d-md-none"
          />
          <span class="font-weight-bold d-none d-md-block">{{ $t("network-module.invite-member") }}</span>
        </b-button>
        <b-dropdown
          variant="link"
          dropup
          left
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="SettingsIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <span>
            <b-dropdown-item v-b-modal.modal-edit-network>
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t("edit") }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="setShowDeleteModal(true)">
              <feather-icon
                icon="TrashIcon"
              />
              <span class="align-middle ml-50">{{ $t("delete") }}</span>
            </b-dropdown-item>
          </span>
        </b-dropdown>
      </div>
    </div>
    <edit-create
      mode="edit"
      :network="network"
    />

    <!-- delete network model -->
    <delete-modal
      name="modal-delete-network"
      :on-delete="deleteNetwork"
      :title="$t('network-module.delete-network-title')"
      :sub-title="$t('network-module.delete-network-question')"
      :is-processing="processingAction==='cancel-request'"
      :show="showDeleteModal"
      @close="setShowDeleteModal(false)"
    />

    <!-- delete network model -->
    <delete-modal
      v-if="hasPendingRequest"
      name="modal-delete-join-request"
      :on-delete="cancelJoinRequest"
      :title="$t('network-module.delete-network-join-request-title')"
      :sub-title="$t('network-module.delete-network-join-request-question')"
      :is-processing="processingAction==='delete-network'"
      :show="showCancelJoinRequest"
      @close="showCancelJoinRequest=false"
    />

    <invite-member :network="network" />
  </div>
  <!-- join button -->
</template>

<script>
import {
  BButton, BDropdown, BDropdownItem, BSpinner,
} from 'bootstrap-vue'
import EditCreate from '@/views/common/network/components/CreateEdit.vue'
import InviteMember from '@/views/common/network/components/InviteMember.vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { mapGetters } from 'vuex'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BButton, InviteMember, EditCreate, BDropdown, BDropdownItem, DeleteModal, BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isProcessing: false,
      showDeleteModal: false,
      showCancelJoinRequest: false,
      processingAction: null,
    }
  },
  computed: {
    ...mapGetters({
      self: 'network/self',
      requestId: 'network/requestId',
      isALeader: 'network/isALeader',
      isACoLeader: 'network/isACoLeader',
      hasJoinedNetwork: 'network/hasJoinedNetwork',
      hasRequested: 'network/hasRequested',
      isInvited: 'network/isInvited',
      hasPendingRequest: 'network/hasPendingRequest',
      myNetworkMembership: 'network/myNetworkMembership',
    }),
    network() {
      const net = this.$store.state.network.network
      if (net.description === 'null') net.description = null
      return net
    },
  },
  methods: {
    setShowDeleteModal(val) {
      this.showDeleteModal = val
    },
    requestJoinNetwork() {
      this.processingAction = 'join-network'
      useJwt.requestJoinNetwork({
        requested_to: this.network.id,
        message: 'I want to join the network',
        requested_for: 'member',
      }).then(response => {
        this.$store.dispatch('network/getMyNetworkMembership')
        this.showSuccessMessage(response)
      }).finally(() => {
        this.processingAction = null
      })
    },
    acceptInvitation() {
      this.processingAction = 'accept-invitation'
      useJwt.acceptRequest({ request_id: this.requestId }).then(response => {
        this.$store.dispatch('notification/resetNotifications')
        this.$store.dispatch('network/getMyNetworkMembership').then(() => {
          this.$store.dispatch('network/getNetworkMembers')
          if (this.isALeader || this.isACoLeader) {
            this.$store.dispatch('network/getCoLeaders')
            this.$store.dispatch('network/getUnVerifiedArticles')
            this.$store.dispatch('network/getInvitations')
            this.$store.dispatch('network/getRequests')
          }
        })
        this.$store.dispatch('notification/getNotifications')
        this.showSuccessMessage(response)
      }).finally(() => {
        this.processingAction = null
      })
    },
    rejectInvitation() {
      this.processingAction = 'reject-invitation'

      useJwt.rejectRequest({ request_id: this.requestId }).then(response => {
        this.$store.dispatch('notification/getNotifications')
        this.$store.dispatch('notification/resetNotifications')
        this.$store.dispatch('network/getMyNetworkMembership')
        this.showSuccessMessage(response)
      }).finally(() => {
        this.processingAction = null
      })
    },
    cancelJoinRequest() {
      this.processingAction = 'cancel-request'
      useJwt.cancelNetworkJoinRequest(this.network.id).then(response => {
        this.$store.dispatch('network/getMyNetworkMembership')
        this.showSuccessMessage(response)
      }).finally(() => {
        this.showCancelJoinRequest = false
        this.processingAction = null
      })
    },
    deleteNetwork() {
      this.processingAction = 'delete-network'
      useJwt.deleteNetwork(this.network.id).then(response => {
        this.showSuccessMessage(response)
        this.showDeleteModal = false
        this.$router.replace({ name: `${getUserData().usertype}-networks` })
      }).finally(() => {
        this.processingAction = null
      })
    },
  },
}
</script>

<style>
.network-actions .dropdown-menu{
  left: -40px;
}
@media (max-width: 576px) {
  .profile-header .nav-pills .nav-link {
      padding: 0.6rem !important;
  }
  .profile-header .small-button{
    margin-top: 8px;
    padding: 0.6rem !important;
  }
  .profile-header .profile-title{
    margin-top:-50px !important;
  }
 }
</style>
