<template>
  <div id="content">

    <b-skeleton-wrapper v-if="state.isProcessing"
                        :loading="state.isProcessing"
    >
      <template #loading>
        <b-card>
          <b-skeleton width="85%" />
          <b-skeleton width="55%" />
          <b-skeleton width="70%" />
        </b-card>
      </template>
    </b-skeleton-wrapper>
    <SafeFrame v-if="state.linkString"
               :frame-link="state.linkString"
    />
  </div>
</template>
<script setup>
import useJwt from '@/auth/jwt/useJwt'
import { reactive, getCurrentInstance } from 'vue'
import { BSkeletonWrapper, BCard, BSkeleton } from 'bootstrap-vue'
import SafeFrame from './components/SafeFrame.vue'

const instance = getCurrentInstance().proxy.$root

const state = reactive({
  isProcessing: false,
  linkString: '',
})

const getPageLink = () => {
  const linkId = instance.$route.params.id
  state.isProcessing = true
  useJwt.getUrlFromLessonLink(linkId).then(response => {
    state.linkString = response.data.data.link
    useJwt.getSsoLink(response.data.data.provider)
  }).finally(() => {
    state.isProcessing = false
  }).catch(error => {
    instance.showErrorMessage(error)
  })
}

getPageLink()
</script>
<style type="text/css">
body, html
{
    margin: 0; padding: 0; height: 100%; overflow: hidden;
}

#content
{
    position:absolute; left: 0; right: 0; bottom: 0; top: 0px;
}
</style>
