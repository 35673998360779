var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex p-2 justify-content-center align-items-center"},[_c('div',[_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"problem-card-title md-12"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("student-test-module.question-answer"))+" ")])]),_c('img',{attrs:{"id":"myImage","src":_vm.hotspot.file}}),(_vm.point)?_c('div',{class:`point ${
            !_vm.point.answered ? ' clickable' : ' not-clickable'
          }`,style:(`top: ${_vm.point.coordinates.y * 100}%; left: ${
            _vm.point.coordinates.x * 100
          }%; `),on:{"click":function($event){return _vm.checkPoints()}}},[_c('span',[_vm._v(_vm._s(_vm.point.answered ? "✓" : "+"))])]):_vm._e()]),(_vm.showLevel)?_c('div',{staticClass:"d-flex col-12 p-0 justify-content-between align-content-center problem-card-footer"},[_c('actions',{attrs:{"level":_vm.level,"mode":"question","engine":_vm.engine,"on-submit":() => {
              _vm.$emit('proceedToQuestion');
            },"button-title":_vm.$t('next'),"action-visible":false}})],1):_vm._e()])]),_c('problem-modal',{attrs:{"active-point":_vm.hotspot,"toggle":_vm.activeProblemModal,"problem":_vm.problem,"total-points":1,"pid":_vm.pid,"level":_vm.level,"engine":_vm.engine,"start-time":_vm.startTime,"duration":_vm.duration,"is-preview":_vm.isPreview,"loader-id":_vm.loaderId},on:{"problemModalClosed":_vm.problemModalClosed,"answeredQuestion":_vm.answeredQuestion,"nextQuestion":function($event){return _vm.$emit('nextQuestion')}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }