<template>
  <div class="d-flex">
    <div class="mr-auto p-0 d-flex align-items-center">
      <span v-if="standard.code">
        <b-badge
          pill
          variant="light-info"
          class="ml-2"
        >{{ standard.code }}</b-badge>
      </span>
    </div>
    <div class="p-0">
      <b-dropdown
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
        class="p-0"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="text-body align-middle m-0 p-0"
          />
        </template>
        <b-dropdown-item @click="$emit('editStandard')">
          <feather-icon
            icon="EditIcon"
            class="mr-50"
          />
          <span>{{ $t('actions.edit') }}</span>
        </b-dropdown-item>
        <b-dropdown-item @click.stop="$emit('copyStandard')">
          <feather-icon
            icon="CopyIcon"
            class="mr-50"
          />
          <span>{{ $t('labels.move') }}</span>
        </b-dropdown-item>
        <b-dropdown-item @click="$emit('deleteStandard')">
          <feather-icon
            icon="TrashIcon"
            class="mr-50"
          />
          <span>{{ $t('actions.delete') }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BDropdown, BDropdownItem, BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    standard: {
      type: Object,
      required: true,
    },

  },
}
</script>

<style>

</style>
