<template>
  <b-modal
    id="history-modal"
    :title="$t('points-setting-module.update-history')"
    ok-only
    :ok-title="$t('actions.close')"
  >
    <b-overlay
      :show="showHistoryOverlay"
      rounded="sm"
    >
      <b-table
        id="history"
        style="min-height:480px"
        responsive="sm"
        :fields="columns"
        :items="history"
        :per-page="historyTable.perPage"
        :current-page="historyTable.currentPage"
      />

      <div class="row align-items-end">
        <b-form-group
          :label="$t('points-setting-module.rows-per-page')"
          class="col-4"
        >
          <b-form-select
            v-model="historyTable.perPage"
            :options="historyTable.perPageOptions"
          />
        </b-form-group>

        <b-pagination
          v-model="historyTable.currentPage"
          :per-page="historyTable.perPage"
          :total-rows="history_rows"
          :disabled="showHistoryOverlay"
          align="right"
          aria-controls="history"
          class="col-8"
        />
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BTable, BPagination, BFormSelect, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'

export default {
  components: {
    BTable,
    BPagination,
    BFormSelect,
    BFormGroup,
    BOverlay,
  },
  data() {
    return {
      showHistoryOverlay: false,
      historyTable: {
        perPageOptions: [10, 15, 20, 25],
        perPage: 10,
        currentPage: 1,
      },
      history: [],
      columns: [
        {
          label: i18n.tc('points-setting-module.updated-by'),
          key: 'updated_by',
        },
        {
          label: i18n.tc('points-setting-module.points'),
          key: 'points',
        },
      ],
    }
  },
  computed: {
    history_rows() {
      return this.history.length
    },
  },
  mounted() {
    this.getHistory()
  },
  methods: {
    getHistory() {
      this.showHistoryOverlay = true
      this.history = []
      useJwt.getPointsSettingHistory().then(response => {
        const { data } = response
        const history = []
        data.data.forEach(value => {
          history.push({
            updated_by: value.created_by ? value.created_by.firstname : '',
            points: value.value,
          })
        })
        this.history = history
      }).catch(err => {
        this.showErrorMessage(err)
      }).finally(() => { this.showHistoryOverlay = false })
    },
  },
}
</script>
