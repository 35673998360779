<template>
  <b-modal
    id="modal-add-question"
    ref="modal-add-question"
    ok-only
    ok-variant="primary"
    :ok-title="$t('confirm')"
    modal-class="modal-primary"
    centered
    :title="$t('actions.create-question')"
    @ok="addQuestion()"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-col md="12">
      <b-tabs
        pills
        align="center"
      >
        <b-tab
          v-for="(level, index) in levels"
          :key="index + level"
          :title="level"
          :active="activeTabIndex === index"
          @click="tabChange(index)"
        >
          <!-- Question -->
          <b-form-group
            v-if="questionType === 'text'"
            :label="$t('labels.create-problem.question')"
            label-for="question"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="question"
              vid="question"
            >
              <b-form-input
                id="question"
                v-model="problem.questions.text[index]"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('labels.create-problem.placeholder.question')"
                name="question"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Image -->
          <b-form-group
            v-else-if="questionType === 'image'"
            :label="$t('labels.create-problem.question')"
            :label-for="`question-${level}`"
          >
            <validation-provider
              v-slot="{ errors }"
              name="question"
              :vid="`question-${level}`"
            >
              <b-form-file
                :id="`question-${level}`"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('labels.file-placeholder')"
                :browse-text="$t('browseButton')"
                accept="image/jpeg, image/png, image/gif"
                @change="handleImage($event, 'questions', index)"
              />
              <small class="text-danger">{{ errors[0] }}</small><br>
              <b-img
                v-if="problem.questions.imageUrl[index]"
                rounded
                fluid
                :src="problem.questions.imageUrl[index]"
                height="150"
                width="150"
                class="mt-2"
              />
            </validation-provider>
          </b-form-group>
          <!-- Image -->
          <!-- Audio -->
          <b-form-group
            v-else-if="questionType === 'audio'"
            :label="$t('labels.create-problem.question')"
            label-for="audio"
          >
            <Recorder
              :url="problem.questions.audioUrl[index]"
              @input="setAudio($event, 'questions', index)"
            />
          </b-form-group>
          <!-- / Question -->

          <!-- Answer -->
          <b-form-group
            v-if="answerType === 'text'"
            :label="$t('labels.create-problem.answer')"
            label-for="question"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="question"
              vid="question"
            >
              <b-form-input
                id="question"
                v-model="problem.answers.text[index]"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('labels.create-problem.placeholder.question')"
                name="question"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Image -->
          <b-form-group
            v-else-if="answerType === 'image'"
            :label="$t('labels.create-problem.answer')"
            :label-for="`question-${level}`"
          >
            <validation-provider
              v-slot="{ errors }"
              name="answer"
              :vid="`answers-${level}`"
            >
              <b-form-file
                :id="`answers-${level}`"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('labels.file-placeholder')"
                :browse-text="$t('browseButton')"
                accept="image/jpeg, image/png, image/gif"
                @change="handleImage($event, 'answers', index)"
              />
              <small class="text-danger">{{ errors[0] }}</small><br>
              <b-img
                v-if="problem.answers.imageUrl[index]"
                rounded
                fluid
                :src="problem.answers.imageUrl[index]"
                height="150"
                width="150"
                class="mt-2"
              />
            </validation-provider>
          </b-form-group>
          <!-- Image -->
          <!-- Audio -->
          <b-form-group
            v-else-if="answerType === 'audio'"
            :label="$t('labels.create-problem.answer')"
            label-for="audio"
          >
            <Recorder
              :url="problem.answers.audioUrl[index]"
              @input="setAudio($event, 'answers', index)"
            />
          </b-form-group>
          <!-- / Answer -->
        </b-tab>
      </b-tabs>
    </b-col>
    <template #modal-footer>
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="isProcessing || !isValid"
            @click="addQuestion"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t("confirm") }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BSpinner,
  BFormGroup,
  BButton,
  BFormInput,
  BFormFile,
  BImg,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import Recorder from '../../components/recorder.vue'

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    BFormGroup,
    BButton,
    BFormInput,
    BFormFile,
    BImg,
    BTabs,
    BTab,
    Recorder,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    toggle: {
      type: Boolean,
      default: () => false,
    },
    questionType: {
      type: String,
      default: () => '',
    },
    answerType: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    activeTabIndex: 0,
    isProcessing: false,
    levels: ['A1', 'A2', 'B1', 'B2'],
    problem: {
      questions: {
        text: ['', '', '', ''],
        image: ['', '', '', ''],
        imageUrl: ['', '', '', ''],
        audio: ['', '', '', ''],
        audioUrl: ['', '', '', ''],
      },
      answers: {
        text: ['', '', '', ''],
        image: ['', '', '', ''],
        imageUrl: ['', '', '', ''],
        audio: ['', '', '', ''],
        audioUrl: ['', '', '', ''],
      },
    },
    isValid: false,
  }),
  watch: {
    toggle(value) {
      if (value === true) {
        this.$refs['modal-add-question'].show()
      } else {
        this.$refs['modal-add-question'].hide()
      }
    },
    problem: {
      deep: true,
      handler() {
        this.validateForm()
      },
    },
  },
  methods: {
    tabChange(index) {
      const indices = [0, 1, 2, 3]
      indices.forEach(i => {
        if (i !== indices) {
          const questions = this.problem.questions[this.questionType]
          if (!questions[i]) {
            questions[i] = questions[this.activeTabIndex]
            if (this.questionType === 'image') {
              this.problem.questions.imageUrl[i] = this.problem.questions.imageUrl[this.activeTabIndex]
            } else if (this.questionType === 'audio') {
              this.problem.questions.audioUrl[i] = this.problem.questions.audioUrl[this.activeTabIndex]
            }
          }
          const answers = this.problem.answers[this.answerType]
          if (!answers[i]) {
            answers[i] = answers[this.activeTabIndex]
            if (this.answerType === 'image') {
              this.problem.answers.imageUrl[i] = this.problem.answers.imageUrl[this.activeTabIndex]
            } else if (this.answerType === 'audio') {
              this.problem.answers.audioUrl[i] = this.problem.answers.audioUrl[this.activeTabIndex]
            }
          }
        }
      })
      this.validateForm()
      this.activeTabIndex = index
    },
    handleImage(event, type, i) {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.problem[type].imageUrl[i] = reader.result
          this.problem[type].imageUrl.push('')
          this.problem[type].imageUrl.pop()
        },
        false,
      )
      if (event.target.files[0]) {
        const file = event.target.files[0]
        if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
          this.problem[type].image[i] = file
          reader.readAsDataURL(file)
        }
      }
    },
    setAudio({ audio, url }, type, i) {
      this.problem[type].audio[i] = audio
      this.problem[type].audioUrl[i] = url
      this.validateForm()
    },
    validateForm() {
      let valid = true
      this.problem.questions[this.questionType].forEach(q => {
        if (!q) valid = false
      })
      this.problem.answers[this.answerType].forEach(a => {
        if (!a) valid = false
      })
      this.isValid = valid
    },
    addQuestion() {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
      this.problem.questions[this.questionType].forEach((q, i) => {
        const quesData = new FormData()
        quesData.append('lang_level', `${i + 1}`)
        quesData.append('problem_id', this.$route.query.problem_id)
        quesData.append('ques_type', this.questionType)
        quesData.append('text', q)

        useJwt.addQuestion(quesData, config).then(res => {
          const reqs = []
          const ansData = new FormData()
          ansData.append('lang_level', `${i + 1}`)
          ansData.append('problem_id', this.$route.query.problem_id)
          ansData.append('question_id', res.data.data.id)
          ansData.append('ans_type', this.answerType)
          ansData.append('text', this.problem.answers[this.answerType][i])
          ansData.append('is_correct', '1')
          reqs.push(useJwt.addAnswer(ansData, config))

          Promise.all(reqs)
            .then(response => {
              if (response.length && i === 3) {
                this.showSuccessMessage(response[0])
              }
            })
            .catch(error => {
              this.showErrorMessage(error)
            })
            .finally(() => {
              if (i === 3) {
                this.$emit('close')
                this.$emit('fetch')
              }
            })
        })
      })
    },
  },
}
</script>
