<template>
  <div>
    <VisibilityManager
      :options-local="optionsLocal"
      :child-users="childUsers"
      :current-user-type="currentUserType"
      :modal-info="modalInfo"
      title="Student Dashboard"
    />
  </div>
</template>
<script setup>
import i18n from '@/libs/i18n'
import VisibilityManager from './components/VisibilityManager.vue'

defineProps({
  optionsLocal: {
    type: Object,
    required: true,
  },
  childUsers: {
    type: Array,
    required: true,
  },
  currentUserType: {
    type: String,
    required: true,
  },
})

const modalInfo = {
  student_dashboard_greeting: {
    title: i18n.tc('setting-module.student-greeting'),
    imageSrc: '/school-settings/student-greeting.png',
    description: i18n.tc('setting-module.student-greeting'),
    steps:
        `<ul>
        <li>Login In to Student/Teacher Account</li>
        <li>You can see below navigation bar</li>
        </ul>`,
  },
  student_dashboard_stats: {
    title: i18n.tc('setting-module.student-stats'),
    imageSrc: '/school-settings/student-dashboard-statistics.png',
    description: i18n.tc('setting-module.student-stats'),
    steps:
        `<ul>
        <li>Login In to Student/Teacher Account</li>
        <li>You can see below stats</li>
        </ul>`,
  },
  student_dashboard_activity: {
    title: i18n.tc('setting-module.student-activity'),
    imageSrc: '/school-settings/student-dashboard-activity.png',
    description: i18n.tc('setting-module.student-activity'),
    steps:
        `<ul>
        <li>Login In to Student/Teacher Account</li>
        <li>You can see the student activity section</li>
        </ul>`,
  },
  student_dashboard_calendar: {
    title: i18n.tc('setting-module.student-calendar'),
    imageSrc: '/school-settings/student-dashboard-event.png',
    description: i18n.tc('setting-module.student-calendar'),
    steps:
        `<ul>
        <li>Login In to Student/Teacher Account</li>
        <li>You can see the calendar section</li>
        </ul>`,
  },
  student_dashboard_time_on: {
    title: i18n.tc('setting-module.student-time-on'),
    imageSrc: '/school-settings/student-dashboard-timeon.png',
    description: i18n.tc('setting-module.student-time-on'),
    steps:
        `<ul>
        <li>Login In to Student/Teacher Account</li>
        <li>You can see the time-on section</li>
        </ul>`,
  },
  student_dashboard_hour_tracked: {
    title: i18n.tc('setting-module.student-hour-tracked'),
    imageSrc: '/school-settings/student-dashboard-hourtracked.png',
    description: i18n.tc('setting-module.student-hour-tracked'),
    steps:
        `<ul>
        <li>Login In to Student/Teacher Account</li>
        <li>You can see the hour tracked section</li>
        </ul>`,
  },
  student_dashboard_rubrics: {
    title: i18n.tc('setting-module.student-rubrics'),
    imageSrc: '/school-settings/student-dashboard-rubric.png',
    description: i18n.tc('setting-module.student-rubrics'),
    steps:
        `<ul>
        <li>Login In to Student/Teacher Account</li>
        <li>You can see the rubrics section</li>
        </ul>`,
  },
}
</script>
