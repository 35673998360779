<template>
  <b-col
    order="1"
    order-lg="2"
  >
    <div
      v-for="data in posts.map(p => { delete p['page_link']; return p})"
      :key="data.id"
      :class="`article-theme-${data.theme}`"
    >
      <b-card>
        <div class="article-head d-flex justify-content-between align-items-center p-1">
          <div class="profile-user-info">
            <h6>
              {{ data.title }}
            </h6>
            <small class="text-muted">{{ data.updateAt }}</small>
          </div>

          <div class="pr-0">
            <b-badge
              v-if="data.is_featured"
              pill
              variant="success"
            >
              {{ $t('actions.featured') }}
            </b-badge>
            <b-dropdown
              v-if="ownProfile"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              class="pr-0"
              right
            >
              <template
                v-if="ownProfile"
                #button-content
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-left vertical-icon"
                />
              </template>
              <b-dropdown-item @click="$emit('editArticle', data)">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>{{ $t('actions.edit-article') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="$emit('deleteArticle', data)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>{{ $t('actions.delete-article') }}</span>
              </b-dropdown-item>
              <b-col class="text-center pt-1">
                <b-form-checkbox
                  :checked="data.is_featured"
                  name="featured-article"
                  inline
                  switch
                  size="16"
                  @change="$emit('changeFeatured',data)"
                >
                  <span style="font-size:14px; color:#6e6b7b;"> {{ $t('actions.featured') }} </span>
                </b-form-checkbox>
              </b-col>
            </b-dropdown>
          </div>
        </div>

        <div class="article-body p-1">
          <!-- post img -->
          <b-img
            v-if="data.img && data.video"
            fluid
            rounded
            class="mb-25"
            :src="data.img"
          />
          <!--/ post img -->
          <b-img
            v-else-if="data.img"
            fluid
            rounded
            class="mb-25"
            :src="data.img"
          />
          <!-- post video -->
          <b-embed
            v-else-if="data.video"
            type="iframe"
            :src="data.video"
            allowfullscreen
            class="rounded mb-50"
          />
          <!--/ post video -->
          <b-card-text
            v-if="data.isEssay"
          >
            <span
              class="essay-description"
              v-html="data.description"
            />
            <span
              class="text-primary see-more font-weight-bold"
              @click="articleIdToShow = data.id"
            >See More</span>
          </b-card-text>

          <b-card-text
            v-else-if="data.description && data.description !== 'null'"
            class="pt-1"
            v-html="data.description"
          />
          <statement
            :statement="{
              template:data.template,
            }"
            :show-level="false"
            :show-before-text="false"
            :problem-statement-id="{}"
          />
        </div>
      </b-card>
    </div>
    <lesson-essay-modal
      :show="!!articleIdToShow"
      :article-id="Number(articleIdToShow)"
      @close="articleIdToShow = null"
    />
  </b-col>

</template>

<script>
import {
  BCard,
  BImg,
  BCol,
  VBTooltip,
  BEmbed,
  BDropdown,
  BDropdownItem,
  BCardText,
  BFormCheckbox,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import LessonEssayModal from '@/views/common/class/components/LessonEssayModal.vue'
import Statement from '../../problem/compoments/Statement.vue'

export default {
  components: {
    BCard,
    BDropdown,
    BDropdownItem,
    BImg,
    BCol,
    BEmbed,
    BCardText,
    Statement,
    BFormCheckbox,
    BBadge,
    LessonEssayModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    posts: {
      type: Array,
      default: () => [],
    },
    ownProfile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      articleIdToShow: null,
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
@import '/src/assets/scss/custom/article-themes/theme.scss';
.vertical-icon{
  margin-right: -20px;
}
.card-body {
  padding: 0;
}
.problem-header-top {
  a {
    display: none;
  }
}
.essay-description {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.see-more:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
