// eslint-disable-next-line import/no-cycle
import { $themeBreakpoints } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'
import { LANG_LEVEL_INDICATOR } from '@/const/langLevel'

let partnerCompanyInfo
try {
  partnerCompanyInfo = JSON.parse(localStorage.getItem('partnerInformation'))
} catch {
  partnerCompanyInfo = null
}

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    partnerCompanyInfo,
    companyLogo: partnerCompanyInfo?.logo || '/img/logo.023152ca.svg',
    languageLevelIndicator: LANG_LEVEL_INDICATOR,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  actions: {
    async getLanguageLevelByCourse({ commit }, payload) {
      const response = await useJwt.getLanguageLevelByCourse({
        params: {
          ...payload,
        },
      })
      if (response.data.data.indicator) {
        commit('SET_LANGUAGE_LEVEL_INDICATOR', response.data.data.indicator)
      } else {
        commit('SET_LANGUAGE_LEVEL_INDICATOR', LANG_LEVEL_INDICATOR)
      }
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_COMPANY_INFORMATION(state) {
      if (state.partnerCompanyInfo) {
        localStorage.setItem('partnerInformation', JSON.stringify(state.partnerCompanyInfo))
      }
    },
    SET_COMPANY_LOGO(state, val) {
      state.companyLogo = val.logo
      state.partnerCompanyInfo = val
    },
    SET_LANGUAGE_LEVEL_INDICATOR(state, val) {
      if (val) {
        state.languageLevelIndicator = val
      } else {
        state.languageLevelIndicator = LANG_LEVEL_INDICATOR
      }
    },
  },
}
