const timezones = [
  {
    zone: 'America/New_York',
    city: 'New York, USA',
  },
  {
    zone: 'Africa/Cairo',
    city: 'Cairo, Egypt',
  },
  {
    zone: 'Europe/London',
    city: 'London, UK',
  },
  {
    zone: 'Asia/Tokyo',
    city: 'Tokyo, Japan',
  },
  {
    zone: 'Australia/Sydney',
    city: 'Sydney, Australia',
  },
  {
    zone: 'Asia/Kathmandu',
    city: 'Kathmandu, Nepal',
  },
  {
    zone: 'Asia/Kolkata',
    city: 'Kolkata, India',
  },
  {
    zone: 'Asia/Bangkok',
    city: 'Bangkok, Thailand',
  },
  {
    zone: 'Asia/Seoul',
    city: 'Seoul, South Korea',
  },
  {
    zone: 'Asia/Ho_Chi_Minh',
    city: 'Ho Chi Minh, Vietnam',
  },
]

export default timezones
