<template>
  <div v-if="algorithm!==null">
    <create-edit
      mode="edit"
      :algorithm="algorithm"
    />
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import CreateEdit from './components/CreateEdit.vue'

export default {
  components: { CreateEdit },
  data() {
    return {
      isProcessing: false,
      algorithm: null,
    }
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.initiate()
      }
    },
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      const id = parseInt(this.$route.params.id, 10)
      this.getAlgorithm(id)
    },
    getAlgorithm(id) {
      useJwt.getLearningAlgorithm(id).then(res => {
        this.algorithm = res.data.data
      })
    },
  },
}
</script>

<style>

</style>
