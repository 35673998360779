var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.point)?_c('div',[_c('div',{class:`point ${
      !_vm.point.statement_visited && ' clickable'
    }`,style:(`top: ${_vm.point.coordinates.y * 100}%; left: ${
      _vm.point.coordinates.x * 100
    }%;
    background-color: ${_vm.point.feedback_visited && (_vm.isAnswerCorrect ? 'green' : 'red')};
    cursor: pointer;
    `),on:{"click":function($event){return _vm.checkPoints(_vm.point)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.point.statement_visited ? (!_vm.isAnswerCorrect && _vm.point.feedback_visited ? 'X' : '✓') : (_vm.point.matchedPointIndex ? '-' : '+'))+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.point.matchedPointIndex),expression:"point.matchedPointIndex"}],style:(`top: ${_vm.point.coordinates.y * 100}%; left: calc(${
      _vm.point.coordinates.x < 0.7
        ? _vm.point.coordinates.x * 100 + 1 + '%'
        : _vm.point.coordinates.x * 100 +
          '%' +
          ' - ' +
          (_vm.tooltipWidth + 25) +
          'px'
    }); position:absolute;`)},[_c('div',{class:`tooltiptext p-1 ${
        _vm.point.coordinates.x < 0.7 ? 'tooltip-left' : 'tooltip-right'
      }`,style:(`width:${_vm.tooltipWidth}px;background-color: #00000054`)},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',{directives:[{name:"text-to-speech",rawName:"v-text-to-speech"}],staticStyle:{"color":"white"},domProps:{"innerHTML":_vm._s(_vm.point.hotspot.statement)}}),(_vm.point.hotspot.statement_audio)?_c('AudioPlayer',{key:_vm.point.hotspot.statement_audio,attrs:{"src":_vm.point.hotspot.statement_audio}}):_vm._e()],1),_c('div',{staticClass:"mt-1"},[_c('v-select',{attrs:{"options":_vm.answers,"label":"text","multiple":"","reduce":i => i.value},on:{"input":_vm.onSelect},model:{value:(_vm.selectedAnswers),callback:function ($$v) {_vm.selectedAnswers=$$v},expression:"selectedAnswers"}})],1),(_vm.point.feedback_visited)?_c('div',{staticClass:"mt-1"},[_c('span',{directives:[{name:"text-to-speech",rawName:"v-text-to-speech"}],staticStyle:{"color":"white"},domProps:{"innerHTML":_vm._s(_vm.point.hotspot.feedback)}})]):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }