<template>
  <b-modal
    id="create-template"
    :ref="name"
    v-model="isVisible"
    ok-only
    ok-variant="outline-secondary"
    :ok-title="$t('close')"
    modal-class="modal-primary"
    centered
    :title="title"
    :size="'xl'"
    :no-close-on-backdrop="true"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-tabs pills>
      <b-tab title="Template 1">
        <TemplateRoot :template="template"
                      :initial-template-options="initialTemplateOptions"
                      :avoid-save="avoidSave"
                      :avoid-delete="true"
                      :is-statement="isAStatement"
                      :extra-template-para="extraTemplatePara"
                      :problem-type="problemType"
                      @templateStored="value => $emit('templateStored', value)"
        />
      </b-tab>
      <template v-if="canHaveMultipleTemplate">
        <b-tab v-for="child,index of template.child_templates"
               :key="index"
               :title="`Template ${index+2}`"
        >
          <TemplateRoot :template="child"
                        :initial-template-options="initialTemplateOptions"
                        :avoid-save="avoidSave"
                        :avoid-delete="false"
                        :extra-template-para="{...extraTemplatePara, parent_id: template.id }"
                        :problem-type="problemType"
                        @removeTemplate="removeTemplate(child)"
                        @updateTemplateStored="template => handleTempleStored(template, index)"
          />
        </b-tab>
      </template>

      <template v-if="canHaveMultipleTemplate && !isAStatement"
                #tabs-end
      >
        <b-button
          variant="flat-primary"
          class="btn-icon"
          @click="addNewTemplate"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </template>
    </b-tabs>
  </b-modal>
</template>

<script>
import {
  VBToggle,
  BTabs,
  BTab,
  BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import TemplateRoot from './components/TemplateRoot.vue'

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    TemplateRoot,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      default: 'Create Template',
    },
    title: {
      type: String,
      default: 'Create Template',
    },
    template: {
      type: Object,
      default: null,
    },
    initialTemplateOptions: {
      type: Array,
      default: null,
    },
    avoidSave: {
      type: Boolean,
      default: false,
    },
    extraTemplatePara: {
      type: Object,
      default: null,
    },
    problemType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isVisible: this.show,
      initiallySelectedTemplate: null,
    }
  },
  computed: {
    isAStatement() {
      return this.title?.includes('statement')
    },
    canHaveMultipleTemplate() {
      return this.template && this.template.child_templates
    },
  },
  watch: {
    show() {
      this.isVisible = this.show

      // this is done to remove the tabindex attr
      // this attr makes some elements of ckeditor unfocusable

      if (this.isVisible) {
        setTimeout(() => {
          document.querySelector('.modal-content').removeAttribute('tabindex')
        }, 300)
      }
    },
    template() {
      if (this.template) {
        this.initiallySelectedTemplate = this.template.type
      }
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleTempleStored(templateData, index) {
      this.template.child_templates[index] = { ...templateData }
      this.$forceUpdate()
    },
    addNewTemplate() {
      if (this.template) {
        this.template.child_templates.push({
          template: null,
        })
        this.$forceUpdate()
      }
    },
    removeTemplate(template) {
      if (template && template.id) {
        this.$swal.fire({
          title: 'Are you sure you want to delete?',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Yes, Delete',
          denyButtonText: 'Don\'t save',
        }).then(result => {
          if (result.isConfirmed) {
            useJwt.removeStatementFeedBackTemplate(template.id).then(() => {
              this.template.child_templates = this.template.child_templates.filter(item => item.id !== template.id)
              this.$forceUpdate()
            }).catch(error => {
              this.showErrorMessage(error)
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import "/src/assets/scss/custom/problem-themes/main.scss";
</style>

<style lang="scss">
.html-code-card {
  .card-body {
    padding: 0 !important;
  }
  .card-header {
    padding: 0 !important;
  }
}
#create-template .modal-title {
  text-transform: capitalize !important;
}
</style>
