<template>
  <b-modal
    :id="modalId"
    :title="$t('labels.badges-information')"
    size="lg"
    ok-title="Close"
    ok-only
  >
    <b-row>
      <b-col
        v-for="badge, index in badges"
        :key="index"
        lg="6"
      >
        <b-card>
          <b-media class="d-flex align-items-center">
            <template #aside>
              <img
                :src="require(`@/assets/images/${badgesIcons[badge.icon]}`)"
                width="72px"
                height="auto"
              >
            </template>
            <h4 class="mt-0">
              {{ $t(`class-module.${badge.name}`) }}
            </h4>
            <p>{{ $t(`class-module.${badge.description}`) }}</p>
          </b-media>
        </b-card>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal, BCard, BMedia, BCol, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BModal, BCard, BMedia, BCol, BRow,
  },
  props: {
    badgesIcons: {
      type: Object,
      default: () => {},
    },
    modalId: {
      type: String,
      default: 'badges-info',
    },
  },
  data() {
    return {
      badges: [
        {
          name: 'highest-points-gainer',
          icon: 'highestPointGainerBadge',
          description: 'highest-points-description',
        },
        {
          name: 'first-lesson-achiever',
          icon: 'firstLessonAchieverBadge',
          description: 'first-lesson-description',
        },
        {
          name: 'most-lessons-achiever',
          icon: 'extraOrdinaryBadge',
          description: 'most-lessons-description',
        },
      ],
    }
  },
}
</script>

<style scoped>
  .dark-layout .card {
    background-color: #2f3851;
  }
</style>
