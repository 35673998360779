<template>
  <div>
    <template
      v-for="item of requiredRanks"
    >
      <h5 :key="item.value+'1'">
        {{ item.text }}
      </h5>
      <b-row
        :key="item.value"
        class="mb-2 mt-2"
        :set="rank = getPersonalityRank(item.value)"
      >
        <b-col class="d-flex">
          <b-form-checkbox
            :checked=" rank[0].checked"
            disabled
          />  {{ rank[0].name }}
        </b-col>
        <b-col class="d-flex">
          <b-form-checkbox
            :checked=" rank[1].checked"
            disabled
          /> {{ rank[1].name }}
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<script>
import { BRow, BCol, BFormCheckbox } from 'bootstrap-vue'
import { getPersonalityRank } from '@/utils/personality-rank'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
  },
  data() {
    return {
      requiredRanks: [
        {
          text: 'Extraversion',
          value: 'extra_version',
        },
        {
          text: 'Anxiety',
          value: 'anxiety',
        },
        {
          text: 'Tough Minded',
          value: 'tough_minded',
        },
        {
          text: 'Independence',
          value: 'independence',
        },
        {
          text: 'Self Control',
          value: 'self_control',
        },
      ],
      studentPersonality: [],
    }
  },
  created() {
    this.getAllPersonalityRanks()
  },
  methods: {
    getPersonalityRank(key) {
      return getPersonalityRank(this.studentPersonality, key)
    },
    handleOverallPersonality(allPersonalities) {
      // get categories
      let categories = []
      allPersonalities.forEach(person => {
        categories = [...categories, ...person?.personalities?.map(type => type.category) || []]
      })
      categories = [...new Set(categories)]
      // evaluate by categories
      const obtainedPersonality = []
      categories.forEach(category => {
        let behaviors = []
        allPersonalities.forEach(person => {
          behaviors = [...behaviors, ...person?.personalities?.filter(type => type.category === category).map(type => type.name) || []]
        })
        if (behaviors.length) {
          obtainedPersonality.push(this.arrayMode(behaviors))
        }
      })
      this.studentPersonality = obtainedPersonality
    },
    getAllPersonalityRanks() {
      useJwt.getPersonalityByEvaluatedBy(this.AUTH_USER().id, 'all', {
        params: {
          withPersonality: true,
        },
      }).then(response => {
        const allPersonalities = response.data.data
        this.handleOverallPersonality(allPersonalities)
      })
    },
  },
}
</script>
