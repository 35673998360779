<template>
  <div>
    <b-card :show="isProcessing"
            class="text-center"
    >
      <b-spinner large />
    </b-card>
  </div>
</template>
<script>
import { BCard, BSpinner } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BSpinner,
  },
  data() {
    return {
      isProcessing: false,
    }
  },
  created() {
    this.verifyPayment()
  },
  methods: {
    verifyPayment() {
      useJwt.reservationSchedulePaymentSuccess({
        refId: this.$route.query.refId,
      }).then(response => {
        this.showSuccessMessage(response)
        if (response.data.data.new_class) {
          this.$router.push({
            name: 'student-view-students',
            params: {
              id: response.data.data.new_class.id,
            },
          })
        } else {
          this.$swal({
            title: response.data.data.message,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            showClass: {
              popup: 'animate__animated animate__shakeX',
            },
            buttonsStyling: false,
          })
          this.$emit('close')
        }
        window.open('/', '_self')
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
  },
}

</script>
