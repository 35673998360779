<template>
  <div class="class-table">
    <b-card
      no-body
      class="mb-0"
    >
      <h5 class="p-1 pb-0">
        {{ $t("class-module.list-of-classes") }}
        <span class="ml-1">
          <b-badge
            pill
            variant="primary"
          >{{ classes.length }}</b-badge>
        </span>
      </h5>

      <div
        v-if="filteredClasses.length > 0"
        class="table-responsive p-0 m-0"
      >
        <b-table-simple
          role="table"
          class="table b-table"
        >
          <thead
            role="rowgroup"
          >
            <tr
              role="row"
            >
              <th
                v-for="column in columnsForClassesTable"
                :key="column.label"
                role="columnheader"
                scope="col"
                aria-colindex="1"
                :class="column.thClass"
                :style="`width:${column.width ? column.width:'auto'}`"
              >
                <div>{{ column.label }}</div>
              </th>
            </tr>
          </thead>
          <draggable
            v-model="filteredClasses"
            tag="tbody"
            :options="{animation:300,}"
            handle=".classes-handle"
            @change="updateOrder('class_rooms')"
          >
            <tr
              v-for="(row, i) in filteredClasses"
              :key="row.name"
              role="row"
              :class="activeClass(row)"
              style="cursor:pointer"
              @click="onClassRowClick(row)"
            >
              <td
                v-for="column in columnsForClassesTable"
                :key="'row_'+column.label"
                role="cell"
              >
                <span
                  v-if="column.field === '#'"
                  class="d-flex"
                >
                  <feather-icon
                    icon="AlignJustifyIcon"
                    size="16"
                    class="text-body align-middle m-0 p-0 classes-handle mr-2"
                  />
                </span>
                <span
                  v-if="column.field === 'name'"
                  class="d-flex"
                >
                  {{ row.name }}
                </span>
                <span
                  v-if="column.field === 'course_name'"
                  class="d-flex"
                >
                  <div v-if="row.course_name">
                    <b-button
                      size="sm"
                      style="padding:6px;font-size:10px"
                      class="d-inline-block"
                      variant="primary"
                      @click="(isAStudent || isATeacher) ? viewLessons(row.course_id,row.id):null"
                    >
                      {{ row.course_name }}
                    </b-button>
                  </div>
                  <div v-else-if="row.courses">
                    <div
                      v-for="(course,index) in row.courses.filter(c => c != null)"
                      :key="course.name"
                    >
                      <b-button
                        v-if="index<2"
                        :style="`padding:6px;font-size:10px;margin-bottom:2px`"
                        size="sm"
                        variant="primary"
                        @click="(isAStudent || isATeacher) ? viewLessons(course.id,row.id):null"
                      >
                        {{ course.name }}
                      </b-button>
                    </div>
                    <b-button
                      v-if="row.courses && row.courses.length>2"
                      :style="`padding:6px;font-size:10px;`"
                      size="sm"
                      variant="secondary"
                      @click="$emit('onShowAllCoursesOf',{...{class_id:row.id},...row})"
                    >
                      {{ $t('class-module.view-all') }}
                    </b-button>
                  </div>
                </span>
                <span
                  v-if="column.field === 'owner'"
                  class="d-flex"
                >
                  {{ row.owner === self.fullname ? $t("you") : row.owner }}
                </span>
                <span
                  v-if="column.field === 'teacher_name'"
                  class="d-flex"
                >
                  {{
                    row.teacher_name === self.fullname
                      ? $t("you")
                      : row.teacher_name
                  }}
                </span>
                <span
                  v-else-if="column.field === 'action'"
                  class="d-flex align-items-center"
                >
                  <div>
                    <span>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-delete-user
                        :disabled="isProcessing"
                        variant="outline-primary"
                        class="btn-icon"
                        style="margin: 2px"
                        @click.stop="$emit('onShowDetails', row)"
                      >
                        <feather-icon icon="EyeIcon" />
                      </b-button>
                      <b-button
                        v-if="isATeacher"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-delete-user
                        :disabled="isProcessing"
                        variant="outline-primary"
                        class="btn-icon"
                        style="margin: 2px"
                        @click.stop="$emit('onEditLink', row.id, i)"
                      >
                        <feather-icon icon="LinkIcon" />
                      </b-button>
                      <b-button
                        v-if="isATeacher"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        :disabled="isProcessing"
                        variant="outline-primary"
                        class="btn-icon"
                        style="margin: 2px"
                        @click.stop="$emit('onOpenCalendar', row.id, i)"
                      >
                        <feather-icon icon="CalendarIcon" />
                      </b-button>
                    </span>
                    <span v-if="self.id === row.created_by">
                      <span>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          :disabled="isProcessing"
                          variant="outline-primary"
                          class="btn-icon"
                          style="margin: 2px"
                          @click.stop="$emit('onClassEdit', row.id, i)"
                        >
                          <feather-icon icon="EditIcon" />
                        </b-button>
                      </span>
                      <span>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-modal.modal-delete-user
                          :disabled="isProcessing"
                          variant="outline-danger"
                          class="btn-icon"
                          style="margin: 2px"
                          @click.stop="$emit('onClassDelete', i)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </span>
                    </span>
                  </div>
                </span>
              </td>
            </tr>
          </draggable>
        </b-table-simple>
      </div>
      <p
        v-else
        class="px-1"
      >
        {{ $t('class-module.no-classes') }}
      </p>
    </b-card>
  </div>

</template>
<script>
import {
  BCard, BButton, BBadge, BTableSimple,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import {
  USER_TYPE_TEACHER,
  USER_TYPE_STUDENT,
  USER_TYPE_PARENT,
} from '@/const/userType'
import useJwt from '@/auth/jwt/useJwt'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'
import i18n from '@/libs/i18n'

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    draggable,
    BTableSimple,
  },
  directives: {
    Ripple,
  },
  props: {
    classes: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columnsForClassesTable: [
        { key: '#', label: '#', field: '#' },
        {
          key: 'name', field: 'name', label: i18n.tc('class-module.class-table.name'), searchable: true,
        },
        {
          key: 'course_name',
          field: 'course_name',
          label: i18n.tc('class-module.class-table.course'),
        },
        {
          key: 'owner', field: 'owner', label: i18n.tc('class-module.class-table.owner'), searchable: true,
        },
        {
          key: 'teacher_name',
          field: 'teacher_name',
          searchable: true,
          label: i18n.tc('class-module.class-table.teacher'),
        },
        {
          key: 'action',
          field: 'action',
          label: i18n.tc('class-module.class-table.actions'),
          thStyle: { minWidth: '230px' },
        },
      ],
      filteredClasses: [],
      selectedClass: null,
      self: getUserData(),
    }
  },
  computed: {
    isAStudent() {
      return getUserData().usertype === USER_TYPE_STUDENT
    },
    isAParent() {
      return getUserData().usertype === USER_TYPE_PARENT
    },
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
  },
  watch: {
    classes() {
      this.filteredClasses = this.classes
      this.searchTable()
    },
    search() {
      this.searchTable()
    },
  },
  methods: {
    viewLessons(id, classId) {
      this.$router.push({
        name: `${this.self.usertype}-lessons`,
        params: { id, classId },
      })
    },
    activeClass(item) {
      if (!this.selectedClass) return ''
      if (item.id === this.selectedClass.id) return 'active-column'
      return ''
    },
    onClassRowClick(params) {
      this.selectedClass = params
      this.$emit('onClassSelected', params)
    },
    updateOrder(type) {
      const input = this.filteredClasses.map((e, i) => ({ id: e.id, order_index: i + 1 }))
      useJwt.updateOrder({
        model: type,
        input,
      })
    },
    searchTable() {
      let matchingClasses = []
      if (this.search.length > 0) {
        const columns = this.columnsForClassesTable.filter(e => e.searchable)
        for (let index = 0; index < this.classes.length; index += 1) {
          for (let colIndex = 0; colIndex < columns.length; colIndex += 1) {
            const classe = this.classes[index]
            const value = classe[columns[colIndex].key].toString()
            if (value && value.toLowerCase().includes(this.search.toLowerCase())) {
              matchingClasses.push(classe)
              // eslint-disable-next-line no-continue
              break
            }
          }
        }
      } else {
        matchingClasses = this.classes
      }

      this.filteredClasses = matchingClasses
    },
  },
}
</script>

<style>
.class-table .active-column {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.class-table tr {
  cursor: pointer;
}
.class-table {
  margin-bottom: 0;
}
.classes-handle {
  cursor: grab;
}
</style>
