var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenshot d-flex flex-column mt-1"},[_c('div',{staticClass:"time-indicator w-100",class:{
      'rounded-left': !_vm.sectionData.hasPrevious,
      'rounded-right': !_vm.sectionData.hasNext,
    },style:(`background:${
      _vm.sectionData.data ? '#387b46' : 'transparent'
    };height:24px`)},[(_vm.sectionData.data && !_vm.sectionData.hasPrevious)?_c('div',{staticClass:"d-flex h-100 text-white ml-1 align-items-center"},[_vm._v(" "+_vm._s((_vm.sectionData.totalNext + 1) * 10)+" "+_vm._s(_vm.$t("student-diary-module.minutes"))+" ")]):_vm._e()]),_c('div',{staticClass:"screenshot-image w-100"},[(_vm.sectionData.data)?_c('a',{staticClass:"w-100 h-100",attrs:{"target":"_"}},[_c('img',{attrs:{"src":_vm.sectionData.data.screenshot,"alt":""},on:{"click":function($event){return _vm.openImage(_vm.sectionData.data.screenshot)}}})]):_c('div',{staticClass:"w-100 no-screenshot h-100 d-flex align-items-center justify-content-center"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("student-diary-module.idle-time"))+" ")])])]),(_vm.sectionData.data)?_c('div',{staticClass:"stats w-100"},[_c('div',{staticClass:"d-flex align-items-center stats-item"},[_c('span',{staticClass:"stats-title"},[_vm._v(_vm._s(_vm.$t('report.activity')))]),_c('div',{staticClass:"w-100"},[_c('p',{staticClass:"m-0 p-0"},[_vm._v(" "+_vm._s(_vm.sectionData.data.avg_activity)+" ")])])]),(!_vm.hideAttention)?_c('div',{staticClass:"d-flex align-items-center stats-item"},[_c('span',{staticClass:"stats-title"},[_vm._v(_vm._s(_vm.$t('report.attention')))]),_c('div',{staticClass:"progress w-100",staticStyle:{"height":"10px","color":"rgb(229, 71, 50)"}},[_c('div',{staticClass:"progress-bar",style:(`background-color:${_vm.getStatusColor(
            _vm.sectionData.data.avg_attention
          )};width:${_vm.sectionData.data.avg_attention}%`),attrs:{"role":"progressbar"}})])]):_vm._e(),(_vm.showLangLevel)?_c('div',{staticClass:"d-flex align-items-center stats-item"},[_c('span',{staticClass:"stats-title"},[_vm._v(_vm._s(_vm.$t('report.level')))]),_c('div',[_c('b-button-group',{attrs:{"size":"sm"}},[(_vm.distLangLevel)?_vm._l((_vm.distLangLevel),function(distlang,distIndex){return _c('div',{key:distIndex,staticClass:"btn btn-outline-primary",class:{ active: _vm.getLevelFromScore(_vm.sectionData.data.cerf_level) === distIndex },attrs:{"type":"button"}},[_vm._v(" "+_vm._s(_vm.distLangLevel[distIndex].label)+" ")])}):_vm._l((_vm.cefrLangLevel),function(lang,langIndex){return _c('div',{key:langIndex,staticClass:"btn btn-outline-primary",class:{ active: _vm.getLevelFromScore(_vm.sectionData.data.cerf_level) === langIndex },attrs:{"type":"button"}},[_vm._v(" "+_vm._s(lang.label)+" ")])})],2)],1)]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center stats-item"},[_c('span',{staticClass:"stats-title"},[_vm._v(_vm._s(_vm.$t('report.score')))]),_c('p',{staticClass:"m-0 p-0"},[_vm._v(" "+_vm._s(_vm.sectionData.data.avg_score)+"% ")])])]):_c('div',{staticStyle:{"width":"214.94px"}}),_c('FsLightbox',{attrs:{"toggler":_vm.toggler,"sources":_vm.screenCasts,"slide":_vm.slide,"types":[...new Array(_vm.screenCasts.length).fill('image')]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }