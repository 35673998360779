<template>
  <b-modal
    id="rules-settings"
    :title="$t('points-setting-module.edit-reward-rules')"
    size="xl"
    :ok-title="id ? $t('actions.update') : $t('actions.create')"
    :cancel-title="$t('labels.cancel')"
    centered
    @ok="handleSubmit"
    @show="getRewardRules"
  >
    <b-overlay :show="isLoading">
      <ckeditor v-model="editorData" />
      <span class="small text-danger">{{ errors['rules'] }}</span>
    </b-overlay>
  </b-modal>
</template>

<script>
import { BModal, BOverlay } from 'bootstrap-vue'
import CKEditor from 'ckeditor4-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BModal,
    BOverlay,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      isLoading: false,
      id: null,
      editorData: '',
      errors: {},
    }
  },
  methods: {
    handleSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.errors = {}
      if (!this.editorData.length) {
        this.errors.rules = 'Please enter the rules.'
      }

      if (!Object.keys(this.errors).length) {
        if (this.id) this.updateRules()
        else this.storeRules()
      }
    },
    getRewardRules() {
      this.isLoading = true
      useJwt.getRulesSetting().then(response => {
        const res = response.data?.data
        if (res) {
          this.editorData = res.value
          this.id = res.id
        }
      }).finally(() => { this.isLoading = false })
    },
    storeRules() {
      useJwt.storeRulesSetting({
        key: 'reward_rules',
        value: this.editorData,
      }).then(response => {
        this.showSuccess(response.data.message)
      }).finally(() => { this.$bvModal.hide('rules-settings') })
    },
    updateRules() {
      useJwt.updateRulesSetting({
        id: this.id,
        value: this.editorData,
        _method: 'put',
      }).then(response => {
        this.showSuccess(response.data.message)
      }).finally(() => { this.$bvModal.hide('rules-settings') })
    },
  },
}
</script>
