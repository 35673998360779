import * as userType from '@/const/userType'

import i18n from '@/libs/i18n'

const menu = {}
menu[userType.USER_TYPE_SUPER] = [
  // {
  //   path: '/super/library',
  //   name: 'super-library',
  //   meta: {
  //     middleware: [isAuthenticated, adminUsersOnly],
  //   },
  //   component: () => import('@/views/super/library/index.vue'),
  // },
  {
    title: i18n.t('navigation-menu.users'),
    route: 'super-users',
    icon: 'UsersIcon',
  },
  {
    title: i18n.t('navigation-menu.classrooms'),
    route: 'super-classes',
    icon: 'HomeIcon',
  },
  {
    title: i18n.t('navigation-menu.builder'),
    icon: 'LayersIcon',
    children: [
      {
        title: i18n.t('navigation-menu.library'),
        route: 'super-library',
        icon: 'BookIcon',
      },
      {
        title: i18n.tc('navigation-menu.curriculum-builder'),
        route: 'curriculum-builder',
        icon: 'ClipboardIcon',
      },
      {
        title: i18n.t('navigation-menu.lessons'),
        route: 'super-lessonPages',
        icon: 'BookOpenIcon',
      },
      {
        title: i18n.t('navigation-menu.image-library'),
        route: 'super-image-library',
        icon: 'ImageIcon',
      },
      {
        title: 'Problem Template',
        route: 'super-problem-template',
        icon: 'SlackIcon',
      },
      {
        title: i18n.t('navigation-menu.error-reports'),
        icon: 'AlertTriangleIcon',
        route: 'super-error-reports',
      },
      {
        title: i18n.t('navigation-menu.scorm'),
        route: 'super-scorm-manager',
        icon: 'SlackIcon',
      },
      {
        title: i18n.t('navigation-menu.q-capture'),
        route: 'super-ocr',
        icon: 'MaximizeIcon',
      },
    ],
  },
  {
    title: i18n.t('navigation-menu.settings'),
    icon: 'SettingsIcon',
    children: [
    //   {
    //     title: i18n.tc('navigation-menu.game-list'),
    //     route: 'game-lists',
    //     icon: 'ClockIcon',
    //   },
      {
        title: i18n.t('navigation-menu.role-permission'),
        route: 'super-permissions',
        icon: 'KeyIcon',
      },
      {
        title: 'Calibration Setting',
        route: 'calibration-setting',
        icon: 'CodesandboxIcon',
      },
      {
        title: i18n.tc('navigation-menu.game-settings'),
        route: 'game-settings',
        icon: 'SlidersIcon',
      },
      {
        title: i18n.tc('navigation-menu.adaptive-learning'),
        route: 'super-adaptive-learning',
        icon: 'ActivityIcon',
      },
      {
        title: i18n.t('navigation-menu.global-settings'),
        route: 'global-settings',
        icon: 'GlobeIcon',
      },
      {
        title: 'Lang Calc',
        icon: 'CodeIcon',
        route: 'super-lang-score-calculator',
      },
    ],
  },
  {
    title: i18n.t('navigation-menu.engagements'),
    type: 'engagements',
    icon: 'EyeIcon',
    children: [
      {
        title: i18n.tc('navigation-menu.animated-items'),
        route: 'animated-items',
        icon: 'PackageIcon',
      },
      {
        title: i18n.tc('help-videos-moduel.help-video'),
        route: 'super-help-videos-list',
        icon: 'VideoIcon',
      },
      {
        title: i18n.t('navigation-menu.rewards'),
        route: 'super-points-setting',
        icon: 'AwardIcon',
      },
      {
        title: i18n.tc('navigation-menu.networks'),
        route: 'super-networks',
        icon: 'Share2Icon',
      },
      {
        title: i18n.t('navigation-menu.personalities'),
        icon: 'UserCheckIcon',
        route: 'super-personality',
      },
    ],
  },
  {
    title: i18n.t('navigation-menu.partner-services'),
    icon: 'UsersIcon',
    children: [
      {
        title: i18n.tc('navigation-menu.marketplace'),
        route: 'super-marketplace',
        icon: 'ShoppingBagIcon',
      },
      {
        title: 'Thirdparty Accounts',
        route: 'super-thirdparty-accounts-list',
        icon: 'UsersIcon',
      },
      {
        title: i18n.t('navigation-menu.partner'),
        route: 'super-partner',
        icon: 'UsersIcon',
      },
      {
        title: i18n.t('navigation-menu.page-links'),
        route: 'super-page-links',
        icon: 'LinkIcon',
      },
    ],
  },
  {
    title: i18n.t('navigation-menu.tools'),
    icon: 'ToolIcon',
    children: [
      {
        title: i18n.t('navigation-menu.ai-ide'),
        route: 'super-ai-ide',
        icon: 'GitlabIcon',
      },
      {
        title: 'Presentation',
        href: 'https://ppt.globalenglishcampus.com/',
        icon: 'CastIcon',
      },
      {
        title: i18n.t('navigation-menu.recent-activities'),
        icon: 'ActivityIcon',
        route: 'super-recent-activities',
      },
      {
        title: i18n.t('navigation-menu.localization'),
        icon: 'GlobeIcon',
        route: 'super-localization',
      },
      {
        title: 'Console Progress',
        icon: 'CodeIcon',
        route: 'super-console',
      },
    ],
  },
  {
    title: i18n.t('navigation-menu.higher-level-report'),
    route: 'super-higher-level-report',
    icon: 'FilePlusIcon',
  },

  {
    title: i18n.t('navigation-menu.individual-report'),
    route: 'super-progress-report',
    icon: 'TrendingUpIcon',
  },

  // {
  //   title: i18n.t('navigation-menu.courses'),
  //   route: 'super-courses',
  //   icon: 'BookIcon',
  // },
  // {
  //   title: i18n.t('navigation-menu.standards'),
  //   route: 'super-standards',
  //   icon: 'ShieldIcon',
  // },
  {
    title: i18n.t('navigation-menu.reservation'),
    route: 'super-reservations',
    icon: 'BookmarkIcon',
  },
  // {
  //   title: i18n.t('navigation-menu.permissions'),
  //   route: 'super-permissions',
  //   icon: 'SlidersIcon',
  // },
  // {
  //   title: 'Home',
  //   route: 'super-home',
  //   icon: 'HomeIcon',
  // },
]
menu[userType.USER_TYPE_COMPANY] = [
  // {
  //   title: 'Home',
  //   route: 'company-home',
  //   icon: 'HomeIcon',
  // },
  {
    title: i18n.tc('navigation-menu.users'),
    type: 'users',
    route: 'company-users',
    icon: 'UsersIcon',
  },
  {
    title: i18n.t('navigation-menu.classrooms'),
    route: 'company-classes',
    icon: 'HomeIcon',
  },
  {
    title: i18n.tc('navigation-menu.networks'),
    route: 'company-networks',
    icon: 'Share2Icon',
  },
  {
    title: i18n.t('navigation-menu.higher-level-report'),
    route: 'company-higher-level-report',
    icon: 'FilePlusIcon',
  },
]
menu[userType.USER_TYPE_DISTRICT] = [
  // {
  //   title: 'Home',
  //   route: 'district-home',
  //   icon: 'HomeIcon',
  // },
  {
    title: i18n.t('navigation-menu.library'),
    route: 'district-library',
    icon: 'BookIcon',
  },
  {
    title: i18n.tc('navigation-menu.users'),
    type: 'users',
    route: 'district-users',
    icon: 'UsersIcon',
  },
  {
    title: i18n.t('navigation-menu.classrooms'),
    route: 'district-classes',
    icon: 'HomeIcon',
  },
  {
    title: i18n.tc('navigation-menu.networks'),
    route: 'district-networks',
    icon: 'Share2Icon',
  },
  {
    title: i18n.t('navigation-menu.custom-reports'),
    icon: 'FilePlusIcon',
    children: [
      {
        title: i18n.t('navigation-menu.higher-level-report'),
        route: 'district-higher-level-report',
        icon: 'FilePlusIcon',
      },
      {
        title: i18n.t('navigation-menu.aggregates-reports'),
        route: 'district-aggregates-report',
        icon: 'FilePlusIcon',
      },
    ],
  },
  {
    title: i18n.t('navigation-menu.partner-services'),
    route: 'district-partner-services',
    icon: 'FilePlusIcon',
  },
  {
    title: i18n.t('navigation-menu.settings'),
    route: 'district-settings',
    icon: 'SettingsIcon',
  },
]
menu[userType.USER_TYPE_INTERNATIONAL] = [
  // {
  //   title: 'Home',
  //   route: 'international-home',
  //   icon: 'HomeIcon',
  // },
  {
    title: i18n.tc('navigation-menu.users'),
    type: 'users',
    route: 'international-users',
    icon: 'UsersIcon',
  },
  {
    title: i18n.t('navigation-menu.classrooms'),
    route: 'international-classes',
    icon: 'HomeIcon',
  },
  {
    title: i18n.tc('navigation-menu.networks'),
    route: 'international-networks',
    icon: 'Share2Icon',
  },
  {
    title: i18n.t('navigation-menu.higher-level-report'),
    route: 'international-higher-level-report',
    icon: 'FilePlusIcon',
  },
]
menu[userType.USER_TYPE_NATIONAL] = [
  // {
  //   title: 'Home',
  //   route: 'national-home',
  //   icon: 'HomeIcon',
  // },
  {
    title: i18n.t('navigation-menu.library'),
    route: 'national-library',
    icon: 'BookIcon',
  },
  {
    title: i18n.tc('navigation-menu.users'),
    type: 'users',
    route: 'national-users',
    icon: 'UsersIcon',
  },
  {
    title: i18n.t('navigation-menu.classrooms'),
    route: 'national-classes',
    icon: 'HomeIcon',
  },
  {
    title: i18n.tc('navigation-menu.networks'),
    route: 'national-networks',
    icon: 'Share2Icon',
  },
  {
    title: i18n.t('navigation-menu.higher-level-report'),
    route: 'national-higher-level-report',
    icon: 'FilePlusIcon',
  },
]
menu[userType.USER_TYPE_SCHOOL] = [
  // {
  //   title: 'Home',
  //   route: 'school-home',
  //   icon: 'HomeIcon',
  // },
  {
    title: i18n.tc('navigation-menu.classes'),
    route: 'school-classes',
    icon: 'BookOpenIcon',
  },
  {
    title: i18n.tc('navigation-menu.users'),
    type: 'users',
    icon: 'UserIcon',
    children: [
      {
        title: i18n.tc('navigation-menu.users-list'),
        route: 'school-users',
        icon: 'UsersIcon',
      },
      {
        title: i18n.tc('navigation-menu.student-personality'),
        route: 'student-personality-evaluation-for-school',
        icon: 'DribbbleIcon',
      },

    ],
  },
  {
    title: i18n.tc('navigation-menu.report'),
    icon: 'BarChartIcon',
    children: [
      {
        title: i18n.tc('navigation-menu.time-spent'),
        route: 'school-view-student-time',
        icon: 'ClockIcon',
      },
      {
        title: i18n.t('navigation-menu.higher-level-report'),
        route: 'school-higher-level-report',
        icon: 'FilePlusIcon',
      },
      // {
      //   title: i18n.tc('class-module.overall-report'),
      //   route: 'school-report-overall-classroom-summary',
      //   icon: 'FilePlusIcon',
      // },
      {
        title: i18n.tc('navigation-menu.attendance'),
        route: 'school-view-attendance',
        icon: 'CalendarIcon',
      },
      {
        title: i18n.tc('navigation-menu.real-time-monitor'),
        route: 'school-monitor-class',
        icon: 'MonitorIcon',
      },
    ],
  },
  {
    title: i18n.tc('navigation-menu.networks'),
    route: 'school-networks',
    icon: 'Share2Icon',
  },
  {
    title: i18n.tc('navigation-menu.marketplace'),
    route: 'school-marketplaces',
    icon: 'ShoppingBagIcon',
  },
  /* {
    title: i18n.tc('navigation-menu.engagements'),
    route: 'school-engagement-settings',
    icon: 'SettingsIcon',
  }, */
  // {
  //   title: i18n.tc('navigation-menu.reservation'),
  //   route: 'school-reservations',
  //   icon: 'BookmarkIcon',
  // },
  {
    title: i18n.tc('navigation-menu.reservation'),
    icon: 'BookmarkIcon',
    children: [
      {
        title: i18n.tc('navigation-menu.reservation'),
        route: 'school-reservations',
        icon: 'BookIcon',
      },
      {
        title: i18n.tc('navigation-menu.reservation-schedule'),
        route: 'school-reservation-schedules',
        icon: 'CalendarIcon',
      },
      {
        title: i18n.tc('navigation-menu.student-payment'),
        route: 'school-student-payments',
        icon: 'CreditCardIcon',
      },
    ],
  },
  {
    title: i18n.tc('navigation-menu.settings'),
    icon: 'SettingsIcon',
    children: [
      {
        title: i18n.tc('navigation-menu.settings'),
        route: 'school-settings',
        icon: 'SettingsIcon',
      },
      {
        title: i18n.tc('navigation-menu.note-template'),
        route: 'school-note-templates',
        icon: 'ClipboardIcon',
      },
      {
        title: 'Mapper',
        type: 'Mapper',
        route: 'mapper',
        icon: 'BookOpenIcon',
      },
    ],
  },
]
menu[userType.USER_TYPE_STUDENT] = [
  {
    title: i18n.tc('Dashboard'),
    route: 'student-dashboard',
    icon: 'HomeIcon',
  },
  {
    title: i18n.tc('navigation-menu.classes'),
    route: 'student-classes',
    icon: 'BookOpenIcon',
  },

  {
    title: i18n.tc('navigation-menu.users'),
    type: 'users',
    icon: 'UserIcon',
    children: [
      {
        title: i18n.tc('navigation-menu.student-personality'),
        route: 'student-personality-evaluation-for-student',
        icon: 'DribbbleIcon',
      },
    ],
  },
  {
    title: i18n.tc('navigation-menu.report'),
    icon: 'BarChartIcon',
    children: [
      {
        title: i18n.t('navigation-menu.higher-level-report'),
        route: 'student-higher-level-report',
        icon: 'FilePlusIcon',
      },
    ],
  },

  {
    title: i18n.tc('navigation-menu.engagements'),
    type: 'engagements',
    icon: 'ZapIcon',
    children: [
      {
        title: i18n.tc('navigation-menu.engagements-settings'),
        icon: 'SettingsIcon',
        route: 'student-engagements',
      },
      {
        title: i18n.tc('navigation-menu.my-animated-items'),
        route: 'my-items',
        icon: 'HeartIcon',
      },
      {
        title: i18n.tc('navigation-menu.animated-items-marketplace'),
        route: 'animated-items-marketplace',
        icon: 'ShoppingCartIcon',
      },
    ],
  },

  // {
  //   title: i18n.tc('navigation-menu.networks'),
  //   route: 'student-networks',
  //   icon: 'Share2Icon',
  // },

]
menu[userType.USER_TYPE_PARENT] = [
  // {
  //   title: 'Home',
  //   route: 'parent-home',
  //   icon: 'HomeIcon',
  // },
  {
    title: i18n.tc('navigation-menu.users'),
    type: 'users',
    icon: 'UserIcon',
    children: [
      {
        title: i18n.tc('navigation-menu.users-list'),
        route: 'parent-users',
        icon: 'UsersIcon',
      },
      {
        title: i18n.tc('navigation-menu.student-personality'),
        route: 'student-personality-evaluation-for-parent',
        icon: 'DribbbleIcon',
      },
    ],
  },
  {
    title: i18n.t('navigation-menu.classrooms'),
    route: 'parent-classes',
    icon: 'HomeIcon',
  },
  // {
  //   title: i18n.tc('navigation-menu.classes'),
  //   route: 'parent-classes',
  //   icon: 'BookOpenIcon',
  // },
  // {
  //   title: i18n.tc('navigation-menu.report'),
  //   route: 'parent-report',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: i18n.tc('navigation-menu.networks'),
  //   route: 'parent-networks',
  //   icon: 'Share2Icon',
  // },
]
menu[userType.USER_TYPE_TEACHER] = [
  // {
  //   title: 'Home',
  //   route: 'teacher-home',
  //   icon: 'HomeIcon',
  // },
  // {
  //   title: 'Dashboard',
  //   route: 'teacher-dashboard',
  //   icon: 'HomeIcon',
  // },
  {
    title: i18n.tc('navigation-menu.classes'),
    route: 'teacher-classes',
    icon: 'BookOpenIcon',
  },
  {
    title: i18n.tc('courses-module.courses'),
    icon: 'BookIcon',
    route: 'teacher-courses',
  },
  {
    title: i18n.tc('navigation-menu.users'),
    type: 'users',
    icon: 'UserIcon',
    children: [
      {
        title: i18n.tc('navigation-menu.users-list'),
        route: 'teacher-users',
        icon: 'UsersIcon',
      },
      {
        title: i18n.tc('navigation-menu.student-personality'),
        route: 'student-personality-evaluation-for-teacher',
        icon: 'DribbbleIcon',
      },
      {
        title: i18n.tc('navigation-menu.compose-email'),
        route: 'send-notifications',
        icon: 'MailIcon',
      },
    ],
  },
  // {
  //   title: i18n.tc('navigation-menu.networks'),
  //   route: 'teacher-networks',
  //   icon: 'Share2Icon',
  // },
  {
    title: i18n.tc('navigation-menu.reports'),
    icon: 'BarChartIcon',
    children: [
      {
        title: i18n.tc('navigation-menu.time-report'),
        icon: 'PieChartIcon',
        children: [
          {
            title: i18n.tc('navigation-menu.time-spent'),
            route: 'teacher-view-student-time',
            icon: 'ClockIcon',
          },
          {
            title: i18n.tc('navigation-menu.attendance'),
            route: 'teacher-view-attendance',
            icon: 'CalendarIcon',
          },
          {
            title: i18n.tc('navigation-menu.real-time-monitor'),
            route: 'teacher-monitor-class',
            icon: 'MonitorIcon',
          },
        ],
      },
      {
        title: i18n.t('navigation-menu.higher-level-report'),
        route: 'teacher-higher-level-report',
        icon: 'FilePlusIcon',
      },
      {
        title: i18n.t('navigation-menu.calibration-menu'),
        route: 'teacher-report-calibration',
        icon: 'CodesandboxIcon',
      },
      // {
      //   title: i18n.tc('class-module.overall-report'),
      //   route: 'teacher-report-overall-classroom-summary',
      //   icon: 'FilePlusIcon',
      // },
      {
        title: i18n.tc('navigation-menu.game-report'),
        route: 'game-report',
        icon: 'TvIcon',
      },
    ],
  },
  {
    title: i18n.tc('navigation-menu.reservation'),
    icon: 'BookmarkIcon',
    children: [
      {
        title: i18n.tc('navigation-menu.reservation-schedule'),
        route: 'teacher-reservation-schedules',
        icon: 'CalendarIcon',
      },
    ],
  },
  // {
  //  title: i18n.tc('navigation-menu.networks'),
  //  route: 'teacher-networks',
  //  icon: 'Share2Icon',
  // },
  {
    title: i18n.tc('navigation-menu.report-issue'),
    route: 'report-issue',
    icon: 'FlagIcon',
  },

]
menu[userType.USER_TYPE_CURRICULUM] = [
  {
    title: i18n.t('navigation-menu.library'),
    route: 'curriculum-library',
    icon: 'BookIcon',
  },
  {
    title: i18n.t('navigation-menu.image-library'),
    route: 'curriculum-image-library',
    icon: 'ImageIcon',
  },
  {
    title: i18n.t('navigation-menu.error-reports'),
    icon: 'AlertTriangleIcon',
    route: 'curriculum-error-reports',
  },
]

export default menu
