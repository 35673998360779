<template>
  <b-overlay :show="isPolyProcessing">
    <div v-if="!audioURL">
      <b-row
        v-if="!recording"
        class="mx-0"
      >
        <b-button
          variant="outline-primary"
          class="btn-icon sm-mt-2 mr-1"
          @click="record()"
        >
          <feather-icon icon="MicIcon" />
        </b-button>
        <b-button
          variant="outline-primary"
          class="btn-icon sm-mt-2"
          @click="upload()"
        >
          <feather-icon icon="UploadIcon" />
        </b-button>
        <template v-if="!hidePolly">
          <b-button
            v-if="hasOnSelectedFromLibraryEvent"
            variant="outline-primary"
            class="btn-icon sm-mt-2 ml-1 mr-1"
            @click="showImageLibrary = true"
          >
            <feather-icon icon="CornerLeftUpIcon" />
          </b-button>
          <b-dropdown
            right
            variant="outline-primary"
            no-caret
          >
            <template #button-content>
              <feather-icon icon="ServerIcon" />
            </template>

            <b-dropdown-item @click="fetchPollySpeech('male')">
              Male
            </b-dropdown-item>
            <b-dropdown-item @click="fetchPollySpeech('female')">
              Female
            </b-dropdown-item>
          </b-dropdown>
          <!-- <b-button
          v-if="!!pollyText"
          v-b-tooltip.hover.top="'Fetch Audio from server'"
          variant="outline-primary"
          class="btn-icon sm-mt-2 ml-1"
          @click="fetchPollySpeech"
        >
          <feather-icon icon="ServerIcon" />
        </b-button> -->
          <b-button
            v-b-tooltip.hover.top="'Generate audio from text'"
            variant="outline-primary"
            class="btn-icon sm-mt-2 ml-1"
            @click="showTextModal"
          >
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </template>
      </b-row>
      <b-button
        v-if="recording"
        variant="outline-primary"
        class="btn-icon sm-mt-2"
        @click="stop()"
      >
        <feather-icon icon="StopCircleIcon" />
      </b-button>
      <b-form-file
        ref="audioInput"
        style="display: none"
        :browse-text="$t('browseButton')"
        :placeholder="$t('issue-report-module.choose-a-file')"
        accept=".mp3,.wav"
        @change="handleAudio($event)"
      />
    </div>
    <div
      v-else
      class="d-flex align-items-center px-0"
    >
      <audio-player
        class="col-10 pl-0 pr-1"
        :src="audioURL"
        :downloadable="downloadable"
        @fetchPollySpeech="fetchPollySpeech"
      />
      <b-button
        variant="outline-danger"
        class="btn-icon ml-auto"
        @click="deleteAudio"
      >
        <feather-icon icon="TrashIcon" />
      </b-button>
    </div>
    <small>{{ $t('labels.create-problem.audio-allowed') }}</small>
    <ImageLibraryPicker :show="showImageLibrary"
                        @close="showImageLibrary = false"
                        @pickImage="handlePickFromLibrary"
    />
    <TextToAudio :show="showTextConvertModal"
                 @close="showTextConvertModal = false"
                 @input="audioUrl => $emit('input', audioUrl)"
    />
  </b-overlay>
</template>

<script>
import {
  BButton, BRow, BFormFile, BOverlay, VBTooltip, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ImageLibraryPicker from '@/views/common/components/ImageLibraryPick.vue'
import useJwt from '@/auth/jwt/useJwt'
import AudioPlayer from './AudioPlayer.vue'
import TextToAudio from './TextToAudio.vue'

export default {
  components: {
    TextToAudio,
    BButton,
    BRow,
    BFormFile,
    AudioPlayer,
    ImageLibraryPicker,
    BOverlay,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    url: {
      type: String,
      default: () => '',
    },
    downloadable: {
      type: Boolean,
      default: true,
    },
    pollyText: {
      type: String,
      default: '',
    },
    hidePolly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    audioURL: '',
    audio: '',
    chunks: [],
    recording: false,
    uploading: true,
    tracks: null,
    showImageLibrary: false,
    isPolyProcessing: false,
    pollyTextGender: 'male',
    showTextConvertModal: false,
    mediaRecorder: null,
    maxMB: 10,
  }),
  computed: {
    hasOnSelectedFromLibraryEvent() {
      return this.$listeners && this.$listeners.onSelectedFromLibrary
    },
  },
  watch: {
    url() {
      this.audioURL = this.url
    },
  },
  created() {
    this.audioURL = this.url
  },
  methods: {
    handlePickFromLibrary(value) {
      this.$emit('onSelectedFromLibrary', value.audio)
      this.showImageLibrary = false
    },
    record() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia(
          {
            audio: true,
          },
        )
        // Success callback
          .then(stream => {
            this.recording = true
            this.mediaRecorder = new MediaRecorder(stream)
            this.mediaRecorder.start()
            this.mediaRecorder.ondataavailable = e => {
              this.chunks.push(e.data)
            }
            this.tracks = stream.getTracks()
          })
        // Error callback
          .catch(() => {
            this.showInfo('Please allow audio and camera permission')
          })
      } else {
        this.showInfo('Audio and camera not supported on your browser!')
      }
    },
    stop() {
      this.mediaRecorder.stop()
      this.recording = false
      this.mediaRecorder.onstop = () => {
        this.tracks.forEach(track => {
          track.stop()
        })
        const blob = new Blob(this.chunks, { type: 'audio/ogg; codecs=opus' })
        this.chunks = []

        blob.lastModifiedDate = new Date()
        blob.name = 'recorded.mp3'
        this.audio = blob

        this.audioURL = window.URL.createObjectURL(blob)
        this.mediaRecorder = null
        this.emitAudio()
      }
    },
    upload() {
      this.$refs.audioInput.$el.childNodes[0].click()
    },
    base64StringToBlobUrl(base64String) {
      // Convert the base64 string to a Uint8Array
      const arrayBuffer = Uint8Array.from(atob(base64String), c => c.charCodeAt(0))

      // Create a Blob from the Uint8Array
      const blob = new Blob([arrayBuffer], { type: 'audio/ogg' })

      // Create a blob URL
      const blobUrl = URL.createObjectURL(blob)

      return {
        audio: blob,
        url: blobUrl,
      }
    },
    handleAudio(event) {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.audioURL = reader.result
          this.emitAudio()
        },
        false,
      )
      if (event.target.files[0]) {
        const file = event.target.files[0]
        if (/\.(mp3)$/i.test(file.name) || /\.(wav)$/i.test(file.name) || /\.(WAV)$/i.test(file.name)) {
          if (file.size <= 1024 * 1024 * this.maxMB) {
            this.audio = file
            reader.readAsDataURL(file)
          } else {
            this.$emit('fileSizeInvalid', `Audio file may not be greater that ${this.maxMB} MB`)
          }
        } else {
          this.$emit('fileTypeInvalid', 'Audio file must be of file type: mp3')
        }
      }
    },
    deleteAudio() {
      this.audioURL = ''
      this.audio = ''
      this.emitAudio()
      this.$emit('onRemove')
    },
    emitAudio() {
      this.$emit('input', { audio: this.audio, url: this.audioURL })
    },
    fetchPollySpeech(gender = this.pollyTextGender) {
      this.isPolyProcessing = true
      useJwt.convertTextToBase64({
        text: this.pollyText,
        gender,
      }).then(response => {
        // this.$emit('input', { base64String: `data:audio/ogg; codecs=opus;base64,${response.data.audio_file}` })
        this.$emit('input', this.base64StringToBlobUrl(`${response.data.audio_file}`))
      }).finally(() => {
        this.isPolyProcessing = false
      })
    },
    showTextModal() {
      this.showTextConvertModal = true
    },
  },
}
</script>

<style lang="scss">
.close{
  .btn-icon {
    padding: 5px 5px !important;
    background-color: #fff;
    top: 5px;
    margin-right: 5px;
  }
}
.iconfont.rate{
  display: none !important;
}
</style>
