<template>
  <b-col
    md="12"
    class="mb-1"
  >
    <b-card
      no-body
      class="mb-0"
    >
      <h5 class="p-1 pb-0">
        {{ $t('network-module.sponsors') }}
      </h5>
      <b-table
        :fields="columns"
        small
        responsive
        :items="getSponsersForTable"
      />

    </b-card>
  </b-col>
</template>
<script>
import {
  BCol, BCard, BTable,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    BCol, BCard, BTable,
  },
  data() {
    return {
      columns: [
        {
          key: 'name',
          label: i18n.tc('network-module.sponsor-table.name'),
        },
      ],
    }
  },
  computed: {
    getSponsersForTable() {
      return [
        {
          name: 'Microsoft',
        },
        {
          name: 'Google',
        },
        {
          name: 'Amazon',
        },
      ]
    },
  },
}
</script>
