<template>
  <div>
    <b-modal
      v-if="!onlyNotes && !activeNotesLists"
      :visible="show"
      ok-only
      centered
      content-class="shadow"
      ok-title="Accept"
      body-class="p-0"
      hide-footer
      @close="$emit('close')"
      @hide="$emit('close')"
    >
      <template #modal-title>
        {{ title }} <feather-icon
          icon="ExternalLinkIcon"
          role="button"
          size="20"
          class="mb-1"
          @click="openPairs"
        />
      </template>
      <b-overlay :show="isProcessing">
        <b-list-group>
          <b-list-group-item
            v-for="pair of pairs"
            :key="pair.id"
            class="d-flex justify-content-between align-items-center"
            role="button"
            @click="activeNotesLists = pair"
          >
            <h5>
              {{ pair.full_name }}
            </h5>
            <b-badge
              v-if="pair.notes.length"
              variant="primary"
              pill
              class="badge-round"
            >
              {{ pair.notes.length }}
            </b-badge>
          </b-list-group-item>
        </b-list-group>
        <h5
          v-if="!isProcessing && pairs.length === 0"
          class="p-2"
        >
          {{ $t('pairing.no-pairs') }}
        </h5>
      </b-overlay>
    </b-modal>
    <b-sidebar
      :visible="!!activeNotesLists"
      ok-only
      right
      size="lg"
      content-class="shadow"
      :title="activeNotesLists ? activeNotesLists.full_name : '-'"
      ok-title="Accept"
      body-class="p-0"
      width="600px"
      backdrop
      shadow
      @hidden="activeNotesLists = null;$emit('closeNote')"
    >
      <b-overlay :show="isProcessing">
        <div
          v-if="activeNotesLists"
          class="p-2 pt-1"
        >
          <b-form-group v-if="!onlyNotes"
                        label="Lessons"
          >
            <b-card no-body>
              <v-select
                v-model="selectedLesson"
                :options="lessonsList"
                :reduce="item => item.id"
                label="name"
              />
            </b-card>
          </b-form-group>
          <lesson-notes
            v-if="selectedLesson && isAStudent"
            :key="selectedLesson"
            ref="lessonNotes"
            :lesson-id="selectedLesson"
            :hide-close="true"
            class="w-100"
            @close="$emit('close')"
          />
          <notes-area
            v-else
            :notes="filteredNotesByLesson(activeNotesLists.notes)"
            :specific-student="true"
          />
        </div>
      </b-overlay>
    </b-sidebar>
  </div>
</template>
<script>
import {
  BModal, BListGroup, BListGroupItem, BOverlay, BBadge, BSidebar, BCard, BFormGroup,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import NotesArea from '@/views/common/lessons/Notes/OtherNotes.vue'
import LessonNotes from '@/views/common/lessons/Notes/index.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BListGroup,
    BListGroupItem,
    BOverlay,
    BBadge,
    BSidebar,
    BCard,
    vSelect,
    NotesArea,
    LessonNotes,
    BFormGroup,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    classId: {
      type: Number,
      default: () => 0,
    },
    studentId: {
      type: Number,
      default: 0,
    },
    onlyNotes: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isProcessing: false,
      pairs: [],
      activeNotesLists: null,
      self: getUserData(),
      selectedLesson: null,
    }
  },
  computed: {
    isAStudent() {
      return this.self.usertype === 'student'
    },
    title() {
      const studentName = this.pairs.find(pair => Number(pair.id) === Number(this.studentId))
      return studentName ? `${studentName.full_name}` : 'Pairs'
    },
    lessonsList() {
      const notes = this.activeNotesLists?.notes || []
      const lessons = [{
        id: null,
        name: 'Select Lessons',
      }]
      notes.forEach(note => {
        if (note.lesson && !lessons.find(lesson => lesson.id === note.lesson.id)) {
          lessons.push({
            id: note.lesson.id,
            name: note.lesson.name,
          })
        }
      })
      return lessons
    },
  },
  watch: {
    studentId: {
      handler() {
        if (this.show && this.classId) {
          this.getMyPairs()
        }
      },
    },
  },
  methods: {
    getMyPairs() {
      this.isProcessing = true
      useJwt.getMyPairs(this.classId, {
        params: {
          student_id: this.studentId ? this.studentId : null,
          ...(this.onlyNotes ? { only_notes: this.onlyNotes } : {}),
        },
      }).then(response => {
        if (this.onlyNotes) {
          const { notes } = response.data
          notes.full_name = `${notes.firstname}  ${notes.lastname}`
          this.activeNotesLists = notes
          return
        }
        const { pairs } = response.data?.data
        this.pairs = pairs.map(data => ({ ...data, full_name: `${data.firstname}  ${data.lastname}` }))
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    filteredNotesByLesson(notes) {
      if (!this.selectedLesson) return notes
      return notes.filter(note => Number(note.lesson?.id) === Number(this.selectedLesson))
    },
    openPairs() {
      const studentIds = this.pairs.map(pair => pair.id)
      window.open(`/view-notes-all?student=${JSON.stringify(studentIds)}&classId=${this.classId}`)
    },
  },
}
</script>
