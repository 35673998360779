<template>
  <div class="overflow-auto">
    <div
      v-if="isEditMode"
      class="mb-2"
      style="position: absolute;
      margin-top: -30px !important;
      background: rgb(78 74 74 / 53%)"
    >
      <b-button
        v-b-tooltip.hover.v-info
        variant="flat-secondary"
        class="btn-icon text-white"
        title="Setting for entire layout"
        @click="showSetting = true"
      >
        <feather-icon
          icon="SettingsIcon"
        />
      </b-button>
    </div>
    <slot />
    <layout-setting
      :settings="settings"
      :show="showSetting"
      :enabled-features="['margin']"
      @updateLayout="value => $emit('updateLayout', value)"
      @close="showSetting = false"
    />
  </div>
</template>
<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import LayoutSetting from '../components/LayoutSetting.vue'

export default {
  components: {
    BButton,
    LayoutSetting,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: [Object, Array],
      default: () => {},
    },
    position: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showSetting: false,
    }
  },
}
</script>
<style scoped>
  .resizer {
    position: absolute;
    left: 0;
    z-index: 1000;
    margin-right: 10px;
    margin-right: 10px;
    margin-top: -30px;
    background: #00000082;
  }
</style>
