<template>
  <div>
    <div class="d-flex justify-content-between">
      <div>
        <b-button
          variant="outline-primary"
          @click="$router.go(-1)"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            class="mr-50"
          />
          <span class="align-middle">Back</span>
        </b-button>
      </div>
      <div>
        <b-button
          variant="outline-primary"
          @click="$router.push({ name: 'super-problem' , query: { lesson_id: lessonId } })"
        >
          <feather-icon
            icon="BoxIcon"
            class="mr-50"
          />
          <span class="align-middle">Open Problem</span>
        </b-button>
      </div>
    </div>
    <div
      v-if="lessonInfo"
      class="mt-1"
    >
      <h3>
        {{ lessonInfo?.name }} / Shared Problems
      </h3>
    </div>
    <b-row>
      <b-col cols="4">
        <lesson-problem
          :preloaded-problems="preloadedProblems"
          @onSubmit="appendMatch"
          @onLessonChanged="value => selectedLesson = value"
        />
      </b-col>
      <b-col cols="8">
        <b-overlay :show="isProcessing">
          <problem-match
            :problem-with-level="problemWithLevel"
            :is-processing="isProcessing"
            :max-row="maxRow"
            @onSubmit="updateProblem"
            @onRemoveProblem="onRemoveProblem"
            @handleDataTransfer="handleDataTransfer"
          />
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BOverlay, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import LessonProblem from './components/LessonProblem.vue'
import ProblemMatch from './components/ProblemMatch.vue'

export default {
  components: {
    BRow,
    BCol,
    LessonProblem,
    ProblemMatch,
    BOverlay,
    BButton,
  },
  data() {
    return {
      selectedLesson: null,
      problemWithLevel: {
        A1: [],
        A2: [],
        B1: [],
        B2: [],
      },
      lessonId: this.$route.params.id,
      isProcessing: false,
      preloadedProblems: [],
      lessonInfo: null,
    }
  },
  computed: {
    maxRow() {
      return Math.max(
        ...[
          this.problemWithLevel.A1.length,
          this.problemWithLevel.A2.length,
          this.problemWithLevel.B1.length,
          this.problemWithLevel.B2.length,
        ],
      )
    },
  },
  created() {
    this.isProcessing = true
    useJwt.getSDPLessonProblemForEdit(this.lessonId).then(response => {
      this.problemWithLevel = response.data?.data?.formatted_data
      this.preloadedProblems = response.data?.data?.problems
      this.lessonInfo = response.data?.data?.lesson
    })
      .catch(error => {
        this.showErrorMessage(error)
      })
      .finally(() => { this.isProcessing = false })
  },
  methods: {
    onRemoveProblem({ level, index }) {
      if (this.problemWithLevel[level] && this.problemWithLevel[level][index]) {
        const problemIdToRemove = { ...this.problemWithLevel[level][index] }
        this.$emit('removeSelectedProblem', problemIdToRemove.value)
        this.problemWithLevel[level].splice(index, 1)
      }
    },
    appendMatch({
      level, problems, lessonId, lessonName,
    }) {
      const oldDataForIndex = this.problemWithLevel[level]
      const newData = [...oldDataForIndex.filter(old => old.lessonId !== lessonId)]
      problems.forEach(problem => {
        if (!newData.find(data => data.value === problem.value)) {
          const index = oldDataForIndex.findIndex(data => data.value === problem.value)
          newData.push({
            name: problem.name,
            value: problem.value,
            lessonId: problem.lessonId,
            lessonName: problem.lessonName || lessonName,
            index: index >= 0 ? index : Number.POSITIVE_INFINITY,
          })
        }
      })
      newData.sort((a, b) => a.index - b.index)
      this.problemWithLevel = {
        ...this.problemWithLevel,
        [level]: newData,
      }
    },
    handleDataTransfer({ level, from, to }) {
      const oldData = this.problemWithLevel[level]
      const fromData = oldData[from] ?? null
      const toData = oldData[to] ?? null
      oldData[from] = toData
      oldData[to] = fromData
      this.problemWithLevel = {
        ...this.problemWithLevel,
        [level]: oldData,
      }
    },
    isProblemValid() {
      return Object.values(this.problemWithLevel).every(problem => problem.length === this.maxRow)
    },
    updateProblem() {
      if (!this.isProblemValid()) {
        this.showErrorMessage('Problem Not valid')
        return
      }
      this.isProcessing = true
      useJwt.updateSDPLesson(this.lessonId, {
        details: this.problemWithLevel,
      }).then(response => {
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error.response?.data?.errors || error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
