<template>
  <div class="border border-2 mb-2 pt-1 pl-1 border-speech">
    <b-row>
      <b-col md="9">
        <AudioRecorder :src="tts()"
                       :only-icon="true"
                       :include-graph="true"
                       class="problem-audio"
        >{{ answer.text }}</AudioRecorder>
      </b-col>
      <b-col v-if="answer.file"
             md="3"
      >
        <b-img
          v-if="answer.file"
          v-image-preview
          :src="answer.file"
          style="height:150px; width:190px;object-fit: contain;"
          class="img-fluid"
        />
      </b-col>
    </b-row>
    <div v-if="recordedAnswers.length"
         ref="recorded-ans-btn"
         class="mx-lg-4 mx-md-2 rounded speech-answers"
    >
      <b-form-group class="ml-2 mt-1">
        <template #label>
          <span>{{ $t('labels.recorded-answer') }}</span>
        </template>
        <div v-for="recAns,ia in recordedAnswers"
             :key="ia"
        >
          <b-form-radio
            v-model="selectedAnswer"
            :value="recAns"
            @change="updateAnswer(recAns, ia)"
          >
            {{ recAns.text }}
          </b-form-radio>
        </div>
      </b-form-group>
    </div>
    <div v-if="!isAnswerSubmitted && !showPreviewAnswer">
      <div v-if="recordedAnswers.length < triesLength"
           class="mt-lg-1 mx-lg-4 mx-md-2"
      >
        <div>
          <label>{{ $t('labels.supported-browser') }}</label><br>
          <recorder
            class="border rounded row"
            :auto-stop="false"
            @textAudio="value => setAnswer(value)"
          />
        </div>
      </div>
      <div v-else
           class="pb-1"
      >
        <span class="text-primary">
          {{ $t('labels.answer-limit-crossed') }}
        </span>
      </div>
    </div>
    <div v-if="showPreviewAnswer">
      <h3 class="text-bold">
        {{ $t('students-answers') }}
      </h3>
      <div v-for="sp,index of previewAnswers"
           :key="index"
           class="mb-2"
      >
        <AudioRecorder :src="sp.audio"
                       :only-icon="true"
                       :include-graph="true"
                       class="problem-audio"
        >{{ sp.text }}</AudioRecorder>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormRadio, BFormGroup, BImg,
} from 'bootstrap-vue'
import AudioRecorder from '@/views/common/components/AudioPlayer.vue'
import Recorder from '../recorder/Speech.vue'

export default {
  components: {
    AudioRecorder,
    Recorder,
    BRow,
    BCol,
    BFormRadio,
    BFormGroup,
    BImg,
  },
  props: {
    answer: {
      type: Object,
      required: true,
    },
    triesLength: {
      type: Number,
      default: 1,
    },
    isAnswerSubmitted: {
      type: Boolean,
      default: false,
    },
    showPreviewAnswer: {
      type: Boolean,
      default: false,
    },
    previewAnswers: {
      type: [Object, Array],
      default: () => [],
    },
  },
  data() {
    return {
      isAnswerPushing: false,
      recordedAnswers: [],
      selectedAnswer: null,
    }
  },
  methods: {
    tts() {
      if (this.answer.extra_file && this.answer.extra_file !== 'null') {
        return this.answer.extra_file
      }
      return this.answer.text
    },
    updateAnswer(ans, index) {
      this.removeUnselected(index)
      this.selectedAnswer = ans
      this.$emit('updateAnswer', ans)
    },
    removeUnselected(index) {
      const radioBtn = this.$refs['recorded-ans-btn']
      const inputs = radioBtn.querySelectorAll('input')
      inputs.forEach((node, i) => {
        // eslint-disable-next-line no-param-reassign
        node.checked = i === index
      })
    },
    setAnswer(answer) {
      if (this.isAnswerPushing) {
        return
      }
      this.isAnswerPushing = true
      const index = Number(this.recordedAnswers?.length) + 1
      const ans = {
        text: answer.text.toLowerCase(), audio: answer.audio, file: answer.audioFile, index,
      }
      // eslint-disable-next-line no-param-reassign
      answer.index = index
      this.recordedAnswers.push(answer)
      setTimeout(() => {
        this.isAnswerPushing = false
        const radioBtn = this.$refs['recorded-ans-btn']
        const inputs = radioBtn.querySelectorAll('input')
        this.updateAnswer(ans, inputs.length - 1)
      }, 1000)
    },
  },
}
</script>
