<template>
  <b-modal
    :visible="show"
    size="lg"
    no-close-on-backdrop
    :title="`Reserve Classroom:`"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-overlay :show="isProcessing">
      <b-tabs
        v-model="activeTab"
        pills
      >

        <b-tab title="Create Classroom">
          <create-classroom
            v-if="reservation && reservationClass"
            ref="createClassroom"
            :show="true"
            :reservation-info="{
              school_id: reservation.school_id,
              name: reservationClass.room_name,
            }"
            @handleClassCreated="handleClassCreated"
            @classRoomCreateFailed="isProcessing = false"
          />
        </b-tab>
        <b-tab title="Or Select Classroom">
          <validation-observer ref="formProvider">
            <b-form-group label="Classroom Lists">
              <validation-provider
                v-slot="{ errors }"
                name="Classroom"
                rules="required"
              >
                <v-select
                  v-model="classroom_id"
                  placeholder="Classrooms"
                  :clearable="false"
                  :options="classroomLists"
                  label="name"
                  :reduce="item => item.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </validation-observer>
        </b-tab>
      </b-tabs>
    </b-overlay>
    <template #modal-footer="{}">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            class="mr-2"
            @click="$emit('close')"
          >
            Cancel
          </b-button>
          <b-button
            v-if="shouldCreateClassRoom"
            variant="success"
            :disabled="isProcessing"
            @click="createClassroom"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            Create Classroom and Save
          </b-button>
          <b-button
            v-else
            variant="success"
            :disabled="isProcessing"
            @click="handleForm"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            Save
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal, BFormGroup, BOverlay, BRow, BSpinner, BCol, BButton, BTabs, BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import CreateClassroom from '@/views/common/class/components/CreateEdit.vue'

export default {
  components: {
    BModal,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BSpinner,
    CreateClassroom,
    BTabs,
    BTab,
    vSelect,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    reservation: {
      type: Object,
      default: () => null,
    },
    reservationClass: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isProcessing: false,
      classroomLists: [],
      classroom_id: null,
      reservationId: this.$route.params.id,
      required,
      activeTab: 0,
    }
  },
  computed: {
    shouldCreateClassRoom() {
      return this.activeTab === 0
    },
  },
  watch: {
    show(value) {
      if (value) {
        setTimeout(() => {
          this.$refs.createClassroom.getGrades()
          this.$refs.createClassroom.onSchoolChange()
        }, 500)
      }
    },
  },
  created() {
    this.getDefaultClassroom()
  },
  methods: {
    getDefaultClassroom() {
      this.isProcessing = true
      useJwt.getDefaultClassroom({
        params: {
          reservation_id: this.reservationId,
        },
      }).then(response => {
        this.classroomLists = response.data.data
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    createClassroom() {
      // this.isProcessing = true
      this.$refs.createClassroom.createClass()
    },
    handleClassCreated(data) {
      this.classroom_id = data.id
      this.settleReservation(true)
    },
    settleReservation(onFailedRemoveClass = false) {
      this.isProcessing = true
      useJwt.reservationToClassRoom({
        classroom_id: this.classroom_id,
        reservation_class: this.reservationClass.id,
      }).then(response => {
        this.$emit('reFetch')
        this.$emit('close')
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
        if (onFailedRemoveClass) {
          // delete class if failed
          useJwt.deleteClass(this.classroom_id, {
            params: {
              type: 'force_delete',
            },
          })
        }
      }).finally(() => {
        this.isProcessing = false
      })
    },
    handleForm() {
      this.isProcessing = false
      this.$refs.formProvider.validate().then(success => {
        if (success) {
          this.settleReservation()
        }
      })
    },
  },
}
</script>
