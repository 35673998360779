// eslint-disable-next-line import/no-cycle
import { isUserLoggedIn, getUserData } from '@/auth/utils'
import moment from 'moment'

export default function isAuthenticated({ next, router }) {
  if (!isUserLoggedIn()) {
    return router.push('login')
  }
  const authUser = getUserData()

  if (authUser && authUser.user_subscription_type && authUser.user_subscription_type !== 'internal') {
    if (moment(authUser.active_subscription_expire_at).isBefore()) {
      return router.push('update-subscription')
    }
  }

  return next()
}
