<template>
  <div class="d-flex justify-content-between">
    <div class="p-0">
      <b-dropdown
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
        class="p-0"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="text-body align-middle m-0 p-0"
          />
        </template>
        <b-dropdown-item @click="$emit('editSubject')">
          <feather-icon
            icon="EditIcon"
            class="mr-50"
          />
          <span>{{ $t('actions.edit') }}</span>
        </b-dropdown-item>
        <b-dropdown-item @click="$emit('deleteSubject')">
          <feather-icon
            icon="TrashIcon"
            class="mr-50"
          />
          <span>{{ $t('actions.delete') }}</span>
        </b-dropdown-item>
        <b-dropdown-item @click="$emit('uploadDomain')">
          <feather-icon
            icon="UploadIcon"
            class="mr-50"
          />
          <span>{{ $t('actions.upload-domain') }}</span>
        </b-dropdown-item>
        <b-dropdown-item @click="$emit('reOrderDomain')">
          <feather-icon
            icon="AlignCenterIcon"
            class="mr-50"
          />
          <span>{{ $t('actions.reorder') }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BDropdown, BDropdownItem,
  },
  directives: {
    Ripple,
  },
  props: {
    subject: {
      type: Object,
      required: true,
    },

  },
}
</script>

<style>

</style>
