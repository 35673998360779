<template>
  <b-row>
    <b-col md="3">
      <layout-cover
        ref="smallLayoutCover"
        :is-edit-mode="isEditMode"
        :settings="layoutSetting.SMALL"
        :style="[layoutSetting && layoutSetting.SMALL, getComputedStyles(layoutSetting.SMALL) ]"
        @updateLayout="value => $emit('updateLayout', value, 'SMALL')"
      >
        <slot name="small" />
      </layout-cover>
    </b-col>
    <b-col md="9">
      <layout-cover
        ref="bigLayoutCover"
        :is-edit-mode="isEditMode"
        :settings="layoutSetting.BIG"
        :style="[layoutSetting && layoutSetting.BIG, getComputedStyles(layoutSetting.BIG) ]"
        @updateLayout="value => $emit('updateLayout', value, 'BIG')"
      >
        <slot name="big" />
      </layout-cover>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import LayoutCover from '../../../helper/LayoutCover.vue'

export default {
  components: {
    BRow,
    BCol,
    LayoutCover,
  },
  props: {
    layoutSetting: {
      type: Object,
      default: () => {},
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    getComputedStyles: {
      type: Function,
      required: true,
    },
  },
}
</script>
