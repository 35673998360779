<template>
  <b-modal :visible="show"
           size="lg"
           :title="modalTitle"
           no-close-on-backdrop
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <validation-observer ref="lessonLinkForm">
      <b-form>
        <b-form-group :label="$t('page-link-module.link')"
                      label-for="link"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('page-link-module.link')"
            rules="required"
          >
            <b-form-input id="link"
                          v-model="link"
                          :placeholder="$t('page-link-module.enter-link')"
                          required
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group :label="$t('page-link-module.link-name')"
                      label-for="linkName"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('page-link-module.link-name')"
            rules="required"
          >
            <b-form-input id="linkName"
                          v-model="linkName"
                          :placeholder="$t('page-link-module.enter-lesson-link')"
                          required
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group :label="$t('page-link-module.link-description')"
                      label-for="linkDescription"
        >
          <b-form-textarea id="linkDescription"
                           v-model="linkDescription"
                           :placeholder="$t('page-link-module.link-description')"
                           rows="5"
          />
        </b-form-group>
        <b-form-group :label="$t('page-link-module.districts')"
                      label-for="Districts"
        >
          <v-select
            v-if="!applyForAllDistrict"
            v-model="selectedDistricts"
            :options="districts"
            :reduce="item => item.id"
            :placeholder="$t('page-link-module.select-district')"
            label="firstname"
            multiple
          />
          <b-form-checkbox v-model="applyForAllDistrict"
                           class="mt-2"
                           :value="1"
                           switch
          >
            {{ $t('page-link-module.all-districts') }}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group :label="$t('page-link-module.courses')"
                      label-for="courses"
        >
          <v-select
            v-model="selectedCourses"
            :options="courses"
            label="name"
            multiple
            @input="onCourseGroupChange"
          />
        </b-form-group>

        <!-- Display selected courses in tabs -->
        <b-tabs v-if="selectedCourses.length > 0">
          <b-tab v-for="course in selectedCourses"
                 :key="course.id"
                 :title="course.name"
          >
            <v-select
              v-model="selectedLessonGroup[course.id]"
              :options="course.groups"
              label="name"
              multiple
              @input="onLessonGroupChange"
            />

            <!-- Display lessons in the selected lesson group -->
            <div v-if="selectedLessonGroup[course.id]"
                 class="row mt-1"
            >
              <div v-for="lessonGroup in selectedLessonGroup[course.id]"
                   :key="lessonGroup.id"
                   class="col-md-6"
              >
                <h4>{{ lessonGroup.name }}</h4>
                <div v-for="lesson in lessonGroup.lessons"
                     :key="lesson.id"
                >
                  <b-form-checkbox-group v-model="selectedLessons">
                    <b-form-checkbox :value="lesson.id">
                      <span class="lesson-name">{{ lesson.name }}</span>
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <div>
        <b-button
          variant="outline-secondary"
          class="mr-2"
          @click.prevent="$emit('close')"
        >
          {{ $t('labels.cancel') }}
        </b-button>
        <b-button :disabled="isProcessing"
                  variant="primary"
                  @click.prevent="submitForm"
        >
          <b-spinner v-if="isProcessing"
                     small
          />  {{ modalTitle }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import {
  BForm, BFormGroup, BFormInput, BFormTextarea, BTabs, BTab, BModal, BFormCheckbox, BFormCheckboxGroup, BButton, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BTabs,
    BTab,
    vSelect,
    BModal,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    courses: {
      type: Array,
      default: () => [],
    },
    districts: {
      type: Array,
      default: () => [],
    },
    linkToEdit: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      link: '',
      linkName: '',
      linkDescription: '',
      selectedCourses: [],
      selectedLessonGroup: {},
      selectedLessons: [],
      selectedDistricts: [],
      applyForAllDistrict: false,
      isProcessing: false,
    }
  },
  computed: {
    modalTitle() {
      return this.linkToEdit ? i18n.tc('page-link-module.edit-lesson-link') : i18n.tc('page-link-module.create-lesson-link')
    },
  },
  watch: {
    linkToEdit: {
      handler(value) {
        if (value) {
          this.link = value.link
          this.linkDescription = value.description
          this.linkName = value.name
          this.selectedCourses = this.courses.filter(course => course.groups.some(group => group.lessons.some(lesson => value.lessons.includes(lesson.id))))
          this.selectedDistricts = value.district_ids || []
          this.applyForAllDistrict = value.is_all_district
          const groupsWithMatchingLessons = {}
          this.courses.flatMap(course => course.groups.filter(group => group.lessons.some(lesson => value.lessons.includes(lesson.id)))).forEach(item => {
            if (groupsWithMatchingLessons[item.course_id]) {
              groupsWithMatchingLessons[item.course_id].push(item)
            } else {
              groupsWithMatchingLessons[item.course_id] = [item]
            }
          })
          this.selectedLessonGroup = groupsWithMatchingLessons
          this.selectedLessons = value.lessons
          this.$nextTick(() => {
            this.$forceUpdate()
          })
        }
      },
      deep: true,
    },
  },
  methods: {
    resetForm() {
      this.link = ''
      this.linkName = ''
      this.linkDescription = ''
      this.selectedCourses = []
      this.selectedLessonGroup = {}
      this.selectedLessons = []
      this.selectedDistricts = []
      this.applyForAllDistrict = false
    },
    submitForm() {
      this.$refs.lessonLinkForm.validate().then(success => {
        if (success) {
          const formParam = {
            link: this.link,
            name: this.linkName,
            description: this.linkDescription,
            lessons: this.selectedLessons,
            district_ids: this.selectedDistricts,
            is_all_district: this.applyForAllDistrict,
          }
          this.isProcessing = true
          let networkUpdater
          if (this.linkToEdit) {
            networkUpdater = useJwt.editLessonLinks(this.linkToEdit.id, formParam)
          } else {
            networkUpdater = useJwt.saveLessonLinks(formParam)
          }
          networkUpdater.then(response => {
            this.showSuccessMessage(response)
            this.$emit('close')
            this.$emit('reFetch')
            this.resetForm()
          }).finally(() => {
            this.isProcessing = false
          }).catch(error => {
            this.showErrorMessage(error)
          })
        }
      })
    },
    onLessonGroupChange() {
      const lessonThatCanSelected = [].concat(...Object.values(this.selectedLessonGroup).map(obj => Object.values(obj).flatMap(innerObj => (innerObj.lessons || []).map(lesson => lesson.id))))
      this.selectedLessons = this.selectedLessons.filter(item => lessonThatCanSelected.includes(item))
    },
    onCourseGroupChange() {
      let lessonThatCanSelected = []
      this.selectedCourses.forEach(course => {
        course.groups.forEach(lessonGroup => {
          lessonThatCanSelected = [...lessonThatCanSelected, ...lessonGroup.lessons.map(item => item.id)]
        })
      })
      this.selectedLessons = this.selectedLessons.filter(item => lessonThatCanSelected.includes(item))
    },
  },
}
</script>
<style scoped>
.lesson-name {
    font-size: 16px;
    margin-left: 10px;
}
</style>
