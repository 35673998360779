<template>
  <div>
    <b-col
      md="12"
    >
      <div class="d-flex">
        Add Associate Standard
        <b-form-checkbox
          v-model="associateToggle"
          value="1"
          unchecked-value="0"
          switch
          class="ml-1"
        />
      </div>
    </b-col>
    <template v-if="associateToggle === '1'">
      <b-col>
        <b-form-group label="Grade"
                      label-for="tem-name"
        >
          <b-form-select
            v-model="selectedGradeId"
            @change="getSubjectForGrade"
          >
            <option
              v-for="grade of [{text: 'Select Grade', value: null}, ...grades]"
              :key="grade.id"
              :value="grade.id"
            >
              {{ grade.name }}
            </option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Subject"
                      label-for="tem-name"
        >
          <b-form-select
            v-model="selectedSubjectId"
            :disabled="!selectedGradeId"
            @change="getStandardDomain"
          >
            <option
              v-for="subject of [{text: 'Select Subject', value: null}, ...subjectList]"
              :key="subject.id"
              :value="subject.id"
            >
              {{ subject.name }}
            </option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Domain"
                      label-for="tem-name"
        >
          <b-form-select
            v-model="standardDomain"
            :disabled="!selectedSubjectId"
            @change="getStandard"
          >
            <option
              v-for="domain of [{text: 'Select Subject', value: null}, ...domainList]"
              :key="domain.id"
              :value="domain.id"
            >
              {{ domain.name }}
            </option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Standard"
                      label-for="tem-name"
        >
          <v-select
            v-model="selectedStandard"
            :clearable="false"
            label="text"
            :options="standardList"
            :reduce="option => ({ value: option.value, text: option.text })"
            debounce="200"
            multiple
          />
        </b-form-group>
      </b-col>
    </template>
  </div>
</template>

<script>
import {
  BFormGroup, BCol, BFormSelect, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'AssociateStandard',
  components: {
    BCol,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    vSelect,
  },
  props: {
    grades: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      associateToggle: '0',
      selectedGradeId: null,
      selectedSubjectId: null,
      standardDomain: null,
      selectedStandard: [],
      subjectList: [],
      domainList: [],
      standardList: [],
      standard: null,
    }
  },
  methods: {
    getSubjectForGrade() {
      this.domainList = []
      this.standardList = []
      const subject = this.grades.find(grade => grade.id === this.selectedGradeId)?.subjects || []
      this.subjectList = subject
    },
    getStandardDomain() {
      this.standardDomain = null
      this.domainList = []
      this.standardList = []
      useJwt
        .getDomainBySubject({ subject_id: this.selectedSubjectId })
        .then(response => {
          this.domainList = response?.data?.data
        })
    },
    getStandard() {
      const standardList = this.domainList.find(domain => domain.id === this.standardDomain)?.standard || []
      this.standardList = standardList
        .filter(standard => standard.id !== this.standard?.id)
        .map(standard => ({
          text: standard.name,
          value: standard.id,
        }))
    },
    resetData() {
      this.associateToggle = '0'
      this.selectedGradeId = null
      this.selectedSubjectId = null
      this.standardDomain = null
      this.selectedStandard = []
    },
    setStandard(standard) {
      this.standard = standard
      if (standard?.standard_families) {
        this.selectedStandard = standard.standard_families.map(associate => ({
          text: associate.children.name,
          value: associate.children.id,
        }))
      }
    },
    getSelectedStandards() {
      return this.selectedStandard.map(item => item.value)
    },
  },
}
</script>
