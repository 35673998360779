<template>
  <div
    :id="componentId"
    :style="[
      setting.bgStyleType == 'color' ? {
        backgroundColor: setting.backgroundColor
      }: {
        backgroundImage: `url(${setting.imageUrl})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '0% 0%',
      }, getComputedStyles(setting)]"
    class="custom-editor"
  >
    <div
      v-if="setting.audioUrl"
      class="float-right"
    >
      <audio-player
        :key="setting.audioUrl"
        ref="backgroundMusic"
        :src="setting.audioUrl"
      />
    </div>
    <component
      :is="layoutComponent"
      :layout-setting="layoutSetting"
      :is-edit-mode="isEditMode"
      :get-computed-styles="getComputedStyles"
      class="wrapper"
      @updateLayout="handleLayoutCover"
    >
      <template
        v-for="slot of positions"
        #[slot.name]
      >
        <template
          v-for="division of slot.items"
        >
          <div
            :key="division.value"
            :draggable="isEditMode"
            :set="item = layoutPosition.find(item => item.position === division.value)"
            class="editor-height"
            :class="{
              'dotted-border': isEditMode
            }"
            :style="[getComputedStyles(layoutPosition.find(item => item.position === division.value) ?layoutPosition.find(item => item.position === division.value).settings : {} )]"
            :data-aos="division.animation"
            @dragstart="startDrag($event, division.value)"
            @drop="onDrop($event, division.value)"
            @dragover.prevent
            @dragenter.prevent
          >
            <Resizable
              v-if="item.isVisible"
              :key="item.position"
              :default-height="item.height"
              :is-edit-mode="isEditMode"
              :settings="item.settings"
              :adjustable="item.component !== 'ProblemBody'"
              :style="{
                marginTop: item.component === 'ProblemBody' && '-13px'
              }"
              :class="[item.component === 'ProblemBody' && 'animated-problem-body',item.component === 'ProblemBody' && setting.bodyTheme === 'transparent' && 'theme-transparent' ]"
              @updateHeight="height => handleHeightUpdate(layoutPosition.find(item => item.position === division.value), height)"
              @updateLayout="setting => handleUpdateLayout(layoutPosition.find(item => item.position === division.value), setting)"
              @handleRemove="handleRemove(layoutPosition.find(item => item.position === division.value))"
            >
              <slot
                v-if="item.component === 'ProblemBody'"
                name="problem-body"
                class="p-2"
              />
              <component
                :is="item.component"
                v-else
                :key="JSON.stringify(item.elementData)"
                ref="layouts"
                :can-start-animation="canStartAnimation"
                :element-data="item.elementData"
                :is-edit-mode="isEditMode"
                @handleCreate="(allData) => handleCreate(allData)"
                @handleEdit="(allData, index) => handleEdit(allData, index)"
                @showImagePreview="handleShowImagePreview"
              />
            </Resizable>
          </div>
        </template>
      </template>
    </component>
    <element-control
      :show="showEditOption"
      :editor-data="editorDataToEdit"
      :index-to-edit="indexToEdit"
      @close="handleClose"
      @destroyData="handleDestroyData"
    />
    <span v-html="getStyleString" />
    <FsLightbox
      :toggler="imagePreviewToggler"
      :slide="slide"
      :sources="previewImages.map(img => img)"
      :types="[...new Array(previewImages.length).fill('image')]"
    />
  </div>
</template>
<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'
import FsLightbox from 'fslightbox-vue'
import FirstElement from './FirstEditor.vue'
import ElementControl from '../ElementControl.vue'
import SmallBottomBigTop from './layouts/SmallBottomBigTop.vue'
import SmallLeftBigRight from './layouts/SmallLeftBigRight.vue'
import SmallRightBigLeft from './layouts/SmallRightBigLeft.vue'
import SmallTopBigBottom from './layouts/SmallTopBigBottom.vue'
import Resizable from '../../helper/Resizable.vue'

export default {
  components: {
    FirstElement,
    ElementControl,
    BRow,
    BCol,
    BCard,
    SmallBottomBigTop,
    SmallLeftBigRight,
    SmallRightBigLeft,
    SmallTopBigBottom,
    Resizable,
    AudioPlayer,
    FsLightbox,
  },
  props: {
    initialLayoutPosition: {
      type: Array,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    setting: {
      type: Object,
      default: () => {},
    },
    layoutSetting: {
      type: Object,
      default: () => {},
    },
    templateId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showEditOption: false,
      editorDataToEdit: [],
      indexToEdit: null,
      canStartAnimation: false,
      imagePreviewToggler: false,
      slide: 0,
      componentId: this.templateId ? `animation-${this.templateId}` : Math.random().toString(36).substring(2, 10),
    }
  },
  computed: {
    previewImages() {
      let images = []
      this.initialLayoutPosition.forEach(item => {
        images = [...images, ...item.elementData.filter(element => element.imageUrl).map(i => i.imageUrl)]
      })
      return images
    },
    layoutPosition: {
      get() {
        return this.initialLayoutPosition
      },
      set(value) {
        this.$emit('layoutPosition', value)
      },
    },
    positions() {
      return [
        {
          name: 'small',
          items: [
            {
              value: 'LEFT_TOP',
            },
            {
              value: 'LEFT_BOTTOM',
            },
          ],
        },
        {
          name: 'big',
          items: [
            {
              value: 'RIGHT_TOP',
            },
            {
              value: 'RIGHT_BOTTOM',
            },
          ],
        },
      ]
    },
    layoutComponent() {
      switch (this.setting.layout) {
        case 'small-left-big-right':
          return SmallLeftBigRight
        case 'small-right-big-right':
          return SmallRightBigLeft
        case 'small-top-big-bottom':
          return SmallTopBigBottom
        case 'small-bottom-big-top':
          return SmallBottomBigTop
        default:
          return SmallLeftBigRight
      }
    },
    getStyleString() {
      const bodySetting = this.initialLayoutPosition.find(item => item.component === 'ProblemBody')?.settings
      const textColor = bodySetting.textColor || 'black'
      if (bodySetting) {
        return `
       <style type="text/css">
          #${this.componentId} div.theme-transparent .animated-problem-body *:not(.no-transparent):not(strong):not([style]) {
            color: ${textColor} !important;
          }
          #${this.componentId} div.theme-transparent .mtf-main-content .match-text,
          #${this.componentId} div.theme-transparent .text-transparent-theme,
          #${this.componentId} div.theme-transparent h3,
          #${this.componentId} div.theme-transparent p,
          #${this.componentId} div.theme-transparent .problem-overview-content .showcase-text h3,
          #${this.componentId} div.theme-transparent .problem-overview-content .showcase-text h2,
          #${this.componentId} div.theme-transparent .problem-card-title h4 {
            color: ${textColor} !important;
          }
          #${this.componentId} div.theme-transparent div {
            color: ${textColor};
          }
        </style>
      `
      }
      return ''
    },
  },
  watch: {
    setting: {
      handler(newValue) {
        if (newValue.audioUrl) {
          setTimeout(() => {
            this.$refs.backgroundMusic.playPause()
          }, 1000)
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleShowImagePreview(image) {
      if (!this.isEditMode) {
        this.imagePreviewToggler = !this.imagePreviewToggler
        this.slide = this.previewImages.findIndex(item => item === image) + 1
      }
    },
    // animation begins from manager
    async startAnimating() {
      // eslint-disable-next-line no-restricted-syntax
      for (const layout of this.$refs.layouts) {
        // eslint-disable-next-line no-await-in-loop
        await layout.renderWithAnimation(true)
      }
      this.setCanAnimate()
    },
    setCanAnimate() {
      this.canStartAnimation = true
    },
    handleHeightUpdate(item, value) {
      // eslint-disable-next-line no-param-reassign
      item.height = value
    },
    handleUpdateLayout(item, setting) {
      // eslint-disable-next-line no-param-reassign
      item.settings = setting
    },
    handleEdit(all, index) {
      if (!this.isEditMode) {
        return
      }
      this.editorDataToEdit = all
      this.indexToEdit = index
      this.showEditOption = true
    },
    handleClose() {
      this.showEditOption = false
    },
    handleDestroyData() {
      this.editorDataToEdit = []
      this.indexToEdit = null
      this.$forceUpdate()
    },
    handleCreate(all) {
      if (!this.isEditMode) {
        return
      }
      this.editorDataToEdit = all
      this.showEditOption = true
      this.indexToEdit = null
    },
    handleRemove(item) {
      // eslint-disable-next-line no-param-reassign
      item.isVisible = false
    },
    startDrag(evt, position) {
      // eslint-disable-next-line no-param-reassign
      evt.dataTransfer.dropEffect = 'move'
      // eslint-disable-next-line no-param-reassign
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('positionLabel', position)
    },
    onDrop(evt, position) {
      if (evt.preventDefault) { evt.preventDefault() }
      if (evt.stopPropagation) { evt.stopPropagation() }
      const swapPosition = evt.dataTransfer.getData('positionLabel')
      const currentLayout = [...this.layoutPosition]
      const firstPos = currentLayout.findIndex(item => item.position === position)
      const secondPos = currentLayout.findIndex(item => item.position === swapPosition)
      currentLayout[firstPos].position = swapPosition
      currentLayout[secondPos].position = position
      this.layoutPosition = [...currentLayout]
    },
    getComputedStyles(settings) {
      return {
        paddingTop: `${settings?.paddingTop}px`,
        paddingRight: `${settings?.paddingRight}px`,
        paddingBottom: `${settings?.paddingBottom}px`,
        paddingLeft: `${settings?.paddingLeft}px`,
        marginTop: `${settings?.marginTop}px`,
        marginRight: `${settings?.marginRight}px`,
        marginBottom: `${settings?.marginBottom}px`,
        marginLeft: `${settings?.marginLeft}px`,
        borderTopLeftRadius: `${settings?.borderTopLeftRadius}px`,
        borderTopRightRadius: `${settings?.borderTopRightRadius}px`,
        borderBottomLeftRadius: `${settings?.borderBottomLeftRadius}px`,
        borderBottomRightRadius: `${settings?.borderBottomRightRadius}px`,
      }
    },
    handleLayoutCover(setting, position) {
      this.layoutSetting[position] = { ...setting }
    },
  },
}
</script>
<style lang="scss">
  .dotted-border {
       /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }
  .custom-editor .audio-play-stop {
    width: 10px !important;
    height: 10px !important;
  }
  .editor-height {
    margin-bottom: 1rem !important
  }
  @media (max-width: 576px) {
  .editor-height {
    margin-bottom: 34px !important
  }
}
</style>
