<template>
  <div class="row ">
    <div class="col-3 text-center">
      <b-button
        variant="gradient-primary"
        size="lg"
        class="btn-icon rounded-circle"
        @click="playWaveFormAudio()"
      >
        <feather-icon :icon="isPlaying ? 'PauseIcon' : 'PlayIcon'" />
      </b-button>
    </div>
    <div class="col-8">
      <div
        ref="waveform"
        style="width: 100%; height: 100px"
      />
      <audio
        ref="waveform_audio"
        :src="audio"
      />
    </div>
  </div>
</template>
<script>
import WFPlayer from 'wfplayer'
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    audio: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isPlaying: false,
    }
  },
  mounted() {
    // eslint-disable-next-line no-unused-expressions
    this.$refs.waveform_audio && this.$refs.waveform_audio.addEventListener('ended', () => {
      this.isPlaying = false
    })
  },
  methods: {
    playWaveFormAudio() {
      const audioSrc = this.audio
      try {
        const wf = new WFPlayer({
          container: this.$refs.waveform,
          mediaElement: this.$refs.waveform_audio,
        })
        wf.load(audioSrc)
        // click event
        wf.on('click', currentTime => {
          wf.seek(currentTime)
        })

        // grab event
        wf.on('grabbing', currentTime => {
          wf.seek(currentTime)
        })

        // scroll event
        wf.on('scroll', deltaY => {
          wf.seek(wf.currentTime + deltaY / 10)
        })
      } catch {
        console.error('audio already mounted')
      } finally {
        if (this.isPlaying) {
          this.isPlaying = false
          this.$refs.waveform_audio.pause()
        } else {
          this.isPlaying = true
          this.$refs.waveform_audio.play()
        }

        // this.$root.$emit('forceStopRecording')
      }
    },
  },
}
</script>
