<template>
  <b-row>
    <b-col class="my-1 d-flex justify-content-center">
      <div>
        <b-pagination
          v-model="pagination.current_page"
          :total-rows="pagination.total"
          :per-page="pagination.per_page"
          :disabled="isProcessing"
          aria-controls="my-table"
          first-number
          last-number
          @input="(value) => $emit('onPaginationChange', value)"
        >
          <template #page="{ page, index }">
            <!-- Call the function passed from the parent to render custom page labels -->
            <span>
              {{ customPageSlot ? customPageSlot(page, index) : page }}
            </span>
          </template>
        </b-pagination>
        <div v-if="jumpTo"
             class="ml-2"
             style="margin-top: 4px"
        >
          Jump to <input v-model.lazy="pagination.current_page"
                         :disabled="isProcessing"
                         style="width: 80px; display: inline"
                         class="mx-1 form-control"
                         type="number"
                         placeholder="page-number"
          > of {{ pagination.total }}
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BPagination,
} from 'bootstrap-vue'

export default ({
  components: {
    BRow, BCol, BPagination,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    customPageSlot: {
      type: Function, // Function passed from parent to customize the page number rendering
      required: false,
    },
    jumpTo: {
      type: Boolean,
      default: false,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Object,
      default: () => ({
        total: 0,
        per_page: 0,
        current_page: 1,
        last_page: 1,
      }),
    },
  },
})
</script>
