export default {
  namespaced: true,
  state: {
    activeViewOfLab: '',
    studentAnswer: '',
    districtSetting: [],
    lessonMode: 'normal',
    isCbEditMode: false,
    metaInfoBeingEdited: {},
  },
  getters: {
    isProblemHintsHiddenByDistrict(state) {
      // eslint-disable-next-line eqeqeq
      return state.districtSetting.find(i => i.setting_name === 'hide_problem_hints')?.value == '1'
    },
    canAbortLesson(state) {
      // eslint-disable-next-line eqeqeq
      return state.districtSetting.find(i => i.setting_name === 'abort_lesson')?.value == '1'
    },
    isAClassTest(state) {
      return state.lessonMode === 'class_test'
    },
  },
  mutations: {
    SET_ACTIVE_LAB_VIEW(state, payload) {
      state.activeViewOfLab = payload
    },
    SET_STUDENT_ANSWER(state, payload) {
      state.studentAnswer = payload
    },
    SET_DISTRICT_SETTINGS(state, payload) {
      state.districtSetting = payload
    },
    SET_LESSON_MODE(state, payload) {
      state.lessonMode = payload
    },
    SET_IS_CB_EDIT_MODE(state, payload) {
      state.isCbEditMode = payload
    },
    SET_META_INFO_BEING_EDITED(state, payload) {
      state.metaInfoBeingEdited = payload
    },
  },
  actions: {
    updateMetaInfoBeingEdited({ commit, state }, payload) {
      const currentMeta = { ...state.metaInfoBeingEdited }
      currentMeta[payload.type] = payload.content
      commit('SET_META_INFO_BEING_EDITED', currentMeta)
    },
    updateAndSaveMetaContent({ state }, payload) {
      console.log(state.metaInfoBeingEdited, payload)
    },
  },
}
