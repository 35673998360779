<template>
  <div>
    <h4>Child Process</h4>
    <b-form-group label="Step">
      <b-form-select v-model="form.child_id"
                     :options="selectAbleChild"
                     text-field="name"
                     value-field="id"
                     placeholder="Select Child Step"
      />
    </b-form-group>
    <b-form-group label="Variables">
      <b-form-tags
        v-model="form.variables"
        class="mb-2"
        remove-on-delete
      />
    </b-form-group>
  </div>
</template>
<script>
import { BFormSelect, BFormGroup, BFormTags } from 'bootstrap-vue'

export default {
  components: {
    BFormSelect,
    BFormGroup,
    BFormTags,
  },
  props: {
    processId: {
      type: Number,
      required: true,
    },
    allProcess: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    selectAbleChild() {
      return [
        {
          name: 'Select Step',
          value: undefined,
        },
        ...this.allProcess.filter(i => i.id !== this.processId).map(item => ({
          name: item.name,
          id: item.id,
        }))]
    },
  },
}

</script>
