import { render, staticRenderFns } from "./GameSelector.vue?vue&type=template&id=4b0e07ac"
import script from "./GameSelector.vue?vue&type=script&lang=js"
export * from "./GameSelector.vue?vue&type=script&lang=js"
import style0 from "./GameSelector.vue?vue&type=style&index=0&id=4b0e07ac&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports