/* eslint-disable no-underscore-dangle */
/* eslint-disable no-underscore-dangle */
<template>
  <div
    id="balloon-container"
    class="congrz-window"
  >
    <div
      class="js-container container"
      style="top:0px !important;"
    />

    <div class="check-mark-area">
      <div class="checkmark-circle">
        <div class="background" />
        <div class="checkmark draw" />
      </div>
      <h1 class="pt-2">
        {{ $t('student-report-module.congratulations') }}🎉
      </h1>
      <h2>{{ self.fullname }}</h2>
      <p>{{ $t('student-report-module.you-have-completed') }}<b>{{ lesson }}</b>. {{ $t('student-report-module.well-done') }}</p>
      <button
        v-if="!hideResult"
        class="submit-btn"
        type="submit"
        @click="$emit('viewResult')"
      >
        <b-spinner v-show="processing" />
        {{ $t('student-report-module.show-result') }}
      </button>
    </div>

  </div>
</template>
<script>
import { BSpinner } from 'bootstrap-vue'
import { STATUS_RIGHT, STATUS_IDLE } from '@/const/avatar'
import { isVisible } from '@/utils/visibilitySettings'

export default {
  components: {
    BSpinner,
  },
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
    self: {
      type: Object,
      default: () => {},
    },
    lesson: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      characterTimeout: null,
    }
  },
  computed: {
    currentStatus() {
      return this.$store.state.appConfig.currentStatus
    },

    hideResult() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('lesson_result', schoolSetting, distSetting)
    },
  },
  watch: {
  },
  mounted() {
    this.$store.dispatch('appConfig/setRefreshRewardStats', true)
    this.$store.commit('appConfig/UPDATE_CURRENT_STATUS', STATUS_RIGHT)
    if (this.characterTimeout) clearTimeout(this.characterTimeout)
    this.characterTimeout = setTimeout(() => {
      this.$store.commit('appConfig/UPDATE_CURRENT_STATUS', STATUS_IDLE)
    }, 7000)
    const Confettiful = function (el) {
      this.el = el
      this.containerEl = null

      this.confettiFrequency = 3
      this.confettiColors = ['#EF2964', '#00C09D', '#2D87B0', '#48485E', '#EFFF1D']
      this.confettiAnimations = ['medium']

      // eslint-disable-next-line no-underscore-dangle
      this._setupElements()
      // eslint-disable-next-line no-underscore-dangle
      this._renderConfetti()
    }

    // eslint-disable-next-line no-underscore-dangle
    Confettiful.prototype._setupElements = function () {
      const containerEl = document.createElement('div')
      const elPosition = this.el.style.position

      if (elPosition !== 'relative' || elPosition !== 'absolute') {
        this.el.style.position = 'relative'
      }

      containerEl.classList.add('confetti-container')

      this.el.appendChild(containerEl)

      this.containerEl = containerEl
    }

    // eslint-disable-next-line no-underscore-dangle
    Confettiful.prototype._renderConfetti = function () {
      this.confettiInterval = setInterval(() => {
        const confettiEl = document.createElement('div')
        const confettiSize = `${Math.floor(Math.random() * 3) + 10}px`
        const confettiBackground = this.confettiColors[Math.floor(Math.random() * this.confettiColors.length)]
        const confettiLeft = `${Math.floor(Math.random() * this.el.offsetWidth)}px`
        const confettiAnimation = this.confettiAnimations[Math.floor(Math.random() * this.confettiAnimations.length)]

        confettiEl.classList.add('confetti', `confetti--animation-${confettiAnimation}`)
        confettiEl.style.left = confettiLeft
        confettiEl.style.width = confettiSize
        confettiEl.style.height = confettiSize
        confettiEl.style.backgroundColor = confettiBackground

        confettiEl.removeTimeout = setTimeout(() => {
          confettiEl.parentNode.removeChild(confettiEl)
        }, 3000)

        this.containerEl.appendChild(confettiEl)
      }, 25)
    }

    window.confettiful = new Confettiful(document.querySelector('.congrz-window .js-container'))
  },
}
</script>
<style lang="scss">

.congrz-window {

@keyframes confetti-slow {
  0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }

  100% { transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg); }
}

@keyframes confetti-medium {
  0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }

  100% { transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg); }
}

@keyframes confetti-fast {
  0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }

  100% { transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg); }
}

.container {
  height: 70vh;
  background: #ffffff;
  border:1px solid white;
  display:fixed;
  top:0px;
}

.confetti-container {
  perspective: 700px;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.confetti {
  position: absolute;
  z-index: 1;
  top: -10px;
  border-radius: 0%;

  &--animation-slow {
    animation: confetti-slow 2.25s linear 1 forwards;
  }

  &--animation-medium {
    animation: confetti-medium 1.75s linear 1 forwards;
  }

  &--animation-fast {
    animation: confetti-fast 1.25s linear 1 forwards;
  }
}

/* Checkmark */
.checkmark-circle {
  width: 150px;
  height: 150px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: auto;
  margin-right: auto;
}
.checkmark-circle .background {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #00C09D;
  position: absolute;
}
.checkmark-circle .checkmark {
  border-radius: 5px;
}
.checkmark-circle .checkmark.draw:after {
  -webkit-animation-delay: 100ms;
  -moz-animation-delay: 100ms;
  animation-delay: 100ms;
  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-name: checkmark;
  -moz-animation-name: checkmark;
  animation-name: checkmark;
  -webkit-transform: scaleX(-1) rotate(135deg);
  -moz-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  -o-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.checkmark-circle .checkmark:after {
  opacity: 1;
  height: 75px;
  width: 37.5px;
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
  border-right: 15px solid white;
  border-top: 15px solid white;
  border-radius: 2.5px !important;
  content: '';
  left: 38px;
  top: 85px;
  position: absolute;
}

@-webkit-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}
@-moz-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}

.submit-btn {
    height: 45px;
    width: 200px;
    font-size: 15px;
    background-color: #00c09d;
    border: 1px solid #00ab8c;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(87,71,81,.2);
    cursor: pointer;
    transition: all 2s ease-out;
    transition: all .2s ease-out;
}

.submit-btn:hover {
    background-color: #2ca893;
    transition: all .2s ease-out;
}
}

.check-mark-area {
  text-align: center;
  margin-top: 10vh;
  position:  absolute;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
}

</style>
