<template>
  <!-- course rating model -->
  <b-modal
    id="modal-course-rating"
    ref="modal-course-rating"
    ok-only
    size="md"
    ok-variant="primary"
    modal-class="modal-primary"
    centered
    :title="$t('rating.rate-course')"
  >
    <b-col md="12">
      <!-- form -->
      <validation-observer ref="ratingForm">
        <!-- <b-form class="course-rating-from"> -->
        <b-row>
          <!-- <b-col v-if="isProcessing">
            <b-row class="d-flex justify-content-center">
              <b-spinner />
            </b-row>
          </b-col> -->
          <b-col md="12">
            <b-row
              v-for="(criterion, index) in criteria"
              :key="criterion.id"
              class="d-flex align-items-center mb-1"
            >
              <b-col md="8">
                <span>{{ criterion.title }}</span>
              </b-col>
              <b-col md="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="rating"
                  rules="required"
                  :vid="`courseRating-${criterion.id}`"
                >
                  <b-form-rating
                    :id="`criteria-${criterion.id}`"
                    v-model="criteriaRatings[index]"
                    color="#7367f0"
                    inline
                    no-border
                    @change="criteriaIds[index] = criterion.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col md="12">
                <span>{{ $t("rating.comment") }}</span>
                <validation-provider
                  v-slot="{ errors }"
                  name="comment"
                  rules="required"
                  vid="rating-comment"
                >
                  <b-form-textarea
                    id="rating-comment"
                    v-model="ratingComment"
                    size="md"
                    :placeholder="$t('rating.enter-something')"
                    rows="3"
                    max-rows="6"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- </b-form> -->
      </validation-observer>
    </b-col>
    <template #modal-footer="{}">
      <b-row class="d-flex justify-content-between">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="mr-2"
          variant="primary"
          @click="$emit('showAll')"
        >
          <span>{{ $t("rating.show") }}</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          :disabled="isProcessing"
          @click="submit"
        >
          <b-spinner
            v-show="isProcessing"
            class="mr-1"
            small
          />
          <span>{{ $t("rating.rate") }}</span>
        </b-button>
      </b-row>
    </template>
  </b-modal>
  <!-- course rating model -->
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
  BModal,
  BFormRating,
  BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'

export default {
  components: {
    BRow,
    BSpinner,
    BModal,
    BFormRating,
    BCol,
    BButton,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    criteria: {
      type: Array,
      default: () => {},
    },
    isProcessing: {
      type: Boolean,
      default: () => {},
    },
  },
  data() {
    return {
      ratingComment: '',
      criteriaRatings: [],
      criteriaIds: [],
    }
  },
  methods: {
    showModal() {
      this.$refs['modal-course-rating'].show()
    },
    hideModal() {
      this.$refs['modal-course-rating'].hide()
    },
    submit() {
      this.$refs.ratingForm.validate().then(success => {
        if (success) {
          // this.isProcessing = true
          const payload = {
            criteriaRatings: this.criteriaRatings,
            criteriaIds: this.criteriaIds,
            comment: this.ratingComment,
          }
          this.hideModal()
          this.$emit('submit', payload)
        }
      })
    },
    clearFields() {
      this.criteriaRatings = []
      this.criteriaIds = []
      this.ratingComment = null
      // this.isProcessing = false
    },
  },
}
</script>

<style>
</style>
