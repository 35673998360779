<template>
  <b-card class="my-2">
    <b-row>
      <b-col md="12">
        <h3>{{ $t('essay-builder-module.introduction') }}</h3>
      </b-col>
      <b-col md="12">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('essay-builder-module.general-statement')"
          rules="required|max:256"
          vid="general-statement"
        >
          <b-form-group
            :label="$t('essay-builder-module.general-statement')"
            label-for="general-statement"
          >

            <b-row>
              <b-col md="12">
                <b-form-textarea
                  v-model="data.general_statement"
                  :disabled="!isEditable"
                  style="padding-right:3rem !important"
                />
                <feather-icon
                  v-if="isEditable"
                  :icon="!fetchingHints.includes('GS_0') ? 'AlertCircleIcon':'LoaderIcon'"
                  size="22"
                  class="hint-icon text-primary"
                  @click="!fetchingHints.includes('GS_0') ? $emit('getHint',null,{type:'GS',order_index:0}) : ''"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-col>
              <b-col md="12">
                <b-alert
                  variant="success"
                  class="mt-1"
                  :show="'GS_0' in hints"
                >
                  <div class="alert-body">
                    <span>{{ hints['GS_0'] }}</span>
                  </div>
                </b-alert>
              </b-col>
            </b-row>

          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('essay-builder-module.main-points')"
          label-for="main-points"
        >
          <div class="pl-2">
            <div
              v-for="(key,ind) in data.main_points.length"
              :key="ind"
              class="d-flex align-content-center"
              style="margin-bottom:8px"
            >

              <div
                class="d-flex flex-column"
                style="flex:1"
              >
                <div
                  class="d-flex"
                >
                  <div
                    class="d-flex align-items-center"
                    style="width:20px"
                  >
                    <label for="no"> {{ ind+1 }}.</label>
                  </div>
                  <div
                    style="flex:1"
                    class="position-relative"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="`${$t('essay-builder-module.main-points')} ${(ind+1)}`"
                      rules="required|max:256"
                      :vid="`main-points_introduction_[${ind}]`"
                    >
                      <b-form-input
                        v-model="data.main_points[ind]"
                        class="pr-3"
                        :disabled="!isEditable"
                      />

                      <feather-icon
                        v-if="isEditable"
                        :icon="!fetchingHints.includes(`MP_${ind+1}`) ? 'AlertCircleIcon':'LoaderIcon'"
                        size="22"
                        class="hint-icon text-primary"
                        @click="$emit('getHint',null,{type:`MP`,order_index:ind+1})"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </div>
                <b-alert
                  variant="success"
                  class="mt-1"
                  :show="`MP_${ind+1}` in hints"
                >
                  <div class="alert-body">
                    <span>{{ hints[`MP_${ind+1}`] }}</span>
                  </div>
                </b-alert>
              </div>
            </div>
          </div>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('essay-builder-module.thesis-statement')"
          rules="required|max:256"
          vid="thesis-statement"
        >
          <b-form-group
            :label="$t('essay-builder-module.thesis-statement')"
            label-for="thesis-statement"
          >
            <b-form-textarea
              v-model="data.thesis_statement"
              :disabled="!isEditable"
            />

            <feather-icon
              v-if="isEditable"
              :icon="!fetchingHints.includes('TS') ? 'AlertCircleIcon':'LoaderIcon'"
              size="22"
              class="hint-icon text-primary"
              @click="$emit('getHint',null,{type:'TS',order_index:0})"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <b-alert
              variant="success"
              :show="'TS_0' in hints"
              class="mt-1"
            >
              <div class="alert-body">
                <span>{{ hints['TS_0'] }}</span>
              </div>
            </b-alert>

          </b-form-group>
        </validation-provider></b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCol, BRow, BFormTextarea, BCard, BFormGroup, BFormInput, BAlert,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full'

export default {
  components: {
    BCol, BRow, BFormTextarea, ValidationProvider, BCard, BFormGroup, BFormInput, BAlert,
  },
  props: {
    hints: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
    fetchingHints: {
      type: Array,
      required: true,
    },
  },
}
</script>
