<template>
  <div>
    <validation-observer ref="matchingForm">

      <!-- Question -->
      <div
        v-for="(question, j) in questionLength"
        :key="j"
      >
        <b-row>
          <!-- Question -->
          <b-col md="5">
            <!-- Text -->
            <b-form-group
              v-if="questionType === 'text'"
              :label="$t('labels.create-problem.question')"
              label-for="question"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="question"
                :vid="`question-text-${level}-${j}`"
              >
                <b-form-textarea
                  v-model="questions.text[j]"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('labels.create-problem.placeholder.question')"
                  name="question"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- /Text -->
            <!-- Image -->
            <b-form-group
              v-else-if="questionType === 'image'"
              label-for="question"
              :label="$t('labels.create-problem.question')"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                :vid="`question-image-${level}-${j}`"
                name="question"
              >
                <image-form
                  :url="questions.imageUrl[j]"
                  :caption="questions.caption[j]"
                  :show-caption="true"
                  @input="handleImage($event, 'questions', j)"
                  @fileTypeInvalid="setError([`question-image-${level}-${j}`], $event)"
                  @fileSizeInvalid="setError([`question-image-${level}-${j}`], $event)"
                />
                <input
                  v-model="questions.imageUrl[j]"
                  hidden
                >
                <small class="text-danger">{{ errors[0] }}</small><br>
              </validation-provider>
            </b-form-group>
            <!-- Image -->
            <!-- Audio -->
            <b-form-group
              v-else-if="questionType === 'audio'"
              :label="$t('labels.create-problem.question')"
              label-for="audio"
            >
              <Recorder
                :url="questions.audioUrl[j]"
                @input="setAudio(j, 'questions', $event)"
                @fileTypeInvalid="setError([`question-audio-${level}-${j}`], $event)"
                @fileSizeInvalid="setError([`question-audio-${level}-${j}`], $event)"
              />
              <validation-provider
                v-slot="{ errors }"
                name="question"
                rules="required"
                :vid="`question-audio-${level}-${j}`"
              >
                <input
                  v-model="questions.audioUrl[j]"
                  hidden
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Audio -->
          </b-col>
          <!-- /Question -->

          <!-- Answer -->
          <b-col md="5">
            <!-- Text -->
            <b-form-group
              v-if="answerType === 'text'"
              :label="$t('labels.create-problem.answer')"
              label-for="answer"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="answer"
                :vid="`answer-text-${level}-${j}`"
              >
                <b-form-textarea
                  v-model="answers.text[j]"
                  name="answer"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('labels.create-problem.placeholder.answer')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- /Text -->
            <!-- Image -->
            <b-form-group
              v-else-if="answerType === 'image'"
              :label="$t('labels.create-problem.answer')"
              label-for="article-image"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="answer"
                :vid="`answer-image-${level}-${j}`"
              >
                <image-form
                  :url="answers.imageUrl[j]"
                  :caption="answers.caption[j]"
                  :show-caption="true"
                  @input="handleImage($event, 'answers', j)"
                  @fileTypeInvalid="setError([`answer-image-${level}-${j}`], $event)"
                  @fileSizeInvalid="setError([`answer-image-${level}-${j}`], $event)"
                />
                <input
                  v-model="answers.imageUrl[j]"
                  hidden
                >
                <small class="text-danger">{{ errors[0] }}</small><br>
              </validation-provider>
            </b-form-group>
            <!-- Image -->
            <!-- Audio -->
            <b-form-group
              v-else-if="answerType === 'audio'"
              :label="$t('labels.create-problem.answer')"
              label-for="article-image"
            >
              <Recorder
                :url="answers.audioUrl[j]"
                @input="setAudio(j, 'answers', $event)"
                @fileTypeInvalid="setError([`answer-audio-${level}-${j}`], $event)"
                @fileSizeInvalid="setError([`answer-audio-${level}-${j}`], $event)"
              />
              <validation-provider
                v-slot="{ errors }"
                name="answer"
                rules="required"
                :vid="`answer-audio-${level}-${j}`"
              >
                <input
                  v-model="answers.audioUrl[j]"
                  hidden
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Audio -->
          </b-col>
          <!-- /Answer -->

          <b-col
            v-if="j > 0"
            class="mt-2"
            md="2"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="btn-icon mr-1"
              @click="askToDelete(j)"
            >
              <feather-icon icon="TrashIcon" />
              {{ $t("Delete") }}
            </b-button>
          </b-col>
        </b-row>
        <hr>
      </div>
      <!-- Question / Answer -->

      <!-- delete item -->
      <b-modal
        id="modal-delete"
        ref="modal-delete"
        ok-only
        ok-variant="danger"
        :ok-title="$t('confirm')"
        modal-class="modal-danger"
        centered
        :title="$t('actions.delete-answer')"
        @ok="deleteQuestion()"
      >
        <b-card-text>
          {{ $t("questions.delete-answer") }}
        </b-card-text>
        <template #modal-footer="{ ok }">
          <b-row>
            <b-col
              md="12"
              class="d-flex justify-content-end"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="danger"
                :disabled="isProcessing"
                @click="ok()"
              >
                <b-spinner
                  v-show="isProcessing"
                  small
                />
                {{ $t("confirm") }}
              </b-button>
            </b-col>
          </b-row>
        </template>
      </b-modal>
      <!-- delete item -->

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="btn-icon"
        @click="addQuestion"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t("Add-New") }}
      </b-button>

    </validation-observer>
  </div>
</template>

<script>
import {
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BModal,
  BCardText,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import Ripple from 'vue-ripple-directive'
import Recorder from '../recorder.vue'
import ImageForm from '../ImageForm.vue'

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BButton,
    ImageForm,
    Recorder,
    BModal,
    BCardText,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    level: {
      type: Number,
      default: () => 0,
    },
    questionType: {
      type: String,
      default: () => 'text',
    },
    answerType: {
      type: String,
      default: () => 'text',
    },
  },
  data: () => ({
    questions: {
      text: [''],
      image: [''],
      imageUrl: [''],
      caption: [''],
      audio: [''],
      audioUrl: [''],
    },
    answers: {
      text: [''],
      image: [''],
      imageUrl: [''],
      caption: [''],
      audio: [''],
      audioUrl: [''],
    },
    deletePayloadIndex: null,
    isProcessing: false,
  }),
  computed: {
    questionLength() {
      return this.$store.getters['problem/getQuestionLength']
    },
    activeQuestions() {
      return this.$store.getters['problem/getQuestions']
    },
    activeAnswers() {
      return this.$store.getters['problem/getAnswers']
    },
    deleteIndex() {
      return this.$store.state.problem.create.matching.deleteIndex
    },
    showError: {
      get() {
        return this.$store.state.problem.showError
      },
      set() {},
    },
  },
  watch: {
    questions: {
      deep: true,
      handler() {
        this.questions[this.questionType].forEach((q, index) => {
          let payload
          if (this.questionType === 'text') {
            payload = {
              type: 'matching', level: this.level, index, question: q,
            }
          } else if (this.questionType === 'image') {
            payload = {
              type: 'matching', level: this.level, index, question: { image: q, imageUrl: this.questions.imageUrl[index], caption: this.questions.caption[index] },
            }
          } else if (this.questionType === 'audio') {
            payload = {
              type: 'matching', level: this.level, index, question: { audio: q, audioUrl: this.questions.audioUrl[index] },
            }
          }
          this.$store.commit('problem/SET_QUESTION_FEILD', payload)
        })
      },
    },
    answers: {
      deep: true,
      handler() {
        this.answers[this.answerType].forEach((q, index) => {
          let payload
          if (this.answerType === 'text') {
            payload = {
              type: 'matching', level: this.level, index, answer: q,
            }
          } else if (this.answerType === 'image') {
            payload = {
              type: 'matching', level: this.level, index, answer: { image: q, imageUrl: this.answers.imageUrl[index], caption: this.answers.caption[index] },
            }
          } else if (this.answerType === 'audio') {
            payload = {
              type: 'matching', level: this.level, index, answer: { audio: q, audioUrl: this.answers.audioUrl[index] },
            }
          }
          this.$store.commit('problem/SET_ANSWER_FEILD', payload)
        })
      },
    },
    questionLength() {
      if (this.deleteIndex !== null) {
        Object.keys(this.questions).forEach(k => {
          this.questions[k].splice(this.deleteIndex, 1)
          this.answers[k].splice(this.deleteIndex, 1)
        })
      }
    },
    activeQuestions(value) {
      this.setQuestions(value)
    },
    activeAnswers(value) {
      this.setAnswers(value)
    },
    showError(value) {
      if (value) {
        this.$refs.matchingForm.validate()
      }
    },
  },
  created() {
    this.setQuestions(this.activeQuestions)
    this.setAnswers(this.activeAnswers)
  },
  methods: {
    addQuestion() {
      this.$store.commit('problem/ADD_QUESTIONS')
    },
    askToDelete(j) {
      this.deletePayloadIndex = j
      this.$refs['modal-delete'].toggle()
    },
    deleteQuestion() {
      this.$store.commit('problem/DELETE_QUESTIONS', { index: this.deletePayloadIndex })
    },
    handleImage({ image, url, caption }, type, j) {
      this[type].image[j] = image
      const imgList = this[type].imageUrl
      imgList[j] = url
      this[type].imageUrl = [...imgList]
      const imgCaption = this[type].caption
      imgCaption[j] = caption
      this[type].caption = [...imgCaption]
    },
    setAudio(j, type, { audio, url }) {
      const audioObj = this[type]
      audioObj.audio[j] = audio
      audioObj.audioUrl[j] = url
      this[type] = { ...audioObj }
    },
    setQuestions(value) {
      Object.keys(value).forEach(key => {
        value[key][this.level].forEach((q, index) => {
          this.questions[key][index] = q
        })
      })
    },
    setAnswers(value) {
      Object.keys(value).forEach(key => {
        value[key][this.level].forEach((a, index) => {
          this.answers[key][index] = a
        })
      })
    },
    setError(vid, msg) {
      this.$refs.matchingForm.setErrors({ [vid]: [msg] })
    },
  },
}
</script>
