<template>
  <b-row>
    <!-- Select Shape -->
    <b-col
      md="6"
      lg="3"
    >
      <b-form-group
        label="Select a shape:"
        class="mr-3 w-100"
        content-cols="12"
      >
        <b-form-select
          v-model="templateData.shape"
          :options="shapes"
          @change="selectTemplateData('shape')"
        />
      </b-form-group>
    </b-col>

    <!-- Select Problem Type -->
    <b-col
      md="6"
      lg="3"
    >
      <b-form-group
        label="Select Problem Type:"
        class="mr-3 w-100"
        content-cols="12"
      >
        <b-form-select
          v-model="templateData.type"
          :options="fibTypes"
          @change="selectTemplateData('type')"
        />
      </b-form-group>
    </b-col>

    <!-- Select Text Align -->
    <b-col
      md="6"
      lg="3"
    >
      <b-form-group
        v-if="templateData.shape !== 'hexagon.png'"
        label="Select Text Align:"
        class="mr-3 w-100"
        content-cols="12"
      >
        <b-form-select
          v-model="templateData.textAlign"
          :options="alignOptions"
          @change="selectTemplateData('textAlign')"
        />
      </b-form-group>
    </b-col>

    <!-- Select Background Color -->
    <b-col
      md="6"
      lg="3"
      class="mb-1"
    >
      <b-form-group
        v-if="templateData.shape !== 'hexagon.png'"
        label="Background Color:"
        class="bg-color mr-3"
        content-cols="5"
        content-cols-md="12"
      >
        <verte
          v-model="bgColor"
          picker="wheel"
          model="rgb"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import Verte from 'verte'
import 'verte/dist/verte.css'
import { mapGetters } from 'vuex'

export default {
  components: {
    Verte,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
  },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fibTypes: [
        { text: 'Fill in the Blanks', value: 'fib' },
        { text: 'Question and Answer', value: 'qa' },
      ],
      bgColor: this.templateData.bgColor,
      alignOptions: [
        { text: 'Left', value: 'left' },
        { text: 'Center', value: 'center' },
        { text: 'Right', value: 'right' },
      ],
    }
  },
  computed: {
    ...mapGetters('problem', { shapes: 'getShapes' }),
    selectedColor() {
      return this.bgColor
    },
  },
  watch: {
    selectedColor(val) {
      this.templateData.bgColor = val
      this.selectTemplateData('bgColor')
    },
  },
  methods: {
    selectTemplateData(type) {
      let data
      switch (type) {
        case 'shape':
          data = this.shapes.find(shape => shape.value === this.templateData.shape)
          break
        case 'type':
          data = this.fibTypes.find(fibType => fibType.value === this.templateData.type)
          break
        case 'bgColor':
          data = this.bgColor
          break
        case 'textAlign':
          data = this.alignOptions.find(align => align.value === this.templateData.textAlign)
          break
        default:
          break
      }
      this.$emit('template-data', type, data)
    },
  },
}
</script>

<style>
  .verte {
    z-index: 100;
  }
  .verte__guide {
    border: 2px solid #f6f6f6;
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
  .bg-color {
    width: 50%;
  }
  @media screen and (max-width: 1024px) {
    .bg-color {
      width: 100%;
    }
  }
</style>
