<template>
  <div id="lessonPlanner">
    <div class="d-flex justify-content-between mb-2 align-items-center">
      <h2 class="pb-1">
        Lesson Plans
      </h2>

      <b-button variant="outline-success"
                class="mb-1"
                @click="previewing=!previewing"
      >
        <feather-icon :icon="previewing ? 'EyeOffIcon' : 'EyeIcon' " />
        {{ previewing ? 'Hide Preview' : 'Preview' }}
      </b-button>
    </div>
    <div class="d-flex"
         style="gap:20px;"
    >
      <b-form-group label="Select Lesson">
        <b-form-select
          v-model="currentLessonId"
          :options="allLessonId"
          class="col-12"
          @change="fetchCurrentLessonPlanner"
        />
      </b-form-group>
      <b-form-group label=" Lesson Plans Type ">
        <b-form-select
          v-model="currentLessonPlanType"
          :options="plansTypes"
          @change="fetchCurrentLessonPlanner"
        />
      </b-form-group>
    </div>
    <validation-observer ref="lessonPlannerForm">
      <b-row
        v-if="!updating && !previewing"
        class="border p-2 mb-2"
      >

        <b-col cols="5">
          <b-form-group label="Course">
            <b-form-select
              v-model="course"
              placeholder="Please select course"
              :options="courses"
              @change="getLessonGroups"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Lesson Group">
            <b-form-select
              v-model="lessonGroup"
              placeholder="Please select course"
              :options="lessonGroups"
              @change="getLessons"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <validation-provider v-slot="{errors}"
                               name="Lesson"
                               :vid="`lesson${index}`"
                               rule="required"
          >
            <b-form-group label="Lesson">
              <b-form-select v-model="creatingLessonPlanners.lesson_id"
                             :options="lessons"
                             :class="errors.length > 0 ? 'is-invalid':null"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <template v-if="creatingLessonPlanners.form.length">
        <b-row v-for="form,index in creatingLessonPlanners.form"
               :key="index"
               class="border lesson-planner-form p-2 mb-2"
               :class="`${form.lesson_planner_type === 'online' && 'light-bg'} ${(form.lesson_planner_type === 'online' && previewing) && ' preview-bg-light '} ${form.lesson_planner_type === 'offline' && 'light-secondary'} ${(form.lesson_planner_type === 'offline' && previewing) && ' preview-bg-secondary '}`"
        >
          <b-row v-if="!previewing">
            <b-col v-if="form.lesson_planner_id"
                   cols="12"
                   class="d-flex justify-content-end pb-1"
            >
              <b-form-checkbox
                :id="`lesson-plan-checkbox${index}`"
                v-model="selectedLessonPlans"
                name="lesson-plan-checkbox"
                :value="{id:form.lesson_planner_id,text:form.title}"
                @change="$emit('updateSelectedPlans',selectedLessonPlans)"
              >
                Select to display lesson plan in ebook
              </b-form-checkbox>
            </b-col>
            <b-col v-if=" !previewing && currentLessonPlanType !== 'global-lesson-plan'"
                   cols="12"
                   class="text-right"
            >
              <h6 class="cursor-pointer text-danger"
                  @click="removeLessonPlanner(index)"
              >
                <feather-icon icon="XIcon"
                              size="22"
                /> Delete
              </h6>
            </b-col>

            <b-col md="6"
                   xl="8"
            >
              <validation-provider v-slot="{ errors }"
                                   name="Title"
                                   :vid="`title${index}`"
                                   rules="required"
              >
                <b-form-group label="Title">
                  <b-form-input v-model="form.title"
                                :disabled="currentLessonPlanType === 'global-lesson-plan'"
                                :class="errors.length > 0 ? 'is-invalid':null"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="3"
                   xl="2"
            >
              <b-form-group label="Type">
                <b-form-select v-model="form.lesson_planner_type"
                               :options="lessonPlanTypes"
                               :disabled="currentLessonPlanType === 'global-lesson-plan'"
                />
              </b-form-group>
            </b-col>
            <b-col md="3"
                   xl="2"
            >
              <b-form-group label="Visibility">
                <b-form-select v-model="form.access_type"
                               :options="accessTypes"
                               :disabled="currentLessonPlanType === 'global-lesson-plan'"
                />

              </b-form-group>
            </b-col>
            <b-col v-if="form.access_type=='share'"
                   md="4"
                   xl="3"
            >
              <b-form-group label="School">
                <v-select v-model="form.school_id"
                          :options="schools"
                          label="fullname"
                          :reduce="(school) => school.id"
                          placeholder="Choose schools"
                          :clearable="false"

                          :disabled="form.access_type !== 'share'"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="form.author"
                   cols="12"
                   class="text-right"
            >
              <h6>Author: {{ `${form.author.firstname} ${form.author.lastname}` }}</h6>
            </b-col>
            <b-col cols="12">
              <validation-provider v-slot="{ errors }"
                                   name="Content"
                                   :vid="`content${index}`"
                                   rules="required"
              >
                <b-form-group label="Content">
                  <quill-editor-custom v-model="form.content"
                                       :disabled="currentLessonPlanType === 'global-lesson-plan'"
                                       :class="errors.length > 0 ? 'is-invalid':null"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-col v-else
                 cols="12"
          >
            <div class="d-flex justify-content-end">
              <div :class="form.lesson_planner_type=='online'?'bg-success':'bg-secondary'"
                   style="height:15px; width:15px; border-radius:50%;"
              />
            </div>
            <h4 class="">
              {{ form.title }}
            </h4>
            <div v-html="form.content" />
          </b-col>

        </b-row>

        <b-col v-if="!previewing && currentLessonPlanType !== 'global-lesson-plan'"
               cols="12"
               class="text-right mb-2"
        >
          <b-button variant="primary"
                    @click="addNewLessonPlanner"
          >
            <feather-icon icon="PlusIcon" /> Add More
          </b-button>
        </b-col>

        <b-col v-if="!previewing && currentLessonPlanType !== 'global-lesson-plan'"
               cols="12"
               class="text-right "
        >
          <b-button v-if="updating"
                    variant="outline-danger"
                    @click="removeAllLessonPlanner"
          >
            Delete
          </b-button>
          <b-button variant="outline-primary"
                    class="ml-2"
                    :disabled="isProcessing"
                    @click="()=>{creatingLessonPlanners.form[0].lesson_planner_id ? updateLessonPlanner() : createLessonPlanner()} "
          >
            <b-spinner v-if="isProcessing"
                       small
            /> {{ creatingLessonPlanners.form[0].lesson_planner_id ? 'Update' : 'Create' }}
          </b-button>

        </b-col>
      </template>
      <b-row v-else-if="!creatingLessonPlanners.form.length && currentLessonPlanType=='global-lesson-plan'">
        <b-col class="d-flex border justify-content-center py-2"
               cols="12"
        >
          <h5>Sorry, No global lesson plans available !</h5>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
// import useJwt from '@/auth/jwt/useJwt'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormSelect, BButton, BFormCheckbox, BSpinner,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import QuillEditorCustom from '@views/common/components/QuillEditorCustom.vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import useApollo from '@/@core/graphql/useApollo'

export default {

  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    QuillEditorCustom,
    vSelect,

  },

  props: {
    courses: {
      type: [Array, Object],
      default: () => {},
    },
    updating: {
      type: Boolean,
      default: false,
    },

    lessonPlannerId: {
      type: [String, Number],
      default: () => {},
    },

    startDate: {
      type: [String, Date],
      default: '',
    },

    selectedPlans: {
      type: [Array, Object],
      default: () => {},
    },

    allLessonId: {
      type: [Object, Array],
      default: () => {},
    },
  },

  data() {
    return {
      previewing: false,
      course: null,
      lessonGroup: null,
      currentLessonId: null,
      selectedLessonPlans: [],
      currentLessonPlanType: 'my-lesson-plan',
      creatingLessonPlanners: {
        lesson_id: null,
        form: [
          {
            title: '',
            content: '',
            access_type: 'private',
            lesson_planner_type: 'online',
            selected: false,
          },
        ],

      },

      lessonPlanTypes: [
        { value: null, text: 'Please select a type' },
        { value: 'offline', text: 'Offline' },
        { value: 'online', text: 'Online' },
      ],
      plansTypes: [
        { value: null, text: 'Please select a type' },
        { value: 'my-lesson-plan', text: 'My Lesson Plans' },
        { value: 'global-lesson-plan', text: 'Global Lesson Plans' },
      ],

      accessTypes: [
        {
          value: null,
          text: 'Please select an access type',
        },
        {
          value: 'public',
          text: 'Public',
        },
        {
          value: 'private',
          text: 'Private',
        },
        // {
        //   value: 'share',
        //   text: 'Share',
        // },
      ],
      lessons: [],
      lessonGroups: [],
      isProcessing: false,
      schools: [],
    }
  },

  computed: {
    classId() {
      return this.$route.params.classId
    },
  },

  watch: {
    lessonPlannerId: {
      handler() {
        this.currentLessonId = this.lessonPlannerId
      },
      immediate: true,
    },
    selectedPlans: {
      handler() {
        this.selectedLessonPlans = this.selectedPlans
      },
      immediate: true,
    },
  },

  mounted() {
    if (this.updating) {
      this.fetchCurrentLessonPlanner()
    }
  },

  methods: {
    getLessons(id) {
      this.lessons = this.lessonGroups[this.lessonGroups.findIndex(e => e.id === id)].lessons.map(e => ({
        value: e.id, id: e.id, text: e.name, onlyTest: e.use_on === 'only-test',
      }))
      this.lessons.unshift({ value: null, text: 'Please  t a Lesson' })
    },

    getLessonGroups(id) {
      this.lessonGroups = this.courses[this.courses.findIndex(e => e.id === id)].lesson_groups.map(e => ({
        value: e.id, id: e.id, lessons: e.lessons, text: e.name,
      }))
      this.lessonGroups.unshift({ value: null, text: 'Please select a Lesson Group' })
    },

    addNewLessonPlanner() {
      this.creatingLessonPlanners.form.push({
        title: '',
        lesson_planner_type: 'online',
        content: '',
        access_type: 'private',
        selected: false,
      })
    },

    removeLessonPlanner(index) {
      const lessonPlan = this.creatingLessonPlanners.form[index]
      if (lessonPlan.lesson_planner_id) {
        const { lesson_planner_id } = lessonPlan
        useJwt.removeLessonPlanner(lesson_planner_id).then(() => {
          this.showSuccess('Lesson Planner removed successfully')
          this.fetchCurrentLessonPlanner()
        })
        return
      }
      this.creatingLessonPlanners.form.splice(index, 1)
    },

    removeAllLessonPlanner() {
      useJwt.removeLessonPlanner(this.creatingLessonPlanners.form[0].lesson_planner_id, 1).then(() => {
        this.showSuccess('All Lesson Planner removed successfully')
        this.$emit('lessonPlannerUpdated')
        this.fetchCurrentLessonPlanner()
      })
    },

    getSchools() {
      useApollo
        .getSchoolUsers()
        .then(response => {
          const res = response.data?.schools?.data

          this.schools = res.map(e => ({
            id: parseInt(e.id, 10),
            fullname: e.fullname,
          }))
        })
    },

    createLessonPlanner() {
      this.$refs.lessonPlannerForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          this.creatingLessonPlanners.start_date = this.startDate
          useJwt.createLessonPlanner(this.creatingLessonPlanners).then(() => {
            this.showSuccess('Lesson Planner created successfully')
            this.$emit('lessonPlannerCreated')
            this.fetchCurrentLessonPlanner()
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => {
            this.isProcessing = false
          })
        }
      })
    },

    updateLessonPlanner() {
      this.$refs.lessonPlannerForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt.updateLessonPlanner(this.lessonPlannerId, this.creatingLessonPlanners).then(() => {
            this.showSuccess('Lesson Planner updated successfully')
            this.$emit('lessonPlannerUpdated')
            this.fetchCurrentLessonPlanner()
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => {
            this.isProcessing = false
          })
        }
      })
    },

    fetchCurrentLessonPlanner() {
      useJwt.fetchCurrentLessonPlanner(this.currentLessonId, { params: { type: this.currentLessonPlanType } }).then(res => {
        const { data } = res
        const form = []
        data.forEach(d => {
          form.push({
            title: d.title,
            lesson_planner_type: d.lesson_planner_type,
            content: d.content,
            access_type: d.access_type,
            school_id: d.school_id,
            lesson_planner_id: d.id,
            author: d.author,
            selected: d.selected || false,
          })
        })

        if (form.length) {
          this.creatingLessonPlanners = {
            lesson_id: this.currentLessonId,
            form,
          }
        } else if (this.currentLessonPlanType !== 'global-lesson-plan') {
          this.creatingLessonPlanners = {
            lesson_id: this.currentLessonId,
            form: [
              {
                title: '',
                lesson_planner_type: 'online',
                content: '<span></span>',
                access_type: 'private',
                selected: false,
              },
            ],
          }
        } else {
          this.creatingLessonPlanners = {
            lesson_id: this.currentLessonId,
            form: [],
          }
        }

        // this.getLessonGroups(this.creatingLessonPlanners.course_id)
        // this.getLessons(this.creatingLessonPlanners.lesson_group_id)
      })
    },

    // lessonPlannerSelected(index) {
    //   const lessonPlan = this.creatingLessonPlanners.form[index]
    //   if (lessonPlan.lesson_planner_id) {
    //     useJwt.changeLessonPlannerSelected(lessonPlan.lesson_planner_id, { selected: lessonPlan.selected }).then(() => {
    //       this.showSuccess(`Lesson Planner ${lessonPlan.selected ? 'selected' : 'unselected'} successfully`)
    //     })
    //   }
    // },
  },

}

</script>

<style lang="scss">
#lessonPlanner{
  .light-bg{
    background-color:#e9f8ee;
  }

  .light-secondary{
    background-color:rgba(130, 134, 139, 0.12);
  }

  .lesson-planner-form{
    border-radius: 10px;
    .cke_contents{
    min-height:350px !important;
  }
  }

  .preview-bg-light, .preview-bg-secondary{
    background:url('../../../../assets/images/illustration/back.png') no-repeat;
    border-radius: 10px;
    background-size: cover;
    .col-12{
      background: #fff;
      padding: 10px 20px 10px 20px;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px #0000001a;
    }
  }

  .lesson-planner-view{
      -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
