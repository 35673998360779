<template>
  <div>
    <div v-if="inputType=='text'"
         class="chat-app-form"
    >
      <div
        role="group"
        class="input-group input-group-merge form-send-message mr-1"
      >
        <input
          v-model="typedMsg"
          type="text"
          placeholder="Enter your message"
          class="form-control"
          @keyup.enter="sendMessage"
        >
      </div>
      <button data-v-5e8ea5c2=""
              type="button"
              class="btn btn-primary"
              :disabled="!typedMsg || isProcessing || isPlaying"
              @click="sendMessage"
      >
        <b-spinner
          v-if="isProcessing"
          small
          type="grow"
        /><span v-else>Send</span>
      </button>
    </div>
    <b-overlay v-else
               :show="isProcessing || isPlaying || false"
    >
      <b-card body-class="p-0">

        <speak-with-bot :auto-stop="true"
                        @textAudio="sendMessage"
        >
          <template #default>
            <div v-if="isPlaying"
                 class="ml-2 d-flex justify-content-center mb-1"
                 style="position: absolute;left: 36%;margin-top: -56px;z-index: 10000;"
            >
              <feather-icon
                icon="StopCircleIcon"
                class="mr-2 play-icon text-danger"
                @click="$emit('stopAudio')"
              />
            </div>
          </template>
        </speak-with-bot>
      </b-card>
    </b-overlay>
    <div v-if="ttsNotSupported"
         class="card"
    >
      <div class="p-1">
        <p class="text-muted mb-0"
           style="font-size: 13px; font-style: italic;"
        >
          <feather-icon icon="InfoIcon" />
          Your Browser Does not support TTS
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { BSpinner, BCard, BOverlay } from 'bootstrap-vue'
import SpeakWithBot from './SpeakWithBot.vue'

export default {
  components: {
    BSpinner,
    SpeakWithBot,
    BCard,
    BOverlay,
  },
  props: {
    isProcessing: {
      type: Boolean,
      default: false,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    defaultInputType: {
      type: String,
      default: '',
    },
    ttsNotSupported: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typedMsg: '',
    }
  },
  computed: {
    inputType() {
      return this.defaultInputType || this.$store.getters['appConfig/currentChatbotInputType']
    },
  },
  methods: {
    sendMessage(val = null) {
      this.$emit('sendMessage', val.text || this.typedMsg)
      this.typedMsg = ''
    },
  },
}
</script>
