export default function getProblemPayload({ type, item }) {
  // eslint-disable-next-line default-case
  switch (type) {
    case 'multiple': {
      // eslint-disable-next-line no-case-declarations
      const questionAnswer = {
        question: item.questionAnswer.find(i => i.type === 'question').text,
        answers: item.questionAnswer.filter(i => i.type === 'answer').map(i => ({ text: i.text, is_correct: i.isCorrect })),
      }
      return questionAnswer
    }
    case 'matching': {
      const questions = item.questionAnswer.filter(i => i.type === 'question')
      const answers = item.questionAnswer.filter(i => i.type === 'answer')
      const questionAnswer = questions.map(question => ({
        question: question.text,
        answer: answers.find(i => i.groupId === question.groupId)?.text,
      }))
      return questionAnswer
    }
  }
  return null
}
