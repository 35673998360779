<template>
  <div class="dropdown-section">
    <b-form-group>
      <template #label>
        <label class="mb-1">{{ $t('ai-ide-module.check-level') }}</label>  <feather-icon id="check-level-tooltip"
                                                                                         class="cursor-pointer text-primary"
                                                                                         icon="InfoIcon"
                                                                                         size="20"
        />
      </template>
      <b-tooltip target="check-level-tooltip"
                 triggers="hover"
      >
        {{ $t('ai-ide-module.check-level-tooltip') }}
      </b-tooltip>
      <b-form-select v-model="modelForm.model_settings.checkLevel">
        <b-form-select-option value="1">
          1
        </b-form-select-option>
        <b-form-select-option value="2">
          2
        </b-form-select-option>
        <b-form-select-option value="3">
          3
        </b-form-select-option>
      </b-form-select>
    </b-form-group>

    <b-form-group>
      <template #label>
        <label class="mb-1">{{ $t('ai-ide-module.check-mode') }}</label>    <feather-icon id="check-mode-tooltip"
                                                                                          class="cursor-pointer text-primary"
                                                                                          icon="InfoIcon"
                                                                                          size="20"
        />
      </template>

      <b-tooltip target="check-mode-tooltip"
                 triggers="hover"
      >
        {{ $t('ai-ide-module.check-mode-tooltip') }}
      </b-tooltip>
      <b-form-select v-model="modelForm.model_settings.checkMode">
        <b-form-select-option value="FULL_CHECK">
          Full Check
        </b-form-select-option>
        <b-form-select-option value="QUICK_CHECK">
          Quick Check
        </b-form-select-option>
        <b-form-select-option value="SPELL_CHECK">
          Spell Check
        </b-form-select-option>
        <b-form-select-option value="ADVANCE_SPELL">
          Advance Spell Check
        </b-form-select-option>
      </b-form-select>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormSelect, BFormSelectOption, BFormGroup, BTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BFormSelect,
    BFormSelectOption,
    BFormGroup,
    BTooltip,
  },
  props: {
    modelForm: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    if (!this.modelForm.model_settings.checkMode) {
      this.modelForm.model_settings.checkMode = 'FULL_CHECK'
    }
  },
}
</script>
