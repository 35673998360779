<template>
  <div class="container-fluid p-0">
    <div class="row gx-lg-5 mt-2 d-flex justify-content-center">
      <div
        v-for="(card,index) in data"
        :key="'card_'+index"
        class="mb-1"
        :class="{
          'col-lg-3':data.length>3,
          'col-lg-4':data.length===3,
          'col-lg-6':data.length===2,
          'col-lg-12':data.length===1,
        }"
      >
        <div
          class="card bg-light border-0 h-100"
        >
          <div class="card-body text-center">
            <img
              v-if="card.image_url"
              v-image-preview
              class="img-fluid"
              :src="card.image_url"
              style="margin:0 auto;"
              :class="{
                'fixed-width': data.length <=2
              }"
            >
            <h2
              v-if="card.title"
              class="fs-4 fw-bold"
              :class="{'mt-2':'image' in card && card.image !==null }"
              v-html="isAProblem ? formatQuestion(card.title,'title',index):card.title"
            />
            <p
              v-if="card.subtitle"
              class="mb-0 center-card-subtitle"
              v-html="isAProblem ? formatQuestion(card.subtitle,'subtitle',index):card.subtitle"
            />
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { checkSettings } from '@/utils/visibilitySettings'

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    placeHolder: {
      type: String,
      default: '',
    },
    isAProblem: {
      type: Boolean,
      default: false,
    },
    questionFormatter: {
      type: Function,
      default: null,
    },
  },
  computed: {
    copyPasteEnabled() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const setting = checkSettings('copy_paste', schoolSetting, distSetting)
      if (setting.value === '1') {
        return true
      }
      return false
    },
  },
  methods: {
    formatQuestion(question, dataString, ind) {
      if (this.questionFormatter && typeof this.questionFormatter === 'function') {
        return this.questionFormatter(question, dataString, ind)
      }
      let html = ''
      let index = 0
      question
        .replaceAll(this.placeHolder, `$${this.placeHolder}$`)
        .split('$')
        .filter(e => e.length > 0)
        .forEach(element => {
          if (element === this.placeHolder) {
            this.$emit('createAnswer', `${dataString}-${ind}-${index}`)
            html += `
              <input
                type="text"
                :id="'Field_' + ${(index + 1)}"
                data-id="${dataString}-${ind}-${index}"
                style="display: inline-block; width: 150px;margin-top:2px"
                class="mx-1 answer form-control"
                :name="'Field_' + ${(index + 1)}"
                onpaste="return ${this.copyPasteEnabled};"
              />
            `
            index += 1
          } else {
            html += element
          }
        })
      return html
    },
  },
}
</script>

<style scoped>
@media (min-width: 992px) {
.fixed-width{
  max-width: 350px !important;
}
}

</style>
