<template>
  <b-overlay :show="isProcessing">

    <b-tabs pills>
      <b-tab :title="$t('calendar-module.calendar-view')">
        <event-template-calendar
          :key="rows.length"
          :events="rows"
          :isolated-template="isolatedTemplate"
          @refetch="showCalendarTemplate"
        />
      </b-tab>
      <b-tab :title="$t('calendar-module.list-view')">
        <!-- search input -->
        <b-row>
          <b-col>
            <div class="custom-search d-flex justify-content-end py-1">
              <b-form-group>
                <b-button
                  class="mr-2"
                  variant="outline-primary"
                  @click="exportListToExcel"
                >
                  <feather-icon icon="DownloadIcon" /> Export Events
                </b-button>
              </b-form-group>
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Search</label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Search"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </div>
          </b-col>
        </b-row>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'fullName'"
              class="text-nowrap"
            >
              <b-avatar
                :src="props.row.avatar"
                class="mx-1"
              />
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  :disabled="isProcessing"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BOverlay, BTab, BTabs, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import useJwt from '@/auth/jwt/useJwt'
import exportFromJSON from 'export-from-json'
import EventTemplateCalendar from './EventTemplateCalendar.vue'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BOverlay,
    EventTemplateCalendar,
    BTab,
    BTabs,
    BButton,
    BRow,
    BCol,
  },
  props: {
    templateId: {
      type: Number,
      default: () => 0,
    },
    type: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      isProcessing: false,
      columns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Start Time',
          field: 'start_time',
        },
        {
          label: 'End Date',
          field: 'end_time',
        },
      ],
      searchTerm: '',
      rows: [],
      isolatedTemplate: null,
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  watch: {
    templateId: {
      handler(newValue) {
        if (newValue) {
          this.showCalendarTemplate(newValue)
        }
      },
      immediate: true,

    },
  },
  methods: {
    showCalendarTemplate(templateId = null) {
      this.isProcessing = true
      const params = {
        type: this.type,
      }
      useJwt.showCalendarTemplate(templateId ?? this.templateId, { params }).then(response => {
        this.rows = response.data.data.events.map(item => ({
          ...item,
          name: item.lesson?.name,
          start_time: this.FORMAT_DATE(item.start_time, 'MMM Do YYYY'),
          end_time: this.FORMAT_DATE(item.end_time, 'MMM Do YYYY'),
          start_time_raw: item.start_time,
          end_time_raw: item.end_time,
          title: item.lesson?.name,
        }))
        if (this.type === 'isolated') this.isolatedTemplate = response.data.data.template
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    exportListToExcel() {
      const fileName = 'event-list'
      const exportType = exportFromJSON.types.csv
      exportFromJSON({
        data: this.rows.map(item => ({
          name: item.name,
          start_time: item.start_time,
          end_time: item.end_time,
        })),
        fileName,
        exportType,
      })
    },
  },
}
</script>
