<template>
  <b-row>
    <b-col
      class="problem-card-title text-center"
      md="12"
    >
      <h4 class="mb-0">
        {{ getProblemHintsDescription('Category Problem') }}
      </h4>
    </b-col>
    <b-col
      md="12"
      class="problem-overview-content"
    >
      <b-row style="margin-top: -30px">
        <b-col
          class="border border-primary p-2 m-2"
          @drop="undoAnswer($event)"
          @touchstart="undoAnswer($event, selectedAnswerForTouch)"
          @dragover.prevent
          @dragenter.prevent
        >
          <div
            class="answers text-center d-flex justify-content-center"
            style="flex-flow: wrap;"
          >
            <template
              v-for="ans of notAnswered"
            >
              <div
                :key="ans.id"
                :draggable="true"
                class="mr-1"
                :class="selectedAnswerForTouch == ans.id ? 'selected-answer' : ''"
                @dragstart="startDrag($event, ans.id)"
                @touchstart.stop="selectedAnswerForTouch = ans.id"
              >
                <div
                  v-if="ans.data_type === 'image'"
                  class="card"
                  style="width: 10rem;"
                  role="button"
                >
                  <b-img
                    :src="ans.image"
                    class="card-img-top"
                  />
                  <div class="card-body p-0">
                    <p
                      v-text-to-speech
                      class="card-text small text-bold"
                    >
                      <b>{{ ans.text }}</b>
                    </p>
                  </div>
                </div>
                <audio-player
                  v-else-if="ans.data_type==='audio'"
                  :audio-class="''"
                  :src="ans.audio"
                  style="width:auto;cursor:grab;color: white  !important;"
                  @dragstart="startDrag($event, ans.id)"
                />
                <b-button
                  v-else
                  v-text-to-speech
                  variant="relief-primary"
                  class="mr-2 w-100"
                  style="cursor:grab;color: white  !important;"
                  @dragstart="startDrag($event, ans.id)"
                >
                  {{ ans.text }}
                </b-button>
              </div>
            </template>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="12"
        >
          <div class="questions pt-3">
            <b-row>
              <b-col
                v-for="question of allQuestions"
                :key="question.id"
              >
                <div class="text-center">
                  <div
                    v-text-to-speech
                    class="pr-2 category"
                    style="background-color:transparent;color: white !important; "
                  >
                    {{ question.text }}
                  </div>
                  <div
                    class="drop-area p-1 d-flex"
                    style="flex-flow: wrap;"
                    @drop="onDrop($event,question.id)"
                    @touchstart="selectAnswerForQuestion(question.id)"
                    @dragover.prevent
                    @dragenter.prevent
                  >
                    <div
                      v-for="answer of selectedAnswers.filter(ans => ans.questionId === question.id)"
                      :key="answer.id"
                      :draggable="true"
                      class="mr-1"
                      :class="selectedAnswerForTouch == answer.id ? 'selected-answer' : ''"
                      @touchstart.stop="selectedAnswerForTouch = answer.id"
                      @dragstart="startDrag($event, answer.id)"
                    >
                      <div
                        v-if="answer.data_type === 'image'"
                        class="card"
                        role="button"
                        style="width: 10rem;"
                      >
                        <b-img
                          :id="`drag_ans${answer.id}`"
                          :src="answer.image"
                          fluid
                          class="card-img-top"
                        />
                        <div
                          :id="`drag_ans_desc${answer.id}`"
                          class="card-body p-0"
                        >
                          <p
                            v-text-to-speech
                            class="card-text small text-bold"
                          >
                            <b>{{ answer.text }}</b>
                          </p>
                        </div>
                      </div>
                      <audio-player
                        v-else-if="answer.data_type==='audio'"
                        :id="`drag_ans${answer.id}`"
                        :key="answer.id"
                        :audio-class="''"
                        :alignment="'left'"
                        style="width:auto;cursor:grab"
                        :src="answer.audio"
                      />
                      <b-button
                        v-else
                        :id="`drag_ans${answer.id}`"
                        v-text-to-speech
                        variant="relief-secondary"
                        class="mr-2 mb-2 w-100"
                        style="color: white !important;"
                        size="sm"
                      >
                        {{ answer.text }}
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <portal
      to="problemFeedBack"
    >
      <b-row>
        <b-col md="12"
               class="p-0"
        >
          <feedback
            :feedback="feedback"
            :problem-statement-id="problemStatementId"
          />
        </b-col>
        <b-col
          v-if="!isPreview"
          class="m-0 p-0"
          md="12"
        >
          <actions
            :is-processing="isProcessing"
            :on-submit="submit"
            :level="level ? level : ''"
            :disabled="!isValidated"
            :has-minimal-view="hasMinimalView"
            :has-feedback="hasFeedback"
            :tooltip-text="$t('student-test-module.match-problem-validation-message')"
            :display-result="displayResult"
            :button-title="hasFeedback ? $t('next') : $t('submit')"
            :engine="engine"
            :school-settings="schoolSettings"
            v-on="$listeners"
          />
        </b-col>
      </b-row>
    </portal>
  </b-row>
</template>
<script>
import {
  BButton, BRow, BCol, BImg,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'
import Actions from './Actions.vue'
import Feedback from './Feedback.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    Actions,
    BImg,
    AudioPlayer,
    Feedback,
  },
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
    previewAnswers: {
      type: Array,
      default: () => [],
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Object,
      default: () => {},
    },
    level: {
      type: Number,
      default: 1,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    problemStatementId: {
      type: [Number, String],
      required: false,
    },
    displayResult: {
      type: Boolean,
      default: false,
    },
    engine: {
      type: String,
      default: () => '',
    },
    hasMinimalView: {
      type: Boolean,
      default: false,
    },
    schoolSettings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localQuestion: [],
      allQuestions: this.questions.map(q => ({ text: q.text, id: q.id, data_type: q.data_type })),
      selectedAnswers: [],
      selectedAnswerForTouch: null,
    }
  },
  computed: {
    ...mapGetters('problem', {
      validation: 'getValidations',
    }),
    isValidated() {
      return this.ARRAY_UNIQUE_BY_KEY(this.selectedAnswers, 'text').length === this.ARRAY_UNIQUE_BY_KEY(this.allAnswers, 'text').length
    },
    hasFeedback() {
      return !!(this.feedback?.text?.length > 0 || this.feedback?.image || this.feedback?.audio || this.feedback?.template)
    },
    notAnswered() {
      return this.ARRAY_UNIQUE_BY_KEY(this.allAnswers.filter(answers => !this.selectedAnswers.find(s => s.text === answers.text)), 'text')
    },
    allAnswers() {
      let answers = []
      this.questions.forEach(question => {
        answers = [...answers, ...question.answers.map(ans => ({
          text: ans.text, id: ans.id, data_type: ans.data_type, image: ans.image ?? ans.file, audio: ans.audio ?? ans.file, questionId: ans.question_id,
        }))]
      })
      answers.sort(() => Math.random() - 0.5)
      return answers
    },
  },
  watch: {
    validation(newValue) {
      this.setValidationClasses(newValue)
    },
  },
  mounted() {
    this.setPreviousAnswered()
  },
  methods: {
    setValidationClasses(inputs) {
      const correctAnswers = []
      inputs.forEach(input => {
        if (input.is_correct) {
          correctAnswers.push(input.input)
        }
      })
      this.selectedAnswers.forEach(answer => {
        if (document.getElementById(`drag_ans${answer.id}`) !== null) {
          if (correctAnswers.includes(answer.id)) {
            document.getElementById(`drag_ans${answer.id}`).style.backgroundColor = 'green'
            if (answer.data_type === 'image') {
              document.getElementById(`drag_ans${answer.id}`).style.border = '3px solid green'
              document.getElementById(`drag_ans_desc${answer.id}`).style.backgroundColor = 'green'
              document.getElementById(`drag_ans_desc${answer.id}`).style.color = 'white'
            }
          } else {
            document.getElementById(`drag_ans${answer.id}`).style.backgroundColor = 'red'
            if (answer.data_type === 'image') {
              document.getElementById(`drag_ans${answer.id}`).style.border = '3px solid red'
              document.getElementById(`drag_ans_desc${answer.id}`).style.backgroundColor = 'red'
              document.getElementById(`drag_ans_desc${answer.id}`).style.color = 'white'
            }
          }
        }
      })
    },
    startDrag(evt, answerId) {
      // eslint-disable-next-line no-param-reassign
      evt.dataTransfer.dropEffect = 'move'
      // eslint-disable-next-line no-param-reassign
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('itemID', answerId)
    },
    submit() {
      const selectedAnswers = []
      this.selectedAnswers.forEach(answer => {
        if (selectedAnswers[answer.questionId]) {
          selectedAnswers[answer.questionId].push(answer.id)
        } else {
          selectedAnswers[answer.questionId] = [answer.id]
        }
      })
      const requiredAnswers = {}
      Object.entries(selectedAnswers).forEach(([index, array]) => {
        requiredAnswers[index] = JSON.stringify(array)
      })
      this.$emit('submitQuestion', { lang_level: this.level, answer: requiredAnswers })
    },
    removeFromAnswers(id) {
      const index = this.allAnswers.findIndex(answer => answer.id === Number(id))
      this.allAnswers.splice(index, 1)
    },
    removeAnswerFromQuestion(answerId) {
      this.selectedAnswers = this.selectedAnswers.filter(sa => sa.id !== answerId)
    },
    selectAnswerForQuestion(questionId, answerId = null) {
      if (answerId === null) {
        // eslint-disable-next-line no-param-reassign
        answerId = this.selectedAnswerForTouch
      }
      let answer = this.allAnswers.find(ans => Number(ans.id) === Number(answerId))
      // check for duplicate answers
      const actualAnswer = this.allAnswers.find(ans => Number(ans.questionId) === Number(questionId) && ans.text === answer.text)
      if (actualAnswer) {
        answer = { ...actualAnswer }
      }
      answer.questionId = questionId
      const selectedAnswers = this.selectedAnswers.filter(sa => sa.id !== answerId)
      selectedAnswers.push(answer)
      this.selectedAnswers = selectedAnswers
      this.selectedAnswerForTouch = null
    },
    onDrop(evt, questionId) {
      if (evt.preventDefault) { evt.preventDefault() }
      if (evt.stopPropagation) { evt.stopPropagation() }
      const answerId = Number(evt.dataTransfer.getData('itemID'))
      this.selectAnswerForQuestion(questionId, answerId)
    },
    undoAnswer(evt, answerId = null) {
      if (evt.preventDefault) { evt.preventDefault() }
      if (evt.stopPropagation) { evt.stopPropagation() }
      // eslint-disable-next-line no-param-reassign
      if (!answerId) answerId = Number(evt.dataTransfer.getData('itemID'))
      this.selectedAnswers = this.selectedAnswers.filter(sa => Number(sa.id) !== Number(answerId))
    },
    setPreviousAnswered() {
      const selectedAnswers = []
      this.previewAnswers.forEach(pa => {
        const answer = this.allAnswers.find(all => all.id === pa.input)
        if (answer) {
          answer.questionId = pa.question
          selectedAnswers.push(answer)
        }
      })
      this.selectedAnswers = selectedAnswers
      if (selectedAnswers.length > 0) {
        this.$nextTick(() => {
          this.setValidationClasses(this.previewAnswers)
        })
      }
    },
  },
}
</script>
<style scoped>
    .category {
        position: relative;
        top: 25%;
        background: #28658e !important;
        color: #fff !important;
        font-weight: bold;
    }
    .drop-area {
        min-height: 20vh;
        min-height: 100px;
        background-color: #ffe6e6;
        border: 1px solid;
        min-width: 2opx;
        width: 100%;
    }
    .cross-icon {
        position: absolute;
        margin-left: -36px;
        margin-top: -6px;
        font-weight: bold;
    }
  .selected-answer {
    box-sizing: border-box !important;
    border: 5px #f1c40f solid !important;
  }
</style>
