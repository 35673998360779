<template>
  <div>
    <b-card>
      <GradeCourseFilter :form="basicForm" />
      <b-form-file
        :browse-text="$t('browseButton')"
        :placeholder="$t('issue-report-module.choose-a-file')"
        accept=".pdf"
        @change="uploadPdf"
      />
    </b-card>
    <b-tabs>
      <b-tab v-for="page of totalPages"
             :key="page"
             :title="`Page ${page}`"
      >
        <PdfPage v-if="pdfDocumentObject"
                 :page-number="page"
                 :render-p-d-f="renderPdf"
                 :lesson-id="basicForm.lessonId"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script setup>
import { reactive, ref, onMounted } from 'vue'
import {
  BTabs, BTab, BFormFile, BCard,
} from 'bootstrap-vue'
import GradeCourseFilter from '@/views/common/lessons/components/GradeCourseFilter.vue'
import PdfPage from './PdfPage.vue'

const totalPages = ref(0)
const pdfDocumentObject = ref(null)
const basicForm = reactive({
  gradeId: 1,
  courseId: 45,
  lessonGroupId: 355,
  lessonId: 2067,
})
const renderPdf = (canvasPdfId, pageNumber, callBack) => {
  const canvasMain = document.getElementById(canvasPdfId)
  const contextMain = canvasMain.getContext('2d')
  pdfDocumentObject.value.getPage(pageNumber).then(page => {
    // pdfContainer.appendChild(canvas);
    const viewport = page.getViewport({
      scale: 1.5,
    })

    canvasMain.height = viewport.height
    canvasMain.width = viewport.width
    callBack({
      height: canvasMain.height,
      width: canvasMain.width,
    })
    const renderContext = {
      canvasContext: contextMain,
      viewport,
    }

    page.render(renderContext)
  })
}

function uploadPdf(ev) {
  pdfDocumentObject.value = null
  const input = ev.target
  const file = input.files[0]
  if (file) {
    const reader = new FileReader()
    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result)
      // eslint-disable-next-line no-undef
      const loadingTask = pdfjsLib.getDocument(data)
      loadingTask.promise.then(pdfDocument => {
        totalPages.value = pdfDocument.numPages
        pdfDocumentObject.value = pdfDocument
      })
    }
    reader.readAsArrayBuffer(file)
  }
}

function loadExternalCdn(cdn) {
  // Create a script element
  const script = document.createElement('script')

  // Set the script source to your external CDN URL
  script.src = cdn

  // Append the script to the document's head
  document.head.appendChild(script)

  script.onerror = () => {
    console.error(`Error loading external CDN- ${cdn}`)
  }
}

onMounted(() => {
  if (!window.pdfjsLib) {
    loadExternalCdn('https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.11.338/pdf.min.js')
  }
  if (!window.Tesseract) {
    loadExternalCdn('https://cdn.jsdelivr.net/npm/tesseract.js@5/dist/tesseract.min.js')
  }
})
</script>
