var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row gx-lg-5 mt-2 d-flex justify-content-center"},_vm._l((_vm.data),function(card,index){return _c('div',{key:'card_'+index,staticClass:"mb-1",class:{
        'col-lg-3':_vm.data.length>3,
        'col-lg-4':_vm.data.length===3,
        'col-lg-6':_vm.data.length===2,
        'col-lg-12':_vm.data.length===1,
      }},[_c('div',{staticClass:"card bg-light border-0 h-100"},[_c('div',{staticClass:"card-body text-center"},[(card.image_url)?_c('img',{directives:[{name:"image-preview",rawName:"v-image-preview"}],staticClass:"img-fluid",class:{
              'fixed-width': _vm.data.length <=2
            },staticStyle:{"margin":"0 auto"},attrs:{"src":card.image_url}}):_vm._e(),(card.title)?_c('h2',{staticClass:"fs-4 fw-bold",class:{'mt-2':'image' in card && card.image !==null },domProps:{"innerHTML":_vm._s(_vm.isAProblem ? _vm.formatQuestion(card.title,'title',index):card.title)}}):_vm._e(),(card.subtitle)?_c('p',{staticClass:"mb-0 center-card-subtitle",domProps:{"innerHTML":_vm._s(_vm.isAProblem ? _vm.formatQuestion(card.subtitle,'subtitle',index):card.subtitle)}}):_vm._e()])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }