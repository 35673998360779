<template>
  <div>
    <h3 class="mb-2">
      {{ $t('setting-module.visibility-setting-title') }}
    </h3>
    <b-table-simple
      responsive
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th
            width="40%"
          >
            {{ $t('setting-module.options') }}
          </b-th>
          <b-th
            width="40%"
          >
            {{ $t('setting-module.switch') }}
          </b-th>
          <b-th
            width="20%"
          >
            {{ $t('setting-module.help-guide') }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>{{ $t('setting-module.display-self-card-title') }}</b-td>
          <b-td>
            <span class="d-flex align-items-baseline">
              <b-form-checkbox v-model="allSettings.student_card"
                               value="0"
                               unchecked-value="1"
                               switch
                               @change="updateClassRoomSetting('student_card')"
              />
              <span class="ml-2">
                {{ $t('setting-module.hide-show') }}
              </span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('student_card')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>{{ $t('setting-module.full-course-progress-title') }}</b-td>
          <b-td>
            <span class="d-flex align-items-baseline">
              <b-form-checkbox v-model="allSettings.full_course_progress"
                               value="0"
                               unchecked-value="1"
                               switch
                               @change="updateClassRoomSetting('full_course_progress')"
              />
              <span class="ml-2">
                {{ $t('setting-module.hide-show') }}
              </span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('full_course_progress')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td>{{ $t('setting-module.current-progress-title') }}</b-td>
          <b-td>
            <span class="d-flex align-items-baseline">
              <b-form-checkbox v-model="allSettings.current_progress"
                               value="0"
                               unchecked-value="1"
                               switch
                               @change="updateClassRoomSetting('current_progress')"
              />
              <span class="ml-2">
                {{ $t('setting-module.hide-show') }}
              </span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('current_progress')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>{{ $t('class-module.show-skill-chart-title') }}</b-td>
          <b-td>
            <span class="d-flex align-items-baseline">
              <b-form-checkbox v-model="allSettings.skill_chart"
                               value="0"
                               unchecked-value="1"
                               switch
                               @change="updateClassRoomSetting('skill_chart')"
              />
              <span class="ml-2">
                {{ $t('setting-module.hide-show') }}
              </span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('skill_chart')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td>{{ $t('labels.point') }}</b-td>
          <b-td>
            <span class="d-flex align-items-baseline">
              <b-form-checkbox v-model="allSettings.point"
                               value="0"
                               unchecked-value="1"
                               switch
                               @change="updateClassRoomSetting('point')"
              />
              <span class="ml-2">
                {{ $t('setting-module.hide-show') }}
              </span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('point')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td>{{ $t('labels.language-level') }}</b-td>
          <b-td>
            <span class="d-flex align-items-baseline">
              <b-form-checkbox v-model="allSettings.language_level"
                               value="0"
                               unchecked-value="1"
                               switch
                               @change="updateClassRoomSetting('language_level')"
              />
              <span class="ml-2">
                {{ $t('setting-module.hide-show') }}
              </span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('language_level')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td>{{ $t('labels.badge') }}</b-td>
          <b-td>
            <span class="d-flex align-items-baseline">
              <b-form-checkbox v-model="allSettings.badge"
                               value="0"
                               unchecked-value="1"
                               switch
                               @change="updateClassRoomSetting('badge')"
              />
              <span class="ml-2">
                {{ $t('setting-module.hide-show') }}
              </span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('badge')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>

    </b-table-simple>
    <setting-info-modal
      :show="showModal"
      :modal-data="modalData"
      @close="showModal = false"
    />
  </div>
</template>
<script>
import {
  BFormCheckbox, BButton,
  BTableSimple,
  BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import SettingInfoModal from '@/views/common/components/SettingInfoModal.vue'

export default {
  components: {
    BFormCheckbox,
    BButton,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    SettingInfoModal,
  },
  props: {
    allSettings: {
      type: Object,
      default: () => {},
    },
    classId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      modalData: {
        title: '',
        imgSrc: '',
        description: '',
        steps: '',
      },
      modalInfo: {
        student_card: {
          title: i18n.tc('setting-module.display-self-card-title'),
          description: i18n.tc('setting-module.student-title-info'),
          imageSrc: '/school-settings/display-self.png',
          steps: '<ul >'
                + '<li>Login In to Student Account</li>'
                + '<li>Go to Classroom</li>'
                + '<li>Student will only see owns card</li>'
                + '</ul>',
        },
        full_course_progress: {
          title: i18n.tc('setting-module.full-course-progress-title'),
          description: i18n.tc('setting-module.full-course-progress'),
          imageSrc: '',
          steps: '<ul >'
                + '<li>Login In to Student Account</li>'
                + '<li>Go to Classroom</li>'
                + '<li>Full course Progress Bar will be shown </li>'
                + '</ul>',
        },
        current_progress: {
          title: i18n.tc('setting-module.current-progress-title'),
          description: i18n.tc('setting-module.current-progress'),
          imageSrc: '/school-settings/current-progress.png',
          steps: '<ul >'
                + '<li>Login In to Student Account</li>'
                + '<li>Go to Classroom</li>'
                + '<li>Current progress bar will be shown</li>'
                + '</ul>',
        },
        skill_chart: {
          title: i18n.tc('class-module.show-skill-chart-title'),
          description: i18n.tc('setting-module.skill-chart'),
          imageSrc: '/school-settings/skill-chart.png',
          steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>You\'ll notice Skill Chart icon on the student card</li>'
                + '</ul>',
        },
        point: {
          title: i18n.tc('labels.point'),
          description: i18n.tc('setting-module.point'),
          imageSrc: '',
          steps: '',
        },
        language_level: {
          title: i18n.tc('labels.language-level'),
          description: i18n.tc('setting-module.language-level'),
          imageSrc: '/school-settings/language-level.png',
          steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Click on Course</li>'
                + '<li>Select Lesson</li>'
                + '<li>Click Start button</li>'
                + '<li>In the Student Lab, at the end of the page, You\'ll notice \'Language Level Indicator\'.</li>'
                + '</ul>',
        },
        badge: {
          title: i18n.tc('labels.badge'),
          description: i18n.tc('setting-module.badge'),
          imageSrc: '',
          steps: '',
        },
      },
    }
  },
  methods: {
    updateClassRoomSetting(type) {
      useJwt.updateClassroomSetting(this.classId, type, {
        value: this.allSettings[type],
      }).then(res => {
        this.showSuccessMessage(res)
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    showHelpGuide(type) {
      this.showModal = true
      this.modalData = {
        title: this.modalInfo[type].title,
        imgSrc: this.modalInfo[type].imageSrc,
        description: this.modalInfo[type].description,
        steps: this.modalInfo[type].steps,
      }
    },
  },
}
</script>
<style lang="scss">
  .modal-steps
   {
    ul {
    padding-left: 23px;
      li{
        list-style: decimal;
      }
    }
   }
</style>
