<template>
  <b-modal
    :id="name"
    :ref="name"
    v-model="isVisible"
    ok-only
    :ok-variant="varient"
    :ok-title="$t('confirm')"
    :modal-class="`modal-${varient}`"
    centered
    :no-close-on-backdrop="noCloseOnBackDrop"
    :title="$t(title ?? 'delete')"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-card-text>
      <template v-if="subTitle">
        {{ subTitle }}
      </template>
      <template v-else>
        {{ $t('delete-confirmation') }}
      </template>
    </b-card-text>
    <slot />
    <template #modal-footer="">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="varient"
            :disabled="isProcessingDelete"
            @click="onDelete"
          >
            <b-spinner
              v-show="isProcessingDelete"
              small
            />
            {{ $t('confirm') }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import {
  BSpinner, BRow, BCol, BModal, BCardText, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import i18n from '@/libs/i18n'

export default ({
  directives: {
    Ripple,
  },
  components: {
    BModal, BSpinner, BRow, BCol, BCardText, BButton,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    noCloseOnBackDrop: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'delete-modal',
    },
    onDelete: {
      type: Function,
      required: true,
    },
    isProcessingDelete: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: i18n.tc('delete'),
    },
    subTitle: {
      type: String,
      default: '',
    },
    varient: {
      type: String,
      default: 'danger',
    },
  },
  data() {
    return {
      isVisible: this.show,
    }
  },
  watch: {
    show() {
      this.isVisible = this.show
    },
  },
})
</script>
