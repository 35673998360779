<template>
  <b-card class="w-100">
    <vue-apex-charts
      :key="chart.series.length"
      type="pie"
      height="350"
      :options="chart.chartOptions"
      :series="chart.series"
    />
  </b-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    VueApexCharts,
    BCard,
  },
  props: {
    interactionReport: {
      type: Array,
      default: () => [],
    },
    scormInfo: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    chart() {
      const categories = [
        'Passed',
        'Failed',
      ]
      const correct = this.interactionReport.filter(report => report.isCorrect).length
      const inCorrect = this.interactionReport.filter(report => !report.isCorrect).length
      const series = [correct, inCorrect]
      return {
        series,
        chartOptions: {
          title: {
            text: 'Learning Result of ',
            align: 'center',
            offsetX: -10,
          },
          subtitle: {
            text: this.scormInfo && this.scormInfo.title,
            align: 'center',
            margin: 20,
            offsetY: 20,
          },
          colors: ['#4CAF4F', '#F44336'],
          chart: {
            width: 380,
            type: 'pie',
            style: {
              marginTop: 10,
            },
          },
          labels: categories,
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          }],
        },
      }
    },
  },
}
</script>
