<template>
  <div>
    <h4 class="text-center">
      {{ title }}
    </h4>
    <div v-if="isDataEmpty"
         class="text-center"
    >
      <b-img :src="require('@/assets/images/elements/no-data.png')"
             :height="200"
             :width="200"
      />
    </div>
    <apexchart
      v-else
      type="donut"
      :height="300"
      :width="300"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    apexchart: VueApexCharts,
    BImg,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    series: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isDataEmpty() {
      return this.series.every(i => i === 0)
    },
    // series() {
    //   return [20, 30]
    // },
    chartOptions() {
      return {
        chart: {
          type: 'donut',
        },
        colors: ['#4CBB18', '#FF4460'],
        labels: this.labels,
      }
    },
  },
}
</script>
