<template>
  <span>
    <div class="d-flex flex-wrap justify-content-lg-end"
         style="gap:10px;"
    >
      <b-button variant="primary"
                data-html2canvas-ignore="true"
                class="mr-1"
                @click="isOpen = !isOpen"
      >
        {{ isOpen ? $t('report.hide-chart') : $t('report.show-chart') }} <feather-icon icon="BarChartIcon" />
      </b-button>
      <slot />
    </div>
    <b-collapse id="collapse1"
                v-model="isOpen"
    >
      <div class="p-lg-4">
        <apexchart v-if="chartSeries.length"
                   :options="chartOptions"
                   :series="chartSeries"
                   type="bar"
                   height="350"
        />
      </div>
    </b-collapse>

  </span>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { BCollapse, BButton } from 'bootstrap-vue'

export default {
  name: 'MyChart',
  components: {
    apexchart: VueApexCharts, BCollapse, BButton,
  },
  props: {
    status: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    chartOptions() {
      return {
        colors: ['#FFEB3A', '#F44336', '#4CAF4F', '#3F50B5'],
        chart: {
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: this.status.map(item => item.status),
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            dataLabels: {
              position: 'bottom',
            },
          },
        },
      }
    },
    chartSeries() {
      const overall = this.status.map(item => item.count)
      const total = overall.reduce((p, c) => p + Number(c), 0)
      return [
        {
          name: 'Count',
          total,
          data: this.status.map(item => parseFloat((item.count / total) * 100).toFixed(2)),
        },
      ]
    },
  },
}
</script>
