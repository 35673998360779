/* eslint-disable import/no-cycle */
import internationalUsersOnly from '@/middlewares/internationalUsersOnly'
import isAuthenticated from '@/middlewares/isAuthenticated'

export default [
  // {
  //   path: '/international/home',
  //   meta: {
  //     middleware: [isAuthenticated, internationalUsersOnly],
  //   },
  //   name: 'international-home',
  //   component: () => import('@/views/international/Home.vue'),
  // },
  {
    path: '/international/users',
    meta: {
      middleware: [isAuthenticated, internationalUsersOnly],
    },
    name: 'international-users',
    component: () => import('@/views/common/user/Index.vue'),
  },
  {
    path: '/international/networks',
    meta: {
      middleware: [isAuthenticated, internationalUsersOnly],
    },
    name: 'international-networks',
    component: () => import('@/views/common/network/Index.vue'),
  },
  {
    path: '/international/networks/:id',
    meta: {
      middleware: [isAuthenticated, internationalUsersOnly],
      navActiveLink: 'international-networks',
    },
    name: 'international-networks-show',
    component: () => import('@/views/common/network/Show.vue'),
  },
  {
    path: '/international/classes',
    meta: {
      middleware: [isAuthenticated, internationalUsersOnly],
    },
    name: 'international-classes',
    component: () => import('@/views/common/class/Handler.vue'),
  },
  {
    path: '/international/classes/:id/:courseId?',
    name: 'international-class',
    meta: {
      middleware: [isAuthenticated, internationalUsersOnly],
      navActiveLink: 'international-classes',
    },
    component: () => import('@/views/common/class/Show.vue'),
  },
  {
    path: '/international/student-diary/:id',
    name: 'international-student-diary',
    meta: {
      middleware: [isAuthenticated, internationalUsersOnly],
    },
    component: () => import('@/views/common/student-diary/Index.vue'),
  },
  {
    path: '/international/courses/:id',
    name: 'international-course',
    meta: {
      middleware: [isAuthenticated, internationalUsersOnly],
      navActiveLink: 'international-classes',
    },
    component: () => import('@/views/common/class/components/ShowCourse.vue'),
  },
  {
    path: '/international/view-students/:id',
    name: 'international-view-students',
    meta: {
      middleware: [isAuthenticated, internationalUsersOnly],
      navActiveLink: 'international-classes',
    },
    component: () => import('@/views/v2/common/class/components/StudentTable.vue'),
  },
  {
    path: '/international/higher-level-report',
    name: 'international-higher-level-report',
    meta: {
      middleware: [isAuthenticated, internationalUsersOnly],
    },
    component: () => import('@/views/common/higher-level-report/index.vue'),
  },
]
