<!-- Sidebar.vue -->
<template>
  <div v-if="show"
       :style="{ width: `${sidebarWidth}px`}"
       class="sidebar p-1"
  >
    <!-- Your sidebar content goes here -->
    <div class="resize-handle"
         @mousedown="startResize"
    >
      <div class="dragger">
        {{ $t('DRAG') }}
      </div>
    </div>
    <lesson-notes
      v-if="lessonId"
      :key="lessonId"
      ref="lessonNotes"
      :class="isResizing && 'disabled'"
      :lesson-id="lessonId"
      :hide-close="true"
      class="p-1"
      @updateLesson="value => lessonInfo = value"
      @close="$emit('close')"
    />
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import LessonNotes from '@/views/common/lessons/Notes/index.vue'

export default {
  components: {
    LessonNotes,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    lessonId: {
      type: Number,
      default: null,
    },
    classId: {
      type: Number,
      default: null,
    },
    studentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isResizing: false,
      resizeOffset: 0,
      sidebarWidth: 500, // Initial width of the sidebar
      notes: [],
      isVisible: this.show,
      isProcessing: false,
      self: getUserData(),
      lessonInfo: null,
    }
  },
  computed: {
    isAStudent() {
      return this.self.usertype === 'student'
    },
  },
  methods: {
    startResize() {
      this.isResizing = true
      document.body.style.userSelect = 'none'
      document.addEventListener('mousemove', this.resize)
      document.addEventListener('mouseup', this.stopResize)
    },
    resize(event) {
      if (this.isResizing && (window.innerWidth - event.clientX) > 450) {
        this.resizeOffset = event.clientX
        this.sidebarWidth = window.innerWidth - event.clientX
      } else {
        this.stopResize()
      }
    },
    stopResize() {
      this.isResizing = false
      document.body.style.userSelect = ''
      document.removeEventListener('mousemove', this.resize)
      document.removeEventListener('mouseup', this.stopResize)
    },
  },
}
</script>

<style scoped lang="scss">
.sidebar {
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;

  z-index: 1000;
  transition: width 0.3s ease, left 0.3s ease;
}

.resize-handle {
  cursor: ew-resize;
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: 50%;
}

.dragger {
  position: absolute;
  top: 50%;
  transform: rotate(-90deg);
  background: #7367f0;
  color: white;
  padding: 7px;
  margin-left: -15px;
  font-weight: 500;
}
.disabled {
  pointer-events: none;
  user-select: none;
}

:not(.dark-layout) {
  .sidebar {
    background: #fff;
  }
}
.dark-layout {
.sidebar {
  background: #161d31;
}
}
</style>
