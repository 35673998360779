<template>
  <div id="report-card">
    <b-card>
      <b-row>
        <b-col cols="9">
          <h4>
            {{ $t('student-report-module.report-pages.overall-chat-report') }}
          </h4>
        </b-col>
        <b-col
          cols="3"
          class="text-right"
        >
          <b-button
            variant="primary"
            data-html2canvas-ignore="true"
            @click="exportPdf()"
          >
            {{ $t('student-report-module.report-pages.export-pdf') }}
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </b-col>
      </b-row>
      <vue-apex-charts
        :key="series.length"
        type="bar"
        height="350"
        :options="chartOptions"
        :series="series"
      />
    </b-card>
    <b-card>
      <h4>
        {{ $t('student-report-module.report-pages.time-line-of-chat') }}
      </h4>
      <b-table-simple responsive="">
        <b-thead>
          <b-tr>
            <b-th>
              {{ $t('student-report-module.report-pages.student-name') }}
            </b-th>
            <b-th
              v-for="month of months"
              :key="month.month"
            >
              {{ month.month }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(student, index) of studentGropedChatReport"
            :key="index"
          >
            <b-td>
              {{ student[0].fullName }}
            </b-td>
            <b-td
              v-for="month of months"
              :key="month.month"
              :set="(reports = student.filter((s) => s.month === month.month))"
            >
              {{ reports.length }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'
import {
  BTableSimple, BTh, BTr, BThead, BTbody, BCard, BTd, BButton, BRow, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BTableSimple, BTh, BTr, BThead, BTbody, BCard, BTd, VueApexCharts, BButton, BRow, BCol,
  },
  props: {
    classRoom: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      overAllChatReport: [],
      studentGropedChatReport: {},
      months: [],
      series: [],
      categories: [],
    }
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          height: 150,
          type: 'bar',
        },
        xaxis: {
          categories: this.categories,
          position: 'top',
        },
        dataLabels: {
          formatter(val) {
            return `${val}%`
          },
        },
        yaxis: {
          min: 0,
          max: 100,
          labels: {
            formatter(val) {
              return `${val}%`
            },
          },
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            dataLabels: {
              position: 'bottom',
            },
          },
        },
      }
    },
  },
  created() {
    this.getReport()
  },
  methods: {
    getReport() {
      useJwt.getGroupChatReport(this.classRoom.id).then(response => {
        const ChatReport = response.data.data.report.map(report => ({
          day: moment(report.created_at).format('YYYY-MM-DD'),
          month: moment(report.created_at).format('YYYY MMM'),
          fullName: `${report.sender?.firstname} ${report.sender?.lastname}`,
          studentId: report.send_by,
          id: report.id,
        }))
        this.studentGropedChatReport = this.groupByKey(ChatReport, 'studentId')
        this.overAllChatReport = ChatReport
        const months = []
        ChatReport.forEach(note => {
          if (!months.find(month => month.month === note.month)) {
            months.push({
              day: note.day,
              month: moment(note.day).format('YYYY MMM'),
              displayName: moment(note.day).format('MMM Do'),
            })
          }
        })
        this.months = months
        this.getStudentComparative()
      })
    },
    getStudentComparative() {
      const maxScore = Math.max(
        ...Object.values(this.studentGropedChatReport).map(o => o.length),
      )
      const categories = []
      const seriesData = []
      Object.values(this.studentGropedChatReport).forEach(report => {
        categories.push(report[0].fullName)
        const score = (report.length / maxScore) * 100
        seriesData.push(score.toFixed(2))
      })
      this.categories = categories
      this.series = [
        {
          name: 'Chat Score',
          data: seriesData,
        },
      ]
    },
    exportPdf() {
      const element = document.getElementById('report-card')
      this.printPdf(element, 'chat-report.pdf')
    },
  },
}
</script>
