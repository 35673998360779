/* eslint-disable import/no-cycle */
import companyUsersOnly from '@/middlewares/companyUsersOnly'
import isAuthenticated from '@/middlewares/isAuthenticated'

export default [
  // {
  //   meta: {
  //     middleware: [isAuthenticated, companyUsersOnly],
  //   },
  //   path: '/company/home',
  //   name: 'company-home',
  //   component: () => import('@/views/company/Home.vue'),
  // },
  {
    meta: {
      middleware: [isAuthenticated, companyUsersOnly],
    },
    path: '/company/users',
    name: 'company-users',
    component: () => import('@/views/common/user/Index.vue'),
  },
  {
    path: '/company/networks',
    meta: {
      middleware: [isAuthenticated, companyUsersOnly],
    },
    name: 'company-networks',
    component: () => import('@/views/common/network/Index.vue'),
  },
  {
    path: '/company/networks/:id',
    meta: {
      middleware: [isAuthenticated, companyUsersOnly],
      navActiveLink: 'company-networks',
    },
    name: 'company-networks-show',
    component: () => import('@/views/common/network/Show.vue'),
  },
  {
    path: '/company/classes',
    meta: {
      middleware: [isAuthenticated, companyUsersOnly],
    },
    name: 'company-classes',
    component: () => import('@/views/common/class/Handler.vue'),
  },
  {
    path: '/company/classes/:id/:courseId?',
    name: 'company-class',
    meta: {
      middleware: [isAuthenticated, companyUsersOnly],
      navActiveLink: 'company-classes',
    },
    component: () => import('@/views/common/class/Show.vue'),
  },
  {
    path: '/company/student-diary/:id',
    name: 'company-student-diary',
    meta: {
      middleware: [isAuthenticated, companyUsersOnly],
    },
    component: () => import('@/views/common/student-diary/Index.vue'),
  },
  {
    path: '/company/view-students/:id',
    name: 'company-view-students',
    meta: {
      middleware: [isAuthenticated, companyUsersOnly],
      navActiveLink: 'company-classes',
    },
    component: () => import('@/views/v2/common/class/components/StudentTable.vue'),
  },
  {
    path: '/company/student/dashboard/:id',
    meta: {
      middleware: [isAuthenticated, companyUsersOnly],
    },
    name: 'student-dashboard-for-company',
    component: () => import('@/views/common/dashboard/Index.vue'),
  },
  {
    path: '/company/higher-level-report',
    name: 'company-higher-level-report',
    meta: {
      middleware: [isAuthenticated, companyUsersOnly],
    },
    component: () => import('@/views/common/higher-level-report/index.vue'),
  },
]
