<template>
  <div v-if="templateOptions.shape">
    <div
      :class="`
        ${ templateOptions.shape.size === 'lg-t-20' ? 'text-center' : `text-${templateOptions.textAlign}` }
        shape-container mx-auto
      `"
      :style="templateOptions.shape.size === 'xl' ? `background-color: ${templateOptions.bgColor}` : ''"
    >
      <div :class="`container container-${templateOptions.shape.size}`">
        <div
          :class="`template-${templateOptions.shape.size}`"
          class="mx-auto pl-1 py-1"
          :style="templateOptions.shape.size === 'md' ? `background-color: ${templateOptions.bgColor}` : ''"
        >
          <vue-perfect-scrollbar
            v-once
            class="scrollbar"
            :settings="scrollbarSettings"
          >
            <div>
              <!-- Before Template  -->
              <div class="before-template w-100">
                <slot />
              </div>

              <!-- Questions  -->
              <div v-if="templateOptions.type !== 'fib'">
                <div
                  v-for="(section, index) in data"
                  :key="index"
                  :class="`
                    question-card
                    question-card-${templateOptions.shape.size}
                    ${templateOptions.shape.size === 'lg-t-20' ? 'mx-auto' : `float-${templateOptions.textAlign}`}
                    ${templateOptions.textAlign === 'center' ? 'mx-auto' : 'mx-2'}`
                  "
                >
                  <h2
                    v-text-to-speech
                    class="question text-warning"
                    @click="$emit('onClick', $event)"
                    v-html="isAProblem ? formatQuestion(section.title,'title',index):section.title"
                  />
                  <p
                    v-text-to-speech
                    @click="$emit('onClick', $event)"
                    v-html="isAProblem ? formatQuestion(section.subtitle,'subtitle',index):section.subtitle"
                  />

                </div>
              </div>
            </div>
          </vue-perfect-scrollbar>
        </div>
      </div>

      <img
        :src="require(`@/assets/images/shapes/${templateOptions.shape.value}`)"
        width="100%"
        height="auto"
        alt=""
      >

    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { checkSettings } from '@/utils/visibilitySettings'

export default {
  components: {
    VuePerfectScrollbar,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    placeHolder: {
      type: String,
      default: '',
    },
    isAProblem: {
      type: Boolean,
      default: false,
    },
    shape: {
      type: Object,
      default: () => null,
    },
    type: {
      type: String,
      default: '',
    },
    bgColor: {
      type: String,
      default: '',
    },
    textAlign: {
      type: String,
      default: 'left',
    },
    answers: {
      type: Array,
      default: () => null,
    },
    templateOptions: {
      type: Object,
      default: () => {},
    },
    questionFormatter: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      scrollbarSettings: {
        swipeEasing: true,
      },
    }
  },
  computed: {
    copyPasteEnabled() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const setting = checkSettings('copy_paste', schoolSetting, distSetting)
      if (setting.value === '1') {
        return true
      }
      return false
    },
  },
  methods: {
    formatQuestion(question, dataString, ind) {
      if (this.questionFormatter && typeof this.questionFormatter === 'function') {
        return this.questionFormatter(question, dataString, ind)
      }
      let html = ''
      question
        .replaceAll(this.placeHolder, `$${this.placeHolder}$`)
        .split('$')
        .filter(e => e.length > 0)
        .forEach((element, index) => {
          if (element === this.placeHolder) {
            if (this.answers) {
              let answers = []
              this.answers.map(answer => answer.text).forEach(answer => {
                answers.push(answer.split(','))
              })
              answers = answers.reduce((prev, next) => prev.concat(next))

              html += `
                <select
                  data-id="${dataString}-${ind}-${index}"
                  :id="'Field_' + ${(index + 1)}"
                  style="display: inline-block; width: 100% !important;margin-top:2px"
                  class="mr-1 answer form-control"
                  :name="'Field_' + ${(index + 1)}"
                >
                <option selected disabled>Select an answer</option>
              `
              if (answers.length) {
                answers.forEach(answer => {
                  html += `<option data-id="${dataString}-${ind}-${index}" value="${answer}">${answer}</option>`
                })
              }
              html += '</select>'
            } else {
              html += `
                <input
                  type="text"
                  :id="'Field_' + ${(index + 1)}"
                  data-id="${dataString}-${ind}-${index}"
                  style="display: inline-block; width: 100% !important;margin-top:2px"
                  placeholder="Answer"
                  class="mr-1 answer form-control text-${this.templateOptions.textAlign}"
                  :name="'Field_' + ${(index + 1)}"
                  onpaste="return ${this.copyPasteEnabled};"
                />
              `
            }
          } else {
            html += element
          }
        })
      return html
    },
  },
}
</script>

<style scoped>
.shape-container {
  position: relative;
  width: 960px;
}
.container {
  position: absolute;
  top: 4%;
}
.container-lg-t-20 {
  top: 24%;
  width: 60%;
  left: 20%;
}
.before-template {
  width: 520px;
  padding: 8px;
}
.template-md {
  font-size: 16px !important;
  padding-bottom: 8px !important;
  width: 486px;
  height: 752px;
  position: relative;
  top: 12px;
  left: 8px;
}
.template-md .scrollbar {
  height: 735px;
  padding: 0 15px;
}
.template-xl {
  height: 525px;
  position: relative;
  bottom: 8px;
}
.template-xl .scrollbar {
  height: 555px;
  padding: 0 15px;
}
.template-lg-t-20 {
  height: 520px;
  position: relative;
  bottom: 10px;
}
.template-lg-t-20 .scrollbar {
  height: 555px;
  padding: 0 15px;
}
.question-card-lg,
.question-card-lg-t-20 {
  width: 75%;
  margin-bottom: 36px;
}
.question-card-md {
  width: 90%;
  margin-bottom: 20px;
}
.question-card-xl {
  width: 80%;
}

@media screen and (max-width: 1200px) {
  .shape-container {
    width: 780px;
  }
  .template-md {
    width: 394px;
    height: 610px;
    left: 6px;
  }
  .question {
    font-size: 20px;
  }
}

@media screen and (max-width: 992px) {
  .shape-container {
    width: 440px;
  }
  .template-md {
    width: 223px;
    height: 344px;
    top: 2px;
    left: 3px;
  }
  .question {
    font-size: 16px;
  }
  .question-card-md {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 576px) {
  .shape-container {
    width: 300px;
  }
  .container {
    padding: 0;
  }
  .template-md {
    width: 152px;
    height: 236px;
    top: 0;
  }
}
</style>

<style>
@media screen and (max-width: 1200px) {
  .before-template div p span {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 992px) {
  .before-template div p span {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 576px) {
  .problem-overview-content {
    padding: 0 !important;
  }
}
</style>
