<template>
  <b-card>
    <!-- media -->
    <b-media no-body v-if="false">
      <b-media-aside>

        <avatar
          :image="optionsLocal.avatar"
          :rounded="true"
          size="80px"
        />
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="ml-50">
        <!-- Plain mode -->
        <validation-observer
          ref="generalSettingsMediaForm"
        >
          <div>
            <validation-provider
              v-slot="{ errors }"
              name="avatar"
              rules="required"
              vid="avatar"
            >
              <!-- Styled -->
              <b-form-file
                id="avatar"
                ref="refInputEl1"
                v-model="avatar"
                :browse-text="$t('browseButton')"
                :placeholder="$t('issue-report-module.choose-a-file')"
                drop-placeholder="Drop file here..."
                class="mb-1"
                accept="image/jpeg, image/png, image/gif"
                capture=""
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small> <br>
              <small>
                {{ $t('files.accepted-images') }}
              </small>

            </validation-provider>
          </div>

          <!-- upload button -->
          <div v-if="avatar && generalData.avatar != avatar">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              :disabled="isUploadingImage"
              @click="submitForm()"
            >
              <b-spinner
                v-show="isUploadingImage"
                class="mr-1"
                small
                variant="light"
              />
              {{ $t('account-setting-general.upload') }}
            </b-button>
            <!-- reset -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              size="sm"
              class="mb-75 mr-75"
              :disabled="isProcessing"
              @click="reserForm()"
            >
              {{ $t('account-setting-general.reset') }}
            </b-button>
          <!--/ reset -->
          </div>
          <div v-else>
            <b-card-text> {{ $t('account-setting-general.allowed') }} </b-card-text>
          </div>
        </validation-observer>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <validation-observer
      ref="generalSettingsForm"
    >
      <b-form
        class="mt-2"
        @submit.prevent="sendForm"
      >

        <b-row>
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              name="First Name"
              rules="required|max:256"
              vid="firstname"
            >
              <b-form-group
                :label="$t('account-setting-general.first-name')"
                label-for="account-username"
              >
                <b-form-input
                  v-model="optionsLocal.firstname"
                  placeholder="First name"
                  name="firstname"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              name="Last Name"
              rules="required|max:256"
              vid="lastname"
            >
              <b-form-group
                :label="$t('account-setting-general.last-name')"
                label-for="account-name"
              >
                <b-form-input
                  v-model="optionsLocal.lastname"
                  name="lastname"
                  placeholder="Last name"
                  :state="errors.length > 0 ? false:null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              name="Nick Name"
              rules="required|max:256"
              vid="nickname"
            >
              <b-form-group
                :label="$t('account-setting-general.nick-name')"
                label-for="nickname"
              >
                <b-form-input
                  v-model="optionsLocal.nickname"
                  name="nickname"
                  placeholder="Nick Name"
                  :state="errors.length > 0 ? false:null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              name="Email"
              rules="required|email|max:256"
              vid="email"
            >
              <b-form-group
                :label="$t('account-setting-general.email')"
                label-for="account-e-mail"
              >
                <b-form-input
                  v-model="optionsLocal.email"
                  name="email"
                  placeholder="Email"
                  :state="errors.length > 0 ? false:null"
                />

              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              name="Phone"
              rules="required|numeric|max:14"
              vid="phone"
            >
              <b-form-group
                :label="$t('account-setting-general.phone')"
                label-for="account-company"
              >
                <b-form-input
                  v-model="optionsLocal.phone"
                  name="usertype"
                  placeholder=""
                  :state="errors.length > 0 ? false:null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>
          </b-col>

          <!-- alert -->
          <b-col
            v-if="!optionsLocal.email_verified_at"
            cols="12"
            class="mt-75"
          >
            <b-alert
              show
              variant="warning"
              class="mb-50"
            >
              <h4 class="alert-heading">
                Your email is not confirmed. Please check your inbox.
              </h4>
              <div class="alert-body">
                <b-link
                  :disabled="isResetProcessing"
                  class="alert-link"
                  @click="resendVerifyEmail"
                >
                  <b-spinner
                    v-show="isResetProcessing"
                    class="mr-1"
                    small
                    variant="dark"
                  />
                  Resend confirmation
                </b-link>
              </div>
            </b-alert>
          </b-col>
          <!--/ alert -->

          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              name="Country"
              vid="country"
              rules="required"
            >
              <b-form-group
                :label="$t('account-setting-general.country')"
                label-for="account-e-mail"
              >
                <b-form-select
                  v-model="optionsLocal.country"
                  :options="countries"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="self.usertype===USER_TYPE_STUDENT"
            sm="6"
          >
            <b-form-group
              label-for="account-e-mail"
              :label="$t('account-setting-general.parent-email')"
            >
              <validation-observer
                ref="inviteParentForm"
              >

                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  rules="email|max:256"
                  vid="email"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="parentEmail"
                      :disabled="self.parent_email!=null && self.parent_email.length>0"
                      :state="errors.length > 0 ? false:null"
                    />

                    <b-input-group-append>
                      <b-button
                        :disabled="errors.length > 0 || (parentEmail && parentEmail.length==0) || isProcessing || (self.parent_email!=null && self.parent_email.length>0)"
                        variant="outline-primary"
                        @click="inviteParent()"
                      >

                        {{ $t('actions.invite') }}
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </validation-observer>
            </b-form-group>
          </b-col>

          <b-col
            v-if="self.usertype===USER_TYPE_PARENT"
            sm="6"
          >

            <b-form-group
              label-for="account-e-mail"
              :label="$t('account-setting-general.child-name')"
            >
              <b-input-group>

                <b-form-input
                  v-model="optionsLocal.child_fullname"
                  :disabled="true"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col
            sm="6"
          >

            <b-form-group
              v-if="self.usertype !== USER_TYPE_SUPER"
              label-for="access_level"

              :label="$t('account-setting-general.access-type')"
            >
              <b-input-group>

                <b-form-input
                  v-model="optionsLocal.access_type"
                  class="text-capitalize"
                  :disabled="true"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col
            v-if="self.usertype ===USER_TYPE_STUDENT"
            sm="6"
          >

            <b-form-group
              label-for="dob"
              :label="$t('account-setting-general.dob')"
            >
              <b-input-group>

                <!-- <flat-pickr
                class="form-control"
              /> -->

                <flat-pickr
                  v-model="optionsLocal.dob"
                  class="form-control"
                  :config="{ altInput: true,altFormat: 'F j, Y', dateFormat: 'Y-m-d', maxDate:maxDate}"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <!--<b-col class="mt-2">
            <h5 style="margin-bottom:8px">
              {{ $t('account-setting-general.notification-settings') }}
            </h5>
            <b-form-checkbox
              v-model="optionsLocal.push_notification"
              class="custom-control-primary"
              style="margin-bottom:8px"
              @change="(value)=>saveUserSetting({key:'pusher',value})"
            >
              {{ $t('account-setting-general.receive-push-notifications') }}
            </b-form-checkbox>
            <b-form-checkbox
              v-model="optionsLocal.email_notification"
              class="custom-control-primary"
              @change="(value)=>saveUserSetting({key:'email',value})"
            >
              {{ $t('account-setting-general.receive-email-notifications') }}
            </b-form-checkbox>
          </b-col>-->

          <!--<b-col cols="12"
                 class="mt-2 row ml-1"
          >
            <b-col md="6" />
            <b-col md="6">
              <h5>Permission Settings</h5>
              <div style="gap:15px;"
                   class="d-flex align-items-center"
              >
                <label class="">Turn on Camera Permission </label>
                <b-form-checkbox switch
                                 :checked="askForPermission"
                                 @change="updateCameraPermission"
                />
              </div>
            </b-col>
          </b-col>-->

          <!--<b-col v-if="isASchoolUser">
            <b-row class="mt-2">
              <b-col>
                <h5 style="margin-bottom:8px">
                  Enable AI
                </h5>
                <b-form-checkbox
                  v-model="optionsLocal.open_ai"
                  class="custom-control-primary"
                  @change="(value)=>saveUserSetting({key:'open_ai',value})"
                >
                  Enable AI
                </b-form-checkbox>
              </b-col>
              <b-col>
                <h5 style="margin-bottom:8px">
                  School Daily Time
                </h5>
                <b-form-input
                  v-model="optionsLocal.study_time_daily"
                  class="custom-control-primary"
                  type="number"
                  placeholder="Time in hours"
                  @change="(value)=>saveUserSetting({key:'study_time_daily',value})"
                />
              </b-col>
            </b-row>
          </b-col>-->

          <!-- v-if="isStudentYoung() || !optionsLocal.dob" -->
          <b-col
            v-if="(self.usertype==USER_TYPE_STUDENT && optionsLocal.dob && isLargeDevices)"
            cols="6"
            class="pt-2"
          >

            <b-form-checkbox
              v-model="themeOn"
              class="custom-control-primary"
              name="check-button"
              switch
              inline
            >
              {{ $t('actions.turn-on-theme') }}
            </b-form-checkbox>

            <b-row
              v-if="themeOn"
              class="pt-1 pl-1"
            >
              <h4>Select Theme:</h4>
            </b-row>
            <b-row
              class="radio-grid"
            >
              <div
                v-for="theme in allThemes"
                :key="theme.name"
              >
                <h6>{{ theme.name }}</h6>
                <label class="radio-card">
                  <input
                    name="theme"
                    class="radio-input"
                    type="radio"
                    :value="theme.code"
                    :checked="theme.code==currentTheme"
                    :disabled="!optionsLocal.dob"
                    @change="updateCurrentTheme"
                  >

                  <span class="theme-details">

                    <img
                      :src="theme.image"
                      class="w-100 h-100"
                    >
                  </span>
                </label>
              </div>

              <!-- <b-col cols="4">
                <img
                  src="/animations/forest/skie.png"
                  class="w-100"
                >
              </b-col> -->
            </b-row>
            <b-row class="pt-1">
              <b-col cols="12">
                <b-form-checkbox
                  v-if="themeOn"
                  v-model="alarmOn"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                  inline
                >
                  {{ $t('actions.turn-on-alarm') }}
                </b-form-checkbox>
              </b-col>
            </b-row>

          </b-col>
          <b-col
            v-if="(self.usertype==USER_TYPE_STUDENT && !optionsLocal.dob)"
            cols="12"
            class="p-2"
          >
            <h6 class="text-info">
              Please set Date of Birth for Theme Feature
            </h6>
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="isProcessing"
              type="submit"
              @click.prevent="sendForm"
            >
              <b-spinner
                v-show="isProcessing"
                class="mr-1"
                small
                variant="light"
              />
              {{ $t('account-setting-general.save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :disabled="isProcessing"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              {{ $t('account-setting-general.reset') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="mt-2 ml-1"
              :disabled="isProcessing"
              type="submit"
              @click.prevent="deActiveAccount"
            >
              <b-spinner
                v-show="isProcessing"
                class="mr-1"
                small
                variant="light"
              />
              Delete Account
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :disabled="isProcessing"
              variant="outline-secondary"
              type="reset"
              class="mt-2 ml-2"
              @click.prevent="GO_BACK()"
            >
              {{ $t('actions.back') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BSpinner, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BFormSelect, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_STUDENT, USER_TYPE_PARENT, USER_TYPE_SUPER } from '@/const/userType'
import Avatar from '@/views/common/components/Avatar.vue'
import RegisteredCountries from '@/const/registered-country'

export default {
  components: {
    BButton,
    Avatar,
    BForm,
    BInputGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BInputGroupAppend,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isProcessing: false,
      isResetProcessing: false,
      isUploadingImage: false,
      previousImage: null,
      profileFile: null,
      showPreview: false,
      imagePreview: '',
      avatar: null,
      country: null,
      parentEmail: null,
      askForPermission: parseInt(getUserData()?.camara_setting, 10) === 1,
      maxDate: moment().subtract(4, 'years').format('YYYY-MM'),
      generalData: {
        avatar: '/img/13-small.d796bffd.png',
        username: 'johndoe',
        fullName: 'John Doe',
        email: 'granger007@hogward.com',
        company: 'Crystal Technologies',
      },
      optionsLocal: {
        avatar: '/img/13-small.d796bffd.png',
        username: 'johndoe',
        fullName: 'John Doe',
        email: 'granger007@hogward.com',
        company: 'Crystal Technologies',
        country: null,
        nickname: '',
      },
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
      countries: RegisteredCountries,
      language: null, // https://github.com/forxer/languages-list/blob/master/src/Languages.csv
      languages: [
        { value: null, text: 'Please select your naitve language' },
        { value: 'en', text: 'English' },
        { value: 'es', text: 'Español,' },
        { value: 'kr', text: '한국어' },
        { value: 'jp', text: '日本語' },
        { value: 'vi', text: 'Tiếng Việt' },
        { value: 'in', text: 'हिन्दी, हिंदी' },
      ],
      self: getUserData(),
      userTimezone: null,
    }
  },
  computed: {
    USER_TYPE_STUDENT() {
      return USER_TYPE_STUDENT
    },
    isASchoolUser() {
      return this.AUTH_USER().usertype === 'school'
    },
    USER_TYPE_PARENT() {
      return USER_TYPE_PARENT
    },
    USER_TYPE_SUPER() {
      return USER_TYPE_SUPER
    },

  },
  watch: {
    avatar() {
      const reader = new FileReader()
      this.isUploadingImage = true
      reader.addEventListener(
        'load',
        () => {
          this.isUploadingImage = false
          this.showPreview = true
          this.previousImage = this.optionsLocal.avatar
          this.optionsLocal.avatar = reader.result
        },
        false,
      )
      if (this.avatar) {
        if (/\.(jpe?g|png|gif)$/i.test(this.avatar.name)) {
          reader.readAsDataURL(this.avatar)
        } else {
          this.isUploadingImage = false
        }
      }
    },
  },
  mounted() {
    this.parentEmail = this.self.parent_email
    this.getUserData()
  },
  beforeCreate() {
    useJwt.getSchoolAndDistrictSettings().then(response => {
      const { school, district } = response.data.data
      const schoolSetting = school?.find(metaValue => metaValue.key === 'nav_general_setting') ?? '0'
      const districtSetting = district?.find(metaValue => metaValue.key === 'nav_general_setting') ?? '0'
      if (districtSetting?.value === '1' || schoolSetting?.value === '1') {
        this.$router.push('not-authorized')
      }
    })
  },
  created() {
    const { expires } = this.$route.query
    const { signature } = this.$route.query

    if (expires && signature) {
      this.isProcessing = true
      useJwt
        .verifyEmail({
          expires, signature,
        }).then(() => {
          this.optionsLocal.email_verified_at = 1
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Your email has been verified',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Your email has been verified. ',
            },
          }, {
            position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
          })
        }).finally(() => {
          this.isProcessing = false
        })
    }
  },
  methods: {
    saveUserSetting(d) {
      useJwt.updateUserSettings(d).then(res => {
        this.showSuccessMessage(res)
      })
    },

    getUserData() {
      this.isProcessing = true
      useJwt
        .userShow()
        .then(response => {
          this.generalData = response.data.data
          this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
          if (!this.optionsLocal.country) this.optionsLocal.country = null
        }).finally(() => {
          this.isProcessing = false
        })
    },
    inviteParent() {
      this.$refs.inviteParentForm.validate().then(success => {
        if (success) {
          this.isProcessing = false
          useJwt.inviteParent({
            email: this.parentEmail,
          }).then(response => {
            const userData = this.self
            // store this new parent_email
            userData.parent_email = this.parentEmail
            localStorage.setItem('userData', JSON.stringify(userData))

            this.showSuccessMessage(response)
          })
            .catch(error => {
              if (error.response?.data?.errors) {
                this.$refs.inviteParentForm.setErrors(error.response.data.errors)
              } else this.showErrorMessage(error)
            }).finally(() => {
              this.isProcessing = false
            })
        }
      })
    },

    reserForm() {
      this.optionsLocal.avatar = this.previousImage
      this.avatar = this.optionsLocal.avatar
    },
    submitForm() {
      this.isUploadingImage = true
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      data.append('avatar', this.$refs.refInputEl1.files[0])

      useJwt.axiosIns.post('/api/v1/user/avatar/update', data, config)
        .then(response => {
          this.optionsLocal = response.data.data
          // this.$root.$emit('userUpdate', response.data.data)
          this.avatar = null
          localStorage.setItem('userData', JSON.stringify(response.data.data))

          this.$refs.generalSettingsMediaForm.reset()
          this.$root.$emit('userUpdate', response.data)
          this.showSuccessMessage(response)
        })
        .catch(error => {
          if (error.response?.data?.errors) this.$refs.generalSettingsMediaForm.setErrors(error.response.data.errors)
          else this.showErrorMessage(error)
        })
        .finally(() => {
          this.isUploadingImage = false
        })
    },
    resendVerifyEmail() {
      this.isResetProcessing = true
      useJwt
        .resendVerifyEmail()
        .then(res => {
          this.showMessage(res.data.message)
        }).catch(err => {
          this.showErrorMessage(err)
        }).finally(() => {
          this.isResetProcessing = false
        })
    },

    updateCameraPermission(value) {
      useJwt.updateUserPermission({ permission: 'camera_setting', value }).then(() => {
        this.showSuccessMessage({ data: { message: 'Permission updated' } })
        this.askForPermission = value
        const currentUserData = getUserData()
        currentUserData.camara_setting = value ? 1 : 0
        this.updateUserData(currentUserData)
      })
    },

    sendForm() {
      this.$refs.generalSettingsForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt
            .userUpdate(
              {
                firstname: this.optionsLocal.firstname,
                lastname: this.optionsLocal.lastname,
                email: this.optionsLocal.email,
                nickname: this.optionsLocal.nickname,
                country: this.optionsLocal.country,
                language: this.optionsLocal.language,
                phone: this.optionsLocal.phone,
                dob: this.optionsLocal.dob,
              },
            )
            .then(response => {
              this.optionsLocal = response.data.data
              localStorage.setItem('userData', JSON.stringify(response.data.data))
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notice',
                  icon: 'CoffeeIcon',
                  variant: 'info',
                  text: 'Your profile has been successfully updated',
                },
              }, {
                position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
              })
              this.$store.commit('appConfig/UPDATE_IS_STUDENT_YOUNG')
              this.$root.$emit('userUpdate', response.data)
              if (parseInt(moment().diff(getUserData().dob, 'years'), 10) < 13) {
                this.$store.commit('appConfig/UPDATE_THEME_ON', true)
              }
            }).finally(() => {
              this.isProcessing = false
            })
        }
      })
    },

    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },

    showDobInfo() {
      this.$swal({
        titleText: 'Date of birth is not set.',
        showConfirmButton: false,
        position: 'top',
        timer: 2000,
      })
    },
    deActiveAccount() {
      this.$swal({
        title: 'Are you sure?',
        text: 'This action will permanently delete your account. Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
        reverseButtons: true,
      }).then(result => {
        if (result.value) {
          this.isProcessing = true
          setTimeout(() => {
            this.$swal({
              titleText: 'Your Account Delete Request has been sent for review.',
              showConfirmButton: false,
              position: 'top',
              timer: 2000,
            })
            localStorage.clear()
            this.$router.push('/login')
          }, 1500)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
