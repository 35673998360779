<template>
  <div class="row">
    <div v-for="row,index of rows"
         :key="index"
         class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column"
    >
      <div class="card d-flex flex-fill">
        <h4 class="card-header  border-bottom-0">
          {{ row.fullname }}
          <small
            v-if="row.nickname"
          >({{ row.nickname }})
          </small>

        </h4>
        <div class="card-body pt-0">
          <div class="row">
            <div class="col-4 text-center">
              <router-link
                :to="{ name: 'user-profile',params:{name:`${row.fullname.replaceAll('_','-').replaceAll(' ','-')}_${row.id}`}}"
                target="_blank"
                @click.stop
              >
                <span
                  class=""
                >
                  <b-avatar
                    v-if="row.avatar"
                    badge
                    :badge-variant="row.is_online ? 'success' : 'secondary'"
                    badge-offset="-0.2em"
                    :src="row.avatar"
                    class="ml-1 mt-1"
                    square
                    size="100"
                  />

                  <b-avatar
                    v-else
                    class="ml-1 mt-1"
                    badge
                    :badge-variant="row.is_online ? 'success' : 'secondary'"
                    badge-offset="-0.2em"
                    :text="getInitials(row.fullname)"
                    :size="100"
                    :variant="getRandomVariant()"
                    square
                  />
                  <!-- <div class="mt-1">{{ row.fullname }}</div>
                  <h5
                    v-if="row.nickname"
                    class="nickname mt-1"
                  >({{ row.nickname }})</h5>
                </span> -->
                </span></router-link>
              <!-- <img src="https://adminlte.io/themes/v3/dist/img/user1-128x128.jpg"
                   alt="user-avatar"
                   class="img-circle img-fluid"
              > -->
            </div>
            <div class="col-8">
              <h2 class="lead">
                <b>{{ row.email }}</b>
              </h2>
              <!-- <p class="text-muted text-sm">
                <b>Password: </b>
              </p> -->
              <ul class="pl-0">
                <li class="small">
                  <span class="fa-li">
                    <feather-icon icon="CalendarIcon" />
                  </span>{{ $t('user-module.user-table.join-date') }}: {{ FORMAT_DATE(row.created_at, 'MMM Do YYYY') }}
                </li>
                <li v-if="row.phone"
                    class="small margin-item"
                >
                  <span class="fa-li">
                    <feather-icon icon="PhoneCallIcon" />
                  </span>{{ $t('user-module.user-table.phone') }} : {{ row.phone }}
                </li>
                <li v-if="row.country"
                    class="small margin-item"
                >
                  <span class="fa-li">
                    <feather-icon icon="FlagIcon" />
                  </span>{{ $t('user-module.user-table.country') }} : {{ row.country }}
                </li>
                <li v-if="row.language"
                    class="small margin-item"
                >
                  <span class="fa-li">
                    <feather-icon icon="MessageCircleIcon" />
                  </span> {{ $t('user-module.user-table.language') }} : {{ row.language }}
                </li>
                <li
                  class="small mt-1"
                >
                  <template v-if="row.password">
                    <span
                      v-if="canShowPassword(row.id) || showAllPassword"
                      class="d-flex align-items-center"
                    >
                      <span class="mr-1">{{ row.password }}</span>
                      <feather-icon
                        role="button"
                        icon="EyeOffIcon"
                        @click="updateShowPassword(row.id)"
                      />
                    </span>
                    <span
                      v-else
                      class="d-flex align-items-center"
                    >
                      <span
                        class="mr-1"
                        style="margin-top: 8px;"
                      >**********</span>
                      <feather-icon
                        role="button"
                        icon="EyeIcon"
                        @click="updateShowPassword(row.id)"
                      />
                    </span>
                  </template>
                  <template v-else>
                    <span class="d-flex align-items-center small text-danger">
                      {{ $t('actions.reset-password') }}
                    </span>
                  </template>

                  <span v-if="AUTH_USER().user_level <= 7 && row.usertype !== 'student'">
                    <b-badge
                      class="rounded-pill success"
                    >
                      {{ row.child_count }}
                    </b-badge> {{ $t('user-module.child-users') }}
                  </span>
                  <span
                    v-if="row.access_type === 'full_deactivate'"
                    class="text-nowrap align-items-center alert alert-danger"
                    style="font-size:12px"
                  >{{ $t('deactivate-user.fully-deactivated') }}</span>
                  <span
                    v-if="row.access_type === 'partial_deactivate'"
                    class="text-nowrap align-items-center alert alert-warning"
                    style="font-size:12px"
                  >{{ $t('deactivate-user.partially-deactivated') }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-footer"
             :class="[ !isAParent(self.usertype) ? 'p-1' : 'p-0']"
        >
          <div class="text-right">
            <span v-if="canHaveChildUsers(users[index].usertype)">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="$t('actions.show-childusers')"
                :disabled="isProcessing"
                variant="outline-success"
                class="btn-icon mr-1 sm-mt-2"
                @click="$emit('onViewChildUsers', index)"
              >
                <feather-icon icon="UsersIcon" />
              </b-button>
            </span>
            <b-button
              v-if="users[index].usertype === 'school'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-delete-user
              v-b-tooltip.hover="'View Attendance'"
              v-b-tooltip.hover.top="$t('user-module.add-teacher')"
              :disabled="isProcessing"
              variant="primary"
              class="btn-icon sm-mt-2 mr-1"
              @click="$emit('onViewUserRelations', users[index].id, 'teacher', 'child')"
            >
              <span class="material-icons"
                    style="font-size: 1rem;"
              >co_present</span>
            </b-button>
            <b-button
              v-if="users[index].usertype === 'school' || users[index].usertype === 'teacher'"
              v-b-modal.modal-delete-user
              v-b-tooltip.hover="'View Attendance'"
              v-b-tooltip.hover.top="$t('user-module.add-student')"
              :disabled="isProcessing"
              variant="success"
              class="btn-icon sm-mt-2 mr-1"
              @click="$emit('onViewUserRelations', users[index].id, 'student', 'child')"
            >
              <span class="material-icons"
                    style="font-size: 1rem;"
              >group_add</span>
            </b-button>
            <span v-if="canViewStudentReport(self.usertype) && (isATeacher(users[index].usertype) || isAStudent(users[index].usertype))">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="$t('users-module.show-dashboard')"
                :disabled="isProcessing"
                variant="outline-success"
                class="btn-icon mr-1 sm-mt-2"
                @click="$router.push({ name: `student-dashboard-for-${self.usertype}`,params: {id: row.id }, query: { from: 'users' } })"
              >
                <feather-icon icon="HomeIcon" />
              </b-button>
              <b-button
                v-if="isASuper || isATeacher"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="$t('users-module.view-classrooms')"
                :disabled="isProcessing"
                variant="outline-info"
                class="btn-icon mr-1 sm-mt-2"
                @click="$emit('onViewStudentClassrooms', {id: row.id, usertype:users[index].usertype})"
              >
                <feather-icon icon="AirplayIcon" />
              </b-button>
            </span>
            <span v-if="isAStudent(users[index].usertype)">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-delete-user
                v-b-tooltip.hover="'View Attendance'"
                v-b-tooltip.hover.top="$t('users-module.view-attendance')"
                :disabled="isProcessing"
                variant="outline-info"
                class="btn-icon sm-mt-2 mr-1"
                @click="$router.push({ name: `${self.usertype}-view-attendance`, query: { fullname: row.fullname }, params: {id: row.id } })"
              >
                <feather-icon icon="ClockIcon" />
              </b-button>
            </span>
            <span v-if="!isAParent(self.usertype)">
              <b-dropdown
                ref="testDropDown"
                variant="link"
                toggle-class="text-decoration-none pl-0 inline"
                no-caret
                tag="button"
                class="p-0 m-0 inline-block pl-0"
              >
                <template #button-content>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :disabled="isProcessing"
                    variant="outline-secondary"
                    class="btn-icon sm-mt-2"
                  >
                    <feather-icon icon="MoreVerticalIcon" />
                  </b-button>
                </template>
                <b-dropdown-item
                  v-if="
                    canAttachParentToStudent(self.usertype) &&
                      isAStudent(users[index].usertype)
                  "
                  v-b-tooltip.hover.top="$t('users-module.assign-parent')"
                  @click="$emit('onAttachParent', index)"
                >

                  <feather-icon icon="PaperclipIcon" />
                  <span class="pl-1">{{ $t('users-module.assign-parent') }}</span>

                </b-dropdown-item>
                <b-dropdown-item @click="$emit('onEditUser', index)">
                  <feather-icon icon="EditIcon" />
                  <span class="pl-1">{{ $t('edit') }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="$emit('onDeleteUser', index)">
                  <feather-icon icon="TrashIcon" />
                  <span class="pl-1">{{ $t('delete') }}</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="isTrashedUsers"
                                 @click="$emit('onRestoreUser', row.id)"
                >
                  <feather-icon icon="RotateCcwIcon" />
                  <span class="pl-1">{{ $t('restore') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canHaveChildUsers(users[index].usertype)"
                  :disabled="isProcessing"
                  @click="$emit('onCreateUser', index)"
                >
                  <feather-icon icon="UserPlusIcon" />
                  <span class="pl-1">{{ $t('actions.add') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="isHighLevel"
                  @click="$emit('onResetPassword', index)"
                >
                  <feather-icon icon="LockIcon" />
                  <span class="pl-1">{{ $t('actions.reset-password') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="AUTH_USER().user_level <= 7"
                  :disabled="isProcessing"
                  @click="$emit('onViewUserRelations', users[index].id)"
                >
                  <feather-icon icon="UserPlusIcon" />
                  <span class="pl-1">{{ $t('actions.view-user-relations') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canCreateSubUsers(users[index].usertype)"
                  :disabled="isProcessing"
                  @click="$emit('onViewSubUsers', index)"
                >
                  <feather-icon icon="UsersIcon" />
                  <span class="pl-1">{{ $t('actions.show-subusers') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="$emit('onDeactivateUser', row)"
                >
                  <feather-icon icon="UserPlusIcon" />
                  <span class="pl-1">{{ $t('deactivate-user.deactivate') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {
  BButton,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['rows',
    'canShowPassword',
    'updateShowPassword',
    'columns',
    'users',
    'canHaveChildUsers',
    'isProcessing',
    'canCreateSubUsers',
    'canViewStudentReport',
    'isATeacher',
    'isAStudent',
    'self',
    'isASuper',
    'isAParent',
    'canAttachParentToStudent',
    'isTrashedUsers',
    'getInitials',
    'getRandomVariant',
    'showAllPassword',
  ],
}
</script>
<style scoped>
  .margin-item {
    margin-top: 3.5px;
  }
</style>
