<template>
  <component :is="isMobileDevice() ? 'portal' : 'div'"
             to="dragModalTargetArea"
  >
    <div
      v-if="!isPreviewMode"
      :options="{
        buttonClose: false,
        top: 300,
      }"
      :class="isMobileDevice() ? 'floating-position-mobile' : 'floating-position'"
    >
      <div
        class="d-flex justify-content-center"
      >
        <div ref="area"
             class="answer-list-area text-center"
             style="border-style: groove;min-height: 50px;min-width: 30px;padding: 10px;padding-right: 70px;"
             @dragover.prevent
             @dragenter.prevent
             @drop="undoAnswer($event)"
             @touchstart.stop="undoAnswer($event)"
        >
          <span v-for="la,index of defaultAnswers"
                :id="`draggable_answer_${index}`"
                :key="index"
                class="mb-1 d-inline-block draggable-answer"
                style="margin-bottom: 3px;"
                :draggable="true"
                @touchstart.stop="handleTouchStart(index)"
                @dblclick="handleDoubleClick(index)"
                @dragstart="startDrag($event, index)"
          >
            <b-button :variant="getRandomVariant()"
                      :data-pos="index"
                      class="no-transparent selectable-text"
                      :class="isMobileDevice() && 'btn-sm'"
                      role="button"
                      style="border: none !important;font-weight: 600;font-size: 18px;"
                      v-html="unescape(la)"
            />
          </span>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-1">
        <p
          style="font-size:13px; font-style: italic;"
        >
          <feather-icon icon="InfoIcon" />
          <span v-if="isMobileDevice()">
            {{ $t('student-test-module.drag-position-touch-hint') }}
          </span>
          <span v-else>
            {{ $t('student-test-module.drag-position-drag-hint') }}
          </span>
        </p>
      </div>
    </div>
  </component>
</template>
<script>
import { BButton } from 'bootstrap-vue'
// import DialogDrag from 'vue-dialog-drag'

export default {
  components: {
    BButton,
    // DialogDrag,
  },
  props: ['undoAnswer', 'startTouch', 'handleDoubleClick', 'startDrag', 'getRandomVariant', 'unescape', 'isPreviewMode', 'defaultAnswers'],
  data() {
    return {
      tapped: false,
    }
  },
  methods: {
    handleTouchStart(index) {
      if (this.tapped) {
        this.handleDoubleClick(index)
        this.tapped = false
      } else {
        this.tapped = true
        setTimeout(() => {
          this.tapped = false
        }, 300)
        this.startTouch(index)
      }
    },
  },
}
</script>
<style lang="scss">
.floating-position {
  position: sticky;
  bottom: 0;
  .answer-list-area {
    background: #f8f8f8f5;
  }
}
.floating-position-mobile {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  max-height: 100px;
  overflow-y: auto;
  .area-with-answer {
    background: #fff;
  }
  .answer-list-area {
    background: #f8f8f8f5;
  }

  .dialog-body {
    padding: 0 !important;
  }
}
.selectable-text {
  padding:  0.3rem 1rem !important;
  border: none !important;
  font-weight: 600;
}
.selectable-text:hover {
  color: blue;
}
</style>
