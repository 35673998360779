<template>
  <div>
    <b-card id="report-card">
      <b-row>
        <b-col
          cols="12"
          class="text-right"
        >
          <b-button
            variant="primary"
            data-html2canvas-ignore="true"
            @click="exportPdf()"
          >
            {{ $t('student-report-module.report-pages.export-pdf') }}
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <h4>
            {{ $t('student-report-module.report-pages.activity-attention-chart') }}
          </h4>
          <vue-apex-charts
            width="530"
            type="bar"
            :options="options"
            :series="options.series"
          />
        </b-col>
        <b-col
          class="mt-2 card  mr-2"
          cols="5"
        >
          <h4>{{ $t('student-report-module.summary') }}</h4>
          <strength-table
            class="mt-2"
            :summary="getMinMax"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row>
        <div class="light col-9  pb-1 lead font-weight-bold">
          {{ $t('student-report-module.report-pages.time-line-activity-attention') }}
        </div>
        <b-col
          cols="3"
          class="text-right pb-2"
        >

          <b-button
            variant="primary"
            @click="exportExcel()"
          >
            {{ $t('student-report-module.report-pages.export-excel') }}
            <feather-icon icon="FileTextIcon" />
          </b-button>

        </b-col>
      </b-row>
      <table class="table table-hover table-striped table-bordered table-sm">
        <thead>
          <tr>
            <th>
              {{ $t('student-report-module.report-pages.student-name') }}
            </th>
            <th
              v-for="n in monthList"
              :key="n"
            >
              {{ monthNames[n] }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td />
            <td
              v-for="n of monthList.length"
              :key="n"
            >
              <b-row><b-col>{{ $t('student-report-module.report-pages.activity') }}</b-col><b-col>{{ $t('student-report-module.report-pages.attention') }}</b-col></b-row>
            </td>
          </tr>
          <tr
            v-for="(report, index) of groupByKey(activityReport, 'user_id')"
            :key="index"
          >
            <td>
              <b>{{ report[0].full_name }}</b>
            </td>
            <td
              v-for="m of monthList"
              :key="m"
              :set="data=report.find(r => r.month_name == m)"
            >

              <b-row>
                <b-col
                  v-b-tooltip.hover.v-warning="{
                    title: getActivityAttentionScore(data, m).activityToolTip,
                    html: true, placement: 'top'
                  }"
                >
                  {{ getActivityAttentionScore(data, m).activity }}%
                </b-col>
                <b-col
                  v-b-tooltip.hover.v-warning="{
                    title: getActivityAttentionScore(data, m).attentionToolTip,
                    html: true, placement: 'bottom'
                  }"
                >
                  {{ getActivityAttentionScore(data, m).attention }}%
                </b-col>
              </b-row>

            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard, BCol, BRow, VBTooltip, BButton,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import VueApexCharts from 'vue-apexcharts'
import exportFromJSON from 'export-from-json'
import StrengthTable from './StrengthTable.vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    VueApexCharts,
    BButton,
    StrengthTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    classRoom: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      activityReport: [],
      maxActivityByDate: [],
      maxAttentionByDate: [],
      maxAttention: [],
      monthList: [],
      maxActivity: 0,
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      ],
      categories: [],
      scores: [],
      attentionScores: [],
      isProcessing: false,
      json_data: [],
    }
  },
  computed: {
    // series() {
    //   return [{
    //     name: 'series-1',
    //     data: this.scores,
    //   }]
    // },
    // attentionSeries() {
    //   return [{
    //     name: 'attention-series',
    //     data: this.attentionScores,
    //   }]
    // },

    options() {
      return {
        series: [{
          data: this.scores,
          name: 'Activity',
        }, {
          data: this.attentionScores,
          name: 'Attention',
        }],

        colors: ['#28c76f', '#4354ff'],
        chart: {
          id: 'vuechart-example',
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          formatter(val) {
            return `${val}%`
          },
          style: {
            fontSize: '12px',
            colors: ['#fff'],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: this.categories,
        },
      }
    },
    getMinMax() {
      const series = [{
        data: this.scores,
        name: 'Activity',
        title: 'activity',
      }, {
        data: this.attentionScores,
        name: 'Attention',
        title: 'attention',
      }]
      const reports = []
      series.forEach(score => {
        const maxScore = Math.max(...score.data.map(s => Number(s)))
        const minScore = Math.min(...score.data.map(s => Number(s)))
        const weakest = []
        const strongest = []
        this.categories.forEach((cat, index) => {
          const value = Number(score.data?.[index])
          if (value === maxScore) {
            strongest.push({
              fullName: cat,
              score,
            })
          } else if (value === minScore) {
            weakest.push({
              fullName: cat,
              score,
            })
          }
        })
        reports.push({
          title: i18n.tc(`student-report-module.report-pages.${score.title}`),
          weakest,
          strongest,
        })
      })
      return reports
    },
  },
  mounted() {
    this.getGroupActivityReport()
  },
  methods: {
    getGroupActivityReport() {
      useJwt.getGroupActivityReport(this.classRoom.id).then(res => {
        const { data } = res.data.data
        this.activityReport = data

        this.monthList = [...new Set(data.map(i => i.month_name))]

        // for over all score
        const group = this.groupByKey(data, 'user_id')

        this.maxActivityByDate = res.data.data.maxActivity
        this.maxAttentionByDate = res.data.data.maxAttention

        this.maxActivity = res.data.data.groupActivityMax
        this.maxAttention = res.data.data.groupAttentionMax

        const totalScore = []
        const categories = []

        const totalAttentionScore = []

        Object.entries(group).forEach(([, v]) => {
          categories.push(v[0].full_name)
          const score = v.reduce((p, c) => p + Number(c.total_activity), 0)
          const attentionScore = v.reduce((p, c) => p + Number(c.total_attention), 0)
          let percent = score
          if (this.maxActivity > 0) {
            percent = ((score / this.maxActivity) * 100).toFixed(2)
          }

          totalScore.push(percent)

          let attentionPercent = attentionScore
          if (this.maxAttention > 0) {
            attentionPercent = ((attentionScore / this.maxAttention) * 100).toFixed(2)
          }
          totalAttentionScore.push(attentionPercent)
          this.filterExcelReport(v)
        })
        this.categories = categories
        this.scores = totalScore
        this.attentionScores = totalAttentionScore
      })
    },
    getActivityAttentionScore(data, m) {
      let activityScore = 0
      if (data && data.total_activity > 0) {
        activityScore = ((data.total_activity / this.maxActivityByDate[m]) * 100).toFixed(2)
      }

      let attentionScore = 0
      if (data && data.total_attention > 0) {
        attentionScore = ((data.total_attention / this.maxAttentionByDate[m]) * 100).toFixed(2)
      }

      const scores = {
        activity: activityScore,
        activityToolTip: `${data ? data.total_activity.toFixed(2) : 0}/${this.maxAttentionByDate[m].toFixed(2)}`,
        attention: attentionScore,
        attentionToolTip: `${data ? data.total_attention.toFixed(2) : 0}/${this.maxAttentionByDate[m].toFixed(2)}`,
      }
      return scores
    },
    filterExcelReport(reports) {
      const newObject = {}
      reports.forEach(report => {
        newObject[`${this.monthNames[report.month_name]} (Activity)`] = report.total_activity.toFixed(2)
        newObject[`${this.monthNames[report.month_name]} (Attention)`] = report.total_attention.toFixed(2)
      })
      this.json_data.push({
        'Student Name': reports[0].full_name ?? '',
        ...newObject,
      })
    },
    exportPdf() {
      const element = document.getElementById('report-card')
      this.printPdf(element, 'attention-chart.pdf')
    },
    exportExcel() {
      const data = this.json_data
      const fileName = 'activity-report'
      const exportType = 'csv'

      exportFromJSON({ data, fileName, exportType })
    },
  },
}
</script>
