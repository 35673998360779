<template>
  <!-- Problem -->
  <b-col>
    <b-tabs
      pills
      align="center"
      class="lang-level-tabs"
    >
      <b-tab
        v-for="(level, index) in levels"
        :key="index + level"
        :title="getLangLevelTransText(level)"
        :title-item-class="showLangLevel ? '' : 'd-none'"
        :active="activeTabIndex === index"
        @click="tabChange(index)"
      >
        <b-row>
          <b-col
            md="12"
            :lg="problemGroupType === 'video' ? 12:4"
            class="p-0 pr-1"
          >
            <b-card>
              <div v-if="problemGroupType==='hot-spot'">
                <HotSpotStatementFeedbackForm
                  :key="index"
                  :index="index"
                />
              </div>
              <statement-feedback-form
                v-else
                :level="index"
                :video-type="problemGroupType === 'video'"
              />
            </b-card>
          </b-col>
          <b-col
            class="p-0"
          >
            <b-card>
              <cefr-form :level="index" />
              <speech-form
                v-if="problemType === 'speech'"
                :level="index"
                :question-type="questionType"
                :answer-type="answerType"
                @answers="setAnswers($event, index)"
                @questions="setQuestions($event, index)"
                @delete="deleteQA(index)"
              />
            </b-card>
            <b-card>
              <b-col md="12">
                <b-row>
                  <b-col
                    md="6"
                    sm="12"
                    xs="12"
                    class="mb-2 md-mb-0 d-flex justify-content-start"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="mr-2"
                      @click="
                        $router.push({
                          path: '/super/problem',
                          query: { lesson_id: $route.query.lesson_id },
                        })
                      "
                    >
                      {{ $t("actions.back") }}
                    </b-button>
                  </b-col>
                  <b-col
                    md="6"
                    sm="6"
                    xs="12"
                    class="d-flex justify-content-end"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-success"
                      class="mr-2"
                      @click="
                        $emit(
                          'showQuestionPreview',
                          getDataForQuestionPreview()
                        )
                      "
                    >
                      {{ $t("actions.preview") }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      :disabled="isProcessing"
                      @click="createQuestions"
                    >
                      <b-spinner
                        v-show="isProcessing"
                        small
                      />
                      {{ $t("actions.create-problem") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </b-col>
</template>

<script>
import {
  BCol, BRow, BTab, BTabs, BCard, BButton, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import StatementFeedbackForm from '../../components/create/StatementFeedback.vue'
import CefrForm from '../../components/create/Cefr.vue'
import SpeechForm from '../../components/create/SpeechForm.vue'
import HotSpotStatementFeedbackForm from '../../components/create/HotSpotStatementFeedback.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    BButton,
    BSpinner,
    StatementFeedbackForm,
    HotSpotStatementFeedbackForm,
    CefrForm,
    SpeechForm,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    questions: {
      text: [[], [], [], []],
      image: [[], [], [], []],
      imageUrl: [[], [], [], []],
      audio: [[], [], [], []],
      audioUrl: [[], [], [], []],
    },
    answers: {
      text: [[''], [''], [''], ['']],
      image: [[''], [''], [''], ['']],
      imageUrl: [[''], [''], [''], ['']],
      audio: [[''], [''], [''], ['']],
      audioUrl: [[''], [''], [''], ['']],
    },
    isProcessing: false,
    deleteIndex: null,
  }),
  computed: {
    typeOptions() {
      return this.$store.state.problem.typeOptions
    },
    problemGroupType() {
      return this.$store.state.problem.create.problemGroupType
    },
    problemType() {
      return this.$store.state.problem.activeProblemType
    },
    isValid() {
      return this.$store.getters['problem/isValid']
    },
    isLevelValid() {
      return this.$store.getters['problem/isLevelValid']
    },
    questionType: {
      get() {
        return this.$store.state.problem.create.questionType
      },
      set(value) {
        this.$store.commit('problem/SET_QA_TYPE', { type: 'questionType', value })
      },
    },
    answerType: {
      get() {
        return this.$store.state.problem.create.answerType
      },
      set(value) {
        this.$store.commit('problem/SET_QA_TYPE', { type: 'answerType', value })
      },
    },
    activeTabIndex: {
      get() {
        return this.$store.state.problem.activeTabIndex
      },
      set(value) {
        this.$store.commit('problem/SET_ACTIVE_TAB_INDEX', value)
      },
    },
    showLangLevel() {
      return this.$store.state.problem.showLangLevel
    },
    levels() {
      let levelTemp = this.$store.state.problem.levels
      if (!this.showLangLevel) levelTemp = [levelTemp[0]]
      return levelTemp
    },
  },
  created() {
    if (!this.showLangLevel) this.activeTabIndex = 0
  },
  methods: {
    tabChange(level) {
      this.activeTabIndex = level
    },
    setQuestions(questions, index) {
      const keys = Object.keys(this.questions)
      keys.forEach(k => {
        this.questions[k][index] = questions[k]
      })
    },
    setAnswers(answers, index) {
      const keys = Object.keys(this.answers)
      keys.forEach(k => {
        this.answers[k][index] = answers[k]
      })
    },
    deleteQA(index) {
      this.deleteIndex = index
    },
    createQuestions(e) {
      e.preventDefault()
      if (!this.isValid) {
        this.$store.commit('problem/SHOW_ERROR')

        // for 4 language levels
        if (this.showLangLevel) {
          if (this.isLevelValid.includes(false)) {
            const levels = ['A1', 'A2', 'B1', 'B2']
            this.isLevelValid.forEach((l, i) => {
              if (!l) this.showError(`Please fill all the required fields in level ${levels[i]}`)
            })
          }
        } else if (!this.isLevelValid[0]) { // without language level
          this.showError('Please fill all the required fields.')
        }
      } else {
        this.isProcessing = true
        this.$store.dispatch('problem/createQuestions')
          .then(response => {
            this.showSuccessMessage(response)
            if (this.problemGroupType === 'hot-spot') this.$emit('questionCreated', response.data.data.id)
            if (this.problemGroupType === 'text') {
              this.$root.$emit('onProblemCreate', {
                lesson_id: this.$route.query.lesson_id,
                problem_id: response.data.data.id,
              })
            } else {
              this.$store.commit('problem/UPDATE_TIMESTAMP', Number(response.data.data.video_timestamp_id))
            }
          })
          .catch(error => {
            this.showErrorMessage(error)
          })
          .finally(() => {
            this.isProcessing = false
            if (this.problemGroupType === 'video') this.$store.dispatch('problem/getCefrSubject')
          })
      }
    },
    getDataForQuestionPreview() {
      return this.$store.getters['problem/previewData']
    },
  },
}
</script>
