<template>
  <div>
    <b-modal
      id="report-details"
      :title="item.title"
      size="lg"
      ok-only
      :ok-title="$t('actions.close')"
      @close="$emit('close')"
    >
      <b-card-text class="text-secondary">
        <b>{{ $t('issue-report-module.reported-at') }} :</b> {{ formatDate(item.created_at) }}
      </b-card-text>
      <b-card-text
        v-if="item.solved_at"
        class="text-secondary"
      >
        <b>{{ $t('issue-report-module.solved-at') }} :</b> {{ formatDate(item.solved_at) }}
      </b-card-text>

      <b-row class="justify-content-between">
        <!-- Left Side -->
        <b-col
          md="9"
          class="border-right border-2"
        >
          <b-card>
            <!-- Page Link -->
            <div
              v-if="item.video_link && isPage()"
              class="mb-2"
            >
              <a
                :href="item.video_link"
                class="btn btn-danger"
                target="_blank"
              >
                {{ $t('issue-report-module.page-link') }}
              </a>
            </div>
            <div v-if="item.problem_id && isASuper">
              <router-link
                :to="{ name: 'super-problem-edit', query: { problem_id: item.problem_id, lesson_id: item.problem?.lesson_id }}"
                class="btn btn-primary"
                target="_blank"
              >
                {{ $t('issue-report-module.open-problem-in-editor') }}
              </router-link>
              <router-link
                :to="{ name: 'super-problem', query: { lesson_id: item.problem?.lesson_id }}"
                class="btn btn-info ml-2"
                target="_blank"
              >
                {{ $t('issue-report-module.open-problem-lession') }}
              </router-link>
              <b-form-checkbox v-model="problemVisibility"
                               switch
                               :value="1"
                               class="mt-2"
                               @change="val => togglePublic(item.problem.id, val)"
              >
                {{ $t('issue-report-module.problem-visibility') }}
              </b-form-checkbox>
            </div>
            <div v-if="isCurriculumIssue && isASuper"
                 class="mt-2"
            >
              <a
                :href="isCurriculumIssue"
                class="btn btn-primary"
                target="_blank"
              >
                {{ $t('issue-report-module.open-page-editor') }}
              </a>
            </div>
            <div v-if="isLessonPageIssue && isASuper">
              <a
                :href="isLessonPageIssue"
                class="btn btn-primary"
                target="_blank"
              >
                {{ $t('issue-report-module.open-page-editor') }}
              </a>
            </div>
            <hr>
            <!-- Description -->
            <div class="mb-2">
              <h5 class="mb-1">
                {{ $t('labels.description') }}
              </h5>
              <p>{{ item.description }}</p>
            </div>

            <!-- Image -->
            <div
              v-if="item.image"
              class="img-wrapper mb-2"
            >
              <img
                :src="item.image"
                alt=""
              >
            </div>
          </b-card>
        </b-col>

        <!-- Right Side -->
        <b-col
          md="3"
          class="pl-1"
        >
          <b-card>
            <!-- Error Type -->
            <h5 class="mb-1">
              {{ `${$t('issue-report-module.error-type')}: ${item.error_type}` }}
            </h5>

            <!-- Forms -->
            <div v-if="isASuper">
              <!-- Assign To -->
              <b-form-group
                :label="item.assigned_to ? `${$t('issue-report-module.assigned-to')}:` : `${$t('issue-report-module.assign-to')}:`"
                label-cols="12"
              >
                <b-form-input
                  v-model="item.assigned_to"
                  type="text"
                  :placeholder="$t('issue-report-module.assign-to')"
                  @change="$emit('update', 'assigned_to')"
                />
              </b-form-group>

              <!-- Status -->
              <b-form-group
                :label="`${$t('issue-report-module.status')}:` "
                label-cols="12"
              >
                <b-form-select
                  v-model="item.status"
                  :options="statusOptions"
                  @change="$emit('update', 'status')"
                />
              </b-form-group>
            </div>

            <div v-else>
              <!-- Status -->
              <h5 class="mb-2">
                {{ `${$t('labels.status')}: ${item.status}` }}
              </h5>
            </div>

            <!-- Video -->
            <div
              v-if="item.video_link && !isPage()"
            >
              <h5>{{ $t('labels.video') }}</h5>
              <b-embed
                type="iframe"
                :src="item.video_link"
                allowfullscreen
              />
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="10">
          <h4>{{ $t('issue-comment-module.title') }}</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-observer
            ref="addCommentForm"
          >
            <b-form class="add-comment-form"
                    @submit.prevent="addComment"
            >
              <validation-provider
                v-slot="{ errors }"
                name="comment"
                rules="required|min:5"
              >
                <b-input-group>
                  <b-form-input
                    v-model="comment.message"
                    type="text"
                    :placeholder="$t('issue-comment-module.comment')"
                  />
                  <b-input-group-append>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-success"
                      type="submit"
                      :disabled="isProcessing"
                      @click.prevent="addComment"
                    >
                      <b-spinner
                        v-show="isProcessing"
                        small
                      />
                      {{ $t('issue-comment-module.button.add') }}</b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
      <b-row class="mt-2 comment-section">
        <b-col v-if="comments.length > 0">
          <div v-for="commentdata in comments"
               :key="commentdata.id"
               class="mb-2 d-flex"
          >
            <span
              class="b-avatar badge-light rounded-circle"
              style="width: 40px; height: 40px;"
            >
              <span class="b-avatar-img">
                <img :src="commentdata.commented_by.avatar"
                     :alt="`${commentdata.commented_by.firstname} ${commentdata.commented_by.lastname}`"
                >
              </span>
            </span>
            <span class="comment-detail ml-2">
              <span class="commentedby">
                {{ commentdata.commented_by.firstname }} {{ commentdata.commented_by.lastname }}
                <span v-if="self.id == commentdata.commented_by.id "
                      style="font-size: 14px"
                >({{ $t('you') }})</span>
                <span class="float-right"
                      style="margin-top: -4px"
                >
                  <b-button
                    v-b-tooltip.hover.top="$t('issue-comment-module.reply')"
                    variant="outline-primary"
                    class="btn-icon btn-sm btn-default no-border"
                    @click="toggleAddCommentModal(commentdata.id)"
                  >
                    <feather-icon icon="CornerDownRightIcon" />
                  </b-button>
                  <b-button
                    v-if="self.id == commentdata.commented_by.id "
                    v-b-tooltip.hover.top="$t('issue-comment-module.edit')"
                    variant="outline-success"
                    class="btn-icon btn-sm no-border"
                    @click="toggleEditModal(commentdata)"
                  >
                    <feather-icon icon="EditIcon"
                                  title="Edit"
                    />
                  </b-button>
                  <b-button
                    v-if="self.id == commentdata.commented_by.id "
                    v-b-tooltip.hover.top="$t('issue-comment-module.delete')"
                    variant="outline-danger"
                    class="btn-icon btn-sm no-border"
                    @click="toggleDeleteModal(commentdata.id)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </span>
              </span>

              <span class="comment-body">
                <span v-b-tooltip.hover.top="formatDate(commentdata.created_at)"
                      class="commentedat float-right badge ml-auto badge-light-primary badge-pill"
                >
                  {{ commentedTime(commentdata.created_at) }}
                </span>
                {{ commentdata.message }}
              </span>
              <!--child comments-->
              <span v-if="commentdata.child_comments && commentdata.child_comments.length > 0"
                    class="mt-2 child-comments"
              >
                <div v-for="childComment in commentdata.child_comments"
                     :key="childComment.id"
                     class="d-flex"
                >
                  <span
                    class="b-avatar badge-light rounded-circle"
                    style="width: 30px; height: 30px;"
                  >
                    <span class="b-avatar-img">
                      <img :src="childComment.commented_by.avatar"
                           :alt="`${childComment.commented_by.firstname} ${childComment.commented_by.lastname}`"
                      >
                    </span>
                  </span>
                  <span class="comment-detail ml-2"
                        style="margin-bottom: 10px;"
                  >
                    <span class="commentedby"> {{ childComment.commented_by.firstname }} {{ childComment.commented_by.lastname }}
                      <span v-if="self.id == childComment.commented_by.id "
                            style="font-size: 14px"
                      >({{ $t('you') }})</span>
                      <span class="float-right">
                        <b-button
                          v-if="self.id == childComment.commented_by.id "
                          v-b-tooltip.hover.top="$t('issue-comment-module.edit')"
                          variant="outline-success"
                          class="btn-icon btn-sm no-border"
                          @click="toggleEditModal(childComment)"
                        >
                          <feather-icon icon="EditIcon"
                                        title="Edit"
                          />
                        </b-button>
                        <b-button
                          v-if="self.id == childComment.commented_by.id "
                          v-b-tooltip.hover.top="$t('issue-comment-module.delete')"
                          variant="outline-danger"
                          class="btn-icon btn-sm no-border"
                          @click="toggleDeleteModal(childComment.id)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </span>
                    </span>
                    <span class="comment-body">
                      <span v-b-tooltip.hover.top="formatDate(childComment.created_at)"
                            class="commentedat float-right badge ml-auto badge-light-primary badge-pill"
                      >
                        {{ commentedTime(childComment.created_at) }}
                      </span>
                      {{ childComment.message }} </span>
                  </span>
                </div>
              </span>
            </span>
          </div>
        </b-col>
        <b-col v-else>
          {{ $t('issue-comment-module.no-comments') }}
        </b-col>
      </b-row>
    </b-modal>

    <!--Reply Comment modal-->
    <b-modal
      id="modal-reply"
      ref="modal-reply"
      ok-only
      ok-variant="primary"
      :ok-title="$t('confirm')"
      hide-footer
      :click-to-close="false"
      :no-close-on-backdrop="true"
      centered
      :title="$t('issue-comment-module.reply')"
    >
      <!--Edit Form-->
      <validation-observer
        ref="replyCommentForm"
      >
        <b-form class="add-comment-form"
                @submit.prevent="submitReplyComment"
        >
          <validation-provider
            v-slot="{ errors }"
            name="comment"
            rules="required|min:5"
          >
            <b-input-group>
              <b-form-input
                v-model="replyComment.message"
                type="text"
                placeholder="Comment"
              />
              <b-input-group-append>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-success"
                  type="submit"
                  :disabled="isProcessing"
                  @click.prevent="submitReplyComment"
                >
                  <b-spinner
                    v-show="isProcessing"
                    small
                  />
                  {{ $t('issue-comment-module.button.reply') }}</b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>

    <!--Delete Comment modal-->
    <b-modal
      id="modal-delete"
      ref="modal-delete"
      ok-only
      ok-variant="danger"
      :ok-title="$t('confirm')"
      modal-class="modal-danger"
      centered
      :title="$t('help-videos-moduel.delete_confirm')"
      @ok="deleteComment"
    >
      <h4> {{ $t('issue-comment-module.delete') }} </h4>
      <template #modal-footer="{ok }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              :disabled="isProcessing"
              @click="ok()"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t("confirm") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <!--Edit Comment modal-->
    <b-modal
      id="modal-edit"
      ref="modal-edit"
      ok-only
      ok-variant="primary"
      :ok-title="$t('confirm')"
      hide-footer
      :click-to-close="false"
      :no-close-on-backdrop="true"
      centered
      :title="$t('issue-comment-module.edit')"
    >
      <!--Edit Form-->
      <validation-observer
        ref="editCommentForm"
      >
        <b-form class="edit-comment-form"
                @submit.prevent="submitEditComment"
        >
          <validation-provider
            v-slot="{ errors }"
            name="comment"
            rules="required|min:5"
          >
            <b-input-group>
              <b-form-input
                v-model="editComment.message"
                type="text"
                placeholder="Comment"
              />
              <b-input-group-append>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-success"
                  type="submit"
                  :disabled="isProcessing"
                  @click.prevent="submitEditComment"
                >
                  <b-spinner
                    v-show="isProcessing"
                    small
                  />
                  {{ $t('issue-comment-module.button.edit') }}</b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BModal, BFormGroup, BFormInput, BFormSelect, BCard, BCardText, BEmbed, BFormCheckbox, BButton, BForm, BInputGroup, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BCardText,
    BEmbed,
    BFormCheckbox,
    BButton,
    BForm,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true,
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
    isASuper: {
      type: Boolean,
      default: () => false,
    },
    self: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      curriculumPageUrl: null,
      problemVisibility: 0,
      isProcessing: false,
      comment: {},
      replyComment: {},
      comments: [],
      deleteCommentItem: null,
      editComment: {},
    }
  },
  computed: {
    isCurriculumIssue() {
      if (this.item.video_link?.startsWith('/curriculum/') && this.item.video_link?.includes('/pages/')) {
        const pageId = this.item.video_link.split('/pages/')[1]
        return `/super/${pageId}/edit-curriculum-page`
      }
      if (this.curriculumPageUrl) {
        return this.curriculumPageUrl
      }
      return null
    },
    isLessonPageIssue() {
      if (this.item.video_link?.startsWith('/lessons/') && this.item.video_link?.includes('/pages/')) {
        const pageId = this.item.video_link.split('/pages/')[1]
        return `/super/${pageId}/edit-lesson-page`
      }
      return null
    },
  },
  watch: {
    item: {
      handler(value) {
        if (value && value.problem_id) {
          this.curriculumPageUrl = null
          this.problemVisibility = value.problem?.is_public
          useJwt.getPageFromProblem(value.problem_id).then(response => {
            if (response.data.data.page) {
              const { page } = response.data.data
              const setNo = response.data.data.set_no
              this.curriculumPageUrl = `/super/${page.id}/edit-curriculum-page?set_no=${setNo}`
            }
          })
        }
        if (value) {
          this.getComments()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // eslint-disable-next-line camelcase
    togglePublic(id, isPublic) {
      const args = {
        id, is_public: isPublic, model: 'problem',
      }
      useJwt.changeVisibility(args)
        .then(res => {
          this.showSuccessMessage(res)
          this.problemVisibility = res.data.data.is_public
        })
    },
    formatDate(date) {
      return moment(date).format('Do MMMM YYYY')
    },
    getStatus(status) {
      return this.statusOptions.find(option => option.value === status).text
    },
    isPage() {
      return this.item.video_link.charAt(0) === '/'
    },
    // formatErrorPageLink(link) {
    //   return link.replace('super', 'teacher')
    // },
    commentedTime(date) {
      // return moment(date).fromNow(true)

      const now = moment()
      const targetDate = moment(date)
      let time = ''
      const diff = now.diff(targetDate, 'minutes')
      if (diff < 60) {
        time = `${diff}  m` // Less than 1 hour, show minutes
      } else if (diff < 1440) {
        time = `${Math.floor(diff / 60)}  h` // Less than 1 day, show hours
      } else {
        time = `${Math.floor(diff / 1440)}  d` // More than 1 day, show days
      }
      return time
    },
    getComments() {
      useJwt.getIssueComments(this.item.id)
        .then(response => {
          this.comments = response.data
        }).catch(error => {
          console.log(error)
        })
    },

    toggleAddCommentModal(commentId = '') {
      if (commentId) {
        this.replyComment.parent_comment_id = commentId
        this.isReply = true
      }
      this.$refs['modal-reply'].toggle()
    },
    submitReplyComment() {
      this.$refs.replyCommentForm.validate()
        .then(success => {
          if (success) {
            this.isProcessing = true
            useJwt.addIssueComment(this.item.id, this.replyComment)
              .then(response => {
                this.getComments()
                this.replyComment = {}
                this.$refs['modal-reply'].toggle()
                this.showSuccessMessage(response)
                this.isProcessing = false
              })
          }
        })
    },
    addComment() {
      this.$refs.addCommentForm.validate()
        .then(success => {
          if (success) {
            this.isProcessing = true
            useJwt.addIssueComment(this.item.id, this.comment)
              .then(response => {
                this.getComments()
                this.comment = {
                  message: '',
                }
                this.$refs.addCommentForm.reset()
                this.showSuccessMessage(response)
                this.isProcessing = false
              })
          }
        })
    },

    toggleDeleteModal(commentId) {
      this.deleteCommentItem = commentId
      this.$refs['modal-delete'].toggle()
    },
    deleteComment() {
      this.isProcessing = true
      useJwt.deleteIssueComment(this.item.id, this.deleteCommentItem)
        .then(response => {
          this.showSuccessMessage(response)
          this.getComments()
          this.isProcessing = false
        })
    },

    toggleEditModal(comment) {
      this.editComment = { ...comment }
      this.$refs['modal-edit'].toggle()
    },
    submitEditComment() {
      this.$refs.editCommentForm.validate()
        .then(success => {
          if (success) {
            this.isProcessing = true
            const params = {
              message: this.editComment.message,
            }
            useJwt.editIssueComment(this.item.id, this.editComment.id, params)
              .then(response => {
                this.getComments()
                this.editComment = {}
                this.$refs['modal-edit'].toggle()
                this.showSuccessMessage(response)
                this.isProcessing = false
              })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
  .img-wrapper {
    width: 100%;

    img {
      width: 100%;
      object-fit: 'cover';
    }
  }

  .card {
    box-shadow: none;
  }

  .comment-section {
    border: 1px solid #d8d6de;
    border-radius: 7px;
    padding: 10px 0px 10px 0px;

    .comment-detail {
      display: inline-grid;
      vertical-align: middle;
      width: 100%;

      .commentedby{
        font-size: 16px;
        font-style: italic;
      }
      .commentedat {
        font-size: 13px;
        font-style: italic;
      }
      .comment-body {
        font-size: 14px;

        .comment-info{
          display: inherit;
          font-size: 13px;
        }
      }
      .child-comments{
        border: 1px solid #d8d6de;
        border-radius: 7px;
        padding: 8px;

        .comment-body {
          font-size: 14px;
        }
      }
    }
    .no-border {
      border: none !important;
    }
  }
</style>
