/* eslint-disable import/prefer-default-export */
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { getUserData } from '@/auth/utils'
import i18n from '@/libs/i18n'

// const TOTAL_CODES = 76
// const TOTAL_CODES = 35
const TOTAL_CODES = ['dev', 'release'].includes(process.env.VUE_APP_ENV) ? 76 : 35
export const THEMES = {
  forest: {
    name: 'Forest',
    image: '/animations/themes/forest-theme.png',
    file: '/animations/themes/forest_theme.glb',
    code: 'forest',
    character: {
      file: '/animations/themes/forest/monkey_v5.glb',
    },
  },
  // ocean: {
  //   name: 'Ocean Theme',
  //   image: '/animations/themes/ocean-theme.png',
  //   file: '/animations/themes/ocean_theme.glb',
  //   code: 'ocean',
  //   character: {
  //     file: '/animations/themes/forest/monkey_v5.glb',
  //   },
  // },
  ...(['dev', 'release'].includes(process.env.VUE_APP_ENV) ? {
    ocean_1: {
      name: 'Ocean',
      image: '/animations/themes/ocean_creatures.png',
      file: '/animations/themes/ocean_theme_1.glb',
      code: 'ocean_1',
      character: {
        file: '/animations/themes/forest/monkey_v5.glb',
      },
    },
    cowboy: {
      name: 'CowBoy',
      image: '/animations/themes/cowboy.png',
      file: '/animations/themes/cowboy_.glb',
      code: 'cowboy',
      character: {
        file: '/animations/themes/forest/monkey_v5.glb',
      },
    },
    space: {
      name: 'Space',
      image: '/animations/themes/space.png',
      file: '/animations/themes/space.glb',
      code: 'space',
      character: {
        file: '/animations/themes/forest/monkey_v5.glb',
      },
    },
  } : {}),

}

export const FOREST_CHARACTERS = [
  {
    name: 'left-animal',
    file: '/animations/animals/elephant_.glb',
    left: '-1%',
    top: '70%',
    character: 'elephant',
    currentAnimation: 'idle',
    rotationAngle: 0.5,
    characterType: 'left',
    cameraPosition: { x: 0, y: 1, z: 2 },
    scaling: { x: 1.5, y: 1.2, z: 1 },
    sound: '/animations/animals/elephant.mp3',
  },

  {
    name: 'right-animal',
    file: '/animations/animals/lion_.glb',
    left: '90%',
    top: '70%',
    currentAnimation: 'idle',
    character: 'lion',
    rotationAngle: -0.5,
    characterType: 'right',
    cameraPosition: { x: 0, y: 1, z: 2 },
    scaling: { x: 1.5, y: 1.2, z: 1 },
    sound: '/animations/animals/lion.mp3',
  },
]

export const ALL_FOREST_CHARACTERS = [
  {
    name: 'elephant',
    file: '/animations/animals/elephant_.glb',
    image: '/animations/animals/elephant.png',
    sound: '/animations/animals/elephant.mp3',
  },
  {
    name: 'lion',
    file: '/animations/animals/lion_.glb',
    image: '/animations/animals/lion.png',
    sound: '/animations/animals/lion.mp3',
  },
  //   {
  //     name: 'crocodile',
  //     file: '/animations/animals/crocodile_.glb',
  //   },
  {
    name: 'giraffe',
    file: '/animations/animals/giraffe_.glb',
    image: '/animations/animals/giraffe.png',
    sound: '/animations/animals/giraffe.mp3',
  },
  {
    name: 'tiger',
    file: '/animations/animals/tiger_.glb',
    image: '/animations/animals/tiger.png',
    sound: '/animations/animals/tiger.mp3',
  },
  ...(['dev', 'release'].includes(process.env.VUE_APP_ENV) ? [
    {
      name: 'karen',
      file: '/animations/animals/karen_.glb',
      image: '/animations/animals/karen.png',
      sound: '',
    },
    {
      name: 'bill',
      file: '/animations/animals/bill_.glb',
      image: '/animations/animals/bill.png',
      sound: '',
    },
    {
      name: 'alice',
      file: '/animations/animals/alice_.glb',
      image: '/animations/animals/alice.png',
      sound: '',
    },
    {
      name: 'julie',
      file: '/animations/animals/julie_.glb',
      image: '/animations/animals/julie.png',
      sound: '',
    },
    {
      name: 'kevin',
      file: '/animations/animals/kevin_.glb',
      image: '/animations/animals/kevin.png',
      sound: '',
    },
    {
      name: 'mike',
      file: '/animations/animals/mike_.glb',
      image: '/animations/animals/mike.png',
      sound: '',
    },
  ] : []),

//   {
//     name: 'hippo',
//     file: '/animations/animals/hippo_.glb',
//   },
]

export const FOREST_CHARACTERS_ANIMATIONS = {
  idle: 0, lookingHeadDress: 1, lookingPants: 2, lookingShirt: 3, roll: 4, walk: 5,
}

export const wearableTypes = [
  { value: null, text: i18n.tc('animateditem-module.wearable.select-wearable') },
  { value: 'head_wear', text: i18n.tc('animateditem-module.wearable.head_wear') },
  { value: 'body_wear', text: i18n.tc('animateditem-module.wearable.body_wear') },
  { value: 'sunglasses', text: i18n.tc('animateditem-module.wearable.sunglasses') },
  { value: 'pants', text: i18n.tc('animateditem-module.wearable.pants') },
  { value: 'foot_wear', text: i18n.tc('animateditem-module.wearable.foot_wear') },
  { value: 'other', text: i18n.tc('animateditem-module.wearable.other') },

]

export const FOREST_CHARACTERS_ALL_OUTFITS = () => {
  const arr = []
  for (let i = 1; i <= TOTAL_CODES; i += 1) {
    const num = i < 10 ? `0${i}` : i.toString()
    arr.push(num)
  }
  return arr
}

const loadAllFiles = () => {
  const loader = new GLTFLoader()
  const dracoLoader = new DRACOLoader()
  dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/')
  loader.setDRACOLoader(dracoLoader)
  ALL_FOREST_CHARACTERS.forEach(async file => {
    loader.load(file.file, () => {})
  })

  Object.keys(THEMES).forEach(async theme => {
    loader.load(THEMES[theme].file, () => {})
  })
}

if (getUserData() && getUserData().usertype === 'student') {
  window.onload = () => {
    loadAllFiles()
  }
}

export const KIDS_CHARACTERS = [
  {
    name: 'left-animal',
    file: '/animations/animals/alice_.glb',
    left: '-1%',
    top: '70%',
    character: 'alice',
    currentAnimation: 'lookingUpNudging',
    rotationAngle: 0.5,
    characterType: 'left',
    cameraPosition: { x: 0, y: 1, z: 2 },
    scaling: { x: 1.5, y: 1.2, z: 1 },
    sound: '',
  },
  {
    name: 'right-animal',
    file: '/animations/animals/bill_.glb',
    left: '90%',
    top: '70%',
    currentAnimation: 'lookingUpNudging',
    character: 'bill',
    rotationAngle: -0.5,
    characterType: 'right',
    cameraPosition: { x: 0, y: 1, z: 2 },
    scaling: { x: 1.5, y: 1.2, z: 1 },
    sound: '',
  },
]

// export const DEFAULT_OCEAN_OUTFIT = ['36', '37', '62', '63', '66', '67', '68']

// export const DEFAULT_COWBOY_OUTFIT = ['36', '37', '62', '63', '73', '74', '75']

// export const DEFAULT_SPACE_OUTFIT = ['36', '37', '62', '63', '70', '71', '69']

export const DEFAULT_OCEAN_OUTFIT = {
  head: '36',
  hair: '37',
  body: '62',
  foot: '63',
  foot_wear: '66',
  body_wear: '67',
  head_wear: '68',
}
export const DEFAULT_COWBOY_OUTFIT = {
  head: '36',
  hair: '37',
  body: '62',
  foot: '63',
  foot_wear: '74',
  body_wear: '73',
  head_wear: '75',
}
export const DEFAULT_SPACE_OUTFIT = {
  head: '36',
  hair: '37',
  body: '62',
  foot: '63',
  foot_wear: '69',
  body_wear: '70',
  head_wear: '71',
}

export const KIDS_CHARACTERS_ANIMATIONS = {
  idle: 0, lookingUpNudging: 1, lookingLeg: 2, lookingDownNudging: 3,
}

export const ALL_KIDS_CHARACTERS = [
  {
    name: 'karen',
    file: '/animations/animals/karen_.glb',
    image: '/animations/animals/karen.png',
    sound: '',
  },
  {
    name: 'bill',
    file: '/animations/animals/bill_.glb',
    image: '/animations/animals/bill.png',
    sound: '',
  },
  {
    name: 'alice',
    file: '/animations/animals/alice_.glb',
    image: '/animations/animals/alice.png',
    sound: '',
  },
  {
    name: 'julie',
    file: '/animations/animals/julie_.glb',
    image: '/animations/animals/julie.png',
    sound: '',
  },
  {
    name: 'kevin',
    file: '/animations/animals/kevin_.glb',
    image: '/animations/animals/kevin.png',
    sound: '',
  },
  {
    name: 'mike',
    file: '/animations/animals/mike_.glb',
    image: '/animations/animals/mike.png',
    sound: '',
  },
]

export const ALL_ANIMALS_CHARACTERES = [
  {
    name: 'elephant',
    file: '/animations/animals/elephant_.glb',
    image: '/animations/animals/elephant.png',
    sound: '/animations/animals/elephant.mp3',
  },
  {
    name: 'lion',
    file: '/animations/animals/lion_.glb',
    image: '/animations/animals/lion.png',
    sound: '/animations/animals/lion.mp3',
  },
  {
    name: 'giraffe',
    file: '/animations/animals/giraffe_.glb',
    image: '/animations/animals/giraffe.png',
    sound: '/animations/animals/giraffe.mp3',
  },
  {
    name: 'tiger',
    file: '/animations/animals/tiger_.glb',
    image: '/animations/animals/tiger.png',
    sound: '/animations/animals/tiger.mp3',
  },
]

export const ALL_SPACE_OUTFIT = ['70', '71', '72']

export const ALL_COWBOY_OUTFIT = ['73', '74', '75']

export const ALL_OCEAN_OUTFIT = ['64', '65', '66', '67', '68', '69']
