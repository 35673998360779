<template>
  <div>
    <h4>
      <b-button
        variant="flat-secondary"
        @click="GO_BACK()"
      >
        <feather-icon
          icon="ArrowLeftIcon"
          size="20"
        />
      </b-button>
      {{ $t('manage-classroom') }}
    </h4>
    <b-overlay
      :show="isProcessing"
      class="mt-2"
    >
      <b-tabs pills>
        <b-tab
          v-for="tab of tabs"
          :key="tab.value"
          :title="tab.name"
          @click="getReservations(tab.value)"
        >
          <public-request
            :rows="rows"
            :status="tab.value"
            @filterReservation="filter => getReservations(status, page, filter)"
            @pickClassRoom="handleClassRoomPicker"
            @handleDelete="id => { reservationToDelete = id; }"
          />
        </b-tab>
      </b-tabs>
    </b-overlay>
    <ConfirmDelete
      :show="!!reservationToDelete"
      :is-processing-delete="isDeleting"
      :on-delete="handleDelete"
      :title="$t('delete')"
      @close="reservationToDelete = null"
    />
    <b-pagination
      :total-rows="totalRows"
      :per-page="perPage"
      :disabled="isProcessing"
      @input="page => getReservations(status, page)"
    />
    <classroom-picker
      :show="!!selectedReservation"
      :reservation-class="selectedReservation"
      :reservation="reservationInfo"
      @reFetch="getReservations"
      @close="selectedReservation= null"
    />
  </div>
</template>
<script>
import {
  BTab, BTabs, BOverlay, BPagination, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ConfirmDelete from '@/views/common/components/DeleteModal.vue'
import { weeklySchedules } from '@/@core/utils/utils'
import i18n from '@/libs/i18n'
import PublicRequest from './components/List.vue'
import ClassroomPicker from './components/ClassroomPicker.vue'

export default {
  components: {
    BTab,
    BTabs,
    BOverlay,
    PublicRequest,
    BPagination,
    BButton,
    ClassroomPicker,
    ConfirmDelete,
  },
  data() {
    return {
      tabs: [
        {
          name: i18n.tc('reservation-moduel.open'),
          value: 'open',
        },
        {
          name: i18n.tc('reservation-moduel.accepted'),
          value: 'accepted',
        },
        {
          name: i18n.tc('reservation-moduel.all'),
          value: 'all',
        },
      ],
      rows: [],
      isProcessing: false,
      reservationToEdit: null,
      status: 'open',
      isDeleting: false,
      reservationToDelete: null,
      totalRows: 1,
      perPage: 2,
      currentPage: 1,
      selectedRequests: [],
      reservationInfo: null,
      preferredTime: {
        day: null,
        time: null,
      },
      schedulesLists: [],
      selectedReservation: null,
    }
  },
  created() {
    this.getReservations()
  },
  methods: {
    resetFilter() {
      this.getReservations()
    },
    handleClassRoomPicker(reservation) {
      this.selectedReservation = reservation
      // if (!this.preferredTime.time || !this.preferredTime.day) {
      //   this.$swal({
      //     type: 'error',
      //     title: 'Please Select Day and time',
      //   })
      //   return
      // }
      // this.showClassroomChooser = true
      // this.selectedRequests = requests.filter(item => this.rows.find(row => Number(row.id) === Number(item)))
    },
    getReservations(status = this.status, page = this.currentPage) {
      if (this.isProcessing) {
        return
      }
      this.isProcessing = true
      this.status = status
      useJwt.getPublicReservation({
        params: {
          status,
          page,
          reservation_id: this.$route.params.id,
        },
      }).then(response => {
        const publicReservation = response.data.data.public_reservations
        this.rows = publicReservation.data
        this.totalRows = publicReservation.total
        this.perPage = publicReservation.per_page
        this.currentPage = page
        this.reservationInfo = response.data.data.reservation
        this.schedulesLists = weeklySchedules(null, this.reservationInfo.time_interval)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    handleEdit(reservation) {
      this.reservationToEdit = { ...reservation }
    },
    handleDelete() {
      this.isDeleting = true
      useJwt.destroyPublicReservation(this.reservationToDelete).then(response => {
        this.getReservations()
        this.reservationToDelete = null
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isDeleting = false
      })
    },
  },
}
</script>
