<template>
  <div class="export-button">
    <b-dropdown
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      :text="$t('student-report-module.report-pages.export-excel')"
      variant="outline-primary"
      right
      size="sm"
    >
      <b-dropdown-item @click="handleExportReport('follow_up')">
        {{ $t('calendar-module.follow-up') }}
      </b-dropdown-item>
      <!-- <b-dropdown-item @click="handleExportReport('in_progress')">
        Active
      </b-dropdown-item> -->
    </b-dropdown>
  </div>
</template>
<script setup>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import exportFromJSON from 'export-from-json'

const props = defineProps({
  followUp: {
    type: Array,
    default: () => [],
  },
  inProgress: {
    type: Array,
    default: () => [],
  },
})

const getFormattedReport = report => report.map(item => ({
  lesson_name: item.lessonName,
  started_at: item.created_at,
  passed: item.totalPass,
  points: item.points,
  score_percentage: item.percentage,
  total_lessons: item.totalLesson,
}))

const handleExportReport = type => {
  const exportType = 'csv'
  switch (type) {
    case 'follow_up': {
      const data = getFormattedReport(props.followUp)
      exportFromJSON({
        data,
        fileName: 'follow_up',
        exportType,
      })
      return null
    }
    case 'in_progress': {
      const data = getFormattedReport(props.inProgress)
      exportFromJSON({
        data,
        fileName: 'active_lesson',
        exportType,
      })
      return null
    }
    default: {
      return null
    }
  }
}

</script>
<style>
.export-button {
    float: right;
}
.modal-dialog .nav {
    border-right: 0 !important;
}
</style>
