<template>
  <b-modal
    :visible="show"
    title="Manage Sets"
    no-close-on-backdrop
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <validation-observer ref="simpleRules">
      <b-overlay :show="isProcessing">
        <b-form-group
          label="Set Images"
          label-for="tem-name"
        >
          <validation-provider
            v-slot="{ errors }"
            name="image|size:800"
            :custom-messages="{ image: $t('messages.invalid-image') }"
            vid="image"
          >
            <!-- media -->
            <b-media no-body>
              <b-media-aside>

                <avatar
                  size="50px"
                  :rounded="true"
                  :image="logoUrl"
                />
              <!--/ avatar -->
              </b-media-aside>

              <b-media-body class="ml-50">
                <!-- Plain mode -->
                <div>
                  <b-form-file
                    ref="refInputEl1"
                    v-model="brandImage"
                    :browse-text="$t('browseButton')"
                    :placeholder="$t('issue-report-module.choose-a-file')"
                    class="mb-1"
                    accept="image/*"
                  />

                </div>
                <div v-if="!brandImage && errors.length==0">
                  <b-card-text> {{ $t('account-setting-general.allowed') }} </b-card-text>
                </div>
              </b-media-body>
            </b-media>
          </validation-provider>
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group
              label="Set Color"
              label-class="text-center"
            >
              <verte
                v-model="form.styles.color"
                picker="wheel"
                model="rgb"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Set Background"
              label-class="text-center"
            >
              <verte
                v-model="form.styles.backgroundColor"
                picker="wheel"
                model="rgb"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Set Name">
          <validation-provider
            v-slot="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-input
              v-model="form.name"
              placeholder="Set Name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Set Descriptions">
          <b-form-textarea
            v-model="form.description"
            placeholder="Set Descriptions"
          />
        </b-form-group>
        <b-form-group label="Problems">
          <validation-provider
            v-slot="{ errors }"
            name="Problems"
            rules="required"
          >
            <v-select
              v-model="selectedProblems"
              placeholder="Problem"
              :options="problemsList"
              label="text"
              :multiple="true"
              :reduce="val => val.id"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-overlay>
    </validation-observer>
    <template #modal-footer="{}">
      <b-button
        variant="secondary"
        @click="$emit('close')"
      >
        Cancel
      </b-button>
      <b-button
        variant="success"
        @click="updateSets"
      >      <b-spinner
        v-if="isProcessing"
        small
      />
        Save
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal, BFormGroup, BFormInput, BButton, BOverlay, BFormTextarea, BMedia, BMediaBody, BFormFile, BCardText, BMediaAside, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Avatar from '@/views/common/components/Avatar.vue'
import Verte from 'verte'
import 'verte/dist/verte.css'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BMedia,
    BMediaBody,
    BFormFile,
    BCardText,
    BMediaAside,
    Avatar,
    vSelect,
    Verte,
    BRow,
    BCol,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    problems: {
      type: Array,
      default: () => [],
    },
    setInfo: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      selectedProblems: [],
      isProcessing: false,
      brandImage: null,
      logoUrl: null,
      form: {
        name: '',
        description: '',
        styles: {
          color: '',
          backgroundColor: 'rgba(5,5,5,0)',
        },
      },
      required,
    }
  },
  computed: {
    problemsList() {
      if (this.setInfo) {
        return this.problems.filter(item => !item.divider).map(item => ({ id: item.id, text: item.name }))
      }
      return this.problems.filter(item => !item.divider && !item.set_id).map(item => ({ id: item.id, text: item.name }))
    },
  },
  watch: {
    show() {
      this.selectedProblems = []
      this.handleFormChange()
    },
    setInfo() {
      this.handleFormChange()
    },
    brandImage() {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.showPreview = true
          this.logoUrl = reader.result
        },
        false,
      )
      if (this.brandImage) {
        if (/\.(jpe?g|png|gif)$/i.test(this.brandImage.name)) {
          reader.readAsDataURL(this.brandImage)
        }
      }
    },
  },
  methods: {
    handleFormChange() {
      const value = this.setInfo
      if (value) {
        this.form = {
          name: value.name,
          description: value.description,
          styles: this.JSON_PARSE(this.JSON_PARSE(value.styles)) || {},
          id: value.id,
        }
        this.selectedProblems = this.problems.filter(item => Number(item.set_id) === Number(value.id)).map(item => item.id).filter(item => item)
      } else {
        this.form = {
          name: '',
          description: '',
          styles: { color: '', backgroundColor: 'rgba(5,5,5,0)' },
        }
      }
    },
    createFormData() {
      const formData = new FormData()
      Object.entries(this.form).forEach(([key, value]) => {
        if (key === 'styles') {
          formData.append(key, JSON.stringify(value))
          return
        }
        formData.append(key, value)
      })
      this.selectedProblems.forEach(problem => {
        formData.append('problems[]', problem)
      })

      if (this.brandImage) {
        formData.append('image', this.brandImage)
      }
      // if (this.setInfo) {
      //   formData.append('_method', 'PUT')
      // }
      return formData
    },
    updateSets() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt.createProblemSetWrapper(this.$route.query.lesson_id, this.createFormData()).then(response => {
            this.$emit('close')
            this.$emit('fetchProblem')
            this.showSuccessMessage(response)
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => {
            this.isProcessing = false
          })
        }
      })
    },
  },
}
</script>
