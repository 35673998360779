<template>
  <div>
    <calibration :class-rooms-list="classRoomsList"
                 :number-of-students="numberOfStudents"
    />
  </div>
</template>
<script>
import useApollo from '@/@core/graphql/useApollo'
import useJwt from '@/auth/jwt/useJwt'
// import {
//   BCard, BFormGroup, BFormInput, BRow, BCol,
// } from 'bootstrap-vue'
import Calibration from './Calibration.vue'

export default {
  components: {
    Calibration,
    // BCard,
    // BFormGroup,
    // BFormInput,
    // BRow,
    // BCol,
  },
  data() {
    return {
      classRoomsList: [],
      numberOfStudents: 1,
      canViewCalibration: false,
    }
  },
  created() {
    this.getPagePermission()
  },
  mounted() {
    this.getClassrooms()
  },
  methods: {
    getClassrooms() {
      useApollo.getMyClassRoom().then(response => {
        this.classRoomsList = [...response.data.rooms.data]
      })
    },
    getPagePermission() {
      const formData = {
        data: '{}',
      }
      useJwt.getCalibrationPermission(formData)
        .then(response => {
          this.canViewCalibration = response.data.data.permissions.find(permission => permission.slug === 'settings.calibration')?.allow
          if (!this.canViewCalibration) {
            this.$router.push('not-authorized')
          }
        })
    },
  },
}
</script>
