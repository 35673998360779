<template>
  <b-modal
    v-if="student"
    id="move-student"
    :title="`${ $t('class-module.student-table.move') } ${student.fullname}`"
    :ok-title="$t('class-module.student-table.move')"
    :cancel-title="$t('labels.cancel')"
    no-close-on-backdrop
    @show="getClasses"
    @ok="moveStudent"
  >
    <p>{{ $t('class-module.student-table.from-classroom') }}: {{ currentRoom.name }}</p>
    <b-form-group
      :label="`${ $t('class-module.student-table.to-classroom') }:`"
    >
      <v-select
        v-model="selectedClass"
        :options="classes"
        label="text"
        :reduce="(item) => item.value"
      />
    </b-form-group>
    <p class="text-muted"
       style="font-size:13px; font-style: italic;"
    >
      <feather-icon icon="InfoIcon" />
      {{ $t('class-module.student-move-info') }}
    </p>
  </b-modal>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { BModal, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BModal, BFormGroup, vSelect,
  },
  props: {
    student: {
      type: Object,
      default: () => {},
    },
    currentRoom: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      classes: [
        { value: null, text: this.$i18n.tc('user-module.user-table.select-class'), disabled: true },
      ],
      selectedClass: null,
    }
  },
  methods: {
    getClasses() {
      if (this.classes.length === 1) {
        useJwt.getClasses().then(response => {
          const classes = response.data?.data
          classes.forEach(cls => {
            if (cls.id !== Number(this.currentRoom.id)) {
              this.classes.push({
                value: cls.id,
                text: cls.name,
              })
            }
          })
        })
      }
    },
    moveStudent() {
      const params = { destination_id: this.selectedClass }
      useJwt.moveStudent(
        this.currentRoom.id,
        this.student.id,
        params,
      ).then(response => {
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.$emit('onStudentMoved')
      })
    },
  },
}
</script>
