<template>
  <div>
    <b-card body-class="p-0"
            class="mb-1"
    >
      <ckeditor
        id="textarea-default"
        v-model.lazy="noteContent"
        placeholder="Type notes here.."
        rows="10"
        no-shrink
        :config="ckEditConfig"
        @focus="isNoteFocused = true"
      />
    </b-card>
    <div class="mb-1">
      <b-button variant="outline-primary"
                block
                :disabled="isProcessing"
                @click="postNotes(true)"
      >
        <b-spinner v-if="isProcessing"
                   small
        />
        Save Note
      </b-button>
    </div>
    <div
      v-if="note && noteId"
      class="comment"
    >
      <h4>{{ $t('student-notes-module.comments') }}</h4>
      <div
        v-for="comment of note.comments"
        :key="comment.id"
        class="card "
      >
        <b-media

          no-body
          class=" p-1 pb-0 d-flex"
        >
          <b-media-aside class="mr-1">
            <b-avatar
              :text="comment.user ? comment.user.firstname.charAt(0) : ''"
              size="20"
            />
          </b-media-aside>
          <b-media-body class="d-flex justify-content-between">
            <div>
              <h5
                v-if="comment.user"
                class="mb-0"
              >
                {{ comment.user.firstname }} {{ comment.user.lastname }}
              </h5>
              <p class="mt-1">
                {{ comment.comment }}
              </p>
            </div>
            <div v-if="self.id == comment.user.id">
              <feather-icon
                icon="TrashIcon"
                role="button"
                class="text-danger"
                @click="deleteComment(note, comment)"
              />
            </div>
          </b-media-body>
        </b-media>
      </div>
    </div>
    <b-form-input
      :placeholder="$t('enter-comment')"
      @keyup.enter="(text) => commentNote(text, note)"
    />
    <TemplateList v-if="showTemplates"
                  @close="showTemplates = false"
                  @applyNoteTemplate="template => { noteContent = template; showTemplates= false }"
    />
  </div>
</template>
<script>
import {
  BCard, BFormInput, BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import CKEditor from 'ckeditor4-vue'
import useJwt from '@/auth/jwt/useJwt'
import _ from 'lodash'
import { checkSettings } from '@/utils/visibilitySettings'
import TemplateList from './template-list.vue'

export default {
  components: {
    BCard,
    BButton,
    ckeditor: CKEditor.component,
    BFormInput,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    TemplateList,
    BSpinner,
  },
  props: {
    note: {
      type: Object,
      default: () => {},
    },
    lessonId: {
      type: Number,
      default: 0,
    },
    problemId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      noteContent: null,
      noteId: null,
      isNoteFocused: false,
      ckEditConfig: {
        allowedContent: true,
        basicEntities: true,
        entities: false,
        toolbar: [
          { name: 'basicstyles', items: ['Underline', 'Italic', 'Bold', 'list', 'colorbutton'] },
          { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'] },
          ['TextColor', 'BGColor'],
        ],
        extraPlugins: 'colorbutton',
        colorButton_colors: 'CF5D4E,454545,FFF,DDD,CCEAEE,66AB16',
        removeButtons: 'Subscript,Superscript',
      },
      fontSelected: '',
      self: this.AUTH_USER(),
      showTemplates: false,
      isProcessing: false,
    }
  },

  watch: {
    note: {
      handler(newValue) {
        this.noteContent = newValue?.content
        this.noteId = newValue?.id ?? null
      },
      deep: true,
    },
  },
  mounted() {
    const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
    const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
    const fontSetting = checkSettings('font', schoolSetting, distSetting, { schoolOverride: true })
    if (fontSetting) {
      this.fontSelected = fontSetting.value

      setTimeout(() => {
        const iframe = document.querySelector('#textarea-default .cke_wysiwyg_frame')
        const style = document.createElement('style')
        style.textContent = `.cke_editable {font-family:${this.fontSelected} !important}`
        iframe.contentDocument.head.appendChild(style)
      }, 2000)
    }
  },
  methods: {
    onNamespaceLoaded(CKEDITOR) {
      const self = this
      CKEDITOR.plugins.add('newplugin', {
        init(editor) {
          editor.ui.addButton('CustomButton', {
            label: 'Load Template',
            command: 'customCommand',
            icon: null,
          })

          editor.addCommand('customCommand', {
            exec() {
              // Handle the custom button click event here
              self.showTemplates = true
            },
          })
        },
      })
    },
    postNotes(notify = false) {
      this.isProcessing = true
      if (this.noteId) {
        useJwt.updateNote(this.noteId, {
          title: 'lesson-notes',
          content: this.noteContent?.trim() ? this.noteContent : '&nbsp;',
          lesson_id: this.lessonId,
          classId: this.$route.query.classId,
        }).then(response => {
          this.noteId = response.data?.data?.id
          if (notify) {
            this.showSuccess('Note Posted')
          }
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
      } else {
        useJwt.createNote({
          title: 'lesson-notes',
          content: this.noteContent?.trim() ? this.noteContent : '&nbsp;',
          lesson_id: this.lessonId,
          classId: this.$route.query.classId,
          ...this.problemId ? ({
            problem_id: this.problemId,
          }) : {},

        }).then(response => {
          this.noteId = response.data?.data?.id
          if (notify) {
            this.showSuccess('Note Posted')
          }
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
      }
    },
    deleteComment(note, comment) {
      useJwt.deleteNoteComment(comment.id).then(() => {
        this.showSuccess('Notes Deleted')
        // eslint-disable-next-line no-param-reassign
        note.comments = note.comments.filter(com => com.id !== comment.id)
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    // eslint-disable-next-line func-names
    updateNotes: _.debounce(function () {
      if (this.isNoteFocused) {
        this.postNotes()
      }
    }, 1500),
    commentNote(comment, note) {
      useJwt.postNotesComment({
        note_id: note.id,
        comment: comment.target.value,
      }).then(response => {
        this.showSuccessMessage(response)
        note.comments.push(response.data.data)
        // eslint-disable-next-line no-param-reassign
        comment.target.value = ''
      })
        .catch(error => {
          this.showErrorMessage(error)
        })
    },
  },

}
</script>
<style scoped>
::v-deep .cke_contents {
    height: 600px !important
}
::v-deep .cke_button__custombutton .cke_button_label {
  display: inline !important;
}
</style>
