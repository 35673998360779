const formatMultipleChoice = problem => {
  if (!problem.distractions) {
    throw new Error('Problem Content not available')
  }
  const { distractions } = problem
  // ? need to confirm
  const questionId = problem.id
  const answers = distractions.map(item => ({
    data_type: 'text',
    id: item.text,
    question_id: questionId,
    text: item.text,
  }))
  answers.push({
    data_type: 'text',
    id: problem.sentence_json.text,
    question_id: questionId,
    text: problem.sentence_json.text,
  })
  const questions = [
    {
      answers,
      data_type: 'image',
      file: problem.image,
      id: questionId,
      lang_level: 2,
      text: problem.vocab_word,
      extra_file: JSON.stringify({
        caption: problem.vocab_word,
      }),
    },
  ]
  return questions
}

const formatProblem = ({ problem }) => {
  const questions = formatMultipleChoice(problem)
  return {
    questions,
  }
}

export default formatProblem
