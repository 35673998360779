var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.minimized)?_c('div',[_c('div',{staticClass:"floating-icon",on:{"click":_vm.setChatOpened}},[(!_vm.chatOpened)?_c('feather-icon',{staticClass:"text-white",staticStyle:{"margin-left":"-2px","margin-top":"-2px"},attrs:{"size":"32","icon":"MessageCircleIcon"}}):_vm._e(),(_vm.chatOpened)?_c('feather-icon',{staticClass:"text-white",staticStyle:{"margin-left":"-2px","margin-top":"-2px"},attrs:{"size":"32","icon":"XIcon"}}):_vm._e()],1),(_vm.chatOpened)?_c('div',{staticClass:"col-lg-3 col-xs-3 col-md-5 col-sm-6 floating-chat-section"},[_c('b-card',{staticClass:"chat-widget",attrs:{"no-body":""}},[_c('b-card-header',[_c('div',{staticClass:"d-flex align-items-center"},[(
                (_vm.chatData.contact.contact_type != 'class' ||
                  !_vm.chatData.contact.users) &&
                  _vm.chatData.contact.avatar
              )?_c('b-avatar',{staticClass:"mr-50 badge-minimal",attrs:{"size":"34","src":_vm.chatData.contact.avatar,"badge":"","badge-variant":"success"}}):_vm._e(),(
                _vm.chatData.contact.contact_type == 'class' &&
                  _vm.chatData.contact.users
              )?_c('group-avatar',{staticStyle:{"cursor":"pointer"},attrs:{"contact":_vm.chatData.contact}}):_vm._e(),_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.chatData.contact.fullName)+" ")])],1),_c('b-nav-item',{attrs:{"to":{ name: 'chat' }},on:{"click":function($event){return _vm.setMinimized()}}},[_c('feather-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":"ArrowUpLeftIcon","size":"17"}})],1)],1),_c('section',{staticClass:"chat-app-window"},[_c('vue-perfect-scrollbar',{ref:"refChatLogPS",staticClass:"user-chats scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings}},[_c('chat-log',{attrs:{"chat-data":_vm.allChatData,"profile-user-avatar":_vm.AUTH_USER()?.avatar,"profile-user-id":_vm.AUTH_USER()?.id}})],1),_c('b-form',{staticClass:"chat-app-form",on:{"submit":function($event){$event.preventDefault();return _vm.sendMessage.apply(null, arguments)}}},[_c('b-input-group',{staticClass:"input-group-merge form-send-message mr-1"},[_c('b-form-input',{attrs:{"placeholder":"Enter your message"},model:{value:(_vm.chatInputMessage),callback:function ($$v) {_vm.chatInputMessage=$$v},expression:"chatInputMessage"}})],1),_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Send ")])],1),_c('div',{staticClass:"mb-1"},[_c('Recorder',{ref:"recordComponent",staticClass:"ml-2",attrs:{"url":''},on:{"input":_vm.setRecording,"emoji":_vm.insertEmoji,"sticker":_vm.sendSticker,"recordingNow":_vm.setRecordingNow}})],1)],1)],1)],1):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }