<template>
  <b-modal
    v-if="showModal"
    id="modal-student-rubrics"
    ref="modal-student-rubricss"
    v-model="showModal"
    ok-only
    size="lg"
    ok-variant="primary"
    :ok-title="$t('confirm')"
    modal-class="modal-primary"
    centered
    :title="`${$t('student-diary-module.rubrics-of')} ${
      student ? student.fullname : ''
    }`"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <div>
      <student-rubric-create-edit
        v-if="isTeacher"
        :rubric="rubricBeingEdited"
        :all-rubrics="rubricForSelect"
        :has-rubrics="studentRubrics.length > 0"
        :student-id="parseInt(student.id)"
        :class-id="classId"
        @onChangeRubricType="onRubricTypeChanged"
        @onRubricUpdated="getStudentRubrics"
        @onUpdateRubricType="getRubricsForSelect"
        @clear="clearRubric"
      />
      <b-overlay :show="isProcessing">
        <student-rubric-table
          :is-a-teacher="isTeacher"
          :rubrics="studentRubrics"
          :student-name="student ? student.fullname : ''"
          @onEditRubric="editRubric"
          @onDeleteRubric="(data) => (idOfRubricBeingDeleted = data)"
          @navigateToCoursePageIndividual="
            (id) =>
              $router.push({
                name: `${self.usertype}-lesson`,
                params: { classId: classId, id: id },
                query: {student_id: student.id}
              })
          "
          @navigateToCoursePage="
            (id) =>
              $router.push({
                name: `${self.usertype}-lessons`,
                params: { classId: classId, id: id },
                query: { student_id: student.id },
              })
          "
        />
      </b-overlay>
      <delete-modal
        v-if="isTeacher"
        name="modal-delete-student-rubric"
        :on-delete="deleteRubric"
        :title="$t('student-diary-module.delete-rubric-title')"
        :sub-title="$t('student-diary-module.delete-rubric-question')"
        :is-processing="isProcessing"
        :show="idOfRubricBeingDeleted != null"
        @close="idOfRubricBeingDeleted = null"
      />
    </div>
    <template #modal-footer="{}">
      <span />
    </template>
  </b-modal>
</template>

<script>
import { BModal, BOverlay } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import formatRubricData from '@/utils/formatRubricData'
import StudentRubricTable from './StudentRubricTable.vue'
import StudentRubricCreateEdit from './StudentRubricCreateEdit.vue'

export default {
  components: {
    BModal,
    StudentRubricTable,
    StudentRubricCreateEdit,
    DeleteModal,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    classId: {
      type: [Number, String],
      default: null,
    },
    rubrics: {
      type: Array,
      required: true,
    },
    student: {
      type: Object,
      default: null,
    },
    isTeacher: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      studentRubrics: [],
      rubricBeingEdited: null,
      self: getUserData(),
      idOfRubricBeingDeleted: null,
      rubricForSelect: [],
      isProcessing: false,
      showModal: false,
    }
  },
  watch: {
    student() {
      if (!this.student) {
        this.showModal = false
        return
      }
      this.getStudentRubrics()
      this.showModal = true
      this.$forceUpdate()
    },
  },
  methods: {
    clearRubric() {
      this.rubricBeingEdited = null
      this.getRubricsForSelect()
    },
    editRubric(data) {
      this.rubricBeingEdited = data
      this.getRubricsForSelect()
    },
    onRubricTypeChanged(id) {
      this.rubricBeingEdited = this.studentRubrics.find(rubric => rubric.rubric_id === id)
    },
    getRubricsForSelect() {
      // eslint-disable-next-line prefer-const
      let newList = []
      this.rubrics.forEach(r => {
        if (
          (this.rubricBeingEdited
            && this.rubricBeingEdited.rubric_id === r.id)
          || this.studentRubrics.findIndex(e => e.rubric_id === r.id) === -1
        ) {
          newList.push(r)
        }
      })
      this.rubricForSelect = newList
    },
    deleteRubric() {
      this.isProcessing = true
      useJwt
        .deleteStudentRubric(this.idOfRubricBeingDeleted)
        .then(res => {
          this.getStudentRubrics()
          this.idOfRubricBeingDeleted = null
          this.showSuccessMessage(res)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    getStudentRubrics() {
      this.isProcessing = true
      useJwt
        .getStudentRubrics(this.student.id, this.classId)
        .then(res => {
          const { rubrics, events } = formatRubricData(res)
          this.studentRubrics = [...rubrics, ...events]
          this.getRubricsForSelect()
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
  },
}
</script>
