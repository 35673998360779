<template>
  <b-row>
    <b-col
      lg="8"
      md="12"
    >
      <b-row>
        <!-- filters and create new button -->
        <b-col
          md="12"
          class="md-d-flex md-justify-content-between"
        >
          <b-row class="p-1">
            <b-col
              md="6"
              sm="12"
            >
              <b-row
                class="d-flex justify-content-start p-0"
              >
                <!-- <b-col
                  md="3"
                  xs="2"
                  sm="12"
                  class="p-0"
                >
                  <b-form-select
                    id="search-usertype"
                    :disabled="isProcessing"
                    name="search-usertype"
                  />
                </b-col> -->
                <b-col
                  class="p-0"
                >
                  <b-form-group>
                    <b-form-input
                      placeholder="Search"
                      type="text"
                      class="d-inline-block"
                      :disabled="isProcessing"
                      @input="searchClassesTable"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              md="6"
              sm="12"
            >

              <b-row class="md-d-flex justify-content-end">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :disabled="isProcessing"
                  variant="outline-primary"
                  @click="openCreateClassModal()"
                >
                  {{ $t('actions.create-class') }}
                </b-button>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <!-- filters and create new button end -->

        <!-- list of users table -->
        <b-col md="12 mt-2">
          <b-row>
            <b-col>
              <p>
                {{ $t('class-module.list-classes') }}
              </p>
            </b-col>
          </b-row>

          <b-overlay
            id="overlay-background"
            :show="isProcessing"
            variant="transparent"
            opacity="0.8"
            blur="0.8"
            rounded="sm"
          >
            <vue-good-table
              :compact-mode="true"
              compact
              :columns="columnsForClassesTable"
              :rows="classes"
              :search-options="{
                enabled: false,
                externalQuery: searchTermForClasses }"
              :pagination-options="{
                enabled: false,
                perPage:pageLength
              }"
              theme="my-theme"
              @on-row-click="onClassRowClick"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'action' && props.row.created_by === self.id">
                  <span>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="btn-icon rounded-circle mr-1"
                      @click.stop="editClass(props.row.id,props.row.originalIndex)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                  </span>

                  <span>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-modal.modal-delete-user
                      variant="outline-danger"
                      class="btn-icon rounded-circle sm-mt-2"
                      @click.stop="showDeleteModalForClass(props.index)"
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                  </span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </b-overlay>
          <!-- list of users table -->

        </b-col>
      </b-row>
    </b-col>
    <b-col
      lg="4"
      md="12"
    >
      <b-row>
        <!-- filters and create new button -->
        <b-col
          md="12"
          class="md-d-flex md-justify-content-between"
        >
          <b-row class="p-1">
            <b-col
              md="6"
              sm="12"
            >
              <b-row
                class="d-flex justify-content-start p-0"
              >
                <!-- <b-col
                  md="3"
                  xs="2"
                  sm="12"
                  class="p-0"
                >
                  <b-form-select
                    id="search-usertype"
                    :disabled="isProcessing"
                    name="search-usertype"
                  />
                </b-col> -->
                <b-col
                  class="p-0"
                >
                  <b-form-group>
                    <b-form-input
                      placeholder="Search"
                      type="text"
                      class="d-inline-block"
                      :disabled="isProcessing"
                      @input="searchStudentsTable"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              md="6"
              sm="12"
            >

              <b-row
                v-if="viewingStudentsForClass!==null"
                class="md-d-flex justify-content-end"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :disabled="isProcessing || viewingStudentsForClass.created_by!==self.id"
                  variant="outline-primary"
                  @click="openAddStudentsModal()"
                >
                  {{ $t('actions.add-students') }}
                </b-button>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <!-- filters and create new button end -->

        <!-- list of users table -->
        <b-col md="12 mt-2">
          <b-row>
            <b-col>
              <p v-if="viewingStudentsForClass!==null">
                {{ $t('class-module.students-in') }} {{ viewingStudentsForClass.name }}
              </p>
              <p v-else>
                {{ $t('class-module.select-to-view-students') }}
              </p>
            </b-col>
          </b-row>
          <b-overlay
            id="overlay-background"
            :show="isProcessing"
            variant="transparent"
            opacity="0.8"
            blur="0.8"
            rounded="sm"
          >
            <vue-good-table
              :columns="columnsForStudentsTable"
              :rows="classStudents"
              :search-options="{
                enabled: false,
                externalQuery: searchTermForStudents }"
              :pagination-options="{
                enabled: false,
                perPage:pageLength
              }"
              theme="my-theme"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'name'">
                  {{ props.row.firstname+" "+props.row.lastname }}
                </span>
                <span
                  v-else-if="props.column.field === 'action'"
                  class="d-flex justify-content-end"
                >
                  <span>
                    <b-button
                      v-if="self.id===viewingStudentsForClass.created_by"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-modal.modal-delete-user
                      variant="outline-danger"
                      class="btn-icon rounded-circle sm-mt-2"
                      :disabled="isProcessing"
                      @click.stop="showDeleteModalForStudent(props.index)"
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                  </span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </b-overlay>
          <!-- list of users table -->

        </b-col>
      </b-row>
    </b-col>
    <!-- delete class model -->
    <b-modal
      id="modal-delete-class"
      ref="modal-delete-class"
      ok-only
      ok-variant="danger"
      :ok-title="$t('confirm')"
      modal-class="modal-danger"
      centered
      :title="$t('actions.delete-class')"
      @ok="deleteClass"
    >
      <b-card-text>
        {{ $t('questions.delete-class') }}
      </b-card-text>
      <template #modal-footer="{ok}">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          > <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="danger"
            :disabled="isProcessing"
            @click="ok()"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('confirm') }}
          </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- delete class model -->
    <!-- create/edit class model -->
    <b-modal
      id="modal-create-class"
      ref="modal-create-class"
      ok-only
      size="sm"
      ok-variant="primary"
      :ok-title="$t('confirm')"
      modal-class="modal-primary"
      centered
      :title="getCreateClassTitle"
    >
      <b-col md="12">
        <!-- form -->
        <validation-observer
          ref="createClassForm"
        >
          <b-form
            class="create-class-from"
          >
            <b-row>

              <!-- class-name -->
              <b-col md="12">
                <b-form-group
                  :label="$t('name')"
                  label-for="name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="name"
                    rules="required"
                    vid="name"
                  >
                    <b-form-input
                      id="class-name"
                      v-model="newClass.name"
                      name="class-name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Class Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- class-name -->

              <b-col
                md="12"
              >
                <b-form-group
                  :label="$t('course')"
                  label-for="course"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Course"
                    rules="required"
                    vid="course_id"
                  >
                    <v-select
                      id="course"
                      v-model="newClass.course_id"
                      transition=""
                      :clearable="false"
                      name="course"
                      :reduce="course=>course.code"
                      :options="coursesForSelect"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>

                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-group
                  :label="$t('teacher')"
                  label-for="teacher"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Teacher"
                    rules="required"
                    vid="teacher_id"
                  >
                    <v-select
                      id="teacher"
                      v-model="newClass.teacher_id"
                      transition=""
                      :clearable="false"
                      name="teacher"
                      :options="teachersForSelect"
                      :reduce="item=>item.code"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <template #modal-footer="{ }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          > <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-dark-blue"
            :disabled="isProcessing"
            @click="isEditingClass ? updateClass(): createClass()"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ isEditingClass ? $t('actions.update-class'):$t('actions.create-class') }}
          </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- create/edit class model -->

    <!-- create/edit student model -->
    <b-modal
      id="modal-add-students"
      ref="modal-add-students"
      ok-only
      size="lg"
      ok-variant="primary"
      :ok-title="$t('confirm')"
      modal-class="modal-primary"
      centered
      :title="$t('actions.add-student')"
    >
      <b-col md="12">
        <!-- form -->
        <validation-observer
          ref="addStudentForm"
        >
          <b-form
            class="add-student-from"
          >
            <b-row>
              <b-col
                md="12"
              >
                <b-form-group
                  :label="$t('students')"
                  label-for="students"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Student"
                    rules="required"
                    vid="student"
                  >
                    <v-select
                      id="students"
                      v-model="selectedStudents"
                      :clearable="false"
                      :state="errors.length > 0 ? false:null"
                      :multiple="true"
                      name="students"
                      :options="studentsForSelect"
                      :reduce="data=>data.code"
                      transition=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <template #modal-footer="{ }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          > <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="isProcessing"
            @click="addStudentsToAClass()"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('actions.add-students') }}
          </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- create/edit class model -->
    <!-- delete student model -->
    <b-modal
      id="modal-delete-student"
      ref="modal-delete-student"
      ok-only
      ok-variant="danger"
      :ok-title="$t('confirm')"
      modal-class="modal-danger"
      centered
      :title="$t('actions.delete-student')"
      @ok="removeStudentFromAClass"
    >
      <b-card-text>
        {{ $t('questions.delete-student') }}
      </b-card-text>
      <template #modal-footer="{ok}">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          > <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="danger"
            :disabled="isProcessing"
            @click="ok()"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('confirm') }}
          </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- delete class model -->
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton, BFormInput, BFormGroup, BSpinner, BModal, BForm, BOverlay, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCardText,
    BSpinner,
    VueGoodTable,
    BForm,
    BModal,
    vSelect,
    BFormInput,
    BCol,
    BFormGroup,
    BOverlay,
    BButton,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isProcessing: false,
      searchTermForClasses: '',
      searchTermForStudents: '',
      pageLength: 10,
      indexOfClassBeingEdited: null,
      newClass: {},
      columnsForClassesTable: [
        {
          label: this.$i18n.tc('name'),
          field: 'name',
          width: '200px',
        },
        {
          label: this.$i18n.tc('course'),
          field: 'course_name',
          width: '200px',
        },
        // {
        //   label: this.$i18n.tc('created-by'),
        //   field: 'owner',
        // },
        {
          label: this.$i18n.tc('teacher'),
          field: 'teacher_name',
        },
        {
          label: this.$i18n.tc('action'),
          field: 'action',
          width: '120px',
          sortable: false,
        },
      ],
      columnsForStudentsTable: [
        {
          label: this.$i18n.tc('name'),
          field: 'name',
        },
        {
          label: this.$i18n.tc('action'),
          field: 'action',
          width: '96px',
          sortable: false,
        },
      ],
      classes: [],
      students: [],
      courses: [],
      teachers: [],
      selectedStudents: [],
      classStudents: [],
      viewingStudentsForClass: null,
    }
  },
  computed: {
    self() {
      return getUserData()
    },
    getCreateStudentTitle() {
      return this.isEditingStudent ? this.$i18n.tc('actions.update-student') : this.$i18n.tc('actions.create-student')
    },
    isEditingClass() {
      return this.indexOfClassBeingEdited !== null
    },
    isEditingStudent() {
      return this.indexOfStudentBeingEdited !== null
    },
    getCreateClassTitle() {
      return this.isEditingClass ? this.$i18n.tc('actions.update-class') : this.$i18n.tc('actions.create-class')
    },
    teachersForSelect() {
      return this.teachers.map(e => ({ code: e.id, label: `${e.firstname} ${e.lastname}` }))
    },
    coursesForSelect() {
      return this.courses.map(e => ({ code: e.id, label: e.name }))
    },
    studentsForSelect() {
      return this.students.map(e => ({ code: e.id, label: `${e.firstname} ${e.lastname}` }))
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    '$route.query.id': function () {
      this.getQueryParams()
    },
  },
  mounted() {
    this.resetNewClass()
    this.getCourses()
    this.getTeachers()
    this.getStudents()
    this.getClasses().then(() => {
      this.getQueryParams()
    })
  },
  methods: {
    getQueryParams() {
      const id = parseInt(this.$route.query.id, 10)
      if (!id) return
      const classIndex = this.classes.findIndex(a => a.id === id)
      if (classIndex === -1) return
      this.editClass(id, classIndex)
    },
    searchStudentsTable(term) {
      this.searchTermForStudents = term
    },
    searchClassesTable(term) {
      this.searchTermForClasses = term
    },
    getTeachers() {
      useJwt.getListOfTeachers().then(response => {
        this.teachers = response.data.data
        if (this.newClass.teacher_id === null) this.newClass.teacher_id = this.teachers.length > 0 ? this.courses[0].id : null
      })
    },
    getCourses() {
      useJwt.getCourses().then(response => {
        this.courses = response.data.data
        if (this.newClass.course_id === null) this.newClass.course_id = this.courses.length > 0 ? this.courses[0].id : null
      })
    },
    getStudents() {
      useJwt.getListOfStudents().then(response => {
        this.students = response.data.data
      })
    },
    getClasses() {
      return new Promise((resolve, reject) => {
        useJwt.getClasses().then(response => {
          this.classes = response.data.data
          resolve('done')
        })
          .catch(error => {
            reject(error)
          }).finally(() => {
            this.isProcessing = false
          })
      })
    },
    createClass() {
      this.$refs.createClassForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt.createClass(
            this.newClass,
          ).then(() => {
            this.$refs['modal-create-class'].hide()
            this.resetNewClass()
            this.getClasses()
          })
            .catch(error => {
              if (error.response?.data?.errors) {
                this.$refs.createClassForm.setErrors(error.response.data.errors)
              } else this.showErrorMessage(error)
            }).finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
    deleteClass(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.isProcessing = true

      const classBeingDeleted = this.classes[this.indexOfClassBeingDeleted]

      useJwt.deleteClass(classBeingDeleted.id)
        .then(response => {
          this.classes.splice(this.indexOfClassBeingDeleted, 1)
          this.indexOfClassBeingDeleted = null
          this.$refs['modal-delete-class'].hide()
          this.showSuccessMessage(response)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    onClassRowClick(params) {
      this.viewingStudentsForClass = params.row
      this.getStudentsForAClass()
    },
    getStudentsForAClass() {
      this.isProcessing = true
      useJwt.getStudentsOfClass(
        this.viewingStudentsForClass.id,
      ).then(response => {
        this.classStudents = response.data.data
      }).finally(() => {
        this.isProcessing = false
      })
    },
    addStudentsToAClass() {
      this.isProcessing = true
      useJwt.addStudentToClass(
        this.viewingStudentsForClass.id,
        {
          students: this.selectedStudents,
        },
      ).then(() => {
        this.selectedStudents = []
        this.$refs['modal-add-students'].hide()
        this.getStudentsForAClass()
      }).finally(() => {
        this.isProcessing = false
      })
    },
    removeStudentFromAClass(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.isProcessing = true

      const studentBeingDeleted = this.classStudents[this.indexOfStudentBeingDeleted]

      useJwt.removeStudentFromClass(this.viewingStudentsForClass.id, studentBeingDeleted.id)
        .then(response => {
          this.classStudents.splice(this.indexOfStudentBeingDeleted, 1)
          this.indexOfStudentBeingDeleted = null
          this.$refs['modal-delete-student'].hide()
          this.showSuccessMessage(response)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    updateClass() {
      this.$refs.createClassForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          const { newClass } = this
          delete newClass.created_by
          useJwt.updateClass(
            this.newClass.id,
            newClass,
          ).then(() => {
            this.$refs['modal-create-class'].hide()
            this.resetNewClass()
            this.getClasses()
          })
            .catch(error => {
              if (error.response?.data?.errors) {
                this.$refs.createClassForm.setErrors(error.response.data.errors)
              } else this.showErrorMessage(error)
            }).finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
    editClass(id, index) {
      this.$refs['modal-create-class'].toggle()
      this.indexOfClassBeingEdited = index
      this.newClass = { ...this.classes[index], ...{} }
    },
    resetNewClass() {
      this.newClass = {
        name: '',
        course_id: this.courses.length > 0 ? this.courses[0].id : null,
        teacher_id: this.teachers.length > 0 ? this.teachers[0].id : null,
      }
    },
    showDeleteModalForClass(index) {
      this.indexOfClassBeingDeleted = index
      this.$refs['modal-delete-class'].show()
    },
    showDeleteModalForStudent(index) {
      this.indexOfStudentBeingDeleted = index
      this.$refs['modal-delete-student'].show()
    },
    openCreateClassModal() {
      this.resetNewClass()
      this.indexOfClassBeingEdited = null
      this.$refs['modal-create-class'].show()
    },
    openAddStudentsModal() {
      this.$refs['modal-add-students'].show()
    },
  },
}
</script>
