<template>
  <BOverlay :show="isProcessing">
    <h3 class="mb-2">
      {{ $t('setting-module.notification-settings') }}
    </h3>
    <b-table-simple
      responsive
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th
            width="40%"
          >
            {{ $t('setting-module.options') }}
          </b-th>
          <b-th
            width="40%"
          >
            {{ $t('setting-module.switch') }}
          </b-th>
          <b-th
            width="20%"
          >
            {{ $t('setting-module.help-guide') }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>{{ $t('account-setting-general.receive-push-notifications') }}</b-td>
          <b-td>
            <span v-if="Number(optionsLocal.district?.pusher) === 0">
              {{ $t('setting-module.disabled-by-district') }}
            </span>
            <span v-else
                  class="d-flex align-items-baseline"
            >
              <b-form-checkbox
                v-model="optionsLocal.push_notification"
                class="custom-control-primary"
                style="margin-bottom:8px"
                switch
                @change="(value)=>saveUserSetting({key:'pusher',value})"
              />
              <span class="ml-2">{{ $t('setting-module.disable-enable') }}</span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('pusher')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>{{ $t('account-setting-general.receive-email-notifications') }}</b-td>
          <b-td>
            <span v-if="Number(optionsLocal.district?.email) === 0">
              {{ $t('setting-module.disabled-by-district') }}
            </span>
            <span v-else
                  class="d-flex align-items-baseline"
            >
              <b-form-checkbox
                v-model="optionsLocal.email_notification"
                class="custom-control-primary"
                switch
                @change="(value)=>saveUserSetting({key:'email',value})"
              />
              <span class="ml-2">{{ $t('setting-module.disable-enable') }}</span>
            </span>

          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('email')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <!--<b-row>
            <b-col cols="12">
                <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mt-2 mr-1"
                        :disabled="isProcessing"
                        type="submit"
                        @click.prevent="sendForm"
                >
                    <b-spinner
                            v-show="isProcessing"
                            class="mr-1"
                            small
                            variant="light"
                    />
                    {{ $t('account-setting-general.save') }}
                </b-button>
                <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        :disabled="isProcessing"
                        variant="outline-secondary"
                        type="reset"
                        class="mt-2"
                        @click.prevent="resetForm"
                >
                    {{ $t('account-setting-general.reset') }}
                </b-button>
                <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        :disabled="isProcessing"
                        variant="outline-secondary"
                        type="reset"
                        class="mt-2 ml-2"
                        @click.prevent="GO_BACK()"
                >
                    {{ $t('Back') }}
                </b-button>
            </b-col>
        </b-row>-->
    <setting-info-modal
      :show="showModal"
      :modal-data="modalData"
      @close="showModal = false"
    />
  </BOverlay>
</template>

<script>
import {
  // BRow, BCol,
  BFormCheckbox, /* BCard,  BButton, */
  BTableSimple,
  BThead, BTbody, BTr, BTh, BTd, BButton, BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import SettingInfoModal from '@/views/common/components/SettingInfoModal.vue'

export default {
  name: 'NotificationSettings',
  components: {
    BOverlay,
    // BRow,
    // BCol,
    BFormCheckbox,
    /* BCard, */
    /* BButton, */
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
    SettingInfoModal,
  },
  props: {
    optionsLocal: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // optionsLocal: {},
      isProcessing: false,
      showModal: false,
      modalData: {
        title: '',
        imgSrc: '',
        description: '',
        steps: '',
      },
      modalInfo: {
        pusher: {
          title: i18n.tc('account-setting-general.receive-push-notifications'),
          description: i18n.tc('setting-module.push-notification'),
          imageSrc: '/school-settings/pusher.png',
          steps: '',
        },
        email: {
          title: i18n.tc('receive-email-notifications'),
          description: i18n.tc('setting-module.email-notification'),
          imageSrc: '',
          steps: '',
        },
      },
    }
  },
  mounted() {
    this.$emit('toogleOverlay', true)
    // this.getUserData()
  },
  methods: {
    saveUserSetting(d) {
      this.isProcessing = true
      // eslint-disable-next-line no-param-reassign
      d.forceSync = this.$store.state.taskHelper.forceSyncSettingToSchool
      useJwt.updateUserSettings(d).then(res => {
        this.showSuccessMessage(res)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    showHelpGuide(type) {
      this.showModal = true
      this.modalData = {
        title: this.modalInfo[type].title,
        imgSrc: this.modalInfo[type].imageSrc,
        description: this.modalInfo[type].description,
        steps: this.modalInfo[type].steps,
      }
    },
  },
}
</script>

<style scoped>

</style>
