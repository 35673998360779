import { LANG_LEVELS } from '@/const/langLevel'

const getLevelParaGraph = level => {
  switch (level) {
    case 'A1':
      return 7
    case 'A2':
      return 12
    case 'B1':
      return 17
    case 'B2':
      return 22
    default:
      return null
  }
}

const getStatementTemplate = instance => {
  const QUE = instance.pageMetaInfo.find(item => item.type === 'Q')?.content
  const ANS = instance.pageMetaInfo.find(item => item.type === 'ANS')?.content
  const templateObject = instance.templates.find(item => item.type === 'statement')
  const langLevel = LANG_LEVELS[instance.$store.state.problem.activeProblem?.statement_level - 1] || 'A2'
  let { template } = templateObject
  template = template.replaceAll('{lsn_nm}', instance.topics)
  template = template.replaceAll('{name}', instance.self.firstname)
  template = template.replaceAll('{num}', getLevelParaGraph(langLevel))
  template = template.replaceAll('{qu}', QUE)
  template = template.replaceAll('{an}', ANS)
  template = template.replaceAll('{lvl}', langLevel)
  return template
}

const getProblemBodyTemplate = instance => {
  const QUE = instance.pageMetaInfo.find(item => item.type === 'Q')?.content
  const ANS = instance.pageMetaInfo.find(item => item.type === 'ANS')?.content
  const templateObject = instance.templates.find(item => item.type === 'problem-body')
  const langLevel = LANG_LEVELS[instance.$store.state.problem.activeProblem?.statement_level - 1] || 'A2'
  let { template } = templateObject
  template = template.replaceAll('{name}', instance.self.firstname)
  template = template.replaceAll('{lsn_nm}', instance.topics)
  template = template.replaceAll('{num}', getLevelParaGraph(langLevel))
  template = template.replaceAll('{qu}', QUE)
  template = template.replaceAll('{an}', ANS)
  template = template.replaceAll('{lvl}', langLevel)
  return template
}

const getProblemFeedBackTemplate = instance => {
  const templateObject = instance.templates.find(item => item.type === 'feedback')
  const QUE = instance.pageMetaInfo.find(item => item.type === 'Q')?.content
  const ANS = instance.pageMetaInfo.find(item => item.type === 'ANS')?.content
  const langLevel = LANG_LEVELS[instance.$store.state.problem.activeProblem?.statement_level - 1] || 'A2'
  let { template } = templateObject
  template = template.replaceAll('{name}', instance.self.firstname)
  template = template.replaceAll('{lsn_nm}', instance.topics)
  template = template.replaceAll('{num}', getLevelParaGraph(langLevel))
  template = template.replaceAll('{lvl}', langLevel)
  template = template.replaceAll('{qu}', QUE)
  template = template.replaceAll('{an}', ANS)
  return template
}

const getLabTemplate = instance => {
  const topic = instance.topics
  const QUE = instance.pageMetaInfo.find(item => item.type === 'Q')?.content
  const ANS = instance.pageMetaInfo.find(item => item.type === 'ANS')?.content
  if (QUE && ANS) {
    if (instance.$store.state.studentLab.activeViewOfLab === 'statement') {
      return getStatementTemplate(instance)
    }
    if (instance.$store.state.studentLab.activeViewOfLab === 'problem-body') {
      return getProblemBodyTemplate(instance)
    }
    if (instance.$store.state.studentLab.activeViewOfLab === 'feedback') {
      return getProblemFeedBackTemplate(instance)
    }
  }
  return `You are a chatbot teacher tutoring a student about ${topic}.\nYou are to ask the student questions about ${topic} and wait for 10 sec each student answer.\nIf the student is correct, you should take two steps.\nCorrect step 1: Give praise to the student for a correct answer.\nCorrect step 2: Ask the student another question about ${topic}.\nIf the student is incorrect, do not give the student the correct answer the first time.\nProvide some hints through Socratic, dialectic teaching in a tactful manner.\nOnly give the answer if the student misses the same question twice.\n\nOnly give the answer to the question.\nWhen the conversation starts, do not answer previous questions.\nNo need to answer questions present in chat history.\n\nchat_history: {chat_history}\nHuman: {human_input}\nChatbot:`
}

export default getLabTemplate
