<template>
  <div>

    <vue-good-table
      :columns="columns"
      :rows="rows"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'full_name'">
          <span
            class=""
          >
            <b-avatar
              v-if="props.row.avatar"
              badge
              :badge-variant="props.row.is_online ? 'success' : 'secondary'"
              badge-offset="-0.2em"
              :src="props.row.avatar"
              class="ml-1 mt-1"
              square
              size="42"
            />

            <b-avatar
              v-else
              class="ml-1 mt-1"
              badge
              :badge-variant="props.row.is_online ? 'success' : 'secondary'"
              badge-offset="-0.2em"
              :text="getInitials(props.row.full_name)"
              :size="42"
              :variant="getRandomVariant()"
              square
            />
            <span class="text-nowrap m-0 ml-1 p-0">{{ props.row.full_name }}</span>
            <h5
              v-if=" props.row.last_tracked"
              class="ml-4 pl-2 nickname text-success"
            >
              {{ props.row.last_tracked }}
            </h5>
            <h5
              v-else
              class="ml-4 pl-2 nickname text-danger"
            >
              &nbsp;{{ $t('student-report-module.never-tracked-time') }}
            </h5>
          </span>
        </span>
        <span v-else-if="props.column.field === 'time_worked'">
          {{ formatTime(props.row.time_worked) }}
        </span>
        <span v-else-if="props.column.field === 'ideal_time'">
          {{ idealTime(props.row) }}
        </span>
      </template>
    </vue-good-table>
    <div>
      <b-pagination
        :value="1"
        :total-rows="paginationData.totalItems"
        :per-page="paginationData.perPage"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        @input="getUsers"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
  </div>
</template>
<script>
import { VueGoodTable } from 'vue-good-table'
import {
  BAvatar, BPagination,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'

const moment = require('moment')

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
  },
  props: {
    searchDate: {
      type: String,
      required: true,
    },
    formatTime: {
      type: Function,
      required: true,
    },
    getRandomVariant: {
      type: Function,
      required: true,
    },
    getInitials: {
      type: Function,
      required: true,
    },
    selectedTimezone: {
      type: [String, Object],
      default: null,
    },
    searchTerm: {
      type: String,
      default: '',
    },
    classRoom: {
      type: [Number, String, Object],
      default: () => null,
    },
  },
  data() {
    return {
      columns: [
        {
          label: i18n.tc('labels.student'),
          field: 'full_name',
        },
        {
          label: i18n.tc('class-module.start-time'),
          field: 'start_time',
        },
        {
          label: i18n.tc('class-module.end-time'),
          field: 'end_time',
        },
        {
          label: i18n.tc('class-module.time-worked'),
          field: 'time_worked',
        },
        {
          label: i18n.tc('class-module.ideal-time'),
          field: 'ideal_time',
        },
      ],
      rows: [],
      paginationData: [],
    }
  },
  watch: {
    searchDate: {
      immediate: true,
      handler() {
        this.getUsers()
      },
    },
    selectedTimezone() {
      this.getUsers()
    },
    searchTerm() {
      this.getUsers()
    },
    classRoom() {
      this.getUsers()
    },
  },
  methods: {
    getUsers(page = 1) {
      const params = {
        page,
        date: this.searchDate,
        timezone: this.selectedTimezone,
        term: this.searchTerm,
        classRoom: this.classRoom,
      }
      this.$emit('toogleOverlay', true)
      useJwt.getStudentTime({ params }).then(res => {
        this.rows = res.data.data
        this.assignPaginationData(res.data.pagination)
      }).finally(() => { this.$emit('toogleOverlay', false) })
    },
    assignPaginationData(data) {
      this.paginationData = {
        currentPage: data.current_page,
        perPage: data.per_page,
        totalItems: data.total,
        lastPage: data.last_page,
      }
    },
    idealTime(row) {
      const timeA = moment(`${this.searchDate} ${row.start_time}`)
      const timeB = moment(`${this.searchDate} ${row.end_time}`)
      return this.formatTime(timeB.diff(timeA, 'second') - row.time_worked)
    },
  },
}
</script>
