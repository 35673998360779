<template>
  <b-modal
    id="modal-grade-create"
    ref="modal-grade-create"
    ok-only
    size="lg"
    ok-variant="primary"
    modal-class="modal-primary"
    centered
    :title="$t(`actions.${modalTitle}`)"
    hide-footer
    @close="close"
    @hide="close"
  >
    <div
      v-if="grade.form === 'table'"
      class="row"
    >
      <b-col md="6">
        <b-col
          sm="12"
          class="d-flex justify-content-end p-0"
        >
          <span>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="addGrade"
            >
              {{ $t("actions.add-grade") }}
            </b-button>
          </span>
        </b-col>
        <vue-good-table
          :columns="gradeColumns"
          :rows="gradeRows"
          theme="my-theme"
          class="py-2 grade-table"
          @on-cell-click="showSubjects"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <div
              v-if="props.column.field === 'name'"
              :class="{ 'active-column': props.row.id === activeGradeId }"
            >
              {{ props.row.name }}
            </div>
            <span
              v-if="props.column.field === 'action'"
              class="d-flex justify-content-center"
              :class="{ 'active-column': props.row.id === activeGradeId }"
            >
              <b-button
                variant="outline-primary"
                class="btn-icon mr-1"
                @click="editGrade(props.row)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>

              <b-button
                variant="outline-danger"
                class="btn-icon sm-mt-2"
                @click="deleteGrade(props.row)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </b-col>
      <b-col
        v-if="isSubjectShow"
        md="6"
      >
        <b-col
          sm="12"
          class="d-flex justify-content-end p-0"
        >
          <span>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              :disabled="!activeGradeId || activeGradeId === -1"
              @click="addSubject"
            >
              {{ $t("actions.add-subject") }}
            </b-button>
          </span>
        </b-col>
        <vue-good-table
          :columns="subjectColumns"
          :rows="subjects"
          theme="my-theme"
          class="py-2"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span
              v-if="props.column.field === 'action'"
              class="d-flex justify-content-center"
            >
              <b-button
                variant="outline-primary"
                class="btn-icon mr-1"
                @click="editSubject(props.row)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>

              <b-button
                variant="outline-danger"
                class="btn-icon sm-mt-2"
                @click="deleteSubject(props.row)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </b-col>
    </div>

    <add-grade-form
      v-else-if="grade.form === 'addGrade'"
      @back="resetModal"
      @created="$emit('created', $event), (subjects = [])"
    />
    <edit-grade-form
      v-else-if="grade.form === 'editGrade'"
      :edit-grade-data="editGradeData"
      @back="resetModal"
      @edited="$emit('edited', $event)"
    />
    <delete-grade-form
      v-else-if="grade.form === 'deleteGrade'"
      :delete-grade-data="deleteGradeData"
      @back="resetModal"
      @deleted="emitGradeDelete($event)"
    />
    <add-subject-form
      v-else-if="grade.form === 'addSubject'"
      :add-subject-data="addSubjectData"
      @back="resetModal"
      @created="addSubjectRow($event)"
    />
    <edit-subject-form
      v-else-if="grade.form === 'editSubject'"
      :edit-subject-data="editSubjectData"
      @back="resetModal"
      @edited="editSubjectRow($event)"
    />
    <delete-subject-form
      v-else-if="grade.form === 'deleteSubject'"
      :delete-subject-data="deleteSubjectData"
      @back="resetModal"
      @deleted="deleteSubjectRow($event)"
    />
  </b-modal>
</template>

<script>
import { BCol, BButton } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'

import AddGradeForm from './AddGradeForm.vue'
import EditGradeForm from './EditGradeForm.vue'
import DeleteGradeForm from './DeleteGradeForm.vue'
import AddSubjectForm from '../subject/AddSubjectForm.vue'
import EditSubjectForm from '../subject/EditSubjectForm.vue'
import DeleteSubjectForm from '../subject/DeleteSubjectForm.vue'

export default {
  components: {
    BCol,
    BButton,
    VueGoodTable,
    AddGradeForm,
    EditGradeForm,
    DeleteGradeForm,
    AddSubjectForm,
    EditSubjectForm,
    DeleteSubjectForm,
  },
  directives: {
    Ripple,
  },
  props: {
    toggle: {
      type: Boolean,
      default: () => false,
    },
    activeGradeId: {
      type: Number,
      default: 0,
    },
    gradeColumns: {
      type: Array,
      default: () => [],
    },
    gradeRows: {
      type: Array,
      default: () => [],
    },
    subjectColumns: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    grade: {
      name: null,
      form: 'table',
    },
    isProcessing: false,
    articleImage: null,
    editGradeData: {},
    deleteGradeData: {},
    addSubjectData: {},
    editSubjectData: {},
    deleteSubjectData: {},
    subjects: [],
    isSubjectShow: false,
    modalTitle: 'grade',
  }),
  watch: {
    toggle(value) {
      if (value === true) {
        this.$refs['modal-grade-create'].show()
      } else {
        this.$refs['modal-grade-create'].hide()
      }
    },
  },
  created() {
    // this.activeGradeId = this.gradeRows[0].id
    this.fetchSubjects(this.activeGradeId)
  },
  methods: {
    close() {
      this.grade.form = 'table'
      this.$emit('close')
    },
    addGrade() {
      this.grade.form = 'addGrade'
      this.modalTitle = 'add-grade'
    },
    editGrade({ id, name }) {
      this.editGradeData = { id, name }
      this.grade.form = 'editGrade'
      this.modalTitle = 'edit-grade'
    },
    deleteGrade({ id }) {
      this.deleteGradeData = { id }
      this.grade.form = 'deleteGrade'
      this.modalTitle = 'delete-grade'
    },
    addSubject() {
      this.grade.form = 'addSubject'
      this.modalTitle = 'add-subject'
      this.addSubjectData = { grade_id: this.activeGradeId }
    },
    editSubject({ id, name, score }) {
      this.grade.form = 'editSubject'
      this.modalTitle = 'edit-subject'
      this.editSubjectData = {
        grade_id: this.activeGradeId,
        id,
        name,
        score,
      }
    },
    deleteSubject({ id }) {
      this.grade.form = 'deleteSubject'
      this.modalTitle = 'delete-subject'
      this.deleteSubjectData = { id }
    },
    addSubjectRow({ id, name, score }) {
      this.subjects.splice(0, 0, { id, name, score })
    },
    editSubjectRow(data) {
      const index = this.subjects.findIndex(row => row.id === data.id)
      this.subjects[index].id = data.id
      this.subjects[index].name = data.name
      this.subjects[index].score = data.score
    },
    deleteSubjectRow({ id }) {
      this.subjects = this.subjects.filter(row => row.id !== id)
    },
    showSubjects(params) {
      const gradeId = params.row.id
      this.activeGradeId = gradeId
      this.fetchSubjects(gradeId)
    },
    resetModal() {
      this.grade.form = 'table'
      this.modalTitle = 'grade'
    },
    fetchSubjects(id) {
      useJwt
        .getSubjectsOfGrade(id)
        .then(res => {
          this.subjects = res.data.data.map(subject => ({
            id: subject.id,
            name: subject.name,
            score: subject.score,
          }))
        })
        .finally(() => {
          this.isSubjectShow = true
        })
    },
    emitGradeDelete(data) {
      if (data.id === this.activeGradeId) this.subjects = []
      this.$emit('deleted', data)
    },
  },
}
</script>

<style lang="scss">
.grade-table{
  tbody {
    td {
      vertical-align: middle !important;
      padding: 0 !important;
      div {
        padding: 18px 14px;
      }
      span {
        padding: 11.5px 14px;
      }
      div:hover,
      span:hover {
        cursor: pointer;
      }
    }
  }
}
.active-column {
  background-color: rgba(0, 0, 0, 0.15);
}
</style>
