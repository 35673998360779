<template>
  <b-nav-item @click="updateSkin">
    <feather-icon
      size="21"
      icon="SunIcon"
    />
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from 'vue'
import { BNavItem } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
  },
  setup() {
    const { skin } = useAppConfig()

    const isSemiLight = computed(() => skin.value === 'semi-light')
    const isDefault = computed(() => skin.value === 'light')
    const isGreen = computed(() => skin.value === 'green')
    const isBrown = computed(() => skin.value === 'brown')

    return {
      skin, isSemiLight, isDefault, isBrown, isGreen,
    }
  },
  methods: {
    updateSkin() {
      if (this.isDefault) this.skin = 'semi-light'
      else if (this.isSemiLight) this.skin = 'semi-light-reverse'
      else if (this.isGreen) this.skin = 'brown'
      else if (this.isBrown) this.skin = 'light'
      else this.skin = 'green'
    },
  },
}
</script>
