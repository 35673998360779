<template>
  <b-modal :visible="true"
           title="Restrict Courses"
           size="lg"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <!-- Search Bar -->
    <b-form-input v-model="searchQuery"
                  placeholder="Search"
    />
    <div class="mt-2">
      <BTableSimple class="table table-striped table-hover table-bordered"
                    sticky-header="700px"
                    :height="800"
      >

        <tbody>
          <template v-for="gdr,index of filteredCourses">
            <tr :key="gdr.grade + '-' + index">
              <th colspan="100%"
                  class="text-center bg-secondary text-white"
              >
                {{ gdr.grade }}
              </th>
            </tr>
            <tr :key="index + 'h'">
              <th>Name</th>
              <th>Action</th>
            </tr>
            <tr v-for="cou of gdr.courses"
                :key="cou.id"
            >
              <td>
                {{ cou.name }}
              </td>
              <td>
                <b-button :variant="cou.blocked ? 'outline-danger': 'outline-primary'"
                          class="btn-icon"
                          @click="toggleBlocked(cou)"
                >
                  <feather-icon
                    :icon="cou.blocked ? 'EyeIcon' : 'EyeOffIcon'"
                  />
                </b-button>
              </td>
            </tr>
          </template>

        </tbody>
      </BTableSimple>

    </div>

    <!-- List of Blocked Courses -->
    <div v-if="blockedCourses.length">
      <h4>Blocked Courses:</h4>
      <b-badge v-for="(course, index) in blockedCourses"
               :key="index"
               variant="danger"
               class="mr-1 mb-1 blocked-course"
      >
        {{ course.name }}
        <span class="close-me">
          <feather-icon icon="XIcon"
                        role="button"
                        @click="unblockCourse(course)"
          />
        </span>
      </b-badge>
    </div>
    <div v-else>
      <p>No courses blocked.</p>
    </div>
    <template #modal-footer>
      <div>
        <b-button variant="outline-secondary"
                  class="mr-1"
                  @click="$emit('close')"
        >
          Cancel
        </b-button>
        <b-button variant="primary"
                  :disabled="isProcessing"
                  @click="onSubmit"
        >
          <b-spinner v-if="isProcessing"
                     small
          />  Submit
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
// Import required components from Bootstrap Vue
import {
  BModal, BFormInput, BTableSimple,
  BButton,
  BBadge,
  BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BModal,
    BFormInput,
    BTableSimple,
    BButton,
    BBadge,
    BSpinner,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: '',
      isProcessing: false,
      coursesLists: [
      ],
      fields: ['name', 'action'],
    }
  },
  computed: {
    filteredCourses() {
      return this.coursesLists.filter(grade => grade.courses.some(course => course.name.toLowerCase().includes(this.searchQuery.toLowerCase())))
    },
    blockedCourses() {
      return this.coursesLists.flatMap(grade => grade.courses.filter(course => course.blocked))
    },
  },
  mounted() {
    this.getCoursesLists()
  },
  methods: {
    onSubmit() {
      const blockedCourses = this.blockedCourses.map(item => item.id)
      this.isProcessing = true
      useJwt.updateBlockedUserCourses({
        teacher_id: this.userId,
        courses: blockedCourses,
      }).then(response => {
        this.showSuccessMessage(response)
        this.$emit('close')
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    getBlockedCourses() {
      return useJwt.getBlockedUserCourses(this.userId)
    },
    getCoursesLists() {
      this.getBlockedCourses().then(blocked => {
        const blockedCourses = blocked.data.data.blocked_courses.map(item => item.course_id)
        console.log(blockedCourses)
        useJwt.getGradeLists().then(response => {
          const courses = response.data.data.grades.map(grade => ({
            courses: grade.courses.map(it => ({ ...it, blocked: blockedCourses.includes(it.id) })),
            grade: grade.name,
          }))
          this.coursesLists = courses
        })
      })
    },
    toggleBlocked(course) {
      // eslint-disable-next-line no-param-reassign
      course.blocked = !course.blocked
      this.$forceUpdate()
    },
    unblockCourse(item) {
      const foundCourse = this.coursesLists.flatMap(grade => grade.courses).find(course => course.name === item.name)
      if (foundCourse) {
        foundCourse.blocked = false
      }
    },
  },
}
</script>
<style scoped lang="scss">
.close-me:hover {
  .blocked-course {
    zoom: 1.1;
  }
}
</style>
