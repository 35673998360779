<template>
  <b-modal
    id="modal-article-edit"
    ref="modal-article-edit"
    ok-only
    size="lg"
    ok-variant="primary"
    :ok-title="$t('confirm')"
    modal-class="modal-primary"
    centered
    :title="$t('labels.update-article')"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-col md="12">
      <!-- form -->
      <validation-observer ref="editArticleForm">
        <b-form @submit.prevent="editArticle">
          <button
            type="submit"
            class="d-none"
          />
          <b-row>
            <b-col class="text-right pt-1">
              <b-form-checkbox
                v-model="article.is_featured"
                name="featured-article"
                inline
                switch
                size="16"
              >
                <span style="font-size:14px; color:#6e6b7b;"> {{ $t('actions.featured') }} </span>
              </b-form-checkbox>
            </b-col>
            <b-col md="12">
              <!-- Title -->
              <b-form-group
                :label="$t('labels.title')"
                label-for="title"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="title"
                  rules="required"
                  vid="title"
                >
                  <b-form-input
                    id="title"
                    v-model="article.title"
                    name="title"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Title of your article"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- / Title -->

              <!-- Description -->
              <b-form-group
                :label="$t('labels.description')"
                label-for="description-area"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="description"
                  rules="required"
                  vid="description"
                >
                  <b-form-textarea
                    id="description-area"
                    v-model="article.description"
                    rows="6"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Your description here..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!--/ Description -->

              <!-- Article Category -->
              <b-form-group
                :label="$t('labels.category')"
                label-for="articleCategory"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="articleCategory"
                  rules="required"
                  vid="articleCategory"
                >
                  <b-form-select
                    id="articleCategory"
                    v-model="article.article_category"
                    :options="articleCategoryOptions"
                    name="access-type"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- / Article Category -->

              <!-- Video -->
              <b-form-group
                :label="$t('labels.video')"
                label-for="articleCategory"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Video"
                  vid="video"
                  rules="youtubeVideoUrl"
                >
                  <b-form-input
                    id="video"
                    v-model="article.video"
                    name="video"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Url of your video"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- / Video -->

              <!-- Image -->
              <b-form-group
                :label="$t('labels.image')"
                label-for="article-image"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Image"
                  rules="image|size:800"
                  :custom-messages="{ image: $t('messages.invalid-image') }"
                  vid="name"
                >
                  <b-form-file
                    id="article-image"
                    ref="articleCategory"
                    v-model="articleImage"
                    :state="errors.length > 0 ? false : null"
                    :browse-text="$t('browseButton')"
                    :placeholder="$t('issue-report-module.choose-a-file')"
                    accept="image/jpeg, image/png, image/gif"
                  />
                  <small class="text-danger">{{ errors[0] }}</small><br>
                  <small>Accepted jpeg/png/gif</small>

                  <b-img
                    v-if="article.image"
                    rounded
                    fluid
                    :src="article.image"
                    height="150"
                    width="150"
                    class="mt-2"
                  />
                  <b-button
                    v-if="article.image"
                    variant="outline-danger"
                    class="btn-icon px-1 ml-2"
                    @click="() => { article.image = null; $refs.articleCategory.reset() }"
                  >
                    <feather-icon icon="TrashIcon" />

                  </b-button>
                </validation-provider>
              </b-form-group>
              <!--/ Image -->
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-col>
    <template #modal-footer="{}">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="
              isProcessing ||
                !article.description ||
                !article.title
            "
            @click="editArticle()"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('labels.update-article') }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BSpinner,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BImg,
  BFormSelect,
  BFormCheckbox,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver, extend,
} from 'vee-validate/dist/vee-validate.full'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { validatorYoutubeUrlValidator } from '@/@core/utils/validations/validators'
import i18n from '@/libs/i18n'

extend('youtubeVideoUrl', value => {
  const valid = validatorYoutubeUrlValidator(value)
  return valid ? true : i18n.tc('messages.invalid-video-url')
})
export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    BForm,
    BFormGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BImg,
    BFormSelect,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    toggle: {
      type: Boolean,
      default: () => false,
    },
    articleData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    article: {},
    articleCategoryOptions: [
      { value: 'philanthropic', text: 'Philanthropic' },
      { value: 'academic', text: 'Academic' },
      { value: 'project', text: 'Project' },
    ],
    isProcessing: false,
    articleImage: null,
  }),
  watch: {
    toggle(value) {
      if (value === true) {
        this.$refs['modal-article-edit'].show()
      } else {
        this.$refs['modal-article-edit'].hide()
      }
    },
    articleImage() {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.article.image = reader.result
        },
        false,
      )

      if (this.articleImage) {
        if (/\.(jpe?g|png|gif)$/i.test(this.articleImage.name)) {
          reader.readAsDataURL(this.articleImage)
        }
      }
    },
    articleData(value) {
      this.article = { ...value }
      this.article.is_featured = this.article.is_featured === 1
    },
  },
  methods: {
    editArticle() {
      this.$refs.editArticleForm.validate().then(success => {
        let youtubeUrl = ''
        youtubeUrl = validatorYoutubeUrlValidator(this.article.video)
        if (!youtubeUrl) youtubeUrl = null

        if (success) {
          this.isProcessing = true

          const config = {
            headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.accessToken}`,
            },
          }

          const data = new FormData()
          data.append('image', this.$refs.articleCategory.files[0] || '')
          data.append('title', this.article.title)
          data.append('description', this.article.description)
          data.append('article_category', this.article.article_category)
          data.append('video', youtubeUrl ?? '')
          data.append('_method', 'put')
          data.append('is_featured', this.article.is_featured === true ? 1 : 0)

          axios
            .post(`/api/v1/article/${this.article.id}`, data, config)
            .then(response => {
              this.$store.dispatch('network/getVerifiedArticles')
              this.$store.dispatch('network/getUnVerifiedArticles')
              this.showSuccessMessage(response)
              this.$emit('updated', response.data.data)
              this.$emit('close')
            })
            .catch(error => {
              if (error.response?.data?.errors) {
                this.$refs.editArticleForm.setErrors(error.response.data.errors)
              } else this.showErrorMessage(error)
            })
            .finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
  },
}
</script>
