<template>
  <div class="ai-demo pl-2">
    <validation-observer ref="AIDemoForm">
      <b-row>
        <b-col md="4">
          <b-card>
            <validation-provider
              v-slot="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-group label="Your Name">
                <b-form-input
                  v-model="name"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="lesson name"
              rules="required"
            >
              <b-form-group label="Lesson Name">
                <b-form-input
                  v-model="lessonInfo.topic"
                  placeholder="Lesson Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="correct answer"
              rules="required"
            >
              <b-form-group label="Correct Answer">
                <b-form-textarea
                  v-model="lessonInfo.correctAnswer"
                  placeholder="Enter your correct answer here"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-card>
        </b-col>
        <b-col md="5">
          <b-card>
            <div class="d-flex">
              <b-card
                class="cursor-pointer mr-3"
                border-variant="primary"
                :bg-variant="activeProblemType === 'writing' ? 'primary' : ''"
                @click="activeProblemType = 'writing'"
              >
                <h5 class="mb-0"
                    :class="activeProblemType === 'writing' ? 'text-light' : 'text-dark'"
                >
                  Writing
                </h5>
              </b-card>
              <b-card
                class="cursor-pointer"
                border-variant="primary"
                :bg-variant="activeProblemType === 'speaking' ? 'primary' : ''"
                @click="activeProblemType = 'speaking'"
              >
                <h5 class="mb-0"
                    :class="activeProblemType === 'speaking' ? 'text-light' : 'text-dark'"
                >
                  Speaking
                </h5>
              </b-card>
            </div>
            <b-overlay :show="isProcessing">
              <validation-provider
                v-slot="{ errors }"
                name="question"
                rules="required"
              >
                <b-form-group label="Question">
                  <b-form-input
                    v-model="lessonInfo.question"
                    placeholder="Write a question"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="answer"
                rules="required"
              >
                <b-form-group label="Answer">
                  <b-form-textarea
                    v-if="activeProblemType === 'writing'"
                    v-model="lessonInfo.answer"
                    placeholder="Write your answer here"
                    rows="5"
                  />

                  <recorder
                    v-else
                    v-model="lessonInfo.answer"
                    class="border rounded row"
                    :auto-stop="false"
                    @is-processing="isBusy = true"
                    @textAudio="setAnswer"
                    @deleted="() => { aiScore = null }"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <div v-if="lessonInfo.answer && activeProblemType === 'speaking'"
                   class="border border-secondary p-1"
              >
                {{ lessonInfo.answer }}
              </div>
              <div
                class="d-flex mt-2"
                :class="activeProblemType === 'writing' ? 'justify-content-end' : 'justify-content-between'"
              >
                <div
                  v-if="activeProblemType === 'speaking'"
                  class="cursor-pointer"
                  @click="playPauseAudio"
                >
                  <Feather-Icon
                    :icon="isPlaying ? 'PauseCircleIcon' : 'PlayCircleIcon'"
                    size="32"
                  />
                </div>
                <b-button
                  variant="primary"
                  :disabled="isBusy"
                  @click="onSubmit"
                >
                  {{ isBusy ? 'Please Wait' : 'Submit' }}
                </b-button>
              </div>
            </b-overlay>

            <b-overlay
              v-if="aiScore"
              :show="isProcessing"
            >
              <b-card
                class="mt-4"
                border-variant="secondary"
              >

                <div class="d-flex justify-content-between">
                  <h2 class="mb-2">
                    AI Generated Report
                  </h2>

                  <b-form-checkbox
                    v-model="showDescription"
                    switch
                  >
                    Show Explanation
                  </b-form-checkbox>
                </div>
                <div class="d-flex flex-wrap">
                  <div
                    v-for="score, index in aiScore"
                    :key="score.label"
                    class="col-md-6 pl-0 mb-1"
                  >
                    <h5 class="mb-0">
                      {{ index + 1 }}. {{ score.label }}:
                      {{ score.score }}
                    </h5>
                    <p v-if="showDescription">
                      {{ score.description }}
                    </p>
                  </div>
                </div>
              </b-card>
            </b-overlay>
          </b-card>
        </b-col>
        <b-col md="3">
          <chat-bot
            :user-name="name"
            :lesson-info="lessonInfo"
          />
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>
<script>

import {
  BRow, BCol, BCard, BFormTextarea, BButton, BFormGroup, BFormInput, BOverlay, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Recorder from '@/views/student/problem/compoments/recorder/Speech.vue'
import { getAIScoreForDemo } from '@/auth/jwt/useDatablock'
import ChatBot from './demobot/index.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BButton,
    BFormGroup,
    BFormInput,
    BOverlay,
    BFormCheckbox,
    Recorder,
    ChatBot,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      name: '',
      lessonInfo: {
        answer: '',
      },
      activeProblemType: 'writing',
      aiScore: null,
      isProcessing: false,
      isBusy: false,
      showDescription: false,
      isPlaying: false,
    }
  },
  watch: {
    activeProblemType(val, oldVal) {
      if (val !== oldVal) {
        this.lessonInfo.answer = ''
        this.lessonInfo.answerAudio = null
      }
    },
  },
  methods: {
    setAnswer(value) {
      this.lessonInfo.answer = value.text
      this.lessonInfo.answerAudio = new Audio(value.audio)
      this.$nextTick(() => { this.isBusy = false })
    },
    playPauseAudio() {
      if (this.lessonInfo.answerAudio) {
        if (!this.isPlaying) {
          this.isPlaying = true
          this.lessonInfo.answerAudio.play()
          this.lessonInfo.answerAudio.addEventListener('ended', () => {
            URL.revokeObjectURL(this.lessonInfo.answerAudio)
            this.isPlaying = false
          })
        } else {
          this.lessonInfo.answerAudio.pause()
          this.isPlaying = false
        }
      }
    },
    onSubmit() {
      this.$refs.AIDemoForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          const {
            topic, question, answer, correctAnswer,
          } = this.lessonInfo
          const params = {
            lesson: topic,
            question,
            answer,
            correctAnswer,
          }

          getAIScoreForDemo(params).then(response => {
            this.aiScore = JSON.parse(response.data.message).data
          }).finally(() => {
            this.isProcessing = false
          })
        }
      })
    },
  },
}
</script>
