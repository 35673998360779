<template>
  <div>
    <h4>
      {{ $t('note-for-student') }}
    </h4>
    <div class="d-flex justify-content-between mb-2">
      <div>
        <b-button
          variant="outline-secondary"
          @click="showTemplate = true"
        >
          {{ $t('load-template') }}
        </b-button>
      </div>
      <div v-if="note">
        <b-form-checkbox v-model="isNotePublic"
                         switch
                         @change="updateNotes"
        >
          {{ $t('actions.publish') }}
        </b-form-checkbox>
      </div>
    </div>
    <b-card body-class="p-0">
      <CKEditor
        v-model.lazy="noteContent"
        placeholder="Type notes here.."
        rows="50"
        no-shrink
        :config="ckEditConfig"
        @input="updateOrCreateNotes"
        @blur="updateOrCreateNotes"
        @focus="isNoteFocused = true"
      />
    </b-card>
    <TemplateList v-if="showTemplate"
                  @applyNoteTemplate="applyTemplate"
                  @close="showTemplate = false"
    />
  </div>
</template>
<script setup>
import { BCard, BButton, BFormCheckbox } from 'bootstrap-vue'
import { component as CKEditor } from 'ckeditor4-vue'
import { getCurrentInstance, ref } from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import _ from 'lodash'
import TemplateList from './template-list.vue'

const props = defineProps({
  lessonId: {
    type: Number,
    required: true,
  },
  studentId: {
    type: Number,
    required: true,
  },
})

const ckEditConfig = {
  allowedContent: true,
  basicEntities: true,
  entities: false,
  toolbar: [
    { name: 'basicstyles', items: ['Underline', 'Italic', 'Bold', 'list', 'colorbutton'] },
    { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'] },
    ['TextColor', 'BGColor'],
  ],
  extraPlugins: 'colorbutton',
  colorButton_colors: 'CF5D4E,454545,FFF,DDD,CCEAEE,66AB16',
  removeButtons: 'Subscript,Superscript',
}
const root = getCurrentInstance().proxy.$root
const noteContent = ref('')
const isNoteFocused = ref(false)
const showTemplate = ref(false)
const isNotePublic = ref(false)
const note = ref(null)

const updateNotes = () => {
  useJwt.updateTeacherFollowUpNote(note.value.id, {
    is_public: isNotePublic.value,
    content: noteContent.value,
  }).catch(error => {
    root.showErrorMessage(error)
  })
}

const postNotes = () => {
  useJwt.storeTeacherFollowUpNote({
    is_public: isNotePublic.value,
    content: noteContent.value,
    lesson_id: props.lessonId,
    student_id: props.studentId,
  }).catch(error => {
    root.showErrorMessage(error)
  })
}

const updateOrCreateNotes = _.debounce(() => {
  if (note.value) {
    updateNotes()
  } else {
    postNotes()
  }
}, 500)

const getNoteContent = () => {
  useJwt.getTeacherFollowUpNotes({
    params: {
      student_id: props.studentId,
      lesson_id: props.lessonId,
    },
  }).then(response => {
    note.value = response.data.data.note
    noteContent.value = response.data.data.note?.content
    isNotePublic.value = !!response.data.data.note?.is_public
  })
}

const applyTemplate = template => {
  noteContent.value = template
  showTemplate.value = false
  updateOrCreateNotes()
}

getNoteContent()
</script>
<style scoped>
::v-deep .cke_contents {
    height: 500px !important
}

</style>
