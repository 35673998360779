<template>
  <div>
    <h4>Full Cb Problem Generations</h4>
    <div class="row">
      <div class="col-4">
        <select v-model="state.selectedVersion"
                class="mb-2 form-control input-sm"
        >
          <option :value="null">
            All Version
          </option>
          <option v-for="i of 4"
                  :key="i"
                  :value="i"
          >
            Only Version {{ i }}
          </option>
        </select>
      </div>
    </div>
    <SyncProgress title="Full Cb Assets Generation"
                  btn-class="btn-block"
                  :is-synching="state.isProcessing"
                  :queue-key="queueId"
                  @onSync="handleGenerate()"
    />
    <b-overlay :show="state.isProcessingLog">
      <app-timeline v-if="queueProgress.length"
                    class="mt-2"
      >
        <app-timeline-item
          v-for="qp,index of queueProgress"
          :key="index + qp.message"
          :title="qp.message"
          :time="FROM_NOW(qp.time)"
          variant="success"
        />
        <app-timeline-item
          v-if="queueFinalResponse"
          :title="queueFinalResponse.exception || 'Completed'"
          :time="FROM_NOW(queueFinalResponse.finished_at)"
          :variant="queueFinalResponse.exception ? 'error' : 'success'"
          :icon="queueFinalResponse.exception ? 'XIcon' : 'CheckIcon'"
        />
        <app-timeline-item
          v-else
          :variant="'primary'"
          :icon="'LoaderIcon'"
        />
      </app-timeline>
    </b-overlay>
  </div>
</template>
<script setup>
import useJwt from '@/auth/jwt/useJwt'
import SyncProgress from '@/views/super/image-library/components/SyncProgress.vue'
import {
  ref, reactive, getCurrentInstance, onMounted, onBeforeUnmount,
} from 'vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { BOverlay } from 'bootstrap-vue'

const root = getCurrentInstance().proxy.$root
const props = defineProps({
  lessonId: {
    type: Number,
    required: true,
  },
})

const queueProgress = ref([])
const queueFinalResponse = ref(null)
const queueId = `${props.lessonId}_cbFullProcess`
const state = reactive({
  isProcessing: false,
  isProcessingLog: false,
  processMonitor: null,
  selectedVersion: null,
})

const handleGenerate = () => {
  state.isProcessing = true
  queueFinalResponse.value = null
  useJwt.fullCbBulkAsset(props.lessonId, {
    params: {
      version: state.selectedVersion,
    },
  }).then(response => {
    root.showSuccessMessage(response)
  }).catch(error => {
    state.isProcessing = false
    root.showErrorMessage(error)
  })
}

const getLastProcess = () => {
  state.isProcessingLog = true
  useJwt.getQueueProgress(queueId, {
    params: {
      lastProgress: 1,
    },
  }).then(response => {
    queueProgress.value = response.data.data.remarks.info.map(item => JSON.parse(item))
    if (response.data.data.finished_at) {
      queueFinalResponse.value = {
        finished_at: response.data.data.finished_at,
        exception: response.data.data.exception,
      }
    }
  }).finally(() => {
    state.isProcessingLog = false
  })
}

onBeforeUnmount(() => {
  clearInterval(state.processMonitor)
})

onMounted(() => {
  getLastProcess()
  state.processMonitor = setInterval(() => getLastProcess(), 5500)
})
</script>
