<template>
  <div>
    <hours-tracked
      v-if="gameReport.length > 0"
      :key="graphData.series.length"
      :series="graphData.series"
      :categories="graphData.categories"
      :round-by="2"
    />
  </div>
</template>
<script>
import HoursTracked from './HoursTracked.vue'

export default {
  components: {
    HoursTracked,
  },
  props: {
    gameReport: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'day',
    },
  },
  computed: {
    graphData() {
      // const endOfWeek = moment().add('1', 'days')
      // const getDays = () => {
      //   // eslint-disable-next-line default-case
      //   switch (this.type) {
      //     case 'weekly': {
      //       return 7
      //     }
      //     case 'monthly': {
      //       return 30
      //     }
      //     case 'annually': {
      //       return 365
      //     }
      //   }
      //   return 0
      // }
      // const weekStartEnd = moment().subtract(getDays(), 'days')
      // const startOfMonth = weekStartEnd
      // const category = []
      // for (
      //   const m = moment(startOfMonth);
      //   m.isBefore(endOfWeek);
      //   m.add(1, 'days')
      // ) {
      //   category.push({
      //     day: m.format('YYYY-MM-DD'),
      //     text: m.format('MMM Do'),
      //   })
      // }
      const groupKey = this.type
      const groupedReport = this.groupByKey(this.gameReport, groupKey)
      const categories = []
      const series = []
      Object.entries(groupedReport).forEach(([key, value]) => {
        categories.push(key)
        const timeWorked = value.reduce((p, c) => p + c.game_time, 0)
        series.push((timeWorked * 0.000277778).toFixed(2))
      })

      return {
        categories,
        series: [{
          data: series,
          name: 'Play Report',
        }],
      }
    },
  },
}
</script>
