<template>
  <b-modal
    :visible="show"
    :title="modalData.title"
    ok-only
    :ok-title="$t('ok')"
    @ok="$emit('close')"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <div>
      {{ modalData.description }}
    </div>
    <div v-if="modalData.imgSrc"
         class="my-1"
    >
      <img :src="modalData.imgSrc"
           class="w-100"
      >
    </div>
    <div v-if="modalData.steps"
         class="modal-steps"
    >
      <h6>{{ $t('setting-module.steps-to-visit') }}</h6>
      <span v-html="modalData.steps" />
    </div>
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    modalData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
