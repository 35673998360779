<template>
  <div>
    <b-card body-class="p-0">
      <h4 class="ml-2 mt-2">
        {{ $t('labels.problem') }}
      </h4>
      <div class="borderless">
        <b-list-group class="mt-1">
          <b-list-group-item
            v-for="problem of problems"
            :key="problem.id"
            class="border-0"
            role="button"
            :class="selectedProblem == problem.id ? 'selected-problem' : ''"
            @click="$emit('getNotes', problem.id)"
          >
            <b-avatar
              rounded="sm"
              size="sm"
              variant="light-primary"
              :text="problem.name.charAt(0)"
              class="mr-1"
            />
            {{ problem.name }}
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BListGroup, BListGroupItem, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BAvatar,
  },
  props: {
    problems: {
      type: Array,
      default: () => [],
    },
    selectedProblem: {
      type: [Object, Number, String],
      default: () => null,
    },
  },
}
</script>
<style scoped>
  .selected-problem {
    background-color: #12141426 !important;
    color: white;
  }
</style>
