<template>
  <div>
    <span>{{ $t("send-notification-module.select-class") }}</span>
    <b-form-select
      v-model="selected"
      style="margin-top:6px;"
      :options="options"
      @input="(s)=>$emit('selected',s)"
    />
  </div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: { BFormSelect },
  data() {
    return {
      selected: null,
      classes: [],
      self: getUserData(),
      options: [
        {
          value: null, text: this.$i18n.tc('actions.all'),
        },
      ],
    }
  },
  mounted() {
    this.getClasses()
  },
  methods: {
    getClasses() {
      useJwt.getClasses({ params: { user_id: this.self.id } }).then(res => {
        const classes = res.data.data
        classes.forEach(e => {
          this.options.push({ value: e.id, text: e.name })
        })
        if (this.$route.query.classId) {
          this.selected = this.$route.query.classId
        }
      })
    },
  },
}
</script>

<style>

</style>
