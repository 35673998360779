import { render, staticRenderFns } from "./DemoChat.vue?vue&type=template&id=9a69f094"
import script from "./DemoChat.vue?vue&type=script&setup=true&lang=js"
export * from "./DemoChat.vue?vue&type=script&setup=true&lang=js"
import style0 from "./DemoChat.vue?vue&type=style&index=0&id=9a69f094&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports