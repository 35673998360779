<template>
  <b-modal
    id="modal-1"
    ref="pdfModal"
    :hide-footer="true"
    size="xl"
    @close="$emit('pdfModalClosed')"
    @hide="$emit('pdfModalClosed')"
  >
    <template #modal-title>
      PDF Viewer <b-button variant="outline-primary"
                           class="ml-2"
                           size="small"
                           @click="copyTextToClipboard"
      >
        Copy Link
      </b-button>
    </template>
    <b-col md="12">
      <embed
        :src="replaceDomain(pdf)"
        type="application/pdf"
        width="100%"
        height="100%"
        style="min-height: 80vh;"
      >
    </b-col>
  </b-modal>
</template>
<script>
import { BModal, BCol, BButton } from 'bootstrap-vue'

export default {
  components: {
    BModal, BCol, BButton,
  },
  props: {
    pdf: {
      type: [String, Object],
      default: () => {},
    },
    toggle: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    toggle(value) {
      if (value === true) {
        this.showModal()
      } else {
        this.hideModal()
      }
    },
  },
  methods: {
    replaceDomain(url) {
      if (!url) {
        return ''
      }
      // Extract the current domain
      const currentDomain = window.location.origin

      // Check if the current domain is localhost
      if (!url.includes('globalenglishcampus') && !currentDomain.includes('http://localhost') && !currentDomain.includes('https://localhost')) {
        // Replace the domain in the URL with the current domain
        // eslint-disable-next-line no-param-reassign
        url = url.replace(/^(https?:\/\/)?[^/]+/, currentDomain)
      }

      return url
    },
    showModal() {
      this.$refs.pdfModal.show()
    },
    hideModal() {
      this.$refs.pdfModal.hide()
    },
    fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement('textarea')
      textArea.value = text

      // Avoid scrolling to bottom
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        const successful = document.execCommand('copy')
        const msg = successful ? 'successful' : 'unsuccessful'
        this.showMessage(`Copying text command was ${msg}`)
      } catch (err) {
        this.showError('Fallback: Oops, unable to copy', err)
      }

      document.body.removeChild(textArea)
    },
    copyTextToClipboard() {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(this.pdf)
        return
      }
      navigator.clipboard.writeText(this.pdf).then(
        () => {
          this.showMessage('Copied To Clipboard')
        },
        () => {
          this.showError('Could not copy text')
        },
      )
    },
  },
}
</script>
