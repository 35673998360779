<template>
  <div>

    <view-html
      :show="isCodeVisible"
      :html-code="htmlCode"
      @close="isCodeVisible=false"
    />

    <div class="student-problem theme-1">

      <b-row class="mb-0">
        <b-col md="4">
          <b-form-group
            :label="$t('template-module.select-template')"
          >
            <b-form-select
              :value="null"
              :options="templates"
              @change="templateChanged"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="selectedTemplate"
          md="8"
          class="d-flex justify-content-end align-items-end"
        >
          <div class="mb-1">
            <b-button
              variant="primary"
              @click="showModal"
            >
              {{ $t('template-module.show-code') }}
            </b-button>
          </div>
        </b-col>
      </b-row>

      <!-- <b-row> -->
      <!-- <b-table :items="storedTemplates" /> -->
      <!-- </b-row> -->

      <div v-if="selectedTemplate">
        <create-common
          :no-template="selectedTemplate=='none'"
          :problem-data="problemData"
        />
        <component
          :is="getTemplateEditComponent"
          v-if="selectedTemplate!='none'"
          :type="selectedTemplate"
          :template-data="templateData"
          :shape="selectedShape"
        />
      </div>
      <h3 v-if="selectedTemplate">
        {{ $t('template-module.preview') }}
      </h3>
      <div
        v-if="selectedTemplate"
        ref="htmlComponent"
        class="problem-overview-content px-3"
      >
        <common :problem-data="problemData" />
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="problemData.before_template.length>0"
          v-html="problemData.before_template"
        />
        <!-- eslint-enable vue/no-v-html -->
        <component
          :is="getTemplateComponent"
          v-if="templateData"
          :data="templateData"
        />
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="problemData.after_template.length>0"
          v-html="problemData.after_template"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormSelect, BFormGroup, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import Cards from './components/Cards.vue'
import Carousel from './components/Carousel.vue'
import CenteredCards from './components/CenteredCard.vue'
import ImageParagraph from './components/ImageParagraph.vue'
import ImageQA from './components/ImageQA.vue'
import List from './components/List.vue'
import Paragraph from './components/Paragraph.vue'
import Table from './components/Table.vue'
import Common from './components/Common.vue'
import CreateCards from './components/create-edit/Cards.vue'
import CreateTable from './components/create-edit/Table.vue'
import CreateCommon from './components/create-edit/Common.vue'
import ViewHtml from './components/ViewHtml.vue'

export default {
  components: {
    Carousel,
    Cards,
    Paragraph,
    ImageParagraph,
    CenteredCards,
    List,
    BButton,
    Table,
    BRow,
    BCol,
    BFormSelect,
    BFormGroup,
    CreateCards,
    CreateTable,
    CreateCommon,
    Common,
    ViewHtml,
    ImageQA,
  },
  data() {
    return {
      selectedTemplate: null,
      templateData: null,
      storedTemplates: [],
      htmlCode: '',
      problemData: {
        question: '',
        page_link: '',
        before_template: '',
        after_template: '',
      },
      isCodeVisible: false,
      templates: [
        { value: null, text: i18n.tc('template-module.select-template') },
        { value: 'none', text: i18n.tc('template-module.none') },
        { value: 'cards', text: i18n.tc('template-module.cards') },
        { value: 'centered-cards', text: i18n.tc('template-module.centered-cards') },
        { value: 'carousel', text: i18n.tc('template-module.carousel') },
        { value: 'list', text: i18n.tc('template-module.list') },
        { value: 'table', text: i18n.tc('template-module.table') },
        { value: 'paragraph', text: i18n.tc('template-module.paragraph') },
        { value: 'image-paragraph', text: i18n.tc('template-module.image-paragraph') },
        { value: 'image-qa', text: i18n.tc('template-module.image-qa') },
      ],
      selectedShape: '',
    }
  },
  computed: {
    getTemplateEditComponent() {
      if (this.selectedTemplate === 'table') return 'CreateTable'
      return 'CreateCards'
    },
    getTemplateComponent() {
      if (this.selectedTemplate === 'cards') return 'Cards'
      if (this.selectedTemplate === 'centered-cards') return 'CenteredCards'
      if (this.selectedTemplate === 'carousel') return 'Carousel'
      if (this.selectedTemplate === 'list') return 'List'
      if (this.selectedTemplate === 'table') return 'Table'
      if (this.selectedTemplate === 'paragraph') return 'Paragraph'
      if (this.selectedTemplate === 'image-paragraph') return 'ImageParagraph'
      if (this.selectedTemplate === 'image-qa') return 'ImageQA'
      if (this.selectedTemplate === 'none') return ''
      return 'CreateCards'
    },
  },
  methods: {
    showModal() {
      this.htmlCode = this.formatHtml(this.$refs.htmlComponent.innerHTML.replaceAll('<!---->', ''))
      this.isCodeVisible = true
    },
    formatHtml(html) {
      const tab = '\t'
      let result = ''
      let indent = ''

      html.split(/>\s*</).forEach(element => {
        if (element.match(/^\/\w/)) {
          indent = indent.substring(tab.length)
        }

        result += `${indent}<${element}>\r\n`

        // eslint-disable-next-line no-useless-escape
        if (element.match(/^<?\w[^>]*[^\/]$/) && !element.startsWith('input')) {
          indent += tab
        }
      })

      return result.substring(1, result.length - 3)
    },

    storeTemplate() {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      data.append('type', this.selectedTemplate)
      data.append('question', this.problemData.question ? this.problemData.question : '')
      data.append('page_link', this.problemData.page_link ? this.problemData.page_link : '')
      data.append('before_template', this.problemData.before_template ? this.problemData.before_template : '')
      data.append('after_template', this.problemData.after_template ? this.problemData.after_template : '')

      if (this.templateData && this.templateData.forEach) {
        this.templateData.forEach((template, index) => {
          Object.keys(template).forEach(key => {
            if (key !== 'image_url') {
              data.append(`template[${index}][${key}]`, template[key])
            }
          })
        })
      }

      useJwt.axiosIns.post('/api/v1/templates', data, config)
    },
    templateChanged(val) {
      switch (val) {
        case 'cards':
        case 'centered-cards':
        case 'carousel':
        case 'list':
        case 'image-paragraph':
          this.templateData = [{
            image_url: 'https://via.placeholder.com/350x150',
            title: i18n.tc('template-module.example'),
            subtitle: i18n.tc('template-module.example'),
          }]
          break
        case 'table':
          this.templateData = [{
            columns: [i18n.tc('template-module.age'), i18n.tc('template-module.name')],
            rows: [['18', i18n.tc('template-module.sample-user')]],
          }]
          break
        case 'none':
          this.templateData = []
          this.problemData.after_template = ''
          break
        case 'paragraph':
          this.templateData = [{
            image_url: 'https://via.placeholder.com/350x150',
            title: i18n.tc('template-module.example'),
            subtitle: i18n.tc('template-module.example'),
            imagePosition: 'left',
          }]
          break
        default:
          this.templateData = [{
            image_url: 'https://via.placeholder.com/350x150',
            title: i18n.tc('template-module.example'),
            subtitle: i18n.tc('template-module.example'),
          }]
      }

      this.selectedTemplate = val
    },
  },
}
</script>

<style lang="scss">
  @import "/src/assets/scss/custom/problem-themes/main.scss";
</style>
