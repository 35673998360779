<template>
  <b-card>
    <b-card-title>
      <h4 class="w-100">{{ permission.name }}   <span class="float-right">
        <feather-icon icon="EditIcon"
                      role="button"
                      color="green"
                      @click="$emit('onEdit', permission)"
        />
        <feather-icon icon="TrashIcon"
                      role="button"
                      color="red"
                      class="ml-1"
                      @click="$emit('onDelete', permission)"
        />
      </span></h4>
    </b-card-title>
    <b-card-text>
      <div>
        <b-badge v-for="(perm,index) in permission.role_permissions"
                 :key="index"
                 variant="primary"
                 class="mr-1 mb-1"
        >
          {{ perm.name }}
        </b-badge>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardText, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BCardTitle, BCardText, BBadge,
  },
  props: {
    permission: {
      type: Object,
      required: true,
    },
  },

}
</script>

<style>

</style>
