<template>
  <b-modal
    :id="name"
    :ref="name"
    v-model="isVisible"
    ok-only
    ok-variant="outline-secondary"
    :ok-title="$t('close')"
    modal-class="modal-primary"
    centered
    :title="title"
    size="lg"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <template>
      <div class="copy-code-modal">
        <b-row>
          <b-col class="d-flex justify-content-end my-1">
            <b-button
              variant="outline-primary"
              @click="copyCode"
            >
              <feather-icon
                icon="CopyIcon"
                class="mr-50"
              />
              Copy
            </b-button>
          </b-col>
        </b-row>
        <b-card-code no-header>
          <div
            class="card-code p-0 m-0"
          >
            <pre
              class="language-markup"
            >
          <code
            class="language-markup"
            v-text="htmlCode"
        />
        </pre>
          </div>
        </b-card-code>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardCode from '@core/components/b-card-code'

export default ({
  directives: {
    Ripple,
  },
  components: {
    BModal, BCardCode, BButton, BRow, BCol,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      default: 'HTML Snippit',
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'HTML Snippit',
    },
    htmlCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVisible: this.show,
    }
  },

  watch: {
    show() {
      this.isVisible = this.show
    },
  },
  methods: {
    fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement('textarea')
      textArea.value = text

      // Avoid scrolling to bottom
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        const successful = document.execCommand('copy')
        const msg = successful ? 'successful' : 'unsuccessful'
        this.showMessage(`Copying text command was ${msg}`)
      } catch (err) {
        this.showError('Oops, unable to copy')
      }

      document.body.removeChild(textArea)
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(() => {
        this.showMessage('Copied To Clipboard')
      }, () => {
        this.showError('Could not copy text')
      })
    },
    copyCode() {
      this.copyTextToClipboard(this.htmlCode)
    },
  },
})
</script>
<style>
.copy-code-modal .card-header{
    display: none !important;
}
.copy-code-modal .card-body{
    padding: 0 !important;
}
</style>
