export default [
  {
    key: '0',
    color: '#34ccff',
  },
  {
    key: '1',
    color: '#66ff99',
  },
  {
    key: '2',
    color: '#ffff80',
  },
  {
    key: '3',
    color: '#ffb84d',
  },
  {
    key: '4',
    color: '#ffb84d',
  },
  {
    key: '5',
    color: '#ffb84d',
  },
  {
    key: '6',
    color: '#ffb84d',
  },
  {
    key: '7',
    color: '#ffff80',
  },
  {
    key: '8',
    color: '#66ff99',
  },
  {
    key: '9',
    color: '#34ccff',
  },
  {
    key: 'space',
    color: '#34ccff',
  },
  {
    key: 'abc',
    color: '#EE82EE',
  },

]
