<template>
  <div>
    <b-card body-class="p-0">
      <b-table-simple>
        <thead>
          <tr>
            <th>{{ $t('scorm-module.slide') }}</th>
            <th>{{ $t('scorm-module.type') }}</th>
            <th>{{ $t('scorm-module.time') }}</th>
            <th>{{ $t('scorm-module.status') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="interaction of interactionReport"
            :key="interaction.id"
          >
            <td>
              {{ interaction.id }}
            </td>
            <td>
              {{ capitalizeFirstLetter(interaction.question_type) }}
            </td>
            <td>
              {{ interaction.time }}
            </td>
            <td>
              <feather-icon
                v-if="interaction.isCorrect"
                icon="CheckIcon"
                class="text-success"
              />
              <feather-icon
                v-else
                class="text-danger"
                icon="XIcon"
              />
            </td>
          </tr>
        </tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>
<script>
import { BTableSimple, BCard } from 'bootstrap-vue'

export default {
  components: {
    BTableSimple,
    BCard,
  },
  props: {
    interactionReport: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
