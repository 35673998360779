/* eslint-disable import/no-cycle */
import useJwt from '@/auth/jwt/useJwt'
import Echo from 'laravel-echo'
import { getUserData, getUserToken } from '@/auth/utils'
import {
  getChatsAndContacts, getSingleChat, getGroupChat, addChatMessage, onTyping,
} from './data'

let activeChat
let profile
let contacts
const chatData = JSON.parse(localStorage.getItem('chatData')) || { activeChatType: null, activeChatId: null }
const activeChatType = localStorage.getItem('activeChatType') || 'chat'
const minimized = false
const maximized = true
const messageTyping = false
const chatType = 'single'
const model = 'class'
const totalUnseen = 0
const currentRoute = null
const defaultVibes = {
  'VIBE-SP': '/stickers/gec/smart_person.png',
  'VIBE-GP': '/stickers/gec/good_friend.png',
  'VIBE-HP': '/stickers/gec/happy_person.png',
  'VIBE-YRA': '/stickers/gec/awesome.png',
  'VIBE-BB': '/stickers/gec/big_brain.png',
  'VIBE-AK': '/stickers/gec/always_kind.png',
}
export default {
  namespaced: true,
  state: {
    activeChat,
    profile,
    contacts,
    chatData,
    minimized,
    maximized,
    messageTyping,
    chatType,
    model,
    activeChatType,
    totalUnseen,
    currentRoute,
    defaultVibes,
  },
  getters: {
    get_default_vibes: state => state.defaultVibes,
  },
  mutations: {
    FETCH_CHAT_CONTACTS(state, data) {
      state.activeChat = data.activeChat
      state.profile = data.profileUserDataMinimal
      state.contacts = data.chatsContacts
    },
    SET_ACTIVE_CHAT_ID(state, { id, type }) {
      if (type === 'group') {
        state.activeChatType = 'group'
      }
      state.chatData.activeChatId = id
      state.chatData.activeChatType = type
    },
    SET_MESSAGE_TYPING(state, value) {
      state.messageTyping = value
    },
    SET_MINIMIZED(state, value) {
      state.minimized = value
      state.maximized = !state.minimized
    },
    SET_ACTIVE_CHAT(state, value) {
      state.activeChat = value
    },
    SET_CHAT_TYPE(state, value) {
      if (value) {
        state.activeChatType = value
      }
    },
    SET_MODEL(state, value) {
      state.model = value
    },
    SET_TOTAL_UNSEEN(state, value) {
      state.totalUnseen = value
    },
    SET_CURRENT_ROUTE(state, value) {
      state.currentRoute = value
    },
  },
  actions: {
    async fetchChatsAndContacts() {
      const data = await getChatsAndContacts()
      return data
    },
    async getChat({ state }, { userId, page = 1 } = {}) {
      let data
      if (state.activeChatType === 'group') {
        data = await getGroupChat(userId, state.model)
      } else data = await getSingleChat(userId, page)
      return data
    },
    onTyping({ state }, userId) {
      const data = onTyping({ id: userId, type: state.activeChat?.type === 'group' ? state.model : null })
      return data
    },
    sendMessage({ state }, {
      contactId, message, senderId, audio, audioUrl, type, code,
    }) {
      return addChatMessage(`/apps/chat/chats/${contactId}`, {
        message, senderId, audio, audioUrl, chatType: state.activeChat?.type, model: state.activeChat?.model, type, code,
      })
    },
    fetchTotalUnseen({ commit }) {
      if (getUserData()) {
        useJwt.getTotalUseenMsgs().then(res => {
          commit('SET_TOTAL_UNSEEN', res.data.data)
        })
      }
    },
    listenForEvents({ dispatch }) {
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_WEBSOCKETS_KEY,
        forceTLS: false,
        wsHost: process.env.VUE_APP_WEBSOCKETS_HOST,
        wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
        disableStats: true,
        authEndpoint: process.env.VUE_APP_WEBSOCKETS_AUTH_ENDPOINT,
        auth: {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
            Accept: 'Application/json',
          },
        },
      })
      window.Echo.channel('chat-room').listen('ChatStoreEvent', () => {
        dispatch('fetchTotalUnseen')
      })
      window.Echo.channel('group-chat').listen('GroupChatEvent', () => {
        dispatch('fetchTotalUnseen')
      })
    },
  },
}
