<template>
  <div class="m-auto">
    <component
      :is="getGameComponent"
      :is-muted="isMuted"
      @gameOver="gameOver"
      @toggleSound="toggleSound"
    />
  </div>
</template>

<script>
import GAME_2048 from '@views/student/games/2048/Index.vue'
import PacRush from '@/views/student/games/pacrush/index.vue'
import NinjaFrog from '@/views/student/games/ninjaFrog/index.vue'
import TOWER_GAME from '@/views/student/games/tower-game/Index.vue'
import useJwt from '@/auth/jwt/useJwt'
import CarRacing from '@/views/student/games/carRacing/index.vue'
import MonsterRun from '@/views/student/games/monsterRun/index.vue'
import BallonShooter from '@/views/student/games/ballon-shooter/index.vue'
import SpringyHedgehog from '@/views/student/games/springy-hedgehog/index.vue'
import BasketBall from '@/views/student/games/basketball/index.vue'
import DinoRun from '@/views/student/games/dino-run/index.vue'
import JumpBall from '@/views/student/games/jump-ball/index.vue'
import PenguinSkip from '@/views/student/games/penguin-skip/index.vue'

export default {
  props: {
    code: {
      type: String,
      default: 'TW',
    },
    loaderId: {
      type: [Object, Number, String],
      default: () => null,
    },
  },
  data() {
    return {
      isMuted: !!window.isSilentMode,
      gameStartAt: new Date(),
    }
  },
  computed: {
    getGameComponent() {
      switch (this.code) {
        case ('TW'):
          return GAME_2048
        case ('PAC_RUSH'):
          return PacRush
        case ('NINJA_FROG'):
          return NinjaFrog
        case ('CAR_RACING'):
          return CarRacing
        case 'MONSTER_RUN':
          return MonsterRun
        case 'BALLON_SHOOTER':
          return BallonShooter
        case 'SPRINGLY_HEDGEHOG':
          return SpringyHedgehog
        case 'BASKET_BALL':
          return BasketBall
        case 'DINO_RUN':
          return DinoRun
        case 'JUMP_BALL':
          return JumpBall
        case 'PENGUIN_SKIP':
          return PenguinSkip
        default:
          return TOWER_GAME
      }
    },
  },
  watch: {
    code: {
      handler(newValue) {
        if (newValue) {
          this.gameStartAt = new Date()
        }
      },
      immediate: true,
    },
  },
  methods: {
    getGameEndTime() {
      const startDate = this.gameStartAt
      // Do your operations
      const endDate = new Date()
      return (endDate.getTime() - startDate.getTime()) / 1000
    },
    toggleSound() {
      const iframe = document.getElementById('gameFrameContent')
      if (iframe) {
        iframe.contentWindow.document.dispatchEvent(new Event('muteSound'))
        iframe.contentWindow.isSilentMode = !iframe.contentWindow.isSilentMode
      }
      this.isMuted = !this.isMuted
    },
    gameOver() {
      document.dispatchEvent(new Event('muteSound'))
      window.isSilentMode = true
      if (typeof window.STOP_GAME_SOUND === 'function') {
        window.STOP_GAME_SOUND()
      }
      this.$emit('gameOver')
      if (this.loaderId) {
        useJwt.recordGameTime(this.loaderId, {
          game_time: this.getGameEndTime(),
          loader_id: this.loaderId,
          game_code: this.code,
        }).catch(error => {
          console.error(error)
        })
      }
    },
  },
}
</script>
