<template>
  <div>
    <!-- CEFR Subject -->
    <b-form-group
      :label="$t('labels.create-problem.cefr-subject')"
      label-for="cefrSubject"
    >
      <validation-provider
        v-slot="{ errors }"
        name="cefrSubject"
        vid="cefrSubject"
      >
        <b-form-select
          id="cefrSubject"
          v-model="selectedSubject"
          :options="subjectList"
          name="cefrSubject"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <!-- CEFR Subject -->
    <!-- CEFR Domain -->
    <b-form-group
      :label="$t('labels.create-problem.cefr-domain')"
      label-for="cefrDomain"
    >
      <validation-provider
        v-slot="{ errors }"
        name="cefrDomain"
        vid="cefrDomain"
      >
        <b-form-select
          id="cefrDomain"
          v-model="selectedDomain"
          :options="cefrDomainList"
          :disabled="selectedSubject ? false : true"
          name="cefrDomain"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <!-- CEFR Domain -->
    <!-- CEFR Standard -->
    <b-form-group
      :label="$t('labels.create-problem.cefr-standard')"
      label-for="cefrStandard"
    >
      <validation-provider
        v-slot="{ errors }"
        name="cefrStandard"
        vid="cefrStandard"
      >
        <b-form-select
          id="cefrStandard"
          v-model="selectedStandard"
          :disabled="selectedDomain ? false : true"
          :options="cefrStandardList"
          name="cefrStandard"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <!-- CEFR Standard -->
    <b-form-group
      label="Problem Description"
      label-for="problemDescription"
    >
      <validation-provider
        v-slot="{ errors }"
        name="problemDescription"
        vid="problemDescription"
      >
        <b-form-input
          id="problemDescription"
          v-model="problemDescription"
          name="problemDescription"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <!-- CEFR Standard -->
  </div>
</template>

<script>
import { BFormGroup, BFormSelect, BFormInput } from 'bootstrap-vue'
import {
  ValidationProvider,
} from 'vee-validate/dist/vee-validate.full'

export default {
  components: {
    BFormGroup,
    BFormSelect,
    BFormInput,
    ValidationProvider,
  },
  props: {
    level: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
    subjectList() {
      return this.$store.state.problem.create.cefr.cefrSubjectList
    },
    cefrDomainList() {
      return this.$store.state.problem.create.cefr.cefrDomainList[this.level]
    },
    cefrStandardList() {
      return this.$store.state.problem.create.cefr.cefrStandardList[this.level]
    },
    selectedSubject: {
      get() {
        return this.$store.state.problem.create.cefr.selectedSubject[this.level]
      },
      set(value) {
        this.$store.commit('problem/SET_CEFR', { index: this.level, name: 'selectedSubject', value })
        if (value) {
          this.$store.dispatch('problem/getCefrDomain', { id: value, index: this.level })
        }
        this.$store.commit('problem/SET_CEFR_DOMAIN_LIST', { domains: [], index: this.level })
        this.$store.commit('problem/SET_CEFR_STANDARD_LIST', { standards: [], index: this.level })
        this.$store.commit('problem/SET_CEFR', { index: this.level, name: 'selectedDomain', value: null })
        this.$store.commit('problem/SET_CEFR', { index: this.level, name: 'selectedStandard', value: null })
      },
    },
    problemDescription: {
      get() {
        return this.$store.state.problem.create.problemDescription
      },
      set(value) {
        this.$store.commit('problem/SET_PROBLEM_DESCRIPTION', value)
      },
    },
    selectedDomain: {
      get() {
        return this.$store.state.problem.create.cefr.selectedDomain[this.level]
      },
      set(value) {
        this.$store.commit('problem/SET_CEFR', { index: this.level, name: 'selectedDomain', value })
        if (value) {
          this.$store.dispatch('problem/getCefrStandard', { id: value, index: this.level })
        }
        this.$store.commit('problem/SET_CEFR_STANDARD_LIST', { standards: [], index: this.level })
        this.$store.commit('problem/SET_CEFR', { index: this.level, name: 'selectedStandard', value: null })
      },
    },
    selectedStandard: {
      get() {
        return this.$store.state.problem.create.cefr.selectedStandard[this.level]
      },
      set(value) {
        this.$store.commit('problem/SET_CEFR', { index: this.level, name: 'selectedStandard', value })
      },
    },
  },
}
</script>
