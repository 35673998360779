<template>
  <div>
    <div>
      <b-row v-for="info,index of data"
             :key="index"
      >
        <b-col
          md="8"
          class="d-flex flex-column justify-content-center"
        >
          <word-with-popover
            v-if="template"
            :text="template.before_template"
            meta-type="before_template"
            popover="answer"
          />
          <slot />
        </b-col>
        <b-col md="4"
               class="d-flex flex-column justify-content-center align-items-center"
        >
          <b-card>
            <b-img v-image-preview
                   :src="info.image_url"
                   :alt="info.subtitle"
                   fluid
            />
            <b-card-text class="text-center mt-2">
              {{ info.title }}
            </b-card-text>
            <b-card-text class="text-center mt-2">
              {{ info.subtitle }}
            </b-card-text>
          </b-card>
          <!-- <b-card>
            <b-img
              v-image-preview
              :src="info.image_url"
              fluid
              alt="Image caption"
            />
            <b-card-text class="text-center mt-2">
              {{ info.subtitle }}
            </b-card-text>
          </b-card> -->
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BImg, BCardText,
} from 'bootstrap-vue'
import WordWithPopover from './WordWithPopover.vue'

export default {
  components: {
    BRow, BCol, BCard, BImg, BCardText, WordWithPopover,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    template: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
