var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100 hot-spot-statement",attrs:{"id":"hot-spot-statement"}},[_c('div',{staticClass:"problem-card-title md-12"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("student-test-module.before-we-begin"))+" ")])]),(_vm.hotSpotType && _vm.hotSpotType === 'hotspot-drag')?_c('div',[_c('DragHotSpot',{ref:"dragHotSpotProblem",attrs:{"points":_vm.points,"preview-answers":_vm.previewAnswers,"image-src":_vm.hotspotImage},on:{"ansUpdate":_vm.dragAnswersUpdate}})],1):_c('div',{staticClass:"d-flex w-100 justify-content-center align-items-center"},[_c('div',[_c('div',{staticStyle:{"position":"relative"}},[_c('img',{attrs:{"id":"myImage","src":_vm.hotspotImage}}),_vm._l((_vm.points),function(point,pKey){return _c('div',{key:pKey},[(point && !_vm.hotSpotType)?[_c('div',{class:`point ${
                !point.statement_visited ? ' clickable' : ' not-clickable'
              }`,style:(`top: ${point.coordinates.y * 100}%; left: ${
                point.coordinates.x * 100
              }%; `),on:{"click":function($event){return _vm.checkPoints(point)}}},[_c('span',[_vm._v(_vm._s(point.statement_visited ? "✓" : "+"))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(point.matchedPointIndex),expression:"point.matchedPointIndex"}],style:(`top: ${point.coordinates.y * 100}%; left: calc(${
                point.coordinates.x < 0.7
                  ? point.coordinates.x * 100 + 1 + '%'
                  : point.coordinates.x * 100 +
                    '%' +
                    ' - ' +
                    (_vm.tooltipWidth + 25) +
                    'px'
              }); position:absolute;`)},[_c('div',{class:`tooltiptext d-flex justify-content-between align-items-center p-1 ${
                  point.coordinates.x < 0.7 ? 'tooltip-left' : 'tooltip-right'
                }`,style:(`width:${_vm.tooltipWidth}px;`)},[_c('span',{directives:[{name:"text-to-speech",rawName:"v-text-to-speech"}],staticStyle:{"color":"white !important"},domProps:{"innerHTML":_vm._s(point.hotspot.statement)}}),(point.hotspot.statement_audio)?_c('AudiPlayer',{key:point.hotspot.statement_audio,attrs:{"src":point.hotspot.statement_audio}}):_vm._e(),_c('span',{staticClass:"btn btn-sm btn-success",on:{"click":function($event){return _vm.pointVisited(point)}}},[_vm._v(" ✓ ")])],1)])]:_c('MultiLayerHotSpot',{ref:"multiLayerHotSpot",refInFor:true,attrs:{"point":point,"tooltip-width":_vm.tooltipWidth,"hot-spot-type":_vm.hotSpotType},on:{"updateAnswers":_vm.updateAnswers,"selectedAnswers":_vm.updateAnswers,"checkAllValid":function($event){_vm.isAllAnswerSelected = _vm.points.every(item => item.matchedPointIndex === true)}}})],2)})],2)])]),(_vm.showLevel)?_c('div',{staticClass:"col-12 p-0 justify-content-between align-content-center problem-card-footer"},[_c('actions',{attrs:{"level":_vm.level,"mode":"statement","engine":_vm.engine,"school-settings":_vm.schoolSettings,"district-settings":_vm.districtSettings,"button-title":_vm.$t('next'),"on-submit":() => {
          if (_vm.isAllAnswerSelected && !_vm.isAnswerSubmitted) {
            // submit answer directly from here
            if (_vm.hotSpotType === 'hotspot-drag') {
              _vm.directSubmitAnswerForDrag()
            } else {
              _vm.directSubmitAnswer()
            }
          } else if (_vm.isAnswerSubmitted) {
            _vm.$emit('nextProblem')
          } else {
            _vm.$emit('proceedToQuestion');
          }
        },"action-visible":_vm.allVisited || _vm.isAllAnswerSelected}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }