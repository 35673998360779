<template>
  <b-modal
    :id="name"
    :ref="name"
    v-model="isVisible"
    ok-only
    :ok-variant="modalVarient"
    :modal-class="`modal-${modalVarient}`"
    centered
    size="lg"
    :title="`${modalTitle}`"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-card-text>
      <b-row>

        <!-- search -->
        <b-col class="col-12">
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('search')"
              @input="search"
            />
          </b-input-group>
        </b-col>
        <!-- search -->

        <b-col class="col-12 mt-1">
          <p
            v-if="actionType == 'allow'"
            class="p-0"
            style="margin-bottom:8px"
          >
            {{ $t('permissions-module.only-selected') }}:
          </p>
          <p
            v-else
            class="p-0"
            style="margin-bottom:8px"
          >
            {{ $t('permissions-module.everyone-except') }}:
          </p>
        </b-col>

        <!-- users table -->
        <b-col class="col-12">
          <b-overlay
            opacity="1"
            blur="1"
            :show="isFetchingUsers"
          >
            <b-table
              responsive="sm"
              class="permission-modal"
              :fields="fields"
              :items="items"
              style="max-height:400px;overflow-y:auto"
            >
              <template #cell(avatar)="data">

                <avatar
                  :image="data.item.avatar"
                  :rounded="'circle'"
                  size="32px"
                />
              </template>
              <template #cell(actions)="data">

                <b-button
                  :variant="blockedUsersIds.includes(data.item.id) ?'outline-danger' :'outline-secondary'"
                  class="btn-icon"
                  size="sm"
                  :disabled="isFetchingPermissions"
                  @click="toggleBlock(data.item)"
                >
                  <feather-icon
                    size="10"
                    :icon="actionType =='allow'? 'PlusIcon' : 'MinusIcon'"
                  />
                </b-button>
              </template>
            </b-table>
          </b-overlay>
        </b-col>
        <!-- users table -->

        <!-- blocked users -->
        <b-col
          v-if="blockedUsers.length>0"
          class="col-12 mt-1"
        >

          <p
            v-if="actionType == 'allow'"
            style="margin-bottom:4px"
          >
            {{ `${$t('permissions-module.users-who-will-see-this')} ${module}` }}
          </p>
          <p
            v-else
            style="margin-bottom:4px"
          >
            {{ `${$t('permissions-module.users-who-wont-see-this')} ${module}` }}
          </p>
          <b-overlay
            opacity="1"
            blur="1"
            :show="isFetchingPermissions"
          >
            <div class="blocked-users">
              <div
                v-for="(blockedUser,index) in blockedUsers"
                :key="index"
                :class="actionType == 'allow' ? 'allowed-user' : 'blocked-user'"
              >
                <avatar
                  :image="blockedUser.avatar"
                  :rounded="'circle'"
                  size="20px"
                />
                <span>{{ blockedUser.fullname }}</span>
                <span
                  class="x-icon"
                  @click="toggleBlock(blockedUser)"
                >
                  <feather-icon
                    size="16"
                    icon="XIcon"
                  />
                </span>
              </div>
            </div>
          </b-overlay>
        </b-col>
        <!-- blocked users -->

      </b-row>
    </b-card-text>
    <template #modal-footer="">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            :disabled="processingStatus"
            variant="outline-secondary"
            class="mr-1"
            @click="$emit('close')"
          >
            {{ $t('actions.close') }}
          </b-button>
          <b-button
            variant="success"
            :disabled="processingStatus"
            @click="createPermissions"
          >
            <b-spinner
              v-show="isStoringData"
              small
            />
            {{ $t('actions.save-changes') }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import {
  BSpinner, BRow, BCol, BModal, BButton, BCardText, BInputGroup, BFormInput, BInputGroupPrepend, BTable, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import avatar from '@views/common/components/Avatar.vue'
import i18n from '@/libs/i18n'

const FETCHING_USERS = 1
const STORING_DATA = 2
const FETCHING_PERMISSIONS = 3
// const PERMISSION_BLOCK = 'block'
// eslint-disable-next-line no-unused-vars
const PERMISSION_UNBLOCK = 'unblock'

export default ({
  directives: {
    Ripple,
  },
  components: {
    BModal, BSpinner, BRow, BCol, BButton, BCardText, BInputGroup, BFormInput, BInputGroupPrepend, BTable, avatar, BOverlay,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    modalVarient: {
      type: String,
      default: 'success',
    },
    name: {
      type: String,
      default: 'delete-modal',
    },
    module: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    actionType: {
      type: String,
      default: 'block',
    },
    userType: {
      type: String,
      default: 'curriculum',
    },
  },
  data() {
    return {
      isVisible: this.show,
      searchTimeout: null,
      searchTerm: '',
      blockedUsers: [],
      processingStatus: null,
      fields: [
        {
          key: 'avatar', label: i18n.tc('permissions-module.users-table.image'),
        },
        {
          key: 'fullname', label: i18n.tc('permissions-module.users-table.name'),
        },
        {
          key: 'actions', label: i18n.tc('permissions-module.users-table.action'),
        },
      ],
      items: [],
    }
  },
  computed: {
    isFetchingUsers() {
      return this.processingStatus === FETCHING_USERS
    },
    isStoringData() {
      return this.processingStatus === STORING_DATA
    },
    isFetchingPermissions() {
      return this.processingStatus === FETCHING_PERMISSIONS
    },
    blockedUsersIds() {
      return this.blockedUsers.map(e => e.id)
    },
    modalTitle() {
      if (this.actionType === 'allow') {
        return i18n.tc('library-moduel.allow-users-title')
      }
      return i18n.tc('library-moduel.block-users-title')
    },
  },
  watch: {
    show() {
      this.isVisible = this.show
    },
    id() {
      if (!this.id) return
      if (this.searchTerm.length > 0) {
        this.searchTerm = ''
        this.getUsers()
      }
      this.getPermissions()
    },
    module() {
      this.getUsers()
    },
  },
  mounted() {
    this.getUsers()
    this.getPermissions()
  },
  methods: {
    getPermissions() {
      this.processingStatus = this.isFetchingPermissions
      const data = {}
      const isLessonGroup = this.module === 'lessonGroups'
      if (isLessonGroup) {
        data.gid = this.id.toString()
      } else {
        data[`${this.module}_id`] = this.id.toString()
      }
      if (!this.module) return
      useJwt.getPermission(`${this.module}.${this.method}`, { data: JSON.stringify(data) }).then(res => {
        const permissions = res.data.data
        this.blockedUsers = []
        permissions.forEach(permission => {
          this.blockedUsers.push({
            id: permission.for,
            permission_id: permission.id,
            ...permission.target,
          })
        })
      }).finally(() => {
        this.processingStatus = null
      })
    },
    search(search) {
      if (this.searchTimeout) { clearTimeout(this.searchTimeout) }

      this.searchTimeout = setTimeout(() => {
        this.getUsers(search)
      }, 400) // delay
    },

    toggleBlock(user) {
      const { id } = user
      if (this.blockedUsersIds.includes(id)) {
        const ind = this.blockedUsersIds.indexOf(id)
        if (this.blockedUsers[ind].permission_id) {
          useJwt.deletePermission(this.blockedUsers[ind].permission_id)
        }
        this.blockedUsers.splice(ind, 1)
      } else { this.blockedUsers.push(user) }
    },

    createPermissions() {
      this.processingStatus = STORING_DATA
      const data = {}
      const isLessonGroup = this.module === 'lessonGroups'
      if (isLessonGroup) {
        data.gid = this.id.toString()
      } else {
        data[`${this.module}_id`] = this.id.toString()
      }

      const params = {
        data: JSON.stringify(data),
        action: isLessonGroup ? 'allow' : this.actionType,
        for: JSON.stringify(this.blockedUsersIds),
      }

      useJwt.createPermission(`${this.module}.${this.method}`, params).then(res => {
        this.showSuccessMessage(res)
      }).finally(() => {
        this.processingStatus = null
        this.$emit('close')
      })
    },

    getUsers(search = null) {
      const params = {
        email: search,
        type: this.userType,
      }
      this.processingStatus = FETCHING_USERS

      useJwt
        .searchForUsers({ params })
        .then(response => {
          this.items = response.data.data
        })
        .finally(() => {
          this.processingStatus = null
        })
    },
  },
})
</script>

<style lang="scss">
.modal-content .modal-title{
  text-transform: capitalize;
}
.blocked-users{
  padding: 4px;
  box-sizing: border-box;
  border: 2px solid #ebe9f1;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  max-height: 100px;
  overflow-y: auto;

  .allowed-user{
    box-sizing: border-box;
    margin:4px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    background: rgba(48, 148, 65, 0.7);
    padding: 4px 8px;
    color: #fff;
    align-items: center;
    font-size: 12px;

    img{
      margin-right: 4px;
    }

    .x-icon{
      margin-left: 4px;
      cursor: pointer;
    }
  }
  .blocked-user{
    box-sizing: border-box;
    margin:4px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    background: rgba(235, 77, 75,0.7);
    padding: 4px 8px;
    color: #fff;
    align-items: center;
    font-size: 12px;

    img{
      margin-right: 4px;
    }

    .x-icon{
      margin-left: 4px;
      cursor: pointer;
    }
  }
}
</style>
