<template>
  <div class="p-3 shadow-sm rounded bg-white modal-settings">
    <!-- Title -->
    <h3 class="modal-title">
      {{ $t('ai-ide-module.modal-settings') }}
    </h3>
    <div>
      <h4>{{ $t('ai-ide-module.select-modal') }}</h4>
    </div>
    <!-- Radio Button Group -->
    <b-form-select
      v-model="modelForm.model_type"
      :options="modelOptions"
      @input="$forceUpdate()"
    />

    <!-- Line Divider -->
    <hr class=" modal-divider">
    <div>
      <template v-for="model,index of modelOptions">
        <component :is="model.component"
                   v-if="model.value == modelType"
                   :key="index"
                   :model-form="modelForm"
        />
      </template>
    </div>
    <slot />
  </div>
</template>

<script>
// Import Bootstrap Vue components
import { BFormRadioGroup, BFormGroup, BFormSelect } from 'bootstrap-vue'
import {
  GPT4, GPT4o, GPT4oMINI, GPT3_5, WRITE_SHIFT, NOUSRESEARCH_NOUS_CAPYBARA_7B, MISTRALAI_MISTRAL_7B_INSTRUCT, HUGGINGFCEH4_ZEPHYR_7B_BETA,
  MYTHO_MIST_7B, PSYFIGHTER_13B, PSYFIGHTER_13B_2, NOUS_HERMES_LLAMA2_13B, PHIND_CODELLAMA_34B, MISTRAL_8X7B_INSTRUCT, LLAMA_2_13B_CHAT,
  PALM_2_CHAT_BISON, PALM_2_CODECHAT_BISON, PALM_2_CHAT_BISON_32K, PALM_2_CODECHAT_BISON_32K, GEMINI_PRO, PPLX_70B_ONLINE, PPLX_7B_ONLINE,
  LLAMA_2_70B_CHAT, GPT3_5_TURBO_TRAINED, GPT_O1, GPT_O1_MINI,
} from '@/const/ai-models'

import Gpt4Setting from '../gpt-4/settings.vue'
import WriteShiftSetting from '../write-shift/settings.vue'

export default {
  components: {
    // Register the components for use
    BFormRadioGroup,
    BFormGroup,
    BFormSelect,
    Gpt4Setting,
    WriteShiftSetting,
  },
  props: {
    modelForm: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedModel: null,
      modelOptions: [
        { value: GPT_O1, text: 'O1 Preview', component: Gpt4Setting },
        { value: GPT_O1_MINI, text: 'O1 Mini', component: Gpt4Setting },
        { value: GPT4, text: 'GPT-4', component: Gpt4Setting },
        { value: GPT4o, text: 'GPT-4o', component: Gpt4Setting },
        { value: GPT4oMINI, text: 'GPT-4o-Mini', component: Gpt4Setting },
        { value: GPT3_5, text: 'GPT-3.5', component: Gpt4Setting },
        { value: GPT3_5_TURBO_TRAINED, text: 'GPT-3.5 Turbo (Trained)', component: Gpt4Setting },
        { value: WRITE_SHIFT, text: 'WriteShift', component: WriteShiftSetting },
        { value: NOUSRESEARCH_NOUS_CAPYBARA_7B, text: 'Nous Capybara 7B (Free)', component: Gpt4Setting },
        { value: MISTRALAI_MISTRAL_7B_INSTRUCT, text: 'Mistral 7B Instruct (Free)', component: Gpt4Setting },
        { value: HUGGINGFCEH4_ZEPHYR_7B_BETA, text: 'Hugging Face Zephyr 7B (Free)', component: Gpt4Setting },
        { value: MYTHO_MIST_7B, text: 'MythoMist 7B (Free)', component: Gpt4Setting },
        { value: PSYFIGHTER_13B, text: 'Psyfighter 13B', component: Gpt4Setting },
        { value: PSYFIGHTER_13B_2, text: 'Psyfighter v2 13B', component: Gpt4Setting },
        { value: NOUS_HERMES_LLAMA2_13B, text: 'Nous Hermes 13B', component: Gpt4Setting },
        { value: PHIND_CODELLAMA_34B, text: 'Phind CodeLlama 34B v2', component: Gpt4Setting },
        { value: MISTRAL_8X7B_INSTRUCT, text: 'Mistral Mixtral 8x7B Instruct', component: Gpt4Setting },
        { value: LLAMA_2_13B_CHAT, text: 'Meta Llama v2 13B Chat', component: Gpt4Setting },
        { value: PALM_2_CHAT_BISON, text: 'Google PaLM 2 Chat', component: Gpt4Setting },
        { value: PALM_2_CODECHAT_BISON, text: 'Google PaLM 2 Code Chat', component: Gpt4Setting },
        { value: PALM_2_CHAT_BISON_32K, text: 'Google PaLM 2 Chat 32k', component: Gpt4Setting },
        { value: PALM_2_CODECHAT_BISON_32K, text: 'Google PaLM 2 Chat 32k', component: Gpt4Setting },
        { value: GEMINI_PRO, text: 'Google Gemini Pro', component: Gpt4Setting },
        { value: PPLX_70B_ONLINE, text: 'Perplexity PPLX 70B Online', component: Gpt4Setting },
        { value: PPLX_7B_ONLINE, text: 'Perplexity PPLX 7B Online', component: Gpt4Setting },
        { value: LLAMA_2_70B_CHAT, text: 'Meta Llama v2 70B Chat', component: Gpt4Setting },
      ],
    }
  },
  computed: {
    modelType() {
      return this.modelForm.model_type
    },
  },
}
</script>

  <style scoped>
  .modal-settings {
    border: 1px solid #e0e0e0;
    transition: box-shadow 0.3s ease-in-out;
  }

  .modal-settings:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }

  .modal-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333333;
  }

  .modal-divider {
    border: none;
    height: 1px;
    background-color: #e0e0e0;
  }

  /* Styling for radio options */
  .b-form-radio {
    color: #333;
  }

  .b-form-radio:checked + .b-form-radio-label:before {
    border-color: #3498db;
    background-color: #3498db;
  }

  .b-form-radio-label:hover:before {
    border-color: #3498db;
  }

  .b-form-radio-label:focus {
    outline: none;
    border-color: #3498db;
  }
  </style>
