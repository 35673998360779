// Function to store data in localStorage with expiration time in days

function setWithExpiry(key, value, days = 0.5) {
  const now = new Date()
  const ttl = days * 24 * 60 * 60 * 1000 // Convert days to milliseconds

  // Create an object to store the value and expiration time
  const item = {
    value,
    expiry: now.getTime() + ttl,
  }

  localStorage.setItem(key, JSON.stringify(item))
}

// Function to retrieve data from localStorage with expiration check
function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key)

  // If the item doesn't exist, return null
  if (!itemStr) {
    return null
  }

  const item = JSON.parse(itemStr)
  const now = new Date()

  // Check if the item has expired
  if (now.getTime() > item.expiry) {
    // If expired, remove the item from localStorage and return null
    localStorage.removeItem(key)
    return null
  }

  // If not expired, return the stored value
  return item.value
}

export { setWithExpiry, getWithExpiry }
