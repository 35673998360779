<template>
  <div>
    <!-- delete article model -->

    <delete-modal
      name="modal-delete-article"
      :on-delete="deleteArticle"
      :title="deleteModalTitle"
      :sub-title="deleteModalBody"
      :is-processing="isProcessing"
      :show="deleteArticleData!=null && deleteArticleData.id!=null"
      @close="deleteArticleData.id=null"
    />

    <b-row>
      <div
        v-if="hasJoinedNetwork"
        class="w-100"
      >
        <div v-if="unVerifiedArticles.length>0">
          <!-- <b-col

            class="mb-1"
          >
            <h3>{{ $t('pending-articles') }}</h3>
          </b-col> -->
          <!-- article -->
          <b-col
            v-for="(article,index) in unVerifiedArticles"
            :key="'article_'+index"
            md="12"
            lg="12"
          >
            <Article
              :article="article"
              type="not-published"
              @onDecline="(id,requestId,articleType)=>setArticleBeingDeleted({id,requestId,type:'decline-article',articleType})"
              @onRemoveArticleFromNetwork="(id,requestId,articleType)=>setArticleBeingDeleted({id,requestId,type:'delete-article-from-network',articleType})"
              @onDelete="(id,requestId,articleType)=>setArticleBeingDeleted({id,requestId,type:'delete-article',articleType})"
              @onEdit="editArticle"
              @onPublish="publishArticle"
            />
          </b-col>

          <b-col
            v-if="unVerifiedArticlesPaginationData.tptalItems>unVerifiedArticlesPaginationData.perPage"
            lg="12"
            class="mb-3"
          >
            <b-pagination
              v-model="unVerifiedArticlesPaginationData.currentPage"
              :total-rows="unVerifiedArticlesPaginationData.totalItems"
              :per-page="unVerifiedArticlesPaginationData.perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              @change="getUnverifiedArticles"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </div>
      </div>
      <!-- verified articles -->
      <div
        v-if="verifiedArticles.length>0"
        class="w-100"
      >
        <!-- article -->
        <b-col
          v-for="(article,index) in verifiedArticles"
          :key="'article_'+index"
          md="12"
          lg="12"
        >
          <Article
            :article="article"
            type="published"
            @onRemoveArticleFromNetwork="(id,requestId,articleType)=>setArticleBeingDeleted({
              id,
              requestId,
              type:'delete-article-from-network',
              articleType
            })"
            @onDelete="(id,requestId,articleType)=>setArticleBeingDeleted({
              id,requestId,type:'delete-article',articleType
            })"
            @onEdit="editArticle"
            @changeFeatured="changeFeatured"
          />
        </b-col>

        <b-col lg="12">
          <b-pagination
            v-if="verifiedArticlesPaginationData.tptalItems>verifiedArticlesPaginationData.perPage"
            v-model="verifiedArticlesPaginationData.currentPage"
            :total-rows="verifiedArticlesPaginationData.totalItems"
            :per-page="verifiedArticlesPaginationData.perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            @change="getVerifiedArticles"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </div>
      <b-col
        v-else
        md="12"
      >
        <b-card>
          <h5>{{ $t('network-no-article', { article: $t(`student-profile.${articleType}`) }) }}</h5>
        </b-card>
      </b-col>
    <!-- articles -->
    </b-row>

    <edit
      :toggle="articleBeingEdited!=null"
      :article-data="articleBeingEdited"
      @close="articleBeingEdited=null"
    />

  </div>
</template>
<script>
import {
  BRow, BCol, BPagination, BCard,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import Ripple from 'vue-ripple-directive'
import Article from '@/views/common/network/components/article/Details.vue'
import Edit from '@/views/common/network/components/article/Edit.vue'
import { mapGetters } from 'vuex'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    BRow, BCol, BPagination, BCard, Article, Edit, DeleteModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isProcessing: false,
      articleBeingEdited: null,
      deleteArticleData: null,
    }
  },
  computed: {
    ...mapGetters({
      self: 'network/self',
      networkId: 'network/networkId',
      myNetworkMembership: 'network/myNetworkMembership',
      hasJoinedNetwork: 'network/hasJoinedNetwork',
      verifiedArticles: 'network/verifiedArticles',
      unVerifiedArticles: 'network/unVerifiedArticles',
      verifiedArticlesPaginationData: 'network/verifiedArticlesPaginationData',
      unVerifiedArticlesPaginationData: 'network/unVerifiedArticlesPaginationData',
      isALeader: 'network/isALeader',
      articleType: 'network/activeTab',
      isACoLeader: 'network/isACoLeader',
      isAMember: 'network/isAMember',
    }),

    deleteModalTitle() {
      if (!this.deleteArticleData) return i18n.tc('actions.delete-article')
      if (this.deleteArticleData.type === 'decline-article') return i18n.tc('actions.decline-article')
      if (this.deleteArticleData.type === 'delete-article-from-network') return i18n.tc('actions.delete-article')
      if (this.deleteArticleData.type === 'delete-article') return i18n.tc('actions.delete-article-permanently')
      return i18n.tc('actions.delete-article')
    },
    deleteModalBody() {
      if (!this.deleteArticleData) return i18n.tc('actions.delete-article')
      if (this.deleteArticleData.type === 'decline-article') return i18n.tc('questions.decline-article')
      if (this.deleteArticleData.type === 'delete-article-from-network') return i18n.tc('questions.delete-article')
      if (this.deleteArticleData.type === 'delete-article') return i18n.tc('questions.delete-article-permanently')
      return i18n.tc('actions.delete-article')
    },
  },
  mounted() {
    this.$store.dispatch('network/getVerifiedArticles')
    if (this.self && this.hasJoinedNetwork) this.$store.dispatch('network/getUnVerifiedArticles')
  },
  methods: {
    editArticle(article) {
      const formattedArticle = JSON.parse(JSON.stringify(article))
      if (article.video === 'null') formattedArticle.video = null
      this.articleBeingEdited = formattedArticle
    },
    setArticleBeingDeleted(data) {
      this.deleteArticleData = data
    },
    getUnverifiedArticles(page) {
      this.$store.dispatch('network/getUnVerifiedArticles', {
        page,
      })
    },
    getVerifiedArticles(page) {
      this.$store.dispatch('network/getVerifiedArticles', {
        page,
      })
    },
    declineArticle() {
      this.isProcessing = true
      useJwt.rejectArticleRequest({ request_id: this.deleteArticleData.requestId })
        .then(response => {
          this.showSuccessMessage(response)
          this.deleteArticleData.id = null
          this.$store.dispatch('network/getUnVerifiedArticles')
        }).finally(() => {
          this.isProcessing = false
        })
    },
    deleteArticle() {
      if (this.deleteArticleData.type === 'decline-article') this.declineArticle()
      if (this.deleteArticleData.type === 'delete-article-from-network') this.deleteArticleFromNetwork()
      if (this.deleteArticleData.type === 'delete-article') this.deleteArticlePermanently()
    },

    changeFeatured(data) {
      useJwt.changeFeaturedArticle(data.id).then(res => {
        this.showSuccessMessage(res)
        this.getVerifiedArticles()
      })
    },

    deleteArticleFromNetwork() {
      useJwt.deleteArticleFromNetwork(this.deleteArticleData.id, this.networkId, { request_id: this.deleteArticleData.requestId })
        .then(response => {
          this.showSuccessMessage(response)
          if (this.deleteArticleData.articleType === 'not-published') this.$store.dispatch('network/getUnVerifiedArticles')
          else this.$store.dispatch('network/getVerifiedArticles')
          this.deleteArticleData = null
        })
    },
    deleteArticlePermanently() {
      useJwt.deleteArticlePermanently(this.deleteArticleData.id, this.networkId, { request_id: this.deleteArticleData.requestId })
        .then(response => {
          this.showSuccessMessage(response)
          if (this.deleteArticleData.articleType === 'not-published') this.$store.dispatch('network/getUnVerifiedArticles')
          else this.$store.dispatch('network/getVerifiedArticles')
          this.deleteArticleData = null
        })
    },
    publishArticle(id) {
      useJwt.acceptRequest({
        request_id: id,
      })
        .then(response => {
          this.showSuccessMessage(response)
          this.$store.dispatch('network/getVerifiedArticles')
          this.$store.dispatch('network/getUnVerifiedArticles')
        })
    },

  },
}
</script>
