<template>
  <div>
    <feather-icon
      icon="PlayCircleIcon"
      class="mr-2 play-icon"
      @click="play"
    />
    <audio
      ref="player"
      preload="metadata"
      :src="src"
      controls
      style="display: none"
      @loadedmetadata="setMax"
    />
  </div>
</template>

<script>
import getBlobDuration from 'get-blob-duration'
import moment from 'moment'

export default {
  props: {
    src: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: 0,
      min: 0,
      max: null,
      dir: 'ltr',
      isPlaying: false,
      formatter: v => moment.utc(v * 1000).format('mm:ss'),
    }
  },
  computed: {
    formattedValue() {
      return moment.utc((this.max - this.value) * 1000).format('mm:ss')
    },
  },
  watch: {
    value() {
      if (this.value >= this.max) {
        this.isPlaying = false
      }
    },
  },
  methods: {
    play() {
      this.isPlaying = true
      this.$refs.player.play()
    },
    pause() {
      this.isPlaying = false
      this.$refs.player.pause()
    },
    updateValue() {
      this.value = parseInt(this.$refs.player.currentTime, 10)
    },
    async setMax() {
      if (this.$refs.player.duration === Infinity) {
        const duration = await getBlobDuration(this.src)
        this.max = parseInt(duration, 10)
      } else {
        this.max = parseInt(this.$refs.player.duration, 10)
      }
    },
    change() {
      this.$refs.player.currentTime = this.value
    },
  },
}
</script>

<style lang='scss'>
@import "@core/scss/vue/libs/vue-slider.scss";
.player {
  display: flex;
  align-items: center;
  gap: 20px;
  .audio-length-slider {
    width: 80% !important;
  }
  .play-button {
    padding: 5px;
  }
  .time-display {
    font-size: 10px;
  }
}
</style>
