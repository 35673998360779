<template>
  <div>
    <b-form-group
      :label="$t('labels.create-problem.statement')"
      label-for="statement"
    >
      <b-form-input
        v-model="statement[index]"
        placeholder="Statement"
      />
    </b-form-group>
    <b-form-group
      :label="$t('labels.create-problem.statement-audio')"
      label-for="audio"
    >
      <Recorder
        :url="statementAudio"
        @input="setStatementAudio($event, 'statementAudio')"
      />
      <input
        v-model="statementAudio"
        hidden
      >
    </b-form-group>
    <b-form-group
      :label="$t('labels.create-problem.feedback')"
      label-for="feedback"
    >
      <b-form-input
        v-model="feedback[index]"
        placeholder="Feedback"
      />
    </b-form-group>
    <b-form-group
      :label="$t('labels.create-problem.feedback-audio')"
      label-for="audio"
    >
      <Recorder
        :url="feedbackAudio"
        @input="setStatementAudio($event, 'feedbackAudio')"
      />
      <input
        v-model="feedbackAudio"
        hidden
      >
    </b-form-group>
  </div>
</template>
<script>
import { BFormInput, BFormGroup } from 'bootstrap-vue'
import Recorder from '../recorder.vue'

export default {
  components: {
    BFormInput, BFormGroup, Recorder,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['index'],
  computed: {
    statement: {
      get() {
        return this.$store.state.problem.create.statement
      },
      set(value) {
        this.$store.commit('problem/SET_STATEMENT_FIELD', value)
      },
    },
    feedback: {
      get() {
        return this.$store.state.problem.create.feedback
      },
      set(value) {
        this.$store.commit('problem/SET_FEEDBACK_FIELD', value)
      },
    },
    feedbackAudio() {
      return this.$store.state.problem.create.feedbackAudio.url[this.index]
    },
    statementAudio() {
      return this.$store.state.problem.create.statementAudio.url[this.index]
    },
  },
  methods: {
    setStatementAudio({ audio, url }, type) {
      this.$store.commit('problem/SET_STATEMENT_FEILD', {
        name: type,
        value: url,
        index: this.index,
        type: 'url',
      })
      this.$store.commit('problem/SET_STATEMENT_FEILD', {
        name: type,
        value: audio,
        index: this.index,
        type: 'file',
      })
      this.$store.commit('problem/SET_STATEMENT_FEILD', {
        name: 'stType',
        value: 'audio',
        index: this.index,
      })
      this.$store.commit('problem/SET_STATEMENT_FEILD', {
        name: 'fbType',
        value: 'audio',
        index: this.index,
      })
    },
  },

}
</script>
