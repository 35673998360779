<template>
  <div class="card text-center mt-5">
    <div
      style="
        border-radius: 200px;
        height: 200px;
        width: 200px;
        background: #f8faf5;
        margin: 0 auto;
      "
    >
      <i class="checkmark">✓</i>
    </div>
    <h1>Success</h1>
    <p v-html="message" />
    <p v-if="!schoolReservation"
       class="mt-2"
    >
      <b-button
        variant="outline-primary"
        pill
        @click="$emit('addMore')"
      >
        <feather-icon icon="PlusIcon" />  {{ actionMessage }}
      </b-button>
    </p>
    <p v-else
       class="mt-2"
    >
      <b-button variant="success"
                pill
                @click="$emit('addMore')"
      >
        Thanks!
      </b-button>
    </p>
  </div>
</template>
<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    actionMessage: {
      type: String,
      default: 'Add More',
    },
    message: {
      type: String,
      default: 'You reservation has been posted<br> we`ll be in touch shortly!',
    },
    schoolReservation: {
      type: Boolean,
      default: false,
    },
  },
}

</script>
<style scoped>
h1 {
  color: #88b04b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}
i {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}
</style>
