<template>
  <b-col>
    <b-card
      v-if="network"
      class="mb-1 col-12"
    >

      <b-row>
        <b-col
          class="mb-1 col-12 pl-0"
        >
          <h5>
            {{ $t('network-module.details') }}
          </h5>
        </b-col>

        <b-col
          class="mb-1 col-12 m-0 p-0"
        >
          {{ getNetworkRoleMessage }}
        </b-col>

        <b-col
          v-if="network && network.description && network.description.length>0"
          class="mb-1 col-12"
        >
          <b-row>
            <b-col
              md="1"
              sm="3"
              class="pl-0"
            >
              <feather-icon
                icon="FileTextIcon"
                size="20"
                class="align-middle text-body"
              />
            </b-col>
            <b-col
              md="11"
              sm="9"
              class="col"
            >
              <b-card-text>
                {{ network.description }}
              </b-card-text>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          class="mb-1 col-12"
        >
          <b-row>
            <b-col
              md="1"
              sm="3"
              class="pl-0"
            >
              <feather-icon
                icon="GlobeIcon"
                size="20"
                class="align-middle text-body"
              />
            </b-col>
            <b-col
              md="11"
              sm="9"
              class="col"
            >
              <b-card-text>
                {{ network.language }}
              </b-card-text>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          class="mb-1 col-12"
        >
          <b-row>
            <b-col
              md="1"
              sm="3"
              class="pl-0"
            >
              <feather-icon
                icon="CalendarIcon"
                size="20"
                class="align-middle text-body"
              />
            </b-col>
            <b-col
              md="11"
              sm="9"
              class="col"
            >
              <b-card-text>
                {{ network.created_at }}
              </b-card-text>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          class="mb-1 col-12"
        >
          <b-row>
            <b-col
              md="1"
              sm="3"
              class="pl-0"
            >
              <feather-icon
                icon="UsersIcon"
                size="20"
                class="align-middle text-body"
              />
            </b-col>
            <b-col
              md="11"
              sm="9"
              class="col"
            >
              <b-card-text>
                {{ network.total_members }}
              </b-card-text>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          class="mb-1 col-12"
        >
          <b-row>
            <b-col
              md="1"
              sm="3"
              class="pl-0"
            >
              <feather-icon
                icon="UserIcon"
                size="20"
                class="align-middle text-body"
              />
            </b-col>
            <b-col
              md="11"
              sm="9"
              class="col"
            >
              <b-card-text>

                <router-link
                  :to="{ name: self ? 'user-profile':'guest-user-profile',params:{name:`${getFullName.replaceAll('_','-').replaceAll(' ','-')}_${network.leader.id}`}}"
                  target="_blank"
                >
                  {{ getFullName }}
                </router-link>
              </b-card-text>
            </b-col>
          </b-row>
        </b-col>

      </b-row>
    </b-card>
  </b-col>
</template>
<script>
import {
  BCard, BCardText, BRow, BCol,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import i18n from '@/libs/i18n'

export default {
  components: {
    BCard, BCardText, BRow, BCol,
  },
  computed: {
    network() {
      return this.$store.state.network.network
    },
    getFullName() {
      if (this.network.leader.fullname) {
        return this.network.leader.fullname
      }
      return `${this.network.leader.firstname} ${this.network.leader.lastname}`
    },
    ...mapGetters({
      self: 'network/self',
      isALeader: 'network/isALeader',
      isACoLeader: 'network/isACoLeader',
      isAMember: 'network/isAMember',
      hasJoinedNetwork: 'network/hasJoinedNetwork',
      isInvited: 'network/isInvited',
      hasPendingRequest: 'network/hasPendingRequest',
    }),

    getNetworkRoleMessage() {
      if (this.isInvited) return i18n.tc('network-module.you-have-been-invited')
      if (this.hasPendingRequest) return i18n.tc('network-module.you-have-requested')
      if (this.isALeader) return i18n.tc('network-module.you-are-a-leader')
      if (this.isACoLeader) return i18n.tc('network-module.you-are-a-coleader')
      if (this.isAMember) return i18n.tc('network-module.you-are-a-member')

      return i18n.tc('network-module.you-have-not-joined-network')
    },
  },

}
</script>

<style scoped>
.col-sm-3{
  width: auto !important;
}
.col-sm-9{
  width: auto !important;
}
</style>
