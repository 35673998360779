<template>
  <b-card class="my-2">
    <b-row>
      <b-col md="12">
        <h3>{{ $t('essay-builder-module.body') }} {{ index }}</h3>
      </b-col>
      <b-col md="12">
        <validation-provider
          v-slot="{ errors }"
          :name="`${$t('essay-builder-module.main-points')} body ${index}`"
          rules="required|max:256"
          :vid="`main-points[${index}]`"
        >
          <b-form-group
            :label="`${$t('essay-builder-module.main-points')} ${index}`"
            label-for="main-points"
            class="position-relative"
          >
            <b-form-input
              v-model="data.main_point"
              :disabled="!isEditable"
            />
            <b-alert
              variant="success"
              :show="`MP_${index}` in hints"
              class="mt-1"
            >
              <div class="alert-body">
                <span>{{ hints[`MP_${index}`] }}</span>
              </div>
            </b-alert>
            <feather-icon
              v-if="isEditable"
              :icon="!fetchingHints.includes(`MP_${index}`) ? 'AlertCircleIcon':'LoaderIcon'"
              size="22"
              class="hint-icon text-primary"
              @click="$emit('getHint',null,{type:`MP`,order_index:index})"
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('essay-builder-module.evidence-or-examples')"
          label-for="evidence-or-examples"
        >
          <div class="pl-2">
            <div
              v-for="(key,ind) in data.evidences"
              :key="ind"
              class="d-flex align-content-center"
              style="margin-bottom:8px"
            >

              <div
                class="d-flex flex-column"
                style="flex:1"
              >
                <div
                  class="d-flex"
                >
                  <div
                    class="d-flex align-items-center"
                    style="width:20px"
                  >
                    <label for="no"> {{ ind+1 }}.</label>
                  </div>
                  <div
                    style="flex:1"
                    class="position-relative"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('essay-builder-module.evidence-or-examples')+' '+(ind+1)"
                      rules="required|max:256"
                      :vid="`evidence_[${index}]_[${ind+1}]`"
                    >
                      <b-form-input
                        v-model="data.evidences[ind]"
                        class="pr-3"
                        :disabled="!isEditable"
                      />

                      <feather-icon
                        v-if="isEditable"
                        :icon="!fetchingHints.includes(`ANS_${index+1}_${ind+1}`) ? 'AlertCircleIcon':'LoaderIcon'"
                        size="22"
                        class="hint-icon text-primary"
                        @click="$emit('getHint',(index+1),{type:`ANS`,order_index:(ind+1)})"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </div>
                <b-alert
                  variant="success"
                  class="mt-1"
                  :show="`ANS_${index+1}_${ind+1}` in hints"
                >
                  <div class="alert-body">
                    <span>{{ hints[`ANS_${index+1}_${ind+1}`] }}</span>
                  </div>
                </b-alert>
              </div>
            </div>
          </div>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <validation-provider
          v-slot="{ errors }"
          :name="`${$t('essay-builder-module.connect-to-thesis')} of ${index}`"
          rules="required|max:256"
          :vid="`connect-to-thesis_${index}`"
        >
          <b-form-group
            :label="$t('essay-builder-module.connect-to-thesis')"
            label-for="connect-to-thesis"
            class="position-relative"
          >
            <b-form-input
              v-model="data.connect_to_thesis"
              :disabled="!isEditable"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="12">

        <validation-provider
          v-slot="{ errors }"
          :name="$t('essay-builder-module.transition-sentence')"
          rules="required|max:256"
          :vid="`transition-sentence[${index}]`"
        >
          <b-form-group
            :label="$t('essay-builder-module.transition-sentence')"
            label-for="transition-sentence"
            class="position-relative"
          >
            <b-form-input
              v-model="data.transition_sentence"
              :disabled="!isEditable"
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCol, BRow, BCard, BFormGroup, BFormInput, BAlert,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full'

export default {
  components: {
    BCol, BRow, ValidationProvider, BCard, BFormGroup, BFormInput, BAlert,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    hints: {
      type: Object,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
    fetchingHints: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style>

</style>
