<template>
  <!-- <div class="card card-statistics mb-1">
    <div class="card-header p-1"
         style="cursor: pointer;"
         @click="showStaticBody = !showStaticBody"
    >
      <h4 class="card-title w-100">
        {{ $t('users-module.statistics') }}:
        {{ capitalizeFirstLetter(studentInfo.firstname) }} {{ capitalizeFirstLetter(studentInfo.lastname) }}

        <span>
          <feather-icon
            :icon="showStaticBody ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            size="24"
          />
        </span>
        <span
          class="statistic-date"
        >
          <feather-icon icon="CalendarIcon" /> {{ $t('messages.tableHeader.date') }}: {{ today }}
        </span>
      </h4>
    </div>
    <div
      class="card-body statistics-body"
    >
      <div class="row">
        <div class="mb-2 mb-md-0 col-sm-6 col-md-3 mb-2 mb-xl-0">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <span
                class="b-avatar badge-light-primary rounded-circle"
                style="width: 48px; height: 48px"
              ><span class="b-avatar-custom">
                <feather-icon icon="TrendingUpIcon" />
              </span>
              </span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ statisticsData.consecutive_days }}
              </h4>
              <p class="card-text font-small-3 mb-0">
                {{ $t('users-module.consecutive-study-days') }}
              </p>
            </div>
          </div>
        </div>
        <div class="mb-2 mb-md-0 col-sm-6 col-md-3 mb-2 mb-xl-0">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <span
                class="b-avatar badge-light-primary rounded-circle"
                style="width: 48px; height: 48px"
              ><span class="b-avatar-custom">
                <feather-icon icon="ClockIcon" />
              </span>
              </span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ CONVERT_HM(statisticsData.total_study_time) }}
              </h4>
              <p class="card-text font-small-3 mb-0">
                {{ $t('users-module.total-study-hours') }}
              </p>
            </div>
          </div>
        </div>
        <div class="mb-2 mb-md-0 col-sm-6 col-md-3 mb-2 mb-xl-0">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <span
                class="b-avatar badge-light-primary rounded-circle"
                style="width: 48px; height: 48px"
              ><span class="b-avatar-custom">
                <feather-icon icon="ClockIcon" />
              </span>
              </span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ CONVERT_HM(statisticsData.average_study_time) }}
              </h4>
              <p class="card-text font-small-3 mb-0">
                {{ $t('users-module.average-study-hours') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <b-card
    no-body
    class="card-statistics student-statistics"
  >
    <b-card-body>
      <b-row>
        <b-col>
          <div class="d-md-flex">
            <div class="media-aside mr-2 align-self-start">
              <span
                class="b-avatar badge-light-primary rounded-circle stat-avatar"
              ><span class="b-avatar-custom">
                <feather-icon icon="TrendingUpIcon" />
              </span>
              </span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ statisticsData.consecutive_days }}
              </h4>
              <p class="card-text  mb-0">
                {{ $t('users-module.consecutive-study-days') }}
              </p>
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="d-md-flex">
            <div class="media-aside mr-2 align-self-start">
              <span
                class="b-avatar badge-light-primary rounded-circle stat-avatar"
              ><span class="b-avatar-custom">
                <feather-icon icon="ClockIcon" />
              </span>
              </span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ CONVERT_HM(statisticsData.total_study_time) }}
              </h4>
              <p class="card-text mb-0">
                {{ $t('users-module.total-study-hours') }}
              </p>
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="d-md-flex">
            <div class="media-aside mr-2 align-self-start">
              <span
                class="b-avatar badge-light-primary rounded-circle stat-avatar"
              ><span class="b-avatar-custom">
                <feather-icon icon="ClockIcon" />
              </span>
              </span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ CONVERT_HM(statisticsData.average_study_time) }}
              </h4>
              <p class="card-text mb-0">
                {{ $t('users-module.average-study-hours') }}
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
  },
  props: {
    statisticsData: {
      type: Object,
      default: () => null,
    },
    // today: {
    //   type: String,
    //   default: '',
    // },
    // studentInfo: {
    //   type: Object,
    //   default: () => null,
    // },
  },
  data() {
    return {
      showStaticBody: false,
    }
  },
  methods: {
    secondToHours(seconds) {
      return seconds / (60 * 60)
    },
  },
}
</script>
<style scoped>
.stat-avatar {
  width: 48px;
  height: 48px;
}
@media(max-width:768px) {
  .stat-avatar {
    width: 20px;
    height: 20px;
  }
  .student-statistics .card-body{
    padding:15px;
  }
  .student-statistics .media-body h4 {
    font-size: 13px;
  }
  .student-statistics .media-body p {
    font-size: 11px;
    line-height: 1.2;
    height: 26px;
    overflow: hidden;
  }
}
</style>
