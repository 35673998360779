<template>
  <div>
    <b-row>
      <b-col md="9">
        <b-form-group
          label="Question"
        >
          <b-form-input
            v-model="question"
            placeholder="Type Your Question Here"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-for="(answer, index) in answers"
           :key="index"
    >
      <b-col md="6">
        <b-form-group
          :label="$t('labels.create-problem.answer')"
          label-for="answer"
        >
          <b-form-input
            v-model="answer.text"
            name="answer"
            :placeholder="$t('labels.create-problem.placeholder.answer')"
            @change="$emit('updateAnswer', answer)"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="2"
        style="margin-top:28px"
      >
        <BFormCheckbox
          v-model="answer.is_correct"
          name="checkbox-1"
          :value="1"
          @change="$emit('updateAnswer', answer)"
        >{{ $t('labels.create-problem.is-correct') }}</BFormCheckbox>
      </b-col>
      <b-col v-if="index > 0"
             md="1"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip="`Delete Question`"
          variant="outline-danger"
          class="btn-icon"
          style="margin-top:24px"
          @click="askToDelete(index)"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-button
          v-b-tooltip="`Add More Answer`"
          variant="outline-primary"
          class="btn-icon"
          @click="addAnswer"
        >
          <feather-icon icon="PlusIcon" />
          {{ $t("Add-New") }}
        </b-button>
      </b-col>
    </b-row>
    <confirm-delete
      :show="!!deleteAnswerIndex"
      :sub-title="$t('questions.delete-answer')"
      :on-delete="deleteQuestion"
    />
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import ConfirmDelete from '@/views/common/components/DeleteModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    ConfirmDelete,
  },
  props: {
    type: {
      type: String,
      default: 'create',
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    initialQuestion: {
      type: Array,
      default: () => [],
    },
    level: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      question: '',
      questionId: null,
      answers: [
        {
          text: '',
          is_correct: false,
        },
      ],
      deleteAnswerIndex: null,
    }
  },
  computed: {
    isEditMode() {
      return this.type === 'edit'
    },
  },
  mounted() {
    this.setData()
  },
  methods: {
    addAnswer() {
      if (this.isEditMode) {
        this.$emit('addMoreAnswer', this.questionId)
      } else {
        this.answers.push({
          text: '',
          is_correct: 0,
        })
      }
    },
    askToDelete(index) {
      this.deleteAnswerIndex = index
    },
    deleteQuestion() {
      this.answers.splice(this.deleteAnswerIndex, 1)
      this.showDelete = true
    },
    setData() {
      if (this.isEditMode) {
        this.questionId = this.initialQuestion[0]?.id
        this.question = this.initialQuestion[0]?.text
        this.answers = this.initialQuestion[0].answers.map(item => ({
          answerId: item.id,
          text: item.text,
          is_correct: item.is_correct,
          langLevel: this.initialQuestion[0]?.lang_level,
        }))
      }
    },
    getData() {
      return {
        questionAnswers: {
          question: this.question,
          answers: this.answers,
        },
        problemType: 'multiple',
      }
    },
  },

}
</script>
