<template>
  <b-overlay :show="isProcessing">
    <filter-view :courses="coursesInfo"
                 :filters="filters"
                 :districts="districts"
                 :is-processing="isProcessing"
                 @filter="getLessonLink(null, 1)"
    />
    <div class="text-right">
      <b-button
        variant="primary"
        @click="showAddUpdateModel = true"
      >
        <feather-icon icon="PlusIcon" /> {{ $t('page-link-module.add-page-link') }}
      </b-button>
    </div>
    <add-update-link :show="showAddUpdateModel || !!linkToEdit"
                     :courses="coursesInfo"
                     :districts="districts"
                     :link-to-edit="linkToEdit"
                     @close="showAddUpdateModel = false;linkToEdit = null"
                     @reFetch="getLessonLink"
    />
    <link-lists :items="linksLists"
                @onEdit="handleEdit"
                @onDelete="item => linkToDelete = item"
    />
    <confirm-delete
      :show="!!linkToDelete"
      name="link-delete"
      :on-delete="handleDelete"
      :is-processing-delete="isDeleting"
      :title="$t('delete')"
      :sub-title="$t('page-link-module.delete-confirmation')"
      @close="linkToDelete = null"
    />
    <pagination :pagination="{
                  total: totalItems,
                  per_page: perPage,
                  currentPage: currentPage
                }"
                :is-processing="isProcessing"
                @onPaginationChange="page => getLessonLink(null, page)"
    />
  </b-overlay>
</template>
<script>
import { BButton, BOverlay } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ConfirmDelete from '@/views/common/components/DeleteModal.vue'
import Pagination from '@/views/common/components/Pagination.vue'
import AddUpdateLink from './components/add-update.vue'
import LinkLists from './components/LinksLists.vue'
import FilterView from './components/filter-view.vue'

export default {
  components: {
    BButton,
    AddUpdateLink,
    LinkLists,
    ConfirmDelete,
    BOverlay,
    FilterView,
    Pagination,
  },
  data() {
    return {
      showAddUpdateModel: false,
      coursesInfo: [],
      districts: [],
      linksLists: [],
      linkToEdit: null,
      isProcessing: false,
      linkToDelete: null,
      isDeleting: false,
      totalItems: 0,
      perPage: 0,
      currentPage: 1,
      filters: {
        linkName: '',
        selectedDistricts: [],
        selectedCourse: null,
        selectedLessonGroup: null,
        selectedLesson: null,
      },
    }
  },
  mounted() {
    this.getLessonLink(true)
  },
  methods: {
    handleEdit(item) {
      this.linkToEdit = item
    },
    handleDelete() {
      this.isDeleting = true
      useJwt.deleteLessonLinks(this.linkToDelete.id).then(response => {
        this.showSuccessMessage(response)
        this.getLessonLink()
        this.linkToDelete = null
      }).finally(() => {
        this.isDeleting = false
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    getLessonLink(withCourse = null, page = 1) {
      this.isProcessing = true
      useJwt.getLessonLinks({
        params: {
          with_course: withCourse,
          page,
          ...this.filters,
        },
      }).then(response => {
        if (withCourse) {
          this.coursesInfo = response.data.data.courses_info
          this.districts = response.data.data.districts
        }
        this.linksLists = response.data.data.data.data
        this.totalItems = response.data.data.data.total
        this.perPage = response.data.data.data.per_page
        this.currentPage = response.data.data.data.current_page
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
