<template>
  <b-modal :visible="true"
           size="xl"
           :title="$t('curriculum-pages-module.mismatched-problem')"
           ok-only
           ok-variant="primary"
           :ok-title="$t('ok')"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <b-overlay :show="isProcessing"
               class="table-responsive"
    >
      <table class="table">
        <thead>
          <tr class="text-center">
            <th>{{ $t('curriculum-pages-module.mismatch-table.name') }}</th>
            <th>{{ $t('curriculum-pages-module.mismatch-table.reading') }}</th>
            <th>{{ $t('curriculum-pages-module.mismatch-table.listening') }}</th>
            <th>Pronunciation</th>
            <th>OE-Speaking</th>
            <th>Typing</th>
            <th>OE-Writing</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="mp,index of mismatchedProblems"
              :key="index"
              :set="data = mp.data"
              class="text-center"
          >
            <td>
              {{ mp.name }}
            </td>
            <td :set="count = data.find(item => item.skill_type === 'reading')?.count ">
              <span :class="count < 15 && 'text-danger font-weight-bold' ">
                {{ count }}
              </span>
            </td>
            <td :set="count = data.find(item => item.skill_type === 'listening')?.count ">
              <span :class="count < 15 && 'text-danger font-weight-bold' ">
                {{ count }}
              </span>
            </td>
            <td :set="count = data.find(item => item.skill_type === 'p_speech')?.count ">
              <span :class="count < 15 && 'text-danger font-weight-bold' ">
                {{ count }}
              </span>
            </td>
            <td :set="count = data.find(item => item.skill_type === 'speaking')?.count ">
              <span :class="count < 15 && 'text-danger font-weight-bold' ">
                {{ count }}
              </span>
            </td>
            <td :set="count = data.find(item => item.skill_type === 'p_writing')?.count ">
              <span :class="count < 15 && 'text-danger font-weight-bold' ">
                {{ count }}
              </span>
            </td>
            <td :set="count = data.find(item => item.skill_type === 'writing')?.count ">
              <span :class="count < 15 && 'text-danger font-weight-bold' ">
                {{ count }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </b-overlay>
  </b-modal>

</template>
<script>
import { BModal, BOverlay } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BModal,
    BOverlay,
  },
  props: {
    selectedLibrary: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mismatchedProblems: [],
      isProcessing: false,
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.isProcessing = true
      useJwt.getMismatchedCbProblems({
        params: {
          library_id: this.selectedLibrary > 0 ? this.selectedLibrary : null,
        },
      }).then(response => {
        this.mismatchedProblems = response.data.data
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
