export default {
  namespaced: true,
  actions: {
    onUserLogin({ dispatch }) {
      dispatch('studentInteractions/init', {}, { root: true })
      dispatch('studentNotes/updateUser', {}, { root: true })
    },
    onUserLogout({ dispatch }) {
      dispatch('studentInteractions/dispose', {}, { root: true })
      dispatch('studentNotes/updateUser', {}, { root: true })
    },
  },
}
