var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticClass:"w-100",class:{
    'active':_vm.isActive
  },attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"mb-0 pb-0"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('adaptive-learning-module.problem'))+" "+_vm._s(_vm.index+1)+" ")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},_vm._l((_vm.levels),function(level){return _c('div',{key:level.text,staticClass:"transaction-item"},[_c('div',{staticClass:"font-weight-bolder level",class:{
              'active':_vm.isActive && level.index === _vm.activeLevel,
              'passed':_vm.actions[level.index] && _vm.actions[level.index].result==='pass',
              'failed':_vm.actions[level.index] && _vm.actions[level.index].result==='fail',
            }},[_vm._v(" "+_vm._s(level.text)+" "),(_vm.isLastProblem && _vm.activeLevel===level.index)?_c('div',{staticClass:"icon-direction completed"},[_c('feather-icon',{staticClass:"completed",staticStyle:{"color":"#e1b12c"},attrs:{"size":"40","icon":"CheckIcon"}})],1):_vm._e(),(level.index in _vm.actions)?_c('div',{staticClass:"icon-direction",class:_vm.actions[level.index].icon},[_c('feather-icon',{class:{
                  'text-success':_vm.actions[level.index].result==='pass',
                  'text-danger':_vm.actions[level.index].result==='fail',
                },attrs:{"size":"40","icon":_vm.actions[level.index].icon}})],1):_vm._e()])])}),0)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }