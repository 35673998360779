<template>
  <b-card v-if="noteId">
    <h5>Comments</h5>
    <b-overlay :show="isGettingNotes">
      <vue-perfect-scrollbar
        class="comment-box"
        :settings="{
          maxScrollbarLength: 60,
          wheelPropagation: false,
          suppressScrollX: false,
        }"
        @ps-y-reach-end="loadMore"
      >
        <div
          v-for="comment of commentList"
          :key="comment.id"
          class="profile-twitter-feed mt-1"
        >
          <div class="d-flex justify-content-between mb-1">
            <div class="d-flex">
              <b-avatar
                v-if="comment.avatar"
                size="sm"
                :src="comment.avatar"
                class="mr-1"
              />
              <b-avatar
                v-else
                :text="comment.userName.charAt(0)"
                variant="light-primary"
                class="mr-1"
              />

              <div class="profile-user-info">
                <h6 class="mb-0">
                  {{ comment.userName }}
                </h6>
                <span><small class="text-muted">
                  <feather-icon icon="ClockIcon" />
                  {{ FROM_NOW(comment.created_at) }}
                </small></span>
              </div>
            </div>
            <div v-if="self.id == comment.user_id">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                dropleft
                class="p-0"
              >
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" />
                </template>
                <b-dropdown-item @click="deleteComment(comment.id)">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  {{ $t('actions.delete') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <p class="card-text mb-50">
            {{ comment.comment }}
          </p>
        </div>
      </vue-perfect-scrollbar>
    </b-overlay>
    <div class="mt-2">
      <b-form-textarea
        v-model="newComment"
        placeholder="Enter a comment."
        rows="8"
      />
      <div class="mt-2">
        <b-button
          :disabled="isProcessing || !canComment"
          variant="primary"
          @click="addComment"
        >
          <b-spinner
            v-show="isProcessing"
            small
            class="mr-2"
          /> {{ $t('student-notes-module.comment') }}
        </b-button>
      </div>
    </div>
  </b-card>
</template>
<script>
import {
  BCard, BFormTextarea, BButton, BSpinner, BAvatar, BOverlay, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BFormTextarea,
    BButton,
    BSpinner,
    BAvatar,
    BOverlay,
    BDropdown,
    BDropdownItem,
    VuePerfectScrollbar,
  },
  props: {
    noteId: {
      type: [Number],
      default: 0,
    },
  },
  data() {
    return {
      newComment: '',
      isProcessing: false,
      isGettingNotes: false,
      commentList: [],
      page: 1,
      hasNextPage: true,
      self: getUserData(),
    }
  },
  computed: {
    canComment() {
      return !!this.newComment
    },
  },
  watch: {
    noteId(newValue) {
      if (newValue) {
        this.getNotes()
      }
    },
  },
  methods: {
    getNotes(page = 1) {
      this.isGettingNotes = true
      if (page === 1) {
        this.commentList = []
      }
      useJwt.getNotesComment({
        params: {
          note_id: this.noteId,
          page,
        },
      }).then(response => {
        const { data } = response
        const commentList = data.data.comments.data.map(comment => ({
          ...comment,
          userName: `${comment.user?.firstname} ${comment.user?.lastname}`,
          avatar: comment.user?.avatar,
        }))
        this.commentList = [...this.commentList, ...commentList]
        this.page = data.data.comments.current_page
        if (!data.data.comments.next_page_url) {
          this.hasNextPage = false
        }
      }).catch(error => {
        console.error(error)
        this.showErrorMessage(error)
      }).finally(() => {
        this.isGettingNotes = false
      })
    },
    deleteComment(id) {
      this.isGettingNotes = true
      useJwt.deleteNoteComment(id).then(response => {
        this.showSuccessMessage(response)
        this.getNotes()
        this.isGettingNotes = false
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isGettingNotes = false
      })
    },
    addComment() {
      this.isProcessing = true
      useJwt.postNotesComment({
        note_id: this.noteId,
        comment: this.newComment,
      }).then(response => {
        this.showSuccessMessage(response)
        this.getNotes()
        this.newComment = ''
      }).catch(error => {
        this.showErrorMessage(error)
        console.error(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    loadMore() {
      if (!this.isGettingNotes && this.hasNextPage) {
        setTimeout(() => {
          this.getNotes(this.page + 1)
        }, 1500)
      }
    },
  },
}
</script>
<style scoped>
  .comment-box {
    max-height: 50vh;
    overflow-y: scroll;
  }
</style>
