<template>
  <!-- Problem -->
  <b-col md="12">
    <b-tabs
      pills
      align="center"
      class="lang-level-tabs"
    >
      <b-tab
        v-for="(level, index) in levels"
        :key="index + level"
        :title="getLangLevelTransText(level)"
        :title-item-class="showLangLevel ? '' : 'd-none'"
        :active="activeTabIndex === index"
        @click="tabChange(index)"
      >
        <b-row>

          <b-col
            md="12"
            :lg="problemGroupType === 'video' ? 12:4"
            class="p-0 pr-1"
          >
            <b-card>
              <div v-if="problemGroupType==='hot-spot'">
                <HotSpotStatementFeedbackForm
                  :key="index"
                  :index="index"
                />
              </div>
              <statement-feedback-form
                v-else
                :level="index"
                :video-type="problemGroupType === 'video'"
              />
            </b-card>
          </b-col>
          <b-col
            class="p-0"
          >
            <b-card>
              <cefr-form :level="index" />
              <b-form-group label="Answer Type">
                <b-form-select
                  v-model="answerType"
                  :options="questionTypeOptions"
                  :value="'text'"
                  @change="updateAnswerType"
                />
              </b-form-group>
              <category-create
                ref="categoryCreate"
                :level="index"
                :answer-type="answerType"
              />
            </b-card>
            <b-card>
              <b-col md="12">
                <b-row>
                  <b-col
                    md="6"
                    sm="12"
                    xs="12"
                    class="mb-2 md-mb-0 d-flex justify-content-start"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-success"
                      class="mr-2"
                      @click="
                        $emit(
                          'showQuestionPreview',
                          getDataForQuestionPreview()
                        )
                      "
                    >
                      {{ $t("actions.preview") }}
                    </b-button>
                  </b-col>
                  <b-col
                    md="6"
                    sm="6"
                    xs="12"
                    class="d-flex justify-content-end"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="mr-2"
                      @click="
                        $router.push({
                          path: '/super/problem',
                          query: { lesson_id: $route.query.lesson_id },
                        })
                      "
                    >
                      {{ $t("actions.back") }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      :disabled="isProcessing"
                      @click="createQuestions"
                    >
                      <b-spinner
                        v-show="isProcessing"
                        small
                      />
                      {{ $t("actions.create-problem") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </b-col>
</template>

<script>
import {
  BCol, BRow, BTab, BTabs, BCard, BButton, BSpinner, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import StatementFeedbackForm from '../../components/create/StatementFeedback.vue'
import CefrForm from '../../components/create/Cefr.vue'
import CategoryCreate from '../../components/create/Category.vue'
import HotSpotStatementFeedbackForm from '../../components/create/HotSpotStatementFeedback.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    BButton,
    BSpinner,
    StatementFeedbackForm,
    CefrForm,
    CategoryCreate,
    BFormGroup,
    BFormSelect,
    HotSpotStatementFeedbackForm,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    questions: {
      text: [[], [], [], []],
      image: [[], [], [], []],
      imageUrl: [[], [], [], []],
      audio: [[], [], [], []],
      audioUrl: [[], [], [], []],
    },
    answers: {
      text: [[''], [''], [''], ['']],
      image: [[''], [''], [''], ['']],
      imageUrl: [[''], [''], [''], ['']],
      audio: [[''], [''], [''], ['']],
      audioUrl: [[''], [''], [''], ['']],
    },
    isProcessing: false,
    questionValid: [false, false, false, false],
    isQuestionValid: false,
    deleteIndex: null,
    questionTypeOptions: [
      { text: 'Text', value: 'text' },
      { text: 'Audio', value: 'audio' },
      { text: 'Image', value: 'image' },
    ],
    answerType: 'text',
  }),
  computed: {
    problemGroupType() {
      return this.$store.state.problem.create.problemGroupType
    },
    problemType() {
      return this.$store.state.problem.activeProblemType
    },
    activeTabIndex: {
      get() {
        return this.$store.state.problem.activeTabIndex
      },
      set(value) {
        this.$store.commit('problem/SET_ACTIVE_TAB_INDEX', value)
      },
    },
    isValid() {
      return this.$store.getters['problem/isValid']
    },
    isLevelValid() {
      return this.$store.getters['problem/isLevelValid']
    },
    showLangLevel() {
      return this.$store.state.problem.showLangLevel
    },
    levels() {
      let levelTemp = this.$store.state.problem.levels
      if (!this.showLangLevel) levelTemp = [levelTemp[0]]
      return levelTemp
    },

    statement: {
      get() {
        return this.$store.state.problem.create.statement
      },
      set(value) {
        this.$store.commit('problem/SET_STATEMENT_FIELD', value)
      },
    },
  },
  created() {
    if (!this.showLangLevel) this.activeTabIndex = 0
  },
  methods: {
    updateAnswerType() {
      this.$store.state.problem.create.answerType = this.answerType
    },
    tabChange(level) {
      this.activeTabIndex = level
    },
    setQuestions(questions, index) {
      const keys = Object.keys(this.questions)
      keys.forEach(k => {
        this.questions[k][index] = questions[k]
      })
    },
    setAnswers(answers, index) {
      const keys = Object.keys(this.answers)
      keys.forEach(k => {
        this.answers[k][index] = answers[k]
      })
    },
    deleteQA(index) {
      this.deleteIndex = index
    },
    transformInToFormObject(data) {
      /* eslint-disable */
      let formData = new FormData()
      for (let key in data) {
        if (Array.isArray(data[key])) {
          data[key].forEach((obj, index) => {
            let keyList = Object.keys(obj);
            keyList.forEach((keyItem) => {
              let keyName = ['category','[',key,']', "[", index, "]", "[", keyItem,']'].join("");
              if (keyItem == 'images' || keyItem == 'audio' || keyItem === 'answers' ) {
                obj[keyItem].forEach((ans, ansIndex) => {
                  formData.append(`${keyName}[${ansIndex}]`, ans ?? '')
                })
              } else {
                formData.append(keyName, obj[keyItem]);
              }
            });
          });
        } else if (typeof data[key] === "object") { 
          for (let innerKey in data[key]) {
            formData.append(`${key}.${innerKey}`, data[key][innerKey]);
          }
        } else {
          formData.append(key, data[key]);
        }
      }
      return formData
    },
    createFile(blobUrl){
      const getConvertedAudio = async ()  => {
        let response = await fetch(blobUrl);
        let data = await response.blob();
        let metadata = {
          type: 'audio/mp3'
        };
        return new File([data], "category_audio.mp3", metadata);
      }
      const audio = getConvertedAudio()
      return audio
    },
    async dataURLtoFile(dataurl, filename) {
      try {
         var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
      } catch(err) {
        const audio = this.createFile(dataurl)
        return audio
      }
      const extension = dataurl.substring("data:image/".length, dataurl.indexOf(";base64"))
      return new File([u8arr], filename+'.'+extension, {type:mime});
    },
    async decodeRequestIntoFiles() {
      let categoryData = this.$store.state.problem.create.category.items
      if (!this.$store.state.problem.showLangLevel) {
        categoryData = [categoryData[0]]
      }
      const allData = categoryData.map(async (datas)=> {
        const toAppend = await Promise.all(datas.map(async data => {
          let answers  = data.answers
          let images = data.images 
          let audio = data.audio
          if (this.answerType === 'image') {
            images = await Promise.all(data.images.map(async ans =>  { 
             const files = await this.dataURLtoFile(ans, 'category_image') 
             return files
            }))
          } else if (this.answerType === 'audio') {
            audio = await Promise.all(data.audio.map(async ans =>  { 
             const files = await this.dataURLtoFile(ans, 'category_audio') 
             return files
            }))
          }
          return {
            ...data,
            answers,
            images,
            audio
          }
        }))
        return toAppend
      })
      return await Promise.all(allData)
    },
    async createQuestions(e) {
      e.preventDefault()
      if (this.$refs.categoryCreate) {
        for (let form of  this.$refs.categoryCreate) {
          const isValid = await form.$refs?.categoryForm?.validate()
          if (!isValid) {
            this.showError('Please fill all the required fields.')
            return 
          }
        }
      }
      const finalizedData = await  this.decodeRequestIntoFiles()
      this.$store.commit('problem/SET_CATEGORY_FINAL_FORM_DATA', this.transformInToFormObject(finalizedData))
      if (!this.isValid) {
        this.$store.commit('problem/SHOW_ERROR')
        // for 4 language levels
        if (this.showLangLevel) {
          if (this.isLevelValid.includes(false)) {
            const levels = ['A1', 'A2', 'B1', 'B2']
            this.isLevelValid.forEach((l, i) => {
              if (!l) this.showError(`Please fill all the required fields in level ${levels[i]}`)
            })
          }
        } else if (!this.isLevelValid[0]) { // without language level
          this.showError('Please fill all the required fields.')
        }
      } else {
        // TODO: this.isProcessing = true
        /* eslint-disable */
        // this.$store.commit('problem/SET_CATEGORY_FINAL_FORM_DATA', )
        this.isProcessing = true
        this.$store.dispatch('problem/createQuestions')
          .then(response => {
            this.showSuccessMessage(response)
            if (this.problemGroupType === 'text') {
               this.$root.$emit('onProblemCreate', {
                lesson_id: this.$route.query.lesson_id,
                problem_id: response.data.data.id,
              })
            } if (this.problemGroupType === 'video') {
              this.$store.commit('problem/UPDATE_TIMESTAMP', Number(response.data.data.video_timestamp_id))
            }
            this.$emit('questionCreated', response.data.data.id)
          })
          .catch(error => {
            this.showErrorMessage(error)
          })
          .finally(() => {
            this.isProcessing = false
            if (this.problemGroupType === 'video') this.$store.dispatch('problem/getCefrSubject')
          })
      }
    },
    getPreviewQuestion() {
      const questionsToPreviews = []
      const categories = this.$store.state.problem.create.category.items[this.activeTabIndex]
      categories.forEach((category, index) => {
        questionsToPreviews.push({
          id: index,
          answers: category.answers.map((ans,index) => ({
            id: Math.random(), text: ans, data_type: this.answerType, image: category.images[index], audio: category.audio[index]
          })),
          text: category.question,
          file: '',
          data_type: this.answerType,
        })
      })
      return questionsToPreviews
    },
    getDataForQuestionPreview() {
      const createState = this.$store.state.problem.create
      const level = this.activeTabIndex
      return {
        problemType: 'category',
        questionType: createState.questionType,
        answerType: createState.answerType,
        theme: createState.theme,
        feedback: {
          type: createState.fbType[level],
          text: createState.feedback[level],
          image: createState.fbType[level] === 'image' ? createState.feedbackImage.url[level] : null,
          audio: createState.fbType[level] === 'audio' ? createState.feedbackAudio.url[level] : null,
          template: createState.feedbackTemplate[level],
        },
        statement: {
          type: createState.stType[level],
          text: createState.statement[level],
          image: createState.stType[level] === 'image' ? createState.statementImage.url[level] : null,
          audio: createState.stType[level] === 'audio' ? createState.statementAudio.url[level] : null,
          template: createState.statementTemplate[level],
        },
        questions: this.getPreviewQuestion(),
        level: this.activeTabIndex + 1,
      }
      // alert('ss')
      // return this.$store.getters['problem/previewData']
    },
  },
}
</script>
