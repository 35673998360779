<template>
  <b-modal
    :title="partnerInfo ? $t('partner-module.update'):$t('partner-module.add')"
    :visible="show"
    size="lg"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <validation-observer ref="createItemForm">
      <b-form @submit.prevent>
        <b-row>
          <b-col>
            <b-form-group
              :label="$t('partner-module.partner-logo')"
              label-for="tem-name"
            >
              <validation-provider
                v-slot="{ errors }"
                name="image|size:800"
                :custom-messages="{ image: $t('messages.invalid-image') }"
                vid="image"
                :rules="!partnerInfo && 'required'"
              >
                <!-- media -->
                <b-media no-body>
                  <b-media-aside>

                    <avatar
                      size="50px"
                      :rounded="true"
                      :image="logoUrl"
                    />
                    <!--/ avatar -->
                  </b-media-aside>

                  <b-media-body class="ml-50">
                    <!-- Plain mode -->
                    <div>
                      <b-form-file
                        ref="refInputEl1"
                        v-model="brandImage"
                        :browse-text="$t('browseButton')"
                        :placeholder="$t('issue-report-module.choose-a-file')"
                        class="mb-1"
                        accept="image/*"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </div>
                    <div v-if="!brandImage && errors.length==0">
                      <b-card-text> {{ $t('account-setting-general.allowed') }} </b-card-text>
                    </div>
                  </b-media-body>
                </b-media>
                <!--/ media -->
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :label="$t('partner-module.fav-icon')"
              label-for="tem-name"
            >
              <validation-provider
                v-slot="{ errors }"
                name="image|size:800"
                :custom-messages="{ image: $t('messages.invalid-image') }"
                vid="image"
                :rules="!partnerInfo && 'required'"
              >
                <!-- media -->
                <b-media no-body>
                  <b-media-aside>

                    <avatar
                      size="50px"
                      :rounded="true"
                      :image="favIconUrl"
                    />
                    <!--/ avatar -->
                  </b-media-aside>

                  <b-media-body class="ml-50">
                    <!-- Plain mode -->
                    <div>
                      <b-form-file
                        ref="refInputEl1"
                        v-model="favIcon"
                        :browse-text="$t('browseButton')"
                        :placeholder="$t('issue-report-module.choose-a-file')"
                        class="mb-1"
                        accept="image/*"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </div>
                    <div v-if="!favIcon && errors.length==0">
                      <b-card-text> {{ $t('account-setting-general.allowed') }} </b-card-text>
                    </div>
                  </b-media-body>
                </b-media>
                <!--/ media -->
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :label="$t('partner-module.nav-icon')"
              label-for="tem-name"
            >
              <validation-provider
                v-slot="{ errors }"
                name="image|size:800"
                :custom-messages="{ image: $t('messages.invalid-image') }"
                vid="image"
                :rules="!partnerInfo && 'required'"
              >
                <!-- media -->
                <b-media no-body>
                  <b-media-aside>

                    <avatar
                      size="50px"
                      :rounded="true"
                      :image="navIconUrl"
                    />
                    <!--/ avatar -->
                  </b-media-aside>

                  <b-media-body class="ml-50">
                    <!-- Plain mode -->
                    <div>
                      <b-form-file
                        ref="refInputEl1"
                        v-model="navIcon"
                        :browse-text="$t('browseButton')"
                        :placeholder="$t('issue-report-module.choose-a-file')"
                        class="mb-1"
                        accept="image/*"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </div>
                    <div v-if="!navIcon && errors.length==0">
                      <b-card-text> {{ $t('account-setting-general.allowed') }} </b-card-text>
                    </div>
                  </b-media-body>
                </b-media>
                <!--/ media -->
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group
          :label="$t('partner-module.enabled')"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Enabled"
          >
            <b-form-checkbox
              v-model="partner.enabled"
              :value="1"
              switch
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group>
          <template #label>
            <div class="d-flex justify-content-between">
              <div>{{ $t('partner-module.company-token') }}</div>
              <div>
                <b-button
                  variant="flat-secondary"
                  class="btn-icon"
                >
                  <feather-icon
                    icon="RefreshCcwIcon"
                    @click="reGenerateToken"
                  />
                </b-button>
              </div>
            </div>
          </template>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Client token"
          >
            <b-form-input
              v-model="partner.client_token"
              readonly
              :placeholder="$t('partner-module.company-token')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('partner-module.company-name')"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Company Name"
          >
            <b-form-input
              v-model="partner.name"
              :placeholder="$t('partner-module.company-name')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('partner-module.company-domain')"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Company Domain"
          >
            <b-form-input
              v-model="partner.domain"
              :placeholder="$t('partner-module.company-domain')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('partner-module.company-email')"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required|email"
            name="Company Email"
          >
            <b-form-input
              v-model="partner.email"
              :placeholder="$t('partner-module.company-email')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('partner-module.mail-to-send-email')"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="email"
            name="Mail to send Email"
          >
            <b-form-input
              v-model="partner.mail_email"
              :placeholder="$t('partner-module.mail-to-send-email')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('partner-module.partnership-date')"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Partnership Date"
          >
            <b-form-datepicker
              v-model="partner.partnership_date"
              :placeholder="$t('partner-module.partnership-date')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('partner-module.cms-domain')"
        >
          <validation-provider
            v-slot="{ errors }"
            name="CMS Domain"
          >
            <b-form-input
              v-model="partner.cms_domain"
              :placeholder="$t('partner-module.cms-domain')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('partner-module.address')"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Company Address"
          >
            <b-form-input
              v-model="partner.address"
              :placeholder="$t('partner-module.address')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('partner-module.slogan')"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Slogan"
          >
            <b-form-input
              v-model="partner.slogan"
              :placeholder="$t('partner-module.slogan')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="`Default Language`"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Locale"
          >
            <b-form-select
              v-model="partner.default_locale"
              :placeholder="`Default Language`"
              :options="locales.map(locale => ({ value: locale.locale, text: locale.name }))"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
    <template #modal-footer="{ }">
      <b-button @click="$emit('close')">
        {{ $t('actions.cancel') }}
      </b-button>
      <b-button
        v-if="partnerInfo"
        variant="primary"
        @click="updatePartner"
      >
        <b-spinner
          v-if="isSaving"
          small
        /> {{ $t('actions.update') }}
      </b-button>
      <b-button
        v-else
        variant="primary"
        @click="savePartner"
      >
        <b-spinner
          v-if="isSaving"
          small
        /> {{ $t('actions.save') }}
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal, BForm, BFormGroup, BFormInput, BMedia, BMediaBody, BFormFile, BCardText, BMediaAside, BFormDatepicker, BFormCheckbox, BRow, BCol,
  BButton, BSpinner, BFormSelect,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import Avatar from '@/views/common/components/Avatar.vue'
import { required, email } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
import locales from '@/const/locales'

const uuid = require('uuid')

const initPartnerInfo = () => ({
  name: '',
  client_token: uuid.v4(),
  address: '',
  partnership_date: '',
  slogan: '',
  email: '',
  domain: '',
  cms_domain: '',
  default_locale: 'en',
})

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BMedia,
    BMediaBody,
    BFormFile,
    BCardText,
    BMediaAside,
    BFormDatepicker,
    BFormCheckbox,
    BButton,
    BSpinner,
    BRow,
    BCol,
    Avatar,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    partnerInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      brandImage: null,
      favIcon: null,
      navIcon: null,
      required,
      logoUrl: null,
      favIconUrl: null,
      navIconUrl: null,
      partner: {
        ...initPartnerInfo(),
      },
      email,
      isSaving: false,
      locales,
    }
  },
  watch: {
    navIcon() {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.showPreview = true
          this.navIconUrl = reader.result
        },
        false,
      )
      if (this.navIcon) {
        if (/\.(jpe?g|png|gif)$/i.test(this.navIcon.name)) {
          reader.readAsDataURL(this.navIcon)
        }
      }
    },
    favIcon() {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.showPreview = true
          this.favIconUrl = reader.result
        },
        false,
      )
      if (this.favIcon) {
        if (/\.(jpe?g|png|gif)$/i.test(this.favIcon.name)) {
          reader.readAsDataURL(this.favIcon)
        }
      }
    },
    brandImage() {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.showPreview = true
          this.logoUrl = reader.result
        },
        false,
      )
      if (this.brandImage) {
        if (/\.(jpe?g|png|gif)$/i.test(this.brandImage.name)) {
          reader.readAsDataURL(this.brandImage)
        }
      }
    },
    partnerInfo(newValue) {
      if (newValue) {
        this.partner = {
          ...newValue,
        }
        this.logoUrl = newValue.logo
        this.navIconUrl = newValue.nav_icon
        this.favIconUrl = newValue.fav_icon
      } else {
        this.partner = {
          ...initPartnerInfo(),
        }
      }
    },
  },
  methods: {
    createFormData() {
      const formData = new FormData()
      Object.entries(this.partner).forEach(([key, value]) => {
        if (key === 'nav_icon' && this.navIcon) {
          formData.append('nav_icon', this.navIcon)
        } else if (key === 'fav_icon' && this.favIcon) {
          formData.append('fav_icon', this.favIcon)
        } else {
          formData.append(key, value)
        }
      })
      if (this.navIcon) {
        formData.append('nav_icon', this.navIcon)
      }
      if (this.favIcon) {
        formData.append('fav_icon', this.favIcon)
      }
      if (this.brandImage) {
        formData.append('logo', this.brandImage)
      }
      if (this.partner.enabled) {
        formData.append('enabled', 1)
      } else {
        formData.append('enabled', 0)
      }
      if (this.partnerInfo) {
        formData.append('_method', 'PUT')
      }
      return formData
    },
    showErrors(error) {
      const errors = error.response?.data?.errors
      if (errors) {
        Object.entries(errors).forEach(message => {
          this.showError(message.join(','))
        })
      } else {
        this.showErrorMessage(error)
      }
    },
    updatePartner() {
      this.$refs.createItemForm.validate().then(success => {
        if (success) {
          this.isSaving = true
          useJwt.updateGecPartner(this.partnerInfo.id, this.createFormData()).then(response => {
            this.showSuccessMessage(response)
            this.$emit('reFetch')
            this.$emit('close')
          }).catch(error => {
            this.showErrors(error)
          }).finally(() => {
            this.isSaving = false
          })
        }
      })
    },
    savePartner() {
      this.$refs.createItemForm.validate().then(success => {
        if (success) {
          this.isSaving = true
          useJwt.storeGecPartners(this.createFormData()).then(response => {
            this.showSuccessMessage(response)
            this.$emit('reFetch')
            this.$emit('close')
          }).catch(error => {
            this.showErrors(error)
          }).finally(() => {
            this.isSaving = false
          })
        }
      })
    },
    reGenerateToken() {
      this.partner = {
        ...this.partner,
        client_token: uuid.v4(),
      }
    },
  },
}
</script>
