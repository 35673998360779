<template>
  <b-overlay :show="isProcessing">
    <b-card no-body>

      <b-row
        v-if="!classTestId && activeCourseInfo"
        class="p-2"
      >

        <b-col md="6">
          <strong>{{ $t('course-completion-module.course-name') }}</strong>:
          <span
            class="text-primary"
            style="cursor: pointer"
            @click="$emit('next', activeCourseInfo.id)"
          >
            {{ activeCourseInfo.name }}
          </span>
          <div v-if="activeCourseInfo.groupName">
            <strong>{{ $t('labels.lesson-group') }}</strong>: <span>
              {{ activeCourseInfo.groupName }}
            </span>
          </div>
        </b-col>
        <b-col md="6">
          <div class="d-flex justify-content-end">
            <div>
              <strong>{{ $t('course-completion-module.total-lesson') }}</strong>:
              <b-badge variant="light-secondary">
                {{ lessonIds.length }}
              </b-badge>
            </div>
          </div>
          <div
            class="d-flex justify-content-end"
            style="margin-top: 5px"
          >
            <div>
              <strong>
                {{ $t('course-completion-module.total-student') }}
              </strong>:
              <b-badge variant="light-secondary">
                {{ loaders.length }}
              </b-badge>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row
        v-else-if="classTestId && activeCourseInfo"
        class="p-2"
      >
        <b-col md="6">

          <strong>{{ $t('student-report-module.report-pages.test-name') }}</strong>:
          <span
            class="text-primary"
            style="cursor: pointer"
          >
            {{ activeCourseInfo.name }}
          </span>
        </b-col>
        <b-col md="6">
          <b-col
            class="text-right mb-2"
          >
            <b-button
              variant="primary"
              @click="exportExcel()"
            >
              {{ $t('student-report-module.report-pages.export-excel') }}
              <feather-icon icon="FileTextIcon" />
            </b-button>

          </b-col>
          <div class="d-flex justify-content-end">
            <div>
              <strong>
                {{ $t('student-report-module.report-pages.total-lesson') }}
              </strong>:
              <b-badge variant="light-secondary">
                {{ lessonIds.length }}
              </b-badge>
            </div>
          </div>
          <div
            class="d-flex justify-content-end"
            style="margin-top: 5px"
          >
            <div>
              <strong>
                {{ $t('student-report-module.report-pages.total-student') }}
              </strong>:
              <b-badge variant="light-secondary">
                {{ loaders.length }}
              </b-badge>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-table-simple
        v-if="lessonIds.length"
        hover
        responsive
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-th>{{ $t('course-completion-module.table-header.student-name') }}</b-th>
            <b-th v-if="lessonType != 'scorm'">
              {{ $t('course-completion-module.table-header.lesson') }}
            </b-th>
            <b-th v-else>
              {{ $t('course-completion-module.table-header.completion-rate') }}
            </b-th>
            <b-th v-if="lessonType != 'scorm'">
              {{ $t('course-completion-module.table-header.cefr-rate') }}
            </b-th>
            <b-th v-if="lessonType != 'scorm'">
              {{ $t('course-completion-module.table-header.lang-level') }}
            </b-th>
            <b-th>
              {{ $t('course-completion-module.table-header.points') }}
            </b-th>
            <b-th>
              {{ $t('course-completion-module.table-header.time-spent') }}
            </b-th>
            <b-th>
              {{ $t('course-completion-module.table-header.actions') }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(loader, index) in loaders"
            :key="index"
          >
            <b-td class="d-flex align-items-center">
              <feather-icon
                icon="UserIcon"
                size="17"
              />
              <span class="ml-1">{{ titleCase(loader.fullname) }}</span>
            </b-td>
            <b-td
              v-if="viewMode === 'course' && lessonType != 'scorm'"
              class="text-nowrap"
            >
              <!-- Progress -->
              <b-badge
                :id="`progressLessonCount-${index}`"
                variant="warning"
                :style="{ padding: '6px' }"
                class="text-white mr-1"
              >
                {{ loader.progressLessons }}
              </b-badge>
              <b-tooltip
                :target="`progressLessonCount-${index}`"
                triggers="hover"
              >
                {{ $t('course-completion-module.lesson-progress') }}
              </b-tooltip>
              <!-- Progress -->

              <!-- Failed -->
              <b-badge
                :id="`failedLessonCount-${index}`"
                variant="danger"
                :style="{ padding: '6px' }"
                class="text-white mr-1"
              >
                {{ loader.failedLessons }}
              </b-badge>
              <b-tooltip
                :target="`failedLessonCount-${index}`"
                triggers="hover"
              >
                {{ $t('course-completion-module.lesson-failed') }}
              </b-tooltip>
              <!-- Failed -->

              <!-- Passed -->
              <b-badge
                :id="`passedLessonCount-${index}`"
                variant="success"
                :style="{ padding: '6px' }"
                class="text-white mr-1"
              >
                {{ loader.passedLessons }}
              </b-badge>
              <b-tooltip
                :target="`passedLessonCount-${index}`"
                triggers="hover"
              >
                {{ $t('course-completion-module.lesson-passed') }}
              </b-tooltip>
              <!-- Passed -->
            </b-td>
            <b-td v-if="lessonType === 'scorm'">
              {{ loader.competition_score }}
            </b-td>
            <b-td v-if="lessonType != 'scorm'">
              {{
                loader.cefrRate === "NaN%" ? "0.00%" : loader.cefrRate + '%'
              }}
            </b-td>
            <b-td v-if="lessonType != 'scorm'">
              <span
                class="badge text-white"
                :style="{ background: loader.cefrColor, padding: '6px' }"
              >{{
                loader.cefrLevel === "invalid" ? "-" : getLangLevelTransText(loader.cefrLevel)
              }}</span>
            </b-td>

            <b-td>{{ loader.points }}</b-td>

            <b-td>{{ loader.timeSpent }}</b-td>
            <b-td
              class="d-flex"
            >
              <b-button
                :id="`stat-report-${index}`"
                variant="outline-primary"
                class="btn-icon mr-1"
                @click.stop="$emit('showStatReport', loader)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-tooltip
                :target="`stat-report-${index}`"
                triggers="hover"
                placement="left"
              >
                {{ $t('actions.view-statistics') }}
              </b-tooltip>
              <div v-if="lessonType != 'scorm'">
                <b-button
                  :id="`skill-report-${index}`"
                  variant="outline-info"
                  class="btn-icon mr-1"
                  @click.stop="$emit('showSkillReport', loader)"
                >
                  <feather-icon icon="BarChart2Icon" />
                </b-button>
                <b-tooltip
                  triggers="hover"
                  :target="`skill-report-${index}`"
                >
                  {{ $t('course-completion-module.view-skill-report') }}
                </b-tooltip>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div
        v-else-if="!isProcessing"
        class="p-3"
      >
        {{ $t('course-completion-module.no-lesson') }}
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BCol,
  BBadge,
  BTooltip,
  BButton,
  BCard,
  BRow,
  BOverlay,
} from 'bootstrap-vue'
import moment from 'moment'
import exportFromJSON from 'export-from-json'
import useApollo from '@/@core/graphql/useApollo'
import { getColorForLevel, getLevelFromScore } from '@/const/langLevel'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_STUDENT, USER_TYPE_TEACHER } from '@/const/userType'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTh,
    BTr,
    BTd,
    BCol,
    BBadge,
    BTooltip,
    BButton,
    BCard,
    BRow,
    BOverlay,
  },
  props: {
    roomId: {
      type: Number,
      default: () => 0,
    },
    lessonIds: {
      type: Array,
      default: () => [],
    },
    activeCourseId: {
      type: Number,
      default: null,
    },
    viewMode: {
      type: String,
      default: () => 'course',
    },
    studentId: {
      type: [Object, Number, Array],
      default: () => null,
    },
    classTestId: {
      type: Number,
      default: 0,
    },
    activeCourseInfo: {
      type: Object,
      default: () => null,
    },
    loaderType: {
      type: String,
      default: 'homework',
    },
    lessonType: {
      type: String,
      default: 'normal',
    },
    lessonGroupId: {
      type: [Object, Number, String],
      default: null,
    },
  },
  data: () => ({
    room: {},
    loaders: [],
    isProcessing: false,
  }),
  computed: {
    isAStudent() {
      return getUserData() && getUserData().usertype === USER_TYPE_STUDENT
    },
    isATeacher() {
      return getUserData() && getUserData().usertype === USER_TYPE_TEACHER
    },
  },
  watch: {
    lessonIds(value) {
      if (!value.length) {
        this.fetchLessonIds().then(ids => {
          this.fetchReport(ids)
        })
      } else this.fetchReport(this.lessonIds)
    },
  },
  created() {
    if (!this.lessonIds?.length) {
      this.fetchLessonIds().then(ids => {
        this.fetchReport(ids)
      })
    } else this.fetchReport(this.lessonIds)
  },
  methods: {
    calcVariant(loader) {
      let status = 'danger'
      if (loader?.status === 'passed') status = 'success'
      else if (loader?.status === 'in_progress') status = 'warning'
      return status
    },
    calcAverage(numbers) {
      const sum = numbers.reduce((a, b) => a + b, 0)
      const avg = (sum / numbers.length).toFixed(2) || 0
      return avg
    },
    cefrLevel(value) {
      return getLevelFromScore(value)
    },
    addTime(times) {
      let sum = '00:00:00'
      times.forEach(t => {
        const d = moment.duration(sum).add(moment.duration(t))
        sum = moment.utc(d.as('milliseconds')).format('HH:mm:ss')
      })
      return sum
    },
    addPoints(points) {
      if (Array.isArray(points) && points.length) {
        return points.reduce((a, p) => a + p)
      }
      return 0
    },
    fetchLessonIds() {
      return new Promise(resolve => {
        useApollo
          .fetchLessonIds(this.roomId, this.activeCourseId)
          .then(response => {
            let lessonIds = []
            if (response?.data?.room?.courses[0]?.lessons?.length) lessonIds = response.data.room.courses[0].lessons.map(l => parseInt(l.id, 10))
            resolve(lessonIds)
          })
      })
    },
    fetchReport(lessonIds) {
      if (this.viewMode === 'course') this.fetchCourseReport(lessonIds)
      else if (this.viewMode === 'lesson') this.fetchCourseReportByGroup(lessonIds)
    },
    fetchCourseReport() {
      this.isProcessing = true
      useJwt.getCalibratedClassCourseReport(this.roomId, this.activeCourseId, {
        params: {
          loaderType: this.loaderType,
          lesson_group_id: this.lessonGroupId,
        },
      }).then(response => {
        this.loaders = response.data.data.map(item => ({
          fullname: item.student_name,
          id: item.user_id,
          passedLessons: item.status?.passed,
          failedLessons: item.status?.failed,
          progressLessons: item.status?.in_progress,
          timeSpent: item.time_spent,
          cefrRate: item.cefr_rate,
          cefrLevel: item.lang_level,
          points: item.points,
          max_score: item.max_score,
          competition_score: item.max_score > 0 ? (item.points / item.max_score) * 100 : 0,
          cefrColor: getColorForLevel(item.lang_level),
        }))
      }).catch(error => {
        this.showErrorMessage(error)
      })
        .finally(() => {
          this.isProcessing = false
        })
    },
    fetchCourseReportByGroup(lessonIds) {
      this.isProcessing = true
      useApollo
        .fetchCourseReportByGroup(this.roomId, lessonIds, [this.roomId])
        .then(res => {
          this.room = res.data.room

          this.loaders = res.data.room.students.map(s => {
            const cefrValue = this.calcAverage(
              s.loaders
                .filter(l => l.status !== 'in_progress')
                .map(l => JSON.parse(l.cefr_rate ? l.cefr_rate : 0)),
            )
            return {
              id: s.id,
              fullname: s.fullname,
              totalPass: s.loaders[0] ? s.loaders[0].totalPass : '-',
              totalFail: s.loaders[0] ? s.loaders[0].totalFail : '-',
              cefrRate: s.loaders.length ? `${cefrValue || '-'}` : '-',
              cefrLevel: s.loaders.length ? this.cefrLevel(cefrValue) : '-',
              cefrColor: getColorForLevel(
                !s.loaders?.length && this.cefrLevel(cefrValue) === 'invalid'
                  ? '-'
                  : this.cefrLevel(cefrValue),
              ),
              timeSpent: this.addTime(s.loaders.map(l => l.time_spent)),
              createdAt: s.loaders.length ? s.loaders[0].created_at : '-',
              completedAt: s.loader.length ? s.loaders[0].completed_at : '-',
            }
          })
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    exportExcel() {
      const data = this.loaders.map(loader => ({
        FullName: this.titleCase(loader.fullname),
        'Passed Lessons': loader.passedLessons ?? 0,
        'Failed Lessons': loader.failedLessons ?? 0,
        'Lessons In Progress': loader.progressLessons ?? 0,
        'CEFR Rate': loader.cefrRate ?? '-',
        'CEFR Level': loader.cefrLevel ?? '-',
        'Time Spent': loader.timeSpent ?? '-',
        'Course Name': this.room.courses.name ?? '-',
        Points: loader.points,

      }))
      const fileName = `course-report-of ${this.room.courses.name}`
      const exportType = 'csv'

      exportFromJSON({ data, fileName, exportType })
    },
  },
}
</script>
