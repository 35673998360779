<template>
  <div>
    <b-overlay
      :show="isProcessing"
    >
      <h3 class="mb-2">
        {{ $t('setting-module.engagement-settings-title') }}
      </h3>
      <b-table-simple
        responsive
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-th
              width="40%"
            >
              {{ $t('setting-module.options') }}
            </b-th>
            <b-th
              width="40%"
            >
              {{ $t('setting-module.switch') }}
            </b-th>
            <b-th
              width="20%"
            >
              {{ $t('setting-module.help-guide') }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="([key, value]) in Object.entries(modalInfo)"
            :key="key"
          >
            <b-td>{{ $t(value.title) }}</b-td>
            <b-td>
              <p v-if="optionsLocal.district?.[key] === '1'">
                {{ $t('setting-module.hidden-by-district') }}
              </p>
              <span
                v-else
                class="d-flex align-items-baseline"
              >
                <b-form-checkbox
                  v-model="optionsLocal[key].value"
                  class="mt-1"
                  value="0"
                  unchecked-value="1"
                  switch
                  @change="updateSchoolSetting(key)"
                />
                <span class="ml-2">{{ $t('setting-module.hide-show') }}</span>
              </span>
            </b-td>
            <b-td>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="$t('setting-module.help-guide')"
                variant="outline-success"
                class="btn-icon"
                @click="showHelpGuide(key)"
              >
                <feather-icon icon="InfoIcon" />
              </b-button>
            </b-td>
          </b-tr>

        </b-tbody>
      </b-table-simple>
    </b-overlay>
    <setting-info-modal
      :show="showModal"
      :modal-data="modalData"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import {
  BOverlay, BFormCheckbox, VBTooltip, /* BCard, */
  BTableSimple,
  BThead, BTbody, BTr, BTh, BTd, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import SettingInfoModal from '@/views/common/components/SettingInfoModal.vue'
// import { getClassroomSettings } from '@/utils/visibilitySettings'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_SCHOOL } from '@/const/userType'

export default {
  components: {
    BOverlay,
    BFormCheckbox,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
    SettingInfoModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    optionsLocal: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: () => false,
    },
    settingType: {
      type: String,
      default: 'class-setting',
    },
    avatarSetting: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      self: getUserData(),
      settings: null,
      settingNames: null,
      currentSetting: null,
      isProcessing: false,
      isPublic: 0,
      allSettings: {
        game: 0,
        avatar: 0,
        attention: 0,
        ai_feedback: 0,
      },
      districtSettings: {
        game: 0,
        avatar: 0,
        attention: 0,
        ai_feedback: 0,
      },
      showModal: false,
      modalData: {
        title: '',
        imgSrc: '',
        description: '',
        steps: '',
      },
      modalInfo: {
        game: {
          title: i18n.tc('setting-module.game-title'),
          imageSrc: '/school-settings/game.png',
          description: i18n.tc('setting-module.game'),
          steps: '',
        },
        avatar: {
          title: i18n.tc('setting-module.avatar-title'),
          imageSrc: '/school-settings/avatar.png',
          description: i18n.tc('setting-module.avatar'),
          steps: '',
        },
        attention: {
          title: i18n.tc('setting-module.attention-system-title'),
          imageSrc: '/school-settings/attention.png',
          description: i18n.tc('setting-module.attention-system'),
          steps: '',
        },
      },
    }
  },
  computed: {
    isASchool() {
      return this.self.usertype === USER_TYPE_SCHOOL
    },
  },
  mounted() {
    // this.getSettings()
  },
  methods: {
    // getSettings() {
    //   getClassroomSettings({ getSchoolAndDistrict: true }).then(response => {
    //     if (this.isASchool) {
    //       this.setSettings(response.school)
    //       this.setSettings(response.district, 'districtSettings')
    //       this.districtSettings.avatar = this.avatarSetting
    //     } else {
    //       this.setSettings(response.district)
    //       this.allSettings.avatar = this.avatarSetting
    //     }
    //   })
    // },

    // setSettings(data, array) {
    //   Object.keys(this.allSettings).forEach(key => {
    //     const setting = data.filter(s => s.setting_name === key)[0]?.value || 0
    //     if (array) this[array][key] = setting
    //     else this.allSettings[key] = setting
    //   })
    // },

    // updateSetting(type) {
    //   this.isProcessing = true
    //   if (!this.isASchool && type === 'avatar') {
    //     useJwt.updateUserSettings({
    //       key: type,
    //       value: this.allSettings[type],
    //     }).then(response => {
    //       this.showSuccessMessage(response)
    //     }).finally(() => {
    //       this.isProcessing = false
    //     })
    //   } else {
    //     useJwt.updateSchoolSettings(type, {
    //       enable: this.allSettings[type],
    //     }).then(res => {
    //       this.showSuccessMessage(res)
    //     }).catch(error => {
    //       this.showErrorMessage(error)
    //     }).finally(() => {
    //       this.isProcessing = false
    //     })
    //   }
    // },
    updateSchoolSetting(type) {
      const { value } = this.optionsLocal[type]
      const userType = this.optionsLocal[type]?.user_type
      this.isProcessing = true
      useJwt.updateUserSettings({
        key: type, value, userType, forceSync: this.$store.state.taskHelper.forceSyncSettingToSchool,
      }).then(() => {
        this.showSuccessMessage({ data: { message: 'Permission updated' } })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    showHelpGuide(type) {
      this.showModal = true
      this.modalData = {
        title: this.modalInfo[type].title,
        imgSrc: this.modalInfo[type].imageSrc,
        description: this.modalInfo[type].description,
        steps: this.modalInfo[type].steps,
      }
    },
  },
}
</script>
