<template>
  <div>
    <b-row>
      <!-- Pending Points -->
      <b-col
        v-if="pendingPoints"
        lg="6"
      >
        <b-card no-body>
          <b-card-header class="reward-card">

            <!-- Pending Points -->
            <div class="d-flex justify-content-start align-items-center">
              <!-- Points Icon -->
              <feather-icon
                icon="CircleIcon"
                size="70"
                fill="#aaa"
              />

              <!-- Points Details -->
              <div class="ml-1">
                <b-card-text>
                  <p class="mb-0"><strong>{{ $tc('labels.pending-points') }}:
                  </strong>{{ `${pendingPoints.points}` }}
                  </p>

                  <p class="mb-0"><strong>{{ $tc('labels.unfinished_lessons') }}:
                  </strong>{{ `${pendingPoints.pending_lessons}` }}
                  </p>

                  <p class="mb-0 small">
                    {{ $t('points-setting-module.complete-lesson') }}
                  </p>
                </b-card-text>
              </div>
            </div>
          </b-card-header>
        </b-card>
      </b-col>

      <!-- Points -->
      <b-col
        v-for="point, index in points"
        :key="point.code"
        lg="6"
      >
        <b-card no-body>
          <div class="info-icon">
            <b-button v-if="point.description"
                      v-b-tooltip.hover.bottom="point.description"
                      variant="flat-success"
            >
              <feather-icon icon="AlertCircleIcon"
                            class="text-primary"
                            size="20"
              />
            </b-button>
          </div>
          <b-card-header class="reward-card">
            <div class="d-flex justify-content-start align-items-start">
              <!-- Points Icon -->
              <div>
                <feather-icon
                  icon="CircleIcon"
                  size="70"
                  :fill="colors[index] ? colors[index] : colors[0]"
                />
              </div>

              <!-- Reward Details -->
              <div class="ml-1 reward-details">
                <b-card-title class="reward-title">
                  <h3>x {{ point.total }}</h3>
                  <strong>{{ formatText(point.name) }} </strong>
                </b-card-title>
                <b-card-text>
                  <p class="mb-0">
                    <span class="font-weight-bold">{{ $tc('labels.value') }}:</span> {{ point.value }}
                  </p>
                </b-card-text>
              </div>
            </div>
          </b-card-header>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BRow, BCol, VBTooltip, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BCardHeader, BCardTitle, BCardText, BRow, BCol, BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    points: {
      type: [Object, Array],
      default: () => {},
    },
    pendingPoints: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {
      colors: [
        '#ff9f43',
        '#aa80ff',
        '#ff5c33',
        '#47d147',
        '#66b3ff',
      ],
    }
  },
  methods: {
    formatText(text) {
      const textSplit = text.split(' ')
      for (let i = 0; i < textSplit.length; i += 1) {
        textSplit[i] = textSplit[i][0].toUpperCase() + textSplit[i].substr(1)
      }
      return textSplit.join(' ')
    },
  },
}
</script>

<style scoped>
.reward-title {
  font-size: 16px;
}
.reward-card {
  min-height: 125px;
}
</style>
