<template>
  <b-card
    no-body
    class="p-1"
  >
    <b-row>
      <b-col
        md="6"
      >
        <b-form-input
          v-model="problemData.question"
          :placeholder="$t('template-module.question')"
        />
      </b-col>

      <b-col
        md="6"
      >
        <b-form-input
          v-model="problemData.page_link"
          :placeholder="$t('template-module.live-page-link')"
        />
      </b-col>

      <b-col
        :md="noTemplate ? 12:6"
        class="mt-1"
      >
        <label for="">{{ noTemplate ? $t('template-module.template'): $t('template-module.before-template') }}</label>
        <editor
          v-model="problemData.before_template"
          :value="problemData.before_template"
        />
      </b-col>
      <b-col
        v-if="!noTemplate"
        md="6"
        class="mt-1"
      >
        <label for="">{{ $t('template-module.after-template') }}</label>
        <editor
          v-model="problemData.after_template"
          :value="problemData.after_template"
        />
      </b-col>
    </b-row>

  </b-card>

</template>

<script>
import {
  BCol, BRow, BFormInput, BCard,
} from 'bootstrap-vue'
import Editor from '@views/common/components/QuillEditorCustom.vue'

export default {
  components: {
    BFormInput, Editor, BCard, BCol, BRow,
  },
  props: {
    problemData: {
      type: Object,
      required: true,
    },
    noTemplate: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>

</style>
