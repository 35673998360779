<template>
  <b-modal
    id="modal-no-backdrop"
    ref="my-modal"
    no-close-on-backdrop
    content-class="shadow"
    :title="$t('user-module.excel-upload')"
    ok-title="Accept"
    @close="$emit('close')"
    @hide="$emit('close')"
    @ok="handleOk"
  >
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="{ errors }"
              name="Csv file"
              rules="required"
            >
              <b-form-file
                ref="refInputEl1"
                v-model="excelFile"
                :browse-text="$t('browseButton')"
                :placeholder="$t('issue-report-module.choose-a-file')"
                no-drop
                accept=".csv"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <template #modal-footer="{ }">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="info"
            :disabled="isProcessing"
            @click="downloadCSV"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('labels.sample-csv') }}
          </b-button>
          <b-button
            variant="primary"
            :disabled="isProcessing"
            class="ml-1"
            @click="handleOk"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('actions.save') }}
          </b-button>
        </b-col>
      </b-row>
    </template>
    <ExcelPreview v-if="errorData.length"
                  :error-data="errorData"
                  @close="errorData = []"
    />
  </b-modal>
</template>
<script>
import {
  BModal,
  BForm,
  BRow,
  BCol,
  BFormFile,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import ExcelPreview from './ExcelPreview.vue'

export default {
  components: {
    BModal,
    BForm,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BButton,
    BSpinner,
    ExcelPreview,
  },
  props: {
    classId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      excelFile: null,
      isProcessing: false,
      errorData: [],
    }
  },
  mounted() {
    if (this.classId) {
      setTimeout(() => {
        if (this.$refs['my-modal']) {
          this.$refs['my-modal'].show()
        }
      }, 500)
    }
    this.$parent.$on('BulkExcelUpload', () => {
      if (this.$refs['my-modal']) {
        this.$refs['my-modal'].show()
      }
    })
  },
  methods: {
    handleOk() {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
      this.isProcessing = true
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const data = new FormData()
          data.append('csv_file', this.$refs.refInputEl1.files[0])
          if (this.classId) {
            data.append('class_id', this.classId)
          }
          useJwt.uploadUserExcel(data, config).then(response => {
            this.excelFile = null
            if (response.data.data?.fields && response.data.data?.fields.length) {
              this.errorData = response.data.data.fields
              return
            }
            this.showSuccessMessage(response)
            this.$refs['my-modal'].toggle('#toggle-btn')
            if (this.$parent.getUsers) {
              this.$parent.getUsers()
            }
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => { this.isProcessing = false })
        }
      })
    },
    sampleCsv() {
      const genRand = len => Math.random().toString(36).substring(2, len + 2)
      const sampleUsers = [
        {
          email: `${genRand(10)}@gmail.com`,
          firstname: genRand(10),
          lastname: genRand(7),
          language: 'en',
          country: 'Korea',
          usertype: 'student',
          phone: genRand(10),
        },
        {
          email: `${genRand(10)}@gmail.com`,
          firstname: genRand(10),
          lastname: genRand(7),
          language: 'en',
          country: 'Korea',
          usertype: 'student',
          phone: genRand(10),
        },

      ]

      const JsonFields = ['email', 'firstname', 'lastname', 'language', 'country', 'usertype', 'phone']
      let csvStr = `${JsonFields.join(',')}\n`
      sampleUsers.forEach(element => {
        const { email } = element
        const { firstname } = element
        const { lastname } = element
        const { language } = element
        const { country } = element
        const { usertype } = element
        const { phone } = element
        csvStr += `${email},${firstname},${lastname},${language},${country},${usertype},${phone}\n`
      })
      return csvStr
    },
    downloadCSV() {
      const csvStr = this.sampleCsv()
      const hiddenElement = document.createElement('a')
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvStr)}`
      hiddenElement.target = '_blank'
      const name = 'gec_user_sample.csv'
      hiddenElement.download = name
      hiddenElement.click()
    },
  },
}
</script>
