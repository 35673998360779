<template>
  <div>
    <vue-apex-charts
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    series: {
      type: [Object, Array],
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: {
        colors: this.getColors(),
        chart: {
          height: 150,
          type: 'bar',
        },
        xaxis: {
          categories: this.categories,
          position: 'top',
        },
        dataLabels: {
          formatter(val) {
            return `${val}%`
          },
        },
        yaxis: {
          min: 0,
          max: 100,
          labels: {
            formatter(val) {
              return `${val}%`
            },
          },
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            dataLabels: {
              position: 'bottom',
            },
          },
        },
      },
    }
  },
  methods: {
    getColors() {
      if (this.categories.length === 5) {
        return ['#F44336', '#F44336', '#FFEB3A', '#3F50B5', '#4CAF4F']
      }
      return ['#F44336', '#FFEB3A', '#3F50B5', '#4CAF4F']
    },
  },
}
</script>
