<template>
  <b-row class="progress-report">
    <b-col md="3">
      <FilterView ref="filterView"
                  :form="filterForm"
      />
    </b-col>
    <b-col md="9">
      <DateRangeFilter ref="dateRangeFilter"
                       :form="dateFilter"
                       :is-comparative-view="isComparative"
                       :is-processing="isProcessing"
                       :hide-export="hideExportInDatePicker"
                       @exportData="handleExport"
                       @applyFilter="handleApplyFilter"
      />
      <div v-if="filterForm.classroom"
           class="row mt-2"
      >
        <div v-for="i,index in reportCounts"
             :key="i"
             :class="reportCounts.length == 1 ? 'col-12' : 'col-6'"
        >
          <LessonCompleteReport v-if="filterForm.reportType === 'lesson_completion'"
                                ref="reportView"
                                :form="filterForm"
                                :dates="{
                                  startDate: dateFilter[`startDate${index + 1}`],
                                  endDate: dateFilter[`endDate${index + 1}`]
                                }"
                                :filter-counter="applyFilterCounter"
                                @updateProcessing="v => isProcessing = v"
          />
          <ScorePercentage
            v-else-if="filterForm.reportType === 'score_percentage'"
            ref="reportView"
            :form="filterForm"
            :dates="{
              startDate: dateFilter[`startDate${index + 1}`],
              endDate: dateFilter[`endDate${index + 1}`]
            }"
            :filter-counter="applyFilterCounter"
            @updateProcessing="v => isProcessing = v"
          />
          <LanguageSkill v-else-if="filterForm.reportType === 'language_skill'"
                         ref="reportView"
                         :form="filterForm"
                         :dates="{
                           startDate: dateFilter[`startDate${index + 1}`],
                           endDate: dateFilter[`endDate${index + 1}`]
                         }"
                         :filter-counter="applyFilterCounter"
                         @updateProcessing="v => isProcessing = v"
          />
          <DailyUseAgeReport v-else-if="filterForm.reportType === 'daily_use_age'"
                             ref="reportView"
                             :form="filterForm"
                             :filter-counter="applyFilterCounter"
                             :dates="{
                               startDate: dateFilter[`startDate${index + 1}`],
                               endDate: dateFilter[`endDate${index + 1}`]
                             }"
          />
        </div>

      </div>
    </b-col>
  </b-row>
</template>
<script setup>
import { BRow, BCol } from 'bootstrap-vue'
import {
  ref, reactive, computed,
} from 'vue'
import exportFromJSON from 'export-from-json'
import FilterView from './components/FilterView.vue'
import DateRangeFilter from './components/DateRangeFilter.vue'
import LessonCompleteReport from './reports/LessonCompleteReport.vue'
import ScorePercentage from './reports/ScorePercentage.vue'
import LanguageSkill from './reports/LanguageSkill.vue'
import DailyUseAgeReport from './reports/DailyUseAgeReport.vue'

const filterForm = reactive({
  classroom: null,
  students: [],
  reportType: 'lesson_completion',
  graphType: 'bar',
  viewType: 'solo',
})

const dateFilter = reactive({
  startDate1: null,
  endDate1: null,
  startDate2: null,
  endDate2: null,
})

const applyFilterCounter = ref(1)
const reportView = ref(null)
const filterView = ref(null)
const dateRangeFilter = ref(null)
const isProcessing = ref(false)

const handleExport = () => {
  let components = reportView.value
  if (!Array.isArray(components)) {
    components = [reportView.value]
  }
  components.forEach(item => {
    // eslint-disable-next-line no-underscore-dangle
    const { data, fileName } = item._setupProxy.getReportData()
    const exportType = 'csv'
    exportFromJSON({ data, fileName, exportType })
  })
}

const isComparative = computed(() => filterForm.viewType === 'comparative')

const reportCounts = computed(() => {
  if (isComparative.value) {
    return [1, 2]
  }
  return [1]
})

const hideExportInDatePicker = computed(() => filterForm.reportType === 'proficiency')

const handleApplyFilter = () => {
  const form = filterView.value
  form.$refs.filterView.validate().then(success => {
    if (success) {
      applyFilterCounter.value += 1
    }
  })
}
</script>
<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
  .dark-layout {
    .progress-report {
      .form-card{
        background-color: $theme-dark-card-bg !important;
        background-color: $theme-dark-box-shadow;
      }
      .single-line-ui{
        background-color: $theme-dark-card-bg !important;
        background-color: $theme-dark-box-shadow !important;
      }
    }
  }
</style>
