<template>
  <div>
    <validation-observer ref="nameStandardForm">

      <!-- Name -->
      <b-form-group
        :label="$t('labels.create-problem.problem-name')"
        label-for="name"
      >
        <validation-provider
          v-slot="{ errors }"
          name="name"
          rules="required|max:256"
          vid="name"
        >
          <b-form-input
            v-model="name"
            :placeholder="$t('labels.name')"
            :state="errors.length > 0 ? false : null"
            name="name"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <!-- /Name -->

      <b-row>
        <!-- Domain -->
        <b-col
          md="12"
          lg="6"
        >
          <b-form-group
            :label="$t('labels.create-problem.domain')"
            label-for="Domain"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Domain"
              rules="required"
            >
              <b-form-select
                id="domain"
                v-model="selectedDomain"
                :options="domains"
                name="standard"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- /Domain -->

        <!-- Tags -->
        <b-col
          md="12"
          lg="6"
        >
          <b-form-group
            :label="$t('labels.create-problem.tags')"
            label-for="tag"
          >
            <validation-provider
              v-slot="{ errors }"
              name="tag"
              rules="required"
            >
              <v-select
                v-model="selectedTags"
                :class="!!errors[0] ? 'v-select-error':''"
                multiple
                label="text"
                :options="tags"
                placeholder="Please select a Tag"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- /Tags -->

        <!-- Standard -->
        <b-col
          md="12"
          lg="6"
        >
          <b-form-group
            :label="$t('labels.create-problem.standard')"
            label-for="standard"
          >
            <validation-provider
              v-slot="{ errors }"
              name="standard"
              vid="standard"
            >
              <b-form-select
                id="standard"
                v-model="standardId"
                :options="standards"
                :disabled="selectedDomain ? false : true"
                name="standard"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- /Standard -->

        <!-- Skill Type -->
        <b-col
          md="12"
          lg="6"
        >
          <b-form-group
            :label="$t('labels.create-problem.skill-type')"
            label-for="skill-type"
            rules="required"
          >
            <validation-provider
              v-slot="{ errors }"
              name="skill-type"
              vid="skill-type"
            >
              <b-form-select
                id="skill-type"
                v-model="skillType"
                :options="skillTypes"
                name="skill-type"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- /Skill Type -->
      </b-row>

      <b-row>
        <b-col
          md="12"
          lg="6"
        >
          <!-- Theme -->
          <b-form-group
            :label="$t('labels.create-problem.theme')"
            label-for="theme"
          >
            <validation-provider
              v-slot="{ errors }"
              name="theme"
              vid="theme"
            >
              <select
                id="theme"
                v-model="theme"
                :options="themes"
                name="theme"
                class="custom-select theme-selector"
              >
                <option
                  v-for="t in themes"
                  :key="t.text"
                  :value="t.value"
                  :class="'theme-' + t.value"
                >
                  {{ t.text }}
                </option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <theme-preview :theme="theme" />
          <!-- /Theme -->
        </b-col>

        <!-- TTS Support -->
        <b-col
          md="12"
          lg="6"
        >
          <b-form-group
            :label="$t('labels.create-problem.tts-support')"
            label-for="theme"
          >
            <b-form-checkbox
              v-model="ttsSupport"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <!-- /TTS Support -->
      </b-row>
    </validation-observer>

  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BFormSelect, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import useJwt from '@/auth/jwt/useJwt'
import ThemePreview from '@views/common/components/ThemePreview.vue'
import vSelect from 'vue-select'
import SkillTypes from '@/const/skillTypes'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    ThemePreview,
    vSelect,
    BFormCheckbox,
  },
  props: {
    level: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      skillTypes: SkillTypes,
    }
  },
  computed: {
    domains() {
      return this.$store.state.problem.domains
    },
    themes() {
      return this.$store.state.problem.themes
    },
    showNameStandardErrorMessage: {
      get() {
        return this.$store.state.problem.showError
      },
      set() {},
    },
    standardId: {
      get() {
        return this.$store.state.problem.create.standard_id
      },
      set(value) {
        this.$store.commit('problem/SET_PROBLEM_FEILD', { name: 'standard_id', value })
      },
    },
    standards: {
      get() {
        return this.$store.state.problem.create.standards
      },
      set(value) {
        this.$store.commit('problem/SET_PROBLEM_FEILD', { name: 'standards', value })
      },
    },
    selectedDomain: {
      get() {
        return this.$store.state.problem.create.selectedDomain
      },
      set(value) {
        this.$store.commit('problem/SET_PROBLEM_FEILD', { name: 'selectedDomain', value })
        useJwt.getStandardsByDomain(value).then(response => {
          const standards = [{ value: null, text: 'Please select a standard' }, ...response.data.data.map(s => ({ value: s.id, text: s.name }))]
          this.$store.commit('problem/SET_PROBLEM_FEILD', { name: 'standards', value: standards })
          this.$store.commit('problem/SET_PROBLEM_FEILD', { name: 'standard_id', value: null })
        })
      },
    },
    tags: {
      get() {
        return this.$store.state.problem.create.tags
      },
    },
    selectedTags: {
      get() {
        return this.$store.state.problem.create.selectedTags
      },
      set(value) {
        this.$store.commit('problem/SET_SELECTED_TAGS', value)
      },
    },
    name: {
      get() {
        return this.$store.state.problem.create.name
      },
      set(value) {
        this.$store.commit('problem/SET_PROBLEM_FEILD', { name: 'name', value })
      },
    },
    theme: {
      get() {
        return this.$store.state.problem.create.theme
      },
      set(value) {
        this.$store.commit('problem/SET_PROBLEM_FEILD', { name: 'theme', value })
      },
    },
    ttsSupport: {
      get() {
        return this.$store.state.problem.ttsSupport
      },
      set(value) {
        this.$store.commit('problem/SET_TTS_SUPPORT', value)
      },
    },
    skillType: {
      get() {
        return this.$store.state.problem.skillType
      },
      set(value) {
        this.$store.commit('problem/SET_SKILL_TYPE', value)
      },
    },
  },
  watch: {
    showNameStandardErrorMessage(value) {
      if (value) {
        this.$refs.nameStandardForm.validate()
      }
    },
    selectedTags(val) {
      if (val.length) {
        if (!this.skillType) {
          const skill = this.skillTypes.find(type => type.value === val[0].id)
          if (skill) this.skillType = skill.value
        }
      } else this.skillType = null
    },
  },
}
</script>
