<template>
  <div>
    <b-row>
      <!-- <b-col>
        <b-card
          :title="$t('higher-level-report-module.monthly-login-title')"
        >
          <b-card-text>
            {{ $t('higher-level-report-module.monthly-login-detail') }}
          </b-card-text>
          <b-card-footer
            class="px-0 pb-0"
          >
            <b-button
              size="sm"
              variant="primary"
              class="btn-tour-skip mr-1"
              :disabled="isProcessing"
              @click="getDetailMonthlyLoginReport"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t('student-report-module.report-pages.export-excel') }}
              <feather-icon icon="FileTextIcon" />
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>
      <b-col>
        <b-card
          :title="$t('higher-level-report-module.lesson-complete-title')"
        >
          <b-card-text>
            {{ $t('higher-level-report-module.lesson-complete-detail') }}
          </b-card-text>
          <b-card-footer
            class="px-0 pb-0"
          >
            <b-button
              size="sm"
              variant="primary"
              class="btn-tour-skip mr-1"
              :disabled="isProcessing"
              @click="getDetailLessonCompleteReport('homework')"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t('student-report-module.report-pages.export-excel') }}
              <feather-icon icon="FileTextIcon" />
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>
      <b-col>
        <b-card
          :title="$t('higher-level-report-module.follow-up-title')"
        >
          <b-card-text>
            {{ $t('higher-level-report-module.follow-up-detail') }}
          </b-card-text>
          <b-card-footer
            class="px-0 pb-0"
          >
            <b-button
              size="sm"
              variant="primary"
              class="btn-tour-skip mr-1"
              :disabled="isProcessing"
              @click="getDetailLessonCompleteReport('follow_up')"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t('student-report-module.report-pages.export-excel') }}
              <feather-icon icon="FileTextIcon" />
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col> -->

      <b-col
        md="12"
      >
        <b-card
          :title="$t('higher-level-report-module.combined-report-title', {currentMonth})"
        >
          <b-card-text>
            {{ $t('higher-level-report-module.combined-report-detail') }}
          </b-card-text>
          <b-card-footer
            class="px-0 pb-0"
          >
            <b-row>
              <b-col md="4">
                <b-form-group
                  :label="$t('student-report-module.summary-table.report-type')"
                  class="mb-2"
                >
                  <b-form-select
                    v-model="selectedReportType"
                    :options="[
                      { value: 'monthly_login', text: $t('higher-level-report-module.monthly-login') },
                      { value: 'daily_usages', text: $t('higher-level-report-module.daily-usage') },
                    ]"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="8"
                     class="d-md-flex align-items-baseline justify-content-start"
                     style="gap:20px;"
              >
                <div>
                  <b-form-group
                    :label="$t('student-report-module.summary-table.from')"
                  >
                    <b-form-datepicker
                      v-model="filter.from"
                      :placeholder="$t('higher-level-report-module.no-date-selected')"
                      :max="maxDate"
                    />
                  </b-form-group>
                </div>
                <div>
                  <b-form-group
                    :label="$t('student-report-module.summary-table.to')"
                  >
                    <b-form-datepicker
                      v-model="filter.to"
                      :placeholder="$t('higher-level-report-module.no-date-selected')"
                      :min="filter.from"
                      :max="maxDate"
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col md="4"
                     class="d-flex align-items-center"
              >
                <b-button
                  size="sm"
                  variant="primary"
                  class="btn-tour-skip mr-1"
                  :disabled="isProcessing"
                  @click="getCombinedReport('download')"
                >
                  <b-spinner
                    v-show="isProcessing"
                    small
                  />
                  {{ $t('student-report-module.report-pages.export-excel') }}
                  <feather-icon icon="FileTextIcon" />
                </b-button>

                <b-button
                  size="sm"
                  variant="success"
                  class="btn-tour-skip mr-1"
                  :disabled="isProcessing"
                  @click="getCombinedReport('view')"
                >
                  <b-spinner
                    v-show="isProcessing"
                    small
                  />
                  {{ $t('actions.view') }}
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
    <b-card
      v-if="showReportTable"
    >
      <b-row>
        <b-col>
          <b-table
            :fields="reportType === 'daily_usages' ? columnsDaily : columnsMonthly"
            :items="report"
            responsive
            class="mb-0"
            tbody-tr-class="rowClass"
            :empty-text="$t('messages.no-data-available')"
            show-empty
            outlined
          />
        </b-col>
      </b-row>

    </b-card>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BCardText, BCardFooter, BButton, BSpinner, BFormDatepicker, BFormGroup, BFormSelect,
  BTable,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import exportFromJSON from 'export-from-json'
import { getUserData } from '@/auth/utils'

const moment = require('moment')

export default {
  components: {
    BFormSelect,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardFooter,
    BButton,
    BSpinner,
    BFormDatepicker,
    BFormGroup,
    BTable,
  },

  data() {
    return {
      selectedReportType: 'monthly_login',
      isProcessing: false,
      currentMonth: moment().format('MMMM'),
      self: getUserData(),
      filter: {
        from: moment().startOf('month').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
      },
      maxDate: moment().format('YYYY-MM-DD'),
      showReportTable: false,
      columnsDaily: [
        { key: 'day', label: 'Date' },
        { key: 'provider_id', label: 'Provider Id' },
        { key: 'student_name', label: 'Student Name' },
      ],
      columnsMonthly: [
        {
          key: 'student_id',
          label: 'Student Id',
        },
        {
          key: 'provider_id',
          label: 'Provider Id',
        },
        {
          key: 'schools',
          label: 'Schools',
        },
        {
          key: 'student_name',
          label: 'Student Name',
        },
        {
          key: 'login_count',
          label: 'Login Count',
        },
        {
          key: 'total_lesson_completed',
          label: 'Total Lesson completed',
        },
        {
          key: 'total_practice',
          label: 'Times Any Lessons Were Repeated',
        },
        {
          key: 'total_follow_up_completed',
          label: 'Total Review Lesson completed',
        },
        {
          key: 'follow_up_complete_at',
          label: 'Review Lesson completed AT',
        },

      ],
      reportType: 'monthly_login',
      report: [],
    }
  },
  methods: {
    getDetailLessonCompleteReport(type) {
      this.isProcessing = true
      useJwt.getLessonCompleteReport({
        params: {
          type,
        },
      }).then(response => {
        const fileName = 'total-lesson-completed-report'
        const exportType = 'csv'
        const responseData = response.data.data
        if (responseData.length) {
          exportFromJSON({
            data: responseData.map(item => ({
              'User Id': item?.user_id,
              'Total Lesson complete': item?.total_lesson_completed,
            })),
            fileName,
            exportType,
          })
        } else {
          this.showErrorMessage(this.$i18n.tc('messages.no-data-available'))
        }
        this.isProcessing = false
      })
    },

    getDetailMonthlyLoginReport() {
      this.isProcessing = true
      useJwt.getMonthlyLoginReport()
        .then(response => {
          const fileName = 'monthly-login-report'
          const exportType = 'csv'
          const responseData = response.data.data
          if (responseData.length) {
            exportFromJSON({
              data: responseData.map(item => ({
                'Student Id': item?.student_id,
                'Login Count': item?.login_count,
              })),
              fileName,
              exportType,
            })
          } else {
            this.showErrorMessage(this.$i18n.tc('messages.no-data-available'))
          }
          this.isProcessing = false
        })
    },

    getCombinedReport(type) {
      this.isProcessing = true
      this.showReportTable = false
      this.report = []
      useJwt.getCombinedReport(
        {
          params: {
            from: this.filter.from,
            to: this.filter.to,
            report_type: this.selectedReportType,
          },
        },
      )
        .then(response => {
          const responseData = response.data.data
          if (type === 'view') {
            this.reportType = this.selectedReportType
            this.showReportTable = true
            this.report = responseData
          } else {
            let fileName = `Monthly-Report-${this.currentMonth}-${this.self.firstname}`
            const exportType = 'csv'
            if (responseData.length) {
              if (this.selectedReportType === 'daily_usages') {
                fileName = `Daily-Usages-${this.currentMonth}-${this.self.firstname}`
                exportFromJSON({
                  data: responseData.map(item => ({
                    date: item?.day,
                    'Provider Id': item?.provider_id,
                    'Student Name': item?.student_name,
                  })),
                  fileName,
                  exportType,
                })
              } else {
                exportFromJSON({
                  data: responseData.map(item => ({
                    'Student Id': item?.student_id,
                    'Provider Id': item?.provider_id,
                    Schools: item?.schools,
                    'Student Name': item?.student_name,
                    'Login Count': item?.login_count,
                    'Total Lesson completed': item?.total_lesson_completed,
                    'Times Any Lessons Were Repeated': item?.total_practice,
                    'Total Review Lesson completed': item?.total_follow_up_completed,
                    'Review Lesson completed AT': item?.follow_up_complete_at,
                  })),
                  fileName,
                  exportType,
                })
              }
            } else {
              this.showErrorMessage(this.$i18n.tc('messages.no-data-available'))
            }
          }
          this.isProcessing = false
        })
    },
  },
}
</script>
