<template>
  <div>
    <b-row>
      <b-col
        md="12"
        sm="12"
        class="d-flex justify-content-end"
      >
        <div>
          <b-button
            variant="outline-primary"
            @click="showCreateModal=true"
          >
            <feather-icon icon="PlusIcon" /> {{ $t('role-permissions-module.create-new-role') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="CreateRole"
      v-model="showCreateModal"
      ok-only
      ok-variant="outline-secondary"
      :ok-title="$t('close')"
      modal-class="modal-primary"
      size="md"
      centered
      :title="isEditMode ? $t('role-permissions-module.update-role') : $t('role-permissions-module.create-role')"
      :no-close-on-backdrop="true"
      @close="$emit('close')"
      @hide="$emit('close')"
    >

      <validation-observer
        ref="CreateRoleForm"
      >
        <b-form
          class="add-student-from"
          @submit.prevent="createRole"
        >
          <button
            type="submit"
            class="d-none"
          />
          <b-row>
            <b-col md="12">
              <b-form-group
                :label="$t('role-permissions-module.role-name')"
                label-for="role-name"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('role-permissions-module.role-name')"
                  rules="required"
                  vid="role_name"
                >
                  <b-form-input v-model="newRole.name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <validation-provider
                v-slot="{ errors }"
                name="Module"
                rules="required"
                vid="role_name"
              >
                <b-form-group
                  :label="$t('role-permissions-module.module')"
                  label-for="role-name"
                >
                  <v-select v-model="newRole.module"
                            :options="modules"
                            label="text"
                            debounce="500"
                            :clearable="false"
                            :reduce="item => item.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12">
              <b-form-group
                :label="$t('role-permissions-module.permission')"
                label-for="role-name"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Role Module"
                  rules="required"
                  vid="role_name"
                >
                  <v-select v-model="newRole.permissions"
                            :options="allPermissions"
                            label="name"
                            multiple
                            :reduce="item => item.id"
                            @search="getPermission"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            variant="success"
            :disabled="isProcessing"
            class="mr-1"
            @click="isEditMode ? updateRole() : createRole()"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('actions.save') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import {
  BButton, BRow, BCol, BModal, BSpinner, BFormInput, BFormGroup, BForm,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import i18n from '@/libs/i18n'

const initialData = () => ({
  id: null,
  name: '',
  permissions: [],
})

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BSpinner,
    BFormInput,
    BFormGroup,
    BForm,
    BModal,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isProcessing: false,
      modules: [
        {
          text: i18n.tc('navigation-menu.library'),
          value: 'library',
        },
        {
          text: i18n.tc('labels.course'),
          value: 'course',
        },
        {
          text: i18n.tc('labels.lesson'),
          value: 'lesson',
        },
        {
          text: i18n.tc('labels.lesson-group'),
          value: 'lesson_group',
        },
      ],
      newRole: {
        ...initialData(),
      },
      selectedRole: null,
      showCreateModal: false,
      allPermissions: [],
    }
  },
  computed: {
    isEditMode() {
      return !!this.newRole.id
    },
  },
  watch: {
    showCreateModal(value) {
      if (!value) {
        this.newRole = { ...initialData() }
      }
    },
  },
  methods: {
    getPermission(term, loading) {
      if (!term) {
        return
      }
      loading(true)
      const currentPermissions = this.allPermissions.filter(item => this.newRole.permissions.includes(item.id))
      useJwt.searchPermission({
        params: {
          term,
        },
      }).then(response => {
        this.allPermissions = this.ARRAY_UNIQUE_BY_KEY([...currentPermissions, ...response.data.data], 'id')
      }).finally(() => {
        loading(false)
      })
    },
    createRole() {
      this.$refs.CreateRoleForm.validate().then(res => {
        if (!res) return
        this.isProcessing = true
        const params = {
          ...this.newRole,
          role_permissions: this.newRole.permissions,
        }
        useJwt.storeRoles(params).then(() => {
          this.showCreateModal = false
          this.$emit('onPermissionUpdate')
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
      })
    },
    updateRole() {
      this.$refs.CreateRoleForm.validate().then(res => {
        if (!res) return
        this.isProcessing = true
        const params = {
          ...this.newRole,
          role_permissions: this.newRole.role_permissions.filter(item => item.value === true),
        }
        useJwt.updateRoles(this.newRole.id, params).then(() => {
          this.showCreateModal = false
          this.$emit('onPermissionUpdate')
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
      })
    },
  },
}
</script>

<style>

</style>
