<template>
  <b-modal
    :visible="props.show"
    size="xl"
    title="Show Problem Automation"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <div class="mb-2">
      <SyncProgress ref="synchProgressBar"
                    queue-key="ai-problem-automation"
                    title="AI Problem Automation "
      />
      <div class="text-right mt-2">
        <b-form-select
          v-model="selectedPromptPage"
          :options="promptOptions"
        />
      </div>
    </div>
    <table class="table table-hover table-striped">
      <thead>
        <tr>
          <th>Problem</th>
          <th>Question Type</th>
          <th>Answer Type</th>
          <th>Domain</th>
          <th>Standard</th>
          <th>Skill Type</th>
          <th>Tags</th>
          <th>Voice</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="option of options"
            :key="option.value"
        >
          <td>
            <b-form-checkbox
              v-model="option.selected"
              :value="true"
              class="mt-2"
            >
              {{ option.text }}
            </b-form-checkbox>
          </td>
          <td>
            <b-form-select
              v-model="option.ques_type"
              :options="questionAnsType"
            />
          </td>
          <td>
            <b-form-select
              v-model="option.ans_type"
              :options="questionAnsType"
            />
          </td>
          <td>
            <b-form-select
              v-model="option.domain_id"
              :options="[{text: 'Select Domain', value: null}, ...domainData]"
              name="skill-type"
              @change="handleStandardChange(option)"
            />
          </td>
          <td>
            <b-form-select
              v-model="option.standard_id"
              :options="[{text: 'Select Standard', value: null}, ...option.standardOptions]"
              name="skill-type"
            />
          </td>
          <td>
            <b-form-select
              v-model="option.skill_type"
              :options="skillTypes"
              name="skill-type"
            />
          </td>
          <td>
            <v-select
              v-model="option.tags"
              multiple
              label="text"
              :options="problemTags"
              placeholder="Please select a Tag"
            />
          </td>
          <td>
            <b-form-select
              v-model="option.voice"
              :options="voiceOptions"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <template #modal-footer>
      <div>
        <b-button variant="outline-secondary"
                  @click="$emit('close')"
        >
          Cancel
        </b-button>
        <b-button
          class="ml-1"
          variant="outline-primary"
          :disabled="isProcessing"
          @click="automateProblem"
        >
          <b-spinner v-show="isProcessing"
                     small
          /> Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script setup>
import {
  BModal,
  BFormCheckbox,
  BButton,
  BSpinner,
  BFormSelect,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import {
  getCurrentInstance, ref, watch,
} from 'vue'
import skillTypes from '@/const/skillTypes'
import vSelect from 'vue-select'
import { problemTags } from '@/const/tags'
import SyncProgress from '../../image-library/components/SyncProgress.vue'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  lessonId: {
    type: Number,
    required: true,
  },
})
const SELECTED_PROMPT_LOCAL_KEY = `last_selected_prompt_${props.lessonId}`

const questionAnsType = [
  {
    text: 'Text',
    value: 'text',
  },
  {
    text: 'Image',
    value: 'image',
  },
  {
    text: 'Audio',
    value: 'audio',
  },
]

const isProcessing = ref(false)
const selectedPromptPage = ref(localStorage.getItem(SELECTED_PROMPT_LOCAL_KEY) || 'prob_auto')
const voiceOptions = [
  {
    text: 'Male',
    value: 'male',
  },
  {
    text: 'Female',
    value: 'female',
  },
]

const promptOptions = [
  {
    text: 'Problem Automation - Video',
    value: 'prob_auto',
  },
  {
    text: 'Problem Automation - Grammar',
    value: 'prob_auto_grammar',
  },
]

const options = ref([
  {
    text: 'Drag Position',
    value: 'drag_position',
    selected: false,
    ques_type: 'text',
    ans_type: 'text',
    voice: 'male',
    skill_type: 'reading',
    domain_id: null,
    standard_id: null,
    standardOptions: [],
    tags: [],
  },
  {
    text: 'Multiple Choice',
    value: 'multiple',
    selected: false,
    ques_type: 'text',
    ans_type: 'text',
    voice: 'male',
    skill_type: 'reading',
    domain_id: null,
    standard_id: null,
    standardOptions: [],
    tags: [],
  },
  {
    text: 'Matching Problem',
    value: 'matching',
    selected: false,
    ques_type: 'text',
    ans_type: 'text',
    voice: 'male',
    skill_type: 'reading',
    domain_id: null,
    standard_id: null,
    standardOptions: [],
  },
  {
    text: 'Prompted Speech',
    value: 'speech',
    selected: false,
    ques_type: 'text',
    ans_type: 'text',
    voice: 'male',
    skill_type: 'speaking',
    domain_id: null,
    standard_id: null,
    standardOptions: [],
  },
  {
    text: 'Open Ended',
    value: 'open_ended',
    selected: false,
    ques_type: 'text',
    ans_type: 'text',
    voice: 'male',
    skill_type: 'writing',
    domain_id: null,
    standard_id: null,
    standardOptions: [],
  },
  {
    text: 'Fill in the blanks',
    value: 'blank',
    selected: false,
    ques_type: 'text',
    ans_type: 'text',
    voice: 'male',
    skill_type: 'writing',
    domain_id: null,
    standard_id: null,
    standardOptions: [],
  },
  {
    text: 'FIB Dropdown',
    value: 'fib_dropdown',
    selected: false,
    ques_type: 'text',
    ans_type: 'text',
    voice: 'male',
    skill_type: 'reading',
    domain_id: null,
    standard_id: null,
    standardOptions: [],
  },
])
const emits = defineEmits(['close', 'refetch'])
const root = getCurrentInstance().proxy.$root
const synchProgressBar = ref(null)
const domainData = ref([])

const automateProblem = async () => {
  // eslint-disable-next-line no-underscore-dangle
  if (synchProgressBar.value?._setupState.isQueueRunning.value) {
    const confirmGenerate = await root.$swal.fire({
      title: 'The Previous problem generation process has not been compted yet. Do you want to force generate the problem?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      allowOutsideClick: false,
    })
    if (!confirmGenerate.isConfirmed) {
      return
    }
  }

  isProcessing.value = true
  localStorage.setItem(SELECTED_PROMPT_LOCAL_KEY, selectedPromptPage.value)
  useJwt
    .automateProblem(props.lessonId, {
      prompt_page: selectedPromptPage.value,
      selected_options: options.value.filter(i => i.selected),
    })
    .then(response => {
      root.showSuccessMessage(response)
      emits('close')
      emits('refetch')
    })
    .finally(() => {
      isProcessing.value = false
    })
}

const handleStandardChange = option => {
  useJwt.getStandardsByDomain(option.domain_id).then(response => {
    const standards = [...response.data.data.map(s => ({ value: s.id, text: s.name }))]
    // eslint-disable-next-line no-param-reassign
    option.standardOptions = standards
  })
}

watch(() => props.show, newValue => {
  if (newValue) {
    useJwt.getDomainByLesson(props.lessonId).then(response => {
      domainData.value = response.data.data.domain.map(i => ({
        text: i.name,
        value: i.id,
      }))
    })
  }
})

</script>
