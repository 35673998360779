<template>
  <b-modal :visible="true"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <template #modal-title>
      <h5>Main Point Editor</h5>
    </template>
    <b-form @submit.prevent="submitForm">
      <b-form-group label="Main Point"
                    label-for="main-point-input"
      >
        <b-form-textarea
          id="main-point-input"
          v-model="mainPoint"
          required
        />
      </b-form-group>
      <b-form-group label="Assets URL"
                    label-for="assets-url-input"
      >
        <b-form-input
          id="assets-url-input"
          v-model="assetsUrl"
          required
        />
      </b-form-group>
    </b-form>
    <template v-if="standard?.standard_families.length > 0">
      <div
        class="mb-1"
      >
        <h5>Associate Standards</h5>
        <span
          v-for="(associate, index) in standard.standard_families"
          :key="index"
          class="border rounded shadow-sm"
          style="padding: 4px; margin-right: 5px;"
        >
          {{ `${associate.children.name} - ${associate.children.code} ,` }}
        </span>
      </div>
    </template>

    <associate-standard ref="associateStandardRef"
                        :grades="grades"
    />

    <template #modal-footer>
      <b-button variant="secondary"
                @click="$emit('close')"
      >
        Cancel
      </b-button>
      <b-button type="submit"
                variant="primary"
                :disabled="isProcessing"
                @click="submitForm"
      >
        <b-spinner v-show="isProcessing"
                   small
        /> Save
      </b-button>

    </template>
  </b-modal>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import {
  BModal, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import AssociateStandard from '@/views/super/standard/components/standard/AssociateStandard.vue'

const props = defineProps({
  point: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    default: 0,
  },
  standardId: {
    type: Number,
    required: true,
  },
})

const standard = ref(null)
const associateStandardRef = ref(null)
const isProcessing = ref(false)
const grades = ref([])
const root = getCurrentInstance().proxy.$root
const emit = defineEmits(['close', 'refetch'])

const mainPoint = ref(props.point.text)
const assetsUrl = ref(props.point.asset)

const submitForm = () => {
  isProcessing.value = true
  useJwt.updateStandardMainPoints(props.standardId, {
    point: mainPoint.value,
    asset: assetsUrl.value,
    index: props.index,
    associate_standard: associateStandardRef.value.getSelectedStandards(),
  }).then(response => {
    root.showSuccessMessage(response)
    emit('refetch')
    emit('close')
  }).finally(() => {
    isProcessing.value = false
  })
}

onMounted(() => {
  useJwt.getStandardGrades({ per_page: 999 }).then(response => {
    grades.value = response.data.data.data
  })
  useJwt.showStandard(props.standardId, {
    params: {
      with_family: 1,
    },
  }).then(response => {
    standard.value = response.data.data
    console.log(standard.value.standard_families)
  })
})
</script>
