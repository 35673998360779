<template>
  <div
    v-if="Object.keys(profileData).length"
    id="user-profile"
  >
    <Header :header-data="profileData.header" />
    <section id="profile-info">
      <b-row>
        <b-col
          lg="4"
          md="6"
          cols="12"
          order="2"
          order-lg="1"
        >
          <about
            :profile="userData"
            :is-viewing-own-profile="isViewingOwnProfile"
          />
        </b-col>
      </b-row>
    </section>
  </div>

</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import Header from '@/views/common/profile/components/Header.vue'
import About from '@/views/common/profile/components/Details.vue'
import { getUserData } from '@/auth/utils'
import string from '@/utils/string'

export default {
  components: {
    BRow,
    BCol,
    Header,
    About,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      profileData: {},
      allCourses: [],
      teacherProfile: {},
      self: getUserData(),
    }
  },
  computed: {
    isViewingOwnProfile() {
      return this.self.id === this.userData.id
    },
  },
  created() {
    this.profileData = {
      header: {
        avatar: this.userData.avatar,
        coverImg: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/timeline.aa03c008.jpg',
        username: string.capitalizeFirstLetter(`${this.userData.firstname || ''} ${this.userData.lastname || ''}`),
        designation: string.capitalizeFirstLetter(this.userData.usertype),
      },
    }
    // this.$http.get('/profile/data').then(res => { this.profileData = res.data })
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';
</style>
