<template>
  <div class="dropdown-section">

    <b-form-group>
      <template #label>
        <label class="mb-1">{{ $t('ai-ide-module.temperature') }}</label>
      </template>
      <b-form-input v-model.number="modelForm.model_settings.temperature"
                    placeholder="Temperature"
                    type="number"
      />
    </b-form-group>
    <b-form-group>
      <template #label>
        <label class="mb-1">{{ $t('ai-ide-module.json-response') }}</label>
      </template>
      <b-form-checkbox v-model.number="modelForm.model_settings.json_response"
                       :value="1"
                       switch
      >
        Json Response
      </b-form-checkbox>
    </b-form-group>
    <!-- <b-form-group>
      <template #label>
        <label class="mb-1">{{ $t('ai-ide-module.check-level') }}</label>  <feather-icon id="check-level-tooltip"
                                                                                         class="cursor-pointer text-primary"
                                                                                         icon="InfoIcon"
                                                                                         size="20"
        />
      </template>
      <b-tooltip target="check-level-tooltip"
                 triggers="hover"
      >
        {{ $t('ai-ide-module.check-level-tooltip') }}
      </b-tooltip>
      <b-form-select v-model="modelForm.model_settings.checkLevel">
        <b-form-select-option value="1">
          1
        </b-form-select-option>
        <b-form-select-option value="2">
          2
        </b-form-select-option>
        <b-form-select-option value="3">
          3
        </b-form-select-option>
      </b-form-select>
    </b-form-group> -->
    <!-- <b-form-group>
      <template #label>
        <label class="mb-1">Check Mode</label>    <feather-icon id="check-mode-tooltip"
                                                                class="cursor-pointer text-primary"
                                                                icon="InfoIcon"
                                                                size="20"
        />
      </template>

      <b-tooltip target="check-mode-tooltip"
                 triggers="hover"
      >
        The mode of the engine during the check. Options are "FULL_CHECK", "QUICK_CHECK", "SPELL_CHECK" and "ADVANCED_SPELL". Default: "FULL_CHECK"
      </b-tooltip>
      <b-form-select v-model="modelForm.model_settings.checkMode">
        <b-form-select-option value="gpt-3.5-turbo-0613">
          gpt-3.5-turbo-0613
        </b-form-select-option>
      </b-form-select>
    </b-form-group> -->
  </div>
</template>

<script>
import {
  // BFormSelect, BFormSelectOption,
  BFormGroup,
  // BTooltip,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    // BFormSelect,
    BFormInput,
    // BFormSelectOption,
    BFormGroup,
    BFormCheckbox,
    // BTooltip,
  },
  props: {
    modelForm: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    if (!this.modelForm.model_settings.checkMode) {
      this.modelForm.model_settings.checkMode = 'gpt-3.5-turbo-0613'
    }
  },
}
</script>
