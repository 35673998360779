<template>
  <div>
    <b-row v-if="selectedProblem">
      <b-col
        v-if="isAStudent"
      >
        <b-card
          ref="my-notes"
          class="w-100"
        >
          <div class="d-flex justify-content-between">
            <div>
              <b-avatar
                size="md"
                variant="light-primary"
                v-bind="{
                  ...(self.avatar
                    ? {
                      src: self.avatar,
                    }
                    : {
                      text: self.firstname.charAt(0),
                    }),
                }"
                class="mr-1"
              />
              <strong
                class="text-bold"
              >{{ self.firstname }} {{ self.lastname }}</strong>
            </div>
            <div>
              <feather-icon
                v-if="editing"
                icon="CheckIcon"
                role="button"
                :class="editing ? 'text-success' : ''"
                size="26"
                @click="
                  () => {
                    editing = !editing;
                    updateNotes();
                  }
                "
              />
              <feather-icon
                v-if="!editing"
                icon="EditIcon"
                role="button"
                :class="editing ? 'text-success' : ''"
                size="26"
                @click="editing = !editing"
              />
            </div>
          </div>
          <ckeditor
            v-if="editing"
            v-model="noteContent"
            debounce="500"
            class="mt-2"
            :config="ckEditConfig"
            rows="20"
          />
          <div
            v-else-if="noteContent"
            class="mt-1 note-content"
          >
            <span v-html="noteContent" />
          </div>
        </b-card>
        <comments :note-id="myNote ? Number(myNote.id) : 0" />
      </b-col>
      <b-col
        v-if="students.length > 0"
      >
        <b-card
          ref="others-notes"
          class="w-100"
        >
          <div>
            <b-form-group>
              <v-select
                v-model="studentId"
                label="name"
                :options="students"
                :reduce="(student) => student.id"
                placeholder="Select Student"
                @input="$emit('getPairNotes', studentId)"
              >
                <template #selected-option="{ name, icon }">
                  <b-avatar
                    v-if="icon"
                    size="16"
                    class="align-middle mr-25"
                    :src="icon"
                  />
                  <feather-icon
                    v-else
                    icon="UserIcon"
                    class="align-middle mr-25"
                  />
                  <span> {{ name }}</span>
                </template>
                <template #option="{ name, icon }">
                  <b-avatar
                    v-if="icon"
                    size="16"
                    class="align-middle mr-25"
                    :src="icon"
                  />
                  <feather-icon
                    v-else
                    icon="UserIcon"
                    class="align-middle mr-25"
                  />
                  <span> {{ name }}</span>
                </template>
              </v-select>
            </b-form-group>
          </div>
          <div
            v-if="pairNote"
            class="mt-1 note-content"
          >
            <span v-html="pairNote.content" />
          </div>
        </b-card>
        <comments :note-id="pairNote ? Number(pairNote.id) : 0" />
      </b-col>
    </b-row>
    <b-row v-else-if="!hasData">
      <b-card class="w-100">
        <h4>{{ $t('student-notes-module.no-notes-here') }}</h4>
      </b-card>
    </b-row>
    <b-row v-else>
      <b-card class="w-100">
        <h4>{{ $t('student-notes-module.select-problem') }}</h4>
      </b-card>
    </b-row>
  </div>
</template>
<script>
import {
  BCol,
  BRow,
  BCard,
  BAvatar,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import CKEditor from 'ckeditor4-vue'
import Comments from './Comments.vue'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BAvatar,
    vSelect,
    BFormGroup,
    Comments,
    ckeditor: CKEditor.component,
  },
  props: {
    students: {
      type: Array,
      default: () => [],
    },
    myNote: {
      type: Object,
      default: () => null,
    },
    pairNote: {
      type: Object,
      default: () => null,
    },
    selectedStudent: {
      type: [Object, String, Number],
      default: () => null,
    },
    selectedProblem: {
      type: [Object, Number, String],
      default: () => null,
    },
    problems: {
      type: Array,
      default: () => [],
    },
    hasData: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      studentId: this.selectedStudent,
      self: getUserData(),
      editing: false,
      noteContent: '',
      ckEditConfig: {
        toolbar: [
          { name: 'basicstyles', items: ['Underline', 'Italic', 'Bold', 'colorbutton'] },
          ['TextColor', 'BGColor'],
        ],
        extraPlugins: 'colorbutton',
        colorButton_colors: 'CF5D4E,454545,FFF,DDD,CCEAEE,66AB16',
        removeButtons: 'Subscript,Superscript',
      },
    }
  },
  computed: {
    isAStudent() {
      return this.self.usertype === 'student'
    },
    isATeacher() {
      return this.self.usertype === 'teacher'
    },
  },
  watch: {
    selectedStudent: {
      handler(newValue) {
        this.studentId = newValue
        if (newValue) {
          this.$emit('getPairNotes', newValue)
        }
      },
      immediate: true,
    },
    myNote(newValue) {
      this.noteContent = newValue?.content
      this.updateHeight()
    },
  },
  methods: {
    updateHeight() {
      this.resetHeight()
      this.$nextTick(() => setTimeout(this.adjustNotesHeight, 500))
    },
    resetHeight() {
      if (this.$refs['my-notes'] && this.$refs['others-notes']) {
        this.$refs['my-notes'].style.minHeight = 0
        this.$refs['others-notes'].style.minHeight = 0
      }
    },
    adjustNotesHeight() {
      if (this.$refs['my-notes'] && this.$refs['others-notes']) {
        const maxHeight = Math.max(...new Set([
          this.$refs['my-notes'].clientHeight,
          this.$refs['others-notes'].clientHeight,
        ]))
        this.$refs['my-notes'].style.minHeight = `${maxHeight}px`
        this.$refs['others-notes'].style.minHeight = `${maxHeight}px`
      }
    },
    updateNotes() {
      const data = {
        problem_id: this.myNote?.problem_id || this.selectedProblem,
        title:
          this.myNote?.title || (this.problems.find(
          problem => Number(problem.id) === Number(this.selectedProblem),
        )?.name ?? '-'),
        content: this.noteContent || '-',
      }
      if (this.myNote?.id) {
        useJwt
          .updateNote(this.myNote.id, data)
          .then(res => {
            this.updateHeight()
            this.showSuccessMessage(res)
          })
          .catch(error => {
            this.showErrorMessage(error)
          })
        return
      }

      useJwt
        .createNote(data)
        .then(res => {
          this.updateHeight()
          this.showSuccessMessage(res)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
    },
  },
}
</script>
<style>
.note-content {
  white-space: pre-wrap;
  font-size: 15px;
  line-height: 25px;
  word-spacing: 0px;
}
</style>
