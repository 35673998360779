<template>
  <b-col
    md="12"
    class="mb-1"
  >
    <b-card
      no-body
      class="mb-0"
    >
      <h5 class="p-1 pb-0">
        {{ $t('network-module.accounts') }}
      </h5>
      <b-table
        small
        responsive
        :items="getAccountsForTable"
      />

    </b-card>
  </b-col>
</template>
<script>
import {
  BCol, BCard, BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BCol, BCard, BTable,
  },
  computed: {
    getAccountsForTable() {
      return [
        {
          in: '500',
          out: '',
          description: this.$i18n.tc('microsoft-donation'),
        },
        {
          in: '500',
          out: '',
          description: this.$i18n.tc('google-donation'),
        },
        {
          in: '',
          out: '500',
          description: this.$i18n.tc('amazon-donation'),
        },
      ]
    },
  },
}
</script>
