<template>
  <b-modal
    :visible="true"
    no-close-on-backdrop
    content-class="shadow"
    :title="$t('class-module.lesson-template-upload')"
    ok-title="Accept"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="{ errors }"
              name="Csv file"
              rules="required"
            >
              <b-form-file
                ref="refInputEl1"
                v-model="csvFile"
                :browse-text="$t('browseButton')"
                :placeholder="$t('class-module.choose-csv-file')"
                no-drop
                accept=".csv"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <template #modal-footer="{ }">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end gap-2"
        >
          <b-button
            variant="outline-primary"
            class="mr-1"
            @click="downloadSample"
          >
            {{ $t('actions.download-sample') }}
          </b-button>
          <b-button
            variant="primary"
            :disabled="isProcessing"
            @click="handleOk"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('actions.save') }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BRow,
  BCol,
  BFormFile,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BModal,
    BForm,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BButton,
    BSpinner,
  },
  data() {
    return {
      required,
      csvFile: null,
      isProcessing: false,
    }
  },
  methods: {
    downloadSample() {
      const sampleData = [
        {
          class_id: '5138',
          lesson_id: '1432',
          start_date: '2025/01/01',
          end_date: '2025/01/08',
          schedule_type: 'sequenced',
          event_tags: 'testing',
          multi_practice: 'yes',
          engine: 'ddf',
          weakest_skill: 'yes',
          skill: 'reading',
          problems: 'open-ended',
        },
        {
          class_id: '5138',
          lesson_id: '1434',
          start_date: '2025/01/02',
          end_date: '2025/01/10',
          schedule_type: 'sequenced',
          event_tags: 'testing2',
          multi_practice: 'no',
          engine: 'ddf',
          weakest_skill: 'no',
          skill: '',
          problems: '',
        },
      ]
      // Convert array of objects to CSV string
      const headers = Object.keys(sampleData[0]).join(',')
      const rows = sampleData.map(obj => Object.values(obj).join(','))
      const csvContent = [headers, ...rows].join('\n')

      const blob = new Blob([csvContent], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = 'sample_template.csv'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    },
    handleOk() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // Handle file upload logic here
          this.isProcessing = true
          // Add your API call here
          const formData = new FormData()
          formData.append('csv_file', this.$refs.refInputEl1.files[0])

          useJwt.uploadLessonTemplate(formData)
            .then(response => {
              this.csvFile = null
              this.showSuccessMessage(response)
              this.$emit('close')
            })
            .catch(error => {
              this.showErrorMessage(error)
            })
            .finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
  },
}
</script>
