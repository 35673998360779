import {
  USER_TYPE_COMPANY, USER_TYPE_CURRICULUM, USER_TYPE_DISTRICT, USER_TYPE_INTERNATIONAL, USER_TYPE_NATIONAL, USER_TYPE_PARENT, USER_TYPE_SCHOOL, USER_TYPE_STUDENT, USER_TYPE_SUPER, USER_TYPE_TEACHER,
} from '@/const/userType'

// export const getUserTypeSpecificPage = (userType, page) => `/${userType.toLowerCase()}/${page}`
const getLandingPageFor = userType => {
  switch (userType) {
    case USER_TYPE_SUPER:
      return 'super-users'
    case USER_TYPE_INTERNATIONAL:
      return 'international-users'
    case USER_TYPE_NATIONAL:
      return 'national-users'
    case USER_TYPE_COMPANY:
      return 'company-users'
    case USER_TYPE_DISTRICT:
      return 'district-users'
    case USER_TYPE_SCHOOL:
      return 'school-users'
    case USER_TYPE_TEACHER:
      return 'teacher-classes'
    case USER_TYPE_STUDENT: {
      const schoolInfo = JSON.parse(localStorage.getItem('schoolInfo'))
      const dashboard = schoolInfo?.user_meta?.find(metaValue => metaValue.key === 'student_dashboard')
      if (dashboard?.value === 'class_lists') {
        return 'student-classes'
      }
      return 'student-dashboard'
    }
    case USER_TYPE_PARENT:
      return 'parent-users'
    case USER_TYPE_CURRICULUM:
      return 'curriculum-library'
    default:
      return 'home'
  }
}
export default getLandingPageFor
