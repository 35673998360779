import i18n from '@/libs/i18n'

export default [
  {
    value: null,
    text: i18n.tc('messages.select-country'),
  },
  { value: 'Brazil', text: 'Brazil' },
  { value: 'Korea', text: 'Korea' },
  { value: 'Japan', text: 'Japan' },
  { value: 'Vietnam', text: 'Vietnam' },
  { value: 'India', text: 'India' },
  { value: 'Bangladesh', text: 'Bangladesh' },
  { value: 'Ukraine', text: 'Ukraine' },
  { value: 'USA', text: 'USA' },
  { value: 'Thailand', text: 'Thailand' },
  { value: 'Turkey', text: 'Turkey' },
  { value: 'Malaysia', text: 'Malaysia' },
]
