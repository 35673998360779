<template>
  <component
    :is="activeUserProfile"
    v-if="userData"
    :user-data="userData"
  />
</template>

<script>

import { getUserData } from '@/auth/utils'
import {
  USER_TYPE_STUDENT, USER_TYPE_PARENT, USER_TYPE_TEACHER,
} from '@/const/userType'
import TeacherProfile from '@/views/teacher/profile/Index.vue'
import ParentProfile from '@/views/parent/profile/Index.vue'
import StudentProfile from '@/views/student/profile/Profile.vue'
import CommonProfile from '@/views/common/profile/Index.vue'
import string from '@/utils/string'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    TeacherProfile,
    ParentProfile,
    CommonProfile,
    StudentProfile,
  },
  data() {
    return {
      self: getUserData(),
      userId: this.self ? this.self.id : null,
      userData: null,
    }
  },
  computed: {
    activeUserProfile() {
      if (!this.userData || !this.userData.usertype) return ''
      if (this.userData.usertype === USER_TYPE_STUDENT || this.userData.usertype === USER_TYPE_PARENT || this.userData.usertype === USER_TYPE_TEACHER) return `${string.capitalizeFirstLetter(this.userData.usertype)}Profile`
      return 'CommonProfile'
    },
    USER_TYPE_TEACHER() {
      return USER_TYPE_TEACHER
    },
    USER_TYPE_PARENT() {
      return USER_TYPE_PARENT
    },
    USER_TYPE_STUDENT() {
      return USER_TYPE_STUDENT
    },
    isViewingOwnProfile() {
      return this.self != null && this.userId != null && this.self.id === this.userId
    },
  },
  watch: {
    '$route.params.name': {
      handler(name) {
        if (name) {
          // eslint-disable-next-line prefer-destructuring
          this.userId = name.split('_')[1]
        } else this.userId = this.self.id
        this.getUserData()
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.isViewingOwnProfile) this.userData = this.self
  },
  methods: {
    getUserData() {
      useJwt.getUser(this.userId).then(res => {
        this.userData = res.data.data
      })
    },
  },

}
</script>
