<template>
  <b-modal :visible="true"
           :title="$t('problem-preview')"
           size="xl"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <b-overlay :show="isProcessing">
      <b-card v-if="isProcessing">
        <b-skeleton animation="throb"
                    width="85%"
        />
        <b-skeleton animation="throb"
                    width="55%"
        />
        <b-skeleton animation="throb"
                    width="70%"
        />
      </b-card>
      <b-card v-for="analyze,index of analyzedData"
              :key="index"
      >
        <h4>{{ analyze.name }}</h4>
        <MultipleChoice v-if="analyze.problemType === 'multiple'"
                        :question-answer="analyze.questionAnswer"
        />
        <MatchingProblem v-else
                         :question-answer="analyze.questionAnswer"
        />
      </b-card>
    </b-overlay>
    <template #modal-footer>
      <div>
        <b-button variant="outline-success"
                  class="mr-2"
                  :disabled="isSaving"
                  @click="createQuestion"
        >
          <b-spinner v-if="isSaving"
                     small
          />  {{ $t('actions.save') }}
        </b-button>
        <b-button variant="outline-secondary"
                  @click="$emit('close')"
        >
          {{ $t('actions.cancel') }}
        </b-button>
      </div>
    </template>
    <ProblemList v-if="showCreatedProblem"
                 :problems="createdProblems"
                 :lesson-id="props.lessonId"
                 @close="showCreatedProblem = false"
    />
  </b-modal>
</template>
<script setup>
import {
  BModal, BOverlay, BCard, BButton, BSpinner, BSkeleton,
} from 'bootstrap-vue'
import html2canvas from 'html2canvas'
import { onMounted, ref, getCurrentInstance } from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import problemPayload from '../services/problem-payload'
import ProblemList from './ProblemLists.vue'
import MultipleChoice from '../problem-types/OcrMultipleChoice.vue'
import MatchingProblem from '../problem-types/OcrMatching.vue'

/* eslint-disable */
const props = defineProps({
  problemData: {
    type: Array,
    default: () => [],
  },
  canvasPdfId: {
    type: String,
    required: true,
  },
  lessonId: {
    type: [Number, Object],
    default: null,
  },
})

const analyzedData = ref([])
const isProcessing = ref(false)
const isSaving = ref(false)
const showCreatedProblem = ref(false)
const createdProblems = ref([])
const root = getCurrentInstance().proxy.$root

// eslint-disable-next-line no-unused-vars
const convertToGreyscale = base64img => new Promise(resolve => {
  const img = new Image()

  img.onload = function () {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.width = img.width
    canvas.height = img.height
    ctx.drawImage(img, 0, 0, img.width, img.height)

    // Convert to greyscale
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    const { data } = imageData

    for (let i = 0; i < data.length; i += 4) {
      const avg = (data[i] + data[i + 1] + data[i + 2]) / 3
      data[i] = avg
      data[i + 1] = avg
      data[i + 2] = avg
    }

    ctx.putImageData(imageData, 0, 0)

    // Get base64-encoded greyscale image
    const base64Greyscale = canvas.toDataURL('image/png')

    // Resolve the promise with the base64-encoded greyscale image
    resolve(base64Greyscale)
  }

  img.src = base64img
})

const analyzeProblemData = async () => {
  isProcessing.value = true
  const dataRequired = []
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < props.problemData.length; i++) {
    const problem = props.problemData[i]
    const { children } = problem
    const questionAnswer = []
    // eslint-disable-next-line no-plusplus
    for (let c = 0; c < children.length; c++) {
      const item = children[c]
      const targetBox = item.element
      const imageContainer = document.getElementById(props.canvasPdfId)

      const containerRect = imageContainer.getBoundingClientRect()
      const elementRect = targetBox.getBoundingClientRect()

      const offsetLeft = elementRect.left - containerRect.left
      const offsetTop = elementRect.top - containerRect.top
      // eslint-disable-next-line no-await-in-loop
      const canvasData = await html2canvas(imageContainer, {
        x: offsetLeft,
        y: offsetTop,
        width: targetBox.clientWidth,
        height: targetBox.clientHeight,
      })
      const cropImage = canvasData.toDataURL()
      // eslint-disable-next-line no-await-in-loop
     

      let ocrData
      // try {
      //   // eslint-disable-next-line no-await-in-loop
      //   ocrData = await useJwt.getTextFromImage({
      //     file: cropImage,
      //   })
      //   ocrData = {
      //     text: ocrData?.data?.text,
      //   }
      // } catch (err) {
      //   root.showErrorMessage(err)
      // }
      const greyScale = await convertToGreyscale(cropImage)
      // // eslint-disable-next-line no-await-in-loop, no-undef
      ocrData = await Tesseract.recognize(greyScale, problem.lang, {
        logger: m => console.log(m),
      })
      ocrData.text = ocrData.data.text
      console.log(ocrData)
      questionAnswer.push({
        type: item.type,
        text: ocrData.text || '',
        image: cropImage,
        groupId: item.groupId,
        isCorrect: 0,
      })
    }
    dataRequired.push({
      name: problem.name,
      problemType: problem.type,
      questionAnswer,
    })
  }
  isProcessing.value = false
  analyzedData.value = dataRequired
}

const createQuestion = () => {
  const problems = analyzedData.value.map(item => ({
    problem_name: item.name,
    question_type: item.problemType,
    questions_answers: problemPayload({ type: item.problemType, item }),
    decoration_image: item.questionAnswer.filter(i => i.type === 'decoration-image')[0]?.image
  }))
  isSaving.value = true
  useJwt.saveOcrProblems({
    lesson_id: props.lessonId,
    domain_id: 348,
    standard_id: 1904,
    problems,
  }).then(response => {
    createdProblems.value = response.data.data.problems
    showCreatedProblem.value = true
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    isSaving.value = false
  })
}

onMounted(() => {
  setTimeout(() => {
    analyzeProblemData()
  }, 1500);

})
</script>
