<template>
  <!-- create/edit class model -->
  <b-modal
    :id="'modal-'+mode+'-network'"
    :ref="'modal-'+mode+'-network'"
    ok-only
    size="md"
    ok-variant="primary"
    :ok-title="$t('confirm')"
    modal-class="modal-primary"
    centered
    :title=" mode=='create'? $t('network-module.create-network'):$t('network-module.update-network')"
  >
    <b-col md="12">
      <!-- form -->
      <b-form
        class="create-network-form"
        @submit.prevent="mode=='create'?createNetwork():updateNetwork()"
      >
        <button
          type="submit"
          class="d-none"
        />
        <validation-observer
          :ref="mode+'NetworkForm'"
        >
          <b-row>
            <b-col md="12">
              <b-form-group
                :label="$t('network-module.image')"
                label-for="image"
              >

                <!-- media -->
                <b-media no-body>
                  <b-media-aside>

                    <avatar
                      size="50px"
                      :rounded="true"
                      :image="newNetwork.logo"
                    />
                    <!--/ avatar -->
                  </b-media-aside>

                  <b-media-body class="ml-50">
                    <!-- Plain mode -->
                    <div>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Image"
                        rules="size:800|image"
                        :custom-messages="{ image: $t('messages.invalid-image') }"
                        vid="image"
                      >
                        <b-form-file
                          ref="refInputEl1"
                          v-model="networkImage"
                          :browse-text="$t('browseButton')"
                          :placeholder="$t('issue-report-module.choose-a-file')"
                          class="mb-1"
                          accept="image/jpeg, image/png, image/gif"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small><br>
                        <small>{{ $t('files.accepted-images') }}</small>

                      </validation-provider>

                    </div>
                    <!-- upload button -->
                    <div v-if="networkImage && mode=='edit'">
                      <!-- reset -->
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        size="sm"
                        class="mb-75 mr-75"
                        @click="resetLogo()"
                      >
                        {{ $t('network-module.reset') }}
                      </b-button>
                      <!--/ reset -->
                    </div>

                    <div v-if="!networkImage">
                      <b-card-text> {{ $t('network-module.allowed') }} </b-card-text>
                    </div>
                  </b-media-body>
                </b-media>
                <!--/ media -->
              </b-form-group>
            </b-col>

            <!-- network-name -->
            <b-col md="12">
              <b-form-group
                :label="$t('network-module.name')"
                label-for="name"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('network-module.network-name')"
                  rules="required"
                  vid="name"
                >
                  <b-form-input
                    id="network-name"
                    v-model="newNetwork.name"
                    name="network-name"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('network-module.network-name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- network-name -->

            <!-- network language -->
            <b-col
              md="12"
            >
              <b-form-group
                :label="$t('network-module.language')"
                label-for="language"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('network-module.language')"
                  rules="required"
                  vid="language"
                >

                  <b-form-select
                    id="language"
                    v-model="newNetwork.language"
                    :options="languages"
                    name="language"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- network langauge -->

            <!-- network min rating -->
            <b-col md="12">
              <b-form-group
                :label="$t('network-module.min-rating')"
                label-for="min-rating"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('network-module.min-rating')"
                  rules="required|numeric"
                  vid="min_rating"
                >
                  <b-form-input
                    id="min-rating"
                    v-model="newNetwork.min_rating"
                    name="min-rating"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- network min rating-->

            <!-- network min rating -->
            <b-col
              v-if="mode=='edit' && isALeader"
              md="12"
            >
              <b-form-group
                :label="$t('network-module.max-coleaders')"
                label-for="no-of-coleaders"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('network-module.co-leader-limit')"
                  rules="required|numeric"
                  vid="coleader_limit"
                >
                  <b-form-input
                    id="no-of-coleaders"
                    v-model="newNetwork.coleader_limit"
                    name="no-of-coleaders"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- network min rating-->

            <!-- network description -->
            <b-col md="12">
              <b-form-group
                :label="$t('network-module.description')"
                label-for="description"
              >
                <b-form-textarea
                  id="network-description"
                  v-model="newNetwork.description"
                  name="network-description"
                  :placeholder="$t('network-module.description')"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
    </b-col>
    <template #modal-footer="{ }">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        > <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          :disabled="isProcessing"
          @click="mode=='create'?createNetwork():updateNetwork()"
        >
          <b-spinner
            v-show="isProcessing"
            small
          />
          {{ mode=='create'? $t('network-module.create-network') :$t('network-module.update-network') }}
        </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
  <!-- create/edit class model -->
</template>
<script>
// import useJwt from '@/auth/jwt/useJwt'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import {
  BRow, BCol, BButton, BSpinner, BModal, BForm, BCardText, BFormFile, BFormTextarea, BMedia, BMediaAside, BMediaBody, BFormSelect, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ref } from 'vue'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import Avatar from '@/views/common/components/Avatar.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BButton,
    ValidationObserver,
    Avatar,
    ValidationProvider,
    BMedia,
    BMediaAside,
    BFormSelect,
    BMediaBody,
    BFormInput,
    BForm,
    BModal,
    BFormGroup,
    BCardText,
    BFormFile,
    BSpinner,
    BFormTextarea,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    mode: {
      type: String,
      default: 'create',
    },
    network: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      networkImage: null,
      newNetwork: {},
      isProcessing: false,
      languages: [
        { value: 'English', text: 'English' },
        { value: 'Spanish', text: 'Español,' },
        { value: 'Korean', text: '한국어' },
        { value: 'Japanese', text: '日本語' },
        { value: 'Vietnamese', text: 'Tiếng Việt' },
        { value: 'Hindi', text: 'हिन्दी, हिंदी' },
      ],
    }
  },

  computed: {
    ...mapGetters({
      isALeader: 'network/isALeader',
    }),
  },

  watch: {
    networkImage() {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.showPreview = true
          this.newNetwork.logo = reader.result
        },
        false,
      )
      if (this.networkImage) {
        if (/\.(jpe?g|png|gif)$/i.test(this.networkImage.name)) {
          reader.readAsDataURL(this.networkImage)
        }
      }
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)
    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  mounted() {
    this.resetNewNetwork()
    if (this.mode === 'edit') this.newNetwork = JSON.parse(JSON.stringify(this.network))
  },
  methods: {
    resetLogo() {
      this.networkImage = null
      this.newNetwork.logo = this.network.logo
    },
    createNetwork() {
      this.$refs[`${this.mode}NetworkForm`].validate().then(success => {
        if (success) {
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.accessToken}`,
            },
          }
          const data = new FormData()
          if (this.networkImage)data.append('logo', this.$refs.refInputEl1.files[0] || null)
          data.append('name', this.newNetwork.name)
          data.append('description', this.newNetwork.description)
          data.append('min_rating', this.newNetwork.min_rating)
          data.append('language', this.newNetwork.language)
          if (this.isALeader)data.append('coleader_limit', this.newNetwork.coleader_limit)
          data.append('status', this.newNetwork.status)

          this.isProcessing = true
          useJwt.axiosIns.post(jwtDefaultConfig.networkEndpoint, data, config).then(response => {
            this.$refs[`modal-${this.mode}-network`].hide()
            this.resetNewNetwork()
            this.$emit('onNetworkCreated', response)
            this.showSuccessMessage(response)
          })
            .catch(error => {
              if (error.response?.data?.errors) {
                this.$refs[`${this.mode}NetworkForm`].setErrors(error.response.data.errors)
              } else this.showErrorMessage(error)
            }).finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
    updateNetwork() {
      this.$refs[`${this.mode}NetworkForm`].validate().then(success => {
        if (success) {
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.accessToken}`,
            },
          }

          const data = new FormData()
          if (this.networkImage)data.append('logo', this.$refs.refInputEl1.files[0] || null)
          data.append('id', this.newNetwork.id)
          data.append('name', this.newNetwork.name)
          data.append('description', this.newNetwork.description)
          data.append('min_rating', this.newNetwork.min_rating)
          data.append('language', this.newNetwork.language)
          data.append('coleader_limit', this.newNetwork.coleader_limit)
          data.append('status', this.newNetwork.status)
          data.append('_method', 'put')

          this.isProcessing = true
          useJwt.axiosIns.post(`${jwtDefaultConfig.networkEndpoint}/${this.network.id}`, data, config).then(response => {
            this.$refs[`modal-${this.mode}-network`].hide()
            this.isProcessing = false
            this.$store.dispatch('network/getNetwork')
            this.showSuccessMessage(response)
            this.networkImage = null
          })
            .catch(error => {
              this.isProcessing = false
              if (error.response?.data?.errors) {
                this.$refs[`${this.mode}NetworkForm`].setErrors(error.response.data.errors)
              } else this.showErrorMessage(error)
            })
        }
      })
    },

    resetNewNetwork() {
      this.newNetwork = {
        name: '',
        language: this.languages[0].value,
        min_rating: 0,
        description: '',
        status: 1,
        logo: null,
        coleader_limit: 1,
      }
    },

  },
}
</script>
