import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import { calendarEventTags } from '@/const/tags'

export default {
  namespaced: true,
  state: {
    classId: null,
    studentId: null,
    className: null,
    studentName: null,
    calendarOptions: [
      {
        color: 'danger',
        label: 'Personal',
      },
      {
        color: 'primary',
        label: 'Business',
      },
      {
        color: 'warning',
        label: 'Family',
      },
      {
        color: 'success',
        label: 'Holiday',
      },
      {
        color: 'info',
        label: 'ETC',
      },
    ],
    selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC'],
    selectedEventType: null,
    activeView: 'calendar',
  },
  getters: {
    className: state => state.className,
    studentName: state => state.studentName,
  },
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
    SET_CLASS_ID(state, val) {
      state.classId = val
    },
    SET_START_DATE(state, val) {
      state.startDate = val
    },
    SET_END_DATE(state, val) {
      state.endDate = val
    },
    SET_STUDENT_ID(state, val) {
      state.studentId = val
    },
    SET_STUDENT_NAME(state, val) {
      state.studentName = val
    },
    SET_CLASS_NAME(state, val) {
      state.className = val
    },
    SET_SELECTED_EVENTS_TYPE(state, val) {
      state.selectedEventType = val
    },
    SET_ACTIVE_VIEW(state, val) {
      if (val === 0) {
        state.activeView = 'calendar'
      } else {
        state.activeView = 'listView'
      }
    },
    SET_SELECTED_STUDENT(state, val) {
      state.selectedStudent = val
    },
  },
  actions: {
    setClassId({ commit }, data) {
      commit('SET_CLASS_ID', data)
    },
    setStartDate({ commit }, data) {
      commit('SET_START_DATE', data)
    },
    setActiveView({ commit }, data) {
      commit('SET_ACTIVE_VIEW', data)
    },
    setEndDate({ commit }, data) {
      commit('SET_END_DATE', data)
    },
    setStudentId({ commit }, data) {
      commit('SET_STUDENT_ID', data)
    },
    setSelectedEventType({ commit }, data) {
      commit('SET_SELECTED_EVENTS_TYPE', data)
    },
    setSelectedStudent({ commit }, data) {
      commit('SET_SELECTED_STUDENT', data)
    },
    getCourses({ state }) {
      return new Promise((resolve, reject) => {
        useJwt.getCourses(null, {
          params: {
            class_id: state.classId,
          },
        }).then(res => {
          resolve(res)
        }).catch(err => reject(err))
      })
    },
    getGrades({ state }) {
      return new Promise((resolve, reject) => {
        useJwt.getGradeLists(state.classId).then(res => {
          resolve(res)
        }).catch(err => reject(err))
      })
    },
    getLessonGroups(ctx, cid) {
      return new Promise((resolve, reject) => {
        useJwt.getLessonGroupByCourse({
          params: { cid, type: 'all' },
        }).then(res => {
          const data = res.data.data.map(({ id, name }) => ({
            id,
            value: id,
            text: name,
          }))
          data.unshift({ value: null, text: 'Please select a Lesson Group' })
          resolve(data)
        }).catch(err => reject(err))
      })
    },
    getLessons(ctx, gid) {
      return new Promise((resolve, reject) => {
        useJwt.getLesson(gid).then(res => {
          const data = res.data.data.data.map(({ id, name }) => ({
            id,
            value: id,
            text: name,
          }))
          data.unshift({ value: null, text: i18n.tc('messages.select-lesson') })
          resolve(data)
        }).catch(err => reject(err))
      })
    },
    async fetchEvents({ state, commit }) {
      const addOneDay = date => {
        const dateObj = new Date(date)
        return dateObj.setDate(dateObj.getDate() + 1)
      }
      const getTagInfo = tags => {
        if (tags && tags.length) {
          return ` [${tags.map(t => calendarEventTags.find(v => v.value === t)?.text || []).join(',')}]`
        }
        return ''
      }
      return new Promise((resolve, reject) => {
        if (!state.classId) {
          return
        }
        const getEventTitle = e => {
          if (e.event_type?.startsWith('class_test')) return `${e.class_test_info?.name} [${i18n.tc('student-diary-module.class-test')}]${getTagInfo(e.event_tags)}`
          if (['instruction', 'rea_event'].includes(e.event_type)) return e.event_title || '-'
          if (e.event_type === 'vocab_words') return `${e.lesson?.name} [${e.id}]]${getTagInfo(e.event_tags)}`
          if (e.event_type === 'follow_up') return `${e.lesson?.name}${getTagInfo(e.event_tags)}`
          if (e.event_type === 'calendar') return `${e.lesson?.name}${getTagInfo(e.event_tags)}`
          return e.lesson?.name || 'LESSON NOT FOUND'
        }

        const startDate = state.activeView === 'calendar' ? state.startDate : null
        const endDate = state.activeView === 'calendar' ? state.endDate : null
        const params = {
          class_id: state.classId,
          startDate,
          endDate,
          event_type: state.selectedEventType || new URLSearchParams(window.location.search).get('filter'),
        }
        if (state.studentId) {
          params.student_id = state.studentId
        }
        useJwt.getCalendarEvents({
          params,
        }).then(async res => {
          commit('SET_STUDENT_NAME', res.data.data.student_name)
          commit('SET_CLASS_NAME', res.data.data.class_name)
          let data = res.data.data.events
          data = data.map(e => ({
            id: e.id,
            title: `${e.is_locked ? '🔒 ' : ''}${getEventTitle(e)}`,
            start: e.start_time,
            end: addOneDay(e.end_time),
            allDay: false,
            testInfo: e.class_test_info,
            order_index: Number(e.order_index),
            // eslint-disable-next-line no-nested-ternary
            // color: getEventColor(e),
            extendedProps: {
              isLocked: e.is_locked,
              lessonName: e.lesson?.name,
              lessonId: e.event_type?.startsWith('class_test') ? e.class_test_info?.lessons : e.lesson?.id,
              courseName: e.course.name,
              courseId: e.course.id,
              gradeId: e.grade?.id,
              gradeName: e.grade?.name,
              lessonPlans: e.lesson_plans.filter(l => l.lesson_id === e.lesson.id).map(l => ({ id: l.id, text: l.title })),
              lessonGroupId: e.lesson_group.id,
              lessonGroupName: e.lesson_group.name,
              nonSequence: e.event_type === 'non-sequenced',
              event_tags: e.event_tags || [],
              event_type: e.event_type,
              event_title: e.event_title,
              event_link: e.event_link,
              event_description: e.event_description,
              students: e.assigned_to,
              assignment_type: e.assignment_type,
              order_index: e.order_index,
              hasTest: e.event_type?.startsWith('class_test'),
              calendar: 'Business',
              schedule_type: e.schedule_type,
              studentId: e.student_id,
              has_multi_practice: e.has_multi_practice,
              weakest_skill: {
                has_multi_practice: e.has_multi_practice,
                rules: e.rules,
                default_engine: e.default_engine,
              },
            },
          }))
          //   let events = []
          //   await dispatch('getLessonPlannerEvents').then(response => {
          //     const lessonPlannerEvents = response
          //     events = data.concat(lessonPlannerEvents)
          //   })

          resolve({ data })
        }).catch(err => reject(err))
      })
    },
    async getLessonPlannerEvents() {
      let data = []
      await useJwt.getLessonPlanner().then(res => {
        data = res.data.data.map(e => ({
          id: e.id,
          title: `Plan : ${e.name}`,
          start: e.created_at,
          end: e.created_at,
          allDay: false,
          color: 'green',
          extendedProps: {
            calendar: 'Holiday',
            event_type: 'lesson-plan',
            lessonId: e.lesson_id,
          },
        }))
      })
      return data
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        useJwt.createCalendarEvent(event).then(res => resolve(res)).catch(err => reject(err))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        useJwt.updateCalendarEvent(event.id, event).then(res => resolve(res)).catch(err => reject(err))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        useJwt.deleteCalendarEvent(id).then(res => resolve(res)).catch(err => reject(err))
      })
    },
  },
}
