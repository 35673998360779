import { MEMBER_TYPE_COLEADER, MEMBER_TYPE_LEADER, MEMBER_TYPE_MEMBER } from '@/const/networkMemberType'

const getters = {
  self: state => state.self,
  isProcessing: state => state.isProcessing,
  network: state => state.network,
  members: state => state.members,
  activeTab: state => state.activeTab,
  coLeaders: state => state.coLeaders,
  requestId: state => state.requestId,
  myNetworkMembership: state => state.myNetworkMembership,
  verifiedArticles: state => state.verifiedArticles,
  verifiedArticlesPaginationData: state => state.verifiedArticlesPaginationData,
  unVerifiedArticles: state => state.unVerifiedArticles,
  unVerifiedArticlesPaginationData: state => state.unVerifiedArticlesPaginationData,
  networkId: state => state.networkId,
  hasRequested: state => state.myNetworkMembership !== 'not_requested',
  hasPendingRequest: state => state.myNetworkMembership === 'requested',
  isInvited: state => state.myNetworkMembership === 'invited',
  isALeader: state => state.myNetworkMembership && (state.myNetworkMembership === MEMBER_TYPE_LEADER || state.myNetworkMembership === 'owner'),
  isAMember: state => state.myNetworkMembership && (state.myNetworkMembership === MEMBER_TYPE_MEMBER),
  isACoLeader: state => state.myNetworkMembership && (state.myNetworkMembership === MEMBER_TYPE_COLEADER),
  hasJoinedNetwork: state => state.myNetworkMembership !== null && state.myNetworkMembership !== 'requested' && state.myNetworkMembership !== 'invited' && state.myNetworkMembership !== 'not_requested',
  shouldShowUnverifiedArticles: state => state.myNetworkMembership === MEMBER_TYPE_LEADER || state.myNetworkMembership === MEMBER_TYPE_COLEADER || state.myNetworkMembership === 'owner',
}

export default getters
