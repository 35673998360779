/* eslint-disable import/no-cycle */
import useJwt from '@/auth/jwt/useJwt'
import useApollo from '@/@core/graphql/useApollo'
import { problemTags } from '@/const/tags'

function initialState() {
  return {
    levels: ['A1', 'A2', 'B1', 'B2'],
    domains: [],
    lessonName: '',
    showLangLevel: true,
    activeTabIndex: 3,
    activeProblemType: 'matching',
    showError: false,
    totalUserScore: null,
    animatedLayoutConfig: null,
    activeProblem: null,
    hideFeedBack: false,
    hideStatement: false,
    isSubmittingQuestion: false,
    create: {
      lesson_id: null,
      standard_id: null,
      selectedDomain: null,
      standards: [{ value: null, text: 'Please select a standard' }],
      name: null,
      problemDescription: null,
      theme: 0,
      stType: ['image', 'image', 'image', 'image'],
      statement: ['', '', '', ''],
      statementTemplate: [null, null, null, null],
      feedbackTemplate: [null, null, null, null],
      statementImage: { url: ['', '', '', ''], file: ['', '', '', ''] },
      statementAudio: { url: ['', '', '', ''], file: ['', '', '', ''] },
      fbType: ['image', 'image', 'image', 'image'],
      feedback: ['', '', '', ''],
      feedbackImage: { url: ['', '', '', ''], file: ['', '', '', ''] },
      feedbackAudio: { url: ['', '', '', ''], file: ['', '', '', ''] },
      tags: problemTags,
      selectedTags: [],
      cefr: {
        type: [null, null, null, null],
        selectedSubject: [null, null, null, null],
        selectedDomain: [null, null, null, null],
        selectedStandard: [null, null, null, null],
        cefrSubjectList: [],
        cefrDomainList: [[{ value: null, text: 'Please select a domain' }], [{ value: null, text: 'Please select a domain' }], [{ value: null, text: 'Please select a domain' }], [{ value: null, text: 'Please select a domain' }]],
        cefrStandardList: [[{ value: null, text: 'Please select a standard' }], [{ value: null, text: 'Please select a standard' }], [{ value: null, text: 'Please select a standard' }], [{ value: null, text: 'Please select a standard' }]],
      },
      questionType: 'text',
      answerType: 'text',
      isCaseSensative: false,
      hasAnimatedLayout: false,
      animatedLayoutData: null,
      blank: {
        type: 'blank',
        questions: {
          text: [[''], [''], [''], ['']],
          extraFile: [[''], [''], [''], ['']],
        },
        template: {
          templateData: [
            null, null, null, null,
          ],
        },
        hideAnswerHints: 0,
        hideImageHints: 0,
        hideTextHints: 0,
      },
      drag_position: {
        type: 'drag_position',
        questions: {
          text: [[''], [''], [''], ['']],
          extraFile: [[''], [''], [''], ['']],
        },
        template: {
          templateData: [
            null, null, null, null,
          ],
        },
        hideAnswerHints: 0,
        hideImageHints: 0,
        hideTextHints: 0,
      },
      matching: {
        questions: {
          text: [[''], [''], [''], ['']],
          image: [[''], [''], [''], ['']],
          imageUrl: [[''], [''], [''], ['']],
          audio: [[''], [''], [''], ['']],
          caption: [[''], [''], [''], ['']],
          audioUrl: [[''], [''], [''], ['']],
        },
        answers: {
          text: [[''], [''], [''], ['']],
          image: [[''], [''], [''], ['']],
          imageUrl: [[''], [''], [''], ['']],
          audio: [[''], [''], [''], ['']],
          caption: [[''], [''], [''], ['']],
          audioUrl: [[''], [''], [''], ['']],
        },
        deleteIndex: null,
      },
      multiple: {
        questions: {
          text: [[''], [''], [''], ['']],
          image: [[''], [''], [''], ['']],
          imageUrl: [[''], [''], [''], ['']],
          audio: [[''], [''], [''], ['']],
          caption: [[''], [''], [''], ['']],
          audioUrl: [[''], [''], [''], ['']],
          extraFile: [[{}], [{}], [{}], [{}]],
        },
        answers: {
          text: [[''], [''], [''], ['']],
          image: [[''], [''], [''], ['']],
          imageUrl: [[''], [''], [''], ['']],
          audio: [[''], [''], [''], ['']],
          caption: [[''], [''], [''], ['']],
          audioUrl: [[''], [''], [''], ['']],
        },
        is_correct: [[false], [false], [false], [false]],
        deleteIndex: null,
        answerChoice: 'multiple',
      },
      speech: {
        questions: {
          text: [[''], [''], [''], ['']],
          image: [[''], [''], [''], ['']],
          imageUrl: [[''], [''], [''], ['']],
          extraFile: [[''], [''], [''], ['']],
        },
        answers: {
          text: [[''], [''], [''], ['']],
          image: [[''], [''], [''], ['']],
          imageUrl: [[''], [''], [''], ['']],
          extraFile: [[''], [''], [''], ['']],
          extraAnswers: [[[]], [[]], [[]], [[]]],
        },
        deleteIndex: null,
      },
      category: {
        type: 'category',
        items: [
          [
            {
              question: '', answers: [''], questionType: 'text', images: [], audio: [],
            },
          ],
          [
            {
              question: '', answers: [''], questionType: 'text', images: [], audio: [],
            },
          ],
          [
            {
              question: '', answers: [''], questionType: 'text', images: [], audio: [],
            },
          ],
          [
            {
              question: '', answers: [''], questionType: 'text', images: [], audio: [],
            },
          ],
        ],
        finalFormData: '',
      },
      openEndWriting: {
        type: 'open-end-writing',
        taskTime: '',
        preparationTime: '',
        items: [
          [
            {
              question: '', answers: [], questionType: 'text', images: [],
            },
          ],
          [
            {
              question: '', answers: [], questionType: 'text', images: [],
            },
          ],
          [
            {
              question: '', answers: [], questionType: 'text', images: [],
            },
          ],
          [
            {
              question: '', answers: [], questionType: 'text', images: [],
            },
          ],
        ],
      },
      text_selection: {
        type: 'text_selection',
        questions: {
          text: [[''], [''], [''], ['']],
          extraFile: [[''], [''], [''], ['']],
        },
        template: {
          templateData: [
            null, null, null, null,
          ],
        },
      },
      problemGroupType: 'text',
      activeTimestamp: null,
      activePoint: null,
      activeCoordinate: null,
      activeStep: 0,
      hotspot: { image: null, id: null, type: null },
      video: { url: null, id: null },
      videoCreated: false,
      hotspotCreated: false,
      problemCreatedCoordinate: [],
      isEditing: false,
      timestamps: [],
      coordinates: [],
      points: [],
      imageWidth: null,
      imageHeight: null,
    },
    lastCreatedProblem: null,
    themes: [
      { value: 0, text: 'Default' },
      { value: 1, text: 'Theme-1' },
      { value: 2, text: 'Theme-2' },
      { value: 3, text: 'Theme-3' },
      { value: 4, text: 'Theme-4' },
      { value: 5, text: 'Theme-5' },
      { value: 6, text: 'Theme-6' },
      { value: 7, text: 'Theme-7' },
      { value: 8, text: 'Theme-8' },
      { value: 9, text: 'Theme-9' },
    ],
    typeOptions: [
      { value: 'text', text: 'Text', disabled: false },
      { value: 'image', text: 'Image', disabled: false },
      { value: 'audio', text: 'Audio', disabled: false },
    ],
    shapes: [
      { text: 'Hexagon', value: 'hexagon.png', size: 'lg-t-20' },
      { text: 'Board with Owl', value: 'cyan_rectangle.png', size: 'md' },
      { text: 'Board with Worm', value: 'green_rectangle.png', size: 'md' },
      { text: 'Horizontal Board', value: 'horizontal_board.png', size: 'xl' },
    ],
    validations: [],
    cachedCerfSubject: [],
    ttsSupport: false,
    skillType: null,
    isProblemConversionAllowed: false,
    problemConversion: false,
  }
}
export default {
  namespaced: true,
  state: initialState(),
  getters: {
    canShowEbook: state => !state.activeProblem?.lesson_info?.hide_ebook,
    getShapes: state => state.shapes,
    getAnswerCluster(state) {
      const animatedConfig = state.animatedLayoutConfig
      if (animatedConfig && animatedConfig.answerCluster) {
        if (animatedConfig.answerCluster === 'horizontal') {
          return 6
        }
        if (animatedConfig.answerCluster === 'vertical') {
          return 12
        }
      }
      return null
    },
    hasAnimatedLayout: state => !!state.animatedLayoutConfig,
    isValid(state) {
      let valid = true
      const hasHotSpotActiveProblem = (state.create.problemGroupType === 'hot-spot' && state.lastCreatedProblem)
      if (!hasHotSpotActiveProblem) {
        if (!state.create.name && state.create.name > 256) valid = false
        if (!state.create.standard_id) valid = false
      }
      if (state.showLangLevel && !state.hideFeedBack && !state.hideStatement) {
        if (state.create.problemGroupType === 'text') {
          if (state.create.statementTemplate.includes(null)) valid = false
        }
        if (state.create.problemGroupType === 'hot-spot') {
          if (state.create.statement.includes('')) valid = false
          if (state.create.feedback.includes('')) valid = false
        } else if (!state.create.hideStatement && state.create.feedbackTemplate.includes(null)) valid = false
        if (state.activeProblemType === 'matching' || state.activeProblemType === 'multiple' || state.activeProblemType === 'speech') {
          let rowValid = true
          state.create[state.activeProblemType].questions[state.create.questionType].forEach(ques => {
            let dataValid = true
            ques.forEach(d => {
              if (!d) dataValid = false
            })
            if (!dataValid) rowValid = false
          })
          state.create[state.activeProblemType].answers[state.create.answerType].forEach(ques => {
            let dataValid = true
            ques.forEach(d => {
              if (!d) dataValid = false
            })
            if (!dataValid) rowValid = false
          })
          if (!rowValid) valid = false
        // eslint-disable-next-line no-empty
        } else if (['category', 'open-end-writing', 'text_selection'].includes(state.activeProblemType)) {
        } else if (state.activeProblemType === 'drag_position') {
          state.create.drag_position.template.templateData.forEach(ques => {
            if (!ques) valid = false
          })
        } else {
          state.create.blank.template.templateData.forEach(ques => {
            if (!ques) valid = false
          })
        }
      } else if (!state.hideFeedBack && !state.hideStatement) {
        if (state.create.problemGroupType === 'text') {
          if (state.create.statementTemplate[0] === null) valid = false
        }
        if (state.create.problemGroupType === 'hot-spot') {
          if (state.create.statement[0] === '') valid = false
          if (state.create.feedback[0] === '') valid = false
        } else if (state.create.feedbackTemplate[0] === null) valid = false
        if (state.activeProblemType === 'matching' || state.activeProblemType === 'multiple' || state.activeProblemType === 'speech') {
          let rowValid = true
          state.create[state.activeProblemType].questions[state.create.questionType][0].forEach(d => {
            if (!d) rowValid = false
          })
          state.create[state.activeProblemType].answers[state.create.answerType][0].forEach(d => {
            if (!d) rowValid = false
          })
          if (!rowValid) valid = false
        } else if (!['category', 'open-end-writing', 'text_selection'].includes(state.activeProblemType) && !state.create.blank.template.templateData[0] && !state.create.drag_position.template.templateData[0]) valid = false
      }
      return valid
    },
    probelmDetailIsValid(state) {
      let valid = true
      // if (state.create.problemGroupType === 'text') {
      if (!state.create.name || state.create.name.length > 256) valid = false
      if (!state.create.problemGroupType) valid = false
      if (!state.create.standard_id) valid = false
      if (state.create.selectedTags.length === 0) valid = false
      if (!state.skillType) valid = false
      // }
      return valid
    },
    isLevelValid(state) {
      let valid = [true, true, true, true]
      const hasHotSpotActiveProblem = (state.create.problemGroupType === 'hot-spot' && state.lastCreatedProblem)
      if (!hasHotSpotActiveProblem) {
        if (!state.create.name && state.create.name > 256) valid = false
        if (!state.create.standard_id) valid = false
      }
      if (state.create.problemGroupType === 'text' && !state.hideStatement) {
        state.create.statementTemplate.forEach((st, i) => {
          if (st === null) valid[i] = false
        })
      }
      if (state.create.problemGroupType !== 'hot-spot' && !state.hideFeedBack) {
        state.create.feedbackTemplate.forEach((ft, i) => {
          if (ft === null) valid[i] = false
        })
      }
      if (state.activeProblemType === 'matching' || state.activeProblemType === 'multiple' || state.activeProblemType === 'speech') {
        state.create[state.activeProblemType].questions[state.create.questionType].forEach((ques, i) => {
          let dataValid = true
          ques.forEach(d => {
            if (!d) dataValid = false
          })
          if (!dataValid) valid[i] = false
        })
        state.create[state.activeProblemType].answers[state.create.answerType].forEach((ques, i) => {
          let dataValid = true
          ques.forEach(d => {
            if (!d) dataValid = false
          })
          if (!dataValid) valid[i] = false
        })
      // eslint-disable-next-line no-empty
      } else if (['category', 'open-end-writing'].includes(state.activeProblemType)) {

      } else if (state.activeProblemType === 'drag_position') {
        state.create.drag_position.template.templateData.forEach((ques, i) => {
          if (!ques) valid[i] = false
        })
      } else {
        state.create.blank.template.templateData.forEach((ques, i) => {
          if (!ques) valid[i] = false
        })
      }
      return valid
    },
    isMultipleValid(state) {
      let isValid = [false, false, false, false]
      if (state.showLangLevel) {
        state.create.multiple.is_correct.forEach((c, i) => {
          if (c.includes(true)) isValid[i] = true
        })
      } else if (state.create.multiple.is_correct[0].includes(true)) isValid = [true, true, true, true]
      return isValid
    },
    previewData(state) {
      let questions
      if (state.activeProblemType === 'multiple') {
        questions = state.create.multiple.questions.text[state.activeTabIndex].map((e, index) => ({
          id: index,
          text: e,
          extra_file: JSON.stringify({ caption: state.create[state.activeProblemType].questions?.caption?.[state.activeTabIndex]?.[index] }),
          data_type: state.create.questionType,
          file: state.create.questionType === 'image' ? state.create.multiple.questions.imageUrl[state.activeTabIndex][index] : state.create.multiple.questions.audioUrl[state.activeTabIndex][index],
          answers: state.create.multiple.answers.text[state.activeTabIndex].map((ans, i) => (
            {
              id: i,
              extra_file: JSON.stringify({ caption: state.create[state.activeProblemType].answers?.caption?.[state.activeTabIndex]?.[index] }),
              data_type: state.create.answerType,
              text: ans,
              file: state.create.answerType === 'image' ? state.create.multiple.answers.imageUrl[state.activeTabIndex][i] : state.create.multiple.answers.audioUrl[state.activeTabIndex][i],
            }
          )),
        }))
      } else if (state.activeProblemType === 'matching') {
        questions = state.create[state.activeProblemType].questions.text[state.activeTabIndex].map((e, index) => ({
          id: index,
          text: e,
          extra_file: JSON.stringify({ caption: state.create[state.activeProblemType].questions?.caption?.[state.activeTabIndex]?.[index] }),
          data_type: state.create.questionType,
          file: state.create.questionType === 'image' ? state.create[state.activeProblemType].questions.imageUrl[state.activeTabIndex][index] : state.create[state.activeProblemType].questions.audioUrl[state.activeTabIndex][index],
          answers: [{
            id: index,
            extra_file: JSON.stringify({ caption: state.create[state.activeProblemType].answers?.caption?.[state.activeTabIndex]?.[index] }),
            data_type: state.create.answerType,
            text: state.create[state.activeProblemType].answers.text[state.activeTabIndex][index],
            file: state.create.answerType === 'image' ? state.create[state.activeProblemType].answers.imageUrl[state.activeTabIndex][index] : state.create[state.activeProblemType].answers.audioUrl[state.activeTabIndex][index],
          }],
        }))
      } else if (state.activeProblemType === 'speech') {
        const activeProblemTypeQuestions = state.create[state.activeProblemType].questions
        questions = state.create[state.activeProblemType].questions.text[state.activeTabIndex].map((e, index) => ({
          id: index,
          text: e,
          image: activeProblemTypeQuestions.image[state.activeTabIndex][index],
          imageUrl: activeProblemTypeQuestions.imageUrl[state.activeTabIndex][index],
          data_type: state.create.questionType,
          answers: [{
            id: index,
            data_type: state.create.answerType,
            text: state.create[state.activeProblemType].answers.text[state.activeTabIndex][index],
            imageUrl: state.create[state.activeProblemType].answers.imageUrl[state.activeTabIndex][index],
          }],
        }))
      } else if (state.activeProblemType === 'text_selection') {
        const data = JSON.stringify(state.create[state.activeProblemType].template.templateData[state.activeTabIndex])
        questions = [{
          id: 0,
          extra_file: state.create[state.activeProblemType]?.questions.extraFile[state.activeTabIndex][0],
          text: data,
          answers: [],
        }]
      } else {
        const data = JSON.stringify(state.create[state.activeProblemType].template.templateData[state.activeTabIndex])
        const answers = []
        if (data.match(/\*\*\*(.*?)\*\*\*/g)) {
          data.match(/\*\*\*(.*?)\*\*\*/g).forEach((answer, index) => {
            answers.push(
              {
                id: index,
                text: answer.replace(/\*\*\*/g, ''),
              },
            )
          })
        }
        questions = [{
          id: 0,
          extra_file: state.create[state.activeProblemType]?.questions.extraFile[state.activeTabIndex][0],
          text: data.replace(/\*\*\*.*?[^\\)]\*\*\*/g, '[ ]'),
          answers: answers.sort(() => Math.random() - 0.5),
        }]
      }

      let problemType
      if (state.activeProblemType === 'blank') {
        problemType = state.create[state.activeProblemType].type
      } else {
        problemType = state.activeProblemType
      }

      return {
        problemType,
        questionType: state.create.questionType,
        answerType: state.create.answerType,
        theme: state.create.theme,
        feedback: {
          type: state.create.fbType[state.activeTabIndex],
          text: state.create.feedback[state.activeTabIndex],
          image: state.create.fbType[state.activeTabIndex] === 'image' ? state.create.feedbackImage.url[state.activeTabIndex] : null,
          audio: state.create.fbType[state.activeTabIndex] === 'audio' ? state.create.feedbackAudio.url[state.activeTabIndex] : null,
          template: state.create.feedbackTemplate[state.activeTabIndex],
        },
        statement: {
          type: state.create.stType[state.activeTabIndex],
          text: state.create.statement[state.activeTabIndex],
          image: state.create.stType[state.activeTabIndex] === 'image' ? state.create.statementImage.url[state.activeTabIndex] : null,
          audio: state.create.stType[state.activeTabIndex] === 'audio' ? state.create.statementAudio.url[state.activeTabIndex] : null,
          template: state.create.statementTemplate[state.activeTabIndex],
        },
        questions: [...questions],
        level: state.activeTabIndex + 1,
      }
    },
    getQuestionLength(state) {
      return state.create[state.activeProblemType].questions.text[0].length
    },
    getAnswerLength(state) {
      return state.create[state.activeProblemType].answers.text[0].length
    },
    getQuestions(state) {
      return state.create[state.activeProblemType].questions
    },
    getAnswers(state) {
      return state.create[state.activeProblemType].answers
    },
    getIsCorrect(state) {
      return state.create.multiple.is_correct
    },
    getTimestamps(state) {
      return state.create.timestamps.filter(t => t !== null)
    },
    getTimestampsByTime(state) {
      return state.create.timestamps.filter(t => t !== null).map(v => {
        let timeList
        if (!v) timeList = ['0', '00']
        else timeList = v.split(':')
        return { minute: timeList[0], second: timeList[1] }
      })
    },
    activeTimestampCreated(state) {
      const activeTimestampId = state.create.activeTimestamp
      const activeTimestamp = state.create.timestamps.find(t => t.id === activeTimestampId)
      return activeTimestamp ? activeTimestamp.created : false
    },
    getValidations(state) {
      return state.validations
    },
  },
  mutations: {
    UPDATE_IS_SUBMITTING(state, payload) {
      state.isSubmittingQuestion = payload
    },
    UPDATE_ACTIVE_PROBLEM(state, payload) {
      state.activeProblem = payload
    },
    TOGGLE_ANIMATED_LAYOUT_CREATE(state, payload) {
      state.create.hasAnimatedLayout = payload
    },
    UPDATE_ANIMATED_LAYOUT_SETTING(state, payload) {
      state.animatedLayoutConfig = payload
    },
    UPDATE_ANIMATED_LAYOUT_CREATE(state, payload) {
      state.create.animatedLayoutData = payload
    },
    SET_SKILL_TYPE(state, payload) {
      state.skillType = payload
    },
    SET_TTS_SUPPORT(state, payload) {
      state.ttsSupport = payload
    },
    SET_CURRENT_OVERALL_SCORE(state, payload) {
      state.totalUserScore = payload
    },
    RESET_QUESTION(state) {
      state.create.matching = initialState().create.matching
      state.create.multiple = initialState().create.multiple
      state.create.speech = initialState().create.speech
      state.create.blank = initialState().create.blank
      state.create.drag_position = initialState().create.blank
      state.create.category = initialState().create.category
      state.create.statement = initialState().create.statement
      state.create.feedback = initialState().create.feedback
      state.create.feedbackTemplate = initialState().create.feedbackTemplate
      state.create.statementTemplate = initialState().create.statementTemplate
    },
    SET_DOMAINS(state, payload) {
      state.domains = [
        { value: null, text: 'Please select a domain' },
        ...payload.map(domain => ({
          value: domain.id,
          text: domain.name,
        })),
      ]
    },
    SET_PROBLEM_GROUP_TYPE(state, payload) {
      state.create.problemGroupType = payload
    },
    SET_CATEGORY_QUESTION(state, payload) {
      state.create.category.items = payload
    },
    SET_CATEGORY_FINAL_FORM_DATA(state, payload) {
      state.create.category.finalFormData = payload
    },
    SET_LESSON_NAME(state, payload) {
      state.lessonName = payload
    },
    SET_LANG_VERSION(state, payload) {
      if (payload.toLowerCase() === 'normal') state.showLangLevel = false
    },
    SET_LESSON_ID(state, payload) {
      state.create.lesson_id = payload
    },
    UPDATE_LAST_CREATED_PROBLEM(state, payload) {
      if (state.create.hotspot.type) {
        state.lastCreatedProblem = payload.id
      }
    },
    SET_CEFR_SUBJECT_LIST(state, payload) {
      state.create.cefr.cefrSubjectList = [
        { value: null, text: 'Please select a subject' },
        ...payload.map(st => ({
          value: st.id,
          text: st.name,
        })),
      ]
      state.cachedCerfSubject = payload
    },
    SET_CEFR_DOMAIN_LIST(state, payload) {
      const { domains, index } = payload
      const FEILD = state.create.cefr.cefrDomainList
      FEILD[index] = [
        { value: null, text: 'Please select a domain' },
        ...domains.map(st => ({
          value: st.id,
          text: st.name,
        })),
      ]
      state.create.cefr.cefrDomainList = [...FEILD]
    },
    SET_PROBLEM_DESCRIPTION(state, payload) {
      state.create.problemDescription = payload
    },
    SET_CEFR_STANDARD_LIST(state, payload) {
      const { standards, index } = payload
      const FEILD = state.create.cefr.cefrStandardList
      FEILD[index] = [
        { value: null, text: 'Please select a standard' },
        ...standards.map(st => ({
          value: st.id,
          text: st.name,
        })),
      ]
      state.create.cefr.cefrStandardList = [...FEILD]
    },
    SET_CEFR(state, payload) {
      const { index, name, value } = payload
      const FEILD = state.create.cefr[name]
      FEILD[index] = value
      state.create.cefr = { ...state.create.cefr, [name]: FEILD }
    },
    SET_ACTIVE_TAB_INDEX(state, payload) {
      const index = state.activeTabIndex
      for (let i = 0; i < 4; i += 1) {
        if (i !== index) {
          const stTypeList = state.create.stType
          if (stTypeList[index] === 'image') {
            if (!state.create.statementImage.url[i]) {
              const { url } = state.create.statementImage
              const { file } = state.create.statementImage
              url[i] = url[index]
              file[i] = file[index]
              state.create.statementImage = { ...state.create.statementImage, url, file }
              stTypeList[i] = stTypeList[index]
            }
          } else if (!state.create.statementAudio.url[i]) {
            const { url } = state.create.statementAudio
            const { file } = state.create.statementAudio
            url[i] = url[index]
            file[i] = file[index]
            state.create.statementAudio = { ...state.create.statementAudio, url, file }
            stTypeList[i] = stTypeList[index]
          }
          if (!state.create.statement[i]) {
            const { statement } = state.create
            statement[i] = statement[index]
            state.create = { ...state.create, statement }
          }
          if (!state.create.statementTemplate[i]) {
            const { statementTemplate } = state.create
            if (statementTemplate[index]) {
              this.dispatch('problem/storeTemplate', { copyIndex: i, template: statementTemplate[index], type: 'statement' })
            }
          }
          if (!state.create.feedbackTemplate[i]) {
            const { feedbackTemplate } = state.create
            if (feedbackTemplate[index]) {
              this.dispatch('problem/storeTemplate', { copyIndex: i, template: feedbackTemplate[index], type: 'feedback' })
            }
          }
          const fbTypeList = state.create.fbType
          if (fbTypeList[index] === 'image') {
            if (!state.create.feedbackImage.url[i]) {
              const { url } = state.create.feedbackImage
              const { file } = state.create.feedbackImage
              url[i] = url[index]
              file[i] = file[index]
              state.create.feedbackImage = { ...state.create.feedbackImage, url, file }
              fbTypeList[i] = fbTypeList[index]
            }
          } else if (!state.create.feedbackAudio.url[i]) {
            const { url } = state.create.feedbackAudio
            const { file } = state.create.feedbackAudio
            url[i] = url[index]
            file[i] = file[index]
            state.create.feedbackAudio = { ...state.create.feedbackAudio, url, file }
            fbTypeList[i] = fbTypeList[index]
          }
          if (!state.create.feedback[i]) {
            const { feedback } = state.create
            feedback[i] = feedback[index]
            state.create = { ...state.create, feedback }
          }
        }
      }
      if (state.activeProblemType === 'blank' || state.activeProblemType === 'drag_position' || state.activeProblemType === 'text_selection') {
        const questionList = state.create[state.activeProblemType].template.templateData
        for (let i = 0; i < 4; i += 1) {
          const question = state.create[state.activeProblemType].template.templateData[i]
          if (!question) {
            questionList[i] = state.create[state.activeProblemType].template.templateData[index]
          }
        }
      } else if (state.activeProblemType === 'matching' || state.activeProblemType === 'multiple') {
        for (let i = 0; i < 4; i += 1) {
          const problemType = state.activeProblemType
          Object.keys(state.create[problemType].questions).forEach(type => {
            const questions = state.create[problemType].questions[type][i]
            questions.forEach((ques, j) => {
              if (!ques) {
                questions[j] = state.create[problemType].questions[type][index][j]
              }
            })
            state.create[problemType].questions[type][i] = [...questions]
            const answers = state.create[problemType].answers[type][i]
            answers.forEach((ans, j) => {
              if (!ans) {
                answers[j] = state.create[problemType].answers[type][index][j]
              }
            })
            state.create[problemType].answers[type][i] = [...answers]
          })
          if (state.activeProblemType === 'multiple') {
            const isCorrect = state.create.multiple.is_correct[i]
            if (!isCorrect.includes(true)) {
              isCorrect.forEach((c, j) => {
                isCorrect[j] = state.create.multiple.is_correct[index][j]
              })
            }
            state.create.multiple.is_correct[i] = [...isCorrect]
          }
        }
      } else if (state.activeProblemType === 'speech') {
        const questionList = state.create.speech.questions.text
        const answerList = state.create.speech.answers.text
        const questionImageList = state.create.speech.questions.image
        const answerImageList = state.create.speech.answers.image
        const questionImageUrlList = state.create.speech.questions.imageUrl
        const answerImageUrlList = state.create.speech.answers.imageUrl
        for (let i = 0; i < 4; i += 1) {
          const questions = state.create.speech.questions.text[i]
          const answers = state.create.speech.answers.text[i]
          const questionImage = state.create.speech.questions.image[i]
          const answerImage = state.create.speech.answers.image[i]
          const questionImageUrl = state.create.speech.questions.imageUrl[i]
          const answerImageUrl = state.create.speech.answers.imageUrl[i]
          questions.forEach((ques, j) => {
            if (!ques) {
              questions[j] = state.create.speech.questions.text[index][j]
              questionImage[j] = state.create.speech.questions.image[index][j]
              questionImageUrl[j] = state.create.speech.questions.imageUrl[index][j]
            }
          })
          answers.forEach((ques, j) => {
            if (!ques) {
              answerImage[j] = state.create.speech.answers.image[index][j]
              answerImageUrl[j] = state.create.speech.answers.imageUrl[index][j]
              answers[j] = state.create.speech.answers.text[index][j]
            }
          })
          questionList[i] = [...questions]
          answerList[i] = [...answers]
          questionImageList[i] = [...questionImage]
          answerImageList[i] = [...answerImage]
          questionImageUrlList[i] = [...questionImageUrl]
          answerImageUrlList[i] = [...answerImageUrl]
        }
      }
      state.activeTabIndex = payload
    },
    SET_PROBLEM_FEILD(state, payload) {
      const { name, value } = payload
      state.create[name] = value
    },
    SET_STATEMENT_FEILD(state, payload) {
      if (payload.type) {
        const {
          name, value, index, type,
        } = payload
        const FEILD = state.create[name]
        const subFEILD = FEILD[type]
        subFEILD[index] = value
        state.create = {
          ...state.create,
          [name]: FEILD,
        }
      } else {
        const { name, value, index } = payload
        const FEILD = state.create[name]
        FEILD[index] = value
        state.create = {
          ...state.create,
          [name]: FEILD,
        }
      }
    },
    SET_STATEMENT_TEMPLATE(state, payload) {
      const { value, index } = payload
      const st = state.create.statementTemplate
      st[index] = value
      state.create.statementTemplate = [...st]
    },
    SET_FEEDBACK_TEMPLATE(state, payload) {
      const { value, index } = payload
      const fb = state.create.feedbackTemplate
      fb[index] = value
      state.create.feedbackTemplate = [...fb]
    },
    SET_QUESTION_FEILD(state, payload) {
      const {
        type, level, index, question,
      } = payload
      const field = state.create[type]
      if (state.create.questionType === 'text' && !(state.activeProblemType === 'speech')) {
        field.questions.text[level][index] = question
      } else if (state.activeProblemType === 'speech') {
        field.questions.text[level][index] = question.text
        field.questions.image[level][index] = question.image
        field.questions.imageUrl[level][index] = question.imageUrl
        field.questions.extraFile[level][index] = question.extraFile
      } else if (state.create.questionType === 'image') {
        field.questions.image[level][index] = question.image
        field.questions.imageUrl[level][index] = question.imageUrl
        if (field.questions.caption) {
          field.questions.caption[level][index] = question.caption
        }
      } else if (state.create.questionType === 'audio') {
        field.questions.audio[level][index] = question.audio
        field.questions.audioUrl[level][index] = question.audioUrl
      }
      if (type === 'blank' || type === 'drag_position' || type === 'multiple') {
        field.questions.extraFile[level][index] = payload.extraFile
      }
      state.create[type] = { ...field }
    },
    SET_ANSWER_FEILD(state, payload) {
      const {
        type, level, index, answer,
      } = payload
      const field = state.create[type]
      if (state.activeProblemType === 'speech') {
        field.answers.text[level][index] = answer.text
        field.answers.imageUrl[level][index] = answer.imageUrl
        field.answers.image[level][index] = answer.image
        field.answers.extraFile[level][index] = answer.extraFile
        field.answers.extraAnswers[level][index] = answer.extraAnswers
      } else if (state.create.answerType === 'text') {
        field.answers.text[level][index] = answer
      } else if (state.create.answerType === 'image') {
        field.answers.image[level][index] = answer.image
        field.answers.imageUrl[level][index] = answer.imageUrl
        if (field.answers.caption) {
          field.answers.caption[level][index] = answer.caption
        }
      } else if (state.create.answerType === 'audio') {
        field.answers.audio[level][index] = answer.audio
        field.answers.audioUrl[level][index] = answer.audioUrl
      }
      state.create[type] = { ...field }
    },
    SET_IS_CORRECT_FEILD(state, payload) {
      const { level, index, isCorrect } = payload
      const field = state.create.multiple.is_correct
      field[level][index] = isCorrect
      state.create.multiple.is_correct = [...field]
    },
    SET_ACTIVE_PROBLEM_TYPE(state, payload) {
      state.activeProblemType = payload
      state.showError = false
    },
    SET_QA_TYPE(state, payload) {
      const { type, value } = payload
      state.create[type] = value
    },
    ADD_QUESTIONS(state) {
      state.showError = false
      if (state.activeProblemType === 'matching') {
        const { questions } = state.create.matching
        const { answers } = state.create.matching
        state.create.matching.deleteIndex = null
        Object.keys(questions).forEach(k => {
          questions[k].forEach(q => {
            q.push('')
          })
        })
        Object.keys(answers).forEach(k => {
          answers[k].forEach(a => {
            a.push('')
          })
        })
        state.create.matching.questions = { ...questions }
        state.create.matching.answers = { ...answers }
      } else if (state.activeProblemType === 'multiple') {
        const { answers } = state.create.multiple
        const isCorrect = state.create.multiple.is_correct
        state.create.multiple.deleteIndex = null
        Object.keys(answers).forEach(k => {
          answers[k].forEach(a => {
            a.push('')
          })
        })
        isCorrect.forEach(c => {
          c.push(false)
        })
        state.create.multiple.answers = { ...answers }
        state.create.multiple.is_correct = [...isCorrect]
      } else if (state.activeProblemType === 'speech') {
        const { questions } = state.create.speech
        const { answers } = state.create.speech
        state.create.speech.deleteIndex = null
        questions.text.forEach(q => {
          q.push('')
        })
        questions.image.forEach(q => {
          q.push('')
        })
        questions.imageUrl.forEach(q => {
          q.push('')
        })
        answers.text.forEach(a => {
          a.push('')
        })
        answers.image.forEach(a => {
          a.push('')
        })
        answers.imageUrl.forEach(a => {
          a.push('')
        })
        answers.extraAnswers.forEach(a => {
          a.push([])
        })
        state.create.speech.questions = { ...questions }
        state.create.speech.answers = { ...answers }
      }
    },
    DELETE_QUESTIONS(state, payload) {
      state.showError = false
      if (state.activeProblemType === 'matching') {
        const { index } = payload
        const { questions } = state.create.matching
        const { answers } = state.create.matching
        state.create.matching.deleteIndex = index
        Object.keys(questions).forEach(k => {
          questions[k].forEach(q => {
            q.splice(index, 1)
          })
        })
        Object.keys(answers).forEach(k => {
          answers[k].forEach(a => {
            a.splice(index, 1)
          })
        })
        state.create.matching.questions = { ...questions }
        state.create.matching.answers = { ...answers }
      } else if (state.activeProblemType === 'multiple') {
        const { index } = payload
        const { answers } = state.create.multiple
        const isCorrect = state.create.multiple.is_correct
        state.create.multiple.deleteIndex = index
        Object.keys(answers).forEach(k => {
          answers[k].forEach(a => {
            a.splice(index, 1)
          })
        })
        isCorrect.forEach(c => {
          c.splice(index, 1)
        })
        state.create.multiple.answers = { ...answers }
        state.create.multiple.is_correct = [...isCorrect]
      } else if (state.activeProblemType === 'speech') {
        const { index } = payload
        const { questions } = state.create.speech
        const { answers } = state.create.speech
        state.create.speech.deleteIndex = index
        questions.text.forEach(q => {
          q.splice(index, 1)
        })
        answers.text.forEach(a => {
          a.splice(index, 1)
        })
        state.create.speech.questions = { ...questions }
        state.create.speech.answers = { ...answers }
      }
    },
    SET_TIMESTAMP(state, payload) {
      const ts = state.create.timestamps
      payload.forEach((v, i) => {
        ts[i] = `${v.minute}:${v.second}`
      })
    },
    ADD_TIMESTAMP(state, payload) {
      state.create.timestamps.push(payload)
    },
    EDIT_TIMESTAMP(state, payload) {
      const index = state.create.timestamps.findIndex(t => t.id === payload.id)
      const { timestamps } = state.create
      timestamps[index].timestamp = payload.timestamp
      state.create.timestamps = timestamps
    },
    CLEAR_TIMESTAMP(state) {
      state.create.timestamps = []
    },
    UPDATE_TIMESTAMP(state, payload) {
      if (state.create.problemGroupType !== 'hot-spot') {
        const timestampIndex = state.create.timestamps.findIndex(time => time.id === payload)
        const { timestamps } = state.create
        timestamps[timestampIndex].created = true
        state.create.timestamps = timestamps
      }
    },
    UPDATE_COORDINATE(state) {
      state.create.problemCreatedCoordinate.push(state.create.activeCoordinate)
    },
    DELETE_COORDINATE(state, payload) {
      const { activeCoordinate } = state.create
      if (activeCoordinate?.x === payload.x && activeCoordinate?.y === payload.y) {
        state.create.activeCoordinate = null
        state.create.isEditing = false
      }
      state.create.points.splice(payload.originalIndex, 0)
    },
    DELETE_TIMESTAMP(state, index) {
      if (state.create.activeTimestamp === index.id) state.create.activeTimestamp = null
      state.create.timestamps.splice(index, 1)
    },
    SET_ACTIVE_TIMESTAMP(state, payload) {
      state.create.activeTimestamp = payload
    },

    SET_ACTIVE_POINT(state, payload) {
      state.create.activePoint = payload
    },

    SET_ACTIVE_COORDINATE(state, payload) {
      state.create.activeCoordinate = payload
    },

    SET_ACTIVE_STEP(state, payload) {
      state.create.activeStep = payload
    },

    SET_VIDEO(state, payload) {
      state.create.video = payload
    },
    SET_VIDEO_CREATED(state, payload) {
      state.create.videoCreated = payload
    },

    SET_HOTSPOT(state, payload) {
      state.create.hotspot = payload
    },

    SET_HOTSPOT_CREATED(state, payload) {
      state.create.hotspotCreated = payload
    },

    SET_POINTS(state, payload) {
      state.create.points = payload
    },

    SET_PROBLEM_ID(state, payload) {
      state.create.activeCoordinate.problem_id = payload
      const { activeCoordinate } = state.create
      state.create.points[activeCoordinate.originalIndex].problem_id = payload
    },

    SET_POINT_ID(state, payload) {
      state.create.activeCoordinate.id = payload
      const { activeCoordinate } = state.create
      state.create.points[activeCoordinate.originalIndex].id = payload
    },

    SET_SELECTED_TAGS(state, payload) {
      state.create.selectedTags = payload
    },

    SET_IMAGE_WIDTH(state, payload) {
      state.create.imageWidth = payload
    },

    SET_IMAGE_HEIGHT(state, payload) {
      state.create.imageHeight = payload
    },

    SHOW_ERROR(state, payload = true) {
      state.showError = payload
    },
    RESET(state) {
      const avoidList = state.create.problemGroupType === 'text' ? [] : ['domains', 'showLangLevel', 'lastCreatedProblem']
      const s = initialState()
      Object.keys(s).forEach(key => {
        if (key === 'create') {
          let avoidCreateList = []
          if (state.create.problemGroupType === 'video') {
            avoidCreateList = ['problemGroupType', 'activeTimestamp', 'showLangLevel', 'videoCreated', 'video', 'timestamps', 'lesson_id']
          } else if (state.create.problemGroupType === 'hot-spot') {
            avoidCreateList = ['problemGroupType', 'hotspot', 'activeCoordinate', 'showLangLevel', 'hotspotCreated', 'problemCreatedCoordinate', 'coordinates', 'points', 'lesson_id', 'imageHeight', 'imageWidth', 'isEditing']
          }
          // const avoidCreateList = state.problemGroupType === 'text' ? [] : ['problemGroupType', 'activeTimestamp', 'videoCreated', 'video', 'timestamps', 'lesson_id']
          Object.keys(state.create).forEach(createKey => {
            if (!avoidCreateList.includes(createKey)) state.create[createKey] = s.create[createKey]
          })
        } else if (!avoidList.includes(key)) state[key] = s[key]
      })
    },
    SET_BLANK_TEMPLATE(state, { value, level }) {
      const { template } = state.create[state.activeProblemType]
      template.templateData[level] = value
      state.create[state.activeProblemType].template = { ...template, templateData: template.templateData }
    },

    SET_BLANK_TYPE(state, value) {
      if (parseInt(value, 10)) state.create.blank.type = 'fib_dropdown'
      else state.create.blank.type = 'blank'
    },

    SET_IS_EDITING(state, payload) {
      state.create.isEditing = payload
    },
    SET_VALIDATIONS(state, payload) {
      state.validations = payload
    },
    SET_PROBLEM_CONVERSION(state, payload) {
      state.problemConversion = payload
    },
    SET_IS_PROBLEM_CONVERSION_ALLOWED(state, payload) {
      state.isProblemConversionAllowed = payload
    },
  },
  actions: {
    getDomainByLesson({ commit }, id) {
      return new Promise((resolve, reject) => {
        useJwt.getDomainByLesson(id).then(res => {
          commit('SET_DOMAINS', res.data.data.domain)
          commit('SET_LESSON_NAME', res.data.data.name)
          commit('SET_LANG_VERSION', res.data.data.type.name)
        }).catch(() => {
          reject()
        }).finally(() => {
          resolve()
        })
      })
    },
    getCefrSubject({ commit }) {
      return new Promise((resolve, reject) => {
        useApollo.getCefrSubject().then(res => {
          const cerfList = res.data.cefrGrade.data[0]?.subjects
          commit('SET_CEFR_SUBJECT_LIST', cerfList)
        }).catch(() => {
          reject()
        }).finally(() => {
          resolve()
        })
      })
    },
    getCefrDomain({ commit, state }, { id, index }) {
      return new Promise((resolve, reject) => {
        const cachedDomain = state.cachedCerfSubject.find(subject => Number(subject.id) === Number(id))
        if (cachedDomain) {
          setTimeout(() => {
            commit('SET_CEFR_DOMAIN_LIST', { domains: cachedDomain.domains.map(domain => ({ id: domain.id, name: domain.name })), index })
            resolve()
          }, 500)
          resolve()
        } else {
          useJwt.getCefrDomain(id).then(res => {
            commit('SET_CEFR_DOMAIN_LIST', { domains: res.data.data, index })
          }).catch(() => {
            reject()
          }).finally(() => {
            resolve()
          })
        }
      })
    },
    getCefrStandard({ commit, state }, { id, index }) {
      return new Promise((resolve, reject) => {
        let cachedStandard = state.cachedCerfSubject.find(subject => subject.domains.find(domain => Number(domain.id) === Number(id)))
        if (cachedStandard) {
          cachedStandard = cachedStandard.domains.find(domain => Number(domain.id) === Number(id))
          setTimeout(() => {
            commit('SET_CEFR_STANDARD_LIST', { standards: [...cachedStandard.standards], index })
            resolve()
          }, 500)
          resolve()
        } else {
          useJwt.getCefrStandard(id).then(res => {
            commit('SET_CEFR_STANDARD_LIST', { standards: res.data.data, index })
          }).catch(() => {
            reject()
          }).finally(() => {
            resolve()
          })
        }
      })
    },
    createQuestions({ commit, state }) {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      data.append('lesson_id', state.create.lesson_id)
      data.append('standard_id', state.create.standard_id)
      data.append('name', state.create.name)
      data.append('theme', state.create.theme)
      if (state.create.problemDescription) {
        data.append('description', state.create.problemDescription)
      }
      data.append('tts_support', state.ttsSupport ? 1 : 0)
      data.append('rules', JSON.stringify({
        hide_hints: state.create[state.activeProblemType]?.hideAnswerHints,
        hide_image_hints: state.create[state.activeProblemType]?.hideImageHints,
        hide_text_hints: state.create[state.activeProblemType]?.hideTextHints,
        choice_type: state.create.multiple.answerChoice,
        hide_statement: state.hideStatement,
        hide_feedback: state.hideFeedBack,
        preparation_time: state.create.openEndWriting.preparationTime,
        task_time: state.create.openEndWriting.taskTime,
      }))
      if (state.create.hasAnimatedLayout) {
        data.append('animation_layout', 1)
        data.append('animation_layout_data', JSON.stringify(state.create?.animatedLayoutData))
      }
      if (state.activeProblemType === 'blank' || state.activeProblemType === 'drag_position') {
        data.append('problem_type', state.create[state.activeProblemType].type)
      } else if (state.activeProblemType === 'open-end-writing') {
        if (state.create.questionType === 'audio') {
          data.append('problem_type', `${state.activeProblemType}-speech`)
        } else {
          data.append('problem_type', state.activeProblemType)
        }
      } else data.append('problem_type', state.activeProblemType)
      data.append('ques_type', state.create.questionType)
      data.append('ans_type', state.create.answerType)
      data.append('is_case_sensative', state.create.isCaseSensative ? 1 : 0)
      data.append('tags', JSON.stringify(state.create.selectedTags))
      data.append('skill_type', state.skillType)
      if (state.create.problemGroupType === 'video') {
        data.append('video_timestamp_id', state.create.activeTimestamp)
        data.append('video', state.create.video.id)
      }
      if (state.activeProblemType === 'category') {
        data.append('problem_type', state.create.category.type)
        data.append('question[0]', '')
        // data.append('category', JSON.stringify(state.create.category.items))
      }
      if (state.create.problemGroupType === 'hot-spot') {
        const { activeCoordinate } = state.create
        const { imageWidth } = state.create
        const { imageHeight } = state.create
        const coordinate = { x: (activeCoordinate.x / imageWidth), y: (activeCoordinate.y / imageHeight) }
        data.append('coordinates', JSON.stringify(coordinate))
        data.append('hotspot_id', state.create.hotspot.id)
      }

      if (state.showLangLevel) { // for 4 language levels
        state.create.statement.forEach((s, i) => {
          data.append(`statement[${i}]`, s)
        })
        state.create.feedback.forEach((f, i) => {
          data.append(`feedback[${i}]`, f)
        })
        for (let i = 0; i < 4; i += 1) {
          data.append(`cerf_standard[${i}]`, state.create.cefr.selectedStandard[i])
          data.append(`st_type[${i}]`, state.create.stType[i])
          data.append(`fb_type[${i}]`, state.create.fbType[i])
          if (!state.hideStatement && state.create.problemGroupType === 'text') {
            data.append(`st_template_id[${i}]`, state.create.statementTemplate[i].id)
          }

          if (!state.hideFeedBack && state.create.problemGroupType !== 'hot-spot') {
            data.append(`fb_template_id[${i}]`, state.create.feedbackTemplate[i].id)
          }

          if (state.create.stType[i] === 'image') data.append(`st_image[${i}]`, state.create.statementImage.file[i])
          else if (state.create.stType[i] === 'audio') data.append(`st_image[${i}]`, state.create.statementAudio.file[i])
          if (state.create.fbType[i] === 'image') data.append(`fb_image[${i}]`, state.create.feedbackImage.file[i])
          else if (state.create.fbType[i] === 'audio') data.append(`fb_image[${i}]`, state.create.feedbackAudio.file[i])
        }
        if (state.activeProblemType === 'blank' || state.activeProblemType === 'drag_position' || state.activeProblemType === 'text_selection') {
          state.create[state.activeProblemType].template.templateData.forEach((q, i) => {
            data.append(`question[${i}][0]`, JSON.stringify(q))
          })
        } else if (state.activeProblemType === 'open-end-writing') {
          // eslint-disable-next-line no-restricted-syntax
          state.create.openEndWriting.items.forEach((pair, index) => {
            data.append(`question[${index}]`, pair[0].question)
            data.append(`images[${index}]`, pair[0].images)
            data.append(`answer[${index}]`, JSON.stringify(pair[0].answers))
          })
        } else if (state.activeProblemType === 'category') {
          // eslint-disable-next-line no-restricted-syntax
          for (const pair of state.create.category.finalFormData.entries()) {
            data.append(pair[0], pair[1])
          }
        } else {
          state.create[state.activeProblemType].questions[state.create.questionType].forEach(
            (ques, i) => {
              ques.forEach((q, j) => {
                data.append(`question[${i}][${j}]`, q)
              })
            },
          )
          if (state.create[state.activeProblemType].questions?.caption) {
            state.create[state.activeProblemType].questions.caption.forEach(
              (ques, i) => {
                ques.forEach((q, j) => {
                  data.append(`question_caption[${i}][${j}]`, q)
                })
              },
            )
          }
        }
        if (state.activeProblemType === 'matching' || state.activeProblemType === 'multiple' || state.activeProblemType === 'speech') {
          state.create[state.activeProblemType].answers[state.create.answerType].forEach(
            (ans, i) => {
              ans.forEach((a, j) => {
                data.append(`answer[${i}][${j}]`, a)
              })
            },
          )
          if (state.create[state.activeProblemType].answers?.caption) {
            state.create[state.activeProblemType].answers.caption.forEach(
              (ques, i) => {
                ques.forEach((q, j) => {
                  data.append(`answer_caption[${i}][${j}]`, q)
                })
              },
            )
          }
          if (state.activeProblemType === 'multiple') {
            state.create.multiple.is_correct.forEach(
              (cor, i) => {
                cor.forEach((c, j) => {
                  data.append(`is_correct[${i}][${j}]`, c)
                })
              },
            )
            state.create.multiple.questions.extraFile.forEach((que, i) => {
              que.forEach((q, j) => {
                if (q) {
                  data.append(`extra_file_blob_question[${i}][${j}]`, JSON.stringify(q))
                }
              })
            })
          }
          if (state.activeProblemType === 'blank' || state.activeProblemType === 'drag_position' || state.activeProblemType === 'text_selection') {
            state.create[state.activeProblemType].questions.extraFile.forEach((que, i) => {
              que.forEach((q, j) => {
                if (q) {
                  data.append(`extra_file_blob[${i}][${j}]`, q)
                }
              })
            })
          }
          if (state.activeProblemType === 'speech') {
            state.create.speech.questions.image.forEach((que, i) => {
              que.forEach((q, j) => {
                data.append(`question_image[${i}][${j}]`, q)
              })
            })
            state.create.speech.answers.image.forEach((que, i) => {
              que.forEach((q, j) => {
                data.append(`answer_image[${i}][${j}]`, q)
              })
            })
            state.create.speech.questions.extraFile.forEach((que, i) => {
              que.forEach((q, j) => {
                if (q) {
                  data.append(`extra_file_blob_question[${i}][${j}]`, q)
                }
              })
            })
            state.create.speech.answers.extraFile.forEach((que, i) => {
              que.forEach((q, j) => {
                if (q) {
                  data.append(`extra_file_blob[${i}][${j}]`, q)
                }
              })
            })
            state.create.speech.answers.extraAnswers.forEach((que, i) => {
              que.forEach((q, j) => {
                if (q) {
                  data.append(`extra_answer[${i}][${j}]`, JSON.stringify(q))
                }
              })
            })
          }
        }
      } else { // without language level
        data.append('statement[0]', state.create.statement[0])
        data.append('feedback[0]', state.create.feedback[0])
        data.append('cerf_standard[0]', state.create.cefr.selectedStandard[0])
        data.append('st_type[0]', state.create.stType[0])
        data.append('fb_type[0]', state.create.fbType[0])
        if (state.create.problemGroupType === 'text') {
          data.append('st_template_id[0]', state.create.statementTemplate[0].id)
        }
        if (state.create.feedbackTemplate[0]) data.append('fb_template_id[0]', state.create.feedbackTemplate[0].id)
        if (state.create.stType[0] === 'image') data.append('st_image[0]', state.create.statementImage.file[0])
        else if (state.create.stType[0] === 'audio') data.append('st_image[0]', state.create.statementAudio.file[0])
        if (state.create.fbType[0] === 'image') data.append('fb_image[0]', state.create.feedbackImage.file[0])
        else if (state.create.fbType[0] === 'audio') data.append('fb_image[0]', state.create.feedbackAudio.file[0])
        if (state.activeProblemType === 'blank' || state.activeProblemType === 'drag_position' || state.activeProblemType === 'text_selection') {
          data.append('question[0][0]', JSON.stringify(state.create[state.activeProblemType].template.templateData[0]))
        } else if (state.activeProblemType === 'category') {
          // eslint-disable-next-line no-restricted-syntax
          for (const pair of state.create.category.finalFormData.entries()) {
            data.append(pair[0], pair[1])
          }
          // data.append('category', JSON.stringify([state.create.category.items[0]]))
        } else if (state.activeProblemType === 'open-end-writing') {
          // eslint-disable-next-line no-restricted-syntax
          for (const pair of state.create.openEndWriting.items[0]) {
            data.append('question[0]', pair.question)
            data.append('images[0]', pair.images)
            data.append('answer[0]', JSON.stringify(pair.answers))
          }
        } else {
          state.create[state.activeProblemType].questions[state.create.questionType][0].forEach((q, j) => {
            data.append(`question[0][${j}]`, q)
          })
          if (state.create[state.activeProblemType].questions?.caption) {
            state.create[state.activeProblemType].questions.caption[0].forEach((q, j) => {
              data.append(`question_caption[0][${j}]`, q)
            })
          }
          if (state.create[state.activeProblemType].answers?.caption) {
            state.create[state.activeProblemType].answers.caption[0].forEach((q, j) => {
              data.append(`answer_caption[0][${j}]`, q)
            })
          }
        }
        if (state.activeProblemType === 'blank' || state.activeProblemType === 'drag_position' || state.activeProblemType === 'text_selection') {
          state.create[state.activeProblemType].questions.extraFile[0].forEach((c, j) => {
            if (c) {
              data.append(`extra_file_blob[0][${j}]`, c)
            }
          })
        }
        if (state.activeProblemType === 'matching' || state.activeProblemType === 'multiple' || state.activeProblemType === 'speech') {
          state.create[state.activeProblemType].answers[state.create.answerType][0].forEach((a, j) => {
            data.append(`answer[0][${j}]`, a)
          })
          if (state.activeProblemType === 'multiple') {
            state.create.multiple.is_correct[0].forEach((c, j) => {
              data.append(`is_correct[0][${j}]`, c)
            })
            state.create.multiple.questions.extraFile[0].forEach((c, j) => {
              if (c) {
                data.append(`extra_file_blob_question[0][${j}]`, JSON.stringify(c))
              }
            })
          }
          if (state.activeProblemType === 'speech') {
            state.create.speech.questions.image[0].forEach((c, j) => {
              data.append(`question_image[0][${j}]`, c)
            })
            state.create.speech.answers.image[0].forEach((c, j) => {
              data.append(`answer_image[0][${j}]`, c)
            })
            state.create.speech.questions.extraFile[0].forEach((c, j) => {
              if (c) {
                data.append(`extra_file_blob_question[0][${j}]`, c)
              }
            })
            state.create.speech.answers.extraFile[0].forEach((c, j) => {
              if (c) {
                data.append(`extra_file_blob[0][${j}]`, c)
              }
            })
            state.create.speech.answers.extraAnswers[0].forEach((c, j) => {
              if (c) {
                data.append(`extra_answer[0][${j}]`, JSON.stringify(c))
              }
            })
          }
        }
      }
      if (state.create.problemGroupType === 'hot-spot' && state.lastCreatedProblem) {
        data.append('last_created_problem', state.lastCreatedProblem)
      }
      return new Promise((resolve, reject) => {
        useJwt
          .createQuestions(data, config)
          .then(response => {
            if (state.create.problemGroupType === 'hot-spot') {
              commit('SET_PROBLEM_ID', response.data.data.id)
              commit('SET_POINT_ID', response.data.data.hotspot_point.id)
              commit('SET_ACTIVE_STEP', 0)
              commit('SET_IS_EDITING', false)
              commit('SET_POINTS', state.create.points)
              commit('UPDATE_LAST_CREATED_PROBLEM', response.data.data)
            }
            resolve(response)
            commit('RESET_QUESTION')
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    storeTemplate({ commit }, payload) {
      const { copyIndex, template, type } = payload

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      data.append('type', template.type)
      data.append('question', template.question ? template.question : '')
      data.append('page_link', template.page_link ? template.page_link : '')
      data.append('after_template', template.after_template ? template.after_template : '')
      data.append('before_template', template.before_template ? template.before_template : '')

      if (template.template) {
        template.template.forEach((t, index) => {
          Object.keys(t).forEach(key => {
            if (key !== 'image_url' || key !== 'columns' || key !== 'rows') {
              data.append(`template[${index}][${key}]`, t[key])
            }
            if (key === 'columns') {
              t[key].forEach((col, i) => {
                data.append(`template[${index}][${key}][${i}]`, col)
              })
            }
            if (key === 'rows') {
              t[key].forEach((row, i) => {
                row.forEach((innerRow, inx) => {
                  data.append(`template[${index}][${key}][${i}][${inx}]`, innerRow)
                })
              })
            }
          })
        })
      }

      useJwt.axiosIns.post('/api/v1/templates', data, config).then(res => {
        if (type === 'statement') {
          commit('SET_STATEMENT_TEMPLATE', { value: res.data.data, index: copyIndex })
        } else {
          commit('SET_FEEDBACK_TEMPLATE', { value: res.data.data, index: copyIndex })
        }
      })
    },
    setValidations({ commit }, payload) {
      commit('SET_VALIDATIONS', payload)
    },
    setProblemConversion({ commit }, payload) {
      commit('SET_PROBLEM_CONVERSION', payload)
    },
    setIsProblemConversionAllowed({ commit }, payload) {
      commit('SET_IS_PROBLEM_CONVERSION_ALLOWED', payload)
    },
  },
}
