/* eslint-disable eqeqeq */
import GEC_EVENT_BUS from '@/utils/eventBus'

export default {
  automateProblem(problemType, answers) {
    setTimeout(() => {
      if (problemType === 'blank') {
        const allInputs = document.querySelectorAll('.problem-overview-content input.answer-input')
        const allInputsArray = Array.from(allInputs)
        allInputsArray.forEach((input, index) => {
          // eslint-disable-next-line no-param-reassign
          input.value = answers[index]
        })
      } else if (problemType === 'fib_dropdown') {
        answers.forEach((answer, index) => {
          const select = document.querySelectorAll('.problem-overview-content select.fib-answer')[index]
          const { options } = select
          for (let i = 0; i < options.length; i += 1) {
            if (options[i].value == answer) {
              select.selectedIndex = i
            }
          }
        })
      } else if (problemType === 'multiple' || problemType === 'multiple_listening') {
        const allElements = document.querySelectorAll('.problem-overview-content.multiple-choice-question')
        const allElementsArray = Array.from(allElements)
        allElementsArray.forEach(element => {
          const inputElement = element.querySelector('input')
          const inputInsideElementValue = Number(inputElement.value)
          if (answers.includes(inputInsideElementValue)) {
            inputElement.checked = true
            const buttonInsideElement = element.querySelector('button')
            if (buttonInsideElement) {
              buttonInsideElement.classList.add('btn-warning')
            }
          }
        })
      } else if (problemType === 'matching' || problemType === 'matching_listening') {
        const allDropZones = document.querySelectorAll('.problem-overview-content .drop-zone.mtf-box')
        const allDropZonesArray = Array.from(allDropZones)
        allDropZonesArray.forEach(dropZone => {
          const questionId = Number(dropZone.getAttribute('data-questionId'))
          const answerId = answers[questionId]
          const answerElement = document.querySelector(`[data-id="match-problem-answer-${answerId}"]`)
          answerElement.className = 'btn btn-sm btn-secondary w-100 h-100 d-flex justify-content-between'
          dropZone.appendChild(answerElement)
        })
      }
      setTimeout(() => {
        GEC_EVENT_BUS.$emit('submitQuestion')
      }, 2000)
    }, 2000)
  },
}
