<template>
  <b-modal :visible="true"
           :title="$t('reservation-moduel.reservation-access')"
           size="lg"
           :ok-title="$t('ok')"
           :cancel-title="$t('labels.cancel')"
           @close="$emit('close')"
           @hide="$emit('close')"
           @ok="updateAccessTypes"
  >
    <b-form-group :label="$t('reservation-moduel.access-type')">
      <v-select v-model="accessTypes"
                :options="accessTypeOptions"
                label="text"
                multiple
                :reduce="item => item.value"
      />
    </b-form-group>
    <b-tabs>
      <b-tab v-if="accessTypes.includes('trial')"
             :title="$t('reservation-moduel.trial')"
      >
        <b-row>
          <b-col>
            <b-form-group :label="$t('reservation-moduel.valid-period-in-days')">
              <b-form-input v-model="accessTypesForms.trial.access_days"
                            type="number"
                            :placeholder="$t('reservation-moduel.valid-period-in-days')"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('reservation-moduel.payment-amount')">
              <b-form-input v-model="accessTypesForms.trial.payment_amount"
                            type="number"
                            steps="any"
                            :placeholder="$t('reservation-moduel.payment-amount')"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab v-if="accessTypes.includes('full_access')"
             :title="$t('reservation-moduel.full-access-self-study')"
      >
        <b-row>
          <b-col>
            <b-form-group :label="$t('reservation-moduel.valid-period-in-days')">
              <b-form-input v-model="accessTypesForms.full_access.access_days"
                            type="number"
                            :placeholder="$t('reservation-moduel.valid-period-in-days')"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('reservation-moduel.payment-amount')">
              <b-form-input v-model="accessTypesForms.full_access.payment_amount"
                            type="number"
                            steps="any"
                            :placeholder="$t('reservation-moduel.payment-amount')"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab v-if="accessTypes.includes('full_access_tutor')"
             :title="$t('reservation-moduel.full-access-with-tutor')"
      >
        <b-row>
          <b-col>
            <b-form-group :label="$t('reservation-moduel.valid-period-in-days')">
              <b-form-input v-model="accessTypesForms.full_access_tutor.access_days"
                            type="number"
                            :placeholder="$t('reservation-moduel.valid-period-in-days')"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('reservation-moduel.payment-amount')">
              <b-form-input v-model="accessTypesForms.full_access_tutor.payment_amount"
                            type="number"
                            steps="any"
                            :placeholder="$t('reservation-moduel.payment-amount')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12"
                 class="mb-2"
          >
            <b-form-checkbox v-model="accessTypesForms.full_access_tutor.has_custom_schedule"
                             switch
            >
              {{ $t('reservation-moduel.allow-custom-schedule') }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row v-if="accessTypesForms.full_access_tutor?.has_custom_schedule">
          <b-col>
            <b-form-group :label="$t('reservation-moduel.schedule-limit')">
              <b-form-input v-model.number="accessTypesForms.full_access_tutor.custom_schedule_info.limit"
                            type="number"
                            steps="any"
                            :placeholder="$t('reservation-moduel.schedule-limit')"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('reservation-moduel.schedule-cost')">
              <b-form-input v-model.number="accessTypesForms.full_access_tutor.custom_schedule_info.cost"
                            type="number"
                            steps="any"
                            :placeholder="$t('reservation-moduel.schedule-cost')"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('reservation-moduel.days-per-week')">
              <b-form-input v-model.number="accessTypesForms.full_access_tutor.custom_schedule_info.days"
                            type="number"
                            steps="any"
                            :placeholder="$t('reservation-moduel.days-per-week')"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('reservation-moduel.interval-in-min')">
              <b-form-input v-model.number="accessTypesForms.full_access_tutor.custom_schedule_info.interval"
                            type="number"
                            steps="any"
                            :placeholder="$t('reservation-moduel.interval-in-min')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-button variant="outline-primary"
                  @click="showReservationSchedule = true"
        >
          <feather-icon icon="CalendarIcon" /> {{ $t('reservation-moduel.reservation-schedule') }}
        </b-button>
      </b-tab>
    </b-tabs>
    <ReservationSchedule v-if="showReservationSchedule"
                         :reservation="reservation"
                         @updateSchedule="handleUpdateSchedule"
                         @removeSchedule="handleRemoveSchedule"
                         @close="showReservationSchedule = false"
    />
  </b-modal>
</template>
<script>
import {
  BModal, BFormGroup, BTabs, BTab, BFormInput, BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import i18n from '@/libs/i18n'
import ReservationSchedule from '../ReservationSchedule.vue'

export default {
  components: {
    BModal,
    vSelect,
    BFormGroup,
    BTabs,
    BTab,
    BFormInput,
    BRow,
    BCol,
    BButton,
    ReservationSchedule,
    BFormCheckbox,
  },
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      accessTypes: [],
      showReservationSchedule: false,
      accessTypesForms: {
        trial: {},
        full_access: {},
        full_access_tutor: {
          custom_schedule_info: {},
        },
      },
      accessTypeOptions: [
        {
          text: i18n.tc('reservation-moduel.trial'),
          value: 'trial',
        },
        {
          text: i18n.tc('reservation-moduel.full-access-self-study'),
          value: 'full_access',
        },
        {
          text: i18n.tc('reservation-moduel.full-access-with-tutor'),
          value: 'full_access_tutor',
        },
      ],
    }
  },
  mounted() {
    this.setupInitialData()
  },
  methods: {
    setupInitialData() {
      const accessItems = this.reservation.access_types
      this.accessTypes = accessItems.map(item => item.name)
      const fullAccessTutor = accessItems.find(item => item.name === 'full_access_tutor') || {
        custom_schedule_info: {},
      }
      if (!fullAccessTutor.custom_schedule_info) {
        fullAccessTutor.custom_schedule_info = {}
      }
      this.accessTypesForms = {
        trial: accessItems.find(item => item.name === 'trial') || {},
        full_access: accessItems.find(item => item.name === 'full_access') || {},
        full_access_tutor: fullAccessTutor,
      }
    },
    handleRemoveSchedule(index) {
      this.reservation.schedules.splice(index, 1)
    },
    handleUpdateSchedule(value, index) {
      if (Number.isInteger(index)) {
        this.reservation.schedules[index] = value
      } else {
        this.reservation.schedules.push(value)
      }
      this.$forceUpdate()
    },
    updateAccessTypes() {
      const allAccessTypes = []
      this.accessTypes.forEach(item => {
        allAccessTypes.push({
          name: item,
          ...this.accessTypesForms[item],
        })
      })
      this.$emit('onUpdateAccessType', allAccessTypes)
    },
  },
}
</script>
