/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'

const actions = {
  loadInitialData({ state }) {
    this.getNetwork()
    if (state.self) {
      this.getMyNetworkMembership()
      this.getNetworkMembers()
    }
  },
  getNetwork({ state, commit }) {
    commit('SET_IS_PROCESSING', true)
    useJwt.showNetwork(state.networkId).then(res => {
      commit('SET_NETWORK', res.data.data)
    })
      .finally(() => {
        commit('SET_IS_PROCESSING', false)
      })
  },

  getMyNetworkMembership({ state, commit }) {
    return new Promise(resolve => {
      commit('SET_IS_PROCESSING', true)
      useJwt.getMyNetworkMemberShip(state.networkId).then(res => {
        commit('SET_MY_NETWORK_MEMBERSHIP', res.data.data.status)
        commit('SET_REQUEST_ID', res.data.data.rid)
      }).finally(() => {
        commit('SET_IS_PROCESSING', false)
        resolve('done')
      })
    })
  },

  getNetworkMembers({ state, commit, getters }) {
    if (getters.hasJoinedNetwork) {
      useJwt.getNetworkMembers(state.networkId).then(res => {
        commit('SET_NETWORK_MEMBERS', res.data.data)
      })
    }
  },

  getInvitations: ({ commit, state }, page) => useJwt.getNetworkInvitations(state.networkId, { params: { page } }).then(response => {
    commit('SET_INVITATIONS', response.data.data)
  }),

  getRequests: ({ commit, state }, page) => {
    useJwt.getMemberRequests(state.networkId, {
      params: { page },
    }).then(response => {
      commit('SET_REQUESTS', response.data.data)
    })
  },

  getVerifiedArticles({ state, commit }, payload) {
    commit('SET_IS_PROCESSING', true)
    const page = payload && payload.page ? payload.page : state.verifiedArticlesPaginationData.currentPage
    const params = {
      page,
      cat: state.activeTab,
    }
    useJwt.getNetworkArticles(state.networkId, { params })
      .then(response => {
        commit('SET_VERIFIED_ARTICLES', response.data.data.data)
        commit('SET_VERIFIED_ARTICLES_PAGINATION_DATA', response.data.data.pagination)
      }).finally(() => {
        commit('SET_IS_PROCESSING', false)
      })
  },
  getUnVerifiedArticles({ state, commit, getters }, payload) {
    if (getters.hasJoinedNetwork) {
      commit('SET_IS_PROCESSING', true)

      const page = payload && payload.page ? payload.page : state.unVerifiedArticlesPaginationData.currentPage
      const params = {
        page,
      }
      useJwt.getNetworkUnverifiedArticles(state.networkId, { params })
        .then(response => {
          commit('SET_UNVERIFIED_ARTICLES', response.data.data.data)
          commit('SET_UNVERIFIED_ARTICLES_PAGINATION_DATA', response.data.data.pagination)
        }).finally(() => {
          commit('SET_IS_PROCESSING', false)
        })
    }
  },
  getCoLeaders({ state, commit, getters }) {
    if (getters.isALeader || getters.isACoLeader) {
      commit('SET_IS_PROCESSING', true)

      useJwt.getNetworkCoLeaders(state.networkId)
        .then(response => {
          commit('SET_COLEADERS', response.data.data)
        }).finally(() => {
          commit('SET_IS_PROCESSING', false)
        })
    }
  },

}
export default actions
