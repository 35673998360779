<template>
  <div>
    <div
      v-if="userInfo"
      class=" card-profile mb-2"
    >

      <div class="profile-image-wrapper">
        <div class="profile-image p-0">
          <span
            class="b-avatar badge-light rounded-circle"
            style="width: 114px; height: 114px;"
          ><span class="b-avatar-img"><img
            :src="userInfo.avatar"
            alt="avatar"
          ></span></span>
        </div>
      </div><h3>{{ userInfo.firstname }} {{ userInfo.lastname }}</h3><h6 class="text-muted">
        {{ userInfo.email }}
      </h6>
    </div>

    <b-card body-class="p-0">
      <b-table-simple
        bordered
        striped
      >
        <thead>
          <tr>
            <td colspan="100%">
              <span>
                {{ $t('course-completion-module.lesson-name') }}: <span
                  v-if="scormInfo"
                  class="font-weight-bold"
                >{{ scormInfo.title }}</span>
              </span>
            <!-- <span>
              100%
            </span> -->
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{ $t('Question') }}
            </td>
            <td
              v-for="report,index of interactionReport"
              :key="report.id"
            >
              {{ index + 1 }}
            </td>
            <td>
              {{ $t('rating.rate') }}
            </td>
            <td>
              {{ $t('scorm-module.scores') }}
            </td>
            <td>
              {{ $t('scorm-module.execution-time') }}
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('scorm-module.result') }}
            </td>
            <td
              v-for="report of interactionReport"
              :key="report.id"
            >
              <feather-icon
                v-if="report.isCorrect"
                icon="CheckIcon"
                class="text-success"
              />
              <feather-icon
                v-else
                class="text-danger"
                icon="XIcon"
              />
            </td>
            <td>
              {{ correctAnswers }}/{{ totalProblem }}
            </td>
            <td>
              {{ scorePercentage }} %
            </td>
            <td>
              {{ overallReport && CONVERT_HM(overallReport.total_time / 60) }}
            </td>
          </tr>
        </tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>
<script>
import { BTableSimple, BCard } from 'bootstrap-vue'

export default {
  components: {
    BTableSimple,
    BCard,
  },
  props: {
    interactionReport: {
      type: Array,
      default: () => [],
    },
    scormInfo: {
      type: Object,
      default: () => null,
    },
    userInfo: {
      type: Object,
      default: () => null,
    },
    overallReport: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    totalProblem() {
      return this.interactionReport.length
    },
    correctAnswers() {
      return this.interactionReport.filter(item => item.isCorrect).length
    },
    scorePercentage() {
      return this.number_format((this.correctAnswers / this.totalProblem) * 100, 2)
    },
  },
}
</script>
