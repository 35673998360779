<template>
  <div>
    <div class="card">
      <!----><!---->
      <div class="card-body">
        <!----><!---->
        <div class="row header">
          <div class="d-flex align-items-baseline col-md-10">
            <h2 class="m-0 p-0 class-title">
              {{ item.name }}
            </h2>
            <!---->
          </div>
          <div class="col-md-2 text-right">
            <b-dropdown
              id="dropdown-1"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              right
              class="pr-0"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-left vertical-icon"
                />
              </template>
              <b-dropdown-item
                v-if="isSuperAdmin"
                :disabled="isProcessing"
                @click.stop="$emit('onEdit', item)"
              >
                <feather-icon icon="Edit2Icon" />
                {{ $t('library-moduel.edit-library-permissions') }}
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="isProcessing"
                @click.stop="$emit('onManagePermission', item)"
              >
                <feather-icon icon="SlidersIcon" />
                {{ $t('library-moduel.block-users') }}
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="isProcessing"
                @click.stop="$emit('onAddSchoolUser', item)"
              >
                <feather-icon icon="BookIcon" />
                {{ $t('library-moduel.add-school-user') }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <hr class="w-100">
        <div class="row body">
          <div class="col-md-4 col-lg-5 col-xl-3">
            <!---->
            <img
              v-if="item.image"
              style="height: 120px; width: 120px"
              :src="item.image"
            >
            <avatar
              v-else
              :username="item.name"
              :rounded="false"
              color="#fff"
              :size="notResponsiveWindow ? 130 : 120"
              class="avatar-image d-xs-none d-sm-block"
            />
          </div>
          <div class="px-2 col-md-7 col-lg-7 col-xl-9">
            <div class="row pt-1">
              <div
                class=" m-0 col-md-12"
                :class=" {'pl-1' : notResponsiveWindow}"
              >
                <h5>{{ $t('library-moduel.total-standard') }}: {{ item.standard_count }}</h5>
              </div>
            </div>
            <div class="row teacher-section pt-1">
              <div
                class="col-md-12 m-0"
                :class=" {'pl-1' : notResponsiveWindow}"
              >
                <h5>{{ $t('library-moduel.total-courses') }}: {{ item.courses_count }}</h5>
              </div>
            </div>
            <div class="row class-action pt-1">
              <div
                class="m-0 pl-1 d-flex flex-wrap col-md-12"
                style="gap:15px;"
                :class=" {'pl-1' : notResponsiveWindow}"
              >
                <router-link :to="{ name: `${user.usertype}-standards`, query: { library: item.id } }">
                  <b-button
                    class=""
                    variant="outline-primary"
                  >
                    <feather-icon icon="ShieldIcon" />
                    {{ $t('library-moduel.standards') }}
                  </b-button>
                </router-link>
                <router-link :to="{ name: `${user.usertype}-curriculum-builder`, ...courseParams(item.id) }">
                  <b-button
                    class=""
                    variant="outline-secondary btn-wishlist"
                  >
                    <feather-icon icon="BookOpenIcon" />
                    {{ $t('library-moduel.curriculum') }}
                  </b-button>
                </router-link>
                <router-link :to="{ name: `${user.usertype}-courses`, ...courseParams(item.id) }">
                  <b-button

                    variant="outline-success btn-wishlist"
                  >
                    <feather-icon icon="BookIcon" />
                    {{ $t('library-moduel.courses') }}
                  </b-button>
                </router-link>
                <a :href="`/${user.usertype}/error-reports?library_id=${item.id}`"
                   target="_blank"
                >
                  <b-button
                    variant="outline-danger"
                  >
                    <feather-icon icon="AlertTriangleIcon" />
                    {{ $t('library-moduel.error-report') }}
                  </b-button>
                </a>
              </div>
            </div>
            <!---->
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="align-items-center text-nowrap">
            <div class="justify-content-between">
              <div>
                <h5
                  v-if="item.owners"
                  class="d-flex flex-wrap pl-1 align-items-center"
                  style="gap:7px;"
                >
                  <span
                    v-for="owner, index of allItems(item.owners)"
                    :key="`${owner.id}-${index}`"
                    class="badge badge-outline-success"
                  >
                    {{ owner.firstname }} {{ owner.lastname }}
                  </span>
                  <span v-if="item.owners.length>3 && !isMore"
                        class="text-primary cursor-pointer ml-1"
                        @click="isMore = true"
                  >
                    ...{{ $t('library-moduel.more') }}
                  </span>
                </h5>
              </div>
            </div>
          </div>
        </div>
        <!----><!---->
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from 'vue-avatar'
import { BButton, BDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  components: {
    Avatar,
    BButton,
    BDropdown,
    BDropdownItem,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    notResponsiveWindow: {
      type: Boolean,
      default: false,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    isSuperAdmin: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isMore: false,
      showSchoolAddForm: false,
    }
  },
  methods: {
    courseParams(id) {
      if (this.user.usertype === 'national' || this.user.usertype === 'curriculum') {
        return {
          params: { library: id },
          query: { library: id },
        }
      }
      return {
        query: { library: id },
      }
    },
    allItems(items) {
      if (this.isMore) return items
      return items.filter((owner, index) => index <= 2)
    },
  },
}
</script>
<style scoped>
.card:hover {
  box-shadow: 0 8px 32px 0 rgba(28, 34, 112, 0.37);
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
}
</style>
