<template>
  <div>
    <b-card title="Email verification 🚀">
      Email verification
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
  },

  data() {
    return {
      expires: '',
      signature: '',
      id: '',
      hash: '',
    }
  },

  created() {
    const { expires } = this.$route.query
    const { signature } = this.$route.query
    const { id } = this.$route.params
    const { hash } = this.$route.params
    useJwt
      .verifyEmail({
        id, hash, expires, signature,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Your email has been verified',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Your email has been verified. ',
          },
        }, {
          position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
        })
      })
  },
}
</script>

<style>

</style>
