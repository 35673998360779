<template>
  <div>
    <b-overlay :show="isProcessing">
      <b-card class="problem-overview-content">
        <b-table
          :fields="fields"
          :items="gameLists"
          responsive
        >
          <template
            #cell(image)="data"
          >
            <b-img
              :src="data.item.image"
              fluid
              thumbnail
            />
          </template>
          <template #cell(is_public)="data">
            <b-form-checkbox
              :checked="data.item.is_public === 1"
              switch
              @change="(value) => toggleGame(data.item.id, value)"
            />
          </template>
          <template #cell(action)="data">
            <b-button
              variant="outline-primary"
              @click="openGame(data.item)"
            >
              <feather-icon
                icon="PlayIcon"
                size="15"
              />
            </b-button>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
    <game
      v-if="selectedGame"
      :code="selectedGame"
      style="z-index: 1000 !important"
      @gameOver="gameOver"
    />
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BTable, BCard, BImg, BFormCheckbox, BOverlay, BButton,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Game from '@/views/student/problem/compoments/Game.vue'

export default {
  components: {
    BTable,
    BCard,
    BImg,
    BFormCheckbox,
    BOverlay,
    FeatherIcon,
    BButton,
    Game,
  },
  data() {
    return {
      gameLists: [],
      isProcessing: false,
      selectedGame: null,
      fields: [
        { key: 'image', label: i18n.tc('labels.image') },
        { key: 'text', label: i18n.tc('labels.text') },
        { key: 'code', label: i18n.tc('labels.code') },
        { key: 'coins', label: i18n.tc('labels.coins') },
        { key: 'is_public', label: i18n.tc('courses-module.table-head.is_public') },
        { key: 'access_type', label: i18n.tc('animateditem-module.access_type') },
        { key: 'category', label: i18n.tc('labels.category') },
        { key: 'action', label: i18n.tc('action') },
      ],
    }
  },
  created() {
    this.isProcessing = true
    useJwt.getGameLists({
      params: {
        all: true,
      },
    }).then(response => {
      this.gameLists = response.data.data?.game_lists
    }).catch(error => {
      this.showErrorMessage(error)
    }).finally(() => { this.isProcessing = false })
  },
  methods: {
    openGame(gameInfo) {
      this.selectedGame = gameInfo.code
    },
    toggleGame(gameId, toggle) {
      useJwt.toggleGameAvailability(gameId, {
        enable: toggle,
      }).then(response => {
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    gameOver() {
    },
  },
}
</script>
<style>
  td .img-thumbnail{
    width: 90px;
    height: 90px;
    object-fit: cover;
    max-width: unset;
  }
</style>
