<template>
  <b-modal
    :title="libraryInfo ? $t('library-moduel.update-library'):$t('library-moduel.add-library')"
    :visible="show"
    size="lg"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <validation-observer ref="createItemForm">
      <b-form @submit.prevent>
        <b-form-group
          :label="$t('library-moduel.logo')"
          label-for="tem-name"
        >
          <validation-provider
            v-slot="{ errors }"
            name="image|size:800"
            :custom-messages="{ image: $t('messages.invalid-image') }"
            vid="image"
            :rules="!libraryInfo && 'required'"
          >
            <!-- media -->
            <b-media no-body>
              <b-media-aside>

                <avatar
                  size="50px"
                  :rounded="true"
                  :image="logoUrl"
                />
              <!--/ avatar -->
              </b-media-aside>

              <b-media-body class="ml-50">
                <!-- Plain mode -->
                <div>
                  <b-form-file
                    ref="refInputEl1"
                    v-model="brandImage"
                    :browse-text="$t('browseButton')"
                    :placeholder="$t('issue-report-module.choose-a-file')"
                    class="mb-1"
                    accept="image/*"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>

                </div>
                <div v-if="!brandImage && errors.length==0">
                  <b-card-text> {{ $t('account-setting-general.allowed') }} </b-card-text>
                </div>
              </b-media-body>
            </b-media>
          <!--/ media -->
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('library-moduel.library-name')"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Library Name"
          >
            <b-form-input
              v-model="library.name"
              :placeholder="$t('library-moduel.library-name')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('library-moduel.enabled')"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Enabled"
          >
            <b-form-checkbox
              v-model="library.is_public"
              :value="1"
              switch
            >
              <p class="text-muted"
                 style="font-size:13px; font-style: italic;"
              >
                <feather-icon icon="InfoIcon" />
                {{ $t("library-moduel.disable-info") }}
              </p>
            </b-form-checkbox>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('library-moduel.description')"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            :name="$t('library-moduel.description')"
          >
            <b-form-textarea
              v-model="library.description"
              :placeholder="$t('library-moduel.description')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <div v-if="AUTH_USER().usertype === 'super'">
          <b-form-group
            :label="`${$t('library-moduel.library-owners')}`"
          >
            <v-select
              v-model="library.owners"
              :clearable="false"
              label="text"
              :multiple="true"
              :options="users"
              :reduce="option => option.value"
            />
          </b-form-group>
          <role-selector :members="library.owners"
                         :users="users"
                         :owners-with-roles="library.ownersWithRoles"
          />
        </div>
      </b-form>
    </validation-observer>
    <template #modal-footer="{ }">
      <b-button @click="$emit('close')">
        {{ $t('labels.cancel') }}
      </b-button>
      <b-button
        v-if="libraryInfo"
        variant="primary"
        @click="updateLibrary"
      >
        <b-spinner
          v-if="isSaving"
          small
        /> {{ $t('labels.update') }}
      </b-button>
      <b-button
        v-else
        variant="primary"
        @click="saveLibrary"
      >
        <b-spinner
          v-if="isSaving"
          small
        /> {{ $t('actions.save') }}
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal, BForm, BFormGroup, BFormInput, BMedia, BMediaBody, BFormFile, BCardText, BMediaAside, BFormCheckbox, BFormTextarea,
  BButton, BSpinner,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import Avatar from '@/views/common/components/Avatar.vue'
import { required, email } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import RoleSelector from './RoleSelector.vue'

const initlibraryInfo = () => ({
  name: '',
  description: '',
  is_public: '',
  owners: [],
  ownersWithRoles: [],
})

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BMedia,
    BMediaBody,
    BFormFile,
    BCardText,
    BMediaAside,
    BFormCheckbox,
    BButton,
    BSpinner,
    BFormTextarea,
    RoleSelector,
    vSelect,
    Avatar,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    libraryInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      brandImage: null,
      required,
      logoUrl: null,
      library: {
        ...initlibraryInfo(),
      },
      email,
      isSaving: false,
      processingStatus: true,
      users: [],
    }
  },
  watch: {
    brandImage() {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.showPreview = true
          this.logoUrl = reader.result
        },
        false,
      )
      if (this.brandImage) {
        if (/\.(jpe?g|png|gif)$/i.test(this.brandImage.name)) {
          reader.readAsDataURL(this.brandImage)
        }
      }
    },
    // eslint-disable-next-line func-names
    'library.owners': function (value) {
      const roles = []
      value.forEach(item => {
        const roleId = this.library.ownersWithRoles.find(i => i.user_id === item)?.role_id || null
        roles.push({
          role_id: roleId,
          user_id: item,
        })
      })
      this.library.ownersWithRoles = roles
    },
    libraryInfo(newValue) {
      if (newValue) {
        this.library = {
          name: newValue.name,
          description: newValue.description,
          is_public: newValue.is_public,
          owners: newValue.owners.map(item => item.id),
          ownersWithRoles: newValue.owners.map(item => ({ user_id: item.id, role_id: item.pivot.role_id })),
        }
        this.logoUrl = newValue.image
      } else {
        this.library = {
          ...initlibraryInfo(),
        }
      }
    },
    show(value) {
      if (value) {
        this.getUsers()
      }
    },
  },
  methods: {
    getUsers(search = null) {
      const params = {
        email: search,
        type: 'national',
      }
      this.processingStatus = true

      useJwt
        .searchForUsers({ params })
        .then(response => {
          this.users = response.data.data.map(item => ({ value: item.id, text: `${item.firstname} ${item.lastname}` }))
        })
        .finally(() => {
          this.processingStatus = null
        })
    },
    createFormData() {
      const formData = new FormData()
      Object.entries(this.library).forEach(([key, value]) => {
        formData.append(key, value)
      })
      delete formData.owners
      formData.append('owners', JSON.stringify(this.library.ownersWithRoles))
      delete formData.ownersWithRoles
      formData.append('ownersWithRoles', JSON.stringify(this.library.ownersWithRoles))
      if (this.brandImage) {
        formData.append('image', this.brandImage)
      }
      if (this.libraryInfo) {
        formData.append('_method', 'PUT')
      }
      return formData
    },
    showErrors(error) {
      const errors = error.response?.data?.errors
      if (errors) {
        Object.entries(errors).forEach(message => {
          this.showError(message.join(','))
        })
      } else {
        this.showErrorMessage(error)
      }
    },
    updateLibrary() {
      this.$refs.createItemForm.validate().then(success => {
        if (success) {
          this.isSaving = true
          useJwt.updateLibrary(this.libraryInfo.id, this.createFormData()).then(response => {
            this.showSuccessMessage(response)
            this.$emit('reFetch')
            this.$emit('close')
          }).catch(error => {
            this.showErrors(error)
          }).finally(() => {
            this.isSaving = false
          })
        }
      })
    },
    saveLibrary() {
      this.$refs.createItemForm.validate().then(success => {
        if (success) {
          this.isSaving = true
          useJwt.storeLibrary(this.createFormData()).then(response => {
            this.showSuccessMessage(response)
            this.$emit('reFetch')
            this.$emit('close')
          }).catch(error => {
            this.showErrors(error)
          }).finally(() => {
            this.isSaving = false
          })
        }
      })
    },
  },
}
</script>
