<template>
  <BOverlay :show="state.isProcessing">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <td v-for="item,index of state.summary"
              :key="index"
          >
            {{ measureTypes.find( i=> i.value == item.test)?.text || item.test }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-for="item,index of state.summary"
              :key="index"
          >
            {{ item.equivalent_score ? number_format(item.equivalent_score, 2) : '-' }}
          </td>
        </tr>
      </tbody>
    </table>
  </BOverlay>
</template>
<script setup>
import useJwt from '@/auth/jwt/useJwt'
import { BOverlay } from 'bootstrap-vue'
import { onMounted, reactive } from 'vue'
import { measureTypes } from '@/const/langLevel'

const props = defineProps({
  score: {
    type: Number,
    required: true,
  },
})

const state = reactive({
  summary: [],
  langLevel: null,
  isProcessing: false,
})

const getEquivalentScore = () => {
  state.isProcessing = true
  useJwt.getEquivalentCefrScore({
    params: {
      score: props.score,
    },
  }).then(response => {
    state.summary = response.data.data.result
    state.langLevel = response.data.data.lang_level
  }).finally(() => {
    state.isProcessing = false
  })
}

onMounted(() => {
  getEquivalentScore()
})
</script>
