<template>
  <div>
    <b-row v-for="(qa, index) in questionAnswers"
           :key="index"
    >
      <b-col md="6">
        <b-form-group
          label="Question"
        >
          <b-form-input
            v-model="qa.question"
            placeholder="Type Your Question Here"
            @change="$emit('updateQuestion', qa)"
          />
        </b-form-group>
      </b-col>
      <b-col md="5">
        <b-form-group
          label="Answer"
        >
          <b-form-input
            v-model="qa.answer"
            :placeholder="`Type Your Answer Here ${index}`"
            @change="$emit('updateAnswer', qa)"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="index > 0"
             md="1"
      >
        <b-button
          v-b-tooltip="`Delete Question`"
          variant="outline-danger"
          class="btn-icon"
          style="margin-top:28px"
          @click="askToDelete(index)"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-button
          v-b-tooltip="`Add More Question`"
          variant="outline-primary"
          class="btn-icon"
          @click="addQuestion"
        >
          <feather-icon icon="PlusIcon" />
          {{ $t("Add-New") }}
        </b-button>
      </b-col>
    </b-row>
    <confirm-delete
      :show="!!deleteQuestionIndex"
      :sub-title="$t('questions.delete-answer')"
      :on-delete="deleteQuestion"
    />
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import ConfirmDelete from '@/views/common/components/DeleteModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    ConfirmDelete,
  },
  props: {
    type: {
      type: String,
      default: 'create',
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    initialQuestion: {
      type: Array,
      default: () => [],
    },
    level: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      questionAnswers: [
        { question: '', answer: '' },
      ],
      deleteQuestionIndex: null,
    }
  },
  computed: {
    isEditMode() {
      return this.type === 'edit'
    },
  },
  mounted() {
    this.setData()
  },
  methods: {
    addQuestion() {
      if (this.isEditMode) {
        this.$emit('addMoreQuestion', this.level)
      } else {
        this.questionAnswers.push({
          question: '', answer: '',
        })
      }
    },
    askToDelete(index) {
      this.deleteQuestionIndex = index
    },
    deleteQuestion() {
      if (this.isEditMode) {
        const question = this.questionAnswers[this.deleteQuestionIndex]
        this.$emit('deleteQuestion', question)
        this.deleteQuestionIndex = null
      } else {
        this.questionAnswers.splice(this.deleteQuestionIndex, 1)
        this.deleteQuestionIndex = null
      }
    },
    getData() {
      return {
        questionAnswers: this.questionAnswers,
        problemType: 'matching',
      }
    },
    setData() {
      if (this.isEditMode) {
        this.questionAnswers = this.initialQuestion.map(item => ({
          question: item.text,
          answer: item.answers[0]?.text,
          questionId: item.id,
          answerId: item.answers[0]?.id,
          langLevel: item.lang_level,
          isCorrect: 1,
        }))
      }
    },
  },
}
</script>
