<template>
  <b-modal :visible="true"
           :title="$t('update-schools')"
           no-close-on-backdrop
           size="lg"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <b-overlay :show="isProcessing">
      <div class="d-flex">
        <div>
          <b-button
            class="mr-1 px-1"
            variant="outline-primary"
            @click="handleSelectAll"
          >
            {{ isSelectedAll ? $t('teacher-schedule.unselect-all') : $t('teacher-schedule.select-all') }}
          </b-button>
        </div>

        <b-form-group class="w-50 mr-1 position-relative">
          <b-form-input
            v-model="searchTerm"
            class="search-input"
            :placeholder="$t('search-users')"
          />
          <span
            v-if="searchTerm"
            v-b-tooltip.hover="$t('clear-users')"
            class="clear-icon text-muted cursor-pointer"
            @click="searchTerm = ''"
          >
            <feather-icon icon="XIcon" />
          </span>
        </b-form-group>

        <b-form-group class="px-0 col-4">
          <v-select
            v-model="selectedDistrict"
            :options="districtsList"
            placeholder="Filter by District"
            :reduce="district => district.id"
            @input="getSchoolbyDistrict"
          />
        </b-form-group>
      </div>
      <div class="row mt-2">
        <div v-for="sc,i of filteredSchoolLists.length ? filteredSchoolLists : schoolLists"
             :key="i"
             class="col-md-4 mb-2"
        >
          <b-form-checkbox v-model="selectedSchools"
                           :value="sc.id"
          >
            <span class="text-capitalize">{{ sc.fullname }}</span>
          </b-form-checkbox>
        </div>
      </div>
    </b-overlay>
    <template #modal-footer>
      <div
        class="d-flex w-100"
        :class="filteredSchoolLists.length ? 'justify-content-between' : 'justify-content-end'"
      >
        <b-pagination
          v-if="filteredSchoolLists.length"
          v-model="currentPage"
          :disabled="isProcessing"
          :total-rows="total"
          :per-page="perPage"
          @input="getSchoolbyDistrict"
        />
        <div>
          <b-button variant="outline-secondary"
                    class="mr-1"
                    @click="emits('close')"
          >
            {{ $t('actions.cancel') }}
          </b-button>
          <b-button variant="outline-success"
                    :disabled="isProcessing"
                    @click="updateSchoolUsers"
          >
            <b-spinner v-if="isProcessing"
                       small
            /> {{ $t('actions.submit') }}
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script setup>
import {
  ref, onMounted, getCurrentInstance, watch,
} from 'vue'
import {
  BModal, BButton, BOverlay, BSpinner, BFormCheckbox, BFormGroup, BFormInput, BPagination,
} from 'bootstrap-vue'
import useApollo from '@/@core/graphql/useApollo'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import _ from 'lodash'

const props = defineProps({
  libraryId: {
    type: Number,
    required: true,
  },
})

const isProcessing = ref(false)
const schoolLists = ref([])
const filteredSchoolLists = ref([])
const selectedSchools = ref([])
const isSelectedAll = ref(false)
const root = getCurrentInstance().proxy.$root
const emits = defineEmits(['close'])
const searchTerm = ref('')
const districtsList = ref([])
const selectedDistrict = ref()
const allSchools = ref([])
const currentPage = ref(1)
const perPage = ref(5)
const total = ref(0)

const getLibrary = () => {
  useJwt.showLibrary(props.libraryId).then(response => {
    selectedSchools.value = response.data?.data?.library?.schools.map(item => item.id) ?? []
  })
}

const getSchoolUsers = () => {
  isProcessing.value = true

  useApollo
    .getSchoolUsers({
      searchTerm: searchTerm.value,
    })
    .then(response => {
      const res = response.data?.schools?.data
      schoolLists.value = res.map(item => ({ ...item, id: Number(item.id) }))
    })
    .finally(() => {
      isProcessing.value = false
    })
}

const searchSchoolUsers = _.debounce(() => {
  getSchoolUsers()
}, 1000)

watch(searchTerm, val => {
  if (val) searchSchoolUsers()
  else getSchoolUsers()
})

const updateSchoolUsers = () => {
  isProcessing.value = true
  const schoolsList = filteredSchoolLists.value.length ? allSchools.value : schoolLists.value.map(school => school.id)

  useJwt.storeSchoolLibrary(props.libraryId, {
    schools_list: schoolsList,
    school_ids: selectedSchools.value,
  }).then(response => {
    root.showSuccessMessage(response)
    emits('close')
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    isProcessing.value = false
  })
}

const handleSelectAll = () => {
  if (isSelectedAll.value) {
    isSelectedAll.value = false
    selectedSchools.value = []
  } else {
    isSelectedAll.value = true
    selectedSchools.value = schoolLists.value.map(item => item.id)
  }
}

const getUsersByUserType = params => new Promise(resolve => {
  useJwt.getUsers({ params }).then(response => {
    resolve(response.data)
  })
})

const getDistrictUsers = () => {
  const params = { usertype: 'district' }
  isProcessing.value = true
  getUsersByUserType(params).then(response => {
    districtsList.value = response.data.map(({ id, fullname }) => ({
      id,
      label: fullname,
    }))
  }).finally(() => {
    isProcessing.value = false
  })
}

const getSchoolbyDistrict = () => {
  if (!selectedDistrict.value) filteredSchoolLists.value = []
  else {
    const params = {
      id: selectedDistrict.value,
      usertype: 'school',
      page: currentPage.value,
      perPage: perPage.value,
    }
    getUsersByUserType(params).then(response => {
      total.value = response.pagination.total
      filteredSchoolLists.value = response.data

      if (!allSchools.value.includes(filteredSchoolLists.value?.[0].id)) {
        allSchools.value = [
          ...allSchools.value,
          ...filteredSchoolLists.value.map(school => school.id),
        ]
      }
    })
  }
}

onMounted(() => {
  getLibrary()
  getSchoolUsers()
  getDistrictUsers()
})
</script>

<style scoped>
  .clear-icon {
    position: absolute;
    right: 8px;
    top: 8px;
  }
</style>
