/* eslint-disable import/no-cycle */
import isAuthenticated from '@/middlewares/isAuthenticated'
import studentUsersOnly from '@/middlewares/studentUsersOnly'
import largeDevicesOnly from '@/middlewares/largeDevicesOnly'

export default [
  {
    path: '/student/dashboard',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
    },
    name: 'student-dashboard',
    component: () => import('@/views/common/dashboard/Index.vue'),
  },
  {
    path: '/student/classes/:classId/calendar',
    name: 'student-class-calendar',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
    },
    component: () => import('@/views/teacher/class/components/calendar/Calendar.vue'),
  },
  {
    path: '/student/classes',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
    },
    name: 'student-classes',
    component: () => import('@/views/common/class/Handler.vue'),
  },
  {
    path: '/student/classes/:id/:courseId?/:reporting?',
    name: 'student-class',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
      navActiveLink: 'student-classes',
    },
    component: () => import('@/views/common/class/Show.vue'),
  },
  {
    path: '/student/networks',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
    },
    name: 'student-networks',
    component: () => import('@/views/common/network/Index.vue'),
  },
  {
    path: '/student/networks/:id',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
      navActiveLink: 'student-networks',
    },
    name: 'student-networks-show',
    component: () => import('@/views/common/network/Show.vue'),
  },
  {
    path: '/student/class/:classId/lesson/:id',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
      navActiveLink: 'student-classes',
    },
    name: 'student-lesson',
    component: () => import('@/views/student/lesson/Show.vue'),
  },
  {
    path: '/student/class/:classId/course/:id/result',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
      navActiveLink: 'student-classes',
    },
    name: 'student-course-result',
    component: () => import('@/views/student/course/Result.vue'),
  },
  {
    path: '/student/class/:classId/lesson/:id/problems',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
      navActiveLink: 'student-classes',
    },
    name: 'student-problems',
    component: () => import('@/views/student/problem/Index1.vue'),
  },
  {
    path: '/student/class/:classId/course/:id/lessons',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
      navActiveLink: 'student-classes',
    },
    name: 'student-lessons',
    component: () => import('@/views/student/lesson/Index.vue'),
  },
  {
    path: '/student/class/:classId/student/:id/rubrics',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
      navActiveLink: 'student-classes',
    },
    name: 'student-rubrics',
    component: () => import('@/views/common/class/components/rubric/Show.vue'),
  },
  {
    path: '/student/report',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
      navActiveLink: 'student-report',
    },
    name: 'student-report',
    component: () => import('@/views/student/report/Index.vue'),
  },
  {
    path: '/student/my-diary',
    name: 'student-diary',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
    },
    component: () => import('@/views/common/student-diary/Index.vue'),
  },
  {
    path: '/calendar-events',
    name: 'student-calendar',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
    },
    component: () => import('@/views/student/calendar/Calendar.vue'),
  },
  {
    path: '/student/courses/:id',
    name: 'student-course',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
      navActiveLink: 'student-classes',
    },
    component: () => import('@/views/common/class/components/ShowCourse.vue'),
  },
  {
    path: '/view-students/:id',
    name: 'student-view-students',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
      navActiveLink: 'student-classes',
    },
    component: () => import('@/views/v2/common/class/components/StudentTable.vue'),
  },
  {
    path: '/student/pair/:classId/student',
    name: 'student-pair-student',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
    },
    component: () => import('@/views/v2/common/class/paring/index.vue'),
  },
  {
    path: '/view-notes',
    name: 'view-notes-all',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
    },
    component: () => import('@/views/v2/common/class/Notes/index.vue'),
  },
  {
    path: '/view-notes/:classId/lesson/:lessonId',
    name: 'student-view-notes',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
    },
    component: () => import('@/views/v2/common/class/Notes/index.vue'),
  },
  {
    path: '/student/my-attendance',
    name: 'my-attendance',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
    },
    component: () => import('@/views/student/attendance/index.vue'),
  },
  {
    path: '/student/class-test/:classId',
    name: 'student-class-test',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
    },
    component: () => import('@/views/student/lesson/ClassTest/index.vue'),
  },
  {
    path: '/student/personality-evaluation',
    name: 'student-personality-evaluation-for-student',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
      navActiveLink: 'student-personality-evaluation-for-student',
    },
    component: () => import('@/views/common/personality-evaluation/Index.vue'),
  },

  {
    path: '/student/animated-items-marketplace',
    name: 'animated-items-marketplace',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly, largeDevicesOnly],
      navActiveLink: '',
    },
    component: () => import('@/layouts/components/ModelChangeModal.vue'),
  },

  {
    path: '/student/engagements',
    name: 'student-engagements',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
      navActiveLink: 'student-engagements',
    },
    component: () => import('@/views/student/Engagements.vue'),
  },

  {
    path: '/my-items',
    name: 'my-items',
    component: () => import('@/views/v2/trading/modules/MyItems.vue'),
    meta: {
      middleware: [isAuthenticated, studentUsersOnly, largeDevicesOnly],
      pageTitle: 'Animated Items',
    },
  },

  {
    path: '/student/market/:marketId',
    name: 'market-details',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
    },
    component: () => import('@views/common/dashboard/components/MarketDetails.vue'),
  },
  {
    path: '/student/individual-report/:studentId',
    name: 'student-individual-report',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
    },
    component: () => import('@/views/common/individual-report/index.vue'),
  },
  {
    path: '/student/chatbot',
    name: 'student-individual-report',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
    },
    component: () => import('@/views/student/chatbot/index.vue'),
  },
  {
    path: '/student/higher-level-report/classroom',
    name: 'student-higher-level-report',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
    },
    component: () => import('@/views/common/higher-level-report/index.vue'),
  },
  {
    path: '/student/vocab-problem/:classId',
    name: 'student-vocab-problem',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
    },
    component: () => import('@/views/student/vocab-lesson/index.vue'),
  },
  {
    path: '/student/vocab-lab/:classId/class/:eventId/event',
    name: 'student-vocab-lab',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
    },
    component: () => import('@/views/student/vocab-lesson/lab.vue'),
  },
  {
    path: '/student/vocab-lab/:classId/class/:eventId/report',
    name: 'student-vocab-lab-report',
    meta: {
      middleware: [isAuthenticated, studentUsersOnly],
    },
    component: () => import('@/views/student/vocab-lesson/report/index.vue'),
  },
  // {
  //   path: '/student/courses',
  //   name: 'student-courses',
  //   meta: {
  //     middleware: [isAuthenticated, studentUsersOnly],
  //   },
  //   component: () => import('@/views/common/courses/Index.vue'),
  // },
]
