<template>
  <div>
    <div v-if="classe">
      <b-modal
        id="class-rubric-modal"
        ref="class-rubric-modal"
        v-model="isVisible"
        ok-only
        ok-variant="outline-secondary"
        :ok-title="$t('close')"
        modal-class="modal-primary"
        size="md"
        centered
        :title="modalTitle"
        :no-close-on-backdrop="true"
        @close="$emit('close')"
        @hide="$emit('close')"
      >
        <div class="text-right">
          <b-button size="sm"
                    variant="outline-primary"
                    @click="showRubric = true"
          >
            {{ $t('add-new-rubric') }} <feather-icon icon="PlusIcon" />
          </b-button>
        </div>
        <validation-observer
          ref="createEditRubricForm"
        >
          <b-form
            class="add-student-from"
            @submit.prevent="syncClassRubrics"
          >
            <button
              type="submit"
              class="d-none"
            />
            <b-row>
              <b-col md="12">
                <b-form-group
                  :label="$t('student-diary-module.rubrics')"
                  label-for="rubric-title"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('student-diary-module.rubrics')"
                    vid="rubrics"
                  >
                    <v-select
                      v-model="selectedRubrics"
                      multiple
                      :options="rubricsForSelect"
                      :reduce="a=>a.code"
                    />
                    <!-- <b-form-input
                    v-model="localRubric.title"
                    :state="errors.length > 0 ? false : null"
                  /> -->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <b-button
              variant="success"
              :disabled="isProcessing"
              class="mr-1"
              @click="syncClassRubrics"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t('actions.save') }}
            </b-button>
          </div>
        </template>
      </b-modal>
      <TeacherRubricModal :rubrics="teacherRubrics"
                          :show="showRubric"
                          @close="showRubric = false"
                          @refreshRubrics="getTeacherRubrics"
      />
    </div>
  </div>

</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import { getUserData } from '@/auth/utils'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import {
  BModal,
  BSpinner,
  BRow,
  BCol,
  BForm,
  BButton,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TeacherRubricModal from '@/views/common/class/components/rubric/components/TeacherRubricModal.vue'

export default {
  components: {
    BModal,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    TeacherRubricModal,
    vSelect,
  },
  props: {
    student: {
      type: Object,
      default: null,
    },
    classe: {
      type: Object,
      default: null,
    },
    rubrics: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
      isCreatingRubric: false,
      rubricBeingEdited: null,
      isProcessing: false,
      idOfRubricBeingDeleted: null,
      self: getUserData(),
      selectedRubrics: [],
      teacherRubrics: [],
      showRubric: false,
    }
  },
  computed: {
    rubricsForSelect() {
      return this.teacherRubrics.map(e => ({
        code: e.id,
        label: e.title,
      }))
    },
    modalTitle() {
      return `${i18n.tc('student-diary-module.rubrics-of')} ${this.classe.name}`
    },
  },
  watch: {
    show(value) {
      this.isVisible = this.show
      if (value && !this.teacherRubrics.length) {
        this.getTeacherRubrics()
      }
    },
    rubrics: {
      handler(newValue) {
        this.selectedRubrics = newValue.map(e => e.id)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    syncClassRubrics() {
      this.$refs.createEditRubricForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt.syncClassRubrics(this.classe.id, { rubric_id: this.selectedRubrics }).then(res => {
            this.showSuccessMessage(res)
            this.$emit('refreshRubrics')
            this.$bvModal.hide('class-rubric-modal')
          }).finally(() => {
            this.isProcessing = false
          })
        }
      })
    },
    deleteRubric() {
      this.isProcessing = true
      useJwt.deleteClassRubric(this.idOfRubricBeingDeleted).then(res => {
        this.$emit('refreshRubrics')
        this.idOfRubricBeingDeleted = null
        this.showSuccessMessage(res)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    rubricModalClosed() {
      this.isCreatingRubric = false
      this.rubricBeingEdited = null
    },
    getTeacherRubrics() {
      this.isProcessing = true
      useJwt
        .getTeacherRubrics()
        .then(res => {
          this.teacherRubrics = res.data.data
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
  },
}
</script>

<style>

</style>
