<template>
  <b-modal :visible="true"
           title="Edit Problem"
           size="xl"
           centered
           ok-only
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <b-row v-if="problem">
      <b-col md="3">
        <b-form-group label="Problem Name">
          <b-form-input v-model="problem.name"
                        placeholder="Problem name"
                        @change="updateProblem"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Skill Type">

          <b-form-select v-model="problem.skill_type"
                         text-field="text"
                         value-field="value"
                         :options="skillTypes"
                         placeholder="Select Skill Type"
                         @change="updateProblem"
          />

        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Domain">
          <b-form-select v-model="domainId"
                         text-field="name"
                         value-field="id"
                         :options="lessonDomain"
                         placeholder="Select Domain"
                         @change="getStandards"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Standard">
          <b-form-select v-model="standardId"
                         text-field="name"
                         value-field="id"
                         :options="standardLists"
                         placeholder="Select Standard"
                         @change="updateProblem"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-overlay v-if="problem"
               :show="isProcessing"
    >

      <component :is="getProblemComponent"
                 ref="problemComponent"
                 type="edit"
                 :initial-question="problem.questions"
                 :level="problem.questions?.[0].lang_level"
                 @updateQuestion="updateQuestion"
                 @updateAnswer="updateAnswer"
                 @addMoreQuestion="addMoreQuestion"
                 @deleteQuestion="deleteQuestion"
                 @addMoreAnswer="addMoreAnswer"
      />

    </b-overlay>
  </b-modal>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BFormGroup, BFormInput, BRow, BCol, BModal, BFormSelect, BOverlay, BTab, BTabs,
} from 'bootstrap-vue'
import skillTypes from '@/const/skillTypes'
import MatchingProblem from './problems-type/Matching-problem.vue'
import TrueFalseProblem from './problems-type/True-False.vue'
import RearrangeProblem from './problems-type/Re-arrange.vue'
import OpenEndWriting from './problems-type/Open-end-writing.vue'
import SpeechProblem from './problems-type/SpeechProblem.vue'

export default {
  components: {
    BFormGroup, BFormInput, BRow, BCol, BModal, BFormSelect, MatchingProblem, TrueFalseProblem, RearrangeProblem, BOverlay, BTab, BTabs, OpenEndWriting, SpeechProblem,
  },
  props: {
    problemId: {
      type: Number,
      required: true,
    },
    lessonDomain: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      problem: null,
      domainId: null,
      standardId: null,
      standardLists: [],
      problemType: null,
      isProcessing: false,
      allLevels: ['A1', 'A2', 'B1', 'B2'],
      activeLangTab: 0,
      skillTypes,
    }
  },
  computed: {
    getProblemComponent() {
      switch (this.problemType) {
        case 'matching':
          return MatchingProblem
        case 'multiple':
          return TrueFalseProblem
        case 'drag_position':
          return RearrangeProblem
        case 'open-end-writing':
          return OpenEndWriting
        case 'speech':
          return SpeechProblem
        default:
          return null
      }
    },
    langLevels() {
      return [...new Set(this.problem?.questions?.map(item => item.lang_level))]
    },
  },
  mounted() {
    this.getProblemInfo()
  },
  methods: {
    getStandards() {
      useJwt.getStandardsByDomain(this.domainId).then(response => {
        this.standardLists = response.data.data
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    getProblemInfo() {
      this.problem = null
      this.isProcessing = true
      useJwt.getProblem(this.problemId, {
        params: {
          withPrivate: true,
        },
      }).then(response => {
        const problem = response.data.data
        this.problem = problem
        this.domainId = problem.problem_set.standard?.standard_domain_id
        this.standardId = problem.problem_set.standard_id
        this.problemType = problem.problem_type.name
        this.getStandards()
      }).finally(() => {
        this.isProcessing = false
      })
    },
    updateProblem() {
      const data = {
        lesson_id: this.problem.lesson_id,
        name: this.problem.name,
        standard_id: this.standardId,
        tags: this.problem.tags,
        tts_support: this.problem.ttsSupport,
        skill_type: this.problem.skill_type,
        description: this.problem.description,
        rules: this.JSON_PARSE(this.problem.rules),
      }
      useJwt.updateProblem(this.problem.id, data)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('reFetch')
        })
    },
    updateQuestion(question) {
      const data = {
        lang_level: question.langLevel,
        problem_id: this.problem.id,
        text: question.question,
        ques_type: 'text',
        _method: 'PUT',
      }
      useJwt.updateQuestion(question.questionId, data).then(response => {
        this.showSuccessMessage(response)
      })
        .catch(error => {
          this.showError(error.response.data.errors.text[0])
        })
    },
    updateAnswer(answer) {
      const data = {
        lang_level: answer.langLevel,
        problem_id: this.problem.id,
        text: answer.answer || answer.text,
        ans_type: 'text',
        is_correct: answer.is_correct || 0,
        _method: 'PUT',
      }

      useJwt.updateAnswer(answer.answerId, data)
        .then(response => {
          this.showSuccessMessage(response)
          this.$emit('fetch')
        })
        .catch(error => {
          // this.showErrorMessage(error)
          this.showError(error.response.data.errors.text[0])
        })
    },
    addMoreQuestion(level) {
      const questionParam = {
        lang_level: level,
        problem_id: this.problem.id,
        ques_type: 'text',
        text: ' ',
      }
      this.isProcessing = true
      useJwt.addQuestion(questionParam).then(response => {
        this.addMoreAnswer(response.data.data.id)
      }).catch(error => {
        this.showErrorMessage(error)
        this.isProcessing = false
      })
    },
    addMoreAnswer(questionId, isCorrect = 0) {
      this.isProcessing = true
      const answer = {
        lang_level: 1,
        problem_id: this.problem.id,
        question_id: questionId,
        ans_type: 'text',
        is_correct: isCorrect,
      }
      useJwt.addAnswer(answer).then(() => {
        this.problem = null
        this.getProblemInfo()
      }).finally(() => {
        this.isProcessing = false
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    deleteAnswer(answerId) {
      this.isProcessing = true
      useJwt.deleteAnswer(answerId).then(response => {
        this.showSuccessMessage(response)
        this.getProblemInfo()
      }).catch(error => {
        this.isProcessing = false
        this.showErrorMessage(error)
      })
    },
    deleteQuestion(question) {
      this.isProcessing = true
      useJwt.deleteQuestion(question.questionId).then(() => {
        if (question.answerId) {
          this.deleteAnswer(question.answerId)
        } else {
          this.getProblemInfo()
        }
      }).catch(error => {
        this.showErrorMessage(error)
        this.isProcessing = false
      })
    },
  },
}
</script>
