var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('validation-observer',{ref:"blankForm"},[_c('b-form-group',{attrs:{"label":_vm.$t('labels.create-problem.question'),"label-for":"question"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary","block":""},on:{"click":function($event){_vm.showTemplate = true}}},[_vm._v(" "+_vm._s(_vm.templateData ? "Update Template" : "Create Template")+" ")]),_c('create-template',{attrs:{"show":_vm.showTemplate,"template":_vm.templateData,"title":"Template","avoid-save":true,"problem-type":"fib_problem","initial-template-options":[
          { value: 'none', text: 'None' },
          { value: 'paragraph', text: 'Paragraph' },
          { value: 'image-paragraph', text: 'Image & Paragraph' },
          { value: 'shapes', text: 'Shapes' },
          { value: 'image-qa', text: 'Question and Answer with Image' },
          { value: 'cards', text: 'Cards' },
          { value: 'centered-cards', text: 'Centered Cards' },
          { value: 'side-cards', text: 'Side Cards' },
        ]},on:{"close":function($event){_vm.showTemplate = false},"templateStored":_vm.updateTemplate}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }