<template>
  <!-- collapse -->
  <b-col
    md="6"
    xs="12"
  >
    <!-- create/edit domain modal -->
    <create-edit-domain
      :show="showDomainModal"
      :domain="domainBeingEdited"
      :subject-id="subjectId"
      :grade-id="gradeId"
      @close="showDomainModal = false"
      @domainCreated="domainCreated"
      @domainUpdated="domainUpdated"
    />
    <!-- create/edit domain modal -->

    <create-edit-standard
      :show="showStandardModal"
      :standard="standardBeingEdited"
      :domain-id="activeDomainId"
      :subject-id="subjectId"
      :grade-id="gradeId"
      :grades="grades"
      @close="showStandardModal = false"
      @standardCreated="standardCreated"
      @standardUpdated="standardUpdated"
    />

    <!-- create domain button -->
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      class="mb-1 btn-block"
      :disabled="!subjectId || !gradeId || isProcessing"
      @click="addNewDomain"
    >
      {{ $t("standard-module.create-domain") }}
    </b-button>
    <!-- create domain button -->

    <!-- list of domains -->
    <draggable
      v-model="domains"
      :options="{animation:300}"
      handle=".domain-handle"
      @change="updateOrder('domain')"
    >
      <domain-item
        v-for="(domain, index) in domains"
        :key="index"
        :index="index"
        :domain="domain"
        @deleteDomain="(id) => (idOfDomainBeingDeleted = id)"
        @editDomain="editDomain"
        @domainSelected="(id) => (activeDomainId = id)"
        @addStandard="addStandard"
        @editStandard="editStandard"
        @copyStandard="copyStandard"
        @deleteStandard="(id) => (idOfStandardBeingDeleted = id)"
      />
    </draggable>

    <b-alert
      variant="info"
      :show="!subjectId && !isProcessing"
    >
      <div class="alert-body">
        <span style="font-size: 12px">{{
          $t("standard-module.select-a-subject")
        }}</span>
      </div>
    </b-alert>
    <b-alert
      variant="warning"
      :show="subjectId && !isProcessing && domains.length === 0"
    >
      <div class="alert-body">
        <span style="font-size: 12px">{{
          $t("standard-module.no-domains")
        }}</span>
      </div>
    </b-alert>
    <!-- list of domains -->

    <!-- delete domain modal -->
    <delete-modal
      name="modal-delete-domain"
      :on-delete="deleteDomain"
      :title="$t('standard-module.delete-domain-title')"
      :sub-title="$t('standard-module.delete-domain-question')"
      :is-processing="isProcessing"
      :show="idOfDomainBeingDeleted != null"
      @close="idOfDomainBeingDeleted = null"
    />
    <!-- delete domain modal -->
    <!-- delete standard modal -->
    <delete-modal
      name="modal-delete-standard"
      :on-delete="deleteStandard"
      :title="$t('standard-module.delete-standard-title')"
      :sub-title="$t('standard-module.delete-standard-question')"
      :is-processing="isProcessing"
      :show="idOfStandardBeingDeleted != null"
      @close="idOfStandardBeingDeleted = null"
    />
    <!-- delete standard modal -->
    <!-- copy domain -->
    <copy-domain
      :show="!!selectedDomainId"
      :domain-id="Number(selectedDomainId)"
      :standard-id="Number(selectedStandardId)"
      :grades="grades"
      @getDomains="getDomains"
      @close="selectedDomainId = null"
    />
  </b-col>
</template>
<script>
import {
  VBToggle, BCol, BButton, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import draggable from 'vuedraggable'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import eventBus from '@/utils/eventBus'
import DomainItem from './Show.vue'
import CreateEditDomain from './CreateEdit.vue'
import CreateEditStandard from '../standard/CreateEdit.vue'
import CopyDomain from './Copy.vue'

export default {
  components: {
    CreateEditStandard,
    CreateEditDomain,
    DomainItem,
    BCol,
    BButton,
    DeleteModal,
    BAlert,
    draggable,
    CopyDomain,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    subjectId: {
      type: Number,
      default: null,
    },
    gradeId: {
      type: Number,
      default: null,
    },
    grades: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showDomainModal: false,
      showStandardModal: false,
      indexOfDomainBeingEdited: null,
      idOfDomainBeingDeleted: null,
      isProcessing: false,
      indexOfStandardBeingEdited: null,
      idOfStandardBeingDeleted: null,
      activeDomainId: null,
      activeStandardId: null,
      domains: [],
      selectedDomainId: null,
      selectedStandardId: null,
    }
  },
  computed: {
    domainBeingEdited() {
      return this.indexOfDomainBeingEdited != null
        ? this.domains[this.indexOfDomainBeingEdited]
        : null
    },
    standardBeingEdited() {
      return this.indexOfStandardBeingEdited != null
        ? this.domains.find(e => e.id === this.activeDomainId).standard[
          this.indexOfStandardBeingEdited
        ]
        : null
    },
  },
  watch: {
    subjectId() {
      if (this.subjectId) this.getDomains()
    },
  },
  mounted() {
    eventBus.$off('refetchGecDomains', this.getDomains)
    eventBus.$on('refetchGecDomains', this.getDomains)
  },
  beforeDestroy() {
    eventBus.$off('refetchGecDomains', this.getDomains)
  },
  methods: {
    getDomains() {
      this.isProcessing = true
      useJwt
        .getDomainBySubject({ subject_id: this.subjectId })
        .then(response => {
          this.domains = response.data.data
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    domainCreated() {
      this.showDomainModal = false
      this.getDomains()
    },
    domainUpdated() {
      this.showDomainModal = false
      this.indexOfDomainBeingEdited = null
      this.getDomains()
    },
    standardCreated() {
      this.indexOfStandardBeingEdited = null
      this.showStandardModal = false
      this.getDomains()
    },
    standardUpdated() {
      this.indexOfStandardBeingEdited = null
      this.showStandardModal = false
      this.getDomains()
    },
    deleteDomain() {
      this.isProcessing = true
      useJwt
        .deleteDomain(this.idOfDomainBeingDeleted)
        .then(res => {
          this.showSuccessMessage(res)
          this.idOfDomainBeingDeleted = null
          this.getDomains()
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    deleteStandard() {
      this.isProcessing = true
      useJwt
        .deleteStandard(this.idOfStandardBeingDeleted)
        .then(res => {
          this.showSuccessMessage(res)
          this.idOfStandardBeingDeleted = null
          this.getDomains()
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    addStandard(domainId) {
      this.activeDomainId = domainId
      this.indexOfStandardBeingEdited = null
      this.showStandardModal = true
    },
    editStandard(index, gId) {
      this.activeDomainId = gId
      this.indexOfStandardBeingEdited = index
      this.showStandardModal = true
    },
    editDomain(index) {
      this.showDomainModal = true
      this.indexOfDomainBeingEdited = index
    },
    addNewDomain() {
      this.indexOfDomainBeingEdited = null
      this.showDomainModal = true
    },
    updateOrder(type) {
      const input = this.domains.map((e, i) => ({ id: e.id, order_index: i + 1 }))
      useJwt.updateOrder({
        model: type,
        input,
      })
    },
    copyStandard(domainId, standardId) {
      this.selectedDomainId = domainId
      this.selectedStandardId = standardId
    },
  },
}
</script>

<style lang="scss" scoped>
.active {
  background-color: rgba(22, 29, 49, 0.4);
}

.btn-link {
  padding: 0px;
  margin: 0px;
}
</style>
