<template>
  <b-modal :visible="true"
           title="Problem create using Ocr"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <table class="table table-hover table-striped">
      <thead>
        <tr>
          <th>Problem name</th>
          <th>Problem Type</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="prob,index of props.problems"
            :key="index"
        >
          <td>
            {{ prob.name }}
          </td>
          <td>
            {{ prob.problem_type?.name }}
          </td>
          <td>
            <b-button class="btn-icon ml-1"
                      variant="outline-success"
                      @click="problemIdToShowPreview = prob.id"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>
    <lesson-preview
      :show="problemIdToShowPreview"
      :problem-id="problemIdToShowPreview"
      :lesson-id="lessonId"
      @close="problemIdToShowPreview = null"
    />
  </b-modal>
</template>
<script setup>
import { BModal, BButton } from 'bootstrap-vue'
import LessonPreview from '@/views/super/problem/components/LessonPreview.vue'
import { ref } from 'vue'

const props = defineProps({
  problems: {
    type: Array,
    default: () => [],
  },
  lessonId: {
    type: [Number],
    required: true,
  },
})
const problemIdToShowPreview = ref(null)
</script>
