<template>
  <b-card>
    <!-- form -->
    <b-form @submit.prevent="changePassword">
      <validation-observer ref="changePasswordForm">
        <button
          class="d-none"
          type="submit"
        />
        <b-row>
          <b-col md="6">
            <!-- old password -->
            <b-form-group
              :label="$t('old-password')"
              label-for="account-old-password"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('old-password')"
                vid="password_old"
                rules="required|min:3"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid':null"
                  class="input-group-merge"
                >
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    :class="errors.length > 0 ? 'is-invalid':null"
                    name="old-password"
                    autocomplete="off"
                    :type="passwordFieldTypeOld"
                    :placeholder="$t('old-password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
            <!-- old password -->
            <!-- new password -->
            <b-form-group
              label-for="account-new-password"
              :label="$t('new-password')"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('new-password')"
                vid="password"
                rules="required|min:3"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid':null"
                  class="input-group-merge"
                >
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    :class="errors.length > 0 ? 'is-invalid':null"
                    name="new-password"
                    autocomplete="off"
                    :placeholder="$t('new-password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!--/ new password -->
            <!-- retype password -->
            <b-form-group
              label-for="account-retype-new-password"
              :label="$t('retype-new-password')"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('retype-new-password')"
                vid="password_confirmation"
                rules="required|min:3"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid':null"
                  class="input-group-merge"
                >
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :class="errors.length > 0 ? 'is-invalid':null"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    :placeholder="$t('retype-new-password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
            <!--/ retype password -->

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click.prevent="changePassword"
            >
              {{ $t('save-change') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              {{ $t('account-setting-general.reset') }}
            </b-button>
          </b-col>
          <b-col md="6 border-left d-none d-md-block">
            <div
              style="padding: 10px 20px"
            >
              <img src="/change-password.png"
                   class="img-fluid"
              >
            </div>
          </b-col>
        </b-row>
      </validation-observer></b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },

    changePassword() {
      this.$refs.changePasswordForm.validate().then(success => {
        if (success) {
          useJwt
            .changePassword(
              {
                password_old: this.passwordValueOld,
                password: this.newPasswordValue,
                password_confirmation: this.RetypePassword,
              },
            )
            .then(response => {
              this.optionsLocal = response.data
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notice',
                  icon: 'CoffeeIcon',
                  variant: 'info',
                  text: 'Your password has been successfully updated',
                },
              }, {
                position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
              })
            }).catch(error => {
              if (error.response?.data?.errors) this.$refs.changePasswordForm.setErrors(error.response.data.errors)
              else this.showErrorMessage(error)
            })
        }
      })
    },

  },
}
</script>
