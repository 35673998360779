<template>
  <div>
    <div class="text-right">
      <b-button
        variant="outline-primary"
        @click="showMarketPlaceCreate = true"
      >
        <feather-icon icon="PlusIcon" /> {{ $t('marketplace-module.add-product') }}
      </b-button>
    </div>
    <market-place-update
      :show="showMarketPlaceCreate || !!marketPlaceToEdit"
      :marketplace="marketPlaceToEdit"
      @onUpdate="getMarketPlaces"
      @close="showMarketPlaceCreate = false;marketPlaceToEdit = null"
    />
    <b-overlay
      :show="isProcessing"
    >
      <b-tabs pills>
        <b-tab
          v-for="tab of tabs"
          :key="tab.value"
          :title="tab.name"
          @click="getMarketPlaces(tab.value)"
        >
          <b-card no-body>
            <b-table
              :fields="columns"
              :items="rows"
              striped
              show-empty
              :empty-text="$t('messages.no-data-available')"
            >
              <template #cell(image)="data">

                <img
                  :src="data.item.image"
                  style="height:60px; width:80px;"
                  @click="()=>{
                    $refs['image-modal'].show()
                    activeMarketplace = data.item
                  }"
                >
              </template>
              <template #cell(start_time)="data">
                {{ FORMAT_DATE(data.item.end_time,'YYYY-MM-DD') }}
              </template>
              <template #cell(end_time)="data">
                {{ FORMAT_DATE(data.item.end_time,'YYYY-MM-DD') }}
              </template>
              <template #cell(status)="data">
                <b-form-select
                  v-model="data.item.status"
                  style="width:90px;"
                  :options="statuses"
                  @change="changeMarketPlacesStatus(data.item.id,data.item.status)"
                />
              </template>
              <template #cell(action)="data">
                <b-button
                  variant="flat-primary"
                  class="btn-icon"
                  @click="handleEdit(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  variant="flat-danger"
                  class="btn-icon"
                  @click="marketPlaceToDelete = data.item.id"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-overlay>
    <ConfirmDelete
      :show="!!marketPlaceToDelete"
      :is-processing-delete="isDeleting"
      :on-delete="handleDelete"
      :title="$t('delete')"
      @close="marketPlaceToDelete = null"
    />
    <b-modal
      id="image-modal"
      ref="image-modal"
      title="Marketplace Preview"
      hide-footer
      size="lg"
    >
      <!-- <img
        :src="activeImage"
        class="w-100"
        style="max-height:500px;"
      > -->

      <banner
        :marketplace="activeMarketplace"
        :price="'30000'"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BTab, BTabs, BTable, BCard, BOverlay, BFormSelect,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ConfirmDelete from '@/views/common/components/DeleteModal.vue'
import { USER_TYPE_SUPER, USER_TYPE_SCHOOL } from '@/const/userType'
import { getUserData } from '@/auth/utils'
import i18n from '@/libs/i18n'
import MarketPlaceUpdate from '../components/add-update.vue'
import Banner from '../components/banner.vue'

export default {
  components: {
    BButton,
    MarketPlaceUpdate,
    BTab,
    BTabs,
    BTable,
    BCard,
    BOverlay,
    // BBadge,
    ConfirmDelete,
    BFormSelect,
    Banner,
  },
  data() {
    return {
      showMarketPlaceCreate: false,
      columns: [
        {
          key: 'title',
          label: i18n.tc('marketplace-module.name'),
        },
        {
          key: 'price',
          label: i18n.tc('marketplace-module.price'),
        },
        {
          key: 'image',
          label: i18n.tc('marketplace-module.image'),
        },
        {
          key: 'start_time',
          label: i18n.tc('marketplace-module.start_time'),
        },
        {
          key: 'end_time',
          label: i18n.tc('marketplace-module.end_time'),
        },
        {
          key: 'url',
          label: i18n.tc('marketplace-module.url'),
        },
        {
          key: 'status',
          label: i18n.tc('marketplace-module.status'),
        },
        {
          key: 'action',
          label: i18n.tc('marketplace-module.action'),
        },
      ],
      tabs: [
        {
          name: i18n.tc('reservation-moduel.open'),
          value: 'open',
        },
        {
          name: i18n.tc('reservation-moduel.closed'),
          value: 'closed',
        },
        {
          name: i18n.tc('reservation-moduel.all'),
          value: 'all',
        },
      ],
      statuses: [
        {
          text: i18n.tc('reservation-moduel.open'),
          value: 'open',
        },
        {
          text: i18n.tc('reservation-moduel.closed'),
          value: 'closed',
        },
      ],
      rows: [],
      isProcessing: false,
      marketPlaceToEdit: null,
      status: null,
      isDeleting: false,
      marketPlaceToDelete: null,
      self: getUserData(),
      activeMarketplace: null,
    }
  },
  computed: {
    isSuperUser() {
      return this.self.usertype === USER_TYPE_SUPER
    },
    isSchoolUser() {
      return this.self.usertype === USER_TYPE_SCHOOL
    },
  },
  created() {
    this.getMarketPlaces()
  },
  methods: {
    getMarketPlaces(status = this.status) {
      this.isProcessing = true
      this.status = status
      if (this.isSuperUser) {
        useJwt.getMarketPlaces({
          params: {
            status,
          },
        }).then(response => {
          this.rows = response.data.data.map(d => ({ ...d, school_ids: d?.schools.map(item => item.id) }))
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
      } else {
        useJwt.getSchoolMarketPlaces(this.self.id, {
          params: {
            status,
          },
        }).then(response => {
          this.rows = response.data.data.map(d => ({ ...d, school_ids: d?.schools?.map(item => item.id) }))
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
      }
    },
    handleEdit(marketplace) {
      this.marketPlaceToEdit = { ...marketplace }
    },
    handleDelete() {
      this.isDeleting = true
      useJwt.deleteMarketPlaces(this.marketPlaceToDelete).then(response => {
        this.getMarketPlaces()
        this.marketPlaceToDelete = null
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isDeleting = false
      })
    },
    changeMarketPlacesStatus(id, status) {
      useJwt.changeMarketPlacesStatus(id, { status }).then(res => {
        this.getMarketPlaces()
        this.showSuccessMessage(res)
      }).catch(err => {
        this.showErrorMessage(err)
      })
    },
  },
}
</script>
