<template>
  <div id="report-card">
    <b-card>
      <b-row>
        <b-col
          cols="12"
          class="text-right pb-2"
        >
          <b-button
            variant="primary"
            data-html2canvas-ignore="true"
            @click="exportPdf()"
          >
            {{ $t('student-report-module.report-pages.export-pdf') }}
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h4>
            {{ $t('student-report-module.report-pages.role-chart') }}
          </h4>
          <vue-apex-charts
            width="700"
            type="bar"
            :options="barChartOptions"
            :series="seriesData"
          />
        </b-col>
        <b-col md="4">
          <h4>
            {{ $t('student-report-module.summary') }}
          </h4>
          <strength-table
            :title="$t('role-permissions-module.role-name')"
            class="mt-2"
            :summary="getMinMax"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h4>
        {{ $t('student-report-module.report-pages.time-line-of-role-chart') }}
      </h4>
      <b-table-simple
        class="table-striped table-hover table-bordered"
        responsive=""
      >
        <b-thead>
          <b-tr>
            <b-th>
              {{ $t('student-report-module.report-pages.student-name') }}
            </b-th>
            <b-th
              v-for="month of months"
              :key="month"
              class="text-center"
            >
              {{ monthsList[month] }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td />
            <b-td
              v-for="month of months"
              :key="month"
            >
              <div class="d-flex justify-content-between">
                <div
                  v-for="schedule of roleType"
                  :key="schedule"
                  class="mr-1"
                >
                  {{ capitalizeFirstLetter(schedule) }}
                </div>
              </div>
            </b-td>
          </b-tr>
          <b-tr
            v-for="table,index of tableData"
            :key="index"
          >
            <b-th>
              {{ table.fullName }}
            </b-th>
            <b-td
              v-for="month of months"
              :key="month"
            >
              <div class="d-flex justify-content-between">
                <div
                  v-for="schedule of roleType"
                  :key="schedule"
                  v-b-tooltip.hover.v-info
                  :title="table[month][schedule].actualValue"
                  class="mr-1"
                >
                  {{ table[month][schedule].value }}%
                </div>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import VueApexCharts from 'vue-apexcharts'
import {
  BCard, BRow, BCol, BTableSimple, BThead, BTh, BTbody, BTr, BTd, VBTooltip, BButton,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import moment from 'moment'
import StrengthTable from './StrengthTable.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    VueApexCharts,
    BTableSimple,
    BThead,
    BTh,
    BTbody,
    BTr,
    BTd,
    StrengthTable,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    classRoom: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      roleType: ['answer', 'conductor', 'read'],
      seriesData: [],
      categories: [],
      tableData: [],
      months: [],
      monthsList: ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    }
  },
  computed: {
    barChartOptions() {
      return {
        colors: ['#F44336', '#259688', '#FBC108', '#4CAF50'],
        chart: {
          id: 'vuechart-example',
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          formatter(val) {
            return `${val}%`
          },
          style: {
            fontSize: '12px',
            colors: ['#fff'],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: this.categories,
        },
      }
    },
    getMinMax() {
      const series = this.seriesData
      const reports = []
      series.forEach(score => {
        const maxScore = Math.max(...score.data.map(s => Number(s)))
        const minScore = Math.min(...score.data.map(s => Number(s)))
        const weakest = []
        const strongest = []
        this.categories.forEach((cat, index) => {
          const value = Number(score.data?.[index])
          if (value === maxScore) {
            strongest.push({
              fullName: cat,
              score,
            })
          } else if (value === minScore) {
            weakest.push({
              fullName: cat,
              score,
            })
          }
        })
        reports.push({
          title: i18n.tc(`student-report-module.report-pages.role-type.${score.title}`),
          weakest,
          strongest,
        })
      })
      return reports
    },
  },
  mounted() {
    this.scheduleReport()
  },
  methods: {
    setGraphByUsers(report, totalRole) {
      const groupReport = this.groupByKey(report, 'student_id')
      const seriesData = []
      this.roleType.forEach(type => {
        seriesData.push({
          name: this.capitalizeFirstLetter(type),
          title: type,
          data: [],
        })
      })
      const categories = []
      Object.entries(groupReport).forEach(([, result]) => {
        categories.push(`${result[0].user?.firstname} ${result[0].user?.lastname}`)
        this.roleType.forEach((type, index) => {
          const score = result.reduce((p, c) => p + Number(c[type]), 0)
          const percentage = (score / totalRole[type]) * 100
          seriesData[index].data.push(percentage.toFixed(2))
        })
      })
      this.seriesData = seriesData
      this.categories = categories
    },
    setTableByUsers(report) {
      const groupReport = this.groupByKey(report, 'student_id')
      const tableData = []
      const months = [...new Set(report.map(r => Number(r.month)))]
      Object.entries(groupReport).forEach(([, result]) => {
        const summary = {}
        months.forEach(month => {
          this.roleType.forEach(type => {
            const totalOnThisMonth = report.filter(r => Number(r.month) === Number(month)).reduce((p, c) => p + Number(c[type]), 0)
            const score = result.filter(r => Number(r.month) === Number(month)).reduce((p, c) => p + Number(c[type]), 0)
            const percentage = (score / totalOnThisMonth) * 100
            if (!summary[month]) {
              summary[month] = {}
              summary[month][type] = {
                value: percentage.toFixed(2),
                actualValue: `${score}/${totalOnThisMonth}`,
              }
            } else {
              summary[month][type] = {
                value: percentage.toFixed(2),
                actualValue: `${score}/${totalOnThisMonth}`,
              }
            }
          })
        })
        summary.fullName = `${result[0].user?.firstname} ${result[0].user?.lastname}`
        tableData.push(summary)
      })
      this.tableData = tableData
      this.months = months
    },
    exportPdf() {
      const element = document.getElementById('report-card')
      this.printPdf(element, 'role-report.pdf')
    },
    scheduleReport() {
      useJwt.getRoleReport(this.classRoom.id).then(response => {
        const report = response.data.data.report.map(data => ({
          ...data,
          month: moment(data.date).format('MM'),
        }))
        const totalRole = {}
        this.roleType.forEach(role => {
          totalRole[role] = report.reduce((p, c) => p + Number(c[role]), 0)
        })
        this.setGraphByUsers(report, totalRole)
        this.setTableByUsers(report)
      })
    },
  },
}
</script>
