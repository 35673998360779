<template>
  <div v-if="network">

    <Header :show-back-button="true" />

    <cover />

    <b-row>

      <b-col
        lg="3"
        md="12"
      >
        <b-row>

          <description />

          <co-leaders v-if="isALeader" />

          <accounts v-if="isALeader || isACoLeader" />

        </b-row>
      </b-col>
      <b-col
        lg="6"
        md="12"
      >

        <create-article v-if="hasJoinedNetwork" />

        <b-overlay
          id="overlay-background"
          :show="isProcessing"
          :opacity="0"
          blur="4px"
          rounded="sm"
        >
          <articles />
        </b-overlay>

      </b-col>
      <b-col
        lg="3"
        md="12"
      >
        <b-row>

          <members v-if="hasJoinedNetwork" />

          <requests v-if="isALeader || isACoLeader" />

          <invitations v-if="isALeader || isACoLeader" />

          <sponsers />

          <charities />

        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import Header from '@/views/common/network/components/Header.vue'
import Ripple from 'vue-ripple-directive'
import Articles from '@/views/common/network/components/article/Index.vue'
import CreateArticle from '@/views/common/network/components/article/Create.vue'
import Members from '@/views/common/network/components/Members.vue'
import Accounts from '@/views/common/network/components/Accounts.vue'
import Sponsers from '@/views/common/network/components/Sponsers.vue'
import Charities from '@/views/common/network/components/Charities.vue'
import Description from '@/views/common/network/components/Details.vue'
import CoLeaders from '@/views/common/network/components/CoLeaders.vue'
import Requests from '@/views/common/network/components/Requests.vue'
import Cover from '@/views/common/network/components/Cover.vue'
import Invitations from '@/views/common/network/components/Invitations.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    Header,
    CoLeaders,
    BOverlay,
    Requests,
    Accounts,
    CreateArticle,
    Cover,
    Invitations,
    Sponsers,
    Charities,
    Members,
    Articles,
    Description,
    BCol,
  },
  directives: {
    Ripple,
  },

  computed: {
    ...mapGetters({
      hasJoinedNetwork: 'network/hasJoinedNetwork',
      isALeader: 'network/isALeader',
      isACoLeader: 'network/isACoLeader',
      isAMember: 'network/isAMember',
      networkId: 'network/networkId',
      isProcessing: 'network/isProcessing',
      self: 'network/self',
    }),

    network() {
      return this.$store.state.network.network
    },
    members() {
      return this.$store.state.network.members
    },
    myNetworkMembership() {
      return this.$store.state.network.myNetworkMembership
    },

  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.initiate()
      }
    },
  },

  created() {
    this.initiate()
  },
  methods: {
    initiate() {
      const id = parseInt(this.$route.params.id, 10)
      this.$store.commit('network/RESET_DATA')
      this.$store.commit('network/SET_NETWORK_ID', parseInt(id, 10))

      if (this.self) {
        this.$store.dispatch('network/getMyNetworkMembership').then(() => {
          this.$store.dispatch('network/getNetwork')
          this.$store.dispatch('network/getNetworkMembers')
        })
      } else {
        this.$store.dispatch('network/getNetwork')
      }
    },
  },
}
</script>
