<template>
  <div class="single-line-ui">
    <!-- Date Range 1 -->
    <div class="date-range">
      <b-form-group label="Start Date"
                    label-for="start-date-1"
                    class="mb-0"
      >
        <b-form-datepicker
          v-model="props.form.startDate1"
          type="date"
          :date-format-options="dateFormat"
          :max="props.form.endDate1"
        />
      </b-form-group>
      <b-form-group label="End Date"
                    label-for="end-date-1"
                    class="mb-0"
      >
        <b-form-datepicker
          v-model="props.form.endDate1"
          :date-format-options="dateFormat"
          :min="props.form.startDate1"
          type="date"
        />
      </b-form-group>
    </div>

    <!-- Date Range 2 -->
    <div v-if="isComparativeView"
         class="date-range"
    >
      <b-form-group label="Start Date"
                    label-for="start-date-2"
                    class="mb-0"
      >
        <b-form-datepicker
          id="start-date-2"
          v-model="props.form.startDate2"
          :date-format-options="dateFormat"
          :max="props.form.endDate2"
          type="date"
        />
      </b-form-group>
      <b-form-group label="End Date"
                    label-for="end-date-2"
                    class="mb-0"
      >
        <b-form-datepicker
          id="end-date-2"
          v-model="props.form.endDate2"
          :min="props.form.startDate2"
          :date-format-options="dateFormat"
          type="date"
        />
      </b-form-group>
    </div>

    <!-- Export Dropdown -->
    <div class="export-dropdown">
      <b-button variant="outline-primary"
                :disabled="isProcessing"
                class="mr-2"
                @click="$emit('applyFilter')"
      >
        <b-spinner v-if="isProcessing"
                   small
        />
        Go <feather-icon icon="ArrowRightIcon"
                         class="ml-1"
                         size="16"
        />
      </b-button>
      <b-dropdown v-if="!props.hideExport"
                  id="export-dropdown"
                  text="Export"
                  variant="primary"
                  class="export-btn"
      >
        <b-dropdown-item @click="exportFile('csv')">
          Export as CSV
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script setup>
import {
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
  BButton,
  BSpinner,
} from 'bootstrap-vue'

const dateFormat = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  weekday: 'short',
}
const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
  isComparativeView: {
    type: Boolean,
    default: false,
  },
  isProcessing: {
    type: Boolean,
    default: false,
  },
  hideExport: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits(['exportData'])

// Export file function
const exportFile = type => {
  emits('exportData', type)
}
</script>

<style scoped>
/* Container for single-line layout */
.single-line-ui {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1060px;
}

/* Date range container */
.date-range {
  display: flex;
  align-items: center;
  gap: 10px;
}
/* Input hover effect */
.date-input:hover,
.date-input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .single-line-ui {
    flex-wrap: wrap;
    gap: 20px;
  }

  .date-range {
    flex-direction: column;
  }

  .date-input {
    width: 100%;
  }

  .export-btn {
    width: 100%;
    text-align: center;
  }
}
</style>
