var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isVideo)?_c('div',[_c('video-statement',{attrs:{"video-timestamp":_vm.problemStatement[_vm.activeIndex].video,"is-last-statement":_vm.isLastStatement},on:{"nextStatement":function($event){_vm.activeIndex += 1},"continueToProblem":function($event){return _vm.$emit('nextProblem')}}})],1):_c('AnimatedLayout',{attrs:{"enabled":_vm.problem.st_template && !!_vm.problem.st_template.animated_layout,"template-id":_vm.problem.st_template && _vm.problem.st_template.animated_template_id,"is-edit-mode":false,"teleport-target":"statementActionForAnimationLayout"}},[_c('statement',{attrs:{"statement":{
        text: _vm.problemStatements[_vm.activeIndex].statement,
        image: _vm.problemStatements[_vm.activeIndex].statement_image,
        audio: _vm.problemStatements[_vm.activeIndex].statement_audio,
        template: _vm.problemStatements[_vm.activeIndex].st_template,
        theme: _vm.problemStatements[_vm.activeIndex].theme,
        game:
          _vm.problemStatements[_vm.activeIndex].game &&
          _vm.problemStatements[_vm.activeIndex].game.length > _vm.activeIndex
            ? _vm.problemStatements[_vm.activeIndex].game[_vm.activeIndex]
            : null,
      },"is-last-statement":_vm.isLastStatement,"level":_vm.level},on:{"nextStatement":function($event){_vm.activeIndex += 1},"continueToProblem":function($event){return _vm.$emit('nextProblem')}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }