<template>
  <b-card>
    <!-- Video -->
    <b-form-group
      :label="$t('labels.create-problem.video')"
      label-for="video"
    >
      <validation-provider
        v-slot="{ errors }"
        name="video"
        vid="video"
      >
        <b-form-input
          id="video"
          v-model="url"
          name="video"
          type="url"
          :disabled="isCreated"
          :state="errors.length > 0 ? false : null"
          placeholder="Url of your video"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <!-- / Video -->
    <iframe
      :src="video.url"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      class="w-100"
      style="height: 280px"
    />

    <div class="d-flex justify-content-end p-0">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="btn-icon mt-3 ml-auto"
        :disabled="!video.url"
        @click="() => {toggle = true; mode = 'create'}"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t("Add-New") }}
      </b-button>
    </div>
    <!-- Timestaps -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      theme="my-theme"
      class="py-2 grade-table"
      @on-cell-click="showEditor"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <div
          v-if="props.column.field === 'start_time'"
          :class="{ 'active-column': props.row.id === activeTimestamp }"
        >
          {{ props.row.timestamp.startTime }}
        </div>
        <div
          v-if="props.column.field === 'end_time'"
          :class="{ 'active-column': props.row.id === activeTimestamp }"
        >
          {{ props.row.timestamp.endTime }}
        </div>
        <span
          v-if="props.column.field === 'action'"
          class="d-flex justify-content-center"
          :class="{ 'active-column': props.row.id === activeTimestamp }"
        >
          <b-button
            variant="outline-primary"
            class="btn-icon sm-mt-2"
            @click.stop="editTimestamp(props.row)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            variant="outline-danger"
            class="btn-icon sm-mt-2 ml-1"
            @click.stop="() => { deleteData = props.row; mode = 'delete', toggle = true }"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </span>
      </template>
    </vue-good-table>
    <add-timestamp-modal
      :toggle="toggle"
      :mode="mode"
      :edit-data="editData"
      @close="resetModal()"
      @created="isCreated = true"
      @delete="deleteTimestamp(deleteData)"
    />

    <!-- Timestaps -->
  </b-card>
</template>

<script>
import {
  BCard, BFormInput, BFormGroup, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import { validatorYoutubeUrlValidator } from '@/@core/utils/validations/validators'
import { VueGoodTable } from 'vue-good-table'

import AddTimestampModal from './AddTimestampModal.vue'

export default {
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    BButton,
    ValidationProvider,
    AddTimestampModal,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    url: null,
    toggle: false,
    columns: [
      {
        label: 'Start time',
        field: 'start_time',
      },
      {
        label: 'End time',
        field: 'end_time',
      },
      {
        label: 'Actions',
        field: 'action',
        category: false,
        thClass: 'text-center',
      },
    ],
    videoId: null,
    isCreated: false,
    editData: {},
    deleteData: {},
    mode: '',
  }),
  computed: {
    rows: {
      get() {
        return this.$store.getters['problem/getTimestamps']
      },
    },
    activeTimestamp: {
      get() {
        return this.$store.state.problem.create.activeTimestamp
      },
      set(value) {
        this.$store.commit('problem/SET_ACTIVE_TIMESTAMP', value)
      },
    },
    video: {
      get() {
        return this.$store.state.problem.create.video
      },
      set(value) {
        this.$store.commit('problem/SET_VIDEO', value)
      },
    },
    videoCreated: {
      get() {
        return this.$store.state.problem.create.videoCreated
      },
      set(value) {
        this.$store.commit('problem/SET_VIDEO_CREATED', value)
      },
    },
  },
  watch: {
    url(value) {
      this.video = { url: validatorYoutubeUrlValidator(value), id: null }
    },
  },
  methods: {
    editTimestamp(row) {
      this.editData = row
      this.mode = 'edit'
      this.toggle = true
    },
    deleteTimestamp(index) {
      this.$store.commit('problem/DELETE_TIMESTAMP', index)
      this.resetModal()
    },
    showEditor(params) {
      this.activeTimestamp = params.row.id
    },
    resetModal() {
      this.toggle = false
      this.mode = ''
      this.editData = {}
      this.deleteData = {}
    },
  },
}
</script>

<style lang="scss">
.grade-table{
  tbody {
    td {
      vertical-align: middle !important;
      padding: 0 !important;
      div {
        padding: 18px 14px;
      }
      span {
        padding: 11.5px 14px;
      }
      div:hover,
      span:hover {
        cursor: pointer;
      }
    }
  }
}
.active-column {
  background-color: rgba(0, 0, 0, 0.15);
}
</style>
