<template>
  <b-card class="border mb-0">
    <div class="player px-0">
      <b-button
        v-if="isPlaying"
        variant="primary"
        class="play-button"
        @click="pause"
      >
        <feather-icon icon="PauseIcon" />
      </b-button>
      <b-button
        v-else
        variant="primary"
        class="play-button"
        @click="play"
      >
        <feather-icon icon="PlayIcon" />
      </b-button>
      <div class="audio-length-slider">
        <vue-slider
          v-model="value"
          :direction="dir"
          :min="min"
          :max="max"
          :tooltip-formatter="formatter"
          @change="change"
        />
      </div>
      <div class="time-display">
        -{{ formattedValue }}
      </div>
      <b-button
        v-if="downloadable"
        variant="outline-primary"
        class="play-button"
        tag="a"
        target="_blank"
        @click="downloadAudio"
      >
        <feather-icon icon="DownloadIcon" />
      </b-button>
    </div>
    <audio
      ref="player"
      preload="metadata"
      :src="src"
      controls
      style="display: none"
      @timeupdate="updateValue"
      @loadedmetadata="setMax"
      @ended="onAudioEnded"
      @pause="onAudioPaused"
    />
  </b-card>
</template>

<script>
import VueSlider from 'vue-slider-component'
import { BCard, BButton } from 'bootstrap-vue'
import getBlobDuration from 'get-blob-duration'
import moment from 'moment'

export default {
  components: {
    VueSlider,
    BCard,
    BButton,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    downloadable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      value: 0,
      min: 0,
      max: null,
      dir: 'ltr',
      isPlaying: false,
      formatter: v => moment.utc(v * 1000).format('mm:ss'),
    }
  },
  computed: {
    formattedValue() {
      return moment.utc((this.max - this.value) * 1000).format('mm:ss')
    },
  },
  watch: {
    value() {
      if (this.value >= this.max) {
        this.isPlaying = false
      }
    },
  },
  methods: {
    play() {
      this.isPlaying = true
      this.$refs.player.play()
    },
    pause() {
      this.isPlaying = false
      this.$refs.player.pause()
    },
    updateValue() {
      this.value = parseInt(this.$refs.player.currentTime, 10)
    },
    downloadAudio() {
      const link = document.createElement('a')
      link.download = this.src
      link.href = this.src
      const currentTimeStamp = Date.now()
      const fileName = `${currentTimeStamp}.mp3`
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    async setMax() {
      if (this.$refs.player.duration === Infinity) {
        const duration = await getBlobDuration(this.src)
        this.max = parseInt(duration, 10)
      } else {
        this.max = parseInt(this.$refs.player.duration, 10)
      }
    },
    change() {
      this.$refs.player.currentTime = this.value
    },
    onAudioEnded() {
      this.isPlaying = false
      this.value = 0
    },
    onAudioPaused() {
      this.isPlaying = false
    },
  },
}
</script>

<style lang='scss'>
@import "@core/scss/vue/libs/vue-slider.scss";
.player {
  display: flex;
  align-items: center;
  gap: 20px;
  .audio-length-slider {
    width: 80% !important;
  }
  .play-button {
    padding: 5px;
  }
  .time-display {
    font-size: 10px;
  }
}
</style>
