/* eslint-disable import/no-cycle */
import curriculumUsersOnly from '@/middlewares/curriculumUsersOnly'
import isAuthenticated from '@/middlewares/isAuthenticated'

export default [
  {
    path: '/curriculum/library',
    name: 'curriculum-library',
    meta: {
      middleware: [isAuthenticated, curriculumUsersOnly],
    },
    component: () => import('@/views/super/library/index.vue'),
  },
  {
    path: '/curriculum/curriculum-builder/:library',
    meta: {
      middleware: [isAuthenticated, curriculumUsersOnly],
      navActiveLink: 'super-curriculum-builder',
    },
    name: 'curriculum-curriculum-builder',
    component: () => import('@/views/common/curriculum/Index.vue'),
  },
  {
    path: '/curriculum/error-reports',
    name: 'curriculum-error-reports',
    meta: {
      middleware: [isAuthenticated, curriculumUsersOnly],
    },
    component: () => import('@/views/common/report-issue/Index.vue'),
  },
  {
    path: '/curriculum/courses',
    name: 'curriculum-courses',
    component: () => import('@/views/common/course/Index.vue'),
    meta: {
      middleware: [isAuthenticated, curriculumUsersOnly],
    },
  },
  {
    path: '/curriculum/problem',
    meta: {
      middleware: [isAuthenticated, curriculumUsersOnly],
    },
    name: 'curriculum-problem',
    component: () => import('@/views/super/problem/Index.vue'),
  },
  {
    path: '/curriculum/problem/create',
    meta: {
      middleware: [isAuthenticated, curriculumUsersOnly],
    },
    name: 'curriculum-problem-create',
    component: () => import('@/views/super/problem/create-problem/Index.vue'),
  },
  {
    path: '/curriculum/problem/edit',
    meta: {
      middleware: [isAuthenticated, curriculumUsersOnly],
    },
    name: 'curriculum-problem-edit',
    component: () => import('@/views/super/problem/edit-problem/Index.vue'),
  },
  {
    path: '/curriculum-demo-lesson',
    name: 'curriculum-demo-lesson',
    meta: {
      layout: 'full',
      // middleware: [isNotAuthenticated],
    },
    component: () => import('@/views/GuestLesson/index.vue'),
  },
  {
    path: '/curriculum/image-library',
    meta: {
      middleware: [isAuthenticated, curriculumUsersOnly],
    },
    name: 'curriculum-image-library',
    component: () => import('@/views/super/image-library/index.vue'),
  },
  {
    path: '/curriculum/assets-management',
    meta: {
      middleware: [isAuthenticated, curriculumUsersOnly],
    },
    name: 'curriculum-assets-management',
    component: () => import('@/views/super/assets-management/index.vue'),
  },
]
