<template>
  <b-modal
    :visible="show"
    size="xl"
    :title="$t('setting-lists')"
    ok-only
    :ok-title="$t('ok')"
    @show="()=>{getClassRoomSettings(); getGameSettings();}"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-overlay>
      <b-tabs
        vertical
      >
        <b-tab
          title="AI"
        >
          <b-row>
            <b-col cols="12">
              <ai-settings
                :all-settings="allSettings"
                :class-id="classId"
              />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab
          :title="$t('setting-module.game-title')"
        >
          <b-row>
            <b-col cols="12">
              <game-settings
                :all-settings="allSettings"
                :class-id="classId"
              />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab
          :title="$t('setting-module.classroom-student-lab-settings-tab')"
        >
          <b-row>
            <b-col md="12">
              <student-lab-settings
                :all-settings="allSettings"
                :class-id="classId"
              />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab
          :title="$t('labels.engagements')"
        >
          <b-row>
            <b-col md="12">
              <!-- <engagement-settings
                :school-settings="schoolSettings"
                :all-settings="allSettings"
                :class-id="classId"
              /> -->
              <h3 class="mb-2">
                {{ $t('navigation-menu.engagements-settings') }}
              </h3>
              <b-table-simple
                responsive
              >
                <b-thead head-variant="light">
                  <b-tr>
                    <b-th
                      width="40%"
                    >
                      {{ $t('setting-module.options') }}
                    </b-th>
                    <b-th
                      width="40%"
                    >
                      {{ $t('setting-module.switch') }}
                    </b-th>
                    <b-th
                      width="20%"
                    >
                      {{ $t('setting-module.help-guide') }}
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-if="!hideGame">
                    <b-td>{{ $t('setting-module.game-title') }}</b-td>
                    <b-td>
                      <span class="d-flex align-items-baseline">
                        <b-form-checkbox id="game-settings"
                                         v-model="allSettings.game"
                                         value="1"
                                         unchecked-value="0"
                                         switch
                                         :disabled="!currentSettingName"
                                         @change="updateClassRoomSetting('game')"
                        />
                        <span class="ml-2">
                          {{ $t('setting-module.off-on') }}
                        </span>
                      </span>
                    </b-td>
                    <b-td>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top="$t('setting-module.help-guide')"
                        variant="outline-success"
                        class="btn-icon"
                        @click="showHelpGuide('game')"
                      >
                        <feather-icon icon="InfoIcon" />
                      </b-button>
                    </b-td>
                  </b-tr>
                  <b-tr v-else
                        class="text-primary"
                  >
                    <b-td colspan="3">
                      {{ $t('setting-module.game-off') }}
                    </b-td>
                  </b-tr>

                  <b-tr v-if="!hideAvatar">
                    <b-td>{{ $t('setting-module.avatar-title') }}</b-td>
                    <b-td>
                      <span class="d-flex align-items-baseline">
                        <b-form-checkbox v-model="allSettings.avatar"
                                         value="1"
                                         unchecked-value="0"
                                         switch
                                         @change="updateClassRoomSetting('avatar')"
                        />
                        <span class="ml-2">
                          {{ $t('setting-module.off-on') }}
                        </span>
                      </span>
                    </b-td>
                    <b-td>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top="$t('setting-module.help-guide')"
                        variant="outline-success"
                        class="btn-icon"
                        @click="showHelpGuide('avatar')"
                      >
                        <feather-icon icon="InfoIcon" />
                      </b-button>
                    </b-td>
                  </b-tr>
                  <b-tr v-else
                        class="text-primary"
                  >
                    <b-td colspan="3">
                      {{ $t('setting-module.avatar-off') }}
                    </b-td>
                  </b-tr>

                  <b-tr v-if="!hideAttention">
                    <b-td>{{ $t('setting-module.attention-system-title') }}</b-td>
                    <b-td>
                      <span class="d-flex align-items-baseline">
                        <b-form-checkbox v-model="allSettings.attention"
                                         value="1"
                                         unchecked-value="0"
                                         switch
                                         @change="updateClassRoomSetting('attention')"
                        />
                        <span class="ml-2">
                          {{ $t('setting-module.off-on') }}
                        </span>
                      </span>
                    </b-td>
                    <b-td>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top="$t('setting-module.help-guide')"
                        variant="outline-success"
                        class="btn-icon"
                        @click="showHelpGuide('attention')"
                      >
                        <feather-icon icon="InfoIcon" />
                      </b-button>
                    </b-td>
                  </b-tr>
                  <b-tr v-else
                        class="text-primary"
                  >
                    <b-td colspan="3">
                      {{ $t('setting-module.attention-system-off') }}
                    </b-td>
                  </b-tr>

                  <b-tr v-if="!hideGame">
                    <b-td> {{ $t('labels.current-game-setting') }}</b-td>
                    <b-td>
                      <span class="d-flex align-items-baseline">
                        <b-form-select
                          v-model="currentSettingName"
                          class="mt-1"
                          :options="settingNames"
                          @change="updateCurrentGameSetting"
                        />

                      </span>
                    </b-td>
                    <b-td>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top="$t('setting-module.help-guide')"
                        variant="outline-success"
                        class="btn-icon"
                        @click="showHelpGuide('game_setting')"
                      >
                        <feather-icon icon="InfoIcon" />
                      </b-button>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <!-- Settings -->
              <b-table
                v-if="(currentSettingName && !hideGame && currentSetting.length > 0)"
                :fields="fields"
                :items="currentSetting"
                responsive
              >
                <template #cell(value)="data">
                  <b-form-input
                    :value="data.item.value"
                    :placeholder="data.item.name"
                    class="col-3"
                    @change="(value) => updateSetting(data.item.name, value)"
                  />
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab
          :title="$t('labels.live-link')"
        >
          <b-row>
            <b-col md="12">
              <b-col cols="12">
                <live-class-settings
                  :all-settings="allSettings"
                  :class-id="classId"
                />
              </b-col>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab
          :title="$t('setting-module.visibility-options')"
        >
          <b-row>
            <b-col md="12">
              <visibility-option-settings
                :all-settings="allSettings"
                :class-id="classId"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-overlay>
    <setting-info-modal
      :show="showModal"
      :modal-data="modalData"
      @close="showModal = false"
    />
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BModal, BTable, BOverlay, BFormInput, BFormCheckbox, BFormSelect, BTabs, BTab, VBTooltip,
  BTableSimple,
  BThead, BTbody, BTr, BTh, BTd,
  BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import fonts from '@/const/fonts'
import i18n from '@/libs/i18n'
import SettingInfoModal from '@/views/common/components/SettingInfoModal.vue'
import { isVisible } from '@/utils/visibilitySettings'
import AiSettings from './settings/AiSettings.vue'
import GameSettings from './settings/GameSettings.vue'
import StudentLabSettings from './settings/StudentLabSettings.vue'
// import EngagementSettings from './settings/EngagementSettings.vue'
import LiveClassSettings from './settings/LiveClassSettings.vue'
import VisibilityOptionSettings from './settings/VisibilityOptionSettings.vue'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BTable,
    BOverlay,
    BFormInput,
    BFormCheckbox,
    // BFormGroup,
    BFormSelect,
    BTabs,
    BTab,
    AiSettings,
    GameSettings,
    StudentLabSettings,
    // EngagementSettings,
    LiveClassSettings,
    VisibilityOptionSettings,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
    SettingInfoModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    self: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      settings: null,
      settingNames: null,
      fields: [
        {
          label: i18n.tc('labels.name'),
          key: 'name',
          tdClass: 'w-25',
        },
        {
          label: i18n.tc('description'),
          key: 'description',
          tdClass: 'w-25',
        },
        {
          label: i18n.tc('labels.value'),
          key: 'value',
        },
      ],
      currentSettingName: null,
      currentSetting: null,
      isProcessing: false,
      isPublic: 0,
      allSettings: {
        game: 0,
        avatar: 0,
        attention: 0,
        enable_open_ai_check: 0,
        game_weekly_limit: 0,
        larger_font: 0,
        speech_tries: 3,
        has_chat_bot: 0,
        font: '',
        live_class_max_time_limit: 0,
        student_card: 0,
        full_course_progress: 0,
        current_progress: 0,
        skill_chart: 0,
        point: 0,
        language_level: 0,
        badge: 0,
        ai_speech: 0,
        ai_feedback: 0,
      },

      schoolSettings: {
        game: 0,
        avatar: 0,
        attention: 0,
        ai_feedback: 0,
        font: '',
      },

      fontsOptions: fonts,

      showModal: false,
      modalData: {
        title: '',
        imgSrc: '',
        description: '',
        steps: '',
      },
      modalInfo: {
        game: {
          title: i18n.tc('setting-module.game-title'),
          description: i18n.tc('setting-module.game'),
          imageSrc: '',
          steps: '',
        },
        avatar: {
          title: i18n.tc('setting-module.avatar-title'),
          description: i18n.tc('setting-module.avatar'),
          imageSrc: '',
          steps: '',
        },
        attention: {
          title: i18n.tc('setting-module.attention-system-title'),
          description: i18n.tc('setting-module.attention-system'),
          imageSrc: '',
          steps: '',
        },
        game_setting: {
          title: i18n.tc('labels.current-game-setting'),
          description: i18n.tc('setting-module.current-game-setting-info'),
          imageSrc: '',
          steps: '',
        },
      },
    }
  },
  computed: {
    classId() {
      return Number(this.$route.params.id)
    },
    fontSelected() {
      const selectedOption = fonts.find(option => option.value === this.allSettings?.font)
      return { 'font-family': selectedOption?.text }
    },

    hideGame() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('game', schoolSetting, distSetting)
    },
    hideAvatar() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('avatar', schoolSetting, distSetting)
    },
    hideAttention() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('attention', schoolSetting, distSetting)
    },
  },
  methods: {
    getGameSettings() {
      if (this.settingNames && this.settingNames.length) return
      this.isProcessing = true
      let classSettings

      useJwt.getClassroomGameSettings(this.classId).then(roomSetting => {
        classSettings = roomSetting.data?.data
        if (classSettings) {
          this.currentSettingName = classSettings.setting_name
          this.isPublic = classSettings.is_public
        }
        useJwt.getGameSettings().then(response => {
          this.settings = response.data?.data?.game_setting.filter(setting => setting.name === 'RATIO' || setting.name === 'COUNT_PLAY')
          this.settingNames = this.settings.map(setting => setting.name)
          this.settingNames.unshift({ text: 'Select a Setting', value: null, disabled: true })

          this.settings.forEach(setting => {
            if (classSettings) {
              if (setting.name === classSettings.setting_name) {
              /* eslint-disable no-param-reassign */
                setting.value = classSettings.value
              }
            }
            this.setCurrentGameSetting()
          })
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => { this.isProcessing = false })
      })
    },

    getClassRoomSettings() {
      useJwt.getClassRoomSettings(this.classId).then(res => {
        const { data, school_settings, district_settings } = res.data
        console.log(school_settings, district_settings)
        this.setClassRoomSettings('all', data)
        this.setClassRoomSettings('school', school_settings, district_settings)
      })
    },

    // updateWeekLimit() {
    //   useJwt.updateUserPermission({ permission: `class-${this.classId}-game_weekly_limit`, value: this.allSettings.weekly_time_limit }).then(() => {
    //     this.showSuccessMessage({ data: { message: 'Game Weekly Limit Changed' } })
    //     this.getClassRoomSettings()
    //   })
    // },

    setClassRoomSettings(type, data, districtSettings = null) {
      const districtSettingKeys = ['ai_feedback', 'has_chat_bot', 'ai_speech', 'enable_open_ai_check', 'avatar', 'game', 'attention']
      if (type === 'school') {
        Object.keys(this.allSettings).forEach(key => {
          if (key === 'enable_open_ai_check') {
            const districtSetting = districtSettings.find(setting => setting.setting_name === 'open_ai')
            if (Number(districtSetting.value)) {
              this.schoolSettings.enable_open_ai_check = data.filter(setting => setting.setting_name === key)[0]?.value || 0
              if (!this.schoolSettings[key]) {
                this.schoolSettings.enable_open_ai_check = data.find(setting => setting.setting_name === 'open_ai')?.value || 0
              }
            } else this.schoolSettings.enable_open_ai_check = 0
          }

          if (districtSettingKeys.includes(key)) {
            const districtSetting = districtSettings.find(setting => setting.setting_name === key)

            if (Number(districtSetting.value)) {
              this.schoolSettings[key] = data.filter(setting => setting.setting_name === key)[0]?.value || 0
            } else this.schoolSettings[key] = 0
          } else {
            this.schoolSettings[key] = data.filter(setting => setting.setting_name === key)[0]?.value || 0
          }
        })
      } else {
        Object.keys(this.allSettings).forEach(key => {
          this.allSettings[key] = data.filter(setting => setting.setting_name === key)[0]?.value || 0
        })
      }
    },

    updateSetting(name, value) {
      const params = {
        classroom_id: this.classId,
        setting_name: name,
        value: Number(value),
        created_by: this.self.id,
        is_public: this.isPublic,
      }

      useJwt.setClassroomGameSettings(params).then(response => {
        this.showSuccessMessage(response)

        this.settings.find(setting => setting.name === name).value = value
      })
    },

    updateClassRoomSetting(type) {
      if (type === 'font') {
        const selectedFont = fonts.find(option => option.value === this.allSettings[type])
        if (localStorage.getItem('schoolData')) {
          const schoolData = JSON.parse(localStorage.getItem('schoolData'))
          schoolData.font = selectedFont.text
          localStorage.setItem('schoolData', JSON.stringify(schoolData))
        } else {
          const schoolData = {
            font: selectedFont.text,
          }
          localStorage.setItem('schoolData', JSON.stringify(schoolData))
        }
      }
      useJwt.updateClassroomSetting(this.classId, type, {
        value: this.allSettings[type],
      }).then(res => {
        this.showSuccessMessage(res)
        this.getClassRoomSettings()
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },

    updateCurrentGameSetting() {
      this.setCurrentGameSetting()
      this.updateSetting(this.currentSettingName, this.currentSetting[0].value)
    },
    setCurrentGameSetting() {
      this.currentSetting = this.settings.filter(setting => setting.name === this.currentSettingName)
    },
    showHelpGuide(type) {
      this.showModal = true
      this.modalData = {
        title: this.modalInfo[type].title,
        imgSrc: this.modalInfo[type].imageSrc,
        description: this.modalInfo[type].description,
        steps: this.modalInfo[type].steps,
      }
    },
  },
}
</script>

<style>
  .modal-dialog .nav .nav-item{
    margin-bottom: 5px;
  }
  .modal-dialog .nav {
    border-right: 1px solid #ededed;
  }
  .modal-dialog .tab-content {
    background: #ffffff;
  }
  .dark-layout .modal-dialog .tab-content {
    background: unset;
  }
</style>
