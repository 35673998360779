/* eslint-disable max-classes-per-file */
/* eslint-disable no-undef */
// this store is used in App.vue

// eslint-disable-next-line import/no-cycle
import { getUserData } from '@/auth/utils'
import { USER_TYPE_STUDENT } from '@/const/userType'
import { STATUS_SLEEP, STATUS_IDLE } from '@/const/avatar'
import html2canvas from 'html2canvas'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'

function getCurrentDateTime() {
  const date = new Date()
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  month = month < 10 ? `0${month}` : month
  let day = date.getDate()
  day = day < 10 ? `0${day}` : day
  let hours = date.getHours()
  hours = hours < 10 ? `0${hours}` : hours
  let minutes = date.getMinutes()
  minutes = minutes < 10 ? `0${minutes}` : minutes
  let seconds = date.getSeconds()
  seconds = seconds < 10 ? `0${seconds}` : seconds

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

function getAverageActivity(data) {
  let total = 0
  data.forEach(element => {
    total += element.mouse_clicks + element.key_presses
  })
  return total
}
function getAverageAttention(data) {
  let total = 0
  data.forEach(element => {
    total += element.avg_attention
  })
  return total / data.length
}

class CamRecord {
  constructor() {
    this.video = document.getElementById('webcam')
    this.canvas = document.createElement('canvas')
    this.canvas.width = 640
    this.canvas.height = 480

    const askForPermission = parseInt(getUserData()?.camara_setting, 10) === 1

    if (askForPermission) {
      this.stream = navigator.mediaDevices.getUserMedia({ video: true, audio: false }).then(stream => {
        this.isPermissionGranted = true
        this.video.srcObject = stream
        this.dataStream = stream
      }).catch(() => {
        this.isPermissionGranted = false
      })
    }

    this.frame = ''
  }

  capture() {
    this.canvas.getContext('2d').drawImage(this.video, 0, 0)
    this.frame = this.canvas.toDataURL('image/jpeg')
    return this.frame
  }
}

export default {
  namespaced: true,
  state: {
    webCam: null,
    screenRecord: null,
    mouseClicks: 0,
    keyPresses: 0,
    studentAttention: [],
    studentActivityInterval: null,
    checkSleepStatusInterval: null,
    studentInteractionInterval: null,
    recordStats: false,
    screenCaptureCanvas: null,
    captureStream: null,
    dataIn10Minutes: {
      startTime: null,
      activities: [],
      screenshot: null,
      time: null,
      url: null,
      initial_test_id: null,
    },
    fileUploadLoading: false,
    alarmAudio: new Audio('/animations/alarm.wav'),
    audioInterval: null,
    recordAttention: null,

  },

  getters: {
    currentStudentAttention: state => (state.studentAttention.length > 0 ? state.studentAttention[state.studentAttention.length - 1] : 0),
    getFileUploadLoading: state => (state.fileUploadLoading),
  },

  actions: {

    init({ state, commit, dispatch }) {
      if (getUserData() && getUserData().usertype === USER_TYPE_STUDENT) {
        // state.screenRecord = new ScreenRecord()
        state.recordStats = true
        document.addEventListener('mouseup', () => {
          if (state.recordStats)commit('INCREMENT_MOUSE_CLICKS')
          dispatch('appConfig/updateStatus', { oldValue: STATUS_SLEEP, newValue: STATUS_IDLE }, { root: true })
          commit('appConfig/UPDATE_ALARM_STATUS', 'idle', { root: true })
          if (state.audioInterval)clearInterval(state.audioInterval)
          dispatch('pauseSound')
        })

        document.addEventListener('keyup', () => {
          if (state.recordStats)commit('INCREMENT_KEY_PRESSES')
          dispatch('appConfig/updateStatus', { oldValue: STATUS_SLEEP, newValue: STATUS_IDLE }, { root: true })
          commit('appConfig/UPDATE_ALARM_STATUS', 'idle', { root: true })
          if (state.audioInterval)clearInterval(state.audioInterval)
          dispatch('pauseSound')
        })

        document.addEventListener('mousemove', () => {
          dispatch('appConfig/updateStatus', { oldValue: STATUS_SLEEP, newValue: STATUS_IDLE }, { root: true })
          commit('appConfig/UPDATE_ALARM_STATUS', 'idle', { root: true })
          if (state.audioInterval)clearInterval(state.audioInterval)
          dispatch('pauseSound')
        })

        state.dataIn10Minutes.startTime = (new Date()).getTime()

        const oldData = localStorage.getItem('student-activity')
        const oldAttention = localStorage.getItem('student-attention')
        if (oldData && state.dataIn10Minutes.activities.length === 0) {
          commit('SET_STUDENT_ACTIVITIES', JSON.parse(oldData))
          commit('SET_STUDENT_ATTENTION', JSON.parse(oldAttention))
        }

        dispatch('recordStudentAttention')

        state.checkSleepStatusInterval = setInterval(() => {
          if (state.mouseClicks <= 0 && state.keyPresses <= 0) {
            commit('appConfig/UPDATE_CURRENT_STATUS', STATUS_SLEEP, { root: true })
            if (JSON.parse(localStorage.getItem('alarmOn'))) {
              commit('appConfig/UPDATE_ALARM_STATUS', 'alarming', { root: true })
              dispatch('playSound')
            }
          }
        }, 60000)

        state.studentActivityInterval = setInterval(async () => {
          if (state.recordStats) {
            state.dataIn10Minutes.activities.push({
              mouse_clicks: state.mouseClicks,
              key_presses: state.keyPresses,
              url: window.location.href,
              time: getCurrentDateTime(),
              avg_attention: parseInt((state.studentAttention.reduce((a, b) => a + b)) / state.studentAttention.length, 10),
            })
            localStorage.removeItem('student-attention')
            state.mouseClicks = 0
            state.keyPresses = 0
            state.studentAttention = []

            if (state.dataIn10Minutes.activities.length >= 10) {
              await dispatch('captureScreenshot')
              localStorage.removeItem('student-activity')
            } else {
              localStorage.setItem('student-activity', JSON.stringify(state.dataIn10Minutes))
            }
          }
        }, 60000)
      }
    },

    pauseSound({ state }) {
      if (!state.alarmAudio.paused) state.alarmAudio.pause()
    },

    playSound({ state, commit }) {
      if ((state.alarmAudio.paused)) {
        const interval = setInterval(() => {
          state.alarmAudio.play()
        }, 1000)
        commit('UPDATE_AUDIO_INTERVAL', interval)
      }
    },

    dispose({ state }) {
      state.recordStats = false
      if (state.webCam && state.webCam.dataStream)state.webCam.dataStream.getTracks().forEach(track => track.stop())
      if (state.studentInteractionInterval) clearInterval(state.studentInteractionInterval)
      if (state.studentActivityInterval)clearInterval(state.studentActivityInterval)
      if (state.checkSleepStatusInterval) clearInterval(state.checkSleepStatusInterval)
    },

    disposeAttention({ state }) {
      if (state.recordAttention) clearInterval(state.recordAttention)
    },

    captureScreenshot({ commit, dispatch }) {
      // commit('SET_SCREENSHOT', state.screenRecord.capture())
      return new Promise(resolve => {
        html2canvas(document.body, {
          useCORS: true,
          width: window.screen.availWidth,
          height: window.screen.availHeight,
          windowWidth: document.body.scrollWidth,
          windowHeight: document.body.scrollHeight,
          x: 0,
          y: window.pageYOffset,
        }).then(screenshot => {
          const canvas = screenshot.toDataURL()
          commit('SET_SCREENSHOT', canvas)
          dispatch('submitActivities')
        })
        resolve()
      })
    },

    recordStudentAttention({ commit, state }) {
      state.webCam = new CamRecord()
      state.recordAttention = setInterval(() => {
        if (!state.dataIn10Minutes.initial_test_id) state.dataIn10Minutes.initial_test_id = localStorage.getItem('current_test_id') || null
        if (state.webCam.isPermissionGranted) {
          const img = state.webCam.capture()
          useJwt.calculateStudentAttention({ student_id: getUserData().id, webcam_img: img }).then(res => {
            const headMotion = res.data
            commit('APPEND_USER_INTERACTION', parseInt(headMotion.distance * headMotion.head * 100, 10))
          })
        } else {
          commit('APPEND_USER_INTERACTION', 0)
        }
        localStorage.setItem('student-attention', JSON.stringify(state.studentAttention))
      }, 10000)
    },

    submitActivities({ state, commit }) {
      const data = {
        final_test_id: localStorage.getItem('current_test_id') || null,
        duration: parseInt((((new Date()).getTime() - state.dataIn10Minutes.startTime) / 1000), 10),
        ...state.dataIn10Minutes,
      }
      useJwt.submitStudentActivities(data).finally(() => {
        commit('RESET_DATA')
      })
    },

    fileUploadLoading({ commit }, data) {
      commit('SET_FILE_UPLOAD_LOADING', data)
    },
  },

  mutations: {
    SET_STUDENT_ACTIVITIES(state, data) {
      state.dataIn10Minutes = data
      if (!data.startTime) state.dataIn10Minutes.startTime = (new Date()).getTime()
    },
    SET_STUDENT_ATTENTION(state, data) {
      state.studentAttention = data || []
    },
    INCREMENT_MOUSE_CLICKS(state) {
      state.mouseClicks += 1
    },
    INCREMENT_KEY_PRESSES(state) {
      state.keyPresses += 1
    },
    RESET_DATA(state) {
      state.dataIn10Minutes = {
        startTime: (new Date()).getTime(),
        activities: [],
        screenshot: null,
        time: null,
        initial_test_id: null,
        url: null,
      }
    },
    APPEND_USER_INTERACTION(state, data) {
      state.studentAttention.push(data)
    },
    SET_SCREENSHOT(state, screenshot) {
      state.dataIn10Minutes.time = getCurrentDateTime()
      state.dataIn10Minutes.screenshot = screenshot
      state.dataIn10Minutes.url = window.location.href
      state.dataIn10Minutes.avg_activity = getAverageActivity(state.dataIn10Minutes.activities)
      state.dataIn10Minutes.avg_attention = getAverageAttention(state.dataIn10Minutes.activities)
    },
    SET_FILE_UPLOAD_LOADING(state, data) {
      state.fileUploadLoading = data
    },
    UPDATE_AUDIO_INTERVAL(state, data) {
      state.audioInterval = data
    },
  },
}
