export default function getGrades() {
  const DEFAULT_GRADE = process.env.VUE_APP_REA_DEFAULT_SUBJECT ?? 49
  return [
    {
      value: 51,
      text: 'Elementary Topics',
    },
    {
      value: 50,
      text: 'Foundational Knowledge',
    },
    {
      value: 56,
      text: 'High School Courses',
    },
    {
      value: DEFAULT_GRADE,
      text: 'Advanced Placement',
    },
    {
      value: 54,
      text: 'University Knowledge',
    },
    {
      value: 55,
      text: 'Modern Journels',
    },
    {
      value: 41,
      text: 'Personal Development',
    },
  ]
}
