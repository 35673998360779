var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',[_vm._v("Word Index")]),_c('b-row',_vm._l((_vm.words),function(item,index){return _c('b-col',{key:item.id,staticClass:"mb-2",attrs:{"cols":"4"}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"square",class:{
             'mouse-cursor': !item.isPassed && !item.isFailed
           },attrs:{"title":item.vocab_word},on:{"click":function($event){return _vm.handleSelectProblem(item)}}},[_c('div',{staticClass:"square-content",class:{
               'selected-problem': _vm.selectedProblem == item.id,
               'bg-success text-white' : item.isPassed,
               'bg-danger text-white' : item.isFailed
             }},[_vm._v(" "+_vm._s(index + 1)+" ")])])])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }