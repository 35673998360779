<!-- eslint-disable indent -->
<!-- eslint-disable vars-on-top -->
<template>
  <span>
    <template v-if="isTTS || arraySrc || includeGraph || !isFancyPlayerOn">
      <div
        v-if="!onlyIcon"
        class="d-flex align-items-center"
        :class="[{
          'justify-content-center':alignment==='center',
          'justify-content-start':alignment==='left',
          'justify-content-end':alignment==='right',
          'flex-row':alignment==='start',
          'ml-2':alignment==='left'
        },audioClass]"
      >
        <button
          type="button"
          class="audio-play-stop rounded-circle swiper-slide-shadow-bottom bg-white file-btn"
          :class="{'btn-primary ml-3':fromChat}"
          style="cursor:pointer;box-shadow:0px 5px 13px rgba(0,0,0,0.3); margin-bottom: 2px; border: none;"
          @click.stop="playPause"
        >
          <svg
            v-if="!isPlaying"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#8bc34a"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-play d-md-inline-block"
            style="margin-left: 2px;"
          ><polygon points="5 3 19 12 5 21 5 3" />
          </svg>

          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#8bc34a"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-pause stop-audio"
          ><rect
            x="6"
            y="4"
            width="4"
            height="16"
          /><rect
            x="14"
            y="4"
            width="4"
            height="16"
          />
          </svg>

        </button>
        <slot />
      </div>
      <span v-else>
        <feather-icon
          v-if="!isPlaying"
          icon="PlayCircleIcon"
          class="mr-2 play-icon"
          @click="playPause"
        />
        <feather-icon
          v-else
          icon="PauseCircleIcon"
          class="mr-2 play-icon"
          @click="playPause"
        />
        <slot />
      </span>

      <audio
        v-if="!arraySrc"
        :ref="reference"
        controls
        style="height:0;width:0;opacity:0;display: none;"
      >
        <source
          :src="src"
          type="audio/ogg"
        >
        <source
          :src="src"
          type="audio/mpeg"
        >
      </audio>
      <audio v-else
             :ref="reference"
             style="height:0;width:0;opacity:0; display: none;"
             controls
      >
        <source
          v-for="au,index of arraySrc"
          :key="index"
          class="audio-source"
          :src="au"
          type="audio/mpeg"
        >
      </audio>
      <div
        v-if="includeGraph"
        v-show="playingWaveForm"
        ref="waveform_div"
        style="height: 100px"
      />
    </template>
    <FancyAudioPlayer v-else
                      :key="src"
                      :audio-src="src"
    />
  </span>
</template>

<script>
import WFPlayer from 'wfplayer'
import FancyAudioPlayer from './FancyAudioPlayer.vue'

export default {
  components: {
    FancyAudioPlayer,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
    alignment: {
      type: String,
      default: 'center',
    },
    reference: {
      type: String,
      default: 'audio',
    },
    fromChat: {
      type: Boolean,
      default: false,
    },
    audioClass: {
      type: String,
      default: 'w-100',
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
    includeGraph: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPlaying: false,
      playingWaveForm: false,
    }
  },
  computed: {
    isFancyPlayerOn() {
      return !!this.$store.state.session.settings?.fancyPlayer
    },
    isTTS() {
      const isUrl = this.src.includes('https://') || this.src.includes('http://') || this.src.startsWith('data:audio')
      return !isUrl && typeof this.src === 'string' && !this.arraySrc
    },
    arraySrc() {
      if (this.PARSE_ONLY_JSON(this.src)) {
        return this.JSON_PARSE(this.src)
      }
      return null
    },
  },
  mounted() {
    if (this.$refs[this.reference]) {
      this.$refs[this.reference].addEventListener('ended', () => { this.isPlaying = false })
      this.$refs[this.reference].addEventListener('play', () => { this.isPlaying = true })
      this.$refs[this.reference].addEventListener('pause', () => { this.isPlaying = false })
    }
  },
  methods: {
    playWaveFormAudio() {
      this.playingWaveForm = true
      try {
        const wf = new WFPlayer({
          container: this.$refs.waveform_div,
          mediaElement: this.$refs[this.reference],
          backgroundColor: '#222164',
          waveColor: 'white',
          cors: false,
        })
        wf.load(this.src)

        // click event
        wf.on('click', currentTime => {
          wf.seek(currentTime)
        })

        // grab event
        wf.on('grabbing', currentTime => {
          wf.seek(currentTime)
        })

        // scroll event
        wf.on('scroll', deltaY => {
          wf.seek(wf.currentTime + deltaY / 10)
        })
      } catch {
        // this.playingWaveForm = false
        console.error('audio already mounted')
      } finally {
        this.$refs[this.reference].play()
        // this.$root.$emit('forceStopRecording')
      }
    },
    playPause() {
      if (this.isTTS) {
        if ('speechSynthesis' in window) {
          // Create a new SpeechSynthesisUtterance instance
          const message = new SpeechSynthesisUtterance()
          const voice = this.getGoogleUSVoice()
          if (voice) {
            // eslint-disable-next-line prefer-destructuring
            message.voice = voice
          }
          message.lang = 'en-US'
          // Set the text that you want to convert to speech
          message.text = this.src
          if (this.isPlaying) {
            this.isPlaying = false
            window.speechSynthesis.cancel()
          } else {
            this.isPlaying = true
            // Use the speech synthesis API to speak the text
            window.speechSynthesis.speak(message)
            message.onend = () => {
              this.isPlaying = false
            }
          }
        }
        return
      }

      if (this.isPlaying) {
        this.$refs[this.reference].pause()
      } else if (this.includeGraph) {
        this.playWaveFormAudio()
        if (this.isPlaying) {
          this.$refs[this.reference].pause()
        }
      } else if (this.arraySrc) {
        const audio = this.$refs[this.reference]
        const sources = document.getElementsByClassName('audio-source')
        audio.src = sources[0].src
        let currentSourceIndex = 0

        audio.addEventListener('ended', () => {
          // eslint-disable-next-line no-plusplus
          if (currentSourceIndex === 0) {
            audio.src = sources[1].src
            audio.play()
            currentSourceIndex = 1
          }
        })

        audio.play()
      } else {
        this.$refs[this.reference].play()
      }
    },
  },
}
</script>
