<template>
  <div :class="'student-problem theme-' + getTheme">
    <div class="row report">
      <issue-report
        v-if="isASuperAdmin"
        class="issue-report"
        :lesson-id="lessonId"
        :problem-id="problem && problem.id"
        :user-id="AUTH_USER().id"
      />
    </div>
    <b-overlay :show="isProcessing"
               variant="transparent"
               style="min-height: calc(100vh - 100px);"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <ProblemOverlay />
        </div>
      </template>
      <b-card
        v-if="!gameCompletedStatus"
        class="mb-0 problem-card"
        no-body
      >
        <b-card-body class="p-0">
          <Header
            v-if="!result && !teachersStudentId"
            :problem-name="problem.name"
            :lesson-pdf="problem.lesson_pdf"
            :progress="progress"
            :show-progress="!result"
            :total-passed="totalPassed"
            :lesson-name="problem.lesson"
            :is-progress-exception="isProgressException"
            :engine="engine"
            :percentage="percentage"
            :round="round"
            :page-link="problem.page_link"
            :from-guest="true"
            :highest-score-data="highestScoreData"
          />
          <div
            v-if="!isProcessing && hasErrorInProblem && (!problem || Object.keys(problem).length === 0 )"
            style="
            background-image: url('/edu/images/no-problems.jpg');
            height: 100vh;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          "
            class="text-center row"
          >
            <div
              style="position: absolute; top: 45%;left: 10%;font-size: 1.5vw"
              class="text-center text-white"
            >
              Practice Materials are not published yet.<br>
              Please contact administrator to published materials
            </div>
          </div>
          <template v-if="!result && !teachersStudentId">
            <!-- carryforward engine -->
            <template v-if="engine === 'carryforwardengine'">
              <carry-forward-statement
                v-if="isStatement"
                :problem-statements="problemStatements"
                @nextProblem="getProblem({ continue: true })"
              />
              <problem
                v-else
                :key="gameKey"
                :problem="problem"
                :pid="pid"
                :has-statement="false"
                :engine="engine"
                :from-guest="true"
                @nextProblem="getProblem"
              />
            </template>
            <!-- carryforward engine -->

            <!-- adaptive engine / linear standalone engine -->
            <template v-else-if="engine === 'adaptivepathengine' || engine === 'linearstandaloneengine'">
              <problem
                :key="gameKey"
                :problem="problem"
                :has-statement="
                  $route.query.layout !== 'only_body' &&
                    (problem && !problem.rules?.hide_statement && !isSdpTest)"
                :engine="engine"
                :level="level"
                :pid="pid"
                :from-guest="true"
                :loader-id="loaderId"
                @onRefetch="level => getProblem({ lang_level: level })"
                @updateStar="updateStart"
                @nextProblem="getProblem"
                @playLastPart="playLastPart"
                @checkForGuestReport="$emit('getReport')"
              />
            </template>
            <!-- adaptive engine / linear standalone engine -->

          </template>
          <result
            v-else
            :teachers-student-id="teachersStudentId"
            :result-info="result || {}"
          />
          <audio
            ref="successAudio"
            src="https://assets.mixkit.co/sfx/preview/mixkit-casting-long-fairy-magic-spell-875.mp3"
          />
        </b-card-body>
      </b-card>
      <div v-else-if="gameCompletedStatus === 'passed'">
        <congralutation
          :result-summary="resultSummary"
          @playAgain="playAgain"
        />
      </div>
      <div v-else>
        <game-failed
          :result-summary="resultSummary"
          @playAgain="playAgain"
        />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { getIdFromURL } from 'vue-youtube-embed'
import { BCard, BCardBody, BOverlay } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import Header from '@/views/student/problem/compoments/Header.vue'
import CarryForwardStatement from '@/views/student/problem/CarryForwardStatement.vue'
import Problem from '@/views/student/problem/Problem.vue'
import Result from '@/views/student/problem/compoments/Result.vue'
import { LOADER_TYPE_SDP_TEST } from '@/const/loaderType'
import IssueReport from '@/views/student/problem/compoments/IssueReport.vue'
import ProblemOverlay from '@/views/student/problem/compoments/ProblemOverlay.vue'
import EventBus from '@/utils/eventBus'
import Congralutation from './Congralutation.vue'
import GameFailed from './GameFailed.vue'

export default {
  components: {
    BCard,
    Header,
    CarryForwardStatement,
    Problem,
    Result,
    BCardBody,
    Congralutation,
    GameFailed,
    IssueReport,
    BOverlay,
    ProblemOverlay,
  },
  props: {
    defaultClassId: {
      type: Number,
      required: true,
    },
    defaultLessonId: {
      type: Number,
      required: true,
    },
    resultSummary: {
      type: Object,
      default: () => {},
    },
    difficultyLevel: {
      type: String,
      default: 'easy',
    },
  },

  data() {
    return {
      progress: {
        current: 0,
        total: 0,
      },
      lessonId: this.defaultLessonId,
      classId: this.defaultClassId,
      pid: 0,
      isStatement: false,
      problem: {},
      problemStatements: [],
      result: null,
      isProgressException: false,
      engine: '',
      level: +(this.$route.query.lang_level || 1),
      totalPassed: 0,
      percentage: 0,
      round: 0,
      highestScoreData: null,
      teachersStudentId: null,
      gameCompletedStatus: null,
      gameKey: Math.random(),
      loaderId: null,
      isProcessing: false,
      hasErrorInProblem: false,
      isSdpTest: false,
    }
  },
  computed: {
    isASuperAdmin() {
      return this.AUTH_USER()?.usertype === 'super'
    },
    getTheme() {
      const animatedConfig = this.$store.state.problem.animatedLayoutConfig

      if (animatedConfig && animatedConfig.bodyTheme) {
        return animatedConfig.bodyTheme
      }
      return (this.problem && this.problem.theme) ? this.problem.theme : '0'
    },
  },
  watch: {
    // '$route.params.id': {
    //   handler(id) {
    //     this.lessonId = parseInt(id, 10)
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    // '$route.params.classId': {
    //   handler(id) {
    //     this.classId = parseInt(id, 10)
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  created() {
    this.getProblem()
  },
  mounted() {
    if (this.isASuperAdmin && this.$route.query.editMode) {
      this.$store.commit('studentLab/SET_IS_CB_EDIT_MODE', true)
      EventBus.$off('reAfterCBEditedMeta')
      EventBus.$on('reAfterCBEditedMeta', () => {
        this.getProblem()
      })
    }
  },
  methods: {
    updateStart() {
      this.totalPassed += 1
    },
    setLanguage(locale) {
      if (locale) {
        this.$i18n.locale = locale
      }
    },
    getProblem(param = {}) {
      // reset statement and problem object
      this.isProcessing = true
      this.problem = {}
      if (this.$route.query.lid) {
        // eslint-disable-next-line no-param-reassign
        param.lid = this.$route.query.lid
      }
      // eslint-disable-next-line no-param-reassign
      param.problem_id = this.$route.query.problem_id
      // eslint-disable-next-line no-param-reassign
      param.lang_level = this.$route.query.lang_level || param.lang_level
      if (this.$route.query.layout === 'only_feedback') {
        // eslint-disable-next-line no-param-reassign
        param.only_feedback = 1
      }
      this.problemStatements = []

      // eslint-disable-next-line no-param-reassign
      useJwt
        .getDemoProblemForLesson(this.difficultyLevel, {
          params: {
            ...param,
          },
        })
        .then(response => {
          // normalize response
          this.gameCompletedStatus = null
          const res = response?.data?.data
          this.setLanguage(res.locale)
          this.engine = res?.engine?.toLowerCase()
          this.pid = res?.pid
          this.level = res?.level
          this.totalPassed = res?.totalPassed
          this.percentage = res?.percentage
          this.round = res?.round
          this.loaderId = res?.loaderId
          this.isSdpTest = res?.loaderType === LOADER_TYPE_SDP_TEST
          if (res.result === 'failed' || res.result === 'passed') {
            this.gameCompletedStatus = res.result
          }
          // check engine type and handle response
          if (this.engine === 'adaptivepathengine' || this.engine === 'linearstandaloneengine') this.handleAdaptiveResponse(res)
          else if (this.engine === 'carryforwardengine') this.handleCarryForwardResponse(res)
          // handle result
          else if (res?.status === 'passed' || res?.status === 'failed' || res?.result) {
            this.result = this.processResult(res)
            this.$refs.successAudio.volume = 0.05
            this.$refs.successAudio.play()
          }
          this.progress = { ...res?.progress }
        }).finally(() => {
          this.isProcessing = false
          this.hasErrorInProblem = true
        })
    },
    handleAdaptiveResponse(res) {
      if (res?.status === 'in_progress' || res?.status === 'continue') {
        // eslint-disable-next-line prefer-destructuring
        this.problem = res.data[0]

        if (this.problem?.video_time_stamp) this.problem.video = this.processVideoData(this.problem.video_time_stamp)
        // else if (this.problem?.wrapper === 'wrapped_by_hotspots') {
        //   console.log()
        // }
      }
    },
    handleCarryForwardResponse(res) {
      // check problem status
      if (res?.status === 'in_progress' || res?.status === 'assessment' || res?.status === 'continue') {
        // assign response to problem object
        // eslint-disable-next-line prefer-destructuring
        this.problem = res.data[0]
        this.isStatement = false
      } else if (res?.status === 'intervention') {
        const problemKeys = Object.keys(res.data)

        // assign response to statement object
        problemKeys.forEach(k => {
          const statement = res.data[k]
          if (res.data[k].video_time_stamp) statement.video = this.processVideoData(res.data[k].video_time_stamp)
          this.problemStatements.push(statement)
        })
        this.isStatement = true
      }
    },
    processResult(data) {
      const result = data.result.reduce((r, a) => {
        const existingEntryIndex = r.findIndex(e => e.code === a.code)
        let existingEntry = {}

        if (existingEntryIndex < 0) {
          existingEntry = { ...a, ...{ total_problems: 1, passed_problems: a.status === 'pass' ? 1 : 0 } }
          r.push(existingEntry)
        } else {
          existingEntry = r[existingEntryIndex]
          existingEntry.total_problems += 1
          existingEntry.passed_problems += a.status === 'pass' ? 1 : 0
          // eslint-disable-next-line no-param-reassign
          r[existingEntryIndex] = existingEntry
        }
        return r
      }, [])
      // eslint-disable-next-line no-param-reassign
      data.result = result
      return data
    },
    processVideoData(videoTimestamp) {
      return {
        startTimeInMs: videoTimestamp?.start_time ? this.timeToMilliseconds(videoTimestamp?.start_time) : 0,
        endTimeInMs: videoTimestamp?.time ? this.timeToMilliseconds(videoTimestamp?.time) : null,
        youtubeId: getIdFromURL(videoTimestamp?.video?.url),
      }
    },
    timeToMilliseconds(duration) {
      const [minutes, seconds] = duration.split(':')
      const millisec = Number(minutes) * 60 + Number(seconds)
      return Number(millisec)
    },
    playLastPart() {
      this.problem.video_time_stamp = null
      if (this.problem.video) {
        const video = {
          startTimeInMs: this.problem?.video?.endTimeInMs,
          youtubeId: this.problem?.video?.youtubeId,
        }
        this.problem.video = video
      }
    },
    playAgain() {
      this.$emit('playAgain')
    },
  },
}
</script>

<style lang="scss">
@import "/src/assets/scss/custom/problem-themes/main.scss";
</style>
