<template>
  <div>
    <!-- Add Report Button -->
    <b-button variant="primary"
              @click="isModalVisible = true"
    >
      Add Report
    </b-button>

    <!-- Import and display ReportModal, passing the prop -->
    <ReportModal :is-visible="isModalVisible"
                 @close="isModalVisible = false"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { BButton } from 'bootstrap-vue'
import ReportModal from './components/ReportModal.vue'

const isModalVisible = ref(false)
</script>

<style scoped>
/* Add any custom styles here if needed */
</style>
