<template>
  <div class="w-100 h-100">
    <section
      id="chatbot-body"
      ref="refChatLogPS"
      class="ps-container position-relative scroll-area ps chatbot"
      style="background-repeat:no-repeat; background-size: 100%;"
      :style="{ 'background-image': `url(${require(`@/assets/images/chatbot/${activeImage}`)})`}"
    >
      <div class="position-absolute setting-icon"
           draggable="false"
           style="right:10px; top:10px; display:none;"
      >
        <feather-icon
          icon="SettingsIcon"
          size="21"
          class="text-primary cursor-pointer"
          @click.stop="openChatbotSetting"
        />
      </div>
      <div class="ps__rail-x"
           style="left: 0px; bottom: 0px"
      >
        <div class="ps__thumb-x"
             tabindex="0"
             style="left: 0px; width: 0px"
        />
      </div>
      <div class="ps__rail-y"
           style="top: 0px; right: 0px"
      >
        <div class="ps__thumb-y"
             tabindex="0"
             style="top: 0px; height: 0px"
        />
      </div>
    </section>
    <chat-footer
      :is-processing="isProcessing"
      :is-playing="isPlaying"
      @sendMessage="sendMessage"
    />
    <b-modal
      id="bot-setting-modal"
      title="Chatbot Settings"
      hide-footer
    >
      <bot-setting />
    </b-modal>
  </div>
</template>
<script>
import { BOT_OBJ } from '@/const/chatbot'
import { getDemoBotResponse } from '@/auth/jwt/useDatablock'
import ChatFooter from './ChatFooter.vue'
import BotSetting from './BotSetting.vue'
import chatbodyMixins from '../helpers/chatbody-mixins'

export default {
  components: {
    ChatFooter,
    BotSetting,
  },
  mixins: [chatbodyMixins],
  props: {
    userName: {
      type: String,
      required: true,
    },
    lessonInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataBlockSession: null,
    }
  },
  methods: {
    chatParameter(msg) {
      const chatHistory = this.messages.map(item => {
        if (item.from === 'bot') {
          return {
            chatbot: item.message,
          }
        }
        return {
          human: item.message,
        }
      })
      return {
        bot: 'Chatbot',
        chat_history: chatHistory,
        human_input: msg,
      }
    },
    sendMessage(msg) {
      this.messages.push({
        from: 'User',
        message: msg,
        avatar: null,
      })
      this.isProcessing = true

      const data = {
        ...this.chatParameter(msg),
        ...this.lessonInfo,
        userName: this.userName,
      }
      getDemoBotResponse(data, {
        headers: {
          ...(this.dataBlockSession ? {
            'x-session': this.dataBlockSession,
          } : {}),
        },

      }).then(response => {
        const responseMessage = response.data.message
        this.messages.push({
          from: 'bot',
          message: responseMessage,
          avatar: BOT_OBJ.avatar,
        })
        this.dataBlockSession = response.data['x-session']
        this.getBase64FromTextAndPlay(responseMessage)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
<style lang="scss">
  .chatbot {
    height: 150px;
    width: 150px;
    margin:auto;

    &:hover{
      .setting-icon {
        display: block !important;
      }
    }
    /* overflow: scroll !important; */
  }
</style>
