<template>
  <div>
    <validation-observer ref="statementForm">
      <!-- Statement -->
      <b-form-group label="Hide Statement">
        <b-form-checkbox v-model="$store.state.problem.hideStatement"
                         switch
        />
      </b-form-group>
      <div v-if="!videoType && !$store.state.problem.hideStatement">
        <label>{{ $t("labels.create-problem.problem-statement") }}</label>
        <b-tabs
          pills
          class="mx-0 px-0 mt-1"
        >
          <b-tab
            title="Image"
            :active="stType === 'image'"
            @click="stType = 'image'"
          >
            <validation-provider
              v-slot="{ errors }"
              name="name"
              :vid="`statement-image-${level}`"
            >
              <image-form
                :url="statementImage"
                :label="$t('labels.create-problem.statement-image')"
                @input="handleStatementImage($event, 'statementImage')"
                @fileTypeInvalid="
                  setError([`statement-image-${level}`], $event)
                "
                @fileSizeInvalid="
                  setError([`statement-image-${level}`], $event)
                "
              />
              <input
                v-model="statementImage"
                hidden
              >
              <small class="text-danger">{{ errors[0] }}</small><br>
            </validation-provider>
          </b-tab>
          <b-tab
            title="Audio"
            :active="stType === 'audio'"
            @click="stType = 'audio'"
          >
            <b-form-group
              :label="$t('labels.create-problem.statement-audio')"
              label-for="audio"
            >
              <validation-provider
                v-slot="{ errors }"
                name="name"
                :vid="`statement-audio-${level}`"
              >
                <Recorder
                  :url="statementAudio"
                  @input="setStatementAudio($event, 'statementAudio')"
                  @fileTypeInvalid="setError([`statement-audio-${level}`], $event)"
                  @fileSizeInvalid="setError([`statement-audio-${level}`], $event)"
                />
                <input
                  v-model="statementAudio"
                  hidden
                >
                <small class="text-danger">{{ errors[0] }}</small><br>
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
        <b-form-group
          :label="$t('labels.create-problem.statement')"
          label-for="statement"
        >
          <!-- <quill-editor-custom
            v-model="statement"
            :placeholder="$t('labels.create-problem.placeholder.statement')"
            @ready="$emit('ready')"
          /> -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            block
            @click="visibleTemplate = 'statement'"
          >
            {{ statementTemplate ? "Update Template" : "Create Template" }}
          </b-button>
          <validation-provider
            v-slot="{ errors }"
            name="statement template"
            rules="required"
            :vid="`statementTemplate-${level}`"
          >
            <input
              v-model="statementTemplate"
              hidden
            >
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <!-- /Statement -->

      <!-- Feedback -->

      <b-form-group label="Hide Feedback">
        <b-form-checkbox v-model="$store.state.problem.hideFeedBack"
                         switch
        />
      </b-form-group>
      <div v-if="!$store.state.problem.hideFeedBack">
        <label>{{ $t("labels.create-problem.problem-feedback") }}</label>
        <b-tabs
          v-if="!$store.state.problem.hideFeedBack"
          pills
          class="mx-0 px-0 mt-1"
        >
          <b-tab
            title="Image"
            :active="fbType === 'image'"
            @click="fbType = 'image'"
          >
            <validation-provider
              v-slot="{ errors }"
              name="name"
              :vid="`feedback-image-${level}`"
            >
              <image-form
                :url="feedbackImage"
                :label="$t('labels.create-problem.feedback-image')"
                @input="handleStatementImage($event, 'feedbackImage')"
                @fileTypeInvalid="
                  setError([`feedback-image-${level}`], $event)
                "
                @fileSizeInvalid="
                  setError([`feedback-image-${level}`], $event)
                "
              />
              <input
                v-model="feedbackImage"
                hidden
              >
              <small class="text-danger">{{ errors[0] }}</small><br>
            </validation-provider>
          </b-tab>
          <b-tab
            title="Audio"
            :active="fbType === 'audio'"
            @click="fbType = 'audio'"
          >
            <b-form-group
              :label="$t('labels.create-problem.feedback-audio')"
              label-for="audio"
            >
              <validation-provider
                v-slot="{ errors }"
                name="name"
                :vid="`feedback-audio-${level}`"
              >
                <Recorder
                  :url="feedbackAudio"
                  @input="setStatementAudio($event, 'feedbackAudio')"
                  @fileTypeInvalid="setError([`feedback-audio-${level}`], $event)"
                  @fileSizeInvalid="setError([`feedback-audio-${level}`], $event)"
                />
                <input
                  v-model="feedbackAudio"
                  hidden
                >
                <small class="text-danger">{{ errors[0] }}</small><br>
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
        <b-form-group
          :label="$t('labels.create-problem.feedback')"
          label-for="feedback"
        >
          <!-- <quill-editor-custom
          v-model="feedback"
          :placeholder="$t('labels.create-problem.placeholder.feedback')"
          @ready="$emit('ready')"
        /> -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            block
            @click="visibleTemplate = 'feedback'"
          >
            {{ feedbackTemplate ? "Update Template" : "Create Template" }}
          </b-button>
          <validation-provider
            v-slot="{ errors }"
            name="feedback template"
            rules="required"
            :vid="`feedbackTemplate-${level}`"
          >
            <input
              v-model="feedbackTemplate"
              hidden
            >
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <!-- /Feedback -->

      <!-- create edit template -->
      <create-template
        :show="visibleTemplate != null"
        :template="
          visibleTemplate === 'statement' ? statementTemplate : (feedbackTemplate || { ...statementTemplate, id: null })
        "
        :title="
          (
            visibleTemplate === 'statement'
              ? statementTemplate
              : feedbackTemplate
          )
            ? `Update ${
              visibleTemplate === 'statement' ? 'Statement' : 'Feedback'
            } Template`
            : `Create ${
              visibleTemplate === 'statement' ? 'Statement' : 'Feedback'
            } Template`
        "
        @templateStored="updateTemplate"
        @close="visibleTemplate = null"
      />
      <!-- create edit template -->
    </validation-observer>
  </div>
</template>

<script>
import {
  BFormGroup, BTabs, BTab, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import CreateTemplate from '@views/common/templates/CreateEdit.vue'
import Ripple from 'vue-ripple-directive'
import ImageForm from '../ImageForm.vue'
import Recorder from '../recorder.vue'

export default {
  components: {
    BFormGroup,
    BTabs,
    BTab,
    BButton,
    ImageForm,
    Recorder,
    BFormCheckbox,
    CreateTemplate,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    level: {
      type: Number,
      default: () => 0,
    },
    videoType: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    visibleTemplate: null,
  }),
  computed: {
    stType: {
      get() {
        return this.$store.state.problem.create.stType[this.level]
      },
      set(value) {
        this.$store.commit('problem/SET_STATEMENT_FEILD', {
          name: 'stType',
          value,
          index: this.level,
        })
      },
    },
    statement: {
      get() {
        return this.$store.state.problem.create.statement[this.level]
      },
      set(value) {
        this.$store.commit('problem/SET_STATEMENT_FEILD', {
          name: 'statement',
          value,
          index: this.level,
        })
      },
    },
    fbType: {
      get() {
        return this.$store.state.problem.create.fbType[this.level]
      },
      set(value) {
        this.$store.commit('problem/SET_STATEMENT_FEILD', {
          name: 'fbType',
          value,
          index: this.level,
        })
      },
    },
    feedback: {
      get() {
        return this.$store.state.problem.create.feedback[this.level]
      },
      set(value) {
        this.$store.commit('problem/SET_STATEMENT_FEILD', {
          name: 'feedback',
          value,
          index: this.level,
        })
      },
    },
    statementTemplate: {
      get() {
        return this.$store.state.problem.create.statementTemplate[this.level]
      },
      set(value) {
        this.$store.commit('problem/SET_STATEMENT_TEMPLATE', {
          value,
          index: this.level,
        })
      },
    },
    feedbackTemplate: {
      get() {
        return this.$store.state.problem.create.feedbackTemplate[this.level]
      },
      set(value) {
        this.$store.commit('problem/SET_FEEDBACK_TEMPLATE', {
          value,
          index: this.level,
        })
      },
    },
    statementImage() {
      return this.$store.state.problem.create.statementImage.url[this.level]
    },
    statementAudio() {
      return this.$store.state.problem.create.statementAudio.url[this.level]
    },
    feedbackImage() {
      return this.$store.state.problem.create.feedbackImage.url[this.level]
    },
    feedbackAudio() {
      return this.$store.state.problem.create.feedbackAudio.url[this.level]
    },
    showError: {
      get() {
        return this.$store.state.problem.showError
      },
      set() {},
    },
  },
  watch: {
    showError(value) {
      if (value) {
        this.$refs.statementForm.validate()
      }
    },
  },
  methods: {
    handleStatementImage({ image, url }, type) {
      this.$store.commit('problem/SET_STATEMENT_FEILD', {
        name: type,
        value: url,
        index: this.level,
        type: 'url',
      })
      this.$store.commit('problem/SET_STATEMENT_FEILD', {
        name: type,
        value: image,
        index: this.level,
        type: 'file',
      })
    },
    setStatementAudio({ audio, url }, type) {
      this.$store.commit('problem/SET_STATEMENT_FEILD', {
        name: type,
        value: url,
        index: this.level,
        type: 'url',
      })
      this.$store.commit('problem/SET_STATEMENT_FEILD', {
        name: type,
        value: audio,
        index: this.level,
        type: 'file',
      })
    },
    updateTemplate(data) {
      if (this.visibleTemplate === 'statement') this.statementTemplate = data
      else this.feedbackTemplate = data
      this.visibleTemplate = null
    },
    setError(vid, msg) {
      this.$refs.statementForm.setErrors({ [vid]: [msg] })
    },
  },
}
</script>
