<template>
  <div>
    <table class="table table-sm">
      <thead>
        <tr>
          <th>
            {{ $t('library-moduel.owners') }}
          </th>
          <th>
            {{ $t('library-moduel.role') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item,index of ownersWithRoles"
            :key="index"
        >
          <td :set="user = users.find(i => i.value === item.user_id )">
            {{ user?.text }}
          </td>
          <td>
            <v-select v-model="item.role_id"
                      :options="libraryRoles"
                      label="name"
                      :reduce="option => option.id"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    ownersWithRoles: {
      type: Array,
      default: () => [],
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      libraryRoles: [],
    }
  },
  mounted() {
    this.getLibraryRoles()
  },
  methods: {
    getLibraryRoles() {
      useJwt.getModuleRoles({
        params: {
          module: 'library',
        },
      }).then(response => {
        this.libraryRoles = response.data.data
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
  },
}
</script>
