<template>
  <b-col
    v-if="myNetworkMembership"
    md="12"
    class="mb-1"
  >
    <b-card
      no-body
      class="mb-0"
    >
      <h5 class="p-1 pb-0">
        {{ $t('network-module.members') }}
      </h5>

      <b-table
        v-if="members.length>0"
        small
        responsive
        :fields="columns"
        :items="getMembersForTable"
      >
        <template #cell(image)="row">

          <avatar
            :image="row.item.image"
            size="28px"
            rounded="circle"
          />
        </template>
        <template
          v-if="isALeader || isACoLeader"
          #cell(action)="member"
        >
          <span>
            <b-dropdown
              v-if="member.item.member_id !== network.created_by && member.item.member_id !== self.id"
              variant="link"
              no-caret
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="member.item.member_type === MEMBER_TYPE_MEMBER"
                @click="promoteUserToCoLeader(member.item.id)"
              >
                <feather-icon icon="ChevronUpIcon" />
                <span class="align-middle ml-50">{{ $t("labels.make-co-leader") }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="member.item.member_type != MEMBER_TYPE_LEADER && isALeader"
                @click="promoteUserToLeader(member.item.id)"
              >
                <feather-icon icon="ChevronsUpIcon" />
                <span class="align-middle ml-50">{{ $t("labels.make-leader") }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canDeleteMember(member.item)"
                @click="deleteMember(member.item.id)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t("delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>

        </template>
      </b-table>

      <p
        v-else
        class="px-1"
      >
        {{ $t('no-members-yet') }}
      </p>

    </b-card>
  </b-col>
</template>
<script>
import {
  BCol, BCard, BTable, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { MEMBER_TYPE_MEMBER, MEMBER_TYPE_LEADER, MEMBER_TYPE_COLEADER } from '@/const/networkMemberType'
import useJwt from '@/auth/jwt/useJwt'
import { mapGetters } from 'vuex'
import Avatar from '@/views/common/components/Avatar.vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    BCol,
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    Avatar,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      isProcessing: false,
      columns: [],
    }
  },
  computed: {
    ...mapGetters({
      members: 'network/members',
      self: 'network/self',
      isALeader: 'network/isALeader',
      isACoLeader: 'network/isACoLeader',
      hasJoinedNetwork: 'network/hasJoinedNetwork',
      isAMember: 'network/isAMember',
      hasRequested: 'network/hasRequested',
      hasPendingRequest: 'network/hasPendingRequest',
      network: 'network/network',
      myNetworkMembership: 'network/myNetworkMembership',
    }),

    getMembersForTable() {
      return this.members.map(e => ({
        image: e.avatar,
        name: `${e.firstname} ${e.lastname}`,
        rating: 0,
        action: '',
        member_type: e.member_type,
        id: e.id,
        member_id: e.pivot.member_id,
      }))
    },
    MEMBER_TYPE_MEMBER() {
      return MEMBER_TYPE_MEMBER
    },
    MEMBER_TYPE_LEADER() {
      return MEMBER_TYPE_LEADER
    },
    MEMBER_TYPE_COLEADER() {
      return MEMBER_TYPE_COLEADER
    },
  },
  mounted() {
    this.columns = [
      {
        key: 'image',
        label: i18n.tc('network-module.member-table.image'),
      },
      {
        key: 'name',
        label: i18n.tc('network-module.member-table.name'),
      },
      {
        key: 'rating',
        label: i18n.tc('network-module.member-table.rating'),
      },
    ]
    if (this.isALeader || this.isACoLeader) {
      this.columns = [
        {
          key: 'image',
          label: i18n.tc('network-module.member-table.image'),
        },
        {
          key: 'name',
          label: i18n.tc('network-module.member-table.name'),
        },
        {
          key: 'rating',
          label: i18n.tc('network-module.member-table.rating'),
        },
        {
          key: 'actions',
          label: i18n.tc('network-module.member-table.actions'),
        },
      ]
    }
  },

  methods: {
    canDeleteMember(member) {
      if (!this.myNetworkMembership) return false
      if (this.myNetworkMembership.member_id === this.self.id) return false
      if (this.myNetworkMembership.member_type === MEMBER_TYPE_LEADER && this.network.created_by === member.id) return false
      if (this.myNetworkMembership.member_type === MEMBER_TYPE_COLEADER && member.member_type === MEMBER_TYPE_LEADER) return false
      return true
    },
    promoteUserToLeader(id) {
      useJwt.promoteUser({
        requested_to: id,
        message: 'Invitation for Leader',
        requested_for: MEMBER_TYPE_LEADER,
        requested_from: this.network.id,
      }).then(response => {
        this.$store.dispatch('network/getMembers')
        this.showSuccessMessage(response)
      })
    },
    promoteUserToCoLeader(id) {
      useJwt.promoteUser({
        requested_to: id,
        message: 'Invitation for Co-Leader',
        requested_for: MEMBER_TYPE_COLEADER,
        requested_from: this.network.id,
      }).then(response => {
        this.$store.dispatch('network/getMembers')
        this.showSuccessMessage(response)
      })
    },
    deleteMember(id) {
      useJwt.removeUserFromNetwork(this.network.id, id).then(response => {
        this.$store.dispatch('network/getNetworkMembers')
        this.$store.dispatch('network/getCoLeaders')
        this.showSuccessMessage(response)
      })
    },
  },
}
</script>
