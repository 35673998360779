<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <h3 class="card-title">
          {{ $t('labels.student-need-help') }}
        </h3>
      </b-card-header>
      <b-table
        :items="getTableData"
        :fields="fields"
        :sticky-header="true"
        class="mb-0"
        tbody-tr-class="rowClass"
        @row-clicked="openLesson"
      >
        <template #cell(lesson)="data">
          {{ data.item.lesson }}
          <b-badge
            variant="primary"
          >
            {{data.item.type.toUpperCase()}}
          </b-badge>
        </template>
        <template #cell(completed)="data">
          <b-badge
            variant="success"
            class="rounded-circle"
          >
            {{ data.item.completed }}
          </b-badge>
        </template>
        <template #cell(in_progress)="data">
          <b-badge
            variant="warning"
            class="rounded-circle"
          >
            {{ data.item.in_progress }}
          </b-badge>
        </template>
        <template #cell(failed)="data">
          <b-badge
            variant="danger"
            class="rounded-circle"
          >
            {{ data.item.failed }}
          </b-badge>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCardHeader, BTable, BBadge,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    BCard,
    BCardHeader,
    BTable,
    BBadge,
  },
  props: {
    realTimeData: {
      type: Array,
      default: () => [],
    },
    lessonList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'lesson',
          label: i18n.tc('labels.lesson'),
        },
        {
          key: 'in_progress',
          label: i18n.tc('issue-report-module.in_progress'),
        },
        {
          key: 'failed',
          label: i18n.tc('labels.failed'),
        },
      ],
    }
  },
  computed: {
    getTableData() {
      const tableSummaryData = []
      this.lessonList.forEach(lesson => {
        const currentLesson = this.realTimeData.filter(real => real.lesson_id === lesson.id)
        const passed = currentLesson.filter(real => real.status === 'passed')
        const failed = currentLesson.filter(real => real.status === 'failed')
        const inProgress = currentLesson.filter(real => real.status === 'in_progress')
        const { type } = currentLesson[0]
        tableSummaryData.push({
          lesson: lesson.name,
          completed: passed.length,
          failed: failed.length,
          id: lesson.id,
          in_progress: inProgress.length,
          type,
        })
      })
      return tableSummaryData
    },
  },
  methods: {
    openLesson(value) {
      this.$emit('selectLesson', value.id)
    },
  },
}
</script>
<style>
  .rowClass {
    cursor: pointer;
  }
</style>
