<template>

  <div class="floating-bot ">
    <gec-dragger
      v-show="chatOpened"
      id="draggable-chat-bot"
      height="224"
      width="300"
      left="70%"
      draggable-section="chatbot-body"
      bottom="10%"
    >
      <section v-show="chatOpened"
               data-v-5e8ea5c2=""
               class="chat-app-window w-100 h-100  mb-2"
               style="background: transparent;"
      >
        <div data-v-5e8ea5c2=""
             class="active-chat w-100 h-100"
        >
          <chat-body :topics="lessonInfo?.name"
                     :page-meta-info="problem?.page_meta_content || []"
                     :problem-id="Number(problem?.id)"
                     :lesson-id="Number(problem?.lesson_info?.id)"
          />
        </div>
      </section>
    </gec-dragger>
    <div class="floating-bot-icon"
         draggable="false"
         @click.stop="setChatOpened"
    >
      <img v-if="!chatOpened"
           src="@/assets/images/icons/bot.png"
           class="img-fluid"
           style="width:34px; height:34px; margin-top:-2px"
           draggable="false"
      >
      <feather-icon
        v-if="chatOpened"
        size="32"
        class="text-white"
        style="margin-left:-2px; margin-top:-2px"
        icon="XIcon"
      />
    </div>
  </div>
</template>
<script>
import GecDragger from '@/layouts/components/GECDragger.vue'
import ChatBody from './components/ChatBody.vue'

export default {
  components: {
    ChatBody,
    GecDragger,
  },
  props: {
    lessonInfo: {
      type: Object,
      default: () => null,
    },
    problem: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      chatOpened: true,
    }
  },
  methods: {
    setChatOpened() {
      this.chatOpened = !this.chatOpened
    },
  },
}
</script>

<style lang="css">
.floating-bot-icon{
    z-index: 1031;
    background-color: rgb(241, 179, 63);
    padding:10px;
    border-radius:50%;
    cursor:pointer;
    transition: 0.3s;
    position: fixed;
    bottom: 26%;
    right: 2%;
    width: 50px;
    height: 50px;
    border: 2px solid white !important;
}

</style>
