<template>
  <div>
    <b-row>
      <!-- Available Coins -->
      <b-col
        v-if="coins.coins"
        lg="6"
      >
        <b-card no-body>
          <b-card-header class="reward-card">
            <div class="d-flex justify-content-start align-items-center">
              <!-- Coins Icon -->
              <b-avatar
                variant="light-success"
                size="70px"
                class="p-1"
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="70"
                  color="#39ac39"
                />
              </b-avatar>

              <!-- Coins Details -->
              <div class="coin-details ml-1">
                <b-card-text>
                  <p class="mb-0">
                    <span class="font-weight-bold">{{ $tc('labels.used_points') }}:</span>
                    {{ coins.used_points }}
                  </p>
                </b-card-text>

                <b-card-title class="reward-title">
                  <span class="font-weight-bold">{{ $tc('labels.available-coins') }}:</span>
                  {{ coins.coins }}
                </b-card-title>
              </div>
            </div>
          </b-card-header>
        </b-card>
      </b-col>

      <!-- Pending Coins -->
      <b-col
        v-if="pendingCoins && pendingCoins.coins"
        lg="6"
      >
        <b-card no-body>
          <b-card-header class="reward-card">
            <div class="d-flex justify-content-start align-items-center">
              <!-- Coins Icon -->
              <b-avatar
                variant="light-secondary"
                size="70px"
                class="p-1"
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="70"
                  color="#999"
                />
              </b-avatar>

              <!-- Coins Details -->
              <div class="coin-details ml-1">
                <b-card-text>
                  <p class="mb-0">
                    <span class="font-weight-bold">{{ $tc('labels.pending-coins') }}:</span> {{ pendingCoins.coins }}
                  </p>
                  <p class="mb-0">
                    <span class="font-weight-bold">{{ $tc('labels.threshold') }}:</span> {{ pendingCoins.threshold }} {{ $tc('labels.points') }}
                  </p>
                  <p class="small w-100 mb-0">
                    <!-- You need {{ pendingCoins.requiredPoints }} points to meet the threshold. -->
                    {{ $t('badge-reward.you-need-point', { points: pendingCoins.requiredPoints}) }}
                  </p>
                </b-card-text>
              </div>
            </div>
          </b-card-header>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-card no-body
                class="mb-1"
        >
          <b-card-header class="reward-card">
            <div class="coin-details ml-1">
              <b-card-text>
                <p>{{ $t('badge-reward.coin-message.hey-there') }} 😃</p>
                <p>{{ $t('badge-reward.coin-message.did-you-know') }} ✨🪙 </p>
                <p>{{ $t('badge-reward.coin-message.these-special-coins') }} 🎮🚀</p>
                <p>{{ $t('badge-reward.coin-message.keep-earning') }} 🌈💰</p>
                <p>{{ $t('badge-reward.coin-message.enjoy') }} 🎉😁</p>
              </b-card-text>
            </div>
          </b-card-header>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BRow, BCol, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BCardHeader, BCardTitle, BCardText, BRow, BCol, BAvatar,
  },
  props: {
    coins: {
      type: [Object, Array],
    },
    pendingCoins: {
      type: [Object, Array],
    },
  },
}
</script>

<style scoped>
.coin-details .reward-title {
  margin-top: -8px;
  font-size: 16px;
}
.reward-card {
  min-height: 125px;
}
</style>
