<template>
  <b-modal
    ok-only
    no-close-on-backdrop
    :visible="true"
    ok-title="Accept"
    size="lg"
    hide-footer="true"
    @hide="stopPendingGame"
    @close="stopPendingGame"
  >
    <template #modal-title>
      Tower Game <feather-icon
        :icon="isMuted ? 'VolumeXIcon' : 'Volume2Icon' "
        size="20"
        role="button"
        @click="toggleSound"
      />
    </template>
    <div
      class="tower-game"
      style="overflow:hidden"
    >
      <canvas
        v-show="showCanvas"
        id="canvas"
        class="m-auto"
        :style="`height:${gameHeight}px;width:${gameWidth}px`"
      />
      <div
        class="game-content"
        :style="`height:${gameHeight}px;width:${gameWidth}px`"
      >
        <div
          v-show="showLoading"
          class="game-loading"
        >
          <div class="main"><img
                              src="/tower-game/main-loading.gif"
                            >
            <div class="game-progress">
              <div class="title font-wenxue">
                {{ percent }}
              </div>
              <div class="bar">
                <div class="sub">
                  <div
                    class="percent"
                    :style="`width:${percent}`"
                  />
                </div>
              </div>
              <div class="text">
                Loading
              </div>
            </div>
          </div>

        </div>
        <div
          v-show="showLanding"
          class="landing"
        >
          <div class="action-1">
            <img
              src="/tower-game/main-index-title.png"
              class="title swing"
            >
          </div>
          <div class="action-2">
            <img
              id="start"
              src="/tower-game/main-index-start.png"
              class="start"
              @click="onStartClick"
            ></div>
        </div>
        <div
          v-show="showModal"
          id="modal"
          class="game-modal"
        >
          <div class="mask" />
          <div
            class="js-modal-content game-modal-content"
            :style="`width:${gameWidth}px`"
          >
            <div class="main">
              <div class="container">
                <img
                  src="/tower-game/main-modal-bg.png"
                  class="bg"
                >
                <div class="modal-main p-2">
                  <div
                    v-show="overModal"
                    id="over-modal"
                    class="js-modal-card"
                  ><img
                     src="/tower-game/main-modal-over.png"
                     class="over-img"
                   >
                    <div
                      id="score"
                      class="over-score font-wenxue"
                      v-text="score"
                    />
                    <div
                      v-show="overZero"
                      id="over-zero"
                    >
                      <div class="tip">

                        <img
                          src="/tower-game/main-modal-again-b.png"
                          class="over-button-b js-reload"
                          @click="restartGame"
                        >
                      <!-- <img
                        src="/tower-game/main-modal-invite-b.png"
                        class="over-button-b js-invite"
                      > -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'

require('./src/index')

export default {
  components: {
    BModal,
  },
  data() {
    return {
      domReady: null,
      loadFinish: null,
      canvasReady: null,
      loadError: null,
      gameStart: null,
      game: null,
      score: null,
      successCount: null,
      // init window height and width
      gameWidth: 400,
      gameHeight: 540,
      ratio: 1.5,
      showCanvas: false,
      showLoading: true,
      showLanding: false,
      showModal: false,
      overZero: false,
      overModal: false,
      percent: '',
      isMuted: false,
    }
  },
  computed: {
    option() {
      return {
        width: this.gameWidth,
        height: this.gameHeight,
        canvasId: 'canvas',
        soundOn: !this.isMuted,
        setGameScore: s => {
          this.score = s
        },
        setGameSuccess: s => {
          this.successCount = s
        },
        setGameFailed: f => {
          // document.querySelector('#score').innerText(this.score)
          if (f >= 3) {
            // this.overShowOver()
            this.$emit('gameOver')
          }
        },
      }
    },
  },
  mounted() {
    const box = document.querySelector('.problem-overview-content')
    const width = box.offsetWidth
    this.gameWidth = width - 40
    if (this.gameHeight / this.gameWidth < this.ratio) {
      this.gameWidth = Math.ceil(this.gameHeight / this.ratio)
    }
    window.addEventListener('load', () => {
      this.domReady = true
      this.hideLoading()
    }, false)
    setTimeout(() => {
      this.gameReady()
    }, 500)
  },
  methods: {
    restartGame() {
      this.gameReady()
      this.showModal = false
      this.gameStart = true
      this.indexHide()
      setTimeout(this.game.start, 400)
    },
    hideLoading() {
      if (this.canvasReady) {
        this.showCanvas = true
        this.loadFinish = true
        setTimeout(() => {
          this.showLoading = false
          this.showLanding = true
        }, 1000)
      }
    },
    updateLoading(status) {
      const { success } = status
      const { total } = status
      const { failed } = status
      if (failed > 0 && !this.loadError) {
        this.loadError = true
        return
      }
      this.percent = parseInt((success / total) * 100, 10)
      if (this.percent === 100 && !this.canvasReady) {
        this.canvasReady = true
        this.hideLoading()
      }
      this.percent = this.percent > 98 ? 98 : this.percent
      this.percent += '%'
    },

    overShowOver() {
      this.showModal = true
      this.overZero = true
      this.overModal = true
    },

    gameReady() {
      // eslint-disable-next-line no-undef
      this.game = TowerGame(this.option)

      this.game.load(() => {
        // this.game.playBgm()
        this.game.init()
      }, this.updateLoading)
    },

    indexHide() {
      document.querySelector('.landing .action-1').classList.add('slideTop')
      document.querySelector('.landing .action-2').classList.add('slideBottom')
      setTimeout(() => {
        this.showLanding = false
      }, 950)
    },

    onStartClick() {
      if (this.gameStart) return
      this.gameStart = true
      this.indexHide()
      setTimeout(this.game.start, 400)
    },
    toggleSound() {
      this.isMuted = !this.isMuted
      this.game.soundOn = !this.game.soundOn
    },
    stopPendingGame() {
      document.dispatchEvent(new Event('stopGameFromGec'))
      this.$emit('gameOver')
    },
  },
}
</script>

<style scoped lang="scss">
   .tower-game{
    img {
        width: 100%
    }

    html {
        background: #FFF;
        height: 100%
    }

    body {
        font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
        margin: 0 auto;
        text-align: center;
        width: 100%;
        height: 100%;
        background: #F95240 url(/tower-game/main-bg.png)
    }

    @media screen and (min-height: 560px) {
        html {
            font-size: 100px
        }
    }

    @media screen and (min-height: 640px) {
        html {
            font-size: 112.5px
        }
    }

    @media screen and (min-height: 720px) {
        html {
            font-size: 125px
        }
    }

    @media screen and (min-height: 800px) {
        html {
            font-size: 137.5px
        }
    }

    @media screen and (min-height: 880px) {
        html {
            font-size: 150px
        }
    }

    @media screen and (min-height: 960px) {
        html {
            font-size: 162.5px
        }
    }

    @media screen and (min-height: 1040px) {
        html {
            font-size: 180px
        }
    }

    @media screen and (min-height: 1200px) {
        html {
            font-size: 200px
        }
    }

    html {
        font-size: 17.6vh
    }

    #canvas {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }

    a {
        text-decoration: none
    }

    li, ul, ol {
        list-style-type: none;
        padding: 0;
        margin: 0
    }

    .hide {
        display: none
    }

    .clear {
        clear: both
    }

    .game-loading {
        background-color: #F05A50;
        height: 100%;
        width: 100%;
    }

    .game-loading .main {
        width: 60%;
        margin: 0 auto;
        color: #FFF;
        display: flex;
        flex-direction: column;
    }

    .game-loading .main img {
        width: 60%;
    }

    .game-loading .main .title {
        font-size:12px;
        display:block;
        text-align: center;
    }

    .game-loading .main .text {
      text-align: center;
        font-size: 18px;
    }
    .game-progress{
      margin: 80px 0;
    }

    .game-loading .main .bar {
        height: 14px;
        width: 100%;
        border: 3px solid #FFF;
        border-radius: .6rem;
        margin: .1rem 0;
    }

    .game-loading .main .bar .sub {
        height: 14px;
        margin-top: -3px;
        margin-right: -3px;
        width: 98%;
    }

    .game-loading .main .bar .percent {
        height: 100%;
        width: 0;
        background-color: #FFF;
        border-radius: .6rem;
    }

    .game-loading .logo {
        position: absolute;
        bottom: .3rem;
        left: 0;
        right: 0
    }

    .game-loading .logo img {
        width: 1rem
    }

    .game-content {
        height: 100vh;
        position: relative;
        width: 100%;
    }

    .landing .title {
      display: block;
      margin: 0 auto;
        width: 60%;
    }

    .landing .logo {
        width: 30%;
        position: absolute;
        right: .2rem;
        top: .2rem;
    }

    .landing .action-2 {
        position: absolute;
        bottom: .2rem;
        width: 100%;
    }

    .landing .start {
      display: block;
      margin: 0 auto;
        width: 65%;
    }

    .slideTop {
        -webkit-animation: st 1s ease-in-out;
        animation: st 1s ease-in-out;
    }

    @-webkit-keyframes st {
        0% {
            transform: translateZ(0)
        }
        100% {
            transform: translate3d(0, -100%, 0)
        }
    }

    @keyframes st {
        0% {
            transform: translateZ(0)
        }
        100% {
            transform: translate3d(0, -100%, 0)
        }
    }

    .slideBottom {
        -webkit-animation: sb 1s ease-in-out;
        animation: sb 1s ease-in-out;
    }

    @-webkit-keyframes sb {
        0% {
            transform: translateZ(0)
        }
        100% {
            transform: translate3d(0, 200%, 0)
        }
    }

    @keyframes sb {
        0% {
            transform: translateZ(0)
        }
        100% {
            transform: translate3d(0, 200%, 0)
        }
    }

    .swing {
        -webkit-animation: sw 2s ease-in-out alternate infinite;
        animation: sw 2s ease-in-out alternate infinite;
    }

    @-webkit-keyframes sw {
        0% {
            transform: rotate(5deg);
            transform-origin: top center;
        }
        100% {
            transform: rotate(-5deg);
            transform-origin: top center;
        }
    }

    @keyframes sw {
        0% {
            transform: rotate(5deg);
            transform-origin: top center;
        }
        100% {
            transform: rotate(-5deg);
            transform-origin: top center;
        }
    }

    .game-modal .mask {
        background-color: #000;
        opacity: .6;
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }

    .game-modal .game-modal-content {
        position: absolute;
        height: 100%;
        width: 90%;
        margin-top: .3rem;
        top: 0;
        display: flex;
        justify-content: center;
    }

    .game-modal .main {
        width: 85%;
        margin: 0 auto;
    }

    .game-modal .container {
        position: relative
    }

    .game-modal .bg {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0
    }

    .game-modal .modal-main {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: -0.4rem;

    }
    .game-modal .modal-main #over-modal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #score{
      padding: 16px 0;
    }

    .game-modal .over-img {
      width: 60%;
      padding-top: 100px;
    }

    .game-modal .over-score {
        margin: 12px auto;
        font-size: 40px;
        color: #FF735C;
        display: block;
        text-shadow: -2px -2px 0 #FFF, 2px -2px 0 #FFF, -2px 2px 0 #FFF, 2px 2px 0 #FFF;
    }

    .game-modal .tip {
        font-size: .16rem;
        color: #9B724E;
        display:flex;
    }

    .game-modal .over-button-b {
        width: 70%;
        margin: 0.1rem auto 0
    }

    @font-face {
        font-family: 'wenxue';
        src: url('/tower-game/wenxue.eot');
        src: url('/tower-game/wenxue.eot'),
        url('/tower-game/wenxue.woff'),
        url('/tower-game/wenxue.ttf'),
        url('/tower-game/wenxue.svg');
    }

    .font-wenxue {
        font-family: 'wenxue';
    }
   }
</style>
