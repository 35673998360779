<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.senderId + String(index)"
      class="chat"
      :class="{ 'chat-left': msgGrp.senderId !== profileUserId }"
    >
      <div class="chat-avatar">
        <b-avatar
          v-if="msgGrp.sender"
          v-show="msgGrp.senderId"
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="
            msgGrp.senderId !== profileUserId
              ? msgGrp.sender.avatar
              : profileUserAvatar
          "
        />
        <b-avatar
          v-else
          v-show="msgGrp.senderId"
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="
            msgGrp.senderId !== profileUserId
              ? formattedChatData.contact.avatar
              : profileUserAvatar
          "
        />
      </div>
      <div class="chat-body position-relative">
        <p
          v-if="chatData.type!=='group' && msgGrp.senderId && msgGrp.senderId !== profileUserId"
          class="msg-sender"
        >
          {{ formattedChatData.contact.fullName }}
        </p>
        <p
          v-else-if="chatData.type==='group' && msgGrp.sender"
          class="msg-sender"
        >
          {{ `${msgGrp.sender.firstname} ${msgGrp.sender.lastname}` }}
        </p>
        <p
          v-else
          v-show="msgGrp.senderId"
          class="msg-sender"
        >
          {{ `${self.firstname}  ${self.lastname}` }}
        </p>
        <div
          v-for="(msgData, i) in msgGrp.messages"
          :key="msgData + i"
          v-longpress="() => {selectedMessageToDelete = msgGrp.senderId === (profileUserId ? msgData.id : null)}"
          :class="{
            'd-flex justify-content-end sender-message': msgGrp.senderId === profileUserId
          }"
        >
          <div
            v-if="msgGrp.senderId === profileUserId"
            class="mr-1 delete-chat-icon"
            role="button"
          >
            <feather-icon
              icon="TrashIcon"
              size="20"
              @click="selectedMessageToDelete = msgData.id"
            />
          </div>
          <div
            :class="{'chat-content': msgData.type == 'text'}"
            style="min-width: 120px"
          >
            <p v-if="msgData.type == 'text'"
               class="message-para"
            >
              {{ msgData.msg }}
            </p>
            <div v-if="msgData.audio != '' && msgData.type == 'audio'">
              <audio-player
                :src="msgData.audioUrl"
                class="col-10 pl-0 pr-1"
              />
            </div>

            <div
              v-if="msgData.type == 'sticker'"
              :class="msgGrp.senderId === profileUserId ? 'text-white' :'text-dark'"
            >
              <img
                :src="msgData.msg"
                width="120px"
                alt=""
              >
            </div>

            <div
              v-if="msgData.audio != '' && msgData.type == 'file'"
              class="d-file"
            >
              <span
                v-if="isImage(msgData.audioUrl)"
                v-b-modal.img-preview
                :class="msgGrp.senderId === profileUserId ? 'text-white' :'text-dark'"
                @click="setPreviewData(msgGrp.messages[i].msg)"
              >
                <img
                  :src="msgData.audioUrl"
                  width="450px"
                  alt=""
                >
              </span>

              <a
                v-else
                :href="msgData.audioUrl"
                :class="msgGrp.senderId === profileUserId ? '' :'text-dark'"
                target="_blank"
              >
                <feather-icon
                  size="16"
                  :class="[msgGrp.senderId === profileUserId ? '' :'text-dark ml-2']"
                  icon="FileIcon"
                />
                {{ msgData.audioUrl.split("/").pop() }}
              </a>
            </div>
            <div
              class="text-right"
            >
              <span style="min-width: 32px; min-height: 16px">
                <feather-icon
                  v-if="msgData.msg_sent && msgGrp.senderId === profileUserId"
                  size="16"
                  class="text-white ml-2"
                  icon="CheckIcon"
                />
                <feather-icon
                  v-if="msgData.msg_recieved && msgGrp.senderId === profileUserId"
                  size="16"
                  class="text-white double-icon"
                  icon="CheckIcon"
                />
              </span>
              <small>{{
                formatDateToMonthShort(msgData.time, {
                  hour: "numeric",
                  minute: "numeric",
                })
              }}</small>
            </div>
          </div>
          <!-- context -->
        </div>
      </div>
    </div>
    <!-- We need to implement this after backend ready for event -->
    <div
      v-show="messageTyping"
      class="chat-left"
    >
      <b-avatar
        size="36"
        class="avatar-border-2 box-shadow-1"
        variant="transparent"
        :src="formattedChatData.contact.avatar"
      />
      <Preloader class="pl-2" />
    </div>

    <!-- image preview modal  -->
    <b-modal
      id="img-preview"
      size="lg"
      centered
      hide-footer
    >
      <div class="modal-body p-0">
        <img
          :src="previewData.image"
          width="100%"
          alt=""
          class="mb-2"
        >
      </div>
    </b-modal>
    <delete-modal
      :show="!!selectedMessageToDelete"
      :on-delete="messageToDelete"
      :is-processing="deletingMessage"
      :sub-title="$t('chat.delete-message')"
      @close="selectedMessageToDelete = null"
    />
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { getUserData } from '@/auth/utils'
import store from '@/store'
import { formatDateToMonthShort } from '@core/utils/filter'
import { VaniEvent } from 'vani-meeting-client'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import useJwt from '@/auth/jwt/useJwt'
import AudioPlayer from '../components/AudioPlayer.vue'
import Preloader from '../components/Preloader.vue'
import ChatHandler from './ChatHandler'

export default {
  components: {
    BAvatar,
    AudioPlayer,
    Preloader,
    DeleteModal,
  },
  props: {
    chats: {
      type: Array,
      required: true,
    },
    chatData: {
      type: [Array, Object],
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: false,
      default: () => '',
    },
    // eslint-disable-next-line vue/require-default-prop
    profileUserId: {
      type: [Number, String],
      required: false,
    },
  },
  data() {
    return {
      formattedChatData: { formattedChatLog: [], contact: {} },
      self: getUserData(),
      loading: false,
      previewData: {
        image: '',
      },
      selectedMessageToDelete: null,
      deletingMessage: false,
      touching: 0,
    }
  },
  computed: {
    ...mapGetters('studentInteractions', {
      fileUploadLoadingStatus: 'getFileUploadLoading',
    }),
    messageTyping() {
      return store.state['app-chat']?.messageTyping
    },
  },

  watch: {
    chatData: {
      handler() {
        this.updateData() // call it in the context of your component object
      },
      deep: true,
    },
    profileUserId: {
      handler() {
      },
      deep: true,
    },
  },
  mounted() {
    ChatHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .on(VaniEvent.OnNewChatMessageReceived, this.onNewChatMessageReceived)
    this.updateData()
  },
  unmounted() {
    ChatHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      .off(VaniEvent.OnNewChatMessageReceived, this.onNewChatMessageReceived)
  },

  methods: {
    ...mapActions('studentInteractions', [
      'fileUploadLoading',
    ]),
    isImage(file) {
      const fileSplit = file.split('.')
      const ext = fileSplit[fileSplit.length - 1].toLowerCase()
      const validExt = ['jpg', 'png', 'jpeg', 'webp', 'gif']

      return validExt.includes(ext)
    },
    isSticker(file) {
      return file.includes('/storage/sticker')
    },
    downloadFile(url, name) {
      // eslint-disable-next-line no-alert
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.download = name
      link.href = url

      document.body.appendChild(link)

      link.click()
      document.body.removeChild(link)

      // console.log(url)
      // const blobUrl = window.URL.createObjectURL(new Blob([url]))
      // const anc = document.createElement('a')
      // anc.href = blobUrl
      // anc.setAttribute('download', name)
      // document.body.appendChild(anc)
      // anc.click()
      // document.body.removeChild(anc)
    },
    updateData() {
      // this.formattedChatData = {}
      ChatHandler.getInstance().eventEmitter.emit('fileSent', {})
      this.formattedChatData.contact = {
        id: this.chatData.contact.id,
        avatar: this.chatData.contact.avatar,
        users: this.chatData.contact.users,
        fullName: this.chatData.contact.fullName,
      }
      this.formattedChatData.profileUserAvatar = this.profileUserAvatar

      let chatLog = []
      if (this.chatData.chat) {
        chatLog = this.chatData.chat
      }
      const formattedChatLog = []
      let chatMessageSenderId = this.chatData.senderId || undefined
      let msgGroup = {}
      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.senderId) {
          msgGroup.sender = msg.sender
          msgGroup.senderId = msg.senderId
          msgGroup.messages.push({
            id: msg.id,
            msg: msg.message,
            sender: msg.sender,
            time: msg.time,
            msg_sent: msg.sent,
            msg_recieved: msg.recieved,
            type: msg.type,
            audio: msg.type === 'audio' || msg.type === 'file' ? msg.type : '',
            audioUrl:
            msg.type === 'audio' || msg.type === 'file' ? msg.message : '',
          })
        } else {
          chatMessageSenderId = msg.senderId
          formattedChatLog.push(msgGroup)
          msgGroup = {
            sender: msg.sender,
            senderId: msg.senderId,
            messages: [
              {
                id: msg.id,
                msg: msg.message,
                time: msg.time,
                msg_sent: msg.sent,
                msg_recieved: msg.recieved,
                type: msg.type,
                audio:
               (msg.type === 'audio' || msg.type === 'file')
                 ? msg.type
                 : '',
                audioUrl:
              (msg.type === 'audio' || msg.type === 'file')
                ? msg.message
                : '',
              },
            ],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })
      this.formattedChatData.formattedChatLog = formattedChatLog
      if (this.fileUploadLoadingStatus) this.fileUploadLoading(false)
    },
    onNewChatMessageReceived(messagePayload) {
      this.loading = false
      if (this.chatData.contact.id === messagePayload.extraData.send_by) {
        if (this.formattedChatData.formattedChatLog.length > 0) {
          if (
            this.formattedChatData.formattedChatLog[
              this.formattedChatData.formattedChatLog.length - 1
            ].senderId === messagePayload.extraData.send_by
          ) {
            this.formattedChatData.formattedChatLog[
              this.formattedChatData.formattedChatLog.length - 1
            ].messages.push({
              msg: messagePayload.extraData.message,
              sender: messagePayload.extraData.send_by,
              time: messagePayload.extraData.updated_at,
              msg_sent: true,
              msg_recieved: true,
              type: messagePayload.extraData.type,
              audio:
              messagePayload.extraData.type === 'audio'
              || messagePayload.extraData.type === 'file'
                ? messagePayload.extraData.type
                : '',
              audioUrl:
              messagePayload.extraData.type === 'audio'
              || messagePayload.extraData.type === 'file'
                ? messagePayload.extraData.message
                : '',
            })
          } else {
            this.loading = false
            this.formattedChatData.formattedChatLog.push({
              senderId: messagePayload.extraData.send_by,
              messages: [
                {
                  msg: messagePayload.extraData.message,
                  sender: messagePayload.extraData.send_by,
                  time: messagePayload.extraData.updated_at,
                  msg_sent: true,
                  msg_recieved: true,
                  type: messagePayload.extraData.type,
                  audio:
                  messagePayload.extraData.type === 'audio'
                  || messagePayload.extraData.type === 'file'
                    ? messagePayload.extraData.type
                    : '',
                  audioUrl:
                  messagePayload.extraData.type === 'audio'
                  || messagePayload.extraData.type === 'file'
                    ? messagePayload.extraData.message
                    : '',
                },
              ],
            })
          }
        }
      }
    },
    setPreviewData(image) {
      this.previewData.image = image
    },
    messageToDelete() {
      this.deletingMessage = true
      useJwt.deleteChatRoom(this.selectedMessageToDelete).then(response => {
        this.selectedMessageToDelete = false
        this.$emit('fetchMessage')
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => { this.deletingMessage = false })
    },
  },

  setup() {
    return {
      formatDateToMonthShort,
    }
  },
}
</script>

<style>
.chat-app-window .chats .chat-body {
  display: flex !important;
  flex-direction: column;
  align-items: flex-end;
}
.chat-app-window .chats .chat-left .chat-body {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
}
.chat-app-window .chats .chat-body .msg-sender {
  transform: translateX(-15px) translateY(-9px);
}
.chat-app-window .chats .chat-left .msg-sender {
  transform: translateX(15px) translateY(-9px);
}

.msg-sender {
  margin-top: 10px;
  font-size: 11px;
  font-weight: 700;
  margin-bottom: -10px;
}

.d-file {
  cursor: pointer;
}
.d-file:hover {
  text-decoration: underline;
}

.sticker {
  width: 70px;
  height: auto;
}

.sender-message .delete-chat-icon {
  visibility: hidden;
}
.sender-message:hover .delete-chat-icon {
  visibility: visible;
}

.chat-content {
  box-sizing: content-box;
  overflow-wrap: break-word;
}
.message-para{
  white-space: pre-line;
  word-spacing: 0px
}
</style>
