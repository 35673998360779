<template>
  <b-card
    no-header
    class="student-diary-body"
  >
    <b-table
      v-if="getRubricsForTable().length>0"
      responsive="sm"
      :items="getRubricsForTable()"
      :fields="columns"
    >
      <template #cell(screenshot)="data">
        <a
          href="data.item.screenshot"
          target="_"
        >
          <img
            style="width:80px;height:80px;object-fit:cover"
            :src="data.item.screenshot"
            alt=""
          >
        </a>
      </template>
    </b-table>
    <b-row v-else>
      <b-col>
        <p class="m-0 p-0">
          {{ $t('student-diary-module.no-data-available') }}
        </p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BTable, BCard, BRow, BCol,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    BTable, BCard, BRow, BCol,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    isProcessing: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      columns: [
        { key: 'rubric', label: i18n.tc('student-diary-module.rubric') },
        { key: 'expected', label: i18n.tc('student-diary-module.expected') },
        { key: 'actual', label: i18n.tc('student-diary-module.actual') },
        { key: 'percent_complete', label: i18n.tc('student-diary-module.percent_complete') },
      ],
    }
  },
  methods: {
    getRubricsForTable() {
      return this.data.map(e => ({
        rubric: e.rubric.title,
        expected: e.expected,
        actual: e.actual,
        percent_complete: parseInt(e.expected, 10) !== 0 ? `${parseInt((parseInt(e.actual, 10) / parseInt(e.expected, 10)) * 100, 10)}%` : '0%',
      }))
    },
  },
}
</script>
