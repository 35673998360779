<template>
  <div
    class="text-right"
    :class="hasAnimatedLayout &&' d-flex justify-content-between'"
  >
    <div
      v-if="hasAnimatedLayout"
      class="row"
    >
      <div class="col">
        <b-button
          v-if="animatedTemplateId"
          variant="success"
          size="sm"
          class="ml-2"
          :disabled="isSaving"
          @click="copyAnimationTemplate"
        >
          <b-spinner
            v-if="isSaving"
            small
          /> Save Template For Using in other problem
        </b-button>
      </div>
      <div class="col">
        <ManageSavedTemplate @applyTemplate="loadSelectedTemplate" />
      </div>
    </div>
    <div
      v-if="showEditMode"
      class="d-flex justify-content-end"
    >
      <b-form-group
        label="Animated Layout"
        class="mr-2"
      >
        <b-form-checkbox
          v-model="updateHasAnimatedLayout"
          :value="1"
          switch
        />
      </b-form-group>
      <b-form-group
        v-if="hasAnimatedLayout"
        label="Editor Mode"
      >
        <b-form-checkbox
          v-model="updateIsAnimatedEditorModeEnabled"
          switch
        />
      </b-form-group>
      <b-form-group
        class="ml-2"
        label="Show Feedback"
      >
        <b-form-checkbox
          v-model="updateIsFeedBackModeEnabled"
          switch
        />
      </b-form-group>
      <b-form-group
        v-if="hasAnimatedLayout"
      >
        <b-button
          variant="danger"
          size="sm"
          class="ml-2"
          @click="$root.$emit('resetAnimationTemplate')"
        >
          Reset
        </b-button>
      </b-form-group>
    </div>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import { BFormCheckbox, BFormGroup, BButton } from 'bootstrap-vue'
import ManageSavedTemplate from '@/views/super/problem/body-template/ManageSavedTemplate.vue'

export default {
  components: {
    BFormCheckbox, BFormGroup, BButton, ManageSavedTemplate,
  },
  props: ['animatedTemplateId', 'showEditMode', 'isAnimatedEditorModeEnabled', 'isFeedBackModeEnabled', 'hasAnimatedLayout'],
  data() {
    return {
      isSaving: false,
    }
  },
  computed: {
    updateIsAnimatedEditorModeEnabled: {
      get() {
        return this.isAnimatedEditorModeEnabled
      },
      set(value) {
        this.$emit('updateIsAnimatedEditorModeEnabled', value)
      },
    },
    updateIsFeedBackModeEnabled: {
      get() {
        return this.isFeedBackModeEnabled
      },
      set(value) {
        this.$emit('updateIsFeedBackModeEnabled', value)
      },
    },
    updateHasAnimatedLayout: {
      get() {
        return this.hasAnimatedLayout
      },
      set(value) {
        this.$emit('updateHasAnimatedLayout', value)
      },
    },
  },
  methods: {
    async copyAnimationTemplate() {
      // eslint-disable-next-line no-alert
      const name = prompt('Please enter template name')
      if (name) {
        this.isSaving = true
        useJwt.saveAnimationTemplateCopy(this.animatedTemplateId, {
          template_name: name,
        }).then(response => {
          this.showSuccessMessage(response)
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isSaving = false
        })
      }
    },
    loadSelectedTemplate(template) {
      if (template) {
        this.$root.$emit('loadSavedAnimationTemplate', template)
      } else {
        this.showError('Template not found')
      }
    },
  },
}
</script>
