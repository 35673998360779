<template>
  <div>
    <Header
      :active-view="activeView"
      :user-details="userDetails"
      :is-self="userId===self.id"
      @changeActiveView="(v)=>activeView=v"
      @getStudentRubrics="getStudentRubrics"
    />

    <rubric-view
      :data="studentRubrics"
      :is-processing="isProcessing"
    />

  </div>
</template>

<script>
// import { BCard } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_STUDENT } from '@/const/userType'
import Header from './components/Header.vue'
import RubricView from './components/RubricView.vue'

export default {
  components: {
    Header, RubricView,
  },
  data() {
    return {
      totalTime: '',
      activeView: 'block',
      self: getUserData(),
      calendarOptions: {
        initialView: 'dayGridMonth',
      },
      studentRubrics: [],
      isProcessing: false,
      userDetails: {
        fullname: 'john doe',
        avatar: 'https://via.placeholder.com/400',
      },
      userId: null,
    }
  },
  watch: {
    '$route.params.id': {
      handler(id) {
        if (id) {
          this.userId = id
          this.getUserData()
        }
      },
      deep: true,
      immediate: true,
      totalTime: '',
    },
    '$route.params.classId': {
      handler(id) {
        if (id) {
          this.classId = id
          this.getStudentRubrics()
        }
      },
      deep: true,
      immediate: true,
      totalTime: '',
    },
  },
  created() {
    if (!this.userId) this.userId = this.self.usertype === USER_TYPE_STUDENT ? this.self.id : null
  },
  mounted() {
    if (this.userId) this.getUserData()
  },
  methods: {
    getUserData() {
      this.isProcessing = true
      useJwt.getUser(this.userId).then(res => {
        this.userDetails = res.data.data
      }).finally(() => {
        this.isProcessing = false
      })
    },
    getStudentRubrics(date) {
      this.isProcessing = true
      useJwt.getStudentRubrics(this.userId, this.classId, { params: date }).then(res => {
        this.studentRubrics = res.data.data
      }).finally(() => {
        this.isProcessing = false
      })
    },

  },
}
</script>

<style>
.student-diary-header{
  z-index: 1;
}
</style>
