export const MEMBER_TYPE_LEADER = 'leader'
export const MEMBER_TYPE_COLEADER = 'co-leader'
export const MEMBER_TYPE_MEMBER = 'member'
export const MEMBER_TYPE_SPONSOR = 'sponsor'
export const MEMBER_TYPE_CHARITY = 'charities'

export const MEMBER_TYPES = [
  MEMBER_TYPE_LEADER,
  MEMBER_TYPE_COLEADER,
  MEMBER_TYPE_MEMBER,
  MEMBER_TYPE_SPONSOR,
  MEMBER_TYPE_CHARITY,
]
