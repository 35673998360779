/* eslint-disable */

export const initGoogleSdk = function(id){
  window.gapi.load('client:auth2', async () => {
    try {
      await window.gapi.client.init({
        clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        scope: 'profile email',
        cookiepolicy: 'single_host_origin',
      });        
      return window.gapi
    } catch (error) {
      console.log(error)
    }
  });  
}
