<template>
  <div>
    <validation-observer ref="matchingForm">

      <!-- Voice -->
      <b-form-group
        :label="$t('labels.create-problem.voice')"
        label-for="voice"
      >
        <b-form-select
          id="domain"
          v-model="selectedVoice"
          :options="voices"
          name="voice"
        />
      </b-form-group>
      <!-- Voice -->

      <!-- Question -->
      <hr class="mt-3">
      <div
        v-for="(q, j) in questionLength"
        :key="j"
      >
        <b-row>
          <b-col md="10">
            <!-- Question -->
            <b-row>
              <b-col md="8">
                <b-form-group
                  :label="$t('labels.create-problem.question')"
                  label-for="question"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="question"
                    rules="required"
                    :vid="`question-audio-${level+j}`"
                  >
                    <b-form-input
                      :id="`question-${level}-audio`"
                      v-model="questions.text[j]"
                      :placeholder="$t('labels.create-problem.question')"
                      :state="errors.length > 0 ? false : null"
                      name="question"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-col
                  class="mt-2"
                  md="1"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon"
                    @click="tts(j, 'questions')"
                  >
                    <feather-icon icon="PlayIcon" />
                  </b-button>
                </b-col>
              </b-col>
              <b-col md="4">
                <image-upload
                  title="Image"
                  :is-required="false"
                  style="height:180px;"
                  :url="questions.imageUrl[j]"
                  @imageRemoved="()=>{questions.image[j]=null;questions.imageUrl[j]=null;}"
                  @imageUploaded="(file,url)=>{questions.image[j]=file;questions.imageUrl[j]=url; commitQuestion()}"
                />
              </b-col>
              <b-col>
                <Recorder
                  :url="questions.extraFile[j] && questions.extraFile[j].url"
                  @input="setRecordedQuestionAudio($event, j)"
                  @fileTypeInvalid="setError([`feedback-audio-${level}`], $event)"
                  @fileSizeInvalid="setError([`feedback-audio-${level}`], $event)"
                />
              </b-col>
            </b-row>
            <!-- Question -->
            <!-- Answer -->
            <b-row class="pt-2">
              <b-col
                md="8"
              >
                <b-form-group
                  :label="$t('labels.create-problem.answer')"
                  label-for="answer"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="answer"
                    rules="required"
                    :vid="`answer-audio-${level+j}`"
                  >
                    <b-form-input
                      :id="`answer-${level}-audio`"
                      v-model="answers.text[j]"
                      :placeholder="$t('labels.create-problem.answer')"
                      :state="errors.length > 0 ? false : null"
                      name="answer"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-col
                  class="mt-2"
                  md="1"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    @click="tts(j, 'answers')"
                  >
                    <feather-icon icon="PlayIcon" />
                  </b-button>
                </b-col>
              </b-col>
              <b-col md="4">
                <image-upload
                  title="Image"
                  :is-required="false"
                  :url="answers.imageUrl[j]"
                  style="height:180px;"
                  @imageRemoved="()=>{answers.image[j]=null;answers.imageUrl[j]=null;}"
                  @imageUploaded="(file,url)=>{answers.image[j]=file;answers.imageUrl[j]=url;commitAnswer()}"
                />
              </b-col>
              <b-col>
                <Recorder
                  :url="answers.extraFile[j] && answers.extraFile[j].url"
                  @input="setRecordedAnswerAudio($event, j)"
                  @fileTypeInvalid="setError([`feedback-audio-${level}`], $event)"
                  @fileSizeInvalid="setError([`feedback-audio-${level}`], $event)"
                />
              </b-col>

            </b-row>
            <!-- Answer -->
            <b-row v-for="extra,ix of answers.extraAnswers[j]"
                   :key="ix"
                   class="pt-2"
            >
              <b-col
                md="8"
              >
                <b-form-group
                  :label="$t('labels.create-problem.answer')"
                  label-for="answer"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="answer"
                    rules="required"
                  >
                    <b-form-input
                      :id="`answer-${level}-audio`"
                      v-model="extra.text"
                      :placeholder="$t('labels.create-problem.answer')"
                      :state="errors.length > 0 ? false : null"
                      name="answer"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-col
                  class="mt-2"
                  md="1"
                >
                  <div class="d-flex">
                    <b-button
                      variant="outline-danger"
                      class="btn-icon mr-1"
                      @click="removeMoreAnswer(ix, j)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="btn-icon mr-1"
                      @click="speakText(extra.text)"
                    >
                      <feather-icon icon="PlayIcon" />
                    </b-button>
                  </div>
                </b-col>
              </b-col>
              <b-col md="4">
                <image-upload
                  title="Image"
                  :is-required="false"
                  :url="extra.imageUrl"
                  style="height:180px;"
                  @imageRemoved="()=>{extra.image = null;extra.imageUrl =null;}"
                  @imageUploaded="(file,url)=>{extra.image = file;extra.imageUrl =url;commitAnswer()}"
                />
              </b-col>
              <b-col>
                <Recorder
                  :url="extra.extraFile && extra.extraFile?.url"
                  @input="setRecordedAnswerAudioExtra($event, extra)"
                  @fileTypeInvalid="setError([`feedback-audio-${level}`], $event)"
                  @fileSizeInvalid="setError([`feedback-audio-${level}`], $event)"
                />
              </b-col>

            </b-row>
            <!-- More Answers -->
            <div class="mt-2">
              <b-button variant="outline-primary"
                        class="btn-icon"
                        @click="pushExtraAnswer(j)"
              >
                <feather-icon icon="PlusIcon" /> Add Answer
              </b-button>
            </div>
          </b-col>
          <b-col
            md="2"
            class="mt-4"
          >
            <b-button
              v-if="j>0"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="btn-icon mr-1"
              @click="deleteQuestion(j)"
            >
              <feather-icon icon="TrashIcon" />
              {{ $t('Delete') }}
            </b-button>
          </b-col>
        </b-row>
        <hr>
      </div>
      <!-- Question / Answer -->

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="btn-icon"
        @click="addQuestion"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t("Add-New") }}
      </b-button>

    </validation-observer>
  </div>
</template>

<script>
import {
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import Ripple from 'vue-ripple-directive'
import ImageUpload from '@views/common/components/CustomImageUpload.vue'
import Recorder from '@/views/super/problem/components/recorder.vue'

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    ImageUpload,
    Recorder,
  },
  directives: {
    Ripple,
  },
  props: {
    level: {
      type: Number,
      default: () => 0,
    },
    questionType: {
      type: String,
      default: () => 'text',
    },
    answerType: {
      type: String,
      default: () => 'text',
    },
  },
  data: () => ({
    questions: {
      text: [''], image: [''], imageUrl: [''], extraFile: [''],
    },
    answers: {
      text: [''], image: [''], imageUrl: [''], extraFile: [''], extraAnswers: [[]],
    },
    voices: [{
      text: 'En-Us',
      value: 'en-US',
      voice: 'en',
      lang: 'en',
    }],
    selectedVoice: 'en-US',
    synth: null,
  }),
  computed: {
    questionLength() {
      return this.$store.getters['problem/getQuestionLength']
    },
    activeQuestions() {
      return this.$store.getters['problem/getQuestions']
    },
    activeAnswers() {
      return this.$store.getters['problem/getAnswers']
    },
    deleteIndex() {
      return this.$store.state.problem.create.matching.deleteIndex
    },
    showError: {
      get() {
        return this.$store.state.problem.showError
      },
      set() {},
    },
  },
  watch: {
    questions: {
      deep: true,
      handler() {
        this.commitQuestion()
      },
    },
    answers: {
      deep: true,
      handler() {
        this.commitAnswer()
      },
    },
    questionLength() {
      if (this.deleteIndex !== null) {
        this.questions.text.splice(this.deleteIndex, 1)
        this.questions.image.splice(this.deleteIndex, 1)
        this.questions.imageUrl.splice(this.deleteIndex, 1)
      }
    },
    activeQuestions(value) {
      this.setQuestions(value)
    },
    activeAnswers(value) {
      this.setAnswers(value)
    },
    showError(value) {
      if (value) {
        this.$refs.matchingForm.validate()
      }
    },
  },
  created() {
    this.synth = window.speechSynthesis
    this.getVoices().then(voices => {
      this.voices = [voices.map((v, i) => ({
        text: v.name, value: i, voice: v, lang: v.lang,
      })).find(v => (v.lang === 'en-US'))]
      this.selectedVoice = JSON.parse(JSON.stringify(this.voices))[0]?.value
    })
    this.setQuestions(this.activeQuestions)
    this.setAnswers(this.activeAnswers)
  },
  methods: {
    removeMoreAnswer(index, i) {
      this.answers.extraAnswers[i].splice(index, 1)
      this.commitQuestion()
    },
    setRecordedQuestionAudio(event, j) {
      this.questions.extraFile[j] = event
      this.commitQuestion()
    },
    setRecordedAnswerAudio(event, j) {
      this.answers.extraFile[j] = event
      this.commitAnswer()
    },
    async setRecordedAnswerAudioExtra(event, obj) {
      // eslint-disable-next-line no-param-reassign
      obj.extraFile = event
      const url = await this.blobUrlToBase64(event.url)
      // eslint-disable-next-line no-param-reassign
      obj.audioUrl = url
      this.commitAnswer()
    },
    pushExtraAnswer(j) {
      if (!this.answers.extraAnswers[j]) {
        this.answers.extraAnswers.push([])
        this.$forceUpdate()
      }
      console.log(this.answers)
      this.answers.extraAnswers[j].push({
        text: '',
        image: '',
        imageUrl: '',
        extraFile: '',
      })
    },
    addQuestion() {
      this.$store.commit('problem/ADD_QUESTIONS')
    },
    deleteQuestion(j) {
      this.$store.commit('problem/DELETE_QUESTIONS', { index: j })
    },
    speakText(textValue) {
      this.synth.cancel()
      const utterThis = new SpeechSynthesisUtterance(textValue)
      utterThis.lang = 'en-US'
      utterThis.rate = 0.8
      this.synth.speak(utterThis)
    },
    tts(j, type) {
      this.synth.cancel()
      let utterThis
      if (type === 'questions') utterThis = new SpeechSynthesisUtterance(this.questions.text[j])
      else utterThis = new SpeechSynthesisUtterance(this.answers.text[j])
      if (this.voices[0].voice) utterThis.voice = this.voices[0].voice
      else utterThis.lang = 'en-US'
      utterThis.rate = 0.8
      this.synth.speak(utterThis)
    },
    setQuestions(value) {
      value.text[this.level].forEach((q, index) => {
        this.questions.text[index] = q
      })
      value.image[this.level].forEach((q, index) => {
        this.questions.image[index] = q
      })
      value.imageUrl[this.level].forEach((q, index) => {
        this.questions.imageUrl[index] = q
      })
    },
    setAnswers(value) {
      value.text[this.level].forEach((a, index) => {
        this.answers.text[index] = a
      })
      value.image[this.level].forEach((a, index) => {
        this.answers.image[index] = a
      })
      value.imageUrl[this.level].forEach((a, index) => {
        this.answers.imageUrl[index] = a
      })
    },
    commitQuestion() {
      this.questions.text.forEach((q, index) => {
        const payload = {
          type: 'speech',
          level: this.level,
          index,
          question: {
            text: q,
            image: this.questions.image[index],
            imageUrl: this.questions.imageUrl[index],
            extraFile: this.questions.extraFile[index]?.audio,
          },
        }
        this.$store.commit('problem/SET_QUESTION_FEILD', payload)
      })
    },
    commitAnswer() {
      this.answers.text.forEach((q, index) => {
        const payload = {
          type: 'speech',
          level: this.level,
          index,
          answer: {
            text: q,
            image: this.answers.image[index],
            imageUrl: this.answers.imageUrl[index],
            extraFile: this.answers.extraFile[index]?.audio,
            extraAnswers: this.answers.extraAnswers[index],
          },
        }
        this.$store.commit('problem/SET_ANSWER_FEILD', payload)
      })
    },
    getVoices() {
      return new Promise(resolve => {
        let id = null
        id = setInterval(() => {
          if (this.synth.getVoices().length !== 0) {
            resolve(this.synth.getVoices())
            clearInterval(id)
          }
        }, 10)
      })
    },
  },
}
</script>
