/* eslint-disable import/no-cycle */
import { isUserLoggedIn, getUserData } from '@/auth/utils'
import getUserTypeSpecificPage from '@/router/utils'

export default function isNotAuthenticated({ next, router }) {
  if (isUserLoggedIn()) {
    return router.replace({ name: getUserTypeSpecificPage(getUserData().usertype, 'login') })
  }

  return next()
}
