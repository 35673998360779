<template>
  <b-card
    no-header
    class="student-diary-body"
  >
    <b-overlay
      id="overlay-background"
      :show="isProcessing"
      variant="light"
      opacity="0.7"
      blur="1"
      rounded="sm"
    >
      <p
        v-if="data.length>0 || Object.keys(data).length>0"
        class="m-0 p-0"
      >
        <strong>  {{ timeToday }}</strong>
      </p>
      <div
        v-if="Object.keys(data).length>0"
        class="d-flex flex-column-reverse mt-2"
      >
        <b-row
          v-for="(hourData,time) in dataForSections"
          :key="time"
          :set="screenshots = hourData.filter(data => data.data).map(data => data.data.screenshot)"
        >
          <b-col class="d-flex day">
            <div class="time">
              <p>{{ time }}</p>
            </div>
            <screenshot-card
              v-for="(section,index) in hourData"
              :key="'section_'+index"
              :screen-casts="screenshots"
              :section-data="section"
            />
          </b-col>
        </b-row>
      </div>
      <b-row v-else>
        <b-col>
          <p class="m-0 p-0">
            {{ $t('student-diary-module.no-data-available') }}
          </p>
        </b-col>
      </b-row>

    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import ScreenshotCard from './ScreenshotCard.vue'

export default {
  components: {
    BCard, BRow, BCol, ScreenshotCard, BOverlay,
  },
  props: {
    data: {
      type: [Object, Array],
      required: true,
    },
    isProcessing: {
      type: Boolean,
      required: true,
    },
    timeToday: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dataForSections: {},
    }
  },
  watch: {
    data: {
      handler(newValue) {
        this.dataForSections = {}
        // eslint-disable-next-line no-restricted-syntax
        for (const key in newValue) {
          if (Object.hasOwnProperty.call(newValue, key)) {
            this.dataForSections[key] = []
            for (let index = 0; index < 6; index += 1) {
              this.dataForSections[key][index] = {
                data: this.getDataForASection(key, index + 1),
                hasNext: index < 5 ? this.getDataForASection(key, index + 2) : null,
                hasPrevious: index > 0 ? this.getDataForASection(key, index) : null,
              }
            }
            let i = 0
            while (i < 6) {
              let totalNext = 0
              for (let ind = i; ind < 6; ind += 1) {
                if (this.dataForSections[key][ind].hasNext) totalNext += 1
                else {
                  this.dataForSections[key][i].totalNext = totalNext
                  i += 1
                  break
                }
              }
            }
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    getMinuteAndSecondFromTime(time) {
      return { min: parseInt(time.split(':')[1], 10), sec: parseInt(time.split(':')[2].split('.')[0], 10) }
    },
    getDataForASection(hour, section) {
      let data = null

      for (let index = 0; index < this.data[hour].length; index += 1) {
        const e = this.data[hour][index]
        const { min, sec } = this.getMinuteAndSecondFromTime(e.created_at)
        const upperBound = section * 10
        const lowerBound = (section - 1) * 10
        if ((min < upperBound || (min === upperBound && sec === 0)) && ((min > lowerBound) || (min === lowerBound && sec > 0))) {
          data = e
          break
        }
      }

      return data
    },
  },
}
</script>

<style lang="css" scoped>
    .day{
        overflow: auto;
    }
    .time{
        margin-top: 75px;
        min-width: 60px;
    }
</style>
