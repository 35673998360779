<template>
  <b-form-group
    :label="$t('Select Text File')"
    label-for="file-input"
    :description="$t('Please select a valid Text file to upload')"
  >
    <b-form-file
      :placeholder="$t('Choose a file or drop it here...')"
      drop-placeholder="Drop file here..."
      @change="handleFileChange"
    />
    <div v-if="formattedJson"
         style="max-height: 550px; overflow-y: scroll"
    >
      <JsonEditorVue
        v-model="formattedJson"
        :debounce="1000"
        :stringified="false"
        mode="text"
      />
    </div>
  </b-form-group>
</template>
<script setup>
import { ref } from 'vue'
import { BFormGroup, BFormFile } from 'bootstrap-vue' // Added missing imports
import JsonEditorVue from 'json-editor-vue'

const fileContent = ref(null)
const formattedJson = ref(null)

const formattedDataForJson = allData => {
  const formatMainPoint = text => text.replace(/^Main Point \d+: /, '')
  const formatStandard = text => text.replace(/^Standard \d+: /, '')
  const numberToLetter = num => {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz'
    return alphabet[num - 1] || '' // Subtract 1 to align with zero-based index
  }
  const formatDomain = text => {
    // Find the position where "GEC" starts and split the string
    const codeStartIndex = text.indexOf('GEC')
    const code = text.slice(codeStartIndex).split(' ')[0]
    const title = text.slice(codeStartIndex + code.length + 1)
    return {
      code,
      title,
    }
  }
  const formattedData = []
  allData.forEach(inputArray => {
    let currentStandard = null
    if (!inputArray[0]?.toLowerCase().startsWith('domain')) {
      return
    }
    const domain = formatDomain(inputArray[0])
    const outputObject = {
      dom: domain.title,
      code: domain.code,
      lsn: [],
    }
    let lastIndex = 1
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < inputArray.length; i++) {
      const text = inputArray[i]
      if (text?.toLowerCase().startsWith('standard')) {
        if (currentStandard) {
          outputObject.lsn.push(currentStandard)
        }
        const standardCode = `${domain.code}.${numberToLetter(lastIndex)}`
        lastIndex += 1
        currentStandard = {
          ln: formatStandard(text),
          code: standardCode,
          mp1: '',
          mp2: '',
          mp3: '',
        }
      } else if (
        text?.toLowerCase().startsWith('main point 1')
        && currentStandard
      ) {
        currentStandard.mp1 = formatMainPoint(text)
      } else if (
        text?.toLowerCase().startsWith('main point 2')
        && currentStandard
      ) {
        currentStandard.mp2 = formatMainPoint(text)
      } else if (
        text?.toLowerCase().startsWith('main point 3')
        && currentStandard
      ) {
        currentStandard.mp3 = formatMainPoint(text)
      }
    }
    if (currentStandard) {
      outputObject.lsn.push(currentStandard)
    }
    formattedData.push(outputObject)
  })
  return formattedData
}

const fetchJsonDataFromText = inputString => {
  // Regular expression to match delimiter lines that contain only x's or \\'s
  const delimiterPattern = /^(x{95}|\\{95})$/

  const cleanSections = inputString
    .split('\n')
    .map(line => line.trim()) // Trim whitespace from each line
    .filter(line => !delimiterPattern.test(line) && line !== '') // Filter out delimiter lines

  const allData = []
  let accumulator = []
  cleanSections.forEach((item, index) => {
    if (item.startsWith('Domain') || !cleanSections[index + 1]) {
      if (accumulator.length) {
        allData.push(accumulator)
        accumulator = []
      }
    }
    if (item.startsWith('xxxx') || item.startsWith('\\\\')) {
      return
    }
    accumulator.push(item)
  })
  const formattedData = formattedDataForJson(allData)
  console.log(formattedData)
  return formattedData
}

const handleFileChange = event => {
  const selectedFile = event.target.files[0]
  if (selectedFile) {
    const reader = new FileReader()
    reader.onload = e => {
      fileContent.value = e.target.result
      formattedJson.value = fetchJsonDataFromText(fileContent.value) // You can process the content as needed
    }
    reader.readAsText(selectedFile)
  }
}
</script>
