<template>
  <div v-if="point">
    <div
      :class="`point ${
        !point.statement_visited && ' clickable'
      }`"
      :style="`top: ${point.coordinates.y * 100}%; left: ${
        point.coordinates.x * 100
      }%;
      background-color: ${point.feedback_visited && (isAnswerCorrect ? 'green' : 'red')};
      cursor: pointer;
      `"
      @click="checkPoints(point)"
    >
      <span>
        {{ point.statement_visited ?
          (!isAnswerCorrect && point.feedback_visited ? 'X' : '✓')
          : (point.matchedPointIndex ? '-' : '+') }}
      </span>
    </div>
    <div
      v-show="point.matchedPointIndex"
      :style="`top: ${point.coordinates.y * 100}%; left: calc(${
        point.coordinates.x < 0.7
          ? point.coordinates.x * 100 + 1 + '%'
          : point.coordinates.x * 100 +
            '%' +
            ' - ' +
            (tooltipWidth + 25) +
            'px'
      }); position:absolute;`"
    >
      <div
        :class="`tooltiptext p-1 ${
          point.coordinates.x < 0.7 ? 'tooltip-left' : 'tooltip-right'
        }`"
        :style="`width:${tooltipWidth}px;background-color: #00000054`"
      >
        <div class="d-flex justify-content-between align-items-center ">
          <span
            v-text-to-speech
            style="color: white"
            v-html="point.hotspot.statement"
          />
          <AudioPlayer
            v-if="point.hotspot.statement_audio"
            :key="point.hotspot.statement_audio"
            :src="point.hotspot.statement_audio"
          />
        </div>
        <div class="mt-1">
          <v-select v-model="selectedAnswers"
                    :options="answers"
                    label="text"
                    multiple
                    :reduce="i => i.value"
                    @input="onSelect"
          />
        </div>
        <div v-if="point.feedback_visited"
             class="mt-1"
        >
          <span
            v-text-to-speech
            style="color: white"
            v-html="point.hotspot.feedback"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'

export default {
  components: {
    vSelect,
    AudioPlayer,
  },
  props: {
    point: {
      type: Object,
      default: () => null,
    },
    hotSpotType: {
      type: String,
      required: true,
    },
    tooltipWidth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedAnswers: [],
      isAnswerCorrect: false,
    }
  },
  computed: {
    answers() {
      return this.point.hotspot.questions[0].answers.map(item => ({
        value: item.id,
        text: item.text,
      }))
    },
  },
  methods: {
    checkPoints(point) {
      // this.hotspot.hotspotPoints.statement_visited = true
      // eslint-disable-next-line no-param-reassign
      point.matchedPointIndex = !point.matchedPointIndex
      this.$forceUpdate()
    },
    onSelect() {
      this.$emit('selectedAnswers', this.point.hotspot.questions[0].id, this.selectedAnswers)
      this.pointVisited(this.point)
    },
    // warning this method is being emitted from parent component HotSpotStatement.vue
    enableMultiLayerFeedback(input) {
      this.point.feedback_visited = true
      const selectedAns = input.find(item => item.question === this.point?.hotspot?.questions[0]?.id)
      this.isAnswerCorrect = selectedAns && !!selectedAns.is_correct
      this.$forceUpdate()
    },
    pointVisited(point) {
      this.$emit('updateQuestionData', point.hotspot.questions)
      // eslint-disable-next-line no-param-reassign
      point.statement_visited = true
      // eslint-disable-next-line no-param-reassign
      point.matchedPointIndex = true
      // eslint-disable-next-line no-param-reassign
      point.statement_visited = true
      this.allVisited = true
      this.$forceUpdate()
      this.$emit('checkAllValid')
    },
  },
}

</script>
<style>
.vs__dropdown-menu {
  z-index: 20000000000 !important
}
</style>
