<template>
  <div>
    <b-row>
      <b-col md="4">
        <h3>{{ $t('calibration-module.permission_info') }}</h3>
      </b-col>
      <b-col md="8">
        <p class="text-muted"
           style="font-size:13px; font-style: italic;"
        >
          <feather-icon icon="InfoIcon" />
          {{ $t('calibration-module.info') }}
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <label for="school"> {{ $t('user-types.school') }}</label>
        <v-select
          id="school"
          v-model="school"
          placeholder="Choose a school"
          label="fullname"
          :reduce="(school) => school.id"
          :options="schoolForSelect"
          name="school"
          @input="onSchoolChange"
        />
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <b-card
          no-body
          class="user-list"
        >
          <b-card-header class="border-bottom">
            <h5>{{ $t('calibration-module.selected-school') }}: {{ selectedUser }}</h5>
          </b-card-header>
          <b-card-body>
            <div v-if="isProcessing"
                 class="text-center"
            >
              <b-spinner
                small
              />
            </div>
            <vue-perfect-scrollbar ref="scrollContainer"
                                   :settings="perfectScrollbarSettings"
                                   class="scroll-content"
                                   @scroll="handleScroll"
            >

              <b-row v-if="users.length > 0">
                <b-col v-for="user, index in users"
                       :key="index"
                       md="4"
                       class="my-1"
                >
                  <div class="form-check d-flex align-item-center">
                    <input
                      :id="user.id"
                      v-model="selectedUsers"
                      type="checkbox"
                      class="form-check-input"
                      :value="{id:user.id, fullname: titleCase(user.fullname)}"
                      style="width:20px; height:20px;"
                    >
                    <label :for="user.id"
                           class="form-check-label"
                           style="margin-left:20px; font-size: 14px; margin-top:4px"
                    >
                      {{ titleCase(user.fullname) }}
                    </label>
                  </div>

                </b-col>
              </b-row>
              <b-row v-else>
                <b-col md="12">
                  <p>{{ $t('messages.no-record-found') }}</p>
                </b-col>
              </b-row>

            </vue-perfect-scrollbar>
          </b-card-body>
          <b-card-footer>
            <b-button
              v-b-tooltip.hover.top="`Add Users to Calibration`"
              variant="primary"
              :disabled="isProcessing || selectedUsers.length === 0 "
              @click="addUsersToCalibration"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t('actions.update') }}
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>
      <b-col v-if="selectedUsers.length"
             md="3"
      >
        <b-card
          no-body
        >
          <b-card-header>
            <h5 class="text-success">
              {{ $t('calibration-module.selected-teacher') }}: {{ selectedUsers.length }}
            </h5>
          </b-card-header>
          <b-card-body class="p-0">
            <b-row>
              <b-col>
                <ul class="list-group">
                  <li v-for="teacher in selectedUsers"
                      :key="teacher.id"
                      class="selected-teacher list-group-item"
                  >
                    {{ teacher.fullname }}
                    <feather-icon class="close text-danger"
                                  icon="XIcon"
                                  role="button"
                                  @click="selectedUsers = selectedUsers.filter(i => i.id != teacher.id)"
                    />
                  </li>
                </ul>
              </b-col>
            </b-row>

          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardBody, BCardFooter, BSpinner, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useApollo from '@/@core/graphql/useApollo'

export default {
  components: {
    BRow,
    BCol,
    VuePerfectScrollbar,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BSpinner,
    BButton,
    vSelect,
  },
  data() {
    return {
      userType: 'teacher',
      userTypeForSelect: [
        { value: 'school', text: 'School' },
        { value: 'teacher', text: 'Teacher' },
      ],
      school: null,
      schoolForSelect: [],
      isProcessing: false,
      currentPage: 1,
      perPage: 30,
      totalItems: 0,
      lastPage: 0,
      users: [],
      selectedUsers: [],
      perfectScrollbarSettings: {
        wheelPropagation: false,
      },
    }
  },
  computed: {
    selectedUser() {
      const selectedOption = this.schoolForSelect.find(option => option.id === this.school)
      return selectedOption ? selectedOption.fullname : ''
    },
  },
  created() {
    this.getUsers()
    this.getSchoolUsers()
    this.getCalibrationPermissionList()
  },
  methods: {
    getUsersByType() {
      this.users = []
      this.currentPage = 1
      this.getUsers()
    },
    getUsers() {
      const params = {
        page: this.currentPage,
        usertype: this.userType,
        perPage: this.perPage,
      }
      this.isProcessing = true
      useJwt.getUsers({ params })
        .then(response => {
          this.users = [...this.users, ...response.data.data]
          this.currentPage = response.data.pagination.current_page
          this.lastPage = response.data.pagination.last_page
        }).catch(error => {
          console.log(error)
        }).finally(() => {
          this.isProcessing = false
        })
    },
    handleScroll(e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target
      // && this.students.hasMorePages
      if (scrollHeight - (scrollTop + offsetHeight) < 5 && !this.isProcessing && this.lastPage >= this.currentPage) {
        this.currentPage += 1
        if (this.currentPage <= this.lastPage) {
          this.getUsers()
        }
      }
    },
    getCalibrationPermissionList() {
      const formData = {
        data: '{}',
      }
      useJwt.getPermission('settings.calibration', formData)
        .then(response => {
          const usersList = response.data.data
          this.selectedUsers = usersList.map(item => ({
            id: item.for,
            fullname: item.target.fullname,
          }))
        })
    },
    addUsersToCalibration() {
      this.isProcessing = true
      const selectedTeachers = this.selectedUsers.map(item => item.id)
      const formData = {
        data: '{}',
        action: 'allow',
        for: JSON.stringify(selectedTeachers),
      }
      useJwt.createPermission('settings.calibration', formData)
        .then(response => {
          this.showSuccessMessage(response)
          this.getCalibrationPermissionList()
        }).catch(error => {
          console.log(error)
        }).finally(() => {
          this.isProcessing = false
        })
    },
    getSchoolUsers() {
      this.isProcessing = true
      useApollo
        .getSchoolUsers()
        .then(response => {
          const res = response.data?.schools?.data
          this.schoolForSelect = res.map(e => ({
            id: parseInt(e.id, 10),
            fullname: e.fullname,
          }))
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    onSchoolChange() {
      this.isProcessing = true
      useApollo.getUsersOfSchool('teacher', this.school).then(response => {
        this.users = response.data?.users?.data
        this.isProcessing = false
      })
    },

  },
}
</script>
<style lang="scss">
.user-list{
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 10px;
  .scroll-content{
    max-height: 300px;
    .gap-10{
      gap:10px;
    }
  }
}
.selected-teacher .close {
  visibility: hidden;
}
.selected-teacher:hover {
  .close {
    visibility: visible;
    margin-top: 3px;
  }
}
</style>
