/* eslint-disable no-underscore-dangle */
/* eslint-disable no-underscore-dangle */
<template>
  <div
    id="balloon-container"
    class="congrz-window"
  >
    <div
      class="js-container container"
      style="top:0px !important;"
    />

    <div class="check-mark-area">
      <!-- <div class="checkmark-circle">
        <div class="background" />
        <BSpinner class="checkmark" />
      </div> -->
      <h1 class="pt-2">
        <BSpinner large
                  style="zoom: 4"
        />
      </h1>
      <h2>{{ self.fullname }}</h2>
      <h3 style="line-height: 2">
        Your answers have been submitted for review. <br>The review process may take a few minutes. <br>You will receive a notification as soon as the review is complete. Kindly be patient, and thank you for your cooperation.
        <br>
        If you wish to continue with other lessons, kindly click on the button below.
      </h3>
      <button
        class="continue-btn"
        type="submit"
        @click="navigateToNext"
      >
        <b-spinner v-show="processing" />
        Continue
      </button>
    </div>

  </div>
</template>
<script>
import { BSpinner } from 'bootstrap-vue'
import { STATUS_RIGHT } from '@/const/avatar'

export default {
  components: {
    BSpinner,
  },
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
    self: {
      type: Object,
      default: () => {},
    },
    lesson: {
      type: String,
      default: '',
    },
    resultInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      characterTimeout: null,
    }
  },
  computed: {
    currentStatus() {
      return this.$store.state.appConfig.currentStatus
    },
  },
  watch: {
  },
  mounted() {
    this.$store.dispatch('appConfig/setRefreshRewardStats', true)
    this.$store.commit('appConfig/UPDATE_CURRENT_STATUS', STATUS_RIGHT)
  },
  methods: {
    navigateToNext() {
      if (this.$route.query.testId) {
        this.$router.push({ name: `${this.AUTH_USER().usertype}-class-test`, params: { classId: this.$route.params.classId } })
      } else {
        this.$router.push({ name: `${this.AUTH_USER().usertype}-classes`, params: { classId: this.$route.params.classId, id: this.course } })
      }
    },
  },
}
</script>
<style lang="scss">

.congrz-window {

@keyframes confetti-slow {
  0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }

  100% { transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg); }
}

@keyframes confetti-medium {
  0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }

  100% { transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg); }
}

@keyframes confetti-fast {
  0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }

  100% { transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg); }
}

.container {
  height: 70vh;
  background: #ffffff;
  border:1px solid white;
  display:fixed;
  top:0px;
}

.confetti-container {
  perspective: 700px;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.confetti {
  position: absolute;
  z-index: 1;
  top: -10px;
  border-radius: 0%;

  &--animation-slow {
    animation: confetti-slow 2.25s linear 1 forwards;
  }

  &--animation-medium {
    animation: confetti-medium 1.75s linear 1 forwards;
  }

  &--animation-fast {
    animation: confetti-fast 1.25s linear 1 forwards;
  }
}

/* Checkmark */
.checkmark-circle {
  width: 150px;
  height: 150px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: auto;
  margin-right: auto;
}
.checkmark-circle .background {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #00C09D;
  position: absolute;
}
.checkmark-circle .checkmark {
  border-radius: 5px;
}
.checkmark-circle .checkmark.draw:after {
  -webkit-animation-delay: 100ms;
  -moz-animation-delay: 100ms;
  animation-delay: 100ms;
  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-name: checkmark;
  -moz-animation-name: checkmark;
  animation-name: checkmark;
  -webkit-transform: scaleX(-1) rotate(135deg);
  -moz-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  -o-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.checkmark-circle .checkmark:after {
  opacity: 1;
  height: 75px;
  width: 37.5px;
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
  border-right: 15px solid white;
  border-top: 15px solid white;
  border-radius: 2.5px !important;
  content: '';
  left: 38px;
  top: 85px;
  position: absolute;
}

@-webkit-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}
@-moz-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}

.continue-btn {
    height: 45px;
    width: 200px;
    font-size: 15px;
    background-color: #0013c0;
    border: 1px solid #0022ab;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(87,71,81,.2);
    cursor: pointer;
    transition: all 2s ease-out;
    transition: all .2s ease-out;
}

.continue-btn:hover {
    background-color: #2ca893;
    transition: all .2s ease-out;
}
}

.check-mark-area {
  text-align: center;
  margin-top: 10vh;
  position:  absolute;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
}
@media (min-width: 375px ){
  .check-mark-area {
    padding: 0px 8px;
    h3{
      font-size: 16px;
    }
  }
 }
</style>
