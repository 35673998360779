<template>
  <div
    class="card text-center"
    role="button"
    @click="$emit('onReserve')"
  >
    <!---->
    <div class="card-body">
      <b-button
        v-if="value.description"
        variant="flat-primary"
        style="position: absolute;right: 0;margin-top: -20px;"
        @click.stop="showDescription = true"
      >
        <feather-icon
          icon="AlertCircleIcon"
          size="20"
        />
      </b-button>
      <h3>
        {{ value.school.firstname }} {{ value.school.lastname }}

        <p>
          <small>
            <feather-icon
              icon="CalendarIcon"
              class="mr-1"
            />{{ FORMAT_DATE(value.start_date, 'MMMM Do YYYY') }} - {{ FORMAT_DATE(value.end_date, 'MMMM Do YYYY') }}</small>
        </p>
      </h3>
      <hr class="mb-2">
      <div class="row">
        <div class="col">
          <!-- <h6 class="text-muted font-weight-bolder">
              Days per week
            </h6> -->
          <h4 class="mb-1">
            Days per week: {{ value.working_days }}
          </h4>
          <h4 class="mb-1">
            {{ value.name }}
          </h4>
          <h4 class="mb-1">
            Duration: {{ value.time_interval }} min sessions
          </h4>
          <h4
            v-if="value.price"
            class="mb-0"
          >
            Price: ${{ value.price }}
          </h4>
        </div>
      </div>
      <hr class="mb-1">
      <div
        v-if="value.description"
        class="row"
      >
        <div class="col">
          {{ value.description }}
        </div>
      </div>
      <div class="row mt-2">
        <div v-for="item,index of value.access_types"
             :key="item.id"
             :class="index == 2 ? 'col-12' : 'col-6'"
             class="mb-2"
        >
          <b-button
            variant="outline-primary"
            block
            @click="$emit('pickReservation', item)"
          >
            {{ getUserAccessTypeLabel(item.name) }}
          </b-button>
        </div>
      </div>
    </div>
    <b-modal
      centered
      :visible="showDescription"
      :title="value.name"
      hide-footer
      @close="showDescription = false"
      @hide="showDescription = false"
    >
      <div>
        {{ value.description }}
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BModal,
  // BSpinner
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BModal,
    // BSpinner,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDescription: false,
    }
  },
  computed: {
    orderId() {
      return this.$route.query.order_id
    },
  },
  methods: {
    openLink(url) {
      window.open(url, '_self')
    },
  },
}
</script>
  <style scoped>
  .text-ellipsis{
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  </style>
