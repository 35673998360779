<template>
  <div id="studentTableNab">
    <b-nav
      id="dropdown-1"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="text-center w-100 d-xs-none"
      toggle-class="text-decoration-none"
      variant="link"
      no-caret
      style="border-radius: 0"
    >
      <template v-for="(nav, index) of navMenus">
        <b-nav-item-dropdown
          v-if="nav.condition && nav.submenus && nav.submenus.length"
          :key="`${index}_dropdown`"
          toggle-class="nav-link-custom"
        >
          <template #text>
            <span class="d-flex flex-column justify-content-center align-items-center">
              <span><feather-icon :icon="nav.icon" /></span>
              <span> {{ nav.name }} </span>
            </span>
          </template>
          <template v-for="menu,subindex of nav.submenus">
            <b-dropdown-item
              v-if="menu.condition"
              :key="subindex"
              :disabled="isProcessing"
              :class="menu.className"
              @click="menu.action"
            >
              <span>
                <span> <feather-icon :icon="menu.icon" /> </span>
                <span> {{ menu.name }}</span>
              </span>
            </b-dropdown-item>
          </template>
        </b-nav-item-dropdown>

        <b-nav-item
          v-else-if="nav.condition"
          :key="index"
          v-b-modal="nav.hasModal"
          :class="nav.class"
          :disabled="isProcessing"
          @click="nav.action"
        >
          <span class="d-flex flex-column justify-content-center align-items-center">
            <span><feather-icon :icon="nav.icon" /></span>
            <span>{{ nav.name }}</span>
          </span>
        </b-nav-item>
      </template>
    </b-nav>
    <!-- For Mobile View -->
    <b-dropdown
      id="dropdown-1"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="text-center d-lg-none d-xs-block menu-nav w-100"
      toggle-class="text-decoration-none"
      variant="link"
      no-caret
    >
      <template #button-content>
        <b-button
          variant="primary nav-button"
          class="w-100"
        >
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="text-white align-left vertical-icon"
          />
          Menu
        </b-button>
      </template>
      <template v-for="(nav, index) of navMenus">
        <b-dropdown-group
          v-if="nav.condition && nav.submenus && nav.submenus.length"
          :key="`${index}_dropdown`"
          text="Dropdown Button 2"
          variant="primary"
          right
          dropright
        >
          <template #header>
            <span class="text-left">
              <feather-icon :icon="nav.icon" /> {{ nav.name }}
            </span>
          </template>
          <template v-for="menu,subindex of nav.submenus">
            <b-dropdown-item
              v-if="menu.condition"
              :key="subindex"
              :disabled="isProcessing"
              @click="menu.action"
            >
              <feather-icon :icon="menu.icon" />
              {{ menu.name }}
            </b-dropdown-item>
          </template>
          <b-dropdown-divider />
        </b-dropdown-group>
        <b-dropdown-item
          v-else-if="nav.condition"
          :key="index"
          v-b-modal="nav.hasModal"
          :class="nav.class"
          :disabled="isProcessing"
          @click="nav.action"
        >
          <feather-icon :icon="nav.icon" />
          {{ nav.name }}
        </b-dropdown-item>
      </template>
    </b-dropdown>
  </div>
</template>
<script>
import i18n from '@/libs/i18n'
import {
  BNav, BNavItem, BDropdown, BDropdownItem, BButton, BNavItemDropdown, BDropdownGroup, BDropdownDivider,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BNav,
    BNavItem,
    BDropdown,
    BDropdownItem,
    BButton,
    BNavItemDropdown,
    BDropdownGroup,
    BDropdownDivider,
  },
  directives: {
    Ripple,
  },
  props: {
    room: {
      type: [Object, Array],
      default: () => { },
    },
    isHighLevelUser: {
      type: [String, Boolean],
      default: false,
    },
    isATeacher: {
      type: [String, Boolean],
      default: false,
    },
    isAStudent: {
      type: [String, Boolean],
      default: false,
    },
    isProcessing: {
      type: [String, Boolean],
      default: false,
    },
    isAClassConductor: {
      type: [String, Boolean],
      default: false,
    },
    classId: {
      type: [Number, String],
      default: () => { },
    },
    hasClassTest: {
      type: Boolean,
      default: false,
    },
    isSelfStudy: {
      type: Boolean,
      default: false,
    },
    hideClassTestSetting: {
      type: Number,
      default: 0,
    },
    hideViewEssay: {
      type: Number,
      default: 0,
    },
    hideShowScript: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      self: getUserData(),
    }
  },
  computed: {
    navMenus() {
      return [
        {
          name: i18n.tc('class-module.reports'),
          icon: 'BarChart2Icon',
          disabled: !this.room?.id,
          condition: true,
          submenus: [
            {
              name: i18n.tc('class-module.class-report'),
              icon: 'BarChart2Icon',
              action: () => this.$emit('showClassStats'),
              disabled: !this.room?.id,
              condition: true,
            },
            {
              name: i18n.tc('student-diary-module.class-monitor'),
              icon: 'MonitorIcon',
              action: () => {
                this.$router.push({
                  name: 'teacher-monitor-class',
                  params: { classId: this.classId },
                })
              },
              condition: (this.isHighLevelUser || this.isATeacher) && !this.isAStudent,
            },
            {
              name: i18n.tc('class-module.overall-report'),
              icon: 'SidebarIcon',
              action: () => {
                this.$router.push({
                  name: `${this.self.usertype}-higher-level-report`,
                  query: { classId: this.classId },
                })
              },
              condition: (this.isHighLevelUser || this.isATeacher) && !this.isAStudent,
            },
          ],
        },
        {
          name: i18n.tc('class-module.events'),
          icon: 'CalendarIcon',
          action: () => this.$emit('viewClassCalendar'),
          disabled: false,
          condition: true,
        },
        {
          name: i18n.tc('class-module.compose-email'),
          icon: 'MailIcon',
          action: () => this.$emit('showNotification'),
          disabled: false,
          condition: this.isHighLevelUser && !this.isAStudent,
        },
        {
          name: i18n.tc('student-diary-module.rubrics'),
          icon: 'GridIcon',
          action: () => this.$emit('showClassRubrics'),
          condition: this.isATeacher,
        },
        {
          name: i18n.tc('student-diary-module.attendance'),
          icon: 'CalendarIcon',
          action: () => this.$emit('showAttendanceModal'),
          condition: this.isATeacher || this.isHighLevelUser,
        },
        {
          name: i18n.tc('student-diary-module.scoreboard'),
          icon: 'UsersIcon',
          action: () => {
            window.open(`/student-role/${this.classId}`, '_blank', 'toolbar=no,scrollbars=yes,resizable=yes,top=500,left=500,width=625,height=400')
          },
          condition: this.isATeacher || this.isHighLevelUser || this.isAClassConductor,
        },

        {
          name: i18n.tc('student-diary-module.class-test'),
          icon: 'PenToolIcon',
          action: () => {
            this.$router.push({
              name: `${this.self.usertype}-class-test`,
              params: { classId: this.classId },
            })
          },
          class: this.hasClassTest && 'bg-danger',
          condition: this.hideClassTestSetting === 0,
        },
        {
          name: i18n.tc('labels.badges-info'),
          icon: 'InfoIcon',
          class: 'd-lg-none d-md-none',
          condition: this.isATeacher || this.isHighLevelUser || this.isAClassConductor,
          hasModal: 'badges-info',
          action: () => { },
        },
        {
          name: i18n.tc('student-diary-module.view-essay'),
          icon: 'EyeIcon',
          condition: this.hideViewEssay === 0,
          action: () => this.$router.push({ name: 'student-essay', params: { classId: this.classId } }),
        },
        {
          name: i18n.tc('navigations.show-instruction'),
          icon: 'BookOpenIcon',
          condition: this.hideShowScript === 0,
          action: () => this.$emit('showInstruction'),
        },
        {
          name: i18n.tc('navigation-menu.settings'),
          icon: 'SettingsIcon',
          condition: (this.isATeacher || this.isHighLevelUser),
          action: () => this.$emit('showClassSettings'),
        },
        /* {
          name: i18n.tc('labels.engagements'),
          icon: 'ToolIcon',
          condition: this.isATeacher || this.isHighLevelUser,
          action: () => this.$emit('showGameSettingsModal'),
        }, */
      ]
    },

  },
}
</script>

<style scoped>
.nav {
  background: #5f8478;
  padding: 0px;
  margin: 0;
}

.dark-layout .nav {
  background: #354061;
  padding: 0px;
  margin: 0;
}

.nav-item,.b-nav-dropdown {
  font-size: 12px;
  border-right: 1px solid #56756b;
}

.dark-layout .nav-item,.b-nav-dropdown {
    border-right: 1px solid #333848;
}
.nav-link {
  padding: 10px 15px;
}

.nav-item a:hover {
  background: #314841;
  color: white !important;
}

.nav-item, a {
  color: white;
}
.b-nav-dropdown, a {
  color: white;
}
::v-deep a.nav-link.dropdown-toggle {
  color: white !important;
}
::v-deep a.nav-link.dropdown-toggle {
  padding-top: 4px !important;
  display:flex;
  align-items:center;
  padding-right:10px !important;
}
.nav-item.b-nav-dropdown.dropdown {
  margin-top: 4px !important;
}
::v-deep .dropdown-item:hover {
  color: #e19f27 !important;
}

</style>
<style>
#studentTableNab .b-nav-dropdown .dropdown-toggle::after {
  margin-right: 5px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") !important
}
</style>
