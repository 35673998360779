<template>
  <div>
    <b-row>
      <b-col md="2">
        <p class="text-capitalize">
          <strong> {{ $t('help-videos-moduel.list-of-videos') }}</strong>
        </p>
      </b-col>
      <b-col md="9">
        <p class="text-muted"
           style="font-size:13px; font-style: italic;"
        >
          <feather-icon icon="InfoIcon" />
          {{ $t('help-videos-moduel.info') }}
        </p>
      </b-col>
      <b-col sm="1"
             xs="1"
             md="1"
             class="px-0"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="mt-1 mt-md-0 w-100 px-0"
          :disabled="isProcessing"
          @click="toggleCreateVideoModal()"
        >
          <b-spinner
            v-show="isProcessing"
            small
          />
          {{ $t("help-videos-moduel.add-video") }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12 mt-2">
        <b-card>
          <b-overlay
            id="overlay-background"
            :show="isProcessing"
            variant="transparent"
            opacity="0.8"
            blur="0.8"
            rounded="sm"
          >
            <!-- <b-table
              striped
              hover
              responsive
              :fields="columns"
              :items="videoLists"
              :empty-text="$t('messages.no-data-available')"
            >
              <template #cell(is_public)="data">
                <span v-if="data.item.is_public == 1">
                  {{ $t('actions.yes') }}
                </span>
                <span v-else>
                  {{ $t('actions.no') }}
                </span>
              </template>
              <template #cell(access_tag)="data">
                <span v-if="data.item.access_tag.length > 0">
                  <span v-for="(tag , index) in data.item.access_tag"
                        :key="index"
                  >
                    {{ tag.access_tag.toUpperCase() }}
                    <span v-if="data.item.access_tag.length > 1">, </span>
                  </span>
                </span>
                <span v-else>
                  N/A
                </span>
              </template>
              <template #cell(action)="data">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.top="$t('help-videos-moduel.delete')"
                  variant="outline-danger"
                  class="btn-icon"
                  @click="toggleDeleteModal(data.item.id)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.top="$t('help-videos-moduel.edit')"
                  variant="outline-success"
                  class="btn-icon ml-1"
                  @click="toggleEditVideoModal(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.top="$t('help-videos-moduel.view')"
                  variant="outline-primary"
                  class="btn-icon ml-1"
                  @click="toggleViewVideoModal(data.item)"
                >
                  <feather-icon icon="VideoIcon" />
                </b-button>
              </template>
            </b-table> -->

            <b-table-simple
              ref="table"
              role="table"
              class="table b-table course-table"
              responsive
              outlined
              :empty-text="$t('messages.no-data-available')"
            >
              <thead
                role="rowgroup"
              >
                <tr
                  role="row"
                >
                  <th
                    v-for="column,index in columns"
                    :key="index"
                    role="columnheader"
                    scope="col"
                    aria-colindex="1"
                    :class="column.thClass"
                    :style="`width:${column.width ? column.width:'auto'}`"
                  >
                    <div>
                      {{ column.label }}
                    </div>
                  </th>
                </tr>
              </thead>
              <template v-if="videoLists.length > 0">

                <draggable
                  v-model="videoLists"
                  tag="tbody"
                  :options="{animation:300,}"
                  handle=".handle"
                  @change="updateOrder"
                >
                  <tr
                    v-for="(video,index) in videoLists"
                    :key="index"
                    role="row"
                  >
                    <td
                      v-for="column in columns"
                      :key="'row_'+column.label"
                      role="cell"
                    >
                      <span
                        v-if="column.key === '#'"
                        class="d-flex"
                      >
                        <feather-icon
                          icon="AlignJustifyIcon"
                          size="16"
                          class="text-body align-middle m-0 p-0 handle mr-2"
                        />
                      </span>        <!-- Public  -->
                      <span v-if="column.key === 'is_public'">
                        <span v-if="video.is_public == 1">
                          {{ $t('actions.yes') }}
                        </span>
                        <span v-else>
                          {{ $t('actions.no') }}
                        </span>
                      </span>
                      <span v-else-if="column.key === 'access_tag'">
                        <span v-if="video.access_tag.length > 0">
                          <span v-for="(tag , tagIndex) in video.access_tag"
                                :key="tagIndex"
                          >
                            {{ tag.access_tag.toUpperCase() }}
                            <span v-if="video.access_tag.length > 1">, </span>
                          </span>
                        </span>
                        <span v-else>
                          N/A
                        </span>
                      </span>
                      <!-- Actions  -->
                      <span
                        v-else-if="column.key === 'action'"
                        class="d-flex align-items-center"
                      >
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-tooltip.hover.top="$t('help-videos-moduel.delete')"
                          variant="outline-danger"
                          class="btn-icon"
                          @click="toggleDeleteModal(video.id)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>

                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-tooltip.hover.top="$t('help-videos-moduel.edit')"
                          variant="outline-success"
                          class="btn-icon ml-1"
                          @click="toggleEditVideoModal(video)"
                        >
                          <feather-icon icon="EditIcon" />
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-tooltip.hover.top="$t('help-videos-moduel.view')"
                          variant="outline-primary"
                          class="btn-icon ml-1"
                          @click="toggleViewVideoModal(video)"
                        >
                          <feather-icon icon="VideoIcon" />
                        </b-button>
                      </span>
                      <span v-else>
                        {{ video[column.key] }}
                      </span>
                    </td>

                  </tr>
                </draggable>
              </template>
              <template v-else>
                <tr
                  role="row"
                >
                  <td :colspan="columns.length"
                      class="text-center"
                  >{{ $t('messages.no-data-available') }}</td>
                </tr>
              </template>
            </b-table-simple>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>

    <!--Add  Video-->
    <b-modal
      id="modal-add-video"
      ref="modal-add-video"
      ok-only
      size="md"
      ok-variant="primary"
      :ok-title="$t('confirm')"
      hide-footer
      :click-to-close="false"
      :no-close-on-backdrop="true"
      modal-class="modal-primary"
      centered
      :title="getVideoModalTitle"
      no-close-on-esc
    >
      <!--form-->
      <validation-observer
        v-slot="{ invalid }"
        ref="addVideoForm"
      >
        <b-form
          class="auth-login-form"
          @submit.prevent="isEdit ? updateVideo() : addVideo()"
        >
          <b-row>
            <b-col md="12">
              <b-form-group
                :label="$t('help-videos-moduel.title')"
                label-for="video-title"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('help-videos-moduel.title')"
                  rules="required"
                  vid="videoTitle"
                >
                  <b-form-input
                    id="video-title"
                    v-model="newVideo.title"
                    name="title"
                    :state="errors.length>0 ? false : null"
                    :placeholder="$t('help-videos-moduel.video-title')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
              <b-form-group
                :label="$t('help-videos-moduel.link')"
                label-for="video-link"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('help-videos-moduel.link')"
                  rules="required"
                  vid="videoLink"
                >
                  <b-form-input
                    id="video-link"
                    v-model="newVideo.link"
                    name="title"
                    :state="errors.length>0 ? false : null"
                    :placeholder="$t('help-videos-moduel.video-link')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('help-videos-moduel.description')"
                label-for="video-description"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('help-videos-moduel.description')"
                  rules="required"
                  vid="videoDescription"
                >
                  <b-form-textarea
                    id="video-description"
                    v-model="newVideo.description"
                    name="title"
                    :state="errors.length>0 ? false : null"
                    :placeholder="$t('help-videos-moduel.video-description')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('help-videos-moduel.language')"
                label-for="video-language"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Language"
                  rules="required"
                  vid="videoLanguage"
                >
                  <v-select
                    id="video-language"
                    v-model="newVideo.language"
                    name="Language"
                    :options="languageList"
                    :state="errors.length>0 ? false : null"
                    :reduce="(item) => item.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('help-videos-moduel.access_tags')"
                label-for="video-access_tags"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Access Tags"
                  rules="required"
                  vid="access_tags"
                >
                  <v-select
                    id="video-access_tags"
                    v-model="newVideo.access_tags"
                    name="access_tags"
                    multiple
                    :options="accessTags"
                    :state="errors.length>0 ? false : null"
                    :reduce="(item) => item.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="`Order`"
                label-for="video-order"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="`Order`"
                  rules="required"
                  vid="videoOrder"
                >
                  <b-form-input
                    id="video-order"
                    v-model="newVideo.order_index"
                    name="order"
                    :state="errors.length>0 ? false : null"
                    :placeholder="`Order`"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('help-videos-moduel.is-publish')"
                label-for="is_public"
              >
                <b-form-checkbox
                  v-model="newVideo.is_public"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                  :value="1"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                :disabled="isProcessing || invalid"
                @click="isEdit ? updateVideo() : addVideo()"
              >
                <b-spinner
                  v-show="isProcessing"
                  small
                />
                {{ $t('actions.submit') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

      <!--form-->
    </b-modal>
    <!--Add  Video-->

    <!--Delete Video-->
    <b-modal
      id="modal-delete"
      ref="modal-delete"
      ok-only
      ok-variant="danger"
      :ok-title="$t('confirm')"
      modal-class="modal-danger"
      centered
      :title="$t('help-videos-moduel.delete')"
      @ok="deleteVideo"
    >
      <h4> {{ $t('help-videos-moduel.delete_confirm') }} </h4>
      <template #modal-footer="{ok }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              :disabled="isProcessing"
              @click="ok()"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t("confirm") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!--Delete Video-->
    <!-- Show Video -->
    <b-modal
      id="modal-show"
      :visible="showVideoModal"
      ok-only
      size="lg"
      ok-variant="primary"
      modal-class="modal-primary"
      centered
      :title="selectedVideo.title"
      hide-footer
      @close="showVideoModal=false"
    >
      <div>
        <iframe
          width="100%"
          height="450"
          :src="embededUrl"
          frameborder="0"
          allowfullscreen
        />
        <p v-if="selectedVideo.description"
           class="p-1"
        >
          {{ $t('help-videos-moduel.description') }}: {{ selectedVideo.description }}
        </p>
      </div>
    </b-modal>
    <!-- Show Video -->
  </div>

</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  // BTable,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BSpinner,
  BOverlay,
  BCard,
  BTableSimple,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import draggable from 'vuedraggable'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'Index.vue',
  components: {
    BRow,
    BCol,
    BButton,
    // BTable,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BCard,
    draggable,
    BTableSimple,
  },
  data() {
    return {
      newVideo: {},
      isProcessing: false,
      deleteVideoItem: null,
      columns: [
        { key: '#', label: '#' },
        { key: 'title', label: i18n.tc('help-videos-moduel.title') },
        { key: 'language', label: i18n.tc('help-videos-moduel.language') },
        { key: 'is_public', label: i18n.tc('help-videos-moduel.is_public') },
        { key: 'access_tag', label: i18n.tc('help-videos-moduel.access_to') },
        { key: 'action', label: i18n.tc('help-videos-moduel.actions'), thStyle: { width: '200px' } },
      ],
      videoLists: [],
      languageList: [
        { value: null, label: 'Select Language' },
        { value: 'English', label: 'English' },
        { value: 'Spanish', label: 'Español' },
        { value: 'Korean', label: '한국어' },
        { value: 'Japanese', label: '日本語' },
        { value: 'Vietnamese', label: 'Tiếng Việt' },
        { value: 'Hindi', label: 'हिन्दी, हिंदी' },
      ],
      accessTags: [
        { value: null, label: 'Select Language' },
        { value: 'all', label: 'All' },
        { value: 'school', label: 'School' },
        { value: 'teacher', label: 'Teacher' },
        { value: 'student', label: 'Student' },
      ],
      isEdit: false,
      showVideoModal: false,
      videoTitle: null,
      videoLink: null,
      selectedVideo: {},
    }
  },
  computed: {
    getVideoModalTitle() {
      return `${i18n.tc('action')} ${i18n.tc('for')} ${i18n.tc('help-videos-moduel.name')}`
    },
  },
  created() {
    this.getHelpVideosList()
  },
  methods: {
    toggleCreateVideoModal() {
      this.newVideo = {}
      this.isEdit = false
      this.$refs['modal-add-video'].toggle()
    },
    toggleDeleteModal(id) {
      this.deleteVideoItem = id
      this.$refs['modal-delete'].toggle()
    },
    toggleEditVideoModal(item) {
      this.newVideo = { ...item, access_tags: item.access_tag.map(i => i.access_tag) }
      this.$refs['modal-add-video'].toggle()
      this.isEdit = true
    },
    toggleViewVideoModal(item) {
      this.showVideoModal = true
      this.selectedVideo = item
      if (this.selectedVideo.link.includes('youtube.com/watch')) {
        const videoId = this.selectedVideo.link.split('v=')[1]
        this.embededUrl = `https://www.youtube.com/embed/${videoId}?rel=0&modestbranding=1`
      } else {
        this.embededUrl = this.selectedVideo.link
      }
    },
    getHelpVideosList() {
      this.isProcessing = true
      useJwt.getAllHelpVideos()
        .then(res => {
          this.videoLists = res.data.data
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    addVideo() {
      this.$refs.addVideoForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt.addHelpVideo(this.newVideo)
            .then(response => {
              this.$refs['modal-add-video'].hide()
              this.getHelpVideosList()
              this.showSuccessMessage(response)
              this.newVideo = {}
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
    deleteVideo() {
      useJwt.deleteHelpVideo(this.deleteVideoItem)
        .then(response => {
          this.getHelpVideosList()
          this.showSuccessMessage(response)
        })
    },
    updateVideo() {
      this.$refs.addVideoForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt.updateHelpVideo(this.newVideo.id, this.newVideo)
            .then(response => {
              this.newVideo = {}
              this.$refs['modal-add-video'].hide()
              this.getHelpVideosList()
              this.showSuccessMessage(response)
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
    updateOrder() {
      const input = this.videoLists.map((e, i) => ({ id: e.id, order_index: i + 1 }))
      useJwt.updateOrder({
        model: 'help_videos',
        input,
      })
    },
  },

}
</script>

<style scoped>
.handle{
  cursor: grab;
  user-select: none;
}
</style>
