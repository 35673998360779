<template>
  <div>
    <app-collapse accordion>
      <app-collapse-item
        v-for="item in filteredItems"
        :key="item.id"
        :title="item.name"
        :is-visible="!!defaultStandard && !!item.children.some(child => child.id === defaultStandard)"
      >
        <b-list-group>
          <b-list-group-item
            v-for="child in item.children"
            :id="`standard${child.id}`"
            :key="child.id"
            role="button"
            :class="{ 'bg-success text-white': selectedStandard === child.id }"
            @click="handleChange(item, child)"
          >
            {{ child.name }}
          </b-list-group-item>
        </b-list-group>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BListGroup,
    BListGroupItem,
  },
  props: {
    domains: {
      type: Array,
      required: true,
    },
    defaultStandard: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedStandard: this.defaultStandard,
    }
  },
  computed: {
    items() {
      return this.domains.map(domain => ({
        id: domain.id,
        name: domain.name,
        children: domain.standards,
      }))
    },
    filteredItems() {
      return this.items // No filtering since searchQuery is removed
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.selectedStandard) {
        const element = document.querySelector(`#standard${this.selectedStandard}`)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      }
    }, 500)
  },
  methods: {
    handleChange(item, child) {
      this.$emit('change', item, child)
      this.selectedStandard = child.id
    },
  },
}
</script>
