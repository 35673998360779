<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      v-if="maximized"
      style="height: inherit"
    >
      <div
        class="body-content-overlay"
        :class="{
          show:
            shallShowUserProfileSidebar ||
            shallShowActiveChatContactSidebar ||
            mqShallShowLeftSidebar,
        }"
        @click="
          mqShallShowLeftSidebar =
            shallShowActiveChatContactSidebar =
            shallShowUserProfileSidebar =
            false
        "
      />

      <!-- Main Area -->
      <section
        class="chat-app-window"
        style="position: relative"
      >
        <!-- Start Chat Logo -->
        <div
          v-if="!activeChat.contact"
          class="start-chat-area"
        />

        <!-- Chat Content -->
        <div
          v-else
          class="active-chat"
        >
          <!-- Chat Navbar -->
          <div class="chat-navbar">
            <header class="chat-header">
              <div
                class="d-flex justify-content-between w-100 align-items-center"
              >
                <!-- Avatar & Name -->
                <div class="d-flex align-items-center">
                  <!-- Toggle Icon -->
                  <div class="sidebar-toggle d-block d-lg-none mr-1">
                    <feather-icon
                      icon="MenuIcon"
                      class="cursor-pointer"
                      size="21"
                      @click="mqShallShowLeftSidebar = true"
                    />
                  </div>

                  <b-avatar
                    v-if="
                      activeChat.contact.contact_type != 'class' ||
                        !activeChat.contact.users
                    "
                    size="36"
                    :src="activeChat.contact.avatar"
                    class="mr-1 cursor-pointer badge-minimal"
                    badge
                    :badge-variant="isUserOnline ? 'success' : 'secondary'"
                    @click.native="shallShowActiveChatContactSidebar = true"
                  />
                  <group-avatar
                    v-if="
                      activeChat.contact.contact_type == 'class' &&
                        activeChat.contact.users
                    "
                    :contact="activeChat.contact"
                    style="cursor: pointer"
                    @click.native="shallShowActiveChatContactSidebar = true"
                  />
                  <h6 class="mb-0">
                    {{ activeChat.contact.fullName }}
                  </h6>
                </div>
                <div
                  class="call-icon d-flex"
                  style="position: relative"
                >
                  <feather-icon
                    icon="VideoIcon"
                    class="cursor-pointer mr-2 videocall"
                    size="21"
                    @click="startVideoCalling"
                  />
                  <div
                    class="call-tooltip"
                    style="position: absolute; left: 0"
                  >
                    <p>
                      {{ $t('chat-page.video-call') }}
                    </p>
                  </div>
                </div>
              </div>

              <!-- Contact Actions -->
              <div class="d-flex align-items-center">
                <div
                  class="acall-icon"
                  style="position: relative"
                >
                  <feather-icon
                    icon="PhoneIcon"
                    class="cursor-pointer mr-2"
                    size="21"
                    @click="startAudioCalling"
                  />
                  <div
                    class="audiocall-tooltip"
                    style="position: absolute; left: 0"
                  >
                    <p>
                      {{ $t('chat-page.audio-call') }}
                    </p>
                  </div>
                </div>
                <feather-icon
                  icon="ArrowDownRightIcon"
                  style="cursor: pointer"
                  size="17"
                  @click="setMinimized"
                />
              </div>
            </header>
          </div>

          <!-- User Chat Area -->
          <vue-perfect-scrollbar
            ref="refChatLogPS"
            :settings="perfectScrollbarSettings"
            class="user-chats scroll-area"
            @ps-scroll-y="loadMore"
          >
            <chat-log
              :chat-data="activeChat"
              :chats="messages"
              :profile-user-avatar="profileUserDataMinimal.avatar"
              :profile-user-id="profileUserDataMinimal.id"
              @fetchMessage="getChatMessage"
            />
            <div class="row">
              <!-- Message Input -->
              <div v-for="sChat,index of snapShotImages"
                   :key="index"
                   class="col-2"
              >
                <span style="position: absolute;right: 0;margin-right: -10px;margin-top: -20px;zoom: 1.5;"
                      @click="snapImage(index)"
                >
                  <b-button
                    variant="flat-danger"
                    class="btn-icon"
                  >
                    <feather-icon icon="XCircleIcon" />
                  </b-button>
                </span>
                <img :src="sChat"
                     style="height: 100%; width: 100%;"
                >
              </div>
            </div>
          </vue-perfect-scrollbar>

          <b-form
            class="chat-app-form"
            @submit.prevent="sendMessage"
          >
            <div class="d-flex w-100">
              <file-upload @fileInput="setFile" />
              <b-input-group class="input-group-merge form-send-message mr-1">
                <ChatBoxInput
                  v-model="chatInputMessage"
                  @updateSnap="updateSnapShot"
                  @sendMessage="sendMessage(); sendSnapChat()"
                  @onTyping="onTyping"
                />
              </b-input-group>
              <b-button
                variant="primary"
                type="submit"
              >
                {{ $t("send-notification-module.send") }}
              </b-button>
            </div>
            <div>

              <Recorder
                ref="recordComponent"
                class="ml-lg-2"
                :url="''"
                @input="setRecording"
                @recordingNow="setRecordingNow"
                @emoji="insertEmoji"
                @sticker="sendSticker"
              />
            </div>
          </b-form>

        </div>
      </section>

      <!-- Active Chat Contact Details Sidebar -->
      <chat-active-chat-content-details-sidedbar
        :shall-show-active-chat-contact-sidebar.sync="
          shallShowActiveChatContactSidebar
        "
        :contact="activeChat.contact || {}"
      />

      <!-- Sidebar -->
      <portal to="content-renderer-sidebar-left">
        <chat-left-sidebar
          :chats-contacts="chatsContacts"
          :contacts="contacts"
          :classes="classes"
          :empty-chats="chatsContacts.length === 0"
          :empty-classes="classes.length === 0"
          :active-chat-contact-id="
            activeChat.contact ? activeChat.contact.id : null
          "
          :teacher-chats="teacherChats"
          :active-chat-type="activeChat.type"
          :active-chat-model="activeChat.model"
          :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
          :profile-user-data="profileUserData"
          :profile-user-minimal-data="profileUserDataMinimal"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
          @open-chat="openChatOfContact"
          @click.native="checkIfUserOnline"
        />
      </portal>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { ref, nextTick, getCurrentInstance } from 'vue'
import {
  BAvatar,
  BForm,
  BInputGroup,
  BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { getUserToken } from '@/auth/utils'
import Echo from 'laravel-echo'
import { VaniEvent, MessagePayload } from 'vani-meeting-client'
// import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import GEC_EVENT_BUS from '@/utils/eventBus'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'
import useChat from './useChat'
import FileUpload from '../components/FileUpload.vue'
import Recorder from '../components/recorder.vue'
import GroupAvatar from '../components/GroupAvatar.vue'
import ChatHandler from './ChatHandler'
import VideoHandler from './VideoHandler'
import ChatBoxInput from './ChatBoxInput.vue'

export default {
  components: {
    // BSV
    BAvatar,
    BForm,
    BInputGroup,
    BButton,
    GroupAvatar,
    ChatBoxInput,
    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
    Recorder,
    FileUpload,
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat()
    const currentInstance = getCurrentInstance()
    const root = currentInstance.proxy.$root
    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      if (refChatLogPS.value) {
        const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
        scrollEl.scrollTop = scrollEl.scrollHeight

        setTimeout(() => {
          if (scrollEl.scrollTop !== scrollEl.scrollHeight) scrollEl.scrollTop = scrollEl.scrollHeight
        }, 500)
      }
    }

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const chatsContacts = ref([])
    const groupChatsContacts = ref([])
    const contacts = ref([])
    const classes = ref([])
    const teacherChats = ref([])

    // Recording
    let recordingNow = false
    let audio = ''
    let audioUrl = ''
    let messageType = 'text'
    let sticker = ''
    let stickerCode = ''
    const recordComponent = ref()
    const floatingComponent = ref()
    let typingTimeout = null

    const fetchChatAndContacts = () => {
      store.dispatch('app-chat/fetchChatsAndContacts').then(response => {
        chatsContacts.value = response.data.chatsContacts
        groupChatsContacts.value = response.data.groupChatsContacts
        contacts.value = response.data.contacts
        teacherChats.value = response.data.teacherChats
        // eslint-disable-next-line no-use-before-define
        profileUserDataMinimal.value = response.data.profileUser
        classes.value = response.data.classes
      })
    }

    fetchChatAndContacts()

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({})
    const { activeChatId, activeChatType } = store.state['app-chat'].chatData
    activeChat.value = store.state['app-chat'].activeChat || {}
    const chatInputMessage = ref('')
    const profileUserData = ref({})
    let typingTime = null
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({})
    const openChatOfContact = ({ userId, type, model }) => {
      // Reset send message input value
      store.commit('app-chat/SET_ACTIVE_CHAT_ID', { id: userId, type })
      store.commit('app-chat/SET_CHAT_TYPE', type)
      store.commit('app-chat/SET_MODEL', model)
      if (sticker === '') chatInputMessage.value = ''
      else sticker = ''
      store.dispatch('app-chat/getChat', { userId, type }).then(response => {
        activeChat.value = response.data
        activeChat.value.model = model
        if (type === 'group') {
          activeChat.value.type = 'group'
          localStorage.setItem(
            'chatData',
            JSON.stringify({
              activeChatId: response.data.contact?.id,
              activeChatType: 'group',
              activeChatModel: model,
            }),
          )
          store.commit('app-chat/FETCH_CHAT_CONTACTS', {
            activeChat: response.data,
            profileUserDataMinimal: profileUserDataMinimal.value,
            chatsContacts: null,
          })
          // Set unseenMsgs to 0
          // contact.chat.unseenMsgs = 0
        } else {
          activeChat.value.type = 'single'
          localStorage.setItem(
            'chatData',
            JSON.stringify({
              activeChatId: response.data.contact?.id,
              type: 'single',
              activeChatModel: model,
            }),
          )
          store.commit('app-chat/FETCH_CHAT_CONTACTS', {
            activeChat: response.data,
            profileUserDataMinimal: profileUserDataMinimal.value,
            chatsContacts: chatsContacts.value,
          })
          fetchChatAndContacts()
          // Set unseenMsgs to 0
          const contact = chatsContacts.value.find(c => c.id === userId)
          if (contact) contact.chat.unseenMsgs = 0

          // Scroll to bottom
          setTimeout(() => {
            scrollToBottomInChatLog()
          }, 700)
        }
      })
      store.dispatch('app-chat/fetchTotalUnseen')

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    }

    const listenForEvents = () => {
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_WEBSOCKETS_KEY,
        forceTLS: false,
        wsHost: process.env.VUE_APP_WEBSOCKETS_HOST,
        wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
        disableStats: true,
        authEndpoint: process.env.VUE_APP_WEBSOCKETS_AUTH_ENDPOINT,
        auth: {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
            Accept: 'Application/json',
          },
        },
      })

      window.Echo.channel('group-chat').listen('GroupChatEvent', () => {
        const currentRoute = root.$route.name
        if (activeChat && activeChat.value.contact && currentRoute === 'chat') {
          openChatOfContact({
            userId: activeChat.value.contact.id,
            type: 'group',
            model: store.state['app-chat'].model,
          })
        }
      })

      window.Echo.channel(
        `message-typing${JSON.parse(localStorage.getItem('userData')).id}`,
      ).listen('MessageTypingEvent', res => {
        if (
          parseInt(res.sender.id, 10)
          === parseInt(store.state['app-chat']?.chatData?.activeChatId, 10)
        ) {
          store.commit('app-chat/SET_MESSAGE_TYPING', true)
          nextTick(() => {
            scrollToBottomInChatLog()
          })
          if (typingTime) clearTimeout(typingTime)
          typingTime = setTimeout(() => {
            store.commit('app-chat/SET_MESSAGE_TYPING', false)
          }, 3000)
        }
      })

      window.Echo.channel(
        `message-typing${store.state['app-chat'].chatData?.activeChatId}`,
      ).listen('MessageTypingEvent', res => {
        if (
          parseInt(res.receiver.id, 10)
          === parseInt(store.state['app-chat'].chatData?.activeChatId, 10)
          && res.sender.id !== JSON.parse(localStorage.getItem('userData')).id
        ) {
          store.commit('app-chat/SET_MESSAGE_TYPING', true)
          nextTick(() => {
            scrollToBottomInChatLog()
          })
          if (typingTime) clearTimeout(typingTime)
          typingTime = setTimeout(() => {
            store.commit('app-chat/SET_MESSAGE_TYPING', false)
          }, 3000)
        }
      })
    }
    const sendSnapChat = () => {
      currentInstance.proxy.snapShotImages.forEach(snap => {
        const file = currentInstance.proxy.covertBase64ToFile(snap, 'image/png')
        // eslint-disable-next-line no-use-before-define
        setFile({ file })
      })
      currentInstance.proxy.snapShotImages = []
    }
    const sendMessage = e => {
      if (e?.shiftKey) return
      const currentRoute = root.$route.name
      if (!chatInputMessage.value && !recordingNow && !sticker && currentRoute !== 'chat') return
      let payload
      if (store.state['app-chat'].chatType === 'group') {
        payload = {
          contactId: activeChat.value.contact.id,
          // eslint-disable-next-line no-use-before-define
          senderId: profileUserDataMinimal.value.id,
          message: sticker !== '' ? sticker : chatInputMessage.value,
          model: store.state['app-chat'].model,
          audio,
          audioUrl,
          type: audio !== '' || sticker !== '' ? messageType : 'text',
          code: sticker !== '' ? stickerCode : '',
        }
      } else {
        payload = {
          contactId: activeChat.value.contact.id,
          // eslint-disable-next-line no-use-before-define
          senderId: profileUserDataMinimal.value.id,
          message: sticker !== '' ? sticker : chatInputMessage.value,
          audio,
          audioUrl,
          type: audio !== '' || sticker !== '' ? messageType : 'text',
          code: sticker !== '' ? stickerCode : '',
        }
      }

      store.dispatch('app-chat/sendMessage', payload).then(() => {
        if (recordingNow) {
          recordingNow = !recordingNow
        }
        recordComponent.value.setRecorded(false)
        audio = ''
        audioUrl = ''
        sticker = ''
        stickerCode = ''
        messageType = 'text'
        // Scroll to bottom
        nextTick(() => {
          scrollToBottomInChatLog()
        })
      })
    }

    const handleNewMessage = msg => {
      setTimeout(() => {
        let payload
        if (store.state['app-chat'].chatType === 'group') {
          payload = {
            contactId: activeChat.value.contact.id,
            // eslint-disable-next-line no-use-before-define
            senderId: profileUserDataMinimal.value.id,
            message: chatInputMessage.value,
            model: store.state['app-chat'].model,
            audio,
            audioUrl,
          }
        } else {
          payload = {
            contactId: activeChat.value.contact.id,
            // eslint-disable-next-line no-use-before-define
            senderId: profileUserDataMinimal.value.id,
            message: msg.message,
            audio,
            audioUrl,
            type: 'single',
          }
        }

        store.dispatch('app-chat/sendMessage', payload).then(() => {
          if (recordingNow) {
            recordingNow = !recordingNow
          }
          recordComponent.value.setRecorded(false)
          audio = ''
          audioUrl = ''
          nextTick(() => {
            scrollToBottomInChatLog()
          })
        })
      }, 500)
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
      // scrollYMarginOffset: 20,
    }

    const shallShowUserProfileSidebar = ref(false)

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }
    const setFile = file => {
      audio = file.file
      audioUrl = file.fileURL
      recordingNow = true
      messageType = 'file'
      sendMessage()
    }
    // sends sticker to the chat
    const sendSticker = data => {
      sticker = data.icon
      stickerCode = data.code
      messageType = 'sticker'
      sendMessage()
    }
    const setRecording = ad => {
      audio = ad.audio
      audioUrl = ad.url
      messageType = 'audio'
    }
    const setRecordingNow = () => {
      recordingNow = !recordingNow
    }

    const onTyping = () => {
      if (typingTimeout) clearTimeout(typingTimeout)
      typingTimeout = setTimeout(() => {
        store.dispatch('app-chat/onTyping', activeChat.value.contact.id)
      }, 500)
    }
    GEC_EVENT_BUS.$on('pushNewlySentChatMessage', ({ message }) => {
      activeChat.value.chat.push(message)
      chatInputMessage.value = ''
    })

    return {
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,
      groupChatsContacts,
      contacts,
      classes,

      // Single Chat
      refChatLogPS,
      activeChat,
      activeChatId,
      activeChatType,
      chatInputMessage,
      openChatOfContact,
      sendMessage,
      handleNewMessage,
      sendSnapChat,
      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,

      // recording
      audio,
      audioUrl,
      setRecording,
      setRecordingNow,
      setFile,
      recordingNow,
      recordComponent,
      sendSticker,

      // Floating Chat
      floatingComponent,

      //
      listenForEvents,

      onTyping,
      typingTime,
      typingTimeout,
      fetchChatAndContacts,
      teacherChats,
    }
  },
  data() {
    return {
      messages: [],
      currentPage: 0,
      nextPageUrl: null,
      snapShotImages: [],
    }
  },
  computed: {
    maximized() {
      this.$nextTick(() => {
        this.psToBottom()
      })
      return store.state['app-chat'].maximized
    },
    activeGroupId() {
      return store.state['app-chat'].chatData?.activeChatId
    },
    isUserOnline() {
      return this.activeChat?.contact?.status === 'online'
    },
  },
  watch: {
    activeGroupId() {
      this.openChatOfContact({
        userId: store.state['app-chat'].chatData?.activeChatId,
        type: store.state['app-chat'].chatData?.activeClassType || store.state['app-chat'].activeChatType,
        model: store.state['app-chat'].model,
      })
      this.listenForEvents()
    },
  },
  beforeCreate() {
    useJwt.getSchoolAndDistrictSettings().then(response => {
      const { school, district } = response.data.data
      const schoolSetting = school?.find(metaValue => metaValue.key === 'nav_chat') ?? '0'
      const districtSetting = district?.find(metaValue => metaValue.key === 'nav_chat') ?? '0'
      if (districtSetting?.value === '1' || schoolSetting?.value === '1') {
        this.$router.push('not-authorized')
      }
    })
  },
  mounted() {
    this.startConversation()
    this.listenForEvents()
    const { activeChatId, activeChatType, activeChatModal } = store.state['app-chat'].chatData
    if (activeChatId && activeChatType) {
      this.openChatOfContact({
        userId: activeChatId,
        type: activeChatType,
        model: activeChatModal,
      })
    }
  },

  methods: {
    snapImage(index) {
      this.snapShotImages.splice(index, 1)
    },
    updateSnapShot(img) {
      this.snapShotImages.push(img)
    },
    getChatMessage() {
      this.openChatOfContact({
        userId: store.state['app-chat'].chatData?.activeChatId,
        type: store.state['app-chat'].chatData?.activeClassType,
        model: store.state['app-chat'].model,
      })
    },
    loadMore() {
      if (this.$refs.refChatLogPS.ps.reach.y === 'start' && this.activeChat.nextPage) {
        const userId = this.activeChat.contact.id
        const type = this.activeChat
        store.dispatch('app-chat/getChat', { userId, type, page: this.activeChat.nextPage }).then(response => {
          this.activeChat = {
            ...this.activeChat,
            chat: [...response.data.chat, ...this.activeChat.chat],
            nextPage: response.data.nextPage,
          }
          this.$el.querySelector('.ps-container').scrollTop = 900
        })
      }
    },
    mounted() {
      ChatHandler.getInstance()
        .getMeetingHandler()
        .getEventEmitter()
        .on(VaniEvent.OnAllParticipants, this.onParticipantDataUpdated)
      // ChatHandler.getInstance()
      //   .getMeetingHandler()
      //   .getEventEmitter()
      //   .on(VaniEvent.OnUserJoined, this.onUserJoined)
      // ChatHandler.getInstance()
      //   .getMeetingHandler()
      //   .getEventEmitter()
      //   .on(VaniEvent.OnUserLeft, this.onUserLeft)
      this.checkIfUserOnline()
    },
    unmounted() {
      ChatHandler.getInstance()
        .getMeetingHandler()
        .getEventEmitter()
        .off(VaniEvent.OnAllParticipants, this.onParticipantDataUpdated)
      // ChatHandler.getInstance()
      //   .getMeetingHandler()
      //   .getEventEmitter()
      //   .off(VaniEvent.OnUserJoined, this.onUserJoined)
      // ChatHandler.getInstance()
      //   .getMeetingHandler()
      //   .getEventEmitter()
      //   .off(VaniEvent.OnUserLeft, this.onUserLeft)
      ChatHandler.getInstance()
        .getMeetingHandler()
        .getEventEmitter()
        .off(VaniEvent.OnNewChatMessageReceived, this.onNewChatMessageReceived)
    },
    checkIfUserOnline() {
      // console.log(this.activeChat?.contact)
      // this.isUserOnline = this.activeChat?.contact?.status === 'online'
    },
    onParticipantDataUpdated() {
      this.checkIfUserOnline()
    },
    onUserJoined(participant) {
      if (
        this.activeChat
        && this.activeChat.contact
        && this.activeChat.contact.id
        && participant.userData.id === this.activeChat.contact.id
      ) {
        // this.isUserOnline = true
      }
    },
    onUserLeft(participant) {
      if (
        this.activeChat
        && this.activeChat.contact
        && this.activeChat.contact.id
        && participant.userData.id === this.activeChat.contact.id
      ) {
        // this.isUserOnline = false
      }
    },
    startCall(callType) {
      VideoHandler.getInstance().callMembers = []
      if (this.activeChat.type === 'group') {
        let callingClass
        if (this.activeChat.model === 'network') {
          callingClass = this.contacts.find(
            eachClass => eachClass.id === this.activeChat.contact.id,
          )
        } else {
          callingClass = this.classes.find(
            eachClass => eachClass.id === this.activeChat.contact.id,
          )
        }
        if (callingClass) {
          const chatPayloadExtraData = {
            time: new Date().getTime(),
            type: 'Group',
            roomId: `${this.activeChat.contact.id}`,
            callerName: this.activeChat.contact.fullName,
            callType,
          }
          const videoCallMembers = []
          callingClass.students.forEach(contact => {
            const participants = ChatHandler.getInstance().getUserByUserId(
              contact.id,
            )
            if (participants) {
              participants.forEach(participant => {
                if (
                  participant.userId
                  !== ChatHandler.getInstance().getMeetingRequest().userId
                ) {
                  const chatPayload = new MessagePayload(
                    'VideoCall',
                    participant.userId,
                  )
                  chatPayload.extraData = chatPayloadExtraData

                  chatPayload.type = 'VideoCall'
                  ChatHandler.getInstance().sendMessage(chatPayload)
                }
              })
              if (participants.length > 0) {
                videoCallMembers.push(contact.userId)
              }
            }
          })
          if (videoCallMembers.length > 0) {
            VideoHandler.getInstance().setup(
              chatPayloadExtraData.roomId,
              ChatHandler.getInstance().getMeetingRequest().userData,
              true,
            )
            VideoHandler.getInstance().callMembers = videoCallMembers
            VideoHandler.getInstance().chatPayloadExtraData = chatPayloadExtraData

            if (callType === 'video') {
              ChatHandler.getInstance().eventEmitter.emit('ShowAudioCall', {})
            } else {
              ChatHandler.getInstance().eventEmitter.emit('ShowAudioCall', {})

              // ChatHandler.getInstance().
            }
          } else {
            this.showError('User is not online')
          }
        } else {
          this.showError('No participant found')
        }
      } else {
        const participants = ChatHandler.getInstance().getUserByUserId(
          this.activeChat.contact.id,
        )
        if (participants) {
          let name
          if (ChatHandler.getInstance().getMeetingRequest().userData.fullname) {
            name = ChatHandler.getInstance().getMeetingRequest().userData.fullname
          } else {
            name = ChatHandler.getInstance().getMeetingRequest().userData.firstname
              + ChatHandler.getInstance().getMeetingRequest().userData.lastname
          }
          const extraData = {
            time: new Date().getTime(),
            type: 'OneOnOne',
            roomId: `${this.activeChat.contact.id}`,
            callerName: name,
            callType,
          }
          participants.forEach(participant => {
            const chatPayload = new MessagePayload(
              'VideoCall',
              participant.userId,
            )
            chatPayload.extraData = extraData
            chatPayload.type = 'VideoCall'
            ChatHandler.getInstance().sendMessage(chatPayload)
          })
          if (participants.length > 0) {
            VideoHandler.getInstance().callMembers.push(
              this.activeChat.contact.id,
            )
          }
          VideoHandler.getInstance().setup(
            extraData.roomId,
            ChatHandler.getInstance().getMeetingRequest().userData,
            true,
          )
          VideoHandler.getInstance().chatPayloadExtraData = extraData

          VideoHandler.getInstance().isVideoCall = (callType === 'video')
          if (callType === 'video') {
            ChatHandler.getInstance().eventEmitter.emit('ShowAudioCall', this.activeChat.contact)
          } else {
            ChatHandler.getInstance().eventEmitter.emit('ShowAudioCall', this.activeChat.contact)

            // ChatHandler.getInstance().
          }
        } else {
          this.showError('User is not online')
        }
      }
    },
    startAudioCalling() {
      this.startCall('audio')
    },
    startVideoCalling() {
      this.startCall('video')
    },
    setMinimized() {
      store.commit('app-chat/SET_MINIMIZED', true)
    },
    setMaximized() {
      this.openChatOfContact(this.activeChat.contact.id)
    },
    psToBottom() {
      if (this.$refs.refChatLogPS) {
        const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS
        scrollEl.scrollTop = scrollEl.scrollHeight
      }
    },
    // inserts emoji to the textbox
    insertEmoji(emoji) {
      this.chatInputMessage += emoji
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-chat.scss";
@import "@core/scss/base/pages/app-chat-list.scss";
.call-icon:hover .call-tooltip {
  visibility: visible;
}
.acall-icon:hover .audiocall-tooltip {
  visibility: visible;
}

.call-tooltip,
.audiocall-tooltip {
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  background-color: #000;
  padding: 4px 8px;
  z-index: 1000000;
  left: -255px !important;
  top: 0;
  border-radius: 8px;
  width: 250px;
}

.call-tooltip p {
  margin: 0;
  color: #fff;
  text-align: center;
}
.audiocall-tooltip p {
  margin: 0;
  color: #fff;
  text-align: center;
}
</style>
