<template>
  <div>
    <b-row>
      <b-col md="3">
        <b-row>
          <b-col>
            <p class="text-capitalize">
              <strong> {{ $t('third-party-module.third-party-provider') }}</strong>
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="9">
        <b-row>
          <b-col>
            <p class="text-muted"
               style="font-size:13px; font-style: italic;"
            >
              <feather-icon icon="InfoIcon" />
              {{ $t('third-party-module.info') }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-overlay
            id="overlay-background"
            :show="isProcessing"
            variant="transparent"
            opacity="0.8"
            blur="0.8"
            rounded="sm"
          >
            <b-table
              striped
              hover
              responsive
              :fields="columns"
              :items="providerList"
            >
              <template #cell(action)="data">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.top="$t('help-videos-moduel.edit')"
                  variant="outline-primary"
                  class="btn-icon btn-success ml-1"
                  @click="$router.push({ name: `super-thirdparty-accounts-user-list`,params: {provider: data.item.provider } })"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </template>
            </b-table>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BOverlay,
  BTable,
  BButton,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'Index.vue',
  components: {
    BRow,
    BCol,
    BCard,
    BOverlay,
    BTable,
    BButton,
  },
  data() {
    return {
      isProcessing: false,
      columns: [
        { key: 'provider', label: i18n.tc('third-party-module.provider') },
        { key: 'total_users', label: i18n.tc('third-party-module.total-user') },
        { key: 'action', label: i18n.tc('help-videos-moduel.actions'), thStyle: { width: '150px' } },
      ],
      providerList: [],
    }
  },
  created() {
    this.getThirdParties()
  },
  methods: {
    getThirdParties() {
      this.isProcessing = true
      useJwt.getAllThirdParties()
        .then(res => {
          this.providerList = res.data.data
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
  },
}
</script>

<style scoped>

</style>
