<template>
  <b-container class="mt-2">
    <b-card>
      <b-row>
        <b-col md="12">
          <b-row>
            <b-col md="8">
              <h2>{{ vocabData.vocab_word }}

                <b-button
                  variant="flat-primary"
                  class="btn-icon"
                  @click="toggleAudio(vocabData.audio)"
                >
                  <feather-icon :icon="isPlaying(vocabData.audio )? 'PauseCircleIcon' : 'PlayCircleIcon'"
                                size="20"
                  />
                </b-button>
              </h2>
              <hr>
              <h4>Synonyms</h4>
              <ul>
                <li v-for="synonym in vocabData.synonyms"
                    :key="synonym.text"
                >
                  {{ synonym.text }}
                  <b-button size="sm"
                            variant="flat-primary"
                            class="btn-icon"
                            @click="toggleAudio(synonym.audio)"
                  >
                    <feather-icon :icon="isPlaying(synonym.audio )? 'PauseCircleIcon' : 'PlayCircleIcon'" />
                  </b-button>
                </li>
              </ul>
            </b-col>
            <b-col md="4">
              <b-img v-image-preview
                     :src="vocabData.image"
                     fluid
                     alt="Vocab Image"
              />
            </b-col>
          </b-row>
          <hr>
          <h4>
            Description
            <b-button variant="flat-primary"
                      class="btn-icon"
                      @click="toggleAudio(vocabData.description_json.audio)"
            >
              <feather-icon :icon="isPlaying(vocabData.description_json.audio )? 'PauseCircleIcon' : 'PlayCircleIcon'" />
            </b-button>
          </h4>
          <p>{{ vocabData.description_json.text }}</p>
          <hr>
          <h4>Example Sentence      <b-button variant="flat-primary"
                                              class="btn-icon"
                                              @click="toggleAudio(vocabData.sentence_json.audio)"
          >
            <feather-icon :icon="isPlaying(vocabData.sentence_json.audio )? 'PauseCircleIcon' : 'PlayCircleIcon'" />
          </b-button></h4>
          <p>{{ vocabData.sentence_json.text }}</p>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import {
  BContainer, BCard, BRow, BCol, BImg, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BCard,
    BRow,
    BCol,
    BImg,
    BButton,
  },
  props: {
    vocabData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentAudio: null,
      playingAudioUrl: null,
    //   vocabData: {
    //     vocab_word: 'carl johnson',
    //     audio: 'http://gec.test/storage/word-net-store/_oA102Kn6yA0nhRKUP9PG7Fx1ARcWDZY1JGp2g5BD.mpeg',
    //     image: 'http://gec.test/storage/ai-generated/66addfec7236c1722671084.jpg',
    //     synonyms: [
    //       { text: 'CJ', audio: 'http://gec.test/storage/word-net-store/_QqGM52OduWxDeoec0iNaCeVPakG1X07nfwpo0L0p.mpeg' },
    //       { text: 'GTA protagonist', audio: 'http://gec.test/storage/word-net-store/_yr7waLzvN7ThBFSCxDxhxWoSY4rjtvAG5JyjmIoL.mpeg' },
    //     ],
    //     description_json: {
    //       text: 'main character in the video game Grand Theft Auto: San Andreas',
    //       audio: 'http://gec.test/storage/word-net-store/_BzVcD8ROjpJVlboGZXQCboP5agFsRlUdQKpnq6py.mpeg',
    //     },
    //     sentence_json: {
    //       text: "Carl Johnson is on a mission to uncover the truth about his mother's murder.",
    //       audio: 'http://gec.test/storage/word-net-store/_ioYS9kFJIYlb1SlcYhwXhKdTAK3rXAlBducD1Xmj.mpeg',
    //     },
    //   },
    }
  },
  methods: {
    toggleAudio(audioUrl) {
      if (this.currentAudio && this.playingAudioUrl === audioUrl) {
        if (this.currentAudio.paused) {
          this.currentAudio.play()
        } else {
          this.currentAudio.pause()
        }
      } else {
        if (this.currentAudio) {
          this.currentAudio.pause()
        }
        this.currentAudio = new Audio(audioUrl)
        this.currentAudio.play()
        this.currentAudio.onended = () => {
          this.playingAudioUrl = null
        }
      }
      this.playingAudioUrl = this.currentAudio.paused ? null : audioUrl
    },
    isPlaying(audioUrl) {
      return this.playingAudioUrl === audioUrl
    },
  },
}
</script>

  <style>
  .container {
    max-width: 800px;
  }
  </style>
