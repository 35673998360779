<template>
  <b-modal
    id="modal-no-animation"
    ref="my-modal"
    content-class="shadow"
    :title="form.id ? $t('points-setting-module.edit-reward') : $t('points-setting-module.create-vibe')"
    no-fade
    no-close-on-backdrop
    ok-title="Accept"
    footer-class="points-settings-custome-footer"
  >
    <validation-observer ref="simpleRules">
      <b-form>
        <!-- Name  -->
        <b-form-group
          :label="$t('points-setting-module.name')"
        >
          <validation-provider
            v-slot="{ errors }"
            name="name"
            rules="required"
          >
            <b-form-input
              v-model="form.name"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('points-setting-module.name')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Value  -->
        <b-form-group :label="$t('points-setting-module.value')">
          <validation-provider
            v-slot="{ errors }"
            name="value"
            rules="required"
          >
            <b-form-input
              v-model="form.value"
              type="number"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('points-setting-module.value')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Code  -->
        <b-form-group :label="$t('points-setting-module.code')">
          <validation-provider
            v-slot="{ errors }"
            name="code"
            rules="required"
          >
            <b-form-input
              v-model="form.code"
              type="text"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('points-setting-module.code')"
              :disabled="form.id ? true : false"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Category  -->
        <b-form-group :label="$t('points-setting-module.category')">
          <validation-provider
            v-slot="{ errors }"
            name="category"
            rules="required"
          >
            <b-form-input
              v-model="form.category"
              list="categories"
              type="text"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('points-setting-module.category')"
            />
            <datalist id="categories">
              <option
                v-for="category in categories"
                :key="category"
                :value="category"
              />
            </datalist>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Price  -->
        <b-form-group :label="$t('points-setting-module.price')">
          <validation-provider
            v-slot="{ errors }"
            name="price"
            rules="required"
          >
            <b-form-input
              v-model="form.price"
              type="number"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('points-setting-module.price')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Limit -->
        <b-form-group :label="$t('points-setting-module.limit')">
          <validation-provider
            v-slot="{ errors }"
            name="limit"
          >
            <b-form-input
              v-model="form.limit"
              type="number"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('points-setting-module.limit')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Status -->
        <b-form-group :label="$t('points-setting-module.status')">
          <validation-provider
            v-slot="{ errors }"
            name="status"
          >
            <b-form-select
              v-model="form.status"
              :options="statusOptions"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Description  -->
        <b-form-group :label="$t('points-setting-module.description')">
          <validation-provider
            v-slot="{ errors }"
            name="description"
          >
            <b-form-textarea
              v-model="form.description"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('points-setting-module.description')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Checkboxes  -->
        <div class="d-flex justify-content-between">
          <!-- is Free  -->
          <b-form-group :label="$t('points-setting-module.free')">
            <b-form-checkbox
              v-model="form.is_free"
              value="1"
              unchecked-value="0"
              switch
            />
          </b-form-group>

          <!-- is Convertable  -->
          <b-form-group :label="$t('points-setting-module.convertable')">
            <b-form-checkbox
              v-model="form.is_convertable"
              value="1"
              unchecked-value="0"
              switch
            />
          </b-form-group>

          <!-- is Transferable  -->
          <b-form-group :label="$t('points-setting-module.transferable')">
            <b-form-checkbox
              v-model="form.is_transferable"
              value="1"
              unchecked-value="0"
              switch
            />
          </b-form-group>
        </div>

        <!-- Icon -->
        <b-form-group
          :label="$t('points-setting-module.icon')"
          class="icon-wrap"
        >
          <validation-provider
            v-slot="{ errors }"
            name="icon"
            :rules="{'required': !form.id}"
          >
            <!-- Icon preview  -->
            <label for="icon">
              <div class="icon-preview d-flex justify-content-center align-items-center">
                <!-- Show the uploaded Icon if form.icon is not empty  -->
                <div v-if="form.icon">
                  <!-- if the value is string, show the icon directly  -->
                  <img
                    v-if="typeof(form.icon) === 'string'"
                    :src="form.icon"
                  >
                  <!-- if not, get icon preview url  -->
                  <img
                    v-else
                    :src="setIconPreview(form.icon)"
                  >
                </div>

                <!-- Show default icon if form.icon is empty and it is update modal -->
                <div v-else-if="form.id && getDefaultImage(form)">
                  <img
                    :src="getDefaultImage(form)"
                    alt=""
                  >
                </div>

                <!-- Show upload icon if form.icon is empty and it is create modal -->
                <div v-else>
                  <featherIcon
                    icon="UploadIcon"
                    size="38"
                    class="icon"
                  />
                </div>
              </div>
            </label>
            <b-form-file
              id="icon"
              v-model="form.icon"
              name="icon"
              accept="image/*"
              class="hidden"
              :browse-text="$t('browseButton')"
              :placeholder="$t('issue-report-module.choose-a-file')"
              @change="onIconChange"
            />
            <small class="icon-error text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
    <template #modal-footer="{}">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="primary"
            :disabled="isProcessing"
            @click="handleOk"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ form.id ? $t('points-setting-module.update') : $t('points-setting-module.save') }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal, VBModal, BForm, BFormGroup, BFormInput, BSpinner, BButton,
  BRow, BCol, BFormTextarea, BFormCheckbox, BFormFile, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
import { mapGetters } from 'vuex'
import {
  HIGHEST_POINTS_GAINER_BADGE, FIRST_LESSON_ACHEIVER_BADGE, EXTRAORDINARY_BADGE,
} from '@/const/badges'

const defaultForm = () => ({
  id: null,
  name: '',
  // slug: '',
  value: '',
  code: '',
  category: '',
  type: null,
  price: '',
  limit: 0,
  description: '',
  is_free: 0,
  is_convertable: 0,
  is_transferable: 0,
  icon: null,
  status: '',
})
export default {
  components: {
    BModal,
    ValidationProvider,
    BForm,
    BFormInput,
    BFormGroup,
    ValidationObserver,
    BSpinner,
    BButton,
    BCol,
    BRow,
    BFormTextarea,
    BFormCheckbox,
    BFormFile,
    BFormSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      required,
      email,
      isProcessing: false,
      form: {
        ...defaultForm(),
      },
      statusOptions: [
        { value: 'active', text: 'Active' },
        { value: 'inactive', text: 'Inactive' },
      ],
      badges: {
        'BADGE-HPG-L': HIGHEST_POINTS_GAINER_BADGE,
        'BADGE-HPG-C': HIGHEST_POINTS_GAINER_BADGE,
        'BADGE-FCD': FIRST_LESSON_ACHEIVER_BADGE,
        'BADGE-FLD': FIRST_LESSON_ACHEIVER_BADGE,
        'BADGE-MLD': EXTRAORDINARY_BADGE,
      },
    }
  },
  computed: {
    ...mapGetters('app-chat', { defaultVibes: 'get_default_vibes' }),
  },
  mounted() {
    this.$parent.$on('CreatePoint', () => {
      if (this.$refs['my-modal']) {
        this.form = { ...this.defaultForm }
        this.$refs['my-modal'].show()
      }
    })
    this.$parent.$on('EditPoints', value => {
      if (this.$refs['my-modal']) {
        this.title = 'Edit Points'
        this.form = { ...value }
        this.$refs['my-modal'].show()
      }
    })
  },
  methods: {
    onIconChange(e) {
      const [file] = e.target.files
      this.form.icon = file
    },
    setIconPreview(file) {
      return URL.createObjectURL(file)
    },
    storePoint() {
      const data = new FormData()
      data.append('type', 'vibe')
      Object.entries(this.form).forEach(entry => {
        data.append(entry[0], entry[1])
      })

      useJwt.storePointsSetting(data).then(res => {
        this.showSuccessMessage(res)
        this.$parent.getList()
        this.$refs['my-modal'].toggle('#toggle-btn')
      }).finally(() => {
        this.isProcessing = false
        this.$emit('close')
      })
    },
    updatePoint() {
      const form = {
        ...this.form,
        limit: this.form.limit == null ? 0 : this.form.limit,
      }
      const data = new FormData()
      Object.entries(form).forEach(entry => {
        data.append(entry[0], entry[1])
      })
      data.append('_method', 'put')

      useJwt.updatePointsSetting(this.form.id, data).then(res => {
        this.showSuccessMessage(res)
        this.$parent.getList()
        this.$refs['my-modal'].toggle('#toggle-btn')
      }).finally(() => {
        this.isProcessing = false
        this.$emit('close')
      })
    },
    handleOk() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isProcessing = true
          if (this.form.id) {
            this.updatePoint()
          } else {
            this.storePoint()
          }
        }
      })
    },
    getDefaultImage(form) {
      if (form.type === 'vibe') {
        return this.defaultVibes[form.code] || ''
      }
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return this.badges[form.code] ? require(`@/assets/images/${this.badges[form.code]}`) : ''
    },
  },
}
</script>
<style>
    .points-settings-custome-footer {
        display: block !important;
    }
    .icon-wrap {
      position: relative;
      min-width: 150px;
      height: 150px;
    }
    .icon-error {
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .icon-preview {
      width: 120px;
      height: 120px;
      padding: 8px;
      border: 1px solid #333;
      cursor: pointer;
    }
    .dark-layout .icon-preview {
      border-color: #aaa;
    }
    .icon-preview img {
      max-width: 100%;
      max-height: 100%;
    }
    .icon {
      transition: transform 0.3s ease-in;
    }
    .icon-preview:hover .icon {
      transform: translateY(-8px);
    }
</style>
