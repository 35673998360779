<template>
  <div>
    <div v-show="shouldShow"
         class="dialogs keyboard-dialogs gec-keyboard"
    >
      <dialog-drag :options="dialogOptions"
                   @drag-start="dragStart"
      >
        <div ref="keyboardPushArea">
          <div
            ref="keyboardLayout"
          >
            <div class="d-flex justify-content-between keyboard__keys m-1">
              <div class="mr-1"
                   role="button"
                   @click="handleClose"
              >
                <feather-icon icon="XCircleIcon"
                              size="60"
                              color="white"
                />
              </div>
              <div id="keyboard-text-screen"
                   class="text-screen"
              />
              <div class="ml-1"
                   role="button"
                   @click="handleBackSpace"
              >
                <feather-icon icon="DeleteIcon"
                              color="white"
                              size="60"
                />
              </div>
              <div class="volume-btn">
                <feather-icon :icon="isVolumeEnabled ? 'Volume2Icon' : 'VolumeXIcon'"
                              color="white"
                              size="50"
                              @click="toggleKeyboardSound"
                />
              </div>
            </div>
          </div>
        </div>
      </dialog-drag>
    </div>
    <div v-if="showKeyboardButton"
         class="mb-1 floating gec-keyboard-floating-button"
    >
      <div
        class="btn d-flex align-items-center justify-content-center text-white btn-icon rounded-circle shadow toggle-button"
        @click="openKeyboard"
      >
        <feather-icon
          style="height: 18px; width: 18px"
          icon="TrelloIcon"
        />
      </div>
    </div>
  </div>
</template>
<script>
import DialogDrag from 'vue-dialog-drag'
import { isStudentYoung } from '@/auth/utils'
import keyboard from './helper/keyboard'

export default {
  components: {
    DialogDrag,
  },
  data() {
    return {
      shouldShow: false,
      hasDragStarted: false,
      isVolumeEnabled: isStudentYoung(),
      dialogOptions: {
        top: 400,
        left: 500,
        zIndex: 20000,
        buttonClose: false,
        buttonPin: false,
      },
    }
  },
  computed: {
    showKeyboardButton() {
      return !['lesson-pages', 'curriculum-pages', 'student-roles'].includes(this.$route.name)
    },
    keyboardPosition() {
      return this.hasDragStarted ? 'none' : 'fixed'
    },
  },
  mounted() {
    setTimeout(() => {
      keyboard.init(this.$refs.keyboardLayout, this.$refs.keyboardPushArea, () => {
        this.shouldShow = true
      })
      keyboard.setSoundOption(this.isVolumeEnabled)
    }, 500)
  },
  methods: {
    openKeyboard() {
      keyboard.handleOpen({})
      this.shouldShow = true
    },
    dragStart() {
      this.hasDragStarted = true
    },
    handleBackSpace() {
      keyboard.handleBackSpace()
    },
    handleClose() {
      keyboard.close()
      this.shouldShow = false
    },
    toggleKeyboardSound() {
      if (this.isVolumeEnabled) {
        this.isVolumeEnabled = false
        keyboard.disableKeyboardSound()
      } else {
        keyboard.enableKeyboardSound()
        this.isVolumeEnabled = true
      }
    },
  },
}

</script>
<style lang="scss">
  .keyboard-dialogs {
    .dialog-header {
      display: none;
    }
    .dialog-body{
      padding: 0;
    }
    .dialog-drag {
      background-color: transparent !important;
    }
    .volume-btn {
      position: absolute;
      bottom: 0;
      right: 0;
      margin-right: 15px;
      margin-bottom: 10px;
    }
  }
  .dialogs {
    bottom: 0;
  }
  .keyboard {
    position: v-bind(keyboardPosition);
    z-index: 200000;
    cursor: pointer;
    left:500px;
    top:400px;
    width: 50vw;
    padding: 5px 0;
    background: #00413470;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
    user-select: none;
    transition: bottom 0.4s;
  }

.keyboard--hidden {
    bottom: -100%;
}

.keyboard__keys {
    text-align: center;
}

.keyboard__key:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
.keyboard__key {
  // text-transform: uppercase;
    box-shadow: 0 5px #999;
    height: 60px;
    font-weight: bold;
    width: 7%;
    max-width: 90px;
    margin: 3px;
    border-radius: 9px;
    color: black;
    font-size: 1.8rem;
    outline: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    position: relative;
}

.keyboard__key:active {
    background: rgba(255, 255, 255, 0.12);
}

.keyboard__key--wide {
    width: 12%;
}

.keyboard__key--extra-wide {
    width: 36%;
    max-width: 500px;
}

.keyboard__key--activatable::after {
    content: '';
    top: 10px;
    right: 10px;
    position: absolute;
    width: 8px;
    height: 8px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
}

.keyboard__key--active::after {
    background: #08ff00;
}

.keyboard__key--dark {
    background: rgba(0, 0, 0, 0.25);
}
.keyboard  .text-screen {
  width: 100%;
    background: #ffff808c;
    border-radius: 10px;
    /* vertical-align: middle; */
    text-align: center;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
}
</style>
<style src='vue-dialog-drag/dist/vue-dialog-drag.css'></style>

<style scoped>
/* .floating {
  position: fixed;
  top: 50%;
  right: 2%;
  z-index: 1000;
} */
.message-box {
  height: 0;
  width: 0;
  transition: all 200ms ease;
  display: none;
}
.expanded {
  height: auto !important;
  width: auto !important;
  display: block !important;
}
.has-notes{
  background-color: #e67e22 !important;
}
.toggle-button {
  height: 40px;
  width: 40px;
  background-color: #2974c7;
  border: 2px solid white !important;
}
.close-button {
  height: 22px;
  width: 22px;
  position: absolute;
  right: -12px;
  top: -12px;
}
.note-field{
  white-space: pre-line
}
</style>
