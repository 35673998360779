<template>
  <section>
    <h4>
      {{ $t('engagement-setting-module.trading-board') }}
    </h4>
    <hr>
    <template v-if="products.length">
      <b-row v-for="product in products"
             :key="product.id"
             class="d-flex align-items-center product-row bg-white p-1 rounded"
      >

        <img
          height="70"
          width="70"
          :src="product.animated_item.image"
        >

        <b-col cols="8">

          <h4>
            {{ product.animated_item.name }}
          </h4>

          <h6>
            {{ $t('engagement-setting-module.by') }} {{ self.id===product.seller.id ? $t('engagement-setting-module.you') : product.seller.firstname }}
          </h6>
          <a href="#!"
             class="text-muted"
          >{{ getWearType(product.animated_item.wear_type) }}</a>
          <p>
            {{ $t('engagement-setting-module.fits-for') }} :  <b-badge v-for="animal,i in product.animated_item.animal_type"
                                                                       :key="i"
                                                                       variant="primary"
                                                                       :class="i>0 && 'ml-1'"
                                                                       style="text-transform: capitalize;"
            >
              {{ animal }}
            </b-badge>

          </p>
          <h6>
            {{ $t('engagement-setting-module.total-on-sale') }}: {{ product.selling_quantity }}
          </h6>
          <h5 class="text-dark price-info">
            <strong> ${{ product.selling_price }}
              {{ $t('engagement-setting-module.per-item') }}
            </strong>
          </h5>
        </b-col>
        <b-col
          cols="2"
        >
          <b-badge v-if="self.id!==product.seller.id"
                   variant="success"
                   class="cursor-pointer"
                   @click="buyItem(product)"
          >
            <feather-icon icon="ShoppingBagIcon" />
            {{ $t('engagement-setting-module.buy') }}
          </b-badge>
          <b-badge v-else
                   variant="primary"
                   class="cursor-pointer"
                   @click="editItem(product)"
          >
            <feather-icon
              icon="EditIcon"
            /> {{ $t('engagement-setting-module.edit') }}
          </b-badge>

        </b-col>

      </b-row>
    </template>
    <b-row v-else
           class="bg-white p-1 rounded"
    >
      <b-col cols="12">
        <h5 class="text-muted">
          {{ $t('engagement-setting-module.no-item-on-trading-board') }}
        </h5>
      </b-col>
    </b-row>
    <buy-trading-modal
      :product="buyingProduct"
      @onBuy="()=>{getAnimatedItems();$emit('onBuy')}"
    />
    <edit-sell-modal :product="editingProduct" />
  </section>
</template>

<script>

import { BBadge, BRow, BCol } from 'bootstrap-vue'
import { wearableTypes } from '@/const/theme'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import BuyTradingModal from './BuyTradingModal.vue'
import EditSellModal from './EditSellModal.vue'

export default {
  components: {
    // BButton,
    BBadge,
    BRow,
    BCol,
    BuyTradingModal,
    EditSellModal,
  },
  data() {
    return {
      products: [],
      isProcessing: false,
      wearableTypes,
      buyingProduct: {},
      editingProduct: {},
      self: getUserData(),
    }
  },

  mounted() {
    this.getAnimatedItems()
  },

  methods: {
    getAnimatedItems() {
      this.isProcessing = true
      useJwt.getTradedItems().then(response => {
        this.products = response.data.data
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    getWearType(type) {
      return this.wearableTypes.find(wearableType => wearableType.value === type).text
    },

    buyItem(product) {
      this.$bvModal.show('buy-trading-animation-modal')
      this.buyingProduct = product
    },

    editItem(product) {
      this.$bvModal.show('edit-sell-animation-modal')
      this.editingProduct = product
    },
  },
}

</script>

<style lang="scss">
.product-row{
  border-bottom: 1px solid rgb(204, 201, 201);
  margin-bottom:10px;
  padding-bottom:10px;
}

.trade-button{
  border-radius:50px;
}

.price-info{
  padding-top:2px;
}
</style>
