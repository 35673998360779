<template>
  <b-card
    no-body
    class="mb-0"
  >
    <b-row
      v-if="activeClass !== null"
      class="d-flex justify-content-start align-items-center"
    >
      <b-col
        md="6"
        class="d-flex justify-content-start align-items-center"
      >
        <h5 class="p-1 pb-0 text-capitalize">
          {{ activeClass.name }}
        </h5>
        <span class="ml-1">
          <b-badge
            pill
            variant="primary"
          >{{ students.length }}</b-badge>
        </span>
        <span
          v-if="activeClass.link"
          class="ml-1"
        >
          <b-badge
            pill
            variant="primary"
          >
            <a
              :href="activeClass.link"
              target="_"
            >{{
              $t("class-module.-link")
            }}</a>
          </b-badge>
        </span>
      </b-col>

      <b-col
        v-if="self.id === activeClass.teacher_id"
        md="6"
        class="d-flex justify-content-end m-0 p-0 pr-2"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :disabled="isProcessing"
          variant="outline-primary"
          class="btn-icon sm-mt-2"
          @click.stop="$emit('onViewRubrics')"
        >
          {{ $t("student-diary-module.rubrics") }}
        </b-button>
      </b-col>
    </b-row>

    <h5
      v-else
      class="p-1 pb-0"
    >
      {{ $t("class-module.select-to-view-students") }}
    </h5>

    <b-table
      v-if="students.length > 0"
      :items="students"
      :fields="columnsForStudentsTable"
      :filter="search"
      :empty-filtered-text="$t('messages.no-record-found')"
      show-empty
      responsive
    >
      <template #cell(action)="data">
        <span
          v-if="
            isASchool || isATeacher || isAnAdmin || data.item.id === self.id
          "
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.top="
              $t('student-report-module.manage-student-rubrics')
            "
            :disabled="isProcessing"
            variant="outline-primary"
            class="btn-icon mr-1"
            @click.stop="$emit('onViewStudentRubric', data.item)"
          >
            <feather-icon icon="GridIcon" />
          </b-button>
        </span>
        <span v-if="self.id === activeClass.created_by">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-delete-user
            :disabled="isProcessing"
            variant="outline-danger"
            class="btn-icon sm-mt-2"
            @click.stop="$emit('onStudentDelete', data.index)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </span>
      </template>
    </b-table>
    <p
      v-else-if="activeClass"
      class="px-1"
    >
      No students yet!
    </p>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
  BBadge,
  BCol,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import i18n from '@/libs/i18n'
import {
  USER_TYPE_SCHOOL,
  USER_TYPE_STUDENT,
  USER_TYPE_SUPER,
  USER_TYPE_TEACHER,
} from '@/const/userType'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BBadge,
    BCol,
    BRow,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    students: {
      type: Array,
      default: () => [],
    },
    activeClass: {
      type: Object,
      default: null,
    },
    search: {
      type: String,
      default: '',
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columnsForStudentsTable: [
        { key: 'fullname', label: i18n.tc('class-module.student-table.name') },
        {
          key: 'action',
          label: i18n.tc('class-module.student-table.actions'),
          thStyle: { minWidth: '100px' },
        },
      ],
      self: getUserData(),
    }
  },
  computed: {
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
    isAnAdmin() {
      return this.self.usertype === USER_TYPE_SUPER
    },
    isASchool() {
      return this.self.usertype === USER_TYPE_SCHOOL
    },
  },
}
</script>

<style scoped>
.dark-layout .text-title {
  color: #e6e6e6 !important;
}
</style>
