<template>

  <b-row>

    <!-- problem title -->
    <b-col
      md="12"
      class="mb-1"
    >
      <validation-provider
        v-slot="{ errors }"
        name="Title"
        rules="required"
        vid="title"
      >
        <h5>{{ $t('lesson-pages-module.title') }}</h5>
        <b-form-input
          v-model="lesson.page.title"
          placeholder="Title"
          name="title"
          :state="errors.length > 0 ? false:null"
          @keyup.enter="$emit('submitData')"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-col>
    <!-- problem title -->

    <!-- main body -->
    <b-col
      lg="6"
      md="12"
      class="mb-1"
    >

      <validation-provider
        v-slot="{ errors }"
        name="Body"
        rules="required"
        vid="body"
      >

        <h5>{{ $t('lesson-pages-module.body') }}</h5>
        <editor
          v-model="lesson.main_text"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-col>
    <!-- main body -->

    <!-- questions -->
    <b-col
      lg="6"
      md="12"
      class="mb-1"
    >
      <validation-provider
        v-slot="{ errors }"
        name="Questions"
        rules="required"
        vid="questions"
      >
        <h5>{{ $t('lesson-pages-module.questions') }}</h5>
        <editor v-model="lesson.questions" />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-col>
    <!-- questions -->

    <!-- extra questions -->
    <b-col
      lg="6"
      md="12"
      class="mb-1"
    >
      <validation-provider
        v-slot="{ errors }"
        name="Extra Questions"
        rules="required"
        vid="extra-questions"
      >
        <h5>{{ $t('lesson-pages-module.extra-questions') }}</h5>
        <editor v-model="lesson.extra_questions" />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-col>
    <!-- extra questions -->

    <!-- bottom questions -->
    <b-col
      lg="6"
      md="12"
      class="mb-1"
    >

      <validation-provider
        v-slot="{ errors }"
        name="Bottom Questions"
        rules="required"
        vid="bottom-questions"
      >

        <h5>{{ $t('lesson-pages-module.bottom-questions') }}</h5>
        <editor v-model="lesson.bottom_question" />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-col>
    <!-- bottom questions -->

  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import Editor from '@views/common/components/QuillEditorCustom.vue'

export default {
  components: {
    BCol, BRow, ValidationProvider, BFormInput, Editor,
  },
  props: {
    lesson: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      problemData: {
        title: '',
      },
    }
  },
  methods: {

  },
}
</script>

<style>

</style>
