var render = function render(){var _vm=this,_c=_vm._self._c;return _c('body',{class:_vm.variant},[_c('div',{staticClass:"container-xl p-0"},[_c('header',{staticClass:"text-center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h2',[_vm._v(_vm._s(_vm.curriculum.page_title)+" "),_c('div',{staticClass:"float-right"},[_vm._t("ebook-selector")],2)])])])]),_c('div',{staticClass:"row no-gutters position-relative"},[_c('div',{staticClass:"top-img"},[_c('img',{staticClass:"img-fluid border-radius-img content-left-top img-thumbnail shadow-sm",attrs:{"src":_vm.curriculum.images.top_image.content
              ? _vm.curriculum.images.top_image.content
              : 'https://via.placeholder.com/400',"alt":""}})]),_c('div',{staticClass:"col-lg-6 bg-light ebook-text col-print-6"},[_c('div',{staticClass:"content-left left-1"},_vm._l((_vm.curriculum.sets),function(set,index){return _c('div',{key:index,staticClass:"my-2"},[_c('word-with-popover',{staticClass:"m-0 p-0 question-para",attrs:{"popover":`question-${index}`,"text":_vm.wordnetWords(set.question.content, index),"raw-text":set.question.content,"meta-id":set.question.id,"word-power-enabled":_vm.enableWordPower}}),_c('word-with-popover',{staticClass:"m-0 p-0",staticStyle:{"font-size":"18px"},attrs:{"popover":`answer-${index}`,"text":_vm.wordnetWords(_vm.questions[index].html),"meta-id":_vm.questions[index].id,"raw-text":_vm.questions[index].html,"word-power-enabled":_vm.enableWordPower}})],1)}),0)]),_c('div',{staticClass:"col-lg-6 col-print-6"},[_c('div',{staticClass:"bg-image left-2",style:(`background-image: url(${
            _vm.curriculum.images.vocab_bg_image.content
              ? _vm.curriculum.images.vocab_bg_image.content
              : 'https://via.placeholder.com/400'
          });`)},[_c('div',{staticClass:"list-style-wrapper"},[_c('div',{staticClass:"list-style-wrapper"},[_c('div',{staticClass:"clearfix"}),_c('vocab-card',{attrs:{"vocabs":_vm.vocabs,"is-note-open":_vm.isNoteOpen}})],1)])]),_vm._m(0)])]),_c('div',{staticClass:"row no-gutters position-relative"},[_c('div',{staticClass:"col-lg-6 bg-light col-print-6 review-section"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"question-box ml-0 mr-0"},[_vm._m(1),_c('p',[_c('word-with-popover',{attrs:{"popover":"review-questions","text":_vm.wordnetWords(_vm.curriculum.review_questions),"word-power-enabled":_vm.enableWordPower}})],1)]),_c('div',{staticClass:"question-ans mb-0"},[_c('p',[_c('word-with-popover',{attrs:{"popover":"extra-question","text":_vm.wordnetWords(_vm.curriculum.extra_question),"word-power-enabled":_vm.enableWordPower}})],1),_c('hr'),_c('hr'),_c('hr'),_c('hr'),_c('hr'),_c('hr')])])]),_c('div',{staticClass:"col-lg-6 bg-light col-print-6"},[_c('div',{staticClass:"bg-image p-1 question-img",style:(`background-image: url(${
            _vm.curriculum.images.question_bg_image.content
              ? _vm.curriculum.images.question_bg_image.content
              : 'https://via.placeholder.com/400'
          });`)},[_c('img',{staticClass:"img-fluid border-radius-img",attrs:{"src":_vm.curriculum.images.question_radius_image.content
                ? _vm.curriculum.images.question_radius_image.content
                : 'https://via.placeholder.com/400',"alt":""}}),_c('word-with-popover',{staticClass:"transparent-paragraph",attrs:{"popover":"transparent","text":_vm.wordnetWords(_vm.curriculum.bottom_question),"word-power-enabled":_vm.enableWordPower}})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend"},[_c('p',[_vm._v("LEGEND")]),_c('ul',{staticClass:"list-inline"},[_c('li',{staticClass:"list-inline-item"},[_c('span',{staticClass:"legend-word"}),_vm._v("Word ")]),_c('li',{staticClass:"list-inline-item"},[_c('span',{staticClass:"legend-synonym"}),_vm._v("Synonym ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("REVIEW QUESTIONS:")]),_c('br')])
}]

export { render, staticRenderFns }