<template>
  <b-modal :visible="true"
           title="Manage Assets"
           size="lg"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <b-tabs>
      <b-tab title="Manage Assets">
        <div class="row px-2">
          <div class="col-6 text-center">
            <h4>Cb Word Generation</h4>
            <SyncProgress
              title="Word Generation"
              :is-synching="state.isWordGenerationProcessing"
              btn-class="btn-block"
              :queue-key="`${props.pageInfo.id}_cbBulkWordGenAssets`"
              @onSync="handleBulkAssetUpdate('word_gen')"
            />
          </div>
          <div class="col-6 text-center">
            <h4>Audio</h4>
            <SyncProgress
              title="Regenerate Audio"
              :is-synching="state.isAudioProcessing"
              btn-class="btn-block"
              :queue-key="`${props.pageInfo.id}_cbBulkAudioAssets`"
              @onSync="state.showAudioOptions = true"
            />
          </div>
          <div class="col-12 mt-2">
            <h4>Image <small>
              <feather-icon icon="InfoIcon" /> Image generation might still continue even after the process completes.</small></h4>
            <SyncProgress
              title="Regenerate Images"
              btn-class="btn-block"
              :is-synching="state.isImageProcessing"
              :queue-key="`${props.pageInfo.id}_cbBulkImageAssets`"
              @onSync="handleBulkAssetUpdate('image')"
            />
          </div>
        </div>
        <div class="mt-2 p-2">
          <CbPipeLineProgress :lesson-id="pageInfo.lesson.id" />
        </div>
      </b-tab>
      <b-tab title="Logs"
             lazy
      >
        <CbPipeLine :lesson-id="pageInfo.lesson.id" />
      </b-tab>
    </b-tabs>
    <b-modal v-model="state.showAudioOptions"
             title="Select Voices"
             @ok="handleBulkAssetUpdate('audio')"
    >
      <b-form-group label="Voice Options">
        <b-form-radio-group
          v-model="audioOption.selected"
          :options="audioOption.options"
          class="demo-inline-spacing"
        />
      </b-form-group>
    </b-modal>
  </b-modal>
</template>
<script setup>
import {
  BModal, BFormGroup, BFormRadioGroup, BTab, BTabs,
} from 'bootstrap-vue'
import SyncProgress from '@/views/super/image-library/components/SyncProgress.vue'
import useJwt from '@/auth/jwt/useJwt'
import { getCurrentInstance, reactive } from 'vue'
import CbPipeLine from './cb-pipeline/index.vue'
import CbPipeLineProgress from './cb-pipeline/pipeline.vue'

const props = defineProps({
  pageInfo: {
    type: Object,
    required: true,
  },
})

const root = getCurrentInstance().proxy.$root
const state = reactive({
  isAudioProcessing: false,
  isImageProcessing: false,
  isWordGenerationProcessing: false,
  showAudioOptions: false,
})

const audioOption = reactive({
  selected: 'male',
  options: [
    { text: 'Male', value: 'male' },
    { text: 'Female', value: 'female' },
  ],
})

const processorName = type => {
  switch (type) {
    case 'audio':
      return 'isAudioProcessing'
    case 'image':
      return 'isImageProcessing'
    case 'word_gen':
      return 'isWordGenerationProcessing'
    default:
      return null
  }
}

const handleBulkAssetUpdate = (type = 'audio') => {
  const processor = processorName(type)
  state[processor] = true
  useJwt.bulkCbAssets(props.pageInfo.id, {
    type,
    voice: audioOption.selected,
  }).then(response => {
    root.showSuccessMessage(response)
  }).catch(error => {
    state[processor] = false
    root.showErrorMessage(error)
  })
}
</script>
