<template>
  <b-modal
    v-model="isVisible"
    ok-only
    ok-variant="outline-secondary"
    :ok-title="$t('actions.close')"
    modal-class="modal-primary"
    centered
    :title="$t('essay-builder-module.essay')"
    size="xl"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-overlay :show="processingAction === 'get-essay'">
      <div v-if="essay">
        <preview :essay="essay.content" />
        <b-row
          class="mt-2"
        >
          <b-col
            v-if="isATeacher"
            md="6"
            sm="12"
          >
            <b-card>

              <b-row>
                <b-badge v-if="getEssayStatus"
                         class="badge-glow mb-2"
                         :variant="getEssayStatus.color"
                >
                  {{ getEssayStatus.text }}
                </b-badge>
                <b-col
                  md="12"
                >
                  <validation-observer ref="scoreValidator">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('essay-builder-module.score')"
                      rules="required|integer|min_value:0|max_value:100"
                      :vid="`score`"
                    >
                      <b-form-group
                        :label="$t('essay-builder-module.score')"
                      >
                        <b-form-input v-model="score" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </validation-observer>

                </b-col>
                <b-col
                  md="12"
                  class="d-flex justify-content-end"
                >

                  <b-button
                    variant="success"
                    class="btn-icon"
                    @click="submitScore"
                  >
                    {{ $t('essay-builder-module.submit-score') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col
            v-else-if="score"
            md="6"
            sm="12"
          >
            <b-card>
              <b-row v-if="isAParent || isAStudent">
                <h5>{{ $t('essay-builder-module.score') }}: {{ score }}</h5>
              </b-row>
            </b-card>

          </b-col>

          <b-col
            md="6"
            sm="12"
          >
            <div>
              <b-card>
                <div class="comment-container">
                  <div
                    v-for="comment in comments"
                    :key="comment.id"
                    class="mb-1"
                  >
                    <div class="d-flex justify-content-between">
                      <h4>

                        <router-link
                          :to="{ name: 'user-profile',params:{id:comment.user.id}}"
                          target="_blank"
                        >
                          <strong>{{ self.id === comment.user.id ? $t('you'): comment.user.fullname }}</strong>
                        </router-link>
                      </h4>
                      <span
                        style="font-size:13px;"
                        class="text-right"
                      >
                        {{ comment.created_at }}
                      </span>
                    </div>
                    <p
                      v-if="editingComment!=comment.id"
                      class="mb-0"
                    >
                      <strong>{{ comment.review }}</strong>
                    </p>

                    <div v-else>
                      <b-form-textarea
                        v-model="updateCommentContent"
                        class="mb-1"
                      />
                      <small
                        v-if="updateCommentContent.length==0"
                        class="text-danger"
                      >{{ $t('essay-builder-module.comment-field-is-required') }}
                      </small>
                    </div>
                    <div
                      v-if="self.id === comment.user.id"
                    >
                      <div
                        v-if="editingComment!=comment.id"
                        class="d-flex justify-content-end"
                      >
                        <b-button
                          variant="success"
                          class="btn-icon mr-1"
                          :disabled="!!processingAction"
                          size="sm"
                          @click="editComment(comment)"
                        >
                          <feather-icon icon="EditIcon" />
                        </b-button>
                        <b-button
                          variant="danger"
                          class="btn-icon"
                          :disabled="!!processingAction"
                          size="sm"
                          @click="deleteComment(comment.id)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </div>
                      <div
                        v-else
                        class="d-flex justify-content-end"
                      >
                        <b-button
                          variant="secondary"
                          :disabled="!!processingAction"
                          size="sm"
                          class="mr-2"
                          @click="editingComment=null"
                        >
                          {{ $t('labels.cancel') }}
                        </b-button>
                        <b-button
                          variant="success"
                          :disabled="processingAction==='update'"
                          size="sm"
                          @click="updateComment()"
                        >
                          <b-spinner
                            v-show="processingAction==='update'"
                            class="mr-1"
                            small
                            variant="light"
                          />
                          {{ $t('essay-builder-module.update-comment') }}
                        </b-button>
                      </div>
                    </div>
                  </div>

                  <b-alert
                    :show="comments.length==0 && !processingAction"
                    variant="danger"
                  >
                    <div class="alert-body">
                      {{ $t('essay-builder-module.no-comments-yet') }}
                    </div>
                  </b-alert>
                </div>
                <div class="mt-2">
                  <b-row>
                    <b-col md="12">
                      <validation-observer ref="remarkValidator">
                        <validation-provider
                          v-slot="{ errors }"
                          name="comment"
                          rules="required"
                          :vid="`comment`"
                        >
                          <b-form-group
                            :label="$t('essay-builder-module.leave-a-comment')"
                          >
                            <b-form-textarea v-model="newCommentContent" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>

                      </validation-observer>
                    </b-col>
                    <b-col
                      md="12"
                      class="d-flex justify-content-end"
                    >
                      <b-button
                        variant="success"
                        class="btn-icon"
                        :disabled="processingAction==='create'"
                        @click="submitComment"
                      >
                        <b-spinner
                          v-show="processingAction==='create'"
                          class="mr-1"
                          small
                          variant="light"
                        />
                        {{ $t('essay-builder-module.submit-comment') }}
                      </b-button>
                    </b-col>
                  </b-row>
                </div>

              </b-card>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
    <b-alert
      variant="danger"
      :show="!processingAction && !essay"
    >
      <div class="alert-body">
        {{ $t('essay-builder-module.no-essay') }}
      </div>
    </b-alert>
  </b-modal>
</template>

<script>
import {
  BModal, BListGroup, BListGroupItem, BAlert, BRow, BCol, BButton, BFormGroup, BFormInput, BCard, BFormTextarea, BSpinner, BOverlay, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import Preview from '@views/student/essay/components/Preview.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_TEACHER, USER_TYPE_PARENT, USER_TYPE_STUDENT } from '@/const/userType'

export default ({
  directives: {
    Ripple,
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BSpinner, BModal, BListGroup, BListGroupItem, BAlert, Preview, BRow, BCol, BButton, ValidationProvider, ValidationObserver, BFormGroup, BFormInput, BCard, BFormTextarea, BOverlay, BBadge,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    lessonId: {
      type: Number,
      default: null,
    },
    studentId: {
      type: Number,
      default: null,
    },
    articleId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      essay: null,
      isVisible: this.show,
      score: 0,
      editingComment: null,
      processingAction: false,
      perPageComments: 6,
      self: getUserData(),
      isATeacher: getUserData().usertype === USER_TYPE_TEACHER,
      isAParent: getUserData().usertype === USER_TYPE_PARENT,
      isAStudent: getUserData().usertype === USER_TYPE_STUDENT,
      currentPage: 1,
      totalComments: 0,
      newCommentContent: '',
      updateCommentContent: '',
      comments: [
      ],
    }
  },
  computed: {
    getEssayStatus() {
      const status = this.essay?.status
      switch (status) {
        case 'is_draft':
          return {
            color: 'warning',
            text: 'Draft',
          }
        case 'for_teacher_review':
          return {
            color: 'primary',
            text: 'For Teacher Review',
          }
        case 'is_published':
          return {
            color: 'success',
            text: 'Is Published',
          }
        default:
          return null
      }
    },
  },
  watch: {
    studentId() {
      if (this.studentId) {
        this.getEssay()
      }
    },
    articleId(newValue) {
      if (newValue) {
        this.getEssay()
      }
    },
    show() {
      this.isVisible = this.show
    },
  },
  methods: {
    submitScore() {
      this.$refs.scoreValidator.validate().then(result => {
        if (!result) return
        useJwt.scoreEssay(this.essay.id, { score: this.score }).then(res => {
          this.showSuccessMessage(res)
        })
      })
    },
    submitComment() {
      this.$refs.remarkValidator.validate().then(result => {
        if (!result) return
        this.processingAction = 'create'
        useJwt.commentOnEssay(this.essay.id, { eassy_id: this.essay.id, review: this.newCommentContent }).then(res => {
          this.showSuccessMessage(res)
          this.newCommentContent = ''
          this.currentPage = 1
          this.getComments()
          this.$nextTick(() => this.$refs.remarkValidator.reset())
        }).finally(() => {
          this.processingAction = false
        })
      })
    },
    deleteComment(id) {
      this.processingAction = 'delete'
      useJwt.deleteEssayComment(id).then(res => {
        this.showSuccessMessage(res)
        this.getComments()
      }).finally(() => {
        this.processingAction = false
      })
    },
    editComment(comment) {
      this.editingComment = comment.id
      this.updateCommentContent = comment.review
    },
    updateComment() {
      if (this.updateCommentContent.length > 0) {
        this.processingAction = 'update'
        useJwt.updateEssayComment(this.editingComment, { eassy_id: this.essay.id, review: this.updateCommentContent }).then(res => {
          this.showSuccessMessage(res)
          this.updateCommentContent = ''
          this.editingComment = null
          this.getComments()
        }).finally(() => {
          this.processingAction = false
        })
      }
    },
    getComments() {
      if (this.essay) {
        this.processingAction = 'get-comments'
        useJwt.getEssayComments(this.essay.id).then(res => {
          this.comments = res.data.data
          this.totalComments = this.comments.length
        })
          .finally(() => {
            this.processingAction = false
          })
      }
    },
    getEssay() {
      this.processingAction = 'get-essay'
      if (this.articleId) {
        useJwt.getEssay({ params: { article_id: this.articleId } }).then(res => {
          this.essay = res.data.data
          this.score = this.essay?.score
          this.getComments()
        }).finally(() => {
          this.processingAction = false
        })
        return
      }
      if (!this.lessonId || !this.studentId) return
      useJwt.getEssay({ params: { lesson_id: this.lessonId, student_id: this.studentId } }).then(res => {
        this.essay = res.data.data
        this.score = this.essay?.score
        this.getComments()
      }).finally(() => {
        this.processingAction = false
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.comment-container{
  max-height: 400px;
  overflow-y: scroll;
  padding:12px;
}
</style>
