import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-boost'
import { setContext } from 'apollo-link-context'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

const cache = new InMemoryCache()
// const { typeDefs } = require('./server-schema')

Vue.use(VueApollo)
const AUTH_TOKEN = 'accessToken'

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_GEC_GRAPHQL,
})

const authLink = setContext(async (_, { headers }) => {
  // Use your async token function here:
  const token = await localStorage.getItem(AUTH_TOKEN)
  // Return the headers to the context so httpLink can read them
  const extraConfig = {}
  const userData = JSON.parse(localStorage.getItem('userData'))
  if (userData && userData.isGuestUser) {
    // eslint-disable-next-line no-param-reassign
    extraConfig['Guest-User'] = true
  }
  return {
    headers: {
      ...headers,
      Authorization: `${jwtDefaultConfig.tokenType} ${token}` || '',
      ...extraConfig,
    },
  }
})

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,

})

export default apolloProvider
