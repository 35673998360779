<template>
  <div>
    <validation-observer ref="editGradeForm">
      <b-form @submit.prevent="editGrade">
        <b-row>
          <b-col md="12">
            <!-- Name -->
            <b-form-group
              :label="$t('labels.name')"
              label-for="title"
            >
              <validation-provider
                v-slot="{ errors }"
                name="name"
                rules="required"
                vid="name"
              >
                <b-form-input
                  id="name"
                  v-model="grade.name"
                  name="name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Name of your Grade"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- / Name -->
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row>
      <b-col
        md="12"
        class="d-flex justify-content-end"
      >
        <b-button
          variant="tertiary"
          class="btn-icon sm-mt-2"
          @click="$emit('back')"
        >
          {{ $t('actions.cancel') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          :disabled="!grade.name"
          @click="editGrade"
        >
          <b-spinner
            v-show="isProcessing"
            small
          />
          {{ $t('actions.edit-grade') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BForm, BFormGroup, BFormInput, BSpinner,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import useJwt from '@/auth/jwt/useJwt'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow, BCol, BButton, BForm, BFormGroup, BFormInput, ValidationProvider, ValidationObserver, BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    editGradeData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    grade: {
      name: null,
      id: 0,
    },
    isProcessing: false,
  }),
  created() {
    this.grade.name = this.editGradeData.name
    this.grade.id = this.editGradeData.id
  },
  methods: {
    editGrade() {
      this.isProcessing = true
      const config = {
        id: this.grade.id,
        name: this.grade.name,
      }
      useJwt.updateGrade(this.grade.id, config).then(response => {
        this.$emit('edited', response.data.data)
        this.$emit('back')
        this.showSuccessMessage(response)
      })
        .catch(error => {
          if (error.response?.data?.errors) {
            this.$refs.editGradeForm.setErrors(error.response.data.errors)
          } else this.showErrorMessage(error)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
  },
}
</script>
