<template>
  <div>
    <h3 class="mb-2">
      {{ $t('setting-module.live-class-title') }}
    </h3>
    <b-table-simple
      responsive
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th
            width="40%"
          >
            {{ $t('setting-module.options') }}
          </b-th>
          <b-th
            width="40%"
          >
            {{ $t('setting-module.switch') }}
          </b-th>
          <b-th
            width="20%"
          >
            {{ $t('setting-module.help-guide') }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>{{ $t('setting-module.live-class-max-time-limit-title') }}</b-td>
          <b-td>
            <span class="d-flex align-items-baseline">
              <b-form-input
                v-model="allSettings.live_class_max_time_limit"
                class="col-4"
                type="number"
                min="0s"
                @change="updateClassRoomSetting('live_class_max_time_limit')"
              />
              <span class="ml-2">
                {{ $t('setting-module.minutes') }}
              </span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('live_class_max_time_limit')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>

    </b-table-simple>
    <setting-info-modal
      :show="showModal"
      :modal-data="modalData"
      @close="showModal = false"
    />
  </div>
</template>
<script>
import {
  BFormInput, BButton,
  BTableSimple,
  BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import SettingInfoModal from '@/views/common/components/SettingInfoModal.vue'

export default {
  components: {
    BFormInput,
    BButton,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    SettingInfoModal,
  },
  props: {
    allSettings: {
      type: Object,
      default: () => {},
    },
    classId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      modalData: {
        title: '',
        imgSrc: '',
        description: '',
        steps: '',
      },
      modalInfo: {
        live_class_max_time_limit: {
          title: i18n.tc('setting-module.live-class-max-time-limit-title'),
          description: i18n.tc('setting-module.live-class-max-time-limit'),
          imageSrc: '',
          steps: '',
        },
      },
    }
  },
  methods: {
    updateClassRoomSetting(type) {
      useJwt.updateClassroomSetting(this.classId, type, {
        value: this.allSettings[type],
      }).then(res => {
        this.showSuccessMessage(res)
        this.getClassRoomSettings()
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    showHelpGuide(type) {
      this.showModal = true
      this.modalData = {
        title: this.modalInfo[type].title,
        imgSrc: this.modalInfo[type].imageSrc,
        description: this.modalInfo[type].description,
        steps: this.modalInfo[type].steps,
      }
    },
  },
}
</script>
