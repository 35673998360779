<template>
  <b-row>
    <b-col md="12">
      <!-- form -->
      <validation-observer ref="createEditRubricForm">
        <b-form
          v-if="allRubrics.length>0"
          class="add-student-from"
          @submit.prevent="handleSubmit"
        >
          <button
            type="submit"
            class="d-none"
          />
          <b-row>
            <b-col md="4">
              <b-form-group
                :label="$t('student-diary-module.rubric-type')"
                label-for="rubric-name"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Rubric Type"
                  rules="required"
                  vid="rubric_type"
                >
                  <b-form-select
                    v-model="localRubric.rubric_id"
                    :options="allRubrics.map(e=>({value:e.id,text:e.title}))"
                    @change="(id) => $emit('onChangeRubricType',id)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('student-diary-module.expected')"
                label-for="rubric-name"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Expected Value"
                  rules="required|integer"
                  vid="expected"
                >
                  <b-form-input
                    v-model="localRubric.expected"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('student-diary-module.actual')"
                label-for="rubric-name"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Actual Value"
                  :rules="`required|integer|max_value:${parseInt(localRubric.expected)}|min_value:0`"
                  vid="actual"
                >
                  <b-form-input
                    v-model="localRubric.actual"
                    :min="0"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col
              class="d-flex justify-content-end"
            >
              <b-button
                v-if="localRubric && localRubric.id"
                variant="danger"
                :disabled="isProcessing"
                class="mr-1"
                @click="clearRubric"
              >
                <b-spinner
                  v-show="isProcessing"
                  small
                />
                {{ $t('student-diary-module.clear') }}
              </b-button>

              <b-button
                variant="primary"
                :disabled="isProcessing"
                @click="handleSubmit()"
              >
                <b-spinner
                  v-show="isProcessing"
                  small
                />
                {{ localRubric && localRubric.id ? $t('student-diary-module.update-rubric'): $t('student-diary-module.create-rubric') }}
              </b-button>
            </b-col>

          </b-row>
        </b-form>
        <b-alert
          v-else-if="hasRubrics"
          variant="success"
          show
        >
          <div class="alert-body">
            <span>{{ $t('student-diary-module.all-rubrics-created') }}</span>
          </div>
        </b-alert>
      </validation-observer>
    </b-col>
  </b-row>
</template>

<script>
import {
  BSpinner,
  BRow,
  BCol,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BAlert,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BFormGroup,
    BAlert,
  },
  directives: {
    Ripple,
  },
  props: {
    studentId: {
      type: Number,
      required: true,
    },
    rubric: {
      type: Object,
      default: null,
    },
    allRubrics: {
      type: Array,
      default: () => [],
    },
    hasRubrics: {
      type: Boolean,
      required: true,
    },
    classId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      localRubric: {
        rubric_id: this.allRubrics.length > 0 ? this.allRubrics[0].id : null,
        user_id: this.studentId,
        expected: 0,
        actual: 0,
      },
      isProcessing: false,
    }
  },

  watch: {
    rubric() {
      if (!this.rubric) {
        this.clearRubric()
      } else this.localRubric = JSON.parse(JSON.stringify(this.rubric))
    },
    allRubrics() {
      if (!this.localRubric.rubric_id || !this.allRubrics.includes(this.localRubric.rubric_id)) this.localRubric.rubric_id = this.allRubrics.length > 0 ? this.allRubrics[0].id : null
    },
  },
  mounted() {
    this.clearRubric()
  },

  methods: {
    clearRubric() {
      this.localRubric = {
        rubric_id: this.allRubrics.length > 0 ? this.allRubrics[0].id : null,
        user_id: this.studentId,
        expected: 0,
        actual: 0,
      }
      this.$emit('clear')
    },
    handleSubmit() {
      this.$refs.createEditRubricForm.validate().then(success => {
        if (success) {
          this.isProcessing = true

          const data = {
            rubric_id: this.localRubric.rubric_id,
            expected: this.localRubric.expected,
            actual: this.localRubric.actual,
            user_id: this.localRubric.user_id,
            classroom_id: this.classId,
          };

          (this.rubric ? useJwt.updateStudentRubric(this.localRubric.id, data) : useJwt.createStudentRubric(data))
            .then(res => {
              this.clearRubric()
              this.$emit('onRubricUpdated')
              this.showSuccessMessage(res)
            })
            .finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
  },
}
</script>
