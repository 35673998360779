<template>
  <validation-observer ref="formProvider">
    <b-card>
      <b-card-title>
        Reservation form.
      </b-card-title>
      <b-card-text>
        Please enter the required information.
      </b-card-text>
      <b-row>
        <b-col>
          <b-form-group label="Parent Email">
            <validation-provider
              v-slot="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="MailIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="parentEmail"
                  type="email"
                  placeholder="Email"
                  @change="$emit('updateEmail', parentEmail)"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Number of children">
            <validation-provider
              v-slot="{ errors }"
              name="number of children"
              rules="required"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="UsersIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model.number="value"
                  type="number"
                  placeholder="Number of children"
                  :max="10"
                  @change="handlePick"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <template #label>
              &nbsp;
            </template>
            <b-button variant="success"
                      @click="handlePick"
            >
              Go
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <slot />
    </b-card>
  </validation-observer>
</template>
<script>
import {
  BCard, BFormInput, BCardTitle, BCardText, BRow, BCol, BFormGroup, BInputGroupPrepend, BInputGroup, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BFormInput,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
  },
  data() {
    return {
      value: null,
      parentEmail: null,
      required,
      email,
    }
  },
  methods: {
    handlePick() {
      if (this.value > 10) {
        this.showError('Only 10 children is allowed')
      } else {
        this.$emit('onPick', this.value)
      }
    },
  },
}

</script>
