<template>
  <div>
    <!-- Video -->
    <b-form-group
      :label="$t('labels.create-problem.video')"
      label-for="video"
    >
      <validation-provider
        v-slot="{ errors }"
        name="video"
        vid="video"
      >
        <b-form-input
          id="video"
          v-model="video.url"
          name="video"
          type="url"
          :state="errors.length > 0 ? false : null"
          placeholder="Url of your video"
          @blur="updateVideo"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <!-- / Video -->
    <iframe
      :src="video.url"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      class="w-100"
      style="height: 280px"
    />
    <!-- Timestaps -->
    <validation-observer ref="timestampForm">
      <b-row class="mt-2">
        <b-col md="6">
          <b-form-group
            :label="$t('labels.create-problem.start-minute')"
            label-for="timestamp"
          >
            <validation-provider
              v-slot="{ errors }"
              name="start minute"
              rules="required|numeric"
              :vid="`start-minute`"
            >
              <b-form-input
                id="minute"
                v-model="startMinute"
                name="minute"
                :state="errors.length > 0 ? false : null"
                placeholder="minute"
                @blur="updateTime"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('labels.create-problem.start-second')"
            label-for="timestamp"
          >
            <validation-provider
              v-slot="{ errors }"
              name="start second"
              rules="required|numeric"
              :vid="`start-second`"
            >
              <b-form-input
                id="second"
                v-model="startSecond"
                name="second"
                :state="errors.length > 0 ? false : null"
                placeholder="Second"
                @blur="updateTime"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('labels.create-problem.end-minute')"
            label-for="timestamp"
          >
            <validation-provider
              v-slot="{ errors }"
              name="end minute"
              rules="required|numeric"
              :vid="`end-minute`"
            >
              <b-form-input
                id="minute"
                v-model="endMinute"
                name="minute"
                :state="errors.length > 0 ? false : null"
                placeholder="minute"
                @blur="updateTime"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('labels.create-problem.end-second')"
            label-for="timestamp"
          >
            <validation-provider
              v-slot="{ errors }"
              name="end second"
              rules="required|numeric"
              :vid="`end-second`"
            >
              <b-form-input
                id="second"
                v-model="endSecond"
                name="second"
                :state="errors.length > 0 ? false : null"
                placeholder="Second"
                @blur="updateTime"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <!-- Timestaps -->
  </div>
</template>

<script>
import {
  BFormInput, BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BFormInput,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    videoTimeStamp: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    url: null,
    toggle: false,
    columns: [
      {
        label: 'Timestamp',
        field: 'timestamp',
      },
      {
        label: 'Actions',
        field: 'action',
        category: false,
        thClass: 'text-center',
      },
    ],
    video: { id: null, url: null },
    timestamp: { id: null, time: null },
    startMinute: null,
    startSecond: null,
    endMinute: null,
    endSecond: null,
  }),
  watch: {
    videoTimeStamp() {
      this.setPage()
    },
  },
  created() {
    this.setPage()
  },
  methods: {
    setPage() {
      this.timestamp.id = this.videoTimeStamp.id
      this.timestamp.time = this.videoTimeStamp.time
      this.video.id = this.videoTimeStamp.video.id
      this.video.url = this.videoTimeStamp.video.url

      if (this.videoTimeStamp?.start_time) {
        const [startMinute, startSecond] = this.videoTimeStamp.start_time.split(':')
        this.startMinute = startMinute
        this.startSecond = startSecond
      }
      if (this.videoTimeStamp?.time) {
        const [endMinute, endSecond] = this.videoTimeStamp.time.split(':')
        this.endSecond = endSecond
        this.endMinute = endMinute
      }
    },
    updateVideo() {
      const data = new FormData()
      data.append('url', this.video.url)
      data.append('_method', 'put')

      useJwt.updateVideo(this.video.id, data).then(res => {
        this.showSuccessMessage(res)
        this.$router.go()
      }).catch(err => {
        const { errors } = err.response.data
        Object.keys(errors).forEach(key => {
          this.showError(errors[key][0])
        })
      })
    },
    updateTime() {
      const endTime = `${this.endMinute}:${this.endSecond}`
      const startTime = `${this.startMinute}:${this.startSecond}`
      this.$refs.timestampForm.validate().then(success => {
        if (success) {
          useJwt.updateTimestamp(this.timestamp.id, { time: endTime, start_time: startTime }).then(res => {
            this.$emit('updated')
            this.showSuccessMessage(res)
          }).catch(err => {
            this.showError(err)
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
.grade-table{
  tbody {
    td {
      vertical-align: middle !important;
      padding: 0 !important;
      div {
        padding: 18px 14px;
      }
      span {
        padding: 11.5px 14px;
      }
      div:hover,
      span:hover {
        cursor: pointer;
      }
    }
  }
}
.active-column {
  background-color: rgba(0, 0, 0, 0.15);
}
</style>
