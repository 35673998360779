<template>
  <div class="py-1 bg-white mb-1 rounded">
    <!-- Back to Courses -->
    <!-- Back to Classroom -->
    <b-button
      variant="flat-secondary"
      class="btn-icon"
      @click="GO_BACK()"
    >
      <span> &#8701; {{ $t('back') }}</span>
    </b-button>
    |
    <b-button
      variant="flat-secondary"
      class="btn-icon"
      @click="$router.push({ name: self.usertype +'-classes' })"
    >
      <span v-if="classRoomInfo">  {{ classRoomInfo.name }}</span>
    </b-button>
    |
    <b-button
      variant="flat-secondary"
      class="btn-icon"
      @click="$router.push({
        name: `${self.usertype}-class-calendar`,
        params: { id: $route.params.classId },
      })"
    >
      {{ $t('student-lab-module.calendar') }}
    </b-button>
  </div>
</template>
<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    classRoomInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      self: this.AUTH_USER(),
    }
  },
}
</script>
