<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <router-link
          class="d-flex justify-content-center mb-2"
          to="/"
        >
          <b-img
            fluid
            :src="$store.state.app.companyLogo"
            alt="Login V2"
            class="col-7 col-xl-5"
          />
        </router-link>
        <b-card-title
          title-tag="h2"
          class="font-weight-bold mb-1 mt-2"
        >
          {{ $t('actions.forgot-password') }}
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('messages.reset-password-message') }}
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="forgotPassword"
          >
            <b-form-group
              :label="$t('email')"
              label-for="forgot-password-email"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                rules="required|email"
                vid="email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="disable || isProcessing"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t('actions.send-reset-link') }}
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> {{ $t('actions.back-to-login') }}
          </b-link>
        </p>
      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm, BFormGroup, BFormInput, BButton, BSpinner, BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BLink,
    BImg,
    BForm,
    BSpinner,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.png'),
      jaacImg: require('@/assets/images/logo/jaac-logo.svg'),
      // validation
      required,
      email,
      isProcessing: false,
      disable: false,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    forgotPassword() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt
            .forgotPassword({
              email: this.userEmail,
            })
            .then(response => {
              this.showSuccessMessage(response)
              this.disable = true
            })
            .catch(error => {
              if (error.response?.data?.errors) {
                this.$refs.simpleRules.setErrors(error.response.data.errors)
              } else this.showErrorMessage(error)
            })
            .finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
