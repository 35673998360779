<template>
  <div>
    <div
      class="lesson-page"
      :class="!isNoteOpen && 'container-xl' "
      :style="{ maxWidth: !isNoteOpen ? '1140px' : ''}"
    >
      <div
        v-if="!isNoteOpen && pageId"
        class="floating d-flex flex-column align-items-end"
      >
        <div class="mb-1">
          <div
            v-if="selectedClassRoom"
            v-b-tooltip.hover.v-info
            title="Open Notes"
            class="btn d-flex align-items-center justify-content-center text-white btn-icon rounded-circle shadow toggle-button"
            @click="isNoteOpen = !isNoteOpen"
          >
            <feather-icon
              style="height: 18px; width: 18px"
              icon="ClipboardIcon"
            />
          </div>
          <div
            v-b-tooltip.hover.v-info
            title="Export to pdf"
            class="btn d-flex align-items-center justify-content-center text-white btn-icon rounded-circle shadow toggle-button-download mt-2"
            @click="exportPdf"
          >
            <feather-icon
              style="height: 18px; width: 18px"
              icon="DownloadCloudIcon"
            />
          </div>
          <div
            v-if="selectedClassRoom"
            v-b-tooltip.hover.v-info
            title="Show Class Script"
            class="btn d-flex align-items-center justify-content-center text-white btn-icon rounded-circle shadow script-button mt-2"
            @click="showClassRoomScript"
          >
            <feather-icon
              style="height: 18px; width: 18px"
              icon="FileTextIcon"
            />
          </div>
          <div
            v-if="selectedClassRoom"
            v-b-tooltip.hover.v-info
            title="Show Scoreboard"
            class="btn d-flex align-items-center justify-content-center text-white btn-icon rounded-circle shadow scoreboard-button mt-2"
            @click="openScoreBoard"
          >
            <feather-icon
              style="height: 18px; width: 18px"
              icon="UsersIcon"
            />
          </div>
          <div
            v-if="isATeacher"
            v-b-tooltip.hover.v-info
            title="Show Lesson Planner"
            class="btn d-flex align-items-center bg-success justify-content-center text-white btn-icon rounded-circle shadow scoreboard-button mt-2"
            @click="()=>{$bvModal.show('lessonPlannerViewModal')}"
          >
            <feather-icon
              style="height: 18px; width: 18px"
              icon="BookIcon"
            />

          </div>
        </div>
      </div>
      <b-overlay
        id="overlay-background"
        :show="isProcessing"
        :opacity="1"
        rounded="sm"
      >
        <div
          v-if="lessonData != null"
          :class="lessonData.color_scheme"
        >
          <div
            class="row"
            :style="{ background: isNoteOpen ? '#f8f8f8' : ''}"
          >
            <div :class="`col${isNoteOpen ? '-8 pl-2' : ''}`">
              <component
                :is="layoutName"
                :page="currentPage"
                :lesson="lessonData"
                :page-id="pageId"
                @open-translation-modal="openTranslationModal"
              >
                <template #ebook-selector>
                  <div v-if="lessonId"
                       class="mr-1"
                  >
                    <OtherVersionSelector :lesson-id="Number(lessonId)" />
                  </div>
                </template>
              </component>
              <footer
                v-show="lessonData != null"
                class="mt-2 d-flex justify-content-center p-2"
              >
                <div
                  class="text-center"
                  style="color: white"
                >
                  <a
                    style="color: white"
                    :class="{
                      'font-weight-bold': currentPage != 0,
                      'text-muted': currentPage == 0,
                    }"
                    @click="previousPage"
                  >{{ $t("lesson-pages-module.previous") }}</a>
                  |
                  <a
                    style="color: white"
                    :class="{
                      'font-weight-bold': currentPage != 5,
                      'text-muted': currentPage == 5,
                    }"
                    @click="nextPage"
                  >{{ $t("lesson-pages-module.next") }}</a>
                </div>
              </footer>
            </div>
            <div
              v-if="isNoteOpen"
              class="col-4 position-fixed right-section"
            >
              <lesson-notes
                v-if="isNoteOpen != 'class-script' "
                :lesson-id="Number(lessonData ? lessonData.lesson_id : null)"
                @close="isNoteOpen = false"
              />
              <ClassRoomScript v-if="isNoteOpen == 'class-script' "
                               :show="showClassScript"
                               class="mt-2"
                               :class-id="parseInt(selectedClassRoom)"
                               :is-high-level-user="false"
                               :with-modal="false"
                               @close="isNoteOpen = false"
              />
            </div>
          </div>
        </div>
      </b-overlay>
      <translation-section
        v-show="openTranslation"
        v-if="fetchedData"
        ref="translationModal"
        :active-translation="activeTranslation"
        :active-word-nets="activeWordNets"
        :active-word="activeWord"
        :default-language="defaultLanguage"
        @set-active-language="setActiveLanguage"
      />

    </div>
    <div
      class="position-absolute"
      style="bottom: 0;"
    >
      <!-- Issue Report -->
      <issue-report
        v-if="isATeacher"
        :lesson-id="lessonId"
        default-issue-title="Lesson page Issue"
        class="issue-report"
        :user-id="self.id"
      />
    </div>
    <lesson-planner-view
      :lesson-id="lessonId"
      :event-id="selectedEventId"
      class="mt-3"
    />
  </div>
</template>

<script>
import { BButton, BOverlay, VBTooltip } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import IssueReport from '@/views/student/problem/compoments/IssueReport.vue'
import {
  USER_TYPE_TEACHER,
} from '@/const/userType'
import html2pdf from 'html2pdf.js'
import ClassRoomScript from '@/views/v2/common/class/components/class-script/index.vue'
import OtherVersionSelector from '@/views/common/curriculum/components/OtherVersionSelector.vue'
import LessonPlannerView from '@/views/teacher/class/lesson-planner/View.vue'
import Layout1 from './components/layouts/Layout1.vue'
import Layout2 from './components/layouts/Layout2.vue'
import Layout3 from './components/layouts/Layout3.vue'
import Layout4 from './components/layouts/Layout4.vue'
import Layout5 from './components/layouts/Layout5.vue'
import Layout6 from './components/layouts/Layout6.vue'
import TranslationSection from './components/TranslationSection.vue'
import LessonNotes from './Notes/index.vue'

export default {
  components: {
    Layout1,
    Layout2,
    Layout3,
    Layout4,
    Layout5,
    Layout6,
    BButton,
    TranslationSection,
    BOverlay,
    LessonNotes,
    IssueReport,
    ClassRoomScript,
    OtherVersionSelector,
    LessonPlannerView,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: this.show,
      lessonData: null,
      currentPage: null,
      isProcessing: false,
      pageId: null,
      activeWord: '',
      activeTranslation: {},
      activeLanguage: 'Korean',
      activeWordNets: {},
      activeOffset: null,
      pageIds: [],
      words: [],
      openTranslation: false,
      defaultLanguage: 'Korean',
      fetchedData: false,
      isNoteOpen: false,
      self: this.AUTH_USER(),
      selectedClassRoom: this.$route.query.classId,
      selectedEventId: this.$route.query.eventId,
      showClassScript: false,
    }
  },
  computed: {
    layoutName() {
      const layout = this.currentPage + 1
      return `Layout${layout}`
    },
    lessonId() {
      return this.lessonData?.lesson_id
    },
    activeLanguageShort() {
      if (this.activeLanguage === 'Korean') {
        return 'kor'
      }
      return 'jpn'
    },
    isATeacher() {
      return this.self && this.self.usertype === USER_TYPE_TEACHER
    },
  },
  watch: {
    show() {
      this.isVisible = this.show
    },
    pageId() {
      if (this.pageId) {
        this.fetchPageData()
      }
    },
  },
  created() {
    this.pageId = this.$route.params.pageId
    this.getWordNets()
  },
  methods: {
    checkActiveLanguage(lang) {
      if (lang === 'kor') {
        return 'Korean'
      }
      if (lang === 'jpn') {
        return 'Japanese'
      }
      return 'Korean'
    },
    previousPage() {
      if (!this.currentPage > 0) return
      this.isProcessing = true
      const oldId = this.pageId
      setTimeout(() => {
        this.currentPage -= 1
        this.pageId = this.pageIds[this.currentPage]
        this.changeUrl(oldId, this.pageId)
        this.fetchPageData()
      }, 100)
    },
    nextPage() {
      if (this.currentPage >= 5) return

      this.isProcessing = true
      const oldId = this.pageId
      setTimeout(() => {
        this.currentPage += 1
        this.pageId = this.pageIds[this.currentPage]
        this.changeUrl(oldId, this.pageId)
        this.fetchPageData()
      }, 100)
    },
    changeUrl(oldId, newId) {
      // eslint-disable-next-line no-restricted-globals
      if (history.pushState) {
        const newurl = `${window.location.protocol}//${
          window.location.host
        }${window.location.pathname.replace(
          `pages/${oldId}`,
          `pages/${newId}`,
        )}?classId=${this.$route.query.classId}&eventId=${this.selectedEventId}`
        window.history.pushState(
          {
            path: newurl,
          },
          '',
          newurl,
        )
      }
    },
    fetchPageData() {
      if (!this.pageId) return
      this.isProcessing = true
      useJwt
        .getLessonPage(this.pageId)
        .then(res => {
          this.lessonData = res.data.data
          this.pageIds = this.lessonData.page_ids
          this.defaultLanguage = this.checkActiveLanguage(
            this.lessonData.default_lang,
          )
          this.activeLanguage = this.defaultLanguage
          this.currentPage = this.pageIds.indexOf(this.lessonData.page_id)
          this.fetchedData = true
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    openTranslationModal(word) {
      this.openTranslation = true
      this.activeWord = word
      this.activeWordNets = {}
      this.activeTranslation = {
        words: ['-'],
      }
      useJwt
        .getWordNet({
          page_id: this.pageId,
          word,
        })
        .then(res => {
          if (res.status === 200) {
            this.activeWordNets = res.data.data
            const selectedWordnets = []
            this.activeWordNets.wordnet.forEach(w => {
              if (w.selected === 1) {
                selectedWordnets.push(w)
              }
            })
            if (selectedWordnets.length > 0) {
              this.activeWordNets.wordnet = selectedWordnets
            }
            const filteredWordNet = this.activeWordNets.wordnet.find(
              w => w.selected === 1,
            )
              ? this.activeWordNets.wordnet.find(w => w.selected === 1)
              : this.activeWordNets.wordnet[0]
            const { offset } = filteredWordNet
            const translatedWord = filteredWordNet[this.activeLanguageShort]
            this.activeOffset = offset
            if (translatedWord) {
              this.activeTranslation.words = [translatedWord]
            } else {
              this.getTranslation(offset)
            }
            this.$refs.translationModal.showModal()
          }
        })
    },
    getWordNets() {
      if (!this.pageId) return
      useJwt.getWordNetsForPage(this.pageId).then(res => {
        Object.values(res.data.data).forEach(word => {
          this.words.push(word.word)
        })
      })
    },
    getTranslation(offset) {
      useJwt
        .getTranslatedWords({
          word_offset: offset,
          language: this.activeLanguage,
        })
        .then(res => {
          if (res.status === 200) {
            this.activeTranslation = JSON.parse(res.data.data)
          }
        })
    },
    openScoreBoard() {
      window.open(`/student-role/${this.selectedClassRoom}`, '_blank', 'toolbar=no,scrollbars=yes,resizable=yes,top=500,left=500,width=625,height=400')
    },
    showClassRoomScript() {
      this.isNoteOpen = 'class-script'
      this.showClassScript = true
    },
    exportPdf() {
      const opt = {
        pagespilt: true,
        filename: `lesson_page_ebook_${this.pageId}.pdf`,
        image: { type: 'jpeg', quality: 0.20 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'p' },
      }

      html2pdf().set(opt).from(document.getElementById('overlay-background')).save()
    },
    setActiveLanguage(language) {
      this.openTranslationModal(this.activeWord)
      this.activeLanguage = language
    },
  },
}
</script>

<style lang="css">
.content-left {
  float: none !important;
  width: 100% !important;
}
.content-left p {
  color: black;
}
.modal .modal-custom {
  max-width: 1400px;
  width: 1400px;
}
.wordnet_popup {
  font-style: italic;
  font-weight: 500;
}
.wordnet_popup:hover {
  font-style: underline;
}

.lesson-page p {
  line-height: 1.5 !important;
}
</style>

<style lang="scss">
@import "/src/assets/scss/custom/lesson-themes.scss";
</style>
<style scoped>
.floating {
  position: fixed;
  top: 18%;
  right: 2%;
  z-index: 1000;
}
.message-box {
  height: 0;
  width: 0;
  transition: all 200ms ease;
  display: none;
}
.expanded {
  height: auto !important;
  width: auto !important;
  display: block !important;
}
.has-notes{
  background-color: #e67e22 !important;
}
.toggle-button {
  height: 50px;
  background-color: #e74c3c;
  width: 50px;
  border: 2px solid white !important;
}
.toggle-button-download {
  height: 50px;
  background-color: #237636;
  width: 50px;
  border: 2px solid white !important;
}
.script-button {
  height: 50px;
  background-color: #762323;
  width: 50px;
  border: 2px solid white !important;
}
.scoreboard-button {
  height: 50px;
  background-color: #1f27a0;
  width: 50px;
  border: 2px solid white !important;
}
.close-button {
  height: 22px;
  width: 22px;
  position: absolute;
  right: -12px;
  top: -12px;
}
.note-field{
  white-space: pre-line
}

.right-section{
  overflow: auto;
  height:100vh;
  right:0;
  padding-bottom: 20px;
  overscroll-behavior: contain;
}
</style>
