<template>
  <b-row>
    <b-col>
      <stats />
    </b-col>
    <b-col>
      <lesson-report
        :number="1"
        :lessons="[]"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import Stats from './components/Stats.vue'
import LessonReport from '../../common/class/components/LessonsReport.vue'

export default {
  components: {
    Stats, BRow, BCol, LessonReport,
  },
  methods: {
    getStudentLessonStats(studentIndex, lessonIndex) {
      const student = this.students[studentIndex]
      this.isProcessing = true
      useJwt.getStudentLessonStats(this.classId, student.student_id, student.lessons[lessonIndex].lesson_id).then(res => {
        this.students[studentIndex].lessons[lessonIndex].problems = res.data.data.tests
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>

<style>

</style>
