<template>
  <b-card title="Filter">
    <b-row>
      <b-col md="3">
        <label for="startDate">{{ $t('marketplace-module.start_time') }}</label>
        <b-form-datepicker id="startDate"
                           v-model="form.start_date"
                           :placeholder="$t('higher-level-report-module.no-date-selected')"
                           @change="updateChange"
        />
      </b-col>
      <b-col md="3">
        <label for="endDate">{{ $t('marketplace-module.end_time') }}</label>
        <b-form-datepicker id="endDate"
                           v-model="form.end_date"
                           :placeholder="$t('higher-level-report-module.no-date-selected')"
                           @change="updateChange"
        />
      </b-col>
      <b-col md="3">
        <label for="studentName">{{ $t('student-name') }}</label>
        <b-form-input id="studentName"
                      v-model.lazy="form.student_name"
                      :placeholder="$t('enter-student-name')"
                      @change="updateChange"
        />
      </b-col>
      <b-col v-if="isSuperAdmin"
             md="3"
      >
        <label for="school-filter">{{ $t('user-types.school') }}</label>
        <v-select
          id="school-filter"
          v-model="form.school_id"
          :options="schoolOptions"
          label="name"
          multiple
          :placeholder="$t('select-school')"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BFormDatepicker, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BFormInput,
    'v-select': vSelect,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      self: this.AUTH_USER(),
      startDate: null,
      endDate: null,
      studentName: '',
      selectedSchool: [],
      schoolOptions: [
        { id: 1, name: 'School A' },
        { id: 2, name: 'School B' },
        { id: 3, name: 'School C' },
        { id: 4, name: 'School D' },
      ],
    }
  },
  computed: {
    isSuperAdmin() {
      return this.self.usertype === 'super'
    },
  },
  methods: {
    updateChange() {
      this.$emit('onChange')
    },
  },
}
</script>
