<template>
  <div>
    <b-row>
      <b-col>
        <layout-cover
          :is-edit-mode="isEditMode"
          :settings="layoutSetting.BIG"
          :style="[layoutSetting && layoutSetting.BIG, getComputedStyles(layoutSetting.BIG)]"
          @updateLayout="value => $emit('updateLayout', value, 'BIG')"
        >
          <slot name="big" />
        </layout-cover>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <layout-cover
          :is-edit-mode="isEditMode"
          :settings="layoutSetting.SMALL"
          :style="[layoutSetting && layoutSetting.SMALL, getComputedStyles(layoutSetting.SMALL)]"
          @updateLayout="value => $emit('updateLayout', value, 'SMALL')"
        >
          <slot name="small" />
        </layout-cover>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import LayoutCover from '../../../helper/LayoutCover.vue'

export default {
  components: {
    BRow, BCol, LayoutCover,
  },
  props: {
    layoutSetting: {
      type: Object,
      default: () => {},
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    getComputedStyles: {
      type: Function,
      required: true,
    },
  },
}
</script>
