<template>
  <b-row
    id="fibDropDownProblem"
  >
    <b-col
      class="problem-card-title text-center"
      md="12"
    >
      <h4 class="mb-0">
        {{ getProblemHintsDescription($t('student-test-module.fill-problem-hint')) }}
      </h4>
    </b-col>

    <b-col
      md="12"
      class="problem-overview-content"
    >
      <div
        v-if="defaultAnswers.length > 1 && shouldShowHints.hints"
        class="text-center"
      >
        <div
          v-if="feedbackQuestionComputed.length > 0"
          class="d-flex justify-content-center"
        >
          <div
            v-for="ans,index in feedbackQuestionComputed"
            :key="index"
            no-body
            class="mr-2"
          >
            <b-img
              v-if="shouldShowHints.image_hints"
              :src="ans.image_url || ans.image"
              thumbnail
              fluid
              style="height: 50px; width: 50px;"
            />
            <div
              v-if="shouldShowHints.text_hints"
              class="small text-bold"
              v-html="ans.title"
            />
          </div>
        </div>
        <template v-else-if="shouldShowHints.text_hints">
          <span
            v-for="ans,index in defaultAnswers"
            :key="index"
            class="mr-2"
            v-html="ans"
          />
        </template>
      </div>
      <div v-if="bodyAudio">
        <audio-player :src="bodyAudio" />
      </div>
      <validation-observer ref="submitQuestionForm">
        <div class="ck-content">
          <!-- eslint-disable vue/no-v-html -->
          <span
            v-if="fullHtml"
            v-html="fullHtml"
          />
          <!-- eslint-enable vue/no-v-html -->
          <!-- <span v-html="fullHtml" /> -->
          <div
            v-else
            class="ck-content"
          >
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="template && template.before_template && template.before_template.length >0
                &&
                ![
                  'Shapes',
                  'SideCard',
                ].includes(getTemplateComponent)
                ||
                (getTemplateComponent === 'Shapes' && template.template[0].type !== 'fib')
              "
              v-html="beforeHtml"
            />
            <!-- eslint-enable vue/no-v-html -->
            <component
              :is="getTemplateComponent"
              v-if="template && template.template"
              :place-holder="placeHolder"
              :data="template.template"
              :is-a-problem="true"
              :answers="selectAnswers"
              :template-options="getTemplateOptions"
            >
              <div
                v-if="[
                  'Shapes',
                  'SideCard',
                ].includes(getTemplateComponent) ||
                  (getTemplateComponent === 'Shapes' && template.template[0].type !== 'fib')"
                v-html="beforeHtml"
              />
            </component>
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="template && template.after_template && template.after_template.length>0"
              v-html="afterHtml"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
          <span
            v-for="(question, index) in localQuestions"
            v-show="false"
            :key="'question_' + index"
          >
            <validation-provider
              v-if="question == placeHolder"
              v-slot="{ errors }"
              :name="'Field_' + (index + 1)"
              rules="required"
            >
              <b-form-select
                :id="'Field_' + (index + 1)"
                v-model="answers[index]"
                :disabled="mode==='feedback'"
                style="display: inline-block; width: 150px;margin-top:2px"
                class="mx-1"
                :state="errors.length > 0 ? false : null"
                size="sm"
                :name="'Field_' + (index + 1)"
                @change="checkValidation"
              />
            </validation-provider>
            <!-- eslint-disable vue/no-v-html -->
            <span
              v-else
              v-html="question"
            />
            <!-- eslint-enable vue/no-v-html -->
          </span>
        </div>
      </validation-observer>
    </b-col>
    <portal
      to="problemFeedBack"
    >
      <b-row>
        <b-col md="12"
               class="p-0"
        >
          <feedback
            :feedback="feedback"
            :problem-statement-id="problemStatementId"
          />
        </b-col>
        <b-col
          v-if="!isPreview"
          class="m-0 p-0"
          md="12"
        >
          <actions
            :has-feedback="hasFeedback"
            :is-processing="isProcessing"
            :level="level ? level : ''"
            :on-submit="submit"
            :disabled="!isValidated"
            :tooltip-text="$t('student-test-module.fill-problem-validation-message')"
            :display-result="displayResult"
            :has-minimal-view="hasMinimalView"
            :button-title="hasFeedback ? $t('next') : $t('submit')"
            :engine="engine"
            :school-settings="schoolSettings"
            :district-settings="districtSettings"
            v-on="$listeners"
          />
        </b-col>
      </b-row>
    </portal>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormSelect, BImg, BCard,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import { mapGetters } from 'vuex'
import Paragraph from '@/views/common/templates/components/Paragraph.vue'
import ImageParagraph from '@/views/common/templates/components/ImageParagraph.vue'
import Shapes from '@/views/common/templates/components/Shapes.vue'
import ImageQA from '@/views/common/templates/components/ImageQA.vue'
import CenteredCard from '@/views/common/templates/components/CenteredCard.vue'
import Cards from '@/views/common/templates/components/Cards.vue'
import SideCard from '@/views/common/templates/components/SideCard.vue'
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'
import Actions from './Actions.vue'
import Feedback from './Feedback.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormSelect,
    Feedback,
    Actions,
    Paragraph,
    ImageParagraph,
    Shapes,
    ImageQA,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BCard,
    CenteredCard,
    Cards,
    SideCard,
    AudioPlayer,
  },
  props: {
    questions: {
      type: Array,
      required: true,
    },
    previewAnswers: {
      type: Array,
      default: () => [],
    },
    isProcessing: {
      type: Boolean,
      required: true,
    },
    feedback: {
      type: Object,
      default: () => {},
    },
    displayResult: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      default: 1,
    },
    feedbackQuestion: {
      type: Array,
      default: () => [],
    },
    problemStatementId: {
      type: [Number, String],
      required: false,
    },
    rules: {
      type: Object,
      default: () => null,
    },
    startTime: {
      type: [String, Date],
      required: false,
      default: () => null,
    },
    engine: {
      type: String,
      default: () => '',
    },
    hasMinimalView: {
      type: Boolean,
      default: false,
    },
    schoolSettings: {
      type: Array,
      default: () => [],
    },
    districtSettings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      placeHolder: '[ ]',
      localQuestions: [],
      isValidated: false,
      answers: {},
      fullHtml: '',
      template: null,
      beforeHtml: '',
      afterHtml: '',
      centralHtml: '',
      defaultShape: 'hexagon.png',
      fibType: 'fib',
    }
  },
  computed: {
    ...mapGetters('problem', {
      validation: 'getValidations',
      shapes: 'getShapes',
    }),
    getTemplateComponent() {
      if (this.template?.type === 'paragraph') return 'Paragraph'
      if (this.template?.type === 'image-paragraph') return 'ImageParagraph'
      if (this.template?.type === 'image-qa') return 'ImageQA'
      if (this.template?.type === 'shapes') return 'Shapes'
      if (this.template?.type === 'centered-cards') return 'CenteredCard'
      if (this.template?.type === 'cards') return 'Cards'
      if (this.template?.type === 'side-cards') return 'SideCard'
      return ''
    },
    bodyAudio() {
      return this.questions[0]?.extra_file
    },
    shouldShowHints() {
      // eslint-disable-next-line eqeqeq
      const image = this.rules?.hide_image_hints != '1'
      // eslint-disable-next-line eqeqeq
      const text = this.rules?.hide_text_hints != '1'
      const isHiddenByDistrict = this.$store.getters['studentLab/isProblemHintsHiddenByDistrict']
      return {
        // eslint-disable-next-line eqeqeq
        hints: !isHiddenByDistrict && this.rules?.hide_hints != '1' && (image || text),
        image_hints: image,
        text_hints: text,
      }
    },
    hasFeedback() {
      return !!(this.feedback?.text?.length > 0 || this.feedback?.image || this.feedback?.audio || this.feedback?.template)
    },
    selectAnswers() {
      return this.questions[0].answers.slice().sort(() => Math.random() - 0.5)
    },
    getTemplateOptions() {
      if (this.template?.type !== 'shapes') return ''
      const sh = this.template?.template[0]?.shape ? this.template?.template[0]?.shape : this.defaultShape

      return {
        shape: this.shapes.filter(shape => shape.value === sh)[0],
        type: this.template?.template[0]?.type ? this.template?.template[0]?.type : this.fibType,
        bgColor: this.template?.template[0]?.bgColor,
        textAlign: this.template?.template[0]?.textAlign ? this.template?.template[0]?.textAlign : 'left',
      }
    },
    feedbackQuestionComputed() {
      if (this.feedback?.template?.template) {
        return this.feedback?.template?.template
      }
      return this.feedbackQuestion
    },
    defaultAnswers() {
      let answers = []
      // eslint-disable-next-line no-unused-expressions
      this.questions?.forEach(question => {
        answers = [...answers, ...question.answers.map(ans => ans.text)]
      })
      // eslint-disable-next-line eqeqeq
      if (this.rules?.disable_random_hints != '1') {
        answers.sort(() => Math.random() - 0.5)
      }
      return answers
    },
  },

  watch: {
    question() {
      this.initData()
    },
    validation() {
      this.setValidationClasses()
    },
  },

  created() {
    document.addEventListener('input', e => {
      const element = e.target
      if (element.className.includes('answer')) {
        const index = element.getAttribute('data-id')
        this.answers[index] = element.value
        this.checkValidation()
        this.$forceUpdate()
      }
    })
    this.initData().then(() => {
      if (this.previewAnswers && this.previewAnswers.length) {
        const inputFields = document.querySelectorAll('.answer')
        inputFields.forEach((field, index) => {
          const answer = this.previewAnswers[index].input
          // eslint-disable-next-line no-param-reassign
          field.value = answer
          this.answers[index] = this.previewAnswers[index].input
        })

        this.setValidationClasses()
      }
    })
    if (!window.adjustSelectWidth) {
      window.adjustSelectWidth = select => {
        const selectedText = select.options[select.selectedIndex].text
        const width = selectedText.length * 10 // Adjust the multiplier as needed
        // eslint-disable-next-line no-param-reassign
        select.style.width = `${width}px`
      }
    }
  },

  methods: {

    initData() {
      return new Promise(resolve => {
        try {
          this.template = JSON.parse(this.questions[0].text)
          this.template.answers = this.questions[0].answers
          if (
            this.template?.template?.[0]?.type === 'qa'
            || this.template?.type === 'image-qa'
            || this.template?.type === 'image-paragraph'
          ) {
            this.formatQA(this.template?.template, 'subtitle')
          }

          if (this.template?.before_template) {
            this.beforeHtml += this.formatQuestion(this.template?.before_template, 'before', 0)
          }

          if (this.template?.after_template) {
            this.afterHtml += this.formatQuestion(this.template?.after_template, 'after', 0)
          }

          if (Array.isArray(this.template?.template)) {
            this.template.template.forEach((item, index) => {
              // eslint-disable-next-line no-param-reassign
              item.subtitle = this.formatQuestion(item.subtitle, 'before', index)
              // eslint-disable-next-line no-param-reassign
              item.title = this.formatQuestion(item.title, 'before', index)
            })
          }
        } catch {
          this.answers = {}
          let question = this.questions[0].text
          question = question.replaceAll(this.placeHolder, `$${this.placeHolder}$`)
          this.localQuestions = question.split('$').filter(e => e.length > 0)
          this.localQuestions.forEach((element, index) => {
            if (element === this.placeHolder) {
              this.answers[index] = ''
              this.fullHtml += `
                <select
                  data-id="${index}"
                  :id="'Field_' + ${(index)}"
                  style="display: inline-block; width: 150px; margin-top: 2px;"
                  class="mx-1 answer fib-answer form-control"
                  :name="'Field_' + ${(index + 1)}"
                  onchange="adjustSelectWidth(this)"
                >
                <option data-id="${index}" selected disabled>Select an answer</option>
                `
              if (this.questions[0] && this.questions[0].answers.length) {
                this.questions[0].answers.forEach(answer => {
                  this.fullHtml += `<option data-id="${index}" value="${answer.text}">${answer.text}</option>`
                })
              }
              this.fullHtml += '</select>'
            } else {
              this.fullHtml += element
            }
          })
        } finally {
          resolve()
          setTimeout(() => {
            if (window.adjustSelectWidth) {
              const selects = document.querySelectorAll('select.fib-answer')
              selects.forEach(select => {
                window.adjustSelectWidth(select)
              })
            }
          }, 1000)
        }
      })
    },
    fixHtmlSpecialCharacter(text) {
      if (!text) return text
      return text.replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'")
        .replace('&amp;rsquo;', "'")
    },
    formatQuestion(question, dataString, ansIndex) {
      let html = ''
      let answerIndex = ansIndex ?? 0
      const questions = this.questions[0] || {}
      const questionAnswers = questions?.answers || []
      questionAnswers.sort((a, b) => a.id - b.id)
      questions.questionAnswers = questionAnswers
      question
        .replaceAll(this.placeHolder, `$${this.placeHolder}$`)
        .split('$')
        .filter(e => e.length > 0)
        .forEach((element, index) => {
          if (element === this.placeHolder) {
            // remove subtitle keys when fib is enabled
            delete this.answers['subtitle-0-0']
            this.answers[`${dataString}-${index}-${ansIndex}`] = ''
            html += `
              <select
                :id="'Field_' + ${(index + 1)}"
                style="display: inline-block; width: 150px;margin-top:2px"
                data-id="${dataString}-${index}-${ansIndex}"
                class="mx-1 answer fib-answer form-control"
                :name="'Field_' + ${(index + 1)}"
                onchange="adjustSelectWidth(this)"
              >
                <option selected disabled>
                  Select an answer
                </option>
              `
            if (questions && questions.answers.length) {
              let answers = []
              questions.answers.map(answer => answer.text).forEach(answer => {
                answers.push([answer])
              })
              answers = answers.reduce((prev, next) => prev.concat(next))
              answers = questions.answers[answerIndex]?.text.split(',')
              answers.sort(() => Math.random() - 0.5)
              answers.forEach(answer => {
                html += `
                  <option
                    value="${answer}"
                    data-id="${dataString}-${index}"
                  >
                    ${this.fixHtmlSpecialCharacter(answer)}
                  </option>`
              })
              answerIndex += 1
            }
            html += '</select>'
          } else {
            html += element
          }
        })
      return html
    },
    formatQA(question, dataString) {
      for (let index = 0; index < question.length; index += 1) {
        this.answers[`${dataString}-${index}-0`] = ''
      }
    },
    checkValidation() {
      this.isValidated = this.validate()
    },
    validate() {
      const keys = Object.keys(this.answers)
      for (let i = 0; i < keys.length; i += 1) {
        if (this.answers[keys[i]] === '') {
          return false
        }
      }
      return true
    },
    sortAnswerByKey(ansObj) {
      // eslint-disable-next-line radix
      const extractNumber = key => parseInt(key.split('-')[1])

      // Sorting the object keys based on the extracted number
      const sortedKeys = Object.keys(ansObj).sort((a, b) => extractNumber(a) - extractNumber(b))

      // Creating a new object with the sorted keys
      const sortedAnswers = []
      sortedKeys.forEach(key => {
        sortedAnswers.push(key)
      })
      return sortedAnswers
    },
    submit() {
      this.$refs.submitQuestionForm.validate().then(success => {
        if (success) {
          const answers = []
          this.sortAnswerByKey(this.answers).forEach(key => {
            answers.push(this.answers[key])
          })
          this.$emit('submitQuestion', { lang_level: this.questions[0].lang_level, answer: answers })
        }
      })
    },
    setValidationClasses() {
      const answers = document.querySelectorAll('.answer')
      answers.forEach((answer, index) => {
        let isCorrect
        if (this.validation.length) isCorrect = this.validation[index]?.is_correct
        else isCorrect = this.previewAnswers[index]?.is_correct
        answer.classList.add(`answer-${isCorrect}`)
      })
    },
  },
}
</script>

<style lang="scss">
.inline-p{
  display: inline-block;
}
.fib-answer {
  min-width: 160px !important;
}
.answer-1 {
  border: 2px solid #2eb82e !important;
}
.answer-0 {
  border: 2px solid #df0000 !important;
}
</style>
