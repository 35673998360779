<template>
  <section class="bg-white p-1 rounded">
    <!-- <h4>Marketplace</h4> -->
    <template v-if="isProcessing">
      <b-row>
        <b-col cols="12"
               class="h-100 d-flex justify-content-center align-items-center"
        >
          <b-spinner variant="primary" />
        </b-col>
      </b-row>
    </template>
    <template v-if=" !isProcessing">
      <template v-if="Object.keys(products).length">
        <b-row v-for="items,productType in products"
               :key="productType"
        >
          <b-col cols="12"
                 class="pb-1"
          >
            <h4>
              {{ getWearType(productType) }}
            </h4>
          </b-col>
          <b-col v-for="item in items"
                 :key="item.id"
                 lg="2"
                 md="4"
                 sm="6"
          >
            <b-card no-body
                    class=""
            >
              <div
                class="pb-1 text-right"
                style="padding:3px;"
              >
                <b-badge v-if="item.access_type=='free'"
                         v-b-tooltip="$t('engagement-setting-module.free-item-tooltip')"
                         variant="success"
                >
                  {{ $t('engagement-setting-module.free-item') }}
                </b-badge>
                <!-- <h6 v-else-if="item.selling_quantity>0"
                class="text-warning"
            >
              {{ item.selling_quantity }} on sale
            </h6> -->
              </div>

              <div class="d-flex justify-content-center">
                <img :src="item.image"
                     height="70"
                     width="70"
                >
              </div>
              <h6 class="text-center mt-1 pb-1">
                <strong> {{ item.name }} (x{{ (item.total) || 1 }}) </strong>
              </h6>
              <div v-if="item.access_type=='full_access'"
                   class="text-center pb-1"
              >
                <b-badge variant="warning"
                         class="cursor-pointer"
                         @click="sellItem(item)"
                >
                  <feather-icon icon="ShoppingCartIcon" />
                  {{ $t('engagement-setting-module.sell') }}
                </b-badge>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </template>
      <b-row v-else
             class="bg-white p-2 rounded"
      >
        <b-col cols="12"
               class="text-center py-5"
        >
          <h4>
            {{ $t('engagement-setting-module.no-items') }}
          </h4>
          <b-button variant="success"
                    class="mt-1"
                    @click="$router.push('student/animated-items-marketplace')"
          >
            {{ $t('engagement-setting-module.click-to-buy') }}

          </b-button>
        </b-col>
      </b-row>
    </template>
    <sell-modal :product="sellingProduct"
                @onSell="fetchMyItems()"
    />
  </section>
</template>
<script>

import {
  BRow, BCol, BCard, BBadge, BButton, BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { wearableTypes } from '@/const/theme'
import SellModal from '../components/SellModal.vue'

export default {
  components: {
    // BButton,
    BBadge,
    BCard,
    BRow,
    BSpinner,
    BCol,
    BButton,
    SellModal,
  },
  data() {
    return {
      products: [],
      isProcessing: true,
      sellingProduct: {},
      wearableTypes,
    }
  },

  mounted() {
    this.fetchMyItems()
  },

  methods: {

    fetchMyItems() {
      this.isProcessing = true
      useJwt.myAnimatedItems().then(res => {
        this.products = res.data.data
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },

    sellItem(product) {
      this.$bvModal.show('sell-animation-modal')
      this.sellingProduct = product
    },

    getWearType(type) {
      return this.wearableTypes.find(wearableType => wearableType.value === type).text
    },
  },
}

</script>

<style lang="scss">
.product-row{
  border-bottom: 1px solid rgb(204, 201, 201);
  margin-bottom:10px;
  padding-bottom:10px;
}

.trade-button{
  border-radius:50px;
}

.price-info{
  padding-top:2px;
}
</style>
