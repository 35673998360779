<template>
  <b-nav-item-dropdown
    ref="noteDropdown"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>

      <feather-icon
        badge-classes="bg-danger"
        icon="ClipboardIcon"
        style="color:#e67e22;"
        size="21"
      />

    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('student-notes-module.notes') }}
        </h4>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="note in notes"
        :key="note.id"
        @click="viewNote(note)"
      >
        <b-media
          class="notification-card"
        >

          <div class="media-heading">
            <div class="d-flex justify-content-between">
              <span class="font-weight-bolder">
                {{ note.problem_name }}
              </span>
              <!-- <span class="notification-dot" /> -->
              <span @click.stop="deleteNote(note.id)">
                <feather-icon icon="XIcon" />
              </span>
            </div>
          </div>
          <h3
            class="notification-text"
            style="margin:4px 0"
            v-html="note.content"
          />

          <p
            style="font-size:10px"
            class="text-right m-0 p-0"
          >
            {{ note.updated_at }}
          </p>

        </b-media>
      </b-link>
      <p
        v-if="notes.length==0"
        class="px-1 pt-1"
      >
        {{ $t('student-notes-module.no-notes') }}
      </p>
    </vue-perfect-scrollbar>
    <div class="text-center">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="flat-primary"
        block
        @click="$router.push({ name: 'view-notes-all' });$refs.noteDropdown.hide(true)"
      >
        <feather-icon icon="ExternalLinkIcon" /> {{ $t('view-all') }}
      </b-button>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BMedia, BLink, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'

export default {
  components: {
    BNavItemDropdown,
    BMedia,
    BLink,
    VuePerfectScrollbar,
    BButton,
    // BDropdownItem,
    // BDropdown,
    // BFormCheckbox,
  },
  computed: {
    ...mapGetters({
      notes: 'studentNotes/allNotes',
    }),
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.$store.dispatch('studentNotes/getStudentNotes')
  },
  methods: {
    deleteNote(note) {
      this.$store.dispatch('studentNotes/deleteNote', note)
    },
    viewNote(note) {
      this.$store.dispatch('studentNotes/displayNote', note)
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
}
</script>
<style lang="css">
.dropdown-menu .dropdown-toggle {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
