<template>
  <div>
    <b-button variant="primary"
              :disabled="isProcessing"
              @click="checkAccuracyByTestStat"
    >
      <b-spinner v-show="isProcessing"
                 small
      /> <feather-icon icon="CpuIcon" /> Check AI Score
    </b-button>
    <component :is="modalComponent"
               v-if="showModal"
               :visible="true"
               title="Ai Score"
               modal-class="modal-big"
               style="z-index: 999999999999999999999999;"
               @close="showModal = false;"
               @hide="showModal = false;"
    >
      <h5
        slot="title"
        class="m-0 text-capitalize"
      >Ai Score
      </h5>

      <b-row v-if="!scoreSummary.isReady"
             class="p-2"
      >
        <b-col md="4"
               class="mt-2"
        >
          <b-skeleton v-for="i of 4"
                      :key="i"
          />
        </b-col>
        <b-col>
          <b-skeleton-img />
        </b-col>
      </b-row>
      <div v-if="allResponses.length > 1">
        <b-button v-for="res,index of allResponses"
                  :key="index"
                  :variant="index == activeAnswer ? 'primary' : 'outline-primary'"
                  class="mr-1"
                  @click="setAnswerData(index); activeAnswer = index"
        >
          Answer {{ index + 1 }}
        </b-button>
      </div>

      <b-row v-if="scoreSummary.isReady"
             class="p-2"
      >
        <b-col md="4">
          <AccuracyScore :scores="scoreSummary.accuracyScores" />
        </b-col>
        <b-col>
          <div class="text-center">
            <AudioPlayer :key="scoreSummary.userVoice"
                         :src="scoreSummary.userVoice"
                         alignment="center"
                         :include-graph="true"
            />
          </div>
          <div class="d-flex mt-1">
            <div v-for="sd,index of scoreDescription"
                 :key="index"
                 class="mr-2"
            >
              <button
                type="button"
                class="btn btn-icon rounded-circle"
                :class="'btn-' + sd.variant"
              />
              &nbsp;{{ sd.text }}
            </div>
          </div>
          <div class="mt-2">
            <span v-for="word,index of scoreSummary.words"
                  :id="`accuracy-word-${index}`"
                  :key="index"
                  class="words-result"
                  :class="getScoreColor(word.AccuracyScore).color"
            >
              <span v-if="word.isPaused">/</span> {{ word.Word }}
              <b-popover
                v-if="word.ErrorType !== 'None'"
                :target="`accuracy-word-${index}`"
                placement="top"
                triggers="click"
              >
                <template #title>
                  Score
                </template>
                <b-list-group flush>
                  <b-list-group-item>
                    <b-progress :max="100"
                                :value="word.AccuracyScore"
                                show-label
                                :variant="getScoreColor(word.AccuracyScore).color?.replace('text-', '')"
                    />
                  </b-list-group-item>
                  <b-list-group-item>
                    {{ word.ErrorType }}
                  </b-list-group-item>
                  <b-list-group-item>
                    /<span v-for="s,k of word.Syllables"
                           :key="k"
                    >
                      {{ s.Syllable }}
                    </span>/  <feather-icon v-play-voice
                                            icon="Volume2Icon"
                                            role="button"
                                            :text="word.Word"
                    />
                  </b-list-group-item>
                </b-list-group>
              </b-popover>
            </span>
          </div>
        </b-col>
      </b-row>
    </component>
  </div>
</template>
<script setup>
import {
  BButton, BSpinner, BPopover, BListGroup, BListGroupItem, BRow, BCol, BProgress, BSkeleton, BSkeletonImg, BModal,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import {
  ref, getCurrentInstance, reactive, computed,
} from 'vue'
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'
import DialogDrag from 'vue-dialog-drag'
import AccuracyScore from './accuracy-chart.vue'

const props = defineProps({
  testStatId: {
    type: Number,
    default: 0,
  },
  isReportView: {
    type: Boolean,
    default: false,
  },
  isDemo: {
    type: Boolean,
    default: false,
  },
})

const isProcessing = ref(false)
const showModal = ref(false)
const root = getCurrentInstance().proxy.$root
const allResponses = ref([])
const activeAnswer = ref(0)
const scoreSummary = reactive({
  isReady: false,
  accuracyScores: [],
  words: [],
  userVoice: null,
})
const withPauses = speechSegments => {
  const pauses = []

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < speechSegments.length; i++) {
    // Calculate pause duration as the time between the end of the current segment and the start of the next segment
    const pauseDuration = (speechSegments[i].Offset - (speechSegments[i - 1]?.Offset + speechSegments[i - 1]?.Duration)) / 1e+9

    // Add pause information to the list
    pauses.push({
      ...speechSegments[i],
      PauseDuration: pauseDuration,
      From: speechSegments[i - 1]?.Word || '',
      To: speechSegments[i].Word,
      isPaused: pauseDuration > 0.01,
    })
  }

  return pauses
}

const setAnswerData = index => {
  const result = allResponses.value[index]
  const nBEst = result.summary.NBest[0]
  scoreSummary.isReady = true
  scoreSummary.accuracyScores = [
    {
      text: 'Accuracy Score',
      value: nBEst.AccuracyScore,
    },
    {
      text: 'Completeness Score',
      value: nBEst.CompletenessScore,
    },
    {
      text: 'Fluency Score',
      value: nBEst.FluencyScore,
    },
    {
      text: 'Pronunciation Score',
      value: nBEst.PronScore,
    },
  ]

  scoreSummary.words = withPauses(nBEst.Words)
  scoreSummary.userVoice = result.user_voice
}

const checkAccuracyByTestStat = () => {
  showModal.value = true
  if (!scoreSummary.isReady) {
    isProcessing.value = true
    useJwt.getSpeechAccuracyByTestStat(props.testStatId, {
      is_demo: props.isDemo,
    }).then(response => {
      // const result = response.data.data
      allResponses.value = response.data.data.data
      setAnswerData(0)
    }).finally(() => {
      isProcessing.value = false
    }).catch(error => {
      console.error(error)
      root.showErrorMessage(error)
      // showModal.value = false
    })
  }
}

const scoreDescription = [
  {
    text: 'Good',
    variant: 'success',
  },
  {
    text: 'Average',
    variant: 'warning',
  },
  {
    text: 'Bad',
    variant: 'danger',
  },
]

const getScoreColor = score => {
  if (score < 50) {
    return {
      text: 'Good',
      color: 'text-danger',
    }
  }
  if (score >= 50 && score <= 80) {
    return {
      text: 'Average',
      color: 'text-warning',
    }
  }
  return {
    text: 'Good',
    color: 'text-success',
  }
}

const modalComponent = computed(() => (props.isReportView ? DialogDrag : BModal))
</script>
<style>
    .words-result {
        cursor: pointer;
        font-size: large;
    }
    /* .modal-big + div {
      z-index: 999999999999999 !important;
    } */
    .modal-big .modal-dialog {
      min-height: 200px;
    }
    @media (min-width: 992px) {
      .modal-big .modal-dialog {
        max-width: 1200px !important;
      }
    }

</style>
