<template>
  <div>
    <b-tabs pills>
      <b-tab title="Lesson Mapper">
        <LessonMappers />
      </b-tab>
      <b-tab title="Classroom Mapper">
        <ClassroomMappers />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import LessonMappers from './components/LessonMappers.vue'
import ClassroomMappers from './components/ClassroomMappers.vue'

export default {
  components: {
    BTabs,
    BTab,
    LessonMappers,
    ClassroomMappers,
  },
  data() {
    return {
    }
  },
}
</script>
