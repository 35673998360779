<template>
  <div>
    <h2>
      {{ $t('user-module.user-activity.of-current-month') }}
    </h2>
    <b-overlay :show="isLoading">
      <b-table
        id="activities-table"
        :fields="columns"
        :items="activities"
        show-empty
        responsive
      >
        <template #cell(action)="data">
          <div class="d-flex justify-content-start">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-delete-user
              :disabled="isLoading"
              variant="outline-primary"
              class="btn-icon"
              @click.stop="showLogDetail(data.item)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="activities-table"
        @change="fetchUserActivities"
      />
    </b-overlay>
    <b-modal
      content-class="shadow"
      :title="$t('user-module.user-activity.detail-title')"
      no-fade
      size="lg"
      ok-only
      :ok-title="$t('ok')"
      :visible="showLogModal"
      no-close-on-backdrop
      @close="showLogModal = false"
      @ok="showLogModal = false"
    >
      <b-row>
        <b-col md="5">
          <b-card
            no-body
          >
            <b-card-header>
              <h5>{{ $t('user-module.user-activity.activity-brief') }}</h5>
            </b-card-header>
            <b-card-body>
              <ul class="p-0">
                <li><strong>Id:</strong> {{ activityDetail.id }}</li>
                <li><strong>{{ $t('user-module.user-activity.user-id') }}:</strong> {{ activityDetail.user_id }}</li>
                <li><strong>{{ $t('user-module.user-activity.activity') }}:</strong> {{ activityDetail.activity }}</li>
                <li><strong>{{ $t('user-module.user-activity.description') }}:</strong> {{ activityDetail.description }}</li>
                <li><strong>{{ $t('user-module.user-activity.date-utc') }}:</strong> {{ activityDetail.date_UTC }}</li>
              </ul>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col md="7">
          <b-card
            no-body
          >
            <b-card-header>
              <h5>{{ $t('user-module.user-activity.activity-detail') }}</h5>
            </b-card-header>
            <b-card-body>
              <template v-if="activityDetail.properties">
                <ul class="p-0">
                  <li v-for="value, key in activityDetail.properties"
                      :key="key"
                  >
                    <strong>{{ formatBrowserName(key) }}:</strong> {{ value }}
                  </li>
                </ul>
              </template>
              <template v-else>
                <p>{{ $t('user-module.user-activity.no-detail-available') }}</p>
              </template>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BTable, BPagination, BOverlay, BButton, BModal, BRow, BCol, BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    BTable,
    BPagination,
    BOverlay,
    BButton,
    BModal,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
  },
  data() {
    return {
      isLoading: false,
      activities: [],
      perPage: 15,
      currentPage: 1,
      totalRows: 0,
      showLogModal: false,
      columns: [
        { key: 'id', label: 'ID' },
        { key: 'activity', label: i18n.tc('user-module.user-activity.activity') },
        { key: 'description', label: i18n.tc('user-module.user-activity.description') },
        { key: 'user_id', label: i18n.tc('user-module.user-activity.user-id') },
        { key: 'date_UTC', label: i18n.tc('user-module.user-activity.date-utc') },
        { key: 'action', label: i18n.tc('class-module.class-table.actions') },
      ],
      activityDetail: {},
    }
  },
  mounted() {
    this.fetchUserActivities()
  },
  methods: {
    fetchUserActivities(page = 1) {
      this.isLoading = true
      useJwt.getUsersActivities(page)
        .then(response => {
          this.activities = response.data.data.data
          this.totalRows = response.data.data.total
        })
        .catch(error => {
          console.error('Error fetching user activities:', error)
        }).finally(() => {
          this.isLoading = false
        })
    },
    showLogDetail(data) {
      this.showLogModal = true
      this.activityDetail = data
    },
    formatBrowserName(value) {
      return value.replace(/_/g, ' ').replace(/\b\w/g, firstLetter => firstLetter.toUpperCase())
    },
  },
}
</script>
