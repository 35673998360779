<template>
  <b-modal :visible="true"
           title="Schedules"
           size="lg"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <b-overlay :show="isProcessing">
      <div v-if="!oldReservation">
        <b-row>
          <b-col v-for="(schedule, index) in schedules"
                 :key="index"
                 cols="6"
          >
            <ScheduleCard :schedule="schedule"
                          @applySchedule="scheduleToApply = schedule"
            />
          </b-col>
        </b-row>
        <div v-if="customSchedule"
             class="text-center large"
        >
          <h4 class="text-muted">
            -OR-
          </h4>
          <b-button variant="outline-primary"
                    class="mt-1 mb-2"
                    @click="showCustomSchedule = true"
          >
            Create Custom Schedule
          </b-button>
          <CustomSchedule :show="showCustomSchedule"
                          :all-schedules="schedules"
                          :reservation-id="reservationId"
                          :custom-schedule="customSchedule"
                          @applySchedule="schedule => {
                            scheduleToApply = schedule;
                            showCustomSchedule = false;
                          }"
                          @afterScheduleReserved="afterScheduleReserved"
                          @close="showCustomSchedule = false"
          />
        </div>
      </div>
      <div v-else>
        <div class="card p-2 text-muted">
          <h4>
            You are in the waiting list please wait util {{ oldReservation.limit - oldReservation.students.length }} students join.
          </h4>
        </div>
      </div>
    </b-overlay>
    <ConfirmModal :show="!!scheduleToApply"
                  :no-close-on-back-drop="true"
                  name="Select Schedule"
                  :on-delete="selectTheSchedule"
                  :is-processing-delete="isProcessing"
                  title="Select Schedule"
                  :sub-title="scheduleToApply?.cost ? `Are you sure you want to select schedule? You need to pay  $${scheduleToApply?.cost}` : 'Are you sure you want to select schedule?'"
                  varient="success"
                  @close="scheduleToApply = null"
    />
  </b-modal>
</template>
<script>
import {
  BModal, BOverlay, BRow, BCol, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ConfirmModal from '@/views/common/components/DeleteModal.vue'
import CustomSchedule from './custom-schedule.vue'
import ScheduleCard from './schedule-card.vue'

export default {
  components: {
    BModal,
    BOverlay,
    BRow,
    BCol,
    BButton,
    ConfirmModal,
    CustomSchedule,
    ScheduleCard,
  },
  props: {
    classId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isProcessing: false,
      schedules: [],
      scheduleToApply: null,
      customSchedule: false,
      reservationId: null,
      showCustomSchedule: false,
      oldReservation: null,
    }
  },
  mounted() {
    this.isProcessing = true
    useJwt.getReservationSchedules(this.classId).then(response => {
      this.schedules = response.data.data.schedules
      this.customSchedule = response.data.data.custom_schedule_info || null
      this.reservationId = response.data.data.reservation_id || null
      this.oldReservation = response.data.data.old_reservation || null
    }).finally(() => {
      this.isProcessing = false
    })
  },
  methods: {
    afterScheduleReserved(response) {
      this.showSuccessMessage(response)
      this.scheduleToApply = null
      if (response.data.data?.payment?.checkoutLink) {
        window.open(response.data.data.payment?.checkoutLink, '_self')
        return
      }
      if (response.data.data?.new_class) {
        this.$router.push({
          name: 'student-view-students',
          params: {
            id: response.data.data.new_class.id,
          },
        })
      } else {
        this.$swal({
          title: response.data.data.message,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          showClass: {
            popup: 'animate__animated animate__shakeX',
          },
          buttonsStyling: false,
        })
        this.$emit('close')
      }
    },
    selectTheSchedule() {
      this.isProcessing = true
      useJwt.updateReservationSchedules(this.classId, this.scheduleToApply.id).then(response => {
        this.afterScheduleReserved(response)
      }).finally(() => {
        this.isProcessing = false
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
  },
}
</script>
