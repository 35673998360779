<template>
  <delete-modal
    :show="!!templateToApply"
    :is-processing-delete="isDeleting"
    :on-delete="handleTemplateApply"
    :title="$t('template-module.apply-confirmation')"
    sub-title=""
    :no-close-on-back-drop="true"
    varient="success"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <div style="margin-top: -15px;">
      <label for="example-datepicker">{{ $t('template-module.apply-from') }}</label>
      <b-form-datepicker
        v-model="dateToApply"
        class="mb-1"
        :min="ADD_DATE(TODAY(), '1')"
      />
      <b-form-group :label="$t('class-room')">
        <v-select v-model="selectedClassroom"
                  :options="classRoomsList"
                  label="name"
                  :reduce="item => item.id"
                  :clearable="false"
        />
      </b-form-group>
    </div>
  </delete-modal>
</template>
<script>
import vSelect from 'vue-select'
import {
  BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'
import useApollo from '@/@core/graphql/useApollo'
import useJwt from '@/auth/jwt/useJwt'
import DeleteModal from '@/views/common/components/DeleteModal.vue'

export default {
  components: {
    vSelect,
    BFormGroup,
    BFormDatepicker,
    DeleteModal,
  },
  props: {
    templateToApply: {
      type: Number,
      default: () => {},
    },
    type: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      isDeleting: false,
      selectedClassroom: this.$route.params.classId,
      classRoomsList: [],
      dateToApply: null,
    }
  },
  watch: {
    templateToApply(value) {
      if (value && !this.classRoomsList.length) {
        this.getClassrooms()
      }
    },
  },
  methods: {
    getClassrooms() {
      useApollo.getMyClassRoom().then(response => {
        this.classRoomsList = [{ value: null, text: 'All Classroom' }, ...response.data.rooms.data]
      })
    },
    handleTemplateApply() {
      if (!this.dateToApply) {
        this.showErrorMessage('No date selected')
        return
      }
      this.isDeleting = true
      useJwt.applyCalendarTemplate(this.templateToApply, {
        apply_date: this.dateToApply,
        selected_class_room: this.selectedClassroom,
        type: this.type,
      }).then(response => {
        this.showSuccessMessage(response)
        this.$emit('update-event')
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isDeleting = false
      })
    },
  },
}
</script>
