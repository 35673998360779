/* eslint-disable import/no-cycle */
import isAuthenticated from '@/middlewares/isAuthenticated'
import schoolUsersOnly from '@/middlewares/schoolUsersOnly'

export default [
  // {
  //   path: '/school/home',
  //   meta: {
  //     middleware: [isAuthenticated, schoolUsersOnly],
  //   },
  //   name: 'school-home',
  //   component: () => import('@/views/school/Home.vue'),
  // },
  {
    path: '/school/users',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
    },
    name: 'school-users',
    component: () => import('@/views/common/user/Index.vue'),
  },
  {
    path: '/school/networks',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
    },
    name: 'school-networks',
    component: () => import('@/views/common/network/Index.vue'),
  },
  {
    path: '/school/marketplaces',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
    },
    name: 'school-marketplaces',
    component: () => import('@/views/marketplace/super/index.vue'),
  },
  {
    path: '/school/networks/:id',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
      navActiveLink: 'school-networks',
    },
    name: 'school-networks-show',
    component: () => import('@/views/common/network/Show.vue'),
  },
  {
    path: '/school/classes',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
    },
    name: 'school-classes',
    component: () => import('@/views/common/class/Handler.vue'),
  },
  {
    path: '/school/classes/:classId/calendar',
    name: 'school-class-calendar',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
      navActiveLink: 'school-classes',
    },
    component: () => import('@/views/teacher/class/components/calendar/Calendar.vue'),
  },
  {
    path: '/school/classes/:id/:courseId?/:reporting?',
    name: 'school-class',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
      navActiveLink: 'school-classes',
    },
    component: () => import('@/views/common/class/Show.vue'),
  },
  {
    path: '/school/student-diary/:id',
    name: 'school-student-diary',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
    },
    component: () => import('@/views/common/student-diary/Index.vue'),
  },
  {
    path: '/school/class/:classId/student/:id/rubrics',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
      navActiveLink: 'school-classes',
    },
    name: 'school-rubrics',
    component: () => import('@/views/common/class/components/rubric/Show.vue'),
  },
  {
    path: '/school/courses/:id',
    name: 'school-course',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
      navActiveLink: 'school-classes',
    },
    component: () => import('@/views/common/class/components/ShowCourse.vue'),
  },
  {
    path: '/school-teacher/schedular',
    name: 'school-schedular',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
    },
    component: () => import('@/views/teacher/class/availability-schedular/index.vue'),
  },
  {
    path: '/school/view-students/:id',
    name: 'school-view-students',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
      navActiveLink: 'school-classes',
    },
    component: () => import('@/views/v2/common/class/components/StudentTable.vue'),
  },
  {
    path: '/school/student/personality-evaluation',
    name: 'student-personality-evaluation-for-school',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
      navActiveLink: 'student-personality-evaluation-for-school',
    },
    component: () => import('@/views/common/personality-evaluation/Index.vue'),
  },
  {
    path: '/school/overall-classroom-summary',
    name: 'school-report-overall-classroom-summary',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
    },
    component: () => import('@/views/v2/common/class/overall-report-summary/overall-summary.vue'),
  },
  {
    path: '/school/higher-level-report',
    name: 'school-higher-level-report',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
    },
    component: () => import('@/views/common/higher-level-report/index.vue'),
  },
  {
    path: '/school/individual-report/:studentId',
    name: 'school-individual-report',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
    },
    component: () => import('@/views/common/individual-report/index.vue'),
  },
  {
    path: '/school/view-attendance/:id?',
    name: 'school-view-attendance',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
      navActiveLink: 'school-view-attendance',
    },
    component: () => import('@/views/student/attendance/index.vue'),
  },
  {
    path: '/school/engagement-settings',
    name: 'school-engagement-settings',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
      navActiveLink: 'engagement-settings',
    },
    component: () => import('@/views/school/Engagements.vue'),
  },
  // reservations
  {
    path: '/school/reservations',
    name: 'school-reservations',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
    },
    component: () => import('@/views/reservations/internal/index.vue'),
  },
  {
    path: '/school/public-reservations/:id',
    name: 'school-public-reservations',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
    },
    component: () => import('@/views/reservations/internal/public-requests/index.vue'),
  },

  {
    path: '/school/settings',
    name: 'school-settings',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
    },
    component: () => import('@/views/settings/Settings.vue'),
  },
  {
    path: '/school/reservation-schedules',
    name: 'school-reservation-schedules',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
    },
    component: () => import('@/views/reservations/internal/teacher-schedule/index.vue'),
  },
  {
    path: '/school/student-payments',
    name: 'school-student-payments',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
    },
    component: () => import('@/views/payments/index.vue'),
  },
  {
    path: '/school/note-templates',
    name: 'school-note-templates',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
    },
    component: () => import('@/views/school/note-template/index.vue'),
  },

  {
    path: '/school/mapper',
    name: 'mapper',
    meta: {
      middleware: [isAuthenticated, schoolUsersOnly],
    },
    component: () => import('@/views/lesson-mapper/Index.vue'),
  },
]
