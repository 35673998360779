<template>
  <div>
    <span>{{ $t('send-notification-module.select-type') }}</span>
    <b-form-select
      v-model="selected"
      style="margin-top:6px;"
      :options="options"
      @input="$emit('selected',selected)"
    />
  </div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue'

export default {
  components: { BFormSelect },
  data() {
    return {
      selected: this.$route.query.classId ? 'class' : null,
      options: [
        {
          value: null, text: this.$i18n.tc('actions.all'),
        },
        {
          value: 'class', text: this.$i18n.tc('by-class'),
        },
      ],
    }
  },
  mounted() {
    if (this.$route.query.classId) {
      this.$emit('selected', 'class')
    }
  },
}
</script>

<style>

</style>
