<template>
  <div v-if="reward">
    <b-card>
      <b-card-title class="small">
        <span v-if="type === 'global'">
          <span v-if="studentName"> {{ $t('badge-reward.student-global-reward', { studentName: studentName, reward: reward }) }}</span>
          <span v-else> {{ $t('badge-reward.self-global-reward', { reward: reward }) }}</span>
        </span>

        <span v-else-if="type === 'classroom'">
          <span v-if="studentName"> {{ $t('badge-reward.student-classroom-reward', { studentName: studentName, reward: reward }) }}</span>
          <span v-else> {{ $t('badge-reward.self-classroom-reward', { reward: reward }) }}</span>
        </span>
      </b-card-title>

      <!-- Badges Instructions -->
      <b-card-body v-if="reward === 'badge'">
        <b-card-text>
          {{ $t('badge-reward.ways') }}
          <b-link v-b-modal.badges-info-1>
            {{ $t('badge-reward.click-here') }}
          </b-link>
          {{ $t('badge-reward.to-know') }}
        </b-card-text>
      </b-card-body>

      <!-- Vibes Instructions -->
      <b-card-body v-else-if="reward === 'vibe'">
        <b-card-text>{{ $t('badge-reward.can-be-received') }}</b-card-text>
        <ul>
          <!-- Vibes from Chat -->
          <li
            v-b-toggle.vibes-chat
            class="text-primary"
          >
            {{ $t('badge-reward.can-be-sent-from-chat') }}
          </li>

          <!-- Vibes from Student Lab -->
          <li
            v-b-toggle.vibes-student-lab
            class="text-primary"
          >
            {{ $t('badge-reward.can-be-sent-from-student-lab') }}
          </li>
        </ul>
      </b-card-body>

      <!-- Points Instructions -->
      <b-card-body v-else-if="reward === 'point'">
        <b-card-text>{{ $t('badge-reward.points-can-be-received-by-completing-the-lessons') }}</b-card-text>
      </b-card-body>

      <!-- Coins Instructions -->
      <b-card-body v-else-if="reward === 'coin'">
        <b-card-text> {{ $t('badge-reward.threshold',{threshold}) }} </b-card-text>
      </b-card-body>
    </b-card>

    <!-- Vibes from Chat -->
    <b-collapse
      v-if="reward === 'vibe'"
      id="vibes-chat"
      accordion="vibes"
    >
      <b-card :title="$t('badge-reward.send-vibes-from-chat')">
        <!-- Step 1 -->
        <b-card-body>
          <div class="d-lg-flex">
            <b-card-text class="steps mr-1">
              {{ $t('badge-reward.step') }} 1:
            </b-card-text>
            <div>
              <b-card-text>{{ $t('badge-reward.step-chat-1') }}</b-card-text>
              <b-card-img
                :src="require('@/assets/images/vibes-instructions/vibes-1.png')"
                alt="Click on smiley icon"
                bottom
              />
            </div>
          </div>
        </b-card-body>

        <!-- Step 2 -->
        <b-card-body class="pt-0">
          <div class="d-lg-flex">
            <b-card-text class="steps mr-1">
              {{ $t('badge-reward.step') }} 2:
            </b-card-text>
            <div>
              <b-card-text>
                {{ $t('badge-reward.step-chat-2') }}
              </b-card-text>
              <b-card-img
                :src="require('@/assets/images/vibes-instructions/vibes-2.png')"
                alt="Click on smiley icon"
                bottom
              />
            </div>
          </div>
        </b-card-body>

        <!-- Step 3 -->
        <b-card-body class="pt-0">
          <div class="d-lg-flex">
            <b-card-text class="steps mr-1">
              {{ $t('badge-reward.step') }} 3:
            </b-card-text>
            <div>
              <b-card-text>
                {{ $t('badge-reward.step-chat-3') }}
              </b-card-text>
              <b-card-img
                :src="require('@/assets/images/vibes-instructions/vibes-3.png')"
                alt="Click on smiley icon"
                bottom
              />
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-collapse>

    <!-- Vibes from Student Lab -->
    <b-collapse
      v-if="reward === 'vibe'"
      id="vibes-student-lab"
      accordion="vibes"
    >
      <b-card :title="$t('badge-reward.send-vibes-from-student-lab')">
        <b-card-body>
          <b-card-text>
            {{ $t('badge-reward.student-lab') }}
          </b-card-text>
        </b-card-body>

        <!-- Step 1 -->
        <b-card-body>
          <div class="d-lg-flex">
            <b-card-text class="steps mr-1">
              {{ $t('badge-reward.step') }} 1:
            </b-card-text>
            <div>
              <b-card-text>
                {{ $t('badge-reward.step-student-lab-1') }}
              </b-card-text>
              <b-card-img
                :src="require('@/assets/images/vibes-instructions/vibes-4.png')"
                alt=""
                bottom
                class="mb-2"
              />
              <b-card-img
                :src="require('@/assets/images/vibes-instructions/vibes-5.png')"
                alt=""
                bottom
              />
            </div>
          </div>
        </b-card-body>

        <!-- Step 2 -->
        <b-card-body>
          <div class="d-lg-flex">
            <b-card-text class="steps mr-1">
              {{ $t('badge-reward.step') }} 2:
            </b-card-text>
            <div>
              <b-card-text>
                {{ $t('badge-reward.step-student-lab-2') }}
              </b-card-text>
              <b-card-img
                :src="require('@/assets/images/vibes-instructions/vibes-6.png')"
                alt=""
                bottom
              />
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import {
  BCard, BCardText, BCardBody, BCardTitle, BCardImg, BLink, BCollapse, VBToggle,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    BCard, BCardText, BCardBody, BCardTitle, BCardImg, BCollapse, BLink,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    reward: {
      type: String,
      default: () => '',
    },
    threshold: {
      type: [Object, Number],
      default: () => {},
    },
    studentName: {
      type: String,
      default: () => null,
    },
    type: {
      type: String,
      default: 'global',
    },
  },
  computed: {
    globalPoints() {
      if (this.studentName) {
        const student = this.studentName
        const rewardtype = this.reward
        return i18n.tc('badge-reward.student-global-reward', { studentName: student, reward: rewardtype })
      }
      return i18n.tc('badge-reward.self-global-reward', { reward: this.reward })
    },
    classroomPoints() {
      if (this.studentName) {
        return `${this.studentName} has received no ${this.reward} s yet`
      }
      return `you have received no ${this.reward}s in this classroom yet.`
    },
  },
}
</script>

<style scoped>
  .card-title {
    font-weight: 700;
    margin-bottom: 0;
  }
  .card-subtitle {
    font-weight: 700 !important;
    margin-bottom: 0 !important;
  }
  .steps {
    min-width: 60px;
    font-weight: 700;
  }
</style>
