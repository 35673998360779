<template>
  <b-card
    no-body
    class="p-2 student-diary-header"
  >
    <b-row class="mb-2 mb-md-0">
      <b-col
        md="12"
        class="d-flex mb-1 align-items-center"
      >
        <div>
          <b-button
            variant="flat-secondary"
            class="btn-icon"
            @click="GO_BACK()"
          >
            <feather-icon
              size="20"
              icon="ArrowLeftIcon"
            />
          </b-button>
        </div>
        <div class="d-flex w-100 justify-content-center">
          <h3>
            <strong
              class="text-capitalize"
            >{{ `${$t('student-diary-module.rubric')} ${$t('student-diary-module.view')}` }}
            </strong>
          </h3>
        </div>
      </b-col>
    </b-row>

    <b-row>

      <b-col
        md="7"
        class="d-flex"
      >
        <Avatar
          :image="userDetails.avatar"
          size="80px"
          rounded="circle"
        />
        <div class="ml-2">
          <h3>
            {{ userDetails.fullname }}
          </h3>
          <span style="font-size:14px">{{ currentTime }}</span>
        </div>
      </b-col>

      <b-col
        v-if="showCalendar"
        md="5"
        class="d-flex align-items-end justify-content-end mt-1 md-mt-0"
      >
        <div class="d-flex align-items-center">
          <label
            class="btn btn-sm btn-outline-secondary m-0"
            style="padding:8px"
            @click="setPreviousDay"
          >
            <feather-icon
              size="18"
              icon="ChevronLeftIcon"
            />
          </label>

          <div class="mx-1">
            <b-form-datepicker
              id="datepicker-placeholder-from"
              v-model="fromDate"
              style="padding:3px"
              local="en"
              size="sm"
              :max="getToday()"
              @input="$emit('getStudentActivities',fromDate)"
            />
            <b-form-datepicker
              id="datepicker-placeholder-to"
              v-model="toDate"
              style="margin-top:4px;padding:3px"
              local="en"
              size="sm"
              :min="new Date(fromDate)"
              :max="getToday()"
            />
          </div>
          <label
            class="btn btn-sm btn-outline-secondary m-0"
            :class="{'disabled':fromDate===getToday()}"
            style="padding:8px"
            @click="fromDate===getToday() ? ()=>{}:setNextDay()"
          >
            <feather-icon
              size="18"
              icon="ChevronRightIcon"
            />
          </label>
        </div>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BFormDatepicker, BButton,
} from 'bootstrap-vue'
import Avatar from '@views/common/components/Avatar.vue'

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {
    BCol, BRow, Avatar, BCard, BFormDatepicker, BButton,
  },
  props: {
    activeView: {
      type: String,
      default: 'block',
    },
    userDetails: {
      type: Object,
      required: true,
    },
    isSelf: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showCalendar: false,
      currentTime: '',
      fromDate: this.getToday(),
      toDate: this.getToday(),
    }
  },
  mounted() {
    this.$emit('getStudentRubrics', { from_date: this.fromDate, to_date: this.toDate })

    // to display time in the header
    this.getNow()
    setInterval(this.getNow, 1000)
  },
  methods: {
    setNextDay() {
      this.fromDate = this.getNextDay(this.fromDate)
      this.toDate = this.getNextDay(this.toDate)
    },
    setPreviousDay() {
      this.fromDate = this.getPreviousDay(this.fromDate)
      this.toDate = this.getPreviousDay(this.toDate)
    },
    getNow() {
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`
      this.currentTime = dateTime
    },
    getToday() {
      return (new Date()).toISOString().split('T')[0]
    },
    getNextDay(currentDate) {
      const tomorrow = new Date(currentDate)
      tomorrow.setDate(tomorrow.getDate() + 1)
      return tomorrow.toISOString().split('T')[0]
    },
    getPreviousDay(currentDate) {
      const yesterday = new Date(currentDate)
      yesterday.setDate(yesterday.getDate() - 1)
      return yesterday.toISOString().split('T')[0]
    },
  },
}
</script>

<style lang="scss">
.student-diary-header{
  .b-form-datepicker{
  label{
    line-height: 2.2 !important;
  }
}
}

#datepicker-placeholder-from__dialog_{
  z-index: 1111111111 !important;
}
</style>
