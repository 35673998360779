<template>
  <div id="report-card">

    <b-card>
      <b-row>
        <b-col
          cols="12"
          class="text-right"
        >
          <b-button
            variant="primary"
            data-html2canvas-ignore="true"
            @click="exportPdf()"
          >
            {{ $t('student-report-module.report-pages.export-pdf') }}
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <vue-apex-charts
            width="530"
            type="bar"
            :options="options"
            :series="series"
          />
        </b-col>
        <b-col md="6">
          <h4>
            {{ $t('student-report-module.summary') }}
          </h4>
          <strength-table
            class="mt-2"
            :summary="getMinMax(scores, categories)"
            :strongest="$t('student-report-module.report-pages.most')"
            :weakest="$t('student-report-module.report-pages.least')"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <div class="light  pb-1 lead font-weight-bold">
        {{ $t('student-report-module.report-pages.time-line-of-attendance') }}
      </div>
      <table class="table table-hover table-striped table-bordered table-sm">
        <thead>
          <tr>
            <th>
              {{ $t('student-report-module.report-pages.student-name') }}
            </th>
            <th
              v-for="n in monthList"
              :key="n"
            >
              {{ monthNames[n] }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(report, index) of groupByKey(attendanceReport, 'user_id')"
            :key="index"
          >
            <td>
              <b>{{ report[0].full_name }}</b>
            </td>
            <td
              v-for="m of monthList"
              :key="m"
              :set="data=report.find(r => r.month_name == m)"
            >
              <div
                v-b-tooltip.hover.v-warning="{
                  title: getTotalAttendancePercentage(data, m).tooltip,
                  html: true, placement: 'top'
                }"
              >
                {{ getTotalAttendancePercentage(data, m).score }}%
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard, BCol, VBTooltip, BRow, BButton,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import VueApexCharts from 'vue-apexcharts'
import StrengthTable from './StrengthTable.vue'
import { AttendanceReportCalc } from './helper/reportHelper'

export default {
  components: {
    BCard,
    BCol,
    VueApexCharts,
    StrengthTable,
    BRow,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    classRoom: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      attendanceReport: [],
      maxAttendanceByDate: [],
      monthList: [],
      totalExpectedDays: 0,
      expectedByDate: [],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      ],
      categories: [],
      scores: [],
    }
  },
  computed: {
    series() {
      return [{
        name: 'series-1',
        data: this.scores,
      }]
    },

    options() {
      return {
        colors: ['#28c76f'],
        chart: {
          id: 'vuechart-example',
        },
        xaxis: {
          categories: this.categories,
        },
        dataLabels: {
          formatter(val) {
            return `${val}%`
          },
        },
      }
    },
  },
  mounted() {
    this.getAttendanceReport()
  },
  methods: {
    getMinMax(score, category) {
      const maxScore = Math.max(...score.map(s => Number(s)))
      const minScore = Math.min(...score.map(s => Number(s)))
      const weakest = []
      const strongest = []
      category.forEach((cat, index) => {
        const value = Number(score[index])
        if (value === maxScore) {
          strongest.push({
            fullName: cat,
            score,
          })
        } else if (value === minScore) {
          weakest.push({
            fullName: cat,
            score,
          })
        }
      })
      return [{
        title: i18n.tc('student-report-module.report-pages.attendance'),
        strongest,
        weakest,
      }]
    },
    getAttendanceReport() {
      useJwt.getGroupAttendanceReport(this.classRoom.id).then(res => {
        const { attendance } = res.data.data
        this.attendanceReport = attendance
        // fetch unique month list from data
        const monthList = [...new Set(attendance.map(i => i.month_name))]
        monthList.sort((a, b) => a - b)
        this.monthList = monthList
        // max attendance of student by month
        this.maxAttendanceByDate = res.data.data.maxByDate

        // expected days by month
        this.expectedByDate = res.data.data.expectedByDate
        this.totalExpectedDays = res.data.data.totalExpectedDays

        const attendanceReport = AttendanceReportCalc({
          totalExpectedDays: this.totalExpectedDays,
          attendance,
          maxAttendanceByDate: this.maxAttendanceByDate,
        }, this)

        this.totalExpectedDays = attendanceReport.totalExpectedDays
        this.categories = attendanceReport.categories
        this.scores = attendanceReport.totalScore
      })
    },
    getTotalAttendancePercentage(data, date) {
      const divisor = this.expectedByDate[date] ? this.expectedByDate[date] : this.maxAttendanceByDate[date]

      let attendanceScore = 0
      if (data && data.total_attendance > 0) {
        attendanceScore = ((data && data.total_attendance / divisor) * 100).toFixed(2)
      }

      return {
        tooltip: `${data ? data.total_attendance : 0}/${divisor}`,
        score: attendanceScore,
      }
    },
    exportPdf() {
      const element = document.getElementById('report-card')
      this.printPdf(element, 'attendance-report.pdf')
    },
  },
}
</script>
