<template>
  <div class="container mt-1">
    <div class="row">
      <div class="col-md-12">
        <b-card title="Step Description">
          <b-form-textarea
            v-model="modelForm.description"
            rows="4"
            placeholder="Enter step description"
          />
        </b-card>
        <b-card title="Step Editor">
          <b-form-textarea
            v-model.lazy="modelForm.template"
            rows="4"
            placeholder="Enter step description"
            class="mb-2"
            @change="generateArrayFromWithBraces"
          />
          <div v-for="(variable, index) in modelForm.variables"
               :key="index"
               class="row"
          >
            <div class="col-6">
              <b-form-group label="Variable">
                <b-form-input
                  v-model="variable.name"
                  :disabled="true"
                  placeholder="Variable name"
                />
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label="Value">
                <b-form-input v-model="variable.value"
                              placeholder="Value"
                />
              </b-form-group>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BFormTextarea,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BFormTextarea,
    BFormGroup,
    BFormInput,
  },
  props: {
    modelForm: {
      type: Object,
      required: true,
    },
  },
  methods: {
    extractTextInCurlyBraces(inputString) {
      const regex = /{([^}]+)}/g
      const matches = []
      let match

      // eslint-disable-next-line no-cond-assign
      while ((match = regex.exec(inputString)) !== null) {
        matches.push(match[1])
      }

      return matches
    },
    generateArrayFromWithBraces() {
      const machedString = this.extractTextInCurlyBraces(this.modelForm.template)
      const newVariables = []
      machedString.forEach(item => {
        newVariables.push({
          name: item,
          value: this.modelForm.variables.find(i => i.name === item)?.value || '',
        })
      })
      this.modelForm.variables = newVariables
      this.$forceUpdate()
    },
  },
}
</script>

  <style>
  /* Add your custom styling here if needed */
  </style>
