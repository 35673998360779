<template>
  <div id="report-card">
    <b-card>
      <b-row>
        <b-col
          cols="12"
          class="text-right"
        >
          <b-button
            variant="primary"
            data-html2canvas-ignore="true"
            @click="exportPdf()"
          >
            {{ $t('student-report-module.report-pages.export-pdf') }}
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <h4>
            {{ $t('student-report-module.report-pages.schedule-chart') }}
          </h4>
          <vue-apex-charts
            type="bar"
            :options="barChartOptions"
            :series="seriesData"
          />
        </b-col>
        <b-col md="6">
          <h4>{{ $t('student-report-module.summary') }}</h4>
          <strength-table
            class="mt-2"
            :title="$t('student-report-module.report-pages.schedule')"
            :summary="getMinMax"
            :strongest="$t('student-report-module.report-pages.most-done')"
            :weakest="$t('student-report-module.report-pages.least-done')"
          />
          <!-- <template
            v-for="value,index of getMinMax"
          >
            <div
              :key="index"
              class="card-body p-1"
            >
              <h5> {{ value.title }} Strength Table</h5>
              <strength-table
                class="mt-2"
                :summary="getMinMax"
              />
            </div>
          </template> -->
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h4>
        {{ $t('student-report-module.report-pages.time-line-schedule-chart') }}
      </h4>
      <b-table-simple
        class="table-striped table-hover table-bordered"
        responsive=""
      >
        <b-thead>
          <b-tr>
            <b-th>
              {{ $t('student-report-module.report-pages.student-name') }}
            </b-th>
            <b-th
              v-for="month of months"
              :key="month"
              class="text-center"
            >
              {{ monthsList[month] }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td />
            <b-td
              v-for="month of months"
              :key="month"
            >
              <div class="d-flex justify-content-between">
                <div
                  v-for="schedule of scheduleType"
                  :key="schedule"
                  class="mr-1"
                  :style="`color:${getColor(schedule)};font-weight:400`"
                >
                  {{ $t(`student-report-module.report-pages.${schedule}`) }}
                </div>
              </div>
            </b-td>
          </b-tr>
          <b-tr
            v-for="table,index of tableData"
            :key="index"
          >
            <b-th>
              {{ table.fullName }}
            </b-th>
            <b-td
              v-for="month of months"
              :key="month"
            >
              <div class="d-flex justify-content-between">
                <div
                  v-for="schedule of scheduleType"
                  :key="schedule"
                  v-b-tooltip.hover.v-info
                  :title="table[month][schedule].actualValue"
                  :class="table[month][schedule].value < 1 && 'text-danger'"
                  class="mr-1"
                >
                  {{ table[month][schedule].value }}%
                </div>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import VueApexCharts from 'vue-apexcharts'
import {
  BCard, BRow, BCol, BTableSimple, BThead, BTh, BTbody, BTr, BTd, VBTooltip,
  BButton,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import StrengthTable from './StrengthTable.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    VueApexCharts,
    BTableSimple,
    StrengthTable,
    BThead,
    BTh,
    BTbody,
    BTr,
    BTd,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    classRoom: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      scheduleType: ['undone', 'early', 'late', 'on_time'],
      seriesData: [],
      categories: [],
      tableData: [],
      months: [],
      monthsList: ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    }
  },
  computed: {
    getMinMax() {
      const series = this.seriesData
      const reports = []
      series.forEach(score => {
        const array = score.data.map(s => Number(s))
        if (!array.every(arr => arr === array[0])) {
          const maxScore = Math.max(...score.data.map(s => Number(s)))
          const minScore = Math.min(...score.data.map(s => Number(s)))
          const weakest = []
          const strongest = []
          this.categories.forEach((cat, index) => {
            const value = Number(score.data?.[index])
            if (value === maxScore) {
              strongest.push({
                fullName: cat,
                value,
              })
            } else if (value === minScore) {
              weakest.push({
                fullName: cat,
                value,
              })
            }
          })
          reports.push({
            title: i18n.tc(`student-report-module.report-pages.${score.title}`),
            weakest,
            strongest,
          })
        }
      })
      return reports
    },
    barChartOptions() {
      return {
        colors: ['#F44336', '#3d85ff', '#FBC108', '#4CAF50'],
        chart: {
          id: 'vuechart-example',
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          formatter(val) {
            return `${val}%`
          },
          style: {
            fontSize: '12px',
            colors: ['#fff'],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: this.categories,
        },
      }
    },
  },
  mounted() {
    this.scheduleReport()
  },
  methods: {
    setGraphByUsers(report, total) {
      const groupReport = this.groupByKey(report, 'user_id')
      const seriesData = []
      this.scheduleType.forEach(type => {
        seriesData.push({
          name: this.capitalizeFirstLetter(type),
          title: type,
          data: [],
        })
      })
      const categories = []
      const scoreFinder = (result, type) => {
        const score = result.filter(array => array.time_status === type).reduce((p, c) => p + Number(c.time_status_count), 0)
        const percentage = (score / total) * 100
        return percentage
      }
      Object.entries(groupReport).forEach(([, result]) => {
        categories.push(`${result[0].firstname} ${result[0].lastname}`)
        this.scheduleType.forEach((type, index) => {
          if (type === 'undone') {
            const percentage = 100 - (scoreFinder(result, 'early') + scoreFinder(result, 'late') + scoreFinder(result, 'on_time'))
            seriesData[index].data.push(percentage.toFixed(2))
          } else {
            const percentage = scoreFinder(result, type)
            seriesData[index].data.push(percentage.toFixed(2))
          }
        })
      })
      this.seriesData = seriesData
      this.categories = categories
    },
    setTableByUsers(report, expectedLesson) {
      const groupReport = this.groupByKey(report, 'user_id')
      const tableData = []
      Object.entries(groupReport).forEach(([, result]) => {
        const summary = {}
        expectedLesson.forEach(month => {
          this.scheduleType.forEach(type => {
            const score = result.filter(r => r.date_name === month.date_name && r.time_status === type).reduce((p, c) => p + Number(c.time_status_count), 0)
            const percentage = (score / month.lesson_count) * 100
            if (!summary[month.date_name]) {
              summary[month.date_name] = {}
              summary[month.date_name][type] = {
                value: percentage.toFixed(2),
                actualValue: `${score}/${month.lesson_count}`,
              }
            } else {
              summary[month.date_name][type] = {
                value: percentage.toFixed(2),
                actualValue: `${score}/${month.lesson_count}`,
              }
            }
            if (summary[month.date_name]) {
              const itemValue = summary[month.date_name]
              summary[month.date_name].undone = {
                actualValue: '',
                value: (100 - (Number(itemValue.early?.value) + Number(itemValue.late?.value) + Number(itemValue.on_time?.value))).toFixed(2),
              }
            }
          })
        })
        summary.fullName = `${result[0].firstname} ${result[0].lastname}`
        tableData.push(summary)
      })
      this.months = expectedLesson.map(expected => expected.date_name)
      this.tableData = tableData
    },
    scheduleReport() {
      useJwt.getScheduleReport(this.classRoom.id).then(response => {
        const { data } = response.data
        this.setGraphByUsers(data.data, data.total_lesson)
        this.setTableByUsers(data.data, data.expected_lessons)
      })
    },
    getColor(schedule) {
      let color = null
      switch (schedule) {
        case 'undone':
          color = '#F44336'
          break
        case 'early':
          color = '#3d85ff'
          break
        case 'on_time':
          color = '#4CAF50'
          break
        case 'late':
          color = '#FBC108'
          break
        default:
          color = 'grey'
      }
      return color
    },
    exportPdf() {
      const element = document.getElementById('report-card')
      this.printPdf(element, 'schedule-report.pdf')
    },
  },
}
</script>
