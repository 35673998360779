<template>
  <div>
    <b-card body-class="p-0">
      <b-table-simple
        bordered
        striped
        class="mb-0"
      >
        <b-thead>
          <b-tr>
            <b-th
              v-for="level,index of levels"
              :key="level"
              :style="{
                backgroundColor: langLevelColor[index],
                color: 'white',
              }"
              role="button"
              @click="onClickLangLevel(level)"
            >
              {{ level }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="row of maxRow"
            :key="row"
          >
            <b-td
              v-for="level of levels"
              :key="level"
              v-b-tooltip.hover.v-primary
              :draggable="true"
              :data-id="`${level}-${row-1}`"
              role="button"
              class="problemValue"
              :set="problemName = getProblemName(level, row - 1)"
              :title="getLessonName(level, row - 1)"
              @drop="onDrop($event)"
              @dragstart="startDrag($event, `${level}-${row-1}`)"
              @dragover.prevent
              @dragenter.prevent
            >
              {{ problemName || '-' }}
              <feather-icon
                v-if="problemName "
                icon="XIcon"
                class="removeIcon d-none text-danger"
                @click="$emit('onRemoveProblem', {
                  level,
                  index: row -1
                })"
              />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <b-button
      variant="outline-primary"
      :disabled="isProcessing"
      @click="$emit('onSubmit')"
    >
      Save
    </b-button>
  </div>
</template>
<script>
import {
  BCard,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTd,
  BTh,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import { LANG_LEVELS_COLORS } from '@/const/langLevel'

export default {
  components: {
    BCard,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    problemWithLevel: {
      type: Object,
      required: true,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    maxRow: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      levels: ['A1', 'A2', 'B1', 'B2'],
      langLevelColor: LANG_LEVELS_COLORS,
    }
  },
  methods: {
    getProblemId(level, index) {
      return this.problemWithLevel[level]?.[index]?.value || ''
    },
    getProblemName(level, index) {
      return this.problemWithLevel[level]?.[index]?.name || null
    },
    getLessonName(level, index) {
      return this.problemWithLevel[level]?.[index]?.lessonName || ''
    },
    onDrop(event) {
      const sourceInfo = event.dataTransfer.getData('itemID').split('-')
      const targetInfo = event.target.getAttribute('data-id').split('-')
      if (sourceInfo[0] === targetInfo[0]) {
        this.$emit('handleDataTransfer', {
          level: targetInfo[0],
          from: Number(sourceInfo[1]),
          to: Number(targetInfo[1]),
        })
      }
    },
    startDrag(evt, problemID) {
      // eslint-disable-next-line no-param-reassign
      evt.dataTransfer.dropEffect = 'move'
      // eslint-disable-next-line no-param-reassign
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('itemID', problemID)
    },
    onClickLangLevel(level) {
      this.$root.$emit('onClickLangLevel', level)
    },
  },
}
</script>
<style scoped lang="scss">
  .problemValue:hover {
    .removeIcon {
      display: inline-block !important;
    }
  }
</style>
