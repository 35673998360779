<template>
  <!-- Modal for previewing assets -->
  <b-modal v-model="showModal"
           size="lg"
           hide-footer
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <template #modal-title>
      Asset Preview
    </template>

    <div class="d-flex justify-content-center">
      <!-- Preview the asset depending on its type (image, video, audio, or YouTube) -->
      <img v-if="isImage"
           :src="assetUrl"
           alt="Asset Preview"
           class="img-fluid"
      >
      <video v-else-if="isVideo"
             :src="assetUrl"
             controls
             class="img-fluid"
      />
      <div v-else-if="isAudio"
           class="text-center"
      >
        <AudioPlayer
          :src="assetUrl"
        />
      </div>
      <div v-else-if="isYoutubeUrl"
           class="text-center"
           style="width: calc(100vw - 100px); max-width: 1100px; min-height: 500px;"
      >
        <iframe :src="formatYoutubeUrl(assetUrl)"
                allowfullscreen
                class="w-100"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                style="height: 100%; width: 100%;"
        />
      </div>
      <div v-else-if="isLinkValid"
           class="iframe-container"
           :style="{ minHeight: modalHeight }"
      >
        <iframe
          id="iFrameLink"
          :src="assetUrl"
          frameborder="0"
          allowfullscreen
          class="iframe-content"
          style="height: 100%; width: 100%; "
          :style="{ minHeight: modalHeight }"
        />
      </div>
      <div v-else
           class="text-danger"
      >
        Not a Valid Asset
      </div>
    </div>
  </b-modal>
</template>

<script>
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'
import { validatorYoutubeUrlValidator } from '@/@core/utils/validations/validators'

export default {
  components: {
    AudioPlayer,
  },
  props: {
    asset: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: false, // Control modal visibility
      assetUrl: '', // URL of the asset
      modalHeight: '100vh',
    }
  },
  computed: {
    isImage() {
      return this.assetUrl.match(/\.(jpeg|jpg|gif|png|bmp|svg)$/i)
    },
    isVideo() {
      return this.assetUrl.match(/\.(mp4|avi|mov|wmv|mkv)$/i)
    },
    isAudio() {
      return this.assetUrl.match(/\.(mp3|wav|ogg|flac|aac)$/i)
    },
    isYoutubeUrl() {
      // Updated regex to support more variations of YouTube URLs
      return /^(https?:\/\/)?(www\.)?(youtube|youtu|vimeo)\.(com|be)\/(watch\?v=|embed\/)([a-zA-Z0-9_-]+)(&.*)?$/.test(this.assetUrl)
    },
    isLinkValid() {
      try {
        // eslint-disable-next-line no-new
        new URL(this.assetUrl)
        return true
      } catch (_) {
        return false
      }
    },
    isIFrame() {
      return (!this.isImage || !this.isVideo || !this.isAudio || !this.isYoutubeUrl) && this.isLinkValid
    },
  },
  watch: {
    asset: {
      handler(newVal) {
        this.assetUrl = newVal
        this.showModal = true // Show modal when a new asset is passed
      },
      immediate: true,
    },
  },
  methods: {
    formatYoutubeUrl() {
      return validatorYoutubeUrlValidator(this.asset)
    },
  },
}
</script>

<style scoped>
/* Container to make iframe responsive */
.iframe-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
</style>
