<template>
  <div class="misc-inner p-2 p-sm-3">
    <div class="w-100 text-center">
      <h2 class="mb-1">
        Update Subscription
      </h2>
      <p class="mb-2">
        Your Subscription has been updated please update it?
      </p>
      <a
        href="#"
        class="btn mb-2 btn-sm-block router-link-active btn-primary"
        target="_self"
      >
        Update Now
      </a>
      <a
        href="#"
        class="btn ml-2 mb-2 btn-sm-block router-link-active btn-primary"
        target="_self"
        @click="logout"
      >
        Logout
      </a>
      <div>
        <img
          src="https://demos.pixinvent.com/vuexy-vuejs-admin-template-vue2/demo-1/img/error.700bd717.svg"
          alt="Error page"
          class="img-fluid"
        >
      </div>
    </div>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'

export default {

  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
      /// Reset activeChat
      localStorage.removeItem('chatData')
      localStorage.removeItem('users-module')
      localStorage.removeItem('current_test_id')
      this.$store.dispatch('authEvents/onUserLogout')
      localStorage.removeItem('partnerInformation')
      localStorage.removeItem('ui')
      this.$store.commit('appConfig/UPDATE_SELECTED_CHARACTER', null)
    },
  },

}
</script>
