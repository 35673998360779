<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notReadCount"
        badge-classes="bg-danger"
        class="text-success"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('user-notification-module.notifications') }}
        </h4>
        <b-badge
          v-if="notReadCount>0"
          pill
          variant="light-primary"
        >
          {{ notReadCount }} {{ $t('user-notification-module.new') }}
        </b-badge>

      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="(notification) in notifications"
        :key="notification.id"
        @click="handleNotificationClick(notification)"
      >
        <b-media
          class="notification-card"
          :class="{'notification-card--active':notification.read_at===null}"
        >
          <template #aside>
            <b-avatar
              size="32"
              variant="light-primary"
            >
              <feather-icon :icon="getNotificationIcon(notification)" />
            </b-avatar>
          </template>
          <div class="media-heading">
            <div class="d-flex justify-content-between">
              <span class="font-weight-bolder">
                {{ notification.data.title }}
              </span>
              <!-- <span class="notification-dot" /> -->
              <span @click.stop="markAsRead(notification.id)">
                <feather-icon
                  icon="XIcon"
                />
              </span>
            </div>
          </div>
          <small
            v-if="notification.read_at === null"
            style="margin-top:6px"
            class="notification-text d-inline-block"
          >
            {{ notification.data.message }}
          </small>
          <small
            v-if="notification.data.message_description"
            class="notification-text d-inline-block"
          >
            Message: <strong>{{ notification.data.message_description }}</strong></small>

          <div
            v-if="isRequestType(notification.data.type)"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              @click="acceptRequest(notification.data.action,notification.id,notification.data.type)"
            >
              Accept
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              size="sm"
              class="ml-1"
              @click="declineRequest(notification.data.action,notification.id,notification.data.type)"
            >
              Reject
            </b-button>
          </div>
          <p
            style="font-size:10px"
            class="text-right m-0 p-0"
          >
            {{ notification.data.date }}
          </p>
        </b-media>
      </b-link>
      <div
        v-show="hasMoreNotifications"
        class="text-center my-1"
      >
        <b-spinner
          variant="primary"
          label="Text Centered"
        />
      </div>
      <!-- <pagination v-if="notifications.length>0" /> -->
      <p
        v-if="notifications.length==0"
        class="px-1 pt-1"
      >
        {{ $t('user-notification-module.no-new-notifications') }}
      </p>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li
      v-if="notReadCount>0"
      class="dropdown-menu-footer"
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="markAllAsRead()"
      >{{ $t('user-notification-module.mark-all-as-read') }}
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BButton, BSpinner, BAvatar,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import {
  isRequestType, getMessageForNotification, getIconForNotification, getTitleForNotification, commitActionForNotification,
} from '@/const/notification'

export default {
  components: {
    BSpinner,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    VuePerfectScrollbar,
    BButton,
    BAvatar,
    // BDropdownItem,
    // BDropdown,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters({
      hasMoreNotifications: 'notification/hasMoreNotifications',
      notReadCount: 'notification/notReadCount',
      notifications: 'notification/notifications',
    }),

  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
      viewingMessage: null,
    }
  },
  mounted() {
    this.addEventListener()
    this.$store.dispatch('notification/getNotifications')
    this.$store.dispatch('notification/listenForEvents')
  },
  methods: {
    handleNotificationClick(item) {
      if (item.data.visit_url) {
        window.open(item.data.visit_url)
      }
    },
    isRequestType(type) {
      return isRequestType(type)
    },
    deleteNotification(id, index) {
      this.$store.dispatch('notification/deleteNotification', {
        id,
        index,
      })
    },
    markAllAsRead() {
      this.$store.dispatch('notification/markAllAsRead')
    },
    markAsRead(id) {
      this.$store.dispatch('notification/markAsRead', id)
    },
    acceptRequest(requestId, notificationId, notificationType) {
      this.$store.dispatch('notification/acceptRequest', {
        requestId, notificationId, notificationType,
      })
    },
    declineRequest(requestId, notificationId, notificationType) {
      this.$store.dispatch('notification/declineRequest', {
        requestId, notificationId, notificationType,
      })
    },
    addEventListener() {
      const el = document.querySelector('.scrollable-container')
      el.onscroll = () => {
        if (el.scrollTop >= (el.scrollHeight - el.offsetHeight)) {
          if (this.hasMoreNotifications) this.$store.dispatch('notification/goToNextPage')
        }
      }
    },
    getNotificationSubtitle(notification) {
      return getMessageForNotification(notification.data.type, notification.data.from, notification.data.for)
    },
    getNotificationIcon(notification) {
      return getIconForNotification(notification.data.type)
    },
    getNotificationTitle(notification) {
      return getTitleForNotification(notification.data.type)
    },
    commitNotificationAction(notification) {
      commitActionForNotification(notification.data)
    },
  },
}
</script>
<style lang="css">
.dropdown-menu .dropdown-toggle{
  padding: 0 !important;
  margin: 0 !important;
}
</style>
