<template>
  <portal to="dragModalTargetArea">
    <dialog-drag
      v-if="show"
      :title="title"
      :options="options"
      style="z-index: 9999; cursor: move;width: calc(100vw - 100px); max-width: 1100px"
      @close="$emit('close')"
    >
      <div class="dialog-overlay">
        <iframe
          :src="url"
          frameborder="0"
          allowfullscreen
          class="iframe-content"
          style="height: 100%; width: 100%; "
        />
      </div>
    </dialog-drag>
  </portal>
</template>

<script>
import DialogDrag from 'vue-dialog-drag'

export default {
  components: {
    DialogDrag,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '#',
    },
  },
  data() {
    return {
      options: { width: 1100, centered: 'viewport' },
    }
  },
}
</script>

<style scoped>
.dialog-overlay {
  width:  100%;
  height: calc(100vh - 150px);
  position: relative;
  padding: 10px;
  overflow: hidden; /* Hide anything outside the iframe */
}

.iframe-content {
  width: 100%;
  height: 100%;
  border: none;
  position: relative; /* Keep iframe within the overlay */
}
</style>
