<template>
  <b-row class="feedback-statement">

    <b-col
      v-if="
        (feedback && feedback.text && feedback.text.length > 0) ||
          feedback.image ||
          feedback.audio ||
          feedback.template
      "
      md="12"
      class="problem-result no-result"
    >
      <div
        v-if="('isCorrect' in feedback) && activeProblem?.problem_type !== 'open-end-writing'"
        class="correct-incorrect-icon"
        :class="{
          'bg-success':
            'isCorrect' in feedback && feedback.isCorrect === true,
          'bg-danger':
            'isCorrect' in feedback && feedback.isCorrect === false,
        }"
      >
        <feather-icon
          v-if="feedback.isCorrect"
          icon="CheckIcon"
          size="30"
        />
        <feather-icon
          v-else
          icon="XIcon"
          size="30"
        />
      </div>
      <div v-else-if="activeProblem"
           class="text-right"
      >
        <!-- <OpenAiReport v-if="feedback?.open_ai_score && feedback?.open_ai_score?.length"
                      :scores="feedback.open_ai_score"
                      :answer="feedback.text"
                      class="mr-1"
        /> -->
        <b-badge
          class="mb-2 badge-lg"
          variant="success"
        >
          Submitted
        </b-badge>
      </div>
      <swiper ref="vueSwiper"
              class="swiper"
      >
        <swiper-slide v-if="canShow(feedback.template)">
          <audio-player
            v-if="feedback.audio"
            :src="feedback.audio"
          />
          <div
            v-if="isAHotSpotProblem"
            class="inner-problem-result"
          >
            <HotSpotFeedBack :hotspot="activeProblem" />
          </div>
          <div
            v-else
            class="inner-problem-result p-0"
          >
            <div
              v-if="feedback.image"
              class="mt-2 rounded-top text-center d-block"
            >
              <b-img
                :src="feedback.image"
                style="max-height: 200px"
                fluid
              />
            </div>
            <div v-if="!feedback.template">
              <p
                v-if="feedback.text && feedback.text.length > 0"
                v-text-to-speech
                class="ck-content"
                :class="{
                  'mt-2':
                    'isCorrect' in feedback || feedback.image || feedback.audio,
                }"
                @click="handleClick"
                v-html="wordnetWords"
              />
            </div>
            <AnimatedLayout v-else
                            :enabled="feedback.template && feedback.template.animated_layout == 1"
                            :template-id="feedback.template && feedback.template.animated_template_id"
                            :is-edit-mode="false"
            >
              <show-template
                :only-feed-back="false"
                :template="feedback.template"
              />
            </AnimatedLayout>

            <translation-section
              ref="translationModal"
              :active-translation="activeTranslation"
              :active-word-nets="activeWordNets"
              :active-word="activeWord"
              @set-active-language="setActiveLanguage"
            />
          </div>
        </swiper-slide>
        <template v-if="hasMultipleFeedBack">
          <template v-for="child,index of feedback.template.child_templates">
            <swiper-slide
              v-if="canShow(child)"
              :key="index"
            >

              <div class="inner-problem-result p-0">
                <AnimatedLayout :enabled="child && child.animated_layout == 1"
                                :template-id="child && child.animated_template_id"
                                :is-edit-mode="false"
                >
                  <show-template
                    :only-feed-back="false"
                    :template="child"
                  />
                </AnimatedLayout>
              </div>

            </swiper-slide>
          </template>
        </template>

      </swiper>
      <div v-if="hasMultipleFeedBack">
        <div v-if="isPrevSlideVisible"
             tabindex="0"
             class="swiper-button-prev"
             role="button"
             @click="slideTo('prev')"
        />
        <div v-if="isNextSlideVisible"
             tabindex="0"
             class="swiper-button-next"
             role="button"
             @click="slideTo('next')"
        />
        <div class="swiper-pagination" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BRow, BImg, BBadge,
} from 'bootstrap-vue'
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'
import ShowTemplate from '@views/common/templates/Show.vue'
import TranslationSection from '@views/common/lessons/components/TranslationSection.vue'
import useJwt from '@/auth/jwt/useJwt'
import HotSpotFeedBack from '@views/student/problem/hotspot/HotSpotFeedback.vue'
import AnimatedLayout from '@/views/super/problem/body-template/manager.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
// import OpenAiReport from '@/views/student/report/components/OpenAIScore.vue'

export default {
  components: {
    BCol,
    BRow,
    BImg,
    AudioPlayer,
    AnimatedLayout,
    Swiper,
    SwiperSlide,
    BBadge,
    // OpenAiReport,
    ShowTemplate,
    TranslationSection,
    HotSpotFeedBack,
  },
  props: {
    feedback: {
      type: Object,
      required: true,
    },
    problemStatementId: {
      type: [Object, Number, String],
      required: false,
    },
  },
  data() {
    return {
      activeTranslation: {},
      activeWord: '',
      activeLanguage: 'Korean',
      activeWordNets: '',
      activeOffset: null,
      isPrevSlideVisible: false,
      isNextSlideVisible: true,
      words: [],
    }
  },
  computed: {
    activeLanguageShort() {
      if (this.activeLanguage === 'Korean') {
        return 'kor'
      }

      return 'jpn'
    },
    hasMultipleFeedBack() {
      return !!this.feedback.template && !!this.feedback.template.child_templates?.length
    },
    activeProblem() {
      const { activeProblem } = this.$store.state.problem
      if (activeProblem) {
        activeProblem.feedback = this.feedback?.text
        activeProblem.feedbackAudio = this.feedback?.audio
      }
      return activeProblem
    },
    isAHotSpotProblem() {
      return this.activeProblem?.hotspotPoints?.id
    },
    wordnetWords() {
      if (this.words.length > 0) {
        this.words.forEach(word => {
          const link = `<a  class="wordnet_popup" data-word="${word}">${word}</a>`
          this.feedback.text = this.feedback.text.replace(word, link)
        })
      }
      return this.feedback.text
    },
  },
  watch: {
    problemStatementId() {
      this.getWordNets()
    },
    feedback(value) {
      if (value && Object.keys(value).length) {
        this.$store.commit('studentLab/SET_ACTIVE_LAB_VIEW', 'feedback')
      }

      if (value?.isCorrect !== undefined) {
        const feedBackMessage = this.feedback.isCorrect ? 'Your Answer is correct' : 'Your Answer is incorrect'
        this.$root.$emit('CHAT_BOT_AFTER_FEEDBACK', feedBackMessage)
      }
    },
  },
  mounted() {
    this.getWordNets()
  },
  updated() {
    this.isNextSlideVisible = !this.$refs.vueSwiper?.$swiper?.isEnd
  },
  methods: {
    slideTo(to) {
      if (to === 'next') {
        this.$refs.vueSwiper.$swiper.slideNext()
      } else {
        this.$refs.vueSwiper.$swiper.slidePrev()
      }
      this.isPrevSlideVisible = !this.$refs.vueSwiper?.$swiper?.isBeginning
      this.isNextSlideVisible = !this.$refs.vueSwiper?.$swiper?.isEnd
    },
    handleClick(e) {
      if (e.target.matches('.wordnet_popup, .wordnet_popup *')) {
        const word = e.target.getAttribute('data-word')
        this.openTranslationModal(word)
      }
    },
    canShow(template) {
      if (!template || !template.rules) {
        return true
      }
      if (this.feedback?.isCorrect === undefined || template?.rules?.applicable_for === 'all') {
        return true
      } if (this.feedback?.isCorrect) {
        return template?.rules?.applicable_for === 'correct'
      } if (!this.feedback?.isCorrect) {
        return template?.rules?.applicable_for === 'incorrect'
      }
      return true
    },
    openTranslationModal(word) {
      this.activeWord = word
      this.activeWordNets = ''
      useJwt
        .getCourseWordNetsForPage({
          problem_statement_id: this.problemStatementId,
          word,
        })
        .then(res => {
          if (res.status === 200) {
            this.activeWordNets = res.data.data
            const selectedWordnets = []
            this.activeWordNets.wordnet.forEach(w => {
              if (w.selected === 1) {
                selectedWordnets.push(w)
              }
            })
            if (selectedWordnets.length > 0) {
              this.activeWordNets.wordnet = selectedWordnets
            }
            const filteredWordNet = this.activeWordNets.wordnet.find(
              w => w.selected === 1,
            )
              ? this.activeWordNets.wordnet.find(w => w.selected === 1)
              : this.activeWordNets.wordnet[0]
            const { offset } = filteredWordNet
            const translatedWord = filteredWordNet[this.activeLanguageShort]
              ? [filteredWordNet[this.activeLanguageShort]]
              : filteredWordNet[`_${this.activeLanguageShort}`]
            this.activeOffset = offset
            if (translatedWord) {
              this.activeTranslation.words = translatedWord
            }
            this.$refs.translationModal.showModal()
          }
        })
    },
    getWordNets() {
      // if (!this.problemStatementId) return
      // useJwt.getCourseWordNetsArray(this.problemStatementId).then(res => {
      //   Object.values(res.data.data).forEach(word => {
      //     this.words.push(word.word)
      //   })
      // })
    },
    setActiveLanguage(language) {
      this.openTranslationModal(this.activeWord)
      this.activeLanguage = language
    },
  },
}
</script>

<style scoped>
.alert-info {
  color: inherit !important;
}
.inner-problem-result {
  position: relative;
}
.correct-incorrect-icon {
  border-radius: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 40px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1000;
}
</style>
<style lang="scss">
.feedback-statement {
  .agile__nav-button {
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 45px;
    height: 100%;
    position: absolute;
    top: 0;
    transition-duration: 0.3s;
    width: 80px;
  }
  .agile__nav-button:hover {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
  }
  .agile__nav-button--prev {
    left: 0;
  }
  .agile__nav-button--next {
    right: 0;
  }
  .agile__dots {
    bottom: 10px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
  .agile__dot {
    margin: 0 10px;
  }
  .agile__dot button {
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    height: 10px;
    font-size: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
    transition-duration: 0.3s;
    width: 10px;
  }
  .agile__dot--current button, .agile__dot:hover button {
    background-color: #fff;
  }

  .slide {
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    width: 110%;
  }
  .agile__nav-button[disabled="disabled"] {
    display: none;
  }
}

.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: 50%;
  width: 100px;
  height:  100px;
  margin-top: 10px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
  &:after {
    font-family: swiper-icons;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
  }
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  &:after {
    content: 'prev';
  }
  left: 10px;
  right: auto;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  &:after {
    content: 'next';
  }
  right: 10px;
  left: auto;
}
</style>
