<template>
  <div>
    <!-- List Title -->
    <b-card-header
      v-b-toggle="id"
      class="p-0 mb-1"
      @click="getData"
    >
      <b-card-title>
        {{ title }}
      </b-card-title>
      <feather-icon
        :icon="expandedType === id ? 'ChevronDownIcon' : 'ChevronRightIcon'"
        fill="#333"
        size="18"
      />
    </b-card-header>
    <!-- List -->
    <b-collapse
      :id="id"
      accordion="courses"
      class="pb-1"
      :visible="id === 'grade' ? true : ''"
    >
      <b-overlay :show="isProcessing">
        <span v-if="!items">{{ $t('labels.no-data-found') }}</span>
        <div v-else>
          <div
            v-for="item in items"
            :key="item.id"
          >
            <b-card
              no-body
              border-variant="secondary p-1 mb-1"
              :text-variant="isActive(item.id) ? 'white' : ''"
              :bg-variant="isActive(item.id) ? 'gradient-primary' : ''"
              class="cursor-pointer"
              @click="selectItem(item.id)"
            >
              <b-card-text>
                {{ +item.name && id === 'grades' ? `${ $t('courses-module.set') } ${item.name}` : item.name }}
              </b-card-text>
            </b-card>
          </div>
        </div>
      </b-overlay>
    </b-collapse>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCollapse,
  BOverlay,
  VBToggle,
} from 'bootstrap-vue'
import GEC_EVENT_BUS from '@/utils/eventBus'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCollapse,
    BOverlay,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: () => '',
    },
    id: {
      type: String,
      required: true,
      default: () => '',
    },
    items: {
      type: (Array, Object, null),
      default: () => null,
    },
    isProcessing: {
      type: Boolean,
      default: () => false,
    },
    defaultSelection: {
      type: Number,
      default: () => null,
    },
    expandedType: {
      type: String,
      default: () => 'grades',
    },
    selectedType: {
      type: String,
      default: () => 'grades',
    },
  },
  data() {
    return {
      selectedItem: null,
    }
  },
  watch: {
    defaultSelection(val) {
      if (!this.selectedItem) this.selectItem(val)
    },
  },
  mounted() {
    if (this.$route.params.data?.grade) {
      this.selectedItem = this.$route.params.data.grade
    }
  },
  methods: {
    getData() {
      this.$emit('getData', this.id)
    },
    selectItem(id) {
      if (this.selectedType === 'search') this.selectedItem = null
      if (this.selectedItem === id) GEC_EVENT_BUS.$emit('clearCourseFilter')
      else {
        this.selectedItem = id
        this.$emit('getCourses', this.id, id)
      }
    },
    isActive(id) {
      return (this.selectedItem === id && this.selectedType === this.id)
    },
  },
}
</script>
