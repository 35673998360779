<template>
  <div>
    <b-row>
      <!-- Sidebar -->
      <b-col md="3">
        <sidebar
          @isLoading="() => { isLoading = true }"
          @getCourses="getCourses"
          @searchResult="setSearchResult"
          @selected-grade="id => selectedGrade = id"
        />
      </b-col>

      <!-- Main Content -->
      <b-col md="9">
        <b-overlay
          v-if="(courses && courses.length) || (lessons && lessons.length)"
          :show="isLoading"
        >
          <list
            class="course-component"
            :items="courses"
            :lessons="lessons"
            :current-list="currentList"
            :selected-grade="selectedGrade"
            :search-term="searchTerm"
          />
        </b-overlay>

        <b-card v-else>
          <b-card-title class="text-center">
            {{ $t('courses-module.no-courses') }}
          </b-card-title>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import Sidebar from './components/sidebar/Index.vue'
import List from './components/List.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BOverlay,
    Sidebar,
    List,
  },
  data() {
    return {
      courses: null,
      lessons: null,
      isLoading: false,
      currentList: 'CourseList',
      selectedGrade: null,
      searchTerm: null,
    }
  },
  methods: {
    getCourses(type, subjects) {
      this.searchTerm = null
      switch (type) {
        case 'grades':
          this.currentList = 'CourseList'
          this.isLoading = true
          this.getCoursesBySubject(subjects)
          break
        default:
          break
      }
    },
    getCoursesBySubject(subjects) {
      if (subjects.length) {
        useJwt.getCourses(subjects).then(res => {
          this.courses = res.data?.data
        }).finally(() => { this.isLoading = false })
      } else {
        this.isLoading = false
        this.courses = null
      }
    },
    setSearchResult({ courses, lessons }, searchTerm) {
      this.courses = courses
      this.lessons = lessons
      this.currentList = 'search-result'
      this.searchTerm = searchTerm
    },
  },
}
</script>
