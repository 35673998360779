<template>
  <!-- basic modal -->
  <b-row>
    <b-col
      class="problem-card-title text-center"
      md="12"
    >
      <h4 class="mb-0">
        {{ getProblemHintsDescription($t('student-test-module.speech-problem-hint')) }}
      </h4>
    </b-col>

    <b-col
      md="12"
      class="mtf-main-content problem-overview-content m-0  p-0"
    >
      <b-row
        v-for="(question, i) in localQuestions"
        :key="i"
        class="m-0 p-0"
      >
        <b-col v-if="activeIndex === i">
          <div class="question m-lg-4 m-md-2 border pl-1 pt-1 border-speech">
            <label class="h2 mb-1">{{ $t('labels.speech-question') }}</label><br>
            <div class=" rounded p-1 row">
              <b-col md="9">
                <AudioRecorder :src="tts(i, 'questions')"
                               :only-icon="true"
                               :include-graph="true"
                               class="problem-audio"
                >{{ question.text }}</AudioRecorder>

              </b-col>
              <b-col md="3">
                <b-img
                  v-if="question.imageUrl || question.file"
                  :src="question.imageUrl ? question.imageUrl : question.file"
                  style="height:150px; width:190px;object-fit: contain;"
                  class="img-fluid"
                />
              </b-col>
            </div>
          </div>
          <div
            v-if="problemConversion"
            class="answer mx-lg-4 mt-lg-4 mx-md-2 mt-md-2"
          >
            <label class="h2 mb-1">{{ $t('labels.speech-answer') }}</label>
            <b-form-textarea
              v-model="localQuestions[activeIndex].recordedAnswer.text"
              placeholder="Write your thoughts"
              rows="8"
            />
          </div>
          <div v-else>
            <div class="answer mx-lg-4 mt-lg-4 mx-md-2 mt-md-2">
              <SpeechArea v-for="ans,ai of question.answers"
                          :key="ai"
                          :answer="ans"
                          :tries-length="triesLength"
                          :is-answer-submitted="isAnswerSubmitted"
                          :show-preview-answer="showPreviewAnswer"
                          :preview-answers="getPreviewAnswer"
                          @updateAnswer="a => { ans.recordedAnswer = a; $forceUpdate() }"
              />
            </div>
            <!-- <div class="answer mx-lg-4 mt-lg-4 mx-md-2 mt-md-2">
              <div>
                <label class="h2 mb-1">{{ $t('labels.speech-answer') }}</label><br>
                <div class="border rounded p-1 row">
                  <b-col md="9">
                    <AudioRecorder :src="tts(i, 'answers')"
                                   :only-icon="true"
                                   :include-graph="true"
                    >
                      {{ question.answers[0].text }}
                    </AudioRecorder>
                  </b-col>
                  <b-col md="3">
                    <b-img
                      v-if="question.answers[0].imageUrl || question.answers[0].file"
                      :src="question.answers[0].imageUrl ? question.answers[0].imageUrl : question.answers[0].file"
                      style="height:150px; width:190px;object-fit: contain;"
                    />
                  </b-col>
                </div>
              </div>
            </div> -->
            <!-- <div
              v-if="!showPreviewAnswer"
              class="mt-lg-4 mx-lg-4 mt-md-2 mx-md-2"
            >
              <div v-if="localQuestions[activeIndex].recordedAnswers.length < triesLength">
                <span class="text-primary mb-1">
                  {{ $t('labels.speech-attempt-left', { total: triesLength-localQuestions[activeIndex].recordedAnswers.length, available: triesLength, used: localQuestions[activeIndex].recordedAnswers.length }) }}
                </span>
                <label>{{ $t('labels.supported-browser') }}</label><br>
                <recorder
                  class="border rounded row"
                  :auto-stop="false"
                  @textAudio="value => setAnswer(value)"
                />
              </div>
              <div v-else
                   class="pb-1"
              >
                <span class="text-primary">
                  {{ $t('create-problem.answer-limit-crossed') }}
                </span>
              </div>
            </div> -->
            <!-- <div
              v-else
              class="mt-4 mx-4"
            >
              <label>Your Response (Supported Browsers: Google Chrome & Edge)</label><br>
              <audio
                :src="question.answers[0].audio"
                controls
              />
            </div> -->
            <!-- <div v-if="localQuestions[activeIndex].recordedAnswers.length"
                 class="mx-lg-4 mx-md-2 border rounded speech-answers"
            >
              <b-form-group>
                <template #label>
                  <span>{{ $t('labels.recorded-answer') }}</span>
                </template>
                <div v-for="ans,index in localQuestions[activeIndex].recordedAnswers"
                     :key="index"
                >
                  <b-form-radio
                    v-model="localQuestions[activeIndex].recordedAnswer"
                    name="recorded-answer"
                    :value="ans"
                  >
                    {{ ans.text }}
                  </b-form-radio>
                  <SpeechWave v-if="ans?.audio"
                              :ans="ans"
                  />
                </div>
              </b-form-group>
            </div> -->
          </div>
        </b-col>
      </b-row>
      <b-row class="mx-4 mt-2 d-flex justify-content-end">
        <b-button
          v-if="localQuestions.length != activeIndex + 1"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          :disabled="localQuestions[activeIndex].answers.some(it => !it.recordedAnswer)"
          @click="activeIndex += 1"
        >
          {{ $t("actions.next") }}
        </b-button>
      </b-row>
    </b-col>
    <portal
      to="problemFeedBack"
    >
      <b-row>
        <b-col v-if="feedbackTestIdForSpeech"
               style="position: absolute;text-align: right;z-index: 2;margin-top: -25px;"
        >
          <SpeechAccuracy :test-stat-id="feedbackTestIdForSpeech"
                          :is-demo="feedback.is_demo"
                          :is-report-view="isPreview"
          />
        </b-col>
        <b-col md="12"
               class="p-0"
        >
          <feedback
            :feedback="feedback"
            :problem-statement-id="problemStatementId"
          />
        </b-col>
        <b-col
          v-if="!isPreview"
          class="m-0 p-0"
          md="12"
        >
          <actions
            :is-processing="isProcessing"
            :on-submit="submit"
            :level="level ? level : ''"
            :disabled="!(activeIndex === localQuestions.length - 1) || localQuestions[activeIndex].answers.some(it => !it.recordedAnswer)"
            :has-minimal-view="hasMinimalView"
            :has-feedback="hasFeedback"
            :tooltip-text="$t('student-test-module.match-problem-validation-message')"
            :display-result="displayResult"
            :button-title="hasFeedback ? $t('next') : $t('submit')"
            :engine="engine"
            :school-settings="schoolSettings"
            :district-settings="districtSettings"
            v-on="$listeners"
          />
        </b-col>
      </b-row>
    </portal>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton, BImg, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AudioRecorder from '@/views/common/components/AudioPlayer.vue'
import Actions from '../Actions.vue'
import Feedback from '../Feedback.vue'
// import SpeechWave from '../SpeechWave.vue'
import SpeechAccuracy from '../speech-accuracy/index.vue'
import SpeechArea from './speech-area.vue'

export default ({
  components: {
    BRow, BCol, Actions, Feedback, BButton, BImg, AudioRecorder, BFormTextarea, SpeechAccuracy, SpeechArea,
  },
  directives: {
    Ripple,
  },
  props: {
    questions: {
      type: Array,
      required: true,
    },
    previewAnswers: {
      type: Object,
      default: () => {},
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Object,
      default: () => {},
    },
    displayResult: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      default: 1,
    },
    problemStatementId: {
      type: [Number, String],
      default: () => '',
    },
    startTime: {
      type: [String, Date],
      required: false,
      default: () => null,
    },
    engine: {
      type: String,
      default: () => '',
    },
    hasMinimalView: {
      type: Boolean,
      default: false,
    },
    testId: {
      type: Number,
      default: 0,
    },
    schoolSettings: {
      type: Array,
      default: () => [],
    },
    districtSettings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localQuestions: [],
      localAnswers: [],
      questionAnswer: {},
      selectedAnswer: null,
      questionDataType: 'text',
      answerDataType: 'text',
      voices: [],
      selectedVoice: null,
      synth: null,
      activeIndex: 0,
      activeAnswer: 0,
      isAnswerSubmitted: false,
    }
  },
  computed: {
    getPreviewAnswer() {
      if (this.previewAnswers?.combined_ans) {
        return this.previewAnswers?.combined_ans
      }
      return this.previewAnswers
    },
    activeQuestion() {
      return this.localQuestions[this.activeIndex]
    },
    hasFeedback() {
      return !!(this.feedback?.text?.length > 0 || this.feedback?.image || this.feedback?.audio || this.feedback?.template)
    },
    feedbackTestIdForSpeech() {
      if (this.feedback.test_id && this.feedback.hasSpeechCheck) {
        return this.feedback.test_id
      }
      if (this.isPreview && this.testId) {
        return this.testId
      }
      return null
    },
    isMatch() {
      const question = this.localQuestions[this.activeIndex]
      const answer = question.answers[0].text.replace(/[,.!\s]/gi, '').toLowerCase()
      // const recordedAnswer = question.recordedAnswer.toLowerCase()
      return question.recordedAnswers.map(item => item.text).includes(answer)
      // return recordedAnswer === answer
    },
    showPreviewAnswer() {
      if (this.previewAnswers && Object.keys(this.previewAnswers).length > 0) return true
      return false
    },

    classId() {
      return this.$route.params.classId
    },
    triesLength() {
      return this.$store.state.session.settings.speechTries || 3
    },
    isProblemConversionAllowed() {
      return this.$store.state.problem.isProblemConversionAllowed
    },
    problemConversion: {
      get() {
        return this.$store.state.problem.problemConversion
      },
      set(value) {
        this.$store.dispatch('problem/setProblemConversion', value)
      },
    },
  },
  watch: {
    questions() {
      this.initData()
    },
  },
  created() {
    this.initData()
    this.synth = window.speechSynthesis
    setTimeout(() => {
      this.voices = [...this.synth.getVoices().map((v, i) => ({
        text: v.name, value: i, voice: v, lang: v.lang,
      }))]
      this.selectedVoice = this.voices.find(v => (v.lang === 'en-US'))
      const [first] = this.voices
      if (!this.selectedVoice) this.selectedVoice = first
    }, 1000)
    if (this.previewAnswers) {
      const previewAnswerKeys = Object.keys(this.previewAnswers)
      if (previewAnswerKeys && previewAnswerKeys.length) {
        previewAnswerKeys.forEach(keys => {
          const answers = this.localQuestions.find(ques => `${ques.id}` === keys)
          answers.answers[0].audio = this.previewAnswers[keys].audio
        })
      }
    }
  },
  methods: {
    initData() {
      this.localQuestions = JSON.parse(JSON.stringify(this.questions)).map(q => ({
        ...q, recordedAnswer: {}, recordedAnswers: [], audio: '',
      }))
      this.localAnswers = this.questions.map(e => e.answers[0])
      this.questionDataType = this.questions[0].data_type
      this.answerDataType = this.localAnswers[0].data_type
    },
    async  convertBlobUrlToFile(blobUrl, fileName) {
      try {
        const response = await fetch(blobUrl)

        if (!response.ok) {
          throw new Error(`Failed to fetch Blob URL: ${response.status} ${response.statusText}`)
        }

        const blob = await response.blob()
        const file = new File([blob], fileName, { type: 'audio/wav; codecs=audio/pcm; samplerate=16000' })

        return file
      } catch (error) {
        throw new Error(`Error converting Blob URL to File: ${error.message}`)
      }
    },
    async submit() {
      if (this.problemConversion) {
        const answer = this.localQuestions.map(q => ({
          id: q.id,
          audio: 'audio', // emit submitQuestion requires audio to map the answer
          text: q.recordedAnswer.text,
        }))
        const files = await Promise.all(answer)
        this.$emit('submitQuestion', {
          lang_level: this.localQuestions[0].lang_level,
          answer: files,
        })
      } else {
        const finalAnswer = []
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.localQuestions.length; i++) {
          const question = this.localQuestions[i]
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < question.answers.length; j++) {
            const answer = question.answers[j].recordedAnswer
            // eslint-disable-next-line no-await-in-loop
            const audio = await this.convertBlobUrlToFile(answer?.audio, 'recorded.wav')
            finalAnswer.push({
              id: `${question.id}-${question.answers[j].id}`,
              audio,
              text: answer?.text,
            })
          }
        }
        // const filePromises = this.localQuestions.map(async q => ({
        //   id: q.id,
        //   audio: await this.convertBlobUrlToFile(q.recordedAnswer.audio, 'recorded.wav'),
        //   text: q.recordedAnswer.text,
        // }))

        // const files = await Promise.all(filePromises)
        this.isAnswerSubmitted = true
        this.$emit('submitQuestion', {
          lang_level: this.localQuestions[0].lang_level,
          answer: finalAnswer,
        })
      }
    },
    // eslint-disable-next-line consistent-return
    tts(j, type) {
      let audioFile
      if (type === 'questions') {
        const objectInfo = this.localQuestions[j]
        if (objectInfo.extra_file) {
          audioFile = objectInfo.extra_file
        } else {
          audioFile = objectInfo.text
        }
      } else {
        const objectInfo = this.localQuestions[j].answers[0]
        if (objectInfo.extra_file) {
          audioFile = objectInfo.extra_file
        } else {
          audioFile = objectInfo.text
        }
      }
      return audioFile
    },
    // setAnswer(answer) {
    //   if (this.isAnswerPushing) {
    //     return
    //   }
    //   this.isAnswerPushing = true

    //   this.localQuestions[this.activeIndex].recordedAnswers.push({ text: answer.text.toLowerCase(), audio: answer.audio, file: answer.audioFile })

    //   setTimeout(() => {
    //     this.isAnswerPushing = false
    //     if (this.localQuestions[this.activeIndex].recordedAnswers.length >= 1) {
    //       const lastIndexOfRecordedAnswer = this.localQuestions[this.activeIndex].recordedAnswers.length - 1
    //       // eslint-disable-next-line prefer-destructuring
    //       this.localQuestions[this.activeIndex].recordedAnswer = this.localQuestions[this.activeIndex].recordedAnswers[lastIndexOfRecordedAnswer]
    //     }
    //   }, 1000)
    // },
  },

})
</script>

<style lang="scss">

  .selected-answer{
    box-sizing: border-box !important;
    border: 5px #f1c40f solid !important;
  }
  .drop-zone{
    border:1px solid grey;
    padding:4px;
    box-sizing: border-box;
  }
  .drop-zone-image{
    height:150px;
  }
  .drop-zone-audio{
    height:90px;
  }
  .drop-zone-audio audio{
    max-width: 180px;
  }

  .draggable-type-audio audio{
    max-width: 180px;
  }
  .media audio{
    width: 100%;
  }

  .match-question-item{
    border: 0px solid white;
    /* border-top-width: 0.1px; */
    /* border-bottom-width: 0.1px; */
  }
  .match-question-item:nth-child(odd){
    border-right-width: 1px;
    border-bottom-width: 1px;
  }
  .match-question-item:nth-child(even){
    /* border-right-width: 1px; */
    border-bottom-width: 1px;
  }
  .play-icon {
    width: 40px;
    height: 40px;
    &:hover{
      cursor: pointer;
    }
  }

  .speech-answers{
    padding:20px;
  }
  .theme-transparent .border-speech {
    border-color: black !important;
  }
  .problem-audio {
    span {
      display: flex;
    }
  }
  @media (max-width: 576px) {
  .problem-card-title h4{
    font-size: 20px !important;
    font-weight: 500 !important;
  }
}
  </style>
