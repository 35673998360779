<template>
  <div>
    <b-card
      class="mb-1"
    >
      <validation-observer
        ref="lessonMapperForm"
      >
        <b-form
          @submit.prevent="submitForm"
        >
          <grade-course-filter
            :form="form"
          />
          <b-row>
            <b-col
              md="8"
            >
              <validation-provider
                v-slot="{ errors }"
                name="provider_lesson_name"
                rules="required"
              >
                <b-form-input
                  id="provider-lesson-name"
                  v-model="provider_lesson_name"
                  placeholder="Provider Lesson Name"
                />
                <small class="text-danger">{{ errors[0] }}</small> <br>
              </validation-provider>
            </b-col>
            <b-col
              md="4"
            >
              <b-button
                variant="primary"
                type="submit"
                :disabled="isProcessing"
                @click.prevent="submitForm"
              >
                <b-spinner
                  v-show="isProcessing"
                  class="mr-1"
                  small
                  variant="light"
                />
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>

      </validation-observer>
    </b-card>
    <b-card>
      <b-row>
        <b-col>
          <b-table
            striped
            hover
            responsive
            :fields="columns"
            :items="lessonMappers"
          >
            <template #cell(lesson_id)="data">
              {{ data.item.map.name }}
            </template>
            <template #cell(action)="data">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="$t('actions.delete')"
                variant="outline-danger"
                class="btn-icon"
                @click="toggleDeleteModal(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="$t('actions.edit')"
                variant="outline-success"
                class="btn-icon ml-1"
                @click="toggleEditLessonModal(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>

            </template>
          </b-table>
          <Pagination :pagination="paginationData"
                      :is-processing="isProcessing"
                      @onPaginationChange="page => getLessonMapper(page) "
          />
        </b-col>
      </b-row>
    </b-card>
    <!--Delete Lesson-->
    <b-modal
      :visible="showDeleteLessonModal"
      ok-only
      ok-variant="danger"
      :ok-title="$t('confirm')"
      modal-class="modal-danger"
      centered
      :title="$t('help-videos-moduel.delete_confirm')"
      @ok="deleteLessonMapper"
      @close="showDeleteLessonModal = false"
    >
      <h4> {{ $t('help-videos-moduel.delete') }} </h4>
      <template #modal-footer="{ok }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              :disabled="isProcessing"
              @click="ok()"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t("confirm") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!--Delete Lesson-->
    <!--Update  Lesson-->
    <b-modal
      :visible="showEditLessonModal"
      ok-only
      size="md"
      ok-variant="primary"
      :ok-title="$t('confirm')"
      hide-footer
      :click-to-close="false"
      :no-close-on-backdrop="true"
      modal-class="modal-primary"
      centered
      title="Update"
      no-close-on-esc
      @close="showEditLessonModal = false"
    >
      <!--form-->
      <validation-observer
        ref="updateLessonMapperForm"
      >
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="{ errors }"
              name="provider_lesson_name"
              rules="required"
            >
              <b-form-input
                id="provider-lesson-name"
                v-model="updateLessonMapper.provider_lesson_name"
                placeholder="Provider Lesson Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              :disabled="isProcessing"
              @click="update"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t('actions.submit') }}
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>

      <!--form-->
    </b-modal>
    <!--Update lesson-->
  </div>
</template>
<script>
import {
  BRow, BCol, BForm, BFormInput, BButton, BSpinner, BTable, BModal, BCard,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import useJwt from '@/auth/jwt/useJwt'
import Pagination from '@/views/common/components/Pagination.vue'
import GradeCourseFilter from '../../common/lessons/components/GradeCourseFilter.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormInput,
    BButton,
    BSpinner,
    GradeCourseFilter,
    ValidationProvider,
    ValidationObserver,
    BTable,
    BModal,
    BCard,
    Pagination,
  },
  data() {
    return {
      lessonMappers: [],
      form: {
        gradeId: null,
        courseId: null,
        lessonGroupId: null,
        lessonId: null,
      },
      provider_lesson_name: '',
      // provider: '',
      isProcessing: false,
      columns: [
        {
          label: 'Lesson',
          key: 'lesson_id',
          thStyle: { width: '40%' },
        },
        {
          label: 'Provider Lesson Name',
          key: 'provider_lesson_name',
          thStyle: { width: '40%' },
        },
        {
          label: this.$i18n.tc('user-module.user-table.actions'),
          key: 'action',
          thStyle: { width: '20%' },
        },
      ],
      deleteLessonMapperItem: null,
      showDeleteLessonModal: false,
      showEditLessonModal: false,
      paginationData: {},
    }
  },
  created() {
    this.getLessonMapper()
  },
  methods: {
    getLessonMapper(page = 1) {
      this.isProcessing = true
      useJwt.getLessonMapper({
        params: {
          page,
        },
      }).then(res => {
        this.lessonMappers = res.data.data.data
        this.paginationData = {
          current_page: res.data.data.current_page,
          per_page: res.data.data.per_page,
          total: res.data.data.total,
          last_page: res.data.data.last_page,
        }
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    submitForm() {
      this.$refs.lessonMapperForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt.storeLessonMapper({
            lesson_id: this.form.lessonId,
            provider_lesson_name: this.provider_lesson_name,
          }).then(res => {
            this.getLessonMapper()
            this.showSuccessMessage(res)
          }).catch(error => {
            console.log(error)
          }).finally(() => {
            this.form = {
              gradeId: null,
              courseId: null,
              lessonGroupId: null,
              lessonId: null,
            }
            this.provider_lesson_name = ''
            this.$refs.lessonMapperForm.reset()
            this.isProcessing = false
          })
        }
      })
    },
    toggleDeleteModal(id) {
      this.deleteLessonMapperItem = id
      this.showDeleteLessonModal = true
    },
    toggleEditLessonModal(item) {
      this.updateLessonMapper = item
      this.showEditLessonModal = true
    },
    deleteLessonMapper() {
      useJwt.deleteLessonMapper(this.deleteLessonMapperItem)
        .then(response => {
          this.getLessonMapper()
          this.showDeleteLessonModal = false
          this.showSuccessMessage(response)
        })
    },
    update() {
      this.$refs.updateLessonMapperForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt.updateLessonMapper(this.updateLessonMapper.id,
            {
              provider_lesson_name: this.updateLessonMapper.provider_lesson_name,
            })
            .then(res => {
              this.getLessonMapper()
              this.showSuccessMessage(res)
            }).catch(error => {
              console.log(error)
            }).finally(() => {
              this.updateLessonMapper = {}
              this.showEditLessonModal = false
              this.isProcessing = false
            })
        }
      })
    },
  },
}
</script>
