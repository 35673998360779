<template>
  <BOverlay :show="isProcessing">
    <h3 class="mb-2">
      {{ title }}
    </h3>
    <b-table-simple
      responsive
      class="visibility-options"
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th
            width="30%"
          >
            {{ $t('setting-module.options') }}
          </b-th>
          <b-th
            width="30%"
          >
            {{ $t('user-module.user-table.user-type') }}
          </b-th>
          <b-th
            width="30%"
          >
            {{ $t('setting-module.switch') }}
          </b-th>
          <b-th
            width="10%"
          >
            {{ $t('setting-module.help-guide') }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template
          v-for="([key, value]) in Object.entries(modalInfo)"
        >
          <template v-if="!value.children">
            <b-tr :key="key">
              <b-td>{{ value.title }} </b-td>
              <b-td>
                <span v-if="(key==='report_issue' && (isADistrict || optionsLocal.district?.[key] !== '1'))">
                  <b-form-checkbox-group
                    :id="`user_type_${key}`"
                    v-model="optionsLocal[key].user_type"
                    :options="childUsers"
                    class="m-0"
                    @change="updateSchoolSetting(key)"
                  />
                </span>
                <span v-else>
                  ---
                </span>
              </b-td>
              <b-td>
                <div>
                  <p v-if="optionsLocal.district?.[key] === '1'">
                    {{ $t('setting-module.hidden-by-district') }}
                  </p>
                  <span
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <b-form-checkbox
                      :id="key"
                      v-model="optionsLocal[key].value"
                      value="0"
                      unchecked-value="1"
                      switch
                      style="margin-right: -16px"
                      @change="updateSchoolSetting(key)"
                    /><span class="ml-2">{{ $t('setting-module.hide-show') }}</span>
                  </span>
                </div>
              </b-td>
              <b-td>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.top="$t('setting-module.help-guide')"
                  variant="outline-success"
                  class="btn-icon"
                  @click="showHelpGuide(key)"
                >
                  <feather-icon icon="InfoIcon" />
                </b-button>
              </b-td>
            </b-tr>
          </template>
          <template v-else>
            <b-tr :key="key"
                  class="table-secondary"
            >
              <b-td colspan="4">
                <h4 class="mb-0">
                  {{ value.title }}
                </h4>
              </b-td>
            </b-tr>
            <template
              v-for="([childKey, childValue]) in Object.entries(value.children)"
            >
              <b-tr :key="childKey">
                <b-td> {{ childValue.title }}</b-td>
                <b-td>---- </b-td>
                <b-td>
                  <p v-if="optionsLocal.district?.[childKey] === '1'">
                    {{ $t('setting-module.hidden-by-district') }}
                  </p>
                  <span
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <b-form-checkbox
                      :id="childKey"
                      v-model="optionsLocal[childKey].value"
                      value="0"
                      unchecked-value="1"
                      switch
                      style="margin-right: -16px"
                      @change="updateSchoolSetting(childKey)"
                    />
                    <span class="ml-2">{{ $t('setting-module.hide-show') }}</span>
                  </span>
                </b-td>
                <b-td>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top="$t('setting-module.help-guide')"
                    variant="outline-success"
                    class="btn-icon"
                    @click="showHelpGuide(childKey, key)"
                  >
                    <feather-icon icon="InfoIcon" />
                  </b-button></b-td>
              </b-tr>
            </template>
            <b-tr :key="`${key}_b`">
              <b-td colspan="4">
                  &nbsp;
              </b-td>
            </b-tr>
          </template>
        </template>
      </b-tbody>
    </b-table-simple>
    <setting-info-modal
      :show="showModal"
      :modal-data="modalData"
      @close="showModal = false"
    />
  </BOverlay>
</template>
<script>
import {
  BFormCheckbox, BTableSimple, BFormCheckboxGroup,
  BThead, BTbody, BTr, BTh, BTd, BButton, BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import SettingInfoModal from '@/views/common/components/SettingInfoModal.vue'
import { USER_TYPE_DISTRICT } from '@/const/userType'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BOverlay,
    BFormCheckbox,
    BTableSimple,
    BFormCheckboxGroup,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
    SettingInfoModal,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    optionsLocal: {
      type: Object,
      default: () => {},
    },
    childUsers: {
      type: Array,
      default: () => [],
    },
    currentUserType: {
      type: String,
      default: '',
    },
    modalInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isProcessing: false,
      self: getUserData(),
      showModal: false,
      modalData: {
        title: '',
        imgSrc: '',
        description: '',
        steps: '',
      },
    }
  },
  computed: {
    isADistrict() {
      return this.self.usertype === USER_TYPE_DISTRICT
    },
  },
  methods: {
    updateSchoolSetting(type) {
      const { value } = this.optionsLocal[type]
      const userType = this.optionsLocal[type]?.user_type
      this.isProcessing = true
      useJwt.updateUserSettings({
        key: type, value, userType, forceSync: this.$store.state.taskHelper.forceSyncSettingToSchool,
      }).then(() => {
        this.showSuccessMessage({ data: { message: 'Permission updated' } })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    showHelpGuide(type, parentType = null) {
      this.modalData = {
        title: !parentType ? this.modalInfo[type].title : this.modalInfo[parentType].children[type].title,
        imgSrc: !parentType ? this.modalInfo[type].imageSrc : this.modalInfo[parentType].children[type].imageSrc,
        description: !parentType ? this.modalInfo[type].description : this.modalInfo[parentType].children[type].description,
        steps: !parentType ? this.modalInfo[type].steps : this.modalInfo[parentType].children[type].steps,
      }
      this.$nextTick(() => {
        this.showModal = true
      })
    },
  },
}
</script>
  <style lang="scss">
    .dark-layout {
      .visibility-options{
        .table-secondary {
          background-color: #5e5873
        }
      }
    }
    .modal-steps
     {
      ul {
      padding-left: 23px;
        li{
          list-style: decimal;
        }
      }
     }
     .nested-skills {
      td {
        border-top: unset !important;
      }
     }
  </style>
