<template>
  <div>
    <b-col md="12">
      <validation-observer ref="editProblemForm">
        <b-col
          md="12"
          class="px-0"
        >
          <div>
            <b-tabs
              pills
              align="center"
              class="lang-level-tabs"
            >

              <b-tab
                v-for="(level, index) in problem.levels"
                :key="index + level"
                :title="getLangLevelTransText(level)"
                :title-item-class="showLangLevel ? '' : 'd-none'"
                :active="activeTabIndex === index"
                @click="activeTabIndex=index"
              >

                <b-button
                  variant="success"
                  :disabled="isProcessing"
                  @click="showCopyProblemModal = true"
                >
                  {{ $t('labels.copy-problem') }}
                </b-button>
                <!-- </div> -->
                <b-row class="mt-3">
                  <b-col
                    md="12"
                    lg="4"
                    class="card p-2"
                  >
                    <!-- Name -->
                    <b-form-group
                      :label="$t('labels.create-problem.problem-name')"
                      label-for="name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="name"
                        rules="required|max:256"
                        vid="name"
                      >
                        <b-form-input
                          :id="`name-${index}`"
                          v-model="problem.name"
                          :placeholder="$t('labels.name')"
                          name="name"
                          @keyup.enter="!errors.length ? updateProblem() : null"
                          @blur="!errors.length ? updateProblem() : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Name -->
                    <b-form-group
                      :label="$t('labels.create-problem.tts-support')"
                      label-for="Domain"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Domain"
                        rules="required"
                      >
                        <b-form-select
                          id="domain"
                          v-model="problem.ttsSupport"
                          :options=" [ { text: 'Yes', value: true}, {text: 'No' , value: false} ]"
                          name="tts_support"
                          :state="errors.length > 0 ? false : null"
                          @change="updateProblem()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Domain -->
                    <b-form-group
                      :label="$t('labels.create-problem.domain')"
                      label-for="Domain"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Domain"
                        rules="required"
                      >
                        <b-form-select
                          id="domain"
                          v-model="selectedDomain"
                          :options="domains"
                          name="standard"
                          :state="errors.length > 0 ? false : null"
                          @change="fetchStandard"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Domain -->

                    <!-- Standard -->
                    <b-form-group
                      :label="$t('labels.create-problem.standard')"
                      label-for="standard"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="standard"
                        vid="standard"
                      >
                        <b-form-select
                          id="standard"
                          v-model="problem.standard_id"
                          :options="standards"
                          :disabled="selectedDomain ? false:true"
                          name="standard"
                          @change="updateProblem"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Standard -->

                    <b-form-group
                      :label="$t('labels.create-problem.tags')"
                      label-for="tag"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="tag"
                        rules="required"
                      >
                        <v-select
                          v-model="problem.tags"
                          :class="!!errors[0] ? 'v-select-error':''"
                          multiple
                          label="text"
                          :options="tags"
                          placeholder="Please select a Tag"
                          :state="errors.length > 0 ? false : null"
                          @input="updateProblem"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Skill Type -->
                    <b-form-group
                      :label="$t('labels.create-problem.skill-type')"
                      label-for="skill-type"
                      rules="required"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="skill-type"
                        vid="skill-type"
                      >
                        <b-form-select
                          id="skill-type"
                          v-model="problem.skill_type"
                          :options="skillTypes"
                          name="skill-type"
                          @change="updateProblem"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- /Skill Type -->

                    <!-- Theme -->
                    <b-form-group
                      :label="$t('labels.create-problem.theme')"
                      label-for="theme"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="theme"
                        vid="theme"
                      >
                        <b-form-select
                          id="theme"
                          v-model="problem.theme"
                          :options="themes"
                          name="theme"
                          @change="updateTheme"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <theme-preview :theme="problem.theme" />
                    <!-- Theme -->

                    <video-type
                      v-if="videoTimeStamp"
                      :video-time-stamp="videoTimeStamp"
                      @updated="$emit('fetch')"
                    />

                    <!-- Statement & Feedback -->
                    <div>
                      <b-form-group label="Hide Statement">
                        <b-form-checkbox v-model="problem.hideStatement"
                                         switch
                                         @change="updateProblem()"
                        />
                      </b-form-group>
                      <div v-if="!hotspotWrapper">
                        <!-- Statement -->
                        <div v-if="!videoTimeStamp">
                          <label>{{ $t('labels.create-problem.problem-statement') }}</label>
                          <b-tabs
                            pills
                            class="mx-0 px-0 mt-1"
                          >
                            <b-tab
                              title="Image"
                              :active="problem.stType[index] === 'image'"
                              @click="problem.stType[index] = 'image'"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                :vid="`statement-image-${level}`"
                              >
                                <image-form
                                  :url="problem.statementImage.url[index]"
                                  @input="handleStatementImage($event, 'statementImage', index)"
                                  @fileTypeInvalid="setError([`statement-image-${level}`], $event)"
                                  @fileSizeInvalid="setError([`statement-image-${level}`], $event)"
                                />
                                <small class="text-danger">{{ errors[0] }}</small><br>
                              </validation-provider>
                            </b-tab>
                            <b-tab
                              title="Audio"
                              :active="problem.stType[index] === 'audio'"
                              @click="problem.stType[index] = 'audio'"
                            >
                              <b-form-group
                                :label="$t('labels.create-problem.statement-audio')"
                                label-for="audio"
                              >
                                <validation-provider
                                  v-slot="{ errors }"
                                  :vid="`statement-audio-${level}`"
                                >
                                  <Recorder
                                    :url="problem.statementAudio.url[index]"
                                    @input="setStatementAudio($event, index, 'statementAudio')"
                                    @fileTypeInvalid="setError([`statement-audio-${level}`], $event)"
                                    @fileSizeInvalid="setError([`statement-audio-${level}`], $event)"
                                    @onSelectedFromLibrary="value => updateProblemStatementAudio(value, index, 'st_image' )"
                                  />
                                  <input
                                    v-model="problem.statementAudio.url[index]"
                                    hidden
                                  >
                                  <small class="text-danger">{{ errors[0] }}</small><br>
                                </validation-provider>
                              </b-form-group>
                            </b-tab>
                          </b-tabs>
                          <b-form-group
                            :label="$t('labels.create-problem.statement')"
                            label-for="statement"
                          >
                            <div v-if="!problem.statement[index].statementTemplateId">
                              <quill-editor-custom
                                v-model="problem.statement[index].statement"
                                :placeholder="$t('labels.create-problem.placeholder.statement')"
                                @blur="updateProblemStatement(index)"
                              />
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="outline-primary"
                                class="mt-2"
                                block
                                @click="()=>{templateType='statement';fetchTemplate('new')}"
                              >
                                {{ 'Create Template' }}
                              </b-button>
                            </div>
                            <b-button
                              v-else
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="outline-primary"
                              block
                              :disabled="templateBeingFetched === problem.statement[index].statementTemplateId"
                              @click="()=>{templateType='statement';fetchTemplate(problem.statement[index].statementTemplateId)}"
                            >
                              {{ 'Update Template' }}
                            </b-button>

                          </b-form-group>
                        </div>

                        <!-- /Statement -->

                        <!-- Feedback -->
                        <label>{{ $t('labels.create-problem.problem-feedback') }}</label>
                        <b-form-group label="Hide FeedBack">
                          <b-form-checkbox v-model="problem.hideFeedBack"
                                           switch
                                           @change="updateProblem()"
                          />
                        </b-form-group>
                        <b-tabs
                          pills
                          class="mx-0 px-0 mt-1"
                        >
                          <b-tab
                            title="Image"
                            :active="problem.fbType[index] === 'image'"
                            @click="problem.fbType[index] = 'image'"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              :vid="`feedback-image-${level}`"
                            >
                              <image-form
                                :url="problem.feedbackImage.url[index]"
                                @input="handleStatementImage($event, 'feedbackImage', index)"
                                @fileTypeInvalid="setError([`feedback-image-${level}`], $event)"
                                @fileSizeInvalid="setError([`feedback-image-${level}`], $event)"
                              />
                              <small class="text-danger">{{ errors[0] }}</small><br>
                            </validation-provider>
                          </b-tab>
                          <b-tab
                            title="Audio"
                            :active="problem.fbType[index] === 'audio'"
                            @click="problem.fbType[index] = 'audio'"
                          >
                            <b-form-group
                              :label="$t('labels.create-problem.feedback-audio')"
                              label-for="audio"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                :vid="`feedback-audio-${level}`"
                              >
                                <Recorder
                                  :url="problem.feedbackAudio.url[index]"
                                  @input="setStatementAudio($event, index, 'feedbackAudio')"
                                  @fileTypeInvalid="setError([`feedback-audio-${level}`], $event)"
                                  @fileSizeInvalid="setError([`feedback-audio-${level}`], $event)"
                                  @onSelectedFromLibrary="value => updateProblemStatementAudio(value, index, 'fb_image' )"
                                />
                                <input
                                  v-model="problem.feedbackAudio.url[index]"
                                  hidden
                                >
                                <small class="text-danger">{{ errors[0] }}</small><br>
                              </validation-provider>
                            </b-form-group>
                          </b-tab>
                        </b-tabs>
                        <b-form-group
                          :label="$t('labels.create-problem.feedback')"
                          label-for="feedback"
                        >
                          <div v-if="!problem.statement[index].feedbackTemplateId">
                            <quill-editor-custom
                              v-model="problem.statement[index].feedback"
                              :placeholder="$t('labels.create-problem.placeholder.feedback')"
                              @blur="updateProblemStatement(index)"
                            />
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="outline-primary"
                              class="mt-2"
                              block
                              @click="()=>{templateType='feedback';fetchTemplate('new')}"
                            >
                              {{ 'Create Template' }}
                            </b-button>
                          </div>
                          <b-button
                            v-else
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            block
                            :disabled="templateBeingFetched === problem.statement[index].feedbackTemplateId"
                            @click="()=>{templateType='feedback';fetchTemplate(problem.statement[index].feedbackTemplateId)}"
                          >
                            {{ 'Update Template' }}
                          </b-button>
                        </b-form-group>
                      </div>
                      <!-- <word-net-section
                        :type="'course'"
                        :problem-statement-id="problem.statement ? problem.statement[index].id : null"
                        :main-text="problem.statement[index].statement +' '+ problem.statement[index].feedback"
                      /> -->
                    </div>
                    <!-- Statement & Feedback -->
                    <div v-if="hotspotWrapper">
                      <b-form-group
                        :label="$t('labels.create-problem.statement')"
                        label-for="statement"
                      >

                        <b-form-input
                          id="statement"
                          v-model="problem.statement[index].statement"
                          placeholder="Statement"
                          @blur="updateProblemStatement(index)"
                          @keyup.enter="updateProblemStatement(index)"
                        />

                      </b-form-group>
                      <b-form-group
                        :label="$t('labels.create-problem.statement-audio')"
                        label-for="audio"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :vid="`statement-audio-${level}`"
                        >
                          <Recorder
                            :url="problem.statementAudio.url[index]"
                            @input="setStatementAudio($event, index, 'statementAudio')"
                            @onSelectedFromLibrary="value => updateProblemStatementAudio(value, index, 'st_image' )"
                            @fileTypeInvalid="setError([`statement-audio-${level}`], $event)"
                            @fileSizeInvalid="setError([`statement-audio-${level}`], $event)"
                          />
                          <input
                            v-model="problem.statementAudio.url[index]"
                            hidden
                          >
                          <small class="text-danger">{{ errors[0] }}</small><br>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        :label="$t('labels.create-problem.feedback')"
                        label-for="feedback"
                      >
                        <b-form-input
                          id="feedback"
                          v-model="problem.statement[index].feedback"
                          placeholder="Feedback"
                          @blur="updateProblemStatement(index)"
                          @keyup.enter="updateProblemStatement(index)"
                        />
                      </b-form-group>
                      <b-form-group
                        :label="$t('labels.create-problem.feedback-audio')"
                        label-for="audio"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :vid="`feedback-audio-${level}`"
                        >
                          <Recorder
                            :url="problem.feedbackAudio.url[index]"
                            @input="setStatementAudio($event, index, 'feedbackAudio')"
                            @onSelectedFromLibrary="value => updateProblemStatementAudio(value, index, 'fb_image' )"
                            @fileTypeInvalid="setError([`feedback-audio-${level}`], $event)"
                            @fileSizeInvalid="setError([`feedback-audio-${level}`], $event)"
                          />
                          <input
                            v-model="problem.feedbackAudio.url[index]"
                            hidden
                          >
                          <small class="text-danger">{{ errors[0] }}</small><br>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col
                    md="12"
                    lg="8"
                    class="pl-md-0 pl-lg-1 pr-0"
                  >
                    <b-card>
                      <!-- CEFR Subject -->
                      <b-form-group
                        :label="$t('labels.create-problem.cefr-subject')"
                        label-for="cefrSubject"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="cefrSubject"
                          vid="cefrSubject"
                        >
                          <b-form-select
                            id="cefrSubject"
                            v-model="problem.cefr.selectedSubjects[index]"
                            :options="subjectList"
                            name="cefrSubject"
                            @change="fetchCefrDomain(index, true)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- CEFR Subject -->
                      <!-- CEFR Domain -->
                      <b-form-group
                        :label="$t('labels.create-problem.cefr-domain')"
                        label-for="cefrDomain"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="cefrDomain"
                          vid="cefrDomain"
                        >
                          <b-form-select
                            id="cefrDomain"
                            v-model="problem.cefr.selectedDomains[index]"
                            :options="cefrDomainList[index]"
                            :disabled="problem.cefr.selectedSubjects[index] ? false:true"
                            name="cefrDomain"
                            @change="fetchCefrStandard(index, true)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- CEFR Domain -->
                      <!-- CEFR Standard -->
                      <b-form-group
                        :label="$t('labels.create-problem.cefr-standard')"
                        label-for="cefrStandard"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="cefrStandard"
                          vid="cefrStandard"
                        >
                          <b-form-select
                            id="cefrStandard"
                            v-model="problem.cefr.selectedStandards[index]"
                            :options="cefrStandardList[index]"
                            :disabled="problem.cefr.selectedDomains[index] ? false:true"
                            name="cefrStandard"
                            @change="updateCefrStandard(index)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- problem descriptions -->
                      <b-form-group
                        :label="$t('labels.create-problem.problem-description')"
                        label-for="cefrStandard"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="problemDescription"
                          vid="problemDescription"
                        >
                          <b-form-input
                            id="pageLink"
                            v-model="problem.description"
                            :placeholder="$t('labels.create-problem.problem-description')"
                            name="problemDescription"
                            @blur="updateProblem"
                            @keyup.enter="updateProblem"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- Page Link -->
                      <b-form-group
                        :label="$t('labels.create-problem.page-link')"
                        label-for="cefrStandard"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="pageLink"
                          vid="pageLink"
                        >
                          <b-form-input
                            id="pageLink"
                            v-model="problem.pageLink[index]"
                            :placeholder="$t('labels.create-problem.page-link')"
                            name="pageLink"
                            @blur="updatePageLink(index, 0)"
                            @keyup.enter="updatePageLink(index, 0)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <!-- Question / Answer -->
                      <div
                        v-for="question, j in activeQuestions[index]"
                        :key="j"
                      >
                        <b-row class="my-1">
                          <!-- Question -->
                          <b-col md="5">
                            <b-form-group
                              v-if="problem.questionType === 'text'"
                              :label="$t('labels.create-problem.question')"
                              label-for="question"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                                name="question"
                                vid="question"
                              >
                                <b-form-textarea
                                  :id="`question-${index}-${j}-matching`"
                                  v-model="problem.questions[problem.questionType][index][j].text"
                                  :state="errors.length > 0 ? false : null"
                                  :placeholder="$t('labels.create-problem.placeholder.question')"
                                  name="question"
                                  @blur="updateQuestion(index, j)"
                                  @keyup.enter="updateQuestion(index, j)"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                            <!-- Image -->
                            <b-form-group
                              v-else-if="problem.questionType === 'image'"
                              :label="$t('labels.create-problem.question')"
                              :label-for="`question-${level + j}`"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="question"
                                :vid="`question-${level + j}`"
                              >
                                <image-form
                                  :url="problem.questions.imageUrl[index][j].text"
                                  :caption="problem.questions.caption[index][j].text"
                                  :show-caption="true"
                                  @input="handleImage($event, 'questions', index, j)"
                                  @fileTypeInvalid="setError([`question-${level + j}`], $event)"
                                  @fileSizeInvalid="setError([`question-${level + j}`], $event)"
                                />
                                <input
                                  v-model="problem.questions.imageUrl[index][j].text"
                                  hidden
                                >
                                <small class="text-danger">{{ errors[0] }}</small><br>
                              </validation-provider>
                            </b-form-group>
                            <!-- Image -->
                            <!-- Audio -->
                            <b-form-group
                              v-else-if="problem.questionType === 'audio'"
                              :label="$t('labels.create-problem.question')"
                              label-for="audio"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="question"
                                :vid="`question-audio-${level + j}`"
                              >
                                <Recorder
                                  :url="problem.questions.audioUrl[index][j].text"
                                  @input="setAudio(index, j, 'questions', $event)"
                                  @fileTypeInvalid="setError([`question-audio-${level + j}`], $event)"
                                  @fileSizeInvalid="setError([`question-audio-${level + j}`], $event)"
                                />
                                <input
                                  v-model="problem.questions.audioUrl[index][j].text"
                                  hidden
                                >
                                <small class="text-danger">{{ errors[0] }}</small><br>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- /Question -->

                          <!-- Answer -->
                          <b-col md="5">
                            <b-form-group
                              v-if="problem.answerType === 'text'"
                              :label="$t('labels.create-problem.answer')"
                              label-for="answer"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                                name="answer"
                                vid="answer"
                              >
                                <b-form-textarea
                                  :id="`answer-${index}-${j}-matching`"
                                  v-model="problem.answers[problem.answerType][index][j].text"
                                  name="answer"
                                  :state="errors.length > 0 ? false : null"
                                  :placeholder="$t('labels.create-problem.placeholder.answer')"
                                  @blur="updateAnswer(index, j)"
                                  @keyup.enter="updateAnswer(index, j)"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                            <!-- Image -->
                            <b-form-group
                              v-else-if="problem.answerType === 'image'"
                              :label="$t('labels.create-problem.answer')"
                              label-for="article-image"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="answer"
                                :vid="`answer-matching-${level + j}`"
                              >
                                <image-form
                                  :url="problem.answers.imageUrl[index][j].text"
                                  :caption="problem.answers.caption[index][j].text"
                                  :show-caption="true"
                                  @input="handleImage($event, 'answers', index, j)"
                                  @fileTypeInvalid="setError([`answer-matching-${level + j}`], $event)"
                                  @fileSizeInvalid="setError([`answer-matching-${level + j}`], $event)"
                                />
                                <input
                                  v-model="problem.answers.imageUrl[index][j].text"
                                  hidden
                                >
                                <small class="text-danger">{{ errors[0] }}</small><br>
                              </validation-provider>
                            </b-form-group>
                            <!-- Image -->
                            <!-- Audio -->
                            <b-form-group
                              v-else-if="problem.answerType === 'audio'"
                              :label="$t('labels.create-problem.answer')"
                              label-for="article-image"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="answer"
                                :vid="`answer-audio-${level + j}`"
                              >
                                <Recorder
                                  :url="problem.answers.audioUrl[index][j].text"
                                  @input="setAudio(index, j, 'answers', $event)"
                                  @onSelectedFromLibrary="value => setAudio(index, j, 'answers', {
                                    audio: value,
                                    url: value
                                  })"
                                  @fileTypeInvalid="setError([`answer-audio-${level + j}`], $event)"
                                  @fileSizeInvalid="setError([`answer-audio-${level + j}`], $event)"
                                />
                                <input
                                  v-model="problem.answers.audioUrl[index][j].text"
                                  hidden
                                >
                                <small class="text-danger">{{ errors[0] }}</small><br>
                              </validation-provider>
                            </b-form-group>
                            <!-- Audio -->
                          </b-col>
                          <!-- /Answer -->

                          <b-col
                            class="mt-2"
                            md="2"
                          >
                            <b-button
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="outline-danger"
                              class="btn-icon mr-1"
                              @click="toggleDeleteModal(j)"
                            >
                              <feather-icon icon="TrashIcon" />
                              {{ $t('Delete') }}
                            </b-button>
                          </b-col>
                        </b-row>
                        <hr>
                      </div>

                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        class="btn-icon"
                        @click="modal.show = true"
                      >
                        <feather-icon icon="PlusIcon" />
                        {{ $t('Add-New') }}
                      </b-button>
                      <!-- /Question / Answer -->
                    </b-card>
                    <b-card>
                      <b-col
                        class="d-flex justify-content-between"
                      >
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-success"
                          class="mr-2"
                          @click="$emit('showQuestionPreview',getDataForQuestionPreview())"
                        >
                          {{ $t("actions.preview") }}
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          @click="$router.push({ path: '/super/problem', query: { lesson_id: $route.query.lesson_id } })"
                        >
                          {{ $t("actions.back") }}
                        </b-button>
                      </b-col>
                    </b-card>
                  </b-col>
                </b-row>
              </b-tab>
              <b-col
                md="12"
                lg="4"
                class="p-0 m-0"
              >
                <hot-spot
                  v-if="hotspotWrapper"
                  :hotspot-point="problemData.hotspot_point"
                  @pointUpdated="$emit('fetch')"
                />
              </b-col>
            </b-tabs>
          </div>
        </b-col>
      </validation-observer>
    </b-col>

    <!-- add item -->
    <add-modal
      :toggle="modal.show"
      :answer-type="problem.answerType"
      :question-type="problem.questionType"
      @close="modal.show = false"
      @fetch="$emit('fetch')"
    />
    <!-- add item -->

    <!-- delete item -->
    <b-modal
      id="modal-delete"
      ref="modal-delete"
      ok-only
      ok-variant="danger"
      :ok-title="$t('confirm')"
      modal-class="modal-danger"
      centered
      :title="$t('actions.delete-question')"
      @ok="deleteQuestion()"
    >
      <b-card-text>
        {{ $t("questions.delete-question") }}
      </b-card-text>
      <template #modal-footer="{ ok }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              :disabled="isProcessing"
              @click="ok()"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t("confirm") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- delete item -->

    <!-- create edit template -->
    <create-template
      :show="visibleTemplate!=null"
      :template="problemTemplates[visibleTemplate]"
      :title="`Update ${templateType} Template`"
      :extra-template-para="extraTemplatePara"
      @removeExtraParams="extraTemplatePara = null"
      @templateStored="templateStored"
      @close="visibleTemplate= null"
    />
    <!-- create edit template -->

    <copy-to-lesson-modal
      :problem-id="problemData.id"
      :show="showCopyProblemModal"
      @close="showCopyProblemModal=false"
    />

  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BFormTextarea,
  BButton,
  BSpinner,
  BTab,
  BTabs,
  BCardText,
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ThemePreview from '@views/common/components/ThemePreview.vue'
import CreateTemplate from '@views/common/templates/CreateEdit.vue'
import QuillEditorCustom from '@views/common/components/QuillEditorCustom.vue'
import vSelect from 'vue-select'
import SkillTypes from '@/const/skillTypes'
import VideoType from '../../components/edit/VideoType.vue'
import HotSpot from '../../components/edit/HotSpot.vue'
import Recorder from '../../components/recorder.vue'
import AddModal from './AddModal.vue'
import ImageForm from '../../components/ImageForm.vue'
// import request from '../request'
import CopyToLessonModal from '../components/CopyToLessonModal.vue'

export default {
  components: {
    BCol,
    BRow,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BTab,
    BTabs,
    BCardText,
    Recorder,
    AddModal,
    BCard,
    QuillEditorCustom,
    VideoType,
    ImageForm,
    HotSpot,
    BFormCheckbox,
    ThemePreview,
    CreateTemplate,
    BFormTextarea,
    // WordNetSection,
    CopyToLessonModal,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    problemData: {
      type: Object,
      default: () => {},
    },
    domains: {
      type: Array,
      default: () => [{ value: null, text: 'Please select a domain' }],
    },
    subjectList: {
      type: Array,
      default: () => [{ value: null, text: 'Please select a subject' }],
    },
    tags: {
      type: Array,
      default: () => { },
    },
  },
  data: () => ({
    isProcessing: false,
    showCopyProblemModal: false,
    activeTabIndex: 3,
    modal: {
      show: false,
    },
    problem: {
      questionLength: 1,
      questionOptions: [
        { value: 'text', text: 'Text', disabled: false },
        { value: 'image', text: 'Image', disabled: false },
        { value: 'audio', text: 'Audio', disabled: false },
      ],
      answerOptions: [
        { value: 'text', text: 'Text', disabled: false },
        { value: 'image', text: 'Image', disabled: false },
        { value: 'audio', text: 'Audio', disabled: false },
      ],
      questionType: 'text',
      answerType: 'text',
      allLevels: ['A1', 'A2', 'B1', 'B2'],
      levels: [],
      questions: {
        text: [[], [], [], []],
        image: [[], [], [], []],
        imageUrl: [[], [], [], []],
        caption: [[], [], [], []],
        audio: [[], [], [], []],
        audioUrl: [[], [], [], []],
      },
      answers: {
        text: [[], [], [], []],
        image: [[], [], [], []],
        imageUrl: [[], [], [], []],
        caption: [[], [], [], []],
        audio: [[], [], [], []],
        audioUrl: [[], [], [], []],
      },
      standard_id: null,
      name: '',
      statement: [{}, {}, {}, {}],
      statementImage: { url: ['', '', '', ''], file: ['', '', '', ''] },
      feedbackImage: { url: ['', '', '', ''], file: ['', '', '', ''] },
      stType: [],
      fbType: [],
      statementAudio: { url: ['', '', '', ''], file: ['', '', '', ''] },
      feedbackAudio: { url: ['', '', '', ''], file: ['', '', '', ''] },
      theme: null,
      cefr: {
        selectedSubjects: [null, null, null, null],
        selectedDomains: [null, null, null, null],
        selectedStandards: [null, null, null, null],
      },
      pageLink: [],
      skill_type: '',
    },
    isValid: false,
    changed: {
      name: false,
      questions: false,
      statement: false,
      feedback: false,
      standard_id: false,
    },
    deleteIndex: null,
    selectedDomain: null,
    standards: [{ value: null, text: 'Please select a standard' }],
    themes: [
      { value: 0, text: 'Default' },
      { value: 1, text: 'Theme-1' },
      { value: 2, text: 'Theme-2' },
      { value: 3, text: 'Theme-3' },
      { value: 4, text: 'Theme-4' },
      { value: 5, text: 'Theme-5' },
      { value: 6, text: 'Theme-6' },
      { value: 7, text: 'Theme-7' },
      { value: 8, text: 'Theme-8' },
      { value: 9, text: 'Theme-9' },
    ],
    cefrDomainList: [
      [{ value: null, text: 'Please select a domain' }],
      [{ value: null, text: 'Please select a domain' }],
      [{ value: null, text: 'Please select a domain' }],
      [{ value: null, text: 'Please select a domain' }],
    ],
    cefrStandardList: [
      [{ value: null, text: 'Please select a standard' }],
      [{ value: null, text: 'Please select a standard' }],
      [{ value: null, text: 'Please select a standard' }],
      [{ value: null, text: 'Please select a standard' }],
    ],
    // extra data
    visibleTemplate: null,
    templateBeingFetched: null,
    problemTemplates: {},
    templateType: '',
    videoTimeStamp: null,
    hotspotWrapper: false,
    extraTemplatePara: null,
    ttsSupport: false,
    skillTypes: SkillTypes,
  }),
  computed: {
    activeQuestions: {
      get() {
        let type = 'text'
        if (this.problem.questionType === 'audio') type = 'audioUrl'
        else if (this.problem.questionType === 'image') type = 'imageUrl'
        return this.problem.questions[type]
      },
    },
    showLangLevel() {
      let status = false
      if (this.problemData?.questions?.find(q => q.lang_level !== '1')) {
        status = true
      }
      return status
    },
  },
  watch: {
    problemData() {
      this.setPage()
    },
    activeTabIndex() {
      if (this.problem.statement[this.activeTabIndex].statementTemplateId) this.fetchTemplate(this.problem.statement[this.activeTabIndex].statementTemplateId, false)
      if (this.problem.statement[this.activeTabIndex].feedbackTemplateId) this.fetchTemplate(this.problem.statement[this.activeTabIndex].feedbackTemplateId, false)
    },
  },
  created() {
    if (this.$route.query.lang_level) {
      this.activeTabIndex = Number(this.$route.query.lang_level) - 1
    }
    this.setPage()
    this.fetchLessons()
  },
  methods: {
    templateStored(data) {
      if (Number.isInteger(this.visibleTemplate)) {
        this.problemTemplates[this.visibleTemplate] = data
        this.visibleTemplate = null
        return
      }
      this.problemTemplates[this.activeTabIndex + this.templateType] = data
      const newStatment = data.problemstatment
      const oldStatment = this.problem.statement
      oldStatment[this.activeTabIndex] = {
        ...oldStatment[this.activeTabIndex],
        statementTemplateId: newStatment.st_template_id,
        feedbackTemplateId: newStatment.fb_template_id,
      }
      this.visibleTemplate = null
      this.$set(this.problem, 'statement', oldStatment)
    },
    fetchTemplate(id, viewTemplate = true) {
      if (id === 'new') {
        this.extraTemplatePara = {
          problem_id: this.problemData.id,
          lang_level: this.activeTabIndex + 1,
          st_type: this.templateType,
          standard_id: this.problem.standard_id,
        }
        this.visibleTemplate = this.activeTabIndex + this.templateType
        return null
      }
      return new Promise(resolve => {
        if (this.problemTemplates[id]) {
          if (viewTemplate) this.visibleTemplate = id
          return
        }

        this.templateBeingFetched = id
        useJwt.getTemplate(id).then(res => {
          this.problemTemplates[id] = res.data.data
          if (viewTemplate) this.visibleTemplate = id
          resolve('')
        }).finally(() => {
          this.templateBeingFetched = null
        })
      })
    },
    setPage() {
      // set standard and problem name
      this.selectedDomain = this.problemData.problem_set?.standard?.domain.id
      this.problem.standard_id = this.problemData.problem_set.standard_id
      this.problem.tags = this.problemData.tags
      this.problem.ttsSupport = !!this.problemData.tts_support
      this.$store.commit('problem/SET_TTS_SUPPORT', !!this.problem.ttsSupport)
      this.problem.name = this.problemData.name

      if (this.problemData.rules) {
        this.problem.hideStatement = this.JSON_PARSE(this.problemData.rules)?.hide_statement
        this.problem.hideFeedBack = this.JSON_PARSE(this.problemData.rules)?.hide_feedback
      }

      this.standards = this.problemData.problem_set.standard?.domain?.standards.map(s => ({ value: s.id, text: s.name }))
      this.problem.theme = this.problemData.theme
      this.videoTimeStamp = this.problemData.video_time_stamp
      this.hotspotWrapper = !!this.problemData.hotspot_point
      this.$store.commit('problem/SET_TTS_SUPPORT', !!this.problem.ttsSupport)
      this.problem.skill_type = this.problemData.skill_type
      // set statement and feedback
      this.problem.statement = [{}, {}, {}, {}]
      this.problemData.problem_statements.forEach(data => {
        this.problem.statement[data.lang_level - 1] = {
          statementTemplateId: data.st_template_id,
          feedbackTemplateId: data.fb_template_id,
          initialStatement: data.statement,
          initialFeedback: data.feedback,
          statement: data.statement,
          id: data.id,
          feedback: data.feedback,
          fb_type: data.fb_type,
          st_type: data.st_type,
        }
        this.problem.pageLink[data.lang_level - 1] = data.page_link
        if (data.standard) {
          this.problem.cefr.selectedSubjects[data.lang_level - 1] = data.standard.domain.subject_id
          this.fetchCefrDomain(data.lang_level - 1, false)
          this.problem.cefr.selectedDomains[data.lang_level - 1] = data.standard.domain.id
          this.fetchCefrStandard(data.lang_level - 1, false)
          this.problem.cefr.selectedStandards[data.lang_level - 1] = data.standard_id
        }

        this.problem.stType.push(data.st_type)
        this.problem.fbType.push(data.fb_type)
        if (data.st_type === 'image') this.problem.statementImage.url[data.lang_level - 1] = data.st_image
        if (data.st_type === 'audio') this.problem.statementAudio.url[data.lang_level - 1] = data.st_image
        if (data.fb_type === 'image') this.problem.feedbackImage.url[data.lang_level - 1] = data.fb_image
        if (data.fb_type === 'audio') this.problem.feedbackAudio.url[data.lang_level - 1] = data.fb_image
      })
      this.problem.description = this.problemData.description
      this.problem.questions = {
        text: [[], [], [], []],
        image: [[], [], [], []],
        imageUrl: [[], [], [], []],
        caption: [[], [], [], []],
        audio: [[], [], [], []],
        audioUrl: [[], [], [], []],
      }
      this.problem.answers = {
        text: [[], [], [], []],
        image: [[], [], [], []],
        imageUrl: [[], [], [], []],
        audio: [[], [], [], []],
        caption: [[], [], [], []],
        audioUrl: [[], [], [], []],
      }

      this.problem.levels = []

      // if linear problem type, set only one language level
      if (this.problemData?.questions?.find(q => q.lang_level !== '1') && !this.problemData?.isLinearLesson) {
        this.problem.levels = [...this.problem.allLevels]
      } else {
        const tabIndex = this.problemData.questions ? this.problemData.questions[0]?.lang_level - 1 : 0
        const newLevelArray = new Array(4).fill('')
        newLevelArray[tabIndex] = this.problem.allLevels[tabIndex]
        this.problem.levels = newLevelArray
        this.activeTabIndex = tabIndex
      }

      this.problemData.questions.forEach(data => {
        this.problem.questionType = data.data_type
        if (data.data_type === 'text') this.problem.questions[data.data_type][data.lang_level - 1].push({ id: data.id, text: data.text })
        else if (data.data_type === 'image') {
          this.problem.questions.imageUrl[data.lang_level - 1].push({ id: data.id, text: data.file })
          this.problem.questions.image[data.lang_level - 1].push({ id: data.id, text: data.file })
          this.problem.questions.caption[data.lang_level - 1].push({ text: this.JSON_PARSE(data.extra_file)?.caption })
        } else if (data.data_type === 'audio') {
          this.problem.questions.audioUrl[data.lang_level - 1].push({ id: data.id, text: data.file })
          this.problem.questions.audio[data.lang_level - 1].push({ id: data.id, text: data.file })
        }

        this.problem.answerType = data.answers[0].data_type
        if (data.answers[0].data_type === 'text') this.problem.answers[data.answers[0].data_type][data.lang_level - 1].push({ id: data.answers[0].id, text: data.answers[0].text })
        else if (data.answers[0].data_type === 'image') {
          this.problem.answers.imageUrl[data.lang_level - 1].push({ id: data.answers[0].id, text: data.answers[0].file })
          this.problem.answers.image[data.lang_level - 1].push({ id: data.answers[0].id, text: data.answers[0].file })
          this.problem.answers.caption[data.lang_level - 1].push({ text: this.JSON_PARSE(data.answers[0]?.extra_file)?.caption })
        } else if (data.answers[0].data_type === 'audio') {
          this.problem.answers.audioUrl[data.lang_level - 1].push({ id: data.answers[0].id, text: data.answers[0].file })
          this.problem.answers.audio[data.lang_level - 1].push({ id: data.answers[0].id, text: data.answers[0].file })
        }
      })

      if (this.problem.statement[this.activeTabIndex].statementTemplateId) this.fetchTemplate(this.problem.statement[this.activeTabIndex].statementTemplateId, false)
      if (this.problem.statement[this.activeTabIndex].feedbackTemplateId) this.fetchTemplate(this.problem.statement[this.activeTabIndex].feedbackTemplateId, false)

      this.$emit('ready')
    },
    updateTheme() {
      const config = {
        theme: `${this.problem.theme}`,
      }
      useJwt.updateTheme(this.$route.query.problem_id, config)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    updateProblem() {
      const config = {
        lesson_id: this.$route.query.lesson_id,
        name: this.problem.name,
        standard_id: this.problem.standard_id,
        tags: this.problem.tags,
        tts_support: this.problem.ttsSupport,
        skill_type: this.problem.skill_type,
        description: this.problem.description,
        rules: {
          hide_statement: this.problem.hideStatement,
          hide_feedback: this.problem.hideFeedBack,
        },
      }
      this.$store.commit('problem/SET_TTS_SUPPORT', !!this.problem.ttsSupport)
      useJwt.updateProblem(this.$route.query.problem_id, config)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    handleImage({ image, url, caption }, type, i, j) {
      this.problem[type].image[i][j].text = image
      const imgList = this.problem[type].imageUrl
      imgList[i][j].text = url
      this.problem[type].imageUrl = [...imgList]
      const imgCaption = this.problem[type].caption
      imgCaption[i][j] = caption
      this.problem[type].caption = [...imgCaption]
      if (type === 'questions') {
        this.updateQuestion(i, j)
      } else if (type === 'answers') this.updateAnswer(i, j)
    },
    handleStatementImage({ url, image }, type, index) {
      this.problem[type].url[index] = url
      if (type === 'statementImage') this.updateProblemStatementImage(image, index, 'st_image')
      else if (type === 'feedbackImage') this.updateProblemStatementImage(image, index, 'fb_image')
    },
    updateCefrStandard(index) {
      useJwt.updateCefrStandard(this.problem.statement[index].id, this.problem.cefr.selectedStandards[index])
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    updateProblemStatement(level) {
      const statement = this.problem.statement[level]
      if (statement.initialStatement !== statement.statement || statement.initialFeedback !== statement.feedback) {
        const config = {
          lang_level: level + 1,
          statement: statement.statement,
          feedback: statement.feedback,
          _method: 'put',
        }
        useJwt.updateProblemStatement(this.problem.statement[level].id, config)
          .then(response => {
            this.showSuccessMessage(response)
          })
          .catch(error => {
            this.showErrorMessage(error)
          })
          .finally(() => {
            this.$emit('fetch')
          })
      }
    },
    updateProblemStatementImage(file, level, type) {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      data.append('lang_level', level + 1)
      data.append('statement', this.problem.statement[level].statement)
      data.append('feedback', this.problem.statement[level].feedback)
      data.append([type], file)
      if (type === 'st_image') data.append('st_type', 'image')
      else if (type === 'fb_image') data.append('fb_type', 'image')
      data.append('_method', 'put')

      useJwt.updateProblemStatement(this.problem.statement[level].id, data, config)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    updateProblemStatementAudio(file, level, type) {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      data.append('lang_level', level + 1)
      data.append('statement', this.problem.statement[level].statement)
      data.append('feedback', this.problem.statement[level].feedback)
      data.append([type], file)
      if (type === 'st_image') data.append('st_type', 'audio')
      else if (type === 'fb_image') data.append('fb_type', 'audio')
      data.append('_method', 'put')

      useJwt.updateProblemStatement(this.problem.statement[level].id, data, config)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    updateQuestion(i, j) {
      const question = this.problem.questions[this.problem.questionType][i][j]
      const caption = this.problem.questions.caption?.[i][j]
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      data.append('lang_level', `${i + 1}`)
      data.append('problem_id', this.$route.query.problem_id)
      if (caption && !question.text && this.problem.questionType === 'image') {
        data.append('caption', caption)
        data.append('text', 'IMAGE_CAPTION')
      } else {
        data.append('text', question.text)
      }

      data.append('ques_type', this.problem.questionType)
      data.append('_method', 'put')

      useJwt.updateQuestion(question.id, data, config)
        .then(response => {
          this.showSuccessMessage(response)
          this.$emit('fetch')
        })
        .catch(error => {
          // this.showErrorMessage(error)
          this.showError(error.response.data.errors.text[0])
        })
    },
    updateAnswer(i, j) {
      const answer = this.problem.answers[this.problem.answerType][i][j]
      const caption = this.problem.answers.caption?.[i][j]
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      data.append('lang_level', `${i + 1}`)
      data.append('problem_id', this.$route.query.problem_id)
      data.append('is_correct', 1)
      if (caption && !answer.text && this.problem.answerType === 'image') {
        data.append('caption', caption)
        data.append('text', 'IMAGE_CAPTION')
      } else {
        data.append('text', answer.text)
      }

      data.append('ans_type', this.problem.answerType)
      data.append('_method', 'put')

      useJwt.updateAnswer(answer.id, data, config)
        .then(response => {
          this.showSuccessMessage(response)
          this.$emit('fetch')
        })
        .catch(error => {
          // this.showErrorMessage(error)
          this.showError(error.response.data.errors.text[0])
        })
    },
    setAudio(i, j, type, { audio, url }) {
      this.problem[type].audio[i][j].text = audio
      this.problem[type].audioUrl[i][j].text = url
      if (audio) {
        if (type === 'questions') this.updateQuestion(i, j)
        else if (type === 'answers') this.updateAnswer(i, j)
      }
    },
    setStatementAudio({ audio, url }, i, type) {
      this.problem[type].url[i] = url
      this.problem[type].file[i] = audio
      if (audio) {
        if (type === 'statementAudio') this.updateProblemStatementAudio(audio, i, 'st_image')
        else if (type === 'feedbackAudio') this.updateProblemStatementAudio(audio, i, 'fb_image')
      }
    },
    lengthChange(length) {
      this.problem.questionLength = length
    },
    addNewField() {
      this.problem.questions[this.problem.questionType].forEach(q => {
        q.push({ id: null, text: '' })
      })

      if (this.problem.answerType === 'text') {
        this.problem.answers.text.forEach(a => {
          a.push({ id: null, text: '' })
        })
      } else if (this.problem.answerType === 'image') {
        this.problem.answers.image.forEach(a => {
          a.push({ id: null, text: '' })
        })
        this.problem.answers.imageUrl.forEach(a => {
          a.push({ id: null, text: '' })
        })
      } else if (this.problem.answerType === 'audio') {
        this.problem.answers.audio.forEach(a => {
          a.push({ id: null, text: '' })
        })
        this.problem.answers.audioUrl.forEach(a => {
          a.push({ id: null, text: '' })
        })
      }
    },
    toggleDeleteModal(index) {
      this.$refs['modal-delete'].toggle()
      this.deleteIndex = index
    },
    toggleAddModal() {
      this.$refs['modal-add'].toggle()
    },
    deleteQuestion() {
      const requests = []
      this.problem.answers[this.problem.answerType].forEach(a => {
        if (a[this.deleteIndex]?.id) requests.push(useJwt.deleteAnswer(a[this.deleteIndex].id))
      })
      this.problem.questions[this.problem.questionType].forEach(q => {
        if (q[this.deleteIndex]?.id) requests.push(useJwt.deleteQuestion(q[this.deleteIndex].id))
      })
      Promise.all(requests).then(response => {
        this.showSuccessMessage(response[requests.length - 1])
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.problem.questions[this.problem.questionType].forEach(q => {
          q.splice(this.deleteIndex, 1)
        })
        this.problem.answers[this.problem.answerType].forEach(a => {
          a.splice(this.deleteIndex, 1)
        })
        this.$emit('fetch')
      })
    },
    fetchStandard() {
      if (this.selectedDomain) {
        useJwt.getStandardsByDomain(this.selectedDomain).then(response => {
          this.standards = [{ value: null, text: 'Please select a standard' }, ...response.data.data.map(s => ({ value: s.id, text: s.name }))]
        })
      }
      this.problem.standard_id = null
    },
    fetchCefrDomain(index, setNull) {
      useJwt.getCefrDomain(this.problem.cefr.selectedSubjects[index]).then(response => {
        this.cefrDomainList[index] = [{ value: null, text: 'Please select a domain' }, ...response.data.data.map(s => ({ value: s.id, text: s.name }))]
        if (setNull) this.problem.cefr.selectedDomains[index] = null
        if (setNull) this.problem.cefr.selectedStandards[index] = null
        this.cefrDomainList.push('')
        this.cefrDomainList.pop()
      })
    },
    fetchCefrStandard(index, setNull) {
      useJwt.getCefrStandard(this.problem.cefr.selectedDomains[index]).then(response => {
        this.cefrStandardList[index] = [{ value: null, text: 'Please select a standard' }, ...response.data.data.map(s => ({ value: s.id, text: s.name }))]
        if (setNull) this.problem.cefr.selectedStandards[index] = null
        this.cefrStandardList.push('')
        this.cefrStandardList.pop()
      })
    },
    getDataForQuestionPreview() {
      const statementAndFeedback = this.problem.statement[this.activeTabIndex]
      const index = this.activeTabIndex
      return {
        theme: this.problem.theme,
        problemType: 'matching',
        hasAnimatedLayout: this.problemData.animated_layout,
        animatedTemplateId: this.problemData.animated_template_id,
        hideStatement: !!this.problem.hideStatement,
        hideFeedBack: !!this.problem.hideFeedBack,
        feedback: {
          type: statementAndFeedback.fb_type,
          text: statementAndFeedback.feedback,
          image: statementAndFeedback.fb_type === 'image' ? this.problem.feedbackImage?.url[index] : null,
          audio: statementAndFeedback.fb_type === 'audio' ? this.problem.feedbackAudio?.url[index] : null,
          template: this.problemTemplates[statementAndFeedback.feedbackTemplateId || (`${this.activeTabIndex}feedback`)],
        },
        statement: {
          type: statementAndFeedback.st_type,
          text: statementAndFeedback.statement,
          image: statementAndFeedback.st_type === 'image' ? this.problem.statementImage?.url[index] : null,
          audio: statementAndFeedback.st_type === 'audio' ? this.problem.statementAudio?.url[index] : null,
          template: this.problemTemplates[statementAndFeedback.statementTemplateId || (`${this.activeTabIndex}statement`)],
          video: this.videoTimeStamp,
        },
        // eslint-disable-next-line eqeqeq
        questions: this.problemData.questions.filter(e => e.lang_level == (this.activeTabIndex + 1).toString()),
        level: this.activeTabIndex + 1,
      }
    },
    setError(vid, msg) {
      this.$refs.editProblemForm.setErrors({ [vid]: [msg] })
    },
    copyLesson(lessonId) {
      this.$emit('copyProblem', lessonId)
    },
    updatePageLink(i) {
      const updatePrams = {
        lang_level: i + 1,
        problem_id: this.$route.query.problem_id,
        page_link: this.problem.pageLink[i],
      }
      useJwt.updatePageLink(updatePrams).then(response => {
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.$emit('fetch')
      })
      // useJwt.updatePageLink()
    },
  },
}
</script>

<style>
.lesson-list ul{
  max-height: 500px;
  overflow: scroll;
}
</style>
