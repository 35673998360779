import useJwt from '@/auth/jwt/useJwt'

export default function nativeAppFcmLogin() {
  if (!window.ReactNativeWebView) {
    return null
  }
  window.ReactNativeWebView.postMessage(JSON.stringify({
    action: 'request_fcm_token',
  }))
  // You can use the userId to perform any necessary actions in your app,
  // such as saving the token to the database or setting up push notifications.
  window.saveNativeMobileAppToken = token => {
    localStorage.setItem('mobileFcmToken', token)
    useJwt.saveFcmToken({
      token,
    })
  }
  return null
}
