<template>
  <div class="circular-progress"
       :style="{ width: size + 'px', height: size + 'px' }"
  >
    <div class="progress-circle"
         :style="{ width: size + 'px', height: size + 'px' }"
    >
      <span class="progress-value"
            :style="{ fontSize: size / 5 + 'px' }"
      >{{ percentage }}%</span>
      <svg class="progress-ring"
           :width="size"
           :height="size"
           viewBox="0 0 100 100"
      >
        <!-- Background Circle -->
        <circle
          class="progress-ring__background"
          stroke="rgba(0, 0, 0, 0.1)"
          :stroke-width="borderSize"
          fill="transparent"
          :r="radius"
          cx="50"
          cy="50"
        />
        <!-- Progress Circle -->
        <circle
          class="progress-ring__circle"
          :stroke="borderColor"
          :stroke-width="borderSize"
          fill="transparent"
          :r="radius"
          cx="50"
          cy="50"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      required: true,
      default: 0, // Default value for percentage
    },
    size: {
      type: Number,
      required: false,
      default: 100, // Default size of the circle (100px by default)
    },
  },
  computed: {
    borderSize() {
      // Calculate border size as a percentage of the overall size
      return Math.max(5, this.size / 20) // Minimum border size is 5px
    },
    radius() {
      // Calculate the radius of the circle
      return (this.size / 2 - this.borderSize / 2)
    },
    borderColor() {
      // Calculate color based on percentage (from red to green)
      const red = Math.max(255 - Math.round((this.percentage / 100) * 255), 0)
      const green = Math.round((this.percentage / 100) * 255)
      return `rgb(${red}, ${green}, 0)`
    },
  },
  watch: {
    percentage() {
      this.updateProgress()
    },
  },
  mounted() {
    this.updateProgress()
  },
  methods: {
    updateProgress() {
      const circumference = 2 * Math.PI * this.radius
      const offset = circumference - (this.percentage / 100) * circumference

      const circle = this.$el.querySelector('.progress-ring__circle')
      circle.style.strokeDasharray = `${circumference} ${circumference}`
      circle.style.strokeDashoffset = offset
    },
  },
}
</script>

<style scoped>
.circular-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.progress-circle {
  position: relative;
}

.progress-value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.progress-ring {
  transform: rotate(-90deg);
}

.progress-ring__circle,
.progress-ring__background {
  transition: stroke-dashoffset 0.35s, stroke 0.35s; /* Transition for stroke color */
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
</style>
