<template>
  <div>
    <b-modal
      id="translation-modal"
      ref="translationModal"
      :title="'Word: ' + activeWord"
      ok-only
    >
      <div class="row">
        <div class="col-md-6">
          <button class="btn btn-secondary w-100">
            {{ $t("translation-wordnet-module.english") }}
          </button>
          <div class="pt-2">
            <h5>{{ activeWord }}</h5>
            <hr>
            <div class="similar-words">
              <h5>Similar Words</h5>
              <ul
                v-if="activeWordNets.wordnet"
                class="list-group pl-1"
              >
                <li
                  v-for="(synonym, i) in activeWordNets.wordnet[0].synonyms"
                  :key="i"
                >
                  {{ synonym }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <select
            ref="selectedValue"
            class="form-control"
            @change="setActiveLanguage"
          >
            <option
              value="Korean"
              :selected="activeLanguage === 'Korean'"
            >
              {{ $t("translation-wordnet-module.korean") }}
            </option>
            <option
              value="Japanese"
              :selected="activeLanguage === 'Japanese'"
            >
              {{ $t("translation-wordnet-module.japanese") }}
            </option>
          </select>
          <div class="p-2">
            <h5>
              {{
                activeTranslation.words
                  ? activeTranslation.words[0] ||
                    $t("translation-wordnet-module.not-available")
                  : $t("translation-wordnet-module.not-available")
              }}
            </h5>
            <hr>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="wordnet pl-1">
          <h5>{{ $t("lesson-pages-module.wordnet") }}</h5>
          <div
            v-for="(filteredActiveWordNet, wordIndex) in activeWordNets.wordnet"
            :key="filteredActiveWordNet.offset"
          >
            <ul class="list-group pl-1">
              <li>
                <b>{{ $t("translation-wordnet-module.name") }} </b>
                {{ filteredActiveWordNet.name }}
                <a
                  style="color: #007bff"
                  class="pl-1"
                  @click="fetchUMN(wordIndex)"
                >
                  {{ $t("translation-wordnet-module.fetch-uwn") }}</a>
              </li>
              <li class="mt-1">
                <b>{{ $t("translation-wordnet-module.definition") }}: </b>
                {{ filteredActiveWordNet.definition }}
              </li>
              <li class="mt-1">
                <b>{{ $t("translation-wordnet-module.examples") }}: </b>
                <span
                  v-for="(example, i) in filteredActiveWordNet.examples"
                  :key="i"
                >{{ example }},
                </span>
              </li>
              <li class="mt-1">
                <b>{{ $t("translation-wordnet-module.synonyms") }}: </b>
                <span
                  v-for="(synonym, i) in filteredActiveWordNet.synonyms"
                  :key="i"
                >{{ synonym }},
                </span>
              </li>
            </ul>
            <hr>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { BModal } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BModal,
  },
  props: {
    activeTranslation: {
      type: [Object, Array],
      required: false,
      default: () => null,
    },
    activeWordNets: {
      type: [Object, Array, String],
      required: false,
      default: () => null,
    },
    activeWord: {
      type: [Object, String, Array],
      required: false,
      default: () => null,
    },
    defaultLanguage: {
      type: [Object, String],
      required: false,
      default: 'Korean',
    },
  },
  data() {
    return {
      activeLanguage: this.defaultLanguage,
    }
  },
  computed: {
    activeLanguageShort() {
      if (this.activeLanguage === 'Korean') {
        return 'kor'
      }

      return 'jpn'
    },
  },
  methods: {
    setActiveLanguage() {
      this.activeLanguage = this.$refs.selectedValue.value
      this.$emit('set-active-language', this.$refs.selectedValue.value)
    },
    showModal() {
      setTimeout(this.$refs.translationModal.show(), 1000)
    },
    fetchUMN(wordIndex) {
      useJwt
        .getTranslatedWords({
          word_offset: this.activeWordNets.wordnet[wordIndex].offset,
          language: this.activeLanguage,
        })
        .then(res => {
          if (res.status === 200) {
            this.activeTranslation.words = [JSON.parse(res.data.data).words[0]]
            this.$forceUpdate()
          }
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
    },
  },
}
</script>
