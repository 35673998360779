<template>
  <!-- create/edit class model -->
  <b-modal
    id="modal-create-edit-class-link"
    ref="modal-create-edit-class-link"
    :visible="show"
    ok-only
    size="md"
    ok-variant="primary"
    :ok-title="$t('confirm')"
    modal-class="modal-primary"
    centered
    :title="$t('class-module.update-class-link')"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-col md="12">
      <!-- form -->
      <b-form @submit.prevent="updateLink()">
        <button
          type="submit"
          class="d-none"
        />
        <b-row>
          <!-- class-name -->
          <b-col md="12">
            <b-form-group
              :label="$t('class-module.class-link')"
              label-for="name"
            >
              <b-form-input
                id="class-name"
                v-model="localLink"
                name="class-name"
                :placeholder="$t('class-module.class-link')"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
    <template #modal-footer="{}">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="isProcessing"
            @click="updateLink()"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{
              $t('class-module.update-class-link')
            }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
  <!-- create/edit class model -->
</template>

<script>
import {
  BModal, BRow, BCol, BButton, BSpinner, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'

export default ({
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BForm,
  },
  directives: {
    Ripple,
  },
  props: {
    classRoom: {
      type: Object,
      default: null,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      isProcessing: false,
      self: getUserData(),
      localLink: '',
    }
  },
  watch: {
    classRoom() {
      this.localLink = this.classRoom.link
    },
  },
  methods: {
    updateLink() {
      this.isProcessing = true
      this.classRoom.link = this.localLink
      const data = {
        name: this.classRoom.name,
        course_id: this.classRoom.course_id,
        id: this.classRoom.id,
        link: this.localLink,
      }
      useJwt.updateClass(
        this.classRoom.id,
        data,
      ).then(response => {
        this.showSuccessMessage(response)
        this.$refs['modal-create-edit-class-link'].hide()
        this.$emit('onClassUpdated')
      })
        .catch(error => {
          if (error.response?.data?.errors) {
            this.$refs.updateClassLinkForm.setErrors(error.response.data.errors)
          } else this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
    },
  },

})
</script>
