<template>
  <div>
    <vue-apex-charts
      :key="chart.series.length"
      type="pie"
      height="350"
      :options="chart.chartOptions"
      :series="chart.series"
    />
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import { getRandomColorArray } from '@/utils/colors'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    gameReport: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    chart() {
      const categories = [...new Set(this.gameReport.map(report => report.game_code))]
      const series = []
      categories.forEach(category => {
        const total = this.gameReport.filter(game => game.game_code === category).reduce((p, c) => p + c.game_time, 0)
        series.push(total)
      })
      const self = this
      return {
        series,
        chartOptions: {
          colors: getRandomColorArray(categories.length),
          chart: {
            width: 380,
            type: 'pie',
          },
          labels: categories,
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          }],
          tooltip: {
            custom({
              // eslint-disable-next-line no-shadow
              series, seriesIndex,
            }) {
              const time = self.CONVERT_HM(series[seriesIndex])
              return `<div class="p-2">${categories[seriesIndex]} <br/>${time}</div>`
            },
          },
        },
      }
    },
  },
}
</script>
