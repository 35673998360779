<template>
  <div>
    <b-modal
      id="show-wordnet"
      ref="modalSection"
      ok-title="Update"
      @ok="setWordnet"
    >
      <div class="card">
        <div class="card-header" />
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <h5>  {{ $t('translation-wordnet-module.dep') }} = {{ activeWord.dep }}</h5>
              <h5>  {{ $t('translation-wordnet-module.pos') }} = {{ activeWord.pos }}</h5>
            </div>
            <div class="col-md-6">
              <select
                ref="selectedValue"
                class="form-control"
                @change="setActiveLanguage"
              >
                <option
                  value="kor"
                  :selected="activeLanguage==='kor'"
                >
                  {{ $t('translation-wordnet-module.korean') }}
                </option>
                <option
                  value="jpn"
                  :selected="activeLanguage==='jpn'"
                >
                  {{ $t('translation-wordnet-module.japanese') }}
                </option>
              </select>
            </div>
          </div>
          <hr>
          <div class="wordnet">
            <h4>  {{ $t('lesson-pages-module.wordnets') }}</h4>
            <div
              v-for="(wordnet,index) in activeWord.wordnet"
              :key="index"
              class="mt-2"
            >
              <div class="row">
                <div class="col-md-5">
                  <input
                    type="checkbox"
                    name=""
                    :checked="wordnet.selected===1"
                    @click="setOffset(wordnet.offset,index)"
                  >
                </div>
                <div class="col-md-7">
                  <input
                    v-model="wordnet.translated_word"
                    class="form-control"
                    placeholder="Search here.."
                    @focus="getAjaxAutoComplete(index)"
                    @input="getAjaxAutoComplete(index)"
                  >

                  <ul
                    v-if="ajaxAutoComplete[index]"
                    class="list-group"
                    style=" overflow: auto;
                            height:180px;
                            position:absolute;"
                  >
                    <li
                      v-for="autoWord in ajaxAutoComplete[index]"
                      :key="'autoword'+autoWord.id"
                      class="list-group-item"
                      style="cursor:pointer;"
                      @click="setAutoCompleteWord(index,autoWord.value)"
                    >
                      {{ autoWord.value }}
                    </li>
                  </ul>
                </div>
              </div>
              <h6><b>{{ $t('translation-wordnet-module.name') }}:</b> {{ wordnet.name }}</h6>
              <h6><b>{{ $t('translation-wordnet-module.definition') }}: </b> {{ wordnet.definition }}</h6>
              <h6><b>{{ $t('translation-wordnet-module.examples') }}:</b>
                <div
                  v-for="(example,i) in wordnet.examples"
                  :key="i"
                >
                  <span>{{ i+1 }} {{ example }}</span>
                </div>
              </h6>
              <h6><b>{{ $t('translation-wordnet-module.synonyms') }}: </b><span
                v-for="(synonym,j) in wordnet.synonyms"
                :key="j"
              >{{ synonym }},</span> </h6>
              <h6><b>{{ $t('translation-wordnet-module.translated-word') }}: </b></h6>
              <input
                v-model="wordnet[activeLanguage]"
                class="form-control"
                placeholder="Translated Word"
              >
              <hr>
            </div>
          </div>

        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-center"
      ref="centeredModal"
      centered
      ok-only
    >
      <h4>{{ message }}</h4>

    </b-modal>
  </div>
</template>
<script>
import { BModal } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BModal,
  },
  props: {
    activeWord: {
      type: [Object, Array],
      required: false,

    },
    selectedWordnets: {
      type: [Object, Array],
      required: false,
    },
  },
  data() {
    return {
      message: '',
      activeLanguage: 'kor',
      activeTranslation: {},
      ajaxAutoComplete: {},
      autoCompleteWord: {},
      activeOffset: [],
      activeIndex: null,
      availableLanguage: ['jpn', 'kor'],

    }
  },
  methods: {
    setWordnet() {
      this.$emit('setWordnet', {
        offset: this.activeOffset, wordNet: this.activeWord.wordnet, activeWordnet: this.activeWord.wordnet[this.activeIndex], availableLanguage: this.availableLanguage,
      })
    },
    setOffset(offset) {
      if (this.activeOffset.includes(offset)) {
        this.activeOffset.splice(this.activeOffset.indexOf(offset), 1)
        this.$forceUpdate()
        return
      }
      this.activeOffset.push(offset)
      this.$forceUpdate()
    },
    openCenteredModal(message) {
      this.$refs.centeredModal.show()
      this.message = message
    },
    setActiveLanguage() {
      if (this.activeWord.wordnet) {
        this.activeWord.wordnet.forEach((word, index) => {
          this.activeWord.wordnet[index].translated_word = ''
        })
      }
      this.activeLanguage = this.$refs.selectedValue.value
    },

    getAjaxAutoComplete(index) {
      const formData = new FormData()
      formData.append('query', this.activeWord.wordnet[index].translated_word)
      formData.append('lang', this.activeLanguage)
      useJwt.getAjaxAutoComplete(formData).then(res => {
        this.ajaxAutoComplete[index] = JSON.parse(JSON.stringify(res.data.suggestions))
        this.$forceUpdate()
      })
    },
    setAutoCompleteWord(index, value) {
      (this.activeWord.wordnet[index])[this.activeLanguage] = value
      this.activeWord.wordnet[index].translated_word = value
      this.ajaxAutoComplete[index] = []
      this.$forceUpdate()
    },
    openModal() {
      setTimeout(this.$refs.modalSection.show(), 1000)
      this.activeOffset = []
      this.selectedWordnets.forEach(word => {
        this.activeOffset.push(word.offset)
      })
    },
  },
}
</script>
