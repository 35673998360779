<template>
  <div>
    <div class="d-flex justify-content-between mb-2">
      <div>
        <slot name="classroom-filter" />
      </div>
      <div>
        <b-button
          variant="primary"
          class="mr-2"
          @click="exportPdf('overall-summary-table')"
        >
          <feather-icon icon="FileTextIcon" /> Download PDF
        </b-button>
        <b-button
          variant="success"
          @click="downloadTableAsCsv('overall-summary-table')"
        >
          <feather-icon icon="BarChart2Icon" />  Download Excel
        </b-button>
      </div>
    </div>
    <b-card body-class="p-0">
      <b-table-simple
        id="overall-summary-table"
        bordered
        striped
      >
        <b-thead>
          <b-tr>
            <b-th
              style="vertical-align: middle; background: #FFE7C7"
            >
              Students
            </b-th>
            <b-th
              colspan="2"
              class="text-center academic"
            >
              Academic
            </b-th>
            <b-th
              class="text-center"
              style="background: #E1F8DC"
            >
              Language Skills
            </b-th>
            <b-th
              colspan="1"
              class="text-center study-skill"
            >
              Study Skill
            </b-th>
            <b-th
              colspan="1"
              class="text-center"
              style="background: #F7D8BA"
            >
              Social Skill
            </b-th>
          </b-tr>
          <b-tr>
            <b-th style="vertical-align: middle; background: #FFE7C7">
                &nbsp;
            </b-th>
            <b-th class="academic">
              Lesson Competition
            </b-th>
            <b-th class="academic">
              Problem Solving
            </b-th>
            <b-th style="background: #E1F8DC">
              Average Language Skill
            </b-th>
            <!-- <b-th
          v-for="skill of skills"
          :key="skill"
        >
          {{ skill }}
        </b-th> -->
            <b-th class="study-skill">
              Attendance Score
            </b-th>
            <b-th>
              Note Score
            </b-th>
          </b-tr>
        </b-thead>
        <slot />
      </b-table-simple>
    </b-card>
  </div>
</template>
<script>
import {
  BTableSimple, BThead, BTr, BTh, BCard, BButton,
} from 'bootstrap-vue'
import html2pdf from 'html2pdf.js'

export default {
  components: {
    BTableSimple, BThead, BTr, BTh, BCard, BButton,
  },
  props: {
    skills: {
      type: Array,
      required: true,
    },
    fileName: {
      type: String,
      default: 'overall_report',
    },
  },
  methods: {
    exportPdf() {
      const opt = {
        margin: 2,
        filename: `${this.fileName}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        jsPDF: { orientation: 'landscape', format: 'a4' },
      }

      html2pdf().set(opt).from(document.getElementById('overall-summary-table')).save()
    },
    downloadTableAsCsv(tableId, separator = ',') {
      const rows = document.querySelectorAll(`table#${tableId} tr`)
      // Construct csv
      const csv = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < rows.length; i++) {
        const row = []; const
          cols = rows[i].querySelectorAll('td, th')
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < cols.length; j++) {
          // Clean inner text to remove multiple spaces and jumpline (break csv)
          let data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
          data = data.replace(/"/g, '""')
          // Push escaped string
          row.push(`"${data}"`)
        }
        csv.push(row.join(separator))
      }
      const csvString = csv.join('\n')
      // Download it
      const filename = `${this.fileName}_${new Date().toLocaleDateString()}.csv`
      const link = document.createElement('a')
      link.style.display = 'none'
      link.setAttribute('target', '_blank')
      link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(csvString)}`)
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
}
</script>
