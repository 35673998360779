var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-editor",style:([
    _vm.setting.bgStyleType == 'color' ? {
      backgroundColor: _vm.setting.backgroundColor
    }: {
      backgroundImage: `url(${_vm.setting.imageUrl})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '0% 0%',
    }, _vm.getComputedStyles(_vm.setting)]),attrs:{"id":_vm.componentId}},[(_vm.setting.audioUrl)?_c('div',{staticClass:"float-right"},[_c('audio-player',{key:_vm.setting.audioUrl,ref:"backgroundMusic",attrs:{"src":_vm.setting.audioUrl}})],1):_vm._e(),_c(_vm.layoutComponent,{tag:"component",staticClass:"wrapper",attrs:{"layout-setting":_vm.layoutSetting,"is-edit-mode":_vm.isEditMode,"get-computed-styles":_vm.getComputedStyles},on:{"updateLayout":_vm.handleLayoutCover},scopedSlots:_vm._u([_vm._l((_vm.positions),function(slot){return {key:slot.name,fn:function(){return [_vm._l((slot.items),function(division){return [_c('div',{key:division.value,staticClass:"editor-height",class:{
            'dotted-border': _vm.isEditMode
          },style:([_vm.getComputedStyles(_vm.layoutPosition.find(item => item.position === division.value) ?_vm.layoutPosition.find(item => item.position === division.value).settings : {} )]),attrs:{"draggable":_vm.isEditMode,"set":_vm.item = _vm.layoutPosition.find(item => item.position === division.value),"data-aos":division.animation},on:{"dragstart":function($event){return _vm.startDrag($event, division.value)},"drop":function($event){return _vm.onDrop($event, division.value)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[(_vm.item.isVisible)?_c('Resizable',{key:_vm.item.position,class:[_vm.item.component === 'ProblemBody' && 'animated-problem-body',_vm.item.component === 'ProblemBody' && _vm.setting.bodyTheme === 'transparent' && 'theme-transparent' ],style:({
              marginTop: _vm.item.component === 'ProblemBody' && '-13px'
            }),attrs:{"default-height":_vm.item.height,"is-edit-mode":_vm.isEditMode,"settings":_vm.item.settings,"adjustable":_vm.item.component !== 'ProblemBody'},on:{"updateHeight":height => _vm.handleHeightUpdate(_vm.layoutPosition.find(item => item.position === division.value), height),"updateLayout":setting => _vm.handleUpdateLayout(_vm.layoutPosition.find(item => item.position === division.value), setting),"handleRemove":function($event){_vm.handleRemove(_vm.layoutPosition.find(item => item.position === division.value))}}},[(_vm.item.component === 'ProblemBody')?_vm._t("problem-body"):_c(_vm.item.component,{key:JSON.stringify(_vm.item.elementData),ref:"layouts",refInFor:true,tag:"component",attrs:{"can-start-animation":_vm.canStartAnimation,"element-data":_vm.item.elementData,"is-edit-mode":_vm.isEditMode},on:{"handleCreate":(allData) => _vm.handleCreate(allData),"handleEdit":(allData, index) => _vm.handleEdit(allData, index),"showImagePreview":_vm.handleShowImagePreview}})],2):_vm._e()],1)]})]},proxy:true}})],null,true)}),_c('element-control',{attrs:{"show":_vm.showEditOption,"editor-data":_vm.editorDataToEdit,"index-to-edit":_vm.indexToEdit},on:{"close":_vm.handleClose,"destroyData":_vm.handleDestroyData}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.getStyleString)}}),_c('FsLightbox',{attrs:{"toggler":_vm.imagePreviewToggler,"slide":_vm.slide,"sources":_vm.previewImages.map(img => img),"types":[...new Array(_vm.previewImages.length).fill('image')]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }