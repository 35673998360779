<template>
  <span class="w-100">
    <!-- Contenteditable div to capture and display the pasted image -->
    <!-- Textarea to show the copied image URL -->
    <textarea
      ref="textarea"
      v-model="copiedImage"
      class="form-control"
      :rows="1"
      max-rows="8"
      tag="pre"
      style="resize:none;"
      :placeholder="$t('chat-page.enter-your-message')"
      @paste="handlePaste"
      @keypress.enter="$emit('sendMessage')"
      @keydown.stop="$emit('onTyping')"
    />
  </span>
</template>

<script>
export default {
  props: {
    value: String,
  },
  computed: {
    copiedImage: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    handlePaste(event) {
      // Prevent default paste behavior
      // Get clipboard data
      const clipboardData = event.clipboardData || window.clipboardData
      // Check if there's an image in the clipboard
      if (clipboardData && clipboardData.items) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of clipboardData.items) {
          if (item.type.indexOf('image') !== -1) {
            const blob = item.getAsFile()

            // Convert the blob to a data URL
            const reader = new FileReader()
            reader.onload = () => {
              // Set the data URL as the value of the contenteditable div
              this.$emit('updateSnap', reader.result)
              // Set the copied image URL in the textarea
            //   this.copiedImage = reader.result
            }
            reader.readAsDataURL(blob)
          }
        }
      }
    },
  },
}
</script>

  <style scoped>
  /* .content-editable {
    border: 1px solid #ccc;
    padding: 8px;
    min-height: 200px;
    overflow: auto;
    white-space: pre-wrap;
  } */

  </style>
