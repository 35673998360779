<template>
  <div class="call-modal-wrap">
    <div class="call-modal">
      <select
        style="width: 250px"

        class="mt-2 px-1 py-sm-1"
        @change="onMicChange($event)"
      >
        <option
          v-for="audioInDevice in audioInDevices"
          :key="audioInDevice.id"
          :value="audioInDevice.id"
          :selected="audioInDevice.id === selectedAudio"
        >{{ audioInDevice.label }}<option>

          <!-- <option class="select-item">
          mic1
        </option>
        <option>mic 2</option> -->
        </option></option></select>
      <select
        v-if="!onlyForAudio"
        style="width: 250px"
        class="mt-2 px-1 py-sm-1"
        @change="onCameraChange($event)"
      >
        <option
          v-for="cameraInDevice in cameraInDevices"
          :key="cameraInDevice.id"
          :value="cameraInDevice.id"
          :selected="cameraInDevice.id === selectedCamera"
        >{{ cameraInDevice.label }}<option /></option></select>
      <div
        class="close"
        @click="closeWindow"
      >
        <feather-icon icon="XIcon" />
      </div>
    </div>
  </div>
</template>

<script>
import { GetDevicesType } from 'vani-meeting-client/lib/user-media-handler/UserMediaHandler'
import ChatHandler from '../ChatHandler'
import VideoHandler from '../VideoHandler'

export default {
  name: 'DeviceSeletion',
  props: {
    onlyForAudio: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      audioInDevices: [],
      cameraInDevices: [],
      selectedCamera: undefined,
      selectedAudio: undefined,
    }
  },
  mounted() {
    this.selectedCamera = VideoHandler.getInstance().getMeetingRequest().cameraDevice
    this.selectedAudio = VideoHandler.getInstance().getMeetingRequest().audioInDevice
    this.getDevices()
  },
  methods: {
    async getDevices() {
      this.audioInDevices = await VideoHandler.getInstance().getMeetingHandler().getDevices(GetDevicesType.AudioIn)
      this.cameraInDevices = await VideoHandler.getInstance().getMeetingHandler().getDevices(GetDevicesType.VideoIn)
    },
    closeWindow() {
      ChatHandler.getInstance().eventEmitter.emit('HideAudioVideoSelector', {})
    },
    onCameraChange(event) {
      VideoHandler.getInstance().getMeetingRequest().cameraDevice = event.target.value
      VideoHandler.getInstance()
        .getMeetingHandler()
        .startLocalStream(true, false)
      this.selectedCamera = VideoHandler.getInstance().getMeetingRequest().cameraDevice
      this.selectedAudio = VideoHandler.getInstance().getMeetingRequest().audioInDevice
    },
    onMicChange(event) {
      VideoHandler.getInstance().getMeetingRequest().audioInDevice = event.target.value
      VideoHandler.getInstance()
        .getMeetingHandler()
        .startLocalStream(false, true)
      this.selectedCamera = VideoHandler.getInstance().getMeetingRequest().cameraDevice
      this.selectedAudio = VideoHandler.getInstance().getMeetingRequest().audioInDevice
    },
  },
}

</script>

<style scoped>
.call-modal-wrap {
  z-index: 1034;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div2 {
  width: 300px;
  height: 100px;
  padding: 50px;
  border: 1px red;
  box-sizing: border-box;
}
.maxDrop {
  min-width: 0 !important;
}
#center {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
}
.call-modal {
  position: relative;
  width: 320px;
  height: 200px;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
}

.call-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.call-info img {
  width: 110px;
  height: 110px;
  border: 2px solid lightgrey;
  border-radius: 50%;
}

.call-info p {
  font-size: 25px;
  font-weight: 600;
  margin-top: 10px;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 101;
  cursor: pointer;
}
select {
  padding: 10px !important;
  font-family: inherit !important;
  font-weight: 600;
}
</style>
