<template>
  <div>
    <span>{{ $t('send-notification-module.user-type') }}</span>
    <b-form-select
      v-model="selected"
      style="margin-top:6px;"
      :options="options"
      @input="$emit('selected',selected)"
    />
  </div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue'

export default {
  components: { BFormSelect },
  data() {
    return {
      selected: 'student-parent',
      options: [
        {
          value: 'student-parent', text: this.$i18n.tc('student-parent'),
        },
        {
          value: 'student', text: this.$i18n.tc('Student'),
        },
        {
          value: 'parent', text: this.$i18n.tc('Parent'),
        },
      ],
    }
  },
}
</script>

<style>

</style>
