/* eslint-disable import/no-cycle */
import useJwt from '@/auth/jwt/useJwt'
import { MessagePayload, ChatMessageType } from 'vani-meeting-client'
// import router from '@/router'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import GEC_EVENT_BUS from '@/utils/eventBus'
import ChatHandler from './ChatHandler'
// ------------------------------------------------
// GET: Return Chats Contacts and Contacts
// ------------------------------------------------
function getStudents(payload) {
  let students = []
  if (payload.teacher) {
    students = [...payload.students, payload.teacher]
  } else if (payload.students) {
    students = [...payload.students]
  }
  return students
}

export function getChatsAndContacts() {
  let chatsContacts = []
  let profileUserData = {}
  let responseData = []
  let contacts = []
  let classes = []
  let teacherChats = []
  const self = getUserData()
  return new Promise(resolve => {
    useJwt.getChatRoom().then(res => {
      responseData = res.data.data
      chatsContacts = Object.values(responseData.chats).map(chat => {
        let contact
        if (!chat.group_id) {
          const receiver = chat.receiver.id === self.id ? chat.sender : chat.receiver
          contact = {
            id: chat.id,
            created_at: chat.created_at,
            userId: responseData.profileUser.id !== chat.receive_by ? chat.receive_by : chat.send_by,
            fullName: `${receiver?.firstname} ${receiver?.lastname} ${getUserData()?.id === receiver.id ? '(You)' : ''}`,
            unseenMsgs: chat.total_unseen,
            avatar: receiver.avatar,
            user_type_id: receiver?.user_type_id,
            status: receiver?.last_seen != null ? 'online' : 'offline',
            about: receiver?.about ? chat.receiver.about : 'this is about',
            lastMessage: {
              message: chat.message,
              time: chat.created_at,
              type: chat.type,
            },
            chat: [
            ],
            type: 'single',
            group: chat.group_name,
          }
        } else {
          contact = {
            id: chat.id,
            userId: chat.group.id,
            fullName: chat.group.name,
            unseenMsgs: null,
            avatar: chat.logo,
            status: !!chat.status,
            about: 'this is about',
            created_at: chat.created_at,
            lastMessage: {
              message: chat.message,
              time: chat.created_at,
              type: chat.type,
            },
            chat: [
            ],
            type: 'group',
            group: chat.group_name,
          }
        }
        return contact
      })
      const userIds = chatsContacts.map(item => item.userId)

      teacherChats = responseData.teachers.filter(item => !userIds.includes(item.id)).map(item => ({
        id: `${item.id}_`,
        userId: item.id,
        fullName: `${item.firstname} ${item.lastname}`,
        lastMessage: {
          time: new Date(),
        },
        type: 'single',
        group: 'single',
      }))

      chatsContacts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

      profileUserData = {
        id: responseData.profileUser.id,
        avatar: responseData.profileUser.avatar,
        fullName: responseData.profileUser.fullname,
        status: responseData.profileUser.status || 'offline',
      }
      contacts = responseData.network.map(c => ({
        id: c.id,
        fullName: c.name,
        avatar: c.logo,
        students: c.contact,
      }))
      classes = responseData.class.map(cls => ({
        id: cls.id,
        name: cls.name,
        students: getStudents(cls),
      }))
      resolve({
        data: {
          chatsContacts, contacts, classes, profileUser: profileUserData, teacherChats,
        },
      })
    })
  })
}

export function getSingleChat(userId, page = 1) {
  return new Promise(resolve => {
    useJwt.showChatRoom(userId, {
      params: {
        page,
      },
    }).then(res => {
      const responseData = res.data.data
      store.dispatch('app-chat/fetchTotalUnseen')
      if (!responseData.contact) {
        console.error('No Contact')
        return
      }
      const chat = responseData.chat.map(c => ({
        message: c.message,
        time: c.created_at,
        senderId: c.send_by,
        type: c.type,
        sent: true,
        recieved: c.viewed_at !== null,
        id: c.id,
      }))
      resolve({
        data:
        {
          chat,
          nextPage: responseData.next_page_url ? responseData.current_page + 1 : null,
          contact:
            {
              id: responseData.contact.id,
              fullName: `${responseData.contact.firstname} ${responseData.contact.lastname}`,
              role: responseData.contact.user_type ? responseData.contact.user_type.name : '',
              country: responseData.contact.country,
              phone: responseData.contact.phone,
              email: responseData.contact.email,
              about: responseData.contact.about ? responseData.contact.about : '',
              avatar: responseData.contact.avatar,
              status: responseData.contact.last_seen != null ? 'online' : 'offline',
            },
        },
      })
    })
  })
}

export function getGroupChat(userId, model) {
  const args = { group_id: userId, model }
  return new Promise(resolve => {
    useJwt.getGroupChatRoom(args).then(res => {
      const responseData = res.data.data
      const chat = responseData.messages.map(c => ({
        message: c.message,
        time: c.created_at,
        senderId: c.send_by,
        sender: c.sender,
        type: c.type,
        sent: true,
        recieved: c.viewed_at !== null,
        id: c.id,
      })).reverse()
      resolve({
        data:
        {
          chat,
          contact:
            {
              id: responseData.group?.id || responseData.group_details?.id,
              fullName: responseData.group?.name || responseData.group_details?.name,
            },
        },
      })
    })
  })
}

export function addChatMessage(url, request) {
  const contactId = Number(url.substring(url.lastIndexOf('/') + 1))
  // Get event from post data
  const {
    message, senderId, audio, audioUrl, chatType, model, type, code,
  } = request
  // const activeChat = data.chats.find(chat => chat.userId === contactId)
  const newMessageData = {
    message,
    time: new Date(),
    senderId,
    audio,
    audioUrl,
  }
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.accessToken}`,
    },
  }
  const formData = new FormData()
  formData.append('message', newMessageData.audio !== '' ? newMessageData.audio : newMessageData.message)
  // formData.append('type', newMessageData.audio !== '' ? 'file' : 'text')
  formData.append('type', type)
  formData.append('code', code)
  if (chatType === 'group') {
    formData.append('model', model)
    formData.append('group_id', contactId)
  } else {
    formData.append('receive_by', contactId)
  }
  useJwt.axiosIns.post(chatType === 'group' ? '/api/v1/group-chat' : '/api/v1/chat-room', formData, config)
    .then(data => {
      ChatHandler.getInstance().eventEmitter.emit('fileSent', {})
      if (chatType === 'group') getGroupChat(contactId)
      else {
        // eslint-disable-next-line no-lonely-if
        if (data.data.data) {
          const participants = ChatHandler.getInstance().getUserByUserId(
            contactId,
          )
          if (participants) {
            const extraData = data.data.data
            participants.forEach(participant => {
              const chatPayload = new MessagePayload(
                'VideoCall',
                participant.userId,
              )
              chatPayload.extraData = extraData
              chatPayload.type = ChatMessageType.Chat
              ChatHandler.getInstance().sendMessage(chatPayload)
            })
          }
        }
        // if (router.currentRoute.name === 'chat') getSingleChat(contactId)
        // getChatsAndContacts()
      }
      const newMessage = data.data.data || {}
      const newMessageFormatted = {
        id: newMessage.id,
        message: newMessage.message,
        time: newMessage.created_at,
        type: newMessage.type,
        sent: true,
        receiverId: newMessage.receive_by,
        senderId: typeof newMessage.send_by === 'object' ? newMessage.send_by?.id : newMessage.send_by,
        recieved: false,
      }
      GEC_EVENT_BUS.$emit('pushNewlySentChatMessage', {
        message: newMessageFormatted,
      })
    })
}

export function onTyping({ id, type }) {
  return useJwt.messageTyping({
    receiver: id,
    type,
  }).then(() => {
  })
}
