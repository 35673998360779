<template>
  <b-card
    no-body
    class="w-100"
    :class="{
      'active':isActive
    }"
  >
    <b-card-header class="mb-0 pb-0">
      <h3>
        {{ $t('adaptive-learning-module.problem') }} {{ index+1 }}
      </h3>
    </b-card-header>

    <b-card-body>
      <b-row>
        <b-col md="12">
          <div
            v-for="(level) in levels"
            :key="level.text"
            class="transaction-item"
          >

            <div
              class="font-weight-bolder level"
              :class="{
                'active':isActive && level.index === activeLevel,
                'passed':actions[level.index] && actions[level.index].result==='pass',
                'failed':actions[level.index] && actions[level.index].result==='fail',
              }"
            >
              {{ level.text }}
              <div
                v-if="isLastProblem && activeLevel===level.index"
                class="icon-direction completed"
              >
                <feather-icon
                  size="40"
                  class="completed"
                  icon="CheckIcon"
                  style="color:#e1b12c"
                />
              </div>

              <!-- <div
                v-if="ind in actions"
                class="count bg-danger"
              >
                <span>
                  {{ actions[ind].totalFails }}
                </span>
              </div> -->

              <div
                v-if="level.index in actions"
                class="icon-direction"
                :class="actions[level.index].icon"
              >
                <feather-icon

                  size="40"
                  :class="{
                    'text-success':actions[level.index].result==='pass',
                    'text-danger':actions[level.index].result==='fail',
                  }"
                  :icon="actions[level.index].icon"
                />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isLastProblem: {
      type: Boolean,
      default: false,
    },
    levels: {
      type: Array,
      required: true,
    },
    actions: {
      type: Object,
      required: true,
    },
    activeLevel: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style scoped>

.card.active{
     background-color: #353b48;
     color:white;
}
.card.active .card-header h3{
     color:white !important;
}
.level{
    height: 80px;
    width: 80px;
    border: 3px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin-top: 12px;
    position: relative;
}

.level.active{
    border-color: #e1b12c;
}
.level.failed{
    border-color: red;
}
.level.passed{
    border-color: green;
}

.icon-direction{
    position: absolute;
    left: 95px;
    top: 35px;
}
.icon-direction.ArrowUpRightIcon{
    position: absolute;
    left: 95px;
    top: -15px;

}
.count{
      position: absolute;
      right: 8px;
      top: 0;
      background: #353b48;
      height: 24px;
      width: 24px;
      padding: 4px;
      border-radius: 4px;
      color: white;
      border: 1px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
}

</style>
