<template>
  <b-col
    md="12"
    class="mb-1"
  >
    <b-card
      no-body
      class="mb-0"
    >
      <h5 class="p-1 pb-0">
        {{ $t('network-module.invitations') }}
      </h5>
      <div v-if="invitations.data.length>0">
        <b-table
          :fields="columns"
          small
          responsive
          :items="getInvitationsForTable"
        >
          <template #cell(action)="member">
            <span>
              <b-dropdown
                variant="link"
                no-caret
                boundary="viewport"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="idOfInvitationBeingDeleted=member.item.request_id">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $t("cancel") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>

          </template>
        </b-table>
        <b-row v-if="invitations.pagination.totalItems>invitations.pagination.perPage">
          <b-col class="mr-1 d-flex flex-row-reverse">
            <b-pagination
              v-model="invitations.pagination.currentPage"
              :total-rows="invitations.pagination.totalItems"
              :per-page="invitations.pagination.perPage"
              aria-controls="my-table"
              @change="getMoreInvitations"
            />
          </b-col>
        </b-row>
      </div>

      <p
        v-else
        class="px-1"
      >
        {{ $t('network-module.no-invitations') }}
      </p>

    </b-card>

    <delete-modal
      name="modal-delete-invitation"
      :on-delete="deleteInvitation"
      :title="$t('network-module.delete-invitation-title')"
      :sub-title="$t('network-module.delete-invitation-question')"
      :show="idOfInvitationBeingDeleted!=null"
      @close="idOfInvitationBeingDeleted=null"
    />

  </b-col>
</template>
<script>
import {
  BCol, BCard, BTable, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import DeleteModal from '@/views/common/components/DeleteModal.vue'

export default {
  components: {
    BCol, BCard, BTable, BDropdown, BDropdownItem, BPagination, DeleteModal,
  },
  data() {
    return {
      columns: ['name', 'action'],
      idOfInvitationBeingDeleted: null,
    }
  },
  computed: {
    invitations() {
      return this.$store.state.network.invitations
    },
    getInvitationsForTable() {
      return this.invitations.data.map(e => ({
        name: e.fullname,
        request_id: e.request_id,
      }))
    },
  },
  created() {
    this.getMoreInvitations()
  },
  methods: {
    getMoreInvitations(page = this.invitations.pagination.current_page) {
      this.$store.dispatch('network/getInvitations', page)
    },
    deleteInvitation() {
      useJwt.cancelInvitation({
        request_id: this.idOfInvitationBeingDeleted,
      }).then(response => {
        this.$store.dispatch('network/getInvitations')
        this.showSuccessMessage(response)
        this.idOfInvitationBeingDeleted = null
      })
    },
  },
}
</script>
