<template>
  <div>
    <Navigation :class-room-info="classRoomInfo" />
    <b-overlay v-if="!state.isProblemCompleted"
               :show="state.isProcessing"
    >
      <b-row>
        <b-col md="9">
          <Statistics v-if="false"
                      :statistics-data="{}"
          />
          <div class="student-problem px-1 theme-1">
            <Header class="mt-2"
                    :problem-stat="problemStat"
            />
            <InterventionView v-if="interventions.length"
                              :interventions="interventions"
                              @continue="getVocabLabProblem(null, {
                                continue: true,
                              })"
            />
            <MultipleChoiceProblem v-else
                                   mode="question"
                                   :is-processing="state.isProcessing"
                                   :questions="questions"
                                   :has-minimal-view="true"
                                   :button-title="problemStat.isLastProblem ? 'Finish' : 'Next'"
                                   :rules="{
                                     choice_type: 'single'
                                   }"
                                   problem-description="Select correct answer"
                                   @submitQuestion="selectedAnswer => submit(selectedAnswer)"
            />
            <portal-target
              name="problemFeedBack"
            />
          </div>
        </b-col>
        <b-col md="3">
          <ProblemSelection :words="otherWords"
                            :selected-problem="+state.selectedProblem"
                            @selectProblem="val => getVocabLabProblem(val)"
          />
        </b-col>
      </b-row>
    </b-overlay>
    <CompletedView v-else
                   :class-id="classId"
                   :event-id="eventId"
                   @playAgain="handlePlayAgain"
    />
    <ConfirmBox :show="state.showConfirmBox"
                :on-delete="() => submit(state.selectedAnswer, true)"
                varient="primary"
                title="Finish?"
                sub-title="Are you sure you want to finish the problem?"
                @close="state.showConfirmBox = false"
    />
    <audio
      ref="successAudio"
      src="https://assets.mixkit.co/sfx/preview/mixkit-fantasy-game-success-notification-270.mp3"
    />
  </div>
</template>
<script setup>
import { BRow, BCol, BOverlay } from 'bootstrap-vue'
import {
  ref, reactive, onMounted, getCurrentInstance,
} from 'vue'
import useApollo from '@/@core/graphql/useApollo'
import useJwt from '@/auth/jwt/useJwt'
import MultipleChoiceProblem from '@/views/student/problem/compoments/MultipleProblem.vue'
import ConfirmBox from '@/views/common/components/DeleteModal.vue'
import moment from 'moment'
import Header from './components/Header.vue'
import Statistics from './components/Statistics.vue'
import ProblemSelection from './components/ProblemSelection.vue'
import Navigation from './components/Navigation.vue'
import formatProblem from './helper/formatProblem'
import CompletedView from './components/CompletedView.vue'
import InterventionView from './intervention/index.vue'

const classRoomInfo = ref(null)
const root = getCurrentInstance().proxy.$root

const { classId } = root.$route.params
const state = reactive({
  isProcessing: false,
  selectedProblem: 0,
  selectedAnswer: null,
  showConfirmBox: false,
  isProblemCompleted: false,
})

const getClassRoomInfo = () => {
  useApollo.getClassRoomInfo(classId).then(response => {
    classRoomInfo.value = response.data.room
  })
}

const successAudio = ref(null)
const { eventId } = root.$route.params

const questions = ref([])
const otherWords = ref([])
const loaderId = ref('')
const selectedProblemId = ref('')
const problemStat = ref({})
const problemStartAt = ref({})
const interventions = ref([])

const getVocabLabProblem = (problemId = null, otherParams = {}) => {
  state.isProcessing = true
  useJwt.getVocabWordEventData(eventId, {
    params: {
      problemId,
      ...otherParams,
    },
  }).then(response => {
    const responseData = response.data.data
    const { loader } = responseData
    const isCompleted = !!loader.completed_at
    // TODO: After Completed navigate to pass
    if (isCompleted) {
      state.isProblemCompleted = true
      return
    }
    loaderId.value = loader.id
    const isLastProblem = false
    otherWords.value = responseData.words.map(item => ({
      ...item,
      isPassed: loader.pass.includes(item.id),
      isFailed: loader.fail.includes(item.id),
    }))
    problemStat.value = {
      totalProblem: otherWords.value.length,
      passedPercent: (loader.pass.length / otherWords.value.length) * 100,
      totalPass: loader.pass.length,
      round: loader.round,
      isLastProblem,
    }
    if (responseData.intervention && responseData.intervention.length) {
      interventions.value = responseData.intervention
      return
    }

    interventions.value = []
    selectedProblemId.value = responseData.problem.id
    const formattedData = formatProblem(responseData)
    questions.value = formattedData.questions
    state.selectedProblem = responseData.problem.id
    if (!problemStartAt.value[state.selectedProblem]) {
      problemStartAt.value[state.selectedProblem] = moment()
    }
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    state.isProcessing = false
  })
}

// Submit the problem
const submit = (selectedAnswer, forceSubmit = false) => {
  if (!forceSubmit && problemStat.value.isLastProblem) {
    state.selectedAnswer = selectedAnswer
    state.showConfirmBox = true
    return
  }
  state.isProcessing = true
  state.showConfirmBox = false
  useJwt.submitVocabWordEventData({
    problem_id: selectedProblemId.value,
    input_answer: selectedAnswer.answer[0],
    vocab_loader_id: loaderId.value,
    is_last_problem: problemStat.value.isLastProblem,
    time_spent: moment().diff(problemStartAt.value[state.selectedProblem], 'seconds'),
  }).then(response => {
    const { data } = response
    const responseData = data.data
    if (responseData.result) {
      successAudio.value.volume = 0.05
      successAudio.value.play()
    }
    if (responseData.is_completed) {
      state.isProblemCompleted = true
    } else {
      getVocabLabProblem()
    }
  }).finally(() => {
    state.isProcessing = false
  }).catch(error => {
    root.showErrorMessage(error)
  })
}

// handle play again
const handlePlayAgain = () => {
  state.isProblemCompleted = false
  getVocabLabProblem(null, {
    playAgain: 1,
  })
}

onMounted(() => {
  getClassRoomInfo()
  getVocabLabProblem()
})

</script>
<style>
.img-caption {
  font-size: 25px;
}
</style>
