<template>
  <b-modal
    id="CreateArticle"
    ref="CreateArticle"
    v-model="isVisible"
    ok-only
    ok-variant="success"
    :ok-title="$t('confirm')"
    modal-class="modal-success"
    centered
    :title="$t('essay-builder-module.create-essay-question')"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-card-text>
      {{ $t('essay-builder-module.create-essay-body') }}
    </b-card-text>
    <template #modal-footer="">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="success"
            :disabled="isProcessing"
            class="mr-1"
            @click="$emit('yes')"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('actions.yes') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="danger"
            :disabled="isProcessing"
            @click="$emit('no')"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('actions.no') }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import {
  BSpinner, BRow, BCol, BModal, BCardText, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default ({
  directives: {
    Ripple,
  },
  components: {
    BModal, BSpinner, BRow, BCol, BCardText, BButton,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: this.show,
    }
  },
  watch: {
    show() {
      this.isVisible = this.show
    },
  },
})
</script>
