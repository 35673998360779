<template>
  <b-card
    no-body
    class="mb-0"
  >
    <b-overlay
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-row>
        <b-col
          cols="12"
          class="text-right p-1"
        >
          <b-button
            variant="primary"
            class="mx-1"
            @click="exportExcel()"
          >
            {{ $t('course-completion-module.excel-export') }}
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="pr-2 d-flex align-items-center justify-content-between">
        <b-col md="6">
          <h5 class="p-1 pb-0">
            {{ $t("student-report-module.list-of-lessons") }}
          </h5>
        </b-col>

        <b-col
          md="6"
          style="max-width: 400px"
        >
          <b-form-input
            id="filterInput"
            v-model="filter"
            class="mr-1"
            :placeholder="$t('labels.type-to-search')"
            @dragover.prevent
            @dragenter.prevent
          />
        </b-col>
      </b-row>

      <b-table
        responsive
        :items="getLessonsForTable"
        :fields="columns"
        :filter="filter"
        :per-page="perPage"
        hover
        show-empty
        :current-page="currentPage"
        @filtered="onFiltered"
      >
        <template #empty="">
          <div class="w-100 d-flex justify-content-center align-items-centert">
            <p class="p-0 m-0">
              {{ $t('messages.no-record-found') }}
            </p>
          </div>
        </template>
        <template #emptyfiltered="">
          <div class="w-100 d-flex justify-content-center align-items-centert">
            <p class="p-0 m-0">
              {{ $t('messages.no-record-found') }}
            </p>
          </div>
        </template>
        <template #cell(name)="data">
          <router-link
            :to="{ name: `${self.usertype}-lesson`, params: {classId: $route.params.id ,id: data.item.lesson_id}}"
            target="_blank"
          >
            <span>
              {{ data.item.name }}
            </span>
          </router-link>
        </template>
        <template #cell(cefr_rate)="data">
          <span>
            {{ data.item.cefr_rate }}%
          </span>
        </template>
        <template #cell(problem)="data">
          <div class="d-flex align-items-center">
            <b-badge
              :id="`failedLessonCount-${data.item.name}`"
              variant="danger"
              :style="{padding:'6px'}"
              class="text-white mr-1"
            >
              {{ data.item.totalFailed }}
            </b-badge>
            <b-tooltip
              :target="`failedLessonCount-${data.item.name}`"
              triggers="hover"
            >
              {{ $t('course-completion-module.problem-failed') }}
            </b-tooltip>
            <b-badge
              :id="`passedLessonCount-${data.item.name}`"
              variant="success"
              :style="{padding:'6px'}"
              class="text-white mr-1"
            >
              {{ data.item.totalPassed }}
            </b-badge>
            <b-tooltip
              :target="`passedLessonCount-${data.item.name}`"
              triggers="hover"
            >
              {{ $t('course-completion-module.problem-passed') }}
            </b-tooltip>
          </div>
        </template>
        <template #cell(lang_level)="data">
          <span
            class="badge badge-secondary"
            :style="{background:data.item.color,padding:'6px'}"
          >
            <span class="text-white">
              {{ data.item.lang_level!=='invalid' ? getLangLevelTransText(data.item.lang_level) : '---' }}
            </span>
          </span>
        </template>
        <template #cell(completed_at)="data">
          {{ FORMAT_DATE(data.item.completed_at, 'YYYY-MM-DD') }}
        </template>
        <template #cell(status)="data">
          <span
            :class="{
              'text-success': data.item.status == 'passed',
              'text-danger': data.item.status == 'failed',
              'text-warning':
                data.item.status == 'in_progress' ||
                data.item.status == 'try_again',
            }"
          >
            {{ $t(`course-completion-module.${data.item.status}`) }}
          </span>
        </template>
        <template #cell(action)="data">
          <div>
            <span>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover="$t('course-completion-module.view-report')"
                size="sm"
                :disabled="isProcessing"
                variant="outline-primary"
                class="btn-icon mr-1"
                @click.stop="
                  $emit(
                    'showStat',
                    index,
                    getLessonsForTable.findIndex(table => table.lesson_id == data.item.lesson_id)
                  )
                "
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover="$t('course-completion-module.view-essay')"
                size="sm"
                :disabled="isProcessing"
                variant="outline-danger mr-1"
                class="btn-icon"
                @click.stop="$emit('showLessonEssay',data.item.lesson_id,studentId)"
              >
                <feather-icon icon="FileTextIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover="$t('course-completion-module.show-notes')"
                size="sm"
                :disabled="isProcessing"
                variant="outline-success"
                class="btn-icon"
                @click.stop="$emit('showLessonNotes',data.item.lesson_id,studentId)"
              >
                <feather-icon icon="ClipboardIcon" />
              </b-button>
            </span>
          </div>
        </template>
      </b-table>
      <b-col
        v-if="totalRows > perPage"
        cols="12 d-flex justify-content-end"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :disabled="isProcessing"
          align="center"
          class="my-1"
          @change="currentPage = $event"
        />
      </b-col>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BButton,
  BFormInput,
  BPagination,
  BOverlay,
  VBTooltip,
  BBadge,
  BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import exportFromJSON from 'export-from-json'
import i18n from '@/libs/i18n'
import { getColorForLevel, getLevelFromScore } from '@/const/langLevel'
import {
  USER_TYPE_STUDENT, USER_TYPE_PARENT,
} from '@/const/userType'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BFormInput,
    BPagination,
    BRow,
    BCol,
    BOverlay,
    BBadge,
    BTooltip,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    lessons: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    studentId: {
      type: Number,
      required: true,
    },
    dialogName: {
      type: String,
      default: '',
    },
    lessonType: {
      type: String,
      default: () => 'normal',
    },
  },
  data() {
    return {
      filter: null,
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      self: getUserData(),
      columns: [
        {
          key: 'name',
          label: i18n.tc('student-report-module.lesson-table.lesson'),
        },
        {
          key: 'attempts',
          label: i18n.tc('student-report-module.lesson-table.attempts'),
        },
        ...(
          this.lessonType === 'scorm'
            ? [{
              key: 'pass_percent',
              label: 'Completion Rate',
            }]
            : [{
              key: 'problem',
              label: i18n.tc('student-report-module.problem-table.problem'),
            }]
        ),
        ...(
          this.lessonType !== 'scorm'
            ? [
              {
                key: 'cefr_rate',
                label: i18n.tc('student-report-module.lesson-table.cefr-rate'),
              },
              {
                key: 'lang_level',
                label: i18n.tc('student-report-module.lesson-table.lang-level'),
              },
            ] : []
        ),
        ...(this.lessonType !== 'scorm' ? [
          {
            key: 'pass_percent',
            label: i18n.tc('student-report-module.lesson-table.percentage'),
          },
        ] : [
          {
            key: 'points',
            label: i18n.tc('student-report-module.lesson-table.score-points'),
          },
        ]),
        {
          key: 'time_spent',
          label: i18n.tc('student-report-module.lesson-table.time-spent'),
        },
        {
          key: 'completed_at',
          label: i18n.tc('student-report-module.lesson-table.completed_at'),
        },
        {
          key: 'status',
          label: i18n.tc('student-report-module.lesson-table.status'),
        },
        // { key: 'date', label: 'Date' },
        ...(this.lessonType !== 'scorm' ? [
          { key: 'action', label: i18n.tc('action') },
        ] : []),

      ],
    }
  },

  computed: {
    getLessonsForTable() {
      return this.lessons.map(e => ({
        name: e.lesson,
        lesson_id: e.lesson_id,
        is_completed: e.is_completed === 1 ? 'completed' : 'pending',
        completed_at: e.completed_at,
        points: `${e.points}${this.passPercentPrefix(e)}`,
        pass_percent: `${e.score_percent?.toFixed(2)}%`,
        time_spent: `${e.time_spend ?? '-'}`,
        status: e.status ? e.status : '-',
        standards_passed: `${e.passed_st} / ${e.total_standards}`,
        attempts: e.attempt,
        cefr_rate: e.cefrRate,
        lang_level: e.lang_level,
        color: getColorForLevel(getLevelFromScore(e.cefrRate)),
        totalPassed: e.totalPass,
        totalFailed: e.totalFail,
        // date: 'to:do',
      }))
    },

    isAStudent() {
      return getUserData().usertype === USER_TYPE_STUDENT
    },
    isAParent() {
      return getUserData().usertype === USER_TYPE_PARENT
    },
  },
  watch: {
    lessons() {
      this.totalRows = this.lessons.length
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.lessons.length
  },

  methods: {
    passPercentPrefix(item) {
      if (this.lessonType === 'scorm') {
        return ` of ${item.max_score}`
      }
      return '%'
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    normalLetter(word) {
      return word.split('_').map(item => item.charAt(0).toUpperCase() + item.substring(1)).join(' ')
    },
    exportExcel() {
      const data = this.getLessonsForTable.map(report => ({
        Lesson: report.name,
        Attempts: report.attempts,
        'Passed Problems': report.totalPassed,
        'Failed Problems': report.totalFailed,
        'CEFR Rate': `${report.cefr_rate}%`,
        'Lang Level': report.lang_level,
        'Pass Score': report.pass_percent,
        'Score Points': report.points,
        Time: report.time_spent,
        'Completed At': report.completed_at,
        Status: this.normalLetter(report.status),
      }))
      const fileName = `${this.dialogName}`
      const exportType = 'csv'

      exportFromJSON({ data, fileName, exportType })
    },
  },

}
</script>

<style>
.class-table .active-column {
  background-color: rgba(0, 0, 0, 0.15) !important;
}
.class-table tr {
  cursor: pointer;
}
.class-table {
  margin-bottom: 0;
}
</style>
