<template>
  <div>
    <b-row v-if="difficultyLevel">
      <b-col :xl="isProblemPreview ? 12 : 9">

        <game-area
          ref="gameArea"
          :default-class-id="271"
          :default-lesson-id="116"
          :result-summary="resultSummary"
          :difficulty-level="difficultyLevel"
          @getReport="getReport"
          @playAgain="playAgain"
        />
      </b-col>
      <b-col
        v-if="!isProblemPreview"
        xl="3"
      >
        <report
          :report-data="guestReport"
          @playAgain="playAgain"
        />
      </b-col>
    </b-row>
    <difficulties-chooser
      v-else
      @choose="setDiffculty"
    />

  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import {
  USER_TYPE_SUPER,
} from '@/const/userType'
import GameArea from './components/GameArea.vue'
import Report from './components/Report.vue'
import DifficultiesChooser from './components/DifficultiesChooser.vue'

export default {
  components: {
    GameArea,
    BRow,
    BCol,
    Report,
    DifficultiesChooser,
  },
  data() {
    return {
      guestReport: {},
      resultSummary: {},
    }
  },
  computed: {
    difficultyLevel() {
      const values = ['k-grade-2-alphabet', 'k-grade-2-phonics', 'k-grade-2-vocabulary', 'grade-3-4-functional', 'grade-3-4-grammar', 'grade-3-4-eiken-testing',
        'grade-5-6-functional', 'grade-5-6-grammar', 'grade-5-6-eiken-testing', 'middle-school-functional', 'middle-school-academic', 'middle-school-elken-testing',
        'high-school-functional', 'high-school-academic', 'high-school-eiken-testing', 'cefr', 'travel', 'business', 'demo-2']

      if (values.includes(this.$route.query.level)) {
        // this.getReport()
        return this.$route.query.level
      }
      return null
    },
    isProblemPreview() {
      // eslint-disable-next-line eqeqeq
      return !!this.$route.query.problem_id && this.$route.query.problem_id != 0
    },
    isASuperAdmin() {
      return this.AUTH_USER()?.usertype === USER_TYPE_SUPER
    },
  },
  watch: {
    difficultyLevel: {
      handler(newValue) {
        if (newValue) {
          this.getReport()
        }
      },
      immediate: true,
    },
    isPlayingFromSuper() {
      return this.$route.query.from_super
    },
  },
  methods: {
    getReport() {
      useJwt.getDemoLessonReport({
        params: {
          level: this.difficultyLevel || this.$route.query.level,
        },
      }).then(response => {
        const { data } = response
        this.guestReport = data.data
        this.resultSummary = {
          ...this.resultSummary,
          passed_score: data.data.passed_score,
          points: data.data.points,
        }
      })
    },
    setDiffculty(level) {
      this.$router.push({ name: 'demo-lesson', query: { level } })
    },
    playAgain() {
      this.guestReport = []
      this.$refs.gameArea.getProblem({ playAgain: 1 })
      this.$refs.gameArea.gameKey = Math.random()
    },
  },
}
</script>

<style>
    .score-table {
        margin-left: 0 !important
    }
    .report{
      position: fixed;
      right: 70px;
      top: 0;
      z-index: 9999999;
    }
</style>
