<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            SN
          </th>
          <th scope="col">
            Group Name
          </th>
          <th scope="col">
            Reading
          </th>
          <th scope="col">
            Listening
          </th>
          <th scope="col">
            OE-Speaking
          </th>
          <th scope="col">
            OE-Writing
          </th>
          <th scope="col">
            Typing
          </th>
          <th scope="col">
            Pronunciation
          </th>
          <th scope="col">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(group, key) in data"
            :key="key"
            :class="group.group_name === groupOnEdit ? 'bg-light-info' : ''"
        >
          <th scope="row">
            {{ key + 1 }}
          </th>
          <th scope="row">
            {{ group.group_name }}
          </th>
          <td v-for="(skill_type, index) in skills"
              :key="index"
          >
            <span v-if="group[skill_type]">
              <div v-for="skill in group[skill_type]"
                   :key="skill.id"
              >
                <router-link
                  :to="{
                    path: `/${AUTH_USER().usertype}/problem/edit`,
                    query: { problem_id: skill.id },
                  }"
                >
                  {{ skill.name }}
                </router-link>
                <p class="text-muted font-italic small mb-0">
                  {{ skill.problem_type.name }}
                </p>
              </div>
            </span>
          </td>
          <td scope="row">
            <b-button
              size="sm"
              variant="outline-success"
              class="btn-icon"
              @click="handleStatementPreview(group)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>
    <lesson-preview
      v-if="problemIdToShowPreview"
      :show="true"
      :problem-id="problemIdToShowPreview"
      :lesson-id="Number($route.query.lesson_id)"
      :lesson-info="lessonInfo"
      :is-edit-mode="true"
      :problems-in-the-groups="problemsInTheGroups"
      @close="problemIdToShowPreview = null"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import LessonPreview from './components/LessonPreview.vue'

export default {
  components: {
    BButton,
    LessonPreview,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    lessonInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      skills: [
        'reading',
        'listening',
        'speaking',
        'writing',
        'typing',
        'pronunciation',
      ],
      problemIdToShowPreview: null,
      problemsInTheGroups: [],
      groupOnEdit: null,
    }
  },
  methods: {
    handleStatementPreview(item) {
      this.groupOnEdit = item.group_name
      this.problemIdToShowPreview = true
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const i of this.skills) {
        if (item[i]?.[0]) {
          this.problemIdToShowPreview = item[i][0].id
          break
        }
      }
      const prob = []
      // eslint-disable-next-line no-restricted-syntax
      for (const i of this.skills) {
        if (item[i]?.[0]) {
          prob.push({
            id: item[i][0].id,
            name: item[i][0].name,
            problem_type: item[i][0].problem_type,
          })
        }
      }
      this.problemsInTheGroups = prob
    },
    // handleFeedbackPreview(item) {
    //   this.problemIdToShowPreview = true

    //   // eslint-disable-next-line no-restricted-syntax, guard-for-in
    //   for (const i of this.skills) {
    //     if (item[i]?.[0]) {
    //       this.problemIdToShowPreview = item[i][0].id
    //       break
    //     }
    //   }
    //   this.previewPayload = {
    //     layout: 'only_feedback',
    //   }
    // },
  },
}
</script>
