<template>
  <b-row class="pt-2 pl-2 pr-2 profile-head-title mx-0">
    <b-col
      v-if="userInfo"
      class="d-flex align-items-stretch col"
      md="9"
    >
      <b-card
        class="w-100 text-center profile-header"
      >
        <div class="d-flex justify-content-center">
          <h1>{{ $t('class-module.class-report') }}</h1>
          <div class="report-type-switch d-flex align-items-baseline">
            <b-form-checkbox
              v-model="isDefaultReportType"
              class="ml-2"
              :value="false"
              :unchecked-value="true"
              switch
              @change="$emit('switch-report-type', isDefaultReportType)"
            />
            <span
              class="cursor-pointer"
              @click="() => { isDefaultReportType = !isDefaultReportType; $emit('switch-report-type', isDefaultReportType) }"
            >
              {{ currentReportType === 'regular' ? $t('individual-report-module.regular') : $t('individual-report-module.scorm') }}
            </span>
          </div>
        </div>
        <h4>{{ $t('class-module.name') }}: {{ userInfo.firstname }} {{ userInfo.lastname }}</h4>
        <b-row class="mt-1">
          <b-col md="7">
            <div class="d-flex mb-1">
              <b class="mr-2">{{ $t('individual-report-module.nationality') }}:</b> {{ userInfo.country }}
            </div>
            <div class="d-md-flex">
              <b class="mr-2">{{ $t('teacher-schedule.classroom') }}:</b>
              <slot name="classroom-picker" />
            </div>
          </b-col>
          <b-col v-if="showHideCredential"
                 md="5"
          >
            <div class="d-flex mb-1">
              <b class="mr-2">{{ $t('user-module.user-table.email') }}:</b> {{ userInfo.email }}
              <b-button v-b-tooltip.hover="$t('user-module.copy-email')"
                        variant="flat-success"
                        class="btn-icon copy-button ml-2 p-0"
                        @click="copyTextToClipboard(userInfo.email)"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>
            </div>
            <div class="d-flex">
              <b class="mr-2">{{ $t('user-module.user-table.password') }}:</b>
              <span v-if="showPassword"
                    role="button"
                    @click="showPassword = false"
              >{{ userPassword }}</span>
              <span v-else
                    role="button"
                    @click="showPassword = true"
              >******</span>
              <b-button v-b-tooltip.hover="$t('user-module.copy-password')"
                        variant="flat-success"
                        class="btn-icon copy-button ml-2 p-0"
                        @click="copyTextToClipboard(userPassword)"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col md="3"
           class="d-xs-none"
    >
      <div class="d-flex justify-content-center">
        <b-img
          :src="require('@/assets/images/elements/image_2023_03_30T11_47_00_253Z.png')"
          height="100"
          width="100"
        />
      </div>
      <p class="text-center pdf-icon"
         data-html2canvas-ignore="true"
      >
        <feather-icon icon="FileTextIcon"
                      role="button"
                      @click="exportPdf"
        />
      </p>
      <p class="text-center download-pdf"
         data-html2canvas-ignore="true"
      >
        {{ $t('individual-report-module.download-pdf') }}
      </p>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BCard, BImg, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import html2pdf from 'html2pdf.js'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_STUDENT } from '@/const/userType'
import { isVisible } from '@/utils/visibilitySettings'

export default {
  components: {
    BRow, BCol, BCard, BImg, BButton, BFormCheckbox,
  },
  props: {
    userInfo: {
      type: Object,
      default: () => null,
    },
    classInfo: {
      type: Object,
      default: () => null,
    },
    currentReportType: {
      type: String,
      default: () => 'regular',
    },
    defaultReportType: {
      type: String,
      default: () => 'regular',
    },
  },
  data() {
    return {
      showPassword: false,
      classRoomLists: [],
      // isDefaultReportType: false,
    }
  },
  computed: {
    userPassword() {
      return this.userInfo?.credential.password
    },
    isDefaultReportType() {
      if (this.currentReportType === 'scorm') {
        return true
      }
      return false
    },
    isAStudent() {
      return getUserData() && getUserData().usertype === USER_TYPE_STUDENT
    },
    showHideCredential() {
      // return this.isAStudent
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const showUserCredential = isVisible('user_credentials', schoolSetting, distSetting)
      if (this.isAStudent && !showUserCredential) {
        return false
      }
      return true
    },
  },
  methods: {
    exportPdf() {
      const opt = {
        pagespilt: true,
        filename: `curriculum_ebook_${this.classInfo.name}.pdf`,
        image: { type: 'jpeg', quality: 0.20 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { orientation: 'landscape', format: 'a4' },
      }

      html2pdf().set(opt).from(document.getElementById('individualReportPdf')).save()
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
  .profile-header {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .report-type-switch {
    margin-top: 8px;
  }
  .profile-head-title {
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 5px;
  }
  .dark-layout {
    .profile-head-title {
      background-color: $theme-dark-card-bg !important;
      background-color: $theme-dark-box-shadow;
    }
  }

.semi-light-layout,
.semi-light-reverse-layout,
.green-layout,
.brown-layout {
  .download-pdf, .pdf-icon {
    color: #5e5873 !important;
  }
}

</style>
