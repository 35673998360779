<template>
  <b-modal ref="followUpModal"
           :visible="true"
           size="xl"
           :no-close-on-backdrop="true"
           modal-class="modal-primary"
           centered
           :title="studentTitle"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <b-row>
      <b-col md="12">
        <div class="border mb-1 p-1">
          <b-tabs v-model="activeTab"
                  pills
                  class="mb-2 flex flex-nowrap"
          >
            <template v-for="pattern of tabsPatterns">
              <b-tab v-if="pattern == 'main'"
                     :key="pattern"
                     title="Main"
                     @click="initFollowUpData('main')"
              >
                <b-row>
                  <b-col md="6">
                    <b-input-group>
                      <b-form-input
                        id="problem"
                        v-model="filter.main"
                        name="vocab"
                        :placeholder="$t('search-problem')"
                      />
                      <!-- <b-input-group-append>
                        <b-button variant="outline-success"
                                  :disabled="isProcessing"
                                  @click="getProblemByVocab"
                        >
                          <b-spinner
                            v-show="isProcessing"
                            small
                          />
                          Search
                        </b-button>
                      </b-input-group-append> -->
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab v-else-if="pattern == 'vocab'"
                     :key="pattern"
                     title="Vocab"
                     @click="initFollowUpData('vocab')"
              >
                <b-row>
                  <b-col md="6">
                    <b-input-group>
                      <b-form-input
                        id="vocab"
                        v-model="filter.vocab"
                        name="vocab"
                        :placeholder="$t('search-vocab')"
                      />
                      <!-- <b-input-group-append>
                        <b-button variant="outline-success"
                                  :disabled="isProcessing"
                                  @click="getProblemByVocab"
                        >
                          <b-spinner
                            v-show="isProcessing"
                            small
                          />
                          Search
                        </b-button>
                      </b-input-group-append> -->
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab v-else-if="pattern == 'grammar'"
                     :key="pattern"
                     :title="$t('grammar')"
              >
                <grade-course-filter
                  :form="gradeCourseLesson"
                />
                <b-row>
                  <b-col md="4">
                    <label>{{ $t('labels.create-problem.skill-type') }}</label>
                    <b-form-select
                      id="skill_type"
                      v-model="filter.d"
                      :options="skillTypes"
                      name="skill-type"
                    />
                  </b-col>
                  <b-col md="3"
                         class="mt-2"
                  >
                    <b-button variant="outline-success"
                              :disabled="isProcessing"
                    >
                      <b-spinner
                        v-show="isProcessing"
                        small
                      />
                      {{ $t('messages.SearchPlaceholder') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab v-else-if="pattern == 'tgp'"
                     :key="pattern"
                     :title="$t('tgp')"
                     lazy
              >
                <TeacherCreateProblem :lesson-id="Number(lessonId)"
                                      :lesson-domain="lessonDomain"
                                      type="tgp"
                                      :selected-problems="selectedProblems"
                                      @updateProblem="value => selectedProblems = value"
                />
              </b-tab>
              <b-tab v-else-if="pattern == 'mgp'"
                     :key="pattern"
                     :title="$t('mgp')"
                     lazy
              >
                <TeacherCreateProblem :lesson-id="Number(lessonId)"
                                      :lesson-domain="lessonDomain"
                                      :selected-problems="selectedProblems"
                                      type="mgp"
                                      @updateProblem="value => selectedProblems = value"
                />
              </b-tab>
            </template>
          </b-tabs>
        </div>
      </b-col>

      <b-col
        v-if="![3,4].includes(activeTab)"
        md="12"
      >
        <b-row>
          <b-col class="select-problems">
            <template v-if="isProcessing">
              <div class="text-center">
                <b-spinner small />
              </div>
            </template>
            <b-row
              v-if="lessonWiseProblems?.length > 0"
              class="p-1"
            >
              <b-col v-for="problem,index in problemsForSelect"
                     :key="index"
                     md="4"
              >
                <div
                  class="card border p-1 mb-1"
                  :class="getProblemBorderColor(problem.code)"
                >
                  <div class="form-check d-flex align-item-center">
                    <input
                      :id="problem.code"
                      v-model="selectedProblems"
                      type="checkbox"
                      class="form-check-input"
                      :checked="true"
                      :value="{code: problem.code, label: problem.label}"
                      style="width:20px; height:20px;"
                    >
                    <label :for="problem.code"
                           class="form-check-label"
                           style="margin-left:20px; font-size: 14px;"
                    >
                      {{ problem.label }}
                    </label>
                    <div style="position: absolute; right: 0;">
                      <b-button
                        variant="flat-success"
                        class="btn-icon rounded-circle"
                        @click="getProblemForPreview(problem.code)"
                      >
                        <feather-icon icon="EyeIcon" />
                      </b-button>

                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <!-- <div v-if="selfCreatedProblems.length > 0">
              <h4>Teacher Generated Problem (TGP)</h4>
              <b-row>
                <b-col v-for="problem,index in selfCreatedProblems"
                       :key="index"
                       md="4"
                >
                  <div
                    class="card border p-1 mb-1"
                    :class="getProblemBorderColor(problem.code)"
                  >
                    <div class="form-check d-flex align-item-center">
                      <input
                        :id="problem.code"
                        v-model="selectedProblems"
                        type="checkbox"
                        class="form-check-input"
                        :checked="true"
                        :value="{code: problem.code, label: problem.label}"
                        style="width:20px; height:20px;"
                      >
                      <label :for="problem.code"
                             class="form-check-label"
                             style="margin-left:20px; font-size: 14px;"
                      >
                        {{ problem.label }}
                      </label>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div> -->
            <div
              v-if="lessonWiseProblems?.length == 0"
              class="justify-content-around"
            >
              <h5 class="p-1">
                {{ $t('no-follow-up-available') }}
              </h5>
            </div>
          </b-col>
          <b-col v-if="selectedProblems.length"
                 md="3"
                 class=""
          >
            <h6 class="text-success">
              Selected Review Lessons : {{ selectedProblems.length }}
            </h6>

            <ul class="list-group">
              <li v-for="code in selectedProblems"
                  :key="code.code"
                  class="selected-follow-up list-group-item"
              >
                {{ code.label }}

                <feather-icon class="close text-danger"
                              icon="XIcon"
                              role="button"
                              @click="selectedProblems = selectedProblems.filter(i => i.code != code.code)"
                />

              </li>
            </ul>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <template #modal-footer="{}">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-if="selectedProblems.filter(it => failedProblems.map(i => i.problem_id).includes(it.code)).length
              &&
              ['main', 'vocab', 'grammar'].includes(tabsPatterns[activeTab])
            "
            variant="outline-primary"
            class="mr-2"
            @click="showMgpCreation = true"
          >
            Generate MGP
          </b-button>

          <b-button
            v-if="eventId"
            v-b-tooltip.hover.top="selectedProblems?.length == 0 ? 'Select at least one Problem' :'Add Review Lesson'"
            variant="primary"
            :disabled="isProcessing || selectedProblems?.length === 0"
            @click="updateFollowup"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            Update Review Lesson
          </b-button>
          <b-button
            v-else
            v-b-tooltip.hover.top="selectedProblems?.length == 0 ? 'Select at least one Problem' :'Add Review Lesson'"
            variant="primary"
            :disabled="isProcessing || selectedProblems?.length === 0"
            @click="addFollowUp"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            Add Review Lesson
          </b-button>
        </b-col>
      </b-row>
    </template>
    <question-preview
      :show="showPreview"
      :show-edit-mode="false"
      :data="problemPreview || {}"
      @close="showPreview=false"
    />
    <machine-generated-problem v-if="showMgpCreation"
                               :selected-problems="selectedProblems"
                               :failed-problems="failedProblems"
                               @close="showMgpCreation = null"
                               @onSuccess="activeTab = 4"
    />
  </b-modal>
</template>
<script>
import {
  BModal, BRow, BCol, BTabs, BTab, BInputGroup, BFormInput, BButton, BFormSelect, BSpinner,
} from 'bootstrap-vue'
import SkillTypes from '@/const/skillTypes'
import useJwt from '@/auth/jwt/useJwt'
import QuestionPreview from '@/views/common/components/QuestionPreview.vue'
import GEC_EVENT_BUS from '@/utils/eventBus'
import GradeCourseFilter from './GradeCourseFilter.vue'
import TeacherCreateProblem from './teacher-problem/index.vue'
import MachineGeneratedProblem from './mgp-problem/GenerateProblem.vue'

export default {
  components: {
    // Register BootstrapVue components for use in the template
    BModal,
    BRow,
    BCol,
    BTabs,
    BTab,
    BInputGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BSpinner,
    // BInputGroupAppend,
    GradeCourseFilter,
    TeacherCreateProblem,
    QuestionPreview,
    MachineGeneratedProblem,
  },
  props: {
    classId: {
      type: Number,
      required: true,
    },
    studentId: {
      type: Number,
      required: true,
    },
    lessonId: {
      type: Number,
      required: true,
    },
    eventId: {
      type: Number,
      default: 0,
    },
    studentInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isProcessing: false,
      lessonWiseProblems: [],
      skillTypes: SkillTypes,
      selectedProblems: [],
      selfCreatedProblems: [],
      filter: {
        main: null,
        vocab: null,
        lesson: null,
        skillType: null,
      },
      activeTab: 0,
      lessons: [
        { value: null, text: 'Select Lesson' },
      ],
      gradeCourseLesson: {
        gradeId: null,
        courseId: null,
        lessonGroupId: null,
        lessonId: null,
      },
      studentInfoResponse: null,
      lessonDomain: [],
      auth: this.AUTH_USER(),
      failedProblems: [],
      passedProblems: [],
      showPreview: false,
      problemBeingPreviewed: null,
      problemTemplates: {},
      isProcessingMgp: false,
      problemPreview: {},
      showMgpCreation: false,
      tabsPatterns: ['main', 'vocab', 'grammar', 'tgp', 'mgp'],
    }
  },
  computed: {
    studentTitle() {
      const studentName = this.studentInfo?.fullName || (this.studentInfoResponse ? (`${this.studentInfoResponse?.firstname} ${this.studentInfoResponse.lastname}`) : '') || ''
      return this.$i18n.tc('follow-up', { studentName })
    },
    passedProblemArray() {
      return this.passedProblems.map(item => item.problem_id)
    },
    allProblemsThatAreAttempted() {
      return [...this.passedProblems, this.failedProblems]
    },
    failedProblemArray() {
      return this.failedProblems.map(item => item.problem_id)
    },
    problemsForSelect() {
      const mappedProblems = this.lessonWiseProblems.map(e => ({
        code: e.id,
        skill: e.skill_type,
        label: `${e.name} - ${e.problem_type?.name} (${e.skill_type})`,
      }))
      if (this.filter.skillType && this.activeTab === 2) {
        return mappedProblems.filter(item => item.skill === this.filter.skillType)
      }
      if (['main', 'vocab'].includes(this.tabsPatterns[this.activeTab])) {
        const term = this.filter[this.tabsPatterns[this.activeTab]]
        if (term) {
          return mappedProblems.filter(it => it.label.includes(term))
        }
      }
      return mappedProblems
    },
  },
  watch: {
    'gradeCourseLesson.lessonId': function (value) {
      this.getProblemByLessons(value)
    },
  },
  mounted() {
    this.initFollowUpData()
    this.getPassedFailedProblemsOfStudent()
  },
  methods: {
    getProblemBorderColor(code) {
      if (this.failedProblemArray.includes(code)) {
        return 'border-danger'
      }
      if (this.passedProblemArray.includes(code)) {
        return 'border-success'
      }
      return ''
    },
    getTemplates(id) {
      if (!id) return
      if (this.problemTemplates[id]) return
      // eslint-disable-next-line consistent-return
      return new Promise(resolve => {
        useJwt.getTemplate(id).then(res => {
          this.problemTemplates[id] = res.data.data
          resolve('')
        })
      })
    },
    getProblemForPreview(problemId) {
      const testId = this.allProblemsThatAreAttempted.find(f => f.problem_id === problemId)?.test_id
      useJwt.getProblem(problemId).then(async res => {
        const problemData = res.data.data
        const index = 0
        const statementAndFeedback = problemData.problem_statements[index] || problemData.problem_statements[0]
        await this.getTemplates(statementAndFeedback.fb_template_id)
        await this.getTemplates(statementAndFeedback.st_template_id)
        let answers = []
        if (testId) {
          const answersInput = await useJwt.getAnswerPreview(testId)
          answers = answersInput.data?.data
        }
        // show the last attempted language level
        // index = this.problemBeingPreviewed.attempt_data?.length > 0 ? this.problemBeingPreviewed.attempt_data[this.problemBeingPreviewed.attempt_data.length - 1].lang_level - 1 : index
        this.problemPreview = {
          theme: problemData.theme ? problemData.theme : 0,
          problemType: problemData.problem_type.name,
          testId,
          feedback: {
            type: statementAndFeedback.fb_type,
            text: statementAndFeedback.feedback,
            image: statementAndFeedback.fb_type === 'image' ? statementAndFeedback.fb_image : null,
            audio: statementAndFeedback.fb_type === 'audio' ? statementAndFeedback.fb_image : null,
            template: this.problemTemplates[statementAndFeedback.fb_template_id],
          },
          statement: {
            type: statementAndFeedback.st_type,
            text: statementAndFeedback.statement,
            image: statementAndFeedback.st_type === 'image' ? statementAndFeedback.st_image : null,
            audio: statementAndFeedback.st_type === 'audio' ? statementAndFeedback.st_image : null,
            template: this.problemTemplates[statementAndFeedback.st_template_id],
            video: problemData.video_time_stamp,
          },
          // eslint-disable-next-line eqeqeq
          questions: problemData.questions.length > 1 ? problemData.questions.filter(e => e.lang_level == (index + 1).toString()) : problemData.questions,
          answers,
          level: index + 1,
        }
        this.showPreview = true
      })
    },
    initFollowUpData(type = 'main') {
      if (this.eventId) {
        this.getProblemFromEvent()
      } else {
        this.getProblemByLessons(undefined, type)
      }
    },
    getProblemFromEvent() {
      this.isProcessing = true
      useJwt.getProblemFromFollowUpEvent(this.eventId).then(response => {
        this.lessonWiseProblems = response.data.data.originalLessonProblems
        this.selectedProblems = response.data.data.followUpProblems.map(item => ({
          code: item.id,
          label: `${item.name} - ${item.problem_type?.name} (${item.skill_type})`,
        }))
        this.selfCreatedProblems = response.data.data.self_created_problem.map(e => ({
          code: e.id,
          skill: e.skill_type,
          label: `${e.name} - ${e.problem_type?.name} (${e.skill_type})`,
        }))
        this.gradeCourseLesson = {
          gradeId: null,
          courseId: response.data.data.parent_lesson_group?.course_id,
          lessonGroupId: response.data.data.parent_lesson_group?.id,
          lessonId: response.data.data.parent_lesson_id,
        }
        this.lessonDomain = response.data.data.lesson_domain
        this.studentInfoResponse = response.data.data.student
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    getPassedFailedProblemsOfStudent() {
      useJwt.getFailedProblemOfStudent(this.studentId, {
        params: {
          lesson_id: this.lessonId,
          class_id: this.classId,
        },
      }).then(response => {
        this.failedProblems = response.data.data.failed_problem.map(item => {
          const testStatIds = item.test_id.split(',')
          testStatIds.sort((a, b) => +b - +a)
          return {
            test_id: +testStatIds[0],
            problem_id: item.problem_id,
          }
        })
        this.passedProblems = response.data.data.passed_problem.map(item => {
          const testStatIds = item.test_id.split(',')
          testStatIds.sort((a, b) => +b - +a)
          return {
            test_id: +testStatIds[0],
            problem_id: item.problem_id,
          }
        })
      }).catch(error => {
        console.error(error)
      })
    },
    getProblemByLessons(lessonId = this.lessonId, type = 'main') {
      this.isProcessing = true
      useJwt.getProblemsLessonId(lessonId, {
        params: {
          branch: type,
        },
      }).then(response => {
        this.lessonWiseProblems = response.data.data.data.filter(item => +item.created_by !== +this.auth.id)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
      useJwt.getTeacherCreateProblem(lessonId).then(response => {
        this.lessonDomain = response.data.data.lesson_domain
        this.selfCreatedProblems = response.data.data.problems.map(e => ({
          code: e.id,
          skill: e.skill_type,
          label: `${e.name} - ${e.problem_type?.name} (${e?.skill_type})`,
        }))
      })
    },
    getProblemByVocab() {
      this.isProcessing = true
      useJwt.getProblemsByVocabs(this.filter.vocab).then(response => {
        this.lessonWiseProblems = response.data.data.data
      }).catch(error => {
        this.showErrorMessage(error)
        console.error(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    addFollowUp() {
      this.isProcessing = true

      const data = {
        class_id: this.classId,
        student_id: this.studentId,
        problem_id: this.selectedProblems.map(item => item.code),
        lesson_id: this.lessonId,
      }
      useJwt.storeFollowUp(this.classId, data)
        .then(response => {
          this.selectedStudents = []
          this.selectedProblems = []
          this.$emit('close')
          this.showSuccessMessage(response)
          GEC_EVENT_BUS.$emit('follow-up-updated')
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
    },
    updateFollowup() {
      this.isProcessing = true
      useJwt.updateFollowUp(this.eventId, {
        problem_id: this.selectedProblems.map(item => item.code),
      })
        .then(response => {
          this.selectedStudents = []
          this.selectedProblems = []
          this.$emit('close')
          this.showSuccessMessage(response)
          GEC_EVENT_BUS.$emit('follow-up-updated')
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
    },
  },
}
</script>
<style lang="scss">
.selected-follow-up .close {
  visibility: hidden;
}
.selected-follow-up:hover {
  .close {
    visibility: visible;
  }
}

.select-problems{
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin-bottom: 10px;
  max-height: 428px;
  overflow-y: scroll;
}

</style>
