<template>
  <div>
    <div>
      <h4>{{ $t('pairing.total-group') }}</h4>
      <b-card>
        <vue-slider
          v-model="groupNum"
          :adsorb="true"
          :interval="1"
          :min="1"
          :marks="true"
          :max="Math.round(students.length / 2, 0)"
          class="mb-3"
          @change="removeHiddenGroupsUsers"
        />
      </b-card>
      <div>
        <h4>{{ $t('pairing.students') }}</h4>
        <b-card>
          <b-button
            v-for="student of unSelectedStudent"
            :key="student.id"
            variant="outline-primary"
            size="sm"
            class="mr-1 mb-1"
            :draggable="!isRandom"
            @dragstart="startDrag($event, student.id)"
          >
            <feather-icon
              icon="UserIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ getStudentName(student.id) }}</span>
          </b-button>
        </b-card>
      </div>
      <div
        v-if="isRandom"
        class="d-flex mb-1 justify-content-end"
      >
        <b-button
          variant="outline-success"
          size="sm"
          @click="randomizeStudentGroups"
        >
          <feather-icon
            icon="RefreshCcwIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('pairing.re-order') }}</span>
        </b-button>
      </div>
    </div>

    <b-row>
      <b-col
        v-for="group, ind in totalGroup"
        :key="group.id"
      >
        <div>
          <h4>{{ $t('student-lab-module.lesson-model.group') }} {{ ind + 1 }}</h4>
        </div>
        <!-- <div
          v-if="self.usertype !== 'student' "
          :style="{ visibility: group.students.length ? 'visible' : 'hidden' }"
        >
          <b-button
            size="sm"
            variant="outline-secondary"
            class="mr-1 mb-1"
            @click="openNotes(group.students)"
          >
            <feather-icon
              icon="ExternalLinkIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('pairing.view-note') }}</span>
          </b-button>
        </div> -->
        <b-card
          style="min-height: 100px"
          @drop="onDrop($event,group.id)"
          @dragover.prevent
          @dragenter.prevent
        >
          <b-button
            v-for="student of group.students"
            :key="student.id"
            variant="outline-primary"
            size="sm"
            class="mr-1 mb-1"
            :draggable="!isRandom"
            @dragstart="startDrag($event, student.id)"
          >
            <feather-icon
              icon="UserIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ getStudentName(student.id) }}</span>
            <span
              v-if="selectedType !== 'random'"
              style="position: absolute; margin-top: -13px;margin-left: 5px"
              @click="removeFromGroup(student.id)"
            >
              <feather-icon
                icon="XCircleIcon"
                class="mr-50"
              />
            </span>
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-end">
      <b-button
        variant="primary"
        :disabled="isProcessing"
        @click="$emit('onUpdate', selectedStudentsWithGroup)"
      >
        <b-spinner
          v-if="isProcessing"
          small
        />
        {{ $t('pairing.update-pair') }}
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    VueSlider,
    BSpinner,
  },
  props: {
    selectedType: {
      type: String,
      default: 'custom',
    },
    students: {
      type: Array,
      default: () => [],
    },
    classId: {
      type: [Number, String, Object],
      default: () => null,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    savedGroupsPairs: {
      type: Array,
      default: () => [],
    },
    savedGroupNum: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      selectedStudentsWithGroup: this.savedGroupsPairs,
      groupNum: this.savedGroupNum,
      self: getUserData(),
    }
  },
  computed: {
    maxRandomGroup() {
      return this.groupNum
    },
    unSelectedStudent() {
      const selectedStudent = this.selectedStudentsWithGroup.map(student => Number(student.id))
      return this.students.filter(student => !selectedStudent.includes(Number(student.id)))
    },
    totalGroup() {
      const groups = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.maxRandomGroup; i++) {
        const id = `G-${this.classId}-${i}`
        groups.push({
          id,
          index: i,
          students: this.selectedStudentsWithGroup.filter(student => student.groupId === id),
        })
      }
      return groups
    },
    isRandom() {
      return this.selectedType === 'random'
    },
  },
  // watch: {
  //   selectedType: {
  //     handler(newValue) {
  //       if (newValue === 'random') {
  //         this.randomizeStudentGroups()
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
  methods: {
    startDrag(evt, studentId) {
      // eslint-disable-next-line no-param-reassign
      evt.dataTransfer.dropEffect = 'move'
      // eslint-disable-next-line no-param-reassign
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('itemID', studentId)
    },
    onDrop(evt, groupId) {
      const id = evt.dataTransfer.getData('itemID')
      const selectedStudentsWithGroup = this.selectedStudentsWithGroup.filter(student => Number(student.id) !== Number(id))
      selectedStudentsWithGroup.push({
        id,
        groupId,
        info: this.students.find(student => Number(student.id) === Number(id)),
      })
      this.selectedStudentsWithGroup = selectedStudentsWithGroup
    },
    removeFromGroup(studentId) {
      this.selectedStudentsWithGroup = this.selectedStudentsWithGroup.filter(groups => Number(groups.id) !== Number(studentId))
    },
    randomizeStudentGroups() {
      const selectedGroups = []
      const availableGroups = this.totalGroup.map(grp => grp.id)
      const maxMember = this.students.length / this.maxRandomGroup
      const chooseGroup = () => {
        const requiredGroups = []
        availableGroups.forEach(groupId => {
          const hasMax = selectedGroups.filter(grp => grp.groupId === groupId).length >= maxMember
          if (!hasMax) {
            requiredGroups.push(groupId)
          }
        })
        const groupIndex = Math.floor(Math.random() * requiredGroups.length)
        return requiredGroups[groupIndex]
      }

      this.students.forEach(student => {
        const groupId = chooseGroup()
        selectedGroups.push({
          id: student.id,
          groupId,
        })
      })
      this.selectedStudentsWithGroup = selectedGroups
    },
    getStudentName(studentId) {
      const studentInfo = this.students.find(student => Number(student.id) === Number(studentId))
      return studentInfo ? studentInfo.name : '-'
    },
    removeHiddenGroupsUsers() {
      const groupIds = [...new Set(this.totalGroup.map(grp => grp.id))]
      this.selectedStudentsWithGroup = this.selectedStudentsWithGroup.filter(group => groupIds.includes(group.groupId))
    },
    openNotes(students) {
      const studentIds = students.map(student => student.id)
      this.$router.push({
        path: '/view-notes-all',
        query: {
          student: JSON.stringify(studentIds),
          classId: this.classId,
        },
      })
    },
  },
}
</script>
<style scoped>
.hide {
    visibility: hidden !important;
}
</style>
