<template>
  <b-row>
    <b-col md="12">
      <!-- form -->
      <validation-observer ref="createEditRubricForm">
        <b-form
          class="add-student-from"
          @submit.prevent="handleSubmit"
        >
          <button
            type="submit"
            class="d-none"
          />
          <b-row>
            <b-col md="12">
              <b-form-group
                :label="$t('student-diary-module.rubric-title')"
                label-for="rubric-name"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('student-diary-module.rubric-title')"
                  rules="required"
                  vid="title"
                >
                  <b-form-input
                    v-model="localRubric.title"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col
              class="d-flex justify-content-end"
            >
              <b-button
                v-if="localRubric && localRubric.id"
                variant="danger"
                :disabled="isProcessing"
                class="mr-1"
                @click="clearRubric"
              >
                <b-spinner
                  v-show="isProcessing"
                  small
                />
                {{ $t('student-diary-module.clear') }}
              </b-button>

              <b-button
                variant="primary"
                :disabled="isProcessing"
                @click="handleSubmit()"
              >
                <b-spinner
                  v-show="isProcessing"
                  small
                />
                {{ localRubric && localRubric.id ? $t('student-diary-module.update-rubric'): $t('student-diary-module.create-rubric') }}
              </b-button>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>
    </b-col>
  </b-row>
</template>

<script>
import {
  BSpinner,
  BRow,
  BCol,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    rubric: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      localRubric: {
        title: '',
      },
      isProcessing: false,
    }
  },

  watch: {
    rubric() {
      if (!this.rubric) {
        this.clearRubric()
      } else this.localRubric = JSON.parse(JSON.stringify(this.rubric))
    },
  },
  mounted() {
    this.clearRubric()
  },

  methods: {
    clearRubric() {
      this.localRubric = {
        title: '',
      }
      this.$nextTick(() => this.$refs.createEditRubricForm.reset())
      this.$emit('clear')
    },
    handleSubmit() {
      this.$refs.createEditRubricForm.validate().then(success => {
        if (success) {
          this.isProcessing = true;

          (this.rubric
            ? useJwt.updateClassRubric(
              this.localRubric.id,
              this.localRubric,
            )
            : useJwt.createClassRubric(this.localRubric)
          )
            .then(res => {
              this.$emit('onRubricUpdated')
              this.clearRubric()
              this.showSuccessMessage(res)
            }).catch(err => {
              this.$refs.createEditRubricForm.setErrors(err.response.data.errors)
            })
            .finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
  },
}
</script>
