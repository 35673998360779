<template>
  <b-modal
    id="personalityCreateEdit"
    :title="mode === 'edit' ? $t('personality-module.update-personality') : $t('personality-module.add-new-personality')"
    :ok-title="mode === 'edit' ? $t('actions.update') : $t('actions.create')"
    :cancel-title="$t('cancel')"
    @ok="handleSubmit"
    @show="loadData"
  >
    <validation-observer ref="personality">
      <!-- Name -->
      <b-form-group :label="$t('personality-module.name')">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('personality-module.name')"
          rules="required"
        >
          <b-form-input
            v-model="form.name"
            type="text"
            :placeholder="$t('personality-module.name')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- Category -->
      <b-form-group :label="$t('personality-module.category')">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('personality-module.category')"
          rules="required"
        >
          <b-form-input
            v-model="form.category"
            list="category"
            type="text"
            :placeholder="$t('personality-module.category')"
          />
          <datalist id="category">
            <option
              v-for="category in categories"
              :key="category.id"
            >
              {{ category }}
            </option>
          </datalist>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- Value -->
      <b-form-group :label="$t('personality-module.value')">
        <b-form-input
          v-model="form.value"
          type="number"
          :placeholder="$t('personality-module.value')"
        />
      </b-form-group>

      <!-- Vibe -->
      <b-form-group :label="$t('personality-module.vibes')">
        <b-form-tags
          id="vibes"
          v-model="selectedVibeNames"
          :placeholder="$t('personality-module.vibes-placeholder')"
          :invalid-tag-text="$t('personality-module.invalid-vibe')"
          :duplicate-tag-text="$t('personality-module.duplicate-vibe')"
          :tag-validator="tagValidator"
        />
      </b-form-group>

      <!-- Vibe Popover -->
      <b-popover
        target="vibes"
        triggers="hover"
        placement="bottom"
      >
        <b-card class="mb-0">
          <img
            v-for="vibe in vibes"
            :key="vibe.id"
            :src="vibe.icon ? vibe.icon : defaultVibes[vibe.code]"
            :alt="vibe.code"
            class="vibe mb-1 cursor-pointer"
            @click="selectVibes(vibe.id)"
          >
        </b-card>
      </b-popover>
      <b-form-group :label="$t('personality-module.description')">
        <b-form-textarea
          v-model="form.description"
          rows="5"
          :placeholder="$t('personality-module.description')"
        />
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal, BFormGroup, BFormInput, BFormTags, BCard, BPopover, BFormTextarea,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormTags,
    BFormInput,
    BCard,
    BPopover,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    personality: {
      type: Object,
      default: () => null,
    },
    categories: {
      type: Array,
      required: true,
      default: () => [],
    },
    mode: {
      type: String,
      required: true,
      default: () => 'create',
    },
    vibes: {
      type: Array,
      required: true,
      default: () => [],
    },
    defaultVibes: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      form: {},
      selectedVibes: [],
      selectedVibeNames: [],
    }
  },
  computed: {
    vibeSelection() {
      return this.selectedVibeNames
    },
  },
  watch: {
    vibeSelection() {
      this.selectedVibes = []
      if (this.selectedVibeNames) {
        this.selectedVibeNames.forEach(vibe => {
          this.selectedVibes.push(this.vibes.find(v => v.name === vibe).id)
        })
      }
    },
  },
  methods: {
    loadData() {
      this.clearData()
      if (this.mode === 'edit') {
        this.form = this.personality
        this.personality.vibes.forEach(vibe => {
          this.selectedVibes.push(vibe.id)
          this.selectedVibeNames.push(vibe.name)
        })
      }
    },
    selectVibes(id) {
      const vibes = this.vibes.find(vibe => vibe.id === id)
      this.selectedVibeNames.push(vibes.name)
    },
    handleSubmit(bvModal) {
      bvModal.preventDefault()
      this.$refs.personality.validate()
        .then(success => {
          if (success) {
            this.form.vibes = this.selectedVibes
            if (this.mode === 'edit') this.update()
            else this.create()
          }
        }).finally(() => { this.$bvModal.hide('personalityCreateEdit') })
    },
    create() {
      useJwt.storePersonality(this.form)
        .then(response => {
          this.showSuccessMessage(response)
          this.$emit('getPersonalities')
        }).finally(() => { this.clearData() })
    },
    update() {
      this.$emit('loading')
      const params = {
        ...this.form,
        _method: 'patch',
      }

      useJwt.updatePersonality(this.personality.id, params)
        .then(response => {
          this.showSuccessMessage(response)
        }).finally(() => {
          this.clearData()
          this.$emit('getPersonalities')
        })
    },
    tagValidator(tag) {
      return this.vibes.find(vibe => vibe.name === tag)
    },
    clearData() {
      this.form = {}
      this.selectedVibes = []
      this.selectedVibeNames = []
      if (this.$refs.personality) this.$refs.personality.reset()
    },
    hidePopover() {
      this.$root.$emit('bv::hide::popover')
    },
  },
}
</script>

<style scoped>
.vibe {
  width: 60px;
  transition: transform 0.3s ease-in;
}
.vibe:hover {
  transform: scale(1.1);
}
.card-body {
  padding: 1rem .5rem !important;
  text-align: center;
}
.vibe {
  margin: auto 6px;
}
</style>
