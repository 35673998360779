<template>
  <b-modal
    id="modal-add-question"
    ref="modal-add-question"
    ok-only
    ok-variant="primary"
    :ok-title="$t('confirm')"
    modal-class="modal-primary"
    centered
    :title="$t('actions.create-question')"
    @ok="addQuestion()"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-col md="12">
      <validation-observer ref="addQuestionsForm">
        <b-tabs
          pills
          align="center"
          class="lang-level-tabs"
        >
          <b-tab
            v-for="(level, index) in levels"
            :key="index + level"
            :title="level"
            :active="activeTabIndex === index"
            @click="tabChange(index)"
          >
            <!-- Question -->
            <b-form-group
              v-if="questionType === 'text'"
              :label="$t('labels.create-problem.question')"
              label-for="question"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="question"
                vid="question"
              >
                <b-form-input
                  id="question"
                  v-model="problem.questions.text[index]"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('labels.create-problem.placeholder.question')"
                  name="question"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Image -->
            <b-form-group
              v-else-if="questionType === 'image'"
              :label="$t('labels.create-problem.question')"
              :label-for="`question-${level}`"
            >
              <validation-provider
                v-slot="{ errors }"
                name="question"
                :vid="`question-image-${level}`"
              >
                <image-form
                  :url="problem.questions.imageUrl[index]"
                  @input="handleImage($event, 'questions', index)"
                  @fileTypeInvalid="setError([`question-image-${level}`], $event)"
                  @fileSizeInvalid="setError([`question-image-${level}`], $event)"
                />
                <input
                  v-model="problem.questions.imageUrl[index]"
                  hidden
                >
                <small class="text-danger">{{ errors[0] }}</small><br>
              </validation-provider>
            </b-form-group>
            <!-- Image -->
            <!-- Audio -->
            <b-form-group
              v-else-if="questionType === 'audio'"
              :label="$t('labels.create-problem.question')"
              label-for="audio"
            >
              <validation-provider
                v-slot="{ errors }"
                name="question"
                :vid="`questions-audio-${level}`"
              >
                <Recorder
                  :url="problem.questions.audioUrl[index]"
                  @input="setAudio($event, 'questions', index)"
                  @fileTypeInvalid="setError([`questions-audio-${level}`], $event)"
                  @fileSizeInvalid="setError([`questions-audio-${level}`], $event)"
                />
                <input
                  v-model="problem.questions.audioUrl[index]"
                  hidden
                >
                <small class="text-danger">{{ errors[0] }}</small><br>
              </validation-provider>
            </b-form-group>
            <!-- / Question -->

            <!-- Answer -->
            <b-form-group
              v-if="answerType === 'text'"
              :label="$t('labels.create-problem.answer')"
              label-for="question"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="question"
                vid="question"
              >
                <b-form-input
                  id="question"
                  v-model="problem.answers.text[index]"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('labels.create-problem.placeholder.question')"
                  name="question"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Image -->
            <b-form-group
              v-else-if="answerType === 'image'"
              :label="$t('labels.create-problem.answer')"
              :label-for="`question-${level}`"
            >
              <validation-provider
                v-slot="{ errors }"
                name="answer"
                :vid="`answers-image-${level}`"
              >
                <image-form
                  :url="problem.answers.imageUrl[index]"
                  @input="handleImage($event, 'answers', index)"
                  @fileTypeInvalid="setError([`answers-image-${level}`], $event)"
                  @fileSizeInvalid="setError([`answers-image-${level}`], $event)"
                />
                <input
                  v-model="problem.answers.imageUrl[index]"
                  hidden
                >
                <small class="text-danger">{{ errors[0] }}</small><br>
              </validation-provider>
            </b-form-group>
            <!-- Image -->
            <!-- Audio -->
            <b-form-group
              v-else-if="answerType === 'audio'"
              :label="$t('labels.create-problem.answer')"
              label-for="audio"
            >
              <validation-provider
                v-slot="{ errors }"
                name="answer"
                :vid="`answers-audio-${level}`"
              >
                <Recorder
                  :url="problem.answers.audioUrl[index]"
                  @input="setAudio($event, 'answers', index)"
                  @fileTypeInvalid="setError([`answers-audio-${level}`], $event)"
                  @fileSizeInvalid="setError([`answers-audio-${level}`], $event)"
                />
                <input
                  v-model="problem.answers.audioUrl[index]"
                  hidden
                >
                <small class="text-danger">{{ errors[0] }}</small><br>
              </validation-provider>
            </b-form-group>
            <!-- / Answer -->
          </b-tab>
        </b-tabs>
      </validation-observer>
    </b-col>
    <template #modal-footer>
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="isProcessing || !isValid"
            @click="addQuestion"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t("confirm") }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BSpinner,
  BFormGroup,
  BButton,
  BFormInput,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import Recorder from '../../components/recorder.vue'
import ImageForm from '../../components/ImageForm.vue'

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    BFormGroup,
    BButton,
    BFormInput,
    BTabs,
    BTab,
    Recorder,
    ImageForm,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    toggle: {
      type: Boolean,
      default: () => false,
    },
    questionType: {
      type: String,
      default: () => '',
    },
    answerType: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    activeTabIndex: 3,
    isProcessing: false,
    levels: ['A1', 'A2', 'B1', 'B2'],
    problem: {
      questions: {
        text: ['', '', '', ''],
        image: ['', '', '', ''],
        imageUrl: ['', '', '', ''],
        audio: ['', '', '', ''],
        audioUrl: ['', '', '', ''],
      },
      answers: {
        text: ['', '', '', ''],
        image: ['', '', '', ''],
        imageUrl: ['', '', '', ''],
        audio: ['', '', '', ''],
        audioUrl: ['', '', '', ''],
      },
    },
    isValid: false,
  }),
  watch: {
    toggle(value) {
      if (value === true) {
        this.$refs['modal-add-question'].show()
      } else {
        this.$refs['modal-add-question'].hide()
      }
    },
    problem: {
      deep: true,
      handler() {
        this.validateForm()
      },
    },
  },
  methods: {
    tabChange(index) {
      const indices = [0, 1, 2, 3]
      indices.forEach(i => {
        if (i !== indices) {
          const questions = this.problem.questions[this.questionType]
          if (!questions[i]) {
            questions[i] = questions[this.activeTabIndex]
            if (this.questionType === 'image') {
              this.problem.questions.imageUrl[i] = this.problem.questions.imageUrl[this.activeTabIndex]
            } else if (this.questionType === 'audio') {
              this.problem.questions.audioUrl[i] = this.problem.questions.audioUrl[this.activeTabIndex]
            }
          }
          const answers = this.problem.answers[this.answerType]
          if (!answers[i]) {
            answers[i] = answers[this.activeTabIndex]
            if (this.answerType === 'image') {
              this.problem.answers.imageUrl[i] = this.problem.answers.imageUrl[this.activeTabIndex]
            } else if (this.answerType === 'audio') {
              this.problem.answers.audioUrl[i] = this.problem.answers.audioUrl[this.activeTabIndex]
            }
          }
        }
      })
      this.validateForm()
      this.activeTabIndex = index
    },
    handleImage({ image, url }, type, i) {
      this.problem[type].image[i] = image
      const imgList = this.problem[type].imageUrl
      imgList[i] = url
      this.problem[type].imageUrl = [...imgList]
    },
    setAudio({ audio, url }, type, i) {
      this.problem[type].audio[i] = audio
      const audioList = this.problem[type].audioUrl
      audioList[i] = url
      this.problem[type].audioUrl = [...audioList]
      this.validateForm()
    },
    validateForm() {
      let valid = true
      this.problem.questions[this.questionType].forEach(q => {
        if (!q) valid = false
      })
      this.problem.answers[this.answerType].forEach(a => {
        if (!a) valid = false
      })
      this.isValid = valid
    },
    addQuestion() {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
      this.problem.questions[this.questionType].forEach((q, i) => {
        const quesData = new FormData()
        quesData.append('lang_level', `${i + 1}`)
        quesData.append('problem_id', this.$route.query.problem_id)
        quesData.append('ques_type', this.questionType)
        quesData.append('text', q)

        useJwt.addQuestion(quesData, config).then(res => {
          const reqs = []
          const ansData = new FormData()
          ansData.append('lang_level', `${i + 1}`)
          ansData.append('problem_id', this.$route.query.problem_id)
          ansData.append('question_id', res.data.data.id)
          ansData.append('ans_type', this.answerType)
          ansData.append('text', this.problem.answers[this.answerType][i])
          ansData.append('is_correct', '1')
          reqs.push(useJwt.addAnswer(ansData, config))

          Promise.all(reqs)
            .then(response => {
              if (response.length && i === 3) {
                this.showSuccessMessage(res)
                Object.keys(this.problem.questions).forEach(k => {
                  this.problem.questions[k] = ['', '', '', '']
                })
                Object.keys(this.problem.answers).forEach(k => {
                  this.problem.answers[k] = ['', '', '', '']
                })
              }
            })
            .catch(error => {
              this.showErrorMessage(error)
            })
            .finally(() => {
              if (i === 3) {
                this.$emit('close')
                this.$emit('fetch')
              }
            })
        })
      })
    },
    setError(vid, msg) {
      this.$refs.addQuestionsForm.setErrors({ [vid]: [msg] })
    },
  },
}
</script>
