<template>
  <div>
    <b-breadcrumb
      :items="breadItems"
    />
    <payment-filter :form="form"
                    @onChange="getUserPayments"
    />
    <b-overlay :show="isProcessing"
               class="mt-2"
    >
      <b-card no-body>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>{{ $t('student') }}</th>
              <th>{{ $t('amount') }}</th>
              <th>{{ $t('pay-at') }}</th>
              <th>{{ $t('remarks') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="payment,index of userPayments"
                :key="index"
            >
              <td>
                {{ payment.user?.firstname }}  {{ payment.user?.lastname }}
              </td>
              <td>
                {{ payment.currency }} {{ payment.amount }}
              </td>
              <td>
                {{ FORMAT_DATE(payment.created_at, 'MMM Do YYYY') }}
              </td>
              <td>
                {{ payment.type }}
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import { BOverlay, BCard, BBreadcrumb } from 'bootstrap-vue'
import PaymentFilter from './payment-filter.vue'

export default {
  components: {
    BOverlay,
    BCard,
    BBreadcrumb,
    PaymentFilter,
  },
  data() {
    return {
      userPayments: [],
      isProcessing: false,
      breadItems: [
        {
          text: 'Reservation',
        },
        {
          text: 'User Payments',
        },
      ],
      form: {
        start_date: null,
        end_date: null,
        student_name: null,
        school_id: null,
      },
    }
  },
  created() {
    this.getUserPayments()
  },
  methods: {
    getUserPayments() {
      this.isProcessing = true
      useJwt.getUserPayments({
        params: {
          ...this.form,
        },
      }).then(response => {
        this.userPayments = response.data.data.user_payment
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
