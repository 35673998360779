<template>
  <div>
    <div
      id="template-table"
      class="table-responsive-sm mt-2"
    >
      <table
        aria-busy="false"
        aria-colcount="4"
        class="table b-table"
        role="table"
      >
        <thead role="rowgroup">
          <tr role="row">
            <th
              v-for="col in data[0].columns"
              :key="col"
            >
              <div v-text-to-speech>
                {{ col }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody role="rowgroup">
          <tr
            v-for="(row,index) in data[0].rows"
            :key="index"
            role="row"
          >
            <td
              v-for="(d,i) in row"
              :key="i"
              v-text-to-speech
              aria-colindex="1"
              role="cell"
            >
              {{ d }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style>

</style>
