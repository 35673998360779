<template>
  <b-col
    col="4"
    class="test-list-height"
  >
    <b-card
      class="mb-0 p-2"
      no-body
    >
      <div v-if="classTestLists.length > 0">
        <h5 class="font-weight-bold mb-2">
          {{ $t("labels.test-groups-in") }}
        </h5>
        <div
          v-for="(test, index) of classTestLists"
          :key="test.id"
          class="group"
        >
          <b-card
            border-variant="secondary"
            no-body
            class="mb-0 p-2 mb-1"
            :class="{
              selected: test.id === selectedTest.id,
              'border-today' : getStartDateInfo(test).days == 0
            }"
            @click="$emit('selectGroup', test)"
          >
            <div>
              <div>
                <h4>
                  <span class="mx-2">{{ index + 1 }}</span> {{ test.name }}
                </h4>
              </div>
              <div
                :set="progressInfo = getProgressBarInfo(test)"
                class="ml-4"
              >
                {{ progressInfo.percentage }} %
                <b-progress
                  v-if="progressInfo.total > 0"
                  v-b-tooltip.hover.v-warning
                  :max="progressInfo.total"
                  :class="[`progress-bar-${progressInfo.variant}`]"
                  style="margin-bottom: 5px;"
                >
                  <b-progress-bar
                    :value="progressInfo.completed"
                    :variant="progressInfo.variant"
                  />
                </b-progress>
              </div>
              <div class="ml-4 mt-1">
                <span
                  v-b-tooltip.hover.v-primary
                  class="mr-1 large"
                  variant="primary"
                  title="Lessons"
                >
                  <feather-icon
                    icon="BookOpenIcon"
                    size="18"
                  /> <span class="-mt-1">X {{ test.lessons.length }}</span>
                </span>
                <span
                  v-b-tooltip.hover.v-primary
                  class="mr-1 large"
                  variant="primary"
                  title="Students"
                >
                  <feather-icon
                    icon="UsersIcon"
                    size="18"
                  /> X {{ test.students.length }}
                </span>
                <span
                  v-if="test.event"
                  v-b-tooltip.hover.v-primary
                  class="mr-1 large"
                  title="Test Date"
                  :class="{
                    'text-success': getStartDateInfo(test).days == 0
                  }"
                >
                  <feather-icon
                    icon="CalendarIcon"
                    size="18"
                    :variant="{
                      'success': getStartDateInfo(test).days == 0
                    }"
                  />
                  <span>
                    {{ getStartDateInfo(test).label }}
                  </span>
                </span>
              </div>
            </div>
          </b-card>
        </div>
      </div>
      <p v-else>
        {{ $t("labels.no-test") }}
      </p>
    </b-card>
  </b-col>
</template>
<script>
import {
  BCard, BCol, VBTooltip, BProgress, BProgressBar,
} from 'bootstrap-vue'
import { getLessonProgressColor } from '@/utils/colors'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BCol,
    BProgress,
    BProgressBar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    classTestLists: {
      type: Array,
      default: () => [],
    },
    selectedTest: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      self: getUserData(),
    }
  },
  computed: {
    isAStudent() {
      return this.self.usertype === 'student'
    },
  },
  methods: {
    getProgressBarInfo(testInfo) {
      const total = testInfo.lessons.length
      const completed = testInfo.lessons?.filter(lesson => lesson.loader?.completed_at).length
      const percentage = ((completed / total) * 100).toFixed(2)
      const variant = getLessonProgressColor(percentage)
      return {
        total,
        completed,
        percentage,
        variant,
      }
    },
    getStartDateInfo(test) {
      const days = this.DAYS_DIFF_FROM_NOW(test.event.start_time)
      let label = ''
      if (days === -1) {
        label = 'From tomorrow'
      } else if (days === 1) {
        label = 'From Yesterday'
      } else if (days === 0) {
        label = 'From Today'
      } else if (days > 0) {
        label = `Before ${days} Days`
      } else {
        label = `After ${Math.abs(days)} Days`
      }

      return {
        days: Math.abs(days),
        label,
        actualDays: days,
      }
    },
  },
}
</script>

<style scoped>
.group {
  cursor: pointer;
}
.selected {
  background-color: #d9d9d9;
}
.dark-layout .selected {
  background-color: #414e71;
}
.highlight {
  border: 2px solid #f39c12 !important;
}
.lesson-completed-badge {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
}
.ddf-switch {
  padding: 0 8px;
}
.border-today {
  border: 1.5px solid #29C76F !important;
}
.test-list-height {
  max-height: 90vh;
  overflow-y: scroll;
}
</style>
