<template>
  <div class="mt-2">
    <b-row>
      <b-col
        md="3"
        style="max-height: 100vh;overflow-y: scroll"
      >
        <slot
          v-if="isClassTest"
          :set-active-lesson="setActiveLesson"
          :set-active-course="setActiveCourse"
          :courses="courses"
          name="classTestList"
        />
        <b-overlay
          v-else
          :show="isProcessing"
        >
          <b-card
            v-for="(c,index) of courses"
            :key="index"
            class="mb-1"
            :class="{'border-card': c.id == activeCourse.id}"
          >
            <b-card-header
              v-b-toggle="`collapse_lesson-${c.id}`"
              class="p-0"
            >
              <b-row class="align-items-center">
                <b-col md="2">
                  <feather-icon
                    size="24"
                    class="mr-1"
                    icon="BookOpenIcon"
                  />
                </b-col>
                <b-col md="7">
                  <span
                    class="ml-25"
                  >
                    {{ c.name }}
                  </span>
                </b-col>
                <b-col md="3">
                  <b-badge
                    variant="primary"
                    class="badge ml-2 float-right text-white"
                    role="button"
                    @click.stop="setActiveCourse(c)"
                  >
                    {{ $t('actions.view') }}
                  </b-badge>
                </b-col>
              </b-row>
            </b-card-header>
            <b-collapse
              :id="`collapse_lesson-${c.id}`"
              class="ml-0"
            >
              <div v-for="lessonGroup,gid of groupByKey(c.selectedLessons, 'group_id')"
                   :key="gid"
              >
                <b-list-group
                  class="mt-2"
                >
                  <b-list-group-item :class="{'bg-primary text-white': selectedLessonGroup == gid }"
                                     role="button"
                                     class="lesson-group-header"
                                     @click="setActiveCourse(c, gid)"
                  >
                    <span class="mb-1 font-weight-bold">
                      {{ lessonGroup[0].group_name }}
                    </span>
                    <span class="float-right"
                          @click.stop="handleLessonGroupToggle(gid)"
                    >
                      <feather-icon :icon="!collapseGroupLesson.find(i => i == gid) ? 'MinusIcon' : 'PlusIcon'"
                                    size="20"
                      />
                    </span>
                  </b-list-group-item>
                  <div v-if="!collapseGroupLesson.find(i => i == gid)"
                       style="max-height: 50vh;overflow-y: scroll"
                  >

                    <b-list-group-item
                      v-for="lt of lessonGroup"
                      :key="lt.id"
                      class="p-1 cursor-pointer"
                      :class="{'bg-secondary': lt.id == activeLesson}"
                      @click="lt.id != activeLesson ? setActiveLesson(lt,c) : ''"
                    >
                      <b-row
                        class="align-items-center"
                      >
                        <b-col md="2">
                          <feather-icon
                            size="15"
                            class="mr-1"
                            :class="{'text-white': lt.id == activeLesson}"
                            icon="BookOpenIcon"
                          />
                        </b-col>
                        <b-col md="10">
                          <b-card-text
                            class="ml-25"
                            :class="{'text-white': lt.id == activeLesson}"
                          >
                            {{ lt.name }}
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </div>
                </b-list-group>
              </div>
            </b-collapse>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col
        md="9"
      >
        <div v-if="viewMode==='course'">
          <course-report-table
            v-if="activeCourse"
            :class-test-id="selectedTest"
            :room-id="parseInt($route.params.id, 10)"
            :active-course-info="activeCourse"
            :loader-type="loaderType"
            :active-course-id="parseInt(activeCourse.id, 10)"
            :lesson-ids="lessons"
            :student-id="studentIdToView"
            :lesson-type="lessonType"
            :lesson-group-id="selectedLessonGroup"
            @showSkillReport="showCourseReportSkill"
            @showStatReport="showStudentLessonStats"
            @updateStudentLesson="updateStudentLesson"
          />
        </div>
        <div v-else-if="viewMode==='lesson'">
          <lesson-report-table
            v-if="activeLesson"
            :class-test-id="selectedTest"
            :loader-type="loaderType"
            :room-id="parseInt($route.params.id, 10)"
            :active-course-id="parseInt(activeCourse.id, 10)"
            :lesson-ids="lessons"
            :lesson-name="activeLessonName"
            @showStatReport="showProblemFromLessonReport"
          />
        </div>
      </b-col>
      <div class="dialogs">
        <dialog-drag
          v-for="dlg in dialogs"
          :id="dlg.id"
          :key="dlg.id"
          :ref="dlg.id"
          :class="dlg.type"
          :options="dlg.options"
          :style="`z-index:${activeDialogs.indexOf(dlg.id)==-1 ? '100':100+(5-activeDialogs.indexOf(dlg.id)).toString()} !important`"
          @close="removeDialog"
          @drag-end="dialogDragEnd"
          @drag-start="selectDialog"
          @move="dialogDragEnd"
        >
          <h5
            slot="title"
            class="m-0 text-capitalize"
            @click="setActiveDialog(dlg.id)"
          >{{ dlg.name }}
          </h5>
          <student-lessons-report
            v-if="dlg.type === DIALOG_TYPE_STUDENT_LESSONS_REPORT"
            :index="Number(dlg.index)"
            :lessons="getStudentDataFromMemory(dlg.index)['lessons'] || []"
            :student-id="getStudentDataFromMemory(dlg.index)['student_id']"
            :is-processing="isProcessing"
            :dialog-name=" dlg.name "
            :lesson-type="lessonType"
            @showStat="showStudentLessonProblemsStat"
            @showLessonNotes="(id,sId)=>{viewingNotesOfLesson=id;activeStudentId=sId}"
            @showLessonEssay="(id,sId)=>{viewingEssayOfLesson=id;activeStudentId=sId}"
          />
          <student-problems-report
            v-else-if="dlg.type === DIALOG_TYPE_STUDENT_LESSON_REPORT"
            :index="dlg.index"
            :problem="getStudentDataFromMemory(dlg.parentIndex)['lessons'][dlg.index]['problems'] || {}"
            :is-processing="isProcessing"
            :dialog-name=" dlg.name "
            @showStat="showStudentLessonProblemsStat"
            @showPreview="showProblemPreview"
          />
          <student-efficiency
            v-else-if="dlg.type === DIALOG_TYPE_STUDENT_EFFICIENCY"
          />
        </dialog-drag>

        <lesson-notes-modal
          :show="viewingNotesOfLesson!=null"
          :lesson-id="viewingNotesOfLesson ? Number(viewingNotesOfLesson) : null "
          :student-id="activeStudentId"
          @close="viewingNotesOfLesson=null"
        />
        <lesson-essay-modal
          :show="viewingEssayOfLesson!=null && activeStudentId!=null"
          :lesson-id="viewingEssayOfLesson ? Number(viewingEssayOfLesson) : null"
          :student-id="activeStudentId"
          @close="()=>{viewingEssayOfLesson=null;activeStudentId=null}"
        />
      </div>
    </b-row>
    <question-preview
      :show="showPreview"
      :show-edit-mode="false"
      :show-hot-spot="isHotSpotPreview"
      :data="problemBeingPreviewed ? problemPreview[problemBeingPreviewed.problem_id] :{}"
      @close="showPreview=false"
    />
    <course-skill-report
      :class-room="roomId"
      :course-info="activeCourse"
      :student-id-to-append="skillChartOfStudentToAppend"
      :loader-info="selectedLoader"
      @resetStudentId="skillChartOfStudentToAppend = null"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  VBToggle,
  BOverlay,
  BCardText,
  BCollapse,
  BCardHeader,
  BListGroup,
  BListGroupItem,
  BBadge,
} from 'bootstrap-vue'
import DialogDrag from 'vue-dialog-drag'
import LessonNotesModal from '@views/student/note/LessonNotesModal.vue'
import useJwt from '@/auth/jwt/useJwt'
import StudentLessonsReport from '@/views/common/class/components/LessonsReport.vue'
import i18n from '@/libs/i18n'
import StudentProblemsReport from '@/views/common/class/components/ProblemsReport.vue'
import { getUserData } from '@/auth/utils'
import LessonEssayModal from '@/views/common/class/components/LessonEssayModal.vue'
import QuestionPreview from '@/views/common/components/QuestionPreview.vue'
import CourseReportTable from './CourseReportTable.vue'
import LessonReportTable from './LessonReportTable.vue'
import CourseSkillReport from './CourseSkillReport.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BOverlay,
    BCardText,
    BCollapse,
    BCardHeader,
    BListGroup,
    BListGroupItem,
    BBadge,
    CourseReportTable,
    LessonReportTable,
    StudentLessonsReport,
    DialogDrag,
    StudentProblemsReport,
    LessonEssayModal,
    LessonNotesModal,
    QuestionPreview,
    CourseSkillReport,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    isClassTest: {
      type: Boolean,
      default: false,
    },
    roomId: {
      type: Number,
      default: () => 0,
    },
    selectedTest: {
      type: Number,
      default: () => 0,
    },
    classTestLists: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    courses: [],
    lessons: [],
    activeCourse: null,
    isProcessing: false,
    activeLesson: null,
    viewMode: 'course',
    dialogs: [],
    activeDialogs: [],
    students: [],
    DIALOG_TYPE_STUDENT_LESSONS_REPORT: 'student-lessons-report',
    DIALOG_TYPE_STUDENT_EFFICIENCY: 'student-efficiency',
    DIALOG_TYPE_STUDENT_LESSON_REPORT: 'student-problems-report',
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
    problemBeingPreviewed: null,
    problemPreview: {},
    problemTemplates: {},
    viewingNotesOfLesson: null,
    viewingEssayOfLesson: null,
    self: getUserData(),
    activeStudentId: null,
    activeLessonName: null,
    showPreview: false,
    studentIdToView: null, // pass from route
    skillChartOfStudentToAppend: null,
    selectedLoader: null,
    isHotSpotPreview: false,
    lessonType: 'normal',
    selectedLessonGroup: null,
    collapseGroupLesson: [],
  }),
  computed: {
    loaderType() {
      return this.isClassTest ? 'class_test' : 'homework'
    },
  },
  watch: {
    activeCourse(value) {
      if (value) {
        this.$store.dispatch('app/getLanguageLevelByCourse', {
          course_id: value.id,
        })
      }
    },
  },
  created() {
    if (this.self.usertype === 'student') {
      this.studentIdToView = this.self.id
    } else if (this.self.usertype === 'parent') {
      this.studentIdToView = this.self.children_ids ?? []
    } else {
      this.activeStudentId = this.$route.query.user_id ? Number(this.$route.query.user_id) : null
    }
    if (!this.isClassTest) {
      this.getCourseResult(this.roomId)
    } else {
      this.setupClassTestList()
    }
  },
  beforeDestroy() {
    this.$store.commit('app/SET_LANGUAGE_LEVEL_INDICATOR')
  },
  methods: {
    handleLessonGroupToggle(gid) {
      // eslint-disable-next-line no-param-reassign
      gid = Number(gid)
      if (this.collapseGroupLesson.find(i => i === gid)) {
        this.collapseGroupLesson = this.collapseGroupLesson.filter(i => i !== gid)
      } else {
        this.collapseGroupLesson.push(gid)
      }
      this.$forceUpdate()
    },
    getLessonTags() {
      useJwt.getLessonsTags(this.lessons[0])
        .then(response => {
          const tags = response.data.data
          const array = tags.split(',').map(item => item.trim().toLowerCase())
          if (array.includes('scorm')) {
            this.lessonType = 'scorm'
          } else {
            this.lessonType = 'normal'
          }
        }).catch(error => {
          console.log(error)
        })
    },
    setupClassTestList() {
      const courses = []
      this.classTestLists.forEach(item => {
        courses.push({
          id: item.id,
          name: item.name,
          selectedLessons: item.lessons,
          _rowVariant: 'secondary',
          __typename: 'Course',
        })
      })
      this.courses = courses
      if (!this.activeCourse && this.courses.length) this.setActiveCourse(this.courses[0])
    },
    getCourseResult(roomId) {
      this.isProcessing = true
      useJwt.getCourseListForReport(roomId).then(response => {
        const courses = response.data.map(item => ({
          id: item.course_id,
          name: item.course_name,
          selectedLessons: item.lessons,
        }))
        this.courses = courses
        if (!this.activeCourse && this.courses.length) this.setActiveCourse(this.courses[0])
      }).finally(() => {
        this.isProcessing = false
      })
    },
    showProblemPreview(prob) {
      this.problemBeingPreviewed = prob
      this.getProblemForPreview()
    },
    getTabIndex(level) {
      const levels = ['A1', 'A2', 'B1', 'B2']
      return levels.indexOf(level)
    },
    getTemplates(id) {
      if (!id) return
      if (this.problemTemplates[id]) return
      // eslint-disable-next-line consistent-return
      return new Promise(resolve => {
        useJwt.getTemplate(id).then(res => {
          this.problemTemplates[id] = res.data.data
          resolve('')
        })
      })
    },
    async getProblemForPreview() {
      // if (this.problemPreview[this.problemBeingPreviewed.problem_id]) {
      //   this.showPreview = true
      // } else {
      useJwt.getProblem(this.problemBeingPreviewed.problem_id).then(async res => {
        const problemData = res.data.data
        const index = this.getTabIndex(this.problemBeingPreviewed.lang_level)
        this.$store.commit('problem/UPDATE_ACTIVE_PROBLEM', problemData)
        this.isHotSpotPreview = !!problemData?.hotspot_point?.hotspot?.type
        const statementAndFeedback = problemData.problem_statements[index] || problemData.problem_statements[0]
        await this.getTemplates(statementAndFeedback.fb_template_id)
        await this.getTemplates(statementAndFeedback.st_template_id)
        const answers = await useJwt.getAnswerPreview(this.problemBeingPreviewed.test_id)
        // show the last attempted language level
        // index = this.problemBeingPreviewed.attempt_data?.length > 0 ? this.problemBeingPreviewed.attempt_data[this.problemBeingPreviewed.attempt_data.length - 1].lang_level - 1 : index
        this.problemPreview[this.problemBeingPreviewed.problem_id] = {
          theme: problemData.theme ? problemData.theme : 0,
          problemType: problemData.problem_type.name,
          testId: this.problemBeingPreviewed?.test_id,
          feedback: {
            type: statementAndFeedback.fb_type,
            text: statementAndFeedback.feedback,
            image: statementAndFeedback.fb_type === 'image' ? statementAndFeedback.fb_image : null,
            audio: statementAndFeedback.fb_type === 'audio' ? statementAndFeedback.fb_image : null,
            template: this.problemTemplates[statementAndFeedback.fb_template_id],
          },
          hotspot: problemData.hotspot_point,
          statement: {
            type: statementAndFeedback.st_type,
            text: statementAndFeedback.statement,
            image: statementAndFeedback.st_type === 'image' ? statementAndFeedback.st_image : null,
            audio: statementAndFeedback.st_type === 'audio' ? statementAndFeedback.st_image : null,
            template: this.problemTemplates[statementAndFeedback.st_template_id],
            video: problemData.video_time_stamp,
          },
          // eslint-disable-next-line eqeqeq
          questions: problemData.questions.length > 1 ? problemData.questions.filter(e => e.lang_level == (index + 1).toString()) : problemData.questions,
          answers: answers.data?.data,
          level: index + 1,
        }
        this.showPreview = true
      })
      // }
    },
    setActiveCourse(item, selectedLessonGroup = null) {
      if (this.selectedTest) {
        // eslint-disable-next-line no-param-reassign
        item = { ...item, actualCourseId: item?.selectedLessons?.[0]?.lesson_group?.course_id }
      }
      console.log(item)
      this.viewMode = 'course'
      this.activeCourse = item
      this.selectedLessonGroup = selectedLessonGroup
      const lessons = []
      item.selectedLessons.forEach(lesson => {
        // eslint-disable-next-line eqeqeq
        if (!selectedLessonGroup || lesson.group_id == selectedLessonGroup) {
          lessons.push(parseInt(lesson.id, 10))
        }
      })
      if (selectedLessonGroup) {
        // eslint-disable-next-line no-param-reassign, eqeqeq
        item.groupName = item.selectedLessons.filter(i => i.group_id == selectedLessonGroup)[0]?.group_name
      } else {
        // eslint-disable-next-line no-param-reassign
        item.groupName = null
      }
      this.lessons = [...lessons]
      this.courses.forEach((c, i) => {
        // eslint-disable-next-line dot-notation
        if (c.id === item.id) this.courses[i]['_rowVariant'] = 'secondary'
        // eslint-disable-next-line dot-notation
        else this.courses[i]['_rowVariant'] = ''
      })
      this.getLessonTags()
      this.activeLesson = null
    },
    findDialog(id, dialogs) {
      // eslint-disable-next-line no-param-reassign
      if (!dialogs) dialogs = this.dialogs
      const index = dialogs.findIndex(val => val.id === id)
      return (index > -1) ? Number(index) : null
    },
    dialogDragEnd(obj) {
      const index = this.findDialog(obj.id)
      this.$set(this.dialogs[index].options, 'left', obj.left)
      this.$set(this.dialogs[index].options, 'top', obj.top)
    },
    selectDialog(obj) {
      const index = this.findDialog(obj.id)
      this.setActiveDialog(this.dialogs[index].id)
    },
    setActiveLesson(item, course) {
      this.lessons = [Number(item.id)]
      this.activeLesson = item.id
      this.activeCourse = course
      this.activeLessonName = item.name
      this.viewMode = 'lesson'
      this.selectedLessonGroup = null
      this.getLessonTags()
    },
    async getStudentStats(student) {
      const studentId = Number(student.id)
      const restApiData = await useJwt.getCalibratedCourseUserReport(this.roomId, this.activeCourse.id, studentId, {
        params: {
          loaderType: this.loaderType,
          classTestId: this.selectedTest > 0 ? this.selectedTest : null,
        },
      })
      const response = restApiData.data.data.data.map(lesson => ({
        attempt: lesson.round,
        lesson: lesson.lesson_name,
        lang_level: lesson.lang_level,
        cefr_rate: lesson.cefr_rate,
        cefrRate: lesson.cefr_rate,
        lesson_id: lesson.lesson_id,
        obtainedPercent: Number(lesson.pass_score),
        points: lesson.points,
        round: lesson.round,
        score_percent: Number(lesson.pass_score),
        status: lesson.status,
        time_spend: lesson.time_spent,
        time_spent: lesson.time_spent,
        totalFail: lesson.total_failed_problems || 0,
        totalPass: lesson.total_passed_problems || 0,
        completed_at: lesson.completed_at,
        loader_id: lesson.loader_id,
        max_score: lesson.max_score || null,
        // completed_at: lesson.
      }))
      // const graphQlData = await useApollo.getStudentStat({
      //   user_id: studentId,
      //   group_id: this.roomId,
      //   course_id: Number(this.activeCourse.id),
      // })
      // const response = graphQlData.data.loaders.data.map(lesson => ({
      //   ...lesson,
      //   lesson: lesson.lesson.name,
      //   lesson_id: lesson.lesson.id,
      //   score_percent: lesson.obtainedPercent,

      // }))
      this.students = [
        ...this.students,
        {
          lessons: response,
          student_id: studentId,
          name: student.fullname,
          course_id: Number(this.activeCourse.id),
        },
      ]
      this.isProcessing = false
    },
    setActiveDialog(e) {
      this.activeDialogs.unshift(e)
      if (this.activeDialogs.length > 5) this.activeDialogs.length = 5
    },
    removeDialog(dialog) {
      const { id } = dialog
      const index = this.findDialog(id)
      this.dialogs.splice(index, 1)
      if (this.activeDialogs[0] === id) this.activeDialogs.shift()
    },
    getStudentDataFromMemory(studentId) {
      return this.students.find(s => s.student_id === Number(studentId) && s.course_id === Number(this.activeCourse.id)) || {}
    },
    async showStudentLessonStats(studentObj) {
      const index = studentObj.id
      let student = this.getStudentDataFromMemory(index)
      if (!student.lessons) {
        await this.getStudentStats(studentObj)
      }
      student = this.getStudentDataFromMemory(index)
      const id = `student-lessons-report-${index}`
      if (this.dialogs.findIndex(e => e.id === id) === -1) {
        this.dialogs.push(this.dialog(
          {
            id,
            index,
            name: student.name,
            type: this.DIALOG_TYPE_STUDENT_LESSONS_REPORT,
          },
        ))
      }
      this.setActiveDialog(id)
      return this.dialogs
    },
    async showProblemFromLessonReport(studentObj, lessonId) {
      const index = studentObj.id
      const student = this.getStudentDataFromMemory(index) || {}
      if (!('lessons' in student)) {
        await this.getStudentStats(studentObj)
      }
      const lessonIndex = this.getStudentDataFromMemory(index).lessons.findIndex(l => Number(l.lesson_id) === Number(lessonId))
      this.showStudentLessonProblemsStat(index, lessonIndex)
    },
    showStudentLessonProblemsStat(studentIndex, lessonIndex) {
      const student = this.getStudentDataFromMemory(studentIndex)
      const lesson = student.lessons[lessonIndex] || {}
      if (!('problems' in lesson)) {
        this.getStudentLessonStats(studentIndex, lessonIndex)
      }
      const id = `student-problems-report-${studentIndex}-${lessonIndex}`
      if (this.dialogs.findIndex(e => e.id === id) === -1) {
        this.dialogs.push(this.dialog(
          {
            id,
            index: Number(lessonIndex),
            parentIndex: studentIndex,
            name: `${student.name} ${lesson.lesson} ${i18n.tc('student-report-module.report')} `,
            type: this.DIALOG_TYPE_STUDENT_LESSON_REPORT,
          },
        ))
      }
      this.setActiveDialog(id)
      return this.dialogs
    },
    rndText() {
      return Math.random().toString(36).substring(7)
    },
    getStudentLessonStats(studentIndex, lessonIndex) {
      const student = this.getStudentDataFromMemory(studentIndex)
      student.id = student.student_id
      this.isProcessing = true
      this.$root.$off('reFetchStudentLessonStatReport')
      this.$root.$on('reFetchStudentLessonStatReport', () => {
        this.getStudentLessonStats(studentIndex, lessonIndex)
        this.getStudentStats(student)
      })
      useJwt.getStudentLessonStatsByLoader(student.lessons[lessonIndex].loader_id, {
        params: {
          class_test_id: this.selectedTest > 0 ? this.selectedTest : null,
        },
      }).then(res => {
        student.lessons[lessonIndex].problems = res.data.data
      }).finally(() => {
        this.isProcessing = false
      })
    },
    dialog(data) {
      const content = this.rndText()
      let options = {}
      const style = this
      options = { ...style.options }
      if (!options.left) options.left = 30 * this.dialogs.length
      if (!options.top) options.top = (this.windowHeight / 2) + 30 * this.dialogs.length
      return {
        ...data,
        ...{
          content, style, options,
        },
      }
    },
    updateStudentLesson(students) {
      const newStudentRec = students.map(student => {
        const lessons = student.loaders
        return {
          course_id: Number(this.activeCourse.id),
          lessons: lessons.map(lesson => ({
            ...lesson,
            cefrRate: lesson.cefr_rate,
            lang_level: lesson.cefr_level,
            attempt: lesson.round,
            lesson: lesson.lesson.name,
            lesson_id: lesson.lesson.id,
            time_spend: lesson.time_spent,
            score_percent: lesson.obtainedPercent,
          })),
          name: student.fullname,
          student_id: Number(student.id),
        }
      })
      this.students = [...this.students, ...newStudentRec]
    },
    showCourseReportSkill(loader) {
      this.skillChartOfStudentToAppend = Number(loader.id)
      this.selectedLoader = loader
    },
  },
}

</script>
<style scoped>
.border-card {
  border:2px solid black;
}
#question-preview-modal___BV_modal_outer_{
z-index: 1000000000 !important;
}
.main-menu{
  z-index: 500;
}
.lesson-group-header {
  position: sticky;
  top: 0;
}
</style>
<style src='vue-dialog-drag/dist/vue-dialog-drag.css'></style>
