<template>
  <div>
    <create-edit :mode="'create'" />
  </div>
</template>
<script>
import CreateEdit from './components/CreateEdit.vue'

export default {
  components: { CreateEdit },
}
</script>

<style>

</style>
