<template>
  <div class="card card-app-design">
    <div v-if="scheduleDetail"
         class="card-body"
    >
      <!-- <span class="badge badge-light-primary">
      </span> -->
      <h4 class="card-title mt-1 mb-75">
        {{ scheduleDetail?.schedule?.name }}
      </h4>
      <div class="design-group mb-1">
        <h6 class="section-label">
          {{ $t('teacher-schedule.schedules') }}
        </h6>
        <span v-for="time of scheduleDetail.schedule.schedule_times"
              :key="time.id"
              class="badge mr-1 badge-light-primary mb-1"
        >
          {{ time.day }} ({{ time.start_time }}- {{ time.end_time }})
        </span>
      </div>
      <div class="design-group mb-1">
        <h6 class="section-label">
          {{ $t('teacher-schedule.classroom') }}
        </h6>
        <a href="#"
           @click="viewClass"
        >
          {{ scheduleDetail?.class_room?.name }}
        </a>
      </div>
      <div class="design-group">
        <h6 class="section-label">
          {{ $t('teacher-schedule.students') }}
        </h6>
        <span
          v-for="user,ui of schedules"
          :key="ui"
          v-b-tooltip.hover
          class="b-avatar mr-1 badge-secondary rounded-circle"
          style="width: 34px; height: 34px"
          :title="user.student?.firstname + ' ' + user.student?.lastname"
        >
          <b-avatar v-if="user.student?.avatar"
                    size="sm"
                    :src="user.student?.avatar"
          />
          <b-avatar v-else
                    size="sm"
                    :text="user.student?.firstname[0]"
          />
        </span>
      </div>
      <div class="design-planning-wrapper">
        <div class="design-planning">
          <p class="card-text mb-25">
            {{ $t('teacher-schedule.students-limit') }}
          </p>
          <h6 class="mb-0">
            {{ scheduleDetail?.schedule.limit }}
          </h6>
        </div>
        <div class="design-planning">
          <p class="card-text mb-25">
            {{ $t('teacher-schedule.const') }}
          </p>
          <h6 class="mb-0">
            ${{ scheduleDetail?.schedule.cost }}
          </h6>
        </div>
      </div>
      <div v-if="scheduleDetail.teacher_schedule"
           class="apply-job-package bg-light-primary rounded p-2"
      >
        <div>
          <sup class="text-body">
            <small>{{ $t('teacher-schedule.reserved-by') }}</small>
          </sup>
          <h2 class="d-inline mr-25">
            {{ scheduleDetail.teacher_schedule?.teacher.firstname }}  {{ scheduleDetail.teacher_schedule?.teacher.lastname }}
          </h2>
          <b-button
            v-if="isSchoolUser"
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="showConfirmation = true"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </div>
      </div>
      <button v-else
              type="button"
              class="btn btn-primary btn-block"
              @click="showConfirmation = true"
      >
        <b-spinner v-if="isProcessing"
                   small
        /> {{ isSchoolUser ? $t('teacher-schedule.assign-teacher') : $t('teacher-schedule.reserve') }}
      </button>
    </div>
    <b-modal v-if="isSchoolUser"
             :title="$t('pick-teacher')"
             :visible="showConfirmation"
             :no-close-on-backdrop="true"
             @close="showConfirmation = false"
             @hide="showConfirmation = false"
    >
      <v-select
        v-model="selectedTeacher"
        :options="teachers"
        :reduce="a=> a.id"
        label="full_name"
        :placeholder="$t('send-notification-module.select-teacher')"
      />
      <template #modal-footer>
        <b-button variant="primary"
                  :disabled="isProcessing"
                  @click="onConfirm"
        >
          <b-spinner v-if="isProcessing"
                     small
          />  {{ $t('actions.update') }}
        </b-button>
      </template>
    </b-modal>
    <ConfirmModal v-else
                  :show="showConfirmation"
                  :on-delete="onConfirm"
                  :is-processing-delete="isProcessing"
                  :title="$t('teacher-schedule.confirm')"
                  varient="success"
                  :sub-title="$t('teacher-schedule.confirmation')"
                  @close="showConfirmation = false"
    />
  </div>
</template>
<script>
import ConfirmModal from '@/views/common/components/DeleteModal.vue'
import {
  BAvatar, BSpinner, VBTooltip, BModal, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'

export default {
  components: {
    BAvatar,
    ConfirmModal,
    BSpinner,
    BModal,
    BButton,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    schedules: {
      type: Array,
      required: true,
    },
    teachers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showConfirmation: false,
      isProcessing: false,
      auth: this.AUTH_USER(),
      selectedTeacher: this.schedules[0]?.teacher_schedule?.teacher?.id || null,
    }
  },
  computed: {
    scheduleDetail() {
      return this.schedules[0]
    },
    isSchoolUser() {
      return this.auth.usertype === 'school'
    },
  },
  methods: {
    viewClass() {
      this.$router.push({
        name: `${this.auth.usertype}-view-students`,
        params: { id: this.scheduleDetail?.class_room.id },
      })
    },
    onConfirm() {
      this.isProcessing = true
      useJwt.onConfirmTeacherReservation({
        student_reservations: this.schedules.map(item => item.id),
        teacher_id: this.selectedTeacher,
      }).then(response => {
        this.showSuccessMessage(response)
        this.showConfirmation = false
        this.$emit('reFetch')
      }).finally(() => {
        this.isProcessing = false
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
  },
}
</script>
