<template>
  <div>
    <excel-import />
  </div>
</template>
<script>
import ExcelImport from '@/views/super/import-data/index.vue'

export default {
  components: {
    ExcelImport,
  },
}
</script>
