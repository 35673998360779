<template>
  <div
    v-if="Object.keys(profileData).length"
    id="user-profile"
  >
    <profile-header
      :header-data="profileData.header"
      :is-viewing-own-profile="isViewingOwnProfile"
      @changeActive="filterArticle($event)"
      @addArticle="modals.isCreateShow = true"
      @grade="modals.isGradeShow = true"
    />
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <b-col md="6">
          <!-- articles -->
          <profile-article
            v-if="filteredArticle.length"
            :own-profile="isViewingOwnProfile"
            :posts="
              filteredArticle.slice(
                (pagination.currentPage - 1) * pagination.perPage,
                (pagination.currentPage) * pagination.perPage
              )
            "
            @editArticle="editArticle($event)"
            @deleteArticle="deleteArticle($event)"
            @changeFeatured="changeFeatured"
          />
          <div
            v-else-if="isProcessing"
          >
            <b-skeleton-wrapper>
              <b-card
                v-for="i in 3"
                :key="i"
              >
                <b-skeleton
                  width="85%"
                  height="100px"
                />
                <b-skeleton
                  width="50%"
                  height="100px"
                />
                <b-skeleton
                  width="70%"
                  height="50px"
                />
              </b-card>
            </b-skeleton-wrapper>
          </div>
          <div
            v-else
          >
            <b-card>{{ $t('messages.empty-article') }}</b-card>
          </div>
          <!--/ articles -->
        </b-col>

        <b-col md="6">
          <!-- Grades -->
          <profile-grade
            v-if="profileData.header.active === 'academic' && gradeRows.length"
            :is-add="modals.isGradeShow"
            :grade-data="gradeRows"
            class="mb-1"
          />
          <!--/ Grades -->

          <!-- Personality -->
          <user-personality
            v-if="personality"
            :student-personality="personality.personalities"
          />
          <!-- / Personality -->
          <!-- Personality Rank -->
          <b-card>
            <personality-rank
              v-if="personality"
              :student-personality="personality.personalities"
            />
          </b-card>

        </b-col>
      </b-row>
      <b-row>
        <!-- Pagination  -->
        <b-col
          cols="12"
          order="4"
        >
          <div
            v-if="filteredArticle.length > pagination.perPage"
            class="d-flex justify-content-center"
          >
            <b-pagination
              v-model="pagination.currentPage"
              hide-goto-end-buttons
              :disabled="isProcessing"
              :total-rows="pagination.rows"
              :per-page="pagination.perPage"
            />
          </div>
        </b-col>
        <!--/ load more  -->
      </b-row>
    </section>
    <!--/ profile info  -->

    <create-article
      v-if="isViewingOwnProfile"
      :toggle="modals.isCreateShow"
      @close="modals.isCreateShow = false"
      @created="fetchArticle"
    />
    <edit-article
      v-if="isViewingOwnProfile"
      :toggle="modals.isEditShow"
      :article-data="editArticleData"
      @close="modals.isEditShow = false"
      @updated="fetchArticle"
    />
    <delete-article
      v-if="isViewingOwnProfile"
      :toggle="modals.isDeleteShow"
      :article-data="deleteArticleData"
      @close="modals.isDeleteShow = false"
      @deleted="removeRow($event)"
    />

    <grade-modal
      v-if="isViewingOwnProfile"
      :toggle="modals.isGradeShow"
      :grade-columns="gradeColumns"
      :grade-rows="gradeRows"
      :subject-columns="subjectColumns"
      :active-grade-id="activeGradeId"
      @close="modals.isGradeShow = false"
      @created="addGradeRow($event)"
      @deleted="deleteGradeRow($event)"
      @edited="editGradeRow($event)"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BPagination,
  BSkeletonWrapper,
  BSkeleton,
  BCard,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import {
  USER_TYPE_STUDENT,
} from '@/const/userType'
import GEC_EVENT_BUS from '@/utils/eventBus'
import { getUserData } from '@/auth/utils'
import i18n from '@/libs/i18n'
import ProfileHeader from './ProfileHeader.vue'
import CreateArticle from './article/CreateArticleModal.vue'
import ProfileArticle from './article/Article.vue'
import EditArticle from './article/EditArticleModal.vue'
import DeleteArticle from './article/DeleteArticleModal.vue'
import ProfileGrade from './grade/ProfileGrade.vue'
import GradeModal from './grade/GradeModal.vue'
import UserPersonality from './UserPersonality.vue'
import PersonalityRank from './PersonalityRank.vue'

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    BPagination,
    BSkeletonWrapper,
    BSkeleton,
    BCard,
    ProfileHeader,
    ProfileArticle,
    CreateArticle,
    EditArticle,
    DeleteArticle,
    ProfileGrade,
    GradeModal,
    UserPersonality,
    PersonalityRank,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    testData: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isProcessing: false,
      activeGradeId: null,

      profileData: {},
      modals: {
        isCreateShow: false,
        isEditShow: false,
        isDeleteShow: false,
        isGradeShow: false,
      },
      allArticles: [],
      filteredArticle: [],
      editArticleData: {},
      deleteArticleData: {},
      gradeColumns: [
        {
          label: i18n.tc('grade'),
          field: 'name',
        },
        {
          label: i18n.tc('actions.action'),
          field: 'action',
          category: false,
          thClass: 'text-center',
        },
      ],
      gradeRows: [],
      subjectColumns: [
        {
          label: i18n.tc('subject'),
          field: 'name',
        },
        {
          label: i18n.tc('labels.score-achieved'),
          field: 'score',
        },
        {
          label: i18n.tc('actions.action'),
          field: 'action',
          category: false,
          thClass: 'text-center',
        },
      ],
      pagination: {
        perPage: 5,
        rows: 0,
        currentPage: 1,
      },
      self: getUserData(),
      allPersonalities: null,
      personality: null,
    }
  },
  computed: {
    isViewingOwnProfile() {
      return this.self !== null && this.self.id === this.userData.id
    },
    isAStudent() {
      return this.self.id === USER_TYPE_STUDENT
    },
  },
  beforeCreate() {
    useJwt.getSchoolAndDistrictSettings().then(response => {
      const { school, district } = response.data.data
      const schoolSetting = school?.find(metaValue => metaValue.key === 'nav_profile') ?? '0'
      const districtSetting = district?.find(metaValue => metaValue.key === 'nav_profile') ?? '0'
      if (districtSetting?.value === '1' || schoolSetting?.value === '1') {
        this.$router.push('not-authorized')
      }
    })
  },
  created() {
    const {
      id, fullname, usertype, avatar,
    } = this.userData
    this.profileData = {
      header: {
        id,
        avatar,
        username: fullname,
        designation: usertype,
        coverImg: require('@/assets/images/pages/study.jpg'),
        active: 'academic',
      },
    }
    this.fetchArticle()
    if (this.isViewingOwnProfile) {
      useJwt
        .getGrade()
        .then(res => {
          this.gradeRows = res.data.data.data.map(g => ({
            id: g.id,
            name: g.name,
          }))
          this.activeGradeId = this.gradeRows.length > 0 ? this.gradeRows[0].id : null
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
    }

    if (this.self) this.loadPersonalities()
    GEC_EVENT_BUS.$on('reloadStudentPersonality', () => {
      if (this.self) this.loadPersonalities()
    })
  },
  methods: {
    getStudentPersonality() {
      useJwt.getPersonality(this.userData.id).then(response => {
        this.personality = response.data?.data
      })
    },
    getPersonalities() {
      useJwt.getPersonalities().then(res => {
        this.allPersonalities = res.data?.data
      })
    },
    loadPersonalities() {
      if (!this.allPersonalities) this.getPersonalities()
      this.getStudentPersonality()
    },
    fetchArticle() {
      this.isProcessing = true
      useJwt
        .getArticle({ params: { sid: this.userData.id } })
        .then(res => {
          this.allArticles = res.data.data.map(a => ({
            id: a.id,
            img: a.image,
            title: a.title,
            description: a.description,
            isEssay: !!a.essay,
            category: a.article_category,
            updateAt: a.created_at,
            video: a.video,
            template: a.template,
            template_id: a.template_id,
            theme: a.theme_id,
            is_featured: a.is_featured === 1,
          }))
          this.filterArticle(this.profileData.header.active)
        }).catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    // methods for modal
    filterArticle(category) {
      this.pagination.currentPage = 1
      this.profileData.header.active = category
      this.filteredArticle = this.allArticles.filter(
        article => article.category === category,
      )
      this.pagination.rows = this.filteredArticle.length
    },
    editArticle(data) {
      this.editArticleData = { ...data }
      this.modals.isEditShow = true
    },
    deleteArticle(data) {
      this.deleteArticleData = { ...data }
      this.modals.isDeleteShow = true
    },
    changeFeatured(data) {
      useJwt.changeFeaturedArticle(data.id).then(res => {
        this.showSuccessMessage(res)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.fetchArticle()
      })
    },
    // modal end
    // methods for article data
    removeRow(id) {
      this.allArticles = this.allArticles.filter(row => row.id !== id)
      this.filterArticle(this.profileData.header.active)
    },
    // article data methods end
    // methods for grade data
    addGradeRow(data) {
      this.gradeRows.splice(0, 0, {
        id: data.id,
        name: data.name,
      })
      this.activeGradeId = this.gradeRows[0].id
    },
    deleteGradeRow({ id }) {
      this.gradeRows = this.gradeRows.filter(row => row.id !== id)
      this.activeGradeId = -1
    },
    editGradeRow(data) {
      const index = this.gradeRows.findIndex(row => row.id === data.id)
      this.gradeRows[index].id = data.id
      this.gradeRows[index].name = data.name
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-profile.scss";
</style>
