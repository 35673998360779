// import store from '@/store'
import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'

// setting with school override / district override
const getMetaSetting = (key, schoolSettings, districtSettings, schoolOverride, keyLabel) => {
  let meta = {}
  if (schoolOverride) {
    meta = schoolSettings?.find(s => s[keyLabel] === key)
    if (!meta) meta = districtSettings?.find(s => s[keyLabel] === key)
  } else {
    meta = districtSettings?.find(s => s[keyLabel] === key)
    if (!meta || meta.value === '1') meta = schoolSettings?.find(s => s[keyLabel] === key) || { key, value: '0' }
  }
  return meta
}

// for visibility settings
// returns 1 || true -> show
// returns 0 || false -> hide
export const isVisible = (key, schoolSettings, districtSettings) => {
  // let schoolSettings = store.getters['appConfig/getSchoolSettings']
  // let districtSettings = store.getters['appConfig/getDistrictSettings']
  const self = getUserData()

  const distUserType = districtSettings?.find(metaValue => metaValue.key === key)?.maps?.[0]?.user_type ?? []

  const schoolUserType = schoolSettings?.find(metaValue => metaValue.key === key)?.maps?.[0]?.user_type ?? []

  let userType = distUserType

  const distSetting = districtSettings?.find(metaValue => metaValue.key === key) ?? { value: '0' }
  // if shown by district, school have full control over user types
  if (distSetting.value === '0') userType = schoolUserType
  const schoolSetting = schoolSettings?.find(metaValue => metaValue.key === key) ?? { value: '0' }
  // has specific usertypes
  if (userType.length) {
    if (userType.includes(self.user_type_id)) {
      // user is included in userTypes
      if (distSetting?.value !== '1' && schoolSetting?.value !== '1') {
        return true
      }
      return false
    }
    // user is excluded in userTypes
    if (distSetting?.value !== '1' && schoolSetting?.value !== '1') {
      return false
    }
    return true
  }

  // no specific userTypes
  if ((!distSetting && !schoolSetting) || (distSetting?.value !== '1' && schoolSetting?.value !== '1')) {
    return true
  }
  return false
}

// for other settings
export const checkSettings = (setting, schoolSettings, districtSettings, { schoolOverride = false, keyLabel = 'key' } = {}) => {
  let settings = {}

  if (Array.isArray(setting)) {
    settings = {}
    setting.forEach(key => {
      const meta = getMetaSetting(key, schoolSettings, districtSettings, schoolOverride, keyLabel)
      settings[key] = meta?.value
    })
  } else {
    settings = getMetaSetting(setting, schoolSettings, districtSettings, schoolOverride, keyLabel)
  }

  return settings
}

// for classroom settings
export const getClassroomSettings = ({ districtOnly = false, getSchoolAndDistrict = false } = {}) => new Promise(resolve => {
  if (!getUserData()) {
    return
  }
  useJwt.getSchoolSettings().then(res => {
    const { school, district } = res.data.data
    const settings = district

    if (districtOnly) {
      resolve(settings)
      return
    }
    if (getSchoolAndDistrict) {
      resolve({ school, district })
      return
    }

    school.forEach(setting => {
      const distSetting = district.find(s => s.setting_name === setting.setting_name)
      if (!distSetting || Number(distSetting.value)) {
        settings.push(setting)
      }
    })
    resolve(settings)
  })
})

// check engagement settings
export const engagementSettings = (keys, generalSettings, districtSettings) => {
  // for array of keys
  if (Array.isArray(keys)) {
    const settings = []
    keys.forEach(key => {
      const districtSetting = districtSettings?.find(item => item.setting_name === key || item.key === key)
      if (Number(districtSetting?.value)) {
        const setting = generalSettings?.find(item => item.setting_name === key || item.key === key)
        settings.push(setting)
      } else settings.push({ setting_name: key, value: 0 })
    })
    return settings
  }

  // for single key
  let setting = { setting_name: keys, value: 0 }
  const districtSetting = districtSettings?.find(item => item.setting_name === keys || item.key === keys)?.value ?? 0
  if (districtSetting) {
    setting = generalSettings?.find(item => item.setting_name === keys || item.key === keys)
  }
  return setting
}
