<template>

  <b-card>
    <swiper ref="vueSwiper"
            class="swiper"
            :options="swiperOption"
    >
      <swiper-slide>
        <Encourage />
      </swiper-slide>
      <swiper-slide
        v-for="item of props.interventions"
        :key="item.id"
        ref="slider"
        class="score-swipe justify-content-center align-items-center"
      >
        <item-detail :vocab-data="item" />
      </swiper-slide>
      <swiper-slide>
        <StartAgain @continue="$emit('continue')" />
      </swiper-slide>
      <div slot="button-prev"
           class="swiper-button-prev"
      />
      <div slot="button-next"
           class="swiper-button-next"
      />
    </swiper>
  </b-card>
</template>
<script setup>
import { BCard } from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import ItemDetail from './item-detail.vue'
import Encourage from './encourage.vue'
import StartAgain from './start-again.vue'

import 'swiper/css/swiper.css'

const props = defineProps({
  interventions: {
    type: Array,
    required: true,
  },
})
const swiperOption = {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
}
</script>
<style lang="scss">

.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: 50%;
  width: 100px;
  height:  100px;
  margin-top: 10px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
  &:after {
    font-family: swiper-icons;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
  }
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  &:after {
    content: 'prev';
  }
  left: 10px;
  right: auto;
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  &:after {
    content: 'next';
  }
  right: 10px;
  left: auto;
}

</style>
