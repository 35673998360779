var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{staticClass:"m-0 text-selection",class:{'fill-row': !_vm.copyPasteEnabled}},[_c('b-col',{staticClass:"problem-card-title text-center",attrs:{"md":"12"}},[_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getProblemHintsDescription(_vm.$t("problem-types.text-selection")))+" ")])]),_c('b-col',{staticClass:"problem-overview-content",attrs:{"md":"12"}},[_c('validation-observer',{ref:"submitQuestionForm"},[_c('div',{staticClass:"ck-content"},[(_vm.fullHtml)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.fullHtml)},on:{"click":_vm.handleClick}}):_c('div',{staticClass:"ck-content"},[(_vm.template && _vm.template.before_template && _vm.template.before_template.length>0
              &&
              ![
                'Shapes',
                'SideCard',
              ].includes(_vm.getTemplateComponent)
              ||
              (_vm.getTemplateComponent === 'Shapes' && _vm.template.template[0].type !== 'fib')
            )?_c('div',{domProps:{"innerHTML":_vm._s(_vm.beforeHtml)},on:{"click":_vm.handleClick}}):_vm._e(),(_vm.template && _vm.template.template)?_c(_vm.getTemplateComponent,{key:"templateComponent",tag:"component",attrs:{"place-holder":_vm.placeHolder,"data":_vm.template.template,"is-a-problem":true,"question-formatter":_vm.generateQuestion,"template-options":_vm.getTemplateOptions},on:{"onClick":_vm.handleClick}},[(
                [
                  'Shapes',
                  'SideCard',
                ].includes(_vm.getTemplateComponent)
                  ||
                  (_vm.getTemplateComponent === 'Shapes' && _vm.template.template[0].type !== 'fib'))?_c('div',{domProps:{"innerHTML":_vm._s(_vm.beforeHtml)},on:{"click":_vm.handleClick}}):_vm._e()]):_vm._e(),(
              _vm.template &&
                _vm.template.after_template &&
                _vm.template.after_template.length > 0
            )?_c('div',{directives:[{name:"text-to-speech",rawName:"v-text-to-speech"}],domProps:{"innerHTML":_vm._s(_vm.afterHtml)}}):_vm._e()],1)])])],1),_c('portal',{attrs:{"to":"problemFeedBack"}},[_c('b-row',{staticClass:"m-0"},[_c('b-col',{staticClass:"p-0",attrs:{"md":"12"}},[_c('feedback',{attrs:{"feedback":_vm.feedback,"problem-statement-id":_vm.problemStatementId}})],1),(!_vm.isPreview)?_c('b-col',{staticClass:"m-0 p-0",attrs:{"md":"12"}},[_c('actions',_vm._g({attrs:{"has-feedback":_vm.hasFeedback,"is-processing":_vm.isProcessing,"level":_vm.level ? _vm.level : '',"has-minimal-view":_vm.hasMinimalView,"on-submit":_vm.submit,"tooltip-text":_vm.$t('student-test-module.fill-problem-validation-message'),"display-result":_vm.displayResult,"button-title":_vm.hasFeedback ? _vm.$t('next') : _vm.$t('submit'),"engine":_vm.engine,"school-settings":_vm.schoolSettings,"district-settings":_vm.districtSettings}},_vm.$listeners))],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }