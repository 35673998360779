<template>
  <div
    v-if="image"
    class="h-100"
  >
    <b-img
      :thumbnail="bordered"
      fluid
      :rounded="rounded"
      :src="image"
      :style="size!=null?`object-fit:cover;height:${size};min-width:${size};max-width:${size}`:''"
    />
  </div>
  <span
    v-else
    class="b-avatar badge-minimal badge-light-primary"
    :class="{'rounded-circle':rounded==='circle','rounded':rounded===true,'h-100':size==null,'w-100':size==null}"
    :style="size!=null?`min-width: ${size};max-width:${size}; height: ${size}`:''"
  >
    <svg
      v-if="defaultIcon==='user'"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      focusable="false"
      role="img"
      aria-label="person fill"
      aria-hidden="true"
      alt="avatar"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      class="bi-person-fill b-icon bi"
    >
      <g>
        <path
          fill-rule="evenodd"
          d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
        />
      </g>
    </svg>

    <svg
      v-if="defaultIcon==='network'"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-share-2"
    ><circle
      cx="18"
      cy="5"
      r="3"
    /><circle
      cx="6"
      cy="12"
      r="3"
    /><circle
      cx="18"
      cy="19"
      r="3"
    /><line
      x1="8.59"
      y1="13.51"
      x2="15.42"
      y2="17.49"
    /><line
      x1="15.41"
      y1="6.51"
      x2="8.59"
      y2="10.49"
    /></svg>

    <svg
      v-if="defaultIcon==='book'"
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="30px"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-book-open"
    >
      <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
      <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
    </svg>
  </span>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default ({
  components: {
    BImg,
  },

  props: {
    image: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: [Boolean, String],
      default: false,
    },
    defaultIcon: {
      type: String,
      default: 'user',
    },
  },
})
</script>
