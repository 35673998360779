<template>
  <div style="overflow: hidden"
       :style="{
         maxWidth: getParentTableWidth()
       }"
  >
    <swiper :options="swiperOption"
            class="w-100"
    >
      <swiper-slide v-for="lesson,index of lessons.filter(i => i.passed_problems > 0 || i.failed_problems > 0)"
                    :key="index"
      >
        <div class="h-100"
             :set="chart = getChartData(lesson)"
        >
          <h5> {{ lesson.lesson_name }}</h5>
          <apexchart
            :key="index"
            type="donut"
            height="400"
            width="300"
            :options="chart.chartOptions"
            :series="chart.series"
          />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import i18n from '@/libs/i18n'

export default {
  components: {
    apexchart: VueApexCharts,
    Swiper,
    SwiperSlide,
  },
  props: {
    lessons: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 10,
        slidesPerGroup: 5,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },
  methods: {
    getParentTableWidth() {
      const tableWidth = document.getElementById('individualStudentTable')?.offsetWidth
      if (tableWidth) {
        return `${tableWidth - 50}px`
      }
      return 'calc(100vw - 400px)'
    },
    getChartData(lesson) {
      return {
        chartOptions: {
          chart: {
            type: 'donut',
          },
          colors: ['#4CBB18', '#FF4460'],
          labels: [i18n.tc('individual-report-module.status.passed'), i18n.tc('individual-report-module.status.failed')],
        },
        series: [lesson?.passed_problems || 0, lesson?.failed_problems || 0],
      }
    },
  },

}
</script>
  <style>
    .summary-post {
      text-align: center;
      height: calc(100vh - 950px);
      margin-top: 60px;
      margin-bottom: 60px;
    }
  </style>
