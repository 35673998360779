<template>
  <b-card>
    <b-card-header
      class="course-img-wrapper d-flex justify-content-center"
    >
      <template v-if="getFileType == 'image'">
        <img
          v-image-preview
          :src="item.path"
          style="height: 160px"
          :alt="item.original_name"
          class="card-img-top img-fluid card-img"
        >
      </template>
      <template v-else-if="getFileType == 'audio'">
        <div style="height: 160px;padding: 48px;">
          <audio-player :key="item.path"
                        :src="item.path"
                        style="vertical-align: middle"
          />
        </div>
      </template>
      <template v-else>
        <a :href="item.path"
           :title="item.original_name"
           target="_blank"
        >
          <feather-icon icon="FileTextIcon"
                        size="160"
          />
        </a>
      </template>
    </b-card-header>
    <b-card-body class="p-0">
      <div class="item-wrapper">
        <div>
          <h6 class="item-price"
              style="word-break:break-all"
          >
            <validation-observer
              :ref="`editAssetForm${item.id}`"
            >
              <b-form
                @submit.prevent="submitEditComment"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('labels.name')"
                    label-cols="3"
                    class="asset-item"
                  >
                    <template v-if="editedFieldId === item.id">
                      <b-form-input
                        :ref="`field${item.id}`"
                        v-model="assetName"
                        debounce="800"
                        required
                        class="col-12 d-inline"
                        :disabled="isProcessing"
                        @change="updateAssetName"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </template>
                    <template v-else>
                      <span class="float-left"
                            style="font-size: 16px; margin-top: 7px; width:78%"
                      >
                        {{ item.original_name }}
                      </span>
                      <b-button v-b-tooltip.hover="$t('assets-module.edit-assets')"
                                class="btn-icon float-right"
                                variant="flat-success"
                                :disabled="isProcessing"
                                @click.prevent="toggleEdit(item.id)"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                    </template>
                  </b-form-group>
                </validation-provider>
              </b-form>
            </validation-observer>
          </h6>
        </div>
      </div>
      <b-button v-b-tooltip.hover="$t('assets-module.delete-assets')"
                class="btn-icon mr-2"
                variant="flat-danger"
                :disabled="isProcessing"
                @click.stop="$emit('onDeleteAsset', item)"
      >
        <feather-icon icon="TrashIcon" />
      </b-button>
      <b-button v-b-tooltip.hover="$t('assets-module.copy-path')"
                variant="flat-success"
                class="btn-icon copy-button mr-2"
                :disabled="isProcessing"
                @click="copyTextToClipboard(item.path)"
      >
        <feather-icon icon="CopyIcon" /> {{ $t('assets-module.path') }}
      </b-button>
      <b-button v-b-tooltip.hover="$t('assets-module.copy-id')"
                variant="flat-success"
                class="btn-icon copy-button"
                :disabled="isProcessing"
                @click="copyTextToClipboard(item.id)"
      >
        <feather-icon icon="CopyIcon" /> {{ $t('assets-module.id') }}
      </b-button>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BForm, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    AudioPlayer,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fileType: '',
      assetName: '',
      imageArray: ['png', 'jpg', 'jpeg', 'gif', 'bmp'],
      audioArray: ['mp3', 'wav', 'ogg', 'aac', 'mpeg'],
      isProcessing: false,
      editedFieldId: null,
    }
  },
  computed: {
    getFileType() {
      const file = this.item.path
      const parts = file.split('.')
      const ext = parts[parts.length - 1]
      if (this.imageArray.includes(ext.toLowerCase())) {
        return 'image'
      } if (this.audioArray.includes(ext.toLowerCase())) {
        return 'audio'
      }
      return 'file'
    },
  },
  created() {
    this.assetName = this.item.original_name
  },
  methods: {
    toggleEdit(id) {
      if (id) {
        this.editedFieldId = id
        this.$nextTick(() => {
          if (this.$refs[`field${id}`]) {
            this.$refs[`field${id}`][0].focus()
          }
        })
      } else {
        this.editedFieldId = null
      }
    },
    updateAssetName() {
      this.$refs[`editAssetForm${this.item.id}`].validate()
        .then(success => {
          if (success) {
            const params = {
              name: this.assetName,
            }
            this.isProcessing = true
            useJwt.updateAssets(this.item.id, params)
              .then(response => {
                this.showSuccessMessage(response)
                this.$emit('assetUpdate')
              }).catch(error => {
                this.showErrorMessage(error)
              }).finally(() => {
                this.isProcessing = false
                this.editedFieldId = null
              })
          }
        })
    },
  },
}
</script>
<style>
.card:hover {
  box-shadow: 0 8px 32px 0 rgba(28, 34, 112, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}
</style>
