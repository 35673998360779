<template>
  <validation-observer
    v-slot="{ invalid }"
    ref="createForm"
  >
    <b-row>
      <b-col
        md="12"
        class="mb-2"
      >
        <b-row>
          <b-col md="3">
            <b-card
              no-body
              class="p-2"
            >
              <b-row>
                <b-col md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="name"
                    rules="required"
                    vid="name"
                  >
                    <label for="">{{
                      $t("adaptive-learning-module.name")
                    }}</label>
                    <b-form-input
                      v-model="localAlgorithm.name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      @input="
                        $emit(
                          'algorithmDataUpdated',
                          'name',
                          localAlgorithm.name
                        )
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col
                  md="12"
                  class="mt-1"
                >
                  <label
                    class="d-inline-block"
                    for="total-steps"
                  >{{
                    $t("adaptive-learning-module.is_default")
                  }}</label>

                  <b-form-checkbox
                    v-model="localAlgorithm.is_default"
                    class="custom-control-primary pl-1 d-inline-block"
                    name="check-button"
                    switch
                    @change="
                      $emit(
                        'algorithmDataUpdated',
                        'is_default',
                        localAlgorithm.is_default
                      )
                    "
                  /></b-col>
                <b-col
                  md="12"
                  class="mt-1"
                >
                  <label
                    class="d-inline-block"
                    for="total-steps"
                  >{{
                    $t("adaptive-learning-module.is_active")
                  }}</label>

                  <b-form-checkbox
                    v-model="localAlgorithm.is_active"
                    class="custom-control-primary d-inline-block pl-1"
                    name="check-button"
                    switch
                    @change="
                      $emit(
                        'algorithmDataUpdated',
                        'is_active',
                        localAlgorithm.is_active
                      )
                    "
                  /></b-col>
                <b-col
                  md="12"
                  class="mt-1"
                >
                  <label for="">{{
                    $t("adaptive-learning-module.starting-level")
                  }}</label>
                  <b-form-select
                    v-model="localAlgorithm.start_level"
                    :options="[
                      { value: 1, text: 'A1' },
                      { value: 2, text: 'A2' },
                      { value: 3, text: 'B1' },
                      { value: 4, text: 'B2' },
                    ]"
                    :disabled="currentRunString.length % localTotalSteps > 0"
                    @change="
                      $emit('activeLevelChanged', localAlgorithm.start_level)
                    "
                  />
                </b-col>
                <b-col
                  md="12"
                  class="mt-1"
                >
                  <label for="total-steps">{{
                    $t("adaptive-learning-module.steps")
                  }}</label>

                  <b-form-select
                    v-model="localTotalSteps"
                    :options="[
                      { value: 1, text: '1' },
                      { value: 2, text: '2' },
                      { value: 3, text: '3' },
                      { value: 4, text: '4' },
                    ]"
                    :disabled="!isFirstProblem"
                    @change="$emit('totalStepsChanged', localTotalSteps)"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col md="9">
            <b-col
              md="12"
              class="mb-2 d-flex justify-content-end"
            >
              <b-button
                variant="outline-success"
                :disabled="isLastProblem"
                size="lg"
                @click="$emit('passedOrFailed', 'P')"
              >
                {{ $t("adaptive-learning-module.pass") }}
              </b-button>
              <b-button
                :disabled="isLastProblem"
                variant="outline-danger"
                class="ml-2"
                size="lg"
                @click="$emit('passedOrFailed', 'F')"
              >
                {{ $t("adaptive-learning-module.fail") }}
              </b-button>
              <b-button
                variant="outline-info"
                class="ml-2"
                size="lg"
                @click="$emit('reset')"
              >
                {{ $t("adaptive-learning-module.reset") }}
              </b-button>
              <b-button
                variant="success"
                class="ml-2"
                size="lg"
                :disabled="isProcessing || invalid"
                @click="$emit('save')"
              >
                {{
                  mode === "create"
                    ? $t("adaptive-learning-module.save")
                    : $t("adaptive-learning-module.update")
                }}
              </b-button>
            </b-col>
            <b-card no-body>
              <b-table
                responsive
                size="sm"
                :fields="columns"
                :items="Object.values(possibleSteps)"
              >
                <template #cell(level)="level">
                  <span>
                    <b-form-select
                      v-model="possibleSteps[level.item.type].level"
                      :options="['same', 'up', 'down']"
                      size="sm"
                    />
                  </span>
                </template>
                <template #cell(problem)="problem">
                  <span>
                    <b-form-select
                      v-model="possibleSteps[problem.item.type].problem"
                      :options="['same', 'next']"
                      size="sm"
                    />
                  </span>
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol, BButton, BFormInput, BFormSelect, BTable, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormSelect,
    BTable,
    BCard,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isLastProblem: {
      type: Boolean,
      required: true,
    },
    isFirstProblem: {
      type: Boolean,
      required: true,
    },
    isProcessing: {
      type: Boolean,
      required: true,
    },
    possibleSteps: {
      type: Object,
      required: true,
    },
    currentRunString: {
      type: String,
      required: true,
    },
    totalSteps: {
      type: Number,
      default: 1,
    },
    algorithm: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      columns: [
        { key: 'type', label: i18n.tc('adaptive-learning-module.actions-table.type') },
        { key: 'level', label: i18n.tc('adaptive-learning-module.actions-table.level') },
        { key: 'problem', label: i18n.tc('adaptive-learning-module.actions-table.problem') },
      ],
      passStreakRequired: 2,
      failStreakRequired: 2,
      startingLevel: 0,
      localTotalSteps: this.totalSteps,
      localAlgorithm: JSON.parse(JSON.stringify(this.algorithm)),
    }
  },

  watch: {
    totalSteps() {
      this.localTotalSteps = this.totalSteps
    },
  },

}
</script>

<style>
</style>
