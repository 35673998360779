/* eslint-disable import/no-cycle */
import { getUserData } from '@/auth/utils'

const state = {
  self: getUserData(),
  networkId: null,
  isProcessing: false,
  network: null,
  verifiedArticles: [],
  verifiedArticlesPaginationData: {
    currentPage: 1,
    perPage: 4,
    totalItems: 0,
    lastPage: 0,
  },
  unVerifiedArticlesPaginationData: {
    currentPage: 1,
    perPage: 4,
    totalItems: 0,
    lastPage: 0,
  },
  unVerifiedArticles: [],
  requestId: null,
  myNetworkMembership: 'guest',
  memberRequests: [],
  memberInvitations: [],
  members: null,
  coLeaders: [],
  accounts: [],
  invitations: {
    data: [],
    pagination: {
      current_page: 1,
    },
  },
  requests: {
    data: [],
    pagination: {
      current_page: 1,
    },
  },
  sponsers: [],
  charities: [],
  activeTab: 'academic',
}

export default state
