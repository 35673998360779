<template>
  <b-modal
    id="modal-1"
    ref="selectCharacterModal"
    title="Please select one Character"
    ok-only
    ok-title="Update"
    size="lg"
    @ok="updateCharacter"
    @close="$emit('selectCharacterModalClosed')"
    @hide="$emit('selectCharacterModalClosed')"
  >
    <b-card>
      <b-row>
        <b-col
          v-for="(character,key) in characters "
          :key="key"
          lg="3"
          sm="6"
          class="pt-2"
        >
          <div
            class="character-image pl-1 pt-1 mr-auto ml-auto"
            :class="{'selected-character':key===selectedCharacter}"
          >
            <b-form-radio
              v-model="selectedCharacter"
              name="selected-character"
              class="p-0"
              :disabled="isProcessing"
              :value="key"
            >
              <div
                class="character-card"
                :style="`background-image:url(${require('@/assets/images/interactive-avatars'+character)}; background-repeat:no-repeat;`"
              />
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-modal>

</template>

<script>
import {
  BCard, BRow, BCol, BFormRadio,
} from 'bootstrap-vue'
import { CHARACTERS } from '@/const/avatar'
import useJwt from '@/auth/jwt/useJwt'

export default {

  components: {
    BCard,
    BRow,
    BCol,
    BFormRadio,
  },
  props: {
    toggle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      characters: CHARACTERS,
      isProcessing: false,
      selectedCharacter: this.$store.getters['appConfig/currentCharacter'],
    }
  },
  watch: {
    toggle(value) {
      if (value === true) {
        this.showModal()
      } else {
        this.hideModal()
      }
    },
  },
  mounted() {

  },
  methods: {
    showModal() {
      this.$refs.selectCharacterModal.show()
    },
    hideModal() {
      this.$refs.selectCharacterModal.hide()
    },
    updateCharacter() {
      this.isProcessing = true
      useJwt.updateStudentCharacter({ character: this.selectedCharacter }).then(res => {
        if (res.data.success) {
          this.showSuccessMessage(res)
          this.$store.commit('appConfig/UPDATE_SELECTED_CHARACTER', res.data.data.character)
        }
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },

}
</script>
