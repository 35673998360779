<template>
  <div class="report-summary">
    <b-overlay :show="isProcessing">

      <TableStructure :skills="skills"
                      :file-name="fileName"
      >
        <template #classroom-filter>
          <div class="d-flex justify-content-between">
            <b-card v-if="isHighLevelUser"
                    body-class="p-0"
                    class="mr-2"
            >
              <v-select
                v-model="filterTeacher"
                :options="teachersForFilter"
                label="fullname"
                placeholder="Search Teacher"
                style="width: 450px;"
                :reduce="item => item.id"
                @input="handleTeacherChange"
              />
            </b-card>
            <b-card body-class="p-0">
              <v-select
                v-model="selectedRoom"
                :options="searchRooms"
                label="name"
                multiple
                placeholder="Search Classroom"
                style="width: 450px;"
                :reduce="item => item.id"
                @input="getOverallReport()"
              />
            </b-card>
          </div>
        </template>
        <template
          v-for="summary,index of overallSummaryData"
        >
          <summary-table
            :key="index"
            :overall-report="summary.summary"
            :class-room="summary.classroom"
            :skills="skills"
            :students="summary.students"
          />
        </template>
      </TableStructure>
    </b-overlay>
    <Pagination
      :pagination="pagination"
      :is-processing="isProcessing"
      @onPaginationChange="getOverallReport"
    />
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import { BOverlay, BCard } from 'bootstrap-vue'
import Pagination from '@/views/common/components/Pagination.vue'
import useApollo from '@/@core/graphql/useApollo'
import vSelect from 'vue-select'
import { isHighLevelUser } from '@/auth/utils'
import SummaryTable from './components/SummaryTable.vue'
import TableStructure from './components/TableStructure.vue'

export default {
  components: {
    SummaryTable,
    TableStructure,
    BOverlay,
    BCard,
    vSelect,
    Pagination,
  },
  data() {
    return {
      overallSummaryData: [],
      skills: ['reading', 'writing', 'listening', 'speaking'],
      isProcessing: false,
      searchRooms: [],
      selectedRoom: [],
      teachersForFilter: [],
      self: this.AUTH_USER(),
      filterTeacher: null,
      isHighLevelUser: isHighLevelUser(),
      pagination: {
        total: 0,
        per_page: 0,
        current_page: 1,
        last_page: 1,
      },
    }
  },
  computed: {
    fileName() {
      if (this.isHighLevelUser && this.filterTeacher) {
        // eslint-disable-next-line eqeqeq
        const teacher = this.teachersForFilter.find(item => item.id == this.filterTeacher)?.fullname
        return `${teacher}_report`
      }
      return 'overall_report'
    },
  },
  created() {
    if (this.isHighLevelUser) {
      this.getSchoolTeacher()
    } else {
      this.getOverallReport()
      this.searchClasses('')
    }
  },
  methods: {
    searchClasses(value) {
      const teacherId = this.filterTeacher || this.self.id
      useApollo
        .searchRooms(value, teacherId)
        .then(res => {
          this.searchRooms = res.data.searchRooms
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
    },
    getSchoolTeacher() {
      this.filterTeacher = null
      useApollo.getUsersOfSchool('teacher', this.school_id).then(response => {
        this.teachersForFilter = response.data?.users?.data
        this.filterTeacher = this.teachersForFilter[0]?.id
        this.handleTeacherChange()
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    handleTeacherChange() {
      this.searchClasses('')
      this.getOverallReport()
    },
    getOverallReport(page = 1) {
      this.isProcessing = true
      useJwt.getOverallClassroomReport({
        params: {
          page,
          classrooms: this.selectedRoom,
          teacher_id: this.filterTeacher,
        },
      }).then(response => {
        this.overallSummaryData = response.data.data.overall_summary
        const classRooms = response.data.data.classrooms
        this.pagination = {
          total: classRooms.total,
          per_page: classRooms.per_page,
          current_page: classRooms.current_page,
          last_page: classRooms.last_page,
        }
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
<style lang="scss">
  .report-summary {
    .academic {
      background-color: #f6f6f6 !important ;
    }
    .study-skill {
      background-color: #f6f6f6 !important;
    }
  }
</style>
