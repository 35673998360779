<template>
  <div>
    <b-card
      v-for="(test,index) of classTests"
      :key="index"
      class="mb-1"
      :class="{'border-card': test.id == selectedTest}"
    >
      <b-card-header
        class="p-0"
      >
        <div class="d-flex align-items-center">
          <feather-icon
            size="24"
            class="mr-1"
            icon="BookOpenIcon"
          />
          <span
            v-b-toggle="`collapse_lesson-${test.id}`"
            class="ml-25"
          >
            {{ test.name }}
          </span>
          <b-badge
            variant="primary"
            class="badge ml-2 float-right"
            role="button"
            @click="$emit('updateSelectedTest', test)"
          >
            {{ $t('actions.view') }}
          </b-badge>
        </div>
      </b-card-header>
      <b-collapse
        :id="`collapse_lesson-${test.id}`"
        class="ml-0"
      >
        <b-list-group
          class="mt-2"
          style="max-height: 50vh;overflow-y: scroll"
        >
          <b-list-group-item
            v-for="lt of test.lessons"
            :key="lt.id"
            class="p-1 cursor-pointer"
            :class="{'bg-secondary text-white': lt.id == selectedLesson}"
            @click="$emit('updateLesson', lt, test)"
          >
            <div class="d-flex align-items-center">
              <feather-icon
                size="15"
                class="mr-1"
                icon="BookOpenIcon"
              />
              <b-card-text class="ml-25">
                {{ lt.name }}
              </b-card-text>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCardHeader, BListGroup, BListGroupItem, BCardText, BCollapse, BBadge, VBToggle,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BCardHeader, BListGroup, BListGroupItem, BCardText, BCollapse, BBadge,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    classTests: {
      type: Array,
      default: () => [],
    },
    selectedLesson: {
      type: [Object, Number],
      default: () => null,
    },
    selectedTest: {
      type: [Object, Number],
      default: () => null,
    },
  },
  data() {
    return {
      activeCourse: {},
    }
  },
}
</script>
<style scoped>
.border-card {
    border:2px solid black;
  }
</style>
