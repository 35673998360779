<template>
  <b-overlay :show="isProcessing">
    <class-test-report
      v-if="selectedTest"
      :is-class-test="true"
      :class-test-lists="classTests"
      :selected-test="selectedTest"
      :room-id="parseInt($route.params.id, 10)"
    >
      <template #classTestList="{ setActiveLesson, setActiveCourse }">
        <test-list
          :class-tests="classTests"
          :selected-test="selectedTest"
          :selected-lesson="selectedLesson"
          @updateLesson="(lesson, test) => { selectedLesson = lesson.id; setActiveLesson(lesson, test);selectedTest =test.id }"
          @updateSelectedTest="(test) => { selectedTest = test.id; selectedLesson = null, setActiveCourse(test)}"
        />
      </template>
    </class-test-report>
    <b-card v-else>
      <h4>{{ $t('report.no-report') }}</h4>
    </b-card>
  </b-overlay>
</template>
<script>
import { BOverlay, BCard } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import TestList from './TestLists.vue'
import ClassTestReport from '../report/CourseReport.vue'

export default {
  components: {
    TestList,
    BOverlay,
    BCard,
    ClassTestReport,
  },
  props: {
    classRoom: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      classTests: [],
      reports: [],
      selectedTest: null,
      selectedLesson: null,
      isProcessing: false,
      studentLessonReport: [],
      selectedCourseInfo: null,
    }
  },
  computed: {
    testReport() {
      if (!this.selectedLesson) {
        return this.reports
      }
      return this.reports.map(report => ({
        ...report,
        loaders: report.loaders.filter(loader => Number(loader.lesson_id) === Number(this.selectedLesson)),
      }))
    },
    selectedTestInfo() {
      return this.classTests.find(test => test.id === this.selectedTest)
    },
  },
  watch: {
    selectedTestInfo(value) {
      if (value && value.lessons[0]) {
        const lessonId = value.lessons[0].id
        console.log(lessonId)
      } else {
        this.selectedCourseInfo = null
      }
    },
  },
  created() {
    this.getClassTestList()
  },
  methods: {
    getClassTestList() {
      this.isProcessing = true
      useJwt.getClassTestList(this.classRoom.id).then(response => {
        const { data } = response.data
        const tests = data.class_tests
        this.classTests = tests.map(item => ({ ...item, selectedLessons: item.lessons }))
        if (!this.selectedTest) {
          this.selectedTest = tests[0]?.id
        }
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    // getLessonReport() {
    //   this.isProcessing = true
    //   useJwt.getClassTestReport(this.classRoom.id, this.selectedTest).then(response => {
    //     this.reports = response.data?.data
    //   }).catch(error => {
    //     this.showErrorMessage(error)
    //   }).finally(() => {
    //     this.isProcessing = false
    //   })
    // },
  },
}
</script>
