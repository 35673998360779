<template>
  <div>
    <div class="d-flex justify-content-between">
      <div>
        <b-button
          variant="flat-secondary"
          class="btn-icon"
          @click="GO_BACK()"
        >
          <feather-icon
            size="20"
            icon="ArrowLeftIcon"
          />
        </b-button>
        <span> Back </span>
      </div>
      <div v-if="lesson">
        <span>
          <feather-icon
            icon="BookOpenIcon"
            size="18"
          />
          {{ lesson.name }}
        </span>
      </div>
    </div>
    <b-row class="row-eq-height m-0">
      <b-col
        v-if="hasData"
        sm="3"
      >
        <courses-list
          v-if="isCoursesView"
          :courses-list="coursesList"
          :selected-problem="selectedProblem"
          :problem-notes-counts="problemNotesCounts"
          @getNotes="getNotesByProblem"
        />
        <problem-list
          v-else
          :problems="problems"
          :selected-problem="selectedProblem"
          @getNotes="getNotesByProblem"
        />
      </b-col>
      <b-col
        sm="9"
      >
        <student-notes
          v-if="!isTeacherView || !hasData || !selectedProblem"
          :students="students"
          :my-note="myNote"
          :pair-note="pairNote"
          :selected-student="selectedStudent"
          :selected-problem="selectedProblem"
          :problems="problems"
          :has-data="hasData"
          @getPairNotes="getPairNotes"
        />
        <b-row v-else>
          <b-col
            v-for="(student, index) of selectedMultipleStudents"
            :key="index"
          >
            <student-notes
              ref="mutipleNotes"
              :students="students"
              :pair-note="student.notes"
              :selected-student="student.studentId"
              :selected-problem="selectedProblem"
              :problems="problems"
              :has-data="hasData"
              @getPairNotes="(student) => getPairNotes(student, index)"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCol, BRow, BButton } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import ProblemList from './components/ProblemList.vue'
import CoursesList from './components/CoursesList.vue'
import StudentNotes from './components/StudentNotes.vue'

export default {
  components: {
    ProblemList,
    BCol,
    BRow,
    StudentNotes,
    BButton,
    CoursesList,
  },
  data() {
    return {
      problems: [],
      students: [],
      myNote: {},
      pairNote: {},
      selectedProblem: this.$route.query.problemId,
      selectedStudent: null,
      lesson: null,
      self: getUserData(),
      coursesList: [],
      selectedMultipleStudents: [],
      problemNotesCounts: [],
    }
  },
  computed: {
    classId() {
      return this.$route.params.classId || this.$route.query.classId
    },
    lessonId() {
      return this.$route.params.lessonId
    },
    isCoursesView() {
      return !this.lessonId
    },
    requiredStudents() {
      return this.$route.query.student
        ? JSON.parse(this.$route.query.student)
        : null
    },
    hasData() {
      return !!this.coursesList.length || !!this.problems.length
    },
    isTeacherView() {
      return this.self.usertype !== 'student'
    },
  },
  mounted() {
    if (this.isCoursesView) {
      this.getPairInfoByCourses()
    } else {
      this.getPairingInfo()
      if (this.selectedProblem) {
        this.getMyNote(this.selectedProblem)
      }
    }
  },
  methods: {
    setStudents(membersList) {
      this.students = membersList
        .map(member => ({
          name: `${member.firstname} ${member.lastname}`,
          id: member.id,
          icon: member.avatar ?? '',
        }))
        .filter(student => Number(student.id) !== Number(this.self.id))
      this.selectedStudent = this.students[0]?.id
      this.selectedMultipleStudents = [{
        studentId: this.students[0]?.id,
        notes: {},
      },
      {
        studentId: this.students[1]?.id,
        notes: {},
      }]
    },
    getPairingInfo() {
      useJwt
        .getParingNoteByLesson({
          lessonId: this.lessonId,
          classId: this.classId,
        })
        .then(response => {
          const { data } = response
          this.problems = data?.lesson?.problem_list || []
          this.setStudents(data.membersList)
          this.lesson = data.lesson
        })
    },
    getPairInfoByCourses() {
      useJwt
        .getAllPairNotes({
          params: {
            students: this.requiredStudents,
            classId: this.classId,
          },
        })
        .then(response => {
          this.coursesList = response.data.courses
          this.problemNotesCounts = response.data?.problemNotesCounts
          this.setStudents(response.data.membersList)
        })
    },
    getMyNote(problemId) {
      if (this.self.usertype !== 'student') {
        return
      }
      useJwt
        .getParingNoteByProblems({
          problemId,
        })
        .then(response => {
          this.myNote = response.data.data
        })
    },
    async getPairNotes(studentId, index = null) {
      if (index !== null) {
        const studentInfo = this.selectedMultipleStudents
        return useJwt
          .getParingNoteByProblems({
            studentId,
            problemId: this.selectedProblem,
          })
          .then(response => {
            studentInfo[index] = {
              studentId,
              notes: response.data.data,
            }
            this.selectedMultipleStudents = [...studentInfo]
          })
      }
      this.selectedStudent = studentId
      return useJwt
        .getParingNoteByProblems({
          studentId,
          problemId: this.selectedProblem,
        })
        .then(response => {
          this.pairNote = response.data.data
        })
    },
    getNotesByProblem(id) {
      this.selectedProblem = id

      if (this.$refs.mutipleNotes) {
        this.$refs.mutipleNotes.forEach(async (noteRef, index) => {
          await this.getPairNotes(noteRef.studentId, index)
        })
      } else {
        this.getMyNote(id)
        this.getPairNotes(this.selectedStudent)
      }
    },
  },
}
</script>
