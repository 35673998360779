import { render, staticRenderFns } from "./Layout2.vue?vue&type=template&id=7d538157&scoped=true"
import script from "./Layout2.vue?vue&type=script&lang=js"
export * from "./Layout2.vue?vue&type=script&lang=js"
import style0 from "./Layout2.vue?vue&type=style&index=0&id=7d538157&prod&lang=css&scoped=true"
import style1 from "./Layout2.vue?vue&type=style&index=1&id=7d538157&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d538157",
  null
  
)

export default component.exports