<template>
  <div>
    <b-row class="d-flex align-items-center">
      <b-col md="5">
        {{ $t('send-notification-module.select-students') }}
      </b-col>
      <b-col md="7">
        <b-form-input
          v-model="search"
          :placeholder="$t('messages.SearchPlaceholder')"
          @input="(val)=>$emit('search',val)"
        />
      </b-col>
    </b-row>
    <b-card
      style="margin:6px 0"
      no-body
    >
      <div
        class="table-responsive-sm"
        :class="{'mb-0':localUsers.length == 0}"
        style="max-height:400px;overflow-y:auto"
      >
        <table
          v-show="localUsers.length>0"
          class="table b-table"
        >
          <thead>
            <tr>
              <th
                v-for="col in columns"
                :key="col.title"
              >
                <div class="d-flex align-items-center">
                  <b-form-checkbox
                    v-if="col.isSelectable"
                    v-model="col.isSelected"
                    class="d-inline-block"
                    @change="(val)=>toggleAll(val,col.key)"
                  />
                  <span>{{ col.title }}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item,ind) in localUsers"
              :key="'item_'+ind"
            >
              <td
                v-for="(col,colIndex) in columns"
                :key="'item_'+ind+col.key"
              >
                <div
                  v-if="item[col.key]"
                  class="d-flex align-items-center"
                >
                  <b-form-checkbox
                    v-if="col.isSelectable"
                    v-model="item[col.key==='name' ? 'isSelected':'isParentSelected']"
                    class="d-inline-block"
                    @change="(val)=>itemValueChanged(col.key,colIndex)"
                  />
                  <span>{{ item[col.key] }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          v-if="localUsers.length==0 && !isProcessing"
          class="m-1"
        >
          {{ $t('send-notification-module.no-students-found') }}
        </p>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BFormCheckbox, BFormInput, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BFormCheckbox, BFormInput, BRow, BCol,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    userTypes: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      localUsers: [],
    }
  },
  computed: {
    columns() {
      return [{
        key: 'name',
        title: this.$i18n.tc('student-name'),
        isSelectable: this.userTypes.includes('student'),
        isSelected: true,
      },
      {
        key: 'parent',
        title: this.$i18n.tc('parent-name'),
        isSelectable: this.userTypes.includes('parent'),
        isSelected: true,
      }]
    },
  },
  watch: {
    users() {
      this.localUsers = JSON.parse(JSON.stringify(this.users))
    },
  },

  methods: {
    toggleAll(status, key) {
      if (key === 'name') {
        this.localUsers.forEach((e, i) => { this.$set(this.localUsers[i], 'isSelected', status) })
        if (status) this.$emit('selected', 'students', this.localUsers.map(e => e.id))
        else this.$emit('selected', 'students', [])
      } else if (key === 'parent') {
        this.localUsers.forEach((e, i) => { this.$set(this.localUsers[i], 'isParentSelected', status) })
        if (status) this.$emit('selected', 'parents', this.localUsers.filter(e => e.parentId).map(e => e.id))
        else this.$emit('selected', 'parents', [])
      }
    },
    itemValueChanged(key, colIndex) {
      let val = false
      if (key === 'name') {
        const selected = this.localUsers.filter(e => e.isSelected)
        this.$emit('selected', 'students', selected.map(e => e.id))
        if (selected.length === this.localUsers.length) val = true
      } else if (key === 'parent') {
        const selected = this.localUsers.filter(e => e.isParentSelected && e.parentId)
        const totalParents = this.localUsers.filter(e => e.parentId).length
        this.$emit('selected', 'parents', selected.map(e => e.parentId))
        if (selected.length === totalParents) val = true
      }
      this.columns[colIndex].isSelected = val
    },
  },
}
</script>
