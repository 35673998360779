<template>
  <div
    class="container-fluid p-0"
  >
    <div class="row">
      <div class="col-md-12">
        <div class="showcase">
          <div class="container-fluid">
            <div
              v-for="(section,index) in data"
              :key="'section_'+index"
              class="row g-0"
              :style="`display:flex;flex-direction:${index%2==0 ? 'row':'row-reverse'}`"
            >
              <div
                class="col-lg-6 order-lg-2 text-white showcase-img"
                :style="`background-size: contain;`"
              >
                &nbsp; <img :src=" section.image_url"
class="img-fluid"
>
              </div>

              <div class="col-lg-6 order-lg-1 my-auto showcase-text">
                <h2
                  v-text-to-speech
                  @click="$emit('onClick', $event)"
                  v-html="isAProblem ? formatQuestion(section.title,'title',index):section.title"
                />

                <p
                  v-text-to-speech
                  class="lead mb-0"
                  @click="$emit('onClick', $event)"
                  v-html="isAProblem ? formatQuestion(section.subtitle,'subtitle',index):section.subtitle"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { checkSettings } from '@/utils/visibilitySettings'

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    placeHolder: {
      type: String,
      default: '',
    },
    isAProblem: {
      type: Boolean,
      default: false,
    },
    questionFormatter: {
      type: Function,
      default: null,
    },
  },
  computed: {
    copyPasteEnabled() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const setting = checkSettings('copy_paste', schoolSetting, distSetting)
      if (setting.value === '1') {
        return true
      }
      return false
    },
  },
  methods: {
    formatQuestion(question, dataString, ind) {
      if (this.questionFormatter && typeof this.questionFormatter === 'function') {
        return this.questionFormatter(question, dataString, ind)
      }
      let html = ''
      let index = 0
      question
        .replaceAll(this.placeHolder, `$${this.placeHolder}$`)
        .split('$')
        .filter(e => e.length > 0)
        .forEach(element => {
          if (element === this.placeHolder) {
            this.$emit('createAnswer', `${dataString}-${ind}-${index}`)
            html += `
              <input
                type="text"
                :id="'Field_' + ${(index + 1)}"
                data-id="${dataString}-${ind}-${index}"
                style="display: inline-block; width: 150px;margin-top:2px"
                class="mx-1 answer form-control"
                :name="'Field_' + ${(index + 1)}"
                onpaste="return ${this.copyPasteEnabled};"
              />
            `
            index += 1
          } else {
            html += element
          }
        })
      return html
    },
  },

}
</script>

<style>

</style>
