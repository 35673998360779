<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <b-avatar
      v-if="type == 'chat'"
      size="42"
      :src="user.avatar"
      :badge="isChatContact"
      class="badge-minimal"
      :badge-variant="isUserOnline ? 'success' :'secondary'"
      variant="transparent"
    />
    <group-avatar
      v-if="type == 'group'"
      :badge-variant="resolveAvatarBadgeVariant(user.status)"
      :contact="user"
    />
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">
        {{
          user.fullName ? user.fullName : `${user.firstname}  ${user.lastname}`
        }}
      </h5>
      <p class="card-text text-truncate">
        {{
          isChatContact
            ? user.lastMessage.type == "audio"
              ? "Audio Message"
              : user.lastMessage.type == "file" ? "Attachment"
                : user.lastMessage.type == "sticker" ? "Sticker" : user.lastMessage.message
            : user.about
        }}
      </p>
    </div>
    <div
      v-if="isChatContact"
      class="chat-meta text-nowrap"
    >
      <small class="float-right mb-25 chat-time">{{
        formatDateToMonthShort(user.lastMessage.time, {
          hour: "numeric",
          minute: "numeric",
        })
      }}</small>
      <b-badge
        v-if="user.unseenMsgs"
        pill
        variant="primary"
        class="float-right"
      >
        {{ user.unseenMsgs }}
      </b-badge>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue'
import { formatDateToMonthShort } from '@core/utils/filter'
import {
  VaniEvent,
  ChatMessageType,
} from 'vani-meeting-client'
import useChat from './useChat'
import GroupAvatar from '../components/GroupAvatar.vue'
import ChatHandler from './ChatHandler'

export default {
  components: {
    BAvatar,
    BBadge,
    GroupAvatar,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'chat',
    },
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat()
    return { formatDateToMonthShort, resolveAvatarBadgeVariant }
  },
  data() {
    return {
      isUserOnline: this.user.status === 'online',
    }
  },
  mounted() {
    ChatHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnAllParticipants, this.onParticipantDataUpdated)
    ChatHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnUserJoined, this.onUserJoined)
    ChatHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnUserLeft, this.onUserLeft)
    ChatHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnNewChatMessageReceived, this.onNewChatMessageReceived)

    this.checkIfUserOnline()
  },

  beforeUnmount() {
    ChatHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnAllParticipants, this.onParticipantDataUpdated)
    ChatHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnUserJoined, this.onUserJoined)
    ChatHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnUserLeft, this.onUserLeft)
    ChatHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnNewChatMessageReceived, this.onNewChatMessageReceived)
  },
  methods: {
    onParticipantDataUpdated() {
      this.checkIfUserOnline()
    },
    onUserJoined(participant) {
      if (participant.userData.id === this.user.userId) {
        this.isUserOnline = true
      }
    },
    onUserLeft(participant) {
      if (participant.userData.id === this.user.userId) {
        this.isUserOnline = false
      }
    },
    onNewChatMessageReceived(messagePayload) {
      const participant = ChatHandler.getInstance().getUserByUserId(this.user.userId)
      if (messagePayload.type === ChatMessageType.Chat) {
        if (participant) {
          if (messagePayload.senderUserId === participant.userId) {
            // show message
            this.user.lastMessage.message = messagePayload.message
          }
        }
      }
    },
    checkIfUserOnline() {
      // const participants = ChatHandler.getInstance().getUserByUserId(this.user.userId)
      // if (participants && participants.length > 0) {
      //   this.isUserOnline = true
      // } else {
      //   this.isUserOnline = false
      // }
    },
  },

}
</script>

<style>
</style>
