<template>
  <b-card>
    <b-row>
      <b-col>
        <b-tabs
          vertical
          nav-wrapper-class="nav-vertical"
        >
          <b-tab
            title="BG image"
            :active="form.bgStyleType == 'image'"
            @click="form.bgStyleType = 'image'"
          >
            <b-form-group>
              <template #label>
                <span>Background Image</span>  <b-button
                  v-if="form.imageUrl"
                  variant="flat-danger"
                  class="btn-icon position-absolute mb-2"
                  size="20"
                  style="right: 0;margin-top: -10px"
                  @click="form.imageUrl = null;form.image = null"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </template>
              <b-form-file
                v-model="image"
                accept="image/*"
                :browse-text="$t('browseButton')"
                :placeholder="$t('issue-report-module.choose-a-file')"
              />
            </b-form-group>
          </b-tab>
          <b-tab
            title="BG Color"
            :active="form.bgStyleType == 'color'"
            @click="form.bgStyleType = 'color'"
          >
            <div class="d-flex justify-content-around">
              <verte
                v-model="form.backgroundColor"
                picker="wheel"
                model="rgb"
              />
              <EyeDropper v-model="form.backgroundColor" />
            </div>
          </b-tab>
        </b-tabs>
      </b-col>
      <b-col>
        <b-form-group label="Layout Option">
          <b-form-select
            v-model="form.layout"
            :options="layoutOption"
          />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Background Music">
          <recorder
            :url="form.audioUrl"
            @onRemove="form.audioUrl = null"
            @input="setAudio($event)"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <div class="row">
            <div class="col">
              <label class="control-label">Padding top</label>
              <input
                v-model.lazy="form.paddingTop"
                type="number"
                class="form-control"
                placeholder="Top"
              >
            </div>
            <div class="col">
              <label class="control-label">Padding right</label>
              <input
                v-model.lazy="form.paddingRight"
                type="number"
                class="form-control"
                placeholder="Right"
              >
            </div>
            <div class="col">
              <label class="control-label">Padding bottom</label>
              <input
                v-model.lazy="form.paddingBottom"
                type="number"
                class="form-control"
                placeholder="Bottom"
              >
            </div>
            <div class="col">
              <label class="control-label">Padding left</label>
              <input
                v-model.lazy="form.paddingLeft"
                type="number"
                class="form-control"
                placeholder="Left"
              >
            </div>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <h4>Problem Body Setting</h4>
    <b-row>
      <b-col>
        <b-form-group label="Body Theme">
          <b-form-select
            v-model="form.bodyTheme"
            :options="bodyThemeOptions"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Answer Cluster">
          <b-form-select
            v-model="form.answerCluster"
            :options="answerCluster"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <slot />
  </b-card>
</template>
<script>
import {
  BCard, BFormFile, BFormGroup, BFormSelect, BRow, BCol, BButton, BTabs, BTab,
} from 'bootstrap-vue'
import Recorder from '@/views/super/problem/components/recorder.vue'
import Verte from 'verte'
import 'verte/dist/verte.css'
import EyeDropper from '@/views/common/components/EyeDropper.vue'

export default {
  components: {
    BCard,
    BFormFile,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    Recorder,
    BTabs,
    BTab,
    Verte,
    EyeDropper,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      layoutOption: [
        {
          text: 'Small Left Big Right',
          value: 'small-left-big-right',
        },
        {
          text: 'Small Right Big Left',
          value: 'small-right-big-right',
        },
        {
          text: 'Small Top Big Bottom',
          value: 'small-top-big-bottom',
        },
        {
          text: 'Small Bottom Big Top',
          value: 'small-bottom-big-top',
        },
      ],
      image: null,
      bodyThemeOptions: [
        {
          text: 'Based on theme',
          value: null,
        },
        {
          text: 'Transparent',
          value: 'transparent',
        },
      ],
      answerCluster: [
        {
          text: 'default',
          value: null,
        },
        {
          text: 'Horizontal',
          value: 'horizontal',
        },
        {
          text: 'Vertical',
          value: 'vertical',
        },
      ],
    }
  },
  watch: {
    image: {
      handler() {
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () => {
            this.form.imageUrl = reader.result
          },
          false,
        )
        if (this.image) {
          reader.readAsDataURL(this.image)
        }
      },
    },
  },
  methods: {
    async  fileToBase64(file) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function () {
          const base64String = reader.result
          resolve(base64String)
        }
      })
    },
    setAudio({ audio }) {
      let convertedAudio = ''
      this.fileToBase64(audio).then(result => {
        convertedAudio = result
        this.form.audioUrl = convertedAudio
      })
    },
  },
}
</script>
