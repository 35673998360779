<template>
  <div class="mb-2 text-center animated-elements">
    <div>
      <template
        v-for="element,index of animatingData"
      >
        <div
          :key="index"
          :data-aos="element.action"
          :data-aos-duration="element.delay"
          role="button"
          :class="`text-${element.textAlign}`"
          :style="{
            backgroundColor: element.textBackground && element.textBackground
          }"
          @click="$emit('handleEdit', elementData, index)"
        >
          <b-img
            v-if="element.imageUrl"
            v-image-preview
            :src="element.imageUrl"
            :height="element.imageHeight"
            :width="element.imageWidth"
            :style="{ width: element.imageWidth || '100%', height: element.imageHeight || '100%', objectFit: 'cover' }"
          />
          <h3
            :style="{
              color: element.textColor
            }"
          >
            <p v-html="element.text" />
            <audio-player
              v-if="element.audioUrl"
              :key="element.audioUrl"
              :src="element.audioUrl"
            />

          </h3>
        </div>
      </template>
      <b-button
        v-if="isEditMode"
        variant="gradient-primary"
        class="my-3 text-center"
        @click="$emit('handleCreate', elementData)"
      >
        <feather-icon icon="PlusIcon" /> Add Element
      </b-button>
    </div>
  </div>
</template>
<script>
import { BButton, BImg } from 'bootstrap-vue'
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'
import editorMixin from '../../helper/editor-mixin'

export default {
  components: {
    BButton,
    BImg,
    AudioPlayer,
  },
  mixins: [editorMixin],
}
</script>
<style>
.animated-elements .audio-play-stop {
  width: 10px !important;
  height: 10px !important;
}
</style>
