<template>
  <div>
    <Header
      :active-view="activeView"
      :user-details="userDetails"
      :user-details-processing="userDetailsProcessing"
      @changeActiveView="(v)=>activeView=v"
      @getStudentActivities="getStudentActivities"
    />

    <block-view
      v-show="activeView==='block'"
      :time-today="totalTime "
      :data="diaryData"
      :is-processing="isProcessing"
    />

  </div>
</template>

<script>
// import { BCard } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_STUDENT } from '@/const/userType'
import Header from './components/Header.vue'
import BlockView from './components/BlockView.vue'

export default {
  components: {
    Header, BlockView,
  },
  data() {
    return {
      totalTime: '',
      activeView: 'block',
      self: getUserData(),
      calendarOptions: {
        initialView: 'dayGridMonth',
      },
      studentActivities: [],
      diaryData: {},
      isProcessing: false,
      userDetails: {
        fullname: 'john doe',
        avatar: 'https://via.placeholder.com/400',
      },
      userId: null,
      userDetailsProcessing: false,
    }
  },
  watch: {
    '$route.params.id': {
      handler(id) {
        if (id) {
          this.userId = id
          this.getUserData()
        }
      },
      deep: true,
      immediate: true,
      totalTime: '',
    },
  },
  created() {
    if (!this.userId) this.userId = this.self.usertype === USER_TYPE_STUDENT ? this.self.id : null
  },
  mounted() {
    if (this.userId) this.getUserData()
  },
  methods: {
    getUserData() {
      this.userDetailsProcessing = true
      useJwt.getUser(this.userId).then(res => {
        this.userDetails = res.data.data
      }).finally(() => {
        this.userDetailsProcessing = false
      })
    },
    getStudentActivities(date) {
      this.isProcessing = true
      useJwt.getStudentActivities(this.userId, {
        params: {
          date,
        },
      }).then(response => {
        this.diaryData = response.data.data.diary ? response.data.data.diary : []
        this.totalTime = response.data.data.total_time ? response.data.data.total_time : ''
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>

<style>
.student-diary-header{
  z-index: 1;
}
</style>
