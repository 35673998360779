<template>
  <b-modal :visible="show"
           :title=" $t('courses-module.batch-upload')"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <validation-observer ref="simpleRules">
      <b-form-group :label="$t('courses-module.upload-excel')">
        <validation-provider
          v-slot="{ errors }"
          name="Excel File"
          rules="required"
        >
          <b-form-select v-model="uploadType"
                         :options="fileOptions"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group :label="$t('courses-module.upload-excel')">
        <validation-provider
          v-slot="{ errors }"
          name="Excel File"
          rules="required"
        >
          <b-form-file ref="refInputEl1"
                       v-model="excelFile"
                       :accept="supportedFile"
                       :browse-text="$t('browseButton')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
    <template #modal-footer>
      <div class="d-flex  w-100 justify-content-between">
        <div class="float-left">
          <b-button variant="outline-secondary"
                    @click="downloadSample"
          >
            <b-spinner v-if="isProcessing"
                       small
            /> {{ $t('curriculum-pages-module.download-sample') }}
          </b-button>
        </div>
        <div class="float-right">
          <b-button variant="success"
                    :disabled="isProcessing"
                    @click="handleOk"
          >
            <b-spinner v-if="isProcessing"
                       small
            />  {{ $t('actions.upload') }}
          </b-button>
          <b-button class="ml-1"
                    variant="outline-secondary"
                    @click="$emit('close')"
          >
            {{ $t('labels.cancel') }}
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal, BFormFile, BFormGroup, BButton, BSpinner, BFormSelect,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'

export default {
  components: {
    BModal,
    BFormFile,
    BFormGroup,
    BButton,
    BSpinner,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isProcessing: false,
      excelFile: null,
      required,
      uploadType: 'excel',
      fileOptions: [
        {
          text: 'CSV/EXCEL',
          value: 'excel',
        },
        {
          text: 'JSON',
          value: 'json',
        },
      ],
    }
  },
  computed: {
    supportedFile() {
      return this.uploadType === 'excel' ? '.xlsx,.xls,.csv' : '.json'
    },
  },

  methods: {
    handleOk() {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isProcessing = true
          const data = new FormData()
          data.append('excel_file', this.$refs.refInputEl1.files[0])
          data.append('upload_type', this.uploadType)
          axios.post('/api/v1/cb-batch-upload', data, config).then(response => {
            this.showSuccessMessage(response)
            this.$emit('close')
            this.$emit('reFetch')
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => {
            this.isProcessing = false
          })
        }
      })
    },
    downloadSample() {
      let href; let
        extension
      if (this.uploadType === 'excel') {
        href = '/sample-file/cb-batch-sample.zip'
        extension = '.zip'
      } else {
        href = '/sample-file/cb-batch-sample.json'
        extension = '.json'
      }
      const link = document.createElement('a')
      link.href = href // Replace with your file URL
      link.download = `sample${extension}` // Specify the file name
      link.click()
    },
  },
}
</script>
