<template>
  <div>
    <vue-apex-charts
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    series: {
      type: [Object, Array],
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: {
        colors: ['#FFEB3A', '#3F50B5', '#4CAF4F', '#F44336'],
        chart: {
          height: 150,
          type: 'bar',
        },
        xaxis: {
          categories: this.categories,
          position: 'top',
        },
        dataLabels: {
          formatter(val) {
            return `${val.toFixed(2)}%`
          },
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 6,
          labels: {
            formatter(value) {
              const val = +value.toFixed(0)
              if (val === 17) {
                return `(A1) ${val}%`
              }
              if (val === 33) {
                return `(A2) ${val}%`
              }
              if (val === 50) {
                return `(B1) ${val}%`
              }
              if (val === 67) {
                return `(B2) ${val}%`
              }
              if (val === 83) {
                return `(C1) ${val}%`
              }
              if (val > 83) {
                return `(C2) ${val}%`
              }
              return `${val}%`
            },
            html: true,
          },
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            dataLabels: {
              position: 'bottom',
            },
          },
        },
      },
    }
  },
}
</script>
