<template>
  <div>
    <b-button
      :style="{
        backgroundColor: props.value + '!important'
      }"
      class="btn-icon rounded-circle"
      @click="openColorPicker"
    >
      <feather-icon icon="Edit3Icon" />
    </b-button>
  </div>
</template>
<script setup>
import { BButton } from 'bootstrap-vue'

const props = defineProps({
  value: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['input'])

function openColorPicker() {
  if (!window.EyeDropper) {
    // eslint-disable-next-line no-alert
    alert('Your browser does not support the EyeDropper API')
  }
  // eslint-disable-next-line no-undef
  const eyeDropper = new EyeDropper()

  eyeDropper.open().then(result => {
    emit('input', result.sRGBHex)
  }).catch(e => {
    alert(e)
  })
}

</script>
