<template>
  <div>
    <b-row>
      <b-col>
        <b-form-textarea v-model="value.text" />
      </b-col>
      <b-col cols="4">
        <Recorder :url="value.audio"
                  :polly-text="value.text"
                  @input="setQuestionAudio($event)"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BFormTextarea, BCol, BRow } from 'bootstrap-vue'
import Recorder from '@/views/super/problem/components/recorder.vue'

export default {
  components: {
    BFormTextarea,
    BCol,
    BRow,
    Recorder,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    async  fileToBase64(file) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function () {
          const base64String = reader.result
          resolve(base64String)
        }
      })
    },
    setQuestionAudio({ audio }) {
      let convertedAudio = ''
      this.fileToBase64(audio).then(result => {
        convertedAudio = result
        this.value.audio = convertedAudio
      })
    },
  },
}
</script>
