<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <!-- Logged In User Profile Sidebar -->
      <user-profile-sidebar
        :shall-show-user-profile-sidebar="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        @close-sidebar="$emit('update:shall-show-user-profile-sidebar', false)"
      />

      <!-- Sidebar Content -->
      <div
        class="sidebar-content"
        :class="{ show: mqShallShowLeftSidebar }"
      >
        <!-- Sidebar close icon -->
        <span class="sidebar-close-icon">
          <feather-icon
            icon="XIcon"
            size="16"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </span>

        <!-- Header -->
        <div class="chat-fixed-search">
          <div class="d-flex align-items-center w-100">
            <div class="sidebar-profile-toggle">
              <b-avatar
                size="42"
                class="cursor-pointer badge-minimal avatar-border-2"
                :src="profileUserMinimalData.avatar"
                variant="transparent"
                badge
                badge-variant="success"
              />
            </div>
            <!-- Search -->
            <div
              class="position-relative"
              style="width: 82%"
            >
              <b-input-group
                class="input-group-merge ml-1 w-100 round position-relative"
              >
                <b-input-group-prepend is-text>
                  <feather-icon
                    icon="SearchIcon"
                    class="text-muted"
                  />
                </b-input-group-prepend>
                <b-form-input
                  :value="searchText"
                  :placeholder="$t('messages.SearchPlaceholder')"
                  :style="{
                    position: 'relative',
                    zIndex: searchText ? 101 : 0,
                  }"
                  @input="fetchUser($event)"
                />
              </b-input-group>
              <ul
                v-if="searchText"
                class="search-dropdown"
              >
                <li
                  v-for="(user, i) in options"
                  v-show="!loading"
                  :key="i"
                  @click="
                    $emit('open-chat', {
                      userId: user.id,
                      type: 'single',
                      model: 'class',
                    });
                    searchText = null;
                  "
                >
                  {{ user.firstname + " " + user.lastname }}
                </li>
                <li
                  v-if="loading"
                  style="text-align: center"
                >
                  fetching user...
                </li>
                <li
                  v-if="searchText && !options[0] && !loading"
                  style="text-align: center"
                >
                  no user found
                </li>
              </ul>
              <div
                v-if="searchText"
                class="dropdown-back"
                @click="
                  options = [];
                  searchText = '';
                "
              />
            </div>
          </div>
        </div>

        <!-- ScrollArea: Chat & Contacts -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
        >
          <!-- Chats Title -->
          <h4 class="chat-list-title">
            {{ $t("Chat") }}
          </h4>
          <b-tabs pills
                  class="mb-2 flex flex-nowrap"
          >
            <b-tab v-for="tab,index of userTypesTabs"
                   :key="index"
            >
              <template #title>
                <span class="pr-1"
                      style="position:relative"
                >
                  {{ tab.text }}
                  <small v-if="getUnreadMsgNumbers(tab.value) > 0"
                         style="position:absolute; right:-8px; top:-2px;"
                  >
                    <b-badge pill
                             variant="primary"
                    >
                      {{ getUnreadMsgNumbers(tab.value) }}
                    </b-badge>
                  </small>
                </span>
              </template>
              <div v-if="tab.value === 'class'">
                <h6
                  v-show="emptyClasses"
                  class="ml-2"
                >
                  {{ emptyClassesMessage }}
                </h6>
                <!-- Classes -->
                <div
                  v-for="(cls, i) in classes"
                  :key="cls + i"
                >
                  <div
                    class="d-flex col-md-12 pl-2 mt-1 py-1"
                    :class="{
                      active:
                        activeChatContactId === cls.id &&
                        activeChatType === 'group' &&
                        activeChatModel === 'class',
                    }"
                    style="cursor: pointer"
                    data-toggle="collapse"
                    :href="'#classContact' + cls.id"
                  >
                    <b-avatar
                      size="42"
                      :src="cls.avatar"
                      class="badge-minimal"
                      variant="transparent"
                    />
                    <h4 class="mb-0 mt-1 ml-1">
                      {{ cls.name }}
                    </h4>
                    <feather-icon
                      class="mt-1 ml-auto mr-2"
                      style="width: 20px; height: 20px"
                      icon="ArrowRightIcon"
                      @click.stop="
                        $emit('open-chat', {
                          userId: cls.id,
                          type: 'group',
                          model: 'class',
                        })
                      "
                    />
                  </div>
                  <div
                    :id="'classContact' + cls.id"
                    class="chat-contact collapse ml-2"
                  >
                    <chat-contact
                      v-for="(contact, j) in cls.students"
                      v-show="contact.id !== profileUserMinimalData.id"
                      :key="j + contact"
                      :user="contact"
                      :user-status="contact.last_seen !== null ? 'online' : 'offline'"
                      :class="{ active: activeChatContactId === contact.id }"
                      tag="li"
                      @click="
                        $emit('open-chat', {
                          userId: contact.id,
                          type: 'single',
                          model: 'class',
                        })
                      "
                    />
                  </div>
                </div>
              </div>
              <!-- Chats -->
              <ul v-else
                  class="chat-users-list chat-list media-list"
              >
                <chat-contact
                  v-for="(contact, i) in filteredChatsContacts.filter(
                    item => {
                      if (tab.value === 'high-level') {
                        return ![9, 7, '7', '9'].includes(item.user_type_id)
                      }
                      return item.user_type_id == tab.value
                    }
                  )"
                  :key="contact + i"
                  :user="contact"
                  tag="li"
                  :class="{
                    active:
                      activeChatContactId === contact.userId &&
                      (contact.type === 'single' ||
                        activeChatModel === contact.group),
                  }"
                  is-chat-contact
                  @click="
                    $emit('open-chat', {
                      userId: contact.userId,
                      type: contact.type,
                      model: contact.group,
                    })
                  "
                />
                <template v-if="tab?.value == 7">
                  <chat-contact
                    v-for="(contact, i) in teacherChats"
                    :key="i + '_'"
                    :user="contact"
                    tag="li"
                    :class="{
                      active:
                        activeChatContactId === contact.userId
                    }"
                    is-chat-contact
                    @click="
                      $emit('open-chat', {
                        userId: contact.userId,
                        type: contact.type,
                        model: contact.group,
                      })
                    "
                  />
                </template>
              </ul>
            </b-tab>
          </b-tabs>
          <!-- <h6
            v-show="emptyChats"
            class="ml-2"
          >
            {{ emptyChatsMessage }}
          </h6> -->

          <!-- Classes Title -->
          <!-- <h4 class="chat-list-title">
            {{ $t("navigation-menu.classes") }}
          </h4> -->

          <!-- Networks Title -->
          <!-- <h4 class="chat-list-title">
            {{ $t("navigation-menu.networks") }}.chat-user-list-wrapper
          </h4> -->
          <!-- Networks -->
          <!-- <div
            v-for="(network, i) in contacts"
            :key="network.id + '' + i"
          >
            <div
              class="d-flex chat-info pl-2 mt-1 py-1"
              :class="{
                active:
                  activeChatContactId === network.id &&
                  activeChatType === 'group' &&
                  activeChatModel === 'network',
              }"
              data-toggle="collapse"
              :href="'#network' + network.id"
              style="cursor: pointer"
            >
              <b-avatar
                size="42"
                :src="network.avatar"
                class="badge-minimal"
                variant="transparent"
              />
              <h4 class="mb-0 mt-1 ml-1">
                {{ network.fullName }}
              </h4>
              <feather-icon
                class="mt-1 ml-auto mr-2"
                style="width: 20px; height: 20px"
                icon="UserPlusIcon"
                @click.stop="
                  $emit('open-chat', {
                    userId: network.id,
                    type: 'group',
                    model: 'network',
                  })
                "
              />
            </div>
            <div
              :id="'network' + network.id"
              class="chat-network collapse ml-2"
            >
              <chat-contact
                v-for="(contact, j) in network.students"
                v-show="contact.id !== profileUserMinimalData.id"
                :key="contact.id + j"
                :user="contact"
                :user-status="contact.last_seen !== null ? 'online' : 'offline'"
                :class="{ active: activeChatContactId === contact.id }"
                tag="li"
                @click="
                  $emit('open-chat', {
                    userId: contact.id,
                    type: 'group',
                    model: 'network',
                  })
                "
              />
            </div>
          </div> -->
        </vue-perfect-scrollbar>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BAvatar, BInputGroup, BInputGroupPrepend, BFormInput, BTabs, BTab, BBadge,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ref, computed } from 'vue'
// import useJwt from '@/@core/auth/jwt/useJwt'
// import { ModelSelect } from 'vue-search-select'
import axiosIns from '@/libs/axios'
import i18n from '@/libs/i18n'
import ChatContact from './ChatContact.vue'
import UserProfileSidebar from './UserProfileSidebar.vue'

export default {
  components: {

    // BSV
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BTabs,
    BTab,
    BBadge,
    // ModelSelect,

    // 3rd party
    VuePerfectScrollbar,

    // SFC
    ChatContact,
    UserProfileSidebar,
  },
  props: {
    teacherChats: {
      type: Array,
      default: () => [],
    },
    chatsContacts: {
      type: Array,
      required: true,
    },
    contacts: {
      type: [Array, Object],
      required: true,
    },
    classes: {
      type: [Array, Object],
      required: false,
      default: () => null,
    },
    emptyChats: {
      type: [Number, Boolean],
      required: false,
      default: false,
    },
    emptyClasses: {
      type: [Number, Boolean],
      required: false,
      default: false,
    },
    shallShowUserProfileSidebar: {
      type: Boolean,
      required: true,
    },
    profileUserData: {
      type: Object,
      required: true,
    },
    profileUserMinimalData: {
      type: Object,
      required: true,
    },
    activeChatContactId: {
      type: Number,
      default: null,
    },
    activeChatType: {
      type: String,
      default: null,
    },
    activeChatModel: {
      type: String,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const resolveChatContact = userId => props.contacts.find(contact => contact.id === userId)
    // Search Query
    const searchQuery = ref('')
    const searchFilterFunction = contact => contact.fullName.toLowerCase().includes(searchQuery.value.toLowerCase())
    const filteredChatsContacts = computed(() => props.chatsContacts.filter(searchFilterFunction))
    // const filteredContacts = computed(() => props.contacts.filter(searchFilterFunction))
    // const filteredClasses = computed(() => props.classes.filter(searchFilterFunction))
    return {
      // Search Query
      searchQuery,
      filteredChatsContacts,
      // filteredContacts,
      // filteredClasses,

      // UI
      resolveChatContact,
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
      emptyClassesMessage: '',
      emptyChatsMessage: '',
      chatData: JSON.parse(localStorage.getItem('chatData')) || null,
      openedClassId: [],
      openedNetworkId: [],
      options: ['prabhat'],
      item: null,
      showSearchResult: true,
      searchText: '',
      loading: false,
      userTypesTabs: [
        {
          text: i18n.tc('chat-page.teacher'),
          value: 7,
        },
        {
          text: i18n.tc('chat-page.student'),
          value: 9,
        },
        {
          text: i18n.tc('chat-page.class'),
          value: 'class',
        },
        {
          text: i18n.tc('chat-page.high-level'),
          value: 'high-level',
        },

      ],
    }
  },
  mounted() {
    setTimeout(() => {
      this.emptyClassesMessage = this.emptyClasses ? "You don't have any classes" : ''
      this.emptyChatsMessage = this.emptyChats ? "You don't have any chats" : ''
    }, 1000)
  },
  methods: {
    setOpenedClass(classId) {
      if (!this.openedClassId.includes(classId)) {
        this.openedClassId.push(classId)
      } else {
        this.openedClassId.splice(this.openedClassId.indexOf(classId), 1)
      }
    },
    setOpenedNetworks(networkId) {
      if (!this.openedNetworkId.includes(networkId)) {
        this.openedNetworkId.push(networkId)
      } else {
        this.openedNetworkId.splice(this.openedNetworkId.indexOf(networkId), 1)
      }
    },
    async fetchUser(value) {
      this.searchText = value
      this.loading = true
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
      axiosIns(`api/v1/chat/search?name=${value.toLowerCase()}`, config).then(data => {
        this.options = data.data
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    getUnreadMsgNumbers(type) {
      const allChatContacts = this.filteredChatsContacts.filter(
        item => {
          if (type === 'high-level') {
            return ![9, 7, '7', '9'].includes(item.user_type_id)
          }
          return item.user_type_id === type
        },
      )
      let unreadMsgNumbers = 0
      allChatContacts.forEach(item => {
        unreadMsgNumbers += item.unseenMsgs || 0
      })

      return unreadMsgNumbers
    },
  },
}
</script>

<style scoped lang="scss">
.search-dropdown {
  position: absolute;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  /* height: auto; */
  max-height: 350px;
  overflow-y: auto;
  left: 10px;
  top: 45px;
  padding: 0;
  z-index: 100;
  border-radius: 7px;
  /* display: none; */
}

.search-dropdown li {
  padding: 12px 10px;
  border-bottom: none;
  cursor: pointer;
}
.search-dropdown li:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
.dropdown-back {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: transparent;
  left: 0;
  top: 0;
  bottom: 0;
}

</style>
