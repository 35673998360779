export default {
  getCachedFilters(key) {
    let oldRec = localStorage.getItem('cached-filters') ?? '{}'
    try {
      oldRec = JSON.parse(oldRec)
      return oldRec[key] ?? {}
    } catch {
      return {}
    }
  },
  storeFilterQuery(key, json) {
    let oldRec = localStorage.getItem('cached-filters') ?? '{}'
    try {
      oldRec = JSON.parse(oldRec)
      oldRec[key] = json
      localStorage.setItem('cached-filters', JSON.stringify(oldRec))
    } catch (error) {
      console.log('error storing filters')
    }
  },
}
