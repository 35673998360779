<template>
  <div :class="isTimeUp ? 'text-danger' : ''"
       class="timer-clock"
  >
    {{ formatTime(increasingMinutes) }}:{{ formatTime(increasingSeconds) }}
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'

// const SECOND_TO_UPDATE_AT_SERVER = 15
export default {
  props: {
    loaderId: {
      type: Number,
      required: true,
    },
    lessonTimeSpent: {
      type: Number,
      default: 0,
    },
    totalTestTime: {
      type: String,
      required: true,
    },
  },
  data() {
    const minutes = Math.floor(this.lessonTimeSpent / 60)
    const remainingSeconds = this.lessonTimeSpent % 60
    const totalTimer = this.totalTestTime.split(':')
    const maxMinute = +totalTimer[0]
    const maxSecond = +totalTimer[1]
    return {
      increasingMinutes: minutes,
      increasingSeconds: remainingSeconds,
      timer: null,
      lessonTimer: null,
      updatingCountDown: 0,
      maxMinute,
      maxSecond,
    }
  },
  computed: {
    isTimeUp() {
      if (this.totalTestTime) {
        const currentTime = `${this.increasingMinutes}:${this.increasingSeconds}`
        return this.timeStringToSeconds(currentTime) >= this.timeStringToSeconds(this.totalTestTime)
      }
      return false
    },
  },
  mounted() {
    this.startTimer()
    this.updateTimeToServer()
  },
  beforeDestroy() {
    clearInterval(this.timer)
    clearInterval(this.lessonTimer)
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.increaseTime()
      }, 1000)
    },
    timeStringToSeconds(timeString) {
      const [minutes, seconds] = timeString.split(':').map(Number)
      return minutes * 60 + seconds
    },
    updateTimeToServer() {
      this.lessonTimer = setInterval(() => {
        useJwt.updateLessonPlayTime(this.loaderId)
      }, 15000)
    },
    increaseTime() {
      if (this.increasingSeconds === 59) {
        // eslint-disable-next-line no-plusplus
        this.increasingMinutes++
        this.increasingSeconds = 0
      } else {
        // eslint-disable-next-line no-plusplus
        this.increasingSeconds++
      }
      this.updatingCountDown += 1
    },
    formatTime(value) {
      return value < 10 ? `0${value}` : value
    },
  },
}
</script>
<style scoped>
.timer-clock {
  font-size: 18px;
}
</style>
