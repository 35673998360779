<template>
  <div id="report-card">
    <b-card>
      <b-row>
        <div class="light col-9  pb-1 lead font-weight-bold">
          {{ $t('setting-module.lesson-completion-rate-title') }}
        </div>
        <b-col
          cols="3"
          class="text-right"
        >
          <b-button
            variant="primary"
            data-html2canvas-ignore="true"
            @click="exportPdf()"
          >
            {{ $t('student-report-module.report-pages.export-pdf') }}
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <vue-apex-charts
            width="500"
            type="bar"
            :options="options"
            :series="series"
          />
        </b-col>
        <b-col md="6">
          <div class="mt-2">
            <h5>
              {{ $t('student-report-module.summary') }}
            </h5>
            <strength-table
              class="mt-2"
              :title="$t('student-report-module.report-pages.skill')"
              :summary="getMinMax(scores, categories)"
            />
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <div class="light  pb-1 lead font-weight-bold">
        <b-row>
          <b-col md="4">
            <h5>
              {{ $t('student-report-module.report-pages.timeline-of-lesson-completion-rate') }}
            </h5>
          </b-col>
          <DateFilter
            :cols="8"
            @getFilteredDate="getAttendanceReportByDate"
          />
        </b-row>
        <em v-if="hasMissingExpectedLesson">
          <feather-icon
            icon="AlertTriangleIcon"
            class="text-danger"
            size="20"
          />
          {{ $t('student-report-module.report-pages.attention-teacher') }}
        </em>
      </div>
      <table class="table table-hover table-striped table-bordered table-sm">
        <thead>
          <tr>
            <th>
              {{ $t('student-report-module.report-pages.student-name') }}
            </th>
            <th
              v-for="n in monthList"
              :key="n"
            >
              {{ monthNames[n - 1] }}
              <feather-icon v-if="!expectedLessonDone[n]"
                            icon="AlertTriangleIcon"
                            class="text-danger"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(report, index) of groupByKey(lessonDoneReport, 'user_id')"
            :key="index"
          >
            <td>
              <b>{{ report[0].user.firstname }}</b>
            </td>
            <td
              v-for="m of monthList"
              :key="m"
              :set="data = getLessonMonthCumulativeReport(m, report)"
            >
              <div
                v-if="data"
                v-b-tooltip.hover.v-warning="{
                  title: `${data.lessonDoneScore }/${data.expectedScore}`,
                  html: true, placement: 'top'
                }"
              >
                {{ data.average }}%
              </div>
              <div v-else>
                -
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BCol, VBTooltip, BRow, BButton,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import useJwt from '@/auth/jwt/useJwt'
import DateFilter from '@/views/common/components/DateFilter.vue'
import i18n from '@/libs/i18n'
import StrengthTable from './StrengthTable.vue'
import { LessonCompletingCalc } from './helper/reportHelper'

export default {
  components: {
    BCard,
    VueApexCharts,
    StrengthTable,
    BCol,
    BRow,
    BButton,
    DateFilter,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    classRoom: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      lessonDoneReport: [],
      monthList: [],
      expectedLessonDone: [],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      ],
      categories: [],
      scores: [],
    }
  },
  computed: {
    series() {
      return [{
        name: 'series-1',
        data: this.scores,
      }]
    },
    hasMissingExpectedLesson() {
      return this.monthList.some(n => !this.expectedLessonDone[n])
    },

    options() {
      return {
        colors: ['#28c76f'],
        chart: {
          id: 'vuechart-example',
        },
        xaxis: {
          categories: this.categories,
        },
        dataLabels: {
          formatter(val) {
            return `${val}%`
          },
        },
      }
    },
  },
  mounted() {
    this.getAttendanceReport()
  },
  methods: {
    getMinMax(score, category) {
      const maxScore = Math.max(...score.map(s => Number(s)))
      const minScore = Math.min(...score.map(s => Number(s)))
      const weakest = []
      const strongest = []
      category.forEach((cat, index) => {
        const value = Number(score[index])
        if (value === maxScore) {
          strongest.push({
            fullName: cat,
            score,
          })
        } else if (value === minScore) {
          weakest.push({
            fullName: cat,
            score,
          })
        }
      })
      return [{
        title: i18n.tc('course-completion-module.table-header.completion-rate'),
        strongest,
        weakest,
      }]
    },
    getAttendanceReport() {
      useJwt.getGroupAcademicReport(this.classRoom.id).then(res => {
        const lessonDoneReport = res.data.data.lessonDone.score
        const expectedLessonDone = res.data.data.lessonDone.expected
        this.lessonDoneReport = lessonDoneReport
        this.expectedLessonDone = expectedLessonDone
        const monthList = [...new Set(this.lessonDoneReport.map(i => i.date_name))]
        monthList.sort((a, b) => a - b)
        this.monthList = monthList
        const { categories, totalScore } = LessonCompletingCalc({ lessonDoneReport, expectedLessonDone }, this)

        this.categories = categories
        this.scores = totalScore
      })
    },
    getLessonMonthCumulativeReport(month, report) {
      const prevExpectedScore = Object.keys(this.expectedLessonDone).filter(key => key < month).reduce((acc, key) => acc + this.expectedLessonDone[key], 0)
      const prevLessonDoneScore = report.filter(r => r.date_name <= month).reduce((c, p) => c + Number(p.total_lesson_done), 0)

      const expectedScore = Object.keys(this.expectedLessonDone).filter(key => key <= month).reduce((acc, key) => acc + this.expectedLessonDone[key], 0)
      const lessonDoneScore = report.filter(r => r.date_name <= month).reduce((c, p) => c + Number(p.total_lesson_done), 0)
      return {
        expectedScore,
        lessonDoneScore,
        average: (prevExpectedScore === expectedScore && prevLessonDoneScore === lessonDoneScore) ? '-' : ((lessonDoneScore / expectedScore) * 100).toFixed(2),
      }
    },
    exportPdf() {
      const element = document.getElementById('report-card')
      this.printPdf(element, 'lesson-completion-rate.pdf')
    },

    getAttendanceReportByDate(date) {
      useJwt.getGroupAcademicReport(this.classRoom.id,
        {
          params: {
            from: date.from,
            to: date.to,
          },
        }).then(res => {
        const lessonDoneReport = res.data.data.lessonDone.score
        this.lessonDoneReport = lessonDoneReport
        const monthList = [...new Set(this.lessonDoneReport.map(i => i.date_name))]
        monthList.sort((a, b) => a - b)
        this.monthList = monthList
      })
    },
  },
}
</script>
