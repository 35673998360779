<template>
  <div>

    <b-breadcrumb :items="breadcrumbItems" />
    <b-col
      md="12"
      class="px-0"
    >
      <b-card>
        <!-- Problem Type (Video or text) -->
        <b-form-group
          :label="$t('labels.create-problem.problem-type')"
          label-for="problem type"
        >
          <b-form-radio-group
            v-model="problemGroupType"
            :options="options"
            :disabled="activeStep > 0 ||!!activeCoordinate"
            name="problemType"
            class="mb-1"
          />
        </b-form-group>
        <!-- Problem Type (Video or text) -->
      </b-card>
      <b-row>
        <b-col
          v-if="problemGroupType === 'video'"
          md="4"
        >
          <video-type :timestamps="timestamps" />
        </b-col>
        <b-col
          v-else-if="problemGroupType==='hot-spot'"
          md="4"
        >
          <hot-spot
            ref="hotspotComponent"
          />
        </b-col>

        <b-col>
          <div v-if="['video'].includes(problemGroupType)">
            <b-button :variant="problemCreationType == 'new' ? 'primary' : 'outline-primary'"
                      class="mr-2"
                      @click="problemCreationType = 'new'"
            >
              Create Problem
            </b-button>
            <b-button :variant="problemCreationType == 'select-created' ? 'primary' : 'outline-primary'"
                      @click="problemCreationType = 'select-created'"
            >
              Select Problem
            </b-button>
          </div>
          <div v-if="problemCreationType === 'select-created'"
               class="mt-2"
          >
            <problem-picker :active-timestamp="activeTimestamp" />
          </div>
          <div v-else-if="lastCreatedProblem">
            <b-card v-if="activeCoordinate && activeCoordinate.problem_id!==null">
              {{ $t('messages.problem-created') }}
              <a :href="`/super/problem/edit?lesson_id=${$route.query.lesson_id}&problem_id=${lastCreatedProblem}`"
                 target="_blank"
              >
                <feather-icon icon="ExternalLinkIcon" />
              </a>
            </b-card>
            <div v-else>
              <div
                v-if="onlyProblemToShow ==='multiple'"
                :title="$t('problem-types.multiple-choice')"
                :active="activeType ==='multiple'"
                @click="setActiveType('multiple')"
              >
                <multiple-choice-form
                  :subject-list="subjectList"
                  @showQuestionPreview="showPreviewModal"
                  @questionCreated="questionCreated"
                  @ready="onReady"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <step-form
              class="m-2"
              :active-step="activeStep"
              @activeStep="probelmDetailIsValid ? activeStep = $event:null"
            />
            <b-card v-if="activeStep === 0">
              <span v-if="activeTimestampCreated || (activeCoordinate && activeCoordinate.problem_id!==null)">
                {{ $t('messages.problem-created') }}
              </span>
              <name-standard-form
                v-else-if="problemGroupType === 'text' || activeTimestamp || (activeCoordinate!=null && activeCoordinate.problem_id===null && !emptyPoints)"
                :level="0"
              />
              <div
                v-if="showNextButton"
                class="d-flex justify-content-end p-0"
              >
                <b-button
                  variant="primary"
                  :disabled="!probelmDetailIsValid"
                  @click="activeStep = 1"
                >
                  {{ $t("actions.next") }}
                </b-button>
              </div>
            </b-card>
            <b-row v-else>
              <b-col>
                <div v-if="problemGroupType === 'video'">
                  <b-card v-if="activeTimestampCreated">
                    {{ $t('messages.problem-created') }}
                  </b-card>
                  <b-tabs
                    v-else
                    pills
                    align="center"
                  >
                    <b-tab
                      :title="$t('problem-types.match-the-following')"
                      :active="activeType ==='matching'"
                      @click="setActiveType('matching')"
                    >
                      <matching-form
                        :subject-list="subjectList"
                        @showQuestionPreview="showPreviewModal"
                        @ready="onReady"
                        @previous="activeStep = 0"
                      />
                    </b-tab>
                    <b-tab
                      :title="$t('problem-types.fill-in-the-blanks')"
                      :active="activeType ==='blank'"
                      @click="setActiveType('blank')"
                    >
                      <fill-blanks-form
                        :subject-list="subjectList"
                        @showQuestionPreview="showPreviewModal"
                        @ready="onReady"
                      />
                    </b-tab>
                    <b-tab
                      :title="$t('problem-types.drag-position-form')"
                      :active="activeType ==='drag_position'"
                      @click="setActiveType('drag_position')"
                    >
                      <drag-position-form
                        :subject-list="subjectList"
                        @showQuestionPreview="showPreviewModal"
                        @ready="onReady"
                      />
                    </b-tab>
                    <b-tab
                      :title="$t('problem-types.multiple-choice')"
                      :active="activeType ==='multiple'"
                      @click="setActiveType('multiple')"
                    >
                      <multiple-choice-form
                        :subject-list="subjectList"
                        @showQuestionPreview="showPreviewModal"
                        @ready="onReady"
                      />
                    </b-tab>
                    <b-tab
                      :title="$t('problem-types.speech')"
                      :active="activeType ==='speech'"
                      @click="setActiveType('speech')"
                    >
                      <speech-recognition-form
                        :subject-list="subjectList"
                        @showQuestionPreview="showPreviewModal"
                        @ready="onReady"
                      />
                    </b-tab>
                    <b-tab
                      :title="$t('problem-types.category')"
                      :active="activeType ==='category'"
                      @click="setActiveType('category')"
                    >
                      <category-form
                        :subject-list="subjectList"
                        @showQuestionPreview="showPreviewModal"
                        @ready="onReady"
                      />
                    </b-tab>
                  </b-tabs>
                </div>
                <div v-else-if="problemGroupType==='hot-spot' ">
                  <div v-if="!emptyPoints || activeCoordinate!==null">
                    <b-card v-if="activeCoordinate && activeCoordinate.problem_id!==null">
                      {{ $t('messages.problem-created') }}
                    </b-card>
                    <b-tabs
                      v-else
                      pills
                    >

                      <b-tab
                        v-if="!onlyProblemToShow || activeType ==='matching'"
                        :title="$t('problem-types.match-the-following')"
                        :active="activeType ==='matching'"
                        @click="setActiveType('matching')"
                      >
                        <matching-form
                          :subject-list="subjectList"
                          @showQuestionPreview="showPreviewModal"
                          @questionCreated="questionCreated"
                          @ready="onReady"
                          @previous="activeStep = 0"
                        />
                      </b-tab>
                      <b-tab
                        v-if="!onlyProblemToShow || activeType ==='multiple'"
                        :title="$t('problem-types.multiple-choice')"
                        :active="activeType ==='multiple'"
                        @click="setActiveType('multiple')"
                      >
                        <multiple-choice-form
                          :subject-list="subjectList"
                          @showQuestionPreview="showPreviewModal"
                          @questionCreated="questionCreated"
                          @ready="onReady"
                        />
                      </b-tab>
                      <b-tab
                        v-if="!onlyProblemToShow || activeType ==='blank'"
                        :title="$t('problem-types.fill-in-the-blanks')"
                        :active="activeType ==='blank'"
                        @click="setActiveType('blank')"
                      >
                        <fill-blanks-form
                          :subject-list="subjectList"
                          @showQuestionPreview="showPreviewModal"
                          @ready="onReady"
                        />
                      </b-tab>
                      <b-tab
                        v-if="!onlyProblemToShow || activeType ==='drag_position'"
                        :title="$t('problem-types.drag-position-form')"
                        :active="activeType ==='drag_position'"
                        @click="setActiveType('drag_position')"
                      >
                        <drag-position-form
                          :subject-list="subjectList"
                          @showQuestionPreview="showPreviewModal"
                          @ready="onReady"
                        />
                      </b-tab>
                      <b-tab
                        v-if="!onlyProblemToShow || activeType ==='speech'"
                        :title="$t('problem-types.speech')"
                        :active="activeType ==='speech'"
                        @click="setActiveType('speech')"
                      >
                        <speech-recognition-form
                          :subject-list="subjectList"
                          @showQuestionPreview="showPreviewModal"
                          @questionCreated="questionCreated"
                          @ready="onReady"
                        />
                      </b-tab>
                      <b-tab
                        v-if="!onlyProblemToShow || activeType ==='category'"
                        :title="$t('problem-types.category')"
                        :active="activeType ==='category'"
                        @click="setActiveType('category')"
                      >
                        <category-form
                          :subject-list="subjectList"
                          @showQuestionPreview="showPreviewModal"
                          @ready="onReady"
                        />
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
                <b-tabs
                  v-else
                  pills
                  align="center"
                >
                  <b-tab
                    :title="$t('problem-types.match-the-following')"
                    :active="activeType ==='matching'"
                    lazy
                    @click="setActiveType('matching')"
                  >
                    <matching-form
                      :subject-list="subjectList"
                      @showQuestionPreview="showPreviewModal"
                      @ready="onReady"
                      @previous="activeStep = 0"
                    />
                  </b-tab>
                  <b-tab
                    :title="$t('problem-types.fill-in-the-blanks')"
                    :active="activeType ==='blank'"
                    lazy
                    @click="setActiveType('blank')"
                  >
                    <fill-blanks-form
                      :subject-list="subjectList"
                      @showQuestionPreview="showPreviewModal"
                      @ready="onReady"
                    />
                  </b-tab>
                  <b-tab
                    :title="$t('problem-types.drag-position-form')"
                    :active="activeType ==='drag_position'"
                    @click="setActiveType('drag_position')"
                  >
                    <drag-position-form
                      :subject-list="subjectList"
                      @showQuestionPreview="showPreviewModal"
                      @ready="onReady"
                    />
                  </b-tab>
                  <b-tab
                    :title="$t('problem-types.multiple-choice')"
                    :active="activeType ==='multiple'"
                    lazy
                    @click="setActiveType('multiple')"
                  >
                    <multiple-choice-form
                      :subject-list="subjectList"
                      @showQuestionPreview="showPreviewModal"
                      @ready="onReady"
                    />
                  </b-tab>
                  <b-tab
                    :title="$t('problem-types.speech')"
                    :active="activeType ==='speech'"
                    lazy
                    @click="setActiveType('speech')"
                  >
                    <speech-recognition-form
                      :subject-list="subjectList"
                      @showQuestionPreview="showPreviewModal"
                      @ready="onReady"
                    />
                  </b-tab>
                  <b-tab
                    :title="$t('problem-types.category')"
                    :active="activeType ==='category'"
                    lazy
                    @click="setActiveType('category')"
                  >
                    <category-form
                      :subject-list="subjectList"
                      @showQuestionPreview="showPreviewModal"
                      @ready="onReady"
                    />
                  </b-tab>
                  <b-tab
                    :title="$t('problem-types.open-end-writing')"
                    :active="activeType ==='open-end-writing'"
                    lazy
                    @click="setActiveType('open-end-writing')"
                  >
                    <open-end-writing-form
                      :subject-list="subjectList"
                      @showQuestionPreview="showPreviewModal"
                      @ready="onReady"
                    />
                  </b-tab>
                  <b-tab
                    :title="$t('problem-types.text-selection')"
                    :active="activeType ==='text_selection'"
                    lazy
                    @click="setActiveType('text_selection')"
                  >
                    <text-selection-form
                      :subject-list="subjectList"
                      @showQuestionPreview="showPreviewModal"
                      @ready="onReady"
                    />
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <question-preview
      :show="showPreview"
      :data="previewData"
      :show-hot-spot="problemGroupType==='hot-spot'"
      @close="showPreview = false"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BTabs, BTab, BBreadcrumb, BCard, BButton, BFormRadioGroup, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import i18n from '@/libs/i18n'
import { getIdFromURL } from 'vue-youtube-embed'
import { getUserData } from '@/auth/utils'
import NameStandardForm from '../components/create/NameStandardTheme.vue'
import MatchingForm from './matching-form/Index.vue'
import FillBlanksForm from './fill-blanks-form/Index.vue'
import MultipleChoiceForm from './multiple-choice-form/Index.vue'
import SpeechRecognitionForm from './speech-recognition-form/Index.vue'
import QuestionPreview from '../../../common/components/QuestionPreview.vue'
import StepForm from '../components/create/StepForm.vue'
import VideoType from '../components/create/VideoType.vue'
import HotSpot from './HotSpot.vue'
import CategoryForm from './category-form/Index.vue'
import OpenEndWritingForm from './open-end-writing-form/index.vue'
import DragPositionForm from './drag-position-form/index.vue'
import TextSelectionForm from './text-selection-form/index.vue'
import ProblemPicker from './ProblemPicker.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BButton,
    BCard,
    MatchingForm,
    FillBlanksForm,
    NameStandardForm,
    MultipleChoiceForm,
    SpeechRecognitionForm,
    BBreadcrumb,
    BFormRadioGroup,
    BFormGroup,
    QuestionPreview,
    StepForm,
    VideoType,
    HotSpot,
    CategoryForm,
    OpenEndWritingForm,
    TextSelectionForm,
    ProblemPicker,
    DragPositionForm,
  },
  directives: {
    Ripple,
  },
  props: {
    toggle: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    showPreview: false,
    previewData: {},
    breadcrumbItems: null,
    subjectList: null,
    options: [
      { text: 'Text Wrapper', value: 'text' },
      { text: 'Video Wrapper', value: 'video' },
      { text: 'Hot Spot', value: 'hot-spot' },
    ],
    createdProblemId: null,
    self: getUserData(),
    showCreateModal: false,
    problemCreationType: 'new',
    onlyProblemToShow: null,
  }),
  computed: {
    lastCreatedProblem() {
      return this.$store.state.problem.lastCreatedProblem
    },
    lessonName() {
      return this.$store.state.problem.lessonName
    },
    problemGroupType: {
      get() {
        return this.$store.state.problem.create.problemGroupType
      },
      set(value) {
        this.$store.commit('problem/SET_PROBLEM_GROUP_TYPE', value)
      },
    },
    activeType: {
      get() {
        return this.$store.state.problem.activeProblemType
      },
    },
    probelmDetailIsValid() {
      return this.$store.getters['problem/probelmDetailIsValid']
    },
    timestamps() {
      return this.$store.getters['problem/getTimestamps']
    },
    activeTimestamp() {
      return this.$store.state.problem.create.activeTimestamp
    },
    activePoint() {
      return this.$store.state.problem.create.activePoint
    },
    activeVideo() {
      return this.$store.state.problem.create.video
    },
    activeTimestampCreated() {
      return this.$store.getters['problem/activeTimestampCreated']
    },
    problemCreatedCoordinate() {
      return this.$store.state.problem.create.problemCreatedCoordinate
    },
    hasAnimatedLayout() {
      return this.$store.state.problem.create.hasAnimatedLayout
    },
    hotspot: {
      get() {
        return this.$store.state.problem.create.hotspot
      },
    },
    activeCoordinate: {
      get() {
        return this.$store.state.problem.create.activeCoordinate
      },
      set(value) {
        this.$store.commit('problem/SET_ACTIVE_COORDINATE', value)
      },
    },
    imageWidth: {
      get() {
        return this.$store.state.problem.create.imageWidth
      },
    },
    imageHeight: {
      get() {
        return this.$store.state.problem.create.imageHeight
      },
    },
    activeStep: {
      get() {
        return this.$store.state.problem.create.activeStep
      },
      set(value) {
        return this.$store.commit('problem/SET_ACTIVE_STEP', value)
      },

    },
    showNextButton() {
      if (this.problemGroupType === 'video') {
        return !this.activeTimeStampCreated
      }
      if (this.problemGroupType === 'hot-spot') return (this.activeCoordinate != null && this.activeCoordinate?.problem_id === null && !this.emptyPoints)
      return true
    },
    emptyPoints() {
      const { points } = this.$store.state.problem.create
      return points.length === 0
    },
  },
  watch: {
    hotspot: {
      handler(value) {
        if (value?.type === 'multiple' || value?.type === 'hotspot-drag') {
          this.$store.state.problem.activeProblemType = 'multiple'
          this.onlyProblemToShow = 'multiple'
          this.$forceUpdate()
        } else {
          this.onlyProblemToShow = null
        }
      },
      deep: true,
    },
    lessonName() {
      this.breadcrumbItems = [
        {
          text: this.lessonName,
          href: '/super/courses',
        },
        {
          text: i18n.tc('actions.problem'),
          href: `/${this.self.usertype}/problem?lesson_id=${this.$route.query.lesson_id}`,
        },
        {
          text: i18n.tc('create'),
          active: true,
        },
      ]
    },
    activeTimestamp() {
      if (this.activeStep > 0) {
        this.$store.commit('problem/SET_PROBLEM_FEILD', { name: 'standard_id', value: null })
        this.$store.commit('problem/SET_PROBLEM_FEILD', { name: 'name', value: null })
        this.$store.commit('problem/SET_PROBLEM_FEILD', { name: 'selectedDomain', value: null })
      }
      this.activeStep = 0
    },

    activeCoordinate() {
      if (this.activeCoordinate === null) this.activeStep = 0
    },
  },
  created() {
    this.$store.commit('problem/SET_LESSON_ID', this.$route.query.lesson_id)
    this.$store.dispatch('problem/getDomainByLesson', this.$route.query.lesson_id)
    this.$store.dispatch('problem/getCefrSubject')
    this.$store.dispatch('app/getLanguageLevelByCourse', {
      lesson_id: this.$route.query.lesson_id,
    })
    this.onReady()
    this.breadcrumbItems = [
      {
        text: this.lessonName,
        href: '/super/courses',
      },
      {
        text: i18n.tc('actions.problem'),
        href: `/super/problem?lesson_id=${this.$route.query.lesson_id}`,
      },
      {
        text: i18n.tc('create'),
        active: true,
      },
    ]
  },
  beforeDestroy() {
    this.$store.commit('app/SET_LANGUAGE_LEVEL_INDICATOR')
  },
  mounted() {
    this.$root.$off('onProblemCreate')
    this.$root.$on('onProblemCreate', param => {
      this.$swal.fire({
        title: 'Do you want to continue editing?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        allowOutsideClick: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$router.push({ path: `/${this.AUTH_USER().usertype}/problem/edit`, query: { lesson_id: param.lesson_id, problem_id: param.problem_id } })
        } else if (result.isDenied) {
          this.$router.push({ path: `/${this.AUTH_USER().usertype}/problem`, query: { lesson_id: param.lesson_id } })
        }
        this.$store.commit('problem/RESET')
      })
    })
  },
  methods: {
    showPreviewModal(data) {
      // eslint-disable-next-line no-param-reassign
      data.hasAnimatedLayout = this.hasAnimatedLayout
      // later rules based on problem types
      // eslint-disable-next-line no-param-reassign
      data.rules = {
        hide_hints: this.$store.state.problem.create.blank.hideAnswerHints,
        choice_type: this.$store.state.problem.create.multiple.answerChoice,
      }
      if (this.problemGroupType === 'video') {
        const matchIndex = this.timestamps.findIndex(t => t.id === this.activeTimestamp)
        let start = '0:0'
        if (matchIndex > 0) start = this.timestamps[matchIndex].timestamp.startTime
        const end = this.timestamps[matchIndex].timestamp.endTime

        const formattedVideo = {
          url: this.activeVideo.url, time: end, endTimeInMs: this.timeToMilliseconds(end), startTimeInMs: this.timeToMilliseconds(start), youtubeId: getIdFromURL(this.activeVideo.url),
        }
        this.previewData = { ...data, statement: { ...data.statement, video: formattedVideo } }
      } else if (this.problemGroupType === 'hot-spot') {
        let formattedHotspot = {}
        const { activeCoordinate } = this
        const coordinates = { x: ((activeCoordinate.x) / this.imageWidth), y: ((activeCoordinate.y) / this.imageHeight) }
        formattedHotspot = {
          file: this.hotspot.image,
          statement: data.statement.text,
          feedback: data.feedback.text,
          statement_audio: data.statement.audio,
          feedback_audio: data.feedback.audio,
          problem_type: data.problemType,
          hotspotPoints: {
            coordinates,
            statement_visited: false,
            feedback_visited: false,
            answered: false,
          },
          questions: data.questions,
        }
        this.previewData = { ...data, hotspot: formattedHotspot }
      } else this.previewData = data
      this.showPreview = true
    },
    questionCreated(id) {
      this.createdProblemId = id
    },

    onReady() {
      const appLoading = document.getElementById('loading-bg')
      if (appLoading) {
        appLoading.style.display = 'none'
      }
    },
    setActiveType(type) {
      this.$store.commit('problem/SET_ACTIVE_PROBLEM_TYPE', type)
    },
    timeToMilliseconds(duration) {
      const [minutes, seconds] = duration.split(':')
      const millisec = Number(minutes) * 60 + Number(seconds)
      return Number(millisec)
    },
  },
}
</script>

<style scoped>
.loading-spinner {
  margin-top: 20vh;
}
</style>

<style>
.ck-content {
  width: 100%;
}
</style>
