<template>
  <b-modal :visible="show"
           :title="$t('courses-module.batch-upload')"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <validation-observer ref="simpleRules">
      <b-form-group
        :label="$t('courses-module.upload-excel')"
      >
        <validation-provider
          v-slot="{ errors }"
          name="Excel File"
          rules="required"
        >
          <b-form-file ref="refInputEl1"
                       v-model="excelFile"
                       accept=".xlsx,.xls"
                       :browse-text="$t('browseButton')"
                       :placeholder="$t('issue-report-module.choose-a-file')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
    <template #modal-footer>
      <div class="d-flex  w-100 justify-content-between">
        <div class="float-left">
          <b-button variant="outline-secondary"
                    @click="downloadSample"
          >
            <b-spinner v-if="isProcessing"
                       small
            />  {{ $t('courses-module.sample') }}
          </b-button>
        </div>
        <div class="float-right">
          <b-button variant="success"
                    :disabled="isProcessing"
                    @click="handleOk"
          >
            <b-spinner v-if="isProcessing"
                       small
            />  {{ $t('actions.upload') }}
          </b-button>
          <b-button class="ml-1"
                    variant="outline-secondary"
                    @click="$emit('close')"
          >
            {{ $t('actions.cancel') }}
          </b-button>
        </div>
      </div>
    </template>
    <b-modal v-if="createdProblems.length"
             size="lg"
             :visible="true"
             title="Created Problems"
             ok-only
             body-class="p-0"
             @close="createdProblems = []; $emit('close')"
             @hide="createdProblems = []; $emit('close')"
    >
      <table class="table table-striped table-bordered mb-0">
        <thead>
          <tr>
            <th>Problem Name</th>
            <th>Problem Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="problem, index of createdProblems"
              :key="index"
          >
            <td>
              {{ problem.name }}
            </td>
            <td>
              {{ problem.problem_type?.name }}
            </td>
            <td>
              <b-button
                variant="flat-success"
                class="btn-icon rounded-circle"
                tag="a"
                :href="`/super/problem/edit?lesson_id=${problem.lesson_id}&problem_id=${problem.id}`"
                target="_blank"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </b-modal>
</template>
<script>
import {
  BModal, BFormFile, BFormGroup, BButton, BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'

export default {
  components: {
    BModal,
    BFormFile,
    BFormGroup,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isProcessing: false,
      excelFile: null,
      createdProblems: [],
      required,
    }
  },

  methods: {
    handleOk() {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isProcessing = true
          const data = new FormData()
          data.append('excel_file', this.$refs.refInputEl1.files[0])
          axios.post('/api/v1/problem-batch-upload', data, config).then(response => {
            this.showSuccessMessage(response)
            this.createdProblems = response.data.data.problems
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => {
            this.isProcessing = false
          })
        }
      })
    },
    downloadSample() {
      window.open('https://docs.google.com/spreadsheets/d/1U2Hls6_AG5awwyGlSQLqqq8U2btdZlz9A5mfimqi6C0/edit#gid=1783918510', '_blank')
    },
  },
}
</script>
