<template>
  <b-modal :visible="true"
           title="Calculator"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <div>
      <b-form-group label="Score">
        <b-input-group>
          <b-form-input v-model.lazy="score"
                        placeholder="Enter Cefr Score"
                        @change="updateMeasurementScore"
          />
          <b-input-group-append>
            <b-button variant="outline-primary"
                      @click="updateMeasurementScore"
            >
              Calculate
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <MeasuredScale v-if="measurementScore"
                     :key="measurementScore"
                     :score="measurementScore"
      />
    </div>
  </b-modal>
</template>
<script setup>
import {
  BModal, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import { ref } from 'vue'
import MeasuredScale from './MeasuredScale.vue'

const score = ref(null)
const measurementScore = ref(score.value)

const updateMeasurementScore = () => {
  measurementScore.value = score.value
}

</script>
