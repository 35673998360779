<template>
  <!-- create/edit class model -->
  <component
    :is="reservationInfo ? 'div' : 'b-modal'"
    id="modal-create-edit-class"
    ref="modal-create-edit-class"
    v-model="isVisible"
    ok-only
    size="lg"
    ok-variant="primary"
    :ok-title="$t('confirm')"
    modal-class="modal-primary"
    centered
    :title="getCreateClassTitle"
    @show="loadModal"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-col md="12">
      <b-tabs
        v-model="activeTab"
        content-class="mt-2"
      >
        <b-tab
          v-if="!isHighUser && ( !isEditingClass || newClass.type == 'default' || newClass.type == 'personal') "
          :title="$t('class-module.simple-class')"
          @click="tabChanged"
        >
          <validation-observer ref="createEditClassForm">
            <b-form
              @submit.prevent="isEditingClass ? updateClass() : createClass()"
            >
              <button
                type="submit"
                class="d-none"
              />
              <b-row>
                <!-- class-name -->
                <b-col md="12">
                  <b-form-group
                    :label="$t('class-module.name')"
                    label-for="name"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('class-module.name')"
                      rules="required|min:5|max:150"
                      vid="class-name"
                    >
                      <b-form-input
                        id="class-name"
                        v-model="newClass.name"
                        name="class-name"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('class-module.class-name')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- class-name -->
                <!-- grade-name -->
                <b-col md="12">
                  <b-form-group
                    :label="$t('class-module.grade')"
                    label-for="grade"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('class-module.grade')"
                      rules="required"
                      vid="grade"
                    >
                      <v-select
                        id="grade"
                        v-model="newClass.grade_id"
                        :placeholder="$t('class-module.all-grades')"
                        transition=""
                        :clearable="false"
                        label="name"
                        name="grade"
                        :reduce="(grade) => grade.id"
                        :options="gradesLists"
                        :state="errors.length > 0 ? false : null"
                        class="border"
                        @input="$forceUpdate()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <div
                    v-for="gradeIds in [newClass.grade_id]"
                    :key="'dyn-tab-' + gradeIds"
                  >
                    <b-form-group
                      :label="$t('class-module.course')"
                      label-for="course"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('class-module.course')"
                        rules="required"
                        vid="course_id"
                      >
                        <v-select
                          id="course"
                          v-model="courseSelectedByGrade[gradeIds]"
                          transition=""
                          :clearable="false"
                          name="course"
                          label="name"
                          :reduce="(course) => course.id"
                          :options="[...coursesForSelect(gradeIds)]"
                          :state="errors.length > 0 ? false : null"
                          class="border"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </b-col>
                <!-- course -->
                <!-- <b-col md="12">
                  <b-form-group
                    :label="$t('class-module.course')"
                    label-for="course"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Course"
                      rules="required"
                      vid="course_id"
                    >
                      <v-select
                        id="course"
                        v-model="newClass.course_id"
                        transition=""
                        :clearable="false"
                        name="course"
                        :reduce="(course) => course.code"
                        :options="coursesForSelect"
                        :state="errors.length > 0 ? false : null"
                        class="border"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                <!-- course -->

                <!-- School Name -->
                <b-col
                  v-if="!isAStudent && !reservationInfo"
                  md="6"
                >
                  <b-form-group
                    :label="$t('class-module.school')"
                    label-for="school"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('class-module.school')"
                      rules=""
                      vid="school_id"
                    >
                      <v-select
                        v-model="newClass.school_id"
                        transition=""
                        :clearable="false"
                        label="fullname"
                        :reduce="(school) => school.id"
                        :options="schoolsLists"
                        :state="errors.length > 0 ? false : null"
                        class="border"
                        :placeholder="$t('class-module.select-school')"
                        @input="onSchoolChange"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Add Teachers -->
                <b-col
                  v-if="!isAStudent"
                  md="6"
                >
                  <b-overlay :show="isTeacherLoading">
                    <b-form-group
                      :label="$t('class-module.teachers')"
                      label-for="teachers"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('class-module.teachers')"
                        rules="required"
                        vid="teacher_id"
                      >
                        <v-select
                          id="teachers"
                          v-model="selectedTeacher"
                          transition=""
                          :clearable="false"
                          name="teachers"
                          :options="teachersForSelect"
                          :reduce="(item) => item.code"
                          class="border"
                          :placeholder="$t('class-module.select-teacher')"
                          @input="onTeacherChange"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-overlay>
                </b-col>

                <!-- Add Students -->
                <b-col
                  v-if="!isAStudent && !isEditingClass && !reservationInfo"
                  md="6"
                >
                  <b-form-group
                    :label="$t('class-module.students')"
                    label-for="students"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('class-module.students')"
                      vid="student_id"
                    >
                      <v-select
                        id="students"
                        v-model="newClass.student_id"
                        transition=""
                        :clearable="false"
                        name="students"
                        multiple
                        :options="studentsForSelect"
                        :reduce="(item) => item && item.code"
                        :placeholder="$t('class-module.select-student')"
                        class="border"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Add Co-Teachers -->
                <b-col
                  v-if="!isAStudent"
                  md="6"
                >
                  <b-overlay :show="isTeacherLoading">
                    <b-form-group
                      :label="$t('class-module.co-teachers')"
                      label-for="co-teachers"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('class-module.co-teachers')"
                        vid="co_teacher_id"
                      >
                        <v-select
                          id="co-teachers"
                          v-model="coTeachers"
                          transition=""
                          :clearable="false"
                          name="co-teachers"
                          multiple
                          :options="coTeachersForSelect"
                          :reduce="(item) => item.code"
                          class="border"
                          :disabled="!selectedTeacher"
                          @input="onCoTeacherChange"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="!selectedTeacher"
                          class="text-muted"
                        >Please select a teacher first.</small>
                      </validation-provider>
                    </b-form-group>
                  </b-overlay>
                </b-col>
                <!-- student need teacher checkbox -->
                <b-col
                  v-if="isAStudent && mode == 'create'"
                  md="12"
                >
                  <b-form-checkbox
                    v-model="newClass.need_teacher"
                    :value="true"
                    class="custom-control-primary"
                    @change="onNeedTeacher"
                  >
                    {{ $t("class-module.need-teacher") }}
                  </b-form-checkbox>

                </b-col>
                <b-col
                  v-if="showSelectTeacher && !reservationInfo"
                  md="12 pt-1"
                >

                  <b-form-group
                    :label="$t('class-module.school')"
                    label-for="school"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('class-module.school')"
                      rules="required"
                      vid="school_id"
                    >
                      <v-select
                        v-model="newClass.school_id"
                        transition=""
                        :clearable="false"
                        label="fullname"
                        :reduce="(school) => school.id"
                        :options="schoolsLists"
                        :state="errors.length > 0 ? false : null"
                        class="border"
                        @input="onSchoolChange"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>

                <!-- student request new teacher checkbox if teacher has been assigned-->
                <b-col
                  v-if="isAStudent && mode == 'edit'"
                  md="12"
                >
                  <b-form-checkbox
                    v-model="newClass.need_teacher"
                    :value="true"
                    class="custom-control-primary"
                    @change="onNeedTeacher"
                  >
                    {{ $t("class-module.request-new-teacher") }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-tab>
        <b-tab
          v-if="!isAStudent && (!isEditingClass || newClass.type == 'custom')"
          :title="$t('class-module.custom-class')"
          @click="tabChanged"
        >
          <validation-observer ref="createEditCustomClassForm">
            <b-form
              @submit.prevent="validateLesson()"
            >
              <button
                type="submit"
                class="d-none"
              />
              <b-row>
                <!-- class-name -->
                <b-col md="12">
                  <b-form-group
                    :label="$t('class-module.name')"
                    label-for="name"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('class-module.name')"
                      rules="required|min:5|max:150"
                      vid="name"
                    >
                      <b-form-input
                        id="class-name"
                        v-model="newClass.name"
                        name="class-name"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('class-module.class-name')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- School Name -->
                <b-col
                  v-if="!reservationInfo"
                  md="6"
                >
                  <b-form-group
                    :label="$t('class-module.school')"
                    label-for="school"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('class-module.school')"
                      rules=""
                      vid="school_id"
                    >
                      <v-select
                        v-model="newClass.school_id"
                        transition=""
                        :clearable="false"
                        label="fullname"
                        :reduce="(school) => school.id"
                        :options="schoolsLists"
                        :state="errors.length > 0 ? false : null"
                        class="border"
                        @input="onSchoolChange"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Add Teachers -->
                <b-col
                  v-if="!isAStudent"
                  md="6"
                >
                  <b-overlay :show="isTeacherLoading">
                    <b-form-group
                      :label="$t('class-module.teachers')"
                      label-for="teachers"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('class-module.teachers')"
                        rules="required"
                        vid="teacher_id"
                      >
                        <v-select
                          id="teachers"
                          v-model="selectedTeacher"
                          transition=""
                          :clearable="false"
                          name="teachers"
                          :options="teachersForSelect"
                          :reduce="(item) => item.code"
                          class="border"
                          :placeholder="$t('class-module.select-teacher')"
                          @input="onTeacherChange"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-overlay>
                </b-col>

                <!-- Add Students -->
                <b-col
                  v-if="!isAStudent && !isEditingClass && !reservationInfo"
                  md="6"
                >
                  <b-form-group
                    :label="$t('class-module.students')"
                    label-for="students"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('class-module.students')"
                      vid="student_id"
                    >

                      <b-input-group>
                        <b-input-group-prepend>
                          <b-spinner
                            v-show="fetchingStudent"
                            small
                            style="position: absolute;top: 12px;left: 5px;"
                          />
                        </b-input-group-prepend>
                        <v-select
                          id="students"
                          v-model="newClass.student_id"
                          transition=""
                          :clearable="false"
                          name="students"
                          multiple
                          :options="studentsForSelect"
                          :reduce="(item) => item && item.code"
                          :placeholder="$t('class-module.select-student')"
                          class="border"
                          style="width:100%"
                        />
                      </b-input-group>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Add Co-Teachers -->
                <b-col
                  v-if="!isAStudent"
                  md="6"
                >
                  <b-overlay :show="isTeacherLoading">
                    <b-form-group
                      :label="$t('class-module.co-teachers')"
                      label-for="co-teachers"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('class-module.co-teachers')"
                        vid="co_teacher_id"
                      >
                        <v-select
                          id="co-teachers"
                          v-model="coTeachers"
                          transition=""
                          :clearable="false"
                          name="co-teachers"
                          multiple
                          :options="coTeachersForSelect"
                          :reduce="(item) => item.code"
                          class="border"
                          :disabled="!selectedTeacher"
                          :placeholder="$t('class-module.select-co-teacher')"
                          @input="onCoTeacherChange"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="!selectedTeacher"
                          class="text-muted"
                        >{{ $t('class-module.select-teacher-first') }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-overlay>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    :label="$t('class-module.grade')"
                    label-for="grade"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('class-module.grade')"
                      rules="required"
                      vid="grade"
                    >
                      <v-select
                        id="grade"
                        v-model="newClass.grade_id"
                        :placeholder="$t('class-module.all-grades')"
                        transition=""
                        :clearable="false"
                        label="name"
                        name="grade"
                        :reduce="(grade) => grade.id"
                        :options="gradesLists"
                        multiple
                        :state="errors.length > 0 ? false : null"
                        class="border"
                        @input="updateCourseLesson"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- class-name -->

                <b-col md="12">
                  <b-tabs>
                    <b-tab
                      v-for="gradeIds in newClass.grade_id"
                      :key="'dyn-tab-' + gradeIds"
                      :title="getGradeName(gradeIds)"
                    >
                      <b-form-group
                        :label="$t('class-module.course')"
                        label-for="course"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('class-module.course')"
                          rules="required"
                          vid="course_id"
                        >
                          <v-select
                            id="course"
                            v-model="courseSelectedByGrade[gradeIds]"
                            transition=""
                            :clearable="false"
                            name="course"
                            label="name"
                            :reduce="(course) => course.id"
                            multiple
                            :options="[...coursesForSelect(gradeIds)]"
                            :state="errors.length > 0 ? false : null"
                            class="border"
                            @input="getCourseLesson"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-col md="12">
                        <span class="text-muted"
                              style="font-size:13px; font-style: italic;"
                        >
                          <feather-icon icon="InfoIcon" />
                          {{ $t('class-module.add-course-info') }}
                        </span>
                      </b-col>
                      <b-col
                        v-if="courseSelectedByGrade[gradeIds] && courseSelectedByGrade[gradeIds].length > 0"
                        md="12"
                      >
                        <label for="">{{ $t("class-module.lessons") }}
                          <span class="text-muted ml-2"
                                style="font-size:13px; font-style: italic;"
                          >
                            <feather-icon icon="InfoIcon" />
                            {{ $t('class-module.add-class-lesson-info') }}
                          </span>
                        </label>
                        <app-collapse>
                          <b-row style="margin-top: 4px">
                            <b-col
                              v-for="(course, ind) in courseSelectedByGrade[gradeIds]"
                              :key="'course_' + ind"
                              md="6"
                              sm="12"
                              class="mb-1"
                            >
                              <app-collapse-item :title=" findCourseById(course).name">
                                <b-list-group v-if="courseLessons[course]">
                                  <b-list-group-item v-for="(groupedLesson, groupName) of groupByKey(courseLessons[
                                                       course
                                                     ],'groupName')"
                                                     :key="groupName"
                                                     class="flex-column align-items-start"
                                  >
                                    <div class="d-flex w-100 justify-content-between">
                                      <h5 class="mb-1">
                                        {{ groupName }}
                                      </h5>
                                    </div>
                                    <b-card-text v-for="(lesson, lessonIndex) in groupedLesson"
                                                 :key="'lesson_' + ind + lessonIndex"
                                                 class="mb-1"
                                    >
                                      {{
                                        lesson.name
                                      }}
                                    </b-card-text>
                                  </b-list-group-item>
                                </b-list-group>
                              </app-collapse-item>
                            <!-- <div class="col-md-offset-1 border p-1 rounded shadow">
                              <b-form-checkbox
                                class="d-inline-block"
                                :checked="allLessonSelectedCourses.includes(course)"
                                @change="(d) => selectAllFromCourse(course, d)"
                              />

                              <p
                                class="text-capitalize mb-0 d-inline-block"
                                style="font-weight: bold"
                              >
                                {{ findCourseById(course).name }}
                              </p>
                              <hr>
                              <ul
                                v-if="courseLessons[course]"
                                class="m-0 p-0"
                              >
                                <span
                                  v-for="(groupedLesson, groupName) of groupByKey(courseLessons[
                                    course
                                  ],'groupName')"
                                  :key="groupName"
                                  class="mb-1"
                                >
                                  <template>
                                    <li
                                      class="text-bold mb-1"
                                    >
                                      <b>{{ groupName }}</b>
                                    </li>
                                    <li
                                      v-for="(lesson, lessonIndex) in groupedLesson"
                                      :key="'lesson_' + ind + lessonIndex"
                                      style="margin: 4px 0"
                                    >
                                      <b-form-checkbox
                                        v-model="selectedCourseLessons"
                                        :value="lesson.id"
                                        :disabled="true"
                                        class="d-inline-block"
                                        @change="(d) => checkAllLessonSelectedCourses(course ,lesson.id)"
                                      />
                                      <span class="d-inline-block">{{
                                        lesson.name
                                      }}</span>
                                    </li>
                                  </template>
                                </span>
                              </ul>
                            </div> -->
                            </b-col>
                          </b-row>
                        </app-collapse>
                        <input
                          v-model="selectedCourseLessons"
                          name="register-phone"
                          type="hidden"
                        >
                      </b-col>
                    </b-tab>
                  </b-tabs>
                </b-col>
                <input
                  v-model="selectedCourseLessons"
                  name="register-phone"
                  type="hidden"
                >
              </b-row>
            </b-form>
          </validation-observer>
        </b-tab>

      </b-tabs>
    </b-col>
    <template #modal-footer="{}">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="isProcessing"
            @click="validateLesson"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{
              isEditingClass
                ? $t("class-module.update-class")
                : $t("class-module.create-class")
            }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </component>
  <!-- create/edit class model -->
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BTabs,
  BTab,
  BOverlay,
  BListGroup,
  BListGroupItem,
  BCardText,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData, isHighLevelUser } from '@/auth/utils'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { USER_TYPE_STUDENT, USER_TYPE_TEACHER } from '@/const/userType'
import useApollo from '@/@core/graphql/useApollo'

export default {
  components: {
    BModal,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BTabs,
    BTab,
    BOverlay,
    AppCollapse,
    AppCollapseItem,
    BListGroup,
    BListGroupItem,
    BCardText,
    BInputGroup,
    BInputGroupPrepend,
  },
  directives: {
    Ripple,
  },
  props: {
    editClass: {
      type: [Object, Array],
      default: null,
    },
    // courses: {
    //   type: Array,
    //   required: true,
    // },
    teachers: {
      type: Array,
      default: () => [],
    },
    students: {
      type: [Array, Object],
      required: false,
      default: () => {},
    },
    mode: {
      type: String,
      default: 'create',
    },
    show: {
      type: Boolean,
      required: true,
    },
    schoolsLists: {
      type: [Array, Object],
      default: () => [],
    },
    allSchools: {
      type: [Array, Object],
      default: () => [],
    },
    parentSchools: {
      type: [Array, Object],
      default: () => [],
    },
    reservationInfo: {
      type: Object,
      default: () => null,
    },
    fetchingStudent: {
      type: Boolean,
    },

  },

  data() {
    return {
      newClass: { ...this.reservationInfo },
      isProcessing: false,
      self: getUserData(),
      isVisible: false,
      courseLessons: {},
      selectedCourseLessons: [],
      activeTab: isHighLevelUser(8) ? 1 : 0,
      allLessonSelectedCourses: [],
      selectedCourseAndLessons: {},
      lessonRequired: false,
      teachersList: null,
      isLoading: false,
      courses: [],
      isTeacherLoading: false,
      isHighUser: isHighLevelUser(8),
      selectedTeacher: null,
      coTeachers: null,
      showSelectTeacher: false,
      gradesLists: [],
      courseSelectedByGrade: {},
    }
  },
  computed: {
    isCustomClass() {
      if (this.isHighUser) {
        return true
      }
      return this.activeTab === 1
    },
    selectedCourses() {
      if (!this.newClass.course_id) return []
      return Array.isArray(this.newClass.course_id)
        ? this.newClass.course_id
        : [this.newClass.course_id]
    },
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
    isEditingClass() {
      return this.mode === 'edit'
    },
    getCreateClassTitle() {
      return this.isEditingClass
        ? this.$i18n.tc('actions.update-class')
        : this.$i18n.tc('actions.create-class')
    },
    teachersForSelect() {
      return this.teachersList
        ? this.teachersList.map(e => ({
          code: Number(e.id),
          label: e.fullname,
        })) : []
    },
    coTeachersForSelect() {
      return this.selectedTeacher && this.teachersList
        ? this.teachersList
          .map(e => ({
            code: Number(e.id),
            label: e.fullname,
          }))
          .filter(teacher => teacher.code !== this.selectedTeacher)
        : []
    },
    studentsForSelect() {
      const students = []
      if (this.students.data) {
        this.students.data.forEach(e => {
          if (!students.find(std => std && std.code === e.id)) {
            students.push({
              code: e.id,
              label: e.fullname ? e.fullname : `${e.firstname} ${e.lastname}`,
            })
          }
        })
      }
      return students
    },
  },
  watch: {
    show(newValue) {
      this.isVisible = this.show
      if (newValue) {
        this.getGrades()
      }
    },
    courseSelectedByGrade: {
      handler(newValue) {
        let newCourses = []
        Object.entries(newValue).forEach(([, value]) => {
          if (Array.isArray(value)) {
            // eslint-disable-next-line no-param-reassign
            value = value.map(v => Number(v))
            newCourses = [...newCourses, ...value]
          } else {
            newCourses = value
          }
        })
        this.newClass.course_id = newCourses
      },
      deep: true,
    },
    students() {
      if (Array.isArray(this.newClass.student_id)) {
        const allStudents = this.students.data.map(student => student.id)
        const studentIds = this.newClass.student_id.filter(student => allStudents.includes(student))
        this.newClass.student_id = studentIds
      }
    },
    // eslint-disable-next-line func-names
    editClass() {
      if (this.mode === 'edit') {
        if (this.editClass) {
          this.newClass = JSON.parse(JSON.stringify(this.editClass))
          if (this.editClass.course.length) {
            this.newClass.course_id = Number(this.editClass.course[0].id)
            this.newClass.course_name = this.editClass.course[0].name
          }
          this.newClass.school_id = Number(this.newClass.school_id)
          this.selectedTeacher = this.newClass.teacher_id
          this.coTeachers = this.newClass.co_teachers
          if (this.newClass.type === 'custom') {
            const selectedCourses = this.newClass.courses ? this.newClass.courses.map(e => parseInt(e.id, 10)) : []
            this.getCourseLesson(selectedCourses)
            // to get unique courses only
            this.$set(this.newClass, 'course_id', [...new Set(selectedCourses)])
            this.selectedCourseLessons = this.newClass.lessons ? this.newClass.lessons.map(e => e.id) : []
            // this.selectedCourseLessons = this.newClass.lessons.map(e => e.id)
            this.filterSelectedLesson()
            this.checkAllLessonSelectedCourses()
          }
          this.getTeachersOfClass(this.newClass.id)
        }
      } else this.resetNewClass()
    },
    courses() {
      if (this.newClass.course_id === null && this.mode !== 'edit') {
        this.newClass.course_id = this.courses.length > 0 ? this.courses[0].id : null
      }
      if (this.courses.length > 0 && !this.courseLessons[this.courses[0].id]) {
        this.getCourseLesson([this.courses[0].id])
      }
    },
    teachers() {
      if (this.newClass.teacher_id === null) {
        this.newClass.teacher_id = this.teachers && this.teachers.length
          ? this.teachers[0].id
          : null
      }
    },
    schoolsLists() {
      if (!this.newClass.school_id && this.schoolsLists.length) {
        this.newClass.school_id = this.schoolsLists[0].id
        this.$nextTick(() => { this.getTeachers() })
      }
    },
  },
  mounted() {
    if (!this.reservationInfo) {
      this.resetNewClass()
    }
  },
  methods: {
    updateCourseLesson(ids) {
      const courseSelectedByGrade = {}
      let courseIds = []
      ids.forEach(id => {
        const courseSelected = this.courseSelectedByGrade[id] || []
        courseSelectedByGrade[id] = courseSelected
        courseIds = [...courseIds, ...courseSelected]
      })
      this.courseSelectedByGrade = courseSelectedByGrade
      this.getCourseLesson(courseIds)
      this.$forceUpdate()
    },
    coursesForSelect(grade) {
      return this.gradesLists.find(grd => Number(grd.id) === Number(grade))?.courses || []
    },
    getGradeName(grade) {
      return this.gradesLists.find(grd => Number(grd.id) === Number(grade))?.name
    },
    validateLesson() {
      let validated = true
      Object.keys(this.selectedCourseAndLessons).forEach(course => {
        if (this.selectedCourseAndLessons[course].length <= 0) {
          validated = false
        }
      })
      Object.keys(this.courseSelectedByGrade).forEach(grade => {
        if (this.courseSelectedByGrade[grade].length === 0) {
          validated = false
          this.showInfo(`Please select at least a course under grade ${this.getGradeName(grade)}`)
        }
      })
      if (!validated && this.activeTab === 1) {
        this.lessonRequired = true
        this.showInfo('Please select at least a lesson')
      } else { this.handleSubmit() }
    },
    setUpGrades() {
      let selectedGrades = []
      const selectGradeWithCourse = {}
      if (Array.isArray(this.newClass.course_id)) {
        selectedGrades = this.gradesLists.filter(grade => grade.courses?.find(course => this.newClass.course_id.includes(course.id)))
        selectedGrades.forEach(grade => {
          const courses = grade.courses || []
          selectGradeWithCourse[grade.id] = courses?.filter(course => this.newClass.course_id.includes(course.id)).map(course => course.id)
        })
      } else {
        selectedGrades = this.gradesLists.filter(grade => grade.courses?.find(course => Number(this.newClass.course_id) === Number(course.id)))
        selectedGrades.forEach(grade => {
          const courses = grade.courses || []
          selectGradeWithCourse[grade.id] = courses?.filter(course => Number(this.newClass.course_id) === Number(course.id)).map(course => course.id)
        })
      }
      this.courseSelectedByGrade = selectGradeWithCourse
      this.newClass.grade_id = selectedGrades.map(grade => grade.id)
    },
    getGrades() {
      this.isLoading = true
      useJwt.getGradeLists().then(response => {
        this.gradesLists = [...response.data.data?.grades]
        let filteredCourse = []
        this.gradesLists.forEach(grade => {
          if (grade.courses && grade.courses.length) {
            filteredCourse = [...filteredCourse, ...(grade.courses || [])]
          }
        })
        this.courses = filteredCourse
        // this.getCourses()
        this.setUpGrades()
      }).catch(error => {
        this.showError('Failed to load grades')
        console.error(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
    // getCourses() {
    //   let filteredCourse = []
    //   let filteredGrades = []
    //   const gradeIds = this.newClass.grade_id || []
    //   if (gradeIds && gradeIds.length > 0) {
    //     filteredGrades = this.gradesLists.filter(grade => gradeIds.includes(grade.id))
    //   } else {
    //     filteredGrades = [...this.gradesLists]
    //   }
    //   filteredGrades.forEach(grade => {
    //     filteredCourse = [...filteredCourse, ...(grade.courses || [])]
    //   })
    //   this.courses = filteredCourse
    //   if (Array.isArray(this.newClass.course_id)) {
    //     this.newClass.course_id = filteredCourse.filter(course => this.newClass.course_id.includes(course.id)).map(course => course.id)
    //   }
    // },
    viewLessons(id, classId) {
      this.$router.push({
        name: `${this.self.usertype}-lessons`,
        params: { id, classId },
      })
    },
    tabChanged() {
      this.$nextTick(() => {
        // eslint-disable-next-line prefer-destructuring
        if (Array.isArray(this.newClass.course_id)) this.newClass.course_id = this.newClass.course_id[0]
        else this.newClass.course_id = [this.newClass.course_id]
        // eslint-disable-next-line prefer-destructuring

        if (this.activeTab === 0 && !Array.isArray(this.newClass.grade_id)) {
          this.newClass.grade_id = this.newClass.grade_id ? [this.newClass.grade_id] : []
        // eslint-disable-next-line prefer-destructuring, no-self-assign
        } else { this.newClass.grade_id = this.newClass.grade_id }

        this.$forceUpdate()
      })
    },
    selectAllFromCourse(id, isChecked) {
      if (!this.courseLessons[id]) {
        return
      }
      this.courseLessons[id].forEach(e => {
        const indexOfLesson = this.selectedCourseAndLessons[id].indexOf(e.id)
        if (isChecked && indexOfLesson < 0) this.selectedCourseAndLessons[id].push(e.id)
        else if (!isChecked && indexOfLesson >= 0) this.selectedCourseAndLessons[id].splice(indexOfLesson, 1)

        const indexOfElement = this.selectedCourseLessons.indexOf(e.id)
        if (isChecked && indexOfElement < 0) this.selectedCourseLessons.push(e.id)
        else if (!isChecked && indexOfElement >= 0) this.selectedCourseLessons.splice(indexOfElement, 1)
      })
      this.checkAllLessonSelectedCourses()
    },
    checkAllLessonSelectedCourses(course = null, lesson = null) {
      if (course) {
        const indexOfElement = this.selectedCourseAndLessons[course].indexOf(lesson)
        if (indexOfElement < 0) this.selectedCourseAndLessons[course].push(lesson)
        else if (indexOfElement >= 0) this.selectedCourseAndLessons[course].splice(indexOfElement, 1)
      }
      this.allLessonSelectedCourses = []
      const courses = this.newClass.course_id
      if (Array.isArray(courses)) {
        courses.forEach(id => {
          if ((this.courseLessons[id]?.map(e => e.id))?.every(v => this.selectedCourseLessons.includes(v))) this.allLessonSelectedCourses.push(id)
        })
      }
    },
    handleSubmit() {
      if (this.isEditingClass) this.updateClass()
      else this.createClass()
    },
    findCourseById(id) {
      // eslint-disable-next-line eqeqeq
      const ind = this.courses.findIndex(e => Number(e.id) == Number(id))
      return ind > -1 ? this.courses[ind] : { name: '' }
    },
    resetNewClass() {
      this.newClass = {
        name: '',
        course_id: this.courses.length > 0 ? this.courses[0].id : null,
      }

      this.newClass.school_id = this.schoolsLists && this.schoolsLists.length
        ? this.schoolsLists[0].id
        : null
      this.onSchoolChange()

      this.selectedTeacher = this.teachers && this.teachers.length
        ? this.teachers[0].id
        : null

      this.allLessonSelectedCourses = []
      this.selectedCourseLessons = []
      if (this.self.usertype === USER_TYPE_TEACHER) {
        if (this.schoolsLists.length > 1) {
          this.selectedTeacher = null
        } else {
          this.selectedTeacher = this.self.id
          this.getStudents([this.selectedTeacher])
        }
      }
      if (this.self.usertype === USER_TYPE_STUDENT) { this.newClass.need_teacher = false }
      this.getCourseLesson([this.newClass.course_id])
    },
    getCourseLesson(ids) {
      setTimeout(() => {
        ids.forEach(element => {
          if (element && !(Object.prototype.hasOwnProperty.call(this.selectedCourseAndLessons, element))) {
            this.selectedCourseAndLessons = {
              ...this.selectedCourseAndLessons,
              [element]: [],
            }
          }
          this.$nextTick(() => {
            Object.keys(this.selectedCourseAndLessons).forEach(course => {
              if (this.selectedCourses.indexOf(parseInt(course, 10)) === -1) {
                this.$delete(this.selectedCourseAndLessons, course)
              }
            })
          })
          if (!this.courseLessons[element]) {
            useJwt.getLessonsForCourses({ course_id: [element] }).then(res => {
              if (res.data.data[0] && res.data.data[0].shared_lessons) {
                this.$set(this.courseLessons, element, res.data.data[0].shared_lessons.map(lesson => ({ ...lesson, groupName: lesson.lesson_group ? lesson.lesson_group.name : '' })))
                this.checkAllLessonSelectedCourses()
                this.$forceUpdate()
                this.selectAllFromCourse(element, true)
              }
            })
          } else if (element) {
            this.selectAllFromCourse(element, true)
          }
        })
      }, 100)
    },
    createClass() {
      // eslint-disable-next-line no-spaced-func
      const activeClassNumber = (this.isCustomClass ? 1 : 0);
      // eslint-disable-next-line no-use-before-define, no-unexpected-multiline
      (activeClassNumber === 0 ? this.$refs.createEditClassForm : this.$refs.createEditCustomClassForm)
        .validate()
        .then(success => {
          if (success) {
            // console.log(this.newClass.student_id.map(e => e.code))
            console.log(this.newClass.student_id)
            const data = {
              name: this.newClass.name,
              grade_id: this.newClass.grade_id,
              // eslint-disable-next-line no-nested-ternary
              course_id: activeClassNumber === 0 ? (Array.isArray(this.newClass.course_id) ? this.newClass.course_id[0] : this.newClass.course_id) : null,
              lesson_id: this.selectedCourseLessons,
              type: activeClassNumber === 0 ? 'default' : 'custom',
              courses: activeClassNumber === 1 ? this.selectedCourseAndLessons : null,
              school_id: this.schoolsLists.length === 1 ? this.schoolsLists[0].id : this.newClass.school_id,
            }
            if (!this.isAStudent) {
              data.teacher_id = this.selectedTeacher
              data.student_id = this.newClass.student_id
              data.co_teachers = this.coTeachers
            } else {
              data.need_teacher = this.newClass.need_teacher
            }
            this.isProcessing = true
            useJwt
              .createClass(data)
              .then(response => {
                if (this.reservationInfo) {
                  this.$emit('handleClassCreated', response.data.data)
                  return
                }
                this.$refs['modal-create-edit-class'].hide()
                this.resetNewClass()
                this.showSuccessMessage(response)
                this.$emit('onClassCreated', response.data.data.type)
                this.$emit('handleClassCreated', response.data.data)
              })
              .catch(error => {
                this.$emit('classRoomCreateFailed')
                if (error.response?.data?.errors) {
                  this.showErrorMessage(error.response.data.errors?.name?.join(' '))
                } else this.showErrorMessage(error)
              })
              .finally(() => {
                this.isProcessing = false
              })
          }
        })
    },
    resendTeacherRequestEmail() {
      this.isProcessing = true
      useJwt
        .resendTeacherRequestEmail({
          class_id: this.editClass.id,
        })
        .then(response => {
          this.showSuccessMessage(response)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },

    updateClass() {
      (this.newClass.type === 'default' || this.newClass.type === 'personal'
        ? this.$refs.createEditClassForm
        : this.$refs.createEditCustomClassForm).validate().then(success => {
        if (success) {
          this.isProcessing = true
          const getCourseId = courseId => (Array.isArray(courseId) ? courseId[0] : courseId)
          const data = {
            name: this.newClass.name,
            course_id: this.newClass.type === 'default' ? getCourseId(this.newClass.course_id) : null,
            id: this.newClass.id,
            lesson_id: (this.newClass.type === 'custom' && this.newClass.grade_id?.length !== 0) ? this.selectedCourseLessons : [],
            courses: (this.newClass.type === 'custom' && this.newClass.grade_id !== null && this.newClass.grade_id?.length !== 0) ? this.selectedCourseAndLessons : null,
            school_id: this.newClass.school_id,
          }
          if (!this.isAStudent) {
            data.teacher_id = this.selectedTeacher
            data.co_teachers = this.coTeachers
          } else {
            data.need_teacher = this.newClass.need_teacher
          }

          useJwt
            .updateClass(this.newClass.id, data)
            .then(response => {
              this.showSuccessMessage(response)
              this.$refs['modal-create-edit-class'].hide()
              this.$emit('onClassUpdated')
            })
            .catch(error => {
              if (error.response?.data?.errors) {
                this.$refs.createEditClassForm.setErrors(
                  error.response.data.errors,
                )
              } else this.showErrorMessage(error)
            })
            .finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
    filterSelectedLesson() {
      this.selectedCourseLessons = []
      if (this.newClass.isModern) {
        this.newClass.coursesLesson.forEach(c => {
          const { lessons } = c
          this.selectedCourseAndLessons[c.course_id] = lessons
          lessons.forEach(lesson => {
            this.selectedCourseLessons.push(lesson)
          })
        })
      } else {
        this.newClass.courses.forEach(c => {
          const lessons = JSON.parse(c.pivot.lessons)
          this.selectedCourseAndLessons[c.id] = lessons
          lessons.forEach(lesson => {
            this.selectedCourseLessons.push(lesson)
          })
        })
      }
    },
    onSchoolChange() {
      this.newClass.teacher_id = null
      this.getTeachers()
      this.newClass.student_id = []
    },
    onTeacherChange() {
      this.newClass.student_id = []
      this.getStudents([this.selectedTeacher])
      if (this.coTeachers.includes(this.selectedTeacher)) {
        const index = this.coTeachers.indexOf(this.selectedTeacher)
        this.coTeachers.splice(index, 1)
      }
      // this.coTeachers = []
    },
    onCoTeacherChange() {
      const teachers = [...this.coTeachers, this.selectedTeacher]
      this.getStudents(teachers)
    },
    getStudents(teacherId) {
      this.$emit('getStudents', teacherId)
    },
    getTeachers() {
      if (this.isEditingClass) this.isLoading = true

      useApollo.getUsersOfSchool('teacher', this.newClass.school_id).then(response => {
        this.teachersList = response.data?.users?.data
      }).finally(() => { this.isLoading = false })
    },
    getTeachersOfClass(id) {
      this.isTeacherLoading = true
      useJwt.getTeachersOfClass(id).then(response => {
        this.selectedTeacher = response.data?.data[0]
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => { this.isTeacherLoading = false })
    },
    onNeedTeacher() {
      this.showSelectTeacher = !this.showSelectTeacher
      if (!this.schoolsLists.length) this.$emit('getSchools')
    },
    loadModal() {
      if (!this.isATeacher && this.isEditingClass) {
        this.isLoading = true
        this.getTeachers()
      } else if (this.isATeacher && !Object.values(this.teachersForSelect).length) {
        this.isLoading = true
        setTimeout(() => { this.isLoading = false }, 600)
      }
    },
  },
}
</script>

<style>
  #modal-create-edit-class .modal-title {
    color: #a9a2f6 !important;
  }
  .border .vs__dropdown-menu {
    border: 1px solid #7367f0 !important;
  }
</style>
