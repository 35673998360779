<template>
  <b-card>
    <div class="mb-2">
      <b-form-select v-model="hotspot.type"
                     :options="hotSpotTypes"
                     label="label"
                     :disabled="!!hotspot?.id"
      />
    </div>
    <canvas
      id="myCanvas"
      :height="canvasHeight"
      :width="canvasWidth"
      :style="'background:url('+image+');'"
    />
    <input
      ref="imageInput"
      type="file"
      style="display:none;"
      @change="previewImage"
    >

    <div class="pt-2 text-right">
      <b-button
        variant="outline-primary"
        @click="$refs.imageInput.click()"
      >
        <feather-icon icon="UploadIcon" />
        {{ $t('actions.upload-image') }}
      </b-button>
    </div>

    <!-- <div class="d-flex justify-content-end p-0">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="btn-icon mt-3 ml-auto"
        @click="toggle = true"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t("Add-Points") }}
      </b-button>
    </div> -->
    <!-- Points -->
    <vue-good-table
      :columns="columns"
      :rows="points"
      theme="my-theme"
      class="py-2 grade-table"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <div
          v-if="props.column.field === 'point'"
          :class="{ 'active-column': activeCoordinate ? props.row.x === activeCoordinate.x && props.row.y === activeCoordinate.y : '' } "
          @click="setActivePoint(props.row)"
        >
          {{ props.row.originalIndex+1 }}
        </div>
        <span
          v-if="props.column.field === 'action'"
          class="d-flex justify-content-center"
          :class="{ 'active-column': activeCoordinate ? props.row.x === activeCoordinate.x && props.row.y === activeCoordinate.y : '' }"
        >
          <b-button
            variant="outline-danger"
            class="btn-icon sm-mt-2"
            @click="removePoints(props.row)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </span>
      </template>
    </vue-good-table>
    <delete-modal
      name="modal-delete-network"
      :on-delete="confirmChange"
      :title="$t('messages.change-point')"
      :sub-title="$t('messages.change-point-warning')"
      :show="showDeleteModal"
      @close="showDeleteModal=false"
    />
  </b-card>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BButton, BCard, BFormSelect,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import DeleteModal from '@/views/common/components/DeleteModal.vue'

let defaultWidth = 400

export default {
  components: {
    BButton,
    BCard,
    VueGoodTable,
    DeleteModal,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    hotspotName: '',
    canvasHeight: 400,
    canvasWidth: 400,
    preview: null,
    image: null,
    canvas: null,
    radius: 8,
    isMouseDown: null,
    mousePosition: null,
    pointBeingDragged: null,
    activePointIndex: null,
    existingHotspot: null,
    showDeleteModal: false,
    changingPoint: null,
    columns: [
      {
        label: 'Points',
        field: 'point',
      },
      {
        label: 'Actions',
        field: 'action',
        category: false,
        thClass: 'text-center',
      },
    ],
    toggle: false,
    hotSpotTypes: [
      {
        text: 'Normal HotSpot',
        value: null,
      },
      {
        text: 'HotSpot Drag',
        value: 'hotspot-drag',
      },
      {
        text: 'HotSpot Multiple',
        value: 'multiple',
      },
    ],
    // points: [],
  }),

  computed: {
    activePoint() {
      return this.$store.state.problem.create.activePoint
    },
    activeCoordinate: {
      get() {
        return this.$store.state.problem.create.activeCoordinate
      },
      set(value) {
        this.$store.commit('problem/SET_ACTIVE_COORDINATE', value)
      },
    },
    hotspot: {
      get() {
        return this.$store.state.problem.create.hotspot
      },
      set(value) {
        this.$store.commit('problem/SET_HOTSPOT', value)
      },
    },
    points: {
      get() {
        return this.$store.state.problem.create.points
      },
      set(value) {
        this.$store.commit('problem/SET_POINTS', value)
      },
    },
    imageHeight: {
      get() {
        return this.$store.state.problem.create.imageHeight
      },
      set(value) {
        this.$store.commit('problem/SET_IMAGE_HEIGHT', value)
      },
    },
    imageWidth: {
      get() {
        return this.$store.state.problem.create.imageWidth
      },
      set(value) {
        this.$store.commit('problem/SET_IMAGE_WIDTH', value)
      },
    },
    isEditing: {
      get() {
        return this.$store.state.problem.create.isEditing
      },
      set(value) {
        this.$store.commit('problem/SET_IS_EDITING', value)
      },
    },
  },

  watch: {
    isEditing() {
      this.draw()
    },
  },

  mounted() {
    const myCanvas = document.getElementById('myCanvas')
    this.canvas = myCanvas.getContext('2d')
    myCanvas.addEventListener('click', e => { if (this.image !== null) this.getCordinates(e) })
    myCanvas.addEventListener('mousedown', e => { this.setDraggable(e, true) })
    myCanvas.addEventListener('mouseup', e => { this.setDraggable(e, false) })
    myCanvas.addEventListener('mousemove', this.mouseMove)
    window.addEventListener('resize', this.resizeWindow)
    this.resizeWindow()
  },

  methods: {
    previewImage(event) {
      const reader = new FileReader()
      reader.onload = e => {
        const img = new Image()
        img.onload = () => {
          const aspectRatio = img.width / img.height

          this.canvasWidth = (aspectRatio) * defaultWidth
          const resizeFactor = this.canvasWidth > defaultWidth ? this.canvasWidth / defaultWidth : 1
          this.canvasWidth /= resizeFactor
          this.canvasHeight = defaultWidth / resizeFactor
          this.imageWidth = this.canvasWidth
          this.imageHeight = this.canvasHeight
        }
        img.src = e.target.result
        this.image = e.target.result
      }
      reader.readAsDataURL(event.target.files[0])
      this.saveHotspot()
    },
    getCordinates(e) {
      const rect = document.getElementById('myCanvas').getBoundingClientRect()
      const x = e.clientX - rect.left
      const y = e.clientY - rect.top
      const checkPoint = this.checkPoints(x, y)
      if (!checkPoint.isDragging) {
        this.drawCircle(x, y)
        const newPoint = this.points.push({
          x,
          y,
          problem_id: null,
        }) - 1
        if (this.activeCoordinate === null) {
          this.activeCoordinate = {
            x,
            y,
            problem_id: null,
            originalIndex: newPoint,
          }
          this.isEditing = true
        }
      }

      this.draw({ x, y }, checkPoint.matchPoint)
    },

    normalize(val, max, min) { return (val - min) / (max - min) },

    drawCircle(point, index) {
      this.canvas.beginPath()
      this.canvas.fillStyle = point.id && point.id !== null ? 'red' : 'green'
      this.canvas.font = '12px '
      this.canvas.lineWidth = 20
      this.canvas.arc(point.x, point.y, this.radius, 0, 2 * Math.PI, false)
      this.canvas.fill()
      this.canvas.beginPath()
      this.canvas.fillStyle = 'white'
      const overFlow = index > 10 ? 4 : 2
      this.canvas.fillText(index + 1, point.x - overFlow, point.y + 2)
      this.canvas.fill()
    },
    draw() {
      this.canvas.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
      this.points.forEach((point, i) => {
        this.drawCircle(point, i)
      })
    },
    checkPoints(x, y) {
      let isDragging = false
      let matchedPoint
      this.points.forEach(point => {
        const dx = point.x - x
        const dy = point.y - y
        if (dx * dx + dy * dy < this.radius * this.radius && point.problem_id === null) {
          isDragging = true
          matchedPoint = point
        }
      })
      return { isDragging, matchedPoint }
    },
    setDraggable(e, isDown) {
      this.isMouseDown = isDown
      if (isDown) {
        const rect = document.getElementById('myCanvas').getBoundingClientRect()
        const x = e.clientX - rect.left
        const y = e.clientY - rect.top
        const checkPoint = this.checkPoints(x, y)
        if (checkPoint.isDragging) {
          this.pointBeingDragged = checkPoint.matchedPoint
        }
      } else {
        this.pointBeingDragged = null
      }
    },
    mouseMove(e) {
      if (this.isMouseDown && this.pointBeingDragged !== null) {
        const rect = document.getElementById('myCanvas').getBoundingClientRect()
        const x = e.clientX - rect.left
        const y = e.clientY - rect.top
        const index = this.points.findIndex(point => point.x === this.pointBeingDragged.x && point.y === this.pointBeingDragged.y)
        if (index === this.activeCoordinate.originalIndex) {
          this.activeCoordinate.x = x
          this.activeCoordinate.y = y
        }
        this.points[index].x = x
        this.points[index].y = y
        this.draw()
      }
    },
    resizeWindow() {
      if (window.innerWidth <= 1745) defaultWidth = 320
      if (window.innerWidth <= 1280) {
        defaultWidth = 260
      }
      if (window.innerWidth <= 720) {
        defaultWidth = 250
      }
    },
    setActivePoint(point) {
      if (this.isEditing === true && this.activeCoordinate.originalIndex !== point.originalIndex) {
        this.showDeleteModal = true
        this.changingPoint = point
        return
      }

      if (point.problem_id !== null) {
        this.isEditing = false
      }
      this.isEditing = true
      this.activeCoordinate = point
      this.$store.commit('problem/RESET')
      this.$store.commit('problem/SET_LESSON_ID', this.$route.query.lesson_id)
      this.$store.dispatch('problem/getDomainByLesson', this.$route.query.lesson_id)
      this.$store.dispatch('problem/getCefrSubject')
    },

    confirmChange() {
      this.isEditing = false
      this.setActivePoint(this.changingPoint)
      this.showDeleteModal = false
    },

    removePoints(point) {
      this.points.splice(point.originalIndex, 1)
      this.$store.commit('problem/DELETE_COORDINATE', point)
      if (point.id && point.id !== null) {
        useJwt.deletePoint(point.id).then(res => {
          this.showSuccessMessage(res)
        }).catch(error => {
          this.showErrorMessage(error)
        })
      }
      this.draw()
    },

    saveHotspot() {
      if (this.existingHotspot !== null) {
        this.updateHotspot()
      } else {
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.accessToken}`,
          },
        }
        const data = new FormData()
        data.append('image', this.$refs.imageInput.files[0])
        if (this.hotspot.type) {
          data.append('type', this.hotspot.type)
        }
        useJwt.saveHotspot(data, config).then(res => {
          this.showSuccessMessage(res)
          this.existingHotspot = res.data.data.id
          this.hotspot = res.data.data
          this.draw()
        }).catch(error => {
          this.showErrorMessage(error)
        })
      }
    },

    updateHotspot() {
      const data = new FormData()
      data.append('image', this.$refs.imageInput.files[0])
      data.append('_method', 'put')
      useJwt.updateHotspot(this.existingHotspot, data).then(res => {
        this.showSuccessMessage(res)
        this.hotspot = res.data.data
        this.draw()
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
  },

}
</script>
<style lang="css">
.active-column {
  background-color: rgba(0, 0, 0, 0.15);
}
#myCanvas{
  background-size:contain !important;
  background-repeat:no-repeat !important;
}
</style>
