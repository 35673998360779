import { render, staticRenderFns } from "./ImageWithSize.vue?vue&type=template&id=5567dc37&scoped=true"
import script from "./ImageWithSize.vue?vue&type=script&lang=js"
export * from "./ImageWithSize.vue?vue&type=script&lang=js"
import style0 from "./ImageWithSize.vue?vue&type=style&index=0&id=5567dc37&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5567dc37",
  null
  
)

export default component.exports