<template>

  <b-card no-body>
    <!-- create/edit course model -->
    <b-modal
      id="modal-edit-courses"
      ref="modal-edit-courses"
      ok-only
      size="lg"
      ok-variant="primary"
      :ok-title="$t('confirm')"
      modal-class="modal-primary"
      centered
      :title="$t('actions.update-course')"
    >
      <b-col md="12">
        <!-- form -->
        <validation-observer
          ref="addCourseForm"
        >
          <b-form
            class="update-course-form"
          >
            <b-row>
              <b-col
                md="12"
              >
                <b-form-group
                  :label="$t('profile-module.courses')"
                  label-for="courses"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Course"
                    rules="required"
                    vid="courses"
                  >
                    <v-select
                      id="courses"
                      v-model="teacherCourseIds"
                      :clearable="false"
                      :state="errors.length > 0 ? false:null"
                      :multiple="true"
                      name="courses"
                      :options="getCoursesForSelect"
                      :reduce="data=>data.code"
                      transition=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <template #modal-footer="{ }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          > <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="isProcessing"
            @click="updateCourses()"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('profile-module.update-course') }}
          </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <b-row class="p-1 d-flex align-items-center justify-content-between">
      <b-col>

        <h5>
          {{ $t('profile-module.courses') }}
        </h5>

      </b-col>
      <b-dropdown
        v-if="isViewingOwnProfile"
        variant="link"
        no-caret
      >
        <template
          #button-content
          class="pr-4"
        >
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body p-0 m-0"
          />
        </template>
        <b-dropdown-item v-b-modal.modal-edit-courses>
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">{{ $t("edit") }}</span>
        </b-dropdown-item>
      </b-dropdown>

      <b-col class="col-12">
        <!-- courses -->
        <div v-if="teacherCourses.length>0">

          <div
            v-for="(data,index) in teacherCourses"
            :key="index"
            class="d-flex justify-content-start align-items-center mt-1"
          >
            <div class="profile-user-info">
              <b-card-text>
                {{ data.name }}
              </b-card-text>
            </div>
          </div>
        </div>
        <div v-else>
          <b-card-text>{{ $t('profile-module.no-courses') }}</b-card-text>
        </div>
        <!--/ courses -->
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BModal, BSpinner, BFormGroup, BForm, BCardText, BDropdownItem, BDropdown,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    ValidationProvider,
    BSpinner,
    ValidationObserver,
    BForm,
    BCardText,
    BDropdownItem,
    BDropdown,
    BRow,
    BModal,
    BFormGroup,
    BCol,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isViewingOwnProfile: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      teacherCourses: [],
      teacherCourseIds: [],
      isProcessing: false,
      allCourses: [],

    }
  },
  computed: {
    getCoursesForSelect() {
      return this.allCourses.map(e => ({ code: e.id, label: e.name }))
    },
  },
  mounted() {
    if (this.isViewingOwnProfile) this.getCourses()
    this.getTeacherCourses()
  },
  methods: {
    getCourses() {
      useJwt.getCourses().then(response => {
        this.allCourses = response.data.data
      })
    },
    getTeacherCourses() {
      useJwt.getTeacherCourses({ params: { teacher_id: this.data.id } }).then(response => {
        this.teacherCourses = response.data.data
        this.teacherCourseIds = this.teacherCourses.map(course => course.id)
      })
    },
    updateCourses() {
      this.$refs.addCourseForm.validate().then(res => {
        if (!res) return
        this.isProcessing = true
        useJwt.updateTeacherCourses({
          course_id: this.teacherCourseIds,
        }).then(response => {
          this.isProcessing = false
          this.$refs['modal-edit-courses'].hide()
          this.teacherCourses = response.data.data
          this.teacherCourseIds = this.teacherCourses.map(course => course.id)
          this.showSuccessMessage(response)
        })
      })
    },
  },

}
</script>
