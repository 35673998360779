<template>
  <b-overlay :show="isProcessing">
    <b-card>
      <h3>
        {{ $t('student-report-module.study-time') }}
      </h3>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <input
              v-model="selectedDate"
              class="form-control"
              type="month"
              onfocus="this.showPicker()"
              :date-format-options="{ year: 'numeric', month: 'long' }"
              :min="minDate"
              :max="maxDate"
              @input="onDateChange"
            >
          </b-form-group>
        </b-col>
      </b-row>
      <apexchart :options="chartOptions"
                 :series="chartSeries"
                 type="bar"
                 height="350"
      />
    </b-card>
  </b-overlay>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import VueApexCharts from 'vue-apexcharts'
import {
  BCard, BFormGroup, BOverlay, BRow, BCol,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    apexchart: VueApexCharts,
    BCard,
    BFormGroup,
    BOverlay,
    BRow,
    BCol,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    classId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isProcessing: false,
      categories: [],
      chartSeries: [],
      selectedDate: new Date().toISOString().slice(0, 7),
      minDate: '2000-01-01',
      maxDate: new Date().toISOString().slice(0, 10),
    }
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: this.categories,
        },
        yaxis: {
          title: {
            text: i18n.tc('student-report-module.time-in-minutes'),
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter(val) {
              return `${val} Min`
            },
          },
        },
      }
    },
  },
  mounted() {
    this.getStudyTime()
  },
  methods: {
    getSeriesColor(type) {
      switch (type) {
        case 'homework':
          return '#00E396'
        case 'follow_up':
          return '#008FFB'
        default:
          return ''
      }
    },
    getSeriesName(name) {
      if (name === 'homework') {
        return 'Preview'
      }
      return 'Review'
    },
    getStudyTime() {
      this.isProcessing = true
      useJwt.getIndividualUserStudyTimeReport({
        classId: this.classId,
        userId: this.userId,
      }, {
        params: {
          date: this.selectedDate,
        },
      }).then(response => {
        const { result } = response.data.data
        const categories = [...new Set(result.map(item => item.date_attempt))]
        const series = [...new Set(result.map(item => item.assignment_type))]
        const chatSeriesData = []
        series.forEach(name => {
          const data = []
          categories.forEach(cat => {
            const value = result.find(i => i.assignment_type === name && i.date_attempt === cat)
            data.push(+value?.total_minutes || 0)
          })
          chatSeriesData.push({
            name: this.getSeriesName(name),
            data,
            color: this.getSeriesColor(name),
          })
        })
        this.chartSeries = chatSeriesData
        this.categories = categories
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    onDateChange() {
      this.getStudyTime()
    },
  },
}
</script>
