<template>
  <div>
    <h3>{{ $t('game-settings-module.setting-list') }}</h3>
    <b-form-group
      :label="`${$t('game-settings-module.game-setting-name')}:`"
      label-cols="2"
      content-cols="2"
    >
      <b-form-select
        :value="currentGameSettingName"
        :options="gameSettingNames"
        @change="(value) => updateGlobalSettings('game_settings', value)"
      />
    </b-form-group>
    <b-overlay :show="isProcessing">
      <b-card>
        <b-table
          :fields="fields"
          :items="currentGameSetting"
          responsive
        >
          <template #cell(value)="data">
            <b-form-input
              :value="data.item.value"
              :placeholder="data.item.name"
              @change="(value) => updateSetting(data.item.id, value)"
            />
          </template>
        </b-table>
      </b-card>
    </b-overlay>
    <div>
      <h3 class="pb-1">
        {{ $t('game-settings-module.game-list') }}
      </h3>
      <game-list />
    </div>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import GameList from '@/views/super/game-settings/GameList.vue'
import {
  BTable, BCard, BFormInput, BOverlay, BFormSelect, BFormGroup,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    BTable,
    BCard,
    BFormInput,
    BOverlay,
    BFormSelect,
    BFormGroup,
    GameList,
  },
  data() {
    return {
      settings: [],
      isProcessing: true,
      fields: [
        { label: i18n.tc('labels.name'), key: 'name' },
        { label: i18n.tc('labels.description'), key: 'description' },
        { label: i18n.tc('labels.value'), key: 'value' },
      ],
      gameSettingNames: [],
      currentGameSettingName: '',
    }
  },
  computed: {
    currentGameSetting() {
      return this.settings.filter(setting => setting.name === this.currentGameSettingName)
    },
  },
  created() {
    this.isProcessing = true
    this.getSettingName()

    useJwt.getGameSettings().then(response => {
      this.settings = response.data?.data?.game_setting
    }).catch(error => {
      this.showErrorMessage(error)
    }).finally(() => { this.isProcessing = false })
  },
  methods: {
    getSettingName() {
      useJwt.getGlobalSetting().then(response => {
        this.gameSettingNames = response.data.data.game_settings_names
        this.currentGameSettingName = response.data.data.settings.find(setting => setting.name === 'game_settings').value
      })
    },
    updateSetting(id, value) {
      this.settings.find(setting => setting.name === this.currentGameSettingName).value = value
      useJwt.updateGameSettingsValue(id, {
        value,
      }).then(response => {
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    updateGlobalSettings(key, value) {
      this.currentGameSettingName = value
      useJwt.updateGlobalSettings({
        key,
        value,
      }).then(() => {
        this.showSuccess(`${key} setting updated`)
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
  },
}
</script>
