<template>
  <div class="container-fluid">
    <b-card no-body>
      <b-card-header>
        <h4>{{ $t('ai-ide-module.response') }}</h4>
        <feather-icon :icon="showResponse ? 'MinusIcon' : 'PlusIcon'"
                      role="button"
                      size="25"
                      @click="showResponse = !showResponse"
        />
      </b-card-header>
      <b-card-body v-if="showResponse"
                   class="response-dashboard p-0"
      >
        <div class="response-screen p-0"
             :class="modelForm.response?.type === 'error' && 'bg-error'"
        >
          <p v-if="modelForm.response">
            <JsonEditorVue :value="response"
                           :main-menu-bar="false"
            />
          </p>
          <p v-else>
            {{ $t('ai-ide-module.please-submit-text') }}
          </p>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import { BCard, BCardBody, BCardHeader } from 'bootstrap-vue'
import JsonEditorVue from 'json-editor-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    JsonEditorVue,
  },
  props: {
    modelForm: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showResponse: true,
    }
  },
  computed: {
    response() {
      const { response } = this.modelForm
      const { content } = response?.choices[0]?.message
      if (content) {
        const jsonContent = this.JSON_PARSE(content)
        if (jsonContent) {
          response.choices[0].message.content = jsonContent
        }
      }
      return response
    },
  },
}
</script>
<style>
.bg-error {
  background-color: #ff686aa3 !important;
}
</style>
