<template>
  <div>
    <div class="d-flex p-2 justify-content-center align-items-center">
      <div>
        <div style="position: relative">
          <div class="problem-card-title md-12">
            <h3 class="mb-0">
              {{ $t("student-test-module.question-answer") }}
            </h3>
          </div>
          <img
            id="myImage"
            :src="hotspot.file"
          >
          <!-- <div v-for="(point, i) in hotspot.points" :key="i"> -->
          <div
            v-if="point"
            :class="`point ${
              !point.answered ? ' clickable' : ' not-clickable'
            }`"
            :style="`top: ${point.coordinates.y * 100}%; left: ${
              point.coordinates.x * 100
            }%; `"
            @click="checkPoints()"
          >
            <span>{{ point.answered ? "✓" : "+" }}</span>
          </div>
          <!-- </div> -->
        </div>
        <div
          v-if="showLevel"
          class="
            d-flex
            col-12
            p-0
            justify-content-between
            align-content-center
            problem-card-footer
          "
        >
          <actions
            :level="level"
            mode="question"
            :engine="engine"
            :on-submit="
              () => {
                $emit('proceedToQuestion');
              }
            "
            :button-title="$t('next')"
            :action-visible="false"
          />
        </div>
      </div>
    </div>
    <problem-modal
      :active-point="hotspot"
      :toggle="activeProblemModal"
      :problem="problem"
      :total-points="1"
      :pid="pid"
      :level="level"
      :engine="engine"
      :start-time="startTime"
      :duration="duration"
      :is-preview="isPreview"
      :loader-id="loaderId"
      @problemModalClosed="problemModalClosed"
      @answeredQuestion="answeredQuestion"
      @nextQuestion="$emit('nextQuestion')"
    />
  </div>
</template>

<script>
import ProblemModal from './ProblemModal.vue'
import Actions from '../compoments/Actions.vue'

export default {
  components: {
    ProblemModal,
    Actions,
  },
  props: {
    pid: {
      type: [Number, String],
      default: null,
    },
    showHotSpotPoint: {
      type: Boolean,
      default: () => false,
    },
    problem: {
      type: Object,
      default: () => {},
    },
    level: {
      type: [Number, String, Object],
      default: () => {},
    },
    hotspot: {
      type: [Object, Array],
      default: () => {},
    },
    startTime: {
      type: [Object, Array],
      required: false,
      default: () => {},
    },
    duration: {
      type: [Object, Array],
      required: false,
      default: () => {},
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: () => {},
    },
    engine: {
      type: [String, Object],
      default: '',
    },
    loaderId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      activeProblemModal: false,
      matchedPointIndex: null,
      showLevel: true,
      matchedPoint: null,
    }
  },
  computed: {
    point() {
      return this.hotspot.hotspotPoints
    },
  },
  mounted() {
    this.openProblemModal()
  },

  methods: {
    checkPoints() {
      this.matchedPointIndex = 0
      this.openProblemModal()
    },
    drawPoints() {
      this.hotspot.points
        .filter(point => point !== null)
        .forEach((point, i) => {
          this.drawCircle(point, i)
        })
    },
    openProblemModal() {
      this.activeProblemModal = true
    },
    problemModalClosed() {
      this.activeProblemModal = false
    },
    answeredQuestion(data) {
      this.point.answered = true
      this.$emit('proceedToQuestion', data)
    },
  },
}
</script>

<style scoped lang="scss">
.tooltiptext {
  min-width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #000 transparent transparent;
}
.point {
  width: 25px;
  height: 25px;
  background-color: white;
  border: 2px solid black;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-left: -20px;
  align-items: center;
}

.point span {
  font-weight: 600;
  // font-size:18px;
  color: black;
}

#myImage {
  display: block;
  max-width: 100%;
  max-height: 100%;
  min-width: 800px;
  min-height: 600px;
  width: auto;
  height: auto;
}
.clickable {
  cursor: pointer;
  -webkit-animation: pulse 2s ease-in-out infinite;
  animation: pulse 2s ease-in-out infinite;
}
.not-clickable {
  cursor: not-allowed;
  background-color: rgb(9, 193, 9);
  span {
    color: white;
    font-size: 12px;
  }
}

@keyframes pulse {
  0%,
  70% {
    box-shadow: 0px 0px 0px 0px  rgba(130, 236, 10, 0.97);
  }
  100% {
    box-shadow: 0px 0px 0px 50px rgba(125, 231, 4, 0);
  }
}

@media (max-width: 1000px) {
  #myImage {
    min-width: 400px !important;
    min-height: 400px !important;
  }
}
</style>
