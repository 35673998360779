<template>
  <div>
    <div
      v-if="showLess"
      class="truncate-content content-para"
      v-html="truncatedContent"
    />

    <div v-else
         class="content-para"
         v-html="fullContent"
    />

    <button class="toggle-button border mt-2"
            @click="toggleContent"
    >
      {{ showLess ? $t('read-more') : $t('show-less') }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    maxLines: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showLess: true,
    }
  },
  computed: {
    truncatedContent() {
      const lines = this.text.split('\n').slice(0, this.maxLines)
      return lines.join('\n')
    },
    fullContent() {
      return this.text
    },
  },
  methods: {
    toggleContent() {
      this.showLess = !this.showLess
    },
  },
}
</script>

  <style scoped>
    .truncate-content {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* Change this value to the number of lines you want to show */
        -webkit-box-orient: vertical;
    }
    .content-para {
        line-height: 1.75;
        font-size: 16px;
        text-align: justify;
    }

    .toggle-button {
        margin-top: 10px;
        background-color: transparent;
        border: none;
        color: blue;
        cursor: pointer;
    }
    .toggle-button:hover {
        color: white !important;
        background: rgba(30, 30, 209, 0.561);
  }
  </style>
