<template>
  <div>
    <iframe
      v-if="scormUid"
      id="scormFrameContentPreview"
      :key="scormUid"
      style="height: 80vh;width: 100%;"
      frameBorder="0"
    />
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    scormUid() {
      return this.data[0]?.uuid
    },
  },
  watch: {
    scormUid: {
      handler(newValue) {
        if (newValue) {
          this.getScorm()
        }
      },
      immediate: true,
    },
  },
  methods: {
    getScorm() {
      useJwt.getScormPlayer(this.scormUid).then(response => {
        const settings = response.data.data
        setTimeout(() => {
          this.settings = settings
          const iframe = document.querySelector('#scormFrameContentPreview').contentWindow.document
          iframe.open('text/html', 'replace')
          iframe.write(response.data.html)
          iframe.close()
        }, 100)
      })
    },
  },

}
</script>
