<template>
  <b-row>
    <b-col
      class="problem-card-title text-center"
      md="12"
    >
      <h4 class="mb-0">
        {{ getProblemHintsDescription('Open Ended  Problem') }}
      </h4>
    </b-col>
    <b-col
      md="12"
      class="problem-overview-content"
    >
      <div v-if="clues"
           class="text-center"
      >
        {{ clues }}
      </div>
      <b-row
        v-if="images.length"
        class="mb-2"
      >
        <b-col
          v-for="image,index of images"
          :key="index"
          md="3"
        >
          <figure>
            <AudioPlayer v-if="image.type === 'audio' && image.image"
                         :key="image.image"
                         :src=" image.image"
            />
            <b-img
              v-else-if="image.image"
              :src="image.image"
              thumbnail
              class="h-100 w-100"
              @click="() => {toggler = !toggler; slide = index + 1 }"
            />
            <figcaption
              v-if="image.caption"
              class="mt-1 text-center"
            >
              {{ image.caption }}
            </figcaption>
          </figure>
        </b-col>

      </b-row>
      <b-row class="mb-2">
        <b-col v-if="questions.length">
          <p v-html="questions[0].text " />
        </b-col>
      </b-row>
      <div>
        <portal-target name="problemTaskTimerPortal" />
      </div>
      <b-row>
        <b-col>
          <div
            v-if="feedback.grammar"
          >
            <p
              class="p-2 text-dark"
              style="background: #F6F5A9;color: black !important;"
              v-html="answerTyped"
            />
          </div>
          <div v-else-if="isSpeechProblem">
            <div
              v-if="answerTyped && !isPreview"
              class="d-flex pb-1 justify-content-end"
            >
              <b-button
                size="sm"
                variant="danger"
                class="ml-2"
                @click="clearAll"
              >
                <feather-icon icon="XIcon" />  Clear All
              </b-button>
            </div>
            <div
              id="speakerAnswers"
              disabled
              class="card"
              placeholder="Your answers will appears here"
              rows="8"
            >
              <div
                class="card-body"
                style="min-height: 200px;"
              >
                <small
                  v-if="!answerTyped"
                  class="text-muted"
                >
                  Your answer will appear here. Please start speaking.
                </small>
                {{ answerTyped }}
              </div>
            </div>
            <div
              v-if="!isPreview"
              class="p-2"
            >
              <label>Your Response (Supported Browsers: Google Chrome & Edge)</label><br>
              <recorder
                :auto-stop="false"
                :hide-player="true"
                @textAudio="setAudioAnswer"
                @handleBlob="updateAudio"
              />
              <div
                v-for="audio,index of audioFiles"
                :key="index"
                class="mt-2"
              >
                <WaveFormPlayer :key="index"
                                :audio="audio"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <b-form-textarea
              v-model="answerTyped"
              :disabled="hasPreviewedAnswer"
              placeholder="Write your thoughts"
              rows="8"
            />
          </div>
          <OpenAIScore v-if="isPreview || feedback?.open_ai_score?.length"
                       :scores="previewAnswers?.open_ai_score ||feedback?.open_ai_score || []"
                       :answer="previewAnswers?.text || answerTyped"
                       :has-teacher-scored="previewAnswers?.has_teacher_scored || !previewAnswers?.open_ai_score?.length"
                       :test-id="Number(testId)"
                       @refetch="(open_ai_scores, ai_score) => { previewAnswers.open_ai_score = open_ai_scores; score = ai_score; $forceUpdate(); previewAnswers.has_teacher_scored = false}"
          />
          <div
            v-if="isATeacher && hasPreviewedAnswer && testId"
          >
            <div
              v-for="audio,index of previewAudio"
              :key="index"
              class="mt-2"
            >
              <WaveFormPlayer :key="index"
                              :audio="audio"
              />
            </div>
            <div class="float-right mt-2">
              <div class="d-flex">
                <b-input-group>
                  <b-form-input
                    v-model="score"
                    placeholder="Score"
                    type="number"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="success"
                      :disabled="isLoading"
                      @click="updateProblemScore"
                    >
                      <b-spinner
                        v-show="isLoading"
                        small
                      /> Save
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <portal
      to="problemFeedBack"
    >
      <b-row>
        <b-col md="12"
               class="p-0"
        >
          <feedback
            :feedback="feedback"
            :problem-statement-id="problemStatementId"
          />
        </b-col>
        <b-col
          v-if="!isPreview"
          class="m-0 p-0"
          md="12"
        >
          <actions
            :is-processing="isProcessing"
            :on-submit="submit"
            :level="level ? level : ''"
            :disabled="!isValidated"
            :has-feedback="hasFeedback"
            :has-minimal-view="hasMinimalView"
            :tooltip-text="$t('student-test-module.match-problem-validation-message')"
            :display-result="displayResult"
            :button-title="hasFeedback ? $t('next') : $t('submit')"
            :engine="engine"
            :school-settings="schoolSettings"
            :district-settings="districtSettings"
            v-on="$listeners"
          />
        </b-col>
      </b-row>
    </portal>
    <FsLightbox
      v-if="images.length"
      :toggler="toggler"
      :slide="slide"
      :sources="images.map(d => d.image)"
      :types="[...new Array(images.length).fill('image')]"
    />
  </b-row>
</template>
<script>
/* eslint-disable */
import {
  BButton, BRow, BCol, BImg, BFormTextarea, BFormInput, BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'
import WaveFormPlayer from './WaveFormPlayer.vue'
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'
import Actions from './Actions.vue'
import Feedback from './Feedback.vue'
import Recorder from './recorder/Speech.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import FsLightbox from 'fslightbox-vue'
import useJwt from '@/auth/jwt/useJwt'
import WFPlayer from 'wfplayer';
import OpenAIScore from '@/views/student/report/components/OpenAIScore.vue'

export default {
  components: {
    FsLightbox,
    BButton,
    BRow,
    BCol,
    Actions,
    BImg,
    AudioPlayer,
    Feedback,
    BFormTextarea,
    Recorder,
    FeatherIcon,
    BFormInput,
    BInputGroup, 
    BInputGroupAppend,
    OpenAIScore,
    WaveFormPlayer
  },
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
    previewAnswers: {
      type:  [String, Object],
      default: () =>  null,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Object,
      default: () => {},
    },
    level: {
      type: Number,
      default: 1,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    problemStatementId: {
      type: [Number, String],
      required: false,
    },
    displayResult: {
      type: Boolean,
      default: false,
    },
    engine: {
      type: String,
      default: () => '',
    },
    testId: {
      type: Number,
      default: 0,
    },
    rules: {
      type: [Object, Array],
      default: () => null,
    },
    hasMinimalView: {
      type: Boolean,
      default: false,
    },
    schoolSettings: {
      type: Array,
      default: () => [],
    },
    districtSettings: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    feedback: {
      handler() {
        setTimeout(() => {
          let tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        
        for(let i = 0; i < tooltips.length; i++) {
          new bootstrap.Tooltip(tooltips[i]);
        }  
        }, 500)
        
      },
      deep: true,
    }
  },
  mounted() {
    if (this.testId && this.isATeacher) {
      this.getProblemScore()
    }
  },
  data() {
    return {
      localQuestion: [],
      allQuestions: this.questions.map(q => ({ text: q.text, id: q.id, data_type: q.data_type })),
      answerTyped: this.getTypedAnswerFromPreview(),
      selectedAnswerForTouch: null,
      isRecording: false,
      toggler: false,
      slide: 1,
      isLoading: false,
      score: null,
      canRecordAudio: true,
      audioFiles: [],
     //TODO: fix it later
      stopAdding: false,
    }
  },
  computed: {
    clues() {
      if (this.rules?.clues) {
        return this.rules.clues
      }
      return null
    },
    isATeacher() {
      return this.AUTH_USER()?.usertype === 'teacher'
    },
    isSpeechProblem() {
      return this.questions[0]?.data_type === 'audio'
    },
    isValidated() {
      return this.answerTyped && this.answerTyped.trim()
    },
    hasFeedback() {
      return !!(this.feedback?.text?.length > 0 || this.feedback?.image || this.feedback?.audio || this.feedback?.template)
    },
    hasPreviewedAnswer() {
      return !!this.previewAnswers
    },
    previewAudio() {
      return this.previewAnswers?.audio || []
    },
    images() {
      return this.questions[0]?.extra_file ? this.JSON_PARSE(this.questions[0]?.extra_file) : []
    },
  },
  watch: {
    answerTyped() {
      this.$root.$emit('endPreparationTime')
    }
  },
  methods: {
    handleRecordStop(slotProps) {
      slotProps.stopFunction(); 
    },
    playWaveFormAudio(index, audioSrc) {
      try {
        const wf = new WFPlayer({
          container: document.querySelector(`#waveform_${index}`),
          mediaElement: document.querySelector(`#waveform_audio_${index}`),
        });
        wf.load(audioSrc)
              // click event
        wf.on('click', (currentTime) => {
            wf.seek(currentTime)
        });

        // grab event
        wf.on('grabbing', (currentTime) => {
            wf.seek(currentTime)
        });

        // scroll event
        wf.on('scroll', (deltaY) => {
            wf.seek(wf.currentTime + deltaY / 10)
        });
      } catch {
        console.error('audio already mounted')
      } finally {
        document.getElementById(`waveform_audio_${index}`).play()
        // this.$root.$emit('forceStopRecording')
      }
    },
    getTypedAnswerFromPreview() {
      if (this.previewAnswers && typeof this.previewAnswers === 'object') {
        return this.previewAnswers.text
      }
      return this.previewAnswers || ''
    },
    submit() {
      this.$emit('submitQuestion', { lang_level: this.level, answer: this.answerTyped, question: [], audioFile: JSON.stringify(this.audioFiles) })
    },
    setAudioAnswer(answer) {
      if (!this.stopAdding) {
        this.stopAdding = true
        this.answerTyped += ' ' + answer.text
        setTimeout(() => {
          this.stopAdding = false
        }, 1500)
      }
      
    },
    updateAudio(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () =>  {
        const base64data = reader.result;                
        this.audioFiles.push(base64data)
      }
    },
    clearAll() {
      if (confirm('Reset all text ?')) {
        this.answerTyped = ''
        this.audioFiles = []
      }
    },
    deleteSelection() {
      const selection = window.getSelection()
      selection.deleteFromDocument()
      setTimeout(() => {
        this.answerTyped = document.getElementById('speakerAnswers').textContent
      }, 500)
    },
    getProblemScore() {
      this.isLoading = true
      useJwt.getProblemScore(this.testId).then(response => {
        this.score = response.data.score
      }).catch(error=> {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
    updateProblemScore() {
      if (this.score > 100) {
        this.showErrorMessage('Score cannot be grater than 100')
        return
      }
      this.isLoading = true
  
      useJwt.updateProblemScore(this.testId, {
        score: this.score
      }).then(response => {
        this.showSuccessMessage(response)
        this.$root.$emit('reFetchStudentLessonStatReport')
        setTimeout(() => {
          this.$emit('close')
        }, 1500)

      }).catch(error=> {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
  }
}
</script>
<style scoped>
    .category {
        position: relative;
        top: 25%;
        background: #28658e !important;
        color: #fff;
        font-weight: bold;
    }
    .drop-area {
        min-height: 20vh;
        min-height: 100px;
        background-color: #ffe6e6;
        border: 1px solid;
        min-width: 2opx;
        width: 100%;
    }
    .cross-icon {
        position: absolute;
        margin-left: -36px;
        margin-top: -6px;
        font-weight: bold;
    }
  .selected-answer {
    box-sizing: border-box !important;
    border: 5px #f1c40f solid !important;
  }
</style>