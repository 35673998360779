<template>
  <div>
    <BModal :visible="props.show"
            size="xl"
            title="Pick Image From Library"
            @close="$emit('close')"
            @hide="$emit('close')"
    >
      <div class="row mb-2">
        <div class="col-4">
          <b-form-input v-model="state.searchTerm"
                        placeholder="Search Image"
                        debounce="800"
                        @update="getImageLibrary()"
          />
        </div>
      </div>
      <div class="row">
        <div v-for="library,index of state.imageLibrary"
             :key="index"
             class="col-4"
        >
          <ImageLibrary :item="library"
                        :from-picker="true"
                        @onPick="value => $emit('pickImage', value)"
          />
        </div>
      </div>
      <Pagination :pagination="state.paginationData"
                  :is-processing="state.isProcessing"
                  @onPaginationChange="page => getImageLibrary(searchTerm, page) "
      />
    </BModal>
  </div>
</template>
<script setup>
import { BModal, BFormInput } from 'bootstrap-vue'
import { reactive } from 'vue'
import ImageLibrary from '@/views/super/image-library/components/LibraryCard.vue'
import useJwt from '@/auth/jwt/useJwt'
import Pagination from '@/views/common/components/Pagination.vue'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
})
const state = reactive({
  isProcessing: false,
  imageLibrary: [],
  searchTerm: '',
  paginationData: {},
})

function getImageLibrary(term = state.searchTerm, page = 1) {
  state.isProcessing = true
  useJwt.getImageLibrary({
    params: {
      term,
      page,
    },
  }).then(response => {
    state.imageLibrary = response.data?.data?.data
    state.paginationData = {
      current_page: response.data.data.current_page,
      per_page: response.data.data.per_page,
      total: response.data.data.total,
      last_page: response.data.data.last_page,
    }
  }).finally(() => {
    state.isProcessing = false
  })
}

// watch('props.show', bool => {
//   if (bool) {
//     getImageLibrary()
//   }
// })

</script>
