<template>
  <b-modal :visible="true"
           title="Translate to other language"
           no-close-on-backdrop
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <b-form @submit="submitForm">
      <b-form-group
        label="Translate Type"
      >
        <b-form-checkbox
          v-model="isSameVersion"
          :options="libraryOptions"
          switch
        >
          Translate For Current Page
        </b-form-checkbox>
      </b-form-group>
      <div v-if="!isSameVersion">
        <!-- Library Dropdown -->
        <b-form-group
          id="libraryDropdown"
          label="Library"
          label-for="librarySelect"
        >
          <b-form-select
            id="librarySelect"
            v-model="selectedLibrary"
            :options="libraryOptions"
          />
        </b-form-group>

        <!-- Courses Dropdown -->
        <b-form-group
          id="courseDropdown"
          label="Courses"
          label-for="courseSelect"
        >
          <v-select
            id="courseSelect"
            v-model="selectedCourse"
            label="text"
            :reduce="item => item.value"
            :options="courseOptions"
          />
        </b-form-group>

        <!-- Lesson Groups Dropdown -->
        <b-form-group
          id="groupDropdown"
          label="Lesson Groups"
          label-for="groupSelect"
        >
          <v-select
            id="groupSelect"
            v-model="selectedGroup"
            label="text"
            :reduce="item => item.value"
            :options="groupOptions"
          />
        </b-form-group>
      </div>
      <!-- Languages Dropdown -->
      <b-form-group label="Select Language">
        <b-form-select v-model="selectedLanguage"
                       :options="languageOptions"
        />
      </b-form-group>

    </b-form>
    <template #modal-footer>
      <div>
        <b-button variant="success"
                  :disabled="isProcessing"
                  @click="submitForm"
        >
          <b-spinner v-if="isProcessing"
                     small
          /> Translate
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BForm, BFormGroup, BFormSelect, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormSelect,
    BButton,
    vSelect,
    BFormCheckbox,
  },
  props: {
    page: {
      type: Object,
      default: () => {},
    },
    library: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedLibrary: null,
      selectedCourse: null,
      selectedGroup: null,
      isProcessing: false,
      // eslint-disable-next-line eqeqeq
      isSameVersion: false,
      selectedLanguage: 'Vietnam',
      languageOptions: [
        { value: 'vietnamese', text: 'Vietnam' },
        { value: 'japanese', text: 'Japan' },
        { value: 'french', text: 'French' },
        { value: 'germany', text: 'German' },
        { value: 'mexican', text: 'Mexican' },
        { value: 'spanish', text: 'Spanish' },
      ],
    }
  },
  computed: {
    // Populate dropdown options based on selected values
    libraryOptions() {
      return this.library.map(libraryItem => ({
        text: libraryItem.name,
        value: libraryItem.id,
      }))
    },
    courseOptions() {
      if (this.selectedLibrary === null) return []
      const selectedLibrary = this.library.find(
        libraryItem => libraryItem.id === this.selectedLibrary,
      )
      return selectedLibrary.courses.map(course => ({
        text: course.name,
        value: course.id,
      }))
    },
    groupOptions() {
      if (this.selectedCourse === null) return []
      const selectedLibrary = this.library.find(
        libraryItem => libraryItem.id === this.selectedLibrary,
      )
      const selectedCourse = selectedLibrary.courses.find(
        courseItem => courseItem.id === this.selectedCourse,
      )
      return selectedCourse.groups.map(group => ({
        text: group.name,
        value: group.id,
      }))
    },
  },
  watch: {
    selectedLibrary() {
      this.selectedCourse = null
      this.selectedGroup = null
    },
    selectedCourse() {
      this.selectedGroup = null
    },
    page(value) {
      // eslint-disable-next-line eqeqeq
      this.isSameVersion = value.version != '4'
    },
  },
  methods: {
    submitForm() {
      this.isProcessing = true
      useJwt.translateCurriculumLesson(this.page.id, {
        lang: this.selectedLanguage,
        group_id: this.selectedGroup,
        same_version: this.isSameVersion,
      }).then(response => {
        this.showSuccessMessage(response)
        this.$emit('close')
        this.$emit('reFetch')
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>

<style>
/* Add your custom CSS styles here */
</style>
