<template>
  <div>
    <div class="card text-center">
      <div class="card-header">
        Vocab Lesson
      </div>
      <div class="card-body">
        <h4 class="card-title">
          #{{ props.item.id }}
        </h4>
        <p class="card-text">
          Click Here to Start
        </p>
        <button type="button"
                class="btn btn-outline-primary"
                @click="startTheLesson"
        >
          Start Lesson
        </button>
      </div>
      <div class="card-footer text-muted">
        Start Time {{ FORMAT_DATE(props.item.start_time, 'MMM Do YY') }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance } from 'vue'

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  classId: {
    type: Number,
    required: true,
  },
})

const root = getCurrentInstance().proxy.$root

const startTheLesson = () => {
  root.$router.push({
    name: `${root.AUTH_USER().usertype}-vocab-lab`,
    params: { eventId: props.item.id, classId: props.classId },
  })
}

</script>
