<template>
  <b-modal
    id="gameModal"
    ok-only
    no-close-on-backdrop
    :visible="true"
    ok-title="Accept"
    size="md"
    hide-footer
    body-class="text-center p-0"
    @hide="stopPendingGame"
    @close="stopPendingGame"
  >
    <template #modal-title>
      Springy Hedgehog
    </template>
    <b-spinner
      v-if="isProcessing"
      type="grow"
      class="text-center"
      label="Loading..."
    />
    <iframe
      id="gameFrameContent"
      style="height: 80vh;width: 100%;"
      frameBorder="0"
    />
  </b-modal>

</template>
<script>
import { BModal, BSpinner } from 'bootstrap-vue'
// import { loadScript } from 'vue-plugin-load-script'

export default {
  components: {
    BModal,
    BSpinner,
  },
  props: {
    isMuted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isProcessing: true,
    }
  },
  async created() {
    const scripts = [
      '/springy-hedgehog/jquery-2.1.1.min.js',
      '/springy-hedgehog/ga_init.js',
      '/springy-hedgehog/mindpocket.js',
      '/springy-hedgehog/c2runtime.js',
      '/penguinSkip/starter_ga.js',
    ]

    this.$nextTick(() => {
      setTimeout(() => {
        let html = `<div id="canvasdiv">
          <canvas
            id="gameContainer"
            width="100%"
            height="100vh"
          />
        </div>`
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < scripts.length; i++) {
          html += `<script src="${scripts[i]}"></`
          html += 'script>'
        }
        setTimeout(() => {
          const iframe = document.getElementById('gameFrameContent')
          iframe.contentWindow.document.open()
          iframe.contentWindow.document.write(html)
          iframe.contentWindow.document.close()
          iframe.contentWindow.document.addEventListener('springyHedgehog', () => {
            this.$emit('gameOver')
          })
          this.isProcessing = false
        }, 1000)
      }, 500)
    })
  },
  methods: {
    stopPendingGame() {
      this.$emit('gameOver')
    },
  },
}
</script>
<style>
#pacRusher .modal-header .close {
  display:none;
}
</style>
