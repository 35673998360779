/* eslint-disable */

class AudioRecorder {
  constructor() {
    this.audioContext = null;
    this.audioRecorder = null;
    this.audioChunks = [];
    this.audioUrl = null;
  }

  startRecording() {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        const input = this.audioContext.createMediaStreamSource(stream);

        this.audioRecorder = this.audioContext.createScriptProcessor(
          4096,
          1,
          1
        );
        this.audioRecorder.onaudioprocess = (e) => {
          const channelData = e.inputBuffer.getChannelData(0);
          this.audioChunks.push(new Float32Array(channelData));
        };

        input.connect(this.audioRecorder);
        this.audioRecorder.connect(this.audioContext.destination);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  }

  stopRecording() {
    this.audioRecorder.disconnect();
    this.audioContext.close();

    const audioBlob = this.exportWAV(this.audioChunks);
    this.audioChunks = [];

    const audioUrl = URL.createObjectURL(audioBlob);
    this.audioUrl = audioUrl;
    return {
      audioBlob,
      audioUrl,
    };
  }

  exportWAV(chunks) {
    const { sampleRate } = this.audioContext;
    const interleaved = this.interleave(chunks);
    if (!interleaved) {
      return
    }
    const buffer = new ArrayBuffer(44 + interleaved.length * 2);
    const view = new DataView(buffer);

    // WAV header
    this.writeString(view, 0, "RIFF");
    view.setUint32(4, 36 + interleaved.length * 2, true);
    this.writeString(view, 8, "WAVE");
    this.writeString(view, 12, "fmt ");
    view.setUint32(16, 16, true); // PCM format
    view.setUint16(20, 1, true); // Mono channel
    view.setUint16(22, 1, true); // Number of channels
    view.setUint32(24, sampleRate, true); // Sample rate
    view.setUint32(28, sampleRate * 2, true); // Byte rate
    view.setUint16(32, 2, true); // Block align
    view.setUint16(34, 16, true); // Bits per sample
    this.writeString(view, 36, "data");
    view.setUint32(40, interleaved.length * 2, true);

    // Write the PCM samples
    this.floatTo16BitPCM(view, 44, interleaved);

    return new Blob([buffer], { type: "audio/wav" });
  }

  interleave(chunks) {
    const { length } = chunks;
    if (chunks[0]) {
      const result = new Float32Array(length * chunks[0].length);
      let offset = 0;
  
      for (let i = 0; i < length; i++) {
        result.set(chunks[i], offset);
        offset += chunks[i].length;
      }
  
      return result;
    }
  
  }

  floatTo16BitPCM(output, offset, input) {
    for (let i = 0; i < input.length; i++, offset += 2) {
      const s = Math.max(-1, Math.min(1, input[i]));
      output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
    }
  }

  writeString(view, offset, string) {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  }

  downloadAudio() {
    const link = document.createElement("a");
    link.href = this.audioUrl;

    // Set the file name
    link.download = "sample-audio.wav";

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the DOM
    document.body.removeChild(link);
  }
}

export default AudioRecorder;
