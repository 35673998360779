<template>
  <div class="text-center">
    <audio ref="audioPlayer"
           @timeupdate="updateProgressBar"
           @ended="resetPlayer"
    >
      <source :src="audioSrc"
              type="audio/mpeg"
      >
    </audio>
    <div class="d-flex justify-content-center">
      <div>
        <b-button
          :id="audioVolumeControlId"
          variant="gradient-warning btn-audio volume-btn"
          class="btn-icon"
          style="border-top-right-radius: 0;border-bottom-right-radius: 0;"
        >
          <feather-icon :icon="volume > 0 ? 'Volume2Icon' : 'VolumeXIcon'" />
        </b-button>
        <b-popover
          :target="audioVolumeControlId"
          triggers="click"
          no-fade
          class="custom-popover"
        >
          <vue-slider
            v-model="volume"
            direction="ltr"
            :min-value="0"
            :max-value="100"
            @change="setVolume"
          />

        </b-popover>
      </div>
      <transition name="width">
        <div v-if="isPlaying"
             class="progress-info"
        >
          <div class="progress-bar"
               @click="seek"
          >
            <div class="progress bg-success"
                 :style="{ width: progress + '%' }"
            />
          </div>
        </div>
      </transition>
      <div>
        <b-button
          variant="gradient-warning"
          class="btn-icon btn-audio play-btn"
          @click.stop="togglePlayback"
        >
          <feather-icon :icon="isPlaying ? 'PauseIcon' : 'PlayIcon'" />
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { BButton, BPopover, VBPopover } from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'

export default {
  components: {
    BButton,
    BPopover,
    VueSlider,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    audioSrc: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isPlaying: false,
      progress: 0,
      volume: 100,
      audioVolumeControlId: Math.random().toString(36).substring(2, 10),
    }
  },
  methods: {
    togglePlayback() {
      const audio = this.$refs.audioPlayer
      if (this.isPlaying) {
        audio.pause()
      } else {
        audio.play()
      }
      this.isPlaying = !this.isPlaying
    },
    updateProgressBar() {
      const audio = this.$refs.audioPlayer
      const progress = (audio.currentTime / audio.duration) * 100
      this.progress = progress
    },
    seek(event) {
      const audio = this.$refs.audioPlayer
      const progressBar = event.target
      const rect = progressBar.getBoundingClientRect()
      const clickX = event.clientX - rect.left
      const percentage = (clickX / progressBar.offsetWidth)
      audio.currentTime = audio.duration * percentage
    },
    setVolume() {
      const audio = this.$refs.audioPlayer
      audio.volume = this.volume / 100
    },
    resetPlayer() {
      this.progress = 0
      this.isPlaying = false
    },
  },
}
</script>

<style scoped>
.progress-bar {
  background-color: #ddd;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.progress {
  /* background-color: blue; */
  height: 100%;
}

/* button {
  margin-top: 10px;
} */
.progress-info {
  width: 100px;
  /* margin-top: 22px; */
}
::v-deep .popover-body {
  width: 200px;
}

/* .fade-enter-active, .fade-leave-active {
  transition: opacity 5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
} */
.width-enter-active, .width-leave-active {
  transition: width 1s;
}
.width-enter, .width-leave-to {
  width: 0;
}
.play-btn {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
@media (max-width: 576px) {
  .volume-btn{
    display:none;
  }
  .play-btn {
    border-top-left-radius: 0.358rem;
    border-bottom-left-radius: 0.358rem;
  }
}
</style>
