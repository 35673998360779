<template>
  <VueFileAgent
    ref="vueFileAgent"
    v-model="fileRecords"
    :theme="'grid'"
    :multiple="true"
    :deletable="true"
    :meta="true"
    :max-size="'20MB'"
    :max-files="14"
    :help-text="$t('choose-images-zip')"
    :error-text="{
      type: $t('error-text-type'),
      size: $t('error-text-size'),
    }"
    @select="filesSelected($event)"
    @beforedelete="onBeforeDelete($event)"
    @delete="fileDeleted($event)"
  />
</template>
<script>
import VueFileAgent from 'vue-file-agent'
import 'vue-file-agent/dist/vue-file-agent.css'

export default {
  components: {
    VueFileAgent: VueFileAgent.VueFileAgent,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fileRecords: [],
      fileRecordsForUpload: [], // maintain an upload queue
    }
  },

  watch: {
    fileRecords: {
      async handler(newValue) {
        const toUpdate = []
        // eslint-disable-next-line no-restricted-syntax
        for (const file of newValue) {
          let fileUrl = file.urlResized
          if (!fileUrl) {
            // eslint-disable-next-line no-await-in-loop
            fileUrl = await this.getBase64(file.file)
          }
          toUpdate.push({
            filename: file.name(),
            data: fileUrl,
            description: '',
          })
        }
        this.$emit('input', toUpdate)
      },
      deep: true,
    },
  },
  methods: {
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    filesSelected(fileRecordsNewlySelected) {
      const validFileRecords = fileRecordsNewlySelected.filter(
        fileRecord => !fileRecord.error,
      )
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords)
    },
    onBeforeDelete(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1)
        const k = this.fileRecords.indexOf(fileRecord)
        if (k !== -1) this.fileRecords.splice(k, 1)
        // eslint-disable-next-line no-restricted-globals
      } else if (confirm('Are you sure you want to delete?')) {
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord) // will trigger 'delete' event
      }
    },
    fileDeleted(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
      }
    },
  },
}
</script>
