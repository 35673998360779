<template>
  <validation-observer ref="reaForm"
                       class="filters"
  >
    <b-row>
      <b-col md="6"
             class="mb-2"
      >
        <validation-provider
          v-slot="{ errors }"
          name="grade"
          rules="required"
        >
          <b-form-group label="Select Grade"
                        label-for="select-grade"
          >
            <v-select
              id="select-grade"
              v-model="selectionForm.grade"
              :options="grades.map((sub) => ({ value: sub.value, label: sub.text }))"
              placeholder="Select Grade"
              :reduce="grade => grade.value"
              @input="getSubject"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>

      <b-col md="6"
             class="mb-2"
      >
        <validation-provider
          v-slot="{ errors }"
          name="subject"
          rules="required"
        >
          <b-form-group label="Select Subject"
                        label-for="select-subject"
          >
            <v-select
              id="select-subject"
              v-model="selectionForm.subject"
              placeholder="Select Subject"
              :options="subjects.map((sub) => ({ value: sub.id, label: sub.name }))"
              :reduce="subject => subject.value"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>

      <b-col md="6"
             class="mb-2"
      >
        <validation-provider
          v-slot="{ errors }"
          name="domain"
          rules="required"
        >
          <b-form-group label="Select Domains"
                        label-for="select-domains"
          >
            <v-select
              id="select-domains"
              v-model="selectionForm.domain"
              placeholder="Select Domains"
              :options="domains"
              :reduce="domain => domain.value"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>

      <b-col md="6"
             class="mb-2"
      >
        <validation-provider
          v-slot="{ errors }"
          name="standard"
          rules="required"
        >
          <b-form-group label="Select Standard"
                        label-for="select-standard"
          >
            <v-select
              id="select-standard"
              v-model="selectionForm.standard"
              placeholder="Select Standard"
              :options="standards"
              :reduce="standard => standard.value"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>

      <b-col md="6"
             class="mb-2"
      >
        <validation-provider
          v-slot="{ errors }"
          name="startDate"
          rules="required"
        >
          <b-form-group label="Start Date"
                        label-for="start-date"
          >
            <b-form-datepicker
              id="start-date"
              v-model="selectionForm.start_time"
              placeholder="Select Start Date"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>

      <b-col md="6"
             class="mb-2"
      >
        <validation-provider
          v-slot="{ errors }"
          name="endDate"
          rules="required"
        >
          <b-form-group label="End Date"
                        label-for="end-date"
          >
            <b-form-datepicker
              id="end-date"
              v-model="selectionForm.end_time"
              placeholder="Select End Date"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>

      <!-- Save Button -->
      <b-col class="text-right mt-3">
        <b-button
          v-if="props.eventId"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          class="mr-2"
          variant="outline-danger"
          @click="$emit('remove-event');"
        >
          {{ $t('actions.delete') }}
        </b-button>
        <b-button variant="primary"
                  :disabled="isProcessing"
                  @click="saveForm"
        >
          <b-spinner v-if="isProcessing"
                     small
                     class="mr-1"
          />
          {{ props.eventId ? 'Update' : 'Create' }}
        </b-button>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script setup>
import axios from 'axios'
import {
  BFormGroup, BRow, BCol, BFormDatepicker, BButton, BSpinner,
} from 'bootstrap-vue'
import {
  ref, onMounted, reactive, computed,
  getCurrentInstance,
} from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import getGrades from '@/views/AiDemo/selected-grade'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import useJwt from '@/auth/jwt/useJwt'

const props = defineProps({
  eventId: {
    type: [String, Number],
    default: null,
  },
  activeTab: {
    type: String,
    default: '',
  },
})

const root = getCurrentInstance().proxy.$root
const reaForm = ref(null)
const subjectEndPoint = '/api/v1/ai-demo/get-subjects'

const DEFAULT_GRADE = process.env.VUE_APP_REA_DEFAULT_SUBJECT ?? 49

const selectionForm = reactive({
  grade: DEFAULT_GRADE,
  subject: null,
  domain: null,
  standard: null,
  start_time: null,
  end_time: null,
  class_id: root.$route.params.classId,
})

const grades = getGrades()
const isProcessing = ref(false)
const subjects = ref([])

const domains = computed(() => {
  if (!selectionForm.subject) {
    return []
  }
  const dm = subjects.value.find(s => s.id === selectionForm.subject)?.domains || []
  return dm.map(d => ({ value: d.id, label: d.name, standards: d.standards }))
})

const standards = computed(() => {
  if (!selectionForm.domain) {
    return []
  }
  const dm = domains.value.find(s => s.value === selectionForm.domain)?.standards || []
  return dm.map(d => ({ value: d.id, label: d.name }))
})

const getSubject = async () => {
  axios.post(`${subjectEndPoint}/${selectionForm.grade}`).then(response => {
    subjects.value = response.data
    if (!selectionForm.subject) {
      selectionForm.subject = response.data[0]?.id || null
    }
  })
}

const emit = defineEmits(['refetch-events'])
const saveForm = () => {
  reaForm.value.validate().then(success => {
    if (success) {
      isProcessing.value = true
      if (props.eventId) {
        useJwt.updateReaAssignment(props.eventId, selectionForm).then(response => {
          root.showSuccessMessage(response)
          emit('refetch-events')
        }).catch(error => {
          root.showErrorMessage(error)
        }).finally(() => {
          isProcessing.value = false
        })
      } else {
        useJwt.createReaAssignment(selectionForm).then(response => {
          root.showSuccessMessage(response)
          emit('refetch-events')
        }).catch(error => {
          root.showErrorMessage(error)
        }).finally(() => {
          isProcessing.value = false
        })
      }
    }
  })
}

onMounted(() => {
  if (props.eventId && props.activeTab !== 'rea_event') {
    return
  }
  if (props.eventId) {
    isProcessing.value = true
    useJwt.showReaAssignment(props.eventId).then(async response => {
      const { data } = response.data
      selectionForm.grade = data.grade_id
      selectionForm.subject = data.subject_id
      await getSubject()
      selectionForm.domain = data.domain_id
      selectionForm.standard = data.standard_id
      selectionForm.start_time = data.start_time
      selectionForm.end_time = data.end_time
    }).catch(error => {
      root.showErrorMessage(error)
    }).finally(() => {
      isProcessing.value = false
    })
  } else {
    getSubject()
  }
})
</script>
