<template>
  <validation-observer ref="categoryForm">
    <div
      v-for="(category,index) of categories[level]"
      :key="index"
      class="border border-info p-2"
    >
      <multiple-image-picker
        :selected-images="category.images"
        @updateData="(data) => category.images = data"
      />
      <b-row>
        <b-col>
          <b-form-group label="Preparation Time">
            <cleave
              v-model="$store.state.problem.create.openEndWriting.preparationTime"
              class="form-control"
              :raw="false"
              :options="{
                time: true,
                timePattern: ['m', 's']
              }"
              placeholder="mm:ss"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Task Time">
            <cleave
              v-model="$store.state.problem.create.openEndWriting.taskTime"
              class="form-control"
              :raw="false"
              :options="{
                time: true,
                timePattern: ['m', 's']
              }"
              placeholder="mm:ss"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Question">
        <QuillEditorCustom
          v-model="category.question"
          placeholder="Questions"
          @blur="updateForAllLevel"
        />
      </b-form-group>
      <b-form-group label="Answers">
        <b-form-tags
          v-model.lazy="category.answers"
          input-id="tags-pills"
          tag-variant="primary"
          tag-pills
          size="lg"
          separator=" "
          :placeholder="$t('labels.create-problem.placeholder.answer')"
          class="mb-2"
          @input="updateForAllLevel"
        />
      </b-form-group>
    </div>
  </validation-observer>
</template>
<script>
/* eslint-disable */
import { BFormGroup, BCol, BRow, BButton, BFormTags, BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import MultipleImagePicker from '@/views/common/curriculum/components/Tools/MultipleImagePicker.vue'
import Cleave from 'vue-cleave-component'
import QuillEditorCustom from '@views/common/components/QuillEditorCustom.vue'

export default {
  components: {
    BFormGroup,
    BCol,
    BRow,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormTags,
    MultipleImagePicker,
    BFormTextarea,
    QuillEditorCustom,
    Cleave,
  },
  directives: {
    Ripple,
  },
  props: {
    level: {
      type: Number,
      default: () => 0,
    },
    answerType: {
      type: String,
      default: 'text',
    },
    canSetValueForAnswers: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      categories: this.$store.state.problem.create.openEndWriting.items,
    }
  },
  methods: {
    updateForAllLevel() {
        const values = this.categories[this.level]
        this.$store.state.problem.create.openEndWriting.items.forEach((states, level) => {
            if (level === this.level) {
              states = values
            }
            else if (!this.canSetValueForAnswers[level]) {
              states[0].answers = values[0].answers
              states[0].question = values[0].question  
              states[0].images = values[0].images || []  
            }
        })
    },
  },
}
</script>
