<template>
  <b-modal :visible="visible"
           size="lg"
           hide-footer
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <div v-if="!resultInfo">
      <b-skeleton
        animation="wave"
        width="85%"
      />
      <b-skeleton
        animation="wave"
        width="55%"
      />
      <b-skeleton
        animation="wave"
        width="70%"
      />
    </div>
    <ResultView v-else
                :route-params="props.params"
                :result-info="resultInfo"
                view-type="modal"
                :teachers-student-id="studentId"
    />
  </b-modal>
</template>
<script setup>
import { BModal, BSkeleton } from 'bootstrap-vue'
import { ref, onMounted } from 'vue'
import ResultView from '@/views/student/problem/compoments/Result.vue'
import useJwt from '@/auth/jwt/useJwt'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
  studentId: {
    type: Number,
    required: true,
  },
})
const visible = ref(true)
const resultInfo = ref(null)

// Fetch result information
const fetchResultInfo = () => {
  try {
    visible.value = true
    // Adjust the API endpoint and parameters as needed
    useJwt.getLessonResult(props.params.loaderId).then(res => {
      resultInfo.value = res.data.data
    })
  } catch (err) {
    console.error('Failed to fetch result information:', err)
  } finally {
    visible.value = false
  }
}
// Fetch data when component is mounted
onMounted(() => {
  fetchResultInfo()
})
</script>
