<template>
  <b-row class="pt-2 mx-0"
         style="background: #040828 !important"
  >
    <b-col
      v-for="(summary, index) of getSummary"
      :key="index"
      class="d-flex align-items-stretch col"
      md="6"
    >
      <b-card
        class="w-100"
        :class="summary.colorClass"
      >
        <h4 class="text-white"> <feather-icon
          :icon="summary.icon"
          size="20"
        /> {{ summary.title }}</h4>
        <div>
          <table class="w-100 table-sm">
            <tbody>
              <tr>
                <th>{{ $t('individual-report-module.item') }}</th>
                <th>{{ $t('labels.score') }}</th>
              </tr>
              <tr v-for="detail of summary.data"
                  :key="detail.title"
              >
                <td>{{ detail.title }}</td>
                <td>
                  <div v-if="summary.value === 'study-habit' && !detail.preventDivide">
                    <b>{{ number_format(detail.value , 2) }} %</b>
                  </div>
                  <div v-else>
                    <b>{{ detail.value }}</b>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    BRow, BCol, BCard,
  },
  props: {
    comparativeReport: {
      type: Object,
      default: () => {},
    },
    expectedReport: {
      type: Object,
      default: () => null,
    },
    actualReport: {
      type: Object,
      default: () => null,
    },
    performanceReport: {
      type: Object,
      default: () => null,
    },
    studyHabit: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    getOverallCourseReport() {
      return this.number_format((this.actualReport.total_course / this.expectedReport?.total_course) * 100, 2)
    },
    getSummary() {
      return [
        {
          title: i18n.t('individual-report-module.study-habit.title'),
          colorClass: 'bg-light-primary',
          icon: 'BookOpenIcon',
          value: 'study-habit',
          data: [
            {
              title: i18n.t('individual-report-module.study-habit.total_late'),
              value: this.studyHabit?.late || 0,
            },
            {
              title: i18n.t('individual-report-module.study-habit.total_not_done'),
              value: this.studyHabit?.not_done || 0,
            },
            {
              title: i18n.t('individual-report-module.study-habit.total_early'),
              value: this.studyHabit?.early || 0,
            },
            {
              title: i18n.t('individual-report-module.study-habit.total_on_time'),
              value: this.studyHabit?.on_time || 0,
            },
            {
              title: i18n.t('individual-report-module.study-habit.total_course_done'),
              value: `${this.actualReport.total_course}/${this.expectedReport?.total_course}`,
              preventDivide: true,
            },
            {
              title: i18n.t('individual-report-module.study-habit.total_lesson_done'),
              value: `${this.actualReport.total_lesson}/${this.expectedReport?.total_lessons}`,
              preventDivide: true,
            },
            {
              title: i18n.tc('student-report-module.report-pages.attendance'),
              value: `${this.comparativeReport?.attendance?.myScore || 0}/${this.comparativeReport?.attendance?.maxScore || 0}`,
              preventDivide: true,
            },
          ],
        },
        {
          title: i18n.t('individual-report-module.performance_summary.title'),
          colorClass: 'bg-light-success',
          icon: 'AwardIcon',
          value: 'performance_summary',
          data: [
            {
              title: i18n.t('individual-report-module.performance_summary.avg_course_competition'),
              value: `${this.getOverallCourseReport}%`,
            },
            {
              title: i18n.t('individual-report-module.performance_summary.avg_lessons_competition'),
              value: `${this.performanceReport?.avg_lessons_competition_rate}%`,
            },
            {
              title: i18n.t('individual-report-module.performance_summary.avg_pass_score'),
              value: `${this.performanceReport?.avg_pass_score}%`,
            },
          ],
        },
      ]
    },
  },
}
</script>
