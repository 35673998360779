<template>
  <div>
    <component
      :is="isFullView ? 'div' : 'b-modal'"
      id="modal-primary"
      ref="updateModal"
      v-model="isVisible"
      ok-only
      modal-class="modal-primary"
      centered
      :title="$t('lesson-pages-module.edit-page')"
      size="xl"
      hide-footer
      @close="close"
      @hide="close"
    >
      <div class="p-2">
        <b-row>
          <b-col class="pb-2">
            <h4>
              <a :href="`/super/problem?lesson_id=${lessonData?.lesson?.id}`"
                 target="_blank"
              >{{ lessonData?.lesson?.name }}</a>  <small>{{ $t('lesson-pages-module.update-problem') }}</small>
            </h4>
          </b-col>
        </b-row>
        <div v-if="lessonData!=null">
          <validation-observer ref="SubmitLessonPage">
            <images-section
              :lesson="lessonData"
            />
            <contents-section
              :lesson="lessonData"
              @submitData="submitData"
            />
            <word-net-section
              :lesson="lessonData"
              :page-id="pageId"
            />
            <b-row>
              <b-col md="6">
                <label><b>{{ $t('translation-wordnet-module.default-language') }}</b></label>
                <select
                  ref="selectedValue"
                  class="form-control"
                  @change="setDefaultLanguage"
                >
                  <option
                    value="kor"
                    :selected="defaultLanguage==='kor'"
                  >
                    {{ $t('translation-wordnet-module.korean') }}
                  </option>
                  <option
                    value="jpn"
                    :selected="defaultLanguage==='jpn'"
                  >
                    {{ $t('translation-wordnet-module.japanese') }}
                  </option>
                </select>
              </b-col>
            </b-row>
            <vocabulary-section
              :lesson="lessonData"
              @submitData="submitData"
            />
          </validation-observer>
        </div>
      </div>
      <hr>
      <b-col
        md="12"
        class="text-right"
        sm="12"
      >
        <b-button
          variant="primary"
          class="btn btn-primary"
          @click="submitData"
        >
          {{ $t('lesson-pages-module.update') }}
        </b-button>
      </b-col>
    </component>
  </div>
</template>

<script>
import {
  BRow, BCol, BModal, BButton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import ContentsSection from './components/ContentsSection.vue'
import ImagesSection from './components/ImagesSection.vue'
import VocabularySection from './components/VocabularySection.vue'
import WordNetSection from './components/WordNetSection.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BRow, BCol, BModal, BButton, ContentsSection, ImagesSection, VocabularySection, WordNetSection, ValidationObserver,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    pageId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isVisible: this.show,
      lessonData: null,
      originalLessonData: null,
      isProcessing: false,
      defaultLanguage: '',
    }
  },
  computed: {
    isFullView() {
      return !!this.$route.params.pageId
    },
  },
  watch: {
    pageId() {
      if (this.pageId) this.fetchPageData()
    },
    show() {
      this.isVisible = this.show
    },
  },
  created() {
    if (this.$route.params.pageId) {
      this.pageId = this.$route.params.pageId
      this.fetchPageData()
    }
  },
  methods: {
    close() {
      this.pageId = null
      this.lessonData = null
      this.originalLessonData = null
      this.$emit('close')
    },
    fetchPageData() {
      if (!this.pageId) return
      useJwt.getLessonPage(this.pageId).then(res => {
        this.lessonData = res.data.data
        this.defaultLanguage = this.lessonData.default_lang
        this.originalLessonData = JSON.parse(JSON.stringify(this.lessonData))
      })
    },
    submitData(bvModalEvent) {
      if (bvModalEvent)bvModalEvent.preventDefault()
      this.$refs.SubmitLessonPage.validate().then(res => {
        if (!res) return
        this.isProcessing = true
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.accessToken}`,
          },
        }
        const data = new FormData()
        // data.append('_method', 'put')
        data.append('page_title', this.lessonData.page.title)
        data.append('bottom_question', this.lessonData.bottom_question)
        data.append('extra_questions', this.lessonData.extra_questions)
        data.append('main_text', this.lessonData.main_text)
        data.append('questions', this.lessonData.questions)
        data.append('default_lang', this.defaultLanguage)

        // this.lessonData.vocabulary.forEach(el => {
        //   data.append('synonym1[]', el.synonym_1)
        //   data.append('synonym2[]', el.synonym_2)
        //   data.append('w_in_japnease[]', el.word_in_eng)
        //   data.append('w_in_english[]', el.word_in_jap)
        // })
        const vocabData = this.lessonData.vocabulary
        vocabData.filter(voc => JSON.stringify(voc))
        data.append('vocabulary', JSON.stringify(vocabData))
        // data.append('synonym1', JSON.stringify(this.lessonData?.vocabulary?.length > 0 ? this.lessonData?.vocabulary?.map(el => el.synonym_1) : []))
        // data.append('synonym2', JSON.stringify(this.lessonData?.vocabulary?.length > 0 ? this.lessonData?.vocabulary?.map(el => el.synonym_2) : []))
        // data.append('w_in_japnease', JSON.stringify(this.lessonData?.vocabulary?.length > 0 ? this.lessonData?.vocabulary?.map(el => el.word_in_jap) : []))
        // data.append('w_in_english', JSON.stringify(this.lessonData?.vocabulary?.length > 0 ? this.lessonData?.vocabulary?.map(el => el.word_in_eng) : []))
        if (this.lessonData?.question_radius_image !== this.originalLessonData?.question_radius_image)data.append('question_radius_image', this.lessonData.question_radius_image)
        if (this.lessonData?.top_image !== this.originalLessonData?.top_image)data.append('top_image', this.lessonData?.top_image)
        if (this.lessonData?.question_bg_image !== this.originalLessonData?.question_bg_image)data.append('question_bg_image', this.lessonData?.question_bg_image)
        if (this.lessonData?.vocab_image !== this.originalLessonData?.vocab_image)data.append('vocab_bg_image', this.lessonData?.vocab_image)
        useJwt.axiosIns.post(`/api/v1/update-lesson-page/${this.pageId}`, data, config)
          .then(response => {
            this.showSuccessMessage(response)
            if (response.data.success) this.$refs.updateModal.hide()
            this.$emit('update')
          })
          .catch(err => {
            this.showErrorMessage(err)
          })
          .finally(() => {
            this.isProcessing = false
          })
      })
    },
    setDefaultLanguage() {
      this.defaultLanguage = this.$refs.selectedValue.value
    },
  },
}
</script>

<style>

</style>
