<template>
  <b-card :title="isEditMode ? 'Enter Main Points' : 'Main Points'"
          class="mb-3"
  >
    <div v-for="index in 3"
         :key="index"
    >
      <b-form-group :label="`Main Point ${index}`"
                    :label-for="`point-${index}-input`"
      >
        <div class="row">
          <div class="col-6">

            <b-form-group label="Main Point"
                          :label-for="`assets-url-${index}-input`"
            >
              <b-input-group class="mb-2">
                <b-form-textarea
                  :id="`point-${index}-input`"
                  v-model="mainPoints[`point_${index}`]"
                  :placeholder="`Main Point ${index}`"
                />
                <b-input-group-append>
                  <b-input-group-text>
                    <feather-icon icon="EditIcon" />
                  </b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- <b-input-group class="mb-2">
              <b-form-input
                :id="`point-${index}-input`"
                v-model="mainPoints[`point_${index}`]"
                :placeholder="`Main Point ${index}`"
              />
              <b-input-group-append>
                <b-input-group-text>
                  <feather-icon icon="EditIcon" />
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group> -->
          </div>
          <div class="col-6">
            <b-form-group label="`Assets URL for Main Point`"
                          :label-for="`assets-url-${index}-input`"
            >
              <b-input-group class="mb-2">
                <b-form-input
                  :id="`assets-url-${index}-input`"
                  v-model="mainPoints[`assets_url_${index}`]"
                  :placeholder="`Assets URL for Main Point ${index}`"
                />
                <b-input-group-append>
                  <b-input-group-text>
                    <feather-icon icon="LinkIcon" />
                  </b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </b-form-group>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupText,
  BInputGroupAppend,
  BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupText,
    BInputGroupAppend,
    BFormTextarea,
  },
  props: {
    mainPoints: {
      type: Object,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
