<template>
  <div>
    <b-row v-show="!hideTitle">
      <b-col
        class="float-left"
        sm="8"
      >
        <h3>
          <span v-if="fromDashboard">{{ $t('time-on') }} :</span>
          {{ formattedDate }}
        </h3>
      </b-col>
      <b-col class="float-right">
        {{ totalPresentDays }} <span v-if="totalPresentDays > 1">{{ $t('labels.days') }}</span><span v-else>{{ $t('labels.day') }}</span>
      </b-col>
    </b-row>
    <div
      class="calendar"
      :class="{ 'pt-2': !hideTitle }"
    >
      <template v-if="!hideTitle">
        <span
          v-for="week of weeks"
          :key="week"
          class="item text-center"
        >
          {{ week }}
        </span>
      </template>
      <div
        v-for="i of blockStart"
        :key="`o${i}`"
        class="item pb-1"
      />
      <div
        v-for="i of noOfDays"
        :key="i.day"
        class="item"
      >
        <b-card
          v-b-tooltip.hover.top="`${i.day}`"
          class="card block"
          :class="[isPresent(i.day) ? 'bg-success' : 'bg-danger' ]"
          role="button"
          no-body
          @click="isPresent(i.day) && handleDayClick(i.day)"
        >
          <b-card-body
            class="p-0 text-white text-center"
            style="margin-top: 4px"
          >
            <span :class="{'selected-day': selectedDate === i.day }">{{ i.dayNum }}</span>
          </b-card-body>
        </b-card>
      </div>
      <div
        v-for="i of blockEnd"
        :key="`n${i}`"
        class="item"
      />
    </div>
  </div>
</template>
<script>
import {
  BCard, VBTooltip, BRow, BCol, BCardBody,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

const moment = require('moment')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardBody,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    year: {
      type: Number,
      default: 0,
    },
    month: {
      type: Number,
      default: 0,
    },
    userId: {
      type: [Object, String, Number],
      default: '',
    },
    attendance: {
      type: Array,
      default: () => [],
    },
    fromDashboard: {
      type: Boolean,
      default: false,
    },
    selectedDate: {
      type: String,
      default: '',
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      processing: false,
      blockStart: 0,
      noOfDays: [],
      blockEnd: 0,
      formattedDate: '',
      presentDays: {},
      self: getUserData(),
      weeks: [
        this.$i18n.tc('sun'),
        this.$i18n.tc('mon'),
        this.$i18n.tc('tue'),
        this.$i18n.tc('wed'),
        this.$i18n.tc('thur'),
        this.$i18n.tc('fri'),
        this.$i18n.tc('sat')],
    }
  },
  computed: {
    totalPresentDays() {
      return this.noOfDays.filter(value => this.attendance.includes(value.day)).length
    },
  },
  watch: {
    year: {
      handler() {
        this.createBlocks()
      },
      immediate: true,
    },
    userId: {
      handler() {
        this.createBlocks()
      },
    },
  },
  methods: {
    createBlocks() {
      const current = `${this.year}-${this.month}-01`
      const startOfMonth = moment(current).clone().startOf('month')
      const endOfMonth = moment(current).clone().endOf('month')
      this.blockStart = startOfMonth.day()
      this.blockEnd = 6 - endOfMonth.day()
      const totalDays = []
      for (
        const m = moment(startOfMonth);
        m.isBefore(endOfMonth);
        m.add(1, 'days')
      ) {
        totalDays.push({
          day: m.format('YYYY-MM-DD'),
          dayNum: m.format('DD'),
        })
      }
      this.noOfDays = totalDays
      this.formattedDate = startOfMonth.format('MMM-YYYY')
    },
    isPresent(date) {
      return this.attendance.includes(date)
    },
    handleDayClick(date) {
      const { self } = this
      this.$router.push({ name: self.usertype === 'student' ? 'student-diary' : `${self.usertype}-student-diary`, params: { id: this.userId }, query: { date } })
    },
  },
}
</script>
<style>
.calendar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.item {
  width: 14.285714286%;
  height: 2em;
  margin-bottom: 1%; /* (100-32*3)/2 */
  position: relative;
}
.block {
  height: 100%;
  margin-right: 6%;
}
.selected-day {
  font-weight: bold;
  color: chartreuse;
}
</style>
