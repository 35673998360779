<template>
 <b-modal
    id="2048"
    title="2048"
    ok-only
    no-close-on-backdrop
    :visible="true"
    size="lg"
    ok-title="Accept"
    hide-footer
    @hide="stopPendingGame"
    @close="stopPendingGame"
  >
 <div class="container m-auto game-2048">
    <div class="heading">
      <h1 class="title">2048</h1>
      <div class="scores-container mb-1">
        <div class="score-container">0</div>
        <div class="best-container ml-1">0</div>
      </div>
    </div>

    <div class="above-game">
      <h5 class="game-intro">Use arrow keys. Join the numbers and get to the <strong>2048 tile!</strong></h5>
      <a class="restart-button">New Game</a>
    </div>

    <div class="game-container">
      <div class="game-message">
        <p></p>
        <div class="lower">
	        <a class="keep-playing-button">Keep going</a>
          <a class="retry-button">Try again</a>
        </div>
      </div>

      <div class="grid-container">
        <div class="grid-row">
          <div class="grid-cell"></div>
          <div class="grid-cell"></div>
          <div class="grid-cell"></div>
          <div class="grid-cell"></div>
        </div>
        <div class="grid-row">
          <div class="grid-cell"></div>
          <div class="grid-cell"></div>
          <div class="grid-cell"></div>
          <div class="grid-cell"></div>
        </div>
        <div class="grid-row">
          <div class="grid-cell"></div>
          <div class="grid-cell"></div>
          <div class="grid-cell"></div>
          <div class="grid-cell"></div>
        </div>
        <div class="grid-row">
          <div class="grid-cell"></div>
          <div class="grid-cell"></div>
          <div class="grid-cell"></div>
          <div class="grid-cell"></div>
        </div>
      </div>

      <div class="tile-container">

      </div>
    </div>
  </div>
 </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'

export default {
components: {
  BModal
},
mounted(){
    const scripts = [
        '/2048/js/bind_polyfill.js',
        '/2048/js/classlist_polyfill.js',
        '/2048/js/animframe_polyfill.js',
        '/2048/js/keyboard_input_manager.js',
        '/2048/js/html_actuator.js',
        '/2048/js/grid.js',
        '/2048/js/tile.js',
        '/2048/js/local_storage_manager.js',
        '/2048/js/game_manager.js',
        '/2048/js/application.js'
    ]
    scripts.forEach(s=>{
      let script = document.createElement('script')
      script.setAttribute('src', s)
      document.head.appendChild(script)
    })
    setInterval(() => {
        if(window.GameManager2048.isGameTerminated()) this.$emit('gameOver');
    }, 2000);
},
methods: {
  stopPendingGame() {
    document.dispatchEvent(new Event('stopGameFromGec'))
    this.$emit('gameOver')
  },
}
}
</script>
<style lang="scss" src="./style/main.scss">
</style>
