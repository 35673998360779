<template>
  <div>
    <!-- <h3>Choose a level</h3>
    <b-row class="ecommerce-application match-height pt-2">
      <b-col
        v-for="l of levels"
        :key="l.value"
        md="4"
        role="button"
        @click="$emit('choose',l.value)"
      >
        <b-card
          class="ecommerce-card"
          no-body
        >
          <img
            class="card-img-top"
            :src="l.image"
          >
          <b-card-body class="text-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="l.color"
              class="btn-icon rounded-circle"
              size="lg"
            >
              <feather-icon
                :icon="l.icon"
                size="30"
              />
            </b-button>
            <div class="truncate pt-1">
              <h2 class="mb-25 font-weight-bolder">
                {{ l.name }}
              </h2>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row> -->
    <div
      v-for="grade, index in grades"
      :key="index"
    >
      <h2 class="text-center mb-1">
        {{ grade.level }}
      </h2>
      <b-row>
        <b-col
          v-for="lesson in grade.lessons"
          :key="lesson.id"
          md="4"
          role="button"
          @click="$emit('choose',lesson.value)"
        >
          <b-card
            class="ecommerce-card"
            no-body
          >
            <img
              class="card-img-top"
              :src="lesson.image"
            >
            <b-card-body class="text-center">
              <div class="truncate">
                <h3 class="mb-25 font-weight-bolder">
                  {{ lesson.title }}
                </h3>
                <h4 class="mb-25 font-weight-bolder">
                  {{ lesson.name }}
                </h4>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BCardBody, // BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    // BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      levels: [
        {
          name: 'Grammar', value: 'grammer', icon: 'ShieldIcon', color: 'success', image: '/edu/images/1kinder.jpg',
        },
        {
          name: 'Elementary', value: 'elementary', icon: 'ShieldIcon', color: 'primary', image: '/edu/images/1elem.jpg',
        },
        {
          name: 'Middle School', value: 'middle-school', icon: 'ShieldIcon', color: 'danger', image: '/edu/images/1hs.jpg',
        },
      ],

      grades: [
        {
          level: 'K-grade 2',
          lessons: [
            {
              id: 2101, title: 'Alphabet', value: 'k-grade-2-alphabet', name: 'Alphabet Aa, Bb, Cc', image: '/edu/demo-lesson/K_2_alphabet.jpeg',
            },
            {
              id: 492, title: 'Phonics', value: 'k-grade-2-phonics', name: 'Early Birds', image: '/edu/demo-lesson/k_2_phonics.jpeg',
            },
            {
              id: 1114, title: 'Vocabulary', value: 'k-grade-2-vocabulary', name: 'Body Parts 1', image: '/edu/demo-lesson/k_2_vocabulary.jpeg',
            },
          ],
        },
        {
          level: 'Grade 3-4',
          lessons: [
            {
              id: 357, title: 'Functional', value: 'grade-3-4-functional', name: 'Runway 1: What\'s Your Name', image: '/edu/demo-lesson/gr3_4_functional.jpeg',
            },
            {
              id: 240, title: 'Grammar', value: 'grade-3-4-grammar', name: 'Identify Plural Nouns', image: '/edu/demo-lesson/gr3_4_grammar.jpeg',
            },
            {
              id: 2666, title: 'Eiken Testing', value: 'grade-3-4-eiken-testing', name: 'Eiken Testing 5', image: '/edu/demo-lesson/gr3_4_eiken.jpeg',
            },
          ],
        },
        {
          level: 'Grade 5-6',
          lessons: [
            {
              id: 425, title: 'Functional', value: 'grade-5-6-functional', name: 'Lift Off 1: Who is the Boy?', image: '/edu/demo-lesson/gr5_6_functional.jpeg',
            },
            {
              id: 215, title: 'Grammar', value: 'grade-5-6-grammar', name: 'Identify Adjectives', image: '/edu/demo-lesson/gr5_6_grammar.jpeg',
            },
            {
              id: 2667, title: 'Eiken Testing', value: 'grade-5-6-eiken-testing', name: 'Eiken Testing 4', image: '/edu/demo-lesson/gr5_6 eiken.jpeg',
            },
          ],
        },
        {
          level: 'Middle School',
          lessons: [
            {
              id: 685, title: 'Functional', value: 'middle-school-functional', name: 'In Flight 1: The Arctic', image: '/edu/demo-lesson/ms_functional.jpeg',
            },
            {
              id: 1493, title: 'Academic', value: 'middle-school-academic', name: 'Personal Economics', image: '/edu/demo-lesson/ms_academics.jpeg',
            },
            {
              id: 2668, title: 'Eiken Testing', value: 'middle-school-elken-testing', name: 'Eiken Testing 3', image: '/edu/demo-lesson/ms_eiken.jpeg',
            },
          ],
        },
        {
          level: 'High School',
          lessons: [
            {
              id: 874, title: 'Functional', value: 'high-school-functional', name: 'In Orbit 1: Time Phrases and Directions', image: '/edu/demo-lesson/hs_functional.jpeg',
            },
            {
              id: 1092, title: 'Academic', value: 'high-school-academic', name: 'Heredity', image: '/edu/demo-lesson/hs_academic.jpeg',
            },
            {
              id: 2669, title: 'Eiken Testing', value: 'high-school-eiken-testing', name: 'Eiken Testing 2.5', image: '/edu/demo-lesson/hs_eiken.jpeg',
            },
          ],
        },
        {
          level: 'Miscellaneous',
          lessons: [
            {
              id: 1497, title: 'CEFR', value: 'cefr', name: 'Multi Skill Adaptive Test', image: '/edu/demo-lesson/CEFR.jpeg',
            },
            {
              id: 975, title: 'Travel', value: 'travel', name: 'Boston: Homestay', image: '/edu/demo-lesson/travel.jpeg',
            },
            {
              id: 2109, title: 'Business', value: 'business', name: 'Business Corner: Meeting New People', image: '/edu/demo-lesson/business.jpeg',
            },
          ],
        },
      ],
    }
  },
}
</script>
<style>
     .ecommerce-application .ecommerce-card:hover {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
      -webkit-box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
      box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
  }
</style>
