<template>
  <div class="d-flex p-2 justify-content-center align-items-center hot-spot-feedback ">
    <div>
      <div style="position:relative">
        <div
          class="problem-card-title md-12"
        >
          <h3 class="mb-0">
            {{ $t("labels.create-problem.feedback") }}
          </h3>
        </div>
        <img
          id="myImage"
          :src="hotspot.file"
        >
        <!-- <div
          v-for="point,i in hotspot.points"
          :key="i"
        > -->
        <template v-if="point">
          <div
            :class="`point ${!point.feedback_visited ? ' clickable' :' not-clickable'}`"
            :style="`top: ${point.coordinates.y*100 }%; left: ${ point.coordinates.x*100 }%; `"
            @click="checkPoints()"
          >
            <span>{{ point.feedback_visited ? '✓' : '+' }}</span>
          </div>
          <div
            :style="`top: ${point.coordinates.y* 100 }%; left: calc(${point.coordinates.x<0.7 ? (point.coordinates.x *100+2)+'%' : (point.coordinates.x*100+'%') +' - '+ (tooltipWidth+25)+'px' }); position:absolute;`"
          >
            <div
              :class="`tooltiptext d-flex justify-content-between align-items-center p-1 ${point.coordinates.x<0.7 ? 'tooltip-left' : 'tooltip-right'}`"
              :style="`width:${tooltipWidth}px;`"
            >
              <AudiPlayer
                v-if="hotspot.feedbackAudio"
                :key="hotspot.feedbackAudio"
                :src="hotspot.feedbackAudio"
              />
              <span style="color:white !important;"> {{ hotspot.feedback }} </span>
              <!-- <span
                class="btn btn-sm btn-success"
                @click="pointVisited(i)"
              > ✓</span> -->

            </div>

          </div>
        </template>

        <!-- </div> -->
      </div>
      <!-- <div
        v-if="showLevel"
        class="d-flex col-12 p-0 justify-content-between align-content-center problem-card-footer"
      >
        <actions
          :level="level"
          mode="feedback"
          :on-submit="()=>{$emit('proceedToQuestion')}"
          :button-title="$t('next')"
          :action-visible="true"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import AudiPlayer from '@/views/common/components/AudioPlayer.vue'
// import useJwt from '@/auth/jwt/useJwt'
// import Actions from '../compoments/Actions.vue'

export default {
  components: {
    AudiPlayer,
  },
  props: {
    showHotSpotPoint: {
      type: Boolean,
      default: () => false,
    },
    hotspot: {
      type: Object,
      default: () => {},
    },
    level: {
      type: [String, Number, Object],
      default: () => {},
    },
    startTime: {
      type: [Object, Array],
      default: () => {},
    },
    duration: {
      type: [Object, Array],
      default: () => {},
    },
    testId: {
      type: [Number, String],
      default: () => {},
    },
  },
  data() {
    return {
      matchedPointIndex: null,
      showLevel: true,
      tooltipWidth: 200,
    }
  },

  computed: {
    allVisited() {
      return this.point.feedback_visited
    },
    point() {
      return this.hotspot.hotspotPoints
    },
  },

  methods: {
    checkPoints() {
      this.matchedPointIndex = 0
    },
    pointVisited() {
      this.hotspot.hotspotPointsfeedback_visited = true
      this.$emit('proceedToQuestion')
      this.matchedPointIndex = null
    },

  },
}
</script>

<style scoped lang="scss">

/* Tooltip text */
.tooltiptext {
  min-width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.tooltip-left::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #000 transparent transparent;
}

.tooltip-right::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #000;
}

.point{
  width:25px;
  height:25px;
  background-color: white;
  border:2px solid black;
  border-radius:50%;
  position:absolute;
  display:flex;
  justify-content:center;
      margin-top:20px;
  margin-left:-20px;
  align-items:center;

}

.point span{
  font-weight: 600;
  font-size:18px;
  color:black;
}

#myImage{
  display: block;
  max-width:100%;
  max-height:100%;
  width: auto;
  height: auto;
    min-width:800px;
  min-height:600px;
}
.clickable{
  cursor: pointer;
    -webkit-animation: pulse 2s ease-in-out infinite;
  animation: pulse 2s ease-in-out infinite;
}
.not-clickable{
  cursor:not-allowed;
  background-color:rgb(9, 193, 9);
  span{
    color:white;
    font-size:12px;
  }
}

@keyframes pulse {
  0%, 70% {
    box-shadow: 0px 0px 0px 0px  rgba(130, 236, 10, 0.97);
  }
  100% {
    box-shadow: 0px 0px 0px 50px rgba(125, 231, 4, 0);
  }
}

@media (max-width:1000px) {
  #myImage{
    min-width:400px !important;
    min-height:400px !important;
  }
}

</style>
<style>
.hot-spot-feedback .audio-play-stop {
  width: 10px !important;
  height: 10px !important;
}
</style>
