// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}
export const guestUserInfo = {
  firstname: 'Guest',
}

export const getUserData = () => {
  try {
    return JSON.parse(localStorage.getItem('userData'))
  } catch {
    return null
  }
}

export const getUserToken = () => localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
export const isHighLevelUser = (level = 7) => getUserData() && getUserData().user_level < level

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}

export const isAParentChild = childId => {
  const childIds = getUserData().children_ids ?? []
  return childIds.includes(Number(childId))
}

export const isStudentYoung = () => {
  if (getUserData() && getUserData().usertype === 'student' && getUserData().dob) {
    const age = parseInt(moment().diff(getUserData().dob, 'years'), 10)
    return age < 13
  }
  return false
}
