<!-- Modal.vue -->
<template>
  <div>
    <b-modal
      :visible="showSendMailModal"
      :title="title"
      size="lg"
      ok-only
      ok-title="Accept"
      hide-footer
      @close="$emit('close')"
      @hide="$emit('close')"
    >
      <form @submit.prevent="sendEmail">
        <b-form-group id="to-group"
                      :label="`${$t('student-report-module.summary-table.to')} :`"
                      label-for="to"
        >
          <b-form-input id="to"
                        :placeholder="student.fullname"
                        disabled
          />
        </b-form-group>

        <b-form-group id="subject-group"
                      :label="`${$t('student-lab-module.lesson-model.subject')}:`"
                      label-for="subject"
        >
          <b-form-input id="subject"
                        v-model="emailSubject"
                        required
          />
        </b-form-group>

        <b-form-group id="message-group"
                      :label="`${$t('labels.message')}:`"
                      label-for="message"
        >
          <ckeditor
            id="textarea-default"
            v-model.lazy="emailMessage"
            :placeholder="$t('labels.message')"
            rows="10"
            no-shrink
            :config="ckEditConfig"
          />
        </b-form-group>

        <b-button type="submit"
                  variant="success"
                  class="float-right"
        >
          {{ $t('send-notification-module.send') }}
          <b-spinner v-if="isSendingEmail"
                     small
                     label="Sending..."
          />
        </b-button>
        <b-button variant="secondary"
                  @click="closeModal"
        >
          {{ $t('actions.close') }}
        </b-button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BModal, BFormGroup, BFormInput, BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import CKEditor from 'ckeditor4-vue'

export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    // BFormTextarea,
    BSpinner,
    ckeditor: CKEditor.component,
  },
  props: {
    showSendMailModal: Boolean,
    classroomId: {
      type: [Number, String],
      required: true,
    },
    student: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      emailTo: '',
      emailSubject: '',
      emailMessage: '',
      isSendingEmail: false,
      title: this.$i18n.tc('actions.send-email'),
      ckEditConfig: {
        toolbar: [
          { name: 'basicstyles', items: ['Underline', 'Italic', 'Bold', 'colorbutton'] },
          ['TextColor', 'BGColor'],
        ],
        extraPlugins: 'colorbutton',
        colorButton_colors: 'CF5D4E,454545,FFF,DDD,CCEAEE,66AB16',
        removeButtons: 'Subscript,Superscript',
      },
    }
  },
  computed: {
    authUser() {
      return this.AUTH_USER()
    },
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    sendEmail() {
      this.isSendingEmail = true
      // Send the email via an API request
      const emailData = {
        to: this.student.id,
        from: this.authUser.id,
        subject: this.emailSubject,
        message: this.emailMessage,
      }

      // Replace 'your-api-url' with the actual API endpoint to send the email
      useJwt.sendEmailToStudent(this.classroomId, emailData)
        .then(() => {
          alert('Email sent successfully')
          this.closeModal()
        })
        .catch(error => {
          console.error('Error sending email:', error)
          alert('An error occurred while sending the email')
        }).finally(() => {
          this.isSendingEmail = false
        })
    },
  },
}
</script>

  <style scoped>
  /* Add your modal styles here */
  .modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
  }

  .form-group {
    margin-bottom: 10px;
  }

  /* Add more styles as needed */
  </style>
