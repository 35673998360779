<template>
  <div
    class="draggable-resizable"
    :style="{ left: `${internalX}px`, top: `${internalY}px`, width: `${internalWidth}px`, height: `${internalHeight}px`, border: '2px solid ' + borderColor }"
    @mousedown="startDrag"
  >
    <slot />
    <div v-if="canBeDestroyed"
         class="close-me"
    >
      <feather-icon icon="XIcon"
                    size="20"
                    class="text-danger"
                    @click="$emit('remove')"
      />
    </div>
    <div v-if="tag"
         class="tag-area"
    >
      {{ tag }}
    </div>
    <div class="resize-handle"
         style="right: 0;  cursor: nwse-resize;"
         @mousedown="startResize"
    />
    <div class="resize-handle"
         style="left: 0;  cursor: nesw-resize;"
         @mousedown="startResize"
    />

  </div>
</template>

<script>
export default {
  props: {
    x: { type: Number, default: 0 },
    y: { type: Number, default: 0 },
    width: { type: Number, default: 200 },
    height: { type: Number, default: 150 },
    parentWidth: { type: Number, default: 0 },
    parentHeight: { type: Number, default: 0 },
    draggable: { type: Boolean, default: true },
    borderColor: { type: String, default: 'red' },
    tag: { type: String, default: '' },
    canBeDestroyed: { type: Boolean, default: true },
  },
  data() {
    return {
      isDragging: false,
      isResizing: false,
      startX: 0,
      startY: 0,
      startWidth: 0,
      startHeight: 0,
      internalX: this.x,
      internalY: this.y,
      internalWidth: this.width,
      internalHeight: this.height,
    }
  },
  watch: {
    x(newX) {
      this.internalX = newX
    },
    y(newY) {
      this.internalY = newY
    },
    width(newWidth) {
      this.internalWidth = newWidth
    },
    height(newHeight) {
      this.internalHeight = newHeight
    },
  },
  methods: {
    updateCoordinate() {
      this.$emit('updateCoordinate', {
        height: this.internalHeight,
        width: this.internalWidth,
      })
    },
    startDrag(e) {
      e.preventDefault()
      if (!this.isResizing && this.draggable) {
        this.isDragging = true
        this.startX = e.clientX - this.internalX
        this.startY = e.clientY - this.internalY
        this.$emit('dragStart')
        window.addEventListener('mousemove', this.drag)
        window.addEventListener('mouseup', this.stopDrag)
      }
    },
    drag(e) {
      if (this.isDragging) {
        let newX = e.clientX - this.startX
        let newY = e.clientY - this.startY

        // Limit the position to stay within the parent boundaries
        newX = Math.max(0, Math.min(newX, this.parentWidth - this.internalWidth))
        newY = Math.max(0, Math.min(newY, this.parentHeight - this.internalHeight))

        this.internalX = newX
        this.internalY = newY
      }
    },
    stopDrag() {
      this.isDragging = false
      this.$emit('dragEnd')
      window.removeEventListener('mousemove', this.drag)
      window.removeEventListener('mouseup', this.stopDrag)
      this.updateCoordinate()
    },
    startResize(e) {
      e.preventDefault()
      this.isDragging = false // Stop dragging when resizing starts
      this.isResizing = true
      this.startWidth = this.internalWidth
      this.startHeight = this.internalHeight
      this.startX = e.clientX
      this.startY = e.clientY
      this.$emit('dragStart')
      window.addEventListener('mousemove', this.resize)
      window.addEventListener('mouseup', this.stopResize)
    },
    resize(e) {
      if (this.isResizing) {
        const newWidth = Math.max(10, this.startWidth + e.clientX - this.startX)
        const newHeight = Math.max(10, this.startHeight + e.clientY - this.startY)

        // Check if the new dimensions exceed the parent boundaries
        if (newWidth <= this.parentWidth && newHeight <= this.parentHeight) {
          this.internalWidth = newWidth
          this.internalHeight = newHeight
        }
      }
    },
    stopResize() {
      this.isResizing = false
      this.$emit('dragEnd')
      window.removeEventListener('mousemove', this.resize)
      window.removeEventListener('mouseup', this.stopResize)
      this.updateCoordinate()
    },
  },
}
</script>

<style scoped lang="scss">
.draggable-resizable {
  position: absolute;
  overflow: hidden;
  cursor: move;
}

.resize-handle {
  position: absolute;
  bottom: 0;
  width: 7px;
  height: 7px;
  background-color: #4285f4;
}
.close-me {
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 1000;
  }
.draggable-resizable:hover {
  .close-me {
    visibility: visible;
  }

}
.tag-area {
  background: #000000b1;
  color: white;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 12px;
  padding: 1px;
}

</style>
