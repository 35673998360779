// eslint-disable-next-line import/no-cycle
import { getUserData } from '@/auth/utils'
import { USER_TYPE_SUPER } from '@/const/userType'

export default function adminUsersOnly({ next, router }) {
  if (getUserData().usertype !== USER_TYPE_SUPER) {
    return router.push('not-authorized')
  }

  return next()
}
