<template>
  <div id="hot-spot-statement"
       class="w-100 hot-spot-statement"
  >
    <div class="problem-card-title md-12">
      <h3 class="mb-0">
        {{ $t("student-test-module.before-we-begin") }}
      </h3>
    </div>
    <div v-if="hotSpotType && hotSpotType === 'hotspot-drag'">
      <DragHotSpot ref="dragHotSpotProblem"
                   :points="points"
                   :preview-answers="previewAnswers"
                   :image-src="hotspotImage"
                   @ansUpdate="dragAnswersUpdate"
      />
    </div>
    <div v-else
         class="d-flex w-100 justify-content-center align-items-center"
    >
      <div>
        <div style="position: relative">
          <img
            id="myImage"
            :src="hotspotImage"
          >
          <div v-for="point,pKey of points"
               :key="pKey"
          >
            <template v-if="point && !hotSpotType">
              <div
                :class="`point ${
                  !point.statement_visited ? ' clickable' : ' not-clickable'
                }`"
                :style="`top: ${point.coordinates.y * 100}%; left: ${
                  point.coordinates.x * 100
                }%; `"
                @click="checkPoints(point)"
              >
                <span>{{ point.statement_visited ? "✓" : "+" }}</span>
              </div>
              <div
                v-show="point.matchedPointIndex"
                :style="`top: ${point.coordinates.y * 100}%; left: calc(${
                  point.coordinates.x < 0.7
                    ? point.coordinates.x * 100 + 1 + '%'
                    : point.coordinates.x * 100 +
                      '%' +
                      ' - ' +
                      (tooltipWidth + 25) +
                      'px'
                }); position:absolute;`"
              >
                <div
                  :class="`tooltiptext d-flex justify-content-between align-items-center p-1 ${
                    point.coordinates.x < 0.7 ? 'tooltip-left' : 'tooltip-right'
                  }`"
                  :style="`width:${tooltipWidth}px;`"
                >
                  <span
                    v-text-to-speech
                    style="color: white !important"
                    v-html="point.hotspot.statement"
                  />
                  <AudiPlayer
                    v-if="point.hotspot.statement_audio"
                    :key="point.hotspot.statement_audio"
                    :src="point.hotspot.statement_audio"
                  />
                  <span
                    class="btn btn-sm btn-success"
                    @click="pointVisited(point)"
                  >
                    ✓
                  </span>
                </div>
              </div>
            </template>
            <MultiLayerHotSpot v-else
                               ref="multiLayerHotSpot"
                               :point="point"
                               :tooltip-width="tooltipWidth"
                               :hot-spot-type="hotSpotType"
                               @updateAnswers="updateAnswers"
                               @selectedAnswers="updateAnswers"
                               @checkAllValid="isAllAnswerSelected = points.every(item => item.matchedPointIndex === true)"
            />
          </div>
          <!-- </div> -->
        </div>

      </div>
    </div>
    <div
      v-if="showLevel"
      class="
            col-12
            p-0
            justify-content-between
            align-content-center
            problem-card-footer
          "
    >
      <actions
        :level="level"
        mode="statement"
        :engine="engine"
        :school-settings="schoolSettings"
        :district-settings="districtSettings"
        :button-title="$t('next')"
        :on-submit="
          () => {
            if (isAllAnswerSelected && !isAnswerSubmitted) {
              // submit answer directly from here
              if (hotSpotType === 'hotspot-drag') {
                directSubmitAnswerForDrag()
              } else {
                directSubmitAnswer()
              }
            } else if (isAnswerSubmitted) {
              $emit('nextProblem')
            } else {
              $emit('proceedToQuestion');
            }
          }
        "
        :action-visible="allVisited || isAllAnswerSelected"
      />
    </div>
  </div>
</template>

<script>
import AudiPlayer from '@/views/common/components/AudioPlayer.vue'
import Actions from '../compoments/Actions.vue'
import MultiLayerHotSpot from './components/MultiLayer.vue'
import DragHotSpot from './components/DragHotSpot.vue'

export default {
  components: {
    Actions,
    AudiPlayer,
    MultiLayerHotSpot,
    DragHotSpot,
  },
  props: {
    showHotSpotPoint: {
      type: Boolean,
      default: () => false,
    },
    hotspot: {
      type: Object,
      default: () => {},
    },
    level: {
      type: [String, Number, Object],
      default: () => {},
    },
    engine: {
      type: String,
      default: () => '',
    },
    previewAnswers: {
      type: Array,
      default: () => [],
    },
    schoolSettings: {
      type: Array,
      default: () => [],
    },
    districtSettings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      matchedPointIndex: null,
      imageWidth: null,
      imageHeight: null,
      showLevel: true,
      allVisited: false,
      tooltipWidth: 200,
      matchedPoint: { x: null, y: null },
      isAllAnswerSelected: false,
      answersForMultiSelect: {},
      isAnswerSubmitted: false,
      dragAnswers: {},
    }
  },
  computed: {
    hotspotImage() {
      return this.hotspot?.hotspotPoints?.hotspot?.image || this.hotspot?.file || this.hotspot?.hotspot?.image
    },
    activeProblem() {
      return this.$store.state.problem.activeProblem
    },
    hotSpotType() {
      const points = this.activeProblem?.hotspotPoints || this.activeProblem?.hotspot_point || this.activeProblem?.hotspot_points
      return points?.hotspot.type
    },
    points() {
      if (!this.activeProblem.hotspot_links || !this.activeProblem.hotspot_links.length) {
        const point = { ...this.hotspot.hotspotPoints }
        point.hotspot = this.hotspot
        return [point]
      }
      return this.activeProblem.hotspot_links.map(item => {
        const statement = this.activeProblem.problem_statements.find(subItem => subItem.id === item.problem_statement_id)
        const questions = this.activeProblem.questions.filter(subItem => item.question_ids.includes(subItem.id))
        return {
          answered: false,
          id: item.id,
          coordinates: item.hotspot_point.coordinates,
          hotspot: {
            statement: statement.statement,
            feedback: statement.feedback,
            feedback_audio: statement.fb_image,
            statement_audio: statement.st_image,
            questions,
          },
          feedback_visited: false,
          statement_visited: false,
          matchedPointIndex: false,
        }
      })
    },
  },

  mounted() {
    if (window.innerWidth < 720) {
      this.tooltipWidth = 150
    }
    this.$root.$off('MAGIC_FEEDBACK_SHOW')
    this.$root.$on('MAGIC_FEEDBACK_SHOW', feedback => {
      this.isAnswerSubmitted = true
      if (this.$refs.dragHotSpotProblem) {
        this.$refs.dragHotSpotProblem.enableFeedBack(feedback.input)
      } else {
        this.$refs.multiLayerHotSpot.forEach(component => {
          component.enableMultiLayerFeedback(feedback.input)
        })
      }
    })
  },

  methods: {
    checkPoints(point) {
      // this.hotspot.hotspotPoints.statement_visited = true
      // eslint-disable-next-line no-param-reassign
      point.matchedPointIndex = true
      this.$forceUpdate()
      // eslint-disable-next-line no-param-reassign
    },
    updateAnswers(question, answers) {
      this.answersForMultiSelect[question] = answers
    },
    dragAnswersUpdate({ answers, bool }) {
      this.isAllAnswerSelected = bool
      this.dragAnswers = answers
    },
    directSubmitAnswerForDrag() {
      this.$emit('submitQuestion', {
        answer: this.dragAnswers,
        problem_wrapper: this.hotSpotType,
        lang_level: this.activeProblem.questions[0]?.lang_level,
      })
    },
    directSubmitAnswer() {
      this.$emit('submitQuestion', {
        answer: this.answersForMultiSelect,
        problem_wrapper: this.hotSpotType,
        lang_level: this.activeProblem.questions[0]?.lang_level,
      })
    },
    pointVisited(point) {
      this.hotspot.hotspotPoints.statement_visited = true
      // eslint-disable-next-line no-param-reassign
      point.matchedPointIndex = null
      this.allVisited = true
      // this.allVisited = this.hotspot.points
      //   .filter(point => point !== null)
      //   .every(point => point.statement_visited)
    },
  },
}
</script>

<style  lang="scss">
/* Tooltip text */
.tooltiptext {
  background-color: black;
  color: #fff !important;
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  margin-top: -30px;

}

.tooltip-left::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #000 transparent transparent;
}

.tooltip-right::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #000;
}

.point {
  width: 25px;
  height: 25px;
  background-color: white;
  border: 2px solid black;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  margin-top: -15px;
  margin-left: -20px;
  align-items: center;
}

.point span {
  font-weight: 600;
  // font-size:18px;
  color: black;
}

#myImage {
  display: block;
  max-width: 100%;
  max-height: 100%;
  min-width: 800px;
  min-height: 600px;
  width: auto;
  height: auto;
}
.clickable {
  cursor: pointer;
  -webkit-animation: pulse 3s ease-in-out infinite;
  animation: pulse 3s ease-in-out infinite;
}
.not-clickable {
  cursor: not-allowed;
  background-color: rgb(9, 193, 9);
  span {
    color: white !important;
    font-size: 12px;
  }
}

@keyframes pulse {
  0%,
  70% {
    box-shadow: 0px 0px 0px 0px rgba(130, 236, 10, 0.97);
  }
  100% {
    box-shadow: 0px 0px 0px 50px rgba(125, 231, 4, 0);
  }
}

@media (max-width: 1000px) {
  #myImage {
    min-width: 400px !important;
    min-height: 400px !important;
  }
}

</style>
<style>
.hot-spot-statement .audio-play-stop {
  width: 10px !important;
  height: 10px !important;
}
</style>
