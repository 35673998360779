<template>
  <div>
    <!-- File upload Button  -->
    <label
      id="tooltip-file"
      variant="background-primary"
      class="btn-primary position-relative btn-icon mr-1 sm-mt-w rounded-circle file-btn"
    >
      <input
        ref="file"
        :disabled="loading"
        type="file"
        class="d-none"
        @change="handleFileChange"
      >
      <div
        v-show="loading"
        class="loading"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
      <feather-icon
        v-show="!loading"
        icon="PaperclipIcon"
      />
      <b-tooltip
        target="tooltip-file"
        triggers="hover"
        placement="bottom"
      >
        {{ $t('chat-page.upload-file') }}
      </b-tooltip>
    </label>
  </div>
</template>

<script>
import { BTooltip } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { POSITION } from 'vue-toastification'

export default {
  computed: {
    ...mapGetters('studentInteractions', {
      loading: 'getFileUploadLoading',
    }),
  },
  components: {
    BTooltip,
  },
  methods: {
    ...mapActions('studentInteractions', {
      set_loading: 'fileUploadLoading',
    }),
    handleFileChange() {
      const inputFile = this.$refs.file.files
      if (inputFile[0].size / 1024 / 1024 > 65) {
        this.$toast('File Size Limit Exceeded', {
          position: POSITION.TOP_CENTER,
          type: 'error',
        })
        return
      }
      // eslint-disable-next-line prefer-destructuring
      this.file = inputFile[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.fileURL = window.URL.createObjectURL(new Blob([new Uint8Array(e.target.result)], { type: this.file.type }))

        // const blob = new Blob([new Uint8Array(e.target.result)], { type: this.file.type })
        this.set_loading(true)
        this.$emit('fileInput', { file: this.file, fileURL: this.fileURL })
      }
      reader.readAsArrayBuffer(this.file)
    },
  },
}
</script>

<style>

</style>
