// eslint-disable-next-line import/no-cycle
import { getUserData } from '@/auth/utils'
import { USER_TYPE_CURRICULUM } from '@/const/userType'

export default function curriculumUsersOnly({ next, router }) {
  if (getUserData().usertype !== USER_TYPE_CURRICULUM && getUserData().usertype !== 'super') {
    return router.push('not-authorized')
  }

  return next()
}
