<template>
  <b-modal :visible="true"
           size="xl"
           ok-only
           title="Image Generation"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <!-- <b-overlay :show="state.isQueueRunning"
               style="min-height: 150px;"
    >
      <img v-if="generatedImage"
           v-image-preview
           style="max-width: 100%;"
           :src="generatedImage"
      >
    </b-overlay>
     -->
    <stable-diffusion
      :word="word"
      @onImageGenerate="value => generatedImage = value"
    />
    <template #modal-footer>
      <template v-if="generatedImage">
        <b-button
          v-if="sourceFrom === 'cb'"
          variant="outline-success"
          class="mr-1"
          :disabled="props.isProcessing"
          @click.stop="$emit('pickImage', generatedImage)"
        >
          {{ $t('image-library-module.pick') }}
        </b-button>
        <b-button
          v-else
          variant="outline-primary"
          class="mr-1"
          :disabled="props.isProcessing"
          @click="$emit('saveImage', generatedImage)"
        >
          <b-spinner v-if="props.isProcessing"
                     small
          /> Save Image
        </b-button>
      </template>
      <b-button variant="outline-secondary"
                @click="$emit('close')"
      >
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>
<script setup>
import {
  // eslint-disable-next-line no-unused-vars
  BModal, BOverlay, BButton, BSpinner,
} from 'bootstrap-vue'
import { ref } from 'vue'
import StableDiffusion from './index.vue'

const props = defineProps({
  word: {
    type: String,
    required: true,
  },
  isProcessing: {
    type: Boolean,
    default: false,
  },
  sourceFrom: {
    type: String,
    default: '',
  },
})
const generatedImage = ref(null)
</script>
