<template>
  <!-- create  -->
  <b-modal
    id="modal-create-edit"
    ref="modal-create-edit"
    v-model="isVisible"
    ok-only
    size="md"
    ok-variant="primary"
    :ok-title="$t('confirm')"
    modal-class="modal-primary"
    centered
    :title="$t(`standard-module.${mode}-grade`)"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-col md="12">
      <!-- form -->
      <b-form @submit.prevent="submitData">
        <validation-observer ref="gradeModalRef">
          <b-row>
            <!-- name -->
            <b-col md="12">
              <b-form-group
                :label="$t('standard-module.name')"
                label-for="tem-name"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="name"
                  rules="required"
                  vid="name"
                >
                  <b-form-input
                    v-model="localGrade.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- name -->
          </b-row>
        </validation-observer>
      </b-form>
    </b-col>
    <template #modal-footer="{ }">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="isProcessing"
            @click="submitData()"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t("actions." + mode) }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
  <!-- create  -->

</template>

<script>
import {
  BModal, BForm, BFormInput, BFormGroup, BButton, BSpinner, BRow, BCol,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BFormGroup,
    BRow,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    grade: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isVisible: false,
      isProcessing: false,
      localGrade: {
        name: '',
      },
    }
  },
  computed: {
    mode() {
      return this.grade ? 'update' : 'create'
    },
  },
  watch: {
    show() {
      this.isVisible = this.show
    },
    grade() {
      if (this.grade) this.localGrade = JSON.parse(JSON.stringify(this.grade))
      else this.localGrade = { name: '' }
    },
  },
  methods: {
    createGrade() {
      this.isProcessing = true
      useJwt.createStandardGrade({ ...this.localGrade, library_id: this.$route.query.library }).then(res => {
        this.showSuccessMessage(res)
        this.localGrade = { name: '' }
        this.$emit('gradeCreated')
      }).catch(e => {
        if (e.response?.data?.errors) this.$refs.gradeModalRef.setErrors(e.response.data.errors)
        else this.showErrorMessage(e)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    updateGrade() {
      this.isProcessing = true
      useJwt.updateStandardGrade(this.localGrade.id, this.localGrade).then(res => {
        this.showSuccessMessage(res)
        this.$emit('gradeUpdated')
      }).catch(e => {
        if (e.response?.data?.errors) this.$refs.gradeModalRef.setErrors(e.response.data.errors)
        else this.showErrorMessage(e)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    submitData() {
      this.$refs.gradeModalRef.validate().then(success => {
        if (success) {
          if (this.mode === 'create') this.createGrade()
          else this.updateGrade()
        }
      })
    },
  },
}
</script>

<style>

</style>
