/* eslint-disable */
import keyLayout from './key-mapping'
import NumPadLayout from './number-keys'
import { isStudentYoung } from '@/auth/utils'

const synth = window.speechSynthesis

const Keyboard = {
  selectedKeyLayout: keyLayout,
  soundEnabled: false,
  elements: {
    main: null,
    keysContainer: null,
    keys: [],
  },
  eventHandlers: {
    oninput: null,
    onclose: null,
  },
  isOpened: false,
  hasClosedManually: false,
  properties: {
    value: '',
    capsLock: false,
  },
  speak(msg) {
    if (synth.speaking) {
      synth.cancel()
      return
    }
    if (!this.soundEnabled) {
      return
    }
    const sp = new SpeechSynthesisUtterance(msg)
    sp.lang = 'en-US'
    // sp.pitch = 1
    sp.rate = 0.5
    speechSynthesis.speak(sp)
  },
  setSoundOption(value) {
    this.soundEnabled = value
  },
  enableKeyboardSound() {
    this.soundEnabled = true
  },
  disableKeyboardSound() {
    this.soundEnabled = false
  },
  drawKeyboard(pushArea) {
     // Setup main elements
    
     
     this.elements.keysContainer.appendChild(this._createKeys())
 
     this.elements.keys = this.elements.keysContainer.querySelectorAll('.keyboard__key')
 
     // Add to DOM
     this.elements.main.appendChild(this.elements.keysContainer)
     pushArea.appendChild(this.elements.main)
  },

  init(element, pushArea, onOpen) {
    // Create main elements
    this.elements.main = element
    this.elements.keysContainer = document.createElement('div')
    this.elements.main.classList.add('keyboard', 'keyboard--hidden')
    this.elements.keysContainer.classList.add('keyboard__keys')
    this.drawKeyboard(pushArea)
    
    document.body.addEventListener( 'click',  ( event ) =>  {
      if ((event.target.tagName == 'TEXTAREA' || event.target.tagName == 'INPUT' )) {
        if (isStudentYoung() && !this.hasClosedManually) {
          Keyboard.handleOpen(event.target)
          onOpen()
        } else if (this.isOpened) {
          Keyboard.handleOpen(event.target)
          onOpen()
        }
      };
    });
  },
  handleBackSpace() {
    this.properties.value = this.properties.value.substring(0, this.properties.value.length - 1)
    this._triggerEvent('oninput')
  },

  handleOpen(target) {
    this.open(target.value, currentValue => {
      target.value = currentValue
      target.focus()
      target.dispatchEvent(new Event('input', {bubbles:true}));
      document.getElementById('keyboard-text-screen').innerText = currentValue
    })
  },
  _setNumPadKeyboard() {
    this.elements.keysContainer.innerHTML = ''
    this.selectedKeyLayout = NumPadLayout
    this.drawKeyboard()
  },
  _setAlphabeticKeyboard() {
    this.elements.keysContainer.innerHTML = ''
    this.selectedKeyLayout = keyLayout
    this.drawKeyboard()
  },

  _createKeys() {
    const fragment = document.createDocumentFragment()

    // Creates HTML for an icon
    const createIconHTML = (icon_name) => {
        return `<i class="material-icons">${icon_name}</i>`;
    };

    this.selectedKeyLayout.forEach(item => {
      const key = item.key
      const keyElement = document.createElement('button')
      const insertLineBreak = [ 'p', 'l', 'm'].indexOf(key) !== -1

      // Add attributes/classes
      keyElement.setAttribute('type', 'button')
      keyElement.classList.add('keyboard__key')
      if (item.color) {
        keyElement.style.background = item.color
      }
      const wordThatShouldNotSpeak = ['123', 'A/a', 'abc']

      keyElement.addEventListener('click', () => {
        if (!wordThatShouldNotSpeak.includes(item.key)) {
          this.speak(item.key)
        }
      })
      switch (key) {
        case 'backspace':
          keyElement.classList.add('keyboard__key--wide')
          keyElement.innerHTML = createIconHTML('backspace')

          keyElement.addEventListener('click', () => {
            this.properties.value = this.properties.value.substring(0, this.properties.value.length - 1)
            this._triggerEvent('oninput')
          })

          break
        
        case '123':
            keyElement.classList.add('keyboard__key--wide')
            keyElement.textContent = key

            keyElement.addEventListener('click', () => {
              this._setNumPadKeyboard()
            })
  
            break
        case 'abc':
            keyElement.classList.add('keyboard__key--wide')
            keyElement.textContent = key

            keyElement.addEventListener('click', () => {
              this._setAlphabeticKeyboard()
            })
  
            break
  
        case 'A/a':
          keyElement.classList.add('keyboard__key--wide', 'keyboard__key--activatable')
          keyElement.textContent = key

          keyElement.addEventListener('click', () => {
            this._toggleCapsLock()
            keyElement.classList.toggle('keyboard__key--active', this.properties.capsLock)
          })

          break

        case 'enter':
          keyElement.classList.add('keyboard__key--wide')
          keyElement.innerHTML = createIconHTML('keyboard_return')

          keyElement.addEventListener('click', () => {
            this.properties.value += '\n'
            this._triggerEvent('oninput')
          })

          break

        case 'space':
          keyElement.classList.add('keyboard__key--extra-wide')
          keyElement.innerHTML = createIconHTML('space_bar')

          keyElement.addEventListener('click', () => {
            this.properties.value += ' '
            this._triggerEvent('oninput')
          })

          break

        case 'done':
          keyElement.classList.add('keyboard__key--wide', 'keyboard__key--dark')
          keyElement.innerHTML = createIconHTML('check_circle')

          keyElement.addEventListener('click', () => {
            this.close()
            this._triggerEvent('onclose')
          })

          break

        default:
          keyElement.textContent = key.toLowerCase()

          keyElement.addEventListener('click', () => {
            this.properties.value += this.properties.capsLock ? key.toUpperCase() : key.toLowerCase()
            this._triggerEvent('oninput')
          })

          break
      }

      fragment.appendChild(keyElement)

      if (insertLineBreak) {
        fragment.appendChild(document.createElement('br'))
      }
    })

    return fragment
  },

  _triggerEvent(handlerName) {
    if (typeof this.eventHandlers[handlerName] === 'function') {
      this.eventHandlers[handlerName](this.properties.value)
    }
  },

  _toggleCapsLock() {
    this.properties.capsLock = !this.properties.capsLock

    for (const key of this.elements.keys) {
      if (key.childElementCount === 0) {
        if (key.textContent != 'A/a') {
          key.textContent = this.properties.capsLock ? key.textContent.toUpperCase() : key.textContent.toLowerCase()
        }
      }
    }
  },

  open(initialValue, oninput, onclose) {
    this.isOpened = true
    this.properties.value = initialValue || ''
    this.eventHandlers.oninput = oninput
    this.eventHandlers.onclose = onclose
    this.elements.main.classList.remove('keyboard--hidden')
  },

  close() {
    this.properties.value = ''
    this.eventHandlers.oninput = oninput
    this.eventHandlers.onclose = onclose
    this.elements.main.classList.add('keyboard--hidden')
    this.isOpened = false
    this.hasClosedManually = true
  },
}

export default Keyboard
