<template>
  <!-- basic modal -->
  <b-modal
    v-model="isVisible"
    :title="isEditing ? 'Editing Schedule' : 'Create Schedule'"
    ok-only
    ok-title="Accept"
    :modal-class="`modal-${isEditing ? 'success' : 'primary'}`"
    size="lg"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-overlay :show="isLoadingShow">
      <validation-observer ref="simpleRules">
        <b-row>
          <b-col md="4">
            <b-form-group :label="$t('labels.timezone')">
              <v-select
                v-model="userTimezone"
                :options="timezones"
                :reduce="tz => tz.zone"
                label="city"
                :clearable="false"
                @input="setUserTimezone"
              />
            </b-form-group>
          </b-col>

          <b-col md="8">
            <b-alert
              show
              class="p-1"
            >
              <div class="d-flex">
                <feather-icon
                  icon="InfoIcon"
                  size="28"
                />
                <p class="ml-1 mb-0">
                  The system will notify you about the upcoming class in your selected time zone shortly before it begins
                </p>
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('class-module.start-date')"
                rules="required"
              >
                <label>
                  {{ weeklyRepeat ? 'Week' : '' }}  {{ $t('class-module.start-date') }}
                </label>
                <b-form-datepicker
                  v-model="form.start_date"
                  :max="form.end_date"
                  placeholder="Start Date"
                  class="form-control"
                  :readonly="!isOwner"
                  @input="scheduleTime"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('class-module.end-date')"
                rules="required"
              >
                {{ weeklyRepeat ? 'Week' : '' }}  <label>{{ $t('class-module.end-date') }}</label>
                <b-form-datepicker
                  v-model="form.end_date"
                  :min="form.start_date"
                  v-bind="{
                    ...(weeklyRepeat ? {max: maxEndDate} : {})
                  }"
                  placeholder="End Date"
                  class="form-control"
                  :readonly="!isOwner"
                  @input="scheduleTime"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isSchoolUser">
          <b-col md="6">
            <b-form-group>
              <validation-provider
                v-slot="{ errors }"
                name="Teacher"
                rules="required"
              >
                <label>
                  {{ $t('teacher-schedule.teacher') }}
                </label>
                <v-select
                  v-model="form.teacher_id"
                  transition=""
                  :clearable="false"
                  label="full_name"
                  :reduce="(teacher) => teacher.id"
                  :options="teacherList"
                  placeholder="Select Teacher"
                  :disabled="!isOwner"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="!isEditing">
          <b-col
            class="mb-2 d-flex justify-content-between"
          >
            <div class="d-flex">
              <b-form-radio
                v-model="repeatSchedule"
                :value="false"
                class="custom-control-primary mr-2"
              >
                No Repeat
              </b-form-radio>
              <b-form-radio
                v-model="repeatSchedule"
                value="D"
                class="custom-control-success mr-2"
              >
                Daily Repeat
              </b-form-radio>
              <b-form-radio
                v-model="repeatSchedule"
                value="W"
                class="custom-control-warning"
              >
                Weekly Repeat
              </b-form-radio>
            </div>
            <div
              v-if="weeklyRepeat"
              class="d-flex"
              style="width: 165px;"
            >
              <b-input-group>
                <b-input-group-prepend>
                  <b-button
                    size="sm"
                    :disabled="noOfWeeksToRepeat === 0"
                    variant="danger"
                    @click="noOfWeeksToRepeat -- "
                  >
                    <feather-icon icon="MinusIcon" />
                  </b-button>
                </b-input-group-prepend>
                <b-form-input
                  :value="noOfWeeksToRepeat + (noOfWeeksToRepeat > 1 ? ' Weeks' : ' Week')"
                  readonly
                  size="sm"
                />
                <b-input-group-append>
                  <b-button
                    size="sm"
                    variant="success"
                    @click="noOfWeeksToRepeat ++ "
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
        <div
          class="table-responsive"
          style="max-height: 65vh;overflow-y: scroll;"
          :style="{ minHeight: schedules.length > 0 && '180px' }"
        >
          <b-table
            responsive="sm"
            :small="true"
            :fields="schedulesFields"
            :items="schedules"
          >
            <template #cell(day)="data">
              <b-button
                v-if="isOwner && ( !repeat || (data.index === 0) ) && !isEditing"
                class="btn-icon"
                variant="flat-success"
                @click="addMoreTimes(data.item.day)"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
              {{ FORMAT_DATE(data.item.day, 'dddd MMM Do YY') }}
            </template>
            <template #cell(classrooms)="data">
              <div
                v-for="(time,index) of data.item.classrooms"
                :key="index"
                :class="{ 'mt-1': index > 0 }"
              >
                <b-button
                  variant="outline-primary"
                  size="sm"
                  @click="assignSchedule({
                    start_time: schedules[data.index].start_times[index].time,
                    end_time: schedules[data.index].end_times[index].time,
                    day: data.item.day,
                    id: schedules[data.index].ids[index]
                  })"
                >
                  <feather-icon icon="CalendarIcon" />  Schedule
                </b-button>
              </div>
            </template>
            <template #cell(start_times)="data">
              <div
                v-for="(time,index) of data.item.start_times"
                :key="index"
                :class="{ 'mt-1': index > 0 }"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="`Start Time`"
                  rules="required"
                  :vid="`start_${data.index}_${index}`"
                >
                  <b-form-input
                    v-model="schedules[data.index].start_times[index].time"
                    type="time"
                    class="form-control"
                    onfocus="this.showPicker()"
                    :max="schedules[data.index].end_times[index].time"
                    placeholder="Start Time"
                    :disabled="!isOwner || (repeat && data.index > 0)"
                    locale="en"
                    :state="validateTime(data.index, index)"
                    @input="data.index === 0 && handleRepeatTime()"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-input>
                </validation-provider>
              </div>
            </template>
            <template #cell(end_times)="data">
              <div
                v-for="(time,index) of data.item.end_times"
                :key="index"
                :class="{ 'mt-1': index > 0 }"
              >
                <div class="d-flex justify-content-between">
                  <validation-provider
                    v-slot="{ errors }"
                    name="End Time"
                    rules="required"
                    :vid="`end_${data.index}_${index}`"
                    class="w-100"
                  >
                    <b-form-input
                      v-model="schedules[data.index].end_times[index].time"
                      type="time"
                      class="form-control"
                      onfocus="this.showPicker()"
                      :min="schedules[data.index].start_times[index].time"
                      placeholder="End Time"
                      :disabled="!isOwner || (repeat && data.index > 0)"
                      locale="en"
                      :state="validateTime(data.index, index)"
                      @input="data.index === 0 && handleRepeatTime()"
                    >
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-input></validation-provider>
                  <b-button
                    v-if="( (data.index !== 0 || index > 0 ))"
                    class="btn-icon"
                    variant="flat-danger"
                    :disabled="!isOwner"
                    tag="a"
                    @click="removeMoreTimes(data.item.day,index)"
                  >
                    <feather-icon
                      icon="MinusIcon"
                      size="20"
                    />
                  </b-button>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </validation-observer>
    </b-overlay>
    <delete-modal
      v-if="isEditing"
      name="modal-delete-student-rubric"
      :on-delete="deleteSchedule"
      title="Delete Schedule"
      sub-title="Are you sure you want to delete ?"
      :is-processing="isDeleting"
      :show="!! deletingSchedule"
      @close="deletingSchedule = null"
    />
    <assign-schedule
      :visible="showAssignSchedule"
      :schedule="daySchedule"
      :class-rooms="classRooms"
      @close="showAssignSchedule = false"
    />
    <template #modal-footer="{}">
      <b-row class="w-100">
        <b-col v-if="isEditing && isWeeklySchedule"
               class="d-flex justify-content-start"
        >
          <b-button
            v-if="isEditing && isOwner"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            class="mr-1"
            size="sm"
            :disabled="isDeleting"
            @click="deletingSchedule = selectedEventId; deleteSingleSchedule = true"
          >
            <b-spinner
              v-show="isDeleting"
              small
            />
            Delete Only This Schedule
          </b-button>
          <b-button
            v-if="isOwner"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="isEditing ? 'outline-success' : 'primary'"
            :disabled="isProcessing"
            size="sm"
            @click="onSubmit(true)"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            <span v-if="isEditing">
              Update Only This Schedule
            </span>
          </b-button>
        </b-col>
        <b-col>
          <div
            class="d-flex justify-content-end"
          >
            <b-button
              v-if="isEditing && isOwner"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              class="mr-1"
              :disabled="isDeleting"
              @click="deletingSchedule = selectedEventId; deleteSingleSchedule = false"
            >
              <b-spinner
                v-show="isDeleting"
                small
              />
              {{ $t('teacher-schedule.delete-schedule') }}
            </b-button>
            <b-button
              v-if="isOwner"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="isEditing ? 'success' : 'primary'"
              :disabled="isProcessing"
              @click="onSubmit()"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              <span v-if="isEditing">
                {{ $t('teacher-schedule.update-schedule') }}
              </span>
              <span v-else>
                {{ $t('teacher-schedule.create-schedule') }}
              </span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BModal,
  VBModal,
  BCol,
  BRow,
  BFormGroup,
  BButton,
  BSpinner,
  BFormDatepicker,
  BTable,
  BFormTimepicker,
  BFormSelect,
  BOverlay,
  BFormCheckbox,
  BFormRadio,
  BInputGroup, 
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import teacherSchedule from '@/const/teacherScheduleStatus'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'
import AssignSchedule from './AssignSchedule.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import timezones from '@/utils/timezones'

const moment = require('moment')

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCol,
    BRow,
    BFormGroup,
    BFormDatepicker,
    BSpinner,
    BButton,
    BTable,
    BFormTimepicker,
    BOverlay,
    vSelect,
    DeleteModal,
    BFormSelect,
    BFormCheckbox,
    AssignSchedule,
    FeatherIcon,
    BFormRadio,
    BInputGroup, 
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BAlert,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    startDate: {
      type: String,
      default: '',
    },
    selectedEventId: {
      type: [Number, Object, String],
      default: () => null,
    },
    teacherList: {
      type: Array,
      default: () => [],
    },
    selectedTeacher: {
      type: [Object, Number, String],
      default:() =>  null,
    },
    isSchoolUser: {
      type: Boolean,
      default: false,
    },
    classRooms: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      timezones,
      isVisible: false,
      required,
      isProcessing: false,
      form: {
        start_date: this.startDate || new Date(),
        end_date: '',
        teacher_id: '',
        status: 'active',
      },
      userTimezone: 'Asia/Seoul',
      schedulesLists: [],
      isLoadingShow: false,
      isEditing: false,
      isDeleting: false,
      deletingSchedule: null,
      status: teacherSchedule,
      self: getUserData(),
      isOwner: false,
      repeatSchedule: false,
      showAssignSchedule: false,
      daySchedule: {},
      noOfWeeksToRepeat: 1,
      isWeeklySchedule : false,
      deleteSingleSchedule: false,
    }
  },
  computed: {
    repeat() { // repeat reqs changed
      return this.repeatSchedule === 'D'
    },
    weeklyRepeat() {
      return this.repeatSchedule === 'W'
    },
    schedulesFields() {
      return [
        {  key: 'day', label: 'Day' },
        ...( this.isEditing ? 
        [ {  key: 'classrooms', label: 'Classroom' } ]: []),
        {  key: 'start_times', label: 'Start Time' },
        {  key: 'end_times', label: 'End Time' },
      ]
    },
    maxEndDate() {
      return moment(this.form.start_date).add('7','day').format('YYYY-MM-DD')
    },
    schedules() {
      return this.schedulesLists.filter(schedule => schedule.start_times && schedule.start_times.length > 0 )
    }
  },
  watch: {
    show(newValue) {
      this.isVisible = newValue
      this.isOwner = true
      this.repeatSchedule = false
      if (newValue && this.selectedEventId) {
        this.isLoadingShow = true
        this.isEditing = true
        // this.schedulesFields.splice(1,0,{  key: 'classrooms', label: 'Classroom' })
        this.schedulesLists = []
        useJwt
          .getScheduleDetail(this.selectedEventId)
          .then(response => {
            const { data } = response.data
            this.isWeeklySchedule = data.is_weekly_schedule
            const schedule = data.schedules
            this.form = {
              ...this.form,
              start_date: schedule.start_date,
              end_date: schedule.end_date,
              teacher_id: schedule.teacher_id,
            }
            const days = [...new Set(schedule.schedule_time.map(s => s.day))]
            const schedules = []
            days.forEach(day => {
              const day_data = schedule.schedule_time.filter(d => d.day === day )
              schedules.push({
                day: day,
                classrooms: day_data.map(d=> d.classroom_id),
                start_times: day_data.map(d => ({time: d.start_time})),
                end_times: day_data.map(d => ({time: d.end_time})),
                ids:  day_data.map(d=> d.id), 
                _rowVariant: day === this.startDate && 'info'
              })
            })
            this.schedulesLists = schedules
            // this.classRooms = data.classRooms
            this.isOwner = this.self.id ===  schedule.created_by
            this.repeatSchedule = schedule.is_repeated ? 'D' : null
          })
          .catch(error => {
            this.showErrorMessage(error)
          })
          .finally(() => {
            this.isLoadingShow = false
          })
      } else {
        this.isEditing = false
      }
    },
    startDate(newValue) {
      this.form = {
        ...this.form,
        start_date: newValue,
        end_date: newValue,
      }
      this.scheduleTime()
    },
    selectedTeacher(newValue) {
      this.form = {
        ...this.form,
        teacher_id: newValue
      }
    },
  },
  mounted() {
    this.userTimezone = this.self.timezone
  },
  methods: {
    setUserTimezone() {
      const params = {
        key: 'timezone',
        value: this.userTimezone
      }
      useJwt.updateUserSettings(params).then(response => {
        this.showSuccessMessage(response)
        localStorage.removeItem('userData')
        this.$nextTick(() => {
          const userData = {
            ...this.self,
            timezone: this.userTimezone
          }
          localStorage.setItem('userData', JSON.stringify(userData))
        })
      })
    },
    deleteSchedule() {
      this.isDeleting = true
      useJwt.destroySchedule(this.deletingSchedule, {
        params: {
          single_delete: this.deleteSingleSchedule ? 1: null,
        }
      }).then(response => {
        this.showSuccessMessage(response)
        this.$emit('onDelete', Number(this.deletingSchedule))
        this.deletingSchedule = null
        this.deleteSingleSchedule  = false
        this.$emit('close')
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally( () => { this.isDeleting = false  })
    },
    onSubmit(signalUpdate = false) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isProcessing = true
          if (this.selectedEventId) {
            // creating
            this.updateSchedule(this.selectedEventId, signalUpdate)
          } else {
            // updating
            this.createNewSchedule()
          }
        }
      })
    },
    formatScheduleData() {
      const schedules = []
      this.schedulesLists.forEach(schedule => {
        schedule.start_times.forEach( (time, index) => {
          const start_time = time.time
          const end_time = schedule.end_times[index].time
          const classroom = schedule.classrooms ? schedule.classrooms[index] : null
          const ids = schedule.ids ? schedule.ids[index] : null
          schedules.push({
            day: schedule.day,
            classroom: classroom,
            ids,
            start_time,
            end_time
          })
        }) 
      })
      return schedules
    },
    createNewSchedule() {
      const schedules = this.formatScheduleData()
      useJwt
        .postNewSchedule({
          details: schedules,
          is_repeated: this.repeat,
          weekly_repeat: (this.weeklyRepeat && this.noOfWeeksToRepeat > 0) ? this.noOfWeeksToRepeat : null,
          ...this.form,
        })
        .then(response => {
          this.$emit('onSave', response.data.data)
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    updateSchedule(eventId, signalUpdate) {
      const schedules = this.formatScheduleData()
      useJwt
        .updateNewSchedule(eventId, {
          details: schedules,
          is_repeated: this.repeat,
          signalUpdate,
          ...this.form,
        })
        .then(response => {
          this.$emit('onSave', response.data.data, response.data.data.id)
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    scheduleTime() {
      const startOfMonth = moment(this.form.start_date)
      const endOfMonth = moment(this.form.end_date).add(1, 'days')
      const totalDays = []
      for (
        const m = moment(startOfMonth);
        m.isBefore(endOfMonth);
        m.add(1, 'days')
      ) {
        const day = m.format('YYYY-MM-DD')
        const currentSchedule = this.schedulesLists.find(s => s.day === day)
        const start_times =  currentSchedule ? currentSchedule.start_times : [
          { time: null },
        ];
        const end_times = currentSchedule ? currentSchedule.end_times : [
          { time: null },
        ];
        const ids = currentSchedule ? currentSchedule.ids : []
        totalDays.push({
          day,
          ids,
          classroom: null,
          start_times: start_times,
          end_times: end_times,
        })

      }
      this.schedulesLists = totalDays
      this.handleRepeatTime()
    },
    addMoreTimes(day) {
      const newSchedule = [... this.schedulesLists]
      const index = newSchedule.findIndex(s => s.day === day)
      let target = newSchedule[index]
      target = {
        ...target,
        start_times: target.start_times.push({ time: null }),
        end_times: target.end_times.push({ time: null })
      }
      this.schedulesLists = newSchedule
      this.handleRepeatTime()
    },
    removeMoreTimes(day, i) {
      const newSchedule = [... this.schedulesLists]
      const index = newSchedule.findIndex(s => s.day === day)
      let target = newSchedule[index]
      const start_times = target.start_times
      start_times.splice(i, 1)
      const end_times = target.end_times
      end_times.splice(i, 1)
      target = {
        ...target,
        start_times,
        end_times,
      }
      this.schedulesLists = newSchedule
      this.$nextTick(() => {
        this.handleRepeatTime()
      }) 
    },
    handleRepeatTime() {
      if (!this.repeat) return 
      const startTimes = this.schedules[0].start_times
      const endTimes = this.schedules[0].end_times
      const newSchedules = []
      this.schedules.forEach((schedule) => {
        newSchedules.push({
          ...schedule,
          start_times: JSON.parse(JSON.stringify(startTimes)),
          end_times:  JSON.parse(JSON.stringify(endTimes)),
        })
      })
      this.schedulesLists = newSchedules
    },
    assignSchedule(item) {
      this.showAssignSchedule = true
      this.daySchedule = item
    },
    validateTime(dateIndex, dayIndex) {
      const date = moment().format('YYYY-MM-DD')
      const startTime = moment(date + ' ' +this.schedules[dateIndex].start_times[dayIndex].time)
      const endTime = moment(date + ' ' +this.schedules[dateIndex].end_times[dayIndex].time)
      return endTime.isAfter(startTime)
    }
  }
}
</script>