<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <b-input-group label="Please Select Page">
          <b-form-select v-model="selectedWebpage"
                         :options="pages"
                         text-field="name"
                         value-field="id"
                         placeholder="Please select Page"
          />
          <b-input-group-append v-if="selectedWebpage">
            <b-button
              variant="flat-success"
              class="btn-icon border"
              @click="editPage"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              variant="flat-danger"
              class="btn-icon border"
              @click="pageToDelete = selectedWebpage"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="col-md-8 text-right">
        <b-button variant="primary"
                  @click="showCreateModal"
        >
          <feather-icon icon="PlusIcon" />  {{ $t('ai-ide-module.create-webpage') }}
        </b-button>
        <b-button variant="outline-primary"
                  tag="a"
                  class="ml-2"
                  href="https://jupiter.globalenglishcampus.com"
                  target="_blank"
                  @click="showCreateModal"
        >
          <span class="material-icons">
            precision_manufacturing
          </span><span style="margin-top: -10px;"> ML AI LAB</span>
        </b-button>
      </div>
    </div>

    <b-modal v-model="showModal"
             :title="`${modalTitle} Webpage`"
             hide-footer
             @close="pageToEdit = null"
             @hide="pageToEdit = null"
    >
      <b-form @submit.prevent="createWebpage">
        <b-form-group :label="$t('ai-ide-module.page-name')">
          <b-form-input v-model="newWebpage.name"
                        :placeholder="$t('ai-ide-module.page-name')"
                        required
          />
        </b-form-group>
        <b-form-group :label="$t('ai-ide-module.key')">
          <b-form-input v-model="newWebpage.key"
                        :placeholder="$t('ai-ide-module.page-key')"
                        required
          />
        </b-form-group>
        <b-form-group :label="$t('ai-ide-module.page-description')">
          <b-form-textarea v-model="newWebpage.description"
                           :placeholder="$t('ai-ide-module.page-description')"
                           required
          />
        </b-form-group>
        <div class="float-right">
          <b-button type="submit"
                    variant="primary"
                    :disabled="isProcessing"
          >
            <b-spinner v-if="isProcessing"
                       small
                       class="mr-1"
            />  {{ modalTitle }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <confirm-delete :show="!!pageToDelete"
                    :is-processing-delete="isProcessing"
                    :on-delete="handleDeletePage"
                    @close="pageToDelete = null"
    />
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormSelect, BModal, BSpinner, BFormTextarea, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import { initProcessCreator } from '@/const/ai-models'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import ConfirmDelete from '@/views/common/components/DeleteModal.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BModal,
    BSpinner,
    BFormTextarea,
    BInputGroupAppend,
    BInputGroup,
    ConfirmDelete,
  },
  props: {
    pages: {
      type: Array,
      default: () => [],
    },
    activatePage: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      showModal: false,
      isProcessing: false,
      pageToEdit: null,
      newWebpage: {
        name: '',
        key: '',
        description: '',
      },
      pageToDelete: null,
    }
  },
  computed: {
    selectedWebpage: {
      get() {
        return this.activatePage?.id
      },
      set(value) {
        // eslint-disable-next-line eqeqeq
        this.$emit('onSelectPage', this.pages.find(item => item.id == value))
      },
    },
    modalTitle() {
      return this.pageToEdit ? i18n.tc('actions.update') : i18n.tc('create')
    },
  },
  methods: {
    showCreateModal() {
      this.showModal = true
      this.newWebpage = {
        name: '',
        key: '',
        description: '',
      }
    },
    editPage() {
      this.pageToEdit = this.activatePage.id
      this.showModal = true
      this.newWebpage = (({ name, key, description }) => ({ name, key, description }))(this.activatePage)
    },
    handleDeletePage() {
      this.isProcessing = true
      useJwt.destroyAiIdePage(this.pageToDelete).then(response => {
        this.showSuccessMessage(response)
        this.pageToDelete = null
        this.$emit('onRefetch')
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    createWebpage() {
      // Clear the form
      this.isProcessing = true
      useJwt.storeAiIdePage({
        ...this.newWebpage,
        pageId: this.pageToEdit,
        processes: [{ ...initProcessCreator() }],
      }).then(response => {
        this.showModal = false
        this.pageToEdit = false
        this.showSuccessMessage(response)
        this.$emit('onRefetch')
      }).finally(() => {
        this.isProcessing = false
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
  },
}
</script>

<style scoped>

/* Custom styling to make the UI more attractive */
.row {
  margin-bottom: 20px;
}

.b-modal-dialog {
  max-width: 400px;
}
</style>
