<template>
  <b-modal :visible="true"
           title="Regenerate"
           @close="$emit('close')"
           @hide="$emit('close')"
           @ok="$emit('onReGenerate', state.selected)"
  >
    <div>

      <b-form-group label="Asset Generation Type">
        <b-form-radio-group
          v-model="state.selected"
          :options="state.options"
          name="radios-stacked"
          stacked
        />
      </b-form-group>
    </div>
  </b-modal>
</template>
<script setup>
import { reactive } from 'vue'
import { BModal, BFormRadioGroup, BFormGroup } from 'bootstrap-vue'

const state = reactive({
  selected: 'only_image',
  options: [
    { text: 'Only Images', value: 'only_image' },
    { text: 'Except Images', value: 'except_image ' },
    { text: 'All', value: 'all' },
  ],
})

</script>
