<template>
  <b-card class="mb-0">
    <b-overlay :show="isLoading">
      <b-card-title
        v-if="evaluatedBy && evaluatedBy.length"
      >
        {{ $t('self-evaluation.personality-assessment-by') }}
      </b-card-title>

      <b-card-title
        v-else
        class="mb-0"
      >
        {{ $t('self-evaluation.no-evaluation') }}
      </b-card-title>

      <div v-if="evaluatedBy">
        <b-card
          v-for="user, index in evaluatedBy"
          :key="user.id"
          v-b-toggle="`personality-${user.id}`"
          border-variant="secondary"
          @click="collapsed[index] = !collapsed[index]"
        >
          <b-card-text class="mb-0 d-flex justify-content-between">
            <span>{{ user.name }}</span>
            <feather-icon
              :icon="collapsed[index] ? 'ChevronDownIcon' : 'ChevronUpIcon'"
              size="22"
            />
          </b-card-text>

          <b-collapse
            :id="`personality-${user.id}`"
            v-model="collapsed[index]"
            class="mt-2"
          >
            <personality-evaluation-form
              v-if="selectedStudent"
              :personality-by-group="personalities"
              :personality="studentPersonalities[index].personality_id"
            />
          </b-collapse>
        </b-card>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardText, BOverlay, BCollapse, VBToggle,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import PersonalityEvaluationForm from './PersonalityEvaluationForm.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BOverlay,
    BCollapse,
    PersonalityEvaluationForm,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    selectedStudent: {
      type: Number,
      required: true,
      default: () => null,
    },
  },
  data() {
    return {
      personalities: [],
      studentPersonalities: null,
      evaluatedBy: null,
      collapsed: [],
      isLoading: false,
    }
  },
  watch: {
    selectedStudent() {
      this.currentIndex = null
      this.getAllPersonalityOfStudent()
    },
  },
  mounted() {
    this.getPersonalities()
    this.getAllPersonalityOfStudent()
  },
  methods: {
    getAllPersonalityOfStudent() {
      this.isLoading = true
      this.evaluatedBy = null
      useJwt.getStudentPersonalities(this.selectedStudent).then(response => {
        // all personality evaluations of the selected student
        this.studentPersonalities = response.data?.data

        // all the users who evaluated the student's personality
        this.evaluatedBy = this.studentPersonalities.map(personality => ({
          id: personality.evaluated_by.id,
          name: `${personality.evaluated_by.firstname} ${personality.evaluated_by.lastname}`,
        }))

        // set all the collapse visibility to true to begin with
        for (let i = 0; i < this.evaluatedBy.length; i += 1) {
          this.collapsed[i] = true
        }
      }).finally(() => { this.isLoading = false })
    },
    getPersonalities() {
      useJwt.getPersonalitiesByGroup().then(res => {
        this.personalities = res.data?.data
      })
    },
  },
}
</script>

<style scoped>
  .not-collapsed .card-text {
    font-weight: 700 !important;
    border-bottom: 2px solid;
  }
</style>
