<template>
  <div>
    <h3 class="mb-2">
      {{ $t('setting-module.camera-setting') }}
    </h3>
    <b-table-simple
      responsive
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th
            width="40%"
          >
            {{ $t('setting-module.options') }}
          </b-th>
          <b-th
            width="40%"
          >
            {{ $t('setting-module.switch') }}
          </b-th>
          <b-th
            width="20%"
          >
            {{ $t('setting-module.help-guide') }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>{{ $t('setting-module.turn-camera-permission') }}</b-td>
          <b-td>
            <span
              class="d-flex align-items-baseline"
            >
              <b-form-checkbox
                id="turn-camera-permission-settings"
                switch
                :checked="askForPermission"
                @change="updateCameraPermission"
              />
              <span class="ml-2">{{ $t('setting-module.disable-enable') }}</span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('camera_setting')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <!-- <b-row>
      <b-col class="mt-2">
        <div style="gap:15px;"
             class="d-flex align-items-center"
        >
          <label class="">Turn on Camera Permission <feather-icon v-b-tooltip.hover="$t('setting-module.camera-permission')"
                                                                  class="cursor-pointer text-primary"
                                                                  size="20"
                                                                  icon="InfoIcon"
          /></label>
          <b-form-checkbox switch
                           :checked="askForPermission"
                           @change="updateCameraPermission"
          />
        </div>
      </b-col>
    </b-row> -->
    <!--<b-row>
            <b-col cols="12">
                <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mt-2 mr-1"
                        :disabled="isProcessing"
                        type="submit"
                        @click.prevent="sendForm"
                >
                    <b-spinner
                            v-show="isProcessing"
                            class="mr-1"
                            small
                            variant="light"
                    />
                    {{ $t('account-setting-general.save') }}
                </b-button>
                <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        :disabled="isProcessing"
                        variant="outline-secondary"
                        type="reset"
                        class="mt-2"
                        @click.prevent="resetForm"
                >
                    {{ $t('account-setting-general.reset') }}
                </b-button>
                <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        :disabled="isProcessing"
                        variant="outline-secondary"
                        type="reset"
                        class="mt-2 ml-2"
                        @click.prevent="GO_BACK()"
                >
                    {{ $t('Back') }}
                </b-button>
            </b-col>
        </b-row>-->
    <setting-info-modal
      :show="showModal"
      :modal-data="modalData"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import {
  // BRow, BCol,
  BFormCheckbox, /* BCard,  BButton, */
  BTableSimple,
  BThead, BTbody, BTr, BTh, BTd, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import i18n from '@/libs/i18n'
import SettingInfoModal from '@/views/common/components/SettingInfoModal.vue'

export default {
  name: 'CameraSettings',
  components: {
    // BRow,
    // BCol,
    BFormCheckbox,
    // BCard,
    // BButton,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
    SettingInfoModal,
  },
  data() {
    return {
      askForPermission: parseInt(getUserData()?.camara_setting, 10) === 1,
      showModal: false,
      modalData: {
        title: '',
        imgSrc: '',
        description: '',
        steps: '',
      },
      modalInfo: {
        camera_setting: {
          title: i18n.tc('setting-module.turn-camera-permission'),
          description: i18n.tc('setting-module.camera-permission'),
          imageSrc: '/school-settings/camera-setting.png',
          steps: '',
        },
      },
    }
  },
  mounted() {
    this.$emit('toogleOverlay', true)
    this.getUserData()
  },
  methods: {
    updateCameraPermission(value) {
      useJwt.updateUserPermission({ permission: 'camera_setting', value, forceSync: this.$store.state.taskHelper.forceSyncSettingToSchool }).then(() => {
        this.showSuccessMessage({ data: { message: 'Permission updated' } })
        this.askForPermission = value
        const currentUserData = getUserData()
        currentUserData.camara_setting = value ? 1 : 0
        this.updateUserData(currentUserData)
      })
    },
    showHelpGuide(type) {
      this.showModal = true
      this.modalData = {
        title: this.modalInfo[type].title,
        imgSrc: this.modalInfo[type].imageSrc,
        description: this.modalInfo[type].description,
        steps: this.modalInfo[type].steps,
      }
    },
  },
}
</script>

<style scoped>

</style>
