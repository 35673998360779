<template>
  <b-tr
    :style="getRowColor(report)"
  >
    <b-td
      style="min-width: 250px;"
      class="text-right"
    >
      <b :style="`color:${ (report[0].lesson_type === 'Normal')?'#6495ed':'' }`">
        {{ report[0].lesson_name }}{{ report[0].lesson_type === 'Normal'?' (Linear)':'' }}
      </b>
    </b-td>
    <b-td
      v-for="student of studentLists"
      :key="student.user_id"
      :set="lessonInfo = report.find(r => r.user_id == student.user_id)"
      :class="getTableClass(report.find(r => r.user_id == student.user_id))"
      class="text-white"
    >
      <div
        v-b-tooltip.hover="{
          title: getToolTipContent(lessonInfo),
          html: true, placement: 'top'
        }"
        class="d-flex justify-content-center align-items-center"
        style="width:60px; height:60px;"
      >
        <span v-if="lessonInfo"
              class="d-flex px-1"
        >
          <span v-if="activeFilter === 'cefr_rate'">{{ lessonInfo.cefr_rate }}</span>
          <span v-else-if="activeFilter === 'completion'"> {{ number_format(lessonInfo.score_percentage, 2) }}</span>
          <span v-else-if="activeFilter === 'language_level'">{{ getLevelFromScore(lessonInfo.cefr_rate) }}</span>
          <span v-else>
            {{ number_format(lessonInfo.score_percentage, 2) }}
          </span>
          &nbsp;<b-badge
            v-if="lessonInfo.round > 1"
            class="bg-danger round"
          >
            {{ lessonInfo.round }}
          </b-badge>

        </span>
      </div>

    </b-td>
  </b-tr>
</template>
<script>
import {
  BTd, BTr, VBTooltip, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BTd, BTr, BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['report', 'getRowColor', 'getTableClass', 'getToolTipContent', 'getLevelFromScore', 'studentLists', 'activeFilter'],
}

</script>
