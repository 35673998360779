<template>
  <b-modal
    :visible="true"
    :title="`${formType} ${$t('template-module.template')}`"
    size="lg"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <validation-observer ref="validationForm">
      <b-form-group
        :label="$t('template-module.template-name')"
        label-for="templateName"
      >
        <validation-provider
          v-slot="{ errors }"
          name="Template Name"
          rules="required"
          vid="email"
        >
          <b-form-input
            id="templateName"
            v-model="form.name"
            :placeholder="$t('template-module.enter-template-name')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- Template Textarea -->
      <b-form-group
        :label="$t('template-module.template')"
        label-for="templateTextarea"
      >
        <validation-provider
          v-slot="{ errors }"
          name="Template"
          rules="required"
          vid="email"
        >
          <Editor
            id="templateTextarea"
            v-model="form.template"
            :placeholder="$t('template-module.enter-template-text')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- Public Checkbox -->
      <b-form-checkbox id="publicCheckbox"
                       v-model="form.is_public"
                       :value="1"
      >
        {{ $t('publish') }}
      </b-form-checkbox>
    </validation-observer>
    <template #modal-footer>
      <div>
        <b-button
          class="mr-2"
          variant="outline-secondary"
          @click="$emit('close')"
        >
          {{ $t('cancel') }}
        </b-button>
        <b-button variant="primary"
                  :disabled="isProcessing"
                  @click="form.id ? updateForm() : saveForm()"
        >
          <b-spinner v-if="isProcessing"
                     v-show="isProcessing"
                     small
          /> {{ formType }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script setup>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { reactive, ref, getCurrentInstance } from 'vue'
import Editor from '@views/common/components/QuillEditorCustom.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  template: {
    type: Object,
    default: () => null,
  },
})
const initialState = {
  name: '',
  template: '',
  is_public: false,
}

const formType = props.template ? i18n.tc('actions.update') : i18n.tc('actions.create')
const root = getCurrentInstance().proxy.$root
const isProcessing = ref(false)
const validationForm = ref(null)
const emit = defineEmits(['close', 'reFetch'])

const form = reactive({
  ...initialState,
  ...props.template,
})

const resetForm = () => {
  Object.assign(form, initialState)
}

const updateForm = () => {
  validationForm.value.validate().then(success => {
    if (success) {
      isProcessing.value = true
      useJwt.updateNoteTemplate(form.id, form).then(response => {
        root.showSuccessMessage(response)
        resetForm()
        emit('close')
        emit('reFetch')
      }).catch(error => {
        root.showErrorMessage(error)
      }).finally(() => {
        isProcessing.value = false
      })
    }
  })
}

const saveForm = () => {
  validationForm.value.validate().then(success => {
    if (success) {
      isProcessing.value = true
      useJwt.storeNoteTemplate(form).then(response => {
        root.showSuccessMessage(response)
        resetForm()
        emit('close')
        emit('reFetch')
      }).catch(error => {
        root.showErrorMessage(error)
      }).finally(() => {
        isProcessing.value = false
      })
    }
  })
}

</script>
