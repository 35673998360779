<template>
  <b-modal :visible="show"
           size="xl"
           title="Create Problem"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <validation-observer
      ref="teacherProblem"
    >
      <b-row>
        <b-col md="4">
          <b-form-group label="Problem Name">
            <validation-provider
              v-slot="{ errors }"
              name="Problem Name"
              rules="required"
            >
              <b-form-input v-model="problemName"
                            placeholder="Problem name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Skill Type">
            <validation-provider
              v-slot="{ errors }"
              name="Skill Type"
              rules="required"
            >
              <b-form-select v-model="skill"
                             text-field="text"
                             value-field="value"
                             :options="skillTypes"
                             placeholder="Select Skill Type"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- <b-col md="4">
          <b-form-group label="Standard">
            <validation-provider
              v-slot="{ errors }"
              name="Standard"
              rules="required"
            >
              <b-form-select v-model="standardId"
                             text-field="name"
                             value-field="id"
                             :options="standardLists"
                             placeholder="Select Standard"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col> -->
      </b-row>
      <b-tabs :key="problemTypeComponentKey"
              pills
              card
              vertical
      >
        <b-tab
          :title="$t('problem-types.match-the-following')"
          active
          @click="selectedProblemType = 'matching'"
        >
          <matching-problem ref="matching"
                            :is-processing="isProcessing"
                            @onSave="handleSave"
          />
        </b-tab>
        <b-tab title="Multiple Choice"
               @click="selectedProblemType = 'multiple'"
        >
          <true-false-problem ref="multiple"
                              :is-processing="isProcessing"
                              @onSave="handleSave"
          />
        </b-tab>
        <b-tab title="Re-arrange"
               @click="selectedProblemType = 're-arrange'"
        >
          <rearrange-problem ref="re-arrange"
                             :is-processing="isProcessing"
          />
        </b-tab>
        <b-tab title="Prompt Speech"
               @click="selectedProblemType = 'prompt-speech'"
        >
          <speech-problem ref="prompt-speech"
                          :is-processing="isProcessing"
          />
        </b-tab>
        <b-tab title="Open Ended Speech"
               @click="selectedProblemType = 'open-ended-speech'"
        >
          <open-ended-speech ref="open-ended-speech"
                             :is-processing="isProcessing"
          />
        </b-tab>
      </b-tabs>
    </validation-observer>
    <template #modal-footer>
      <div>
        <b-button variant="outline-secondary"
                  class="mr-1"
                  @click="$emit('close')"
        >
          Close
        </b-button>
        <b-button variant="primary"
                  :disabled="isProcessing"
                  @click="handleSave"
        >
          <b-spinner v-show="isProcessing"
                     small
          /> Save Problem
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import {
  BTabs, BTab, BFormGroup, BFormInput, BRow, BCol, BModal, BButton, BSpinner,
  BFormSelect,
} from 'bootstrap-vue'

import useJwt from '@/auth/jwt/useJwt'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import skillTypes from '@/const/skillTypes'
import MatchingProblem from './problems-type/Matching-problem.vue'
import TrueFalseProblem from './problems-type/True-False.vue'
import RearrangeProblem from './problems-type/Re-arrange.vue'
import SpeechProblem from './problems-type/SpeechProblem.vue'
import OpenEndedSpeech from './problems-type/Open-end-writing.vue'

export default {
  components: {
    MatchingProblem,
    TrueFalseProblem,
    RearrangeProblem,
    SpeechProblem,
    OpenEndedSpeech,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormSelect,
    BModal,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    lessonId: {
      type: Number,
      required: true,
    },
    lessonDomain: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      problemName: '',
      domainId: null,
      standardId: null,
      isProcessing: false,
      standardLists: [],
      required,
      problemTypeComponentKey: Math.random(),
      selectedProblemType: 'matching',
      skillTypes,
      skill: 'reading',
    }
  },
  methods: {
    resetProblemCreate() {
      this.problemName = ''
      this.problemTypeComponentKey = Math.random()
    },
    getStandards() {
      useJwt.getStandardsByDomain(this.domainId).then(response => {
        this.standardLists = response.data.data
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    handleSave() {
      this.$refs.teacherProblem.validate().then(success => {
        const { questionAnswers, problemType } = this.$refs[this.selectedProblemType].getData()
        if (success) {
          const data = {
            standard_id: this.standardId,
            questions_answers: questionAnswers,
            question_type: problemType,
            problem_name: this.problemName,
            lesson_id: this.lessonId,
            skill_type: this.skill,
            generated_type: 'TGP',
          }
          this.isProcessing = true
          useJwt.followUpTeacherCreateProblem(data).then(response => {
            this.showSuccessMessage(response)
            this.resetProblemCreate()
            this.$emit('reFetch')
            this.$emit('close')
            this.$refs.teacherProblem.reset()
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => {
            this.isProcessing = false
          })
        }
      })
    },
  },
}
</script>
