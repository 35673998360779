<template>
  <div id="lessonPlanner">
    <div class="d-flex justify-content-between mb-2 align-items-center">
      <h2 class="pb-1">
        {{ $t('labels.lesson-planner') }}
      </h2>
      <b-button variant="outline-success"
                @click="previewing=!previewing"
      >
        <feather-icon :icon="previewing ? 'EyeOffIcon' : 'EyeIcon' " />
        {{ previewing ? $t('template-module.hide-preview') : $t('template-module.preview') }}
      </b-button>
    </div>
    <validation-observer ref="lessonPlannerForm">
      <b-row
        v-if="!updating && !previewing"
        class="border p-2 mb-2"
      >

        <b-col cols="5">
          <b-form-group
            :label=" $t('actions.course') "
          >
            <b-form-select
              v-model="course"
              :placeholder="$t('template-module.please-select-course')"
              :options="courses"
              @change="getLessonGroups"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group :label="$t('courses-module.LessonGroup')">
            <b-form-select
              v-model="lessonGroup"
              :placeholder="$t('template-module.please-select-course')"
              :options="lessonGroups"
              @change="getLessons"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <validation-provider v-slot="{errors}"
                               name="Lesson"
                               :vid="`lesson${index}`"
                               rules="required"
          >
            <b-form-group :label="$t('course-completion-module.table-header.lesson')">
              <b-form-select v-model="creatingLessonPlanners.lesson_id"
                             :options="lessons"
                             :class="errors.length > 0 ? 'is-invalid':null"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row v-for="form,index in creatingLessonPlanners.form"
             :key="index"
             class="border lesson-planner-form p-2 mb-2"
             :class="`${form.lesson_planner_type === 'online' && 'light-bg'} ${(form.lesson_planner_type === 'online' && previewing) && ' preview-bg-light '} ${form.lesson_planner_type === 'offline' && 'light-secondary'} ${(form.lesson_planner_type === 'offline' && previewing) && ' preview-bg-secondary '}`"
      >
        <b-row v-if="!previewing">
          <b-col v-if="!previewing"
                 cols="12"
                 class="text-right"
          >
            <h6 class="cursor-pointer text-danger"
                @click="removeLessonPlanner(index)"
            >
              <feather-icon icon="XIcon"
                            size="22"
              />  {{ $t('courses-module.delete') }}
            </h6>
          </b-col>

          <b-col md="6"
                 xl="8"
          >
            <validation-provider v-slot="{ errors }"
                                 name="Title"
                                 :vid="`title${index}`"
                                 rules="required|max:255"
            >
              <b-form-group :label="$t('curriculum-pages-module.title')">
                <b-form-input v-model="form.title"
                              :class="errors.length > 0 ? 'is-invalid':null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="3"
                 xl="2"
          >
            <validation-provider v-slot="{ errors }"
                                 name="Type"
                                 :vid="`type${index}`"
                                 rules="required"
            >
              <b-form-group :label="$t('Type')">
                <b-form-select v-model="form.lesson_planner_type"
                               :options="lessonPlanTypes"
                               :class="errors.length > 0 ? 'is-invalid':null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="3"
                 xl="2"
          >
            <validation-provider v-slot="{ errors }"
                                 name="Visibility"
                                 :vid="`type${index}`"
                                 rules="required"
            >
              <b-form-group :label="$t('template-module.visibility')">
                <b-form-select v-model="form.access_type"
                               :options="accessTypes"
                               :class="errors.length > 0 ? 'is-invalid':null"
                />

              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col v-if="form.access_type=='share'"
                 md="4"
                 xl="3"
          >
            <b-form-group :label="$t('user-types.school')">
              <v-select v-model="form.school_id"
                        :options="schools"
                        label="fullname"
                        :reduce="(school) => school.id"
                        placeholder="Choose schools"
                        :clearable="false"
                        :disabled="form.access_type !== 'share'"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="form.author"
                 cols="12"
                 class="text-right"
          >
            <h6>{{ $t('template-module.author') }}: {{ `${form.author.firstname} ${form.author.lastname}` }}</h6>
          </b-col>
          <b-col cols="12">
            <validation-provider v-slot="{ errors }"
                                 name="Content"
                                 :vid="`content${index}`"
                                 rules="required"
            >
              <b-form-group :label="$t('template-module.content')">
                <quill-editor-custom v-model="form.content"
                                     :class="errors.length > 0 ? 'is-invalid':null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <b-col v-else
               cols="12"
        >
          <div class="d-flex justify-content-end">
            <div :class="form.lesson_planner_type=='online'?'bg-success':'bg-secondary'"
                 style="height:15px; width:15px; border-radius:50%;"
            />
          </div>
          <h4 class="">
            {{ form.title }}
          </h4>
          <div v-html="form.content" />
        </b-col>

      </b-row>
      <b-col v-if="!previewing"
             cols="12"
             class="text-right mb-2"
      >
        <b-button variant="primary"
                  @click="addNewLessonPlanner"
        >
          <feather-icon icon="PlusIcon" /> {{ $t('image-library-module.add-more') }}
        </b-button>
      </b-col>

      <b-col v-if="!previewing"
             cols="12"
             class="text-right "
      >
        <b-button v-if="updating"
                  variant="outline-danger"
                  @click="removeAllLessonPlanner"
        >
          {{ $t('courses-module.delete') }}
        </b-button>
        <b-button variant="outline-primary"
                  class="ml-2"
                  @click="()=>{updating ? updateLessonPlanner() : createLessonPlanner()} "
        >
          {{ updating ? $t('actions.update'): $t('actions.create') }}
        </b-button>

      </b-col>
    </validation-observer>
  </div>
</template>

<script>
// import useJwt from '@/auth/jwt/useJwt'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormSelect, BButton,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import i18n from '@/libs/i18n'
import QuillEditorCustom from '@views/common/components/QuillEditorCustom.vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import useApollo from '@/@core/graphql/useApollo'

export default {

  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    ValidationProvider,
    ValidationObserver,
    // BFormCheckbox,
    QuillEditorCustom,
    vSelect,

  },

  props: {
    courses: {
      type: [Array, Object],
      default: () => {},
    },
    updating: {
      type: Boolean,
      default: false,
    },

    lessonPlannerId: {
      type: [String, Number],
      default: () => {},
    },

    startDate: {
      type: [String, Date],
      default: '',
    },
  },

  data() {
    return {
      previewing: false,
      course: null,
      lessonGroup: null,
      creatingLessonPlanners: {
        lesson_id: null,
        form: [
          {
            title: '',
            content: '',
            access_type: 'private',
            lesson_planner_type: 'online',
          },
        ],

      },

      lessonPlanTypes: [
        {
          value: null,
          text: i18n.tc('template-module.select-type'),
        },
        {
          value: 'offline',
          text: i18n.tc('template-module.offline'),
        },
        {
          value: 'online',
          text: i18n.tc('template-module.online'),
        },
      ],

      accessTypes: [
        {
          value: null,
          text: i18n.tc('template-module.select-visibility-type'),
        },
        {
          value: 'public',
          text: i18n.tc('template-module.public'),
        },
        {
          value: 'private',
          text: i18n.tc('template-module.private'),
        },
        // {
        //   value: 'share',
        //   text: 'Share',
        // },
      ],
      lessons: [],
      lessonGroups: [],
      isProcessing: false,
      schools: [],

    }
  },

  computed: {
    classId() {
      return this.$route.params.classId
    },
  },

  mounted() {
    if (this.updating) {
      this.fetchCurrentLessonPlanner()
    }
  },

  methods: {
    getLessons(id) {
      this.lessons = this.lessonGroups[this.lessonGroups.findIndex(e => e.id === id)].lessons.map(e => ({
        value: e.id, id: e.id, text: e.name, onlyTest: e.use_on === 'only-test',
      }))
      this.lessons.unshift({ value: null, text: 'Please select a Lesson' })
    },

    getLessonGroups(id) {
      this.lessonGroups = this.courses[this.courses.findIndex(e => e.id === id)].lesson_groups.map(e => ({
        value: e.id, id: e.id, lessons: e.lessons, text: e.name,
      }))
      this.lessonGroups.unshift({ value: null, text: 'Please select a Lesson Group' })
    },

    addNewLessonPlanner() {
      this.creatingLessonPlanners.form.push({
        title: '',
        lesson_planner_type: 'online',
        content: '',
        access_type: 'private',
      })
    },

    removeLessonPlanner(index) {
      if (this.updating) {
        const { lesson_planner_id } = this.creatingLessonPlanners.form[index]
        useJwt.removeLessonPlanner(lesson_planner_id).then(() => {
          this.showSuccess('Lesson Planner removed successfully')
          this.fetchCurrentLessonPlanner()
        })
        return
      }
      this.creatingLessonPlanners.form.splice(index, 1)
    },

    removeAllLessonPlanner() {
      useJwt.removeLessonPlanner(this.creatingLessonPlanners.form[0].lesson_planner_id, 1).then(() => {
        this.showSuccess('All Lesson Planner removed successfully')
        this.$emit('lessonPlannerUpdated')
      })
    },

    getSchools() {
      useApollo
        .getSchoolUsers()
        .then(response => {
          const res = response.data?.schools?.data

          this.schools = res.map(e => ({
            id: parseInt(e.id, 10),
            fullname: e.fullname,
          }))
        })
    },

    createLessonPlanner() {
      this.$refs.lessonPlannerForm.validate().then(success => {
        if (success) {
          this.creatingLessonPlanners.start_date = this.startDate
          useJwt.createLessonPlanner(this.creatingLessonPlanners).then(() => {
            this.showSuccess('Lesson Planner created successfully')
            this.$emit('lessonPlannerCreated')
          })
        }
      })
    },

    updateLessonPlanner() {
      this.$refs.lessonPlannerForm.validate().then(success => {
        if (success) {
          useJwt.updateLessonPlanner(this.lessonPlannerId, this.creatingLessonPlanners).then(() => {
            this.showSuccess('Lesson Planner updated successfully')
            this.$emit('lessonPlannerUpdated')
          })
        }
      })
    },

    fetchCurrentLessonPlanner() {
      useJwt.fetchCurrentLessonPlanner(this.lessonPlannerId, { params: { type: 'my-lesson-plan' } }).then(res => {
        const { data } = res
        const form = []
        data.forEach(d => {
          form.push({
            title: d.title,
            lesson_planner_type: d.lesson_planner_type,
            content: d.content,
            access_type: d.access_type,
            school_id: d.school_id,
            lesson_planner_id: d.id,
            author: d.author,
          })
        })

        this.creatingLessonPlanners = {
          lesson_id: data[0].lesson_id,
          form,
        }
        this.getLessonGroups(this.creatingLessonPlanners.course_id)
        this.getLessons(this.creatingLessonPlanners.lesson_group_id)
      })
    },

  },

}

</script>

<style lang="scss">
#lessonPlanner{
  .light-bg{
    background-color:#e9f8ee;
  }

  .light-secondary{
    background-color:rgba(130, 134, 139, 0.12);
  }

  .lesson-planner-form{
    border-radius: 10px;
    .cke_contents{
    min-height:350px !important;
  }
  }

  .preview-bg-light, .preview-bg-secondary{
    background:url('../../../../assets/images/illustration/back.png') no-repeat;
    border-radius: 10px;
    background-size: cover;
    .col-12{
      background: #fff;
      padding: 10px 20px 10px 20px;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px #0000001a;
    }
  }

  .lesson-planner-view{
      -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
