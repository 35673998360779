<template>
  <b-modal
    id="new-product-modal"
    ref="new-product-modal"
    size="lg"
    title="New Programs"
    hide-footer
  >

    <b-row>
      <b-col
        v-for="marketplace in marketplaces"
        :key="marketplace.id"
        md="6"
      >
        <b-card
          no-body
          class="cursor-pointer"
        >
          <b-card-header
            class="course-img-wrapper p-0 mb-1 text-center"
          >
            <b-card-img
              class="course-img"
              :src="marketplace.image"
            />

          </b-card-header>

          <b-card-body>
            <!-- Course Name -->
            <b-card-title>
              <h6>{{ marketplace.title }}</h6>
            </b-card-title>
            <b-card-text>
              <h6>$ {{ marketplace.price }}</h6>
              <small v-if="!DATE_IS_PASSED(marketplace.end_time)">Ends {{ FROM_NOW(marketplace.end_time) }}</small>
            </b-card-text>
            <!-- Grade -->
            <!-- <b-card-text
              class="mb-0 font-weight-bold"
            >
              {{ marketplace.description }}
            </b-card-text> -->

            <b-card-text class="text-right pt-1 pb-2">
              <b-button
                variant="primary"
                @click="()=>{
                  $bvModal.hide('new-product-modal')
                  $router.push(`/${self.usertype}/market/${marketplace.id}`)
                }"
              >
                {{ $t('labels.view-details') }}
              </b-button>
            </b-card-text>
            <!-- Course Description -->
            <!-- <b-card-text
                :id="`course-${index}`"
                :ref="`course-${index}`"
                class="course-description"
                :class="{ 'mb-2': item.description }"
              >
                {{ item.description }}
              </b-card-text> -->

          </b-card-body>

        </b-card>
      </b-col>
    </b-row>

  </b-modal>
</template>

<script>
import {
  BCard, BRow, BCol, BCardHeader, BCardBody, BCardText, BCardImg, BButton, BCardTitle,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BRow,
    BCardHeader,
    BCardBody,
    BCardText,
    BCol,
    BCardImg,
    BButton,
    BCardTitle,
  },

  props: {
    marketplaces: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {
      self: getUserData(),
    }
  },
}
</script>

<style scoped>
.card {
  height: 85%;
}
.card:hover {
  box-shadow: 0 8px 32px 0 rgba(28, 34, 112, 0.37);
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
}
.vue-avatar--wrapper {
  width: 100% !important;
  height: 100% !important;
  font-size: 48px !important;
}
.course-list .card-title {
  font-size: 18px;
  margin-bottom: 8px;
}
.course-img-wrapper {
  height: 160px !important;
}
.course-img {
  height: 100%;
}
.course-description {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popover .popover-body {
  max-height: 280px;
  overflow: auto;
}
</style>
