<template>
  <div>
    <b-card
      no-body
      class="mb-0 mt-2"
    >
      <b-row
        v-if="!isSDPLesson && !isCreateMode"
        class="pl-2"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          :disabled="!rows.length"
          class="m-1"
          @click="selected = rows.map(r => r.id)"
        >
          {{ $t("actions.select-all") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          :disabled="!selected.length"
          class="my-1"
          @click="selected = []"
        >
          {{ $t("actions.clear-selection") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          :disabled="!selected.length"
          class="m-1 ml-1"
          @click="toggleMultipleDelete"
        >
          {{ $t("actions.delete") }}
        </b-button>
        <b-button-group class="d-block mt-1">
          <b-button
            :disabled="!selected.length"
            variant="outline-primary"
            @click="toggleSelectedVisibility('unpublish')"
          >
            {{ $t('actions.unpublish') }}
          </b-button>
          <b-button
            :disabled="!selected.length"
            variant="outline-primary"
            @click="toggleSelectedVisibility('publish')"
          >
            {{ $t('actions.publish') }}
          </b-button>
        </b-button-group>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="m-1 ml-1"
          @click="reorderProblem = true"
        >
          {{ $t("actions.order-by-name") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="m-1 ml-1"
          @click="reorderProblem = 'random'"
        >
          Random Order
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="m-1 ml-1"
          @click="showCreateSets = true"
        >
          {{ $t('actions.create-sets') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="m-1 ml-1"
          @click="showLessonTemplateAnimated = true"
        >
          {{ $t('actions.manage-animated-template') }}
        </b-button>
        <b-button
          v-if="selected.length"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="m-1 ml-1"
          @click="showProblemCopy = true"
        >
          {{ $t("labels.copy-problem") }}
        </b-button>
      </b-row>
      <b-table-simple responsive>
        <thead
          role="rowgroup"
        >
          <tr
            role="row"
          >
            <th
              v-for="column in columns"
              :key="column.label"
              role="columnheader"
              scope="col"
              aria-colindex="1"
              :class="column.thClass"
            >
              <div>{{ column.label }}</div>
            </th>
          </tr>
        </thead>
        <draggable
          v-model="tableRows"
          tag="tbody"
          :options="{animation:300}"
          handle=".handle"
          @change="updateOrder"
        >
          <tr
            v-for="(row, index) in tableRows"
            :key="row.name + index"
            :role="'row'"
            :class="selectedProblem == row.id && 'bg-info'"
            @click="$emit('markSelectedProblem', row.id)"
          >
            <template v-if="row.isSetGroup">
              <td
                colspan="100%"
                class="text-center font-weight-bold text-uppercase row-group"
                :style="row.styles"
              >
                {{ row.name }}
                <span class="actions">
                  <feather-icon
                    icon="EditIcon"
                    role="button"
                    class="mx-1 text-primary"
                    @click="setInfoToEdit = row.setInfo"
                  />
                  <feather-icon
                    icon="TrashIcon"
                    role="button"
                    class="mr-2 text-danger"
                    @click="setIdToDelete = row.setInfo.id"
                  />
                </span>
              </td>
            </template>
            <template v-else-if="row.groupDivider">
              <td
                colspan="100%"
                class="bg-secondary"
              >
                &nbsp;
              </td>
            </template>
            <template v-else>
              <td
                v-for="column in columns"
                :key="'row_'+column.label"
                role="cell"
              >
                <span
                  v-if="column.field === 'selectRow'"
                  class="d-flex"
                >
                  <b-form-checkbox-group
                    id="checkbox-group"
                    v-model="selected"
                  >
                    <b-form-checkbox :value="row.id" />
                  </b-form-checkbox-group>
                </span>
                <span
                  v-if="column.field === '#'"
                  class="d-flex"
                >
                  <feather-icon
                    icon="AlignJustifyIcon"
                    size="16"
                    class="text-body align-middle m-0 p-0 handle mr-2"
                  />
                </span>
                <span
                  v-if="column.field === 'wrapper'"
                  class="d-flex"
                >
                  <b-badge
                    v-if="row.hasGameWrapper"
                    variant="success"
                    class="badge-glow mr-1"
                  >
                    {{ 'Game' }}
                  </b-badge>
                  <b-badge
                    v-else-if="row.hasVideoWrapper"
                    variant="primary"
                    class="badge-glow"
                  >
                    {{ 'Video' }}
                  </b-badge>
                  <b-badge
                    v-else-if="row.hasHotSpotWrapper"
                    variant="danger"
                    class="badge-glow mr-1"
                  >
                    {{ 'Hotspot' }}
                  </b-badge>
                  <b-badge
                    v-if="!row.hasGameWrapper && !row.hasVideoWrapper && !row.hasHotSpotWrapper"
                    variant="secondary"
                    class="badge-glow"
                  >
                    {{ 'Text' }}
                  </b-badge>
                </span>
                <span
                  v-if="column.field === 'action'"
                  class="d-flex justify-content-center align-items-center"
                >
                  <template v-if="!isCreateMode">
                    <b-form-checkbox
                      :checked="row.is_public"
                      class="custom-control-primary pr-1"
                      name="check-button"
                      switch
                      @change="togglePublic(row.id, row.is_public, row.sdp_problem_id)"
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                    <b-button
                      variant="outline-primary"
                      class="btn-icon sm-mt-2"
                      @click="editProblem(row.id)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                    <b-button
                      v-if="!isSDPLesson"
                      variant="outline-danger"
                      class="btn-icon sm-mt-2 mx-1"
                      @click="toggleDeleteModal(row.id)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </template>
                  <b-button
                    v-if="!isSDPLesson"
                    variant="outline-success"
                    class="btn-icon sm-mt-2"
                    @click="problemIdToShowPreview = Number(row.id)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <b-button
                    v-if="row.hasGameWrapper"
                    v-b-tooltip.hover.top="'Remove Game wrapper'"
                    variant="outline-danger"
                    class="btn-icon sm-mt-2"
                    @click="problemIdOfGameToRemove = row.id"
                  >
                    <feather-icon icon="XSquareIcon" />
                  </b-button>
                </span>
                <span
                  v-else-if="column.field === 'tags'"
                  class="d-flex"
                >
                  <div>
                    <b-badge
                      v-for="tag of row.tags"
                      :key="tag.id"
                      variant="primary"
                      class="badge-glow mr-1"
                    >
                      {{ tag.text }}
                    </b-badge>
                    <span v-if="!row.tags.length">
                      --
                    </span>
                  </div>
                </span>
                <span v-else-if="column.field === 'ddf_set'">
                  <b-form-select
                    :value="row.ddf_set"
                    :options="ddfOption"
                    @input="(value)=> updateDDF(row.id,value)"
                  />
                </span>
                <span v-else>
                  {{ row[column.field] }}
                </span>
              </td>
            </template>
          </tr>
        </draggable>
      </b-table-simple>
      <p
        v-show="rows.length==0"
        class="p-2 p-0 m-0"
      >
        {{ $t("messages.no-record-found") }}
      </p>
    </b-card>

    <!-- delete item -->
    <b-modal
      id="modal-delete"
      ref="modal-delete"
      ok-only
      ok-variant="danger"
      :ok-title="$t('confirm')"
      modal-class="modal-danger"
      centered
      :title="$t('actions.delete-problem')"
      @ok="deleteProblem()"
    >
      <b-card-text v-if="deleteMode === 'single'">
        {{ $t("questions.delete-problem") }}
      </b-card-text>
      <b-card-text v-else-if="deleteMode === 'multiple'">
        {{ $t("questions.delete-multiple-problem") }}
      </b-card-text>
      <template #modal-footer="{ ok }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              :disabled="isProcessing"
              @click="ok()"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t("confirm") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- delete item -->

    <!-- force delete item -->
    <b-modal
      id="modal-force-delete"
      ref="modal-force-delete"
      ok-variant="danger"
      :ok-title="$t('confirm')"
      modal-class="modal-danger"
      centered
      :title="$t('actions.delete-problem')"
      @ok="forceDeleteProblem()"
    >
      <b-card-text>
        {{ $t("questions.force-delete-problem") }}
      </b-card-text>
      <template #modal-footer="{ ok }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="secondary"
              :disabled="isProcessing"
              class="mr-1"
              @click="$refs['modal-force-delete'].hide()"
            >
              {{ $t("actions.close") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              :disabled="isProcessing"
              @click="ok()"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t("actions.delete-anyway") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- force delete item -->
    <confirm-delete
      :show="!!problemIdOfGameToRemove"
      sub-title="Are you sure you want delete game wrapper?"
      :on-delete="removeGameWrapper"
      :is-processing="removingGameWrapper"
      @close="problemIdOfGameToRemove = null"
    />
    <confirm-delete
      :show="!!reorderProblem"
      title="Reorder?"
      varient="primary"
      :sub-title="reorderProblem == 'random' ? 'Are you sure you want reorder problem in random?' : 'Are you sure you want reorder problem by name?'"
      :on-delete="orderByName"
      :is-processing="isReorderingProblem"
      @close="reorderProblem = false"
    />
    <confirm-delete
      :show="!!setIdToDelete"
      sub-title="Are you sure you want to delete sets?"
      :on-delete="deleteSets"
      :is-processing="isDeletingSets"
      @close="setIdToDelete = null"
    />
    <manage-sets
      :show="!!showCreateSets || !!setInfoToEdit"
      :set-info="setInfoToEdit"
      :problems="tableRows"
      @close="showCreateSets = null;setInfoToEdit = null"
      @fetchProblem="$emit('fetchProblem')"
    />
    <lesson-preview
      :show="problemIdToShowPreview"
      :problem-id="problemIdToShowPreview"
      :lesson-info="lessonInfo"
      :lesson-id="Number($route.query.lesson_id)"
      @close="problemIdToShowPreview = null"
    />
    <LessonAnimatedTemplate v-if="showLessonTemplateAnimated"
                            :show="showLessonTemplateAnimated"
                            :problems="rows"
                            :lesson-id="Number(lessonInfo.id)"
                            @close="showLessonTemplateAnimated = false"
                            @fetchProblem="$emit('fetchProblem')"
    />
    <CopyProblemModal :show="showProblemCopy"
                      :problem-id="selected"
                      @close="showProblemCopy = false"
    />
    <b-modal
      id="modal-warning"
      ok-only
      :visible="!!problemIdToEdit"
      ok-variant="warning"
      ok-title="Continue Edit"
      modal-class="modal-warning"
      centered
      title="Confirm Edit"
      @close="problemIdToEdit = null"
      @hide="problemIdToEdit = null"
      @ok="editProblem(problemIdToEdit, true)"
    >
      <b-card-text>
        Are You Sure You want to edit? Editing Will affect the shared problem too.
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
// import { VueGoodTable } from 'vue-good-table'
import {
  BButton, BModal, BRow, BCol, BCardText, BSpinner, BTableSimple, BCard, BFormCheckbox, BBadge, BFormCheckboxGroup,
  BFormSelect, VBTooltip, BButtonGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import draggable from 'vuedraggable'
import ConfirmDelete from '@/views/common/components/DeleteModal.vue'
import { getUserData } from '@/auth/utils'
import CopyProblemModal from '@/views/super/problem/edit-problem/components/CopyToLessonModal.vue'
import ManageSets from './components/ManageSets.vue'
import LessonAnimatedTemplate from './components/LessonAnimatedTemplate.vue'
import LessonPreview from './components/LessonPreview.vue'

export default {
  components: {
    // VueGoodTable,
    BButton,
    BModal,
    BRow,
    BCol,
    BTableSimple,
    BCardText,
    BSpinner,
    draggable,
    BCard,
    BBadge,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormSelect,
    BButtonGroup,
    ManageSets,
    LessonPreview,
    ConfirmDelete,
    LessonAnimatedTemplate,
    CopyProblemModal,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    isSDPLesson: {
      type: Boolean,
      default: false,
    },
    lessonInfo: {
      type: Object,
      default: () => null,
    },
    isCreateMode: {
      type: Boolean,
      default: false,
    },
    selectedProblem: {
      type: Number,
      default: Number,
    },
  },
  data: () => ({
    deleteItemId: null,
    isProcessing: false,
    tableRows: null,
    selected: [],
    deleteMode: '',
    ddfOption: [
      { value: null, text: 'None' },
      { value: 1, text: 'Primary' },
      { value: 2, text: 'Secondary' },
      { value: 0, text: 'Exclude' },
    ],
    ddf: [],
    problemIdOfGameToRemove: null,
    removingGameWrapper: false,
    problemIdToEdit: null,
    auth: getUserData(),
    reorderProblem: false,
    isReorderingProblem: false,
    showCreateSets: null,
    setInfoToEdit: null,
    setIdToDelete: null,
    isDeletingSets: false,
    problemIdToShowPreview: null,
    showLessonTemplateAnimated: false,
    showProblemCopy: false,
  }),
  watch: {
    rows() {
      this.handleRowChanges()
    },
  },
  created() {
    this.handleRowChanges()
  },
  methods: {
    deleteSets() {
      this.isDeletingSets = true
      useJwt.deleteProblemSetWrapper(this.setIdToDelete).then(response => {
        this.setIdToDelete = null
        this.$emit('fetchProblem')
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isDeletingSets = false
      })
    },
    handleRowChanges() {
      let tableData = []
      let hasLastGroup = 0
      this.rows.forEach(row => {
        if (!tableData.find(item => item.id === row.id)) {
          if (row.set_id) {
            const pairs = this.rows.filter(item => item.set_id === row.set_id)
            pairs.sort((a, b) => Number(a.order_index) - Number(b.order_index))
            tableData = [...tableData, {
              name: row.set_name,
              isSetGroup: true,
              divider: true,
              set_id: row.set_id,
              setInfo: row.setInfo,
              styles: this.JSON_PARSE(this.JSON_PARSE(row.setInfo?.styles)),
            }, ...pairs]
            hasLastGroup = row.set_id
          } else {
            if (hasLastGroup) {
              tableData = [...tableData, {
                name: '', groupDivider: true, divider: true, set_id: null,
              }]
            }
            tableData = [...tableData, row]
            hasLastGroup = null
          }
        }
      })
      this.tableRows = tableData
    },
    updateOrder() {
      const input = []
      let activeSet = null
      let activeSetName = ''
      let index = 0
      this.tableRows.forEach(item => {
        if (item.divider) {
          activeSet = item.set_id
          activeSetName = item.name
        } else {
          input.push({
            id: item.id,
            set_name: activeSetName,
            set_id: activeSet,
            order_index: index + 1,
          })
          index += 1
        }
      })
      useJwt.updateOrder({
        model: 'problem',
        input,
        lesson_id: this.$route.query.lesson_id,
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    // eslint-disable-next-line camelcase
    togglePublic(id, isPublic, sdpProblemId) {
      const args = {
        id, is_public: !isPublic ? 1 : 0, model: 'problem',
      }
      if (sdpProblemId) {
        args.model = 'sdp_problem'
        args.id = sdpProblemId
      }
      useJwt.changeVisibility(args)
        .then(res => {
          this.showSuccessMessage(res)
        })
        .finally(() => {
          const index = this.tableRows.findIndex(row => row.id === id)
          this.tableRows[index].is_public = !isPublic
        })
    },
    toggleDeleteModal(id) {
      this.$refs['modal-delete'].toggle()
      this.deleteItemId = id
      this.deleteMode = 'single'
    },
    toggleMultipleDelete() {
      this.$refs['modal-delete'].toggle()
      this.deleteMode = 'multiple'
    },
    deleteProblem() {
      if (this.deleteMode === 'single') this.deleteSingleProblem()
      else if (this.deleteMode === 'multiple') this.deleteMultipleProblem()
    },
    deleteSingleProblem() {
      this.isProcessing = true
      useJwt.deleteProblem(this.deleteItemId)
        .then(response => {
          this.$refs['modal-delete'].hide()
          this.showSuccessMessage(response)
          this.$emit('deleted')
        })
        .catch(err => {
          if (err.response.data.errors === 'child_record_found') {
            this.$refs['modal-force-delete'].toggle()
          }
        })
        .finally(() => {
          this.deleteMode = ''
          this.isProcessing = false
        })
    },
    deleteMultipleProblem() {
      useJwt
        .deleteMultipleProblem({
          id: this.selected,
        })
        .then(response => {
          this.$refs['modal-delete'].hide()
          this.showSuccessMessage(response)
          this.$emit('deleted')
        })
        .finally(() => {
          this.deleteMode = ''
          this.selected = []
          this.isProcessing = false
        })
    },
    forceDeleteProblem() {
      this.isProcessing = true
      useJwt.forceDeleteProblem(this.deleteItemId)
        .then(response => {
          this.showSuccessMessage(response)
          this.$emit('deleted')
        })
        .catch(err => {
          this.showErrorMessage(err)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    updateDDF(id, value) {
      useJwt.updateProblemDDFSet({
        id,
        ddf_set: value,
      }).then(response => {
        this.showSuccessMessage(response)
      }).catch(err => {
        this.showErrorMessage(err)
      })
    },
    removeGameWrapper() {
      this.removingGameWrapper = true
      useJwt.destroyGameWrapperForProblem(this.problemIdOfGameToRemove).then(response => {
        this.problemIdOfGameToRemove = null
        this.showSuccessMessage(response)
        this.$emit('deleted') // refetch the problems
      }).catch(err => {
        this.showErrorMessage(err)
      }).finally(() => { this.removingGameWrapper = false })
    },
    editProblem(rowId, directEdit = false) {
      if (directEdit || !this.isSDPLesson) {
        this.$router.push({ path: `/${this.auth.usertype}/problem/edit`, query: { lesson_id: this.$route.query.lesson_id, problem_id: rowId } })
      } else {
        this.problemIdToEdit = rowId
      }
    },
    orderByName() {
      this.isReorderingProblem = true
      useJwt.sortProblemByName(this.$route.query.lesson_id, {
        random_order: this.reorderProblem === 'random' ? 1 : null,
      }).then(response => {
        this.showSuccessMessage(response)
        this.$emit('fetchProblem')
        this.reorderProblem = false
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isReorderingProblem = false
      })
    },
    toggleSelectedVisibility(action) {
      this.isProcessing = true
      useJwt.toggleAllVisibility(action, {
        ids: this.selected,
        model: 'problem',
      }).then(response => {
        this.showSuccessMessage(response)
        this.$emit('fetchProblem')
      }).finally(() => {
        this.isProcessing = false
      })
    },

  },
}
</script>
<style scoped lang="scss">
.handle{
cursor: grab;
}
tr{
  user-select: none;
}
.row-group {
  .actions {
    display: none;
  }
}
.row-group:hover {
  .actions {
    display: inline;
  }
}
</style>
