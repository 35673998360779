import Toast from '@/utils/toast'
import Echo from 'laravel-echo'
// eslint-disable-next-line import/no-cycle
import { getUserToken } from '@/auth/utils'

export default {
  namespaced: true,
  actions: {
    listen() {
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_WEBSOCKETS_KEY,
        forceTLS: false,
        wsHost: process.env.VUE_APP_WEBSOCKETS_HOST,
        wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
        disableStats: true,
        authEndpoint: process.env.VUE_APP_WEBSOCKETS_AUTH_ENDPOINT,
        auth: {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
            Accept: 'Application/json',
          },
        },
      })
      window.Echo.channel('deployment-channel').notification(() => {
        Toast.showAppUpdatedToast()
      })
    },
  },
}
