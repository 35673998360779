<template>
  <div>
    <div>
      <b-button variant="primary"
                class="mr-2"
                @click="showForm = true"
      >
        Create <feather-icon icon="PlusIcon" />
      </b-button>
      <b-button variant="outline-success"
                @click="state.showCalculator = true"
      >
        Calculator <feather-icon icon="PlusIcon" />
      </b-button>
    </div>
    <b-overlay :show="state.isProcessing"
               class="mt-2"
    >
      <b-card body-class="p-0">
        <b-table
          :items="state.formulaData"
          :fields="fields"
          class="mb-0"
          striped
          hover
          responsive
        >
          <template #cell(action)="data">
            <div>
              <b-button class="btn-icon"
                        variant="flat-success"
                        @click="itemToEdit = data.item"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button class="btn-icon"
                        variant="flat-danger"
                        @click="state.itemToDelete = data.item.id"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
    <Pagination :pagination="state.paginationData"
                :is-processing="state.isProcessing"
                @onPaginationChange="page => fetchFormulas(page) "
    />
    <CreateEdit v-if="showForm || !!itemToEdit"
                :edit-item="{...itemToEdit}"
                @close="showForm = false; itemToEdit = null"
                @feFetch="fetchFormulas"
    />
    <ConfirmDelete
      :show="!!state.itemToDelete"
      :sub-title="$t('questions.delete-answer')"
      title="Are you sure you want to delete"
      :on-delete="handleOnDelete"
      :is-processing-delete="state.isProcessingDelete"
      @close="state.itemToDelete = null"
    />
    <Calculator v-if="state.showCalculator"
                @close="state.showCalculator = false"
    />
  </div>
</template>
<script setup>
import {
  reactive, ref, onMounted, getCurrentInstance,
} from 'vue'
import {
  BButton, BOverlay, BTable, BCard,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import Pagination from '@/views/common/components/Pagination.vue'
import ConfirmDelete from '@/views/common/components/DeleteModal.vue'
import CreateEdit from './components/CreateEdit.vue'
import Calculator from './components/Calculator.vue'

const root = getCurrentInstance().proxy.$root
const showForm = ref(false)
const itemToEdit = ref(null)

const state = reactive({
  isProcessing: false,
  formulaData: [],
  paginationData: {},
  itemToDelete: null,
  isProcessingDelete: false,
  showCalculator: false,
})

const fields = [
  { key: 'id', label: 'ID' },
  { key: 'type', label: 'Type' },
  { key: 'action', label: 'Action' },
]

const fetchFormulas = (page = 1) => {
  state.isProcessing = true
  useJwt.getCefrFormula({
    params: {
      page,
    },
  }).then(response => {
    state.formulaData = response.data.data
    state.paginationData = {
      total: response.data.total,
      per_page: response.data.per_page,
      current_page: response.data.current_page,
    }
  }).finally(() => {
    state.isProcessing = false
  })
}

const handleOnDelete = () => {
  state.isProcessingDelete = true
  useJwt.deleteCefrFormula(state.itemToDelete).then(response => {
    root.showSuccessMessage(response)
    state.itemToDelete = null
    fetchFormulas()
  }).finally(() => {
    state.isProcessingDelete = false
  })
}

onMounted(() => {
  fetchFormulas()
})
</script>
