<template>
  <div>
    <b-card no-body>
      <b-table
        :fields="columns"
        :items="rows"
        striped
        show-empty
        :empty-text="$t('messages.no-data-available')"
      >

        <template #cell(class_room)="data">
          {{ }}
          <span :set="classroom = data.item.class_room && data.item.class_room ">
            <router-link
              v-if="classroom"
              target="_blank"
              :to="{ name: AUTH_USER().usertype + '-view-students', params: { id: classroom.id } }"
            >
              {{ classroom.name }}
            </router-link>
          </span>
        </template>
        <template #cell(action)="data">
          <b-button
            variant="outline-primary"
            class="btn-icon mr-2"
            @click="schedules = data.item.schedule"
          >
            {{ $t('reservation-moduel.view_schedules') }}
          </b-button>
          <b-button
            v-if="!data.item.classroom_id"
            variant="outline-primary"
            class="btn-icon mr-2"
            @click="$emit('pickClassRoom', data.item)"
          >
            {{ $t('reservation-moduel.settle_now') }}
          </b-button>
          <b-button
            variant="outline-primary"
            class="btn-icon mr-2"
            @click="publicReservations = data.item"
          >
            <b-badge
              v-if="data.item.reservation_requests.length"
              variant="light-danger"
              class="badge-pill"
            >
              {{ data.item.reservation_requests.length }}
            </b-badge> {{ $t('reservation-moduel.view_requests') }}
          </b-button>
          <b-button
            variant="outline-danger"
            class="btn-icon"
            @click="$emit('handleDelete', data.item.id)"
          >
            <feather-icon
              icon="TrashIcon"
            />
          </b-button>
        </template>
      </b-table>
    </b-card>
    <b-modal
      v-if="schedules.length"
      :visible="true"
      hide-footer
      :title="$t('reservation-moduel.preferred_schedules')"
      @close="schedules = []"
      @hide="schedules = []"
    >
      <table class="table table-hover table-bordered">
        <thead>
          <tr>
            <th>{{ $t('labels.day') }}</th>
            <th>{{ $t('labels.time') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="schedule of schedules"
            :key="schedule.day"
          >
            <td>{{ schedule.day }}</td>
            <td
              v-if="schedule.time"
              class="text-nowrap"
            >
              {{ schedule.time }}
            </td>
            <td
              v-else
              class="text-nowrap"
            >
              {{ schedule.start_time }} - {{ schedule.end_time }}
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>
    <b-modal
      v-if="publicReservations?.reservation_requests.length"
      :visible="true"
      hide-footer
      size="lg"
      :title="$t('reservation-moduel.reservation_users')"
      @close="publicReservations = null"
      @hide="publicReservations = null"
    >
      <table class="table table-hover table-bordered">
        <thead>
          <tr>
            <th>{{ $t('reservation-moduel.parent-email') }}</th>
            <th>{{ $t('reservation-moduel.student-email') }}</th>
            <th>{{ $t('reservation-moduel.classroom') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item of publicReservations.reservation_requests"
            :key="item.id"
          >
            <td>
              {{ item.user_data.parent_email }}
            </td>
            <td>
              {{ item.user_data.student_email }}
            </td>
            <td>
              {{ publicReservations.class_room?.name }}
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BTable, BCard, BModal, BBadge,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    BButton,
    BTable,
    BCard,
    BModal,
    BBadge,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedReservation: null,
      schedules: [],
      selectedDays: null,
      columns: [
        {
          key: 'room_name',
          label: i18n.tc('room-name'),
        },
        ...(this.status === 'accepted' ? [
          {
            key: 'class_room',
            label: i18n.tc('labels.classroom'),
          },
        ] : []),
        // {
        //   key: 'schedule',
        //   label: 'Preferred Schedules',
        // },
        {
          key: 'action',
          label: i18n.tc('marketplace-module.action'),
        },
      ],
      selectedTime: null,
      publicReservations: null,
    }
  },
  watch: {
    selectedDays() {
      if (this.selectedDays && this.selectedTime) {
        this.$emit('filterReservation', { day: this.selectedDays, time: this.selectedTime })
      }
    },
    selectedTime() {
      if (this.selectedDays && this.selectedTime) {
        this.$emit('filterReservation', { day: this.selectedDays, time: this.selectedTime })
      }
    },
  },
  methods: {
    selectAll() {
      this.selectedRequests = this.rows.map(item => item.id)
    },
    getProfileLink(user) {
      const fullName = `${user.firstname} ${user.lastname}`
      return `${fullName.replaceAll('_', '-').replaceAll(' ', '-')}_${user.id}`
    },
  },
}
</script>
