<template>
  <div class="card">
    <div class="card-header">
      <h3>
        {{ schedule.name }}
        <div>
          <small class="font-weight-bold"> {{ schedule.students?.length || 0 }}/{{ schedule.limit }} Students</small>
        </div>
      </h3>
      <b-button variant="outline-primary"
                @click="$emit('applySchedule')"
      >
        Reserve
      </b-button>
    </div>
    <div class="card-body">
      <table class="table table-hover table-sm">
        <thead>
          <tr>
            <th>Day</th>
            <th>Start Time</th>
            <th>End Time</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(time, timeIndex) in schedule.schedule_times"
              :key="timeIndex"
          >
            <td>{{ time.day }}</td>
            <td>{{ FORMAT_DATE('2023-09-24 ' + time.start_time, 'h:mm A') }}</td>
            <td>{{ FORMAT_DATE('2023-09-24 ' + time.end_time, 'h:mm A') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import {
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    schedule: {
      type: Object,
      required: true,
    },
  },
}
</script>
