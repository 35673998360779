<template>
  <div class="d-flex align-items-center">
    <b-form-input
      v-model="xValue"
      type="number"
      placeholder="Enter score"
      class="mr-2"
    />
    <span class="mx-2">=</span>
    <b-form-input
      :value="result"
      readonly
      class="mr-2"
      :class="result == 'Error' ? 'bg-danger text-white' : ''"
      style="width: 100px;"
    />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { BFormInput } from 'bootstrap-vue'

const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  formula: String,
})

const xValue = ref('')
const result = ref('')
const hasError = ref(false)

const evaluateExpression = () => {
  hasError.value = false
  if (xValue.value && props.formula) {
    try {
      // Replace {x} with the entered x value in the formula
      const expression = props.formula.replace(/{x}/g, xValue.value)
      // Use eval to compute the result
      // eslint-disable-next-line no-eval
      result.value = eval(expression)
    } catch (error) {
      result.value = 'Error'
      hasError.value = true
    }
  } else {
    result.value = ''
  }
}

// Watch for changes in xValue to update result dynamically
watch(xValue, evaluateExpression)
watch(props, evaluateExpression)
</script>

<style scoped>
  .mr-2 {
    margin-right: 0.5rem;
  }
  .mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
</style>
