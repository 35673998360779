<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">{{ $t('Search') }}</label>
          <b-form-input
            v-model="searchTerm"
            :placeholder="$t('Search')"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>
        <span v-else-if="props.column.field === 'classroom'">
          {{ props.row.class?.name || 'DELETED' }}
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span class="text-center">
            <b-button
              v-b-tooltip.hover.top="$t('calendar-template.show-events')"
              variant="flat-primary"
              class="btn-icon rounded-circle"
              @click="$emit('showEvent', props.row.id)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              v-b-tooltip.hover.top="$t('calendar-template.apply-template')"
              variant="flat-success"
              class="btn-icon rounded-circle"
              @click="templateToApply = props.row.id"
            >
              <feather-icon icon="BoxIcon" />
            </b-button>
            <b-button
              v-b-tooltip.hover.top="props.row.is_public ? $t('calendar-template.separate-template') : $t('calendar-template.share-template')"
              variant="flat-primary"
              class="btn-icon rounded-circle"
              @click="templateToShare = props.row"
            >
              <feather-icon :icon="props.row.is_public ? 'ShieldOffIcon' : 'ShareIcon'" />
            </b-button>
            <b-button
              v-b-modal.isolate-template-confirm-modal
              v-b-tooltip.hover.top="$t('calendar-module.isolate-template')"
              variant="flat-warning"
              class="btn-icon rounded-circle"
              @click="templateToIsolate = props.row.id"
            >
              <feather-icon icon="PieChartIcon" />
            </b-button>
            <b-button
              v-b-tooltip.hover.top="$t('calendar-template.delete-template')"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="templateToDelete = props.row.id"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              :disabled="isDeleting"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <delete-modal
      :show="!!templateToDelete"
      :is-processing-delete="isDeleting"
      :on-delete="handleDelete"
      @close="templateToDelete = null"
    />
    <delete-modal
      :show="!!templateToShare"
      :title="$t('calendar-template.share-title')"
      :sub-title="$t('calendar-template.share-sub-title')"
      varient="primary"
      :is-processing-delete="isDeleting"
      :on-delete="handleShare"
      @close="templateToShare = null"
    />
    <apply-template :template-to-apply="templateToApply"
                    @update-event="$emit('update-event'); templateToApply = null"
                    @close="templateToApply = null"
    />
    <confirm-modal
      modal="isolate-template-confirm-modal"
      :title="$t('calendar-module.isolate-template')"
      @on-confirm="isolateTemplate"
    >
      <p>{{ $t('calendar-module.isolate-template') }}</p>
    </confirm-modal>
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BButton, VBTooltip,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import { VueGoodTable } from 'vue-good-table'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import useJwt from '@/auth/jwt/useJwt'
import ConfirmModal from '@/views/common/components/ConfirmModal.vue'
import GEC_EVENT_BUS from '@/utils/eventBus'
import ApplyTemplate from './ApplyTemplate.vue'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    ApplyTemplate,
    DeleteModal,
    ConfirmModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    templateList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: i18n.tc('labels.name'),
          field: 'name',
        },
        {
          label: i18n.tc('labels.classroom'),
          field: 'classroom',
        },
        {
          label: i18n.tc('class-module.start-date'),
          field: 'start_date',
        },
        {
          label: i18n.tc('class-module.end-date'),
          field: 'end_date',
        },
        {
          label: i18n.tc('action'),
          field: 'action',
        },
      ],
      templateToDelete: null,
      templateToShare: null,
      isDeleting: false,
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      templateToApply: null,
      dateToApply: null,
      classRoomsList: [],
      selectedClassroom: this.$route.params.classId,
      templateToIsolate: null,
    }
  },
  computed: {
    rows() {
      return this.templateList
    },
  },
  watch: {
    templateToApply(value) {
      if (value && !this.classRoomsList.length) {
        this.getClassrooms()
      }
    },
  },
  methods: {
    handleShare() {
      this.isDeleting = true
      useJwt.toggleCalendarTemplateVisibility(this.templateToShare?.id).then(response => {
        this.showSuccessMessage(response)
        this.templateToShare = null
        this.$emit('refetch')
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isDeleting = false
      })
    },
    handleDelete() {
      this.isDeleting = true
      useJwt.deleteCalendarTemplate(this.templateToDelete).then(response => {
        this.showSuccessMessage(response)
        this.templateToDelete = null
        this.$emit('refetch')
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isDeleting = false
      })
    },
    handleTemplateApply() {
      if (!this.dateToApply) {
        this.showErrorMessage('No date selected')
        return
      }
      this.isDeleting = true
      useJwt.applyCalendarTemplate(this.templateToApply, {
        apply_date: this.dateToApply,
        selected_class_room: this.selectedClassroom,
      }).then(response => {
        this.showSuccessMessage(response)
        this.templateToApply = null
        this.$emit('update-event')
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isDeleting = false
      })
    },
    isolateTemplate() {
      useJwt.cloneCalendarTemplate(this.templateToIsolate).then(() => {
        this.showSuccess('Template Isolated')
        this.templateToIsolate = null
      }).finally(() => {
        this.$bvModal.hide('isolate-template-confirm-modal')
        GEC_EVENT_BUS.$emit('fetch-isolate-templates')
      })
    },
  },
}
</script>
