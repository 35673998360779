<template>
  <div>
    <validation-observer
      ref="editSubjectForm"
      v-slot="{ invalid }"
    >
      <b-form @submit.prevent="editGrade(invalid)">
        <button
          class="d-none"
          type="submit"
        />
        <b-row>
          <b-col md="12">
            <!-- Name -->
            <b-form-group
              :label="$t('labels.name')"
              label-for="name"
            >
              <validation-provider
                v-slot="{ errors }"
                name="name"
                rules="required"
                vid="name"
              >
                <b-form-input
                  id="name"
                  v-model="subject.name"
                  name="name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Name of your Subject"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- / Name -->

            <!-- Score -->
            <b-form-group
              :label="$t('labels.score')"
              label-for="score"
            >
              <validation-provider
                v-slot="{ errors }"
                name="score"
                rules="required|min_value:0|max_value:100"
                vid="score"
              >
                <b-form-input
                  id="score"
                  v-model="subject.score"
                  name="score"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Score"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- / Score -->
          </b-col>
        </b-row>
      </b-form>
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="tertiary"
            class="btn-icon sm-mt-2"
            @click="$emit('back')"
          >
            {{ $t('actions.cancel') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="invalid"
            @click="editGrade(invalid)"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('actions.edit-subject') }}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import useJwt from '@/auth/jwt/useJwt'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    editSubjectData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    subject: {
      id: null,
      name: null,
      grade_id: null,
      score: null,
    },
    isProcessing: false,
  }),
  created() {
    this.subject.grade_id = this.editSubjectData.grade_id
    this.subject.name = this.editSubjectData.name
    this.subject.id = this.editSubjectData.id
    this.subject.score = this.editSubjectData.score
  },
  methods: {
    editGrade(invalid) {
      if (!invalid) {
        this.isProcessing = true
        useJwt
          .updateSubject(this.subject.id, this.subject)
          .then(response => {
            this.subject = { name: null }
            this.$emit('edited', response.data.data)
            this.$emit('back')
            this.showSuccessMessage(response)
          })
          .catch(error => {
            if (error.response?.data?.errors) {
              this.$refs.editSubjectForm.setErrors(error.response.data.errors)
            } else this.showErrorMessage(error)
          })
          .finally(() => {
            this.isProcessing = false
          })
      }
    },
  },
}
</script>
