<template>
  <div>
    <b-row>
      <b-col cols="4">
        <b-form-group>
          <template #label>
            <h4>{{ $t('import-student-data') }}</h4>
          </template>
          <SyncProgress :key="state.laUserImportProgressKey"
                        :title="$t('import-student-csv')"
                        icon="FileTextIcon"
                        queue-key="la-user-import-progress"
                        @onSync="state.showImportStudent = true"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group>
          <template #label>
            <h4>{{ $t('import-student-schedule-data') }}</h4>
          </template>
          <SyncProgress :key="state.laUseScheduleImportProgressKey"
                        :title="$t('import-student-schedule')"
                        icon="FileTextIcon"
                        queue-key="la-user-import-schedule-progress"
                        @onSync="state.showImportSchedule = true"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group>
          <template #label>
            <h4>{{ $t('import-student-schedule-data') }}</h4>
          </template>
          <SyncProgress
            :title="$t('import-followup')"
            icon="FileTextIcon"
            queue-key="la-user-import-followup-progress"
            @onSync="state.showFollowUp = true"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <import-student-with-follow-up v-if="state.showImportStudent"
                                   @close="state.showImportStudent = false"
                                   @onSave="state.laUserImportProgressKey ++"
    />
    <import-student-schedule v-if="state.showImportSchedule"
                             @close="state.showImportSchedule = false"
                             @onSave="state.laUseScheduleImportProgressKey ++"
    />
    <ImportFollowUp v-if="state.showFollowUp"
                    @close="state.showFollowUp = false"
    />
    <LogViewer class="mt-2" />
  </div>
</template>
<script setup>
import { reactive } from 'vue'
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import ImportStudentWithFollowUp from './components/import-student.vue'
import ImportStudentSchedule from './components/import-student-schedule.vue'
import ImportFollowUp from './components/import-followup.vue'
import SyncProgress from '../image-library/components/SyncProgress.vue'
import LogViewer from './logs/index.vue'

const state = reactive({
  showImportStudent: false,
  showImportSchedule: false,
  showFollowUp: false,
  laUserImportProgressKey: 0,
  laUseScheduleImportProgressKey: 0,

})

</script>
<style scoped>
.card:hover {
  box-shadow: 0 12px 32px 0 rgba(28, 34, 112, 0.37);
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  cursor: pointer;
}
</style>
