<template>
  <!-- Bootstrap modal visibility is controlled via the is-visible prop -->
  <b-modal :visible="props.isVisible"
           title="Add Report"
           @hide="emitClose"
           @close="emitClose"
  >
    <!-- Form Inputs -->
    <b-form @submit.prevent="submitForm">
      <!-- Name -->
      <b-form-group label="Name"
                    label-for="report-name"
      >
        <b-form-input id="report-name"
                      v-model="form.name"
                      required
        />
      </b-form-group>

      <!-- Data Source (Dropdown) -->
      <b-form-group label="Datasource"
                    label-for="report-datasource"
      >
        <b-form-select id="report-datasource"
                       v-model="form.datasource"
                       :options="datasourceOptions"
                       required
        />
      </b-form-group>

      <!-- Attributes (Dropdown) -->
      <b-form-group label="Attributes"
                    label-for="report-attributes"
      >
        <b-form-select id="report-attributes"
                       v-model="form.attributes"
                       :options="attributeOptions"
                       required
        />
      </b-form-group>

      <!-- Duration -->
      <b-form-group label="Duration"
                    label-for="report-duration"
      >
        <b-form-input id="report-duration"
                      v-model="form.duration"
                      required
        />
      </b-form-group>

      <!-- Visualization Type (Dropdown) -->
      <b-form-group label="Visualization Type"
                    label-for="report-visualization"
      >
        <b-form-select id="report-visualization"
                       v-model="form.visualization_type"
                       :options="visualizationOptions"
                       required
        />
      </b-form-group>

      <!-- Filters (Dropdown) -->
      <b-form-group label="Filters"
                    label-for="report-filters"
      >
        <b-form-select id="report-filters"
                       v-model="form.filters"
                       :options="filterOptions"
                       required
        />
      </b-form-group>
    </b-form>

    <!-- Modal Footer -->
    <template #modal-footer>
      <b-button type="submit"
                variant="success"
                @click="submitForm"
      >
        Submit
      </b-button>
      <b-button variant="secondary"
                @click="emitClose"
      >
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script setup>
import { ref } from 'vue'
import {
  BModal, BForm, BFormGroup, BFormInput, BFormSelect, BButton,
} from 'bootstrap-vue'

const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(['close'])

const form = ref({
  name: '',
  datasource: null,
  attributes: null,
  duration: '',
  visualization_type: null,
  filters: null,
})

const resetForm = () => {
  form.value.name = ''
  form.value.datasource = null
  form.value.attributes = null
  form.value.duration = ''
  form.value.visualization_type = null
  form.value.filters = null
}

const emitClose = () => {
  emit('close')
  resetForm()
}

const submitForm = () => {
  // Logic to handle form submission
  console.log('Form submitted:', form.value)
  emitClose()
}

const datasourceOptions = ref([
  { value: null, text: 'Please select a datasource' },
  { value: 'academic_score_grades', text: 'Academic Scores/Grades' },
  { value: 'assignment_project_completion', text: 'Assignment/Project Completion' },
  { value: 'behavioral_metrics', text: 'Behavioral Metrics' },
  { value: 'improvement_over_time', text: 'Improvement Over Time' },
  { value: 'error_accuracy_rate', text: 'Error/Accuracy Rates' },
  { value: 'skill_based_metrics', text: 'Skill-Based Metrics' },
  { value: 'comprehensive_performance_indicators', text: 'Comprehensive Performance Indicators' },
  { value: 'performance_extra_curricular_activities', text: 'Performance In Extra-Curricular Activities' },
])

const attributeOptions = ref([
  { value: null, text: 'Please select an attribute' },
  { value: 'attr1', text: 'Attribute 1' },
  { value: 'attr2', text: 'Attribute 2' },
  { value: 'attr3', text: 'Attribute 3' },
])

const visualizationOptions = ref([
  { value: null, text: 'Please select a visualization type' },
  { value: 'bar', text: 'Bar Chart' },
  { value: 'line', text: 'Line Chart' },
  { value: 'pie', text: 'Pie Chart' },
])

const filterOptions = ref([
  { value: null, text: 'Please select a filter' },
  { value: 'filter1', text: 'Filter 1' },
  { value: 'filter2', text: 'Filter 2' },
  { value: 'filter3', text: 'Filter 3' },
])
</script>

  <style scoped>
  /* Add any custom styles here if needed */
  </style>
