<template>
  <div>
    <div v-if="activeImage">
      <image-upload
        ref="imageUploader"
        :style="{ height }"
        :url="activeImage.image"
        :always-show-delete="images && images.filter(img => img.image ).length > 1"
        @onSelectedFromLibrary="handlePickFromLibrary"
        @imageUploaded="
          (file, url) => {
            updateImage(file, url)
          }
        "
        @imageRemoved="handleImageRemoved"
      />
      <b-form-input
        v-if="!noCaption"
        v-model.lazy="activeImage.caption"
        class="mt-1"
        placeholder="Input Caption"
        size="small"
      />
    </div>
    <div class="pt-1">
      <span
        v-for="(img, index) of images"
        :key="index"
        role="button"
        class="mb-1"
        @click="activeIndex = index"
      >
        <b-img
          v-if="img.image"
          class="mb-1 mr-1"
          style="height: 40px; width: 40px;"
          :src="img.image"
        />
        <feather-icon
          v-else
          icon="ImageIcon"
          class="mb-1 mr-1"
          style="height: 40px; width: 40px;"
        />
        <!-- <feather-icon
          v-if="!img.image"
          icon="XIcon"
          class="text-danger image-trash"
          @click="handleImageRemoved"
        /> -->
      </span>
      <b-button
        v-if="images.length >= 1"
        size="sm"
        variant="outline-secondary"
        @click="addMore"
      >
        <feather-icon
          icon="PlusIcon"
        />
      </b-button>
    </div>
  </div>
</template>
<script>
import ImageUpload from '@views/common/components/CustomImageUpload.vue'
import { BFormInput, BButton, BImg } from 'bootstrap-vue'
import _ from 'lodash'

export default {
  components: {
    ImageUpload,
    BFormInput,
    BButton,
    BImg,
  },
  props: {
    selectedImages: {
      type: [Array, String],
      default: () => [],
    },
    noCaption: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '200px',
    },
    debounce: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      activeIndex: 0,
      images:
      [
        {
          image: '',
          caption: '',
        },
      ],
    }
  },
  computed: {
    activeImage() {
      return this.images[this.activeIndex]
    },
  },
  watch: {
    images: {
      // eslint-disable-next-line func-names
      handler: _.debounce(function () {
        this.$emit('updateData', JSON.stringify(this.images))
      }, 1500),
      deep: true,
    },
  },
  created() {
    this.images = this.tryMakingJson().length > 0 ? this.tryMakingJson() : this.images
  },
  methods: {
    handlePickFromLibrary(imgUrl, item) {
      const image = this.images[this.activeIndex]
      image.image = imgUrl
      image.caption = item.caption
      this.$forceUpdate()
    },
    tryMakingJson() {
      if (Array.isArray(this.selectedImages)) {
        return this.selectedImages
      }
      try {
        return JSON.parse(this.selectedImages)
      } catch {
        return [
          {
            image: this.selectedImages,
            caption: '',
          },
        ]
      }
    },
    addMore() {
      this.$refs.imageUploader.$refs.imageUploadButton.click()
      this.images.push({
        image: '',
      })
      this.$nextTick(() => {
        this.activeIndex = this.images.length - 1
      })
    },
    updateImage(file, url) {
      const image = this.images[this.activeIndex]
      image.image = url
    },
    handleImageRemoved() {
      if (this.images.length <= 1 || this.activeIndex === 0) {
        const image = this.images[this.activeIndex]
        image.image = null
      } else {
        this.images.splice(this.activeIndex, 1)
        this.$nextTick(() => {
          this.activeIndex -= 1
        })
      }
    },
  },
}
</script>
<style scoped>
  .image-trash {
    position: absolute;
    margin-left: -30px;
    margin-top: -45px;
    font-weight: bold;
  }
</style>
