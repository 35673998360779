<template>
  <validation-observer ref="formProvider">
    <b-row>
      <b-col>
        <b-card
          body-class="p-0"
        >
          <b-table-simple
            bordered
            sticky-header
            class="mb-0"
          >
            <b-thead>
              <b-tr>
                <b-th> Class </b-th>
                <b-th> Total Students </b-th>
                <b-th> Action </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="room of (hasAnyFilters ? filteredReservation : classRoomReservations)"
                :key="room.id"
              >
                <b-td>
                  {{ room.room_name }}
                </b-td>
                <b-td>
                  <h4>
                    {{ room.reservation_requests_count }}/{{ reservation.student_limit }} <small>Students</small>
                  </h4>
                </b-td>
                <b-td>
                  <b-button
                    variant="outline-success"
                    @click="showSchedules = room"
                  >
                    Reserve
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
      <b-col
        md="5"
      >
        <b-card>
          <h4>Schedules</h4>
          <b-row
            v-for="(schedule, index) of form.customSchedule"
            :key="index"
          >
            <b-col>
              <b-form-group :label="`Day ${index + 1}`">
                <validation-provider
                  v-slot="{ errors }"
                  :name="`Day ${index+1}`"
                  rules="required"
                >
                  <b-form-select
                    v-model="schedule.day"
                    :options="weekDays"
                    @input="time => handleTimeChange()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Time">
                <validation-provider
                  v-slot="{ errors }"
                  :name="`Time ${index + 1}`"
                  rules="required"
                >
                  <v-select
                    v-model="schedule.selected"
                    size="small"
                    label="text"
                    placeholder="Choose time"
                    :options="schedule.slots"
                    :clearable="false"
                    :reduce="(item) => item.value"
                    @input="time => handleTimeChange()"
                  >
                    <template #selected-option="{ text }">
                      <span class="text-nowrap">{{ text }}</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="text-right">
            <b-button
              variant="gradient-success"
              @click="handleFormWithCustom"
            >
              Submit
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      :visible="!!showSchedules"
      ok-title="Reserve"
      title="Reserve This Class"
      ok-variant="success"
      centered
      @ok="reserveClass"
      @close="showSchedules = null"
      @hide="showSchedules = null"
    >
      <table class="table table-hover table-striped">
        <thead>
          <tr>
            <th>Day</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody v-if="showSchedules">
          <tr
            v-for="schedule,index of showSchedules.schedule"
            :key="index"
          >
            <td>{{ schedule.day }}</td>
            <td>{{ schedule.time }}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </validation-observer>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BTableSimple,
  BThead,
  BTr,
  BTd,
  BTbody,
  BTh,
  BModal,
  BFormSelect,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { weeklySchedules } from '@/@core/utils/utils'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    vSelect,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BTbody,
    BTh,
    BModal,
    BFormSelect,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    reservation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      classRoomReservations: [],
      filteredReservation: [],
      showSchedules: null,
      weekDays: [
        {
          text: 'Choose Day',
          value: null,
        },
        {
          text: 'Sunday',
          value: 'Sunday',
        },
        {
          text: 'Monday',
          value: 'Monday',
        },
        {
          text: 'Tuesday',
          value: 'Tuesday',
        },
        {
          text: 'Wednesday',
          value: 'Wednesday',
        },
        {
          text: 'Thursday',
          value: 'Thursday',
        },
        {
          text: 'Friday',
          value: 'Friday',
        },
        {
          text: 'Saturday',
          value: 'Saturday',
        },
      ],
      required,
    }
  },
  computed: {
    hasAnyFilters() {
      return !!this.form?.customSchedule.find(item => !!item.day || !!item.selected)
    },
  },
  mounted() {
    this.form.customSchedule = [
      ...weeklySchedules('any', this.reservation.time_interval || 20, this.reservation.working_days),
    ]
    useJwt
      .getReservationPublicClassRoom(this.reservation.id)
      .then(response => {
        this.classRoomReservations = response.data.data.class_rooms
      })
      .catch(error => {
        this.showErrorMessage(error)
      })
  },
  methods: {
    handleFormWithCustom() {
      this.$refs.formProvider.validate().then(success => {
        if (success) {
          this.form.selected_class = null
          this.showSchedules = {
            id: null,
            schedule: this.form.customSchedule.map(item => ({ day: item.day, time: item.selected })),
          }
        }
      })
    },
    reserveClass() {
      this.form.selected_class = this.showSchedules.id
      this.$emit('submitForm')
    },
    handleTimeChange() {
      const selectedSchedule = this.form.customSchedule.filter(item => item.selected || item.day).map(item => ({ day: item.day, time: item.selected }))
      if (selectedSchedule.length) {
        const filtered = this.classRoomReservations.filter(item => {
          let canOccur = true
          selectedSchedule.forEach(schedule => {
            // canOccur = !!schedule.time || !!schedule.day
            if (canOccur && schedule.day) {
              canOccur = !!item.schedule.find(s => s.day === schedule.day)
            }
            if (canOccur && schedule.time && schedule.time !== 'any') {
              canOccur = !!item.schedule.find(s => s.time === schedule.time)
            }
          })
          return canOccur
        })
        this.filteredReservation = filtered
      } else {
        this.filteredReservation = []
      }
    },
  },
}
</script>
