/* eslint-disable import/no-cycle */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import { getUserData, isUserLoggedIn } from '@/auth/utils'
import { USER_TYPE_STUDENT } from '@/const/userType'

import studentRoutes from './groups/student'
import parentRoutes from './groups/parent'
import teacherRoutes from './groups/teacher'
import companyRoutes from './groups/company'
import schoolRoutes from './groups/school'
import superRoutes from './groups/super'
import districtRoutes from './groups/district'
import internationalRoutes from './groups/international'
import nationalRoutes from './groups/national'
import curriculumRoutes from './groups/curriculum'
import commonRoutes from './groups/common'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...commonRoutes,
    ...studentRoutes,
    ...parentRoutes,
    ...teacherRoutes,
    ...companyRoutes,
    ...schoolRoutes,
    ...superRoutes,
    ...districtRoutes,
    ...internationalRoutes,
    ...nationalRoutes,
    ...curriculumRoutes,
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(to => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    if (to.name === 'super-problem-create' || to.name === 'super-problem-edit') {
      appLoading.style.display = 'block'
    } else {
      appLoading.style.display = 'none'
    }
  }
})

router.beforeEach((to, from, next) => {
  // store.commit('app-chat/SET_CURRENT_ROUTE', to)
  if (isUserLoggedIn()) {
    if (getUserData().usertype === USER_TYPE_STUDENT) {
      if (localStorage.getItem('vuexy-skin') === 'dark') {
        store.commit('appConfig/UPDATE_SKIN', 'light')
      }
    } else {
      const skins = ['semi-light', 'semi-light-reverse', 'brown', 'green']
      const selectedSkin = localStorage.getItem('vuexy-skin')
      if (skins.includes(selectedSkin)) store.commit('appConfig/UPDATE_SKIN', 'light')
    }
  }
  window.PREVIOUS_ROUTE_PATH = window.location.pathname + window.location.search
  // page should go to top
  setTimeout(() => {
    window.scrollTo({ top: 0 })
  }, 1500)
  next()
})

// checks for next middleware
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

// checks for middlewares
router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]
    const context = {
      from,
      next,
      router,
      to,
    }
    const nextMiddleware = nextFactory(context, middleware, 1)
    return middleware[0]({ ...context, next: nextMiddleware })
  }
  return next()
})

export default router
