<template>
  <div>
    <div class="row pb-2">
      <div class="d-flex justify-content-end col-lg-6">
        <b-form-datepicker
          v-model="start_date"
          :max="end_date"
          :date-format-options="{
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            weekday: 'short',
          }"
          @input="getReport"
        />
        <b-form-datepicker
          v-model="end_date"
          class="ml-2"
          :min="start_date"
          :date-format-options="{
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            weekday: 'short',
          }"
          @input="getReport"
        />
      </div>
    </div>
    <b-card no-body>
      <b-card-body class="p-0">
        <b-overlay>
          <b-table-simple class="table-hover table-striped table-bordered">
            <b-thead>
              <b-tr>
                <b-th>{{ $t('teacher-schedule.teacher') }}</b-th>
                <b-th>{{ $t('class-module.start-time') }}</b-th>
                <b-th>{{ $t('class-module.end-time') }}</b-th>
                <b-th>{{ $t('setting-module.hours') }}</b-th>
                <!-- <b-th>Classroom</b-th> -->
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-for="(group, date) of schedules">
                <b-tr :key="date">
                  <b-td
                    colspan="5"
                    class="bg-secondary text-white text-bold"
                  >
                    {{ formatDate(date, "MMM Do YYYY") }}
                  </b-td>
                </b-tr>
                <template v-for="(teacherGroup, tid) of group">
                  <b-tr
                    v-for="(value,index) of teacherGroup"
                    :key="`${index}_${tid}_${date}`"
                  >
                    <b-td
                      v-if="index === 0"
                      :rowspan="teacherGroup.length"
                    >
                      <b-button
                        variant="default"
                        :style="{background: value.classroom ? scheduledWithClassRoomColor : scheduledWithOutClassRoomColor}"
                        size="sm"
                        class="btn-icon rounded-circle"
                      />
                      {{ getTeacherName(value) }}
                    </b-td>
                    <b-td>{{ formatDate(value.day + ' ' + value.start_time," h:mm A") }}</b-td>
                    <b-td>{{ formatDate(value.day + ' ' + value.end_time," h:mm A") }}</b-td>
                    <b-td>{{ getHours({
                      start: value.day + ' ' + value.start_time,
                      end: value.day + ' ' + value.end_time,
                    }) }}</b-td>
                    <!-- <b-td>{{ value.classroom ? value.classroom.name : 'N/A' }}</b-td> -->
                  </b-tr>
                </template>
              </template>
            </b-tbody>
            <b-tfoot v-if="!Object.entries(schedules).length">
              <b-tr>
                <b-td class="p-2">
                  {{ $t('teacher-schedule.no-schedule') }}
                </b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </b-overlay></b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BTableSimple, BTr, BTd, BTh, BCard, BCardBody, BThead, BTbody, BFormDatepicker, BOverlay, BButton, BTfoot,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

const moment = require('moment')

export default {
  components: {
    BTableSimple,
    BTr,
    BTd,
    BTh,
    BCard,
    BCardBody,
    BThead,
    BTbody,
    BFormDatepicker,
    BOverlay,
    BButton,
    BTfoot,
  },
  props: {
    teacherId: {
      type: [Object, Number, String],
      default: null,
    },
    scheduledWithOutClassRoomColor: {
      type: String,
      default: '',
    },
    scheduledWithClassRoomColor: {
      type: String,
      default: '',
    },
    classRoomId: {
      type: [Object, String, Number],
      default: null,
    },
  },
  data() {
    return {
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().endOf('month').format('YYYY-MM-DD'),
      schedules: {},
    }
  },
  watch: {
    teacherId() {
      this.getReport()
    },
    classRoomId() {
      this.getReport()
    },
  },
  mounted() {
    this.getReport()
  },
  methods: {
    getReport() {
      const { start_date, end_date } = this
      useJwt.getTeacherSchedules({
        params: {
          end_date,
          start_date,
          teacher_id: this.teacherId,
          view_type: 'summary',
          classroom: this.classRoomId,
        },
      }).then(response => {
        this.schedules = response.data.data
      })
    },
    formatDate(date, format) {
      return moment(date).format(format)
    },
    getTeacherInRow(items) {
      const teachers = [...new Set(items.map(item => item.teacher.id))]
      return teachers
    },
    getTeacherName(value) {
      return value.schedular
        ? (`${value.schedular.teacher.firstname}${value.schedular.teacher.lastname}`) : ''
    },
    getHours({ start, end }) {
      const admission = moment(start)
      const discharge = moment(end)
      return this.CONVERT_HM(discharge.diff(admission, 'seconds'))
    },
  },
}
</script>
