<template>
  <div id="report-card">
    <b-card>
      <b-row>
        <b-col cols="8">
          <h4>{{ $t('student-report-module.report-pages.overall-note-report') }}</h4>
          <vue-apex-charts
            :key="series.length"
            type="bar"
            height="350"
            :options="chartOptions"
            :series="series"
          />
        </b-col>
        <b-col cols="4">
          <b-row>
            <b-col cols="6">
              <h4>
                {{ $t('student-report-module.summary') }}
              </h4>
            </b-col>
            <b-col cols="6">
              <b-button
                variant="primary"
                data-html2canvas-ignore="true"
                @click="exportPdf()"
              >
                {{ $t('student-report-module.report-pages.export-pdf') }}
                <feather-icon icon="FileTextIcon" />
              </b-button>
            </b-col>
          </b-row>
          <strength-table
            class="mt-2"
            :title="$t('student-report-module.report-pages.skill')"
            :summary="getMinMax"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h4>
        {{ $t('student-report-module.report-pages.time-line-of-note-report') }}
      </h4>
      <b-table-simple>
        <b-thead>
          <b-tr>
            <b-th>
              {{ $t('student-report-module.report-pages.student-name') }}
            </b-th>
            <b-th
              v-for="month of months"
              :key="month.day"
            >
              {{ month.month }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(student, index) of studentGropedNoteReport"
            :key="index"
          >
            <b-td>
              {{ student[0].fullName }} <b>({{ student.length }})</b>
            </b-td>
            <b-td
              v-for="month of months"
              :key="month.day"
              :set="(reports = student.filter((s) => filterByMonth(s, month)))"
            >
              {{ reports.length }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'
import {
  BTableSimple, BTh, BTr, BThead, BTbody, BCard, BTd, BRow, BCol, BButton,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import VueApexCharts from 'vue-apexcharts'
import StrengthTable from './StrengthTable.vue'
import { NoteReportCalc } from './helper/reportHelper'

export default {
  components: {
    BTableSimple, BTh, BTr, BThead, BTbody, BCard, BTd, VueApexCharts, StrengthTable, BRow, BCol, BButton,
  },
  props: {
    classRoom: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      overAllNoteReport: [],
      studentGropedNoteReport: {},
      months: [],
      series: [],
      categories: [],
    }
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          height: 150,
          type: 'bar',
        },
        xaxis: {
          categories: this.categories,
          position: 'top',
        },
        dataLabels: {
          formatter(val) {
            return `${val}%`
          },
        },
        yaxis: {
          min: 0,
          max: 100,
          labels: {
            formatter(val) {
              return `${val}%`
            },
          },
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            dataLabels: {
              position: 'bottom',
            },
          },
        },
      }
    },
    getMinMax() {
      const { series } = this
      const reports = []
      series.forEach(score => {
        const maxScore = Math.max(...score.data.map(s => Number(s)))
        const minScore = Math.min(...score.data.map(s => Number(s)))
        const weakest = []
        const strongest = []
        this.categories.forEach((cat, index) => {
          const value = Number(score.data?.[index])
          if (value === maxScore) {
            strongest.push({
              fullName: cat,
              score,
            })
          } else if (value === minScore) {
            weakest.push({
              fullName: cat,
              score,
            })
          }
        })
        reports.push({
          title: i18n.tc(`student-report-module.report-pages.${score.title}`),
          weakest,
          strongest,
        })
      })
      return reports
    },
  },
  created() {
    this.getReport()
  },
  methods: {
    filterByMonth(s, item) {
      return moment(s.day).format('MM') === moment(item.day).format('MM')
    },
    getReport() {
      useJwt.getGroupNoteReport(this.classRoom.id).then(response => {
        const noteReport = NoteReportCalc({ noteReportData: response.data.data.report }, this)
        this.studentGropedNoteReport = noteReport.studentGropedNoteReport
        this.overAllNoteReport = noteReport.noteReport
        this.months = this.ARRAY_UNIQUE_BY_KEY(noteReport.months, 'month')
        this.categories = noteReport.categories
        this.series = [
          {
            name: 'Note Score',
            title: 'note-score',
            data: noteReport.seriesData,
          },
        ]

        // const noteReport = response.data.data.report.map(report => ({
        //   day: moment(report.created_at).format('YYYY-MM-DD'),
        //   fullName: `${report.student?.firstname} ${report.student?.lastname}`,
        //   studentId: report.student_id,
        //   id: report.id,
        // }))
        // this.studentGropedNoteReport = this.groupByKey(noteReport, 'studentId')
        // this.overAllNoteReport = noteReport
        // const months = []
        // noteReport.forEach(note => {
        //   if (!months.find(month => month.day === note.day)) {
        //     months.push({
        //       day: note.day,
        //       displayName: moment(note.day).format('MMM Do'),
        //     })
        //   }
        // })
        // this.months = months
        // this.getStudentComparative()
      })
    },
    getStudentComparative() {
      // const maxScore = Math.max(
      //   ...Object.values(this.studentGropedNoteReport).map(o => o.length),
      // )
      // const categories = []
      // const seriesData = []
      // Object.values(this.studentGropedNoteReport).forEach(report => {
      //   categories.push(report[0].fullName)
      //   const score = (report.length / maxScore) * 100
      //   seriesData.push(score.toFixed(2))
      // })
      // seriesData.sort((a, b) => b - a)
      // this.categories = categories
      // this.series = [
      //   {
      //     name: 'Note Score',
      //     data: seriesData,
      //   },
      // ]
    },
    exportPdf() {
      const element = document.getElementById('report-card')
      this.printPdf(element, 'note-report.pdf')
    },
  },
}
</script>
