<template>
  <b-card v-if="data!=null">
    <b-modal
      id="modal-update-profile"
      ref="modal-update-profile"
      ok-only
      hide-footer="false"
      size="lg"
      ok-variant="primary"
      :ok-title="$t('confirm')"
      modal-class="modal-primary"
      centered
      :title="$t('actions.update-profile')"
      @ok="updateProfile"
    >
      <b-col md="12">
        <b-col md="12">
          <!-- form -->
          <validation-observer
            ref="updateProfileForm"
          >
            <b-form
              class="update-profile-form"
              @submit="updateProfile"
            >
              <input
                type="submit"
                class="d-none"
              >
              <b-row>
                <b-col
                  md="12"
                >
                  <b-form-group
                    :label="$t('name')"
                    label-for="name"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Name"
                      rules="required|min:3"
                      vid="name"
                    >
                      <b-form-input
                        id="item_name"
                        v-model="data.name"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    :label="$t('description')"
                    label-for="description"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Description"
                      rules="required"
                      vid="description"
                    >
                      <b-form-textarea
                        id="item_name"
                        v-model="data.description"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    :label="$t('address')"
                    label-for="address"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Address"
                      rules="required"
                      vid="address"
                    >
                      <b-form-input
                        id="item_name"
                        v-model="data.address"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    :label="$t('phone')"
                    label-for="phone"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Phone"
                      rules="required|numeric|max:14"
                      vid="phone"
                    >
                      <b-form-input
                        id="item_name"
                        v-model="data.phone"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <b-row class="py-2">
            <b-col
              md="12"
              class="d-flex justify-content-end"
            > <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              :disabled="isProcessing"
              @click="updateProfile()"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t('actions.update-profile') }}
            </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-col>
    </b-modal>
    <!-- about -->
    <b-row
      class="d-flex align-items-center d-flex justify-content-between"
    >

      <b-col>
        <h5 class="text-capitalize mb-75">
          {{ $t('details') }}
        </h5>
      </b-col>

      <b-dropdown
        v-if="ownProfile"
        variant="link"
        no-caret
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>
        <b-dropdown-item v-b-modal.modal-update-profile>
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">{{ $t("edit") }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-row>

    <b-row>
      <b-col
        md="12"
        sm="12"
        class="mt-1"
      >
        <h5>
          {{ $t('name') }}
        </h5>
        <b-card-text>
          {{ profile.name }}
        </b-card-text>
      </b-col>
      <b-col
        md="12"
        class="mt-2"
      >
        <h5>
          {{ $t('description') }}
        </h5>
        <b-card-text>
          {{ profile.description }}
        </b-card-text>
        <h5>
          {{ $t('address') }}
        </h5>
        <b-card-text>
          {{ profile.address }}
        </b-card-text>
        <h5>
          {{ $t('phone') }}
        </h5>
        <b-card-text>
          {{ profile.phone }}
        </b-card-text>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BButton, BForm, BCol, BRow, BFormTextarea, BDropdown, BDropdownItem, BFormGroup, BSpinner, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import useJwt from '@/auth/jwt/useJwt'
import { ref } from 'vue'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

export default {
  components: {
    BCard,
    BFormGroup,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    BDropdown,
    BDropdownItem,
    BCol,
    BFormTextarea,
    BFormInput,
    BRow,
    BCardText,
    BButton,
    BForm,
    // BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    ownProfile: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)
    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  data() {
    return {
      isProcessing: false,
      profile: null,
      data: null,
    }
  },
  mounted() {
    this.getProfile()
  },
  methods: {
    getProfile() {
      useJwt.getProfile().then(response => {
        this.profile = response.data.data
        this.data = JSON.parse(JSON.stringify(response.data.data))
      })
    },

    resetLogo() {

    },

    uploadLogo() {
      this.isProcessing = true
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
      const data = new FormData()
      data.append('logo', this.$refs.refInputEl1.files[0])

      useJwt.axiosIns.post('/api/v1/profile/logo/update', data, config)
        .then(response => {
          this.profile = response.data.data
          this.data = JSON.parse(JSON.stringify(this.profile))
          this.showSuccessMessage(response)
        }).finally(() => {
          this.isProcessing = false
        })
    },

    updateProfile(e) {
      if (e) e.preventDefault()
      this.$refs.updateProfileForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt.updateProfile(this.profile.id, {
            description: this.data.description,
            name: this.data.name,
            address: this.data.address,
            phone: this.data.phone,
          })
            .then(response => {
              this.profile = response.data.data
              this.$refs['modal-update-profile'].hide()
              this.showSuccessMessage(response)
            })
            .catch(error => {
              if (error.response?.data?.errors) this.$refs.updateProfileForm.setErrors(error.response.data.errors)
              else this.showErrorMessage(error)
            })
            .finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
  },
}
</script>
