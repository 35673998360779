<template>
  <div
    :style="`position:${position};top:${top};left:${left}; width:${width}; height:${height};`"
    @mouseover="visibleEdit=true"
    @mouseleave="visibleEdit=false"
  >
    <canvas
      :id="canvasId"
      :class="changeAnimationEnabled && 'cursor-pointer'"
      @click="$emit('onClick')"
    />
    <button
      v-if="visibleEdit && changeAnimationEnabled && !isProcessing && notMarketplaceRoute"
      role="button"
      class="btn"
      style="position: absolute; right: 15%; top:0%; color:#e3de15"
      @click="$emit('changeModel',index)"
    >
      <feather-icon
        icon="EditIcon"
      />
    </button>
  </div>
</template>
<script type="model">
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import {
  FOREST_CHARACTERS_ALL_OUTFITS, ALL_FOREST_CHARACTERS,
  // DEFAULT_COWBOY_OUTFIT, DEFAULT_OCEAN_OUTFIT, DEFAULT_SPACE_OUTFIT,
} from '@/const/theme'
import { gsap } from 'gsap'

// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

export default {
  props: {
    animationFile: {
      type: String,
      default: '',
    },
    index: {
      type: [Number, String],
      default: 0,
    },
    activeAnimation: {
      type: [Array, Object, String],
      default: () => ['idle'],
    },
    animationTypes: {
      type: [Object, Array],
      default: () => {},
    },
    position: {
      type: String,
      default: '',
    },
    top: {
      type: [String, Number],
      default: 0,
    },
    left: {
      type: [String, Number],
      default: 0,
    },
    canvasId: {
      type: String,
      default: 'webgl',
    },
    cameraPosition: {
      type: Object,
      default: () => {},
    },
    scaling: {
      type: [Object, Array],
      default: () => {},
    },
    lightIntensity: {
      type: [String, Number],
      default: 2,
    },
    width: {
      type: [String, Number],
      default: window.innerWidth,
    },
    height: {
      type: [String, Number],
      default: '200',
    },
    canvasWidth: {
      type: [String, Number],
      default: window.innerWidth,
    },
    canvasHeight: {
      type: [String, Number],
      default: window.innerHeight,
    },
    changeAnimationEnabled: {
      type: [String, Boolean],
      default: false,
    },
    background: {
      type: [String, Boolean],
      default: 'transparent',
    },
    rotation: {
      type: [String, Boolean],
      default: false,
    },

    playAllAnimation: {
      type: [String, Boolean],
      default: false,
    },
    rotationAngle: {
      type: [String, Number],
      default: 0,
    },
    characterType: {
      type: [String, Number],
      default: 0,
    },
    selectedOutfits: {
      type: [Array, Object],
      default: () => {},
    },
    characterName: {
      type: [String],
      default: 'elephant',
    },
    activeIndex: {
      type: [Number, String],
      default: 0,
    },
    // currentTheme: {
    //   type: String,
    //   default: () => {},
    // },
  },
  data() {
    return {
      canvas: null,
      scene: null,
      mixer: null,
      loader: null,
      dracoLoader: null,
      light: null,
      clock: new THREE.Clock(),
      renderer: null,
      spotLight: null,
      animations: null,
      visibleEdit: false,
      root: null,
      isProcessing: false,
      allOutfits: FOREST_CHARACTERS_ALL_OUTFITS,
      allAnimationCharacters: ALL_FOREST_CHARACTERS,
      currentActiveIndex: 0,
      // selectedOutfits: {},
    }
  },
  computed: {
    notMarketplaceRoute() {
      return this.$route.name !== 'animated-items-marketplace'
    },
  },
  watch: {
    activeAnimation() {
      if (this.activeAnimation && this.activeAnimation.length > 0) {
        this.playAnimation()
      }
    },
    animationFile() {
      this.clearSceneAndInit()
    },
    // eslint-disable-next-line func-names
    // '$store.state.appConfig.selectedOutfits': {
    //   handler() {
    //     this.initialAnimationModal()
    //   },
    //   deep: true,
    // },
    selectedOutfits() {
      this.filterOutfits()
      if (this.scene) {
        this.scene.remove(this.root)
      }
      this.setMaterial(true)
      // this.clearSceneAndInit()
    },
    rotationAngle() {
      this.updateRotation()
    },

    canvasHeight() {
      this.renderer.setSize(this.canvasWidth, this.canvasHeight)
    },

    canvasWidth() {
      this.renderer.setSize(this.canvasWidth, this.canvasHeight)
    },
  },
  mounted() {
    this.initialAnimationModal()
  },
  methods: {
    initialAnimationModal() {
      if (this.scene) {
        this.scene.clear()
      }
      this.canvas = document.getElementById(this.canvasId)
      this.spotLight = new THREE.SpotLight(0xffa95c, this.lightIntensity)
      this.clearSceneAndInit()
      this.filterOutfits()
      this.currentActiveIndex = this.activeIndex
    },
    filterOutfits() {
      // const checkTheme = ['cowboy', 'ocean_1', 'space']
      // const theme = this.$store.state.appConfig.selectedOutfits.themes
      // this.allOutfits = FOREST_CHARACTERS_ALL_OUTFITS()
      // if (theme) {
      //   if (checkTheme.includes(this.currentTheme)) {
      //     this.selectedOutfits = Object.values(theme[this.currentTheme][this.characterName])
      //   }
      // } else {
      //   if (this.currentTheme === 'cowboy') {
      //     this.selectedOutfits = Object.values(DEFAULT_COWBOY_OUTFIT)
      //   }
      //   if (this.currentTheme === 'ocean_1') {
      //     this.selectedOutfits = Object.values(DEFAULT_OCEAN_OUTFIT)
      //   }
      //   if (this.currentTheme === 'space') {
      //     this.selectedOutfits = Object.values(DEFAULT_SPACE_OUTFIT)
      //   }
      // }
      this.allOutfits = FOREST_CHARACTERS_ALL_OUTFITS()
      if (this.selectedOutfits && this.selectedOutfits.length > 0) {
        this.selectedOutfits.forEach(outfit => {
          this.allOutfits = [...this.allOutfits.filter(e => e !== outfit)]
        })
      }
    },
    async clearSceneAndInit() {
      if (this.scene) this.scene.clear()
      this.scene = null
      this.$nextTick(async () => {
        await this.init()
        this.animate()
      })
    },

    unselectedOutfits(name) {
      const filterName = name.replace(`${this.characterName}`, '')
      return this.allOutfits.includes(filterName)
    },
    init() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async resolve => {
        if (this.scene) this.scene.clear()
        this.scene = new THREE.Scene()
        this.light = new THREE.HemisphereLight(0xffeeb1, 0x080820, this.lightIntensity)
        this.scene.add(this.light)

        this.spotLight.castShadow = true
        this.spotLight.shadow.bias = -0.0001
        this.spotLight.shadow.mapSize.width = 1024 * 4
        this.scene.add(this.spotLight)

        const point = new THREE.PointLight(0xffffff, 1, 200)
        point.position.set(1, 1, 1)
        this.scene.add(point)

        this.renderer = new THREE.WebGL1Renderer({
          canvas: this.canvas,
        })

        this.renderer.setSize(this.canvasWidth, this.canvasHeight)
        this.renderer.setPixelRatio(window.devicePixelRatio)
        this.renderer.setClearColor(0x000000, 0)
        if (this.background === 'dark') {
        // const backgroundLoader = new THREE.TextureLoader()
        // backgroundLoader.load('/animations/forest/skie.png', texture => {
        //   this.scene.background = texture
        // })
        }
        this.renderer.toneMapping = THREE.ReinhardToneMapping
        this.renderer.toneMappingExposure = 2.5
        this.renderer.shadowMap.enabled = true
        this.renderer.gameOutput = true
        this.loader = new GLTFLoader()
        this.dracoLoader = new DRACOLoader()
        this.dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/') // use a full url path
        this.loader.setDRACOLoader(this.dracoLoader)
        this.camera = new THREE.PerspectiveCamera(75, this.canvasWidth / this.canvasHeight, 1, 10)
        this.camera.zoom = 1

        this.root = null
        await this.loader.load(this.animationFile, glb => {
          this.animations = glb?.animations
          this.root = glb.scene
          const camera = glb?.cameras['0']

          if (camera) {
            const posX = camera.position.x
            const posY = camera.position.y
            const posZ = camera.position.z
            this.camera.fov = camera.fov
            this.camera.near = camera.near
            this.camera.far = camera.far
            this.camera.aspect = camera.aspect
            this.camera.updateProjectionMatrix()
            this.camera.position.set(posX, posY, posZ)
          } else {
            this.camera.position.set(this.cameraPosition.x, this.cameraPosition.y, this.cameraPosition.z)
          }
          this.scene.add(this.camera)
          this.updateRotation()
          this.scene.fog = new THREE.Fog(0xDFE9F3, 1, 1000)

          // const model = root.children[0]
          // model.position.set(0, -5, -25)
          this.setMaterial()

          this.root.scale.set(this.scaling.x, this.scaling.y, this.scaling.z)
          this.scene.add(this.root)
          this.mixer = new THREE.AnimationMixer(this.root)
          this.playAnimation()
        })
        resolve(true)
      })
    },

    setMaterial(addRoot = false) {
      if (this.root) {
        this.root.traverse(o => {
          if (this.unselectedOutfits(o.name.toLowerCase())) {
          // eslint-disable-next-line no-param-reassign
            o.visible = false
          } else {
          // eslint-disable-next-line no-param-reassign
            o.visible = true
          }
        })
      }

      if (addRoot && this.scene && this.root) this.scene.add(this.root)
    },

    async changeCamera() {
      if (this.changeAnimationEnabled && !this.isProcessing) {
        this.isProcessing = true
        const xPosition = this.characterType === 'left' ? 3 : -3
        await gsap.fromTo(this.camera.position, { x: this.cameraPosition.x }, { x: xPosition, duration: 3 })
        this.$nextTick(async () => {
          await this.changeAnimationModel()
          this.changeCameraReverse()
        })
      }
    },

    async changeAnimationModel() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async resolve => {
        let rotation = 0
        if (this.characterType === 'left') {
          rotation = 0.6
          this.currentActiveIndex = this.currentActiveIndex === 0 ? 2 : 0
        } else {
          rotation = -0.6
          this.currentActiveIndex = this.currentActiveIndex === 1 ? 3 : 1
        }
        this.$nextTick(async () => {
          await this.$emit('changedAnimationModel', {
            index: this.index, name: this.allAnimationCharacters[this.currentActiveIndex].name, file: this.allAnimationCharacters[this.currentActiveIndex].file, sound: this.allAnimationCharacters[this.currentActiveIndex].sound, rotationAngle: rotation,
          })
        })
        setTimeout(resolve(true), 2000)
      })
    },

    async changeCameraReverse() {
      if (this.changeAnimationEnabled) {
        const xPosition = this.characterType === 'left' ? 3 : -3
        await gsap.fromTo(this.camera.position, { x: xPosition }, {
          x: this.cameraPosition.x, duration: 3, delay: 0, onComplete: this.completedCameraReverse,
        })
      }
    },

    completedCameraReverse() {
      this.mixer.stopAllAction()
      this.$emit('changeAnimation', { index: this.index, value: 'idle' })
      this.isProcessing = false
    },

    resetCamera() {
      this.camera.position.set(this.cameraPosition.x, this.cameraPosition.y, this.cameraPosition.z)
    },

    updateRotation() {
      if (this.rotation) {
        gsap.to(this.root.rotation, { y: this.rotationAngle, duration: 0.7 })
        // this.root.rotation.y = this.rotationAngle
      }
    },

    playAnimation() {
      if (this.mixer) {
        this.mixer.stopAllAction()
      }
      if (this.animations && this.animations.length > 0) {
        if (this.playAllAnimation) {
          for (let i = 0; i < this.animations.length; i += 1) {
            if (this.animations[i]) {
              const animation = this.mixer.clipAction(this.animations[i])
              if (animation) {
                animation.enable = true
                animation.play()
              }
            }
          }
          return
        }
        let animation
        if (this.activeAnimation && this.activeAnimation.length > 0) {
          this.activeAnimation.forEach(anim => {
            if (!this.animations[this.animationTypes[anim]]) return
            animation = this.mixer.clipAction(this.animations[this.animationTypes[anim]])
            if (!animation) return
            animation.enable = true
            animation.play()
          })
        }
      }
    },

    animate() {
      requestAnimationFrame(this.animate)
      if (this.mixer) {
        const delta = this.clock.getDelta()
        this.mixer.update(delta)
      }
      this.renderer.render(this.scene, this.camera)
      this.spotLight.position.set(
        this.camera.position.x + 10,
        this.camera.position.y + 10,
        this.camera.position.z + 10,
      )
    },

  },
}
</script>
<style lang="scss">
.cursor-pointer{
  cursor:pointer;

}

</style>
