<template>
  <b-modal
    :visible="show"
    :title="title"
    size="lg"
    no-close-on-backdrop
    @hide="$emit('close')"
    @close="$emit('close')"
  >
    <validation-observer ref="formProvider">
      <b-form-group
        :label="$t('marketplace-module.title')"
      >
        <validation-provider
          v-slot="{ errors }"
          name="Product Title"
          rules="required"
        >
          <b-form-input
            v-model="form.title"
            :placeholder="$t('marketplace-module.title')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-row>
        <b-col>
          <b-form-group
            :label="$t('marketplace-module.start_time')"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Start Date"
              rules="required"
            >
              <b-form-datepicker
                v-model="form.start_time"
                :placeholder="$t('higher-level-report-module.no-date-selected')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            :label="$t('marketplace-module.end_time')"
          >
            <validation-provider
              v-slot="{ errors }"
              name="End Date"
              rules="required"
            >
              <b-form-datepicker
                v-model="form.end_time"
                :placeholder="$t('higher-level-report-module.no-date-selected')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-col
            md="12"
            class="p-0 m-0"
          >
            <b-form-group
              :label="`${$t('marketplace-module.price')} ($)`"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Price"
                rules="required"
              >
                <b-form-input
                  v-model="form.price"
                  placeholder="40000"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            class="p-0 m-0"
          >
            <b-form-group
              :label="$t('marketplace-module.url')"
            >
              <b-form-input
                v-model="form.url"
                placeholder="https://example.com"
              />
            </b-form-group>
          </b-col>
        </b-col>
        <b-col>
          <label class="">
            {{ $t('marketplace-module.image') }}
          </label>
          <image-upload
            ref="imageUploader"
            :url="form.image"
            style="height:180px;"
            @imageUploaded="
              (file, url) => {
                updateImage(file, url)
              }
            "
            @imageRemoved="handleImageRemoved"
          />

        </b-col>
      </b-row>
      <b-row />
      <b-row v-if="isSuperUser">
        <b-col>
          <b-form-checkbox v-model="form.all_schools"
                           @change="selectAllSchools"
          >
            {{ $t('marketplace-module.all-schools') }}
          </b-form-checkbox>
          <label>{{ $t('marketplace-module.schools') }}</label>
          <v-select
            v-model="form.school_ids"
            :options="schoolLists"
            :label="$t('labels.fullname')"
            :reduce="school => school.id"
            multiple
          />
        </b-col>
      </b-row>
      <b-row class="pt-1">
        <b-col>
          <b-form-group
            :label="$t('marketplace-module.descriptions')"
          >
            <quill-editor-custom v-model="form.description"
                                 placeholder="Product Descriptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="pt-1">
        <b-col>
          <b-form-group>
            <b-form-checkbox
              v-model="form.showUrl"
              name="featured-article"
              inline
              switch
              size="16"
            >
              <span style="font-size:14px; color:#6e6b7b;"> {{ $t('labels.show-external-link') }} </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{}">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            class="mr-2"
            @click="$emit('close')"
          >
            {{ $t('labels.cancel') }}
          </b-button>
          <b-button
            variant="success"
            :disabled="isProcessing"
            @click="handleForm"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('actions.save') }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal, BFormGroup, BFormInput, BRow, BCol, BSpinner, BButton, BFormDatepicker, BFormCheckbox,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ImageUpload from '@views/common/components/CustomImageUpload.vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import { USER_TYPE_SUPER, USER_TYPE_SCHOOL } from '@/const/userType'
import { getUserData } from '@/auth/utils'
import useApollo from '@/@core/graphql/useApollo'
import QuillEditorCustom from '@views/common/components/QuillEditorCustom.vue'
import i18n from '@/libs/i18n'

const initialForm = () => ({
  title: '',
  image: null,
  start_time: null,
  end_time: null,
  url: null,
  status: 'open',
  price: '',
  description: '',
  school_ids: [],
  showUrl: false,
  all_schools: false,
})
export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    ImageUpload,
    // vSelect,
    BSpinner,
    BButton,
    ValidationProvider,
    ValidationObserver,
    // BFormSelect,
    BFormDatepicker,
    vSelect,
    QuillEditorCustom,
    BFormCheckbox,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    marketplace: {
      type: Object,
      default: () => null,
    },
    ageGroups: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      schoolLists: [],
      isProcessing: false,
      form: {
        ...initialForm(),
      },
      statusList: ['open', 'closed'],
      daysLists: Array.from({ length: 7 }, (_, i) => i + 1),
      required,
      self: getUserData(),
    }
  },
  computed: {
    title() {
      return this.marketplace ? i18n.tc('marketplace-module.update-product') : i18n.tc('marketplace-module.add-product')
    },
    isSuperUser() {
      return this.self.usertype === USER_TYPE_SUPER
    },
    isSchoolUser() {
      return this.self.usertype === USER_TYPE_SCHOOL
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    marketplace(newValue) {
      if (newValue) {
        this.form = newValue
      } else {
        this.form = {
          ...initialForm(),
        }
      }
    },
  },
  created() {
    this.getSchoolUsers()
  },
  methods: {
    selectAllSchools(bool) {
      if (bool) {
        this.form.school_ids = this.schoolLists.map(item => item.id)
      } else {
        this.form.school_ids = []
      }
    },
    getSchoolUsers() {
      useApollo
        .getSchoolUsers()
        .then(response => {
          const res = response.data?.schools?.data
          this.schoolLists = res.map(item => ({ ...item, id: Number(item.id) }))
        })
        .finally(() => {
          this.schoolProcessing = false
        })
    },
    create() {
      this.isProcessing = true
      if (this.isSchoolUser) {
        this.form.school_ids = [this.self.id]
      }
      useJwt.storeMarketPlaces(this.form).then(response => {
        this.$emit('onUpdate')
        this.$emit('close')
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    update() {
      if (this.isSchoolUser) {
        this.form.school_ids = [this.self.id]
      }
      this.isProcessing = true
      useJwt.updateMarketPlaces(this.marketplace.id, this.form).then(response => {
        this.$emit('onUpdate')
        this.$emit('close')
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    handleForm() {
      this.$refs.formProvider.validate().then(success => {
        if (success) {
          if (this.marketplace) this.update()
          else this.create()
        }
      })
    },

    updateImage(file, url) {
      this.form.image = url
    },
    handleImageRemoved() {

    },
  },
}
</script>
