<template>
  <div>
    <b-breadcrumb
      :items="items"
    />
    <b-row class="mt-2">
      <b-col v-for="schedules,index of studentSchedules"
             :key="index"
             md="4"
      >
        <view-card :schedules="schedules"
                   :teachers="teacherList"
                   @reFetch="getStudentScheduleGroup"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol, BBreadcrumb } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import useApollo from '@/@core/graphql/useApollo'
import ViewCard from './components/ViewCard.vue'

export default {
  components: {
    ViewCard,
    BBreadcrumb,
    BRow,
    BCol,
  },
  data() {
    return {
      studentSchedules: {},
      teacherList: [],
      auth: this.AUTH_USER(),
      items: [
        {
          text: this.$i18n.tc('navigation-menu.schedule'),
        },
        {
          text: this.$i18n.tc('navigation-menu.reservation-schedule'),
        },
      ],
    }
  },
  created() {
    this.getStudentScheduleGroup()
    if (this.auth.usertype === 'school') {
      this.getTeachers()
    }
  },
  methods: {
    getStudentScheduleGroup() {
      useJwt.getStudentScheduleGroup().then(response => {
        this.studentSchedules = this.groupByKey(response.data.data.student_reservation, 'schedule_id')
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    getTeachers() {
      useApollo.getUserDropDown({
        type: 'teacher',
        limit: 100000,
      }).then(response => {
        const { data } = response.data.users
        const teachers = data.map(d => ({
          full_name: d.fullname,
          id: Number(d.id),
        }))
        this.teacherList = [...teachers]
      })
    },
  },
}
</script>
