<template>
  <b-row>
    <b-col
      v-if="self.id !== viewingClassesOfId"
      md="12"
    >
      <div class="pb-1">
        <b-button
          variant="flat-secondary"
          class="btn-icon"
          @click="GO_BACK()"
        >
          <feather-icon
            size="20"
            icon="ArrowLeftIcon"
          />
        </b-button>
        <span>
          {{
            `${$t("class-module.classes")} ${$t("messages.of")} ${
              viewingClassesOf.firstname
            } ${viewingClassesOf.lastname}`
          }}
        </span>
      </div>
    </b-col>
    <b-col
      lg="8"
      md="12"
    >
      <b-row>
        <!-- filters and create new button -->
        <b-col
          md="12"
          class="md-d-flex md-justify-content-between"
        >
          <b-row class="p-1">
            <b-col
              md="6"
              sm="12"
            >
              <b-row class="d-flex justify-content-start p-0">
                <b-col class="p-0">
                  <b-form-group>
                    <b-form-input
                      v-model="searchTermForClasses"
                      :placeholder="$t('search')"
                      type="text"
                      class="d-inline-block"
                      :disabled="isProcessing"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              md="6"
              sm="12"
            >
              <b-row
                v-if="!isAParent && (viewingClassesOfId==self.id)"
                class="md-d-flex justify-content-end"
              >
                <b-button
                  v-if="isATeacher"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :disabled="isProcessing"
                  variant="primary"
                  class="mr-1"
                  @click="showTeacherRubric=true"
                >
                  {{ $t("class-module.create-rubrics") }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :disabled="isProcessing"
                  variant="primary"
                  @click="openCreateClassModal()"
                >
                  {{ $t("class-module.create-class") }}
                </b-button>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <!-- filters and create new button end -->

        <!-- list of users table -->
        <b-col md="12 mt-2">
          <b-overlay
            id="overlay-background"
            :show="isProcessing"
            variant="transparent"
            opacity="0.8"
            blur="0.8"
            rounded="sm"
          >
            <component
              :is="isAStudent ? 'class-table': 'teacher-class-table'"
              :is-processing="isProcessing"
              :classes="classes"
              :search="searchTermForClasses"
              @onClassSelected="onClassRowClick"
              @onClassEdit="editClass"
              @onShowDetails="showClassStats"
              @onClassDelete="showDeleteModalForClass"
              @onEditLink="editClassLink"
              @onShowAllCoursesOf="showAllCoursesOf"
              @onOpenCalendar="viewClassCalendar"
            />
          </b-overlay>
          <!-- list of users table -->
        </b-col>
      </b-row>
    </b-col>
    <b-col
      lg="4"
      md="12"
    >
      <b-row>
        <!-- filters and create new button -->
        <b-col
          md="12"
          class="md-d-flex md-justify-content-between"
        >
          <b-row class="p-1">
            <b-col
              md="6"
              sm="12"
            >
              <b-row class="d-flex justify-content-start p-0">
                <b-col class="p-0">
                  <b-form-group>
                    <b-form-input
                      v-model="searchTermForStudents"
                      :placeholder="$t('search')"
                      type="text"
                      class="d-inline-block"
                      :disabled="isProcessing"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              md="6"
              sm="12"
            >
              <b-row
                v-if="
                  viewingStudentsForClass !== null &&
                    self.id == viewingStudentsForClass.created_by
                "
                class="md-d-flex justify-content-end"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :disabled="isProcessing"
                  variant="outline-primary"
                  @click="showAddStudentModal"
                >
                  {{ $t("class-module.add-students") }}
                </b-button>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <!-- filters and create new button end -->

        <!-- list of users table -->
        <b-col md="12 mt-2">
          <b-overlay
            id="overlay-background"
            :show="isProcessing"
            variant="transparent"
            opacity="0.8"
            blur="0.8"
            rounded="sm"
          >
            <student-table
              :is-processing="isProcessing"
              :students="studentsFromSelectedClass"
              :active-class="viewingStudentsForClass"
              :search="searchTermForStudents"
              @onStudentDelete="showDeleteModalForStudent"
              @onViewRubrics="showClassRubrics=true"
              @onViewStudentRubric="(data)=>viewingRubricsOfStudent=data"
              @onViewRubricScore="showStudentRubricScore"
            />
          </b-overlay>
          <!-- list of users table -->
        </b-col>
      </b-row>
    </b-col>

    <delete-modal
      name="modal-delete-class"
      :on-delete="deleteClass"
      :title="$t('class-module.delete-class-title')"
      :sub-title="$t('class-module.delete-class-question')"
      :is-processing="isProcessing"
      :show="indexOfClassBeingDeleted != null"
      @close="indexOfClassBeingDeleted = null"
    />

    <create-edit-class
      :teachers="teachers"
      :courses="courses"
      :edit-class="classes[indexOfClassBeingEdited]"
      :show="showClassModal"
      :mode="isEditingClass ? 'edit' : 'create'"
      @close="showClassModal = false"
      @onClassCreated="getClasses()"
      @onClassUpdated="getClasses()"
    />

    <live-class-link
      :show="showClassLinkModal"
      :class-room="classes[indexOfClassLinkBeingEdited]"
      @close="showClassLinkModal = false"
    />

    <add-students
      :students="students"
      :class-id="viewingStudentsForClass ? viewingStudentsForClass.id : null"
      :show="showStudentModal"
      @close="showStudentModal = false"
      @onStudentsAdded="getStudentsOfClass()"
    />

    <delete-modal
      name="modal-delete-student"
      :on-delete="removeStudentFromAClass"
      :title="$t('class-module.delete-student-title')"
      :sub-title="$t('class-module.delete-student-question')"
      :is-processing="isProcessing"
      :show="indexOfStudentBeingDeleted != null"
      @close="indexOfStudentBeingDeleted = null"
    />

    <class-rubric-modal
      :show="showClassRubrics"
      :classe="viewingStudentsForClass"
      :rubrics="activeClassRubrics"
      :teacher-rubrics="teacherRubrics"
      @close="showClassRubrics=false"
      @refreshRubrics="getClassRubrics"
    />

    <teacher-rubric-modal
      v-if="isATeacher"
      :rubrics="teacherRubrics"
      :show="showTeacherRubric"
      @close="showTeacherRubric=false"
      @refreshRubrics="getTeacherRubrics"
    />

    <student-rubric-modal
      :student="viewingRubricsOfStudent"
      :show="viewingRubricsOfStudent===null"
      :class-id="viewingStudentsForClass ? viewingStudentsForClass.id : null"
      :rubrics="activeClassRubrics"
      :is-teacher="viewingStudentsForClass ? self.id === viewingStudentsForClass.teacher_id : false"
      @close="viewingRubricsOfStudent=null"
    />

    <b-modal
      id="all-class-courses"
      ref="all-class-courses"
      v-model="showAllCoursesModal"
      ok-only
      ok-variant="primary"
      :ok-title="$t('actions.close')"
      modal-class="modal-primary"
      centered
      :title="`${$t('class-module.courses-of')} ${viewingAllCoursesOf ? viewingAllCoursesOf.name :''}`"
    >

      <div v-if="viewingAllCoursesOf">
        <div
          v-for="(course) in viewingAllCoursesOf.courses"
          :key="course.name"
          class="d-inline-block ml-1 mb-1"
        >
          <b-button
            variant="primary"
            @click="(isAStudent || isATeacher) ? viewLessons(course.id):null"
          >
            {{ course.name }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormGroup,
  BOverlay,
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import ClassTable from '@/views/common/class/components/ClassTable.vue'
import TeacherClassTable from '@/views/common/class/components/TeacherClassTable.vue'
import StudentTable from '@/views/common/class/components/StudentTable.vue'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import CreateEditClass from '@/views/common/class/components/CreateEdit.vue'
import AddStudents from '@/views/common/class/components/AddStudents.vue'
import LiveClassLink from '@/views/common/class/components/LiveClassLink.vue'
import { USER_TYPE_TEACHER, USER_TYPE_PARENT, USER_TYPE_STUDENT } from '@/const/userType'
import ClassRubricModal from './components/rubric/components/ClassRubricModal.vue'
import TeacherRubricModal from './components/rubric/components/TeacherRubricModal.vue'
import StudentRubricModal from './components/rubric/components/StudentRubricModal.vue'

export default {
  components: {
    BRow,
    CreateEditClass,
    AddStudents,
    DeleteModal,
    BFormInput,
    BCol,
    BFormGroup,
    BOverlay,
    BButton,
    ClassTable,
    TeacherClassTable,
    LiveClassLink,
    BModal,
    StudentTable,
    ClassRubricModal,
    StudentRubricModal,
    TeacherRubricModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showClassModal: false,
      viewingAllCoursesOf: null,
      showAllCoursesModal: false,
      showStudentModal: false,
      isProcessing: false,
      searchTermForClasses: '',
      searchTermForStudents: '',
      indexOfClassBeingEdited: null,
      showClassRubrics: false,
      viewingRubricsOfStudent: null,
      classes: [],
      students: [],
      courses: [],
      teachers: [],
      studentsFromSelectedClass: [],
      indexOfClassBeingDeleted: null,
      indexOfStudentBeingDeleted: null,
      viewingStudentsForClass: null,
      showClassLinkModal: false,
      indexOfClassLinkBeingEdited: null,
      self: getUserData(),
      viewingClassesOfId: getUserData().id,
      viewingClassesOf: getUserData(),
      showTeacherRubric: false,
      USER_TYPE_TEACHER,
      activeClassRubrics: [],
      teacherRubrics: [],
      searchStudent: '',
    }
  },
  computed: {
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
    isEditingClass() {
      return this.indexOfClassBeingEdited !== null
    },
    isAParent() {
      return this.self.usertype === USER_TYPE_PARENT
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newVal) {
        if ('user_id' in newVal) {
          this.viewingClassesOfId = newVal.user_id
          this.getUserData()
        }
      },
    },
  },
  mounted() {
    this.getCourses()
    if (this.self.usertype !== USER_TYPE_TEACHER) this.getTeachers()
    this.getClasses().then(() => {
      this.getQueryParams()
    })
    if (this.isATeacher) this.getTeacherRubrics()
  },

  methods: {
    getTeacherRubrics() {
      this.isProcessing = true
      useJwt.getTeacherRubrics().then(res => {
        this.teacherRubrics = res.data.data
      }).finally(() => {
        this.isProcessing = false
      })
    },
    getClassRubrics() {
      this.isProcessing = true
      useJwt.getClassRubrics(this.viewingStudentsForClass.id).then(res => {
        this.activeClassRubrics = res.data.data
      }).finally(() => {
        this.isProcessing = false
      })
    },
    showStudentRubricScore(id) {
      this.$router.push({ name: `${this.self.usertype}-rubrics`, params: { id, classId: this.viewingStudentsForClass.id } })
    },
    showAllCoursesOf(data) {
      this.viewingAllCoursesOf = data
      this.showAllCoursesModal = true
    },
    viewClassCalendar(id) {
      this.$router.push({ name: `${this.self.usertype}-class-calendar`, params: { id, classId: id } })
    },
    viewLessons(id) {
      this.$router.push({
        name: `${this.self.usertype}-lessons`,
        params: { id, classId: this.viewingAllCoursesOf.class_id },
      })
    },
    getUserData() {
      useJwt.getUser(this.viewingClassesOfId).then(res => {
        this.viewingClassesOf = res.data.data
      })
    },
    showAddStudentModal() {
      this.getStudentsForClass()
      this.showStudentModal = true
    },
    getQueryParams() {
      const id = parseInt(this.$route.query.id, 10)
      if (!id) return
      const classIndex = this.classes.findIndex(a => a.id === id)
      if (classIndex === -1) return
      this.editClass(id, classIndex)
    },
    getTeachers() {
      useJwt.getListOfTeachers().then(response => {
        this.teachers = response.data.data ? response.data.data : []
      })
    },
    getCourses() {
      useJwt.getCourses().then(response => {
        this.courses = response.data.data
      })
    },
    getStudentsForClass() {
      useJwt
        .getStudentsForClass(this.viewingStudentsForClass.id)
        .then(response => {
          this.students = response.data.data ? response.data.data.data : []
        })
    },
    getClasses() {
      return new Promise((resolve, reject) => {
        useJwt
          .getClasses({
            params: {
              userId: this.viewingClassesOfId,
              byAuthUser: !this.viewingClassesOfId,
            },
          })
          .then(response => {
            this.classes = response.data.data
            resolve('done')
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            this.isProcessing = false
          })
      })
    },
    deleteClass(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.isProcessing = true

      const classBeingDeleted = this.classes[this.indexOfClassBeingDeleted]

      useJwt
        .deleteClass(classBeingDeleted.id)
        .then(response => {
          this.getClasses()
          this.indexOfClassBeingDeleted = null
          this.showSuccessMessage(response)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    onClassRowClick(params) {
      this.viewingStudentsForClass = params
      this.getClassRubrics()
      this.getStudentsOfClass()
    },
    getStudentsOfClass() {
      this.isProcessing = true
      useJwt
        .getStudentsOfClass(this.viewingStudentsForClass.id)
        .then(response => {
          this.studentsFromSelectedClass = response.data.data
        })
        .finally(() => {
          this.isProcessing = false
        })
    },

    removeStudentFromAClass(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.isProcessing = true

      const studentBeingDeleted = this.studentsFromSelectedClass[this.indexOfStudentBeingDeleted]

      useJwt
        .removeStudentFromClass(
          this.viewingStudentsForClass.id,
          studentBeingDeleted.id,
        )
        .then(response => {
          this.getStudentsOfClass()
          this.indexOfStudentBeingDeleted = null
          this.showSuccessMessage(response)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },

    editClass(id, index) {
      this.showClassModal = true
      this.indexOfClassBeingEdited = index
    },
    editClassLink(id, index) {
      this.showClassLinkModal = true
      this.indexOfClassLinkBeingEdited = index
    },
    showDeleteModalForClass(index) {
      this.indexOfClassBeingDeleted = index
    },
    showDeleteModalForStudent(index) {
      this.indexOfStudentBeingDeleted = index
    },
    openCreateClassModal() {
      this.indexOfClassBeingEdited = null
      this.showClassModal = true
    },
    showClassStats(cls) {
      this.$router.push({
        name: `${this.self.usertype}-class`,
        params: { id: cls.id, courseId: 'course', reporting: 'reporting' },
        query: { user_id: this.viewingClassesOfId },
      })
      // if (cls.type !== 'custom') {
      //   this.$router.push({
      //     name: `${this.self.usertype}-class`,
      //     params: { id: cls.id },
      //     query: this.self.id === this.viewingClassesOfId ? {} : { user_id: this.viewingClassesOfId },
      //   })
      // } else {
      //   this.$router.push({
      //     name: `${this.self.usertype}-course`,
      //     params: { id: cls.id },
      //   })
      // }
    },
  },
}
</script>
