<template>
  <div class="sticky-icons">
    <div
      :class="['nav-trigger', { 'drawer-open': isDrawerOpen }]"
      @click="toggleDrawer"
    >
      <feather-icon
        :icon="isDrawerOpen ? 'XIcon': 'AlignJustifyIcon'"
      />
    </div>
    <div
      :class="['drawer', { open: isDrawerOpen }]"
    >
      <div v-if="isDrawerOpen"
           class="drawer-content"
      >
        <ul>

          <!-- li is inside note-floating-button -->
          <note-floating-button />
          <li>
            <help-video-list
              v-if="(isASchool || isATeacher || isAStudent) && !hideHelpVideoStatus"
              :user-type="self.usertype"
            />
          </li>
          <li>
            <VirtualKeyboard v-if="isAStudent && !hideVirtualKeyboard" />
          </li>
        </ul>
      </div>

    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import NoteFloatingButton from '@views/student/problem/compoments/NoteFloatingButton.vue'
import {
  USER_TYPE_SCHOOL,
  USER_TYPE_TEACHER,
  USER_TYPE_STUDENT,
} from '@/const/userType'
import { getUserData } from '@/auth/utils'
import VirtualKeyboard from '@/views/common/keyboard/index.vue'
import HelpVideoList from '@/views/common/components/HelpVideoList.vue'
import {
  isVisible,
} from '@/utils/visibilitySettings'

export default {
  components: {
    NoteFloatingButton,
    HelpVideoList,
    VirtualKeyboard,
  },
  data() {
    return {
      self: getUserData(),
      isDrawerOpen: false,
    }
  },
  computed: {
    ...mapGetters('appConfig', ['getSchoolSettings', 'getDistrictSettings']),
    isASchool() {
      return this.self.usertype === USER_TYPE_SCHOOL
    },
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },

    hideHelpVideoStatus() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('help_video', schoolSetting, distSetting)
    },

    hideVirtualKeyboard() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('virtual_keyboard', schoolSetting, distSetting)
    },
  },
  mounted() {
    this.responsiveWindow()
  },
  methods: {
    toggleDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen
    },
    closeDrawer() {
      this.isDrawerOpen = false
    },
    responsiveWindow() {
      if (window.innerWidth <= 768) {
        this.isDrawerOpen = false
      } else {
        this.isDrawerOpen = true
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.sticky-icons {
    .nav-trigger {
        position: fixed;
        top: 78px;
        right: 10px;
        width: 20px;
        height: 20px;
        background-color: #9a9797;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: white;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease;
        z-index: 1001;
    }

    .nav-trigger:hover {
        transform: scale(1.1);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    }

    .drawer {
        position: fixed;
        top: 80px;
        right: 0;
        width: 0;
        height: 60%;
        // background-color: #000;
        color: white;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        overflow: hidden;
        // box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
        z-index: 1000;
    }

    .drawer.open {
        width: 60px;
    }

    .drawer-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
        opacity: 1;
        transition: opacity 0.3s ease;
    }

    .drawer-header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        padding: 0 10px;
        position: relative;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    li {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 0 0;
        cursor: pointer;
        position: relative;
        transition: all 0.2s ease;
    }

    li:hover {
        background: rgba(230, 226, 226, 0.1);
    }

    li:first {
        margin-top:20px;
    }
    /* Tooltip Styles */
    li::after {
        content: attr(data-tooltip);
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        white-space: nowrap;
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s ease;
        pointer-events: none;
        z-index: 1001;
    }

    li:hover::after {
        opacity: 1;
        visibility: visible;
    }
    .nav-trigger:not(:hover) {
        animation: pulse 2s infinite;
    }
    // li:first-child{
    //     margin-top: 46px !important;
    // }
    @keyframes pulse {
        0% {
            transform: scale(1);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
        }
        50% {
            transform: scale(1.05);
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        }
        100% {
            transform: scale(1);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
        }
    }

}

@media (max-width: 576px) {
    .sticky-icons {
        .nav-trigger {
            top: 66px;
            right: 10px;
            width: 30px;
            height: 30px;
        }
        .drawer {
            box-shadow: none;
        }
        .drawer.open {
            width: 50px;
        }
        .drawer-content {
            padding-top: 30px;
        }
        li {
            padding: 10px 0 0;
        }
    }
}

</style>
