export const CHARACTER_1 = 'male'
export const CHARACTER_2 = 'female'
export const CHARACTER_3 = 'jp_male'
export const CHARACTER_4 = 'jp_female'

export const STATUS_IDLE = 'idle'
export const STATUS_SPEAKING = 'speaking'

export const STATUS_HAPPY_IDLE = 'happy_idle'
export const STATUS_HAPPY_SPEAKING = 'happy_speaking'

export const STATUS = [STATUS_IDLE, STATUS_SPEAKING, STATUS_HAPPY_IDLE, STATUS_HAPPY_SPEAKING]

export const IDLE = {
  [CHARACTER_1]: 'Android_man_avatar_idle_.png',
  [CHARACTER_2]: 'Android_Female_idle.png',
  [CHARACTER_3]: 'JP_MAN_IDLE.png',
  [CHARACTER_4]: 'JP_WOMAN_IDLE.png',
}

export const SPEAKING = {
  [CHARACTER_1]: 'Android_man_avatar_talk_.png',
  [CHARACTER_2]: 'Android_Female_talking.png',
  [CHARACTER_3]: 'JP_MAN_TALKING.png',
  [CHARACTER_4]: 'JP_WOMAN_TALKING.png',
}

export const HAPPY_IDLE = {
  [CHARACTER_1]: 'Android_Male_Happy_idle_.png',
  [CHARACTER_2]: 'Android_Female_Happy_idle_.png',
  [CHARACTER_3]: 'JP_MAN_IDLE.png',
  [CHARACTER_4]: 'JP_WOMAN_IDLE.png',
}

export const HAPPY_SPEAKING = {
  [CHARACTER_1]: 'Android_Male_Happy_talk_.png',
  [CHARACTER_2]: 'Android_Female_Happy_Talk_.png',
  [CHARACTER_3]: 'JP_MAN_TALKING.png',
  [CHARACTER_4]: 'JP_WOMAN_TALKING.png',
}
export const BOT_OBJ = { avatar: 'https://www.airport-technology.com/wp-content/uploads/sites/14/2022/01/Ameca_White_BG_03-1038x778.jpg' }

export const GEC_ML_END_POINT = 'https://ml.globalenglishcampus.com/api/v1'
