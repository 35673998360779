const mutations = {

  RESET_DATA(state) {
    state.networkId = null
    state.network = null
    state.myNetworkMembership = null
    state.members = []
  },

  SET_NETWORK(state, network) {
    state.network = network
  },

  SET_NETWORK_ID(state, id) {
    state.networkId = id
  },
  SET_REQUEST_ID(state, id) {
    state.requestId = id
  },
  SET_IS_PROCESSING(state, val) {
    state.isProcessing = val
  },
  SET_INVITATIONS(state, invitations) {
    state.invitations = invitations
  },
  SET_REQUESTS(state, requests) {
    state.requests = requests
  },
  SET_MY_NETWORK_MEMBERSHIP(state, membership) {
    state.myNetworkMembership = membership
  },
  SET_NETWORK_MEMBERS(state, members) {
    state.members = members
  },
  SET_ACTIVE_TAB(state, tab) {
    state.activeTab = tab
  },
  SET_COLEADERS(state, data) {
    state.coLeaders = data
  },
  SET_VERIFIED_ARTICLES(state, data) {
    state.verifiedArticles = data
  },
  SET_VERIFIED_ARTICLES_PAGE(state, page) {
    state.verifiedArticles.currentPage = page
  },
  SET_VERIFIED_ARTICLES_PAGINATION_DATA(state, data) {
    if (data) {
      state.verifiedArticlesPaginationData = {
        currentPage: data.current_page,
        perPage: data.per_page,
        totalItems: data.total,
        lastPage: data.total_pages,
      }
    }
  },
  SET_UNVERIFIED_ARTICLES(state, data) {
    state.unVerifiedArticles = data
  },
  SET_UNVERIFIED_ARTICLES_PAGINATION_DATA(state, data) {
    if (data) {
      state.unVerifiedArticlesPaginationData = {
        currentPage: data.current_page,
        perPage: data.per_page,
        totalItems: data.total,
        lastPage: data.total_pages,
      }
    }
  },

}

export default mutations
