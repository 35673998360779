<template>

  <b-modal
    id="modal-user-relations"
    ref="modal-view-user-relations"
    size="lg"
    ok-only
    ok-variant="primary"
    :click-to-close="false"
    :no-close-on-backdrop="true"
    :ok-title="$t('ok')"
    modal-class="modal-primary"
    centered
    :title="getCreateUserModalTitle"
    no-close-on-esc
  >
    <h5>{{ $t('user-module.user-table.relationship') }} <span class="badge badge-success">{{ userRelations.length }}</span></h5>
    <div
      v-if="selfDetail"
      class="p-2"
    >
      <b>{{ $t('user-module.user-table.name') }}: {{ selfDetail.firstname+' '+selfDetail.lastname }}</b>
      <br>
      <label>{{ $t('user-module.user-table.email') }}: {{ selfDetail.email }} </label>
    </div>
    <b-row v-if="AUTH_USER().user_level < 7">
      <b-col
        xs="4"
        md="6"
        sm="12"
        class="p-2 pr-md-1"
      >
        <b-form-group>
          <b-form-input
            v-model="searchUser"
            debounce="500"
            :placeholder="$t('labels.search-by-firstname-lastname-email')"
            type="text"
            class="d-inline-block"
            @update="searchParentUser"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="canShowViewAll"
        xs="4"
        md="6"
        sm="12"
        class="p-2 pr-md-1"
      >
        <b-form-checkbox v-model="enableAllFilter"
                         :value="1"
                         switch
                         @change="searchParentUser"
        >
          {{ $t('user-module.view-non-related-users') }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <div class="w-100 mb-1">
      {{ $t('user-module.click') }} <feather-icon icon="LinkIcon" /> {{ $t('user-module.to-add-users') }}
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            ID
          </th>
          <th scope="col">
            {{ $t('user-module.user-table.name') }}
          </th>
          <th scope="col">
            {{ $t('library-moduel.role') }}
          </th>
          <th
            v-if="AUTH_USER().user_level < 7"
            scope="col"
          >
            {{ $t('actions.action') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="user, index in userRelations"
          :key="user.id"
          :class="`${user.is_default?'table-secondary':''}`"
        >
          <td>{{ user.id }}</td>
          <td>{{ user.firstname }} {{ user.lastname }}</td>
          <td>{{ user.user_type.name }} </td>
          <td v-if="AUTH_USER().user_level < 7">
            <span v-if="AUTH_USER().user_level < getUserTypeLevel(user.user_type.name)">
              <feather-icon
                v-if="searchUser || enableAllFilter"
                icon="LinkIcon"
                size="18"
                class="mr-50 text-success"
                role="button"
                @click="linkParent(user.id)"
              />
              <feather-icon
                v-else
                icon="XIcon"
                size="18"
                class="mr-50 text-danger"
                role="button"
                @click="removeRelation(user.id, index)"
              />
            </span>
          </td>
        </tr>

      </tbody>
    </table>
  </b-modal>
</template>
<script>
import {
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import {
  getUserTypeLevel,
} from '@/const/userType'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
  },
  props: ['getCreateUserModalTitle'],
  data() {
    return {
      getUserTypeLevel,
      userRelations: [],
      selfDetail: null,
      searchUser: '',
      selectedUserType: null,
      userAddingType: 'parent',
      enableAllFilter: 0,
      canShowViewAll: false,
    }
  },
  watch: {
    userAddingType(value) {
      if (value === 'child') {
        this.canShowViewAll = true
      } else {
        this.canShowViewAll = false
        this.enableAllFilter = 0
      }
    },
  },
  methods: {
    searchParentUser() {
      if (this.searchUser || this.enableAllFilter) {
        useJwt.searchParentUsers(this.selfDetail.id, {
          params: {
            search: this.searchUser,
            child: this.selectedUserType ? getUserTypeLevel(this.selectedUserType) : null,
            all: this.enableAllFilter ? 1 : null,
          },
        }).then(res => {
          this.userRelations = res.data.data
        })
      } else {
        this.viewUserRelations(this.selfDetail.id, this.selectedUserType)
      }
    },
    viewUserRelations(userId, userType = null, userAddType = this.userAddingType) {
      this.searchUser = null
      this.$refs['modal-view-user-relations'].show()
      this.selectedUserType = userType
      this.userAddingType = userAddType
      useJwt.getUsersRelations(userId, {
        params: {
          child: userType ? getUserTypeLevel(userType) : null,
          // all: this.enableAllFilter ? 1 : null,
        },
      }).then(res => {
        this.userRelations = res.data.data.parents
        this.selfDetail = res.data.data.selfDetail
        this.enableAllFilter = null
      }).catch(err => {
        console.log(err)
      })
    },
    linkParent(parentUserId) {
      let data

      if (this.userAddingType === 'child') {
        data = {
          type: 'attach',
          parent_id: this.selfDetail.id,
          child_id: parentUserId,
        }
      } else {
        data = {
          type: 'attach',
          parent_id: parentUserId,
          child_id: this.selfDetail.id,
        }
      }
      useJwt.updateRelation(data).then(res => {
        this.userRelations = this.userRelations.filter(item => item.id !== parentUserId)
        // this.viewUserRelations(this.selfDetail.id, this.selectedUserType)
        console.log(res)
        this.showSuccessMessage(res)
      }).catch(err => {
        // console.log(err)
        // this.showErrorMessage(err.response?.data.message)
        this.showErrorMessage(err)
      })
    },
    removeRelation(parentUserId, index) {
      let data
      if (this.userAddingType === 'child') {
        data = {
          type: 'detach',
          parent_id: this.selfDetail.id,
          child_id: parentUserId,
        }
      } else {
        data = {
          type: 'detach',
          parent_id: parentUserId,
          child_id: this.selfDetail.id,
        }
      }
      useJwt.updateRelation(data).then(res => {
        this.userRelations.splice(index, 1)
        this.showSuccessMessage(res)
      }).catch(err => {
        // console.log(err.response)
        this.showErrorMessage(err.response?.data.message)
      })
    },
  },
}
</script>
