<template>
  <div class="video-call">
    <div
      v-show="loading"
      class="loading"
    >
      <div class="effect-1 effects" />
      <div class="effect-2 effects" />
      <div class="effect-3 effects" />
    </div>
    <div class="call-screen">
      <div :class="videoHoldersModels.length === 1 ? 'local-video_wrap w-100' : 'local-video_wrap'">
        <video-player
          v-if="videoHoldersModels.length > 0"
          :key="videoHoldersModels[0].participant.userId + videoHoldersModels[0].videoHolderModelType"
          :is-full-screen-view="true"
          :video-holder-model="videoHoldersModels[0]"
          class="h-100"
        />
      </div>
      <div
        v-show="videoHoldersModels.length > 1"
        class="remote-video_wrap"
      >
        <div
          v-for="(videoHolderModel ,index) in videoHoldersModels"
          :key="videoHolderModel.participant.userId + videoHolderModel.videoHolderModelType"
        >
          <video-player
            v-if="index>0"
            :is-full-screen-view="false"
            :video-holder-model="videoHolderModel"
          />
        </div>
      </div>
    </div>
    <div class="call-bottom">
      <div class="call-btns">
        <div
          class="call-btn"
          @click="onOffCamera"
        >
          <feather-icon
            :icon="isCameraOff ? 'VideoOffIcon' : 'VideoIcon'"
            class="cursor-pointer text-white"
            size="21"
          />
        </div>
        <div
          class="call-btn"
          @click="muteUnmuteMic"
        >
          <feather-icon
            :icon="isMuted ? 'MicOffIcon' : 'MicIcon'"
            class="cursor-pointer text-white"
            size="21"
          />
        </div>
        <div
          class="call-btn bg-danger"
          @click="endCall"
        >
          <feather-icon
            icon="PhoneOffIcon"
            class="cursor-pointer text-white"
            size="21"
          />
        </div>
        <div
          class="call-btn "
          @click="shareScreenTapped"
        >
          <feather-icon
            icon="MonitorIcon"
            class="cursor-pointer text-white"
            size="21"
          />
        </div>
        <div
          class="call-btn"
          @click="openSettings"
        >
          <feather-icon
            icon="SettingsIcon"
            class="cursor-pointer text-white"
            size="21"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VaniEvent,
  MessagePayload,
} from 'vani-meeting-client'
import { TrackKind } from 'vani-meeting-client/lib/model/Track'

import router from '@/router'
import VideoPlayer from './call/VideoPlayer.vue'
import VideoHandler from './VideoHandler'
import ChatHandler from './ChatHandler'
import VideoHolderModel from './VideoHolderModel'

export default {
  name: 'VideoCall',
  components: {
    VideoPlayer,
  },
  data() {
    return {
      isCameraOff: true,
      isMuted: true,
      isScreenSharing: false,
      videoHoldersModels: [],
      loading: false,
    }
  },
  created() {
    window.onbeforeunload = function () {
      return 'handle your events or msgs here'
    }
  },
  beforeDestroy() {
    window.onbeforeunload = null
  },
  mounted() {
    // Show Loader
    // this.$toast("I'm a toast!")
    this.loading = true
    if (!VideoHandler.getInstance().meetingRequest) {
      this.routeBack()
    } else {
      ChatHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnNewChatMessageReceived, this.onNewChatMessageReceived)

      VideoHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnInitDone, this.onInitDone)
      VideoHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnSocketConnected, this.onSocketConnected)
      VideoHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnTrack, this.onTrack)
      VideoHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnTrackEnded, this.onTrackEnded)

      VideoHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnPermissionApproved, this.permissionApproved)
      VideoHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnPermissionError, this.permissionError)

      VideoHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnUserLeft, this.onUserLeft)
      VideoHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnUserJoined, this.onUserJoined)
      VideoHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnAllParticipants, this.onAllParticipants)
      VideoHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnSpeakerChanged, this.onSpeakerChanged)
      VideoHandler.getInstance().getMeetingHandler().init()
    }
  },
  methods: {
    routeBack() {
      router.replace('/chat')
    },
    declineCallAlert() {
      this.$swal('Call Declined').then(() => {
        this.endCall()
      })
    },
    openSettings() {
      ChatHandler.getInstance().eventEmitter.emit('ShowAudioVideoSelector', {})
    },
    onInitDone() {
      // Hide Loader
      this.loading = false
      this.videoHoldersModels.push(
        new VideoHolderModel(
          VideoHandler.getInstance().getMeetingHandler()
            .participantByUserId(
              VideoHandler.getInstance().getMeetingRequest().userId,
            ),
          'Main',
          true,
        ),
      )
      VideoHandler.getInstance().getMeetingHandler().startLocalStream(VideoHandler.getInstance().isVideoCall, true)
    },
    onSocketConnected() {
      VideoHandler.getInstance().getMeetingHandler().startMeeting()
      // hide loader
      this.loading = false
    },
    permissionApproved() {
      // show Loader
      this.loading = true
      VideoHandler.getInstance().getMeetingHandler().checkSocket()
    },
    onTrack(track) {
      if (
        track.trackKind === TrackKind.ScreenshareVideo
      || track.trackKind === TrackKind.ScreenshareAudio
      ) {
        this.checkAndAddUser(
          track.participant,
          'ScreenShare',
        )

        if (track.isLocalTrack) {
          this.isScreenSharing = true
        }
      } else if (track.isLocalTrack && track.trackKind === TrackKind.Video) {
        this.isCameraOff = false
      } else if (track.isLocalTrack && track.trackKind === TrackKind.Audio) {
        this.isMuted = false
      }
    },
    onTrackEnded(track) {
      if (
        track.trackKind === TrackKind.ScreenshareVideo
      || track.trackKind === TrackKind.ScreenshareAudio
      ) {
        this.videoHoldersModels = this.videoHoldersModels.filter(
          videoHoldersModel => !(
            videoHoldersModel.participant.userId === track.participant.userId
            && videoHoldersModel.videoHolderModelType
            === 'ScreenShare'
          ),
        )
        this.isScreenSharing = false
      } else if (track.isLocalTrack && track.trackKind === TrackKind.Video) {
        this.isCameraOff = true
      } else if (track.isLocalTrack && track.trackKind === TrackKind.Audio) {
        this.isMuted = true
      }
    },
    onSpeakerChanged(participant) {
      if (
        this.videoHoldersModels.length > 2
      && participant.userId !== VideoHandler.getInstance().getMeetingRequest().userId
      && this.videoHoldersModels[0].participant.userId !== participant.userId
      ) {
        let indexWhereToMoveParticipant = 0
        if (this.videoHoldersModels[0].isPinned) {
          indexWhereToMoveParticipant = 1
        }
        const indexOfItem = this.videoHoldersModels.findIndex(
          videoHoldersModel => videoHoldersModel.participant.userId === participant.userId,
        )
        if (indexOfItem > -1 && indexOfItem !== indexWhereToMoveParticipant) {
          const videoHolderModel = this.videoHoldersModels[indexOfItem]
          this.videoHoldersModels.splice(indexOfItem, 1)
          this.videoHoldersModels.splice(
            indexWhereToMoveParticipant,
            0,
            videoHolderModel,
          )
        }
      }
    },
    shareScreenTapped() {
      if (this.isScreenSharing) { VideoHandler.getInstance().getMeetingHandler().stopScreenSharing() } else { VideoHandler.getInstance().getMeetingHandler().startScreenShare(false) }
    },
    endCall() {
      if (VideoHandler.getInstance().isAdmin) {
        VideoHandler.getInstance().callMembers.forEach(contactId => {
          const participants = ChatHandler.getInstance().getUserByUserId(contactId)
          if (participants) {
            participants.forEach(participant => {
              if (participant.userId !== ChatHandler.getInstance().getMeetingRequest().userId) {
                const chatPayload = new MessagePayload('VideoCallEnded', participant.userId)
                chatPayload.type = 'VideoCallEnded'
                ChatHandler.getInstance().sendMessage(chatPayload)
              }
            })
          }
        })
      }
      VideoHandler.getInstance().cleanUp()
      this.routeBack()
    },

    permissionError() {
      alert('Please allow Permission')
    },

    onUserLeft(participant) {
      this.videoHoldersModels = this.videoHoldersModels.filter(
        videoHoldersModel => videoHoldersModel.participant.userId !== participant.userId,
      )
      const name = participant.userData.firstname + participant.userData.lastname
      this.$toast(`${name} left the call`)
    },
    onUserJoined(participant) {
      this.checkAndAddUser(
        participant,
        'Main',
      )
      const name = participant.userData.firstname + participant.userData.lastname
      this.$toast(`${name} joined the call`)
    },
    onAllParticipants(participants) {
      if (participants.length > 0) {
        this.videoHoldersModels = []

        participants.forEach(participant => {
          this.checkAndAddUser(
            participant,
            'Main',
          )
        })
        if (this.videoHoldersModels.length === 0) {
          this.checkAndAddUser(
            VideoHandler.getInstance().getMeetingHandler()
              .participantByUserId(
                VideoHandler.getInstance().getMeetingRequest().userId,
              ),
            'Main',
          )
        }
      }
    },
    checkAndAddUser(
      participant,
      viewHolderType,
    ) {
      const alreadyExitModel = this.videoHoldersModels.find(
        viewHolder => viewHolder.participant.userId === participant.userId
        && viewHolder.videoHolderModelType === viewHolderType,
      )
      if (!alreadyExitModel) {
        if (this.videoHoldersModels.length > 0 && this.videoHoldersModels[0].isPinned) {
          this.videoHoldersModels.splice(
            1,
            0,
            new VideoHolderModel(
              participant,
              viewHolderType,
              participant.userId
            === VideoHandler.getInstance().getMeetingRequest().userId,
            ),
          )
        } else {
          this.videoHoldersModels.unshift(
            new VideoHolderModel(
              participant,
              viewHolderType,
              participant.userId
            === VideoHandler.getInstance().getMeetingRequest().userId,
            ),
          )
        }
      }
    },
    onOffCamera() {
      if (this.isCameraOff) {
        VideoHandler.getInstance().getMeetingHandler().resumeCamera()
      } else {
        VideoHandler.getInstance().getMeetingHandler().pauseCamera()
      }
    },
    muteUnmuteMic() {
      if (this.isMuted) {
        VideoHandler.getInstance().getMeetingHandler().unmute()
      } else {
        VideoHandler.getInstance().getMeetingHandler().muteUser()
      }
    },
    onNewChatMessageReceived(messagePayload) {
      ChatHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnNewChatMessageReceived, this.onNewChatMessageReceived)
      if (VideoHandler.getInstance().isAdmin) {
        if (messagePayload.type === 'VideoCallDecline' || messagePayload.type === 'VideoCallEnded') {
          VideoHandler.getInstance().callMembers = VideoHandler.getInstance().callMembers.filter(member => member !== messagePayload.sender.userData.id)
          const name = ChatHandler.getInstance().getMeetingRequest().userData.firstname + ChatHandler.getInstance().getMeetingRequest().userData.lastname
          if (VideoHandler.getInstance().callMembers.length === 0) {
            this.declineCallAlert(`${name}`)
          } else {
            this.$toast(`${name} declined the call`)
          }
        }
      } else if (messagePayload.type === 'VideoCallEnded' || messagePayload.type === 'VideoCallDecline') {
        ChatHandler.getInstance().eventEmitter.emit('HideAudioCall', {})
      }
    },
  },
  onDeactivated() {
    ChatHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnNewChatMessageReceived, this.onNewChatMessageReceived)

    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnPermissionApproved, this.permissionApproved)
    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnPermissionError, this.permissionError)

    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnInitDone, this.onInitDone)
    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnSocketConnected, this.onSocketConnected)
    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnTrack, this.onTrack)
    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnTrackEnded, this.onTrackEnded)

    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnUserLeft, this.onUserLeft)
    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnUserJoined, this.onUserJoined)
    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnAllParticipants, this.onAllParticipants)
    VideoHandler.getInstance().cleanUp()
  },
  onUnmounted() {
    ChatHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnNewChatMessageReceived, this.onNewChatMessageReceived)

    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnPermissionApproved, this.permissionApproved)
    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnPermissionError, this.permissionError)

    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnInitDone, this.onInitDone)
    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnSocketConnected, this.onSocketConnected)
    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnTrack, this.onTrack)
    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnTrackEnded, this.onTrackEnded)

    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnUserLeft, this.onUserLeft)
    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnUserJoined, this.onUserJoined)
    VideoHandler.getInstance().getMeetingHandler().getEventEmitter().off(VaniEvent.OnAllParticipants, this.onAllParticipants)
    VideoHandler.getInstance().cleanUp()
  },

}
</script>

<style>
.video-call {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1032;
}
.call-screen {
  height: calc(100% - 66px) !important;
  display: flex;
  justify-content: space-between;
  position: relative !important;
  background-color: #fff;
  padding: 20px 50px;
}

.local-video_wrap {
  width: 80%;
  height: 100%;
  display: flex;
  /* align-items: center; */
  background-color: blueviolet;
  justify-content: center;
  /* background-color: turquoise; */
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.full-width {
  width: 100%;
}

.local-video_wrap .info {
  position: absolute;
  bottom: 10px;
  left: 15px;
}
.local-video_wrap .mic-status {
  position: absolute;
  top: 10px;
  right: 10px;
}
.remote-video_wrap {
  width: 17%;
  height: 100%;
  overflow-y: auto;
  padding-right: 5px;
  /* background-color: violet; */
}
.no-remote {
  display: none;
}
.remote-video_wrap::-webkit-scrollbar {
  /* appearance: none; */
  width: 6px;
}

.remote-video_wrap::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--color-icon);
}

.remote-video_wrap .name{
  bottom: 2px;
}
.remote-video_wrap .name p{
  font-size: 13px;
}

.video-box {
  position: relative;
  width: 100%;
  height: 140px;
  overflow: hidden;
  background-color: var(--color-text);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7367f0;
  margin-bottom: 10px;
}

.remote-video-box {
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
  background-color: var(--color-text);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.call-bottom{
  background-color: #fff;
  width: 100%;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid lightgrey;
}

.call-btns{
  display: flex;
}

.call-btn{
  width: 45px;
  height: 45px;
  background-color: #7367f0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.2);
}

.call-btn:not(:last-child){
  margin-right: 25px;
}
.participants-avatar {
  position: absolute;
    width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.participants-avatar span {
  font-size: 30px;
  text-transform: uppercase;
}

.status-icon {
  position: absolute;
  right: 8px;
  top: 8px;

  display: flex;
  align-items: center;
}

.participant-name {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.participant-name p {
  font-size: 14px;
}
#loading-bg {
  width: 100%;
  height: 100%;
  background: #fff;
  display: block;
  position: absolute;
  z-index: 10;
}
.loading-logo {
  position: absolute;
  left: calc(50% - 45px);
  top: 40%;
}
.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
  z-index: 100;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(121, 97, 249, 1);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(121, 97, 249, 1);
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}

</style>
