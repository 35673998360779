const standard = {
  set_1: ['fib_dropdown', 'multiple', 'open-end-writing', 'blank', 'speech', 'open-end-writing-speech'],
  set_2: ['multiple', 'matching_listening', 'open-end-writing', 'blank', 'speech', 'open-end-writing-speech'],
  set_3: ['matching', 'matching_listening', 'open-end-writing', 'blank', 'speech', 'open-end-writing-speech'],
  // page_1: {
  //   set_1: ['fib_dropdown', 'speech'],
  //   set_2: ['blank', 'matching'],
  //   set_3: ['multiple', 'speech'],
  // },
  // page_2: {
  //   set_1: ['fib_dropdown', 'speech'],
  //   set_2: ['blank', 'matching'],
  //   set_3: ['multiple', 'speech'],
  // },
  // page_3: {
  //   set_1: ['fib_dropdown', 'speech'],
  //   set_2: ['blank', 'matching'],
  //   set_3: ['multiple', 'speech'],
  // },
  // page_4: {
  //   set_1: ['fib_dropdown', 'speech'],
  //   set_2: ['blank', 'matching'],
  //   set_3: ['multiple', 'speech'],
  // },
}

export default standard
