<template>
  <validation-observer
    ref="createCardForm"
  >
    <!-- Options -->
    <shape-options
      v-if="type === 'shapes'"
      :template-data="templateData[0]"
      @template-data="(type, data) => { $emit('template-data', type, data) }"
    />

    <b-row v-if="templateData && templateData[0] && templateData[0].type !== 'fib'">
      <b-col
        v-for="(card,index) in templateData"
        :key="index"
        lg="3"
        md="6"
        sm="12"
        class="d-flex"
      >
        <b-card
          no-body
          class="p-1 w-100"
        >

          <b-button
            v-if="index>0"
            class="delete-button"
            variant="danger"
            size="sm"
            style="z-index:10"
            @click="templateData.splice(index,1)"
          >
            <feather-icon
              icon="XIcon"
            />
          </b-button>
          <image-upload
            v-if="type !== 'shapes'"
            title="Image"
            :is-required="type==='cards'"
            :url="card.image_url"
            @imageRemoved="()=>{card.image_url=null;card.image=null}"
            @imageUploaded="(file,url)=>{avoidSave ? uploadImage(file) : card.image=file;card.image_url=url;}"
          />

          <validation-provider
            v-slot="{ errors }"
            name="Title"
          >
            <b-form-textarea
              v-model="card.title"
              class="mt-1"
              :state="errors.length > 0 ? false:null"
              placeholder="title"
              rows="0"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <validation-provider
            v-if="type!=='cards'"
            v-slot="{ errors }"
            name="Card Subtitle"
          >
            <b-form-textarea
              v-model="card.subtitle"
              class="mt-1"
              :state="errors.length > 0 ? false:null"
              placeholder="subtitle"
              rows="1"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <div v-if="type==='paragraph'">
            <label
              for=""
              class="mt-1"
            >Image Position</label>
            <b-form-select
              v-model="card.imagePosition"
              :options="['left','right']"
            />
          </div>
        </b-card>
      </b-col>
      <b-col
        lg="3"
        md="6"
        sm="12"
        class="d-flex"
      >
        <b-card
          no-body
          class="p-1 w-100"
        >
          <div
            class="d-flex w-100 h-100 align-items-center justify-content-center bg-light-primary"
          >
            <b-button
              variant="flat-primary"
              @click="addCard"
            >
              <feather-icon
                icon="PlusIcon"
                size="100"
              />
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import ImageUpload from '@views/common/components/CustomImageUpload.vue'
import {
  BRow, BCol, BCard, BFormTextarea, BButton, BFormSelect,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ShapeOptions from './ShapeOptions.vue'

export default {
  components: {
    ImageUpload,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BButton,
    ShapeOptions,
    BFormSelect,
  },
  props: {
    type: {
      type: String, default: 'cards',
    },
    templateData: {
      type: [Array, null],
      required: true,
    },
    avoidSave: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fibType() {
      if (this.templateData) return this.templateData[0]?.type
      return null
    },
  },
  watch: {
    fibType(val) {
      if (val === 'qa') {
        this.templateData[0].title = 'Question'
        this.templateData[0].subtitle = '***Answer***'
      }
    },
  },
  methods: {
    uploadImage(file, index) {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      data.append('upload', file)

      useJwt.axiosIns.post('/api/v1/editor/image/upload', data, config).then(res => {
        this.templateData[index].image_url = res.data.url
      })
    },
    addCard() {
      const data = {
        image_url: 'https://via.placeholder.com/350x150',
        title: this.type === 'shapes' || this.type === 'image-qa' ? 'Question' : 'Example',
        subtitle: this.type === 'shapes' || this.type === 'image-qa' ? '***Answer***' : 'Example',
        body: 'example',
        imagePosition: 'left',
      }
      if (this.type === 'shape') data.shape = this.shape

      this.templateData.push(data)
    },
  },
}
</script>

<style scoped>
.delete-button{
  position: absolute;
  right: 8px;
  top: 8px;
}
.shape-input {
  width: 264px;
}
</style>
