var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{attrs:{"body-class":"p-0"}},[_c('b-table-simple',{staticClass:"mb-0",attrs:{"bordered":"","striped":""}},[_c('b-thead',[_c('b-tr',_vm._l((_vm.levels),function(level,index){return _c('b-th',{key:level,style:({
              backgroundColor: _vm.langLevelColor[index],
              color: 'white',
            }),attrs:{"role":"button"},on:{"click":function($event){return _vm.onClickLangLevel(level)}}},[_vm._v(" "+_vm._s(level)+" ")])}),1)],1),_c('b-tbody',_vm._l((_vm.maxRow),function(row){return _c('b-tr',{key:row},_vm._l((_vm.levels),function(level){return _c('b-td',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],key:level,staticClass:"problemValue",attrs:{"draggable":true,"data-id":`${level}-${row-1}`,"role":"button","set":_vm.problemName = _vm.getProblemName(level, row - 1),"title":_vm.getLessonName(level, row - 1)},on:{"drop":function($event){return _vm.onDrop($event)},"dragstart":function($event){return _vm.startDrag($event, `${level}-${row-1}`)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_vm._v(" "+_vm._s(_vm.problemName || '-')+" "),(_vm.problemName )?_c('feather-icon',{staticClass:"removeIcon d-none text-danger",attrs:{"icon":"XIcon"},on:{"click":function($event){return _vm.$emit('onRemoveProblem', {
                level,
                index: row -1
              })}}}):_vm._e()],1)}),1)}),1)],1)],1),_c('b-button',{attrs:{"variant":"outline-primary","disabled":_vm.isProcessing},on:{"click":function($event){return _vm.$emit('onSubmit')}}},[_vm._v(" Save ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }