<template>
  <div class="reportArea">
    <div v-if="hasReport">
      <b-card no-body>
        <b-card-header class="pt-1 p-0 pl-1 pb-0 d-flex justify-content-between">
          <div>
            <b-button
              variant="outline-primary"
              size="sm"
              class="mb-0 mr-2 mb-1"
              @click="$router.push({ name: 'demo-lesson' })"
            >
              <feather-icon
                icon="HomeIcon"
              />
              Home
            </b-button>
          </div>
          <div>
            <h4>Report</h4>
          </div>
          <div>
            <b-button
              variant="outline-primary"
              size="sm"
              class="mb-0 mr-2 mb-1"
              @click="$emit('playAgain')"
            >
              Try Again
            </b-button>
            <b-button
              v-if="isASuper"
              variant="outline-primary"
              size="sm"
              class="mb-0 mr-2 mb-1"
              @click="openEditProblem"
            >
              Edit Problem
            </b-button>
            <b-button
              v-if="curriculumPageUrl"
              variant="outline-primary"
              size="sm"
              class="mb-0 mr-2 mb-1"
              @click="openCurriculumProblem"
            >
              Edit Problem From curriculum
            </b-button>          </div>
        </b-card-header>

        <b-card-body class="p-0">
          <b-table
            responsive
            :fields="fields"
            :items="groupReportData"
          >
            <template #cell(lang_level)="data">
              <b-badge :style="{background: getColorForLevel( data.item.lang_level)}">
                {{ data.item.lang_level }}
              </b-badge>
            </template>
            <template #cell(wrongs)="data">
              <span
                v-for="(w,index) of data.item.wrongs"
                :key="index"
              >
                <feather-icon
                  v-if="w == 0"
                  icon="CheckIcon"
                  class="text-success"
                />
                <feather-icon
                  v-else
                  icon="XIcon"
                  class="text-danger"
                />
              </span>
            </template>
          </b-table>
        </b-card-body>
        <b-card-footer>
          <b-row>
            <b-col sm="5">
              <b>
                <feather-icon icon="BookOpenIcon" />
                Reading Time
              </b>
            </b-col>
            <b-col
              sm="7"
              class="pull-right"
            >
              <div class="float-right">
                {{ sumTimeStamp('reading_time') }}
              </div>
            </b-col>
          </b-row>
          <b-row class="pt-1">
            <b-col sm="4"
                   class="text-nowrap"
            >
              <b>
                <feather-icon icon="BarChart2Icon" />
                Solving Time</b>
            </b-col>
            <b-col sm="8">
              <div class="float-right">
                {{ sumTimeStamp('solving_time') }}
              </div>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-card>
    </div>
    <div v-else>
      <b-card>
        <b-card-body class="text-center">
          <div>
            <b-button
              variant="outline-primary"
              size="sm"
              class="mb-0 mr-2 mb-1"
              @click="$router.push({ name: 'demo-lesson' })"
            >
              <feather-icon
                icon="HomeIcon"
              />
              Home
            </b-button>
            <b-button
              variant="outline-primary"
              size="sm"
              class="mb-0 mr-2 mb-1"
              @click="$emit('playAgain')"
            >
              Try Again
            </b-button>
            <b-button
              v-if="isASuper"
              variant="outline-primary"
              size="sm"
              class="mb-0 mr-2 mb-1"
              @click="openEditProblem"
            >
              Edit Problem
            </b-button>
            <b-button
              v-if="curriculumPageUrl"
              variant="outline-primary"
              size="sm"
              class="mb-0 mr-2 mb-1"
              @click="openCurriculumProblem"
            >
              Edit Problem From curriculum
            </b-button>
          </div>
          <h4>Welcome to GEC Demo Play</h4>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BTable, BBadge, BCardFooter, BRow, BCol, BCardHeader, BButton,
} from 'bootstrap-vue'
import { getColorForLevel } from '@/const/langLevel'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BCardBody,
    BTable,
    BBadge,
    BCardFooter,
    BRow,
    BCol,
    BCardHeader,
    BButton,
  },
  props: {
    reportData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fields: [
        { label: 'Probem', key: 'name' },
        { label: 'Type', key: 'type' },
        { label: 'Level', key: 'lang_level' },
        { label: 'Result', key: 'wrongs' },
      ],
      getColorForLevel,
      isASuper: this.AUTH_USER()?.usertype === 'super',
      curriculumPageUrl: null,
    }
  },
  computed: {
    hasReport() {
      return this.reportData?.data?.length
    },
    groupReportData() {
      const key = 'problem_id'
      const groupReport = this.reportData.data.reduce((r, a) => {
        // eslint-disable-next-line no-param-reassign
        r[a[key]] = r[a[key]] || []
        r[a[key]].push(a)
        return r
      }, Object.create(null))
      const reportToShow = []
      Object.keys(groupReport).forEach(keyPair => {
        const reportArray = groupReport[keyPair]
        const report = reportArray[reportArray.length - 1]
        reportToShow.push({
          name: report.name,
          type: report.type,
          wrongs: groupReport[keyPair].map(r => r.wrong),
          lang_level: report.lang_level,
          order: report.index,
        })
      })
      reportToShow.sort((a, b) => a.order - b.order)
      return reportToShow
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.problem.activeProblem': {
      handler(newValue) {
        if (this.isASuper) {
          this.curriculumPageUrl = null
          useJwt.getPageFromProblem(newValue.id).then(response => {
            if (response.data.data.page) {
              const { page } = response.data.data
              const setNo = response.data.data.set_no
              this.curriculumPageUrl = `/super/${page.id}/edit-curriculum-page?set_no=${setNo}`
            }
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    openEditProblem() {
      const { activeProblem } = this.$store.state.problem
      window.open(`/super/problem/edit?problem_id=${activeProblem.id}&lang_level=${activeProblem.questions[0]?.lang_level}`)
    },
    openCurriculumProblem() {
      window.open(this.curriculumPageUrl)
    },
    convertHMS(value) {
      const sec = parseInt(value, 10) // convert value to number if it's string
      let hours = Math.floor(sec / 3600) // get hours
      let minutes = Math.floor((sec - (hours * 3600)) / 60) // get minutes
      let seconds = sec - (hours * 3600) - (minutes * 60) //  get seconds
      // add 0 if value < 10; Example: 2 => 02
      if (hours < 10) { hours = `0${hours}` }
      if (minutes < 10) { minutes = `0${minutes}` }
      if (seconds < 10) { seconds = `0${seconds}` }
      return `${hours}:${minutes}:${seconds}` // Return is HH : MM : SS
    },
    sumTimeStamp(key) {
      const actualReport = this.reportData.data
      const timestamps = actualReport.map(r => r[key])
      const toSeconds = s => {
        const parts = s.split(':')
        return +parts[0] * 3600 + +parts[1] * 60 + +parts[2]
      }
      const totalSeconds = timestamps.reduce((total, ts) => total + toSeconds(ts), 0)
      return this.convertHMS(totalSeconds)
    },
  },
}
</script>
<style scoped>
  .reportArea {
    max-height: 100vh;
    overflow-y: scroll;
  }
</style>
