<template>
  <validation-observer ref="selfEvaluationForm">
    <form class="personality-assessment-form">
      <b-overlay :show="isProcessing">
        <b-row>
          <b-col
            v-for="field, group in allPersonalities"
            :key="field.value"
            md="6"
          >
            <b-card border-variant="secondary">
              <b-form-group class="my-1">
                <validation-provider
                  :id="field.name"
                  v-slot="{ errors }"
                  :name="group"
                  rules="required"
                >
                  <b-form-radio-group
                    :name="group"
                    inline
                    @change="() => { disabled = false }"
                  >

                    <b-form-radio
                      v-for="f of field"
                      :key="f.id"
                      v-model="form[group]"
                      :value="f.id"
                    >
                      {{ f.name }}  <feather-icon
                        v-if="f.description"
                        v-b-tooltip.hover
                        class="text-primary"
                        icon="InfoIcon"
                        size="18"
                        :title="f.description"
                      />
                    </b-form-radio>
                  </b-form-radio-group>
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >
                    {{ $t('self-evaluation.required') }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>
    </form>

    <b-button
      variant="primary"
      :disabled="isProcessing || disabled"
      class="mt-2 float-right"
      @click="handleSubmit"
    >
      {{ editMode ? $t('actions.update') : $t('actions.submit') }}
    </b-button>
  </validation-observer>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BOverlay, BRow, BCol, BFormGroup, BFormRadioGroup, BButton, BCard, BFormRadio, VBTooltip,
} from 'bootstrap-vue'
import { USER_TYPE_SCHOOL } from '@/const/userType'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import GEC_EVENT_BUS from '@/utils/eventBus'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    BCard,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    student: {
      type: Number,
      default: () => null,
    },
    user: {
      type: Object,
      default: () => null,
    },
    personalityByGroup: {
      type: [Array, Object],
      default: () => null,
    },
    personality: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      form: {},
      personalities: [],
      studentPersonalities: null,
      isProcessing: false,
      editMode: false,
      disabled: false,
    }
  },
  computed: {
    isASchool() {
      return this.user.usertype === USER_TYPE_SCHOOL
    },
    allPersonalities() {
      return this.personalityByGroup ? this.personalityByGroup : this.personalities
    },
  },
  watch: {
    student() {
      this.resetForm()
      this.getStudentPersonality()
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      if (!this.personalityByGroup) this.getPersonalities()
      else this.loadForm(this.personalityByGroup, this.personality)
      if (this.student) this.getStudentPersonality()
    },
    handleSubmit() {
      this.$refs.selfEvaluationForm.validate()
        .then(success => {
          if (success) {
            const personalities = Object.values(this.form)
            if (this.editMode === true) this.updatePersonality(personalities)
            else this.storePersonality(personalities)
          }
        })
    },
    storePersonality(personalities) {
      this.isProcessing = true

      const params = {
        user_id: this.student ? this.student : this.user.id,
        personality_id: personalities,
        evaluated_by: this.user.id,
      }
      useJwt.storeStudentPersonality(params).then(response => {
        this.showSuccessMessage(response)
      }).finally(() => {
        this.isProcessing = false
        this.$emit('close')
        this.editMode = true
        this.disabled = true
      })
    },
    updatePersonality(personalities) {
      this.isProcessing = true
      const params = {
        user_id: this.student ? this.student : this.user.id,
        personality_id: personalities,
        evaluated_by: this.user.id,
      }

      useJwt.updateStudentPersonality(this.student ? this.student : this.user.id, params).then(response => {
        this.showSuccessMessage(response)
      }).finally(() => {
        GEC_EVENT_BUS.$emit('reloadStudentPersonality')
        this.$emit('close')
        this.isProcessing = false
        this.disabled = true
      })
    },
    getPersonalities() {
      this.isProcessing = true
      useJwt.getPersonalitiesByGroup().then(res => {
        this.personalities = res.data?.data
      }).finally(() => { this.isProcessing = false })
    },
    getStudentPersonality() {
      this.isProcessing = true
      useJwt.getPersonalityByEvaluatedBy(this.student, this.user.id).then(response => {
        const studentPersonality = response.data?.data?.personality_id
        if (studentPersonality) {
          this.loadForm(this.personalities, studentPersonality)
          this.editMode = true
          this.disabled = true
        } else {
          this.editMode = false
          this.disabled = false
        }
      }).finally(() => { this.isProcessing = false })
    },
    loadForm(personalities, studentPersonality) {
      const form = {}
      this.editMode = false
      this.disabled = false
      Object.keys(personalities).forEach(key => {
        form[key] = personalities[key]
          .find(personality => studentPersonality.includes(personality.id)).id
      })
      this.form = form
    },
    resetForm() {
      this.form = {}
      this.$refs.selfEvaluationForm.reset()
    },
  },
}
</script>

<style>
.personality-assessment-form .bv-no-focus-ring{
  display: flex;
  justify-content: space-between;
}
.personality-assessment-form .custom-radio {
  flex: 1;
}
.personality-assessment-form .card-body {
  padding: 12px 16px;
}
</style>
