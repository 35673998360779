<template>
  <div>
    <b-table
      :items="items"
      :fields="fields"
      responsive
    >
      <template #cell(status)="data">
        <span
          v-if="data.item.status === 'in_progress'"
          class="text-warning"
        >{{
          data.item.status
        }}</span>
        <span
          v-else-if="data.item.status === 'passed'"
          class="text-success"
        >{{
          data.item.status
        }}</span>
        <span
          v-else-if="data.item.status === 'failed'"
          class="text-danger"
        >{{
          data.item.status
        }}</span>
      </template>
      <template #cell(cefr_level)="data">
        <span
          class="badge"
          :style="{
            background: getColorForLevel(data.item.cefr_level),
            padding: '6px',
          }"
        >{{ data.item.cefr_level }}</span>
      </template>
      <template #cell(actions)="data">
        <b-button
          size="sm"
          variant="outline-primary"
          class="btn-icon mr-1"
          @click.stop="$emit('showStat', data.index)"
        >
          <feather-icon icon="EyeIcon" />
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable, BButton } from 'bootstrap-vue'
import { getColorForLevel } from '@/const/langLevel'

export default {
  components: {
    BTable,
    BButton,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    fields: ['lesson', 'cefr_level', 'points', 'round', 'time_spent', 'status', 'actions'],
  }),
  methods: {
    getColorForLevel,
  },
}
</script>
