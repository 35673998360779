<template>
  <div class="mt-2">
    <div
      class="media-list"
    >
      <div
        v-for="(item,index) in data"
        :key="'list_'+index"
        class="media px-0"
      >
        <div
          class="media-aside align-self-start"
        >
          <img
            v-image-preview
            :src="item.image_url"
            style="width: 75px; height: 75px;object-fit:cover"
          >
        </div>

        <div
          class="media-body"
        >
          <p
            v-text-to-speech
            class="card-text mb-0"
          >
            {{ item.title }}
          </p>

          <p
            v-text-to-speech
            class="card-text mb-0"
          >
            {{ item.subtitle }}
          </p>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style>

</style>
