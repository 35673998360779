<template>
  <div>
    <b-card
      :title="$t('engagement-setting-module.engagement-setting')"
    >
      <b-row>
        <b-col
          v-if="self.usertype ===USER_TYPE_STUDENT"
          sm="6"
          class=""
        >
          <h6 class="mb-2">
            {{ $t('engagement-setting-module.companion-avatar') }}
          </h6>
          <b-card
            v-if="selectedCharacter"
            no-header
            class="character-card"
            style="max-height:180px; max-width:180px; border:2px solid black;"
          >
            <img
              class="w-100 h-100"
              :src="require('@/assets/images/interactive-avatars'+characters[selectedCharacter])"
            >
          </b-card>
          <b-button
            variant="primary"
            class="mt-1"
            :disabled="isProcessing"
            @click="toggleCharacterModal = true"
          >
            {{ selectedCharacter ? $t('engagement-setting-module.update-character') :$t('actions.select-character') }}
          </b-button>

          <b-form-checkbox
            class="mt-1"
            :checked="showAvatarStudent"
            @change="updateCharacterShow"
          >
            {{ $t('engagement-setting-module.show-character') }}
          </b-form-checkbox>

        </b-col>
        <!-- v-if="isStudentYoung() || !optionsLocal.dob" -->
        <b-col
          v-if="(self.usertype==USER_TYPE_STUDENT && isLargeDevices)"
          cols="6"
          class="pt-2"
        >
          <h6>
            {{ $t('engagement-setting-module.3d-themes') }}
          </h6>
          <b-form-checkbox
            v-model="themeOn"
            class="custom-control-primary"
            name="check-button"
            switch
            inline
          >
            {{ $t('engagement-setting-module.turn-on-theme') }}
          </b-form-checkbox>

          <b-row
            v-if="themeOn"
            class="pt-1"
          >
            <b-col
              md="12"
            >
              <h4>
                {{ $t('engagement-setting-module.select-theme') }}:
              </h4>
            </b-col>
          </b-row>
          <b-row
            class="radio-grid pt-2"
          >
            <div
              v-for="theme in allThemes"
              :key="theme.name"
            >
              <h6>{{ theme.name }}</h6>
              <label class="radio-card">
                <input
                  name="theme"
                  class="radio-input"
                  type="radio"
                  :value="theme.code"
                  :checked="theme.code==currentTheme"
                  @change="updateCurrentTheme"
                >

                <span class="theme-details">

                  <img
                    :src="theme.image"
                    class="w-100 h-100"
                  >
                </span>
              </label>
            </div>
          </b-row>

          <b-row class="pt-1">
            <b-col cols="12">
              <b-form-checkbox
                v-model="alarmOn"
                class="custom-control-primary"
                name="check-button"
                switch
                inline
              >
                {{ $t('engagement-setting-module.turn-on-alarm') }}
              </b-form-checkbox>
            </b-col>
          </b-row>

        </b-col>
        <b-col
          v-if="(self.usertype==USER_TYPE_STUDENT && !optionsLocal.dob)"
          cols="12"
          class="p-2"
        >
          <h6 class="text-info">

            {{ $t('engagement-setting-module.set-dob') }}
          </h6>
        </b-col>
      </b-row>
    </b-card>
    <select-character
      :toggle="toggleCharacterModal"
      @selectCharacterModalClosed="toggleCharacterModal=false"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_STUDENT } from '@/const/userType'
import SelectCharacter from '@/views/common/components/SelectCharacters.vue'
import { CHARACTERS } from '@/const/avatar'
import { THEMES } from '@/const/theme'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    SelectCharacter,
  },
  data() {
    return {
      self: getUserData(),
      characters: CHARACTERS,
      allThemes: THEMES,
      toggleCharacterModal: false,
      optionsLocal: {},
      isProcessing: false,

    }
  },

  computed: {
    USER_TYPE_STUDENT() {
      return USER_TYPE_STUDENT
    },
    selectedCharacter() {
      return this.$store.state.appConfig.selectedCharacter
    },
    showAvatarStudent() {
      return this.$store.state.appConfig.showAvatarStudent
    },
    themeOn: {
      get() {
        // return localStorage.getItem('themeOn')
        return this.$store.state.appConfig.themeOn
      },
      set(value) {
        this.$store.commit('appConfig/UPDATE_THEME_ON', value)
        this.storeThemeSettings(value, 'theme_on')
      },
      immediate: true,
    },
    alarmOn: {
      get() {
        return this.$store.state.appConfig.alarmOn
      },
      set(value) {
        this.$store.commit('appConfig/UPDATE_ALARM_ON', value)
      },
    },
    currentTheme: {
      get() {
        return this.$store.state.appConfig.currentTheme
      },
      set(value) {
        this.$store.commit('appConfig/UPDATE_CURRENT_THEME', value)
        this.storeThemeSettings(value, 'current_theme')
      },
    },

    isLargeDevices() {
      return window.innerWidth > 1068
    },
  },
  created() {
    this.getUserData()
  },
  methods: {
    isStudentYoung() {
      return this.$store.getters['appConfig/getIsStudentYoung']
    },
    updateCurrentTheme(e) {
      if (!this.optionsLocal.dob) {
        this.showError('To experience these themes, you need to set your date of birth.')
      } else {
        this.currentTheme = e.target.value
      }
    },

    updateCharacterShow(value) {
      localStorage.setItem('showAvatarStudent', value)
      this.$store.commit('appConfig/UPDATE_SHOW_AVATAR_STUDENT', value)
    },

    getUserData() {
      this.isProcessing = true
      useJwt
        .userShow()
        .then(response => {
          const generalData = response.data.data
          this.optionsLocal = JSON.parse(JSON.stringify(generalData))
          if (!this.optionsLocal.country) this.optionsLocal.country = null
        }).finally(() => {
          this.isProcessing = false
        })
    },
    storeThemeSettings(value, type) {
      // console.log(value, type)
      useJwt.updateUserSettings({ key: type, value })
        .then(response => {
          this.showSuccessMessage(response)
        })
    },

  },
}
</script>
