<template>
  <div class="mt-2">
    <slot />
    <hr>
    <b-row>
      <b-col cols="2"
             class="border-right"
      >
        <span v-if="canManageWeakestSkill"
              @click.stop
        >
          <b-form-checkbox v-model="hasMultiPractice"
                           switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </span>
      </b-col>
      <b-col>
        {{ $t('multi-practice-info') }}
      </b-col>
    </b-row>
    <div v-if="canManageWeakestSkill && lessonId && !isConversational && isCbGenerated">
      <hr>
      <b-row>
        <b-col cols="2"
               class="border-right"
        >
          <b-dropdown
            tag="span"
            text=""
            variant="flat-primary"
            no-caret
            dropup
            class="lesson-setting btn-outline-primary"
          >
            <template #button-content>
              <span
                class="btn-icon sm-mt-2"
              >
                <feather-icon icon="SettingsIcon" />
              </span>
            </template>
            <template v-if="isCbGenerated">
              <b-dropdown-item @click.stop>
                <span @click.stop>
                  <b-form-group
                    @click.stop
                  >
                    <legend
                      class="bv-no-focus-ring col-form-label"
                      @click.stop
                    >
                      {{ $t('class-module.engine') }}
                      <feather-icon
                        size="16"
                        icon="InfoIcon"
                        style="cursor: pointer"
                        @click.stop="showEngineModal = true"
                      />
                    </legend>
                    <b-form-select v-model="defaultEngine"
                                   @click.stop
                    >
                      <option v-for="engine of defaultEngineOptions"
                              :key="engine.value"
                              :value="engine.value"
                              @click.stop
                      >
                        {{ engine.text }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                </span>
              </b-dropdown-item>
              <template v-if="canShowWeakestSkill(lesson)">
                <b-dropdown-item v-if="!!lesson.rules && canManageWeakestSkill && isCbGenerated"
                                 tag="span"
                                 @click.stop
                >
                  <span
                    @click.stop
                  >
                    <b-form-checkbox
                      v-model="lesson.rules.has_weakest_skill"
                      switch
                    >
                      <span v-if="lesson.rules.has_weakest_skill">
                        {{ $t('template-module.weakest-skill-on') }}
                      </span>
                      <span v-else>
                        {{ $t('template-module.weakest-skill-off') }}
                      </span>
                    </b-form-checkbox>
                  </span>
                </b-dropdown-item>
                <template v-if="lesson.rules && canManageWeakestSkill && isCbGenerated">
                  <b-dropdown-item v-for="lRule,i of lessonRulesOptions.filter(item => item.on_if_weakest_skill == (!!lesson.rules.has_weakest_skill || !!item.always_on) && item.ignoreEngine !== defaultEngine)"
                                   :key="i"
                                   @click.stop
                  >
                    <b-form-group
                      :label="lRule.text"
                      label-cols-lg="5"
                      class="mb-0"
                      @click.stop
                    >
                      <select v-model="lesson.rules[lRule.key]"
                              class="form-control"
                              placeholder="Select"
                              @click.stop
                      >
                        <option v-for="opt of lRule.options"
                                :key="opt.value"
                                :value="opt.value"
                                @click.stop
                        >
                          {{ opt.text }}
                        </option>
                      </select>
                    </b-form-group>
                  </b-dropdown-item>
                </template>
              </template>
            </template>
          </b-dropdown>
        </b-col>
        <b-col>
          {{ $t('weakest-skill-info') }}
        </b-col>
      </b-row>
    </div>
    <div v-if="isConversational">
      <b-form-group>
        <template #label>
          <div class="small">
            {{ $t('labels.create-problem.skill-type') }}
          </div>
        </template>
        <b-form-select
          id="conversationalOptions"
          v-model="lesson.rules.conversational_skill_type"
          :options="conversationalOptions"
        />
      </b-form-group>
    </div>
    <engine-info-modal
      :show-engine-modal="showEngineModal"
      @close="showEngineModal = false"
    />
  </div>
</template>

<script>
import {
  BDropdown, BFormSelect, BFormGroup, BFormCheckbox, BDropdownItem, BRow, BCol,
} from 'bootstrap-vue' // Import BootstrapVue components
import SkillTypes from '@/const/skillTypes'
import { LANG_LEVELS } from '@/const/langLevel'
import useJwt from '@/auth/jwt/useJwt'
import { checkSettings } from '@/utils/visibilitySettings'
import EngineInfoModal from '@/views/common/components/EngineInfoModal.vue'

const INITIAL_ENGINE = 'adaptivePathEngine'

export default {
  components: {
    BDropdown,
    BFormSelect,
    BFormGroup,
    BFormCheckbox,
    BDropdownItem,
    BRow,
    BCol,
    EngineInfoModal,
  },
  props: {
    isATeacher: {
      type: Boolean,
      default: false,
    },
    courseId: {
      type: Number,
      default: 0,
    },
    lessonId: {
      type: [Number, Array],
      default: 0,
    },
    isConversational: {
      type: Boolean,
      default: false,
    },
    currentWeakestSkill: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      defaultEngineOptions: [
        { text: 'Default', value: null },
        { text: 'DDF', value: 'ddf' },
        { text: 'CAF', value: 'caf' },
        { text: 'Linear', value: 'linearStandAloneEngine' },
        { text: 'Adaptive Path Engine', value: 'adaptivePathEngine' },
      ],
      lesson: {},
      defaultEngine: null,
      isCbGenerated: false,
      isScormLesson: false,
      hasMultiPractice: false,
      // hasSchoolDistrictMultiPractice: false,
      lessonRulesOptions: [
        {
          text: this.$i18n.tc('student-report-module.report-pages.skill'),
          options: [
            ...SkillTypes.filter(item => !!item.value),
            {
              text: this.$i18n.tc('oe-writing'),
              value: 'oe-writing',
            },
            {
              text: this.$i18n.tc('oe-speaking'),
              value: 'oe-speaking',
            },

          ],
          key: 'skill_type',
          on_if_weakest_skill: false,
        },
        {
          text: 'Problem Type',
          key: 'problem_type',
          on_if_weakest_skill: true,
          options: [
            {
              text: 'Prompted',
              value: 'prompted',
            },
            {
              text: 'Open Ended',
              value: 'open-ended',
            },
          ],
        },
        {
          text: 'Level',
          options: [...LANG_LEVELS.map((item, index) => ({ text: item, value: index + 1 }))],
          key: 'level',
          always_on: true,
          ignoreEngine: 'adaptivePathEngine',
          on_if_weakest_skill: true,
        },
      ],
      conversationalOptions: [
        {
          text: this.$i18n.tc('curriculum-pages-module.select-skill-type'),
          value: '',
        },
        {
          text: this.$i18n.tc('oe-writing'),
          value: 'oe-writing',
        },
        {
          text: this.$i18n.tc('oe-speaking'),
          value: 'oe-speaking',
        },
      ],
      skill_type: '',
      showEngineModal: false,
    }
  },
  computed: {
    canShow() {
      return this.isATeacher && this.lessonId
    },
    isSuperAdmin() {
      return this.AUTH_USER().usertype === 'super'
    },
    canManageWeakestSkill() {
      return this.isSuperAdmin || this.isATeacher
    },
    hasSchoolDistrictMultiPractice() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const setting = checkSettings('multi_practice', schoolSetting, distSetting)
      return setting.value === '1'
    },
  },
  mounted() {
    if (this.courseId && this.lessonId) {
      this.getClassRoomLesson()
    }

    // checkSettings('multi_practice').then(response => {
    //   if (response.value === '1') {
    //     this.hasSchoolDistrictMultiPractice = true
    //   }
    // })
  },
  methods: {
    canShowWeakestSkill() {
      return this.defaultEngine === 'adaptivePathEngine' || this.defaultEngine === 'linearStandAloneEngine'
    },
    getClassRoomLesson() {
      useJwt.getClassRoomLesson(this.$route.params.classId, this.courseId, this.lessonId)
        .then(response => {
          const responseData = response.data.data
          this.isScormLesson = responseData.isScormLesson
          // this.defaultEngine = responseData?.default_engine === undefined ? INITIAL_ENGINE : responseData?.default_engine
          // this.hasMultiPractice = this.hasSchoolDistrictMultiPractice ? this.hasSchoolDistrictMultiPractice : !!responseData?.has_multi_practice
          // const rules = Array.isArray(responseData?.rules) && !responseData?.rules.length ? {} : (responseData?.rules || {})
          this.defaultEngine = this.currentWeakestSkill?.default_engine || INITIAL_ENGINE
          this.hasMultiPractice = !!this.currentWeakestSkill?.has_multi_practice
          const rules = this.currentWeakestSkill?.rules || {}
          this.lesson = {
            rules: Array.isArray(rules) ? {} : rules,
          }
          this.isCbGenerated = responseData.isCbGenerated
          this.$forceUpdate()
        })
    },
  },
}
</script>
<style scoped>
::v-deep .custom-switch {
  zoom: 1.3;
}
</style>
