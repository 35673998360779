<template>
  <div>
    <h3 class="mb-2">
      {{ $t('setting-module.classroom-student-lab-settings') }}
    </h3>
    <b-table-simple
      responsive
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th
            width="40%"
          >
            {{ $t('setting-module.options') }}
          </b-th>
          <b-th
            width="40%"
          >
            {{ $t('setting-module.switch') }}
          </b-th>
          <b-th
            width="20%"
          >
            {{ $t('setting-module.help-guide') }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>{{ $t('setting-module.large-font-size-title') }}
          </b-td>
          <b-td>
            <span class="d-flex align-items-baseline">
              <b-form-checkbox v-model="allSettings.larger_font"
                               value="1"
                               unchecked-value="0"
                               switch
                               @change="updateClassRoomSetting('larger_font')"
              />
              <span class="ml-2">
                {{ $t('setting-module.off-on') }}
              </span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('larger_font')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>{{ $t('setting-module.speech-tries-title') }}</b-td>
          <b-td>
            <span class="d-flex align-items-baseline">
              <b-form-input
                v-model="allSettings.speech_tries"
                class="col-3"
                type="number"
                min="0.00"
                @input="(value)=>{value==0 && (allSettings.speech_tries = 1)}"
                @change="updateClassRoomSetting('speech_tries')"
              />
              <span class="ml-2">
                {{ $t('setting-module.times') }}
              </span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('speech_tries')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>{{ $t('setting-module.font-title') }}</b-td>
          <b-td>
            <span class="align-items-baseline">
              <b-form-select
                v-model="allSettings.font"
                class="m-100"
                :options="fontsOptions"
                @change="updateClassRoomSetting('font')"
              />
              <span style="font-size: 12px; margin-top:5px">
                Demo Font: <br>
                <span :style="fontSelected">
                  A B C D E F G H I J k L M N O P Q R S T U V W X Y Z <br>
                  a b c d e f g h i j k l m n o p q r s t u v w x y z
                </span>
              </span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('font')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>

    </b-table-simple>
    <setting-info-modal
      :show="showModal"
      :modal-data="modalData"
      @close="showModal = false"
    />
  </div>
</template>
<script>
import {
  BFormCheckbox, BButton, BFormInput, BFormSelect,
  BTableSimple,
  BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import fonts from '@/const/fonts'
import i18n from '@/libs/i18n'
import SettingInfoModal from '@/views/common/components/SettingInfoModal.vue'

export default {
  components: {
    BFormCheckbox,
    BButton,
    BFormInput,
    BFormSelect,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    SettingInfoModal,
  },
  props: {
    allSettings: {
      type: Object,
      default: () => {},
    },
    classId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      modalData: {
        title: '',
        imgSrc: '',
        description: '',
        steps: '',
      },
      fontsOptions: fonts,
      modalInfo: {
        larger_font: {
          title: i18n.tc('setting-module.large-font-size-title'),
          description: i18n.tc('setting-module.large-font-size'),
          imageSrc: '/school-settings/font-setting.png',
          steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Click on Course</li>'
                + '<li>Select Lesson</li>'
                + '<li>Click Start button</li>'
                + '<li>In the Student Lab, fonts size will be larger.</li>'
                + '</ul>',
        },
        speech_tries: {
          title: i18n.tc('setting-module.speech-tries-title'),
          description: i18n.tc('setting-module.speech-tries'),
          imageSrc: '',
          steps: '',
        },
        font: {
          title: i18n.tc('setting-module.font-title'),
          description: i18n.tc('setting-module.font'),
          imageSrc: '/school-settings/font-setting.png',
          steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Click on Course</li>'
                + '<li>Select Lesson</li>'
                + '<li>Click Start button</li>'
                + '<li>In the Student Lab, fonts of text will be changed.</li>'
                + '</ul>',
        },
      },
    }
  },
  computed: {
    fontSelected() {
      const selectedOption = fonts.find(option => option.value === this.allSettings?.font)
      return { 'font-family': selectedOption?.text }
    },
  },
  methods: {
    updateClassRoomSetting(type) {
      if (type === 'font') {
        const selectedFont = fonts.find(option => option.value === this.allSettings[type])
        if (localStorage.getItem('schoolData')) {
          const schoolData = JSON.parse(localStorage.getItem('schoolData'))
          schoolData.font = selectedFont.text
          localStorage.setItem('schoolData', JSON.stringify(schoolData))
        } else {
          const schoolData = {
            font: selectedFont.text,
          }
          localStorage.setItem('schoolData', JSON.stringify(schoolData))
        }
      }
      useJwt.updateClassroomSetting(this.classId, type, {
        value: this.allSettings[type],
      }).then(res => {
        this.showSuccessMessage(res)
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    showHelpGuide(type) {
      this.showModal = true
      this.modalData = {
        title: this.modalInfo[type].title,
        imgSrc: this.modalInfo[type].imageSrc,
        description: this.modalInfo[type].description,
        steps: this.modalInfo[type].steps,
      }
    },
  },
}
</script>
