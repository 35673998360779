<template>
  <validation-observer
    ref="createTableForm"
  >
    <b-row v-if="templateData[0]">
      <b-col
        md="6"
      >
        <b-card
          no-body
          class="p-1 w-100"
        >

          <b-card-header class="d-flex pt-0 px-0 justify-content-end">
            <b-button
              variant="outline-primary"
              class="mr-1 btn-sm"
              @click="addColumn"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('template-module.add-column') }}</span>
            </b-button>
            <b-button
              variant="outline-primary"
              class="btn-sm"
              @click="addRow"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('template-module.add-row') }}</span>
            </b-button>
          </b-card-header>
          <table
            role="table"
            class="table b-table table-hover"
          >
            <thead>
              <tr>
                <th
                  v-for="(col,index) in templateData[0].columns"
                  :key="'column_number_'+index"
                  style="position:relative"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Column"
                    rules="required"
                  >
                    <b-form-input
                      v-model="templateData[0].columns[index]"
                      class="m-0 py-0"
                      :state="errors.length > 0 ? false:null"
                    />
                  </validation-provider>
                  <b-button
                    class="delete-button p-0 m-0"
                    variant="danger"
                    size="sm"
                    @click="deleteColumn(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                    />
                  </b-button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row,index) in templateData[0].rows"
                :key="'row_'+index"
                style="position:relative"
              >
                <td
                  v-for="(item,itemIndex) in row"
                  :key="'field'+index+itemIndex"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Row"
                    rules="required"
                  >
                    <b-form-input
                      v-model="templateData[0].rows[index][itemIndex]"
                      class="m-0 py-0"
                      :state="errors.length > 0 ? false:null"
                    />
                  </validation-provider>
                </td>
                <b-button
                  class="delete-button p-0 m-0"
                  variant="danger"
                  size="sm"
                  @click="templateData[0].rows.splice(index,1)"
                >
                  <feather-icon
                    icon="XIcon"
                  />
                </b-button>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import {
  BRow, BCol, BCard, BFormInput, BButton, BCardHeader,
} from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol, BCard, BFormInput, ValidationProvider, ValidationObserver, BButton, BCardHeader,
  },
  props: {
    templateData: {
      type: [Array, null],
      required: true,
    },
  },
  methods: {
    addColumn() {
      const newColumnIndex = this.templateData[0].columns.length
      this.templateData[0].columns.push('')
      this.templateData[0].rows.forEach(e => {
        e[newColumnIndex] = ''
      })
    },
    addRow() {
      const newRowIndex = this.templateData[0].rows.length
      this.templateData[0].rows.push([])
      this.templateData[0].columns.forEach((e, index) => {
        this.templateData[0].rows[newRowIndex][index] = ''
      })
    },
    deleteColumn(index) {
      this.templateData[0].rows.forEach(e => {
        e.splice(index, 1)
      })
      this.templateData[0].columns.splice(index, 1)
    },
  },
}
</script>

<style scoped>
.delete-button{
  position: absolute;
  right: 4px;
  top: 4px;
}

</style>
