<template>
  <div>
    <BTableSimple
      bordered
      striped
      responsive
    >
      <thead>
        <tr>
          <th
            style="vertical-align: middle; background: #2a2e3021;"
            rowspan="2"
          >
            {{ $t('sno') }}
          </th>
          <th
            style="vertical-align: middle; background: #2a2e3021"
            rowspan="2"
          >
            <template v-if="isStudent">
              {{ $t('labels.classroom') }}
            </template>
            <template v-else>
              {{ level === 'classroom' ? $t('labels.classroom') : $t('navigation-menu.users') }}
            </template>
          </th>
          <template v-if="showHideReportHead">
            <th
              colspan="2"
              style="vertical-align: middle; background: #2a2e3021"
              class="text-center academic"
            >
              {{ $t('higher-level-report-module.academic') }}
            </th>
            <th
              class="text-center"
              style="background: #2a2e3021"
            >
              {{ $t('higher-level-report-module.language-skills') }}
            </th>
            <th
              colspan="1"
              style="background: #2a2e3021"
              class="text-center study-skill"
            >
              {{ $t('higher-level-report-module.study-skill') }}
            </th>
            <th
              colspan="1"
              class="text-center"
              style="background: #2a2e3021"
            >
              {{ $t('higher-level-report-module.social-skill') }}
            </th>
          </template>
          <th
            style="vertical-align: middle; background: #2a2e3021"
            rowspan="2"
          >
            {{ $t('messages.tableHeader.action') }}
          </th>
        </tr>
        <tr>
          <template v-for="col,index of columns">
            <th v-if="!col.hide"
                :key="index"
                style="padding:10px;"
            >
              <div class="d-md-flex align-items-center"
                   :style="`${col.width ? `width:${col.width}` : ''} gap:10px;`"
              >
                <span class="flex-shrink-0">{{ col.text }}</span>
                <feather-icon
                  v-b-tooltip.hover="col.tooltip"
                  class="cursor-pointer"
                  icon="InfoIcon"
                  title="hello"
                />
              </div>
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row,index of getTableRow"
            :key="index"
        >
          <td>{{ index + 1 }}</td>
          <td v-for="col,i of columns"
              :key="i"
          >
            <span v-if="col.key === 'userName'">
              {{ row[col.key] }}
            </span>
            <span v-else-if="col.key === 'action'">
              <b-button
                v-if="props.level != 'teacher' && props.level != 'student'"
                variant="outline-primary"
                class="btn-icon rounded-circle"
                @click="$emit('getNextLevelReport', row.userId, props.level)"
              >
                <feather-icon icon="ArrowRightCircleIcon" />
              </b-button>
              <b-button
                v-else-if="classRoomId || isStudentClassView"
                variant="outline-primary"
                class="btn-icon rounded-circle"
                @click="openIndividualReport(row.userId)"
              >
                <feather-icon icon="ExternalLinkIcon" />
              </b-button>
              <b-button
                v-else-if="props.level == 'teacher'"
                variant="outline-primary"
                class="btn-icon rounded-circle"
                @click="$emit('getTeacherLevelReport', row.userId, props.level)"
              >
                <feather-icon icon="ArrowRightCircleIcon" />
              </b-button>

              <b-button
                v-else-if="props.level == 'student'"
                variant="outline-primary"
                class="btn-icon rounded-circle"
                @click="$emit('getStudentLevelReport', row.userId)"
              >
                <feather-icon icon="ArrowRightCircleIcon" />
              </b-button>
            </span>
            <span v-else>
              {{ number_format(row[col.key], 2) }}
            </span>
          </td>
        </tr>
      </tbody>
      <tfoot v-if="showHideReportHead">
        <tr class="bg-dark text-white">
          <td colspan="2"
              class="text-right"
          >
            {{ $t('average') }}
          </td>
          <td v-for="avg,index of getTableAverage"
              :key="index"
          >
            {{ number_format(avg, 2) }}
          </td>
          <td />
        </tr>
      </tfoot>
    </BTableSimple>
  </div>
</template>
<script setup>
import { computed, getCurrentInstance, ref } from 'vue'
import {
  BTableSimple, BButton,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_STUDENT } from '@/const/userType'
import { isVisible } from '@/utils/visibilitySettings'
import store from '@/store/index'

const showHideReportHead = ref(false)

const root = getCurrentInstance().proxy.$root
const isStudent = getUserData() && getUserData().usertype === USER_TYPE_STUDENT
const schoolSetting = store.state.appConfig?.schoolInfo?.user_meta
const distSetting = store.state.appConfig?.districtInfo?.user_meta
const showReportHead = isVisible('report_head', schoolSetting, distSetting)

if (isStudent && !showReportHead) {
  showHideReportHead.value = false
} else {
  showHideReportHead.value = true
}

const props = defineProps({
  tableData: {
    type: Object,
    default: () => {},
  },
  level: {
    type: String,
    required: true,
  },
  classRoomId: {
    type: Number,
    default: 0,
  },
  isStudentClassView: {
    type: Boolean,
    default: false,
  },
  specificStudent: {
    type: Number,
    default: 0,
  },
})

function getSum(array, user) {
  return array.filter(item => item.user_id === user.id).reduce((p, c) => p + Number(c.score), 0)
}

const columns = [
  {
    text: 'Name',
    key: 'userName',
    hide: true,
  },
  ...(showHideReportHead.value
    ? [
      {
        text: `${i18n.tc('higher-level-report-module.lesson-completion')}(%)`,
        key: 'lesson',
        tooltip: i18n.tc('lesson-completion-tooltip'),
      },
      {
        text: i18n.tc('individual-report-module.pass_score'),
        key: 'problem_solving',
        tooltip: i18n.tc('pass-score-tooltip'),
      },
      {
        text: i18n.tc('higher-level-report-module.language-skills'),
        key: 'language',
        tooltip: i18n.tc('language-skill-tooltip'),
      },
      {
        text: i18n.tc('student-diary-module.attendance'),
        key: 'attendance',
        tooltip: i18n.tc('attendance-tooltip'),
      },
      {
        text: i18n.tc('student-notes-module.notes'),
        key: 'notes',
        tooltip: i18n.tc('notes-tooltip'),
      },
    ]
    : []),
  {
    text: i18n.tc('messages.tableHeader.action'),
    key: 'action',
    hide: true,
  },

]

function openIndividualReport(userId) {
  if (props.isStudentClassView) {
    root.$router.push({
      name: `${root.AUTH_USER().usertype}-individual-report`,
      params: {
        studentId: props.specificStudent || root.AUTH_USER().id,
      },
      query: {
        classroomId: userId,
      },
    })
    // window.open(`/${root.AUTH_USER().usertype}/individual-report/${props.specificStudent || root.AUTH_USER().id}?classroomId=${userId}`)
  } else {
    root.$router.push({
      name: `${root.AUTH_USER().usertype}-individual-report`,
      params: {
        studentId: userId,
      },
      query: {
        classroomId: props.classRoomId,
      },
    })
    // window.open(`/${root.AUTH_USER().usertype}/individual-report/${userId}?classroomId=${props.classRoomId}`)
  }
}

const getTableRow = computed(() => {
  const users = []
  Object.entries(props.tableData).forEach(([, items]) => {
    items.forEach(record => {
      if (!users.find(item => item.id === record.user_id)) {
        users.push({
          id: record.user_id,
          fullName: record.username,
        })
      }
    })
  })
  const overAllReport = users.map(user => ({
    userName: user.fullName,
    userId: user.id,
    attendance: getSum(props.tableData.attendance_score, user),
    lesson: getSum(props.tableData.lesson_score, user),
    notes: getSum(props.tableData.notes_score, user),
    problem_solving: getSum(props.tableData.pass_score, user),
    language: getSum(props.tableData.language_score, user),
  }))
  return overAllReport
})

const getTableAverage = computed(() => {
  const average = {}
  columns.filter(item => !item.hide).forEach(item => {
    const tableRow = getTableRow.value.map(i => i[item.key]).filter(i => i > 0)
    average[item.key] = tableRow.reduce((p, c) => p + Number(c), 0) / tableRow.length
  })
  return average
})

</script>
