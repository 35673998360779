<template>
  <b-modal :visible="true"
           title="Certificate"
           size="xl"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <b-overlay :show="isProcessing">
      <div v-if="certificateData"
           ref="pdfArea"
           class="outer-border certificate"
      >
        <div class="inner-dotted-border">
          <span class="certification">
            {{ $t('certificate.completion-title') }}
          </span>
          <br><br>
          <span class="certify"><i>{{ $t('certificate.certify-that') }}</i></span>
          <br><br>
          <span class="student-name"><b>
            {{ capitalizeFirstLetter(certificateData.user?.fullname) }}
          </b>
          </span><br><br>
          <span class="certify"><i>{{ $t('certificate.has-successfully') }}</i></span> <br><br>
          <span class="fs-30">{{ certificateData.lesson?.name }}</span> <br><br>
          <span class="fs-20">{{ $t('certificate.score-of') }} <b>{{ certificateData.obtainedPercent }}%</b></span> <br><br><br><br>
          <span class="certify"><i>{{ $t('certificate.dated') }}</i></span><br>
          <span class="fs-30">{{ FORMAT_DATE(certificateData.completed_at, 'MMM Do YYYY') }}</span>
        </div>
      </div>
    </b-overlay>
    <template #modal-footer>
      <div>
        <b-button variant="primary"
                  @click="downloadAsPdf()"
        >
          {{ $t('certificate.download-pdf') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { BModal, BOverlay, BButton } from 'bootstrap-vue'
import useApollo from '@/@core/graphql/useApollo'
import html2pdf from 'html2pdf.js'

export default {
  components: {
    BModal,
    BOverlay,
    BButton,
  },
  props: {
    studentId: {
      type: Number,
      required: true,
    },
    classId: {
      type: Number,
      required: true,
    },
    lessonId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isProcessing: false,
      certificateData: null,
    }
  },
  mounted() {
    this.getResult()
  },
  methods: {
    downloadAsPdf() {
      const opt = {
        margin: 2,
        filename: `${this.certificateData.lesson?.name}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        jsPDF: { orientation: 'landscape', format: 'a4' },
      }

      html2pdf().set(opt).from(this.$refs.pdfArea).save()
    },
    getResult() {
      this.isProcessing = true
      useApollo
        .getLessonPassedResult({
          user_id: this.studentId,
          group_id: this.classId,
          lesson_id: this.lessonId,
          // eslint-disable-next-line no-unused-vars
        }).then(response => {
          const resultData = response.data.loaders.data[0]
          this.certificateData = resultData
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
    },
  },
}
</script>
<style lang="scss">
.certificate {
    font-family: 'Times New Roman', Times, serif;
    .outer-border {
        width:800px; height:650px; padding:20px; text-align:center; border: 10px solid #673AB7;    margin-left: 21%;
    }

    .inner-dotted-border{
      height:600px;
      padding:20px;
      text-align:center;
      border: 5px solid #4e4d50;
      border-style: dotted;
    }

    .certification{
        font-size:50px; font-weight:bold;
    }

    .certify{
        font-size:25px;
    }

    .student-name {
        font-size:30px;    color: green;
    }

    .fs-30{
        font-size:30px;
    }

    .fs-20{
        font-size:20px;
    }
}

</style>
