<template>
  <div>
    <b-table
      v-if="rubrics.length > 0"
      :items="rubrics"
      :fields="columns"
      :empty-filtered-text="$t('messages.no-record-found')"
      show-empty
      small
      responsive
    >
      <template #cell(action)="data">
        <div>
          <b-button
            :disabled="isProcessing"
            variant="outline-info"
            class="btn-icon"
            @click.stop="$emit('onEditRubric', data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            :disabled="isProcessing"
            variant="outline-danger"
            class="btn-icon ml-1"
            @click.stop="$emit('onDeleteRubric', data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
      </template>
    </b-table>
    <p
      v-else
      class="px-1"
    >
      {{ $t("student-diary-module.no-rubrics") }}
    </p>
  </div>
</template>

<script>
import {
  BTable, BButton, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import i18n from '@/libs/i18n'

export default ({
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BTable, BButton, BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    rubrics: {
      type: Array,
      default: () => [],
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    isATeacher: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [
        { key: 'title', label: i18n.tc('student-diary-module.rubric-title') },
        { key: 'action', label: i18n.tc('action'), thStyle: { width: '150px' } },
      ],
      self: getUserData(),
    }
  },

})
</script>
