<template>
  <b-modal
    id="modal-1"
    ref="problemModal"
    :hide-footer="true"
    title="Please solve the following problem"
    size="xl"
    @close="$emit('problemModalClosed')"
    @hide="$emit('problemModalClosed')"
  >
    <b-col
      md="12"
      lg="12"
      sm="12"
      class="mx-auto"
      :class="'student-problem theme-2'"
    >
      <b-overlay
        :opacity="0"
        :show="isProcessing"
      >
        <b-card
          v-if="!isComplete"
          no-body
          class="mb-0 problem-card"
        >
          <component
            :is="getProblemComponent"
            :display-result="true"
            :questions="activePoint && activePoint.questions"
            :preview-answers="previewAnswers"
            :is-processing="isProcessing"
            :feedback="isPreview ? activePoint.feedback : (feedback || {} )"
            :is-preview="isPreview"
            :level="level"
            :mode="feedback ? 'feedback' : 'question'"
            :engine="engine"
            :problem-group-type="problemGroupType"
            @submitQuestion="submitProblem"
            @nextQuestion="$emit('nextQuestion')"
          />
          <audio
            ref="successAudio"
            src="https://assets.mixkit.co/sfx/preview/mixkit-fantasy-game-success-notification-270.mp3"
          />
        </b-card>
      </b-overlay>
    </b-col>
  </b-modal>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BCol, BRow, BCard, BButton, BCardText, BOverlay,
} from 'bootstrap-vue'

import {
  PROBLEM_TYPE_MATCH,
  PROBLEM_TYPE_FILL_BLANK,
  PROBLEM_TYPE_FILL_DROPDOWN,
  PROBLEM_TYPE_MULTIPLE,
  PROBLEM_TYPE_SPEECH,
  PROBLEM_TYPE_CATEGORY,
} from '@/const/problemTypes'

import { STATUS_RIGHT, STATUS_WRONG, STATUS_IDLE } from '@/const/avatar'
import MatchProblem from '../compoments/MatchProblem.vue'
import FillProblem from '../compoments/FillProblem.vue'
import FillProblemDropdown from '../compoments/FillProblemDropdown.vue'
import MultipleProblem from '../compoments/MultipleProblem.vue'
import SpeechProblem from '../compoments/SpeechProblem/index.vue'
import CategoryProblem from '../compoments/CategoryProblem.vue'

export default {
  components: {
    MatchProblem,
    FillProblem,
    FillProblemDropdown,
    MultipleProblem,
    SpeechProblem,
    BCol,
    BRow,
    BCard,
    BButton,
    BCardText,
    BOverlay,
    CategoryProblem,
  },
  props: {
    activePoint: {
      type: Object,
      default: () => {},
    },
    pid: {
      type: [Number, String],
      default: null,
    },

    problem: {
      type: Object,
      default: () => {},
    },

    toggle: {
      type: Boolean,
      default: false,
    },

    level: {
      type: [Number, String],
      default: 1,
    },
    duration: {
      type: [Object, Array],
      default: () => {},
    },
    isPreview: {
      type: Boolean,
      default: () => {},
    },
    totalPoints: {
      type: [Number, String],
      default: () => {},
    },
    startTime: {
      type: [Object, Array],
      default: () => {},
    },
    engine: {
      type: [String, Object],
      default: '',
    },
    loaderId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      displayResult: false,
      feedback: null,
      isProcessing: false,
      problemGroupType: 'hot-spot',
      mode: 'question',
      previewAnswers: [],
      isComplete: false,
      showResult: false,
      characterTimeout: null,
    }
  },
  computed: {
    getProblemComponent() {
      if (this.activePoint && this.activePoint.problem_type) {
        if (this.activePoint.problem_type === PROBLEM_TYPE_MATCH) {
          return 'MatchProblem'
        }
        if (this.activePoint.problem_type === PROBLEM_TYPE_FILL_BLANK) {
          return 'FillProblem'
        }
        if (
          this.activePoint.problem_type === PROBLEM_TYPE_FILL_DROPDOWN
        ) {
          return 'FillProblemDropdown'
        }
        if (this.activePoint.problem_type === PROBLEM_TYPE_MULTIPLE) {
          return 'MultipleProblem'
        }
        if (this.activePoint.problem_type === PROBLEM_TYPE_SPEECH) {
          return 'SpeechProblem'
        }
        if (this.activePoint.problem_type === PROBLEM_TYPE_CATEGORY) {
          return 'CategoryProblem'
        }
        return ''
      }
      return ''
    },
  },
  watch: {
    toggle(value) {
      if (value === true) {
        this.showModal()
      } else {
        this.hideModal()
      }
    },
  },
  mounted() {},
  methods: {
    showModal() {
      this.$refs.problemModal.show()
    },
    hideModal() {
      this.$refs.problemModal.hide()
    },

    submitProblem(data) {
      // const bodyTime = parseInt((new Date().getTime() - this.startTime.body) / 1000, 10)
      // this.checkProblemSolution({
      //   ...data,
      //   ...{
      //     time_spent: parseInt(this.duration.statement / this.totalPoints, 10) + bodyTime, st_time: parseInt(this.duration.statement / this.totalPoints, 10), body_time: bodyTime, pid: this.pid,
      //   },
      // })
      this.checkProblemSolution({
        ...data,
        ...{
          time_spent: this.duration.body,
          body_time: this.duration.body,
          pid: this.pid,
          st_time: 0,
          loader_id: this.loaderId,
        },
      })
    },

    checkProblemSolution(data) {
      let config = {}
      const formData = new FormData()
      if (Array.isArray(data.answer)) {
        config = {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.accessToken}`,
          },
        }
        formData.append('time_spent', data.time_spent)
        formData.append('lang_level', data.lang_level)
        formData.append('pid', data.pid)
        formData.append('st_time', data.st_time)
        formData.append('body_time', data.body_time)
        formData.append('loader_id', data.loader_id)
        data.answer.forEach((a, i) => {
          if (a.audio) {
            formData.append(`answer[${a.id}][audio]`, a.audio)
            formData.append(`answer[${a.id}][text]`, a.text)
          } else formData.append(`answer[${i}]`, a)
        })
      }

      // this.hideModal()

      return new Promise((resolve, reject) => {
        useJwt
          .checkProblemSolution(
            this.activePoint.id,
            Array.isArray(data.answer) ? formData : data,
            config,
          )
          .then(response => {
            this.$emit('answeredQuestion', response.data.data)
            this.showResult = true
            this.result = response.data.data
            this.feedback = {
              text: response.data.data.feedback,
              image: response.data.data.image,
              audio: response.data.data.audio,
              template: response.data.data.feedback_template,
              isCorrect: !response.data.data.is_wrong,
              grammar: response.data.data.grammar, // only for open end writing
            }
            if (!response.is_wrong) {
              this.$refs.successAudio.volume = 0.05
              this.$refs.successAudio.play()
              this.$emit('updateStar')
              this.$store.commit('appConfig/UPDATE_CURRENT_STATUS', STATUS_RIGHT)
            } else {
              this.$store.commit('appConfig/UPDATE_CURRENT_STATUS', STATUS_WRONG)
            }
            if (this.characterTimeout) clearTimeout(this.characterTimeout)
            this.characterTimeout = setTimeout(() => {
              this.$store.commit('appConfig/UPDATE_CURRENT_STATUS', STATUS_IDLE)
            }, 7000)
            resolve(response.data.data)
          })
          .catch(err => reject(err))
      })
    },
  },
}
</script>
