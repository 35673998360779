<template>
  <component :is="getComponent" />
</template>
<script>
import classicIndex from '@/views/common/class/Index.vue'
import modernIndex from '@/views/v2/common/class/Index.vue'
import schoolTable from '@/views/school/class/Index.vue'
import {
  USER_TYPE_PARENT,
} from '@/const/userType'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    classicIndex,
    modernIndex,
    schoolTable,
  },
  computed: {
    ui() {
      return this.$store.state.appConfig.layout.ui
    },
    isModernUI() {
      return this.ui === 'modern'
    },
    getComponent() {
      return this.isModernUI ? 'modernIndex' : 'classicIndex'
    },
    isAParent() {
      return getUserData() && getUserData().type === USER_TYPE_PARENT
    },
  },

}
</script>
