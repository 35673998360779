<template>
  <div>
    <b-card no-body>
      <b-card-body class="p-0">
        <b-table
          :fields="columns"
          responsive
          :items="attendance"
          class="table-hover table-striped table-bordered"
        >
          <template #cell(total_days)="data">
            <span v-if="data.item.total_days > 1">
              {{ data.item.total_days }} days
            </span>
            <span v-else> {{ data.item.total_days }} day </span>
          </template>
          <template #cell(name)="data">
            <span>
              <b-avatar
                class="ml-1 mt-1"
                :text="getInitials(data.item.name)"
                :size="42"
                :variant="getRandomVariant()"
                square
              />
              <span class="ml-1">{{ data.item.name }}</span>
            </span>
          </template>
          <template #cell(most_active_days)="data">
            <span>
              {{ getMostActiveDays(data.item.dates) }}
            </span>
          </template>
          <template #cell(action)="data">
            <b-button
              variant="outline-primary"
              class="btn-icon"
              @click="showAttendance(data)"
            >
              <feather-icon icon="CalendarIcon" />
            </b-button>
          </template>
        </b-table>
        <div
          v-if="attendance.length === 0"
          class="p-2"
        >
          No data in table
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      ok-only
      :visible="selectedBlock.show"
      :ok-title="$t('actions.close')"
      modal-class="modal-primary"
      centered
      :title="`${selectedBlock.userName} / ${selectedBlock.monthName}`"
      @close="resetSelectedBlock"
      @hide="resetSelectedBlock"
    >
      <b-overlay :show="isProcessing">
        <template v-if="!isProcessing">
          <month-blocks
            :attendance="selectedBlock.attendance"
            :year="selectedBlock.year"
            :user-id="selectedBlock.userId"
            :month="selectedBlock.month"
          />
        </template>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import {
  BTable, BCard, BAvatar, BButton, BModal, BOverlay, BCardBody,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import MonthBlocks from './MonthBlocks.vue'

const moment = require('moment')

export default {
  components: {
    BTable,
    BCard,
    BAvatar,
    BButton,
    BModal,
    MonthBlocks,
    BOverlay,
    BCardBody,
  },
  props: {
    attendance: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columns: [
        {
          key: 'name',
          label: i18n.tc('messages.tableHeader.name'),
        },
        {
          key: 'total_days',
          label: i18n.tc('labels.present-days'),
        },
        {
          key: 'presentPercent',
          label: i18n.tc('labels.present-percentage'),
        },
        {
          key: 'most_active_days',
          label: i18n.tc('labels.most-active-days'),
        },
        {
          key: 'action',
          label: i18n.tc('user-module.user-table.actions'),
        },
      ],
      selectedBlock: {
        show: false,
        attendance: [],
        year: 0,
        month: 0,
      },
      isProcessing: false,
      showModal: false,
    }
  },
  methods: {
    getRandomVariant() {
      const a = ['primary', 'secondary', 'success', 'danger', 'warning', 'info']
      return a[Math.floor(a.length * Math.random())]
    },
    modeArray(array) {
      if (array.length === 0) return null
      const modeMap = {}
      let maxCount = 1
      let modes = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < array.length; i++) {
        const el = array[i]

        if (modeMap[el] == null) modeMap[el] = 1
        else modeMap[el] += 1

        if (modeMap[el] > maxCount) {
          modes = [el]
          maxCount = modeMap[el]
        } else if (modeMap[el] === maxCount) {
          modes.push(el)
          maxCount = modeMap[el]
        }
      }
      return modes
    },
    getMostActiveDays(dates) {
      const weeks = []
      if (dates.length === 0) return '-'
      dates.forEach(date => {
        const week = moment(date).format('ddd')
        weeks.push(week)
      })
      const mostOccupancy = this.modeArray(weeks)
      return mostOccupancy.join(', ')
    },
    getInitials(name) {
      const names = name.split(' ')
      let initials = names[0].substring(0, 1).toUpperCase()

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase()
      }
      return initials
    },
    resetSelectedBlock() {
      this.selectedBlock = {
        show: false,
        attendance: [],
        year: 0,
        month: 0,
      }
    },
    showAttendance(data) {
      this.isProcessing = true
      this.selectedBlock = { ...this.selectedBlock, show: true }
      const startDate = moment(`${data.item.year}-${data.item.month}-01`).format('YYYY-MM-DD')
      const endDate = moment(startDate).endOf('month').format('YYYY-MM-DD')
      const params = {
        user_id: data.item.user_id,
        start_date: startDate,
        end_date: endDate,
      }
      useJwt.getStudentAttendance({
        params,
      }).then(response => {
        const attendance = response.data.data.map(day => moment(day).format('YYYY-MM-DD'))
        this.selectedBlock = {
          show: true,
          attendance: [...new Set(attendance)],
          year: data.item.year,
          month: data.item.month,
          monthName: moment(startDate).format('MMMM'),
          userName: data.item.name,
          userId: data.item.user_id,
        }
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
