<template>
  <b-overlay :show="isProcessing">
    <h4 v-if="isFullReport">
      {{ $t('report.overall-game-reports') }}
    </h4>
    <b-row v-if="gameReport.length">
      <b-col
        v-if="!studentId"
        md="4"
        class="align-items-stretch d-flex"
      >
        <b-card
          no-body
          style="overflow:scroll; max-height:100vh;"
          class=" w-100"
        >
          <div class="p-1">
            <h4>{{ $t('report.report-based-on-students') }}</h4>
          </div>
          <!-- <h3>Game Report</h3> -->
          <b-table-simple>
            <b-thead>
              <b-tr>
                <b-th>{{ $t('report.student') }}</b-th>
                <b-th>{{ $t('report.time') }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="report,index of groupGameReport"
                :key="index"
              >
                <b-td>
                  {{ report.fullName }}
                </b-td>
                <b-td>
                  {{ report.time }} / <small>{{ report.playCount }}<sub>{{ $t('times') }}</sub></small>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
      <b-col
        :md=" studentId ? 12 : 8 "
        class="d-flex align-items-stretch"
      >
        <div class=" w-100">
          <b-card
            no-body
            class=" w-100"
          >
            <div class="p-1">
              <h4>{{ $t('report.report-based-on-game') }}</h4>
            </div>
            <game-report-pie-chart :game-report="gameReport" />
          </b-card>
          <b-card
            no-body
            class="w-100"
          >
            <div class="row p-2">
              <div class="col-md-4">
                <h4>{{ $t('report.report-of-game-played') }}</h4>
              </div>
              <div class="col-md-3 offset-md-5">
                <b-form-select
                  v-model="reportType"
                  :options="reportTypeOptions"
                />
              </div>
            </div>
            <weekly-played-game-bar
              :key="reportType"
              :game-report="gameReport"
              :type="reportType"
            />
          </b-card>
        </div>
      </b-col>
    </b-row>
    <b-card v-if="!isProcessing && gameReport.length === 0">
      <h4>{{ $t('report.no-report') }}</h4>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCard, BTableSimple, BTh, BTd, BTr, BThead, BTbody, BRow, BCol, BOverlay, BFormSelect,
} from 'bootstrap-vue'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'
import GameReportPieChart from '@/views/common/Charts/GameReportPieChart.vue'
import WeeklyPlayedGameBar from '@/views/common/Charts/WeeklyPlayedGameBar.vue'

export default {
  components: {
    BCard,
    BTableSimple,
    BTh,
    BTd,
    BTbody,
    BTr,
    BThead,
    BRow,
    BCol,
    BOverlay,
    GameReportPieChart,
    WeeklyPlayedGameBar,
    BFormSelect,
  },
  props: {
    classRoom: {
      type: Object,
      default: () => {},
    },
    studentId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      gameReport: [],
      isProcessing: false,
      groupGameReport: [],
      reportType: 'month',
      reportTypeOptions: [
        {
          text: this.$i18n.tc('monthly'),
          value: 'month',
        },
        {
          text: this.$i18n.tc('daily'),
          value: 'day',
        },
        {
          text: this.$i18n.tc('annually'),
          value: 'year',
        },
      ],
    }
  },
  computed: {
    isFullReport() {
      return this.$route.name === 'game-report'
    },
  },
  created() {
    this.getReport()
  },
  methods: {
    getReport() {
      this.isProcessing = true
      let classId
      if (this.$route.name === 'game-report') {
        classId = 'all'
      } else {
        classId = this.classRoom.id
      }
      useJwt.getGameReport(classId, {
        params: {
          userId: this.studentId > 0 ? this.studentId : null,
        },
      }).then(response => {
        const gameReport = response.data.data.report.map(report => ({
          ...report,
          day: moment(report.created_at).format('YYYY-MM-DD'),
          month: moment(report.created_at).format('YYYY-MMM'),
          year: moment(report.created_at).format('YYYY'),
          fullName: `${report.user?.firstname} ${report.user?.lastname}`,
        }))
        this.gameReport = gameReport
        const groupGameReport = []
        Object.entries(this.groupByKey(gameReport, 'user_id')).forEach(([, value]) => {
          const report = this.getTotalTime(value)
          groupGameReport.push({
            fullName: value[0].fullName,
            ...report,
          })
        })
        groupGameReport.sort((a, b) => b.totalTime - a.totalTime)
        this.groupGameReport = groupGameReport
      }).catch(error => {
        console.error(error)
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    getTotalTime(report) {
      const totalTime = report.reduce((p, c) => c.game_time + p, 0)
      return {
        time: this.CONVERT_HM(totalTime),
        totalTime,
        playCount: report.length,
      }
    },
  },
}
</script>
