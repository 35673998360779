/* eslint-disable */

export const initFbsdk = function(){
  return new Promise(resolve => {
    window.fbAsyncInit = function() {
        FB.init({
          appId      : process.env.VUE_APP_FACEBOOK_APP_ID,
          cookie     : true,
          xfbml      : true,
          version    : 'v16.0'
        });
          
        FB.AppEvents.logPageView();   
        resolve()
      };

      (function(d, s, id){
         var js, fjs = d.getElementsByTagName(s)[0];
         if (d.getElementById(id)) {return;}
         js = d.createElement(s); js.id = id;
         js.src = "https://connect.facebook.net/en_US/sdk.js";
         fjs.parentNode.insertBefore(js, fjs);
       }(document, 'script', 'facebook-jssdk'));
  })
}



export const getUserDetails = function(){
    return new Promise((resolve)=>{
        FB.api('/me',{fields: 'id,name,email,picture'}, function (response) {
          console.log('fb response', response)
          resolve(response);
      });
    })
}

