<template>
  <div>
    <div
      v-for="(datum, index) in data"
      :key="index"
      class="row mt-2"
    >
      <div class="col-md-12">
        <p
          v-text-to-speech
          @click="$emit('onClick', $event)"
          v-html="
            isAProblem
              ? formatQuestion(datum.title, 'title', index)
              : datum.title
          "
        />
      </div>

      <div class="col-md-12">
        <div
          class="media"
          :style="`display:flex;flex-direction:${
            datum.imagePosition === 'left' ? 'row' : 'row-reverse'
          }`"
        >
          <img
            v-image-preview
            :class="{
              'mr-3': datum.imagePosition === 'left',
              'ml-3': datum.imagePosition === 'right',
            }"
            :src="datum.image_url"
            style="width: 250px"
          >
          <div class="media-body">
            <h3
              v-text-to-speech
              class="mt-0"
              @click="$emit('onClick', $event)"
              v-html="
                isAProblem
                  ? formatQuestion(datum.subtitle, 'subtitle', index)
                  : datum.subtitle
              "
            />

            <!-- <p>{{ datum.body }}</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkSettings } from '@/utils/visibilitySettings'

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    placeHolder: {
      type: String,
      default: '',
    },
    isAProblem: {
      type: Boolean,
      default: false,
    },
    questionFormatter: {
      type: Function,
      default: null,
    },
  },
  computed: {
    copyPasteEnabled() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const setting = checkSettings('copy_paste', schoolSetting, distSetting)
      if (setting.value === '1') {
        return true
      }
      return false
    },
  },
  methods: {
    formatQuestion(question, dataString, ind) {
      if (this.questionFormatter && typeof this.questionFormatter === 'function') {
        return this.questionFormatter(question, dataString, ind)
      }
      let html = ''
      question
        .replaceAll(this.placeHolder, `$${this.placeHolder}$`)
        .split('$')
        .filter(e => e.length > 0)
        .forEach((element, index) => {
          if (element === this.placeHolder) {
            this.$emit('createAnswer', `${dataString}-${ind}-${index}`)
            html += `
              <input
                type="text"
                :id="'Field_' + ${index + 1}"
                data-id="${dataString}-${ind}-${index}"
                style="display: inline-block; width: 150px;margin-top:2px"
                class="mx-1 answer form-control"
                :name="'Field_' + ${index + 1}"
                onpaste="return ${this.copyPasteEnabled};"
              />
            `
          } else {
            html += element
          }
        })
      return html
    },
  },
}
</script>

<style>
</style>
