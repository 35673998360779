/* eslint-disable eqeqeq */
import moment from 'moment'
import html2pdf from 'html2pdf.js'
import store from '../store'
import 'moment/locale/vi'
import 'moment/locale/ja'
import 'moment/locale/en-in'
// eslint-disable-next-line import/order
import i18n from '@/libs/i18n'

// without this line it didn't work
const locale = localStorage.getItem('locale')
if (locale === 'vn') {
  moment.locale('vi')
} else if (locale === 'jp') {
  moment.locale('ja')
} else {
  moment.locale('en-in')
}

export default {
  CONVERT_HM(value) {
    const sec = parseInt(value, 10) // convert value to number if it's string
    if (sec < 60 && sec > 0) return `< 1${this.$i18n.tc('time.m')}`
    if (sec <= 0) return `${this.$i18n.tc('time.m')}`
    const hours = Math.floor(sec / 3600) // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60) // get minutes
    // add 0 if value < 10; Example: 2 => 02
    if (minutes < 10) { minutes = `0${minutes}` }
    return `  ${hours > 0 ? `${hours}${this.$i18n.tc('time.h')}` : ''} ${minutes || 0} ${this.$i18n.tc('time.m')}` // Return is HH : MM : SS
  },
  SECOND_TO_TIME(seconds) {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    // Add leading zeros if needed
    const formattedHours = String(hours).padStart(2, '0')
    const formattedMinutes = String(minutes).padStart(2, '0')
    const formattedSeconds = String(remainingSeconds).padStart(2, '0')

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
  },
  capitalizeFirstLetter(string) {
    if (!string) return ''
    return string.charAt(0).toUpperCase() + string.slice(1)
  },
  titleCase(str) {
    return str.replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
    )
  },

  snakeToTitleCase(str) {
    return str.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ` ${d.toUpperCase()}`))
  },

  joinArrayWith(array, key) {
    return array.map(arr => arr[key]).join(', ')
  },
  groupByKey(array, key) {
    return array
      .reduce((hash, obj) => {
        if (obj[key] === undefined) return hash
        return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
      }, {})
  },
  FORMAT_DATE(date, format) {
    return moment(date).format(format)
  },
  ADD_DATE(date, days, format = 'YYYY-MM-DD') {
    return moment(date).add(days, 'days').format(format)
  },
  SUBTRACT_DATE(date, days, format = 'YYYY-MM-DD') {
    return moment(date).add(-days, 'days').format(format)
  },
  FROM_NOW(date) {
    if (!date) return ''
    return moment
      .utc(date)
      .local()
      .fromNow()
  },
  DATE_IS_PASSED(date) {
    return moment(date).isBefore(moment())
  },
  TODAY(format = 'YYYY-MM-DD') {
    return moment().format(format)
  },
  DAYS_DIFF_FROM_TO(from, to) {
    const date1 = moment.utc(from).local()
    const date2 = moment(to).utc().local()
    const diff = date2.diff(date1, 'days')
    return diff + 1
  },
  DAYS_DIFF_FROM_NOW(date) {
    const date1 = moment(moment(date).format('YYYY-MM-DD'))
    const date2 = moment(moment().format('YYYY-MM-DD'))
    const diff = date2.diff(date1, 'days')
    return diff
  },
  AUTH_USER() {
    return JSON.parse(localStorage.getItem('userData'))
  },
  ARRAY_UNIQUE_BY_KEY(array, key) {
    return [...new Map(array.map(item => [item[key], item])).values()]
  },
  JSON_PARSE(json) {
    if (!json) return null
    try {
      return JSON.parse(json)
    } catch {
      return json
    }
  },
  PARSE_ONLY_JSON(json) {
    if (!json) return null
    try {
      return JSON.parse(json)
    } catch {
      return null
    }
  },
  number_format(str, round = false) {
    // eslint-disable-next-line no-param-reassign
    str = parseFloat(str)
    if (isNaN(str)) {
      return '-'
    }
    if (round || round === 0) {
      // eslint-disable-next-line no-param-reassign
      str = str.toFixed(round)
    }
    return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  arrayMode(array) {
    if (array.length === 0) { return null }
    const modeMap = {}
    let maxEl = array[0]; let
      maxCount = 1
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < array.length; i++) {
      const el = array[i]
      // eslint-disable-next-line no-plusplus
      if (modeMap[el] == null) { modeMap[el] = 1 } else { modeMap[el]++ }
      if (modeMap[el] > maxCount) {
        maxEl = el
        maxCount = modeMap[el]
      }
    }
    return maxEl
  },
  GO_BACK() {
    if (window.PREVIOUS_ROUTE_PATH) {
      this.$router.push(window.PREVIOUS_ROUTE_PATH)
      window.PREVIOUS_ROUTE_PATH = null
    } else {
      this.$router.back()
    }
  },

  printPdf(element, filename = 'file.pdf', fullwidth = false) {
    const opt = {
      margin: 0,
      filename,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 2,
        width: fullwidth ? element.offsetWidth : element.offsetWidth * 0.796,
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
      },
      jsPDF: { format: 'a4', orientation: 'portrait' },
    }

    // New Promise-based usage:
    html2pdf().set(opt).from(element).save()
  },
  getRandomColor(name = null) {
    let firstAlphabet
    // get first alphabet in upper case
    if (name) firstAlphabet = name.charAt(0).toLowerCase()
    const a = ['primary', 'secondary', 'success', 'danger', 'warning', 'info']

    return {
      color: a[Math.floor(a.length * Math.random())],
      character: name ? firstAlphabet.toUpperCase() : null,
    }
  },
  getLangLevelTransText(key) {
    const indicators = store.state.app.languageLevelIndicator
    return indicators?.[key]?.label || key
  },
  addMultipleTimesAndGetTotalTime(timeStrings) {
    // Initialize total seconds to 0
    let totalSeconds = 0

    // Loop through each time string and add it to the total seconds
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < timeStrings.length; i++) {
      // Split the time string into hours, minutes, and seconds
      const [hours, minutes, seconds] = timeStrings[i].split(':')

      // Calculate the total seconds for the time string
      // eslint-disable-next-line radix
      const timeSeconds = (parseInt(hours) * 3600) + (parseInt(minutes) * 60) + parseInt(seconds)

      // Add the time to the total seconds
      totalSeconds += timeSeconds
    }

    // Calculate the hours, minutes, and seconds from the total seconds
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60

    // Format the hours, minutes, and seconds as a string
    const totalTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

    // Return the total time taken as a string
    return totalTime
  },
  fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea')
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      const successful = document.execCommand('copy')
      const msg = successful ? 'successful' : 'unsuccessful'
      this.showMessage(`Copying text command was ${msg}`)
    } catch (err) {
      this.showError('Fallback: Oops, unable to copy', err)
    }

    document.body.removeChild(textArea)
  },
  copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text)
      return
    }
    navigator.clipboard.writeText(text).then(
      () => {
        this.showMessage(i18n.tc('copied-to-clipboard'))
      },
      () => {
        this.showError(i18n.tc('could-not-copy'))
      },
    )
  },
  getStoreKeyForPlayedGame() {
    if (this.$store.state.problem.activeProblem) {
      const problemId = this.$store.state.problem.activeProblem.id
      const { classId } = this.$route.params
      return `class_${classId}_problem_${problemId}_game`
    }
    return null
  },

  updateUserData(value) {
    localStorage.setItem('userData', JSON.stringify(value))
  },

  getProblemHintsDescription(text) {
    const { activeProblem } = this.$store.state.problem
    const { create } = this.$store.state.problem
    const description = activeProblem?.description ? activeProblem?.description : create?.problemDescription
    return description?.trim() ? description : text
  },

  async blobUrlToBase64(blobUrl) {
    const response = await fetch(blobUrl)
    const blob = await response.blob()

    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = function (event) {
        const base64String = event.target.result
        resolve(base64String)
      }

      reader.onerror = function (error) {
        reject(error)
      }

      reader.readAsDataURL(blob)
    })
  },

  covertBase64ToFile(base64String, mimeType) {
    // Remove the data URI prefix (e.g., "data:image/png;base64,")
    const base64Data = base64String.replace(/^data:[A-Za-z]+\/[A-Za-z]+;base64,/, '')

    // Convert the Base64 data to binary data
    const binaryData = atob(base64Data)

    // Create an ArrayBuffer to hold the binary data
    const arrayBuffer = new ArrayBuffer(binaryData.length)
    const uint8Array = new Uint8Array(arrayBuffer)

    // Populate the ArrayBuffer with the binary data
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i)
    }

    // Create a Blob object from the ArrayBuffer
    const blob = new Blob([arrayBuffer], { type: mimeType })
    const extension = mimeType.split('/')[1]
    // Create a File object from the Blob
    const file = new File([blob], `${Math.random().toString(36).substring(2, 12)}.${extension}`, { type: mimeType })

    return file
  },
  getUserAccessTypeLabel(accessType) {
    switch (accessType) {
      case 'trial': {
        return 'Trial'
      }
      case 'full_access': {
        return 'Full Access'
      }
      case 'full_access_tutor': {
        return 'Full Access (Tutor Support)'
      }
      default: {
        return ''
      }
    }
  },
  addParamToCurrentUrl(key, value) {
    // Construct URLSearchParams object instance from current URL querystring.
    const queryParams = new URLSearchParams(window.location.search)

    // Set new or modify existing parameter value.
    queryParams.set(key, value)

    // Replace current querystring with the new one.
    history.replaceState(null, null, `?${queryParams.toString()}`)
  },
  prioritizeInCss(html) {
    const htmlString = html.replace(/style="([^"]*)color:\s*([^;"]+)(?:\s*!important)?/g, (match, p1, p2) => {
      // Check if !important is already present, if not, add it
      if (!/\s*!important/.test(p2)) {
        return `style="${p1}color: ${p2} !important"`
      }
      // Return the original match if !important is already present
      return match
    })
    return htmlString
  },
  isMobileDevice() {
    return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('Mobile') !== -1)
  },
  RoundNumber(number, roundOf = 2) {
    const num = Number(number)
    if (isNaN(num)) {
      return '-'
    }
    return num.toFixed(roundOf)
  },
  getGoogleUSVoice() {
    // Get the available voices
    const voices = window.speechSynthesis.getVoices()

    // Try to find a Google female English voice
    const googleFemaleVoice = voices.find(voice => voice.name.includes('Google') && voice.lang === 'en-US' && voice.name.toLowerCase().includes('female'))

    // If no female voice is found, use any Google US English voice
    const supportedVoices = ['Google', 'Samantha', 'Nicky']
    let fallbackVoice = null
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= supportedVoices.length; i++) {
      // eslint-disable-next-line no-loop-func
      fallbackVoice = voices.find(voice => voice.name.includes(supportedVoices[i]) && voice.lang === 'en-US')
      if (fallbackVoice) {
        break
      }
    }

    // Return the selected voice or null if none found
    return googleFemaleVoice || fallbackVoice || voices.find(i => i.lang == 'en-Us')
  },
  async fileToBase64(file) {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function () {
        const base64String = reader.result
        resolve(base64String)
      }
    })
  },
  doBrowserReallyDontHaveSpeechSupport() {
    // for react native need to find alternative methods
    return !window.webkitSpeechRecognition || !!navigator.brave || !!window.ReactNativeWebView
  },
}
