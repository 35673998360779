export const CHARACTER_1 = 'alice'
export const CHARACTER_2 = 'bill'
export const CHARACTER_3 = 'julie'
export const CHARACTER_4 = 'karen'
export const CHARACTER_5 = 'kevin'
export const CHARACTER_6 = 'mike'

export const CHARACTERS = {
  [CHARACTER_1]: '/1/idle.png',
  [CHARACTER_2]: '/2/idle.png',
  [CHARACTER_3]: '/3/idle.png',
  [CHARACTER_4]: '/4/idle.png',
  [CHARACTER_5]: '/5/idle.png',
  [CHARACTER_6]: '/6/idle.png',
}

export const STATUS_IDLE = 'idle'
export const STATUS_SLEEP = 'sleep'
export const STATUS_RIGHT = 'right'
export const STATUS_WRONG = 'wrong'

export const STATUS = [STATUS_IDLE, STATUS_SLEEP, STATUS_RIGHT, STATUS_WRONG]

export const IDLE = {
  [CHARACTER_1]: '/1/idle.png',
  [CHARACTER_2]: '/2/idle.png',
  [CHARACTER_3]: '/3/idle.png',
  [CHARACTER_4]: '/4/idle.png',
  [CHARACTER_5]: '/5/idle.png',
  [CHARACTER_6]: '/6/idle.png',
}

export const SLEEP = {
  [CHARACTER_1]: '/1/sleep.png',
  [CHARACTER_2]: '/2/sleep.png',
  [CHARACTER_3]: '/3/sleep.png',
  [CHARACTER_4]: '/4/sleep.png',
  [CHARACTER_5]: '/5/sleep.png',
  [CHARACTER_6]: '/6/sleep.png',
}

export const RIGHT = {
  [CHARACTER_1]: '/1/right.png',
  [CHARACTER_2]: '/2/right.png',
  [CHARACTER_3]: '/3/right.png',
  [CHARACTER_4]: '/4/right.png',
  [CHARACTER_5]: '/5/right.png',
  [CHARACTER_6]: '/6/right.png',
}

export const WRONG = {
  [CHARACTER_1]: '/1/wrong.png',
  [CHARACTER_2]: '/2/wrong.png',
  [CHARACTER_3]: '/3/wrong.png',
  [CHARACTER_4]: '/4/wrong.png',
  [CHARACTER_5]: '/5/wrong.png',
  [CHARACTER_6]: '/6/wrong.png',
}
