<template>
  <div
    class="w-100 theme-preview mb-2"
  >
    <b-row :class="'theme-'+(theme||'1')">
      <b-col md="12 d-flex flex-column">
        <div
          class="problem-card-title p-2"
        >
          <h3>{{ $t('lesson-pages-module.question-header') }}</h3>
        </div>
        <div
          class="problem-overview-content p-2"
          style="height:100px"
        >
          <span>{{ $t('lesson-pages-module.question-body') }}</span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: { BRow, BCol },
  props: {
    theme: {
      type: [String, Number],
      default: '1',
    },
  },
}

</script>

<style lang="scss">
  @import "/src/assets/scss/custom/problem-themes/main.scss";
</style>
