<template>
  <b-modal :visible="true"
           :title="$t('upload-excel')"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <div>
      <BFormFile type="file"
                 accept=".xlsx, .xls, .csv"
                 :placeholder="$t('no-file-choosen')"
                 :browse-text="$t('browseButton')"
                 @input="handleFileUpload"
      />
    </div>
    <template #modal-footer>
      <div>
        <b-button variant="outline-secondary"
                  class="mr-1"
                  @click="$emit('close')"
        >
          {{ $t('actions.cancel') }}
        </b-button>
        <b-button variant="primary"
                  :disabled="isProcessing"
                  @click="uploadFile"
        >
          <b-spinner v-if="isProcessing"
                     small
          />  {{ $t('actions.save') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { BFormFile, BButton, BSpinner } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BFormFile,
    BButton,
    BSpinner,
  },
  data() {
    return {
      file: null,
      isProcessing: false,
    }
  },
  methods: {
    handleFileUpload(file) {
      // eslint-disable-next-line prefer-destructuring
      this.file = file
    },
    uploadFile() {
      if (!this.file) {
        this.showError('Please select a file to upload')
        return
      }

      const formData = new FormData()
      formData.append('file', this.file)
      this.isProcessing = true
      useJwt.uploadLaExcelFileUser(formData).then(response => {
        this.$emit('onSave')
        this.$emit('close')
        this.showSuccessMessage(response)
        location.reload()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
