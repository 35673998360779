import gql from 'graphql-tag'

export default {
  FETCH_CLASS_ROOMS: gql`query getClassRooms($id:ID, $type:String, $page:Int, $limit:Int, $member_id:ID, $created_by:ID, $schoolId:ID, $teacherId:ID){
    rooms(id:$id, type:$type, page:$page, limit:$limit, member_id:$member_id, created_by:$created_by, school_id:$schoolId, teacher_id:$teacherId) {
      total
      current_page
      last_page
      from
      to
      data {
        id
        name
        type
        link
        is_archived
        course_completion
        students{
          id
        }
        school {
          id
          fullname
        }
        coursesLesson{
          course_id
          lessons
        }
        courses {
          id
          name
          lessons{
            id
            name
          }
        }
        classroom_teachers {
          role
          user {
            id
            fullname
          }
        }
        owner{
          id
          fullname
        }
      }
    }
  # default: rooms(type:$type, byAuthUser:true, page:$customCurrentPage, limit:$limit) {
  #   total
  #   current_page
  #   last_page
  #   from
  #   to
  #   data {
  #     id
  #     name
  #     course{
  #       id
  #       name
  #     }
  #     teacher {
  #       id
  #       fullname
  #     }
  #      owner{
  #         id
  #         fullname
  #       }
  #   }
  # }
  }`,
  SEARCH_ROOMS: gql`query searchRoom($search:String!,$userId:ID!, $type:String) {
    searchRooms(search: $search,userId:$userId, type:$type) {
      id
      name
      school {
        id
      }
    }
  }`,

  FIND_CLASSROOM_WITH_STUDENTS: gql` query findClassRoomWithStudents($id:ID!, $group_id:[Int], $user_id:ID) {
    room(id:$id) {
      id
      name
      type
      coursesLesson{
        countLessons
      }
      created_at
      completed_at
      teachers{
        id
        fullname
      }
      owner{
          id
          fullname
        }
      school {
        id
        fullname
      }
      courses {
        id
        name
        lessons(withProblems:true){
          name
        }
      }
      students(user_id:$user_id) {
        id
        fullname
        email
        avatar
        isOnline
        userMeta(key: "lang_level") {
          value
        }
        loaders( status: "passed",  group_id:$group_id) {
          completed_at(humanReadable: false)
          user_id
          points
          group_id
        }
      }
    }
  }`,

  FIND_COURSECOMPLETED_RESULT: gql` query findClassRoomWithStudents($id:ID!, $group_id:[Int], $user_id:ID) {
  room(id:$id) {
    students(user_id:$user_id) {
      loaders(group_id:$group_id){
        status
        cefr_rate
        completed_at(humanReadable: false)
        created_at(humanReadable: false)
        user_id
        points
        obtainedPercent
        lesson{
          id
          name
          pass_percent
        }
        group_id
        cefr_history
        totalPass
      }
    }
  }
}`,

  SEARCH_STUDENTS: gql`query searchUser($search:String!,$roomId:ID){
    searchUser(search:$search, roomId:$roomId){
      id
      fullname
    }
  }`,

  // Report queries
  FETCH_LESSSON_ID: gql`query fetchLessonIds($id: ID!, $courseId: ID){
    room(id: $id){
      coursesLesson(course_id: $courseId){
        lessons
      }
    }
  }`,
  FETCH_REPORT: gql`query fetchReport($id: ID!, $lessonIds: [Int], $groupId: [Int], $user_id: ID, $classTestId: Int) {
    room(id: $id) {
      name
      type
      courses {
        id
        name
        totalLessons
      }
      students(user_id: $user_id, class_test_id: $classTestId) {
        id
        fullname
        loaders(lesson_id: $lessonIds, group_id: $groupId, class_test_id: $classTestId){
          lesson {
            id
            name
            pass_percent
          }
          cefr_rate
          cefr_level
          user_id
          totalPass
          totalFail
          time_spent
          obtainedPercent
          status
          round
          points
          completed_at
        }
      }
    }
  }`,
  // TODO: use conditional user_id for user reports
  FETCH_REPORT_MULTIPLE_STUDENT: gql`query fetchReport($id: ID!, $lessonIds: [Int], $groupId: [Int], $user_id: [ID]) {
    room(id: $id) {
      name
      type
      courses {
        id
        name
        totalLessons
      }
      students(user_ids: $user_id) {
        id
        fullname
        loaders(lesson_id: $lessonIds, group_id: $groupId){
          lesson {
            id
            name
            pass_percent
          }
          cefr_rate
          cefr_level
          user_id
          totalPass
          totalFail
          time_spent
          obtainedPercent
          status
          round
          points
          completed_at
        }
      }
    }
  }`,
  // TODO: use conditional user_id for user reports
  FETCH_REPORT_BY_GROUP: gql`query fetchReportByGroup($id: ID!, $lessonIds: [Int], $groupId: [Int], $user_id: ID,  $classTestId: Int) {
    room(id: $id) {
      name
      type
      students(user_id: $user_id) {
        fullname
        id
        loaders(lesson_id: $lessonIds, group_id: $groupId, class_test_id: $classTestId){
          lesson {
            id
            name
            totalProblems
          }
          points
          totalPass
          totalFail
          created_at
          completed_at
          cefr_rate
          cefr_level
          time_spent
          user_id
          status
          round
        }
      }
    },
  }`,
  FETCH_REPORT_BY_GROUP_MULTIPLE_USER: gql`query fetchReportByGroup($id: ID!, $lessonIds: [Int], $groupId: [Int], $user_id: [ID]) {
    room(id: $id) {
      name
      type
      students(user_ids: $user_id) {
        fullname
        id
        loaders(lesson_id: $lessonIds, group_id: $groupId){
          lesson {
            id
            name
            totalProblems
          }
          points
          totalPass
          totalFail
          created_at
          completed_at
          cefr_rate
          cefr_level
          time_spent
          user_id
          status
          round
        }
      }
    },
  }`,
  Fetch_Course_List: gql`query fetchCourseList($id: ID, $classId: ID!){
    rooms(id: $id) {
      data {
        id
        courses {
          id
          name
          selectedLessons(class_id: $classId) {
            id
            name
          }
        }
      }
    }
  }`,
  FETCH_LESSON_LIST: gql`query($id: ID!){
    course(id: $id) {
      lessons {
        id
        name
        lessonStandards {
          code
          name
        }
      }
    }
  }`,

  FETCH_STUDENT_LESSON_REPORT: gql`query($userID: Int, $groupId: Int){
    loaders(user_id: $userID, group_id: $groupId) {
      data {
        lesson{
          id
          name
          pass_percent
          totalProblems
        }
        cefr_level
        cefr_rate
        completed_at(humanReadable: false)
        created_at(humanReadable: false)
        totalPass
        totalFail
        totalProblems
        time_spent
        status
        score
        round
        points
      }
    }
  }`,

  GET_LESSON_COURSE_RESULT: gql`query getResultCourse($user_id: Int, $group_id: Int,$course_id: Int){
    loaders(user_id: $user_id,group_id: $group_id,course_id: $course_id) {
      data{
      id
      completed_at
      user_id
      event_id
      group_id
      lesson_id
      status
      totalPass
      points
      time_spent
      totalProblems
      loaderType
      lesson{
        id
        lessonStandards{
          id
          code
          name
        }
        name
        totalProblems
      },
    }
    }
  }`,
  // Report queries

  FETCH_LESSON_WITH_GROUP: gql`query($id: ID!, $courseId: ID, $classId: ID){
    room(id:$id){
      courses(id:$courseId){
        id
        name
        selectedLessons(class_id:$classId){
          id
          name
          default_engine
          image
          file
          live_link
          lessonPage{
            id
              pages{
                id
              }
          }
          lessonGroup{
            id
            name

          }
        }
      }
    }
  }`,
  FALLBACK_FETCH_LESSON_WITH_GROUP: gql`query($id: ID!, $courseId: ID){
    room(id:$id){
      courses(id:$courseId){
        id
        name
        lessons{
          id
          name
          image
          file
          live_link
          lessonPage{
            id
              pages{
                id
            }
          }
          lessonGroup{
            id
            name
          }
        }
      }
    }
  }`,

  GET_COURSE_INFO: gql`query courses($id: ID!) {
    course (id: $id) {
      name
    }
  }`,

  GET_LESSON_PASSED_RESULT: gql`query getLessonResult($user_id: Int,$group_id: Int,$lesson_id: Int,$event_id: Int){
    loaders(user_id: $user_id,group_id: $group_id,lesson_id: $lesson_id,event_id: $event_id){
      data {
      id
      user_id
      score
      time_spent
      group_id
      lesson_id
      user {
        fullname
      }
      completed_at
      engine
      points
      status
      obtainedPercent
      totalProblems
      lesson {
        image
        course_id
        name
        pass_percent
      }
      passedProblems {
        id
        name
        standard {
          name
          code
        }
      }
      failedProblems {
        standard {
          code
        }
      }
      totalPass
      totalFail
    }
    }
  }`,

  TOP_SCORER_QUERY: gql`query highestPoint($roomId:ID!){
   top_scorer: sorting(roomId:$roomId, reportType:REPORT_HIGHEST_POINT) {
      user {
        fullname
        avatar
        id
      }
      points
    }

  first_finisher: sorting(roomId:$roomId, reportType:REPORT_FIRST_LESSON_COMPLETED) {
      user {
        fullname
        avatar
        id
      }
      completed_at
    }
  }`,

  FETCH_LESSON_STATUS: gql`query getLessonStatus($user_id:Int, $group_id: Int){
       loaders(user_id: $user_id, group_id: $group_id) {
      data {
          created_at(humanReadable: true)
          lesson_id
          completed_at(humanReadable: true)
          user {
            id
            firstname
          }
         }
       }
  }`,

  GET_LESSION_HIGH_SCORE: gql`query getScore($roomId: ID!,$lessonId: ID){
    sorting(reportType: REPORT_HIGHEST_POINT, roomId: $roomId, lessonId: $lessonId) {
      points
      user {
        id,
        firstname
      }
    }
  }`,

  GET_LESSION_FIRST_FINISHER: gql`query highestPoint($roomId: ID!,$lessonId: ID) {
    sorting(reportType:REPORT_HIGHEST_POINT, roomId: $roomId, lessonId: $lessonId) {
      user {
        id,
        firstname
      }
      points
    }
  }`,

  GET_USER_OVERALL_SCORE: gql`query user($id: ID!){
    user(id: $id) {
      userMeta(key: "points") {
        value
      }
    }
  }`,

  GET_AUTO_GENERATED_PROBLEMS_LIST: gql`query fetchTempLogs($key: String) {
    tempLogs(key: $key){
      data {
      key
      value
        problem {
          id
          lesson_id
          problemType {
            name
          }
        }
      }
    }
  }`,

  GET_STUDENT_STAT: gql`query getStudentStat($user_id: Int,$group_id: Int, $course_id: Int) {
    loaders(user_id: $user_id, group_id: $group_id, course_id: $course_id) {
        data {
          attempt:round
          cefrRate:cefr_rate
          completed_at
          lang_level:cefr_level
          lesson {
            name
            id
            pass_percent
          }
          score
          points
          status
          time_spend:time_spent
          total_problems:totalProblems
          obtainedPercent
        }
      }
  }`,

  GET_GUEST_LESSON_REPORT_RESULT: gql`query getResults($user_id: Int, $group_id: Int,$lessonIds: [Int] ){
    loaders(user_id:$user_id, group_id:$group_id,lessonIds: $lessonIds){
      data{
        passedProblems{
          id
          name
        }
        failedProblems{
          id
          name
          problemType {
            name
          }
        }
        lesson{
          id
          name
        }
        totalPass
        totalFail
        totalQueue
        created_at
        history{
          cefr_rate
          time_spent
          totalAttempted
        }
      }
    }
  }`,

  GET_PARENT_USER: gql`query parentUsers($userType: String) {
    parentUsers(userType: $userType){
      data{
        id
        fullname
      }
    }
  }`,

  GET_PARENT_SCHOOL: gql`query getParentUser($childId: Integer) {
    parentUsers(userType:"school", childId: $childId){
      data{
        id
        fullname
      }
    }
  }`,

  GET_SCHOOL_USERS: gql`query getSchoolUsers(
    $searchTerm: String,
  ){
    schools(search: $searchTerm)
    {
      data{
        id
        fullname
      }
    }
  }`,
  GET_SCHOOL_USERS_WITH_SCHOOL_TIME: gql`query getSchoolUsers{
    schools{
      data{
        id
        fullname
        schoolTime
      }
    }
  }`,

  GET_ALL_SCHOOLS: gql`query getUsers {
    users(type:"school") {
      data{
        id
        fullname
      }
    }
  }`,

  GET_USERS_OF_SCHOOL: gql`query getUsers($userType: String, $parentId: ID) {
    users(type: $userType, parentId: $parentId) {
      data {
        fullname
        id
      }
    }
  }`,

  GET_USERS_OF_TYPES: gql`query studentsByTeacherGroup($teacherIds: [ID]) {
    studentsByTeacherGroup( teacherIds: $teacherIds) {
      data {
        fullname
        id
      }
    }
  }`,

  GET_CLASS_BY_TEACHER: gql`query getClassrooms($schoolId: ID!, $teacherId: ID!, $page: Int, $limit: Int) {
    classroomByTeacher(school_id: $schoolId, teacher_id: $teacherId, page: $page, limit: $limit) {
      total
      current_page
      last_page
      from
      to
      data {
        id
        name
        type
        link
        students{
          id
        }
        school {
          id
          fullname
        }
        coursesLesson{
          course_id
          lessons
        }
        courses {
          id
          name
          lessons{
            id
            name
          }
        }
        teacher {
          id
          fullname
        }
        owner{
          id
          fullname
        }
      }
    }
  }`,

  GET_CLASS_BY_SCHOOL: gql`query getClassrooms($schoolId: ID!, $page: Int, $limit: Int) {
    classroomBySchool(school_id: $schoolId, page: $page, limit: $limit) {
      total
      current_page
      last_page
      from
      to
      data {
        id
        name
        type
        link
        students{
          id
        }
        school {
          id
          fullname
        }
        coursesLesson{
          course_id
          lessons
        }
        courses {
          id
          name
          lessons{
            id
            name
          }
        }
        teacher {
          id
          fullname
        }
        owner{
          id
          fullname
        }
      }
    }
  }`,

  GET_USER_DROPDOWN: gql`query getUserDropDown($type: String, $search: String, $limit: Int) {
    users(type: $type,search: $search,limit: $limit){
      data{
        id
        fullname
      }
    }
  }`,
  GET_MY_CLASSROOM: gql` query getMyClassroom($limit: Int) {
    rooms(limit: $limit) {
      data {
        id
        value:id
        text:name
        name
        school {
          id
        }
      }
    }
  }`,
  GET_CERF_SUBJECT: gql`query getCefr {
    cefrGrade {
      data {
        id,
        name,
        subjects{
        id
        name
        domains {
          id
          name
          standards{
            id
            name
          }
        }
      }
      }
    }
  }`,

  GET_CLASSROOM_INFO: gql`query getClassRoomInfo($id: ID!) {
      room(id: $id) {
        id,
        name,
        owner {
          id
        }
      }
  }`,

  GET_CLASSROOM_STUDENTS: gql`query getClassRoomStudents($id: ID!) {
    room(id: $id) {
      id 
      students {
        id
        fullname
      }
    }
  }`,

}
