<template>
  <b-overlay :show="isProcessing">
    <vue-good-table
      :columns="columns"
      :rows="rows"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <span v-if="props.column.field==='name'"
              class="d-flex align-items-center"
              style="gap:8px;"
        >
          {{ props.row.name }}
          <feather-icon v-b-tooltip.hover="infoTexts[props.row.name]"
                        icon="InfoIcon"
                        class="text-primary cursor-pointer"
          />
        </span>

        <span
          v-if="props.column.field === 'value'"
          class="text-nowrap"
        >
          <span v-if="['tts_support', 'global_game_wrapper'].includes(props.row.name)">
            <b-form-checkbox
              :checked="!! props.row.value"
              class="custom-control-primary"
              name="check-button"
              switch
              @change="(value) => updateSettings(props.row.name, value)"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </span>
          <span v-else-if="props.row.value === 'refresh-database'">
            <b-button
              variant="primary"
              :disabled="isProcessing"
              @click="refreshDatabase"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              <feather-icon
                v-if="!isProcessing"
                icon="RefreshCwIcon"
              />  {{ $t('reset-dummy-account') }}
            </b-button>
          </span>
          <span v-else-if="['conversion_rate','point_to_coin_conversion_rate','point_conversion_limit', 'index_to_show_game', 'required_similarity_percent_for_speech'].includes(props.row.name)">
            <b-form-input
              :placeholder="props.row.name"
              :value="props.row.value"
              style="width: 100px"
              @change="(value) => updateSettings(props.row.name, value)"
            />
          </span>
          <span v-else-if="['game_settings'].includes(props.row.name)">
            <b-form-select
              :value="props.row.value"
              :options="gameSettingNames"
              style="width: 120px"
              @change="(value) => updateSettings(props.row.name, value)"
            />
          </span>
          <span v-else-if="props.row.name === 'weakest_skill_weightage'">
            <b-form-input
              :value="props.row.value"
              type="number"
              style="width: 100px"
              @change="(value) => updateSettings(props.row.name, value)"
            />
          </span>
          <span v-else-if="props.row.name === 'language_level_indicator'">
            <table>
              <thead>
                <tr>
                  <th v-for="value,key of langLevelIndicator"
                      :key="key"
                  >{{ key }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th v-for="value,key of langLevelIndicator"
                      :key="key"
                  >
                    <input
                      v-model="langLevelIndicator[key].label"
                      type="text"
                      style="width: 80px"
                      @change="handleLangLevelChange"
                    >
                  </th>
                </tr>
              </tbody>
            </table>
          </span>
          <span v-else-if="props.row.name === 'lang_level_score_counts'">
            <table>
              <thead>
                <tr>
                  <th v-for="value,key of langLevelWordScores"
                      :key="key"
                  >{{ key }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th v-for="value,key of langLevelWordScores"
                      :key="key"
                  >
                    <input
                      v-model="langLevelWordScores[key].value"
                      type="number"
                      style="width: 80px"
                      @change="handleLangLevelWordScoreChange"
                    >
                  </th>
                </tr>
              </tbody>
            </table>
          </span>
          <span v-else-if="['streak_milestone','perseverance_milestone'].includes(props.row.name)">
            <b-form-input
              :placeholder="props.row.name"
              :value="props.row.value"
              style="width: 100px"
              @change="(value) => updateSettings(props.row.name, value)"
            />
          </span>
          <span v-else-if="['streak_perseverance_message','streak_perseverance_audio'].includes(props.row.name)">
            <b-form-checkbox
              :checked="props.row.value === '1'"
              class="custom-control-primary"
              name="check-button"
              switch
              @change="(value) => updateSettings(props.row.name, value)"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </span>
          <span v-else-if="['streak_message_text','perseverance_message_text'].includes(props.row.name)">
            <b-form-input
              :placeholder="props.row.name"
              :value="props.row.value"
              style="width: 100%"
              @change="(value) => updateSettings(props.row.name, value)"
            />
          </span>
          <span v-else-if="['streak_message_audio','perseverance_mesasge_audio'].includes(props.row.name)">
            <span v-if="(props.row.name === 'streak_message_audio')"
                  class="d-flex"
            >
              <Recorder
                :url="props.row.value"
                :hide-polly="true"
                @onRemove="updateSettings(props.row.name, '')"
                @input="handleAudio($event, props.row.name)"
              />
            </span>

            <span v-if="(props.row.name === 'perseverance_mesasge_audio')"
                  class="d-flex"
            >
              <Recorder
                :url="props.row.value"
                :hide-polly="true"
                @onRemove="updateSettings(props.row.name, '')"
                @input="handleAudio($event, props.row.name)"
              />
            </span>
          </span>
          <!-- Personality popup settings -->
          <!-- <span v-else-if="['personality_popup'].includes(props.row.name)">
            <b-form-checkbox
              :checked="props.row.value"
              value="1"
              unchecked-value="0"
              switch
              @change="(value) => { updateSettings(props.row.name, value) }"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </span> -->
          <span v-else>
            {{ props.row.value }}
          </span>
        </span>
      </template>
      <template slot="emptystate">
        <p class="text-center">
          {{ $t('messages.no-data-available') }}
        </p>

      </template>
    </vue-good-table>
  </b-overlay>
</template>
<script>
import { VueGoodTable } from 'vue-good-table'
import useJwt from '@/auth/jwt/useJwt'
import {
  BFormCheckbox, BFormInput, BFormSelect, BButton, BSpinner, BOverlay,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import Recorder from '@/views/super/problem/components/recorder.vue'

export default {
  components: {
    VueGoodTable,
    BFormCheckbox,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BOverlay,
    Recorder,
  },
  props: {
    requiredSettings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columns: [
        {
          label: i18n.tc('labels.name'),
          field: 'name',
        },
        {
          label: i18n.tc('labels.value'),
          field: 'value',
        },
      ],
      rows: [],
      gameSettingNames: [],
      isProcessing: false,
      langLevelIndicator: {},
      langLevelWordScores: {},
      infoTexts: {
        tts_support: 'TTS support enables all the text in the student lab to be both clickable and speakable.',
        point_conversion_limit: 'The point conversion limit defines the maximum number of points that a student can accumulate in the system before their points are automatically converted into coins. '
                + 'In this context, the conversion rate is such that 1 coin is equivalent to the defined limit of points. '
                + 'For example, if the point conversion limit is set to 1000, it means that once a student accumulates 1000 points in the system, those points will be automatically converted into 1 coin. '
                + 'This conversion helps manage the student\'s point balance and provides a tangible unit (coins) that can be used or redeemed within the system.',
        point_to_coin_conversion_rate: 'Total Coins = Total Points * Point to Coin Conversion Rate.  '
                + 'Where: Total Points represents the number of points a student has accumulated.'
                + 'Point to Coin Conversion Rate is the rate at which points are converted to coins. '
                + 'For example, if this rate is 0.001, it means 1 point is equivalent to 0.001 coins.',
        index_to_show_game: 'An index for showing games in a continuous sequence within the same interval in the student lab.',
        weakest_skill_weightage: 'The weakest_skill_weightage represents the percentage of problems assigned to the weakest skill category. '
                + 'For instance, if there are four skills (reading, writing, listening, and speaking) and the weakest skill, which is reading, '
                + 'has a weightage of 40% out of a total of 100 problems, then there will be 40 problems related to reading, and the remaining skills will each have 20 problems assigned.',
        conversion_rate: 'Conversion Rate',
        language_level_indicator: 'The `language_level_indicator` is a label that reflects a student\'s language proficiency level. The default levels are B2, B1, A2, and A1. '
                + 'These levels correspond to CEFR (Common European Framework of Reference for Languages) ratings as follows: '
                + ' - A1: CEFR rating 0 to 25 '
                + ' - A2: CEFR rating 26 to 50 '
                + ' - B1: CEFR rating 51 to 75 '
                + ' - B2: CEFR rating 76 to 100',
        global_game_wrapper: 'Enabling global_game_wrapper allows games to be integrated between problems in the student lab while users are solving problems.',
        required_similarity_percent_for_speech: 'The required_similarity_percent_for_speech is the minimum answer similarity percentage that a student should achieve when responding to prompted speaking problems.',
        'Click here to reset dummy account': 'We have a demo system that allows users to experience the entire GEC system. Some data in the demo is set as unchangeable. If you click this button, all newly added data will be erased, and the system will be restored to its original unchangeable dummy data.',
        'Streak and Perseverance Widget': 'Streak and Perseverance Widget',
        streak_milestone: 'Streak Milstone',
        perseverance_milestone: 'Perseverance Milestone',
        streak_perseverance_message: 'Enabling this will show Streak and Perseverance messages',
        streak_perseverance_audio: 'Enabling this will show Streak and Perseverance audio',
        streak_message_text: 'Add to show Streak message',
        streak_message_audio: 'Add to show Streak message audio',
        perseverance_message_text: 'Add to show Perseverance message',
        perseverance_mesasge_audio: 'Add to show Perseverance mesasge audio',
      },
      streakRecording: false,
      recordedFileBase64Url: '',
    }
  },
  mounted() {
    this.getSettings()
  },
  methods: {
    uploadPerseverance() {
      this.$refs.perservanceAudioInput.$el.childNodes[0].click()
    },
    uploadStreak() {
      this.$refs.streakAudioInput.$el.childNodes[0].click()
    },
    getSettings() {
      useJwt.getGlobalSetting().then(response => {
        this.gameSettingNames = response.data.data.game_settings_names
        const { settings } = response.data.data
        console.log(settings)
        const streakMilestone = settings.find(row => row.name === 'streak_milestone')
        if (!streakMilestone) {
          settings.push(
            {
              name: 'streak_milestone',
              value: '',
            },
          )
        }
        const perseveranceMilestone = settings.find(row => row.name === 'perseverance_milestone')
        if (!perseveranceMilestone) {
          settings.push(
            {
              name: 'perseverance_milestone',
              value: '',
            },
          )
        }
        const streakPerseveranceMessage = settings.find(row => row.name === 'streak_perseverance_message')
        if (!streakPerseveranceMessage) {
          settings.push(
            {
              name: 'streak_perseverance_message',
              value: '',
            },
          )
        }
        const streakMessgeText = settings.find(row => row.name === 'streak_message_text')
        if (!streakMessgeText) {
          settings.push(
            {
              name: 'streak_message_text',
              value: '',
            },
          )
        }

        const perseveranceMessgeText = settings.find(row => row.name === 'perseverance_message_text')
        if (!perseveranceMessgeText) {
          settings.push(
            {
              name: 'perseverance_message_text',
              value: '',
            },
          )
        }

        const streakPerseveranceAudio = settings.find(row => row.name === 'streak_perseverance_audio')
        if (!streakPerseveranceAudio) {
          settings.push(
            {
              name: 'streak_perseverance_audio',
              value: '',
            },
          )
        }

        const streakMessgeAudio = settings.find(row => row.name === 'streak_message_audio')
        if (!streakMessgeAudio) {
          settings.push(
            {
              name: 'streak_message_audio',
              value: '',
            },
          )
        }

        const perseveranceMessgeAudio = settings.find(row => row.name === 'perseverance_mesasge_audio')
        if (!perseveranceMessgeAudio) {
          settings.push(
            {
              name: 'perseverance_mesasge_audio',
              value: settings.find(row => row.name === 'perseverance_mesasge_audio')?.value,
            },
          )
        }

        settings.push(
          {
            name: 'Click here to reset dummy account',
            value: 'refresh-database',
          },
        )
        this.rows = settings.filter(row => {
          if (row.name === 'game_settings') return false
          if (this.requiredSettings.length) {
            return this.requiredSettings.includes(row.name)
          }
          return true
        })
        this.langLevelIndicator = settings.find(item => item.name === 'language_level_indicator')?.value
        this.langLevelWordScores = settings.find(item => item.name === 'lang_level_score_counts')?.value
      })
    },
    updateSettings(key, value) {
      this.isProcessing = true
      useJwt.updateGlobalSettings({
        key,
        value,
        audio: '',
      }).then(response => {
        this.getSettings()
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    handleLangLevelChange() {
      this.updateSettings('language_level_indicator', JSON.stringify(this.langLevelIndicator))
      // eslint-disable-next-line no-param-reassign
      // object.value[key].label = value.target.value
      // this.$forceUpdate()
    },
    handleLangLevelWordScoreChange() {
      this.updateSettings('lang_level_score_counts', JSON.stringify(this.langLevelWordScores))
      // eslint-disable-next-line no-param-reassign
      // object.value[key].label = value.target.value
      // this.$forceUpdate()
    },
    refreshDatabase() {
      if (confirm('Are you sure you want to refresh?')) {
        this.isProcessing = true
        useJwt.restoreDummyDatabase().then(response => {
          this.showSuccessMessage(response)
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => { this.isProcessing = false })
      }
    },
    handleAudio(event, key) {
      this.isProcessing = true
      const file = event.url
      if (!file) {
        return
      }
      this.blobUrlToBase64(file).then(result => {
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.accessToken}`,
          },
        }
        const data = new FormData()
        data.append('key', key)
        data.append('audio', result)
        useJwt.updateGlobalSettingsFile(data, config)
          .then(response => {
            this.getSettings()
            this.showSuccessMessage(response)
          })
          .catch(error => {
            this.showErrorMessage(error)
          })
          .finally(() => {
            this.isProcessing = false
          })
      })
    },
  },
}
</script>
