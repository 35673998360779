<template>
  <div>
    <b-form-radio-group v-model="calibrationType"
                        class="mb-2"
    >
      <b-form-radio value="course">
        By Course
      </b-form-radio>
      <b-form-radio value="class_test">
        By Test
      </b-form-radio>
    </b-form-radio-group>
    <b-row>
      <b-col cols="3">
        <b-card>
          <b-form-group label="Classrooms">
            <vSelect
              v-model="selectedClass"
              :clearable="false"
              label="text"
              :reduce="(value) => value.value"
              :options="classRoomsList"
              @input="getCalibrationStats"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <template v-if="calibrationType === 'course'">
        <b-col cols="3">
          <b-card>
            <b-form-group label="Courses">
              <vSelect
                v-model="selectedCourse"
                :clearable="false"
                label="name"
                :reduce="(value) => value.id"
                :options="courses"
              />
            </b-form-group>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card>
            <b-form-group label="Lesson Groups">
              <vSelect
                v-model="selectedLessonGroups"
                :clearable="false"
                label="name"
                :reduce="(value) => value.id"
                :options="lessonGroups"
              />
            </b-form-group>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card>
            <b-form-group label="Lessons">
              <vSelect
                v-model="selectedLessons"
                :clearable="false"
                label="name"
                :reduce="(value) => value.id"
                :options="lessons"
                @input="onLessonChanged"
              />
            </b-form-group>
          </b-card>
        </b-col>
      </template>
      <template v-else>
        <b-col cols="3">
          <b-card>
            <b-form-group label="Tests">
              <vSelect
                v-model="selectedClassTest"
                :clearable="false"
                label="name"
                :reduce="(value) => value.id"
                :options="computedClassTestLists"
              />
            </b-form-group>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card>
            <b-form-group label="Lessons">
              <vSelect
                v-model="selectedLessons"
                :clearable="false"
                label="name"
                :reduce="(value) => value.id"
                :options="computedTestLessons"
                @input="onLessonChanged"
              />
            </b-form-group>
          </b-card>
        </b-col>
      </template>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay :show="isProcessing">
          <b-spinner v-if="isGettingCefr" />
          <CalibrateForm v-if="!isGettingCefr && selectedLessons"
                         :key="selectedLessons || 'form'"
                         :students-lists="ARRAY_UNIQUE_BY_KEY(studentsLists, 'student_id')"
                         :class-id="selectedClass"
                         :lesson-id="selectedLessons"
                         :cefr-class-room-reports="cefrClassRoomReports"
                         :number-of-students="numberOfStudents"
                         :lesson="calibrationType === 'course' ? lessons.find(item => item.id == selectedLessons) : computedTestLessons.find(item => item.id == selectedLessons)"
                         :default-calibration-average="calibrationAverage"
          />
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard, BFormGroup, BRow, BCol, BOverlay, BSpinner, BFormRadioGroup, BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import CalibrateForm from './CalibrateForm.vue'

export default {
  components: {
    BFormGroup, BRow, BCol, CalibrateForm, vSelect, BOverlay, BCard, BSpinner, BFormRadioGroup, BFormRadio,
  },
  props: {
    numberOfStudents: {
      type: Number,
      default: 0,
    },
    classRoomsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedClass: null,
      studentsLists: [],
      isProcessing: false,
      calibrationAverage: null,
      classroomCourses: [],
      cefrClassRoomReports: [],
      selectedCourse: null,
      selectedLessonGroups: null,
      selectedLessons: null,
      isGettingCefr: false,
      calibrationType: 'course',
      classTestLists: [],
      selectedClassTest: null,
    }
  },
  computed: {
    courses() {
      return this.classroomCourses.map(item => ({
        id: item.id,
        name: item.name,
        lesson_groups: item.lesson_groups,
      }))
    },
    lessonGroups() {
      const course = this.courses.find(item => item.id === this.selectedCourse)
      if (!course) {
        return []
      }
      return course.lesson_groups.map(item => ({
        id: item.id,
        course_id: item.course_id,
        lessons: item.lessons,
        name: item.name,
      }))
    },
    lessons() {
      const lesson = this.lessonGroups.find(item => item.id === this.selectedLessonGroups)
      if (!lesson) {
        return []
      }
      return this.ARRAY_UNIQUE_BY_KEY(lesson.lessons.map(item => ({
        id: item.id,
        lesson_group_id: item.lesson_group_id,
        name: item.name,
      })), 'id')
    },
    computedClassTestLists() {
      return this.classTestLists.map(item => ({
        id: item.id,
        name: item.name,
        lessons: item.lessons,
      }))
    },
    computedTestLessons() {
      const test = this.computedClassTestLists.find(item => item.id === this.selectedClassTest)
      if (!test) {
        return []
      }
      return this.ARRAY_UNIQUE_BY_KEY(test.lessons.map(item => ({
        id: item.id,
        name: item.name,
      })), 'id')
    },
  },
  watch: {
    calibrationType() {
      this.$nextTick(() => {
        this.getCalibrationStats()
      })
    },
  },
  methods: {
    getClassroomCourses() {
      useJwt.getCourses(null, {
        params: {
          class_id: this.selectedClass,
        },
      }).then(response => {
        this.classroomCourses = response.data.data
      })
    },
    onLessonChanged() {
      this.isGettingCefr = true
      useJwt.getStudentCalibrationCefr(this.selectedClass, this.selectedLessons, {
        params: {
          type: this.calibrationType === 'course' ? 'course' : 'test',
        },
      }).then(response => {
        this.cefrClassRoomReports = response.data.data.flat()
      }).finally(() => {
        this.isGettingCefr = false
      })
    },
    getClassTestInfo() {
      useJwt.getSelectedLessonsForTest(this.selectedClass).then(response => {
        this.classTestLists = response.data?.data
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    getCalibrationStats() {
      if (!this.selectedClass) {
        return
      }
      if (this.calibrationType === 'course') {
        this.getClassroomCourses()
      } else {
        this.getClassTestInfo()
      }
      useJwt.getStudentCalibration(this.selectedClass).then(response => {
        if (response.data.data) {
          const studentCalibration = response.data.data.calibration_students
          this.studentsLists = studentCalibration.map(item => ({
            student_id: item.student_id,
            fullName: `${item.student?.firstname} ${item.student?.lastname}`,
            selected: 1,
            R: item.reading_output,
            L: item.listening_output,
            S: item.speaking_output,
            W: item.writing_output,
            reading: item.reading_inside,
            listening: item.listening_inside,
            writing: item.writing_inside,
            speaking: item.speaking_inside,
            R_computed: item.reading_output - item.reading_inside,
            L_computed: item.listening_output - item.listening_inside,
            S_computed: item.speaking_output - item.speaking_inside,
            W_computed: item.writing_output - item.writing_inside,
          }))
          this.calibrationAverage = {
            listening: response.data.data.average_listening,
            reading: response.data.data.average_reading,
            speaking: response.data.data.average_speaking,
            writing: response.data.data.average_writing,
          }
        } else {
          this.getClassroomMembers()
        }
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    getClassroomMembers() {
      useJwt
        .getClassRoomStat(this.selectedClass)
        .then(response => {
          this.studentsLists = response.data.data.students.map(item => ({ student_id: item.student_id, fullName: `${item.firstname} ${item.lastname}` }))
        }).finally(() => {
          this.isProcessing = false
        })
    },
  },
}
</script>
