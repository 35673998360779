var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-overlay',{attrs:{"show":_vm.isProcessing}},[_c('b-tabs',{attrs:{"pills":""}},[_c('b-tab',{attrs:{"title":_vm.$t('calendar-module.calendar-view')}},[_c('event-template-calendar',{key:_vm.rows.length,attrs:{"events":_vm.rows,"isolated-template":_vm.isolatedTemplate},on:{"refetch":_vm.showCalendarTemplate}})],1),_c('b-tab',{attrs:{"title":_vm.$t('calendar-module.list-view')}},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"custom-search d-flex justify-content-end py-1"},[_c('b-form-group',[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"outline-primary"},on:{"click":_vm.exportListToExcel}},[_c('feather-icon',{attrs:{"icon":"DownloadIcon"}}),_vm._v(" Export Events ")],1)],1),_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
          enabled: true,
          externalQuery: _vm.searchTerm },"select-options":{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        },"pagination-options":{
          enabled: true,
          perPage:_vm.pageLength
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'fullName')?_c('span',{staticClass:"text-nowrap"},[_c('b-avatar',{staticClass:"mx-1",attrs:{"src":props.row.avatar}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.fullName))])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3','5','10']},on:{"input":(value)=>props.perPageChanged({currentPerPage:value})},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"disabled":_vm.isProcessing,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"input":(value)=>props.pageChanged({currentPage:value})},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }