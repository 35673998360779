<template>
  <b-card
    no-body
    class="card-statistics student-statistics"
  >
    <b-card-body>
      <b-row>
        <b-col v-for="(item, index) in stats.statisticData"
               :key="index"
               :class="item.customClass"
        >
          <b-media
            no-body
            class="stats-hover stats-detail"
          >
            <b-media-aside
              class="mr-0 pr-1"
              @click="setActiveTable(item)"
            >
              <b-avatar
                :variant="item.color"
                class="stat-avatar"
              >
                <feather-icon
                  :icon="item.icon"
                  v-bind="{ ...( item.fill && { fill: item.fill } ) }"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body
              class="my-auto"
              @click="setActiveTable(item)"
            >
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text
                class="mb-0"
              >
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard, BCol, BRow, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody, BCardText,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import { mapGetters } from 'vuex'
import { getUserData } from '@/auth/utils'
import {
  USER_TYPE_STUDENT,
  USER_TYPE_TEACHER,
} from '@/const/userType'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCardText,
  },
  data() {
    return {
      stats: {
        statisticData: [],
      },
      isAStudent: getUserData() && getUserData().usertype === USER_TYPE_STUDENT,
      isATeacher: getUserData() && getUserData().usertype === USER_TYPE_TEACHER,
    }
  },
  computed: {
    ...mapGetters('appConfig', ['getSchoolSettings', 'getDistrictSettings']),
    hiddenFeatures() {
      return {
        greeting: !this.getDistrictSettings?.find(metaValue => metaValue.key === 'student_dashboard_greeting')?.value || this.getDistrictSettings?.find(metaValue => metaValue.key === 'student_dashboard_greeting')?.value === '1',
        stats: this.getDistrictSettings?.find(metaValue => metaValue.key === 'student_dashboard_stats')?.value === '1',
        activity: this.getDistrictSettings?.find(metaValue => metaValue.key === 'student_dashboard_activity')?.value === '1',
        event: this.getDistrictSettings?.find(metaValue => metaValue.key === 'student_dashboard_calendar')?.value === '1',
        timeOn: !this.getDistrictSettings?.find(metaValue => metaValue.key === 'student_dashboard_time_on')?.value || this.getDistrictSettings?.find(metaValue => metaValue.key === 'student_dashboard_time_on')?.value === '1',
        hoursTracked: !this.getDistrictSettings?.find(metaValue => metaValue.key === 'student_dashboard_hour_tracked')?.value || this.getDistrictSettings?.find(metaValue => metaValue.key === 'student_dashboard_hour_tracked')?.value === '1',
        rubric: this.getDistrictSettings?.find(metaValue => metaValue.key === 'student_dashboard_rubrics')?.value === '1',
      }
    },
    showLangLevel() {
      if (this.isAStudent || this.isATeacher) {
        const distLangLevelSetting = this.getDistrictSettings?.find(metaValue => metaValue.key === 'lang_level') ?? '0'
        // check school level setting
        const schoolLangLevelSetting = this.getSchoolSettings?.find(metaValue => metaValue.key === 'lang_level') ?? '0'
        if (distLangLevelSetting?.value !== '0' && schoolLangLevelSetting?.value !== '0') {
          return false
        }
        return true
      }
      return true
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    pushToStats({
      statsData = 'statisticData', title, subtitle, color, icon, fill, type = '',
    }) {
      this.stats[statsData].push({
        type,
        title,
        subtitle,
        color,
        icon,
        fill,
        customClass: 'mb-2 mb-xl-0',
      })
    },
    loadData() {
      const userId = this.$route.params.id
      const params = {}
      if (userId) {
        params.id = userId
        this.fromTeacher = true
      } else {
        this.fromTeacher = false
        const userData = JSON.parse(localStorage.getItem('userData'))
        this.welcomeData = {
          name: userData?.fullname ? userData.fullname : '',
          time: this.generateGreetings(),
          text: i18n.tc('messages.welcome-dashboard'),
        }
      }
      useJwt.getStudentDashboardData({ params }).then(res => {
        const response = res.data?.data
        // stats data
        if (response) {
          this.pushToStats({
            title: response.total_lesson, subtitle: response.total_lesson > 1 ? i18n.tc('labels.lessons-done') : i18n.tc('labels.lesson-done'), color: 'light-info', icon: 'BookIcon',
          })
          if (this.showLangLevel) {
            this.pushToStats({
              title: response.lang_level, subtitle: i18n.tc('labels.current-lang-level'), color: 'light-danger', icon: 'BarChartIcon',
            })
          }
          this.pushToStats({
            title: response.score.toFixed(2), subtitle: `${i18n.tc('labels.score-achieved')}`, color: 'light-secondary', icon: 'CheckIcon',
          })

          this.pushToStats({
            title: response.attendance_of_month, subtitle: i18n.tc('labels.study_days_of_month'), color: 'light-success', icon: 'UserIcon', fill: '#28c76f',
          })
        }

        // student_activity data
        if (response?.student_activity) {
          const activity = response.student_activity?.diary
          this.activityData = []
          Object.keys(activity).forEach(k => {
            this.activityData = [...this.activityData, ...activity[k]]
          })
        }
        if (userId) {
          this.welcomeData = { name: response.user_name }
        }
        this.eventData = response.event
      })
    },
    getAwardStats() {
      this.isProcessing = true
      // Push to Award Stats
      const params = { uid: this.$route.params.id }
      useJwt.getStudentRewardCount({
        params,
      }).then(response => {
        const res = response.data?.data?.award_stats

        // Badges
        this.pushToStats({
          statsData: 'awardStatsData',
          type: 'badge',
          title: res.badge,
          subtitle: res.badge > 1 ? i18n.tc('labels.badges') : i18n.tc('labels.badge'),
          icon: 'AwardIcon',
          fill: 'transparent',
          color: 'light-warning',
        })

        // Vibes
        this.pushToStats({
          statsData: 'awardStatsData',
          type: 'vibe',
          title: res.vibe,
          subtitle: res.vibe > 1 ? i18n.tc('labels.vibes') : i18n.tc('labels.vibe'),
          icon: 'TwitchIcon',
          fill: 'transparent',
          color: 'light-danger',
        })

        // Points
        this.pushToStats({
          statsData: 'awardStatsData',
          type: 'points',
          title: res.points,
          subtitle: i18n.tc('labels.points'),
          icon: 'CircleIcon',
          fill: '#ff9f43',
          color: 'light-secondary',
        })

        // Coins
        this.pushToStats({
          statsData: 'awardStatsData',
          type: 'coins',
          title: res.coins,
          subtitle: i18n.tc('labels.coins'),
          icon: 'DollarSignIcon',
          fill: 'transparent',
          color: 'light-success',
        })
      }).finally(() => { this.isProcessing = false })
    },
  },
}
</script>
<style>
.stat-avatar {
  width: 48px;
  height: 48px;
}
.student-statistics .card-body{
    padding: 12px 15px;
  }
@media (max-width:992px) and (min-width:768px) {
    .card-statistics-responsive {
      margin-bottom: 80px;
    }
  }

@media(max-width:768px) {
  .stat-avatar {
    width: 20px;
    height: 20px;
  }
  .student-statistics .card-body{
    padding:15px;
  }
  .student-statistics .media-body h4 {
    font-size: 13px;
  }
  .student-statistics .media-body p {
    font-size: 11px;
    line-height: 1.2;
    height: 26px;
    overflow: hidden;
  }
}
</style>
