<template>
  <div>
    <b-col md="12">
      <h3>{{ $t("actions.delete-subject") }}</h3>
      <p>{{ $t("questions.delete-subject") }}</p>
    </b-col>
    <b-row>
      <b-col
        md="12"
        class="d-flex justify-content-end"
      >
        <b-button
          variant="tertiary"
          class="btn-icon sm-mt-2"
          @click="$emit('back')"
        >
          {{ $t('actions.cancel') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="deleteSubject"
        >
          <b-spinner
            v-show="isProcessing"
            small
          />
          {{ $t('actions.delete-subject') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow, BCol, BButton, BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    deleteSubjectData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    subject: {
      id: 0,
    },
    isProcessing: false,
  }),
  created() {
    this.subject.id = this.deleteSubjectData.id
  },
  methods: {
    deleteSubject() {
      this.isProcessing = true
      useJwt.deleteSubject(this.subject.id).then(response => {
        this.$emit('deleted', response.data.data)
        this.$emit('back')
        this.showSuccessMessage(response)
        this.$emit('back')
      })
        .finally(() => {
          this.isProcessing = false
        })
    },
  },
}
</script>
