<template>
  <div
    v-if="template != null"
    class="ck-content main-template-content"
  >
    <common
      v-if="!onlyFeedBack"
      :problem-data="template"
    />
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="!onlyFeedBack && template.before_template && template.before_template.length > 0 &&
        (getTemplateComponent !== 'Shapes' || getTemplateComponent === 'Shapes' && template.template[0].type !== 'fib' )
        && getTemplateComponent !== 'SideCard'
      "
      v-text-to-speech
    >
      <word-with-popover
        :text="template.before_template"
        meta-type="before_template"
        popover="answer"
      />
    </div>
    <!-- eslint-enable vue/no-v-html -->
    <component
      :is="getTemplateComponent"
      v-if="template.template"
      :data="template.template"
      :template="template"
      :template-options="getTemplateOptions"
    >
      <div
        v-if="getTemplateComponent === 'Shapes' && template.template[0].type === 'fib'"
        v-html="template.before_template"
      />
    </component>
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="onlyFeedBack && template.after_template && template.after_template.length > 0"
      v-text-to-speech
      v-html="template.after_template"
    />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script>
import {
  BRow, BCol, BFormSelect, BFormGroup, BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
// import FsLightbox from 'fslightbox'
import Cards from './components/Cards.vue'
import Carousel from './components/Carousel.vue'
import CenteredCards from './components/CenteredCard.vue'
import ImageParagraph from './components/ImageParagraph.vue'
import List from './components/List.vue'
import Paragraph from './components/Paragraph.vue'
import Table from './components/Table.vue'
import Shapes from './components/Shapes.vue'
import ImageQA from './components/ImageQA.vue'
import Common from './components/Common.vue'
import Scorm from './components/Scorm.vue'
import SideCard from './components/SideCard.vue'
import WordWithPopover from './components/WordWithPopover.vue'

export default {
  components: {
    Carousel,
    Cards,
    Paragraph,
    ImageParagraph,
    CenteredCards,
    List,
    BButton,
    Table,
    BRow,
    BCol,
    BFormSelect,
    BFormGroup,
    Common,
    Shapes,
    ImageQA,
    Scorm,
    SideCard,
    WordWithPopover,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
    onlyFeedBack: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapGetters('problem', {
      shapes: 'getShapes',
    }),
    getTemplateComponent() {
      if (this.template.type === 'cards') return 'Cards'
      if (this.template.type === 'centered-cards') return 'CenteredCards'
      if (this.template.type === 'carousel') return 'Carousel'
      if (this.template.type === 'list') return 'List'
      if (this.template.type === 'table') return 'Table'
      if (this.template.type === 'paragraph') return 'Paragraph'
      if (this.template.type === 'image-paragraph') return 'ImageParagraph'
      if (this.template.type === 'shapes') return 'Shapes'
      if (this.template.type === 'image-qa') return 'ImageQA'
      if (this.template.type === 'scorm') return 'Scorm'
      if (this.template.type === 'side-cards') return 'SideCard'
      if (this.template.type === 'none') return ''
      return 'Cards'
    },
    getTemplateOptions() {
      if (this.template?.type !== 'shapes') return ''
      const sh = this.template?.template[0]?.shape ? this.template?.template[0]?.shape : this.defaultShape

      return {
        shape: this.shapes.filter(shape => shape.value === sh)[0],
        type: this.template?.template[0]?.type ? this.template?.template[0]?.type : this.fibType,
        bgColor: this.template?.template[0]?.bgColor,
        textAlign: this.template?.template[0]?.textAlign ? this.template?.template[0]?.textAlign : 'left',
      }
    },
  },
  mounted() {
    // setTimeout(() => {
    //   // eslint-disable-next-line no-undef
    //   const fslightbox = new FsLightbox()
    //   fslightbox.props.sources = ['http://gec.test/storage/template/b024d8eabstract-creative-illustration-colorful-lion-abstract-creative-illustration-colorful-lion-black-color-190846410-1678181497.jpg']
    //   fslightbox.props.types = ['image']
    //   fslightbox.open()

    //   console.log(fslightbox)
    // }, 1500)
  },
  created() {
    if (this.template.template) {
      this.template.template.forEach(element => {
        // eslint-disable-next-line no-param-reassign
        if (element.image) element.image_url = element.image
      })
    }
  },

}
</script>
