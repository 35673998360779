<template>
  <b-card
    no-body
    class="card-statistics"
    :class="[
      { 'card-statistics-responsive': marketplaces && marketplaces.length && !isADashBoardView },
      cardOptions.title
    ]"
  >
    <b-card-header v-if="cardOptions.title !== 'award-statistics'">
      <b-card-title>{{ $t(`labels.${cardOptions.title}`) }}</b-card-title>
    </b-card-header>
    <b-card-body
      v-if="noStats"
      class="text-center"
    >
      <b-card-text>
        {{ $t(`messages.no-${cardOptions.title}`) }}
      </b-card-text>
    </b-card-body>
    <b-card-body
      v-else
      class="statistics-body"
    >
      <b-row class="justify-content-between w-100">
        <b-col
          v-for="(item, index) in data"
          :key="index"
          v-b-modal="`${cardOptions.title}-modal`"
          :class="[item.customClass, isADashBoardView && 'col-md-6 col-sm-12 pb-1']"
          @click="cardOptions.title === 'statistics' ? setCurrentItem(item) : setCurrentItem(item.type)"
        >
          <b-media
            no-body
            class="stats-hover stats-detail"
          >
            <b-media-aside
              class="mr-0 pr-1"
              @click="setActiveTable(item)"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                  v-bind="{ ...( item.fill && { fill: item.fill } ) }"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body
              class="my-auto"
              @click="setActiveTable(item)"
            >
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text
                class="font-small-3 mb-0"
              >
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <!-- <b-col
          v-if="marketplaces && marketplaces.length && !isADashBoardView"
          md="6"
          lg="2"
          class="p-0 marketplace-card"
        >

          <h4
            id="shakemessage"
            v-b-modal="`new-product-modal`"
            :class="$store.getters['appConfig/isDarkMode'] && 'btn-light-dark'"
          >
            {{ $t('labels.market') }}
          </h4>
        </b-col> -->
      </b-row>

      <!-- Award Stat Group Modal -->
      <award-stats-group
        v-if="cardOptions.title === 'award-statistics'"
        :student-id="studentId"
        :item="currentItem"
        :total-points="parseInt(data[2].title, 10)"
      />

      <product-list
        v-if=" !isADashBoardView"
        :marketplaces="marketplaces"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { getUserData } from '@/auth/utils'
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import {
  HIGHEST_POINTS_GAINER_BADGE, FIRST_LESSON_ACHEIVER_BADGE, EXTRAORDINARY_BADGE,
} from '@/const/badges'
import { USER_TYPE_STUDENT } from '@/const/userType'
import useJwt from '@/auth/jwt/useJwt'
import ProductList from './components/ProductList.vue'
import awardStatsGroup from './components/AwardStatsGroup.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    awardStatsGroup,
    ProductList,
  },
  data() {
    return {
      self: getUserData(),
      currentItem: '',
      marketplaces: null,
      isProcessing: false,
      badges: {
        'BADGE-HPG-L': HIGHEST_POINTS_GAINER_BADGE,
        'BADGE-HPG-C': HIGHEST_POINTS_GAINER_BADGE,
        'BADGE-FCD': FIRST_LESSON_ACHEIVER_BADGE,
        'BADGE-FLD': FIRST_LESSON_ACHEIVER_BADGE,
        'BADGE-MLD': EXTRAORDINARY_BADGE,
      },
      awardStatsGroup: {
        badge: [],
        vibe: [],
        points: [],
        coin: [],
        rules: '',
      },
    }
  },
  props: {
    cardOptions: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Array,
      default: () => [],
    },
    studentId: {
      type: (Object, Number),
      default: () => null,
    },
  },
  computed: {
    noStats() {
      let status = true
      if (this.data.length) status = false
      return status
    },
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
    isADashBoardView() {
      return this.cardOptions.title === 'statistics'
    },
  },
  mounted() {
    this.getMarketPlaces()
  },
  methods: {
    setActiveTable(data) {
      const tableViewList = ['Courses', 'Problems', 'Lessons', 'Standards']
      if (tableViewList.includes(data?.subtitle)) this.$emit('tableView', data?.subtitle)
    },
    setCurrentItem(item) {
      this.currentItem = item
    },
    getMarketPlaces() {
      this.isProcessing = true
      // const schoolId = this.self.id
      useJwt.getMarketPlaces().then(response => {
        this.marketplaces = response.data.data
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>

<style scoped>

#shakemessage {
    background-color: #c72f43;
    border-radius: 5px;
    color: #fff;
    font-family: "Patua One", cursive;
    font-size: 1.5em;
    text-align: center;
    text-transform: uppercase;
    animation: shake 5s;
    padding:8px;
    cursor:pointer;
  animation-iteration-count: infinite;
}

.stats-hover:hover {
  cursor: pointer;
}
.feather {
  transition: all 0.2s ease-in;
}
.stats-hover:hover .feather {
  transform: scale(1.2);
}
.award-statistics {
  height: 80px;
}
.award-statistics .statistics-body {
  padding-top: 1rem !important;
}
#award-statistics-modal .card-header {
  padding: 18px;
}
@media (max-width:992px) and (min-width:768px) {
    .card-statistics-responsive {
      margin-bottom: 62px;
    }
    .marketplace-card {
      margin:0px auto
    }
  }
@media(width:768px) {
  .statistics-body .media{
    flex-wrap: unset !important;
  }
}
@media screen and (max-width: 630px) {
  .b-avatar {
    width: 30px !important;
    height: 30px !important;
  }
  .media-body h4 {
    font-size: 0.9rem !important;
  }
  .award-statistics .card-body {
    padding: 8px 12px !important;
  }
  .award-statistics .media {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
  .award-statistics .media .media-aside {
    padding: 0 !important;
    align-self: center !important;
  }
  .award-statistics .media-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .award-statistics .media-body .card-text {
    font-size: 0.7rem !important;
    margin-left: 4px;
  }
}

@media screen and (max-width: 500px) {
    .b-avatar {
    width: 20px !important;
    height: 20px !important;
  }
  .award-statistics .media-body {
    display: flex;
    flex-flow: column;
    padding-top: 6px;
  }
  .award-statistics .media-body p {
    line-height: 1rem;
  }
}

@media screen and (max-width: 280px) {
  .award-statistics .media-body p {
    display: none;
  }
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes float {
0% {box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);transform: translatey(0px);}
50% {
  box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
  transform: translatey(-20px);
}
100% {
  box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
  transform: translatey(0px);
}
}
</style>
