<template>
  <b-modal
    :visible="show"
    :title="$t('choose-user-type')"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-row class="ecommerce-application match-height pt-2">
      <b-col
        v-for="l of levels"
        :key="l.value"
        role="button"
        @click="form.userLevel = l.value"
      >
        <b-card
          class="ecommerce-card"
          :class="{ selected: form.userLevel == l.value }"
          no-body
        >
          <img
            class="card-img-top"
            :src="l.image"
          >
          <b-card-body class="text-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="l.color"
              class="btn-icon rounded-circle"
              size="lg"
            >
              <feather-icon
                :icon="l.icon"
                size="30"
              />
            </b-button>
            <div class="truncate pt-1">
              <h2 class="mb-25 font-weight-bolder">
                {{ l.name }}
              </h2>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <template #modal-footer="{}">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
          :set="
            (userType = levels.find((level) => level.value == form.userLevel))
          "
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="userType ? userType.color : 'primary'"
            :disabled="form.isProcessing || !isValid"
            @click="login"
          >
            <b-spinner
              v-show="form.isProcessing"
              small
            />
            {{ $t('actions.login') }}
            <span v-if="userType">{{ $t('actions.as') }} {{ userType.name }}</span>
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BModal,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, reactive, computed } from 'vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BModal,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const levels = ref([
      {
        name: 'Teacher',
        value: 'teacher',
        icon: 'ShieldIcon',
        color: 'primary',
        image: '/edu/images/1elem.jpg',
      },
      {
        name: 'Student',
        value: 'student',
        icon: 'ShieldIcon',
        color: 'danger',
        image: '/edu/images/1hs.jpg',
      },
    ])
    const form = reactive({
      userLevel: null,
      isProcessing: false,
    })
    const isValid = computed(() => !!form.userLevel)

    function login() {
      form.isProcessing = true
      useJwt
        .guestLogin({
          usertype: form.userLevel,
        })
        .then(response => {
          emit('afterLogin', response)
        })
        .finally(() => {
          form.isProcessing = false
        })
    }

    return {
      levels,
      form,
      isValid,
      login,
    }
  },
}
</script>
<style>
.ecommerce-application .ecommerce-card:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
  box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
}
.selected {
  border: 3px solid black;
}
</style>
