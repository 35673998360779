<template>
  <b-modal :visible="true"
           size="lg"
           :title="$t('navigation-menu.reservation-schedule')"
           :ok-title="$t('ok')"
           :cancel-title="$t('labels.cancel')"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <b-button variant="outline-primary"
              @click="showScheduleModal = true"
    >
      {{ $t('reservation-moduel.add-schedule') }} <feather-icon icon="PlusIcon" />
    </b-button>
    <div class="mt-2">
      <table class="table table-hover table-striped">
        <thead>
          <th>
            {{ $t('reservation-moduel.schedule-name') }}
          </th>
          <th>
            {{ $t('reservation-moduel.limit') }}
          </th>
          <th>
            {{ $t('reservation-moduel.teacher') }}
          </th>
          <th>
            {{ $t('reservation-moduel.action') }}
          </th>
        </thead>
        <tbody>
          <tr v-for="item,si of reservation.schedules"
              :key="si"
          >
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.students?.length || 0 }}/{{ item.limit }}
            </td>
            <td>
              {{ teacherList.find(i => i.id == item.teacher_id)?.full_name }}
            </td>
            <td>
              <b-button variant="flat-info"
                        class="btn-icon"
                        @click="showDetails(item.id)"
              >
                <feather-icon icon="CalendarIcon" />
              </b-button>
              <b-button variant="flat-success"
                        class="btn-icon"
                        @click="editScheduleTime(si)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button variant="flat-danger"
                        class="btn-icon"
                        @click="deleteScheduleTime(si)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- schedule times -->
    <b-modal v-if="showScheduleModal"
             :visible="true"
             :title="$t('reservation-moduel.manage-schedule')"
             @close="showScheduleModal = false; scheduleIndexForEdit = null"
             @hide="showScheduleModal = false; scheduleIndexForEdit = null"
    >
      <validation-observer ref="formProvider">
        <b-row>
          <b-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('reservation-moduel.schedule-name')"
              rules="required"
            >
              <b-form-group :label="$t('reservation-moduel.schedule-name')">
                <b-form-input v-model="newSchedule.name"
                              :placeholder="$t('reservation-moduel.schedule-name')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('reservation-moduel.week-days-validation')"
              rules="required"
            >
              <b-form-group :label="$t('reservation-moduel.week-days')">
                <b-form-input v-model.number="newSchedule.working_days"
                              :placeholder="$t('reservation-moduel.week-days')"
                              type="number"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('reservation-moduel.time-interval')"
              rules="required"
            >
              <b-form-group :label="$t('reservation-moduel.time-interval')">
                <b-form-input v-model.lazy="newSchedule.time_interval"
                              :placeholder="$t('reservation-moduel.time-interval')"
                              type="number"
                              @change="updateScheduleInterval"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('reservation-moduel.schedule-limit')"
              rules="required"
            >
              <b-form-group :label="$t('reservation-moduel.schedule-limit')">
                <b-form-input v-model.number="newSchedule.limit"
                              :placeholder="$t('reservation-moduel.schedule-limit')"
                              type="number"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('reservation-moduel.schedule-cost')"
              rules="required"
            >
              <b-form-group :label="$t('reservation-moduel.schedule-cost')">
                <b-form-input v-model.number="newSchedule.cost"
                              :placeholder="$t('reservation-moduel.schedule-cost')"
                              type="number"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              :name="('reservation-moduel.teacher')"
              rules="required"
            >
              <b-form-group :label="$t('reservation-moduel.teacher')">
                <v-select v-model="newSchedule.teacher_id"
                          :placeholder="$t('reservation-moduel.select-teacher')"
                          :clearable="false"
                          label="full_name"
                          :reduce="item => item.id"
                          :options="teacherList"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>{{ $t('reservation-moduel.day') }}</th>
                  <th>{{ $t('reservation-moduel.time') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="schedule,si of newSchedule.schedule_times"
                    :key="si"
                >
                  <td>
                    <select v-model="schedule.day"
                            class="form-control w-100"
                    >
                      <option v-for="st,index of weekDays"
                              :key="index"
                      >
                        {{ st.text }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <select class="form-control"
                            @change="value => onChangeTime(schedule, value)"
                    >
                      <option :value="null">
                        {{ $t('reservation-moduel.select-schedule') }}
                      </option>
                      <option v-for="st,index of scheduleTimes"
                              :key="index"
                              :selected="`${st.start_time}-${st.end_time}` === `${schedule.start_time}-${schedule.end_time}`"
                              :value="`${st.start_time}-${st.end_time}`"
                      >
                        {{ FORMAT_DATE('2023-09-24 ' + st.start_time, 'h:mm A') }}-{{ FORMAT_DATE('2023-09-24 ' + st.end_time, 'h:mm A') }}
                      </option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button variant="primary"
                    @click="updateSchedules"
          >
            {{ $t('actions.save') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal :visible="scheduleDetailsToShow"
             :title="$t('reservation-moduel.student-lists')"
             @close="scheduleDetailsToShow = null"
    >
      <b-overlay :show="isShowingSchedule">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>{{ $t('sno') }}</th>
              <th>{{ $t('reservation-moduel.student') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item,index of scheduleDetails?.students_info || []"
                :key="index"
            >
              <td>
                {{ index + 1 }}
              </td>
              <td>
                {{ item.firstname }}  {{ item.lastname }}
              </td>
            </tr>
          </tbody>
        </table>
      </b-overlay>
      <template #modal-footer>
        <div class="w-100">
          <b-button class="float-left"
                    variant="outline-secondary"
                    @click="scheduleDetailsToShow = false"
          >
            {{ $t('labels.cancel') }}
          </b-button>
          <b-button
            class="float-right"
            variant="outline-primary"
            @click="showStartingClass = true"
          >
            {{ $t('reservation-moduel.start-class') }}
          </b-button>
        </div>
      </template>
      <confirm-modal :show="showStartingClass"
                     :on-delete="handleStartClass"
                     :is-processing-delete="isStartingClass"
                     :title="$t('reservation-moduel.start-class-title')"
                     varient="success"
                     :sub-title="$t('reservation-moduel.start-class-sub-title')"
      />
    </b-modal>
  </b-modal>
</template>
<script>
import {
  BModal, BButton, BFormGroup, BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import { createTimeInterval } from '@/@core/utils/utils'
import vSelect from 'vue-select'
import useApollo from '@/@core/graphql/useApollo'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import ConfirmModal from '@/views/common/components/DeleteModal.vue'

const initialForm = () => ({
  name: '',
  teacher_id: null,
  limit: 1,
  cost: 0,
  working_days: 1,
  time_interval: 30,
  schedule_times: [],
})
export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ConfirmModal,
  },
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      showScheduleModal: false,
      teacherList: [],
      weekDays: [
        {
          text: 'Choose Day',
          value: null,
        },
        {
          text: 'Sunday',
          value: 'Sunday',
        },
        {
          text: 'Monday',
          value: 'Monday',
        },
        {
          text: 'Tuesday',
          value: 'Tuesday',
        },
        {
          text: 'Wednesday',
          value: 'Wednesday',
        },
        {
          text: 'Thursday',
          value: 'Thursday',
        },
        {
          text: 'Friday',
          value: 'Friday',
        },
        {
          text: 'Saturday',
          value: 'Saturday',
        },
      ],
      scheduleIndexForEdit: null,
      scheduleDetailsToShow: null,
      scheduleDetails: null,
      isShowingSchedule: false,
      showStartingClass: false,
      isStartingClass: false,
      scheduleTimes: [],
      newSchedule: {
        ...initialForm(),
        teacher_id: this.getDefaultTeacher(),
      },
    }
  },
  watch: {
    'newSchedule.working_days': {
      handler() {
        this.reformScheduleTimes()
      },
    },
  },
  mounted() {
    this.getTeachers()
    this.updateScheduleInterval()
  },
  methods: {
    reformScheduleTimes() {
      this.newSchedule.schedule_times = Array.from(new Array(this.newSchedule.working_days)).map((item, index) => ({
        day: this.newSchedule.schedule_times[index]?.day || null,
        start_time: this.newSchedule.schedule_times[index]?.start_time || null,
        end_time: this.newSchedule.schedule_times[index]?.end_time || null,
      }))
    },
    updateScheduleInterval() {
      this.scheduleTimes = createTimeInterval(this.newSchedule.time_interval)
    },
    handleStartClass() {
      if (this.scheduleDetails?.class_room) {
        this.$swal('Info', `Schedule has been updated to room-${this.scheduleDetails.class_room?.name}`, 'success')
        this.scheduleDetailsToShow = null
        this.showStartingClass = false
        return
      }
      this.isStartingClass = true
      useJwt.startScheduleClass(this.scheduleDetailsToShow).then(response => {
        this.showSuccessMessage(response)
        this.scheduleDetailsToShow = null
        this.showStartingClass = false
      }).catch(error => {
        this.showErrorMEssage(error)
      }).finally(() => {
        this.isStartingClass = false
      })
    },
    showDetails(scheduleId) {
      this.scheduleDetailsToShow = scheduleId
      this.isShowingSchedule = true
      useJwt.showScheduleDetail(scheduleId).then(response => {
        this.scheduleDetails = response.data.data.schedule
      }).finally(() => {
        this.isShowingSchedule = false
      })
    },
    deleteScheduleTime(index) {
      this.$emit('removeSchedule', index)
    },
    editScheduleTime(index) {
      this.newSchedule = { ...this.reservation.schedules[index] }
      this.showScheduleModal = true
      this.scheduleIndexForEdit = index
    },
    getTeachers() {
      useApollo.getUserDropDown({
        type: 'teacher',
        limit: 100,
      }).then(response => {
        const { data } = response.data.users
        const teachers = data.map(d => ({
          full_name: d.fullname,
          id: Number(d.id),
        }))
        this.teacherList = [...teachers]
      })
    },
    updateSchedules() {
      this.$refs.formProvider.validate().then(success => {
        if (success) {
          // eslint-disable-next-line no-restricted-syntax, guard-for-in
          for (const item of this.newSchedule.schedule_times) {
            if (!item.start_time || !item.end_time) {
              alert('Please Select All times')
              return
            }
          }
          this.$emit('updateSchedule', this.newSchedule, this.scheduleIndexForEdit)
          this.scheduleIndexForEdit = null
          this.showScheduleModal = false
          this.scheduleIndexForEdit = null
          setTimeout(() => {
            this.newSchedule = { ...initialForm(this.reservation) }
          }, 100)
        }
      })
    },
    onChangeTime(schedule, ev) {
      if (!ev.target.value) {
        // eslint-disable-next-line prefer-destructuring, no-param-reassign
        schedule.start_time = ''
        // eslint-disable-next-line prefer-destructuring, no-param-reassign
        schedule.end_time = ''
        return
      }
      const times = ev.target.value.split('-')
      // eslint-disable-next-line prefer-destructuring, no-param-reassign
      schedule.start_time = times[0].trim()
      // eslint-disable-next-line prefer-destructuring, no-param-reassign
      schedule.end_time = times[1].trim()
      this.$forceUpdate()
    },
    getDefaultTeacher() {
      switch (this.AUTH_USER().id) {
        case '1766':
        case 1766: {
          return 1870
        }
        default:
          return null
      }
    },
  },
}
</script>
