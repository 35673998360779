<template>
  <b-card v-if="data!=null">
    <b-modal
      id="modal-update-profile"
      ref="modal-update-profile"
      ok-only
      size="lg"
      ok-variant="primary"
      :ok-title="$t('confirm')"
      modal-class="modal-primary"
      centered
      :title="$t('actions.update-profile')"
    >
      <b-col md="12">
        <!-- media -->
        <validation-observer
          ref="updateProfileMedia"
        >
          <b-form-group
            :label="$t('logo')"
            label-for="logo"
          >
            <!-- media -->
            <b-media no-body>
              <b-media-aside>
                <avatar
                  size="80px"
                  :bordered="false"
                  :image="data.avatar"
                  :rounded="false"
                />
                <!--/ avatar -->
              </b-media-aside>

              <b-media-body class="ml-50">
                <!-- Plain mode -->
                <div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                    rules="required"
                    vid="logo"
                  >
                    <!-- Styled -->
                    <b-form-file
                      ref="refInputEl1"
                      v-model="logoFile"
                      :browse-text="$t('browseButton')"
                      :placeholder="$t('issue-report-module.choose-a-file')"
                      class="mb-1"
                      accept="image/jpeg, image/png, image/gif"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>

                <div v-if="logoFile!=null">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="uploadLogo()"
                  >
                    {{ $t('profile-module.upload') }}
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="resetLogo()"
                  >
                    {{ $t('profile-module.reset') }}
                  </b-button>
                </div>
                <div v-else>
                  <b-card-text> {{ $t('profile-module.allowed') }} </b-card-text>
                </div>
              </b-media-body>
            </b-media>
          </b-form-group>
        </validation-observer>
        <!--/ media -->
      </b-col>
      <b-col md="12">
        <!-- form -->
        <validation-observer
          ref="updateProfileForm"
        >
          <b-form
            class="update-profile-form"
            @submit.prevent="updateProfile"
          >
            <button
              type="submit"
              class="d-none"
            />
            <b-row>
              <b-col
                md="12"
              >
                <b-form-group
                  :label="$t('name')"
                  label-for="name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                    rules="required|min:3"
                    vid="name"
                  >
                    <b-form-input
                      id="item_name"
                      v-model="data.name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-group
                  :label="$t('profile-module.description')"
                  label-for="description"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Description"
                    rules="required"
                    vid="description"
                  >
                    <b-form-textarea
                      id="item_name"
                      v-model="data.description"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-group
                  :label="$t('profile-module.address')"
                  label-for="address"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Address"
                    rules="required"
                    vid="address"
                  >
                    <b-form-input
                      id="item_name"
                      v-model="data.address"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-group
                  :label="$t('profile-module.phone')"
                  label-for="phone"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone"
                    rules="required|numeric|max:14"
                    vid="phone"
                  >
                    <b-form-input
                      id="item_name"
                      v-model="data.phone"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <template #modal-footer="{ }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          > <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="isProcessing"
            @click="updateProfile()"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('profile-module.update-profile') }}
          </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- about -->
    <b-row
      class="d-flex align-items-center d-flex justify-content-between"
    >

      <b-col>
        <h5 class="text-capitalize mb-75">
          {{ $t('profile-module.details') }}
        </h5>
      </b-col>

      <b-dropdown
        v-if="isViewingOwnProfile"
        variant="link"
        no-caret
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>
        <b-dropdown-item v-b-modal.modal-update-profile>
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">{{ $t("edit") }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-row>

    <b-row v-if="profile">
      <b-col
        md="4"
        sm="12"
        class="pr-0"
      >
        <b-card-text v-if="profile!==null">
          <avatar
            :image="profile.avatar"
            rounded="circle"
            :bordered="true"
            size="80px"
          />
        </b-card-text>
      </b-col>
      <b-col
        md="8"
        sm="12"
        class="mt-1"
      >
        <h5>
          {{ $t('profile-module.name') }}
        </h5>
        <b-card-text>
          {{ profile.fullname }}
        </b-card-text>
      </b-col>
      <b-col
        md="12"
        class="mt-2"
      >
        <h5>
          {{ $t('profile-module.description') }}
        </h5>
        <b-card-text>
          {{ profile.description }}
        </b-card-text>
        <h5>
          {{ $t('profile-module.address') }}
        </h5>
        <b-card-text>
          {{ profile.address }}
        </b-card-text>
        <h5>
          {{ $t('profile-module.phone') }}
        </h5>
        <b-card-text>
          {{ profile.phone }}
        </b-card-text>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BButton, BForm, BCol, BRow, BFormFile, BFormTextarea, BDropdown, BDropdownItem, BFormGroup, BSpinner, BFormInput, BMedia, BMediaAside, BMediaBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import useJwt from '@/auth/jwt/useJwt'
import { ref } from 'vue'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import Avatar from '@/views/common/components/Avatar.vue'

export default {
  components: {
    BCard,
    BFormGroup,
    BSpinner,
    Avatar,
    BFormFile,
    ValidationObserver,
    ValidationProvider,
    BMedia,
    BMediaAside,
    BMediaBody,
    BDropdown,
    BDropdownItem,
    BCol,
    BFormTextarea,
    BFormInput,
    BRow,
    BCardText,
    BButton,
    BForm,
    // BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    isViewingOwnProfile: {
      type: Boolean,
      required: true,
    },
    profile: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)
    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  data() {
    return {
      isProcessing: false,
      // profile: null,
      data: this.profile,
      logoFile: null,
    }
  },

  watch: {
    logoFile() {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.showPreview = true
          // this.previousImage = this.data.avatar
          this.data.avatar = reader.result
        },
        false,
      )
      if (this.logoFile) {
        if (/\.(jpe?g|png|gif)$/i.test(this.logoFile.name)) {
          reader.readAsDataURL(this.logoFile)
        }
      }
    },
  },
  mounted() {
    if (this.isViewingOwnProfile) this.getProfile()
  },
  methods: {
    getProfile() {
      useJwt.getProfile().then(response => {
        this.profile = response.data.data
        this.data = JSON.parse(JSON.stringify(response.data.data))
      })
    },

    resetLogo() {
      this.logoFile = null
      this.data.avatar = this.profile.avatar
      this.$refs.updateProfileMedia.reset()
    },

    uploadLogo() {
      this.isProcessing = true
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
      const data = new FormData()
      data.append('logo', this.$refs.refInputEl1.files[0])

      useJwt.axiosIns.post('/api/v1/profile/logo/update', data, config)
        .then(response => {
          this.profile = response.data.data
          this.data = JSON.parse(JSON.stringify(this.profile))
          this.$refs['modal-update-profile'].hide()
          this.showSuccessMessage(response)
        })
        .catch(error => {
          if (error.response?.data?.errors) this.$refs.updateProfileMedia.setErrors(error.response.data.errors)
          else this.showErrorMessage(error)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },

    updateProfile() {
      this.$refs.updateProfileForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt.updateProfile(this.profile.id, {
            description: this.data.description,
            name: this.data.name,
            address: this.data.address,
            phone: this.data.phone,
          })
            .then(response => {
              this.profile = response.data.data
              this.$refs['modal-update-profile'].hide()
              this.showSuccessMessage(response)
            }).catch(error => {
              if (error.response?.data?.errors) this.$refs.updateProfileForm.setErrors(error.response.data.errors)
              else this.showErrorMessage(error)
            }).finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
  },
}
</script>

<style>
@media (max-width: 576px) {
  #user-profile .dropdown-menu{
    transform: translate3d(-90px, 40px, 0px) !important;
  }
}

</style>
