<template>
  <div>
    <b-button v-if="type != 'mgp'"
              variant="outline-primary"
              class="float-right"
              @click="showProblemForm = true"
    >
      <feather-icon icon="PlusIcon" /> {{ $t('actions.create-problem') }}
    </b-button>
    <TeacherProblemCreate :show="showProblemForm"
                          :lesson-id="lessonId"
                          :lesson-domain="lessonDomain"
                          @close="showProblemForm = false"
                          @reFetch="getTeacherCreatedProblem"
    />
    <div class="mt-1">
      <b-button
        class="mr-1"
        variant="outline-primary"
        @click="selectAll"
      >
        Select All
      </b-button>
      <b-button
        variant="outline-primary"
        @click="unSelectAll"
      >
        UnSelect  All
      </b-button>
    </div>
    <div class="mt-2">
      <b-row>
        <b-col>
          <b-overlay :show="isFetching">
            <b-table striped
                     hover
                     responsive
                     :items="teacherCreatedProblem"
                     :fields="tableFields"
            >
              <template #cell(check)="data">
                <span>
                  <input
                    :id="data.item.id"
                    v-model="teacherSelectedProblem"
                    type="checkbox"
                    :disabled="!data.item.is_public"
                    class="form-check-input"
                    :value="{code: data.item.id, label: `${data.item.name} - ${data.item.problem_type?.name} (${data.item.skill_type})`}"
                    style="width:20px; height:20px; margin-top:-10px"
                  >
                </span>
              </template>
              <template #cell(id)="data">
                <div class="d-flex justify-content-left align-items-center">
                  <span style="margin-left:10px">
                    {{ data.item.id }}
                  </span>
                </div>
              </template>
              <template #cell(problem_type)="data">
                <span>
                  {{ data.item.problem_type?.name }}
                </span>
              </template>
              <template #cell(action)="data">
                <div class="d-flex justify-content-left align-items-center">
                  <b-form-checkbox v-model="data.item.is_public"
                                   :value="1"
                                   switch
                                   @change="changeVisibility(data.item)"
                  >
                    <span class="switch-icon-right">
                      <feather-icon icon="EyeOffIcon" />
                    </span>
                    <span class="switch-icon-left">
                      <feather-icon icon="EyeIcon" />
                    </span>
                  </b-form-checkbox>
                  <b-button class="btn-icon ml-1"
                            variant="outline-success"
                            @click="problemIdToShowPreview = data.item.id"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <b-button class="btn-icon ml-1"
                            variant="outline-primary"
                            @click="problemIdToEdit = data.item.id"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-button>
                  <b-button class="btn-icon ml-1"
                            variant="outline-danger"
                            @click="problemIdToDelete = data.item.id"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-overlay>
        </b-col>
        <b-col v-if="selectedProblems.length"
               md="3"
        >
          <h6 class="text-success">
            Selected Review Lessons : {{ selectedProblems.length }}
          </h6>

          <ul class="list-group">
            <li v-for="code in selectedProblems"
                :key="code.code"
                class="selected-follow-up list-group-item"
            >
              {{ code.label }}

              <feather-icon class="close text-danger"
                            icon="XIcon"
                            role="button"
                            @click="teacherSelectedProblem = teacherSelectedProblem.filter(i => i.code != code.code)"
              />

            </li>
          </ul>
        </b-col>
      </b-row>

      <TeacherProblemEdit v-if="problemIdToEdit"
                          :lesson-domain="lessonDomain"
                          :problem-id="problemIdToEdit"
                          @reFetch="getTeacherCreatedProblem"
                          @close="problemIdToEdit = null"
      />
      <confirm-delete
        :show="!!problemIdToDelete"
        :is-processing-delete="isDeleting"
        sub-title="Are you sure you want to delete?"
        :on-delete="deleteProblem"
        @close="problemIdToDelete = null"
      />
      <lesson-preview
        :show="problemIdToShowPreview"
        :problem-id="problemIdToShowPreview"
        :lesson-id="lessonId"
        @close="problemIdToShowPreview = null"
      />
    </div>
  </div>

</template>
<script>
import {
  BButton, BTable, BFormCheckbox, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ConfirmDelete from '@/views/common/components/DeleteModal.vue'
import LessonPreview from '@/views/super/problem/components/LessonPreview.vue'
import TeacherProblemCreate from './problem-form.vue'
import TeacherProblemEdit from './edit-problem-form.vue'

export default {
  components: {
    BButton,
    BTable,
    BRow,
    BCol,
    TeacherProblemCreate,
    TeacherProblemEdit,
    BFormCheckbox,
    ConfirmDelete,
    LessonPreview,
    BOverlay,
  },
  props: {
    lessonId: {
      type: Number,
      required: true,
    },
    selectedProblems: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'tgp',
    },
  },
  data() {
    return {
      problemIdToShowPreview: null,
      problemIdToEdit: null,
      problemIdToDelete: null,
      isDeleting: false,
      showProblemForm: false,
      isFetching: false,
      lessonDomain: [],
      teacherCreatedProblem: [],
      tableFields: [
        { key: 'check', label: 'Select' },
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Name' },
        { key: 'problem_type', label: 'Problem Type' },
        { key: 'skill_type', label: 'Skill Type' },
        { key: 'action', label: 'Action' },
      ],
    }
  },
  computed: {
    teacherSelectedProblem: {
      get() {
        return this.selectedProblems
      },

      set(value) {
        this.$emit('updateProblem', value)
      },
    },
  },
  mounted() {
    this.getTeacherCreatedProblem()
  },
  methods: {
    selectAll() {
      this.selectedProblems = this.teacherCreatedProblem.map(item => ({ code: item.id, label: `${item.name} - ${item.problem_type?.name} (${item.skill_type})` }))
      // this.$emit('updateProblem', this.selectedProblems)
    },
    unSelectAll() {
      this.selectedProblems = []
    },
    deleteProblem() {
      this.isDeleting = true
      useJwt.deleteProblem(this.problemIdToDelete).then(() => {
        this.problemIdToDelete = null
        this.getTeacherCreatedProblem()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isDeleting = false
      })
    },
    changeVisibility(item) {
      useJwt.changeVisibility({
        is_public: item.is_public,
        id: item.id,
        model: 'problem',
      }).then(response => {
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    getTeacherCreatedProblem() {
      this.isFetching = true
      useJwt.getTeacherCreateProblem(this.lessonId, {
        params: {
          showAll: true,
          type: this.type,
        },
      }).then(response => {
        this.lessonDomain = response.data.data.lesson_domain
        this.teacherCreatedProblem = response.data.data.problems
      }).finally(() => {
        this.isFetching = false
      })
    },
  },

}
</script>
