smallsmallsmallsmall<template>
  <div>
    <!-- create class -->
    <b-card no-body>
      <b-row>
        <b-col>

          <b-card
            no-body
            :class="{'open': isCollapseOpen}"
            class="m-0"
          >
            <b-card-header
              style="cursor:pointer"
              :class="{'collapsed': !isCollapseOpen}"
              :aria-expanded="isCollapseOpen ? 'true' : 'false'"
              role="tab"
              data-toggle="collapse"
              @click="updateisCollapseOpen(!isCollapseOpen)"
            >
              <span>
                {{ $t('actions.create-article') }}
              </span>
            </b-card-header>

            <b-collapse
              :id="collapseItemID"
              v-model="isCollapseOpen"
              role="tabpanel"
            >
              <b-card-body>
                <b-form
                  class="create-article-form"
                  @submit.prevent="createArticle"
                >

                  <b-col class="text-right pt-1">
                    <b-form-checkbox
                      v-model="newArticle.is_featured"
                      name="featured-article"
                      inline
                      switch
                      size="16"
                    >
                      <span style="font-size:14px; color:#6e6b7b;"> {{ $t('actions.featured') }} </span>
                    </b-form-checkbox>
                  </b-col>

                  <validation-observer
                    ref="CreateArticleForm"
                  >
                    <b-col md="12">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('labels.image')"
                        vid="image"
                        rules="image|size:800"
                        :custom-messages="{ image: $t('messages.invalid-image') }"
                      >
                        <b-form-group
                          :label="$t('labels.image')"
                          label-for="image"
                        >
                          <!-- media -->
                          <b-media no-body>
                            <b-media-aside>

                              <avatar
                                :image="newArticle.image"
                                size="50px"
                                :rounded="false"
                              />
                            <!--/ avatar -->
                            </b-media-aside>

                            <b-media-body class="ml-50">
                              <!-- Plain mode -->
                              <div>
                                <!-- Styled -->
                                <b-form-file
                                  ref="refInputEl1"
                                  v-model="articleImage"
                                  :state="errors.length > 0 ? false:null"
                                  :browse-text="$t('browseButton')"
                                  :placeholder="$t('issue-report-module.choose-a-file')"
                                  class="mb-1"
                                  accept="image/jpeg, image/png, image/gif"
                                />
                                <small class="text-danger">{{ errors[0] }}</small><br>
                                <small>{{ $t('labels.create-article.image-accept') }}</small>

                              </div>
                              <div v-if="!articleImage">
                                <b-card-text> {{ $t('account-setting-general.allowed') }} </b-card-text>
                              </div>
                            </b-media-body>
                          </b-media>
                        <!--/ media -->
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="12">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('labels.title')"
                        rules="required"
                        vid="title"
                      >
                        <b-form-group

                          :label="$t('labels.title')"
                        >
                          <b-form-input
                            v-model="newArticle.title"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="12">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('labels.video-url')"
                        rules="youtubeVideoUrl"
                        vid="video"
                      >
                        <b-form-group
                          :label="$t('labels.video-url')"
                        >
                          <b-form-input
                            v-model="newArticle.video"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>

                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="12">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('labels.description')"
                        rules="required|min:20"
                        vid="description"
                      >
                        <b-form-group
                          :label="$t('labels.description')"
                        >
                          <b-form-textarea
                            v-model="newArticle.description"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>

                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      md="12"
                      class="flex-row-reverse d-flex mb-1"
                    >
                      <b-button
                        class="align-self-end"
                        variant="primary"
                        :disabled="isProcessing "
                        type="submit"
                        @click="createArticle()"
                      >
                        <b-spinner
                          v-show="isProcessing"
                          small
                        />
                        {{ $t('actions.create-article') }}
                      </b-button>
                      <b-col />
                    </b-col>
                  </validation-observer>
                </b-form>
              </b-card-body>
            </b-collapse>
          </b-card>
        </b-col>

      </b-row>
    </b-card>
  </div>
</template>
<script>
// import useJwt from '@/auth/jwt/useJwt'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate/dist/vee-validate.full'
import {
  BRow, BCol, BButton, BSpinner, BCollapse, BCardHeader, BCardBody, BCard, BForm, BCardText, BFormFile, BFormTextarea, BMedia, BMediaAside, BMediaBody, BFormGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import { ref } from 'vue'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import useJwt from '@/auth/jwt/useJwt'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import Avatar from '@/views/common/components/Avatar.vue'
import { validatorYoutubeUrlValidator } from '@/@core/utils/validations/validators'
import i18n from '@/libs/i18n'

extend('youtubeVideoUrl', value => {
  const valid = validatorYoutubeUrlValidator(value)
  return valid ? true : i18n.tc('messages.invalid-video-url')
})

export default {
  components: {
    BButton,
    BCollapse,
    ValidationObserver,
    ValidationProvider,
    BFormTextarea,
    BMedia,
    BCardHeader,
    BCardBody,
    BMediaAside,
    BMediaBody,
    BCard,
    BFormInput,
    BForm,
    BFormGroup,
    BCardText,
    BFormFile,
    BSpinner,
    BRow,
    BCol,
    BFormCheckbox,
    Avatar,
  },
  directives: {
    Ripple,
  },
  props: {
    article: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      articleImage: null,
      newArticle: {},
      isProcessing: false,
      isCollapseOpen: false,
      collapseItemID: null,
    }
  },
  computed: {
    ...mapGetters({
      isALeader: 'network/isALeader',
      isACoLeader: 'network/isACoLeader',
    }),
    networkId() {
      return this.$store.state.network.networkId
    },
    category() {
      return this.$store.state.network.activeTab
    },
  },

  watch: {
    articleImage() {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.showPreview = true
          this.newArticle.image = reader.result
        },
        false,
      )
      if (this.articleImage) {
        if (/\.(jpe?g|png|gif)$/i.test(this.articleImage.name)) {
          reader.readAsDataURL(this.articleImage)
        }
      }
    },
  },
  created() {
    this.collapseItemID = uuidv4()
  },

  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)
    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  mounted() {
    this.resetNewArticle()
  },
  methods: {
    updateisCollapseOpen(val = true) {
      this.isCollapseOpen = val
    },
    resetLogo() {
      this.articleImage = null
      this.newArticle.image = this.article.image
    },
    createArticle() {
      this.$refs.CreateArticleForm.validate().then(success => {
        if (success) {
          let youtubeUrl = ''
          youtubeUrl = validatorYoutubeUrlValidator(this.newArticle.video)
          if (!youtubeUrl) youtubeUrl = null

          const config = {
            headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.accessToken}`,
            },
          }
          const data = new FormData()
          if (this.$refs.refInputEl1.files[0])data.append('image', this.$refs.refInputEl1.files[0])
          data.append('title', this.newArticle.title)
          data.append('description', this.newArticle.description)
          data.append('is_featured', this.newArticle.is_featured === true ? 1 : 0)
          if (youtubeUrl) data.append('video', youtubeUrl)
          data.append('article_category', this.category)

          this.isProcessing = true
          useJwt.axiosIns.post(`${jwtDefaultConfig.articleEndpoint}?nid=${this.networkId}`, data, config).then(response => {
            this.isCollapseOpen = false
            this.resetNewArticle()
            this.articleImage = null
            this.$store.dispatch('network/getUnVerifiedArticles')
            this.$store.dispatch('network/getVerifiedArticles')
            this.showSuccessMessage(response)
            this.$refs.CreateArticleForm.reset()
          })
            .catch(error => {
              if (error.response?.data?.errors) {
                this.$refs.CreateArticleForm.setErrors(error.response.data.errors)
              } else this.showErrorMessage(error)
            }).finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
    resetNewArticle() {
      this.newArticle = {
        title: '',
        description: '',
        image: null,
        video: '',
        is_featured: false,
      }
    },

  },
}
</script>
