var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-left"},[_c('div',{staticClass:"sidebar"},[_c('user-profile-sidebar',{attrs:{"shall-show-user-profile-sidebar":_vm.shallShowUserProfileSidebar,"profile-user-data":_vm.profileUserData},on:{"close-sidebar":function($event){return _vm.$emit('update:shall-show-user-profile-sidebar', false)}}}),_c('div',{staticClass:"sidebar-content",class:{ show: _vm.mqShallShowLeftSidebar }},[_c('span',{staticClass:"sidebar-close-icon"},[_c('feather-icon',{attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){return _vm.$emit('update:mq-shall-show-left-sidebar', false)}}})],1),_c('div',{staticClass:"chat-fixed-search"},[_c('div',{staticClass:"d-flex align-items-center w-100"},[_c('div',{staticClass:"sidebar-profile-toggle"},[_c('b-avatar',{staticClass:"cursor-pointer badge-minimal avatar-border-2",attrs:{"size":"42","src":_vm.profileUserMinimalData.avatar,"variant":"transparent","badge":"","badge-variant":"success"}})],1),_c('div',{staticClass:"position-relative",staticStyle:{"width":"82%"}},[_c('b-input-group',{staticClass:"input-group-merge ml-1 w-100 round position-relative"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"text-muted",attrs:{"icon":"SearchIcon"}})],1),_c('b-form-input',{style:({
                  position: 'relative',
                  zIndex: _vm.searchText ? 101 : 0,
                }),attrs:{"value":_vm.searchText,"placeholder":_vm.$t('messages.SearchPlaceholder')},on:{"input":function($event){return _vm.fetchUser($event)}}})],1),(_vm.searchText)?_c('ul',{staticClass:"search-dropdown"},[_vm._l((_vm.options),function(user,i){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],key:i,on:{"click":function($event){_vm.$emit('open-chat', {
                    userId: user.id,
                    type: 'single',
                    model: 'class',
                  });
                  _vm.searchText = null;}}},[_vm._v(" "+_vm._s(user.firstname + " " + user.lastname)+" ")])}),(_vm.loading)?_c('li',{staticStyle:{"text-align":"center"}},[_vm._v(" fetching user... ")]):_vm._e(),(_vm.searchText && !_vm.options[0] && !_vm.loading)?_c('li',{staticStyle:{"text-align":"center"}},[_vm._v(" no user found ")]):_vm._e()],2):_vm._e(),(_vm.searchText)?_c('div',{staticClass:"dropdown-back",on:{"click":function($event){_vm.options = [];
                _vm.searchText = '';}}}):_vm._e()],1)])]),_c('vue-perfect-scrollbar',{staticClass:"chat-user-list-wrapper list-group scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings}},[_c('h4',{staticClass:"chat-list-title"},[_vm._v(" "+_vm._s(_vm.$t("Chat"))+" ")]),_c('b-tabs',{staticClass:"mb-2 flex flex-nowrap",attrs:{"pills":""}},_vm._l((_vm.userTypesTabs),function(tab,index){return _c('b-tab',{key:index,scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"pr-1",staticStyle:{"position":"relative"}},[_vm._v(" "+_vm._s(tab.text)+" "),(_vm.getUnreadMsgNumbers(tab.value) > 0)?_c('small',{staticStyle:{"position":"absolute","right":"-8px","top":"-2px"}},[_c('b-badge',{attrs:{"pill":"","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.getUnreadMsgNumbers(tab.value))+" ")])],1):_vm._e()])]},proxy:true}],null,true)},[(tab.value === 'class')?_c('div',[_c('h6',{directives:[{name:"show",rawName:"v-show",value:(_vm.emptyClasses),expression:"emptyClasses"}],staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.emptyClassesMessage)+" ")]),_vm._l((_vm.classes),function(cls,i){return _c('div',{key:cls + i},[_c('div',{staticClass:"d-flex col-md-12 pl-2 mt-1 py-1",class:{
                    active:
                      _vm.activeChatContactId === cls.id &&
                      _vm.activeChatType === 'group' &&
                      _vm.activeChatModel === 'class',
                  },staticStyle:{"cursor":"pointer"},attrs:{"data-toggle":"collapse","href":'#classContact' + cls.id}},[_c('b-avatar',{staticClass:"badge-minimal",attrs:{"size":"42","src":cls.avatar,"variant":"transparent"}}),_c('h4',{staticClass:"mb-0 mt-1 ml-1"},[_vm._v(" "+_vm._s(cls.name)+" ")]),_c('feather-icon',{staticClass:"mt-1 ml-auto mr-2",staticStyle:{"width":"20px","height":"20px"},attrs:{"icon":"ArrowRightIcon"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('open-chat', {
                        userId: cls.id,
                        type: 'group',
                        model: 'class',
                      })}}})],1),_c('div',{staticClass:"chat-contact collapse ml-2",attrs:{"id":'classContact' + cls.id}},_vm._l((cls.students),function(contact,j){return _c('chat-contact',{directives:[{name:"show",rawName:"v-show",value:(contact.id !== _vm.profileUserMinimalData.id),expression:"contact.id !== profileUserMinimalData.id"}],key:j + contact,class:{ active: _vm.activeChatContactId === contact.id },attrs:{"user":contact,"user-status":contact.last_seen !== null ? 'online' : 'offline',"tag":"li"},on:{"click":function($event){return _vm.$emit('open-chat', {
                        userId: contact.id,
                        type: 'single',
                        model: 'class',
                      })}}})}),1)])})],2):_c('ul',{staticClass:"chat-users-list chat-list media-list"},[_vm._l((_vm.filteredChatsContacts.filter(
                  item => {
                    if (tab.value === 'high-level') {
                      return ![9, 7, '7', '9'].includes(item.user_type_id)
                    }
                    return item.user_type_id == tab.value
                  }
                )),function(contact,i){return _c('chat-contact',{key:contact + i,class:{
                  active:
                    _vm.activeChatContactId === contact.userId &&
                    (contact.type === 'single' ||
                      _vm.activeChatModel === contact.group),
                },attrs:{"user":contact,"tag":"li","is-chat-contact":""},on:{"click":function($event){return _vm.$emit('open-chat', {
                    userId: contact.userId,
                    type: contact.type,
                    model: contact.group,
                  })}}})}),(tab?.value == 7)?_vm._l((_vm.teacherChats),function(contact,i){return _c('chat-contact',{key:i + '_',class:{
                    active:
                      _vm.activeChatContactId === contact.userId
                  },attrs:{"user":contact,"tag":"li","is-chat-contact":""},on:{"click":function($event){return _vm.$emit('open-chat', {
                      userId: contact.userId,
                      type: contact.type,
                      model: contact.group,
                    })}}})}):_vm._e()],2)])}),1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }