<template>
  <div class="rewards-detail">

    <b-overlay
      :show="nameLoading"
      class="w-25"
    >
      <h4 class="font-weight-bold">
        <!-- {{ !studentId || studentId === self.id ? 'Your' : `${studentName}'s` }}
        {{ $t('labels.rewards') }} -->
        <span v-if="!studentId || studentId === self.id">
          {{ $t('badge-reward.your-rewards') }}
        </span>
        <span v-else>
          {{ $t('badge-reward.student-rewards',{studentName}) }}
        </span>
      </h4>
    </b-overlay>

    <!-- Rewards tab -->
    <b-tabs class="mx-auto">
      <b-tab
        v-for="item, type, index in awardStats"
        :key="index"
        :active="type === currentItem"
        :title="$tc(`labels.${type}`)"
        @click="setCurrentItem(type)"
      >
        <!-- Rewards Table -->
        <b-overlay :show="isLoading">
          <!-- Search Bar -->
          <b-form-input
            v-model="searchTerms[type]"
            type="text"
            :placeholder="$t('badge-reward.search-type', {type})"
            @input="getAwardStats(type)"
          />
          <!-- Data Table -->
          <vue-good-table
            :columns="columns[type]"
            :rows="awardStats[type]"
            :pagination-options="{
              enabled: awardStats[type].length ? true : false,
              perPage: rppOptions[rppOptions.length - 1],
            }"
            :search-options="{
              enabled: false,
            }"
            :sort-options="{
              enabled: false,
            }"
            :responsive="true"
          >
            <!-- Custom columns -->
            <template
              slot="table-column"
              slot-scope="props"
            >
              <span v-if="props.column.label === 'Value'">
                {{ getTotal('Value', 'value', type) }}
              </span>

              <span v-else-if="props.column.label === 'Points'">
                {{ getTotal('Points', 'convertable_value', type) }}
              </span>

              <span v-else-if="props.column.label === 'Coins'">
                {{ getTotal('Coins', 'coins', type) }}
              </span>
            </template>

            <!-- Custom rows -->
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Serial Number -->
              <span v-if="props.column.field === 'id'">
                {{ awardStats[currentItem].from + props.row.originalIndex }}
              </span>

              <!-- Rewards -->
              <span
                v-else-if="props.column.field === 'reward' &&
                  (currentItem === 'point' || currentItem === 'badge')"
              >
                <p class="mb-0">{{ props.row.lesson }}</p>
                <span class="font-italic small">{{ props.row.reward }}</span>
              </span>

              <!-- Vibe/Badge Image -->
              <div
                v-else-if="props.column.field === 'code' &&
                  (currentItem === 'badge' || currentItem === 'vibe')"
              >
                <img
                  v-if="props.row.code"
                  :class="currentItem === 'badge' ? 'badge-icon' : 'vibe-icon'"
                  :src="currentItem === 'badge' ?
                    require(`@/assets/images/${badges[props.row.code]}`) :
                    getVibeIcon(props.row.code)"
                  :alt="props.row.reward"
                  class="mx-auto"
                >
              </div>

              <!-- Received Date -->
              <span v-else-if="props.column.field === 'date'">
                {{ formatDate(props.row.date) }}
              </span>
            </template>

            <!-- Pagination -->
            <template
              v-if="awardStats[type].length"
              slot="pagination-bottom"
            >
              <div class="table-footer border">
                <b-row class="justify-content-between align-items-center px-1">
                  <!-- Left Side of Pagination -->
                  <b-col class="d-flex align-items-center">
                    <b-form-select
                      v-model="rowsPerPage[type]"
                      :options="rppOptions"
                      :disabled="awardStats[type].total < 10"
                      @change="getAwardStats(type, awardStats[type].currentPage, rowsPerPage[type])"
                    />

                    <span class="mx-1">{{ $t('labels.rows-per-page') }}</span>
                  </b-col>

                  <!-- Right Side of Pagination -->
                  <b-col>
                    <div class="right-side float-right">
                      <!-- Previous Button -->
                      <b-button
                        class="pagination-button px-0"
                        variant="light"
                        :disabled="!awardStats[type].prevPage"
                        @click="getAwardStats(type, awardStats[type].currentPage - 1)"
                      >
                        <feather-icon
                          icon="ChevronLeftIcon"
                        />
                        {{ $tc('navigations.prev') }}
                      </b-button>
                      <!-- Page Info -->
                      <span class="pages p-1">
                        {{ awardStats[type].from }} - {{ awardStats[type].to }} of {{ awardStats[type].total }}
                      </span>
                      <!-- Next Button -->
                      <b-button
                        class="pagination-button px-0"
                        variant="light"
                        :disabled="!awardStats[type].nextPage"
                        @click="getAwardStats(type, awardStats[type].currentPage + 1)"
                      >
                        {{ $tc('navigations.next') }}
                        <feather-icon
                          icon="ChevronRightIcon"
                        />
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </template>

            <div
              slot="emptystate"
              class="text-center"
            >
              {{ $t('badge-reward.no-reward-received',{type}) }}
            </div>
          </vue-good-table>
        </b-overlay>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import {
  BTabs, BTab, BOverlay, BButton, BFormSelect, BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'
import i18n from '@/libs/i18n'
import {
  HIGHEST_POINTS_GAINER_BADGE, FIRST_LESSON_ACHEIVER_BADGE, EXTRAORDINARY_BADGE,
} from '@/const/badges'
import { mapGetters } from 'vuex'

export default {
  components: {
    VueGoodTable,
    BTabs,
    BTab,
    BOverlay,
    BButton,
    BFormSelect,
    BRow,
    BCol,
    BFormInput,
  },
  data() {
    return {
      self: getUserData(),
      studentId: null,
      studentName: '',
      currentItem: '',
      columns: {
        vibe: [
          { label: i18n.tc('sno'), field: 'id', width: '70px' },
          { label: i18n.tc('chat-page.vibes'), field: 'code', width: '100px' },
          { label: i18n.tc('badge-reward.reward'), field: 'reward' },
          { label: i18n.tc('labels.value'), field: 'value' },
          { label: i18n.tc('badge-reward.sender'), field: 'sender' },
          { label: i18n.tc('badge-reward.received-date'), field: 'date', width: '160px' },
        ],
        point: [
          { label: i18n.tc('sno'), field: 'id', width: '70px' },
          { label: i18n.tc('badge-reward.reward'), field: 'reward' },
          { label: i18n.tc('labels.value'), field: 'value' },
          { label: i18n.tc('badge-reward.received-date'), field: 'date', width: '160px' },
          { label: i18n.tc('labels.lesson'), field: 'lesson', hidden: true },
        ],
        badge: [
          { label: i18n.tc('sno'), field: 'id', width: '70px' },
          { label: i18n.tc('labels.badges'), field: 'code', width: '100px' },
          { label: i18n.tc('badge-reward.reward'), field: 'reward' },
          { label: i18n.tc('labels.value'), field: 'value' },
          { label: i18n.tc('badge-reward.received-date'), field: 'date', width: '160px' },
          { label: i18n.tc('labels.lesson'), field: 'lesson', hidden: true },
        ],
        coin: [
          { label: i18n.tc('sno'), field: 'id', width: '70px' },
          { label: i18n.tc('labels.points'), field: 'convertable_value' },
          { label: i18n.tc('labels.coins'), field: 'coins' },
          { label: i18n.tc('badge-reward.received-date'), field: 'date', width: '160px' },
        ],
      },
      awardStats: {
        badge: [],
        vibe: [],
        point: [],
        coin: [],
      },
      badges: {
        'BADGE-HPG-L': HIGHEST_POINTS_GAINER_BADGE,
        'BADGE-HPG-C': HIGHEST_POINTS_GAINER_BADGE,
        'BADGE-FCD': FIRST_LESSON_ACHEIVER_BADGE,
        'BADGE-FLD': FIRST_LESSON_ACHEIVER_BADGE,
        'BADGE-MLD': EXTRAORDINARY_BADGE,
      },
      vibes: [],
      isLoading: false,
      nameLoading: false,
      searchTerms: {
        badge: '',
        vibe: '',
        point: '',
        coin: '',
      },
      rowsPerPage: {
        badge: 10,
        vibe: 10,
        point: 10,
        coin: 10,
      },
      rppOptions: [10, 15, 20, 30, 50],
    }
  },
  computed: {
    ...mapGetters('app-chat', {
      defaultVibes: 'get_default_vibes',
    }),
  },
  mounted() {
    if (this.$route.params.reward) {
      this.currentItem = this.$route.params.reward
    }
    if (this.$route.params.uid) {
      this.studentId = this.$route.params.uid
    }
    this.$nextTick(() => {
      if (this.studentId) {
        this.nameLoading = true
        this.getStudent(this.studentId)
      }
      this.getAwardStats(this.currentItem)
    })
  },
  methods: {
    formatDate(date) {
      return moment(date).format('ll')
    },
    setCurrentItem(type) {
      this.currentItem = type
      if (!this.awardStats[type].length) this.getAwardStats(type)
      if (type === 'vibe' && !this.vibes.length) this.getVibes()
    },
    getAwardStats(type, page = 1) {
      /* eslint-disable no-unneeded-ternary */
      this.isLoading = this.searchTerms[type] ? false : true
      const uid = this.studentId ? this.studentId : this.self.id

      const params = {
        type, uid, page, limit: this.rowsPerPage[type], search: this.searchTerms[type],
      }
      useJwt.getStudentRewardEarning({
        params,
      }).then(response => {
        let res
        if (type === 'coin') res = response.data?.data?.data
        else res = response.data?.data
        this.awardStats[type] = res.data
        this.awardStats[type].total = res.total
        this.awardStats[type].currentPage = res.current_page
        this.awardStats[type].nextPage = res.next_page_url
        this.awardStats[type].prevPage = res.prev_page_url
        this.awardStats[type].from = res.from
        this.awardStats[type].to = res.to
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => { this.isLoading = false })
    },
    getVibeIcon(code) {
      const vibe = this.vibes?.filter(v => v.code === code)
      return vibe.icon ? vibe.icon : this.defaultVibes[code]
    },
    getVibes() {
      useJwt.getVibes().then(res => {
        this.vibes = res.data?.data
      })
    },
    getTotal(column, row, type) {
      let total = 0
      this.awardStats[type].forEach(el => {
        total += el[row]
      })
      return `${column} (${total})`
    },
    getStudent(id) {
      if (id) {
        useJwt.getStudentById(id).then(response => {
          this.studentName = response.data?.data?.firstname
        }).finally(() => { this.nameLoading = false })
      }
    },
  },
}
</script>

<style scoped>
  .vibe-icon {
    width: 60px;
    height: auto;
  }
  .badge-icon {
    width: 40px;
    height: auto;
  }
  .pagination-button {
    background-color:transparent;
    border: none;
  }
  .pagination-button:active {
    background-color: transparent !important;
  }
  .pagination-button:disabled {
    cursor: not-allowed;
  }
  .feather {
    color: #0099ff;
    fill: #0099ff;
  }
  .pagination-button:disabled .feather {
    color: #e6e6e6;
    fill: #e6e6e6;
  }
  .table-footer {
    background-color: #fff;
    padding: 6px 0;
  }
  .table-footer .custom-select {
    width: 72px;
  }
  .dark-layout .feather {
    color: #00cfe8;
    fill: #00cfe8;
  }
  .dark-layout .table-footer {
    background-color: #161D31;
  }
  .dark-layout .pagination-button {
    color: #e6e6e6;
  }
</style>

<style>
.dark-layout .vgt-global-search {
  border: 1px solid #444;
}
.dark-layout .vgt-input {
  background-color: #283046;
  border-color: #333;
  color: #f1f1f1;
}
.dark-layout .vgt-input:focus {
  border-color: #a6a6a6;
}
.dark-layout .vgt-input::placeholder {
  color: #f4f4f4;
}
</style>
