<template>
  <div>
    <b-button variant="outline-primary"
              @click="state.openModal = true;animationTemplateCopy()"
    >
      Manage Template
    </b-button>
    <b-modal v-model="state.openModal"
             title="Template Lists"
             hide-footer
             modal-class="p-0"
    >
      <b-overlay :show="state.isProcessing"
                 class="table-responsive"
      >
        <b-form-input v-model="state.searchTerm"
                      placeholder="Search Template..."
                      class="mb-2"
                      @keyup="searchTemplates"
        />
        <table class="table table-hover table-bordered table-striped">
          <thead>
            <tr>
              <!-- <th>S.N</th> -->
              <th class="col-1">
                Name
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="template of state.filteredTemplate"
                :key="template.id"
            >
              <!-- <td>{{ ++index }}</td> -->
              <td class="col-1 template-name">
                {{ template.name }}
              </td>
              <td class="d-flex">
                <b-button variant="flat-primary"
                          class="btn-icon"
                          @click="applySelectedTemplate(template.id)"
                >
                  Apply Template
                </b-button>
                <b-button variant="flat-success"
                          class="btn-icon"
                          @click="state.templateToDelete = template.id"
                >
                  <feather-icon icon="TrashIcon"
                                class="text-danger"
                  />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </b-overlay>
    </b-modal>
    <DeleteModal :on-delete="handleDeleteTemplate"
                 title="Delete"
                 :show="!!state.templateToDelete"
                 sub-title="Are you sure in want to delete?"
                 varient="danger"
                 :is-processing-delete="state.isDeleting"
                 @close="state.templateToDelete = null"
    />
  </div>
</template>
<script setup>
import {
  BButton, BModal, BOverlay, BFormInput,
} from 'bootstrap-vue'
import { reactive, getCurrentInstance } from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import DeleteModal from '@/views/common/components/DeleteModal.vue'

const state = reactive({
  openModal: false,
  isDeleting: false,
  templateToDelete: null,
  isProcessing: false,
  savedTemplateLists: [],
  filteredTemplate: [],
  searchTerm: '',
})

const emit = defineEmits(['applyTemplate'])

function animationTemplateCopy() {
  state.isProcessing = true
  useJwt.getSavedAnimationTemplateCopy().then(response => {
    state.savedTemplateLists = response.data.data.map(item => ({ name: item.template_name, value: item.id, ...item }))
    state.filteredTemplate = [...state.savedTemplateLists]
  }).catch(error => {
    this.showErrorMessage(error)
  }).finally(() => {
    state.isProcessing = false
  })
}
const root = getCurrentInstance().proxy.$root

function applySelectedTemplate(value) {
  if (value) {
    const template = state.savedTemplateLists.find(item => item.value === value)
    if (template) {
      emit('applyTemplate', template)
    } else {
      root.showError('Template not found')
    }
  }
}

function handleDeleteTemplate() {
  state.isDeleting = true
  useJwt.destroyAnimationTemplate(state.templateToDelete).then(response => {
    if (response.data.success) {
      root.showSuccess(response.data.message)
      animationTemplateCopy()
      state.templateToDelete = false
    } else {
      root.showError(response.data.message)
    }
  }).catch(error => {
    root.showError(error)
  }).finally(() => {
    state.isDeleting = false
  })
}

function searchTemplates() {
  if (!state.searchTerm) {
    state.filteredTemplate = [...state.savedTemplateLists]
  } else {
    state.filteredTemplate = state.savedTemplateLists.filter(obj => obj.name.toLowerCase().includes(state.searchTerm.toLowerCase()))
  }
}

</script>
<style>
  td.template-name {
    width: 3px;
    text-overflow: ellipse;
  }
</style>
