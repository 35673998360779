<template>
  <div>
    <b-modal
      v-model="isVisible"
      ok-only
      ok-variant="success"
      :ok-title="$t('confirm')"
      modal-class="modal-success"
      centered
      size="lg"
      title="Copy To Lesson"
      @close="$emit('close')"
      @hide="$emit('close')"
    >

      <validation-observer ref="copyQuestion">
        <b-form
          class="auth-login-form"
          @submit.prevent="handleSubmit"
        >
          <b-row>
            <b-col md="12">
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Course"
                  >
                    <validation-provider
                      v-slot="{errors}"
                      name="Course"
                      rules="required"
                      vid="course_id"
                    >
                      <v-select
                        id="event-lesson"
                        v-model="copyTo.course_id"
                        :options="courses"
                        label="text"
                        :reduce="v => v.value"
                        :state="errors.length > 0 ? false : null"
                        @input="(id)=>getLessonGroups(id,true)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Lesson Group"
                  >
                    <validation-provider
                      v-slot="{errors}"
                      name="Lesson Group"
                      rules="required"
                      vid="lesson_group_id"
                    >
                      <b-form-select
                        id="event-lesson"
                        v-model="copyTo.lesson_group_id"
                        :options="lessonGroups"
                        :disabled="!copyTo.course_id"
                        :state="errors.length > 0 ? false : null"
                        @change="(id)=>getLessons(id,true)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Lesson"
                label-for="event-lesson"
              >
                <validation-provider
                  v-slot="{errors}"
                  name="Lesson"
                  rules="required"
                  vid="lesson_id"
                >

                  <b-form-select
                    id="event-lesson"
                    v-model="copyTo.lesson_id"
                    :disabled="!copyTo.lesson_group_id"
                    :options="lessons"
                    :state="errors.length > 0 ? false : null"
                    @change="(id)=>getDomains(id,true)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Domain -->
            <b-col md="12">
              <b-form-group
                :label="$t('labels.create-problem.domain')"
                label-for="Domain"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Domain"
                  rules="required"
                >
                  <b-spinner
                    v-if="isDomainProcessing"
                    label="Loading..."
                  />
                  <b-form-select
                    v-else
                    id="domain"
                    v-model="copyTo.domain_id"
                    :disabled="!copyTo.lesson_id"
                    :options="domains"
                    name="Domain"
                    :state="errors.length > 0 ? false : null"
                    @change="(id)=>getStandards(id,true)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Domain -->

            <!-- Standard -->
            <b-col md="12">
              <b-form-group
                :label="$t('labels.create-problem.standard')"
                label-for="Domain"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Standard"
                  rules="required"
                >
                  <b-spinner
                    v-if="isStandardProcessing"
                    label="Loading..."
                  />
                  <b-form-select
                    v-else
                    id="domain"
                    v-model="copyTo.standard_id"
                    :disabled="!copyTo.domain_id"
                    :options="standards"
                    name="Standard"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Standard -->
          </b-row>
        </b-form>
      </validation-observer>

      <template #modal-footer="">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              :disabled="isProcessing"
              @click="copy"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t('copy') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BSpinner, BRow, BCol, BModal, BButton, BForm, BFormSelect, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import vSelect from 'vue-select'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'

export default ({
  directives: {
    Ripple,
  },
  components: {
    BModal,
    BFormGroup,
    BSpinner,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormSelect,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    problemId: {
      type: [Number, Array],
      required: true,
    },
  },
  data() {
    return {
      isVisible: this.show,
      courses: [],
      lessonGroups: [],
      lessons: [],
      domains: [],
      standards: [],
      isProcessing: false,
      copyTo: {
        lesson_id: null,
        course_id: null,
        lesson_group_id: null,
        domain_id: null,
        standard_id: null,
      },
      isDomainProcessing: false,
      isStandardProcessing: false,
    }
  },
  watch: {
    show() {
      this.isVisible = this.show
    },
  },
  created() {
    this.getCourses()
  },
  methods: {
    getCourses() {
      useJwt.getCourses().then(res => {
        this.courses = res.data.data.map(e => ({
          value: e.id, id: e.id, lesson_groups: e.lesson_groups, text: e.name,
        }))
        this.courses.unshift({ value: null, text: i18n.tc('requests.select-course') })
      })
    },
    getLessonGroups(id) {
      this.copyTo.lesson_group_id = null
      this.lessonGroups = this.courses[this.courses.findIndex(e => e.id === id)].lesson_groups.map(e => ({
        value: e.id, id: e.id, lessons: e.lessons, text: e.name,
      }))
      this.lessonGroups.unshift({ value: null, text: i18n.tc('requests.select-lesson-group') })
    },
    getLessons(id) {
      this.copyTo.lesson_id = null
      this.lessons = this.lessonGroups[this.lessonGroups.findIndex(e => e.id === id)].lessons.map(e => ({
        value: e.id, id: e.id, text: e.name,
      }))
      this.lessons.unshift({ value: null, text: i18n.tc('requests.select-lesson') })
    },
    getDomains(id) {
      this.copyTo.domain_id = null
      this.isDomainProcessing = true
      if (id) {
        useJwt.getDomainByLesson(id)
          .then(res => {
            this.domains = res.data.data.domain.map(domain => ({
              value: domain.id, id: domain.id, text: domain.name,
            }))
          }).finally(() => {
            this.domains.unshift({ value: null, text: i18n.tc('requests.select-domain') })
            this.isDomainProcessing = false
          })
      } else this.isDomainProcessing = false
    },
    getStandards(id) {
      if (id) {
        this.copyTo.standard_id = null
        this.isStandardProcessing = true
        useJwt.getStandardsByDomain(id).then(res => {
          this.standards = res.data.data.map(s => ({ value: s.id, text: s.name }))
        }).finally(() => {
          this.standards.unshift({ value: null, text: i18n.tc('requests.select-standard') })
          this.isStandardProcessing = false
        })
      } else this.isStandardProcessing = false
    },
    copy() {
      this.$refs.copyQuestion.validate().then(res => {
        if (res) {
          this.isProcessing = true
          const data = {
            lesson_id: this.copyTo.lesson_id,
            domain_id: this.copyTo.domain_id,
            standard_id: this.copyTo.standard_id,
            problem_id: this.problemId,
          }
          useJwt.copyProblem(data)
            .then(() => {
              this.showSuccess('Problem has been copied successfully.')
            })
            .catch(error => {
              this.showErrorMessage(error)
            }).finally(() => {
              this.isProcessing = false
              this.copyTo = {
                lesson_id: null,
                lesson_group_id: null,
                course_id: null,
              }
              this.$emit('close')
            })
        }
      })
    },
  },
})
</script>
