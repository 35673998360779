<template>
  <div>
    <slot />
    <b-overlay :show="isProcessing">
      <b-list-group class="searchable-list">
        <b-list-group-item v-for="value in imageLibrary"
                           :key="value.id + i"
                           role="button"
                           @click="handleClick(value)"
        >
          <b-img :src="value.image"
                 thumbnail
                 height="50"
                 width="50"
          />
          <span class="ml-2">
            {{ value.vocab_word }} ({{ value.synonyms?.map(item => item.text ).join(',') }})
          </span>
          <span class="float-right">
            <b-button
              variant="flat-success"
              class="btn-icon rounded-circle"
              @click.stop="editImageLibrary(value)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </span>
        </b-list-group-item>
      </b-list-group>
    </b-overlay>
    <manage-image-library
      :imagelibrary-info="itemToEdit"
      :show="!!itemToEdit"
      :readonly="true"
      @close="itemToEdit = null"
    />
  </div>
</template>
<script>
import {
  BListGroup, BListGroupItem, BOverlay, BImg, BButton,
} from 'bootstrap-vue'
import { debounce } from 'lodash'
import useJwt from '@/auth/jwt/useJwt'
import ManageImageLibrary from '@/views/super/image-library/components/Manage.vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BOverlay,
    BImg,
    BButton,
    ManageImageLibrary,
  },
  props: {
    term: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imageLibrary: [],
      isProcessing: false,
      canProcessSearch: true,
      itemToEdit: null,
    }
  },
  watch: {
    term: debounce(function () {
      if (this.canProcessSearch) {
        this.getImageLibrary()
      }
    }, 1000),
  },
  methods: {
    editImageLibrary(value) {
      this.itemToEdit = value
    },
    handleClick(value) {
      this.$emit('onWordSelected', value)
      this.imageLibrary = []
      this.canProcessSearch = false
      setTimeout(() => {
        this.canProcessSearch = true
      }, 1500)
    },
    getImageLibrary(term = this.term, page = 1) {
      if (!term) {
        this.imageLibrary = []
        return
      }
      this.isProcessing = true
      useJwt.getImageLibrary({
        params: {
          term,
          page,
        },
      }).then(response => {
        this.imageLibrary = response.data?.data?.data
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
<style scoped>
    .list-group-item {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
    .searchable-list {
      position: absolute;
      width: 100%;
      z-index: 1000;
    }
</style>
