<template>
  <b-card>
    <b-card-header>
      <h4>{{ $t('self-evaluation.personal-assessment') }}</h4>
    </b-card-header>

    <b-card-body>
      <div class="d-flex flex-wrap mb-2">
        <b-badge
          v-for="personality in studentPersonality"
          :key="personality.id"
          variant="primary"
          class="mr-1 mb-1"
        >
          {{ personality.name }}
        </b-badge>
      </div>

      <b-button
        v-b-modal.self-evaluation-modal-edit
        class="float-right"
        variant="primary"
      >
        <feather-icon
          icon="EditIcon"
        />
        {{ $t('actions.edit') }}
      </b-button>
    </b-card-body>

    <self-evaluation-modal
      mode="edit"
      :user="self"
    />

  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import {
  USER_TYPE_STUDENT,
} from '@/const/userType'
import selfEvaluationModal from '@/@core/layouts/components/layout-content-renderer/components/SelfEvaluationModal.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    selfEvaluationModal,
  },
  props: {
    studentPersonality: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      self: getUserData(),
    }
  },
  computed: {
    // showPersonality() {
    //   if (this.allPersonalities && this.studentPersonality) {
    //     return this.allPersonalities.filter(personality => this.studentPersonality.includes(personality.id))
    //   }
    //   return null
    // },
    isAStudent() {
      return this.self.userType === USER_TYPE_STUDENT
    },
  },
}
</script>
