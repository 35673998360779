<!-- eslint-disable eol-last -->
<template>
  <b-overlay :show="isProcessing">
    <div v-if="enabled">
      <div v-if="isEditMode">
        <h4>Layout Editor</h4>
        <editor-setting :form="setting">
          <h4 v-if="hiddenLayout.length">
            Hidden Layouts
          </h4>
          <b-button
            v-for="hidden of hiddenLayout"
            :key="hidden.position"
            class="mr-2"
            variant="outline-danger"
            size="sm"
            @click="hidden.isVisible = true"
          >
            {{ hidden.position }} <feather-icon icon="RefreshCcwIcon" />
          </b-button>
        </editor-setting>
      </div>
      <Editor
        v-if="isTemplateLoaded"
        :key="isEditMode"
        ref="animationEditor"
        :initial-layout-position="initialLayoutPosition"
        :is-edit-mode="isEditMode"
        :setting="setting"
        :layout-setting="layoutSetting"
        :template-id="templateId"
        @onUpdate="value => initialLayoutPosition = value"
      >
        <template #problem-body>
          <slot class="p-2" />
        </template>
      </Editor>
    </div>
    <slot v-else />
    <portal-target v-if="isProblemBodyPreview"
                   name="problemFeedBack"
    />
    <portal-target v-else-if="teleportTarget"
                   :name="teleportTarget"
    />

  </b-overlay>
</template>

<script>
import { BButton, BOverlay } from 'bootstrap-vue'
import AOS from 'aos'
import useJwt from '@/auth/jwt/useJwt'
import Editor from './components/editor/index.vue'
import 'aos/dist/aos.css'
import EditorSetting from './components/EditorSetting.vue'

const defaultAnimation = () => [
  {
    position: 'LEFT_TOP',
    animation: 'flip-left',
    component: 'FirstElement',
    isVisible: true,
    elementData: [],
    height: 200,
    settings: {
      background: 'rgba(0, 0, 0, 0)',
    },
  },
  {
    position: 'LEFT_BOTTOM',
    isVisible: true,
    component: 'FirstElement',
    animation: 'flip-left',
    elementData: [],
    height: 200,
    settings: {
      background: 'rgba(0, 0, 0, 0)',
    },
  },
  {
    position: 'RIGHT_TOP',
    isVisible: true,
    component: 'FirstElement',
    animation: 'flip-right',
    elementData: [],
    height: 200,
    settings: {
      background: 'rgba(0, 0, 0, 0)',
    },
  },
  {
    position: 'RIGHT_BOTTOM',
    component: 'ProblemBody',
    animation: 'flip-right',
    isVisible: true,
    elementData: [],
    height: 200,
    settings: {
      background: 'rgba(0, 0, 0, 0)',
    },
  },
]
const defaultLayoutSetting = () => ({
  SMALL: {
    background: 'rgba(0, 0, 0, 0)',
  },
  BIG: {
    background: 'rgba(0, 0, 0, 0)',
  },
})

const initialSetting = () => ({
  image: null,
  bgStyleType: 'image',
  backgroundColor: null,
  imageUrl: null,
  layout: 'small-left-big-right',
  bodyTheme: null,
  answerCluster: null,
  audioUrl: '',
})

export default {
  components: {
    EditorSetting,
    Editor,
    BOverlay,
    BButton,
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: true,
    },
    enabled: {
      type: [Boolean, Number],
      default: false,
    },
    templateId: {
      type: [Number, Object],
      default: () => null,
    },
    // setting up problem body animation layout because it requires vuex to deep components
    isProblemBodyPreview: {
      type: Boolean,
      default: false,
    },
    teleportTarget: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      setting: { ...initialSetting() },
      layoutSetting: { ...defaultLayoutSetting() },
      isProcessing: false,
      isTemplateLoaded: true,
      canStartUpdatingTemplate: false,
      animationStarted: false,
      initialLayoutPosition: [
        ...defaultAnimation(),
      ],
    }
  },
  computed: {
    hiddenLayout() {
      return this.initialLayoutPosition.filter(item => item.isVisible === false)
    },
  },
  watch: {
    isEditMode() {
      this.beginAnimatingLayout()
    },
    initialLayoutPosition: {
      handler() {
        this.updateTemplate()
      },
      immediate: false,
      deep: true,
    },
    setting: {
      handler() {
        this.updateTemplate()
        this.updateSettingToStore()
      },
      deep: true,
    },
    layoutSetting: {
      handler() {
        this.updateTemplate()
        this.updateSettingToStore()
      },
      deep: true,
    },
    templateId: {
      handler(value) {
        if (value) {
          this.getTemplate(value)
        } else if (this.$store.state.problem.create?.animatedLayoutData) {
          // try to load from store
          const { layoutDetails, setting } = this.$store.state.problem.create?.animatedLayoutData
          this.initialLayoutPosition = layoutDetails
          this.setting = setting
        }
      },
      immediate: true,
    },
    enabled: {
      handler(value) {
        if (value) {
          this.beginAnimatingLayout()
        }
        this.$store.commit('problem/TOGGLE_ANIMATED_LAYOUT_CREATE', value)
        this.updateSettingToStore()
      },
      immediate: true,
    },
  },
  created() {
    AOS.init()
    // reInitialize AOS when multiple animation layout is added
    AOS.refresh()
  },
  beforeDestroy() {
    // if (this.isProblemBodyPreview) {
    this.$store.commit('problem/UPDATE_ANIMATED_LAYOUT_SETTING', null)
    // }
  },
  mounted() {
    if (!this.templateId && this.$route.query.problem_id) {
      this.updateTemplate()
    }
    if (this.isProblemBodyPreview) {
      this.$root.$off('resetAnimationTemplate')
      this.$root.$on('resetAnimationTemplate', () => {
        this.$swal.fire({
          title: 'Are you sure you want to reset all changes?',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Yes, Reset',
          denyButtonText: 'Don\'t save',
        }).then(result => {
          if (result.isConfirmed) {
            this.handleResetTemplate()
          }
        })
      })
      this.$root.$off('loadSavedAnimationTemplate')
      this.$root.$on('loadSavedAnimationTemplate', template => {
        this.loadTemplate(template)
      })
    }
  },
  methods: {
    handleResetTemplate() {
      this.initialLayoutPosition = [
        ...defaultAnimation(),
      ]
      this.setting = { ...initialSetting() }
      this.layoutSetting = { ...defaultLayoutSetting() }
      this.$forceUpdate()
      this.$nextTick(() => {
        this.updateTemplate()
      })
    },
    beginAnimatingLayout() {
      setTimeout(() => {
        this.$refs.animationEditor.setCanAnimate()
        setTimeout(() => {
          if (this.$refs.animationEditor) this.$refs.animationEditor.startAnimating()
          this.animationStarted = true
        }, 500)
      }, 500)
    },
    updateSettingToStore() {
      // if (!this.isProblemBodyPreview) {
      //   return
      // }
      if (this.enabled) {
        this.$store.commit('problem/UPDATE_ANIMATED_LAYOUT_SETTING', this.setting)
      } else {
        this.$store.commit('problem/UPDATE_ANIMATED_LAYOUT_SETTING', null)
      }
    },
    getTemplateParams() {
      return {
        layoutDetails: this.initialLayoutPosition,
        setting: this.setting,
        layout_setting: this.layoutSetting,
      }
    },
    updateTemplate() {
      const params = this.getTemplateParams()
      if (this.isProblemBodyPreview) {
        if (this.templateId) {
          if (this.canStartUpdatingTemplate) {
            this.$root.$emit('updateAnimatedLayout', this.templateId, params)
          }
        } else {
          this.$root.$emit('createAnimatedLayout', params)
          this.$store.commit('problem/UPDATE_ANIMATED_LAYOUT_CREATE', params)
          setTimeout(() => {
            this.canStartUpdatingTemplate = true
          }, 2500)
        }
      } else if (this.canStartUpdatingTemplate) {
        this.$emit('handleTemplateUpdate', this.templateId, params)
      }
    },
    loadTemplate(template) {
      this.initialLayoutPosition = template.element_data.map(item => ({ ...item, settings: item.settings || {} }))
      this.setting = template.editor_setting || {}
      this.layoutSetting = template.layout_setting || { ...defaultLayoutSetting() }
      this.$nextTick(() => {
        if (this.$refs.animationEditor) { this.$refs.animationEditor.startAnimating() }
        setTimeout(() => {
          this.canStartUpdatingTemplate = true
          this.beginAnimatingLayout()
        }, 2500)
      })
    },
    getTemplate(value) {
      this.isTemplateLoaded = false
      this.isProcessing = true
      useJwt.getAnimationTemplate(value).then(response => {
        const { template } = response.data.data
        if (template) {
          this.loadTemplate(template)
        }
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => { this.isTemplateLoaded = true; this.isProcessing = false })
    },
  },
}
</script>
<style>
.swal2-cancel {

  margin-left: 10px;
}
</style>
