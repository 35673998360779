<template>
  <b-card v-if="teacherProfile && (teacherProfile.description && !isViewingOwnProfile)">
    <b-modal
      v-if="isViewingOwnProfile"
      id="modal-update-profile"
      ref="modal-update-profile"
      ok-only
      size="lg"
      ok-variant="primary"
      :ok-title="$t('confirm')"
      modal-class="modal-primary"
      centered
      :title="$t('profile-module.update-profile')"
    >
      <b-col md="12">
        <!-- form -->
        <validation-observer
          ref="updateProfileForm"
        >
          <b-form
            class="update-profile-form"
          >
            <b-row>
              <b-col
                md="12"
              >
                <b-form-group
                  :label="$t('profile-module.description')"
                  label-for="description"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Description"
                    rules="required"
                    vid="description"
                  >
                    <b-form-textarea
                      id="item_name"
                      v-model="data.description"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <template #modal-footer="{ }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          > <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="isProcessing"
            @click="updateTeacherProfile()"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('profile-module.update-profile') }}
          </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- about -->
    <b-row
      class="d-flex justify-content-between align-items-center"
    >

      <b-col>
        <h5 class="text-capitalize mb-75">
          {{ $t('profile-module.description') }}
        </h5>
      </b-col>

      <b-dropdown
        v-if="isViewingOwnProfile"
        variant="link"
        no-caret
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>
        <b-dropdown-item v-b-modal.modal-update-profile>
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">{{ $t("edit") }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-row>
    <b-card-text>
      {{ teacherProfile.description }}
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BButton, BForm, BCol, BRow, BFormTextarea, BDropdown, BDropdownItem, BFormGroup, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BFormGroup,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    BDropdown,
    BDropdownItem,
    BCol,
    BFormTextarea,
    BRow,
    BCardText,
    BButton,
    BForm,
    // BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isViewingOwnProfile: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isProcessing: false,
      teacherProfile: JSON.parse(JSON.stringify(this.data)),
    }
  },
  mounted() {
    // this.getTeacherProfile()
  },
  methods: {
    getTeacherProfile() {
      useJwt.getTeacherProfile(getUserData().id).then(response => {
        this.teacherProfile = response.data.data
        this.data = JSON.parse(JSON.stringify(this.teacherProfile))
      })
    },
    updateTeacherProfile() {
      this.$refs.updateProfileForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt.updateTeacherProfile(this.teacherProfile.id, {
            description: this.data.description,
          })
            .then(response => {
              this.teacherProfile = response.data.data
              this.$refs['modal-update-profile'].hide()
              this.showSuccessMessage(response)
            }).catch(error => {
              if (error.response?.data?.errors) this.$refs.updateProfileForm.setErrors(error.response.data.errors)
              else this.showErrorMessage(error)
            })
            .finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
  },
}
</script>
