<!-- eslint-disable vue/html-indent -->
<template>
  <div>
    <div class="report-head-back mb-1">
      <div style="padding:6px 5px">
        <!-- Back to Courses -->
        <b-button variant="flat-secondary"
            class="btn-icon"
            @click="GO_BACK()"
            >
          <span>
            <feather-icon icon="ArrowLeftIcon" />{{ $t("actions.back") }}</span>
        </b-button>
      </div>
    </div>
    <b-overlay id="individualReportPdf"
      :show="isProcessing"
      >
      <div v-if="individualReport.length">
      <profile-header
        :user-info="userInfo"
        :class-info="classInfo"
        :current-report-type="currentReportType"
        @onChangeClassRoom="onChangeClassRoom"
        @switch-report-type="setReportType"
      >
        <template #classroom-picker>
          <ClassRoomPicker
            :class-room-lists="classRoomLists"
            :classroom-id="+classroomId"
            style="min-width: 300px;"
            @onChangeClassRoom="onChangeClassRoom"
          />
        </template>
      </profile-header>
      <template v-if="!hideStatsSummary">
        <statistic-card
          v-if="currentReportType === 'regular'"
          :table-data="individualReport"
          :get-lesson-competition="getLessonCompetition"
          :get-average="getAverage"
          :expected-report="expectedReport"
          :actual-report="actualDoneReport"
          :comparative-report="comparativeReport"
          :performance-report="performanceReport"
          :study-habit="studyHabit"
        />
        <scorm-statistic-card
          v-else
          :expected-report="expectedReport"
          :actual-report="actualDoneReport"
          :comparative-report="comparativeReport"
          :performance-report="performanceReport"
          :study-habit="studyHabit"
        />
      </template>
      <LearningProgress
        :table-data="individualReport"
        :expected-report="expectedReport"
        :actual-report="actualDoneReport"
        :average-study-time="averageStudyTime"
        class="mt-2 pagebreak"
      />
      <study-time
        class="mt-2 pagebreak"
        :user-id="Number(userId)"
        :class-id="+classroomId"
      />
      <div class="row mx-0">
        <div class="col-12 p-0 table-responsive">
          <b-card body-class="p-0 w-100">
            <table-data
              :report-data="individualReport"
              :get-lesson-competition="getLessonCompetition"
              :get-average="getAverage"
              :actual-report="actualDoneReport"
              :report-type="currentReportType"
              :summary-graph="summaryGraph"
              @openThirdLevelReport="openReport"
            />
          </b-card>
        </div>
        </div>
      </div>
      <div v-else>
        <div class="card center justify p-5">
          <p v-html="noticeMessage" />
          <p v-html="requestMessage" />
          <p>{{ $t('individual-report-module.thank_you_for_understanding') }}</p>
          <div class="row">
            <div class="col-4">
              <label class="control-label h4">Classrooms</label>
              <ClassRoomPicker
                :class-room-lists="classRoomLists"
                :classroom-id="+classroomId"
                style="min-width: 300px;"
                @onChangeClassRoom="onChangeClassRoom"
              />
            </div>
        </div>
      </div>
    </div>
    </b-overlay>
    <b-modal
      :visible="!!overallReport"
      :title="overallReport?.lesson"
      size="xl"
      @close="overallReport = null"
      @hide="overallReport = null"
    >
      <b-overlay :show="isProcessing">
        <student-problems-report
        :problem="overallReport"
        @showPreview="showProblemPreview"
      />
      </b-overlay>
    </b-modal>
    <question-preview
      :show="showPreview"
      :show-edit-mode="false"
      :data="problemPreview || {}"
      @close="showPreview=false"
    />
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard, BModal, BOverlay, BButton,
} from 'bootstrap-vue'
import StudentProblemsReport from '@/views/common/class/components/ProblemsReport.vue'
import QuestionPreview from '@/views/common/components/QuestionPreview.vue'
import i18n from '@/libs/i18n'
import { mapGetters } from 'vuex'
import {
  isVisible,
} from '@/utils/visibilitySettings'
import useApollo from '@/@core/graphql/useApollo'
import TableData from './components/TableData.vue'
import StatisticCard from './components/Statistics.vue'
import ScormStatisticCard from './components/ScormStatistics.vue'
import ProfileHeader from './components/ProfileHeader.vue'
import LearningProgress from './components/LearningProgress.vue'
import StudyTime from './components/StudyTime.vue'
import ClassRoomPicker from './components/ClassroomPicker.vue'

export default {
  components: {
    TableData,
    BCard,
    StudentProblemsReport,
    StatisticCard,
    ScormStatisticCard,
    BModal,
    ProfileHeader,
    BOverlay,
    BButton,
    QuestionPreview,
    LearningProgress,
    StudyTime,
    ClassRoomPicker,
  },
  data() {
    return {
      userId: this.$route.params.studentId,
      classroomId: this.$route.query.classroomId,
      individualReport: [],
      overallReport: null,
      activeDialogs: [],
      isProcessing: false,
      userInfo: null,
      classInfo: null,
      comparativeReport: [],
      expectedReport: null,
      actualDoneReport: null,
      performanceReport: null,
      studyHabit: null,
      problemBeingPreviewed: null,
      showPreview: false,
      problemTemplates: {},
      problemPreview: {},
      averageStudyTime: [],
      summaryGraph: {},
      classRoomLists: [],
      defaultReportType: '',
      currentReportType: 'regular',
      reportTypes: ['regular', 'scorm'],
    }
  },
  computed: {
    ...mapGetters('appConfig', ['getSchoolSettings', 'getDistrictSettings']),
    fullname() {
      return `${this.userInfo?.firstname} ${this.userInfo?.lastname}`
    },
    noticeMessage() {
      return i18n.t('individual-report-module.notice', { fullname: this.fullname })
    },
    requestMessage() {
      return i18n.t('individual-report-module.request', { name: this.userInfo?.firstname })
    },
    hideStatsSummary() {
      const distSetting = this.getDistrictSettings
      const schoolSetting = this.getSchoolSettings
      return !isVisible('stats_summary', schoolSetting, distSetting)
    },
  },
  mounted() {
    this.getAllClassRooms()
    this.getIndividualUserReport()
  },
  methods: {
    setReportType(isDefault) {
      const nonDefaultReport = this.reportTypes.find(report => report !== this.defaultReportType)
      if ((isDefault && this.defaultReportType === 'scorm') || (!isDefault && this.defaultReportType === 'regular')) {
        this.currentReportType = nonDefaultReport
      } else {
        this.currentReportType = this.defaultReportType
      }
      // this.currentReportType = !isDefault ? this.defaultReportType : this.reportTypes.find(report => report !== this.defaultReportType)
      console.log(isDefault, this.currentReportType)
    },
    getAllClassRooms() {
      const userId = this.AUTH_USER().id
      const selectedClassType = 'all'
      useApollo
        .searchRooms('', userId, selectedClassType)
        .then(res => {
          this.classRoomLists = res.data.searchRooms.map(i => ({
            name: i.name,
            id: +i.id,
          }))
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
    },
    onChangeClassRoom(classId) {
      this.classroomId = classId
      this.getIndividualUserReport()
    },
    showProblemPreview(prob) {
      this.problemBeingPreviewed = prob
      this.getProblemForPreview()
    },
    getTemplates(id) {
      if (!id) return
      if (this.problemTemplates[id]) return
      // eslint-disable-next-line consistent-return
      return new Promise(resolve => {
        useJwt.getTemplate(id).then(res => {
          this.problemTemplates[id] = res.data.data
          resolve('')
        })
      })
    },
    getProblemForPreview() {
      useJwt.getProblem(this.problemBeingPreviewed.problem_id).then(async res => {
        const problemData = res.data.data
        this.$store.commit('problem/UPDATE_ACTIVE_PROBLEM', problemData)
        const index = 0
        const statementAndFeedback = problemData.problem_statements[index] || problemData.problem_statements[0]
        await this.getTemplates(statementAndFeedback.fb_template_id)
        await this.getTemplates(statementAndFeedback.st_template_id)
        const answers = await useJwt.getAnswerPreview(this.problemBeingPreviewed.test_id)
        // show the last attempted language level
        // index = this.problemBeingPreviewed.attempt_data?.length > 0 ? this.problemBeingPreviewed.attempt_data[this.problemBeingPreviewed.attempt_data.length - 1].lang_level - 1 : index
        this.problemPreview = {
          theme: problemData.theme ? problemData.theme : 0,
          problemType: problemData.problem_type.name,
          testId: this.problemBeingPreviewed?.test_id,
          feedback: {
            type: statementAndFeedback.fb_type,
            text: statementAndFeedback.feedback,
            image: statementAndFeedback.fb_type === 'image' ? statementAndFeedback.fb_image : null,
            audio: statementAndFeedback.fb_type === 'audio' ? statementAndFeedback.fb_image : null,
            template: this.problemTemplates[statementAndFeedback.fb_template_id],
          },
          statement: {
            type: statementAndFeedback.st_type,
            text: statementAndFeedback.statement,
            image: statementAndFeedback.st_type === 'image' ? statementAndFeedback.st_image : null,
            audio: statementAndFeedback.st_type === 'audio' ? statementAndFeedback.st_image : null,
            template: this.problemTemplates[statementAndFeedback.st_template_id],
            video: problemData.video_time_stamp,
          },
          // eslint-disable-next-line eqeqeq
          questions: problemData.questions.length > 1 ? problemData.questions.filter(e => e.lang_level == (index + 1).toString()) : problemData.questions,
          answers: answers.data?.data,
          level: index + 1,
          rules: problemData.rules,
        }
        this.showPreview = true
      })
    },
    getLessonCompetition(report) {
      const completedLesson = report.lessons.filter(
        item => !!item.completed_at,
      ).length
      return (completedLesson / report.lessons.length) * 100
    },
    getAverage(report, key) {
      const sum = report.lessons.reduce((p, c) => p + Number(c[key]), 0)
      return sum / report.lessons.length
    },
    openReport(loaderId) {
      this.isProcessing = true
      useJwt
        .getStudentLessonStatsByLoader(loaderId)
        .then(response => {
          this.overallReport = response.data.data
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    getIndividualUserReport() {
      if (!this.classroomId) {
        this.showError(this.$i18n.tc('choose-classroom'))
      } else {
        this.isProcessing = true
        useJwt
          .getIndividualUserReport(this.userId, {
            params: {
              classroomId: this.classroomId,
            },
          })
          .then(response => {
            this.individualReport = response.data.data.academic_report.map(
              item => ({
                ...item, visible: false, showGraph: false, showSummary: false,
              }),
            )
            this.userInfo = response.data.data.info.user
            this.classInfo = response.data.data.info.classroom
            this.comparativeReport = response.data.data.comparative_report
            this.expectedReport = response.data.data.expected
            this.actualDoneReport = response.data.data.actual_done
            this.performanceReport = response.data.data.performance_report
            this.studyHabit = response.data.data.study_habits
            this.averageStudyTime = response.data.data.average_study_time
            this.summaryGraph = response.data.data.lesson_summary_graph

            if (this.$route.params.defaultReport) {
              this.setDefaultReportType(this.$route.params.defaultReport)
            } else this.getDefaultReportType()
          })
          .catch(error => {
            this.showErrorMessage(error)
          })
          .finally(() => {
            this.isProcessing = false
          })
      }
    },
    getDefaultReportType() {
      const key = 'default_individual_report'
      const params = {
        key,
        ...(this.$route.params.schoolId
          ? { schoolId: this.$route.params.schoolId }
          : { classId: this.$route.query.classroomId }
        ),
      }
      useJwt.getSchoolAndDistrictSettings({ params }).then(response => {
        // check district setting
        let defaultReportSetting = response.data.data.district?.find(setting => setting.key === key)

        if (!defaultReportSetting) {
          // check school setting
          defaultReportSetting = response.data.data.school.find(setting => setting.key === key)
        }
        this.setDefaultReportType(defaultReportSetting?.value)
      })
    },
    setDefaultReportType(isScormSettingOn) {
      const reportType = Number(isScormSettingOn) ? 'scorm' : 'regular'
      this.currentReportType = reportType
      this.defaultReportType = reportType
    },
  },
}
</script>
<style src="vue-dialog-drag/dist/vue-dialog-drag.css"></style>
<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
  .pagebreak {
      page-break-before: always;
    }

    .report-head-back {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 5px;
}
.dark-layout {
  .report-head-back {
    background-color: $theme-dark-card-bg !important;
    background-color: $theme-dark-box-shadow;
  }
 }
 .semi-light-layout,
.semi-light-reverse-layout,
.green-layout,
.brown-layout {
  .report-head-back {
    .btn-flat-secondary span {
      color: #5e5873 !important;
    }
  }
}
</style>
