<template>
  <layout-vertical>
    <router-view :key="$route.fullPath" />
    <template #navbar="{ toggleVerticalMenuActive }">

      <!-- Navbar -->
      <navbar
        :toggle-vertical-menu-active="toggleVerticalMenuActive"
      />
      <template
        v-if="isAStudent && largeDevices"
      >
        <animation-modal
          v-if="alarmOn"
          animation-file="/animations/model.glb"
          :animation-types="{alarming:0}"
          :active-animation="[alarmAnimationState]"
          canvas-id="webgl-dog"
          position="absolute"
          top="-80%"
          :camera-position="{x:0,y:0.7,z:2}"
          :scaling="{x:5,y:5,z:5}"
          left="0%"
          :canvas-height="150"
          :canvas-width="150"
          height="150"
          width="150"
          :light-intensity="5"
          :rotate="true"
        />
        <template v-if="currentTheme==='forest' && themeOn">
          <template v-for="(animationFile,index) in allAnimationFile">
            <animation-modal
              :key="animationFile.character"
              ref="animationModal"
              :index="index"
              :animation-file="animationFile.file"
              :animation-types="animalAnimationTypes"
              :active-animation="[animationFile.currentAnimation]"
              :canvas-id="`webgl-${animationFile.name}`"
              :character-name="animationFile.character"
              position="fixed"
              :canvas-height="200"
              :canvas-width="180"
              :top="animationFile.top"
              :camera-position="animationFile.cameraPosition"
              :character-type="animationFile.characterType"
              :scaling="animationFile.scaling"
              :left="animationFile.left"
              :light-intensity="4"
              :change-animation-enabled="true"
              :selected-outfits="Object.values(selectedOutfits[animationFile.character])"
              :rotation="true"
              :rotation-angle="animationFile.rotationAngle"
              :active-index="animationFile.characterType==='left'?leftActiveIndex:rightActiveIndex"
              :current-theme="currentTheme"
              @onClick="playSound(animationFile)"
              @changeModel="showCustomizationModal"
              @changeAnimation="changeAnimation"
              @changedAnimationModel="changedAnimationModel"
            />
          </template>
        </template>

      </template>
      <!-- Floating Chat -->

      <model-change-modal
        v-if="notMarketplaceRoute"
        :key="currentTheme"
        :animation-character="customizingAnimationFile"
        :toggle="showModelChangeModal"
        :edit-character="true"
        :current-theme="currentTheme"
        @close="showModelChangeModal=false"
      />
      <student-floating-chat
        v-if="isAStudent && studentFloatingChat.profile && $route.name != 'chat' && !hideChatIcon && !floatingChat"
        ref="floatingComponent"
        :chat-data="studentFloatingChat.chatData"
        :profile="studentFloatingChat.profile"
        :contacts="studentFloatingChat.contacts"
        :active-chat-id="''"
      />
      <floating-chat
        v-else-if="floatingChat"
        ref="floatingComponent"
        :chat-data="chatData"
        :profile="profile"
        :contacts="contacts"
        :active-chat-id="''"
      />
    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import store from '@/store'
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import FloatingChat from '@/views/common/chat/FloatingChat.vue'
import AnimationModal from '@/layouts/components/AnimationModal.vue'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_STUDENT } from '@/const/userType'
import ModelChangeModal from '@/layouts/components/ModelChangeModal.vue'
import {
  FOREST_CHARACTERS, FOREST_CHARACTERS_ANIMATIONS, ALL_FOREST_CHARACTERS,
  // KIDS_CHARACTERS, ALL_KIDS_CHARACTERS, KIDS_CHARACTERS_ANIMATIONS,
} from '@/const/theme'
import useJwt from '@/auth/jwt/useJwt'
import StudentFloatingChat from '@/views/common/chat/StudentFloatingChat.vue'
import { isVisible } from '@/utils/visibilitySettings'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    FloatingChat,
    AnimationModal,
    ModelChangeModal,
    StudentFloatingChat,
    // ModelViewer,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
      animalAnimationTypes: FOREST_CHARACTERS_ANIMATIONS,
      allAnimationCharacters: ALL_FOREST_CHARACTERS,
      windowWidth: window.innerWidth,
      allAnimationFile: FOREST_CHARACTERS,
      windowHeight: window.innerHeight,
      showModelChangeModal: false,
      interval: null,
      leftActiveIndex: 0,
      isProcessing: true,
      rightActiveIndex: 1,
      customizingAnimationFile: null,
      studentFloatingChat: {
        chatData: [],
        contacts: {},
        profile: null,
      },
      chatIconVisibility: false,
    }
  },
  computed: {
    chatData() {
      return store.state['app-chat'].activeChat
    },
    isStudentYoung() {
      return this.$store.getters['appConfig/getIsStudentYoung']
    },
    profile() {
      return store.state['app-chat'].profile
    },
    contacts() {
      return store.state['app-chat'].contacts
    },
    floatingChat() {
      return store.state['app-chat'].minimized
    },
    alarmAnimationState() {
      return store.state.appConfig.alarmAnimationState || 'idle'
    },
    isAStudent() {
      return getUserData() && getUserData().usertype === USER_TYPE_STUDENT
    },
    userData() {
      return getUserData()
    },
    themeOn() {
      const themeValue = this.$store.state.appConfig.themeOn
      if (themeValue === 'false' || themeValue === false) {
        return false
      }
      return true
    },
    alarmOn() {
      return this.$store.state.appConfig.alarmOn
    },
    currentTheme() {
      return this.$store.getters['appConfig/currentTheme']
    },
    currentRoute() {
      return this.$route.name
    },
    selectedOutfits() {
      return this.$store.state.appConfig.selectedOutfits || {}
    },
    notMarketplaceRoute() {
      return this.$route.name !== 'animated-items-marketplace' && this.isAStudent
    },
    largeDevices() {
      return window.innerWidth > 1068
    },
    // selectedCharacter() {
    //   return this.$store.state.appConfig.selectedCharacter
    // },
    hideChatIcon() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('chat_icon', schoolSetting, distSetting)
    },
  },

  watch: {
    currentRoute() {
      if (this.themeOn && this.currentTheme === 'forest' && this.$refs.animationModal && !this.$refs.animationModal[0].isProcessing && !this.$refs.animationModal[1].isProcessing) {
        this.allAnimationFile[0].rotationAngle = -0.6
        this.allAnimationFile[1].rotationAngle = 0.6

        this.changeAnimation({ index: 0, value: 'walk' })
        this.changeAnimation({ index: 1, value: 'walk' })

        this.$refs.animationModal[0].changeCamera()
        this.$refs.animationModal[1].changeCamera()
      }
      // if (this.themeOn && this.$refs.animationModal && !this.$refs.animationModal[0].isProcessing && !this.$refs.animationModal[1].isProcessing) {
      //   if (this.currentTheme === 'forest') {
      //     this.allAnimationFile[0].rotationAngle = -0.6
      //     this.allAnimationFile[1].rotationAngle = 0.6

      //     this.changeAnimation({ index: 0, value: 'walk' })
      //     this.changeAnimation({ index: 1, value: 'walk' })

      //     this.$refs.animationModal[0].changeCamera()
      //     this.$refs.animationModal[1].changeCamera()
      //   } else {
      //     const newCharacters = this.getRandomCharacters(ALL_KIDS_CHARACTERS, 2)
      //     console.log('change characters', newCharacters)
      //     this.allAnimationFile[0].character = newCharacters[0].name
      //     this.allAnimationFile[0].file = newCharacters[0].file
      //     // TODO:: rotate the characters and add walking action like above
      //     // karen's rotation angle is different than other so slightly changed
      //     if (newCharacters[0].name === 'karen') {
      //       this.allAnimationFile[0].rotationAngle = 1.8
      //     } else {
      //       this.allAnimationFile[0].rotationAngle = 0.6
      //     }
      //     this.allAnimationFile[1].character = newCharacters[1].name
      //     this.allAnimationFile[1].file = newCharacters[1].file
      //     if (newCharacters[1].name === 'karen') {
      //       this.allAnimationFile[1].rotationAngle = 0.8
      //     } else {
      //       this.allAnimationFile[1].rotationAngle = -0.6
      //     }
      //   }
      //   this.$forceUpdate()
      // }
    },
    themeOn() {
      if (this.$refs.animationModal?.[0]) {
        this.$refs.animationModal[0].clearSceneAndInit()
      }
      if (this.$refs.animationModal[1]) {
        this.$refs.animationModal[1].clearSceneAndInit()
      }
    },
    // currentTheme: {
    //   handler(value) {
    //     if (value !== 'forest') {
    //       // TODO:: move on next page page
    //       // this.allAnimationFile = [KIDS_CHARACTERS[KIDS_CHARACTERS.length - 1]]
    //       this.allAnimationFile = KIDS_CHARACTERS
    //       this.animalAnimationTypes = KIDS_CHARACTERS_ANIMATIONS
    //       // this.changeAnimation({ index: 0, value: 'lookingDownNudging' })
    //       // this.changeAnimation({ index: 1, value: 'lookingDownNudging' })
    //       this.$forceUpdate()
    //     } else {
    //       this.allAnimationFile = FOREST_CHARACTERS
    //     }
    //   },
    //   immediate: true,
    // },
    // selectedCharacter: {
    //   handler(value) {
    //     if (this.currentTheme !== 'forest') {
    //       console.log(this.selectedCharacter)
    //       this.allAnimationFile = KIDS_CHARACTERS
    //       this.allAnimationFile[0].character = value
    //       this.allAnimationFile[0].file = `/animations/animals/${value}_.glb`
    //       if (this.selectedCharacter === 'karen') {
    //         this.allAnimationFile[0].rotationAngle = 1.8
    //       } else {
    //         this.allAnimationFile[0].rotationAngle = 0.5
    //       }
    //       this.animalAnimationTypes = KIDS_CHARACTERS_ANIMATIONS
    //       this.changeAnimation({ index: 0, value: 'lookingLeg' })
    //       this.$forceUpdate()
    //     } else {
    //       this.allAnimationFile = FOREST_CHARACTERS
    //     }
    //   },
    //   immediate: true,
    // },
  },
  mounted() {
    if (this.isAStudent) {
      this.checkOutfitsForAllCharacters()
      this.getDefaultFloatingChat()
    }
  },
  methods: {
    getDefaultFloatingChat() {
      useJwt.getDefaultChatOfUser().then(response => {
        const chatResponse = response.data.data.data
        chatResponse.contact = {
          ...chatResponse.contact,
          fullName: `${chatResponse.contact.firstname} ${chatResponse.contact.lastname}`,
        }
        chatResponse.chat = chatResponse.chat.map(c => ({
          message: c.message,
          time: c.created_at,
          senderId: c.send_by,
          type: c.type,
          sent: true,
          recieved: c.viewed_at !== null,
          id: c.id,
        }))
        this.studentFloatingChat = {
          chatData: chatResponse,
          contacts: [],
          profile: response.data.data.profile,
        }
      })
    },
    playSound(animal) {
      const audio = new Audio(animal.sound)
      audio.play()
    },
    showCustomizationModal(index) {
      this.customizingAnimationFile = this.allAnimationFile[index]
      console.log(this.allAnimationFile[index])
      this.$nextTick(() => {
        this.showModelChangeModal = true
      })
    },
    changeAnimation({ index, value }) {
      // console.log('change animation', value)
      this.allAnimationFile[index].currentAnimation = value
    },

    changedAnimationModel(data) {
      // console.log(data)
      this.allAnimationFile[data.index].file = data.file
      this.allAnimationFile[data.index].rotationAngle = data.rotationAngle
      setTimeout(() => {
        this.allAnimationFile[data.index].character = data.name
        this.allAnimationFile[data.index].sound = data.sound
      }, 1000)
      setTimeout(() => {
        this.changeAnimation({ index: data.index, value: 'idle' })
      }, 8000)
    },

    updateSelectedOutfits() {
      // const data = {
      //   selected_outfits: JSON.stringify(this.selectedOutfits),
      // }
      // useJwt.updateSelectedOutfits(data).then(() => {
      // })
      this.$store.commit('appConfig/UPDATE_SELECTED_OUTFITS', this.selectedOutfits)
    },

    getCharacterName() {
      this.allAnimationFile.forEach((animationFile, index) => {
        const defaultCharacter = (animationFile.name === 'left-animal') ? 'elephant' : 'lion'
        const name = localStorage.getItem(animationFile.name) || defaultCharacter
        this.allAnimationFile[index].name = name
        this.allAnimationFile.file = `/animations/animals/${name}_.glb`
      })
      this.isProcessing = false
    },

    checkOutfitsForAllCharacters() {
      this.allAnimationCharacters.forEach(char => {
        if (!this.selectedOutfits[char.name]) {
          this.selectedOutfits[char.name] = {}
        }
      })

      // this.updateSelectedOutfits()
    },
    // getRandomCharacters(arr, num) {
    //   const shuffled = arr.sort(() => 0.5 - Math.random())
    //   return shuffled.slice(0, num)
    // },
  },

}
</script>
