<template>
  <div>
    <div class="row">
      <div class="col-3">
        <b-card v-for="prob, index of problemData"
                :key="index"
        >
          <b-form-group :label="$t('labels.create-problem.problem-name')">
            <b-form-input v-model.lazy="prob.name" />
          </b-form-group>
          <b-form-group :label="$t('labels.create-problem.type')">
            <h4>{{ problemTypes.find(i => i.value === prob.type)?.text }}</h4>
          </b-form-group>
          <b-form-group :label="$t('actions.language')">
            <b-form-select v-model="prob.lang"
                           :options="availableLanguage"
            />
          </b-form-group>
          <div>
            <b-button variant="outline-danger"
                      size="sm"
                      @click="removeProblem(index)"
            >
              {{ $t('image-library-module.remove') }}
            </b-button>
          </div>
        </b-card>
        <div>

          <!-- primary -->
          <b-dropdown
            :text="$t('image-library-module.add-more')"
            variant="primary"
          >
            <b-dropdown-item @click="addMoreProblem('multiple')">
              {{ $t('problem-types.multiple-choice') }}
            </b-dropdown-item>
            <b-dropdown-item @click="addMoreProblem('matching')">
              {{ $t('matching') }}
            </b-dropdown-item>
          </b-dropdown>
          <b-button variant="outline-success"
                    class="ml-2"
                    :disabled="showPreview"
                    @click="showPreview = true;"
          >
            <b-spinner v-if="showPreview"
                       small
            />
            <feather-icon v-else
                          icon="EyeIcon"
            /> {{ $t('preview-problem') }}
          </b-button>
        </div>
      </div>
      <div class="col-9">
        <div
          class="canvas-container"
        >
          <canvas :id="canvasPdfId"
                  :height="canvasInfo.height"
                  :width="canvasInfo.width"
          />
          <template v-for="bounding, index of problemData">
            <BoundingBox :key="index"
                         :coordinate="bounding"
                         :parent-height="canvasInfo.height"
                         :parent-width="canvasInfo.width"
                         @remove="removeProblem(index)"
            />
          </template>
        </div>
      </div>
    </div>
    <OcrPreview v-if="showPreview"
                :problem-data="problemData"
                :canvas-pdf-id="canvasPdfId"
                :lesson-id="lessonId"
                @onPreviewed="isShowingPreview = false"
                @close="showPreview = false"
    />
  </div>
</template>
<script setup>
import { onMounted, reactive, ref } from 'vue'
import {
  BCard, BButton, BFormGroup, BFormInput, BDropdown, BDropdownItem, BFormSelect, BSpinner,
} from 'bootstrap-vue'
import BoundingBox from './components/BoundingBox.vue'
import OcrPreview from './components/OcrPreview.vue'
import { generateProblemData } from './services/problem-data'

const props = defineProps({
  pageNumber: {
    type: Number,
    required: true,
  },
  renderPDF: {
    type: Function,
    required: true,
  },
  lessonId: {
    type: [Number, Object],
    default: null,
  },
})
const canvasPdfId = `pdf-canvas-${props.pageNumber}`
const canvasInfo = reactive({
  height: 700,
  width: 1000,
})

onMounted(() => {
  props.renderPDF(canvasPdfId, props.pageNumber, ({ height, width }) => {
    canvasInfo.height = height
    canvasInfo.width = width
  })
})

const problemData = ref([
  {
    name: `Problem 1-${props.pageNumber}`,
    lang: 'eng',
    x: 35,
    y: 153,
    width: 247,
    height: 320,
    element: null,
    type: 'multiple',
    children: [{
      x: 8,
      y: 50,
      width: 218,
      height: 50,
      type: 'question',
      element: null,
    },
    {
      x: 7,
      y: 109,
      width: 218,
      height: 54,
      type: 'answer',
      element: null,
    },
    ],
  },
])

const addMoreProblem = type => {
  problemData.value.push(
    generateProblemData({
      type,
      problemData,
    }),
  )
}

const showPreview = ref(false)
const removeProblem = index => {
  problemData.value.splice(index, 1)
}

const problemTypes = [
  {
    text: 'Multiple Choice',
    value: 'multiple',
  },
  {
    text: 'Matching Problem',
    value: 'matching',
  },
]

const availableLanguage = [
  {
    text: 'English',
    value: 'eng',
  },
  {
    text: 'Japanese',
    value: 'jpn',
  },
  {
    text: 'Nepali',
    value: 'nep',
  },
]
</script>
  <style scoped>
  .canvas-container {
      position: relative;
      height: fit-content;
      overflow-x: scroll;
      /* width: 100%; */
      /* margin-left: 100px; */
  }

  canvas {
      border: 2px solid #000;
      overflow-y: scroll;
      /* width: 100%; */
  }

  .bounding-box {
      position: absolute;
      border: 2px solid red;
      cursor: move;
      overflow: hidden;
  }

  .resize-handle {
      position: absolute;
      width: 10px !important;
      height: 10px !important;
      background-color: #f3f3f3;
      bottom: 0;
      right: 0;
      cursor: se-resize;
  }
  </style>
