<template>
  <b-tabs>
    <b-tab
      v-for="report of reportType"
      :key="report.value"
      :title="report.text"
      lazy
      @click="selectedReport = report.value"
    >
      <component
        :is="report.component"
        :class-room="classRoom"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  VBTooltip, BTabs, BTab,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import SkillReport from '../GroupReport/SkillReport.vue'

export default {
  components: {
    SkillReport,
    BTabs,
    BTab,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    classRoom: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedReport: 'skill-report',
      reportType: [
        {
          text: i18n.tc('report.skill-report'),
          value: 'skill-report',
          component: 'SkillReport',
        },
      ],
    }
  },
}
</script>
