<template>
  <div>
    <!-- Issues Table -->
    <b-overlay :show="isLoading">
      <b-table
        id="issuestable"
        :items="issues"
        :fields="isATeacher ? filteredFields : fields"
        :busy="isLoading"
        :style="colCSS"
        :empty-text="$t('issue-report-module.no-issues-yet')"
        show-empty
        responsive
      >
        <template #cell(title)="data">
          {{ data.item.title }}
          <span v-if="data.item.comments_count>0"
                v-b-tooltip.hover.top="$t('issue-comment-module.title')"
                class="badge mr-1 ml-auto badge-light-primary badge-pill"
          >{{ data.item.comments_count }}</span>
        </template>
        <!-- Status -->
        <template #cell(status)="data">
          <b-form-select
            v-if="isASuper || isACurriculum"
            v-model="data.item.status"
            :options="statusOptions"
            @change="handleChange(data.item, 'status')"
          />
          <span v-else>{{ getStatus(data.item.status) }}</span>
        </template>

        <!-- Assign To -->
        <template #cell(assigned_to)="data">
          <b-form-input
            v-model="data.item.assigned_to"
            type="text"
            placeholder="Assign to"
            @change="handleChange(data.item, 'assigned_to')"
          />
        </template>

        <!-- Assign To -->
        <template #cell(reported_by)="data">
          {{ data.item.issue_reported_by?.firstname }} {{ data.item.issue_reported_by?.lastname }}
        </template>

        <!-- View Details -->
        <template #cell(action)="data">
          <b-button
            variant="outline-primary"
            @click="showIssueModal(data.item)"
          >
            <feather-icon
              icon="EyeIcon"
            />
          </b-button>
        </template>
      </b-table>

    </b-overlay>
    <b-pagination
      v-if="rows > perPage"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      :disabled="isLoading"
      aria-controls="issuesTable"
      @input="getIssues()"
    />
    <!-- Report Detaul -->
    <report-detail-modal
      v-if="selectedItem"
      :item="selectedItem"
      :status-options="statusOptions"
      :is-a-super="isASuper"
      :self="self"
      @update="update"
      @close="selectedItem = null"
    />
  </div>
</template>
<script>
import {
  BTable, BOverlay, BPagination, BFormInput, BFormSelect, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import {
  USER_TYPE_SUPER, USER_TYPE_TEACHER, USER_TYPE_CURRICULUM,
} from '@/const/userType'
import moment from 'moment'
import i18n from '@/libs/i18n'
import ReportDetailModal from './ReportDetailModel.vue'

export default {
  name: 'IssueListTable',
  components: {
    BTable,
    BOverlay,
    BPagination,
    BFormInput,
    BFormSelect,
    ReportDetailModal,
    BButton,
  },
  props: {
    status: {
      type: String,
      default: '',
    },
    libraryId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      self: getUserData(),
      issues: [],
      fields: [
        {
          label: i18n.tc('issue-report-module.title'),
          key: 'title',
          tdClass: 'title',
        },
        {
          label: i18n.tc('issue-report-module.description'),
          key: 'description',
          tdClass: 'description',
        },
        {
          label: i18n.tc('issue-report-module.error-type'),
          key: 'error_type',
          tdClass: 'error-type',
        },
        {
          label: i18n.tc('issue-report-module.reported-by'),
          key: 'reported_by',
          tdClass: 'assign',
          adminOnly: true,
        },
        {
          label: i18n.tc('issue-report-module.pending'),
          key: 'status',
          tdClass: 'status',
        },
        {
          label: i18n.tc('issue-report-module.assign-to'),
          key: 'assigned_to',
          tdClass: 'assign',
          adminOnly: true,
        },

        {
          label: i18n.tc('issue-report-module.view-details'),
          key: 'action',
          tdClass: 'action',
        },
      ],
      statusOptions: [
        {
          text: i18n.tc('issue-report-module.pending'),
          value: 'pending',
        },
        {
          text: i18n.tc('issue-report-module.in_progress'),
          value: 'in_progress',
        },
        {
          text: i18n.tc('issue-report-module.done'),
          value: 'done',
        },
      ],
      isLoading: false,
      currentPage: 1,
      perPage: 10,
      rows: null,
      selectedItem: null,
    }
  },
  computed: {
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
    isASuper() {
      return this.self.usertype === USER_TYPE_SUPER
    },
    isACurriculum() {
      return this.self.usertype === USER_TYPE_CURRICULUM
    },
    filteredFields() {
      return this.fields.filter(field => !field.adminOnly)
    },
    colCSS() {
      if (this.isATeacher) {
        return {
          '--status-width': '10%',
          '--action-width': '15%',
        }
      }
      return {
        '--status-width': '15%',
      }
    },
  },
  mounted() {
    this.getIssues()
  },
  methods: {
    activeNotificationItem() {
      if (this.$route.query.target_item) {
        this.selectedItem = this.issues.find(i => +i.id === +this.$route.query.target_item)
        this.$nextTick(() => { this.$bvModal.show('report-details') })
      }
    },
    getIssues() {
      this.isLoading = true
      let api = 'getIssues'
      if (this.isASuper) api = 'getAllIssues'

      useJwt[api](Number(this.currentPage), this.isATeacher ? this.self.id : '', {
        params: {
          status: this.status,
          library_id: this.libraryId,
        },
      })
        .then(response => {
          const data = response.data?.data
          this.issues = data.data.map(item => ({ ...item, _rowVariant: !item.seen_by && 'info' }))
          this.rows = data.total
          this.activeNotificationItem()
        }).finally(() => { this.isLoading = false })
    },
    update(field) {
      const params = {
        field,
        value: this.selectedItem[field],
      }

      useJwt.updateIssue(this.selectedItem.id, [params]).then(response => {
        this.showSuccessMessage(response)
        this.issues = this.issues.filter(item => item.status === this.status)
        if (field === 'status') {
          if (params.value === 'done') this.selectedItem.solved_at = moment()
          else this.selectedItem.solved_at = null
        }
      })
    },
    handleChange(item, field) {
      this.selectedItem = item
      this.$nextTick(() => {
        this.update(field)
      })
    },
    rowClass(item) {
      return item.status
    },
    getStatus(status) {
      return this.statusOptions.find(option => option.value === status).text
    },
    showIssueModal(item) {
      this.selectedItem = item
      this.$nextTick(() => { this.$bvModal.show('report-details') })
      useJwt.updateIssueSeen(item.id).then(() => {
        // eslint-disable-next-line no-underscore-dangle, no-param-reassign
        item._rowVariant = null
        this.$root.$emit('reduceSeenIssueReportCount')
      })
    },
  },
}
</script>
