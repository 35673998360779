import {
  MeetingHandler,
  MeetingType,
  VaniEvent,
} from 'vani-meeting-client'
import EventEmitter from 'events'

class ChatHandler {
  static instance = new ChatHandler();

  meetingHandler = new MeetingHandler();

  meetingRequest;

  eventEmitter;

  isAdmin;

  roomId;

  messages;

  static getInstance() {
    if (ChatHandler.instance === null) {
      ChatHandler.instance = new ChatHandler()
    }
    return ChatHandler.instance
  }

  constructor() {
    this.meetingRequest = undefined
    this.eventEmitter = new EventEmitter()
    this.isAdmin = false
    this.roomId = null
    this.messages = []
    this.getMeetingRequest = this.getMeetingRequest.bind(this)
    this.sendMessage = this.sendMessage.bind(this)
    this.cleanUp = this.cleanUp.bind(this)
    this.onNewChatMessageReceived = this.onNewChatMessageReceived.bind(this)
    this.onInitDone = this.onInitDone.bind(this)
    this.onSocketConnected = this.onSocketConnected.bind(this)
  }

  cleanUp() {
    this.unregisterOfEvents()
    this.getMeetingHandler().endAndDestory()
    this.meetingRequest = undefined
    this.messages = []
    this.eventEmitter.removeAllListeners()
  }

  setup(userId, userData) {
    if (!this.meetingRequest || this.meetingRequest == null) {
      this.meetingRequest = this.getMeetingHandler().meetingStartRequestObject(
        'gem_chat_room',
        userId,
        'GEC',
        process.env.VUE_APP_WSS_URL,
      )
      this.meetingRequest.numberOfUsers = 2
      this.meetingRequest.userData = userData
      this.meetingRequest.isAdmin = this.isAdmin
      this.meetingRequest.videoCaptureHeight = 480
      this.meetingRequest.videoCaptureWidth = 850
      this.meetingRequest.meetingType = MeetingType.CHAT
      this.registerOfEvents()
      this.getMeetingHandler().init()
    }
  }

  registerOfEvents() {
    this.getMeetingHandler().getEventEmitter().setMaxListeners(10000)
    this.getMeetingHandler().getEventEmitter().on(VaniEvent.OnInitDone, this.onInitDone)
    this.getMeetingHandler().getEventEmitter().on(VaniEvent.OnSocketConnected, this.onSocketConnected)
  }

  unregisterOfEvents() {
    this.getMeetingHandler().getEventEmitter().off(VaniEvent.OnInitDone, this.onInitDone)
    this.getMeetingHandler().getEventEmitter().off(VaniEvent.OnSocketConnected, this.onSocketConnected)
  }

  // Events handlers

  onInitDone() {
    this.getMeetingHandler().checkSocket()
  }

  onSocketConnected() {
    this.getMeetingHandler().startMeeting()
  }
  /// /////////////////////////////////////////////////////////////

  // Check Online offline
  getUserByUserId(userId) {
    const participantById = this.getMeetingHandler().getAllParticipants().filter(participant => participant.userData.id === userId)
    return participantById
  }

  ///
  onNewChatMessageReceived(messagePayload) {
    this.messages.push(messagePayload)
    this.eventEmitter.emit('onMessageListUpdated', this.messages)
  }

  sendMessage(messagePayload) {
    this.meetingHandler.sendMessage(messagePayload)
    this.onNewChatMessageReceived(messagePayload)
  }

  getMeetingHandler() {
    return this.meetingHandler
  }

  getMeetingRequest() {
    return this.meetingRequest
  }
}

export default ChatHandler
