import router from '@/router'
// eslint-disable-next-line object-curly-newline
import { reactive, getCurrentInstance, watch, toRefs } from 'vue'
import moment from 'moment'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

// interval in minutes
export const createTimeInterval = interval => {
  // Define the start and end times
  const startTime = new Date()
  startTime.setHours(8, 0, 0) // 8:00 AM

  const endTime = new Date()
  endTime.setHours(21, 0, 0) // 9:00 PM

  // Create an array to store the time intervals
  const timeIntervals = []

  // Initialize a variable with the start time
  let currentTime = new Date(startTime)

  // Loop to generate time intervals
  while (currentTime <= endTime) {
    const intervalEndTime = new Date(currentTime)
    intervalEndTime.setMinutes(currentTime.getMinutes() + interval)

    timeIntervals.push({
      start_time: moment(currentTime).format('HH:mm:00'),
      end_time: moment(intervalEndTime).format('HH:mm:00'),
    })

    currentTime = intervalEndTime
  }

  return timeIntervals
}

export const weeklySchedules = (defaultValue = 'any', interval = 20, limit = 7) => {
  const slots = defaultValue ? [{
    text: 'Any',
    value: defaultValue,
  }] : []
  const timeFrom = moment('2021-04-05 8:00:00 AM')
  const minutesToAdd = interval

  do {
    const startTime = timeFrom.format('h:mm A')
    const endTime = timeFrom.add(minutesToAdd, 'minutes').format('h:mm A')
    slots.push({
      value: `${startTime}-${endTime}`,
      text: `${startTime}-${endTime}`,
    })
  } while (timeFrom.format('YYYY-MM-DD') === '2021-04-05')

  const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  return weekDays.slice(0, limit).map(() => ({
    day: null,
    selected: null,
    slots,
  }))
}

/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }
