<template>
  <b-modal
    id="ballonShooter"
    ok-only
    no-close-on-backdrop
    :visible="true"
    ok-title="Accept"
    body-class="text-center"
    size="lg"
    hide-footer
    @close="stopPendingGame"
  >
    <template #modal-title>
      Balloon Shooter <feather-icon
        :icon="isMuted ? 'VolumeXIcon' : 'Volume2Icon' "
        size="20"
        role="button"
        @click="$emit('toggleSound')"
      />
    </template>
    <b-spinner
      v-if="isProcessing"
      type="grow"
      class="text-center"
      label="Loading..."
    />
    <iframe
      id="gameFrameContent"
      style="height: 80vh;width: 100%;"
      frameBorder="0"
    />
  </b-modal>

</template>
<script>
import { BModal, BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BSpinner,
  },
  props: {
    isMuted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isProcessing: true,
    }
  },
  async created() {
    const scripts = [
      '/shoot-balloons/scripts/supportcheck.js',
      '/shoot-balloons/scripts/offlineclient.js',
      '/shoot-balloons/scripts/main.js',
      '/shoot-balloons/scripts/register-sw.js',
    ]
    let html = ' <div id="gameContainer" />'
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < scripts.length; i++) {
      html += `<script src="${scripts[i]}"></`
      html += 'script>'
    }
    setTimeout(() => {
      const iframe = document.getElementById('gameFrameContent')
      iframe.contentWindow.document.open()
      iframe.contentWindow.document.write(html)
      iframe.contentWindow.document.close()
      iframe.contentWindow.document.addEventListener('ballonShooterGameOver', () => {
        this.$emit('gameOver')
      })
      this.isProcessing = false
    }, 1000)
  },
  methods: {
    stopPendingGame() {
      this.$emit('gameOver')
    },
  },
}
</script>
<style>
#pacRusher .modal-header .close {
  display:none;
}
</style>
