<template>
  <b-modal
    :visible="show"
    :title="title"
    size="lg"
    no-close-on-backdrop
    @hide="$emit('close')"
    @close="$emit('close')"
  >
    <validation-observer ref="formProvider">
      <b-form-group :label="`${$t('reservation-moduel.name')}`">
        <validation-provider
          v-slot="{ errors }"
          name="name"
          rules="required"
        >
          <b-form-input
            v-model="form.name"
            :placeholder="`${$t('reservation-moduel.name')}`"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-row>
        <b-col md="6">
          <b-form-group
            :label="$t('marketplace-module.start_time')"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="$t('marketplace-module.start_time')"
              rules="required"
            >
              <b-form-datepicker
                v-model="form.start_date"
                :placeholder="$t('higher-level-report-module.no-date-selected')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('marketplace-module.end_time')"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="$t('marketplace-module.end_time')"
              rules="required"
            >
              <b-form-datepicker
                v-model="form.end_date"
                :placeholder="$t('higher-level-report-module.no-date-selected')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="`${$t('reservation-moduel.type')}`">
            <validation-provider
              v-slot="{ errors }"
              name="Type"
              rules="required"
            >
              <b-form-select
                v-model="form.type"
                :options="scheduledType"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="`${$t('reservation-moduel.student_limit')}`">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('reservation-moduel.student_limit')"
              rules="required"
            >
              <b-form-input
                v-model.number="form.student_limit"
                :placeholder="$t('reservation-moduel.student_limit')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="5">
          <b-form-group :label="`${$t('reservation-moduel.school_user')}`">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('reservation-moduel.school')"
              rules="required"
            >
              <v-select
                v-model="form.school_id"
                :placeholder="$t('reservation-moduel.school-lists')"
                :options="schoolLists"
                :clearable="false"
                label="fullname"
                :reduce="item => item.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- reservation types -->
      <b-row v-if="form.type === 'scheduled'">
        <b-col cols="2">
          <b-form-group :label="`${$t('reservation-moduel.age_group')}`">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('reservation-moduel.age_group')"
              rules="required"
            >
              <b-form-select
                v-model="form.age_group"
                :options="ageGroups"
                @change="getAgeGroups"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group :label="`${$t('reservation-moduel.min_age')}`">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('reservation-moduel.min_age')"
            >
              <b-form-input
                v-model.number="form.min_age"
                :placeholder="$t('reservation-moduel.min_age')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group :label="`${$t('reservation-moduel.max_age')}`">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('reservation-moduel.max_age')"
            >
              <b-form-input
                v-model.number="form.max_age"
                :placeholder="$t('reservation-moduel.max_age')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="`${$t('reservation-moduel.working_days')}`">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('reservation-moduel.working_days')"
              rules="required"
            >
              <b-form-select
                v-model.number="form.working_days"
                :placeholder="$t('reservation-moduel.working_days')"
                :options="daysLists"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="`${$t('reservation-moduel.time_interval')}`">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('reservation-moduel.time-interval-validation')"
            >
              <b-form-input
                v-model.number="form.time_interval"
                :placeholder="$t('reservation-moduel.time_interval')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

      </b-row>

      <b-row v-else>
        <b-col md="4">
          <b-form-group :label="`${$t('reservation-moduel.payment_gateway')}`">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('reservation-moduel.payment_gateway')"
              rules="required"
            >
              <b-form-select
                v-model="form.payment_gateway"
                :options="paymentGateWayTypes"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col v-if="isShopifyPayment"
               md="8"
        >
          <b-row>
            <b-col md="6">
              <b-form-group :label="`${$t('reservation-moduel.payment_gateway_url')}`">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('reservation-moduel.payment_gateway_url')"
                  rules="required"
                >
                  <b-form-input
                    v-model="form.payment_gateway_url"
                    :placeholder="$t('reservation-moduel.payment_gateway_url')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="`${$t('reservation-moduel.shopify_product')}`">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('reservation-moduel.shopify_product')"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input v-model="form.product_info.name"
                                  disabled
                    />
                    <b-input-group-append>
                      <b-button variant="outline-primary"
                                @click="showShopifyProducts = true"
                      >
                        {{ $t('actions.update') }}
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <p class="text-muted"
                 style="font-size:13px; font-style: italic;"
              >
                <feather-icon icon="InfoIcon" />
                {{ $t('reservation-moduel.info') }}
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          class="mb-2"
        >
          <br>
          <b-button
            v-if="!isShopifyPayment"
            variant="outline-primary"
            @click="showTrialPaid = true"
          >
            <feather-icon icon="SettingsIcon" />{{ $t('reservation-moduel.payment-setting') }}
          </b-button>
          <b-button
            v-else
            variant="outline-primary"
            @click="showReservationSchedule = true"
          >
            <feather-icon icon="CalendarIcon" /> {{ $t('reservation-moduel.reservation-schedule') }}
          </b-button>
        </b-col>
      </b-row>
      <b-form-group
        :label="$t('marketplace-module.descriptions')"
      >
        <b-form-textarea
          v-model="form.description"
          :placeholder="$t('reservation-moduel.program-descriptions')"
        />
      </b-form-group>
      <b-form-group
        :label="$t('reservation-moduel.calendar_template')"
      >
        <validation-provider
          v-slot="{ errors }"
          name="Calendar Template"
          rules="required"
        >
          <v-select
            v-model="form.template_id"
            :placeholder="$t('template-lists')"
            :options="calendarTemplateLists"
            :clearable="false"
            label="name"
            :reduce="item => item.id"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <div v-if="reservation && reservation.school"
           :set="reservationLink = isShopifyPayment ? `${locationDomain}/reservations/${reservation.school.id}/${reservation.id}` : `${locationDomain}/reservations/book/${reservation.school.id}`"
      >
        <feather-icon icon="CopyIcon"
                      role="button"
                      @click="copyTextToClipboard(reservationLink)"
        />
        <a
          :href="reservationLink"
          target="_blank"
        >
          {{ $t('reservation-moduel.link_to_program') }}
        </a>
      </div>
    </validation-observer>
    <template #modal-footer="{}">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            class="mr-2"
            @click="$emit('close')"
          >
            {{ $t('labels.cancel') }}
          </b-button>
          <b-button
            variant="success"
            :disabled="isProcessing"
            @click="handleForm"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t('actions.save') }}
          </b-button>
        </b-col>
      </b-row>
    </template>
    <UpdateShopifyProducts v-if="showShopifyProducts"
                           :reservation="reservation"
                           @close="showShopifyProducts = false"
                           @updateProduct="updateProduct"
    />
    <TrailPaidManagement v-if="showTrialPaid"
                         :reservation="form"
                         @close="showTrialPaid = false"
                         @onUpdateAccessType="updateAccessType"
    />
    <ReservationSchedule v-if="showReservationSchedule"
                         :reservation="form"
                         @updateSchedule="handleUpdateSchedule"
                         @removeSchedule="handleRemoveSchedule"
                         @close="showReservationSchedule = false"
    />
  </b-modal>
</template>
<script>
import {
  BModal, BFormGroup, BFormInput, BRow, BCol, BSpinner, BButton, BFormSelect, BFormDatepicker, BFormTextarea, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import UpdateShopifyProducts from './update-shopify-products.vue'
import TrailPaidManagement from './trial-paid/index.vue'
import ReservationSchedule from './ReservationSchedule.vue'

const initialForm = () => ({
  name: '',
  school_id: null,
  student_limit: 1,
  age_group: 'early-bird',
  days: '',
  status: 'open',
  min_age: '',
  max_age: '',
  price: '',
  description: '',
  type: 'scheduled',
  template_id: null,
  payment_gateway: 'none',
  payment_gateway_url: null,
  schedules: [],
  access_types: [],
  product_info: {
    id: null,
    product_name: '',
  },
})
export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    vSelect,
    BSpinner,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormDatepicker,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    UpdateShopifyProducts,
    TrailPaidManagement,
    ReservationSchedule,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    reservation: {
      type: Object,
      default: () => null,
    },
    ageGroups: {
      type: Array,
      default: () => [],
    },
    schoolLists: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      calendarTemplateLists: [],
      isProcessing: false,
      showReservationSchedule: false,
      form: {
        ...initialForm(),
      },
      statusList: ['open', 'closed'],
      daysLists: Array.from({ length: 7 }, (_, i) => i + 1),
      required,
      locationDomain: window.location.origin,
      showShopifyProducts: false,
      showTrialPaid: false,
      paymentGateWayTypes: [
        {
          text: i18n.tc('reservation-moduel.stripe'),
          value: 'stripe',
        },
        {
          text: i18n.tc('reservation-moduel.shopify'),
          value: 'shopify',
        },
      ],
      scheduledType: [
        {
          text: i18n.tc('reservation-moduel.scheduled'),
          value: 'scheduled',
        },
        {
          text: i18n.tc('reservation-moduel.non-scheduled'),
          value: 'non-scheduled',
        },
      ],
    }
  },
  computed: {
    title() {
      return this.reservation ? i18n.tc('update-program') : i18n.tc('manage-program')
    },
    isShopifyPayment() {
      return this.form.payment_gateway && this.form.payment_gateway === 'shopify'
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    reservation(newValue) {
      if (newValue) {
        this.form = {
          ...newValue,
          product_info: newValue.shopify_product || {},
        }
      } else {
        this.form = {
          ...initialForm(),
          product_info: newValue.shopify_product || {},
        }
      }
    },
  },
  created() {
    // this.getSchoolUsers()
    this.getCalendarTemplate()
  },
  methods: {
    handleRemoveSchedule(index) {
      this.reservation.schedules.splice(index, 1)
    },
    handleUpdateSchedule(value, index) {
      if (Number.isInteger(index)) {
        this.reservation.schedules[index] = value
      } else {
        this.reservation.schedules.push(value)
      }
      this.$forceUpdate()
    },
    updateAccessType(accessType) {
      this.form.access_types = accessType
    },
    updateProduct(product) {
      this.form = {
        ...this.form,
        product_info: product,
      }
    },
    getAgeGroups() {
      let minAge = 0
      let maxAge = 0
      switch (this.form.age_group) {
        case 'g-k-2':
          minAge = 0
          maxAge = 2
          break
        case 'g-3-5':
          minAge = 3
          maxAge = 5
          break
        case 'g-6-8':
          minAge = 6
          maxAge = 8
          break
        case 'g-9-12':
          minAge = 9
          maxAge = 12
          break
        case 'university':
          minAge = 13
          maxAge = 100
          break
        default:
          minAge = 25
          maxAge = 100
          break
      }
      this.form.min_age = minAge
      this.form.max_age = maxAge
    },
    // getSchoolUsers() {
    //   useApollo
    //     .getSchoolUsers()
    //     .then(response => {
    //       const res = response.data?.schools?.data
    //       this.schoolLists = res.map(item => ({ ...item, id: Number(item.id) }))
    //       if (!this.form.school_id) {
    //         this.form.school_id = this.schoolLists[0]?.id
    //       }
    //     })
    //     .finally(() => {
    //       this.schoolProcessing = false
    //     })
    // },
    getCalendarTemplate() {
      useJwt.getCalendarTemplate({
        params: {
          type: 'public',
        },
      }).then(response => {
        this.calendarTemplateLists = response.data.data.template
      })
    },
    create() {
      this.isProcessing = true
      useJwt.storeReservations(this.form).then(response => {
        this.$emit('onUpdate')
        this.$emit('close')
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    async update() {
      if (this.form.status === 'closed') {
        const confirm = await this.$swal.fire({
          title: 'Are you certain you wish to close the reservation? This action will also deactivate the classroom for Shopify users.',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Yes, close',
          denyButtonText: 'Don\'t save',
        })
        if (!confirm.isConfirmed) {
          return
        }
      }
      this.isProcessing = true
      useJwt.updateReservations(this.reservation.id, this.form).then(response => {
        this.$emit('onUpdate')
        this.$emit('close')
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    handleForm() {
      this.$refs.formProvider.validate().then(success => {
        if (success) {
          if (this.reservation) this.update()
          else this.create()
        }
      })
    },
  },
}
</script>
