<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>
        <span v-else-if="props.column.field === 'classroom'">
          {{ props.row.class?.name || 'DELETED' }}
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span class="text-center">
            <b-button
              v-b-tooltip.hover.top="$t('calendar-template.show-events')"
              variant="flat-primary"
              class="btn-icon rounded-circle"
              @click="$emit('showEvent', props.row)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              v-b-tooltip.hover.top="$t('calendar-template.apply-template')"
              variant="flat-success"
              class="btn-icon rounded-circle"
              @click="templateToApply = props.row.id"
            >
              <feather-icon icon="BoxIcon" />
            </b-button>
            <b-button
              v-b-tooltip.hover.top="$t('calendar-template.add-event')"
              variant="flat-primary"
              class="btn-icon rounded-circle"
              @click="$emit('addEvent', props.row)"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
            <b-button
              v-b-modal.template-clone-confirm-modal
              v-b-tooltip.hover.top="$t('calendar-template.clone-template')"
              variant="flat-primary"
              class="btn-icon rounded-circle"
              @click="templateToClone = props.row.id"
            >
              <feather-icon icon="CopyIcon" />
            </b-button>
            <b-button
              v-b-tooltip.hover.top="$t('calendar-template.delete-template')"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="showDeleteModal(props.row.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <apply-template
      :template-to-apply="templateToApply"
      type="isolated"
      @update-event="templateToApply = null"
      @close="templateToApply = null"
    />
    <delete-modal
      name="delete-isolated-template-modal"
      :sub-title="$t('calendar-module.delete-template')"
      :on-delete="deleteTemplate"
    />
    <confirm-modal
      modal="template-clone-confirm-modal"
      :title="$t('calendar-template.clone-template')"
      @on-confirm="cloneTemplate"
    >
      <p>{{ $t('calendar-module.clone-template') }}</p>
    </confirm-modal>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import { VueGoodTable } from 'vue-good-table'
import {
  BFormSelect, BPagination, BButton, VBTooltip,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import ConfirmModal from '@/views/common/components/ConfirmModal.vue'
import GEC_EVENT_BUS from '@/utils/eventBus'
import ApplyTemplate from './ApplyTemplate.vue'

export default {
  components: {
    VueGoodTable,
    BFormSelect,
    BPagination,
    ApplyTemplate,
    BButton,
    DeleteModal,
    ConfirmModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      searchTerm: '',
      rows: [],
      pageLength: 5,
      templateToApply: null,
      columns: [
        {
          label: i18n.tc('labels.name'),
          field: 'name',
        },
        {
          label: i18n.tc('class-module.start-date'),
          field: 'start_date',
        },
        {
          label: i18n.tc('class-module.end-date'),
          field: 'end_date',
        },
        {
          label: i18n.tc('action'),
          field: 'action',
        },
      ],
      selectedTemplate: null,
      templateToClone: null,
    }
  },
  mounted() {
    this.getIsolatedTemplate()
    GEC_EVENT_BUS.$off('fetch-isolate-templates')
    GEC_EVENT_BUS.$on('fetch-isolate-templates', this.getIsolatedTemplate)
  },
  methods: {
    getIsolatedTemplate() {
      useJwt.getCalendarTemplate({
        params: {
          type: 'isolated_all',
        },
      }).then(response => {
        this.rows = response.data.data.template
      })
    },
    showDeleteModal(id) {
      this.selectedTemplate = id
      this.$bvModal.show('delete-isolated-template-modal')
    },
    deleteTemplate() {
      useJwt.deleteCalendarTemplate(this.selectedTemplate).then(response => {
        this.showSuccessMessage(response)
        this.selectedTemplate = null
      }).finally(() => {
        this.$bvModal.hide('delete-isolated-template-modal')
        this.getIsolatedTemplate()
      })
    },
    cloneTemplate() {
      useJwt.cloneCalendarTemplate(this.templateToClone).then(response => {
        this.showSuccessMessage(response)
        this.templateToClone = null
      }).finally(() => {
        this.$bvModal.hide('template-clone-confirm-modal')
        this.getIsolatedTemplate()
      })
    },
  },
}
</script>
