<template>
  <div>
    <b-row class="d-flex align-items-center justify-content-between pt-1">
      <b-col class="d-flex justify-content-end">
        <b-col v-show="isWordnetVisible">
          <h5>
            {{ $t('lesson-pages-module.wordnet') }}
          </h5>
        </b-col>
        <b-button
          variant="primary"
          size="sm"
          :disabled="isProcessing"
          style="height:32px;padding:0 8px;"
          @click.stop="isWordnetVisible=!isWordnetVisible"
        >
          <feather-icon
            :icon="isWordnetVisible ? 'MinusIcon':'PlusIcon'"
            class="mr-50"
          />
          <span class="align-middle">{{ isWordnetVisible ? $t('lesson-pages-module.hide'):$t('lesson-pages-module.get-wordnet') }}</span>
        </b-button>
      </b-col>

    </b-row>

    <b-row>
      <b-col
        v-if="isProcessing"
        md="12"
        class="text-center"
      >
        <b-spinner />
      </b-col>
      <b-col
        v-show="isWordnetVisible && !isProcessing"
        class="wordnet mb-2 p-2"
        md="12"
        sm="12"
        :style="`height:${isWordnetVisible ? 'auto':'0px'}`"
      >
        <a
          v-for="(word,index) in wordnetWords"
          :key="word+index"
          style="text-decoration:underline"
          class="mr-1"
          @click="addWord(word)"
        >{{ word }}
        </a>
      </b-col>
      <b-col
        v-if="!isProcessing"
        md="12"
        sm="12"
        class="row mt-2 pb-1 pl-2"
      >
        <div
          v-for="(word,index) in words"
          v-show="isWordnetVisible"
          :key="word+index"
          class="btn btn-primary mr-1 mb-1 d-flex"
        >
          <div
            class="col-md-10"
            @click="getWord(word)"
          >

            {{ word }}
          </div>
          <span
            class="bg-danger text-white rounded-circle ml-1 p-0"
            size="sm"
            @click="removeWord(word)"
          >
            <feather-icon icon="XIcon" />
          </span>
        </div>
      </b-col>
    </b-row>
    <modal-section
      v-if="activeWord"
      ref="modalSection"
      :active-word="activeWord"
      :selected-wordnets="selectedWordnets"
      @setWordnet="setWordnet"
    />
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ModalSection from './ModalSection.vue'
// import i18n from '@/libs/i18n'

export default {
  components: {
    BButton, BRow, BCol, ModalSection, BSpinner,
  },
  props: {
    lesson: {
      type: Object,
      required: false,
      default: () => {},
    },
    pageId: {
      type: [Number, String],
      required: false,
      default: 1,
    },
    mainText: {
      type: [Object, String],
      required: false,
      default: () => {},
    },
    problemStatementId: {
      type: [Number, String],
      required: false,
      default: () => {},
    },
    type: {
      type: [Object, String],
      required: false,
      default: 'lesson',
    },
  },
  data() {
    return {
      words: [],
      isWordnetVisible: false,
      activeWord: {},
      offsets: [],
      activeTranslation: '',
      activeLanguage: '',
      selectedWordnets: [],
      isProcessing: false,
      avoidedWords: ['data', 'span', 'answer', 'question', 'selectable', 'wrong', 'index', 'class', 'problem'],
    }
  },
  computed: {
    wordnetWords() {
      let mainText = ''
      if (this.type === 'course' || this.type === 'curriculum-builder') {
        mainText = this.mainText
      } else {
        mainText = this.lesson.main_text
      }

      return mainText.replace(/[.*+?,^${}()|[\]\\]/g, '').replace(/\./g, '').replace('&rsquo;', "'").replace('&shy;', '')
        .replace(/(&nbsp;|(<([^>]+)>))/ig, ' ')
        .split(' ')
    },
  },
  mounted() {
    this.getWordNets()
  },
  methods: {
    removeWord(word) {
      this.isProcessing = true
      if (this.type === 'course' && this.problemStatementId) {
        useJwt.deleteCourseWordNet({
          problem_statement_id: this.problemStatementId,
          word,
        }).then(res => {
          if (res.status === 200) {
            this.getWordNets()
            this.showSuccess(res.data.message)
          }
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
      } else {
        useJwt.deleteWordNet({
          page_id: this.pageId,
          word,
        }).then(res => {
          if (res.status === 200) {
            this.getWordNets()
            this.showSuccess(res.data.message)
          }
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
      }
    },
    getWordNets() {
      this.words = []
      if (this.type === 'course' && this.problemStatementId) {
        useJwt.getCourseWordNetsArray(this.problemStatementId).then(res => {
          Object.values(res.data.data).forEach(word => {
            this.words.push(word.word)
          })
        })
      } else {
        useJwt.getWordNetsForPage(this.pageId).then(res => {
          Object.values(res.data.data).forEach(word => {
            this.words.push(word.word)
          })
        })
      }
    },
    addWord(word) {
      this.isProcessing = true
      let existedItem = false
      if (this.avoidedWords.includes(word.toLowerCase())) {
        this.showError(`Wordnet for ${word} not found`)
        this.isProcessing = false
        return
      }
      this.words.forEach(w => {
        if (w.toLowerCase() === word.toLowerCase()) {
          existedItem = true
        }
      })
      if (existedItem) {
        this.showError('Item already exist')
        this.isProcessing = false
      } else if (this.type === 'course' && this.problemStatementId) {
        useJwt.storeCourseWordNet({
          problem_statement_id: this.problemStatementId,
          word,
        }).then(res => {
          if (res.status === 200) {
            this.getWordNets()
            this.showSuccess(res.data.message)
          }
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
      } else {
        useJwt.storeWordNet({
          page_id: this.pageId,
          word,
        }).then(res => {
          if (res.status === 200) {
            this.getWordNets()
            this.showSuccess(res.data.message)
          }
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
      }
    },
    getWord(word) {
      this.isProcessing = true
      this.offsets = []
      this.selectedWordnets = []
      if (this.type === 'course' && this.problemStatementId) {
        useJwt.getCourseWordNet({
          problem_statement_id: this.problemStatementId,
          word,
        }).then(res => {
          if (res.status === 200) {
            this.activeWord = res.data.data
            this.activeWord.wordnet.forEach(w => {
              if (w.selected === 1) {
                this.offsets.push(w.offset)
                this.selectedWordnets.push(w)
              }
            })
          }
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
          this.$refs.modalSection.openModal()
        })
      } else {
        useJwt.getWordNet({
          page_id: this.pageId,
          word,
        }).then(res => {
          if (res.status === 200) {
            this.activeWord = res.data.data
            this.offsets = []
            this.selectedWordnets = []
            this.activeWord.wordnet.forEach(w => {
              if (w.selected === 1) {
                this.offsets.push(w.offset)
                this.selectedWordnets.push(w)
              }
            })
          }
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
          this.$refs.modalSection.openModal()
        })
      }
    },
    setWordnet(payload) {
      if (this.type === 'course' && this.problemStatementId) {
        const request = {
          problem_statement_id: this.problemStatementId,
          wordnet_word_check: payload.offset,
        }
        payload.wordNet.forEach(word => {
          payload.availableLanguage.forEach(lang => {
            request[`${lang}_${word.offset}`] = word[lang]
          })
        })
        useJwt.setCourseWordNetForPage(request).then(res => {
          this.showSuccessMessage(res)
        }).catch(error => {
          this.showErrorMessage(error)
        })
      } else {
        const request = {
          page_id: this.pageId,
          wordnet_word_check: payload.offset,
        }
        payload.wordNet.forEach(word => {
          payload.availableLanguage.forEach(lang => {
            request[`${lang}_${word.offset}`] = word[lang]
          })
        })
        useJwt.setWordNetForPage(request).then(res => {
          this.showSuccessMessage(res)
        }).catch(error => {
          this.showErrorMessage(error)
        })
      }
    },
  },
}
</script>

<style scoped lang="css">
.wordnet{
  transition: all 200ms ease-in-out;
}

</style>
