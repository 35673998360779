<template>
  <b-modal
    :title="`${$t('labels.move')}  Standard`"
    no-fade
    ok-only
    centered
    no-close-on-backdrop
    :visible="show"
    ok-title="Accept"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <validation-observer ref="myForm">
      <b-overlay :show="isLoading">
        <div>

          <validation-provider
            v-slot="{ errors }"
            name="Grade"
            rules="required"
            vid="Grade"
          >
            <label>Course</label>
            <b-form-select
              v-model="gradeId"
              @change="getSubjectForGrade"
            >
              <option
                v-for="grade of [{text: 'Select Course', value: null}, ...grades]"
                :key="grade.id"
                :value="grade.id"
              >
                {{ grade.name }}
              </option>
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <div class="mt-1">
            <validation-provider
              v-slot="{ errors }"
              name="Lesson"
              rules="required"
              vid="Lesson"
            >
              <label>Subjects</label>
              <b-form-select
                v-model="subjectId"
                :disabled="!gradeId"
                @change="getStandardDomain"
              >
                <option
                  v-for="grade of [{text: 'Select Subjects', value: null}, ...subjectList]"
                  :key="grade.id"
                  :value="grade.id"
                >
                  {{ grade.name }}
                </option>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
          <div class="mt-1">
            <validation-provider
              v-slot="{ errors }"
              name="Domain"
              rules="required"
              vid="Domain"
            >
              <label>Domain</label>
              <v-select
                v-model="standardDomain"
                :clearable="false"
                label="text"
                :options="standardDomainOptions"
                :reduce="option => option.value"
                debounce="200"
                :disabled="!subjectId"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </div>
      </b-overlay>
    </validation-observer>
    <template #modal-footer="{ }">
      <div
        class="d-flex justify-content-end"
      >
        <b-button
          variant="primary"
          :disabled="isProcessing"
          @click="submitData"
        >
          <b-spinner
            v-show="isProcessing"
            small
          />
          {{ $t('labels.move') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BFormSelect, BOverlay, BButton, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import { required } from '@validations'

export default {
  components: {
    BFormSelect,
    BOverlay,
    BButton,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    domainId: {
      type: Number,
      default: 0,
    },
    standardId: {
      type: Number,
      default: 0,
    },
    grades: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      gradeId: null,
      isLoading: false,
      isProcessing: false,
      subjectList: [],
      standardDomain: null,
      standardDomainOptions: [],
      subjectId: null,
      required,
    }
  },
  methods: {
    getSubjectForGrade() {
      const subject = this.grades.find(grade => grade.id === this.gradeId)?.subjects || []
      this.subjectList = subject
    },
    // eslint-disable-next-line func-names
    getStandardDomain() {
      this.standardDomain = null
      useJwt.filterDomainStandard({
        params: {
          subjectId: this.subjectId,
        },
      }).then(response => {
        this.standardDomainOptions = response.data?.data?.map(lesson => ({
          text: lesson.name,
          value: lesson.id,
        }))
      })
    },
    submitData() {
      this.$refs.myForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt.moveDomainStandard(this.domainId, {
            to_domain: this.standardDomain,
            standard_id: this.standardId,
          }).then(response => {
            this.showSuccessMessage(response)
            this.$emit('close')
            this.$emit('getDomains')
          }).catch(error => {
            this.showErrorMessage(error)
          })
            .finally(() => { this.isProcessing = false })
        }
      })
    },
  },
}
</script>
