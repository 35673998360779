<template>
  <div class="w-100">
    <div
      v-if="!audioURL"
      class="p-0"
    >
      <feather-icon
        v-if="!recording"
        icon="MicIcon"
        class="mr-2 play-icon"
        @click="record()"
      />
      <feather-icon
        v-else
        icon="StopCircleIcon"
        class="mr-2 play-icon text-danger"
        @click="stop()"
      />
      <b-form-file
        ref="audioInput"
        style="display: none"
        :browse-text="$t('browseButton')"
        :placeholder="$t('issue-report-module.choose-a-file')"
        accept="audio/mp3"
        @change="handleAudio($event)"
      />
      <span v-if="!browserSupport">
        <span>{{ $t('labels.browser-do-not-support') }}</span>
      </span>
      <template v-else>
        <span v-if="msg">{{ msg }}</span>
        <span v-else-if="info"
              class="text-nowrap"
        >{{ info }}</span>
        <span v-else>{{ $t('labels.start-recording') }}</span>
      </template>
    </div>
    <div
      v-else
      class="d-flex justify-content-between align-items-center px-0 w-100"
    >
      <div class="d-flex align-items-center">
        <audio-player v-if="!hidePlayer"
                      :src="audioURL"
        />
        <div>{{ msg }}</div>
      </div>
      <!-- <feather-icon
        icon="TrashIcon"
        class="trash-icon"
        @click="deleteAudio"
      /> -->
    </div>
  </div>
</template>

<script>
import { BFormFile } from 'bootstrap-vue'
import AudioPlayer from './AudioPlayer.vue'
import VoiceRecorder from './voice-recorder'

export default {
  components: {
    BFormFile,
    AudioPlayer,
  },
  props: {
    url: {
      type: String,
      default: () => '',
    },
    isStop: {
      type: Boolean,
      default: () => false,
    },
    info: {
      type: String,
      default: () => '',
    },
    msg: {
      type: String,
      default: () => '',
    },
    browserSupport: {
      type: Boolean,
      default: () => true,
    },
    hidePlayer: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'audio/mp3',
    },
  },
  data: () => ({
    audioURL: '',
    audio: '',
    chunks: [],
    recording: false,
    uploading: true,
    tracks: null,
    mediaRecorder: new VoiceRecorder(),
    isNativePhone: /(android)/i.test(navigator.userAgent),
  }),
  watch: {
    url() {
      this.audioURL = this.url
    },
    isStop(value) {
      if (value) this.stop()
    },
    recording(newValue) {
      this.$emit('recordingState', newValue)
    },
  },
  created() {
    this.audioURL = this.url
  },
  methods: {
    record() {
      if (this.browserSupport) {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          this.recording = true
          this.mediaRecorder.startRecording()
          this.$emit('start')
        } else {
          this.showInfo('Audio and camera not supported on your browser!')
        }
      } else {
        this.showInfo('Audio and camera not supported on your browser!')
      }
    },
    stop() {
      this.recording = false
      const response = this.mediaRecorder.stopRecording()
      this.audio = response.audioBlob
      this.audioURL = response.audioUrl
      this.$emit('handleBlob', response.audioBlob)
      this.$emit('stop')
      this.emitAudio()
      setTimeout(() => {
        this.deleteAudio()
      }, 1500)
    },
    upload() {
      this.$refs.audioInput.$el.childNodes[0].click()
    },
    handleAudio(event) {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.audioURL = reader.result
          this.emitAudio()
        },
        false,
      )
      if (event.target.files[0]) {
        const file = event.target.files[0]
        if (/\.(mp3)$/i.test(file.name)) {
          this.audio = file
          reader.readAsDataURL(file)
        }
      }
    },
    deleteAudio() {
      this.audioURL = ''
      this.audio = ''
      this.emitAudio()
      this.$emit('deleted')
    },
    emitAudio() {
      this.$emit('input', { audio: this.audio, url: this.audioURL, msg: this.msg })
    },
  },
}
</script>

<style lang="scss">
.close{
  .btn-icon {
    padding: 5px 5px !important;
    background-color: #fff;
    top: 5px;
    margin-right: 5px;
  }
}
.iconfont.rate{
  display: none !important;
}
.play-icon {
  width: 40px;
  height: 35px;
  float: left;
  &:hover{
    cursor: pointer;
  }
}
.trash-icon{
    width: 35px;
    height: 35px;
    &:hover{
      cursor: pointer;
    }
  }
</style>
