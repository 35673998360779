<template>
  <b-overlay :show="isProcessing">
    <b-card>
      <b-row>
        <b-col>
          <b-form-group label="Select Scorm file">
            <v-select
              v-model="selectedScorm"
              :options="scormList"
              label="title"
              :reduce="option => option.uuid"
              placeholder="Select a scorm file"
            />
          </b-form-group>
        </b-col>
        <b-col style="margin-top: -20px">
          <create-scorm @reFetch="handleCreate" />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BFormGroup, BCard, BOverlay, BRow, BCol,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import CreateScorm from '@views/scorm/crud/components/Create.vue'

export default {
  components: {
    BFormGroup, BCard, vSelect, BOverlay, BRow, BCol, CreateScorm,
  },
  props: {
    templateData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isProcessing: false,
      scormList: [],
    }
  },
  computed: {
    selectedScorm: {
      get() {
        return this.templateData[0]?.uuid
      },
      set(newValue) {
        this.$emit('onScormChange', newValue)
      },
    },
  },
  created() {
    this.getScormList()
  },
  methods: {
    getScormList() {
      this.isProcessing = true
      useJwt.getScormList().then(response => {
        this.scormList = response.data.data.data?.map(data => ({ title: data.scos[0]?.title, uuid: data.scos[0]?.uuid }))
      }).catch(error => {
        this.showError(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    async handleCreate(value) {
      if (value.duplicate) {
        this.selectedScorm = value.uuid
      } else {
        await this.getScormList()
        this.$nextTick(() => {
          this.selectedScorm = value.uuid
        })
      }
    },
  },
}
</script>
