// eslint-disable-next-line import/no-cycle
import { getUserData } from '@/auth/utils'
import { USER_TYPE_NATIONAL } from '@/const/userType'

export default function nationalUsersOnly({ next, router }) {
  if (getUserData().usertype !== USER_TYPE_NATIONAL && getUserData().usertype !== 'super') {
    return router.push('not-authorized')
  }

  return next()
}
