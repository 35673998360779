import AOS from 'aos'

export default {
  mounted() {
    if (!this.isEditMode) {
      setTimeout(() => {
        if (this.$refs.smallLayoutCover && this.$refs.bigLayoutCover) {
          this.$refs.smallLayoutCover.$el.style.maxHeight = `${this.$refs.bigLayoutCover.$el.clientHeight}px`
          AOS.refresh()
        }
      }, 550)
    }
  },
}
