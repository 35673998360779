/* eslint-disable class-methods-use-this */
import apolloProvider from '@/libs/vue-apollo'
import { queries } from './index'

const { defaultClient } = apolloProvider
export default class JwtService {
  fetchClassRoomData(type, page, limit, id) {
    return defaultClient.query({
      fetchPolicy: 'no-cache',
      query: queries.FETCH_CLASS_ROOMS,
      variables: {
        // member_id: memberId,
        // created_by: createdBy,
        type,
        page,
        limit,
        id,
        // byAuthUser,
        // member_id: userId,
        // created_id: userId,
      },
    })
  }

  searchRooms(search, userId, type) {
    return defaultClient.query({
      query: queries.SEARCH_ROOMS,
      variables: {
        search,
        userId,
        type,
      },
    })
  }

  getStudentsOfClassRooms(id, groupId, userId) {
    return defaultClient.query({
      fetchPolicy: 'no-cache',
      query: queries.FIND_CLASSROOM_WITH_STUDENTS,
      variables: {
        id,
        group_id: groupId,
        user_id: userId,
      },
    })
  }

  getCourseResultCompleted(id, groupId, userId) {
    return defaultClient.query({
      fetchPolicy: 'no-cache',
      query: queries.FIND_COURSECOMPLETED_RESULT,
      variables: {
        id,
        group_id: groupId,
        user_id: userId,
      },
    })
  }

  searchStudents(search, roomId) {
    return defaultClient.query({
      query: queries.SEARCH_STUDENTS,
      variables: {
        search,
        roomId,
      },
    })
  }

  getLessonCourseResult(variables) {
    return defaultClient.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_LESSON_COURSE_RESULT,
      variables: {
        ...variables,
      },
    })
  }

  getCourseInfo(variables) {
    return defaultClient.query({
      query: queries.GET_COURSE_INFO,
      variables: {
        ...variables,
      },
    })
  }

  getLessonPassedResult(variables) {
    return defaultClient.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_LESSON_PASSED_RESULT,
      variables: {
        ...variables,
      },
    })
  }

  fetchLessonWithGroup(id, courseId) {
    return defaultClient.query({
      query: queries.FETCH_LESSON_WITH_GROUP,
      variables: {
        id,
        courseId,
        classId: id,
      },
    })
  }

  // report start
  fetchLessonIds(id, courseId) {
    return defaultClient.query({
      query: queries.FETCH_LESSSON_ID,
      variables: {
        id,
        courseId,
      },
    })
  }

  fetchCourseList(id, classId) {
    return defaultClient.query({
      query: queries.Fetch_Course_List,
      variables: {
        id,
        classId,
      },
    })
  }

  fetchCourseReport(id, lessonIds, groupId, studentId = null, classTestId = null) {
    return defaultClient.query({
      fetchPolicy: 'no-cache',
      query: Array.isArray(studentId) ? queries.FETCH_REPORT_MULTIPLE_STUDENT : queries.FETCH_REPORT,
      variables: {
        id,
        lessonIds,
        groupId,
        user_id: studentId,
        classTestId,
      },
    })
  }

  fetchCourseReportByGroup(id, lessonIds, groupId, studentId = null, classTestId = null) {
    return defaultClient.query({
      query: Array.isArray(studentId) ? queries.FETCH_REPORT_BY_GROUP_MULTIPLE_USER : queries.FETCH_REPORT_BY_GROUP,
      variables: {
        id,
        lessonIds,
        groupId,
        user_id: studentId,
        classTestId,
      },
    })
  }

  fetchLessonList(id) {
    return defaultClient.query({
      query: queries.FETCH_LESSON_LIST,
      variables: {
        id,
      },
    })
  }

  fetchStudentLessonReport(userId, groupId) {
    return defaultClient.query({
      query: queries.FETCH_STUDENT_LESSON_REPORT,
      variables: {
        userId,
        groupId,
      },
    })
  }
  // report end

  fallbackFetchLessonWithGroup(id, courseId) {
    return defaultClient.query({
      query: queries.FALLBACK_FETCH_LESSON_WITH_GROUP,
      variables: {
        id,
        courseId,
      },
    })
  }

  getTopScorerOfClass(roomId) {
    return defaultClient.query({
      query: queries.TOP_SCORER_QUERY,
      variables: {
        roomId,
      },
    })
  }

  getLessonStatus(userId, groupId) {
    return defaultClient.query({
      query: queries.FETCH_LESSON_STATUS,
      variables: {
        user_id: userId,
        group_id: groupId,
      },
    })
  }

  getLessonHighScore(variables) {
    return defaultClient.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_LESSION_HIGH_SCORE,
      variables: {
        ...variables,
      },
    })
  }

  getFirstFinisher(variables) {
    return defaultClient.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_LESSION_FIRST_FINISHER,
      variables: {
        ...variables,
      },
    })
  }

  getUserOverallScore(id) {
    return defaultClient.query({
      query: queries.GET_USER_OVERALL_SCORE,
      variables: {
        id,
      },
    })
  }

  fetchAutoGeneratedProblems(key) {
    return defaultClient.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_AUTO_GENERATED_PROBLEMS_LIST,
      variables: {
        key,
      },
    })
  }

  getStudentStat(variables) {
    return defaultClient.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_STUDENT_STAT,
      variables: {
        user_id: variables.user_id,
        group_id: variables.group_id,
        course_id: variables.course_id,
      },
    })
  }

  getGuestLessonReport(variables) {
    return defaultClient.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_GUEST_LESSON_REPORT_RESULT,
      variables: {
        user_id: variables.user_id,
        group_id: variables.group_id,
        course_id: variables.course_id,
      },
    })
  }

  getParentUser(userType) {
    return defaultClient.query({
      query: queries.GET_PARENT_USER,
      variables: {
        userType,
      },
    })
  }

  getSchoolUsers(data) {
    return defaultClient.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_SCHOOL_USERS,
      variables: { ...data },
    })
  }

  getSchoolUsersWithSchoolTime() {
    return defaultClient.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_SCHOOL_USERS_WITH_SCHOOL_TIME,
    })
  }

  getParentSchool(childId) {
    return defaultClient.query({
      query: queries.GET_PARENT_SCHOOL,
      variables: {
        childId,
      },
    })
  }

  getAllSchools() {
    return defaultClient.query({
      query: queries.GET_ALL_SCHOOLS,
    })
  }

  getUsersOfSchool(userType, parentId) {
    return defaultClient.query({
      query: queries.GET_USERS_OF_SCHOOL,
      variables: {
        userType,
        parentId,
      },
    })
  }

  getUsersOfTypes(userType, parentId) {
    return defaultClient.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_USERS_OF_TYPES,
      variables: {
        userType,
        teacherIds: parentId,
      },
    })
  }

  filterClassroom(schoolId, teacherId, studentId, page, limit) {
    return defaultClient.query({
      query: queries.FETCH_CLASS_ROOMS,
      variables: {
        member_id: studentId,
        schoolId,
        teacherId,
        page,
        limit,
      },
      fetchPolicy: 'no-cache',
    })
  }

  getUserDropDown(data) {
    return defaultClient.query({
      query: queries.GET_USER_DROPDOWN,
      variables: {
        ...data,
      },
    })
  }

  getMyClassRoom(limit = 1000) {
    return defaultClient.query({
      query: queries.GET_MY_CLASSROOM,
      variables: {
        limit,
      },
    })
  }

  getCefrSubject() {
    return defaultClient.query({
      query: queries.GET_CERF_SUBJECT,
      variables: {},
    })
  }

  getClassRoomInfo(id) {
    return defaultClient.query({
      query: queries.GET_CLASSROOM_INFO,
      variables: {
        id,
      },
    })
  }

  getClassRoomStudents(id) {
    return defaultClient.query({
      query: queries.GET_CLASSROOM_STUDENTS,
      variables: {
        id,
      },
    })
  }
}
