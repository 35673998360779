/* eslint-disable import/no-cycle */
import { getUserData } from '@/auth/utils'
import { USER_TYPE_TEACHER } from '@/const/userType'

export default function teacherUsersOnly({ next, router }) {
  if (getUserData().usertype !== USER_TYPE_TEACHER && getUserData().usertype !== 'super') {
    return router.push('not-authorized')
  }

  return next()
}
