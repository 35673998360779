<template>
  <b-modal id="lessonPlannerViewModal"
           hide-footer
           size="lg"
  >
    <div class="bg-white lesson-planner-view overflow-auto p-1"
         style="max-height: 85vh;"
    >
      <div class="d-flex justify-content-between align-items-center mb-1">
        <h3>Lesson Plan</h3>
        <div>
          <b-button variant="primary"
                    @click="exportPdf"
          >
            <feather-icon icon="FileIcon" /> Export Pdf
          </b-button>
          <!-- online switch -->
          <b-checkbox-group
            class="ml-2"
          >
            <b-form-checkbox v-model="onlineSwitch"
                             value="online"
                             name="online"

                             switch
                             inline
            >
              Online
            </b-form-checkbox>
          </b-checkbox-group>
        </div>
      </div>
      <template v-if="!processing">
        <b-row v-if="lessonPlanners?.length<=0">
          <b-col cols="12">
            <h4 class="text-center">
              Sorry, No Lesson Planner Found
            </h4>
          </b-col>
        </b-row>
        <div id="lesson-planner">
          <div v-for="lessonPlanner,index in lessonPlanners"
               :key="index"
          >
            <b-row v-if="onlineSwitch ? lessonPlanner.lesson_planner_type === 'online' : true"
                   class="p-2 mb-2"
                   :class="`${lessonPlanner.lesson_planner_type === 'online' && 'light-bg'} ${lessonPlanner.lesson_planner_type === 'offline' && 'secondary-bg'}`"
            >
              <b-col v-if="!processing"
                     cols="12"
              >
                <div class="d-flex justify-content-end">
                  <div :class="lessonPlanner.lesson_planner_type=='online'?'bg-success':'bg-secondary'"
                       style="height:15px; width:15px; border-radius:50%;"
                  />
                </div>
                <h4 class="">
                  {{ lessonPlanner.title }}
                </h4>
                <div v-html="lessonPlanner.content" />
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
      <div v-else
           cols="12"
           class="d-flex justify-content-center align-items-center h-100"
      >
        <b-spinner />
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BSpinner, BModal, BButton,
  BCheckboxGroup, BFormCheckbox,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    BModal,
    BCheckboxGroup,
    BFormCheckbox,
    BButton,
  },
  props: {
    lessonId: {
      type: Number,
      default: () => null,
    },
    eventId: {
      type: [Number, String],
      default: () => null,
    },
  },
  data() {
    return {
      lessonPlanners: [],
      processing: false,
      onlineSwitch: false,
    }
  },
  computed: {
    isLoggedIn() {
      return !!this.AUTH_USER()
    },
  },

  watch: {
    lessonId() {
      if (this.isLoggedIn) this.getLessonPlanners()
    },
  },
  mounted() {
    if (this.lessonId) {
      this.getLessonPlanners()
    }
  },

  methods: {
    getLessonPlanners() {
      this.processing = true
      useJwt.fetchCurrentLessonPlanner(this.lessonId, { params: { eventId: this.eventId } }).then(res => {
        const { data } = res.data
        this.lessonPlanners = data
      }).finally(() => {
        this.processing = false
      })
    },
    exportPdf() {
      const element = document.getElementById('lesson-planner')
      this.printPdf(element, 'Lesson Planner')
    },
  },
}
</script>
<style lang="scss">
#lessonPlannerViewModal{
  .light-bg, .secondary-bg{
    background:url('../../../../assets/images/illustration/back.png') no-repeat;
    border-radius: 10px;
    background-size: cover;
    .col-12{
      background: #fff;
      padding: 10px 20px 10px 20px;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px #0000001a;
    }
  }

  .lesson-planner-view{
      -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
