<template>
  <b-overlay
    :show="isProcessing"
    opacity="0.5"
  >
    <b-card>
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>

            <b-col
              md="12"
              class="mb-1"
            >
              <h3>{{ $t('send-notification-module.send-notifications') }}</h3>
            </b-col>

            <b-col md="12">
              <b-row>
                <b-col
                  style="margin-bottom:8px"
                  md="6"
                >
                  <main-filter @selected="(d)=>filters.mainFilter=d" />
                </b-col>

                <b-col
                  style="margin-bottom:8px"
                  md="6"
                >
                  <user-type-filter @selected="(d)=>filters.userTypeFilter=d" />
                </b-col>

                <b-col
                  v-if="filters.mainFilter==='class'"
                  style="margin-bottom:8px"
                  md="6"
                >
                  <class-filter @selected="classChanged" />
                </b-col>

                <b-col
                  v-if="filters.mainFilter==='teacher'"
                  style="margin-bottom:8px"
                  md="6"
                >
                  <teacher-filter @selected="(d)=>filters.teacherFilter=d" />
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12">
              <b-form-group :label="$t('send-notification-module.notification-url')">
                <b-form-input v-model="visitUrl"
                              :placeholder="$t('send-notification-module.notification-url-optional')"
                />
              </b-form-group>
            </b-col>
            <b-col class="mt-2">

              <validation-observer
                ref="notificationForm"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="subject"
                  rules="required|max:256"
                  vid="subject"
                >
                  <span>
                    {{ $t('send-notification-module.subject') }}
                  </span>
                  <b-form-textarea
                    v-model="subject"
                    style="margin:6px 0"
                    :placeholder="$t('send-notification-module.subject')"
                    rows="3"
                    name="subject"
                    :state="errors.length > 0 ? false:null"
                  />
                  <p><small class="text-danger">{{ errors[0] }}</small></p>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="message"
                  rules="required|max:256"
                  vid="message"
                >
                  <span>
                    {{ $t('send-notification-module.message') }}
                  </span>
                  <b-form-textarea
                    v-model="body"
                    style="margin:6px 0"
                    :placeholder="$t('send-notification-module.message')"
                    rows="6"
                    name="message"
                    :state="errors.length > 0 ? false:null"
                  />
                  <p><small class="text-danger">{{ errors[0] }}</small></p>
                </validation-provider>
              </validation-observer>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col md="12">

              <users-list
                :users="searchUsersList"
                :is-processing="isProcessing"
                :user-types="filters.userTypeFilter"
                @selected="(type,d)=>selectedUsers[type] = d"
                @search="searchUsers"
              />
            </b-col>
            <b-col
              md="12"
              class="d-flex mt-1 justify-content-end"
            >
              <b-button
                variant="primary"
                :disabled="isProcessing"
                @click="sendNotifications"
              >
                {{ $t('send-notification-module.send') }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BOverlay, BFormTextarea, BButton, BCard, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import MainFilter from './components/MainFilter.vue'
import ClassFilter from './components/ClassFilter.vue'
import UsersList from './components/UsersList.vue'
import TeacherFilter from './components/TeacherFilter.vue'
import UserTypeFilter from './components/UserTypeFilter.vue'

export default {
  components: {
    BRow, BCol, MainFilter, ClassFilter, TeacherFilter, UsersList, BOverlay, ValidationProvider, ValidationObserver, BFormTextarea, BButton, UserTypeFilter, BCard, BFormInput, BFormGroup,
  },
  data() {
    return {
      isProcessing: false,
      students: [],
      subject: '',
      body: '',
      visitUrl: '',
      filters: {
        mainFilter: null,
        classFilter: null,
        teacherFilter: null,
        userTypeFilter: 'student-parent',
      },
      self: getUserData(),
      selectedUsers: {
        students: [],
        parents: [],
      },
      searchUsersList: [],
      searchTerm: '',

    }
  },
  mounted() {
    this.getStudents()
  },
  methods: {
    clearData() {
      this.subject = ''
      this.body = ''
    },
    searchUsers(val) {
      if (val !== undefined) this.searchTerm = val
      const searchUsersList = this.students.filter(e => e.name.toLowerCase().includes(this.searchTerm.toLowerCase()) || (e.parent && e.parent.toLowerCase().includes(this.searchTerm.toLowerCase())))
      this.searchUsersList = searchUsersList
    },

    getStudents(classId = null) {
      const data = {}
      if (classId) data.class_id = classId
      this.isProcessing = true
      useJwt.filterClassStudents({ params: data }).then(res => {
        this.students = res.data.data
        this.selectedUsers = {
          students: [],
          parents: [],
        }
        this.students.forEach(e => {
          if (e.parent) this.selectedUsers.parents.push(e.parent.id)
          this.selectedUsers.students.push(e.student.id)
        })

        this.students = this.students.map(e => ({
          email: e.student.email,
          name: e.student.fullname,
          id: e.student.id,
          parent: e.parent ? e.parent.fullname : null,
          parentId: e.parent ? e.parent.id : null,
          isSelected: true,
          isParentSelected: true,
        }))

        this.searchUsers()
      }).finally(() => {
        this.isProcessing = false
      })
    },
    sendNotifications() {
      this.$refs.notificationForm.validate().then(success => {
        if (!success) return
        if (this.selectedUsers.parents.length === 0 && this.selectedUsers.students.length === 0) {
          this.showError(i18n.tc('send-notification-module.select-user'))
          return
        }
        this.isProcessing = true
        useJwt.sendNotifications({
          parent_ids: this.filters.userTypeFilter.includes('parent') ? this.selectedUsers.parents : [], student_ids: this.filters.userTypeFilter.includes('student') ? this.selectedUsers.students : [], subject: this.subject, body: this.body, visit_url: this.visitUrl,
        }).then(res => {
          this.showSuccessMessage(res)
          this.clearData()
          this.$nextTick(() => {
            this.$refs.notificationForm.reset()
          })
        }).finally(() => {
          this.isProcessing = false
        })
      })
    },
    classChanged(c) {
      this.filters.classFilter = c
      this.getStudents(c)
    },
  },
}
</script>

<style>

</style>
