<template>
  <b-tabs>
    <b-tab
      v-for="report of filteredReportTypes"
      :key="report.value"
      :title="report.text"
      lazy
      @click="selectedReport = report.value"
    >
      <component
        :is="report.component"
        :class-room="classRoom"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  VBTooltip, BTabs, BTab,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import AttendanceReport from '../GroupReport/AttendanceReport.vue'
import ActivityReport from '../GroupReport/ActivityReport.vue'
import ScheduleReport from '../GroupReport/ScheduleReport.vue'

export default {
  components: {
    AttendanceReport,
    ActivityReport,
    ScheduleReport,
    BTabs,
    BTab,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    classRoom: {
      type: Object,
      default: () => {},
    },
    studySkillsReport: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedReport: 'skill-report',
      reportType: [
        {
          text: i18n.tc('setting-module.attendance-report-title'),
          value: 'attendance-report',
          component: 'AttendanceReport',
        },
        {
          text: i18n.tc('setting-module.activity-report-title'),
          value: 'activity-report',
          component: 'ActivityReport',
        },
        {
          text: i18n.tc('setting-module.schedule-report-title'),
          value: 'schedule-report',
          component: 'ScheduleReport',
        },
      ],
    }
  },
  computed: {
    filteredReportTypes() {
      let filteredTypes = this.reportType.slice() // Make a copy of the report types
      if (this.studySkillsReport.attendance === 1) {
        filteredTypes = filteredTypes.filter(type => type.value !== 'attendance-report')
      }
      if (this.studySkillsReport.activity === 1) {
        filteredTypes = filteredTypes.filter(type => type.value !== 'activity-report')
      }
      if (this.studySkillsReport.schedule === 1) {
        filteredTypes = filteredTypes.filter(type => type.value !== 'schedule-report')
      }
      return filteredTypes
    },
  },
}
</script>
