/* eslint-disable no-underscore-dangle */
/* eslint-disable no-underscore-dangle */
<template>
  <div
    id="balloon-container"
    class="congrz-window"
  >
    <div
      class="js-container container"
      style="top:0px !important;"
    />

    <div style="text-align:center;margin-top:150px;position:  absolute;width:100%;height:100%;top:0px;left:0px;">
      <div>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="outline-danger"
          size="lg"
          class="btn-icon rounded-circle"
        >
          <feather-icon
            icon="XIcon"
            size="100"
          />
        </b-button>
      </div>
      <h1 class="pt-2">
        Lesson Failed
      </h1>
      <p>You have earned <b>{{ resultSummary.points }}</b> Points.</p>
      <b-button
        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
        variant="outline-primary"
        size="lg"
        @click="$emit('playAgain')"
      >
        <b-spinner v-show="processing" />
        Try Again
      </b-button></div>

  </div>
</template>
<script>
import { BSpinner, BButton } from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
    BButton,
  },
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
    lesson: {
      type: String,
      default: '',
    },
    resultSummary: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      characterTimeout: null,
    }
  },
  computed: {
    currentStatus() {
      return this.$store.state.appConfig.currentStatus
    },
  },
  watch: {
  },
  mounted() {
  },
}
</script>
