<template>
  <v-select v-model="selectedClassId"
            :options="classRoomLists"
            :reduce="r => r.id"
            label="name"
            class="w-100"
  />
</template>
<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    classroomId: {
      type: Number,
      default: 0,
    },
    classRoomLists: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectedClassId: {
      get() {
        return this.classroomId
      },
      set(value) {
        this.$emit('onChangeClassRoom', value)
      },
    },
  },

}
</script>
