<template>
  <div class="card">
    <div class="card-body statistics-body">
      <div class="row">
        <div class="mb-2 mb-md-0 col-sm-6 col-md-3 mb-2 mb-xl-0">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <span
                class="b-avatar badge-light-primary rounded-circle"
                style="width: 48px; height: 48px"
              ><span class="b-avatar-custom">
                <feather-icon icon="ColumnsIcon"
                              size="20"
                />
              </span>
              </span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                14
              </h4>
              <p class="card-text font-small-3 mb-0">
                Total
              </p>
            </div>
          </div>
        </div>
        <div class="mb-2 mb-md-0 col-sm-6 col-md-3 mb-2 mb-xl-0">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <span
                class="b-avatar badge-light-success rounded-circle"
                style="width: 48px; height: 48px"
              ><span class="b-avatar-custom">
                <feather-icon icon="CheckIcon"
                              size="20"
                />
              </span>
              </span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                22
              </h4>
              <p class="card-text font-small-3 mb-0">
                Correct
              </p>
            </div>
          </div>
        </div>
        <div class="mb-2 mb-md-0 col-sm-6 col-md-3 mb-2 mb-xl-0">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <span
                class="b-avatar badge-light-danger rounded-circle"
                style="width: 48px; height: 48px"
              ><span class="b-avatar-custom">
                <feather-icon icon="XIcon"
                              size="20"
                />
              </span>
              </span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                22
              </h4>
              <p class="card-text font-small-3 mb-0">
                Incorrect
              </p>
            </div>
          </div>
        </div>
        <div class="mb-2 mb-md-0 col-sm-6 col-md-3 mb-2 mb-xl-0">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <span
                class="b-avatar badge-light-info rounded-circle"
                style="width: 48px; height: 48px"
              ><span class="b-avatar-custom">
                <feather-icon icon="ShieldIcon"
                              size="20"
                />
              </span>
              </span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                22
              </h4>
              <p class="card-text font-small-3 mb-0">
                Pass Score
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    statisticsData: {
      type: Object,
      default: () => null,
    },
  },
  methods: {
    secondToHours(seconds) {
      return seconds / (60 * 60)
    },
  },
}
</script>
