<template>
  <div>
    <b-overlay :show="isLoading">
      <b-row class="justify-content-between">
        <b-col
          v-for="library of libraries"
          :key="library.id"
          md="6"
        >
          <b-card border-variant="primary">
            <div class="d-flex">
              <div class="image-wrapper">
                <img
                  :src="library.image"
                  alt=""
                >
              </div>

              <div class="ml-2">
                <h4>{{ library.name }}</h4>
                <b-button
                  v-b-modal.add-school-modal
                  variant="outline-primary"
                  @click="selectedLibrary = library.id"
                >
                  <feather-icon icon="PlusIcon" />
                  {{ $t('setting-module.add-schools') }}
                </b-button>
                <p class="text-muted"
                   style="font-size:13px; font-style: italic;"
                >
                  <feather-icon icon="InfoIcon" />
                  {{ $t("setting-module.library-info") }}
                </p>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

    <!-- Add School Modal -->
    <b-modal
      id="add-school-modal"
      :title="$t('setting-module.add-library-permission')"
      size="lg"
      no-close-on-backdrop
      @shown="() => { getSchools(); getLibrarySchools() }"
      @hidden="clearModal"
    >
      <b-overlay :show="isLoading">
        <div class="d-flex">
          <div>
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="handleSelectAll"
            >
              {{ isSelectedAll ? $t('actions.unselect-all') : $t('actions.select-all') }}
            </b-button>
          </div>

          <b-form-group class="w-50 position-relative">
            <b-form-input
              v-model="searchTerm"
              class="search-input"
              :placeholder="$t('setting-module.search-users')"
              @input="filterSchools"
            />
            <span
              v-if="searchTerm"
              v-b-tooltip.hover="$t('setting-module.clear-search')"
              class="clear-icon text-muted cursor-pointer"
              @click="searchTerm = ''"
            >
              <feather-icon icon="XIcon" />
            </span>
          </b-form-group>
        </div>
        <div class="row mt-2">
          <div
            v-for="sc,i of filteredSchoolLists.length ? filteredSchoolLists : schoolLists"
            :key="i"
            class="col-md-4 mb-2"
          >
            <b-form-checkbox
              v-model="selectedSchools"
              :value="sc.id"
            >
              {{ sc.fullname }}
            </b-form-checkbox>
          </div>
        </div>
      </b-overlay>

      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            :disabled="isLoading"
            @input="getSchools"
          />
          <div>
            <b-button
              class="mr-1"
              @click="$bvModal.hide('add-school-modal')"
            >
              {{ $t('actions.cancel') }}
            </b-button>

            <b-button
              variant="primary"
              @click="updateSchoolUsers"
            >
              {{ $t('actions.add') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script setup>
import { getCurrentInstance, onMounted, ref } from 'vue'
import {
  BOverlay, BRow, BCol, BCard, BButton, BModal, BFormGroup, BFormCheckbox, BFormInput, BPagination,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import _ from 'lodash'

const instance = getCurrentInstance().proxy
const self = getUserData()
const libraries = ref([])
const schoolLists = ref([])
const allSchools = ref([])
const filteredSchoolLists = ref([])
const selectedSchools = ref([])
const searchTerm = ref('')
const selectedLibrary = ref()
const isSelectedAll = ref(false)
const isLoading = ref(false)
const currentPage = ref(1)
const perPage = ref(30)
const total = ref(0)

const getLibraries = () => {
  isLoading.value = true
  useJwt.getLibrary().then(response => {
    libraries.value = response.data.data
  }).catch(error => {
    instance.showError(error)
  }).finally(() => {
    isLoading.value = false
  })
}

const getSchools = () => {
  const params = {
    id: self.id,
    usertype: 'school',
    perPage: perPage.value,
    page: currentPage.value,
  }
  isLoading.value = true

  if (!schoolLists.length) {
    useJwt.getUsers({ params }).then(response => {
      schoolLists.value = response.data.data.map(({ id, fullname }) => ({
        id,
        fullname,
      }))
      total.value = response.data.pagination.total

      if (!allSchools.value.includes(schoolLists.value?.[0].id)) {
        allSchools.value = [
          ...allSchools.value,
          ...schoolLists.value.map(school => school.id),
        ]
      }
    }).finally(() => {
      isLoading.value = false
    })
  }
}

const handleSelectAll = () => {
  if (isSelectedAll.value) {
    isSelectedAll.value = false
    selectedSchools.value = []
  } else {
    isSelectedAll.value = true
    selectedSchools.value = schoolLists.value.map(item => item.id)
  }
}

const filterSchools = _.debounce(() => {
  if (searchTerm.value) {
    filteredSchoolLists.value = schoolLists.value?.filter(school => school.fullname.toLowerCase().indexOf(searchTerm.value?.toLowerCase()) !== -1)
  } else filteredSchoolLists.value = []
}, 1000)

const updateSchoolUsers = () => {
  isLoading.value = true
  useJwt.storeSchoolLibrary(selectedLibrary.value, {
    schools_list: allSchools.value,
    school_ids: [...new Set(selectedSchools.value)],
  }).then(response => {
    instance.$root.showSuccessMessage(response)
  }).catch(error => {
    instance.$root.showErrorMessage(error)
  }).finally(() => {
    isLoading.value = false
    instance.$bvModal.hide('add-school-modal')
    currentPage.value = 1
  })
}

const getLibrarySchools = () => {
  useJwt.showLibrary(selectedLibrary.value).then(response => {
    selectedSchools.value = response.data?.data?.library?.schools.map(item => item.id) ?? []
  })
}

const clearModal = () => {
  searchTerm.value = ''
  filteredSchoolLists.value = []
  selectedSchools.value = []
}

onMounted(() => {
  getLibraries()
})
</script>

<style lang="scss" scoped>
.image-wrapper {
  width: 80px;
  height: 80px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.clear-icon {
  position: absolute;
  right: 8px;
  top: 8px;
}
</style>
