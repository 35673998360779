<template>
  <Resizable :ref="el => props.coordinate.element = el?.$el"
             :x="props.coordinate.x"
             :y="props.coordinate.y"
             :height="props.coordinate.height"
             :width="props.coordinate.width"
             :draggable="!isChildDragging"
             :parent-width="parentWidth"
             :parent-height="parentHeight"
             @updateCoordinate="updateParentCoordinate"
             @remove="$emit('remove')"
  >
    <div class="problem-title">
      {{ props.coordinate.name }}
    </div>
    <Resizable v-for="child,index of props.coordinate.children"
               :key="index"
               :ref="el => child.element = el?.$el"
               :x="child.x"
               :y="child.y"
               :width="child.width"
               :height="child.height"
               :parent-height="props.coordinate.height"
               :parent-width="props.coordinate.width"
               :border-color="getBorderColor(child)"
               :tag="getTag(child)"
               :can-be-destroyed="child.type === 'question' || props.coordinate.type === 'multiple'"
               @dragStart="isChildDragging = true"
               @dragEnd="isChildDragging = false"
               @remove="removeChild(index)"
    />
    <div class="add-more">
      <b-dropdown
        dropup
        left
        no-caret
        variant="primary"
      >
        <template #button-content>
          <feather-icon icon="PlusIcon" />
        </template>
        <b-dropdown-item @click="addMoreBoundingBox">
          {{ $t('answer-question') }}
        </b-dropdown-item>
        <b-dropdown-item v-if="!props.coordinate.children.find(i => i.type === 'decoration-image')"
                         @click="addMoreDecorationImage"
        >
          {{ $t('decoration-image') }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </Resizable>
</template>
<script setup>
import { ref } from 'vue'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import Resizable from './Resizable.vue'
import { addMoreFields, addDecorationImage } from '../services/problem-data'

const props = defineProps({
  coordinate: {
    type: Object,
    required: true,
  },
  parentHeight: {
    type: Number,
    default: 800,
  },
  parentWidth: {
    type: Number,
    default: 800,
  },
})

const isChildDragging = ref(false)

const updateParentCoordinate = ({ height, width }) => {
  props.coordinate.height = height
  props.coordinate.width = width
}

const addMoreBoundingBox = () => {
  const moreField = addMoreFields({
    type: props.coordinate.type,
    lastData: props.coordinate.children[props.coordinate.children.length - 1],
    allData: props.coordinate.children,
  })
  props.coordinate.children = [...props.coordinate.children, ...moreField]
}

const addMoreDecorationImage = () => {
  const moreField = addDecorationImage({
    lastData: props.coordinate.children[props.coordinate.children.length - 1],
  })
  props.coordinate.children = [...props.coordinate.children, ...[moreField]]
}

const removeChild = index => {
  const item = { ...props.coordinate.children[index] }
  if (item.groupId) {
    props.coordinate.children = props.coordinate.children.filter(i => i.groupId !== item.groupId)
  } else {
    props.coordinate.children.splice(index, 1)
  }
}

const getTag = item => {
  if (!item.groupId) {
    return ''
  }
  const type = item.type === 'question' ? 'Q' : 'A'
  return type + (props.coordinate.children.filter(i => i.type === 'question').findIndex(i => i.groupId === item.groupId) + 1)
}

const getBorderColor = item => {
  switch (item.type) {
    case 'answer':
      return 'green'
    case 'question':
      return 'blue'
    case 'decoration-image':
      return 'yellow'
    default:
      return 'black'
  }
}
</script>
<style scoped>
.add-more {
    position: absolute;
    bottom: 0;
    margin-bottom: 5px;
    text-align: center;
    width: 100%;
}
.problem-title {
    position: 'absolute';
    top: 0;
    background-color: #21205F;
    color: white;
    text-align: center;
    font-weight: bold;
}
::v-deep .dropdown-menu {
  z-index: 1000;
}
</style>
