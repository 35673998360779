<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <!-- Add new vibe button  -->
        <b-button
          variant="outline-primary"
          class="float-left"
          @click="showCreateModal"
        >
          {{ $t('actions.add-new-vibe') }}
        </b-button>

        <!-- Update History  -->
        <b-button
          v-b-modal.history-modal
          variant="primary"
          class="mx-1"
        >
          {{ $t('labels.history') }}
        </b-button>
        <!-- Settings  -->
        <b-dropdown :text="$t('navigation-menu.settings')">
          <b-dropdown-item @click="showSettingModal = true">
            {{ $t('labels.points-setting') }}
          </b-dropdown-item>

          <b-dropdown-item v-b-modal.rules-settings>
            {{ $t('labels.rules-setting') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <!-- Filters  -->
      <div class="d-flex align-items-baseline">
        <p>{{ $t('labels.filters') }}:</p>

        <b-button
          ref="point"
          class="filter-btn"
          variant="outline-primary"
          @click="filter('point', $event)"
        >
          {{ $t('labels.point') }}
        </b-button>

        <b-button
          ref="vibe"
          class="filter-btn"
          variant="outline-primary"
          @click="filter('vibe', $event)"
        >
          {{ $t('labels.vibe') }}
        </b-button>

        <b-button
          ref="badge"
          class="filter-btn"
          variant="outline-primary"
          @click="filter('badge', $event)"
        >
          {{ $t('labels.badge') }}
        </b-button>
      </div>

      <!-- Search bar -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">{{ $t('search') }}</label>
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('search')"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
    </div>

    <!-- Main Content  -->
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <vue-good-table
        :columns="columns"
        :rows="filterType ? filteredRows : rows"
        :sort-options="{ enabled: false }"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Serial Number  -->
          <span v-if="props.column.field === 'id'">
            {{ props.row.originalIndex + 1 }}
          </span>

          <!-- Icon  -->
          <span v-else-if="props.column.field === 'icon'">
            <p
              v-if="props.row.type === 'point'"
              class="text-center m-0"
            >-</p>

            <p
              v-else
              class="text-center mb-0"
            >
              <!-- If there is a link to the icon -->
              <img
                v-if="props.row.icon"
                :src="props.row.icon"
                width="60px"
                height="auto"
                alt=""
              >

              <!-- Else show default icon -->
              <img
                v-else
                :class="`reward-${props.row.type}`"
                :src="getDefaultImage(props.row)"
                alt=""
              >
            </p>
          </span>

          <!-- Status  -->
          <span v-else-if="props.column.field === 'status'">
            {{ props.row.status === "inactive" ? $t('points-setting-module.inactive') : $t('points-setting-module.active') }}
          </span>

          <!-- Price  -->
          <span v-else-if="props.column.field === 'price'">
            {{ props.row.is_free ? $t('points-setting-module.free') : props.row.price }}
          </span>

          <!-- Action Buttons  -->
          <span v-else-if="props.column.field === 'action'">
            <b-button
              size="sm"
              variant="primary"
              class="btn-icon"
              @click="edit(props.row)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </span>
        </template>
      </vue-good-table>
    </b-overlay>
    <create-edit :categories="categories" />
    <!-- Settings -->
    <settings
      :visible="showSettingModal"
      @close="showSettingModal = false"
    />

    <!-- Rules Settings -->
    <rules-settings />

    <!-- History Modal -->
    <reward-history />
  </div>
</template>
<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import useJwt from '@/auth/jwt/useJwt'
import { mapGetters } from 'vuex'
import {
  HIGHEST_POINTS_GAINER_BADGE,
  FIRST_LESSON_ACHEIVER_BADGE,
  EXTRAORDINARY_BADGE,
} from '@/const/badges'
import i18n from '@/libs/i18n'
import CreateEdit from './CreateEdit.vue'
import Settings from './components/Settings.vue'
import RewardHistory from './components/RewardHistory.vue'
import RulesSettings from './components/RulesSettings.vue'

export default {
  components: {
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BButton,
    CreateEdit,
    BOverlay,
    BDropdown,
    BDropdownItem,
    Settings,
    RewardHistory,
    RulesSettings,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      searchTerm: '',
      showOverlay: false,
      columns: [
        { label: 'S.No.', field: 'id', width: '96px' },
        {
          label: i18n.tc('points-setting-module.name'),
          field: 'name',
          width: '240px',
        },
        {
          label: i18n.tc('points-setting-module.type'),
          field: 'type',
        },
        {
          label: i18n.tc('points-setting-module.value'),
          field: 'value',
        },
        {
          label: i18n.tc('points-setting-module.price'),
          field: 'price',
        },
        {
          label: i18n.tc('points-setting-module.category'),
          field: 'category',
        },
        {
          label: i18n.tc('points-setting-module.icon'),
          field: 'icon',
        },
        {
          label: i18n.tc('points-setting-module.status'),
          field: 'status',
        },
        {
          label: i18n.tc('points-setting-module.action'),
          field: 'action',
        },
      ],
      rows: [],
      filterType: '',
      filteredRows: [],
      showSettingModal: false,
      badges: {
        'BADGE-HPG-L': HIGHEST_POINTS_GAINER_BADGE,
        'BADGE-HPG-C': HIGHEST_POINTS_GAINER_BADGE,
        'BADGE-FCD': FIRST_LESSON_ACHEIVER_BADGE,
        'BADGE-FLD': FIRST_LESSON_ACHEIVER_BADGE,
        'BADGE-MLD': EXTRAORDINARY_BADGE,
      },
      categories: [],
    }
  },
  computed: {
    ...mapGetters('app-chat', { defaultVibes: 'get_default_vibes' }),
    watch_rows() {
      return this.rows
    },
  },
  watch: {
    watch_rows() {
      if (this.filterType) this.filter(this.filterType)
    },
  },
  created() {
    this.getList()
  },
  methods: {
    edit(value) {
      this.$emit('EditPoints', value)
    },
    getList() {
      this.showOverlay = true
      useJwt
        .getPointsSetting()
        .then(response => {
          const { data } = response
          this.rows = data.data
        })
        .catch(err => {
          this.showErrorMessage(err)
        })
        .finally(() => {
          this.showOverlay = false
        })
    },
    filter(type) {
      this.filterType = type
      // reset the filtered row
      this.filteredRows = []

      // make the clicked filter selected and others not selected
      this.$refs[type].parentNode.children.forEach(element => {
        if (element.classList.contains('selected')) element.classList.toggle('selected')
      })
      this.$refs[type].classList.add('selected')

      // filter the rows, if all selected, show all data
      if (type !== 'all') {
        this.rows.forEach(row => {
          if (row.type === type) {
            this.filteredRows.push(row)
          }
        })

        // Show type only when all the rewards are shown
        this.$set(
          this.columns.filter(col => col.field === 'type')[0],
          'hidden',
          true,
        )
      } else {
        this.$set(
          this.columns.filter(col => col.field === 'type')[0],
          'hidden',
          false,
        )
      }

      // Don't show the icons for points
      if (type === 'point') {
        this.$set(
          this.columns.filter(col => col.field === 'icon')[0],
          'hidden',
          true,
        )
      } else {
        this.$set(
          this.columns.filter(col => col.field === 'icon')[0],
          'hidden',
          false,
        )
      }
    },
    showCreateModal() {
      if (!this.categories.length) {
        this.rows.forEach(row => {
          if (!this.categories.includes(row.category)) this.categories.push(row.category)
        })
      }
      this.$emit('CreatePoint')
    },
    getDefaultImage(form) {
      if (form.type === 'vibe') {
        return this.defaultVibes[form.code] || ''
      }
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return this.badges[form.code] ? require(`@/assets/images/${this.badges[form.code]}`) : ''
    },
  },
}
</script>

<style scoped>
.filter-btn {
  margin: 0 6px !important;
}

.filter-btn:focus {
  background-color: #978ef4 !important;
  color: #fff !important;
}

.selected {
  background-color: #978ef4;
  color: #fff;
}

.reward-vibe {
  width: 60px;
  height: auto;
}

.reward-badge {
  width: 50px;
  height: auto;
}
</style>
