<template>
  <div>
    <div class="row">
      <div class="col-4">
        <b-card no-body>
          <v-select v-model="selectedLibrary"
                    :placeholder="$t('curriculum-pages-module.all-libraries')"
                    :options="libraries"
                    :reduce="item => item.id"
                    label="name"
          />
        </b-card>
      </div>
      <div class="col-md-8">
        <p class="text-muted"
           style="font-size:13px; font-style: italic;"
        >
          <feather-icon icon="InfoIcon" />
          {{ $t('issue-report-module.info') }}
        </p>
      </div>
    </div>
    <b-card>
      <div class="issue-report">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h4 class="mr-2">
            {{ $t('issue-report-module.reported-issue') }}
          </h4>

          <!-- Add new report -->
          <b-button
            v-if="isATeacher"
            v-b-modal.report-issue-modal
            variant="primary"
          >
            {{ $t('issue-report-module.report-an-issue') }}
          </b-button>
        </div>
        <b-tabs pills>
          <b-tab
            v-for="status of statusOptions"
            :key="status.value"
            :title="status.text"
            lazy
          >
            <IssueListTable :key="selectedLibrary"
                            :status="status.value"
                            :library-id="Number(selectedLibrary)"
            />
          </b-tab>
        </b-tabs>

        <!-- Add new issue report -->
        <report-issue-modal
          :user-id="self.id"
          @add-issues="setIssues"
        />

      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard, BTabs, BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  USER_TYPE_TEACHER,
} from '@/const/userType'

import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import ReportIssueModal from './components/ReportIssueModal.vue'
import IssueListTable from './components/IssueListTables.vue'

export default {
  components: {
    BButton,
    ReportIssueModal,
    IssueListTable,
    BCard,
    BTabs,
    BTab,
    vSelect,
  },
  data() {
    return {
      self: getUserData(),
      selectedLibrary: this.$route.query.library_id ? Number(this.$route.query.library_id) : null,
      libraries: [],
      isProcessing: false,
      statusOptions: [
        {
          text: i18n.tc('issue-report-module.pending'),
          value: 'pending',
        },
        {
          text: i18n.tc('issue-report-module.in_progress'),
          value: 'in_progress',
        },
        {
          text: i18n.tc('issue-report-module.done'),
          value: 'done',
        },
      ],
    }
  },
  computed: {
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
  },
  mounted() {
    this.getLibrary()
  },
  methods: {
    setIssues(issue) {
      this.issues.unshift(issue)
    },
    getLibrary() {
      this.isProcessing = true
      useJwt.getLibrary({
        params: {
          library_id: this.selectedLibrary,
        },
      }).then(response => {
        this.libraries = response.data.data.map(item => ({
          name: item.name,
          id: item.id,
        }))
      }).catch(error => {
        this.showError(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>

<style lang="scss">
.issue-report {
  .table td,
  .table th {
    padding: 0.73rem 1rem 0.25rem !important;
  }
  .title {
    width: 12%;
  }
  .description{
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: -125px !important;
    margin-bottom: 8px;
  }
  .error-type {
    width: 12%;
  }
  .status {
    width: var(--status-width);
  }
  .assign {
    width: 18%;
  }
  .action {
    width: var(--action-width);
  }
}
</style>
