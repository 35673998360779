<template>
  <div class="floating-bot ">
    <gec-dragger
      v-show="chatOpened"
      id="draggable-chat-bot"
      :height="200"
      :width="300"
      left="70%"
      draggable-section="chatbot-body"
      :style="'inset: auto auto 20% 75%;'"
    >
      <section
        v-show="chatOpened"
        data-v-5e8ea5c2=""
        class="chat-app-window w-100 h-100  mb-2"
        style="background: transparent;"
      >
        <div
          data-v-5e8ea5c2=""
          class="active-chat w-100 h-100"
        >
          <chat-body
            :lesson-info="lessonInfo"
            :user-name="userName"
          />
        </div>
      </section>
    </gec-dragger>
    <div
      class="floating-bot-icon"
      draggable="false"
      @click.stop="setChatOpened"
    >
      <img
        v-if="!chatOpened"
        src="@/assets/images/icons/bot.png"
        class="img-fluid"
        style="width:40px; height:40px;"
        draggable="false"
      >
      <feather-icon
        v-if="chatOpened"
        size="40"
        class="text-white"
        icon="XIcon"
      />
    </div>
  </div>
</template>
<script>
import GecDragger from '@/layouts/components/GECDragger.vue'
import ChatBody from './components/ChatBody.vue'

export default {
  components: {
    ChatBody,
    GecDragger,
  },
  props: {
    lessonInfo: {
      type: Object,
      default: () => null,
    },
    userName: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      chatOpened: true,
    }
  },
  methods: {
    setChatOpened() {
      this.chatOpened = !this.chatOpened
    },
  },
}
</script>

<style lang="css">
.floating-bot-icon{
  z-index: 1031;
  background-color: rgb(253, 178, 39);
  padding:10px;
  border-radius:50%;
  cursor:pointer;
  transition: 0.3s;
  position: fixed;
  bottom: 5%;
  right: 5%;
}
</style>
