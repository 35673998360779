// import useJwt from '@/auth/jwt/useJwt'

// const gameListApi = useJwt.getGameLists()

// export default gameListApi

export default [
  { value: 'TW', text: '2048', image: '/game-image/2048.png' },
  { value: 'TOWER_GAME', text: 'Tower Game', image: '/game-image/tower-game.png' },
  { value: 'PAC_RUSH', text: 'PAC RUSH', image: '/game-image/pac-rush.jpeg' },
  { value: 'NINJA_FROG', text: 'Ninja Frog', image: '/game-image/ninja-frog.jpeg' },
  { value: 'CAR_RACING', text: 'Car Racing', image: '/game-image/car-racing.jpeg' },
  { value: 'MONSTER_RUN', text: 'Monster Run', image: '/game-image/monster-run.jpeg' },
  { value: 'BALLON_SHOOTER', text: 'Ballon Shooter', image: '/game-image/ballon-shooter.png' },
  { value: 'SPRINGLY_HEDGEHOG', text: 'Springy Hedgehog', image: '/game-image/springy-hedgehog.jpeg' },
  { value: 'BASKET_BALL', text: 'Basket ball', image: '/game-image/basketball.png' },
  { value: 'DINO_RUN', text: 'DINO RUN', image: '/game-image/dino-run.png' },
  { value: 'JUMP_BALL', text: 'Jump Ball', image: '/game-image/jump-ball.webp' },
  { value: 'PENGUIN_SKIP', text: 'Penguin Skip', image: '/game-image/penguin-skip.jpeg' },
]
