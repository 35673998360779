<template>
  <div>
    <b-button variant="outline-primary"
              @click="state.showForm = true"
    >
      {{ $t('template-module.create-template') }} <feather-icon icon="PlusIcon" />
    </b-button>

    <b-overlay :show="state.isProcessing"
               class="mt-2"
    >
      <h3>
        {{ $t('template-module.note-templates') }}
      </h3>
      <b-row class="mt-1">
        <b-col v-for="template, index of templates"
               :key="index"
               cols="3"
        >
          <TemplatePreviewCard :template="template"
                               @edit="state.templateToEdit = template"
                               @delete="state.templateIdToDelete = template.id"
          />
        </b-col>
      </b-row>
    </b-overlay>
    <ManageTemplate v-if="state.showForm || !!state.templateToEdit"
                    :template="state.templateToEdit"
                    @close="state.showForm= false; state.templateToEdit = null"
                    @reFetch="getNoteTemplates"
    />
    <ConfirmDelete :show="!!state.templateIdToDelete"
                   :on-delete="deleteTemplate"
                   :is-processing-delete="state.isDeleting"
                   @close="state.templateIdToDelete = 0"
    />
  </div>
</template>
<script setup>
import {
  BButton, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import { reactive, ref, getCurrentInstance } from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import ConfirmDelete from '@/views/common/components/DeleteModal.vue'
import ManageTemplate from './components/manage-template.vue'
import TemplatePreviewCard from './components/template-preview-card.vue'

const state = reactive({
  showForm: false,
  isProcessing: false,
  templateToEdit: null,
  templateIdToDelete: 0,
  isDeleting: false,
})

const templates = ref([])
const root = getCurrentInstance().proxy.$root

const getNoteTemplates = () => {
  state.isProcessing = true
  useJwt.getNoteTemplates().then(response => {
    templates.value = response.data.data
  }).finally(() => {
    state.isProcessing = false
  })
}

const deleteTemplate = () => {
  state.isDeleting = true
  useJwt.deleteNoteTemplate(state.templateIdToDelete).then(response => {
    root.showSuccessMessage(response)
    templates.value = templates.value.filter(item => item.id !== state.templateIdToDelete)
    state.templateIdToDelete = 0
  }).catch(error => {
    this.showErrorMessage(error)
  }).finally(() => {
    state.isDeleting = false
  })
}

getNoteTemplates()
</script>
