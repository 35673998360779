<template>
  <b-modal
    id="modal-grade-create"
    ref="modalRef"
    ok-only
    size="lg"
    ok-variant="primary"
    modal-class="modal-primary"
    centered
    :title="$t(`actions.${modalState}-game`)"
    hide-footer
    @close="close"
    @hide="close"
  >
    <b-row v-if="modalState === 'read'">
      <b-col md="4">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="m-1"
          @click="modalState = 'create'"
        >
          {{ $t("actions.create-game") }}
        </b-button>
      </b-col>
      <b-col
        v-if="games.length"
        md="8"
      >
        <b-badge
          v-for="game in games"
          :key="game.id"
          class="m-1"
          variant="primary"
          @click="editGame(game)"
        >
          {{ game.game_code + '-' + game.id }}
        </b-badge>
      </b-col>
    </b-row>
    <validation-observer
      v-if="modalState === 'create' || modalState === 'update'"
      ref="createGameRef"
      v-slot="{ invalid }"
      class="m-3"
    >
      <validation-provider
        v-slot="{ errors }"
        name="name"
        rules="required"
        vid="game"
      >
        <b-form-group
          :label="$t('labels.game')"
          label-for="title"
        >
          <b-form-select
            v-model="selectedGame"
            :options="gameOptions"
          />
        </b-form-group>
        <small class="text-danger">{{ errors[0] }}</small>

      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="problem"
        rules="required"
        vid="problems"
      >
        <b-form-group
          :label="$t('labels.problems')"
          label-for="title"
          class="mt-1"
        >
          <vue-good-table
            :columns="columns"
            :rows="rows.filter(row => !row.hasGameWrapper)"
            theme="my-theme"
            :sort-options="{
              enabled: false,
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span
                v-if="props.column.field === 'checkbox'"
                class="text-nowrap"
              >
                <b-form-checkbox
                  v-model="selectedProblems"
                  :value="props.row.id"
                />
              </span>
              <span
                v-if="props.column.field === 'name'"
                class="text-nowrap"
              >
                <span class="text-nowrap">{{ props.row.name }}</span>
              </span>
              <span
                v-else-if="props.column.field === 'problem_type'"
                class="text-nowrap"
              >
                <span class="text-nowrap">{{ props.row.problem_type }}</span>
              </span>
            </template>
          </vue-good-table>
          <input
            v-model="selectedProblems"
            hidden
          >
        </b-form-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
      <b-col
        md="12"
        class="d-flex justify-content-end mt-2"
      >
        <b-button
          variant="tertiary"
          class="btn-icon sm-mt-2 mr-2"
          @click="resetModal"
        >
          {{ $t('actions.cancel') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          :disabled="invalid || isProcessing"
          @click="modalState === 'update' ? updateGame() : createGame()"
        >
          <b-spinner
            v-show="isProcessing"
            small
          />
          {{ $t(`actions.${modalState}-game`) }}
        </b-button>
      </b-col>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal, BFormGroup, BFormSelect, BFormCheckbox, BCol, BRow, BButton, BSpinner, BBadge,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import {
  ref,
  toRefs,
  watch,
} from 'vue'
import GAME_LIST from '@/const/gamesList'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BBadge,
    ValidationObserver,
    ValidationProvider,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  props: {
    toggle: {
      type: Boolean,
      default: () => false,
    },
    lessonId: {
      type: String,
      default: null,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const modalRef = ref(null)
    const createGameRef = ref(null)
    const { toggle } = toRefs(props)
    const { lessonId } = toRefs(props)
    const selectedGame = ref('TW')
    const selectedProblems = ref([])
    const editId = ref(null)
    const modalState = ref('read')
    const gameOptions = ref(GAME_LIST)
    const games = ref([])
    const isProcessing = ref(false)

    const toggleModal = value => {
      if (modalRef.value) {
        if (value) modalRef.value.show()
        else modalRef.value.hide()
      }
    }
    const getGameByLesson = () => {
      useJwt.getGameByLesson(lessonId.value)
        .then(res => {
          games.value = [...res.data.data]
        })
    }
    const editGame = game => {
      editId.value = game.id
      modalState.value = 'update'
      selectedProblems.value = [...game.problems.map(p => p.id)]
      selectedGame.value = game.game_code
    }
    const resetModal = () => {
      modalState.value = 'read'
      selectedProblems.value = []
      selectedGame.value = 'TW'
    }

    toggleModal(toggle.value)
    getGameByLesson()
    watch(toggle, toggleModal)

    // methods
    const close = () => {
      emit('close')
    }

    return {
      modalRef,
      createGameRef,
      close,
      gameOptions,
      selectedGame,
      selectedProblems,
      isProcessing,
      modalState,
      games,
      editGame,
      resetModal,
      editId,
      getGameByLesson,
    }
  },
  methods: {
    createGame() {
      this.isProcessing = true
      const data = new FormData()
      data.append('game_code', this.selectedGame)
      data.append('lesson_id', this.$route.query.lesson_id)
      this.selectedProblems.forEach((g, i) => {
        data.append(`problem_id[${i}]`, g)
      })
      useJwt.createGame(data)
        .then(res => {
          this.showSuccessMessage(res)
          this.$emit('close')
          this.$emit('created')
        }).catch(err => {
          this.showError(err)
        }).finally(() => {
          this.isProcessing = false
          this.getGameByLesson()
          this.resetModal()
        })
    },
    updateGame() {
      this.isProcessing = true
      const data = new FormData()
      data.append('_method', 'put')
      data.append('game_code', this.selectedGame)
      data.append('lesson_id', this.$route.query.lesson_id)
      this.selectedProblems.forEach((g, i) => {
        data.append(`problem_id[${i}]`, g)
      })
      useJwt.updateGame(this.editId, data)
        .then(res => {
          this.showSuccessMessage(res)
          this.$emit('close')
          this.$emit('updated')
        }).catch(err => {
          this.showError(err)
        }).finally(() => {
          this.isProcessing = false
          this.getGameByLesson()
          this.resetModal()
        })
    },
  },
}
</script>
