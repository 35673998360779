<template>
  <b-modal :visible="true"
           size="lg"
           :title="$t('reservation-moduel.shopify_product')"
           hide-footer
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <b-row>
      <b-col md="12">
        <p class="text-muted"
           style="font-size:13px; font-style: italic;"
        >
          <feather-icon icon="InfoIcon" />
          {{ $t('reservation-moduel.shopify_product_info') }}
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-for="collect,index of collections"
             :key="index"
      >
        <b-card :class="selectedCollection == collect.product_id && 'bg-info'"
                role="button"
                @click="fetchProducts(collect.product_id)"
        >
          <h3 class="card-title">
            {{ collect.title }}
          </h3>
        </b-card>
      </b-col>
    </b-row>
    <b-modal v-if="showModal"
             @hide="resetSelectedProduct"
    >
      <h3>{{ $t('reservation-moduel.selected-product') }}</h3>
      <p>{{ selectedProduct.name }}</p>
      <b-button @click="callApiForSelectedProduct">
        {{ $t('reservation-moduel.call-api') }}
      </b-button>
    </b-modal>
    <b-overlay :show="isProcessing">
      <b-list-group v-if="products.length > 0">
        <b-list-group-item
          v-for="product in products"
          :key="product.id"
          class="font-weight-bold"
          role="button"
          @click="selectProduct(product)"
        >
          {{ product.title }} [#{{ product.id }}]
        </b-list-group-item>
      </b-list-group>
    </b-overlay>
  </b-modal>
</template>

<script>
// Import BootstrapVue components
import {
  BCard, BModal, BButton, BListGroup, BListGroupItem, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'

export default {
  components: {
    BCard,
    BModal,
    BButton,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BOverlay,
  },
  data() {
    return {
      products: [],
      selectedProduct: null,
      showModal: false,
      isProcessing: false,
      collections: [
        {
          title: i18n.tc('reservation-moduel.student-english-courses'),
          product_id: 293486198946,
        },
        {
          title: i18n.tc('reservation-moduel.adult-english-courses'),
          product_id: 293486231714,
        },
      ],
      selectedCollection: null,
    }
  },
  methods: {
    fetchProducts(collection) {
      this.isProcessing = true
      this.selectedCollection = collection
      useJwt.getShopifyProducts(collection).then(response => {
        this.products = response.data.data
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    selectProduct(product) {
      this.$emit('updateProduct', {
        product_id: product.id,
        name: product.title,
      })
      this.$emit('close')
    },
    resetSelectedProduct() {
      this.selectedProduct = null
      this.showModal = false
    },
    callApiForSelectedProduct() {
      // Simulate API call for the selected product
      // Replace this with your actual API call
    },
  },
}
</script>

  <style scoped>
  /* Add your custom CSS to style the component here */
  </style>
