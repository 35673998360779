<template>
  <div>
    <b-row
      class="d-flex justify-content-between"
    >
      <b-col
        md="4"
        class="d-flex"
      >
        <div class="pb-2">
          <b-button
            variant="default"
            :style="{background: scheduledWithOutClassRoomColor }"
            class="btn-icon rounded-circle"
          />
          <span class="ml-1">{{ $t('teacher-schedule.open') }}</span>
        </div>
        <div class="pb-2 ml-2">
          <b-button
            variant="default"
            :style="{background: scheduledWithClassRoomColor }"
            class="btn-icon rounded-circle"
          />
          <span class="ml-1">{{ $t('teacher-schedule.reserved') }}</span>
        </div>
      </b-col>
      <b-col
        v-if="!isATeacher"
        md="4"
      >
        <label>{{ $t('teacher-schedule.teacher') }}</label>
        <b-card no-body>
          <b-card-body class="p-0">
            <v-select
              v-model="teacher_id"
              transition=""
              :clearable="false"
              label="full_name"
              :reduce="(school) => school.id"
              :options="teacherList"
              :placeholder="$t('send-notification-module.select-teacher')"
              @input="fetchNewData()"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        md="4"
      >
        <label>{{ $t('teacher-schedule.classroom') }}</label>
        <b-card no-body>
          <b-card-body class="p-0">
            <v-select
              v-model="classRoomId"
              transition=""
              :clearable="false"
              label="text"
              :reduce="(value) => value.value"
              :options="classRoomsList"
              :placeholder="$t('all-classrooms')"
              @input="fetchNewData()"
            />
          </b-card-body>
        </b-card>
      </b-col>

    </b-row>
    <b-overlay :show="isProcessing">
      <b-tabs pills>
        <b-tab
          :title="$t('teacher-schedule.teacher-schedule')"
          lazy
        >
          <summary-view
            :teacher-id="teacher_id"
            :class-room-id="classRoomId"
            :scheduled-with-out-class-room-color="scheduledWithOutClassRoomColor"
            :scheduled-with-class-room-color="scheduledWithClassRoomColor"
          />
        </b-tab>
        <b-tab
          :title="$t('teacher-schedule.calendar')"
          lazy
        >
          <div
            class="overflow-hidden border teacher-schedular"
          >
            <b-card no-body>
              <b-card-body class="p-0">
                <FullCalendar
                  ref="fullCalendar"
                  :events="events"
                  :options="config"
                  @datesRender="handleMonthChanged"
                  @eventRender="eventRender"
                />
                <schedule
                  :show="showSchedular"
                  :start-date="selectedDate"
                  :class-room-id="classRoomId"
                  :selected-event-id="selectedEventId"
                  :teacher-list="teacherList"
                  :selected-teacher="teacher_id"
                  :is-school-user="isSchoolUser"
                  :class-rooms="classRoomsList"
                  @onSave="handleSaved"
                  @onDelete="handleDelete"
                  @close="showSchedular = false"
                />
              </b-card-body>
            </b-card>
          </div>
        </b-tab>
        <b-tab :title="$t('teacher-schedule.archived-schedule')"
               lazy
        >
          <ArchivedSchedule :class-room-id="classRoomId"
                            @refetch="fetchNewData"
          />
        </b-tab>
      </b-tabs>
    </b-overlay>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BRow, BCol, BOverlay, BButton, BTab, BTabs,
} from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import useJwt from '@/auth/jwt/useJwt'
import { USER_TYPE_SCHOOL, USER_TYPE_TEACHER } from '@/const/userType'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'
import teacherScheduleStatus from '@/const/teacherScheduleStatus'
import useApollo from '@/@core/graphql/useApollo'
// eslint-disable-next-line import/no-extraneous-dependencies
import allLocales from '@fullcalendar/core/locales-all'
import Schedule from './components/Schedule.vue'
import SummaryView from './components/Summary.vue'
import ArchivedSchedule from './components/ArchivedSchedule.vue'

const moment = require('moment')

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    Schedule,
    SummaryView,
    BCard,
    BCardBody,
    vSelect,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BTab,
    BTabs,
    ArchivedSchedule,
  },
  data() {
    return {
      events: [],
      showSchedular: false,
      selectedDate: '',
      classRoomId: null,
      selectedEventId: null,
      self: getUserData(),
      teacher_id: null,
      teacherList: [],
      isProcessing: false,
      start_date: '',
      end_date: '',
      status: teacherScheduleStatus,
      scheduledWithOutClassRoomColor: '#4CAF4F',
      scheduledWithClassRoomColor: 'grey',
      viewMode: 'calendar',
      viewModeOption: [
        { text: 'Calendar', value: 'calendar' },
        { text: 'Summary', value: 'summary' },
      ],
      classRoomsList: [],
    }
  },
  computed: {
    isSchoolUser() {
      return this.self.usertype === USER_TYPE_SCHOOL
    },
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
    config() {
      return {
        ...this.configOptions,
        ...this.eventHandlers,
      }
    },
    configOptions() {
      let locale = localStorage.getItem('locale') || 'en'
      if (locale === 'vn') {
        locale = 'vi'
      } else if (locale === 'jp') {
        locale = 'ja'
      }
      return {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        locales: allLocales,
        locale,
        rerenderDelay: 350,
        displayEventTime: false,
        events: this.events,
      }
    },
    eventHandlers() {
      return {
        dateClick: this.handleDateClick,
        eventClick: this.eventClick,
        select: this.onDateSelect,
        datesSet: this.handleMonthChanged,
        eventRender() {
        },
      }
    },
  },
  mounted() {
    if (this.isSchoolUser) {
      this.getTeachers()
    }
    this.getClassrooms()
  },
  methods: {
    getClassrooms() {
      useApollo.getMyClassRoom().then(response => {
        this.classRoomsList = [{ value: null, text: this.$i18n.tc('all-classrooms') }, ...response.data.rooms.data]
      })
    },
    handleDateClick(arg) {
      if (this.self.usertype === 'teacher') {
        this.selectedEventId = null
        this.showSchedular = true
        this.selectedDate = arg.dateStr
      }
    },
    handleSaved() {
      this.showSchedular = false
      this.fetchNewData()
      this.scheduleKey = Math.random()
    },
    handleDelete() {
      // let { events } = this
      // events = events.filter(ev => ev.id !== idToRemove)
      // this.events = events
      this.fetchNewData()
    },
    makeCalendarEvent(event) {
      const endDate = new Date(event.end_date)
      const requiredColor = event.classroom ? this.scheduledWithClassRoomColor : this.scheduledWithOutClassRoomColor
      const className = event.classroom ? `(${event.classroom_name})` : ''
      return {
        title: `${event.teacher.firstname} ${event.teacher.lastname} ${className}`,
        start: event.start_date,
        end: endDate,
        id: event.id,
        color: requiredColor,
        className: event.classroom ? 'reserved-class' : '',
        eventOverlap: !!event.classroom,
        isDayMode: !!event.viewMode,
      }
    },
    getTeachers() {
      useApollo.getUserDropDown({
        type: 'teacher',
        limit: 100,
      }).then(response => {
        const { data } = response.data.users
        const teachers = data.map(d => ({
          full_name: d.fullname,
          id: Number(d.id),
        }))
        this.teacherList = [{ full_name: 'All', id: null }, ...teachers]
      })
    },
    eventClick(event) {
      this.selectedEventId = event.event.id
      this.showSchedular = true
      if (event.event.extendedProps.isDayMode) {
        this.selectedDate = moment(event.event.startStr).format('YYYY-MM-DD')
      } else {
        this.selectedDate = ''
      }
    },
    fetchNewData(start_date = this.start_date, end_date = this.end_date, teacher_id = this.teacher_id) {
      // eslint-disable-next-line camelcase
      if (!start_date || !end_date) {
        return
      }
      this.isProcessing = true
      useJwt.getTeacherSchedules({
        params: {
          end_date,
          start_date,
          teacher_id,
          classroom: this.classRoomId,
        },
      }).then(response => {
        const { data } = response.data
        const events = data.map(event => this.makeCalendarEvent(event))
        this.events = events
      }).finally(() => { this.isProcessing = false })
    },
    handleMonthChanged(payload) {
      if (this.start_date === payload.startStr) {
        return
      }
      this.start_date = payload.startStr
      this.end_date = payload.endStr
      this.fetchNewData(payload.startStr, payload.endStr)
    },
    eventRender(ev) {
      console.log(ev)
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
[dir] .teacher-schedular .fc .fc-toolbar.fc-header-toolbar {
  padding: 1em;
}
.fc-timegrid-event-harness {
  left: 0 !important;
  .reserved-class {
    z-index: 100 !important;
  }
}
</style>
