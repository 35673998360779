<template>
  <!-- show all rating model -->
  <b-modal
    id="modal-show-all-rating"
    ref="modal-show-all-rating"
    ok-only
    size="md"
    ok-variant="primary"
    modal-class="modal-primary"
    centered
    :title="$t('rating.show-ratings')"
  >
    <b-col md="12">
      <!-- form -->
      <div v-if="isProcessing">
        <b-row class="d-flex justify-content-center">
          <b-spinner />
        </b-row>
      </div>
      <div v-else>
        <b-row>
          <b-col md="12">
            <b-row v-if="getRows < 0 || allRatings.criteria.length <= 0">
              <span>{{ $t("rating.not-available") }}</span>
            </b-row>
            <b-row
              v-for="(criterion, index) in allRatings.criteria"
              v-else
              :key="criterion.id"
              class="d-flex align-items-center"
            >
              <b-col md="8">
                <span>{{ criterion.title }}</span>
              </b-col>
              <b-col md="4">
                <div>
                  <b-form-rating
                    :id="`criteria-${index}`"
                    v-b-popover.hover.righttop="
                      `${criterion.avgRating} of total ${allRatings.totalRating} ratings`
                    "
                    :title="$t('rating.overall')"
                    :value="criterion.avgRating"
                    color="#7367f0"
                    readonly
                    inline
                    no-border
                    precision="2"
                  />
                </div>
              </b-col>
            </b-row>
            <div
              v-if="getRows > 0"
              class="mt-2"
            >
              <span>{{ $t("rating.comments") }}:</span>
              <b-row
                v-for="comment in paginatedRatings"
                :key="comment.userName"
                class="d-flex align-items-center my-1"
              >
                <b-col md="12">
                  <b-row class="d-flex">
                    <b-avatar
                      class="mx-1"
                      variant="info"
                      :src="comment.userAvatar"
                    />
                    <b-col class="pl-0">
                      <span>{{ comment.userName }}</span>
                      <p class="font-weight-light">
                        {{ comment.description }}
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            order="4"
          >
            <div class="d-flex justify-content-center">
              <b-pagination
                v-if="getRows > 0"
                v-model="pagination.currentPage"
                :disabled="isProcessing"
                :total-rows="getRows"
                :per-page="pagination.perPage"
                aria-controls="modal-show-all-rating"
                @change="filterRatings"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <template #modal-footer="{}">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="danger"
            @click="hideModal"
          >
            <b-spinner
              v-show="isProcessing"
              class="mr-1"
              small
            />
            <span>
              {{ $t('actions.close') }}
            </span>
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
  <!-- show all rating model -->
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
  BModal,
  BFormRating,
  BPagination,
  BAvatar,
  VBPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BSpinner,
    BModal,
    BFormRating,
    BCol,
    BButton,
    BPagination,
    BAvatar,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  props: {
    isProcessing: {
      type: Boolean,
      default: () => {},
    },
    allRatings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      pagination: {
        perPage: 3,
        currentPage: 1,
      },
      paginatedRatings: [],
    }
  },
  computed: {
    getRows() {
      this.paginate(this.pagination.perPage, 0)
      return this.allRatings.comments.length
    },
  },
  mounted() {
  },
  methods: {
    showModal() {
      this.$refs['modal-show-all-rating'].show()
    },
    hideModal() {
      this.$refs['modal-show-all-rating'].hide()
    },
    paginate(perPage, currentPage) {
      this.paginatedRatings = this.allRatings.comments.slice(
        currentPage * perPage,
        (currentPage + 1) * perPage,
      )
      return this.paginatedRatings
    },
    filterRatings(page) {
      return this.paginate(this.pagination.perPage, page - 1)
    },
  },
}
</script>

<style>
</style>
