<template>
  <div>
    <b-overlay v-if="!activeTimestampCreated"
               :show="isProcessing"
    >
      <div class="text-right">
        <validation-observer ref="problemCreateForm">
          <div class="row">
            <div class="col-3 offset-7">
              <validation-provider
                v-slot="{ errors }"
                name="name"
                rules="required"
                vid="name"
              >
                <b-form-input v-model="problemName"
                              placeholder="Problem name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-2">
              <b-button variant="primary"
                        :disabled="!selectedProblem || isSaving"
                        class="btn-block"
                        @click="createProblem"
              >
                <b-spinner v-if="isSaving"
                           small
                />   Create Problem
              </b-button>
            </div>
          </div>
        </validation-observer>
      </div>
      <group-table v-if="activeTimestamp"
                   ref="problemLists"
                   :columns="problemColumns"
                   :rows="problemRows"
                   class="clearfix"
                   :is-create-mode="true"
                   @markSelectedProblem="id => selectedProblem = id"
      />
    </b-overlay>
    <b-card v-else>
      {{ $t('messages.problem-created') }}
    </b-card>
  </div>
</template>
<script>

import GroupTable from '@/views/super/problem/GroupTable.vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import {
  BOverlay, BButton, BSpinner, BFormInput, BCard,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'

export default {
  components: {
    GroupTable,
    BOverlay,
    BButton,
    BSpinner,
    BFormInput,
    BCard,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    activeTimestamp: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      problemList: [],
      problemRows: [],
      isProcessing: false,
      selectedProblem: null,
      isSaving: false,
      problemName: null,
      problemType: {
        matching: i18n.tc('problem-types.match-the-following'),
        blank: i18n.tc('problem-types.fill-in-the-blanks'),
        fib_dropdown: i18n.tc('problem-types.fib-dropdown'),
        multiple: i18n.tc('problem-types.multiple-choice'),
        speech: i18n.tc('problem-types.speech'),
        text_selection: i18n.tc('problem-types.text-selection'),
        matching_listening: i18n.tc('problem-types.matching-listening'),
        multiple_listening: i18n.tc('problem-types.multiple-choice-listening'),
      },
    }
  },
  computed: {
    problemColumns() {
      return [
        {
          label: '',
          field: 'selectRow',
        },
        {
          label: i18n.tc('Name'),
          field: 'name',
        },
        {
          label: i18n.tc('Type'),
          field: 'problem_type',
        },
        {
          label: i18n.tc('Tags'),
          field: 'tags',
        },
        {
          label: i18n.tc('Wrapper'),
          field: 'wrapper',
        },
        {
          label: i18n.tc('actions.action'),
          field: 'action',
          category: false,
          thClass: 'text-center',
        },
      ]
    },
    activeTimestampCreated() {
      return this.$store.getters['problem/activeTimestampCreated']
    },
  },
  mounted() {
    if (!this.activeTimestampCreated) {
      this.getProblems()
    }
  },
  methods: {
    getProblems() {
      this.isProcessing = true
      useJwt.getProblemByLesson({
        params: {
          lid: this.$route.query.lesson_id,
        },
      }).then(res => {
        this.problemList = res.data.data.data
        res.data.data.data.forEach(problem => {
          this.addProblemsTable(problem)
        })
      }).finally(() => {
        this.isProcessing = false
      })
    },
    createProblem() {
      this.$refs.problemCreateForm.validate().then(success => {
        if (!success) {
          return
        }
        const problemIds = this.$refs.problemLists.selected
        this.isSaving = true
        useJwt.storeMultipleProblemWithVideo({
          problemIds,
          timeStampId: this.activeTimestamp,
          lesson_id: this.$route.query.lesson_id,
          problem_name: this.problemName,
        }).then(response => {
          this.$store.commit('problem/UPDATE_TIMESTAMP', Number(response.data.data.problems[0].video_timestamp_id))
          this.showSuccessMessage(response)
        }).finally(() => {
          this.isSaving = false
        })
      })
    },
    addProblemsTable(problem) {
      this.problemRows.push({
        name: problem.name,
        problem_type: this.problemType[problem.problem_type.name] ? this.problemType[problem.problem_type.name] : problem.problem_type.name,
        standard: problem.problem_set.standard ? problem.problem_set.standard.name : null,
        id: problem.id,
        tags: problem.tags,
        ddf_set: problem.ddf_set,
        is_public: problem.is_public === 1,
        hasGameWrapper: !!problem.game.length,
        hasVideoWrapper: !!problem.video_timestamp_id,
        hasHotSpotWrapper: !!problem.hotspot_point,
        sdp_problem_id: problem.sdp_problem_id,
        order_index: problem.order_index,
        skill_type: problem.skill_type,
      })
    },
  },
}
</script>
