<template>
  <b-card class="article-details">
    <b-row>
      <b-col class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <h3>
            {{ article.title }}
          </h3>
          <span
            v-if="article.request_id"
            style="margin-left:8px;margin-bottom: 0.5rem;"
          >
            <b-badge
              size="sm"
              variant="light-warning"
            >
              {{ $t('labels.not-published') }}
            </b-badge>

          </span>

        </div>
        <div class="pr-0">
          <b-badge
            v-if="article.is_featured===1"
            pill
            variant="success"
          >
            {{ $t('actions.featured') }}
          </b-badge>
          <b-dropdown
            v-if="isALeader || isACoLeader || isTheWriter"
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="canBePublished"
              @click="$emit('onPublish',article.request_id)"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">{{ $t("publish") }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isTheWriter"
              @click="$emit('onEdit',article)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t("edit") }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="article.request_id && (isALeader || isACoLeader)"
              @click="$emit('onDecline',article.id,article.request_id,type)"
            >
              <feather-icon :icon="'MinusCircleIcon'" />
              <span class="align-middle ml-50">{{ $t('decline') }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-else
              @click="$emit('onRemoveArticleFromNetwork',article.id,article.request_id || null,type)"
            >
              <feather-icon :icon="'TrashIcon'" />
              <span class="align-middle ml-50">{{ $t("delete") }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="isTheWriter"
              @click="$emit('onDelete',article.id, article.request_id || null,type)"
            >
              <feather-icon :icon="'Trash2Icon'" />
              <span class="align-middle ml-50">{{ $t("delete-permanently") }}</span>
            </b-dropdown-item>

            <b-col
              v-if="isTheWriter"
              class="pt-1"
            >
              <b-form-checkbox
                v-model="isFeatured"
                name="featured-article"
                inline
                switch
                size="16"
                @change="$emit('changeFeatured',article)"
              >
                <span style="font-size:14px; color:#6e6b7b;"> {{ $t('actions.featured') }} </span>
              </b-form-checkbox>
            </b-col>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <p
      class="m-0 p-0 d-flex justify-content-between"
    >
      <span v-if="article.student_name">
        {{ article.student_name ? article.student_name.toUpperCase() :"" }}
      </span>
      <span
        v-if="canBePublished"
      >
        {{ article.article_category.toUpperCase() }}
      </span>
    </p>

    <b-img
      v-if="article.image && article.video"
      :src="article.image"
      fluid
      class="w-100 my-2"
      style="height:360px;object-fit:cover"
    />
    <b-img
      v-else-if="article.image"
      :src="article.image"
      fluid
      class="w-100 my-2"
      style="height:360px;object-fit:cover"
    />
    <b-embed
      v-else-if="article.video && article.video !== 'null'"
      type="iframe"
      aspect="16by9"
      class="my-2"
      :src="article.video"
      allowfullscreen
      rounded
    />

    <b-card-text class="mt-1">
      {{ article.description }}
    </b-card-text>
  </b-card>
</template>
<script>
import {
  BRow, BCol, BCard, BImg, BCardText, BEmbed, BDropdown, BDropdownItem, VBTooltip, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'

export default {
  components: {
    BRow, BCard, BCol, BImg, BCardText, BEmbed, BDropdown, BDropdownItem, BBadge, BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'published',
    },
  },
  computed: {
    isTheWriter() {
      return this.self && this.self.id === this.article.student_id
    },
    canBePublished() {
      return this.article.request_id && (this.isALeader || this.isACoLeader)
    },
    ...mapGetters({
      self: 'network/self',
      hasJoinedNetwork: 'network/hasJoinedNetwork',
      isALeader: 'network/isALeader',
      articleType: 'network/activeTab',
      isACoLeader: 'network/isACoLeader',
      isAMember: 'network/isAMember',
    }),

  },
  data() {
    return {
      isFeatured: this.article.is_featured === 1,
    }
  },
  watch: {
    article() {
      this.isFeatured = this.article.is_featured === 1
    },
  },

  methods: {
    removeArticle() {
      if (this.canBePublished) this.$emit('onRejectArticle', this.article.request_id)
      else this.$emit('onRemoveArticleFromNetwork', this.article.id)
    },
  },

}
</script>

<style>
@media (max-width: 576px) {
  .article-details .dropdown-menu {
    transform: translate3d(-120px, 40px, 0px) !important;
  }
}
</style>
