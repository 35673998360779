<template>
  <div>
    <b-row>
      <b-col class="col-12 mb-1">
        <h3>{{ $t('role-permissions-module.role-permission') }}</h3>
      </b-col>
      <b-col class="col-12">
        <role-select-create ref="roleSelectCreate"
                            @selected="(r)=>selectedRole=r"
                            @onPermissionUpdate="getRoles"
        />
      </b-col>
    </b-row>
    <b-overlay :show="isProcessing">
      <b-row>
        <b-col class="col-12 mt-1">
          <b-row>
            <b-col
              v-for="permission,index in rolesPermissions"
              :key="index"
              md="4"
              lg="3"
              sm="12"
            >
              <permission-card-list :permission="permission"
                                    @onEdit="handlePermissionEdit"
                                    @onDelete="value => roleToDelete = value.id"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-overlay>
    <Pagination :pagination="pagination"
                :is-processing="isProcessing"
                @onPaginationChange="getRoles"
    />
    <DeleteModal :show="!!roleToDelete"
                 name="Delete"
                 :on-delete="handleRoleDelete"
                 :title="$t('delete')"
                 :is-processing-delete="isDeleting"
                 :sub-title="$t('delete-confirmation')"
    />
  </div>
</template>

<script>
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { BRow, BCol, BOverlay } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import Pagination from '@/views/common/components/Pagination.vue'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import PermissionCardList from './components/PermissionListCard.vue'
import RoleSelectCreate from './components/RoleSelectCreate.vue'

export default {
  components: {
    BRow, BCol, RoleSelectCreate, PermissionCardList, BOverlay, Pagination, DeleteModal,
  },
  data() {
    return {
      isProcessing: false,
      rolesPermissions: [],
      roleToDelete: null,
      isDeleting: false,
      roles: [
        {
          id: 1,
          title: 'International',
        },
        {
          id: 2,
          title: 'National',
        },
        {
          id: 3,
          title: 'District',
        },
      ],
      selectedRole: null,
      pagination: {
        total: 0,
        per_page: 0,
        current_page: 1,
        last_page: 1,
      },
      predefinedRoles: [
        {
          name: 'Create',
          textValue: 'create',
          value: false,
        },
        {
          name: 'Read',
          textValue: 'read',
          value: false,
        },
        {
          name: 'Update',
          textValue: 'update',
          value: false,
        },
        {
          name: 'Delete',
          textValue: 'delete',
          value: false,
        },
      ],
    }
  },
  mounted() {
    this.getRoles()
  },
  methods: {
    getRoles(page = this.pagination.current_page) {
      this.isProcessing = true
      useJwt.getRoles({
        params: {
          page,
        },
      }).then(response => {
        this.rolesPermissions = response.data.data?.data
        this.pagination = {
          total: response.data.data?.total,
          per_page: response.data.data?.per_page,
          current_page: response.data.data?.current_page,
          last_page: response.data.data?.last_page,
        }
      }).finally(() => {
        this.isProcessing = false
      })
    },
    handleRoleDelete() {
      this.isDeleting = true
      useJwt.deleteRoles(this.roleToDelete).then(response => {
        this.showSuccessMessage(response)
        this.roleToDelete = null
        this.getRoles()
      }).finally(() => {
        this.isDeleting = false
      })
    },
    handlePermissionEdit(role) {
      const roleInstance = { ...role }
      const roleRef = this.$refs.roleSelectCreate
      // const permissions = []
      // this.predefinedRoles.forEach(item => {
      //   // eslint-disable-next-line no-param-reassign
      //   item.value = !!role.role_permissions.find(i => i.name === `${role.module}_${item.textValue}`)
      //   permissions.push(item)
      // })
      // // eslint-disable-next-line no-param-reassign
      roleInstance.permissions = roleInstance.role_permissions.map(item => item.id)
      roleRef.allPermissions = roleInstance.role_permissions
      roleRef.newRole = { ...roleInstance }
      roleRef.showCreateModal = true
    },
  },
}
</script>

<style>

</style>
