<template>
  <b-overlay :show="state.isProcessing">
    <div class="text-right mb-2">
      <b-button variant="outline-primary"
                class="mr-2"
                @click="handleSelectAll"
      >
        {{ $t('teacher-schedule.select-all') }}</b-button>
      <b-button variant="outline-primary"
                class="mr-2"
                @click="state.selectedSchedules = []"
      >
        {{ $t('teacher-schedule.unselect-all') }}</b-button>
      <b-button variant="primary"
                class="mr-2"
                :disabled="state.selectedSchedules.length === 0"
                @click="state.showScheduleRestore = true"
      >
        {{ $t('teacher-schedule.restore') }}</b-button>
      <b-button variant="danger"
                :disabled="state.selectedSchedules.length === 0"
                @click="state.showScheduleDelete = true"
      >
        {{ $t('teacher-schedule.delete-permanently') }}</b-button>
    </div>
    <b-card body-class="p-0">
      <b-table :fields="columns"
               :items="state.trashedSchedules"
      >
        <template #cell(action)="data">
          <span class="text-nowrap">
            <b-form-checkbox
              :checked="state.selectedSchedules.includes(data.item.id)"
              @change="value => handleSwitch(value, data.item)"
            />
          </span>
        </template>
        <template #cell(deleted_at)="data">
          <span class="text-nowrap">
            {{ FROM_NOW(data.item.deleted_at) }}
          </span>
        </template>
      </b-table>
    </b-card>
    <ConfirmModal :show="state.showScheduleRestore"
                  :is-processing-delete="state.isProcessingConfirmation"
                  :on-delete="restoreOrDeleteSchedule"
                  :title="$t('teacher-schedule.restore')"
                  :sub-title="$t('teacher-schedule.restore-confirm')"
                  varient="primary"
    />
    <ConfirmModal :show="state.showScheduleDelete"
                  :is-processing-delete="state.isProcessingConfirmation"
                  :on-delete="restoreOrDeleteSchedule"
                  :title="$t('teacher-schedule.remove')"
                  :sub-title="$t('teacher-schedule.remove-confirmation')"
                  varient="danger"
    />
    <Pagination :pagination="state.pagination"
                :is-processing="state.isProcessing"
                @onPaginationChange="getArchivedSchedules"
    />
  </b-overlay>
</template>
<script setup>
import { onMounted, reactive, getCurrentInstance } from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import {
  BTable, BFormCheckbox, BCard, BButton, BOverlay,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import ConfirmModal from '@/views/common/components/DeleteModal.vue'
import Pagination from '@/views/common/components/Pagination.vue'

const props = defineProps({
  classRoomId: {
    type: [Number, Object],
    default: () => null,
  },
})

const state = reactive({
  trashedSchedules: [],
  selectedSchedules: [],
  showScheduleRestore: false,
  showScheduleDelete: false,
  isProcessingConfirmation: false,
  isProcessing: false,
  pagination: {
    total: 0,
    per_page: 0,
    current_page: 1,
  },
})
const root = getCurrentInstance().proxy.$root
const emit = defineEmits(['refetch'])
const columns = [
  {
    key: 'action',
    label: i18n.tc('curriculum-pages-module.action'),
  },
  {
    key: 'start_date',
    label: i18n.tc('class-module.start-time'),
  },
  {
    key: 'end_date',
    label: i18n.tc('class-module.end-time'),
  },
  {
    key: 'deleted_at',
    label: i18n.tc('teacher-schedule.deleted-at'),
  },
]

function getArchivedSchedules(page = 1) {
  state.isProcessing = true
  useJwt.getArchivedSchedule({
    params: {
      page,
      class_id: props.classRoomId,
    },
  }).then(response => {
    state.pagination = {
      total: response.data.data.schedules.total,
      per_page: response.data.data.schedules.per_page,
      current_page: response.data.data.schedules.current_page,
    }
    state.trashedSchedules = response.data.data.schedules.data
  }).finally(() => {
    state.isProcessing = false
  })
}

onMounted(() => {
  getArchivedSchedules()
})

function handleSwitch(bool, value) {
  if (bool) {
    state.selectedSchedules.push(value.id)
  } else {
    state.selectedSchedules = state.selectedSchedules.filter(item => item !== value.id)
  }
}

function restoreOrDeleteSchedule() {
  state.isProcessingConfirmation = true
  useJwt.restoreArchivedSchedule({
    schedules: state.selectedSchedules,
    toRemove: state.showScheduleDelete ? 'yes' : 'no',
  }).then(response => {
    root.showSuccessMessage(response)
    getArchivedSchedules()
    state.showScheduleRestore = false
    state.showScheduleDelete = false
    state.selectedSchedules = []
    emit('refetch')
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    state.isProcessingConfirmation = false
  })
}

function handleSelectAll() {
  state.selectedSchedules = []
  const ids = []
  state.trashedSchedules.forEach(item => {
    ids.push(item.id)
  })
  state.selectedSchedules = ids
}

</script>
