<template>
  <b-modal
    id="addTimestamp"
    ref="addTimestampModal"
    ok-only
    size="lg"
    ok-variant="primary"
    :ok-title="$t('confirm')"
    modal-class="modal-primary"
    centered
    :title="mode === 'create' ? $t('labels.create-problem.add-timestamp') : mode === 'edit' ? $t('labels.create-problem.update-timestamp') : $t('labels.create-problem.delete-timestamp')"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-col
      md="12"
      @keyup.enter="mode === 'create' ? addTimestamp() : mode === 'edit' ? updateTimestamp() : deleteTimestamp()"
    >
      <!-- form -->
      <div v-if="mode === 'delete'">
        <b-card-text>
          {{ $t('questions.delete-timestamp') }}
        </b-card-text>
      </div>
      <validation-observer
        v-else
        ref="addTimestamp"
      >
        <b-form @submit.prevent="addTimestamp">
          <b-row>
            <b-col md="5">
              <b-form-group
                :label="$t('labels.create-problem.start-minute')"
                label-for="timestamp"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="start minute"
                  rules="required|numeric"
                  vid="start_minute"
                >
                  <b-form-input
                    id="start-minute"
                    v-model="startTime.minute"
                    name="start-minute"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('labels.create-problem.placeholder.minute')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group
                :label="$t('labels.create-problem.start-second')"
                label-for="timestamp"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="start second"
                  rules="required|numeric"
                  vid="start_second"
                >
                  <b-form-input
                    id="start-second"
                    v-model="startTime.second"
                    name="start-second"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('labels.create-problem.placeholder.second')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col md="5">
              <b-form-group
                :label="$t('labels.create-problem.end-minute')"
                label-for="timestamp"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="end minute"
                  rules="required|numeric"
                  vid="end_minute"
                >
                  <b-form-input
                    id="end-minute"
                    v-model="endTime.minute"
                    name="end-minute"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('labels.create-problem.placeholder.minute')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group
                :label="$t('labels.create-problem.end-second')"
                label-for="timestamp"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="end second"
                  rules="required|numeric"
                  vid="end_second"
                >
                  <b-form-input
                    id="end-second"
                    v-model="endTime.second"
                    name="end-second"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('labels.create-problem.placeholder.second')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-col>
    <template #modal-footer="{}">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="isProcessing"
            @click="mode === 'create' ? addTimestamp() : mode === 'edit' ? updateTimestamp() : deleteTimestamp()"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ mode === 'create' ? $t('labels.create-problem.add-timestamp') : mode === 'edit' ? $t('labels.create-problem.update-timestamp') : $t('labels.create-problem.delete-timestamp') }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BSpinner,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BCardText,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import useJwt from '@/auth/jwt/useJwt'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    BForm,
    BFormGroup,
    BButton,
    BCardText,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    toggle: {
      type: Boolean,
      default: () => false,
    },
    mode: {
      type: String,
      default: () => '',
    },
    editData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    startTime: {
      minute: null,
      second: null,
    },
    endTime: {
      minute: null,
      second: null,
    },
    isProcessing: false,
  }),
  computed: {
    rows: {
      get() {
        return this.$store.getters['problem/getTimestamps']
      },
    },
    videoCreated: {
      get() {
        return this.$store.state.problem.create.videoCreated
      },
      set(value) {
        this.$store.commit('problem/SET_VIDEO_CREATED', value)
      },
    },
    video: {
      get() {
        return this.$store.state.problem.create.video
      },
      set(value) {
        this.$store.commit('problem/SET_VIDEO', value)
      },
    },
  },
  watch: {
    toggle(value) {
      if (value === true) {
        this.$refs.addTimestampModal.show()
      } else {
        this.$refs.addTimestampModal.hide()
      }
    },
    editData(value) {
      if (value.timestamp) {
        const [startMinute, startSecond] = value.timestamp.startTime.split(':')
        const [endMinute, endSecond] = value.timestamp.endTime.split(':')
        this.startTime.minute = startMinute
        this.startTime.second = startSecond
        this.endTime.minute = endMinute
        this.endTime.second = endSecond
      } else {
        this.startTime.minute = null
        this.startTime.second = null
        this.endTime.minute = null
        this.endTime.second = null
      }
    },
  },
  methods: {
    addTimestamp() {
      this.$refs.addTimestamp.validate().then(success => {
        if (!success) return
        if (!this.videoCreated) {
          if (!this.video) {
            this.showErrorMessage({ response: { status: 422, statusText: 'Invalid Url', data: { message: 'The video url is invalid' } } })
          } else if (this.isStartBigger()) this.showError('End time must be greater than start time')
          else {
            this.storeVideo({ method: 'POST' })
            this.$emit('close')
          }
        } else if (this.isTimestampAdded()) this.showError('This timestamp is already added')
        else if (this.isStartBigger()) this.showError('End time must be greater than start time')
        else {
          this.storeVideo({ method: 'PUT' })
          this.$emit('close')
        }
      })
    },
    isTimestampAdded() {
      let status = false
      this.rows.forEach(r => {
        const [startTimeMinute, startTimeSecond] = r.timestamp.startTime.split(':')
        const [endTimeMinute, endTimeSecond] = r.timestamp.endTime.split(':')
        if (
          parseInt(startTimeMinute, 10) === parseInt(this.startTime?.minute, 10)
          && parseInt(startTimeSecond, 10) === parseInt(this.startTime?.second, 10)
          && parseInt(endTimeMinute, 10) === parseInt(this.endTime?.minute, 10)
          && parseInt(endTimeSecond, 10) === parseInt(this.endTime?.second, 10)
        ) status = true
      })
      return status
    },
    isStartBigger() {
      let status = false
      const startMinute = parseInt(this.startTime?.minute, 10)
      const startSecond = parseInt(this.startTime?.second, 10)
      const endMinute = parseInt(this.endTime?.minute, 10)
      const endSecond = parseInt(this.endTime?.second, 10)

      if (parseInt(this.startTime?.minute, 10) === parseInt(this.endTime?.minute, 10) && parseInt(this.startTime?.second, 10) === parseInt(this.endTime?.second, 10)) status = true

      else if (((startMinute * 60) + startSecond) > ((endMinute * 60) + endSecond)) {
        status = true
      }
      return status
    },
    storeVideo({ method }) {
      const data = new FormData()
      data.append('url', this.video.url)
      let index = -1
      this.rows.forEach((r, i) => {
        data.append(`start_time[${i}]`, r.timestamp.startTime)
        data.append(`time[${i}]`, r.timestamp.endTime)
        index = i
      })
      data.append(`start_time[${index + 1}]`, `${this.startTime.minute}:${this.startTime.second}`)
      data.append(`time[${index + 1}]`, `${this.endTime.minute}:${this.endTime.second}`)

      let type
      let req
      if (method === 'PUT') {
        type = 'updateVideo'
        data.append('_method', 'put')
        req = useJwt[type](this.video.id, data)
      } else {
        type = 'storeVideo'
        req = useJwt[type](data)
      }
      this.isProcessing = true
      req.then(res => {
        this.showSuccessMessage(res)
        this.$emit('created')

        // this.$store.commit('problem/CLEAR_TIMESTAMP', true)
        if (method !== 'PUT') {
          this.$store.commit('problem/SET_VIDEO_CREATED', true)
          this.video = { url: res.data.data.url, id: res.data.data.id }
        }
        const { time } = res.data.data
        const t = time[time.length - 1]
        this.$store.commit('problem/ADD_TIMESTAMP', { id: t.id, timestamp: { startTime: t.start_time, endTime: t.time }, created: false })
        this.startTime.minute = null
        this.startTime.second = null
        this.endTime.minute = null
        this.endTime.second = null
      }).catch(error => {
        if (error.response?.data) {
          const { errors } = error.response.data
          Object.keys(errors).forEach(key => {
            this.showError(errors[key][0])
          })
        } else this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    updateTimestamp() {
      const startTime = `${this.startTime.minute}:${this.startTime.second}`
      const endTime = `${this.endTime.minute}:${this.endTime.second}`
      this.$refs.addTimestamp.validate().then(success => {
        if (success) {
          if (this.isTimestampAdded()) this.showError('This timestamp is already added')
          else {
            useJwt.updateTimestamp(this.editData.id, { time: endTime, start_time: startTime }).then(res => {
              this.showSuccessMessage(res)
              this.$store.commit('problem/EDIT_TIMESTAMP', { id: this.editData.id, timestamp: { startTime, endTime } })
              this.$emit('close')
            }).catch(err => {
              this.showError(err)
            })
          }
        }
      })
    },
    deleteTimestamp() {
      this.$emit('delete')
    },
  },
}
</script>
