<template>
  <div>
    <div v-for="scr,index of scores"
         :key="index"
         class="mb-2"
    >
      <h4>{{ scr.text }}</h4>
      <b-progress :max="100"
                  :value="scr.value"
                  height="2rem"
                  show-value
                  :variant="getScoreColor(scr.value)"
      />
    </div>
  </div>
</template>

<script>
import { BProgress } from 'bootstrap-vue'

export default {
  components: {
    BProgress,
  },
  props: {
    scores: {
      type: Array,
      default: () => [0, 0, 0, 0],
    },
  },
  methods: {
    getScoreColor(score) {
      if (score < 50) {
        return 'danger'
      }
      if (score >= 50 && score <= 80) {
        return 'warning'
      }
      return 'success'
    },
  },
}
</script>

<style scoped>
/* Add any custom styles here */
</style>
