<template>
  <div>
    <b-col md="12">
      <validation-observer ref="createProblemForm">
        <b-col
          md="12"
          class="px-0"
        >
          <div>
            <b-tabs
              pills
              align="center"
              class="lang-level-tabs"
            >
              <b-tab
                v-for="(level, index) in problem.levels"
                :key="index + level"
                :title="getLangLevelTransText(level)"
                :title-item-class="showLangLevel ? '' : 'd-none'"
                :active="activeTabIndex === index"
                @click="activeTabIndex=index"
              >

                <b-button
                  variant="success"
                  :disabled="isProcessing"
                  @click="showCopyProblemModal = true"
                >
                  {{ $t('labels.copy-problem') }}
                </b-button>
                <!-- <b-dropdown
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  text="Copy to lesson"
                  variant="outline-primary"
                  class="lesson-list"
                >
                  <b-dropdown-item
                    v-for="lesson in lessons"
                    :key="lesson.id"
                    @click="copyLesson(lesson.id)"
                  >
                    {{ lesson.name }}
                  </b-dropdown-item>
                </b-dropdown> -->
                <b-row class="mt-3">
                  <b-col
                    md="12"
                    lg="4"
                    class="card p-2"
                  >
                    <!-- Name -->
                    <b-form-group
                      :label="$t('labels.create-problem.problem-name')"
                      label-for="name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="name"
                        rules="required|max:256"
                        vid="name"
                      >
                        <b-form-input
                          :id="`name-${index}`"
                          v-model="problem.name"
                          :placeholder="$t('labels.name')"
                          name="name"
                          @keyup.enter="!errors.length ? updateProblem() : null"
                          @blur="!errors.length ? updateProblem() : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Name -->
                    <b-form-group
                      :label="$t('labels.create-problem.tts-support')"
                      label-for="Domain"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Domain"
                        rules="required"
                      >
                        <b-form-select
                          id="domain"
                          v-model="problem.ttsSupport"
                          :options=" [ { text: 'Yes', value: true}, {text: 'No' , value: false} ]"
                          name="tts_support"
                          :state="errors.length > 0 ? false : null"
                          @change="updateProblem()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Domain -->
                    <b-form-group
                      :label="$t('labels.create-problem.domain')"
                      label-for="Domain"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Domain"
                        rules="required"
                      >
                        <b-form-select
                          id="domain"
                          v-model="selectedDomain"
                          :options="domains"
                          name="standard"
                          :state="errors.length > 0 ? false : null"
                          @change="fetchStandard"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Domain -->

                    <!-- Standard -->
                    <b-form-group
                      :label="$t('labels.create-problem.standard')"
                      label-for="standard"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="standard"
                        vid="standard"
                      >
                        <b-form-select
                          id="standard"
                          v-model="problem.standard_id"
                          :options="standards"
                          :disabled="selectedDomain ? false:true"
                          name="standard"
                          @change="updateProblem"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Standard -->

                    <!-- Tags -->
                    <b-form-group
                      :label="$t('labels.create-problem.tags')"
                      label-for="tag"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="tag"
                        rules="required"
                      >
                        <v-select
                          v-model="problem.tags"
                          :class="!!errors[0] ? 'v-select-error':''"
                          multiple
                          label="text"
                          :options="tags"
                          placeholder="Please select a Tag"
                          :state="errors.length > 0 ? false : null"
                          @input="updateProblem"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- /Tags -->

                    <!-- Skill Type -->
                    <b-form-group
                      :label="$t('labels.create-problem.skill-type')"
                      label-for="skill-type"
                      rules="required"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="skill-type"
                        vid="skill-type"
                      >
                        <b-form-select
                          id="skill-type"
                          v-model="problem.skill_type"
                          :options="skillTypes"
                          name="skill-type"
                          @change="updateProblem"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- /Skill Type -->

                    <!-- Theme -->
                    <b-form-group
                      :label="$t('labels.create-problem.theme')"
                      label-for="theme"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="theme"
                        vid="theme"
                      >
                        <b-form-select
                          id="theme"
                          v-model="problem.theme"
                          :options="themes"
                          name="theme"
                          @change="updateTheme"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <theme-preview :theme="problem.theme" />
                    <!-- Theme -->

                    <video-type
                      v-if="videoTimeStamp"
                      :video-time-stamp="videoTimeStamp"
                      @updated="$emit('fetch')"
                    />

                    <!-- Statement & Feedback -->
                    <div>
                      <!-- Statement -->
                      <div v-if="!videoTimeStamp">
                        <label>{{ $t('labels.create-problem.problem-statement') }}</label>
                        <b-form-group label="Hide Statement">
                          <b-form-checkbox v-model="problem.hideStatement"
                                           switch
                                           @change="updateProblem()"
                          />
                        </b-form-group>
                        <b-tabs
                          pills
                          class="mx-0 px-0 mt-1"
                        >
                          <b-tab
                            title="Image"
                            :active="problem.stType[index] === 'image'"
                            @click="problem.stType[index] = 'image'"
                          >
                            <b-img
                              v-if="problem.statementImage.url[index]"
                              rounded
                              fluid
                              :src="problem.statementImage.url[index]"
                              height="150"
                              width="150"
                              class="mt-2 mr-2"
                            />
                            <div class="media-body">
                              <b-form-group
                                :label="$t('labels.create-problem.statement-image')"
                                :label-for="`statement-matching-${level}`"
                              >
                                <b-form-file
                                  :id="`statement-matching-${level}`"
                                  :placeholder="$t('labels.file-placeholder')"
                                  :browse-text="$t('browseButton')"
                                  accept="image/jpeg, image/png, image/gif"
                                  @change="handleStatementImage($event, 'statementImage', index)"
                                />
                              </b-form-group>
                            </div>
                          </b-tab>
                          <b-tab
                            title="Audio"
                            :active="problem.stType[index] === 'audio'"
                            @click="problem.stType[index] = 'audio'"
                          >
                            <b-form-group
                              :label="$t('labels.create-problem.statement-audio')"
                              label-for="audio"
                            >
                              <Recorder
                                :url="problem.statementAudio.url[index]"
                                @onSelectedFromLibrary="value => updateProblemStatementAudio(value, index, 'st_image')"
                                @input="setStatementAudio($event, index, 'statementAudio')"
                              />
                            </b-form-group>
                          </b-tab>
                        </b-tabs>
                        <b-form-group
                          :label="$t('labels.create-problem.statement')"
                          label-for="statement"
                        >
                          <div v-if="!problem.statement[index].statementTemplateId">
                            <quill-editor-custom
                              v-model="problem.statement[index].statement"
                              :placeholder="$t('labels.create-problem.placeholder.statement')"
                              @blur="updateProblemStatement(index)"
                            />
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="outline-primary"
                              class="mt-2"
                              block
                              @click="()=>{templateType='statement';fetchTemplate('new')}"
                            >
                              {{ 'Create Template' }}
                            </b-button>
                          </div>
                          <b-button
                            v-else
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            block
                            :disabled="templateBeingFetched === problem.statement[index].statementTemplateId"
                            @click="()=>{ templateType='statement';fetchTemplate(problem.statement[index].statementTemplateId)}"
                          >
                            {{ 'Update Template' }}
                          </b-button>
                        </b-form-group>
                      </div>
                      <!-- /Statement -->

                      <!-- Feedback -->
                      <label>{{ $t('labels.create-problem.problem-feedback') }}</label>
                      <b-form-group label="Hide FeedBack">
                        <b-form-checkbox v-model="problem.hideFeedBack"
                                         switch
                                         @change="updateProblem()"
                        />
                      </b-form-group>
                      <b-tabs
                        pills
                        class="mx-0 px-0 mt-1"
                      >
                        <b-tab
                          title="Image"
                          :active="problem.fbType[index] === 'image'"
                          @click="problem.fbType[index] = 'image'"
                        >
                          <b-img
                            v-if="problem.feedbackImage.url[index]"
                            rounded
                            fluid
                            :src="problem.feedbackImage.url[index]"
                            height="150"
                            width="150"
                            class="mt-2 mr-2"
                          />
                          <div class="media-body">
                            <b-form-group
                              :label="$t('labels.create-problem.feedback-image')"
                              :label-for="`feedback-matching${level}`"
                            >
                              <b-form-file
                                :id="`feedback-matching${level}`"
                                :placeholder="$t('labels.file-placeholder')"
                                :browse-text="$t('browseButton')"
                                accept="image/jpeg, image/png, image/gif"
                                @change="handleStatementImage($event, 'feedbackImage', index)"
                              />
                            </b-form-group>
                          </div>
                        </b-tab>
                        <b-tab
                          title="Audio"
                          :active="problem.fbType[index] === 'audio'"
                          @click="problem.fbType[index] = 'audio'"
                        >
                          <b-form-group
                            :label="$t('labels.create-problem.feedback-audio')"
                            label-for="audio"
                          >
                            <Recorder
                              :url="problem.feedbackAudio.url[index]"
                              @input="setStatementAudio($event, index, 'feedbackAudio')"
                              @onSelectedFromLibrary="value => updateProblemStatementAudio(value, index, 'fb_image')"
                            />
                          </b-form-group>
                        </b-tab>
                      </b-tabs>
                      <b-form-group
                        :label="$t('labels.create-problem.feedback')"
                        label-for="feedback"
                      >
                        <div v-if="!problem.statement[index].feedbackTemplateId">
                          <quill-editor-custom
                            v-model="problem.statement[index].feedback"
                            :placeholder="$t('labels.create-problem.placeholder.feedback')"
                            @blur="updateProblemStatement(index)"
                          />
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            class="mt-2"
                            block
                            @click="()=>{templateType='feedback';fetchTemplate('new')}"
                          >
                            {{ 'Create Template' }}
                          </b-button>
                        </div>
                        <b-button
                          v-else
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="outline-primary"
                          block
                          :disabled="templateBeingFetched === problem.statement[index].feedbackTemplateId"
                          @click="()=>{ templateType='feedback';fetchTemplate(problem.statement[index].feedbackTemplateId)}"
                        >
                          {{ 'Update Template' }}
                        </b-button>
                      </b-form-group>
                      <!-- /Feedback -->

                      <!-- <word-net-section
                        :type="'course'"
                        :problem-statement-id="problem.statement[index].id"
                        :main-text="problem.statement[index].statement +' '+ problem.statement[index].feedback"
                      /> -->
                    </div>
                    <!-- Statement & Feedback -->

                  </b-col>
                  <b-col
                    md="12"
                    lg="8"
                    class="pl-md-0 pl-lg-1 pr-0"
                  >
                    <b-card>
                      <!-- CEFR Subject -->
                      <b-form-group
                        :label="$t('labels.create-problem.cefr-subject')"
                        label-for="cefrSubject"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="cefrSubject"
                          vid="cefrSubject"
                        >
                          <b-form-select
                            id="cefrSubject"
                            v-model="problem.cefr.selectedSubjects[index]"
                            :options="subjectList"
                            name="cefrSubject"
                            @change="checkFetchCefrDomain(index, true)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- CEFR Subject -->
                      <!-- CEFR Domain -->
                      <b-form-group
                        :label="$t('labels.create-problem.cefr-domain')"
                        label-for="cefrDomain"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="cefrDomain"
                          vid="cefrDomain"
                        >
                          <b-form-select
                            id="cefrDomain"
                            v-model="problem.cefr.selectedDomains[index]"
                            :options="cefrDomainList[index]"
                            :disabled="problem.cefr.selectedSubjects[index] ? false:true"
                            name="cefrDomain"
                            @change="checkFetchCefrStandard(index, true)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- CEFR Domain -->
                      <!-- CEFR Standard -->
                      <b-form-group
                        :label="$t('labels.create-problem.cefr-standard')"
                        label-for="cefrStandard"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="cefrStandard"
                          vid="cefrStandard"
                        >
                          <b-form-select
                            id="cefrStandard"
                            v-model="problem.cefr.selectedStandards[index]"
                            :options="cefrStandardList[index]"
                            :disabled="problem.cefr.selectedDomains[index] ? false:true"
                            name="cefrStandard"
                            @change="updateCefrStandard(index)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- problem descriptions -->
                      <b-form-group
                        :label="$t('labels.create-problem.problem-description')"
                        label-for="cefrStandard"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="problemDescription"
                          vid="problemDescription"
                        >
                          <b-form-input
                            id="pageLink"
                            v-model="problem.description"
                            :placeholder="$t('labels.create-problem.problem-description')"
                            name="problemDescription"
                            @blur="updateProblem"
                            @keyup.enter="updateProblem"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- Page Link -->
                      <b-form-group
                        :label="$t('labels.create-problem.page-link')"
                        label-for="cefrStandard"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="pageLink"
                          vid="pageLink"
                        >
                          <b-form-input
                            id="pageLink"
                            v-model="problem.pageLink[index]"
                            :placeholder="$t('labels.create-problem.placeholder.page-link')"
                            name="pageLink"
                            @blur="updatePageLink(index, 0)"
                            @keyup.enter="updatePageLink(index, 0)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <!-- Dropdown Switch  -->
                      <b-form-group
                        :label="$t('labels.create-problem.dropdown')"
                        label-for="fib-dropdown"
                      >
                        <b-form-checkbox
                          v-model="fib_dropdown"
                          name="fib-dropdown"
                          value="1"
                          unchecked-value="0"
                          inline
                          switch
                          size="16"
                          @change="updateProblemType"
                        />
                      </b-form-group>
                      <b-row>
                        <b-col md="2">
                          <!-- Dropdown Switch  -->
                          <b-form-group
                            label="Hide Hints"
                            label-for="hideAnswerHints"
                          >
                            <b-form-checkbox
                              v-model="problem.hideAnswerHints"
                              name="hideAnswerHints"
                              value="1"
                              unchecked-value="0"
                              inline
                              switch
                              size="16"
                              @change="updateProblem()"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="2">
                          <!-- Dropdown Switch  -->
                          <b-form-group
                            label="Hide Image Hints"
                            label-for="hideImageHints"
                          >
                            <b-form-checkbox
                              v-model="problem.hideImageHints"
                              name="hideImageHints"
                              value="1"
                              unchecked-value="0"
                              inline
                              switch
                              size="16"
                              @change="updateProblem()"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="2">
                          <!-- Dropdown Switch  -->
                          <b-form-group
                            label="Hide Text Hints"
                            label-for="hideTextHints"
                          >
                            <b-form-checkbox
                              v-model="problem.hideTextHints"
                              name="hideTextHints"
                              value="1"
                              unchecked-value="0"
                              inline
                              switch
                              size="16"
                              @change="updateProblem()"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <!-- Dropdown Switch  -->
                          <b-form-group
                            label="Disable Random Hints"
                            label-for="disableRandomHints"
                          >
                            <b-form-checkbox
                              v-model="problem.disableRandomHints"
                              name="disableRandomHints"
                              value="1"
                              unchecked-value="0"
                              inline
                              switch
                              size="16"
                              @change="updateProblem()"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col v-if="problem.questions[index][0]"
                               md="3"
                        >
                          <Recorder :url="problem.questions[index][0].audioFile"
                                    @input="setQuestionAudio($event, index)"
                                    @onRemove="problem.questions[index][0].audioFile = ''; updateQuestion(index, 0)"
                          />
                        </b-col>
                      </b-row>
                      <!-- Question -->
                      <b-form-group
                        :label="$t('labels.create-problem.question')"
                        label-for="question"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="question"
                          rules="required|noHtml"
                          vid="question"
                        >
                          <!-- <quill-editor-custom
                            v-model="problem.questions[problem.levels.indexOf(level)][0].text"
                            :placeholder="$t('labels.create-problem.placeholder.question')"
                            @blur="updateQuestion(index, 0)"
                            @ready="$emit('ready')"
                          /> -->
                          <!-- create edit template -->
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            block
                            @click="() => {showTemplateIndex = index; questionTemplate = problem.questions[index][0].text}"
                          >
                            {{ "Update Template" }}
                          </b-button>
                          <create-template
                            :show="showTemplateIndex === index"
                            :template="questionTemplate"
                            title="Question Template"
                            problem-type="fib_problem"
                            :avoid-save="true"
                            :initial-template-options="[
                              { value: 'none', text: 'None' },
                              { value: 'paragraph', text: 'Paragraph' },
                              { value: 'image-paragraph', text: 'Image & Paragraph' },
                              { value: 'shapes', text: 'Shapes' },
                              { value: 'image-qa', text: 'Question and Answer with Image' },
                              { value: 'cards', text: 'Cards' },
                              { value: 'centered-cards', text: 'Centered Cards' },
                              { value: 'side-cards', text: 'Side Cards' },
                            ]"
                            @close="() => { showTemplateIndex = null; questionTemplate = {}}"
                            @templateStored="(data)=>{problem.questions[index][0].text = data; showTemplateIndex = null; questionTemplate = {}, updateQuestion(index, 0)}"
                          />
                          <!-- create edit template -->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- Question -->
                    </b-card>
                    <b-card>
                      <b-col
                        class="d-flex justify-content-betweem"
                        md="12"
                      >
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-success"
                          class="mr-2"
                          @click="$emit('showQuestionPreview',getDataForQuestionPreview())"
                        >
                          {{ $t("actions.preview") }}
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          @click="$router.push({ path: `/${AUTH_USER().usertype}/problem`, query: { lesson_id: $route.query.lesson_id } })"
                        >
                          {{ $t("actions.back") }}
                        </b-button>
                      </b-col>
                    </b-card>
                  </b-col>
                </b-row>

              </b-tab>
            </b-tabs>
          </div>
        </b-col>
      </validation-observer>
    </b-col>
    <!-- create edit template -->
    <create-template
      :show="visibleTemplate!=null"
      :template="problemTemplates[visibleTemplate]"
      :title="`Update ${templateType} Template`"
      :extra-template-para="extraTemplatePara"
      @removeExtraParams="extraTemplatePara = null"
      @templateStored="templateStored"
      @close="visibleTemplate= null"
    />
    <!-- create edit template -->

    <copy-to-lesson-modal
      :problem-id="problemData.id"
      :show="showCopyProblemModal"
      @close="showCopyProblemModal=false"
    />

  </div>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BTab,
  BTabs,
  BButton,
  BRow,
  BImg,
  BFormFile,
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
} from 'vee-validate/dist/vee-validate.full'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ThemePreview from '@views/common/components/ThemePreview.vue'
import CreateTemplate from '@views/common/templates/CreateEdit.vue'
import QuillEditorCustom from '@views/common/components/QuillEditorCustom.vue'
import vSelect from 'vue-select'
import SkillTypes from '@/const/skillTypes'
import Recorder from '../../components/recorder.vue'
import VideoType from '../../components/edit/VideoType.vue'
import CopyToLessonModal from '../components/CopyToLessonModal.vue'
// import request from '../request'

extend('noHtml', value => {
  const regexp = /\*\*\*(.*?)\*\*\*/g
  const ans = [...value.matchAll(regexp)]
  let valid = true
  ans.forEach(a => {
    if (a[1].includes('&lt;') && a[1].includes('&gt;')) {
      valid = false
    }
  })
  return valid ? true : 'Don\'t include html or html tags inside *** and ***'
})

export default {
  components: {
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BTab,
    BTabs,
    BButton,
    BRow,
    BImg,
    BFormFile,
    Recorder,
    BCard,
    QuillEditorCustom,
    ThemePreview,
    VideoType,
    CreateTemplate,
    // WordNetSection,
    CopyToLessonModal,
    vSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    problemData: {
      type: Object,
      default: () => {},
    },
    domains: {
      type: Array,
      default: () => [{ value: null, text: 'Please select a domain' }],
    },
    subjectList: {
      type: Array,
      default: () => [{ value: null, text: 'Please select a subject' }],
    },
    tags: {
      type: Array,
      default: () => { },
    },
  },
  data: () => ({
    showCopyProblemModal: false,
    isProcessing: false,
    activeTabIndex: 3,
    problem: {
      name: '',
      allLevels: ['A1', 'A2', 'B1', 'B2'],
      levels: [],
      questions: [[], [], [], []],
      statement: [{}, {}, {}, {}],
      standard_id: null,
      statementImage: { url: ['', '', '', ''], file: ['', '', '', ''] },
      feedbackImage: { url: ['', '', '', ''], file: ['', '', '', ''] },
      stType: [],
      fbType: [],
      statementAudio: { url: ['', '', '', ''], file: ['', '', '', ''] },
      feedbackAudio: { url: ['', '', '', ''], file: ['', '', '', ''] },
      theme: null,
      cefr: {
        selectedSubjects: [null, null, null, null],
        selectedDomains: [null, null, null, null],
        selectedStandards: [null, null, null, null],
      },
      pageLink: [],
      skill_type: '',
    },
    isValid: false,
    changed: {
      name: false,
      statement: false,
      feedback: false,
      standard_id: false,
    },
    selectedDomain: null,
    standards: [{ value: null, text: 'Please select a standard' }],
    themes: [
      { value: 0, text: 'Default' },
      { value: 1, text: 'Theme-1' },
      { value: 2, text: 'Theme-2' },
      { value: 3, text: 'Theme-3' },
      { value: 4, text: 'Theme-4' },
      { value: 5, text: 'Theme-5' },
      { value: 6, text: 'Theme-6' },
      { value: 7, text: 'Theme-7' },
      { value: 8, text: 'Theme-8' },
      { value: 9, text: 'Theme-9' },
    ],
    cefrDomainList: [
      [{ value: null, text: 'Please select a domain' }],
      [{ value: null, text: 'Please select a domain' }],
      [{ value: null, text: 'Please select a domain' }],
      [{ value: null, text: 'Please select a domain' }],
    ],
    cefrStandardList: [
      [{ value: null, text: 'Please select a standard' }],
      [{ value: null, text: 'Please select a standard' }],
      [{ value: null, text: 'Please select a standard' }],
      [{ value: null, text: 'Please select a standard' }],
    ],
    // extra data
    visibleTemplate: null,
    templateBeingFetched: null,
    problemTemplates: {},
    templateType: '',
    videoTimeStamp: null,
    showTemplateIndex: null,
    questionTemplate: {},
    extraTemplatePara: null,
    fib_dropdown: 0,
    ttsSupport: false,
    skillTypes: SkillTypes,
    answers: [],
    updatedAnswer: {},
    isLoading: false,
  }),
  computed: {
    showLangLevel() {
      let status = false
      if (this.problemData.questions.length > 1) {
        status = true
      }
      return status
    },
  },
  watch: {
    problemData() {
      this.setPage()
    },
    activeTabIndex() {
      if (this.problem.statement[this.activeTabIndex].statementTemplateId) this.fetchTemplate(this.problem.statement[this.activeTabIndex].statementTemplateId, false)
      if (this.problem.statement[this.activeTabIndex].feedbackTemplateId) this.fetchTemplate(this.problem.statement[this.activeTabIndex].feedbackTemplateId, false)
    },
  },
  created() {
    if (this.$route.query.lang_level) {
      this.activeTabIndex = Number(this.$route.query.lang_level) - 1
    }
    this.setPage()
    // if (this.problemData?.questions && this.problemData?.questions.length === 1) this.activeTabIndex = 0
  },
  methods: {
    templateStored(data) {
      if (Number.isInteger(this.visibleTemplate)) {
        this.problemTemplates[this.visibleTemplate] = data
        this.visibleTemplate = null
        return
      }
      this.problemTemplates[this.activeTabIndex + this.templateType] = data
      const newStatment = data.problemstatment
      const oldStatment = this.problem.statement
      oldStatment[this.activeTabIndex] = {
        ...oldStatment[this.activeTabIndex],
        statementTemplateId: newStatment.st_template_id,
        feedbackTemplateId: newStatment.fb_template_id,
      }
      this.visibleTemplate = null
      this.$set(this.problem, 'statement', oldStatment)
    },
    fetchTemplate(id, viewTemplate = true) {
      if (id === 'new') {
        this.extraTemplatePara = {
          problem_id: this.problemData.id,
          lang_level: this.activeTabIndex + 1,
          st_type: this.templateType,
          standard_id: this.problem.standard_id,
        }
        this.visibleTemplate = this.activeTabIndex + this.templateType
        return null
      }
      return new Promise(resolve => {
        if (this.problemTemplates[id]) {
          if (viewTemplate) this.visibleTemplate = id
          return
        }

        this.templateBeingFetched = id
        useJwt.getTemplate(id).then(res => {
          this.problemTemplates[id] = res.data.data
          if (viewTemplate) this.visibleTemplate = id
          resolve('')
        }).finally(() => {
          this.templateBeingFetched = null
        })
      })
    },
    setQuestionAudio({ url }, i) {
      if (url) {
        this.blobUrlToBase64(url)
          .then(base64String => {
            this.problem.questions[i][0].audioFile = base64String
            this.updateQuestion(i, 0)
          })
          .catch(error => {
            console.error('Error:', error)
          })
      }
    },
    setPage() {
      // set standard and problem name
      this.selectedDomain = this.problemData.problem_set.standard.domain.id
      this.problem.standard_id = this.problemData.problem_set.standard_id
      this.problem.tags = this.problemData.tags
      this.problem.description = this.problemData.description
      this.problem.ttsSupport = !!this.problemData.tts_support
      this.$store.commit('problem/SET_TTS_SUPPORT', !!this.problem.ttsSupport)
      this.problem.name = this.problemData.name
      if (this.problemData.rules) {
        this.problem.hideStatement = this.JSON_PARSE(this.problemData.rules)?.hide_statement
        this.problem.hideFeedBack = this.JSON_PARSE(this.problemData.rules)?.hide_feedback
        this.problem.hideAnswerHints = this.JSON_PARSE(this.problemData.rules)?.hide_hints
        this.problem.hideImageHints = this.JSON_PARSE(this.problemData.rules)?.hide_image_hints
        this.problem.hideTextHints = this.JSON_PARSE(this.problemData.rules)?.hide_text_hints
        this.problem.disableRandomHints = this.JSON_PARSE(this.problemData.rules)?.disable_random_hints
      }

      this.standards = this.problemData.problem_set.standard.domain.standards.map(s => ({ value: s.id, text: s.name }))
      this.problem.theme = this.problemData.theme
      this.videoTimeStamp = this.problemData.video_time_stamp
      this.$store.commit('problem/SET_TTS_SUPPORT', !!this.problem.ttsSupport)
      this.fib_dropdown = (this.problemData.problem_type.name === 'fib_dropdown') * 1
      this.problem.skill_type = this.problemData.skill_type
      this.answers = this.problemData.questions[0].answers

      // set statement and feedback
      let i = 0
      while (i < 4) {
        this.problem.statement.push({
          statement: '', id: null, feedback: '',
        })
        i += 1
      }
      this.problemData.problem_statements.forEach(data => {
        this.problem.statement[data.lang_level - 1] = {
          statementTemplateId: data.st_template_id,
          feedbackTemplateId: data.fb_template_id,
          initialStatement: data.statement,
          initialFeedback: data.feedback,
          statement: data.statement,
          id: data.id,
          feedback: data.feedback,
          fb_type: data.fb_type,
          st_type: data.st_type,
        }
        this.problem.pageLink[data.lang_level - 1] = data.page_link
        if (data.standard) {
          this.problem.cefr.selectedSubjects[data.lang_level - 1] = data.standard.domain.subject_id
          this.fetchCefrDomain(data.lang_level - 1, false)
          this.problem.cefr.selectedDomains[data.lang_level - 1] = data.standard.domain.id
          this.fetchCefrStandard(data.lang_level - 1, false)
          this.problem.cefr.selectedStandards[data.lang_level - 1] = data.standard_id
        }

        this.problem.stType.push(data.st_type)
        this.problem.fbType.push(data.fb_type)
        if (data.st_type === 'image') this.problem.statementImage.url[data.lang_level - 1] = data.st_image
        if (data.st_type === 'audio') this.problem.statementAudio.url[data.lang_level - 1] = data.st_image
        if (data.fb_type === 'image') this.problem.feedbackImage.url[data.lang_level - 1] = data.fb_image
        if (data.fb_type === 'audio') this.problem.feedbackAudio.url[data.lang_level - 1] = data.fb_image
      })

      this.problem.levels = []

      // if linear problem type, set only one language level
      if (this.problemData?.questions?.find(q => q.lang_level !== '1') && !this.problemData?.isLinearLesson) {
        this.problem.levels = [...this.problem.allLevels]
      } else {
        const tabIndex = this.problemData.questions ? this.problemData.questions[0]?.lang_level - 1 : 0
        const newLevelArray = new Array(4).fill('')
        newLevelArray[tabIndex] = this.problem.allLevels[tabIndex]
        this.problem.levels = newLevelArray
        this.activeTabIndex = tabIndex
      }

      // set question and answer
      this.problemData.questions.forEach(data => {
        let question = data.text
        data.answers.forEach(a => {
          question = question.replace('[ ]', `***${a.text}***`)
        })
        let text = ''
        try {
          text = JSON.parse(question)
        } catch {
          text = {
            type: 'none',
            page_link: '',
            question: '',
            before_template: question,
            template: [],
            after_template: '',
          }
        }
        this.problem.questions[data.lang_level - 1].push({
          id: data.id, text, initialText: text, audioFile: data.extra_file,
        })
      })
      if (this.problem.statement[this.activeTabIndex].statementTemplateId) this.fetchTemplate(this.problem.statement[this.activeTabIndex].statementTemplateId, false)
      if (this.problem.statement[this.activeTabIndex].feedbackTemplateId) this.fetchTemplate(this.problem.statement[this.activeTabIndex].feedbackTemplateId, false)
      this.$emit('ready')
    },
    updateTheme() {
      const config = {
        theme: `${this.problem.theme}`,
      }
      useJwt.updateTheme(this.$route.query.problem_id, config)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    updateProblem() {
      const config = {
        lesson_id: this.$route.query.lesson_id,
        name: this.problem.name,
        standard_id: this.problem.standard_id,
        tags: this.problem.tags,
        skill_type: this.problem.skill_type,
        tts_support: this.problem.ttsSupport,
        description: this.problem.description,
        rules: {
          hide_statement: this.problem.hideStatement,
          hide_feedback: this.problem.hideFeedBack,
          hide_hints: this.problem.hideAnswerHints,
          hide_image_hints: this.problem.hideImageHints,
          hide_text_hints: this.problem.hideTextHints,
          disable_random_hints: this.problem.disableRandomHints,
        },
      }
      this.$store.commit('problem/SET_TTS_SUPPORT', !!this.problem.ttsSupport)
      useJwt.updateProblem(this.$route.query.problem_id, config)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    updateProblemType() {
      const type = parseInt(this.fib_dropdown, 10) ? 'fib_dropdown' : 'blank'

      const config = { problem_type: type, _method: 'PUT' }
      useJwt.updateProblemType(this.$route.query.problem_id, config)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    handleStatementImage(event, type, index) {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.problem[type].url[index] = reader.result
        },
        false,
      )
      if (event.target.files[0]) {
        const file = event.target.files[0]
        if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
          if (type === 'statementImage') this.updateProblemStatementImage(file, index, 'st_image')
          else if (type === 'feedbackImage') this.updateProblemStatementImage(file, index, 'fb_image')
          reader.readAsDataURL(file)
        }
      }
    },
    updateCefrStandard(index, isNull) {
      useJwt.updateCefrStandard(this.problem.statement[index].id, isNull ? null : this.problem.cefr.selectedStandards[index])
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    updateProblemStatement(level) {
      const statement = this.problem.statement[level]
      if (statement.initialStatement !== statement.statement || statement.initialFeedback !== statement.feedback) {
        const config = {
          lang_level: level + 1,
          statement: statement.statement,
          feedback: statement.feedback,
          _method: 'put',
        }
        useJwt.updateProblemStatement(this.problem.statement[level].id, config)
          .then(response => {
            this.showSuccessMessage(response)
          })
          .catch(error => {
            this.showErrorMessage(error)
          })
          .finally(() => {
            this.$emit('fetch')
          })
      }
    },
    updateProblemStatementImage(file, level, type) {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      data.append('lang_level', level + 1)
      data.append('statement', this.problem.statement[level].statement)
      data.append('feedback', this.problem.statement[level].feedback)
      data.append([type], file)
      if (type === 'st_image') data.append('st_type', 'image')
      else if (type === 'fb_image') data.append('fb_type', 'image')
      data.append('_method', 'put')

      useJwt.updateProblemStatement(this.problem.statement[level].id, data, config)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    updateProblemStatementAudio(file, level, type) {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      data.append('lang_level', level + 1)
      data.append('statement', this.problem.statement[level].statement)
      data.append('feedback', this.problem.statement[level].feedback)
      data.append([type], file)
      if (type === 'st_image') data.append('st_type', 'audio')
      else if (type === 'fb_image') data.append('fb_type', 'audio')
      data.append('_method', 'put')

      useJwt.updateProblemStatement(this.problem.statement[level].id, data, config)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    setStatementAudio({ audio, url }, i, type) {
      this.problem[type].url[i] = url
      this.problem[type].file[i] = audio
      if (audio) {
        if (type === 'statementAudio') this.updateProblemStatementAudio(audio, i, 'st_image')
        else if (type === 'feedbackAudio') this.updateProblemStatementAudio(audio, i, 'fb_image')
      }
    },
    updateQuestion(i, j) {
      const question = this.problem.questions[i][j]
      if (this.checkHtml(JSON.stringify(question.text))) {
        const config = {
          lang_level: `${i + 1}`,
          problem_id: this.$route.query.problem_id,
          text: JSON.stringify(question.text),
          extra_file_blob: question.audioFile,
          _method: 'put',
        }
        useJwt.updateQuestion(question.id, config)
          .then(response => {
            this.showSuccessMessage(response)
          })
          .catch(error => {
            this.showError(error.response.data.errors.text[0])
          })
          .finally(() => {
            this.$emit('fetch')
          })
      }
    },
    checkHtml(q) {
      let valid = true
      const regexp = /\*\*\*(.*?)\*\*\*/g
      const ans = [...q.matchAll(regexp)]
      ans.forEach(a => {
        if (a[1].includes('&lt;') && a[1].includes('&gt;')) {
          valid = false
        }
      })
      return valid
    },
    fetchStandard() {
      if (this.selectedDomain) {
        useJwt.getStandardsByDomain(this.selectedDomain).then(response => {
          this.standards = [{ value: null, text: 'Please select a standard' }, ...response.data.data.map(s => ({ value: s.id, text: s.name }))]
        })
      }
      this.problem.standard_id = null
    },
    checkFetchCefrDomain(index, setNull) {
      if (this.problem.cefr.selectedStandards[index]) {
        this.updateCefrStandard(index, true)
      }
      this.fetchCefrDomain(index, setNull)
    },
    fetchCefrDomain(index, setNull) {
      const setDomain = domains => {
        this.cefrDomainList[index] = [{ value: null, text: 'Please select a domain' }, ...domains.map(s => ({ value: s.id, text: s.name }))]
        if (setNull) this.problem.cefr.selectedDomains[index] = null
        if (setNull) this.problem.cefr.selectedStandards[index] = null
        this.cefrDomainList.push('')
        this.cefrDomainList.pop()
      }
      const id = Number(this.problem.cefr.selectedSubjects[index])
      const cachedDomain = this.subjectList?.find(subject => Number(subject.id) === id)
      if (cachedDomain) {
        setDomain(cachedDomain.domains)
        return
      }
      useJwt.getCefrDomain(id).then(response => {
        setDomain(response.data.data)
      })
    },
    checkFetchCefrStandard(index, setNull) {
      if (this.problem.cefr.selectedStandards[index]) {
        this.updateCefrStandard(index, true)
      }
      this.fetchCefrStandard(index, setNull)
    },
    fetchCefrStandard(index, setNull) {
      const setStandard = standard => {
        this.cefrStandardList[index] = [{ value: null, text: 'Please select a standard' }, ...standard.map(s => ({ value: s.id, text: s.name }))]
        if (setNull) this.problem.cefr.selectedStandards[index] = null
        this.cefrStandardList.push('')
        this.cefrStandardList.pop()
      }
      const id = Number(this.problem.cefr.selectedDomains[index])
      let cachedStandard = this.subjectList?.find(subject => subject.domains?.find(domain => Number(domain.id) === Number(id)))
      if (cachedStandard) {
        cachedStandard = cachedStandard.domains?.find(domain => Number(domain.id) === Number(id))
        setStandard(cachedStandard.standards)
      } else {
        useJwt.getCefrStandard(id).then(response => {
          setStandard(response.data.data)
        })
      }
    },
    getDataForQuestionPreview() {
      const statementAndFeedback = this.problem.statement[this.activeTabIndex]
      const index = this.activeTabIndex
      return {
        theme: this.problem.theme,
        problemType: this.fib_dropdown ? 'fib_dropdown' : 'blank',
        hasAnimatedLayout: this.problemData.animated_layout,
        animatedTemplateId: this.problemData.animated_template_id,
        hideStatement: !!this.problem.hideStatement,
        hideFeedBack: !!this.problem.hideFeedBack,
        rules: {
          hide_hints: this.problem.hideAnswerHints,
          hide_image_hints: this.problem.hideImageHints,
          hide_text_hints: this.problem.hideTextHints,
          disable_random_hints: this.problem.disableRandomHints,
        },
        feedback: {
          type: statementAndFeedback.fb_type,
          text: statementAndFeedback.feedback,
          image: statementAndFeedback.fb_type === 'image' ? this.problem.feedbackImage?.url[index] : null,
          audio: statementAndFeedback.fb_type === 'audio' ? this.problem.feedbackAudio?.url[index] : null,
          template: this.problemTemplates[statementAndFeedback.feedbackTemplateId || (`${this.activeTabIndex}feedback`)],
        },
        statement: {
          type: statementAndFeedback.st_type,
          text: statementAndFeedback.statement,
          image: statementAndFeedback.st_type === 'image' ? this.problem.statementImage?.url[index] : null,
          audio: statementAndFeedback.st_type === 'audio' ? this.problem.statementAudio?.url[index] : null,
          template: this.problemTemplates[statementAndFeedback.statementTemplateId || (`${this.activeTabIndex}statement`)],
          video: this.videoTimeStamp,
        },
        // eslint-disable-next-line eqeqeq
        questions: this.problemData.questions.filter(e => e.lang_level == (this.activeTabIndex + 1).toString()),
        level: this.activeTabIndex + 1,
      }
    },
    copyLesson(lessonId) {
      this.$emit('copyProblem', lessonId)
    },
    updatePageLink(i) {
      const updatePrams = {
        lang_level: i + 1,
        problem_id: this.$route.query.problem_id,
        page_link: this.problem.pageLink[i],
      }
      useJwt.updatePageLink(updatePrams).then(response => {
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.$emit('fetch')
      })
      // useJwt.updatePageLink()
    },
  },
}
</script>
