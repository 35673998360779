<template>
  <div>
    <video
      id="webcam"
      width="200"
      height="200"
      autoplay
      class="d-none"
    />
    <video
      id="screen-sharing"
      width="200"
      height="200"
      autoplay
      class="d-none"
    />
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
