<template>
  <b-overlay :show="isProcessing">
    <b-card>
      <div class="float-right">
        <b-button
          variant="outline-primary"
          class="btn-icon refetch-button"
          @click="refetch"
        >
          <feather-icon icon="RefreshCwIcon" />
        </b-button>
      </div>
      <b-form-group label="Filter By">
        <div class="demo-inline-spacing">
          <b-form-radio
            v-for="option of filterOptions"
            :key="option.value"
            v-model="form.filterOption"
            name="some-radios"
            :value="option.value"
          >
            {{ option.name }}
          </b-form-radio>
        </div>
        <!-- <v-select
          v-model="form.filterOption"
          :options="filterOptions"
          label="name"
          :reduce="item => item.value"
          placeholder="Select Course"
          :clearable="false"
          @input="form.lessonGroupId = null"
        /> -->
      </b-form-group>
      <template v-if="form.filterOption === 'language-level'">
        <v-select
          v-model="form.selectedLangLevel"
          :options="langLevel"
          label="name"
          :reduce="item => item"
          placeholder="Select Course"
          :clearable="false"
          @input="getProblemByLanguageLevel"
        />
      </template>
      <template v-else>
        <b-form-group label="Course">
          <v-select
            v-model="form.courseId"
            :options="courses"
            label="name"
            :reduce="item => item.value"
            placeholder="Select Course"
            @input="form.lessonGroupId = null"
          />
        </b-form-group>
        <b-form-group label="Lesson Group">
          <v-select
            v-model="form.lessonGroupId"
            label="name"
            :reduce="item => item.value"
            :options="lessonGroup || []"
            placeholder="Select Lesson Group"
            @input="form.lessonId = null"
          />
        </b-form-group>
        <b-form-group label="Lesson">
          <v-select
            v-model="form.lessonId"
            label="name"
            :reduce="item => item.value"
            :options="lessons || []"
            placeholder="Select Lesson"
            @input="getProblems"
          />
        </b-form-group>
      </template>
    </b-card>

    <b-card v-if="selectedLesson || form.filterOption === 'language-level'">
      <h5>Problems
        <span v-if="selectedLesson">
          ({{ selectedLesson.langLevel }})
        </span>
        <span v-else-if="form.filterOption === 'language-level'">
          ({{ form.selectedLangLevel }})
        </span>
      </h5>
      <b-tabs v-if="form.filterOption === 'language-level'">
        <b-tab
          v-for="problemList,index of groupByKey(problems, 'lessonName')"
          :key="index"
          :title="problemList[0].lessonName"
        >
          <b-row class="mt-1">
            <b-col
              v-for="problem,key of problemList"
              :key="key"
              v-b-tooltip.hover.v-primary
              cols="4"
              :title="problem.lessonName || '' "
              class="mb-1"
            >
              <b-form-checkbox
                v-model="selectedProblems"
                :value="problem.value"
                @change="onSubmit"
              >
                {{ problem.name }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
      <b-row
        v-else
        class="mt-1"
      >
        <b-col
          v-for="problem,index of problems"
          :key="index"
          v-b-tooltip.hover.v-primary
          cols="4"
          :title="problem.lessonName || '' "
          class="mb-1"
        >
          <b-form-checkbox
            v-model="selectedProblems"
            :value="problem.value"
            @change="onSubmit"
          >
            {{ problem.name }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCard, BFormGroup, BOverlay, BRow, BCol, BFormCheckbox, BButton, BFormRadio, VBTooltip, BTabs, BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'

const langLevel = [
  '-', 'A1', 'A2', 'B1', 'B2',
]
export default {
  components: {
    BCard,
    vSelect,
    BFormGroup,
    BOverlay,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    BFormRadio,
    BTabs,
    BTab,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    preloadedProblems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      coursesBook: [],
      courses: [],
      filterOptions: [
        { name: 'Courses-Lesson', value: 'course-lesson' },
        { name: 'Language Level', value: 'language-level' },
      ],
      isProcessing: false,
      form: {
        filterOption: 'course-lesson',
        courseId: null,
        lessonGroupId: null,
        lessonId: null,
        selectedLangLevel: 'A1',
      },
      problems: [],
      selectedProblems: [],
      langLevel: langLevel.filter(level => level !== '-'),
    }
  },
  computed: {
    lessonGroup() {
      return this.coursesBook.find(course => course.id === this.form.courseId)?.groups
        .map(group => ({
          name: group?.name,
          value: group?.id,
          lessons: group.lessons,
        }))
    },
    lessons() {
      return this.lessonGroup?.find(group => group.value === this.form.lessonGroupId)?.lessons.map(lesson => ({
        // eslint-disable-next-line prefer-template
        name: lesson.name + ' (' + langLevel[lesson.lang_level] + ')',
        value: lesson.id,
        langLevel: langLevel[lesson.lang_level],
      }))
    },
    selectedLesson() {
      return this.lessons?.find(lesson => lesson.value === this.form.lessonId)
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.lessonId': function (value) {
      this.$emit('onLessonChanged', value)
    },
    // eslint-disable-next-line func-names
    'form.filterOption': function (value) {
      if (value === 'language-level') {
        this.getProblemByLanguageLevel()
      } else {
        this.getCourseLists()
      }
    },
    preloadedProblems(value) {
      this.selectedProblems = value
    },
  },
  created() {
    this.$parent.$on('removeSelectedProblem', problemId => {
      this.selectedProblems = this.selectedProblems.filter(problem => problem !== problemId)
    })
    this.$root.$on('onClickLangLevel', level => {
      this.form.filterOption = 'language-level'
      this.form.selectedLangLevel = level
      this.getProblemByLanguageLevel()
    })
    this.getCourseLists()
  },
  methods: {
    getCourseLists() {
      this.isProcessing = true
      useJwt.getCoursesWithLinearLesson().then(response => {
        this.coursesBook = response.data?.data?.courses
        this.courses = response.data?.data?.courses.map(data => ({
          name: data.name,
          value: data.id,
        }))
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    getProblemByLanguageLevel() {
      this.isProcessing = true
      useJwt.getLinearLessonProblemByLangLevel({
        params: {
          level: this.form.selectedLangLevel,
        },
      }).then(response => {
        this.problems = response.data?.data.problems.map(data => ({
          name: data.name,
          value: data.id,
          lessonId: data.lesson_id,
          lessonName: data.lesson.name,
        }))
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    getProblems() {
      this.isProcessing = true
      useJwt.getProblemByLesson({
        params: {
          lid: this.form.lessonId,
        },
      }).then(response => {
        this.problems = response.data?.data?.data?.map(data => ({
          name: data.name,
          value: data.id,
          lessonId: this.form.lessonId,
        }))
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    onSubmit() {
      const isByLangLevel = this.form.filterOption === 'language-level'
      let level
      if (isByLangLevel) {
        level = this.form.selectedLangLevel
      } else {
        level = this.lessons.find(lesson => lesson.value === this.form.lessonId)?.langLevel
      }
      const problems = this.problems.filter(problem => this.selectedProblems.includes(problem.value))
      this.$emit('onSubmit', {
        level,
        problems,
        lessonId: !isByLangLevel ? this.form.lessonId : null,
        lessonName: !isByLangLevel ? this.lessons.find(lesson => Number(lesson.value) === Number(this.form.lessonId))?.name : null,
      })
    },
    refetch() {
      if (this.form.filterOption === 'language-level') {
        this.getProblemByLanguageLevel()
      } else {
        this.getCourseLists()
      }
    },
  },
}
</script>
<style scoped>
.refetch-button {
    position: absolute;
    margin-top: -15px;
    margin-right: 10px !important;
    margin-left: -40px;
}

</style>
