var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{staticClass:"feedback-statement"},[(
      (_vm.feedback && _vm.feedback.text && _vm.feedback.text.length > 0) ||
        _vm.feedback.image ||
        _vm.feedback.audio ||
        _vm.feedback.template
    )?_c('b-col',{staticClass:"problem-result no-result",attrs:{"md":"12"}},[(('isCorrect' in _vm.feedback) && _vm.activeProblem?.problem_type !== 'open-end-writing')?_c('div',{staticClass:"correct-incorrect-icon",class:{
        'bg-success':
          'isCorrect' in _vm.feedback && _vm.feedback.isCorrect === true,
        'bg-danger':
          'isCorrect' in _vm.feedback && _vm.feedback.isCorrect === false,
      }},[(_vm.feedback.isCorrect)?_c('feather-icon',{attrs:{"icon":"CheckIcon","size":"30"}}):_c('feather-icon',{attrs:{"icon":"XIcon","size":"30"}})],1):(_vm.activeProblem)?_c('div',{staticClass:"text-right"},[_c('b-badge',{staticClass:"mb-2 badge-lg",attrs:{"variant":"success"}},[_vm._v(" Submitted ")])],1):_vm._e(),_c('swiper',{ref:"vueSwiper",staticClass:"swiper"},[(_vm.canShow(_vm.feedback.template))?_c('swiper-slide',[(_vm.feedback.audio)?_c('audio-player',{attrs:{"src":_vm.feedback.audio}}):_vm._e(),(_vm.isAHotSpotProblem)?_c('div',{staticClass:"inner-problem-result"},[_c('HotSpotFeedBack',{attrs:{"hotspot":_vm.activeProblem}})],1):_c('div',{staticClass:"inner-problem-result p-0"},[(_vm.feedback.image)?_c('div',{staticClass:"mt-2 rounded-top text-center d-block"},[_c('b-img',{staticStyle:{"max-height":"200px"},attrs:{"src":_vm.feedback.image,"fluid":""}})],1):_vm._e(),(!_vm.feedback.template)?_c('div',[(_vm.feedback.text && _vm.feedback.text.length > 0)?_c('p',{directives:[{name:"text-to-speech",rawName:"v-text-to-speech"}],staticClass:"ck-content",class:{
                'mt-2':
                  'isCorrect' in _vm.feedback || _vm.feedback.image || _vm.feedback.audio,
              },domProps:{"innerHTML":_vm._s(_vm.wordnetWords)},on:{"click":_vm.handleClick}}):_vm._e()]):_c('AnimatedLayout',{attrs:{"enabled":_vm.feedback.template && _vm.feedback.template.animated_layout == 1,"template-id":_vm.feedback.template && _vm.feedback.template.animated_template_id,"is-edit-mode":false}},[_c('show-template',{attrs:{"only-feed-back":false,"template":_vm.feedback.template}})],1),_c('translation-section',{ref:"translationModal",attrs:{"active-translation":_vm.activeTranslation,"active-word-nets":_vm.activeWordNets,"active-word":_vm.activeWord},on:{"set-active-language":_vm.setActiveLanguage}})],1)],1):_vm._e(),(_vm.hasMultipleFeedBack)?[_vm._l((_vm.feedback.template.child_templates),function(child,index){return [(_vm.canShow(child))?_c('swiper-slide',{key:index},[_c('div',{staticClass:"inner-problem-result p-0"},[_c('AnimatedLayout',{attrs:{"enabled":child && child.animated_layout == 1,"template-id":child && child.animated_template_id,"is-edit-mode":false}},[_c('show-template',{attrs:{"only-feed-back":false,"template":child}})],1)],1)]):_vm._e()]})]:_vm._e()],2),(_vm.hasMultipleFeedBack)?_c('div',[(_vm.isPrevSlideVisible)?_c('div',{staticClass:"swiper-button-prev",attrs:{"tabindex":"0","role":"button"},on:{"click":function($event){return _vm.slideTo('prev')}}}):_vm._e(),(_vm.isNextSlideVisible)?_c('div',{staticClass:"swiper-button-next",attrs:{"tabindex":"0","role":"button"},on:{"click":function($event){return _vm.slideTo('next')}}}):_vm._e(),_c('div',{staticClass:"swiper-pagination"})]):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }