<template>
  <div v-if="!isReservationSent">
    <div>
      <children-picker
        ref="childPicker"
        class="mb-2"
        @onPick="(value) => (numberOfChildren = value)"
        @updateEmail="(value) => (parentEmail = value)"
      />
      <b-tabs v-model="activeTab"
              pills
      >
        <b-tab
          v-for="(child, index) of reservationForm"
          :key="index"
          :title="`Child ${index + 1}`"
        >
          <b-overlay :show="isProcessing">
            <reservation-form
              ref="childInfoForm"
              :form="child"
              :show="!savedForms.includes(index)"
              :school-lists="schoolLists"
              @submitForm="handleForm"
            >
              <form-success
                ref="childInfoForm"
                action-message="Next Child"
                @addMore="moveToNextChild(index)"
              />
            </reservation-form>
          </b-overlay>
        </b-tab>
      </b-tabs>
    </div>
  </div>
  <div v-else
       class="text-center"
  >
    <form-success @addMore="resetForm" />
  </div>
</template>
<script>
import {
  BTabs,
  BTab,
  BOverlay,
  // BButton, BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ChildrenPicker from './components/NumberOfChildrenPicker.vue'
import ReservationForm from './components/ReservationForm.vue'
import FormSuccess from './components/FormSuccess.vue'

export default {
  components: {
    ChildrenPicker,
    ReservationForm,
    BTabs,
    // BButton,
    BTab,
    BOverlay,
    // BSpinner,
    FormSuccess,
  },
  data() {
    return {
      numberOfChildren: 0,
      reservationForm: [],
      parentEmail: null,
      isProcessing: false,
      activeTab: 0,
      savedForms: [],
      schoolLists: [],
    }
  },
  computed: {
    isReservationSent() {
      return (
        this.savedForms.length && this.savedForms.length === this.reservationForm.length
      )
    },
  },
  watch: {
    numberOfChildren(value) {
      const newValue = value - this.reservationForm.length
      if (newValue < 0) {
        this.reservationForm.splice(
          this.reservationForm.length - Math.abs(newValue),
          Math.abs(newValue),
        )
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < Math.abs(newValue); i++) {
          this.reservationForm.push({
            dob: null,
            student_email: '',
            reservation_id: null,
            customSchedule: [],
          })
        }
      }
    },
  },
  mounted() {
    this.getSchools()
  },
  methods: {
    getSchools() {
      useJwt.getPublicSchools().then(response => {
        this.schoolLists = [
          { text: 'Any', value: null },
          ...response.data.data.schools.map(item => ({
            text: `${item.firstname} ${item.lastname}`,
            value: item.id,
          })),
        ]
      })
    },
    moveToNextChild(index) {
      this.activeTab = index + 1
    },
    resetForm() {
      this.savedForms = []
      Object.assign(this.$data, this.$options.data())
    },
    async handleForm() {
      const isChildValid = await this.$refs.childPicker.$refs.formProvider.validate()
      if (!isChildValid) {
        this.showError('Please enter valid parent email')
        return
      }
      let isValid = true
      const index = this.activeTab
      // eslint-disable-next-line no-plusplus
      // for (let index = 0; index < this.$refs.childInfoForm.length; index++) {
      const element = this.$refs.childInfoForm[index]
      // eslint-disable-next-line no-await-in-loop
      const valid = await element.$refs.formProvider.validate()
      if (!valid && !element.form.selected_class) {
        isValid = false
        this.showError(`Form not valid for child ${index + 1}`)
      }
      if (!element.form.reservation_id) {
        isValid = false
        this.showError(`Please select reservation for child  ${index + 1}`)
      }
      if (!element.form.selected_class) {
        if (element.form.customSchedule?.every(item => !item.selected)) {
          isValid = false
          this.showError(`Please at least one time for child  ${index + 1}`)
        }
        if (element.form.customSchedule) {
          element.form.customSchedule.forEach(item => {
            // eslint-disable-next-line no-tabs
            const isRepeated = element.form.customSchedule.filter(i => i.selected === item.selected && i.day === item.day).length > 1
            if (isRepeated) {
              this.showError(
                `Schedule for ${item.day} - ${
                  item.selected
                } repeated for child ${index + 1}`,
              )
              isValid = false
            }
          })
        }
      }
      // }
      if (!isValid) {
        return
      }
      this.isProcessing = true
      useJwt
        .storePublicReservation({
          parent_email: this.parentEmail,
          reservation_form: [this.reservationForm[this.activeTab]],
        })
        .then(response => {
          this.showSuccessMessage(response)
          // this.isReservationSent = true
          this.savedForms.push(this.activeTab)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
  },
}
</script>
