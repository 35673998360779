<template>
  <question-preview
    :show="showPreview"
    :data="
      problemBeingPreviewed
        ? problemPreview[problemBeingPreviewed.problem_id]
        : {}
    "
    @close="showPreview = false"
  />
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import QuestionPreview from '@/views/common/components/QuestionPreview.vue'

export default {
  components: {
    QuestionPreview,
  },
  props: {
    showPreview: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    problemTemplates: {},
    problemPreview: {},
    problemBeingPreviewed: null,
  }),
  methods: {
    showProblemPreview(prob) {
      this.problemBeingPreviewed = prob
      this.getProblemForPreview()
    },
    async getProblemForPreview() {
      if (this.problemPreview[this.problemBeingPreviewed.problem_id]) {
        this.showPreview = true
      } else {
        useJwt.getProblem(this.problemBeingPreviewed.problem_id).then(async res => {
          const problemData = res.data.data
          const index = this.getTabIndex(this.problemBeingPreviewed.lang_level)
          const statementAndFeedback = problemData.problem_statements[index]

          await this.getTemplates(statementAndFeedback.fb_template_id)
          await this.getTemplates(statementAndFeedback.st_template_id)

          const answers = await useJwt.getAnswerPreview(this.problemBeingPreviewed.test_id)

          this.problemPreview[this.problemBeingPreviewed.problem_id] = {
            theme: problemData.theme ? problemData.theme : 0,
            problemType: problemData.problem_type.name,
            feedback: {
              type: statementAndFeedback.fb_type,
              text: statementAndFeedback.feedback,
              image: statementAndFeedback.fb_type === 'image' ? statementAndFeedback.fb_image : null,
              audio: statementAndFeedback.fb_type === 'audio' ? statementAndFeedback.fb_image : null,
              template: this.problemTemplates[statementAndFeedback.fb_template_id],
            },
            statement: {
              type: statementAndFeedback.st_type,
              text: statementAndFeedback.statement,
              image: statementAndFeedback.st_type === 'image' ? statementAndFeedback.st_image : null,
              audio: statementAndFeedback.st_type === 'audio' ? statementAndFeedback.st_image : null,
              template: this.problemTemplates[statementAndFeedback.st_template_id],
              video: problemData.video_time_stamp,
            },
            questions: problemData.questions.filter(e => e.lang_level === (index + 1).toString()),
            answers: answers.data?.data,
            level: index + 1,
          }
          this.showPreview = true
        })
      }
    },
    getTabIndex(level) {
      const levels = ['A1', 'A2', 'B1', 'B2']
      return levels.indexOf(level)
    },
    getTemplates(id) {
      if (!id) return
      if (this.problemTemplates[id]) return
      // eslint-disable-next-line consistent-return
      return new Promise(resolve => {
        useJwt.getTemplate(id).then(res => {
          this.problemTemplates[id] = res.data.data
          resolve('')
        })
      })
    },
  },
}
</script>
