<template>
  <b-modal
    :visible="showEngineModal"
    :title="$t('class-module.engine')"
    ok-only
    :ok-title="$t('ok')"
    @ok="$emit('close')"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <p>{{ $t('class-module.engine-info-sdp') }}</p>
    <p>{{ $t('class-module.engine-info-ddf') }}</p>
    <p>{{ $t('class-module.engine-info-normal') }}</p>
    <p>{{ $t('class-module.engine-info-caf') }}</p>
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  props: {
    showEngineModal: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
