var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.data),function(datum,index){return _c('div',{key:index,staticClass:"row mt-2"},[_c('div',{staticClass:"col-md-12"},[_c('p',{directives:[{name:"text-to-speech",rawName:"v-text-to-speech"}],domProps:{"innerHTML":_vm._s(
          _vm.isAProblem
            ? _vm.formatQuestion(datum.title, 'title', index)
            : datum.title
        )},on:{"click":function($event){return _vm.$emit('onClick', $event)}}})]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"media",style:(`display:flex;flex-direction:${
          datum.imagePosition === 'left' ? 'row' : 'row-reverse'
        }`)},[_c('img',{directives:[{name:"image-preview",rawName:"v-image-preview"}],class:{
            'mr-3': datum.imagePosition === 'left',
            'ml-3': datum.imagePosition === 'right',
          },staticStyle:{"width":"250px"},attrs:{"src":datum.image_url}}),_c('div',{staticClass:"media-body"},[_c('h3',{directives:[{name:"text-to-speech",rawName:"v-text-to-speech"}],staticClass:"mt-0",domProps:{"innerHTML":_vm._s(
              _vm.isAProblem
                ? _vm.formatQuestion(datum.subtitle, 'subtitle', index)
                : datum.subtitle
            )},on:{"click":function($event){return _vm.$emit('onClick', $event)}}})])])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }