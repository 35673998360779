export default function answerFormatter(response) {
  if (!response?.input || !Array.isArray(response?.input)) {
    return response
  }
  const checkWrongAnswer = response.input.filter(i => i.is_correct === 0)
  let beforeTemplate = response?.feedback_template?.before_template
  if (!beforeTemplate) {
    return response
  }
  checkWrongAnswer.forEach(wrongAnswer => {
    const regex = new RegExp(`\\b(${wrongAnswer.c_ans})\\b`, 'g')
    beforeTemplate = beforeTemplate.replace(regex, '<span class="text-danger">$1</span>')
  })
  if (response.feedback_template) {
    // eslint-disable-next-line no-param-reassign
    response.feedback_template.before_template = beforeTemplate
  }
  return response
}
