const skillTypes = [
  {
    value: null,
    text: 'Please Select a skill type',
    disabled: true,
  },
  {
    value: 'reading',
    text: 'Reading',
  },
  {
    value: 'writing',
    text: 'Writing',
  },
  {
    value: 'listening',
    text: 'Listening',
  },
  {
    value: 'speaking',
    text: 'Speaking',
  },
  {
    value: 'typing',
    text: 'Typing',
  },
  {
    value: 'pronunciation',
    text: 'Pronunciation',
  },
]

export default skillTypes
