<template>
  <div>
    <div
      v-if="isolatedTemplate"
      class="px-2"
    >
      <h5>
        {{ $t('template-module.isolated-template') }}:
        <span class="font-weight-normal">{{ isolatedTemplate.name }}</span>
      </h5>

      <h5>
        {{ $t('class-module.start-date') }}:
        <span class="font-weight-normal">{{ FORMAT_DATE(isolatedTemplate.startDate, 'YYYY-MM-DD') }}</span>
      </h5>

      <h5>
        {{ $t('class-module.end-date') }}:
        <span class="font-weight-normal">{{ FORMAT_DATE(isolatedTemplate.endDate, 'YYYY-MM-DD') }}</span>
      </h5>
    </div>
    <FullCalendar
      ref="refCalendar"
      :options="config"
      :events="configOptions.events"
    />

    <calendar-event-handler
      v-if="canEditTemplate"
      :key="getEvent().id || getRandomKey()"
      v-model="isEventHandlerSidebarActive"
      :event="getEvent()"
      :grades="grades"
      :require-refetch-emit="true"
      @remove-event="removeEvent"
      @add-event="(e, fn)=>{ addEvent({...e, template_id: isolatedTemplate?.id, assignment_type: 'isolated'}, fn) }"
      @update-event="(e, fn)=>{ updateEvent(e, fn) }"
      @refetch="$emit('refetch')"
      @close="clearEventData"
    />
  </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import moment from 'moment'
import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import useCalendar from '../useCalendar'
import CalendarEventHandler from '../calendar-event-handler/CalendarEventHandler.vue'

export default {
  components: {
    FullCalendar,
    CalendarEventHandler,
  },
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    isolatedTemplate: {
      type: Object,
      default: () => null,
    },
  },
  setup() {
    const {
      refCalendar,
      calendarOptions,
      getEvent,
      updateEvent,
      removeEvent,
      clearEventData,
      addEvent,
      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar()

    return {
      refCalendar,
      addEvent,
      getEvent,
      updateEvent,
      removeEvent,
      clearEventData,
      calendarOptions,
      isEventHandlerSidebarActive,
    }
  },
  data() {
    return {
      grades: [],
    }
  },
  computed: {
    canEditTemplate() {
      return getUserData().usertype !== 'student'
    },
    config() {
      return {
        ...this.configOptions,
        // ...this.eventHandlers,
      }
    },
    configOptions() {
      const getEventColor = e => {
        if (e.event_type === 'class_test') return 'red'
        if (e.event_type === 'instruction') return 'green'
        return (e.is_non_sequence ? 'yellow' : '')
      }
      const events = this.events.map(({
        id, lessonPlans, lesson_id, course_id, lesson_group_id, grade_id, calendar, is_non_sequence, event_type, event_title, event_link, event_description, schedule_type, assignment_type, order_index, event_tags, ...item
      }) => ({
        id,
        // eslint-disable-next-line camelcase
        title: item.title || event_title,
        start: item.start_time_raw,
        end: item.end_time_raw,
        color: getEventColor(item),
        className: 'bg-light-primary event-title-template',
        extendedProps: {
          // eslint-disable-next-line camelcase
          courseId: course_id,
          lessonGroupId: lesson_group_id,
          gradeId: grade_id,
          lessonPlans,
          lessonId: lesson_id,
          isMultiple: Array.isArray(lesson_id),
          calendar,
          nonSequence: is_non_sequence,
          event_tags,
          event_type,
          event_title,
          event_link,
          event_description,
          schedule_type,
          assignment_type,
          order_index,
          has_multi_practice: item.has_multi_practice,
          weakest_skill: {
            has_multi_practice: item.has_multi_practice,
            rules: item.rules,
            default_engine: item.default_engine,
          },
        },
      }))
      return {
        ...this.calendarOptions,
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        rerenderDelay: 350,
        displayEventTime: false,
        events,
        ...(events.length && this.isolatedTemplate ? {
          initialDate: moment(this.isolatedTemplate.start_date).format('YYYY-MM-01'),
        } : {}
        ),
      }
    },
  },
  mounted() {
    this.getGrades()
  },
  methods: {
    getRandomKey() {
      return moment(this.getEvent().start).format('YYYY-MM-DD')
    },
    getGrades() {
      useJwt.getGradeLists().then(response => {
        this.grades = response.data.data.grades.map(({ id, name, courses }) => ({
          value: id,
          text: name,
          courses,
        }))
        this.grades.unshift({ value: null, text: 'Please select a grade' })
      })
    },
  },
}
</script>
<style>
.event-title-template .fc-event-title {
  color: blue !important;
}
</style>
