<template>
  <div>
    <b-row class="py-1 d-flex align-items-center justify-content-between">
      <b-col>
        <h5>
          {{ $t('lesson-pages-module.vocabulary') }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button
          variant="primary"
          size="sm"
          style="height:32px;padding:0 8px;"
          @click.stop="addNewVocabulary"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('lesson-pages-module.add') }}</span>
        </b-button>
      </b-col>

    </b-row>

    <b-table
      responsive
      :fields="columns"
      :items="lesson.vocabulary"
    >
      <template #cell(word_in_eng)="data">
        <validation-provider
          v-slot="{ errors }"
          name="Word In English"
          rules="required|max:256"
          vid="word_in_eng"
        >

          <b-form-input
            v-model="lesson.vocabulary[data.index].word_in_eng"
            name="title"
            :state="errors.length > 0 ? false:null"
            @keyup.enter="$emit('submitData')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </template>
      <template #cell(word_in_jap)="data">
        <validation-provider
          v-slot="{ errors }"
          name="Word In Japanese"
          rules="required|max:256"
          vid="word_in_jap"
        >

          <b-form-input
            v-model="lesson.vocabulary[data.index].word_in_jap"
            name="title"
            :state="errors.length > 0 ? false:null"
            @keyup.enter="$emit('submitData')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </template>
      <template #cell(synonym_1)="data">
        <validation-provider
          v-slot="{ errors }"
          name="Synonym 1"
          rules="required|max:256"
          vid="synonym_1"
        >

          <b-form-input
            v-model="lesson.vocabulary[data.index]['synonym_1']"
            name="title"
            :state="errors.length > 0 ? false:null"
            @keyup.enter="$emit('submitData')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </template>
      <template #cell(synonym_2)="data">
        <validation-provider
          v-slot="{ errors }"
          name="Synonym 2"
          rules="required|max:256"
          vid="synonym_2"
        >

          <b-form-input
            v-model="lesson.vocabulary[data.index]['synonym_2']"
            name="title"
            :state="errors.length > 0 ? false:null"
            @keyup.enter="$emit('submitData')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </template>
      <template #cell(action)="data">
        <span>
          <b-button
            variant="outline-danger"
            class="btn-icon mr-1"
            @click.stop="deleteVocabulary(data.index, lesson.vocabulary[data.index].id ? lesson.vocabulary[data.index].id : null)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </span>
      </template>

    </b-table>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BTable, BFormInput, BButton, BRow, BCol,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full'

export default {
  components: {
    BTable, BFormInput, ValidationProvider, BButton, BRow, BCol,
  },
  props: {
    lesson: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      columns: [
        {
          key: 'word_in_eng',
          label: i18n.tc('lesson-pages-module.vocab-table.word-in-english'),
        },
        {
          key: 'word_in_jap',
          label: i18n.tc('lesson-pages-module.vocab-table.word-in-japanese'),
        },
        {
          key: 'synonym_1',
          label: i18n.tc('lesson-pages-module.vocab-table.synonym-1'),
        },
        {
          key: 'synonym_2',
          label: i18n.tc('lesson-pages-module.vocab-table.synonym-2'),
        },
        {
          key: 'action',
          label: i18n.tc('lesson-pages-module.vocab-table.action'),
        },
      ],
    }
  },
  methods: {
    addNewVocabulary() {
      if (this.lesson?.vocabulary) {
        this.lesson.vocabulary.unshift({
          word_in_eng: '',
          word_in_jap: '',
          synonym_1: '',
          synonym_2: '',

        })
      } else {
        this.lesson.vocabulary = [{
          word_in_eng: '',
          word_in_jap: '',
          synonym_1: '',
          synonym_2: '',

        }]
      }
    },
    deleteVocabulary(index, id) {
      if (id !== null) {
        useJwt.deleteVocab(id).then(res => {
          this.showSuccessMessage(res)
        }).catch(error => {
          this.showErrorMessage(error)
        })
      }
      this.lesson.vocabulary.splice(index, 1)
    },
  },
}
</script>

<style>

</style>
