<template>
  <div>
    <gec-dragger
      v-if="showAvatarStudent && !hideAvatar"
      id="avatar-dragger"
      :width="avatarMinimize? 60: 120"
      :height="avatarMinimize? 60: 120"
    >
      <feather-icon
        :icon="avatarMinimize? 'Maximize2Icon' : 'Minimize2Icon'"
        class="cursor-pointer mix-min-icon"
        @click="avatarMinimize = !avatarMinimize"
      />
      <img
        v-if="currentAvatar"
        :src="require(`@/assets/images/interactive-avatars${currentAvatar}`)"
        draggable="false"
        class="w-100 h-100"
      >
    </gec-dragger>
  </div>

</template>

<script>
import {
  IDLE,
  SLEEP,
  RIGHT,
  WRONG,
  STATUS_WRONG,
  STATUS_IDLE,
  STATUS_RIGHT,
  STATUS_SLEEP,
} from '@/const/avatar'
import { isVisible } from '@/utils/visibilitySettings'
import GecDragger from '@/layouts/components/GECDragger.vue'

export default {
  components: {
    GecDragger,
  },

  data() {
    return {
      avatarMinimize: false,
      avatarSize: 120,
    }
  },
  computed: {
    activeImageIndex() {
      return this.$store.state.appConfig.selectedCharacter
    },
    studentAttention() {
      return this.$store.getters['studentInteractions/currentStudentAttention']
    },
    currentStatus() {
      return this.$store.state.appConfig.currentStatus
    },
    showAvatarStudent() {
      return this.$store.state.appConfig.showAvatarStudent
    },
    // showAvatar() {
    //   return this.$store.state.appConfig.showAvatar
    // },
    hideAvatar() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('avatar', schoolSetting, distSetting)
    },
    currentAvatar: {
      get() {
        if (this.currentStatus === STATUS_WRONG) return WRONG[this.activeImageIndex]
        if (this.currentStatus === STATUS_RIGHT) return RIGHT[this.activeImageIndex]
        if (this.currentStatus === STATUS_SLEEP) return SLEEP[this.activeImageIndex]
        if (this.currentStatus === STATUS_IDLE) return IDLE[this.activeImageIndex]
        return null
      },
    },
  },
  mounted() {
    this.notResponsiveWindow()
  },
  methods: {
    notResponsiveWindow() {
      if (window.innerWidth <= 768) {
        this.avatarMinimize = true
      }
    },
  },
}
</script>
<style scoped>
@media only screen and (max-width: 1000px) {
  #interactive-avatar {
    width: 100px;
    height: 100px;
  }
}
.mix-min-icon {
  right: 0px;
  top: -4px;
  position: absolute;
  display: none;
}
#avatar-dragger:hover .mix-min-icon {
  display: block;
}
</style>
