export const USER_TYPE_SUPER = 'super'
export const USER_TYPE_INTERNATIONAL = 'international'
export const USER_TYPE_NATIONAL = 'national'
export const USER_TYPE_COMPANY = 'company'
export const USER_TYPE_DISTRICT = 'district'
export const USER_TYPE_SCHOOL = 'school'
export const USER_TYPE_TEACHER = 'teacher'
export const USER_TYPE_STUDENT = 'student'
export const USER_TYPE_PARENT = 'parent'
export const USER_TYPE_CURRICULUM = 'curriculum'

const USER_TYPES = [
  USER_TYPE_SUPER,
  USER_TYPE_INTERNATIONAL,
  USER_TYPE_NATIONAL,
  USER_TYPE_COMPANY,
  USER_TYPE_DISTRICT,
  USER_TYPE_SCHOOL,
  USER_TYPE_TEACHER,
  USER_TYPE_PARENT,
  USER_TYPE_STUDENT,
]

export function canCreateSubUsers(userType) {
  return USER_TYPES.indexOf(userType) <= 5
}
export function getAllUserTypes() {
  return USER_TYPES.slice()
}
export function canHaveChildUsers(userType) {
  return userType !== USER_TYPE_STUDENT
}
export function getFirstChildOfUser(userType) {
  if (userType === USER_TYPE_TEACHER) return USER_TYPE_STUDENT
  if (userType === USER_TYPE_PARENT) return USER_TYPE_STUDENT
  return USER_TYPES[USER_TYPES.indexOf(userType) + 1]
}
export function getFirstParentOfUser(userType) {
  if (userType === USER_TYPE_PARENT) return null
  return USER_TYPES[USER_TYPES.indexOf(userType) - 1]
}
export function canViewStudentReport(userType) {
  return USER_TYPES.indexOf(userType) <= 7
}
export function getChildUserTypesOfType(userType) {
  if (userType === USER_TYPE_TEACHER) return [USER_TYPE_STUDENT, USER_TYPE_PARENT]
  if (userType === USER_TYPE_PARENT) return [USER_TYPE_STUDENT]
  if (userType === USER_TYPE_STUDENT || userType === USER_TYPE_PARENT) return []
  if (userType === USER_TYPE_SUPER) return [...USER_TYPES, USER_TYPE_CURRICULUM]
  // create new variable
  // prevents copy by reference
  const userTypes = USER_TYPES.slice()
  return userTypes.splice(userTypes.indexOf(userType) + 1)
}

export function canCreateUsers(userType) {
  if (userType === USER_TYPE_TEACHER) return [USER_TYPE_STUDENT, USER_TYPE_PARENT]
  if (userType === USER_TYPE_PARENT) return [USER_TYPE_STUDENT]
  if (userType === USER_TYPE_STUDENT || userType === USER_TYPE_PARENT) return []
  if (userType === USER_TYPE_SUPER) return [USER_TYPE_SUPER, USER_TYPE_INTERNATIONAL]
  if (userType === USER_TYPE_NATIONAL) return [USER_TYPE_COMPANY, USER_TYPE_CURRICULUM]
  // create new variable
  // prevents copy by reference
  const userTypes = USER_TYPES.slice()
  return userTypes.splice(userTypes.indexOf(userType) + 1)
}

export function canAttachParentToStudent(userType) {
  return USER_TYPES.indexOf(userType) <= 6
}

export function canDirectlyCreateUserOfType(authUserType, toCreateUserType) {
  let userTypeThatCanBeCreated = []
  if (authUserType === USER_TYPE_SUPER) {
    userTypeThatCanBeCreated = [USER_TYPE_INTERNATIONAL, USER_TYPE_SUPER]
  } else if (authUserType === USER_TYPE_NATIONAL) {
    userTypeThatCanBeCreated = [USER_TYPE_COMPANY]
  } else if (authUserType === USER_TYPE_COMPANY) {
    userTypeThatCanBeCreated = [USER_TYPE_DISTRICT]
  } else if (authUserType === USER_TYPE_DISTRICT) {
    userTypeThatCanBeCreated = [USER_TYPE_SCHOOL]
  } else if (authUserType === USER_TYPE_SCHOOL) {
    userTypeThatCanBeCreated = [USER_TYPE_TEACHER]
  } else if (authUserType === USER_TYPE_TEACHER) {
    userTypeThatCanBeCreated = [USER_TYPE_STUDENT]
  } else if (authUserType === USER_TYPE_PARENT) {
    userTypeThatCanBeCreated = [USER_TYPE_STUDENT]
  }
  return userTypeThatCanBeCreated.includes(toCreateUserType)
}

export function getChildUserOf(authUserType) {
  switch (authUserType) {
    case USER_TYPE_SUPER:
      return USER_TYPE_NATIONAL
    case USER_TYPE_INTERNATIONAL:
      return USER_TYPE_NATIONAL
    case USER_TYPE_NATIONAL:
      return USER_TYPE_COMPANY
    case USER_TYPE_COMPANY:
      return USER_TYPE_DISTRICT
    case USER_TYPE_DISTRICT:
      return USER_TYPE_SCHOOL
    case USER_TYPE_SCHOOL:
      return USER_TYPE_TEACHER
    case USER_TYPE_TEACHER:
      return USER_TYPE_STUDENT
    default:
      return authUserType
  }
}

export function getUserTypeLevel(authUserType) {
  switch (authUserType) {
    case USER_TYPE_SUPER:
      return 1
    case USER_TYPE_INTERNATIONAL:
      return 2
    case USER_TYPE_NATIONAL:
      return 3
    case USER_TYPE_COMPANY:
      return 4
    case USER_TYPE_DISTRICT:
      return 5
    case USER_TYPE_SCHOOL:
      return 6
    case USER_TYPE_TEACHER:
      return 7
    case USER_TYPE_PARENT:
      return 8
    case USER_TYPE_STUDENT:
      return 9
    default:
      return 100
  }
}
