<template>
  <b-col
    md="12"
    class="mb-1"
  >
    <b-card
      no-body
      class="mb-0"
    >
      <h5 class="p-1 pb-0">
        {{ $t('network-module.requests') }}
      </h5>
      <div v-if="requests.data.length>0">
        <b-table
          small
          responsive
          :items="getRequestsForTable"
          :fields="columns"
        >
          <template
            #cell(action)="member"
          >
            <span>
              <b-dropdown
                variant="link"
                no-caret
                boundary="viewport"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item
                  @click="acceptRequest(member.item.request_id)"
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">{{ $t("accept") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="declineRequest(member.item.request_id)"
                >
                  <feather-icon icon="XIcon" />
                  <span class="align-middle ml-50">{{ $t("decline") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>

          </template>
        </b-table>
        <b-row v-if="requests.pagination.totalItems>requests.pagination.perPage">
          <b-col class="mr-1 d-flex flex-row-reverse">
            <b-pagination
              v-model="requests.pagination.currentPage"
              :total-rows="requests.pagination.totalItems"
              :per-page="requests.pagination.perPage"
              aria-controls="my-table"
              @change="getMemberRequests"
            />
          </b-col>
        </b-row>
      </div>
      <p
        v-else
        class="px-1"
      >
        {{ $t('network-module.no-requests') }}
      </p>
    </b-card>
  </b-col>
</template>
<script>
import {
  BCol, BCard, BTable, BPagination, BRow, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCol, BCard, BTable, BPagination, BRow, BDropdown, BDropdownItem,
  },
  data() {
    return {
      columns: ['name', 'action'],
    }
  },
  computed: {
    requests() {
      return this.$store.state.network.requests
    },
    networkId() {
      return this.$store.state.network.networkId
    },
    getRequestsForTable() {
      return this.requests.data.map(e => ({
        name: e.fullname,
        request_id: e.request_id,
      }))
    },
  },
  created() {
    this.getMemberRequests()
  },
  methods: {
    acceptRequest(id) {
      useJwt.acceptRequest({
        request_id: id,
      }).then(response => {
        this.getMemberRequests()
        this.$store.dispatch('network/getNetworkMembers')
        this.showSuccessMessage(response)
      })
    },
    declineRequest(id) {
      useJwt.rejectRequest({
        request_id: id,
      }).then(response => {
        this.getMemberRequests()
        this.showSuccessMessage(response)
      })
    },
    assignPaginationData(data) {
      this.paginationData = {
        currentPage: data.current_page,
        perPage: data.per_page,
        totalItems: data.total,
        lastPage: data.total_pages,
      }
    },
    getMemberRequests(page = this.requests.pagination.current_page) {
      this.$store.dispatch('network/getRequests', page)
    },
  },
}
</script>
