<template>
  <b-overlay
    class="student-problem theme-1"
    :show="updatingLayout"
    @keyup.enter="handleSubmit"
  >
    <b-row class="mb-0">
      <b-col md="4">
        <b-form-group label="Select a Template">
          <b-form-select
            v-model="initiallySelectedTemplate"
            :options="templates"
            @change="templateChanged"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="selectedTemplate"
        md="8"
        class="d-flex mb-1 justify-content-end align-items-end"
      >
        <div v-if="template && template.rules && !isStatement"
             class="d-flex mb-1 mr-2"
        >
          <b-form-radio
            v-model="template.rules.applicable_for"
            value="all"
            :name="`applicableFor${templateRootID}`"
            class="custom-control-primary mr-2"
            @change="$forceUpdate()"
          >
            All
          </b-form-radio>
          <b-form-radio
            v-model="template.rules.applicable_for"
            value="correct"
            :name="`applicableFor${templateRootID}`"
            class="custom-control-success mr-2"
            @change="$forceUpdate()"
          >
            Only Correct
          </b-form-radio>
          <b-form-radio
            v-model="template.rules.applicable_for"
            value="incorrect"
            :name="`applicableFor${templateRootID}`"
            class="custom-control-danger"
            @change="$forceUpdate()"
          >
            Only Incorrect
          </b-form-radio>
        </div>
        <b-button
          v-if="template && !avoidDelete && template.id"
          variant="danger"
          class="mr-1"
          :disabled="isProcessing"
          @click="$emit('removeTemplate')"
        >
          <b-spinner
            v-show="isProcessing"
            class="mr-1"
            small
            variant="light"
          />
          Delete Template
        </b-button>
        <div class="mr-1">
          <b-button
            v-b-tooltip.hover="template ? 'Show/Hide Code' : 'Create a template first'"
            variant="primary"
            :disabled="isProcessing || !template"
            @click="toggleCode"
          >
            {{ isCodeVisible ? "Hide Code" : "Show Code" }}
          </b-button>
        </div>
        <div>
          <b-button
            variant="primary"
            :disabled="isProcessing"
            @click="handleSubmit"
          >
            <b-spinner
              v-show="isProcessing"
              class="mr-1"
              small
              variant="light"
            />
            {{ template ? "Update Template" : "Create Template" }}
          </b-button>

        </div>
      </b-col>
    </b-row>

    <b-collapse
      id="collapse-1"
      v-model="isCodeVisible"
      class="mt-2"
    >
      <b-card class="border mb-1">
        <div class="mb-1">
          <b-button
            variant="outline-primary"
            class="mr-1"
            @click="copyCode"
          >
            <feather-icon
              icon="CopyIcon"
              class="mr-50"
            />
            Copy
          </b-button>
          <b-button
            variant="outline-primary"
            @click="refreshCode"
          >
            <feather-icon
              icon="RefreshCwIcon"
              class="mr-50"
            />
            Refresh
          </b-button>
        </div>

        <b-card-code
          no-header
          class="html-code-card"
        >
          <div class="card-code p-0 m-0">
            <pre class="language-markup">
          <code
            class="language-markup"
            v-text="htmlCode"
          />
        </pre>
          </div>
        </b-card-code>
      </b-card>
    </b-collapse>

    <div v-if="selectedTemplate">
      <create-common
        :no-template="selectedTemplate == 'none'"
        :problem-data="problemData"
      />

      <h3
        v-if="selectedTemplate"
        class="mb-1"
      >
        <span v-if="problemType === 'fib_problem'">
          <small class="d-block text-italic">
            <i class="text-secondary"><b>Hints:</b> A fox can ***<span class="text-success">Correct</span>,<span class="text-danger">Incorrect</span>,<span class="text-danger">ans2</span>,<span class="text-danger">ans3</span>*** over the ***<span class="text-success">Correct</span>,<span class="text-danger">Incorrect</span>,<span class="text-danger">Incorrect</span>***</i>
          </small>
          <small class="d-block text-italic">
            Note: Correct answer must be on a first place
          </small>
        </span>
      </h3>

      <component
        :is="getTemplateEditComponent"
        v-if="selectedTemplate != 'none'"
        :type="selectedTemplate"
        :template-data="templateData"
        :avoid-save="avoidSave"
        @template-data="setTemplateData"
        @onScormChange="handleScormChange"
      />
      <div class="d-flex justify-content-between"
           :class="template ? 'flex-row' : 'flex-column'"
      >
        <div>
          <h3>Preview</h3>
        </div>
        <div v-if="template"
             class="d-flex"
        >
          <div v-if="template.animated_layout"
               class="col d-flex"
          >
            <div class="mr-3 mt-2">
              <b-button
                variant="success"
                size="sm"
                class="ml-2"
                @click="copyAnimationTemplate"
              >
                <b-spinner
                  v-if="isSaving"
                  small
                /> Save Template For Using in other problem
              </b-button>
            </div>
            <ManageSavedTemplate @applyTemplate="loadSelectedTemplate" />
            <!-- <b-form-group label="Animated Layout">
              <v-select
                :options="savedTemplateLists"
                :reduce="item => item.value"
                label="name"
                style="width: 300px"
                placeholder="Select preloaded template"
                @input="loadSelectedTemplate"
              />
            </b-form-group> -->
          </div>
          <b-form-group v-if="!problemType"
                        label="Animated Layout"
          >
            <b-form-checkbox v-model="template.animated_layout"
                             value="1"
                             switch
                             @change="handleAnimationTemplateToggle"
            />
          </b-form-group>
        </div>
        <div v-else
             class="flex-1"
        >
          <h6 class="text-primary">
            Please save the template to preview it.
          </h6>
        </div>
      </div>
    </div>
    <b-overlay v-if="template"
               :show="isPreviewLoading"
    >
      <animated-layout ref="animationLayout"
                       :key="template.animated_template_id"
                       :enabled="!!template.animated_layout && !!template.animated_template_id"
                       :template-id="template.animated_template_id"
                       @handleTemplateUpdate="handleAnimationTemplateUpdate"
      >
        <div
          v-if="selectedTemplate"
          ref="htmlComponent"
          class="problem-overview-content px-3 px-md-1"
        >
          <common :problem-data="problemData" />
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="
              problemData.before_template &&
                problemData.before_template.length > 0 &&
                (selectedTemplate !== 'shapes' || (selectedTemplate === 'shapes' && fibType !== 'fib'))
                && selectedTemplate !== 'side-cards'
            "
            v-html="problemData.before_template"
          />
          <!-- eslint-enable vue/no-v-html -->
          <component
            :is="getTemplateComponent"
            v-if="templateData"
            :data="templateData"
            :template="template"
            :template-options="getTemplateOptions"
          >
            <div
              v-if="selectedTemplate === 'shapes' && fibType === 'fib'"
              v-html="problemData.before_template"
            />
          </component>
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="
              problemData.after_template &&
                problemData.after_template.length > 0
            "
            v-html="problemData.after_template"
          />
        <!-- eslint-enable vue/no-v-html -->

        </div>
      </animated-layout>
    </b-overlay>
  </b-overlay>
</template>
<script>
import {
  BRow,
  BCol,
  BFormSelect,
  BFormGroup,
  BButton,
  BModal,
  BSpinner,
  BCollapse,
  BCard,
  BOverlay,
  BFormRadio,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import { mapGetters } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import AnimatedLayout from '@/views/super/problem/body-template/manager.vue'
import vSelect from 'vue-select'
import ManageSavedTemplate from '@/views/super/problem/body-template/ManageSavedTemplate.vue'
import Cards from './Cards.vue'
import Carousel from './Carousel.vue'
import CenteredCards from './CenteredCard.vue'
import ImageParagraph from './ImageParagraph.vue'
import List from './List.vue'
import Paragraph from './Paragraph.vue'
import Table from './Table.vue'
import Common from './Common.vue'
import ImageQA from './ImageQA.vue'
import Shapes from './Shapes.vue'
import CreateCards from './create-edit/Cards.vue'
import CreateTable from './create-edit/Table.vue'
import CreateCommon from './create-edit/Common.vue'
import Scorm from './Scorm.vue'
import ScormCreate from './create-edit/Scorm.vue'
import SideCard from './SideCard.vue'

export default {
  components: {
    Carousel,
    BCollapse,
    Cards,
    Paragraph,
    BCard,
    ImageParagraph,
    BCardCode,
    CenteredCards,
    List,
    BButton,
    Table,
    BRow,
    BCol,
    BFormSelect,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    CreateCards,
    CreateTable,
    CreateCommon,
    Common,
    BModal,
    BSpinner,
    ImageQA,
    Shapes,
    BOverlay,
    Scorm,
    ScormCreate,
    AnimatedLayout,
    ManageSavedTemplate,
    SideCard,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    template: {
      type: Object,
      default: null,
    },
    initialTemplateOptions: {
      type: Array,
      default: null,
    },
    avoidSave: {
      type: Boolean,
      default: false,
    },
    extraTemplatePara: {
      type: Object,
      default: null,
    },
    problemType: {
      type: String,
      default: '',
    },
    avoidDelete: {
      type: Boolean,
      default: false,
    },
    isStatement: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedTemplate: null,
      initiallySelectedTemplate: null,
      templateData: null,
      storedTemplates: [],
      htmlCode: '',
      problemData: {
        question: '',
        page_link: '',
        before_template: '',
        after_template: '',
      },
      isProcessing: false,
      isCodeVisible: false,
      templates: [
        { value: null, text: 'Please select a template' },
        { value: 'none', text: 'None' },
        { value: 'cards', text: 'Cards' },
        { value: 'centered-cards', text: 'Centered Cards' },
        { value: 'carousel', text: 'Carousel' },
        { value: 'list', text: 'List' },
        { value: 'table', text: 'Table' },
        { value: 'paragraph', text: 'Paragraph' },
        { value: 'image-paragraph', text: 'Image & Paragraph' },
        { value: 'shapes', text: 'Shapes' },
        { value: 'image-qa', text: 'Q&A with Image' },
        { value: 'scorm', text: 'Scorm Template' },
        { value: 'side-cards', text: 'Side Cards' },
      ],
      defaultShape: 'hexagon.png',
      selectedShape: null,
      fibType: 'fib',
      bgColor: '',
      textAlign: 'left',
      isPreviewLoading: false,
      updatingLayout: false,
      savedTemplateLists: [],
      templateRootID: (Math.random() + 1).toString(36).substring(7),
      isSaving: false,
    }
  },
  computed: {
    ...mapGetters('problem', { shapes: 'getShapes' }),
    templateApplicableFor: {
      get() {
        return this.template?.rules?.applicable_for
      },
      set(value) {
        this.template.rules.applicable_for = value
      },
    },
    getTemplateEditComponent() {
      if (this.selectedTemplate === 'table') return 'CreateTable'
      if (this.selectedTemplate === 'scorm') return 'ScormCreate'
      return 'CreateCards'
    },
    getTemplateComponent() {
      if (this.selectedTemplate === 'cards') return 'Cards'
      if (this.selectedTemplate === 'centered-cards') return 'CenteredCards'
      if (this.selectedTemplate === 'carousel') return 'Carousel'
      if (this.selectedTemplate === 'list') return 'List'
      if (this.selectedTemplate === 'table') return 'Table'
      if (this.selectedTemplate === 'paragraph') return 'Paragraph'
      if (this.selectedTemplate === 'image-paragraph') return 'ImageParagraph'
      if (this.selectedTemplate === 'shapes') return 'Shapes'
      if (this.selectedTemplate === 'image-qa') return 'ImageQA'
      if (this.selectedTemplate === 'scorm') return 'Scorm'
      if (this.selectedTemplate === 'side-cards') return 'SideCard'
      if (this.selectedTemplate === 'none') return ''
      return 'CreateCards'
    },
    getTemplateOptions() {
      return {
        shape: this.selectedShape,
        type: this.fibType,
        bgColor: this.bgColor,
        textAlign: this.textAlign,
      }
    },
  },
  watch: {
    template: {
      handler() {
        if (this.template) {
          this.initiallySelectedTemplate = this.template.type
          this.selectedTemplate = this.template.type
          this.problemData = {
            question: this.template.question,
            page_link: this.template.page_link ? this.template.page_link : '',
            after_template: this.template.after_template
              ? this.template.after_template
              : '',
            before_template: this.template.before_template
              ? this.template.before_template
              : '',
            rules: this.template?.rules || {},
          }
          this.templateData = this.template.template
          if (this.selectedTemplate === 'shapes') {
            this.getShape()
            this.textAlign = this.template?.template[0]?.textAlign
            this.fibType = this.template?.template[0]?.type
            this.bgColor = this.template?.template[0]?.bgColor
          }
          if (this.templateData) {
            this.templateData.forEach(e => {
              if (e.image) e.image_url = e.image
            })
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    if (this.initialTemplateOptions) {
      this.templates = this.initialTemplateOptions
      setTimeout(() => {
        if (!this.initiallySelectedTemplate) {
          this.initiallySelectedTemplate = this.templates[0].value
          this.templateChanged(this.templates[0].value)
        }
      }, 1500)
    }
  },
  methods: {
    refreshCode() {
      this.htmlCode = this.formatHtml(
        this.$refs.htmlComponent.innerHTML.replaceAll('<!---->', ''),
      )
    },
    loadSelectedTemplate(template) {
      if (template) {
        this.$refs.animationLayout.loadTemplate(template)
      } else {
        this.showError('Template not found')
      }
    },
    toggleCode() {
      this.refreshCode()
      this.isCodeVisible = !this.isCodeVisible
    },
    copyCode() {
      this.copyTextToClipboard(this.htmlCode)
    },
    fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement('textarea')
      textArea.value = text

      // Avoid scrolling to bottom
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        const successful = document.execCommand('copy')
        const msg = successful ? 'successful' : 'unsuccessful'
        this.showMessage(`Copying text command was ${msg}`)
      } catch (err) {
        this.showError('Fallback: Oops, unable to copy', err)
      }

      document.body.removeChild(textArea)
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        () => {
          this.showMessage('Copied To Clipboard')
        },
        () => {
          this.showError('Could not copy text')
        },
      )
    },
    handleSubmit() {
      if (this.template && this.template.id) {
        this.updateTemplate()
        return
      }
      this.storeTemplate()
    },

    formatHtml(html) {
      const tab = '\t'
      let result = ''
      let indent = ''

      html.split(/>\s*</).forEach(element => {
        if (element.match(/^\/\w/)) {
          indent = indent.substring(tab.length)
        }

        result += `${indent}<${element}>\r\n`

        // eslint-disable-next-line no-useless-escape
        if (element.match(/^<?\w[^>]*[^\/]$/) && !element.startsWith('input')) {
          indent += tab
        }
      })

      return result.substring(1, result.length - 3)
    },

    storeTemplate() {
      this.isProcessing = true
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      data.append('type', this.selectedTemplate)
      data.append(
        'question',
        this.problemData.question ? this.problemData.question : '',
      )
      data.append(
        'page_link',
        this.problemData.page_link ? this.problemData.page_link : '',
      )
      data.append(
        'after_template',
        this.problemData.after_template ? this.problemData.after_template : '',
      )
      data.append(
        'before_template',
        this.problemData.before_template ? this.problemData.before_template : '',
      )
      data.append(
        'rules',
        JSON.stringify((this.problemData?.rules?.applicable_for && this.problemData?.rules) || {
          applicable_for: 'all',
        }),
      )

      if (this.extraTemplatePara) {
        if (this.extraTemplatePara) {
          Object.keys({ ...this.extraTemplatePara }).forEach(key => {
            data.append(key, this.extraTemplatePara[key])
          })
        }
      }

      if (this.templateData) {
        this.templateData.forEach((template, index) => {
          Object.keys(template).forEach(key => {
            switch (key) {
              case 'shape':
                data.append(`template[${index}][${key}]`, this.selectedShape.value)
                break
              case 'bgColor':
                data.append(`template[${index}][${key}]`, this.bgColor)
                break
              case 'textAlign':
                data.append(`template[${index}][${key}]`, this.textAlign)
                break
              default:
                break
            }
            if (key !== 'image_url' || key !== 'columns' || key !== 'rows') {
              data.append(
                `template[${index}][${key}]`,
                template[key] != null ? template[key] : '',
              )
            }
            if (key === 'columns') {
              template[key].forEach((col, i) => {
                data.append(`template[${index}][${key}][${i}]`, col)
              })
            }
            if (key === 'rows') {
              template[key].forEach((row, i) => {
                row.forEach((innerRow, inx) => {
                  data.append(
                    `template[${index}][${key}][${i}][${inx}]`,
                    innerRow,
                  )
                })
              })
            }
          })
        })
      }

      if (this.avoidSave) {
        this.$emit('templateStored', {
          type: this.selectedTemplate,
          question: this.problemData.question ? this.problemData.question : '',
          page_link: this.problemData.page_link ? this.problemData.page_link : '',
          before_template: this.problemData.before_template ? this.problemData.before_template : '',
          template: this.templateData,
          after_template: this.problemData.after_template ? this.problemData.after_template : '',
        })
        this.isProcessing = false
        this.$emit('removeExtraParams')
      } else {
        const hitApi = this.extraTemplatePara ? '/api/v1/problem/templates' : '/api/v1/templates'
        useJwt.axiosIns
          .post(hitApi, data, config)
          .then(res => {
            this.$emit('templateStored', res.data.data)
            this.$emit('updateTemplateStored', res.data.data.template)
            this.showMessage(res.data.message)
          })
          .catch(err => {
            this.showErrorMessage(err)
          })
          .finally(() => {
            this.isProcessing = false
            this.$emit('removeExtraParams')
          })
      }
    },
    updateTemplate() {
      this.isProcessing = true
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      data.append('_method', 'PUT')
      data.append('type', this.selectedTemplate)
      data.append(
        'question',
        this.problemData.question ? this.problemData.question : '',
      )
      data.append(
        'page_link',
        this.problemData.page_link ? this.problemData.page_link : '',
      )
      data.append(
        'rules',
        JSON.stringify((this.problemData?.rules?.applicable_for && this.problemData?.rules) || {
          applicable_for: 'all',
        }),
      )
      data.append('before_template', this.problemData.before_template)
      data.append('after_template', this.problemData.after_template)
      if (this.extraTemplatePara) {
        Object.keys({ ...this.extraTemplatePara }).forEach(key => {
          data.append(key, this.extraTemplatePara[key])
        })
      }
      if (this.templateData) {
        this.templateData.forEach((template, index) => {
          Object.keys(template).forEach(key => {
            if (key === 'shape') {
              data.append(`template[${index}][${key}]`, this.selectedShape.value)
            }
            if (key !== 'image_url' || key !== 'columns' || key !== 'rows') {
              data.append(
                `template[${index}][${key}]`,
                template[key] != null ? template[key] : '',
              )
            }
            if (key === 'columns') {
              template[key].forEach((col, i) => {
                data.append(`template[${index}][${key}][${i}]`, col)
              })
            }
            if (key === 'rows') {
              template[key].forEach((row, i) => {
                row.forEach((innerRow, inx) => {
                  data.append(
                    `template[${index}][${key}][${i}][${inx}]`,
                    innerRow,
                  )
                })
              })
            }
          })
        })
      }

      if (this.avoidSave) {
        this.$emit('templateStored', {
          type: this.selectedTemplate,
          question: this.problemData.question ? this.problemData.question : '',
          page_link: this.problemData.page_link ? this.problemData.page_link : '',
          before_template: this.problemData.before_template ? this.problemData.before_template : '',
          template: this.templateData,
          after_template: this.problemData.after_template ? this.problemData.after_template : '',
        })
        this.isProcessing = false
        this.$emit('removeExtraParams')
      } else {
        const hitApi = `/api/v1/templates/${this.template.id}`
        useJwt.axiosIns
          .post(hitApi, data, config)
          .then(res => {
            this.$emit('updateTemplateStored', res.data.data)
            this.$emit('templateStored', res.data.data)
            this.showMessage(res.data.message)
          })
          .catch(err => {
            this.showErrorMessage(err)
          })
          .finally(() => {
            this.isProcessing = false
            this.$emit('removeExtraParams')
          })
      }
    },
    templateChanged(val) {
      switch (val) {
        case 'cards':
        case 'centered-cards':
        case 'carousel':
        case 'list':
        case 'image-paragraph':
          this.templateData = [
            {
              image_url: 'https://via.placeholder.com/350x150',
              title: 'Example',
              subtitle: 'Example',
            },
          ]
          break
        case 'table':
          this.templateData = [
            {
              columns: ['Age', 'Name'],
              rows: [['18', 'Sample User']],
            },
          ]
          break
        case 'paragraph':
          this.templateData = [
            {
              image_url: 'https://via.placeholder.com/350x150',
              title: 'Example',
              subtitle: 'Example',
              imagePosition: 'left',
            },
          ]
          break
        case 'shapes':
          this.templateData = [
            {
              title: '',
              subtitle: '',
              shape: this.selectedShape ? this.selectedShape.value : this.defaultShape,
              type: this.fibType,
              bgColor: this.bgColor ? this.bgColor : '#057a9d',
              textAlign: this.textAlign,
            },
          ]
          break
        case 'image-qa':
          this.templateData = [
            {
              image_url: 'https://via.placeholder.com/350x150',
              title: 'Question',
              subtitle: '***Answer***',
            },
          ]
          break
        case 'none':
          this.templateData = []
          this.problemData.after_template = ''
          break
        case 'scorm':
          this.templateData = [
            {
              uuid: '',
            },
          ]
          this.problemData.after_template = ''
          break
        default:
          this.templateData = [
            {
              image_url: 'https://via.placeholder.com/350x150',
              title: 'Example',
              subtitle: 'Example',
            },
          ]
      }
      this.selectedTemplate = val
      if (val === 'shapes') this.getShape()
    },
    setTemplateData(dataType, data) {
      switch (dataType) {
        case 'shape':
          this.isPreviewLoading = true
          this.selectedShape = data
          break
        case 'type':
          this.fibType = data.value
          break
        case 'bgColor':
          this.bgColor = data
          break
        case 'textAlign':
          this.textAlign = data.value
          break
        default:
          break
      }
      if (dataType === 'bgColor') this.templateData[0].bgColor = data
      else {
        this.templateData[0][dataType] = data.value
        if (dataType === 'shape') {
          setTimeout(() => {
            this.isPreviewLoading = false
          }, 400)
        }
      }
    },
    getShape() {
      /* eslint-disable prefer-destructuring */
      const sh = this.template?.template[0]?.shape ? this.template.template[0].shape : this.defaultShape
      this.selectedShape = this.shapes.find(shape => shape.value === sh)
    },
    handleScormChange(newScorm) {
      this.templateData = [
        {
          uuid: newScorm,
        },
      ]
    },
    handleAnimationTemplateUpdate(templateId, params) {
      if (this.updatingLayout) {
        return
      }
      this.updatingLayout = true
      useJwt.updateAnimationTemplate(templateId, {
        ...params,
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.updatingLayout = false
      })
    },
    handleAnimationTemplateToggle(value) {
      this.$forceUpdate()
      const animationTemplateRef = this.$refs.animationLayout
      useJwt.toggleStatementFeedBackAnimationTemplate(this.template.id, {
        ...animationTemplateRef.getTemplateParams(),
        enable: value,
      }).then(response => {
        const template = response.data.data
        this.template.animated_layout = template.animated_layout
        this.template.animated_template_id = template.animated_template_id
        this.$forceUpdate()
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    copyAnimationTemplate() {
      // eslint-disable-next-line no-alert
      const name = prompt('Please enter template name')
      if (name) {
        this.isSaving = true
        useJwt.saveAnimationTemplateCopy(this.template.animated_template_id, {
          template_name: name,
        }).then(response => {
          this.showSuccessMessage(response)
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isSaving = false
        })
      }
    },
  },

}
</script>
