<template>
  <b-overlay :show="processing">
    <b-modal
      id="modal-add-students"
      ref="modal-add-students"
      ok-only
      size="lg"
      ok-variant="primary"
      :ok-title="$t('actions.close')"
      modal-class="modal-primary"
      centered
      :title="$t('class-module.add-students')"
      class="p-0 m-0"
      @close="$emit('close')"
      @hidden="$emit('close')"
    >
      <b-col md="12"
             class="p-0 m-0"
      >
        <b-row>
          <b-col md="12">
            <div>
              <input
                type="text"
                class="form-control mb-1"
                :placeholder="$t('messages.seachLabel')"
                @input="searchStudents"
              >
            </div>
          </b-col>
          <b-col v-if="students?.length"
                 md="12"
          >
            <div class="select-students">
              <vue-perfect-scrollbar ref="scrollContainer"
                                     :settings="perfectScrollbarSettings"
                                     class="scroll-content"
                                     @scroll="handleScroll"
              >
                <div class="d-flex align-items-center"
                     :class="students?.length>2 ? 'justify-content-around' :'gap-10'"
                >
                  <div v-for="students,index in studentsForSelect"
                       :key="index"
                  >
                    <div v-for="option in students"
                         :key="option.code"
                         class="form-check d-flex align-items-center"
                    >
                      <input
                        :id="option.code"
                        v-model="selectedStudents"
                        type="checkbox"
                        class="form-check-input"
                        :value="option.code"
                        style="width:20px; height:20px;"
                      >
                      <label :for="option.code"
                             class="form-check-label"
                             style="margin-left:7px;"
                      >
                        {{ option.label }}
                      </label>
                    </div>
                  </div>
                </div>
                <div v-if="processing"
                     class="text-center"
                >
                  <b-spinner />
                </div>
              </vue-perfect-scrollbar>
            </div>
            <div v-if="selectedStudents.length"
                 class=""
            >
              <h6 class="text-success">
                {{ $t('selected-students') }} : {{ selectedStudents.length }}
              </h6>
            </div>
            <div v-else>
              <p class="text-muted"
                 style="font-size:13px; font-style: italic;"
              >
                <feather-icon icon="InfoIcon" />
                {{ $t("class-module.add-student-info") }}
              </p>
            </div>
          </b-col>

          <b-col v-else>
            <b-alert
              show
              class="p-1"
            >
              {{ $t('no-student-added-in-class') }}
            </b-alert>
          </b-col>
        </b-row>
      </b-col>
      <template #modal-footer="{}">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-if="students?.length"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="selectedStudents?.length == 0 ? $t('select-atleast-one-student') : $t('add-student-to-class')"
              variant="primary"
              :disabled="isProcessing || selectedStudents?.length === 0"
              @click="addStudentsToAClass()"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t("class-module.add-students") }}
            </b-button>

            <b-button
              v-else
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              @click="() => { $bvModal.hide('modal-add-students') }"
            >
              {{ $t("actions.close") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BModal,
  BSpinner,
  BRow,
  BCol,
  BButton,
  BAlert,
  BOverlay,
  VBTooltip,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    BModal,
    BSpinner,
    BRow,
    BCol,
    // vSelect,
    BButton,
    BAlert,
    BOverlay,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    classId: {
      type: [Number, String],
      default: null,
    },
    students: {
      type: [Array, Object],
      required: true,
    },
    isHighUser: {
      type: Boolean,
      default: false,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    lastPage: {
      type: [String, Number],
      default: 1,
    },
  },
  data() {
    return {
      isProcessing: false,
      selectedStudents: [],
      currentPage: 1,
      perfectScrollbarSettings: {
        wheelPropagation: false,
      },
      searchTimer: null,
    }
  },
  computed: {
    studentsForSelect() {
      const mappedStudents = this.students.map(e => ({
        code: e.id,
        label: e.fullname ? e.fullname : `${e.firstname} ${e.lastname}`,
      }))
      const chunkSize = Math.ceil(mappedStudents.length / 3)

      // Split the original array into three separate arrays
      const firstArray = mappedStudents.slice(0, chunkSize)
      const secondArray = mappedStudents.slice(chunkSize, chunkSize * 2)
      const thirdArray = mappedStudents.slice(chunkSize * 2)
      return [firstArray, secondArray, thirdArray]
    },
  },
  methods: {
    addStudentsToAClass() {
      this.isProcessing = true
      useJwt
        .addStudentToClass(this.classId, {
          students: this.selectedStudents,
        })
        .then(res => {
          this.selectedStudents = []
          this.$refs['modal-add-students'].hide()
          this.$emit('onStudentsAdded')
          this.showSuccessMessage(res)
        }).catch(error => {
          if (error.response?.data?.code === 403) {
            this.showUnAuthorized(error.response?.data?.message)
          } else {
            this.showError(error.response?.data?.message)
          }
        })
        .finally(() => {
          this.isProcessing = false
        })
    },

    searchStudents(event) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(() => {
        this.$emit('searchStudents', event.target.value)
      }, 500)
    },

    handleScroll(e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target
      // console.log(this.currentPage, this.lastPage)
      // && this.students.hasMorePages
      if (scrollTop < 5 && !this.processing) {
        this.currentPage = 1
        this.$emit('fetchMoreStudents', this.currentPage)
      } else if (scrollHeight - (scrollTop + offsetHeight) < 5 && !this.processing && this.lastPage >= this.currentPage) {
        this.currentPage += 1
        this.$emit('fetchMoreStudents', this.currentPage)
      }
    },
  },
}
</script>

<style lang="scss">
.dark-layout .vs__deselect {
  background: #fff !important;
  border-radius: 1px;
}
.select-students{
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 10px;
  .scroll-content{
    max-height: 300px;
    .gap-10{
      gap:10px;
    }
  }
  .form-check{
    margin-bottom: 8px;
  }
}
</style>
