<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  data() {
    return {
      unseenIssueReport: 0,
    }
  },
  mounted() {
    const updateReportCount = () => {
      const errorReportIndex = this.items.findIndex(item => item.route === 'super-error-reports')
      if (this.unseenIssueReport > 0) {
        this.items[errorReportIndex] = {
          ...this.items[errorReportIndex],
          tag: this.unseenIssueReport,
          tagVariant: 'light-danger',
        }
        this.$forceUpdate()
      } else {
        this.items[errorReportIndex] = {
          ...this.items[errorReportIndex],
          tag: null,
          tagVariant: null,
        }
      }
      this.$forceUpdate()
    }

    const checkUnseenIssue = () => {
      useJwt.getUnseenIssue().then(response => {
        const { unseen } = response.data.data
        this.unseenIssueReport = unseen
        updateReportCount()
      }).finally(() => {
        setTimeout(() => {
          checkUnseenIssue()
        }, 300000)
      })
    }
    if (this.AUTH_USER() && this.AUTH_USER().usertype === 'super') {
      checkUnseenIssue()
      this.$root.$off('reduceSeenIssueReportCount')
      this.$root.$on('reduceSeenIssueReportCount', () => {
        this.unseenIssueReport -= 1
        updateReportCount()
      })
    }
  },
}
</script>
