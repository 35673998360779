<template>
  <div>
    <b-row>
      <b-col
        md="4"
        class="d-flex"
      >
        <label class="mr-1"
               style="font-size:15px;margin-top:10px;"
        >{{ $t('animateditem-module.filter') }}:</label>
        <b-form-select
          id="character"
          v-model="character"
          :options="characterList"
          name="character"
          @change="getAnimatedItems"
        />
      </b-col>
      <b-col
        md="8"
      >
        <div class="text-right pb-2">
          <b-button
            variant="outline-primary"
            @click="showAnimatedItemCreate = true"
          >
            <feather-icon icon="PlusIcon" /> {{ $t('animateditem-module.add-animated-item') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <animated-item-update
      :show="showAnimatedItemCreate || !!animatedItemToEdit"
      :animated-item="animatedItemToEdit"
      @onUpdate="getAnimatedItems()"
      @close="showAnimatedItemCreate = false;animatedItemToEdit = null"
    />
    <b-overlay
      :show="isProcessing"
    >

      <b-card no-body>
        <b-table
          :fields="columns"
          :items="rows"
          striped
          show-empty
          :empty-text="$t('messages.no-data-available')"
        >
          <template #cell(image)="data">

            <img
              :src="data.item.image"
              style="height:80px; width:80px;"
            >
          </template>

          <template #cell(wear_type)="data">

            <b-badge
              class="ml-1"
              variant="primary"
            >
              {{ $t('animateditem-module.wearable.'+data.item.wear_type) }}
            </b-badge>

          </template>

          <template #cell(animal_type)="data">

            <b-badge v-for="animal,i in data.item.animal_type"
                     :key="i"
                     variant="primary"
                     :class="i>0 && 'ml-1'"
            >
              {{ getCharacterText(animal) }}
            </b-badge>

          </template>
          <template #cell(is_public)="data">
            <b-form-checkbox
              :checked="data.item.is_public"
              class="custom-control-primary pr-1"
              name="check-button"
              switch
              @change="togglePublic(data.item.id, data.item.is_public)"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>

          </template>

          <template #cell(action)="data">
            <b-button
              variant="flat-primary"
              class="btn-icon"
              @click="handleEdit(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              variant="flat-danger"
              class="btn-icon"
              @click="animatedItemToDelete = data.item.id"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
    <ConfirmDelete
      :show="!!animatedItemToDelete"
      :is-processing-delete="isDeleting"
      :on-delete="handleDelete"
      :title="$t('delete')"
      @close="animatedItemToDelete = null"
    />
  </div>
</template>
<script>
import {
  BButton, BTable, BCard, BOverlay, BBadge, BRow, BCol, BFormSelect, BFormCheckbox,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import ConfirmDelete from '@/views/common/components/DeleteModal.vue'
import { USER_TYPE_SUPER, USER_TYPE_SCHOOL } from '@/const/userType'
import { getUserData } from '@/auth/utils'
import AnimatedItemUpdate from './components/add-update.vue'

export default {
  components: {
    BButton,
    AnimatedItemUpdate,
    BTable,
    BCard,
    BBadge,
    BOverlay,
    // BBadge,
    ConfirmDelete,
    BRow,
    BCol,
    BFormSelect,
    BFormCheckbox,
  },
  data() {
    return {
      showAnimatedItemCreate: false,
      columns: [
        {
          key: 'name',
          label: i18n.tc('animateditem-module.name'),
        },
        {
          key: 'code_name',
          label: i18n.tc('animateditem-module.code_name'),
        },
        {
          key: 'price',
          label: i18n.tc('animateditem-module.price'),
        },
        {
          key: 'image',
          label: i18n.tc('animateditem-module.image'),
        },
        {
          key: 'wear_type',
          label: i18n.tc('animateditem-module.wear_type'),
        },
        {
          key: 'access_type',
          label: i18n.tc('animateditem-module.access_type'),
        },
        {
          key: 'animal_type',
          label: i18n.tc('animateditem-module.animal_type'),
        },
        {
          key: 'is_public',
          label: i18n.tc('animateditem-module.is-public'),
        },
        {
          key: 'action',
          label: i18n.tc('messages.tableHeader.action'),
        },
      ],

      rows: [],
      isProcessing: false,
      animatedItemToEdit: null,
      isDeleting: false,
      animatedItemToDelete: null,
      self: getUserData(),
      activeAnimatedItem: null,
      character: null,
      characterList: [
        { value: null, text: i18n.tc('animateditem-module.characters.select') },
        { value: 'all', text: i18n.tc('animateditem-module.characters.all') },
        { value: 'all-animals', text: i18n.tc('animateditem-module.characters.all-animals') },
        { value: 'all-humans', text: i18n.tc('animateditem-module.characters.all-humans') },
        { value: 'elephant', text: i18n.tc('animateditem-module.characters.elephant') },
        { value: 'lion', text: i18n.tc('animateditem-module.characters.lion') },
        { value: 'girrafe', text: i18n.tc('animateditem-module.characters.girrafe') },
        { value: 'tiger', text: i18n.tc('animateditem-module.characters.tiger') },
        { value: 'karen', text: i18n.tc('animateditem-module.characters.karen') },
        { value: 'bill', text: i18n.tc('animateditem-module.characters.bill') },
        { value: 'alice', text: i18n.tc('animateditem-module.characters.alice') },
        { value: 'julie', text: i18n.tc('animateditem-module.characters.julie') },
        { value: 'kevin', text: i18n.tc('animateditem-module.characters.kevin') },
        { value: 'mike', text: i18n.tc('animateditem-module.characters.mike') },
      ],
    }
  },
  computed: {
    isSuperUser() {
      return this.self.usertype === USER_TYPE_SUPER
    },
    isSchoolUser() {
      return this.self.usertype === USER_TYPE_SCHOOL
    },
  },
  created() {
    this.getAnimatedItems()
  },
  methods: {
    getAnimatedItems() {
      this.isProcessing = true
      useJwt.getAnimatedItems({
        params: {
          character: this.character,
        },
      }).then(response => {
        this.rows = response.data.data
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    handleEdit(animatedItem) {
      this.animatedItemToEdit = { ...animatedItem }
    },
    handleDelete() {
      this.isDeleting = true
      useJwt.deleteAnimatedItems(this.animatedItemToDelete).then(response => {
        this.getAnimatedItems()
        this.animatedItemToDelete = null
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isDeleting = false
      })
    },
    togglePublic(id, isPublic) {
      const args = {
        id, is_public: !isPublic ? 1 : 0, model: 'animated_items',
      }
      useJwt.changeVisibility(args).then(response => {
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        const index = this.rows.findIndex(row => row.id === id)
        this.rows[index].is_public = !isPublic
      })
    },
    getCharacterText(animal) {
      const character = this.characterList.find(char => char.value === animal)
      return character ? character.text : ''
    },
  },
}
</script>
