<template>
  <b-modal :visible="show"
           size="lg"
           title="Text to audio"
           centered
           @close="$emit('close'); textToBeConverted = ''"
           @hide="$emit('close'); textToBeConverted = ''"
  >
    <div v-for="(textToConverted, index) in textToBeConvertedSsml"
         :key="index"
    >
      <b-row>
        <b-col md="9">
          <b-form-group label="Text to convert ">
            <b-form-textarea v-model="textToConverted.text"
                             placeholder="Text to convert"
                             rows="5"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Engine">
            <div class="d-flex align-items-center">
              <select v-model="textToConverted.engine"
                      class="form-control"
              >
                <option v-for="engine of voiceWithEngine"
                        :key="engine.text"
                        :value="engine.text"
                >
                  {{ engine.text }}
                </option>

              </select>
            </div>
          </b-form-group>
          <b-form-group label="Voices">
            <div class="d-flex align-items-center">
              <select v-model="textToConverted.voice"
                      class="form-control"
              >
                <option v-for="item of (voiceWithEngine.find(i => i.text === textToConverted.engine)?.data || [])"
                        :key="item"
                        :value="item"
                >
                  {{ item }}
                </option>

              </select>
            </div>
            <div class="mt-1">
              <b-button
                v-show="index != 0"
                variant="outline-danger mr-1"
                size="sm"
                :disabled="isPolyProcessing"
                class="btn-icon rounded-circle"
                @click="removeText(index)"
              >
                <feather-icon size="14"
                              icon="MinusCircleIcon"
                />
              </b-button>
              <b-button variant="outline-primary"
                        size="sm"
                        class="btn-icon rounded-circle"
                        @click="previewPollyText(textToConverted)"
              >
                <feather-icon size="14"
                              icon="Volume2Icon"
                />
              </b-button>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div>
        <b-button
          variant="primary"
          class="btn-icon mr-2"
          @click="addMoreText"
        >
          <feather-icon icon="PlusCircleIcon" />
          Add More Text
        </b-button>
        <b-button variant="secondary"
                  @click="$emit('close')"
        >
          Cancel
        </b-button>
        <b-button variant="primary"
                  class="ml-2"
                  :disabled="(textToBeConvertedSsml[0].text =='') || isPolyProcessing"
                  @click="convertTextToPolySpeech"
        >
          <b-spinner v-if="isPolyProcessing"
                     small
          /> Convert
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal, BFormGroup, BSpinner, BFormTextarea, BCol, BRow, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import voiceWithEngine from '@/const/polly-voices'

const defaultEngine = 'neural'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormTextarea,
    BSpinner,
    BCol,
    BRow,
    BButton,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPolyProcessing: false,
      textToBeConverted: '',
      voiceWithEngine,
      textToBeConvertedSsml: [
        {
          text: '',
          engine: defaultEngine,
          voice: '',
        },
      ],
    }
  },
  methods: {
    previewPollyText({ text, voice, engine }) {
      this.isPolyProcessing = true
      useJwt.convertTextToBase64([
        {
          text,
          voice,
          engine,
        },
      ]).then(response => {
        const base64AudioString = response.data.audio_file
        // eslint-disable-next-line no-undef
        const blob = this.base64StringToBlobUrl(base64AudioString)
        const objectUrl = URL.createObjectURL(blob.audio)
        const audio = new Audio(objectUrl)
        // Play the audio
        audio.play()
      }).finally(() => {
        this.isPolyProcessing = false
      })
    },
    addMoreText() {
      const newTexttoConvert = {
        text: '',
        voice: '',
      }
      this.textToBeConvertedSsml.push(newTexttoConvert)
    },
    removeText(index) {
      this.textToBeConvertedSsml.splice(index, 1)
    },
    base64StringToBlobUrl(base64String) {
      // Convert the base64 string to a Uint8Array
      const arrayBuffer = Uint8Array.from(atob(base64String), c => c.charCodeAt(0))

      // Create a Blob from the Uint8Array
      const blob = new Blob([arrayBuffer], { type: 'audio/ogg' })

      // Create a blob URL
      const blobUrl = URL.createObjectURL(blob)

      return {
        audio: blob,
        url: blobUrl,
      }
    },
    convertTextToPolySpeech() {
      this.isPolyProcessing = true
      useJwt.convertTextToBase64(
        this.textToBeConvertedSsml,
      ).then(response => {
        this.$emit('input', this.base64StringToBlobUrl(`${response.data.audio_file}`))
        this.$emit('close')
      }).finally(() => {
        this.isPolyProcessing = false
      })
    },
  },
}
</script>
