export const CLASS_TYPE_ALL = 'all'
export const CLASS_TYPE_DEFAULT = 'default'
export const CLASS_TYPE_CUSTOM = 'custom'
export const CLASS_TYPE_ARCHIVED = 'archived'

export const CLASS_TYPES = [
  CLASS_TYPE_ALL,
  CLASS_TYPE_DEFAULT,
  CLASS_TYPE_CUSTOM,
  CLASS_TYPE_ARCHIVED,
]
