<template>
  <div>
    <b-col md="12">
      <validation-observer ref="createProblemForm">
        <b-col
          md="12"
          class="px-0"
        >
          <div>
            <b-tabs
              pills
              align="center"
              class="lang-level-tabs"
            >
              <b-tab
                v-for="(level, index) in problem.levels"
                :key="index + level"
                :title="getLangLevelTransText(level)"
                :title-item-class="showLangLevel ? '' : 'd-none'"
                :active="activeTabIndex === index"
                @click="activeTabIndex = index"
              >
                <b-button
                  variant="success"
                  :disabled="isProcessing"
                  @click="showCopyProblemModal = true"
                >
                  {{ $t("labels.copy-problem") }}
                </b-button>
                <b-row class="mt-3">
                  <b-col
                    md="12"
                    lg="4"
                    class="card p-2"
                  >
                    <!-- Name -->
                    <b-form-group
                      :label="$t('labels.create-problem.problem-name')"
                      label-for="name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="name"
                        rules="required|max:256"
                        vid="name"
                      >
                        <b-form-input
                          :id="`name-${index}`"
                          v-model="problem.name"
                          :placeholder="$t('labels.name')"
                          name="name"
                          @keyup.enter="!errors.length ? updateProblem() : null"
                          @blur="!errors.length ? updateProblem() : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Name -->
                    <b-form-group
                      :label="$t('labels.create-problem.tts-support')"
                      label-for="Domain"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Domain"
                        rules="required"
                      >
                        <b-form-select
                          id="domain"
                          v-model="problem.ttsSupport"
                          :options="[
                            { text: 'Yes', value: true },
                            { text: 'No', value: false },
                          ]"
                          name="tts_support"
                          :state="errors.length > 0 ? false : null"
                          @change="updateProblem()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Domain -->
                    <b-form-group
                      :label="$t('labels.create-problem.domain')"
                      label-for="Domain"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Domain"
                        rules="required"
                      >
                        <b-form-select
                          id="domain"
                          v-model="selectedDomain"
                          :options="domains"
                          name="standard"
                          :state="errors.length > 0 ? false : null"
                          @change="fetchStandard"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Domain -->

                    <!-- Standard -->
                    <b-form-group
                      :label="$t('labels.create-problem.standard')"
                      label-for="standard"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="standard"
                        vid="standard"
                      >
                        <b-form-select
                          id="standard"
                          v-model="problem.standard_id"
                          :options="standards"
                          :disabled="selectedDomain ? false : true"
                          name="standard"
                          @change="updateProblem"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Standard -->

                    <!-- Tags -->
                    <b-form-group
                      :label="$t('labels.create-problem.tags')"
                      label-for="tag"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="tag"
                        rules="required"
                      >
                        <v-select
                          v-model="problem.tags"
                          :class="!!errors[0] ? 'v-select-error' : ''"
                          multiple
                          label="text"
                          :options="tags"
                          placeholder="Please select a Tag"
                          :state="errors.length > 0 ? false : null"
                          @input="updateProblem"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- /Tags -->

                    <!-- Skill Type -->
                    <b-form-group
                      :label="$t('labels.create-problem.skill-type')"
                      label-for="skill-type"
                      rules="required"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="skill-type"
                        vid="skill-type"
                      >
                        <b-form-select
                          id="skill-type"
                          v-model="problem.skill_type"
                          :options="skillTypes"
                          name="skill-type"
                          @change="updateProblem"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- /Skill Type -->

                    <!-- Theme -->
                    <b-form-group
                      :label="$t('labels.create-problem.theme')"
                      label-for="theme"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="theme"
                        vid="theme"
                      >
                        <b-form-select
                          id="theme"
                          v-model="problem.theme"
                          :options="themes"
                          name="theme"
                          @change="updateTheme"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <theme-preview :theme="problem.theme" />
                    <!-- Theme -->

                    <video-type
                      v-if="videoTimeStamp"
                      :video-time-stamp="videoTimeStamp"
                      @updated="$emit('fetch')"
                    />

                    <!-- Statement & Feedback -->
                    <div>
                      <b-form-group label="Hide Statement">
                        <b-form-checkbox v-model="problem.hideStatement"
                                         switch
                                         @change="updateProblem()"
                        />
                      </b-form-group>
                      <div v-if="!hotspotWrapper">
                        <div v-if="!videoTimeStamp">
                          <label>{{
                            $t("labels.create-problem.problem-statement")
                          }}</label>
                          <b-tabs
                            pills
                            class="mx-0 px-0 mt-1"
                          >
                            <b-tab
                              title="Image"
                              :active="problem.stType[index] === 'image'"
                              @click="problem.stType[index] = 'image'"
                            >
                              <b-img
                                v-if="problem.statementImage.url[index]"
                                rounded
                                fluid
                                :src="problem.statementImage.url[index]"
                                height="150"
                                width="150"
                                class="mt-2 mr-2"
                              />
                              <div class="media-body">
                                <b-form-group
                                  :label="
                                    $t('labels.create-problem.statement-image')
                                  "
                                  :label-for="`statement-matching-${level}`"
                                >
                                  <b-form-file
                                    :id="`statement-matching-${level}`"
                                    :placeholder="$t('labels.file-placeholder')"
                                    :browse-text="$t('browseButton')"
                                    accept="image/jpeg, image/png, image/gif"
                                    @change="
                                      handleStatementImage(
                                        $event,
                                        'statementImage',
                                        index
                                      )
                                    "
                                  />
                                </b-form-group>
                              </div>
                            </b-tab>
                            <b-tab
                              title="Audio"
                              :active="problem.stType[index] === 'audio'"
                              @click="problem.stType[index] = 'audio'"
                            >
                              <b-form-group
                                :label="
                                  $t('labels.create-problem.statement-audio')
                                "
                                label-for="audio"
                              >
                                <Recorder
                                  :url="problem.statementAudio.url[index]"
                                  @onSelectedFromLibrary="value => updateProblemStatementAudio(value, index, 'st_image' )"
                                  @input="
                                    setStatementAudio(
                                      $event,
                                      index,
                                      'statementAudio'
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-tab>
                          </b-tabs>
                          <b-form-group
                            :label="$t('labels.create-problem.statement')"
                            label-for="statement"
                          >
                            <div
                              v-if="
                                !problem.statement[index].statementTemplateId
                              "
                            >
                              <quill-editor-custom
                                v-model="problem.statement[index].statement"
                                :placeholder="
                                  $t(
                                    'labels.create-problem.placeholder.statement'
                                  )
                                "
                                @blur="updateProblemStatement(index)"
                              />
                            </div>
                            <b-button
                              v-else
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="outline-primary"
                              block
                              :disabled="
                                templateBeingFetched ===
                                  problem.statement[index].statementTemplateId
                              "
                              @click="
                                () => {
                                  templateType = 'statement';
                                  fetchTemplate(
                                    problem.statement[index].statementTemplateId
                                  );
                                }
                              "
                            >
                              {{ "Update Template" }}
                            </b-button>
                          </b-form-group>
                          <!-- <word-net-section
                            :type="'course'"
                            :problem-statement-id="problem.statement ? problem.statement[index].id : null"
                            :main-text="problem.statement[index].statement +' '+ problem.statement[index].feedback"
                          /> -->
                        </div>
                        <!-- /Statement -->

                        <!-- Feedback -->
                        <label>{{
                          $t("labels.create-problem.problem-feedback")
                        }}</label>
                        <b-form-group label="Hide FeedBack">
                          <b-form-checkbox v-model="problem.hideFeedBack"
                                           switch
                                           @change="updateProblem()"
                          />
                        </b-form-group>
                        <b-tabs
                          pills
                          class="mx-0 px-0 mt-1"
                        >
                          <b-tab
                            title="Image"
                            :active="problem.fbType[index] === 'image'"
                            @click="problem.fbType[index] = 'image'"
                          >
                            <b-img
                              v-if="problem.feedbackImage.url[index]"
                              rounded
                              fluid
                              :src="problem.feedbackImage.url[index]"
                              height="150"
                              width="150"
                              class="mt-2 mr-2"
                            />
                            <div class="media-body">
                              <b-form-group
                                :label="
                                  $t('labels.create-problem.feedback-image')
                                "
                                :label-for="`feedback-matching${level}`"
                              >
                                <b-form-file
                                  :id="`feedback-matching${level}`"
                                  :placeholder="$t('labels.file-placeholder')"
                                  :browse-text="$t('browseButton')"
                                  accept="image/jpeg, image/png, image/gif"
                                  @change="
                                    handleStatementImage(
                                      $event,
                                      'feedbackImage',
                                      index
                                    )
                                  "
                                />
                              </b-form-group>
                            </div>
                          </b-tab>
                          <b-tab
                            title="Audio"
                            :active="problem.fbType[index] === 'audio'"
                            @click="problem.fbType[index] = 'audio'"
                          >
                            <b-form-group
                              :label="
                                $t('labels.create-problem.feedback-audio')
                              "
                              label-for="audio"
                            >
                              <Recorder
                                :url="problem.feedbackAudio.url[index]"
                                @onSelectedFromLibrary="value => updateProblemStatementAudio(value, index, 'fb_image' )"
                                @input="
                                  setStatementAudio(
                                    $event,
                                    index,
                                    'feedbackAudio'
                                  )
                                "
                              />
                            </b-form-group>
                          </b-tab>
                        </b-tabs>
                        <b-form-group
                          :label="$t('labels.create-problem.feedback')"
                          label-for="feedback"
                        >
                          <div
                            v-if="!problem.statement[index].feedbackTemplateId"
                          >
                            <quill-editor-custom
                              v-model="problem.statement[index].feedback"
                              :placeholder="
                                $t('labels.create-problem.placeholder.feedback')
                              "
                              @blur="updateProblemStatement(index)"
                            />
                          </div>
                          <b-button
                            v-else
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            block
                            :disabled="
                              templateBeingFetched ===
                                problem.statement[index].feedbackTemplateId
                            "
                            @click="
                              () => {
                                templateType = 'feedback';
                                fetchTemplate(
                                  problem.statement[index].feedbackTemplateId
                                );
                              }
                            "
                          >
                            {{ "Update Template" }}
                          </b-button>
                        </b-form-group>
                        <!-- Feedback -->
                      </div>
                      <video-type
                        v-if="videoTimeStamp"
                        :video-time-stamp="videoTimeStamp"
                        @updated="$emit('fetch')"
                      />
                    </div>

                    <!-- Statement & Feedback -->
                    <div v-if="hotspotWrapper">
                      <b-form-group
                        :label="$t('labels.create-problem.statement')"
                        label-for="statement"
                      >

                        <b-form-input
                          id="statement"
                          v-model="problem.statement[index].statement"
                          placeholder="Statement"
                          @blur="updateProblemStatement(index)"
                          @keyup.enter="updateProblemStatement(index)"
                        />

                      </b-form-group>
                      <b-form-group
                        :label="$t('labels.create-problem.statement-audio')"
                        label-for="audio"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :vid="`statement-audio-${level}`"
                        >
                          <Recorder
                            :url="problem.statementAudio.url[index]"
                            @input="setStatementAudio($event, index, 'statementAudio')"
                            @fileTypeInvalid="setError([`statement-audio-${level}`], $event)"
                            @fileSizeInvalid="setError([`statement-audio-${level}`], $event)"
                          />
                          <input
                            v-model="problem.statementAudio.url[index]"
                            hidden
                          >
                          <small class="text-danger">{{ errors[0] }}</small><br>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        :label="$t('labels.create-problem.feedback')"
                        label-for="feedback"
                      >
                        <b-form-input
                          id="feedback"
                          v-model="problem.statement[index].feedback"
                          placeholder="Feedback"
                          @blur="updateProblemStatement(index)"
                          @keyup.enter="updateProblemStatement(index)"
                        />
                      </b-form-group>
                      <b-form-group
                        :label="$t('labels.create-problem.feedback-audio')"
                        label-for="audio"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :vid="`feedback-audio-${level}`"
                        >
                          <Recorder
                            :url="problem.feedbackAudio.url[index]"
                            @input="setStatementAudio($event, index, 'feedbackAudio')"
                            @fileTypeInvalid="setError([`feedback-audio-${level}`], $event)"
                            @fileSizeInvalid="setError([`feedback-audio-${level}`], $event)"
                          />
                          <input
                            v-model="problem.feedbackAudio.url[index]"
                            hidden
                          >
                          <small class="text-danger">{{ errors[0] }}</small><br>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <!-- Statement & Feedback -->
                  </b-col>
                  <b-col
                    md="12"
                    lg="8"
                    class="pl-md-0 pl-lg-1 pr-0"
                  >
                    <b-card>
                      <!-- CEFR Subject -->
                      <b-form-group
                        :label="$t('labels.create-problem.cefr-subject')"
                        label-for="cerfSubject"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="cerfSubject"
                          vid="cerfSubject"
                        >
                          <b-form-select
                            id="cerfSubject"
                            v-model="problem.cerf.selectedSubjects[index]"
                            :options="subjectList"
                            name="cerfSubject"
                            @change="fetchCerfDomain(index, true)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- CEFR Subject -->
                      <!-- CEFR Domain -->
                      <b-form-group
                        :label="$t('labels.create-problem.cefr-domain')"
                        label-for="cerfDomain"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="cerfDomain"
                          vid="cerfDomain"
                        >
                          <b-form-select
                            id="cerfDomain"
                            v-model="problem.cerf.selectedDomains[index]"
                            :options="cerfDomainList[index]"
                            :disabled="
                              problem.cerf.selectedSubjects[index]
                                ? false
                                : true
                            "
                            name="cerfDomain"
                            @change="fetchCerfStandard(index, true)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- CEFR Domain -->
                      <!-- CEFR Standard -->
                      <b-form-group
                        :label="$t('labels.create-problem.cefr-standard')"
                        label-for="cerfStandard"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="cerfStandard"
                          vid="cerfStandard"
                        >
                          <b-form-select
                            id="cerfStandard"
                            v-model="problem.cerf.selectedStandards[index]"
                            :options="cerfStandardList[index]"
                            :disabled="
                              problem.cerf.selectedDomains[index] ? false : true
                            "
                            name="cerfStandard"
                            @change="updateCerfStandard(index)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- CEFR Standard -->
                      <!-- problem descriptions -->
                      <b-form-group
                        :label="$t('labels.create-problem.problem-description')"
                        label-for="cefrStandard"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="problemDescription"
                          vid="problemDescription"
                        >
                          <b-form-input
                            id="pageLink"
                            v-model="problem.description"
                            :placeholder="$t('labels.create-problem.problem-description')"
                            name="problemDescription"
                            @blur="updateProblem"
                            @keyup.enter="updateProblem"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- Voice -->
                      <b-form-group
                        :label="$t('labels.create-problem.voice')"
                        label-for="voice"
                      >
                        <b-form-select
                          id="domain"
                          v-model="selectedVoice"
                          :options="voices"
                          name="voice"
                        />
                      </b-form-group>
                      <!-- Voice -->

                      <!-- Voice -->
                      <hr class="mt-3">
                      <div
                        v-for="(question, j) in activeQuestions[index]"
                        :key="j"
                      >
                        <b-row>
                          <b-col md="11">
                            <!-- Question -->
                            <b-row>
                              <b-col md="5">
                                <b-form-group
                                  :label="$t('labels.create-problem.question')"
                                  label-for="question"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="question"
                                    rules="required"
                                    :vid="`question-text-${index + j}`"
                                  >
                                    <b-form-input
                                      :id="`question-${index}-blank`"
                                      v-model="
                                        problem.questions.text[
                                          index
                                        ][j].text
                                      "
                                      :placeholder="
                                        $t('labels.create-problem.question')
                                      "
                                      :state="errors.length > 0 ? false : null"
                                      name="question"
                                      @blur="updateQuestion(index, j, 'text')"
                                      @keyup.enter="updateQuestion(index, j, 'text')"
                                    />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <image-upload
                                  title="Image"
                                  :is-required="false"
                                  style="height: 180px"
                                  :url="
                                    problem.questions['imageUrl'][index][j].text
                                  "
                                  @onSelectedFromLibrary="
                                    (value) => {
                                      problem.questions['image'][index][
                                        j
                                      ].text = value;
                                      problem.questions['imageUrl'][index][
                                        j
                                      ].text = url;
                                      updateQuestion(index, j, 'image');
                                    }
                                  "
                                  @imageRemoved="
                                    () => {
                                      problem.questions['imageUrl'][index][
                                        j
                                      ].text = null;
                                      problem.questions['image'][index][
                                        j
                                      ].text = null;
                                      updateQuestion(index, j, 'image');
                                    }
                                  "
                                  @imageUploaded="
                                    (file, url) => {
                                      problem.questions['image'][index][
                                        j
                                      ].text = file;
                                      problem.questions['imageUrl'][index][
                                        j
                                      ].text = url;
                                      updateQuestion(index, j, 'image');
                                    }
                                  "
                                />
                              </b-col>
                              <b-col
                                class="mt-2"
                                md="3"
                              >
                                <b-button
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  variant="outline-primary"
                                  class="btn-icon mr-1"
                                  @click="tts(index, j, 'questions')"
                                >
                                  <feather-icon icon="PlayIcon" />
                                </b-button>
                                <Recorder
                                  :url="problem.questions['extraFile'][index][j].text"
                                  @input="setRecordedQuestionAudio($event, index, j)"
                                  @onSelectedFromLibrary="value => setRecordedQuestionAudio({
                                    audio: value,
                                  }, index, j)"
                                  @fileTypeInvalid="setError([`feedback-audio-${level}`], $event)"
                                  @fileSizeInvalid="setError([`feedback-audio-${level}`], $event)"
                                />
                              </b-col>
                            </b-row>
                            <!-- Question -->
                            <!-- Answer -->
                            <b-row v-for="pa,si of problem.answers['text'][index][j]"
                                   :key="si"
                                   class="pt-1"
                            >
                              <b-col md="5">
                                <b-form-group
                                  :label="$t('labels.create-problem.answer')"
                                  label-for="answer"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="answer"
                                    rules="required"
                                    :vid="`answer-text-${index + j}`"
                                  >
                                    <b-form-input
                                      :id="`answer-${index}-blank`"
                                      v-model="
                                        problem.answers['text'][
                                          index
                                        ][j][si].text
                                      "
                                      :placeholder="
                                        $t('labels.create-problem.answer')
                                      "
                                      :state="errors.length > 0 ? false : null"
                                      name="answer"
                                      @blur="updateAnswer(index, j, si, 'text')"
                                      @keyup.enter="updateAnswer(index, j, si, 'text')"
                                    />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-form-group>
                                <div>
                                  <b-button
                                    variant="outline-danger"
                                    class="btn-icon rounded-circle"
                                    @click="removeMoreAnswer(index, j, si)"
                                  >
                                    <feather-icon icon="TrashIcon" />
                                  </b-button>
                                </div>
                              </b-col>
                              <b-col md="4">
                                <image-upload
                                  title="Image"
                                  :is-required="false"
                                  style="height: 180px"
                                  :url="
                                    problem.answers['imageUrl'][index][j][si].text
                                  "
                                  @onSelectedFromLibrary="
                                    (value) => {
                                      problem.answers['image'][index][j][si].text =
                                        value;
                                      problem.answers['imageUrl'][index][
                                        j
                                      ][si].text = url;
                                      updateAnswer(index, j, si, 'image');
                                    }
                                  "
                                  @imageRemoved="
                                    () => {
                                      problem.answers['imageUrl'][index][
                                        j
                                      ][si].text = null;
                                      problem.answers['image'][index][j][si].text =
                                        null;
                                      updateAnswer(index, j, si, 'image');
                                    }
                                  "
                                  @imageUploaded="
                                    (file, url) => {
                                      problem.answers['image'][index][j][si].text =
                                        file;
                                      problem.answers['imageUrl'][index][
                                        j
                                      ][si].text = url;
                                      updateAnswer(index, j,si, 'image');
                                    }
                                  "
                                />
                              </b-col>
                              <b-col
                                class="mt-2"
                                md="3"
                              >
                                <b-button
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  variant="outline-primary"
                                  class="btn-icon mr-1 mb-2"
                                  @click="tts(index, j, 'answers')"
                                >
                                  <feather-icon icon="PlayIcon" />
                                </b-button>
                                <Recorder
                                  :url="problem.answers['extraFile'][index][j][si].text"
                                  @input="setRecordedAnswerAudio($event, index, j, si)"
                                  @onSelectedFromLibrary="value => setRecordedAnswerAudio({
                                    audio: value,
                                  },index,j, si)"
                                  @fileTypeInvalid="setError([`feedback-audio-${level}`], $event)"
                                  @fileSizeInvalid="setError([`feedback-audio-${level}`], $event)"
                                />
                              </b-col>
                            </b-row>
                            <!-- Answer -->
                            <b-button variant="outline-primary"
                                      class="btn-icon"
                                      @click="addMoreAnswer(index, j, problem.questions['text'][index][j].id)"
                            >
                              <feather-icon icon="PlusIcon" /> Add Answer
                            </b-button>
                          </b-col>
                          <b-col
                            md="1"
                            class="mt-4"
                          >
                            <b-button
                              v-if="j > 0"
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="outline-danger"
                              class="btn-icon mr-1"
                              @click="toggleDeleteModal(j)"
                            >
                              <feather-icon icon="TrashIcon" />
                              {{ $t("Delete") }}
                            </b-button>
                          </b-col>
                        </b-row>
                        <hr>
                      </div>

                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        class="btn-icon"
                        @click="modal.show = true"
                      >
                        <feather-icon icon="PlusIcon" />
                        {{ $t("Add-New") }}
                      </b-button>
                      <!-- /Question / Answer -->
                    </b-card>
                    <b-card>
                      <b-col class="d-flex justify-content-between">
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-success"
                          class="mr-2"
                          @click="
                            $emit(
                              'showQuestionPreview',
                              getDataForQuestionPreview()
                            )
                          "
                        >
                          {{ $t("actions.preview") }}
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          @click="
                            $router.push({
                              path: `/${AUTH_USER().usertype}/problem`,
                              query: { lesson_id: $route.query.lesson_id },
                            })
                          "
                        >
                          {{ $t("actions.back") }}
                        </b-button>
                      </b-col>
                    </b-card>
                  </b-col>
                </b-row>
              </b-tab>
              <b-col
                md="12"
                lg="4"
                class="p-0 m-0"
              >
                <hot-spot
                  v-if="hotspotWrapper"
                  :hotspot-point="problemData.hotspot_point"
                  @pointUpdated="$emit('fetch')"
                />
              </b-col>
            </b-tabs>
          </div>
        </b-col>
      </validation-observer>
    </b-col>

    <!-- add item -->
    <add-modal
      :toggle="modal.show"
      :answer-type="problem.answerType"
      :question-type="problem.questionType"
      @close="modal.show = false"
      @fetch="$emit('fetch')"
    />
    <!-- add item -->

    <!-- delete item -->
    <b-modal
      id="modal-delete"
      ref="modal-delete"
      ok-only
      ok-variant="danger"
      :ok-title="$t('confirm')"
      modal-class="modal-danger"
      centered
      :title="$t('actions.delete-question')"
      @ok="deleteQuestion()"
    >
      <b-card-text>
        {{ $t("questions.delete-question") }}
      </b-card-text>
      <template #modal-footer="{ ok }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              :disabled="isProcessing"
              @click="ok()"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t("confirm") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- delete item -->

    <!-- create edit template -->
    <create-template
      :show="visibleTemplate != null"
      :template="problemTemplates[visibleTemplate]"
      :title="`Update ${templateType} Template`"
      @templateStored="
        (data) => {
          problemTemplates[visibleTemplate] = data;
          visibleTemplate = null;
        }
      "
      @close="visibleTemplate = null"
    />
    <!-- create edit template -->

    <copy-to-lesson-modal
      :problem-id="problemData.id"
      :show="showCopyProblemModal"
      @close="showCopyProblemModal = false"
    />
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BButton,
  BSpinner,
  BTab,
  BTabs,
  BCardText,
  BImg,
  BFormFile,
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ThemePreview from '@views/common/components/ThemePreview.vue'
import CreateTemplate from '@views/common/templates/CreateEdit.vue'
import QuillEditorCustom from '@views/common/components/QuillEditorCustom.vue'
import vSelect from 'vue-select'
import ImageUpload from '@views/common/components/CustomImageUpload.vue'
import SkillTypes from '@/const/skillTypes'
import VideoType from '../../components/edit/VideoType.vue'
import HotSpot from '../../components/edit/HotSpot.vue'
import Recorder from '../../components/recorder.vue'
import AddModal from './AddModal.vue'
// import request from '../request'
import CopyToLessonModal from '../components/CopyToLessonModal.vue'

export default {
  components: {
    BCol,
    BRow,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    BTab,
    BTabs,
    BCardText,
    BImg,
    BFormFile,
    BFormCheckbox,
    Recorder,
    AddModal,
    BCard,
    QuillEditorCustom,
    ThemePreview,
    VideoType,
    HotSpot,
    CreateTemplate,
    // WordNetSection,
    CopyToLessonModal,
    vSelect,
    ImageUpload,
  },
  directives: {
    Ripple,
  },
  props: {
    problemData: {
      type: Object,
      default: () => { },
    },
    domains: {
      type: Array,
      default: () => [{ value: null, text: 'Please select a domain' }],
    },
    subjectList: {
      type: Array,
      default: () => [{ value: null, text: 'Please select a subject' }],
    },
    tags: {
      type: Array,
      default: () => { },
    },
  },
  data: () => ({
    isProcessing: false,
    showCopyProblemModal: false,
    activeTabIndex: 3,
    selectedVoice: null,
    voices: [],
    modal: {
      show: false,
    },
    problem: {
      questionLength: 1,
      questionOptions: [
        { value: 'text', text: 'Text', disabled: false },
        { value: 'image', text: 'Image', disabled: false },
        { value: 'audio', text: 'Audio', disabled: false },
      ],
      answerOptions: [
        { value: 'text', text: 'Text', disabled: false },
        { value: 'image', text: 'Image', disabled: false },
        { value: 'audio', text: 'Audio', disabled: false },
      ],
      questionType: 'text',
      answerType: 'text',
      allLevels: ['A1', 'A2', 'B1', 'B2'],
      levels: [],
      questions: {
        text: [[], [], [], []],
      },
      answers: {
        text: [[], [], [], []],
      },
      standard_id: null,
      name: '',
      ttsSupport: false,
      statement: [{}, {}, {}, {}],
      statementImage: { url: ['', '', '', ''], file: ['', '', '', ''] },
      feedbackImage: { url: ['', '', '', ''], file: ['', '', '', ''] },
      stType: [],
      fbType: [],
      statementAudio: { url: ['', '', '', ''], file: ['', '', '', ''] },
      feedbackAudio: { url: ['', '', '', ''], file: ['', '', '', ''] },
      theme: null,
      cerf: {
        selectedSubjects: [null, null, null, null],
        selectedDomains: [null, null, null, null],
        selectedStandards: [null, null, null, null],
      },
      skill_type: '',
    },
    isValid: false,
    changed: {
      name: false,
      questions: false,
      statement: false,
      feedback: false,
      standard_id: false,
    },
    deleteIndex: null,
    selectedDomain: null,
    standards: [{ value: null, text: 'Please select a standard' }],
    themes: [
      { value: 0, text: 'Default' },
      { value: 1, text: 'Theme-1' },
      { value: 2, text: 'Theme-2' },
      { value: 3, text: 'Theme-3' },
      { value: 4, text: 'Theme-4' },
      { value: 5, text: 'Theme-5' },
      { value: 6, text: 'Theme-6' },
      { value: 7, text: 'Theme-7' },
      { value: 8, text: 'Theme-8' },
      { value: 9, text: 'Theme-9' },
    ],
    cerfDomainList: [
      [{ value: null, text: 'Please select a domain' }],
      [{ value: null, text: 'Please select a domain' }],
      [{ value: null, text: 'Please select a domain' }],
      [{ value: null, text: 'Please select a domain' }],
    ],
    cerfStandardList: [
      [{ value: null, text: 'Please select a standard' }],
      [{ value: null, text: 'Please select a standard' }],
      [{ value: null, text: 'Please select a standard' }],
      [{ value: null, text: 'Please select a standard' }],
    ],
    // extra data
    visibleTemplate: null,
    templateBeingFetched: null,
    problemTemplates: {},
    templateType: '',
    videoTimeStamp: null,
    hotspotWrapper: false,
    skillTypes: SkillTypes,
  }),
  computed: {
    activeQuestions: {
      get() {
        return this.problem.questions.text
      },
    },
    showLangLevel() {
      let status = false
      // if (this.problemData.questions.length > 1) {
      //   status = true
      // }
      // eslint-disable-next-line eqeqeq
      if (this.problemData?.questions?.find(q => q.lang_level != '1')) {
        status = true
      }
      return status
    },
  },
  watch: {
    problemData() {
      this.setPage()
    },
    activeTabIndex() {
      if (this.problem.statement[this.activeTabIndex].statementTemplateId) this.fetchTemplate(this.problem.statement[this.activeTabIndex].statementTemplateId, false)
      if (this.problem.statement[this.activeTabIndex].feedbackTemplateId) this.fetchTemplate(this.problem.statement[this.activeTabIndex].feedbackTemplateId, false)
    },
  },
  created() {
    this.setPage()
    if (this.$route.query.lang_level) {
      this.activeTabIndex = Number(this.$route.query.lang_level) - 1
    }
    this.synth = window.speechSynthesis
    this.getVoices().then(voices => {
      this.voices = [voices.map((v, i) => ({
        text: v.name, value: i, voice: v, lang: v.lang,
      })).find(v => (v.lang === 'en-US'))]
    })
    this.selectedVoice = JSON.parse(JSON.stringify(this.voices))[0]?.value
    this.fetchLessons()
    // if (this.problemData?.questions && this.problemData?.questions.length === 1) this.activeTabIndex = 0
  },
  methods: {
    removeMoreAnswer(index, j, k) {
      const answer = this.problem.answers.text[index][j][k]
      console.log(answer)
      useJwt.deleteAnswer(answer.id).then(() => {
        this.$emit('fetch')
      })
      this.$forceUpdate()
    },
    addMoreAnswer(index, j, questionId) {
      this.problem.answers.text[index][j].push({
        id: null,
        text: '',
        questionId,
      })
      this.problem.answers.imageUrl[index][j].push({
        id: null,
        text: '',
      })
      if (this.problem.answers.audio[index][j]) {
        this.problem.answers.audio[index][j].push({
          id: null,
          text: '',
        })
      }

      this.problem.answers.extraFile[index][j].push({
        id: null,
        text: '',
      })
      if (this.problem.answers.audioUrl[index][j]) {
        this.problem.answers.audioUrl[index][j].push({
          id: null,
          text: '',
        })
      }

      this.$forceUpdate()
    },
    setRecordedQuestionAudio(event, index, j) {
      this.problem.questions.extraFile[index][j].text = event.audio
      this.updateQuestion(index, j, 'extraFile')
    },
    setRecordedAnswerAudio(event, index, j, k) {
      this.problem.answers.extraFile[index][j][k].text = event.audio
      this.updateAnswer(index, j, k, 'extraFile')
    },
    setPage() {
      // set standard and problem name
      this.selectedDomain = this.problemData.problem_set.standard.domain.id
      this.problem.standard_id = this.problemData.problem_set.standard_id
      this.problem.tags = this.problemData.tags

      this.problem.name = this.problemData.name

      if (this.problemData.rules) {
        this.problem.hideStatement = this.JSON_PARSE(this.problemData.rules)?.hide_statement
        this.problem.hideFeedBack = this.JSON_PARSE(this.problemData.rules)?.hide_feedback
      }

      this.problem.ttsSupport = !!this.problemData.tts_support
      this.$store.commit('problem/SET_TTS_SUPPORT', !!this.problem.ttsSupport)
      this.standards = this.problemData.problem_set.standard.domain.standards.map(s => ({ value: s.id, text: s.name }))
      this.problem.theme = this.problemData.theme
      this.videoTimeStamp = this.problemData.video_time_stamp
      this.hotspotWrapper = !!this.problemData.hotspot_point
      this.problem.skill_type = this.problemData.skill_type

      // set statement and feedback
      this.problem.statement = [{}, {}, {}, {}]
      this.problemData.problem_statements.forEach(data => {
        this.problem.statement[data.lang_level - 1] = {
          statementTemplateId: data.st_template_id,
          feedbackTemplateId: data.fb_template_id,
          initialStatement: data.statement,
          initialFeedback: data.feedback,
          statement: data.statement,
          id: data.id,
          feedback: data.feedback,
          fb_type: data.fb_type,
          st_type: data.st_type,
        }

        if (data.standard) {
          this.problem.cerf.selectedSubjects[data.lang_level - 1] = data.standard.domain.subject_id
          this.fetchCerfDomain(data.lang_level - 1, false)
          this.problem.cerf.selectedDomains[data.lang_level - 1] = data.standard.domain.id
          this.fetchCerfStandard(data.lang_level - 1, false)
          this.problem.cerf.selectedStandards[data.lang_level - 1] = data.standard_id
        }

        this.problem.stType.push(data.st_type)
        this.problem.fbType.push(data.fb_type)
        if (data.st_type === 'image') this.problem.statementImage.url[data.lang_level - 1] = data.st_image
        if (data.st_type === 'audio') this.problem.statementAudio.url[data.lang_level - 1] = data.st_image
        if (data.fb_type === 'image') this.problem.feedbackImage.url[data.lang_level - 1] = data.fb_image
        if (data.fb_type === 'audio') this.problem.feedbackAudio.url[data.lang_level - 1] = data.fb_image
      })

      this.problem.questions = {
        text: [[], [], [], []],
        image: [[], [], [], []],
        imageUrl: [[], [], [], []],
        audio: [[], [], [], []],
        audioUrl: [[], [], [], []],
        extraFile: [[], [], [], []],
      }
      this.problem.answers = {
        text: [[], [], [], []],
        image: [[], [], [], []],
        imageUrl: [[], [], [], []],
        audio: [[], [], [], []],
        audioUrl: [[], [], [], []],
        extraFile: [[], [], [], []],
      }

      this.problem.levels = []

      // if linear problem type, set only one language level
      if (this.problemData?.questions?.find(q => q.lang_level !== '1') && !this.problemData?.isLinearLesson) {
        this.problem.levels = [...this.problem.allLevels]
      } else {
        const tabIndex = this.problemData.questions ? this.problemData.questions[0]?.lang_level - 1 : 0
        const newLevelArray = new Array(4).fill('')
        newLevelArray[tabIndex] = this.problem.allLevels[tabIndex]
        this.problem.levels = newLevelArray
        this.activeTabIndex = tabIndex
      }

      this.problemData.questions.forEach(data => {
        this.problem.questionType = data.data_type
        if (data.data_type === 'text' || data.data_type === 'image') this.problem.questions.text[data.lang_level - 1].push({ id: data.id, text: data.text })
        else if (data.data_type === 'audio') {
          this.problem.questions.audioUrl[data.lang_level - 1].push({ id: data.id, text: data.file })
          this.problem.questions.audio[data.lang_level - 1].push({ id: data.id, text: data.file })
        }
        this.problem.questions.imageUrl[data.lang_level - 1].push({ id: data.id, text: data.file })
        this.problem.questions.image[data.lang_level - 1].push({ id: data.id, text: data.file })

        this.problem.answerType = data.answers[0].data_type
        this.problem.answers.text[data.lang_level - 1].push(data.answers.map(i => ({ id: i.id, text: i.text })))
        if (data.answers[0].data_type === 'audio') {
          this.problem.answers.audioUrl[data.lang_level - 1].push(data.answers.map(i => ({ id: i.id, text: i.file })))
          this.problem.answers.audio[data.lang_level - 1].push(data.answers.map(i => ({ id: i.id, text: i.file })))
        }
        this.problem.answers.imageUrl[data.lang_level - 1].push(data.answers.map(i => ({ id: i.id, text: i.file })))
        this.problem.answers.image[data.lang_level - 1].push(data.answers.map(i => ({ id: i.id, text: i.file })))
        this.problem.answers.extraFile[data.lang_level - 1].push(data.answers.map(i => ({ id: i.id, text: i.extra_file })))
        this.problem.questions.extraFile[data.lang_level - 1].push({ id: data.id, text: data.extra_file })
      })

      if (this.problem.statement[this.activeTabIndex].statementTemplateId) this.fetchTemplate(this.problem.statement[this.activeTabIndex].statementTemplateId, false)
      if (this.problem.statement[this.activeTabIndex].feedbackTemplateId) this.fetchTemplate(this.problem.statement[this.activeTabIndex].feedbackTemplateId, false)

      this.$emit('ready')
    },
    fetchTemplate(id, viewTemplate = true) {
      return new Promise(resolve => {
        if (this.problemTemplates[id]) {
          if (viewTemplate) this.visibleTemplate = id
          return
        }

        this.templateBeingFetched = id
        useJwt.getTemplate(id).then(res => {
          this.problemTemplates[id] = res.data.data
          if (viewTemplate) this.visibleTemplate = id
          resolve('')
        }).finally(() => {
          this.templateBeingFetched = null
        })
      })
    },
    tts(i, j, type) {
      this.synth.cancel()
      const utterThis = new SpeechSynthesisUtterance(this.problem[type].text[i][j].text)
      if (this.voices[0].voice) utterThis.voice = this.voices[0].voice
      else utterThis.lang = 'en-US'
      utterThis.rate = 0.8
      this.synth.speak(utterThis)
    },
    updateTheme() {
      const config = {
        theme: `${this.problem.theme}`,
      }
      useJwt.updateTheme(this.$route.query.problem_id, config)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    updateProblem() {
      const config = {
        lesson_id: this.$route.query.lesson_id,
        name: this.problem.name,
        standard_id: this.problem.standard_id,
        tags: this.problem.tags,
        tts_support: this.problem.ttsSupport,
        skill_type: this.problem.skill_type,
        description: this.problem.description,
        rules: {
          hide_statement: this.problem.hideStatement,
          hide_feedback: this.problem.hideFeedBack,
        },
      }
      this.$store.commit('problem/SET_TTS_SUPPORT', !!this.problem.ttsSupport)
      useJwt.updateProblem(this.$route.query.problem_id, config)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    // handleImage(event, type, i, j) {
    //   const reader = new FileReader()
    //   reader.addEventListener(
    //     'load',
    //     () => {
    //       this.problem[type].imageUrl[i][j].text = reader.result
    //     },
    //     false,
    //   )
    //   if (event.target.files[0]) {
    //     const file = event.target.files[0]
    //     if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
    //       this.problem[type].image[i][j].text = file
    //       if (type === 'questions') this.updateQuestion(i, j)
    //       else if (type === 'answers') this.updateAnswer(i, j)
    //       reader.readAsDataURL(file)
    //     }
    //   }
    // },
    handleStatementImage(event, type, index) {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.problem[type].url[index] = reader.result
        },
        false,
      )
      if (event.target.files[0]) {
        const file = event.target.files[0]
        if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
          if (type === 'statementImage') this.updateProblemStatementImage(file, index, 'st_image')
          else if (type === 'feedbackImage') this.updateProblemStatementImage(file, index, 'fb_image')
          reader.readAsDataURL(file)
        }
      }
    },
    updateCerfStandard(index) {
      useJwt.updateCerfStandard(this.problem.statement[index].id, this.problem.cerf.selectedStandards[index])
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    updateProblemStatement(level) {
      const statement = this.problem.statement[level]
      if (statement.initialStatement !== statement.statement || statement.initialFeedback !== statement.feedback) {
        const config = {
          lang_level: level + 1,
          statement: statement.statement,
          feedback: statement.feedback,
          _method: 'put',
        }
        useJwt.updateProblemStatement(this.problem.statement[level].id, config)
          .then(response => {
            this.showSuccessMessage(response)
          })
          .catch(error => {
            this.showErrorMessage(error)
          })
          .finally(() => {
            this.$emit('fetch')
          })
      }
    },
    updateProblemStatementImage(file, level, type) {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      data.append('lang_level', level + 1)
      data.append('statement', this.problem.statement[level].statement)
      data.append('feedback', this.problem.statement[level].feedback)
      data.append([type], file)
      if (type === 'st_image') data.append('st_type', 'image')
      else if (type === 'fb_image') data.append('fb_type', 'image')
      data.append('_method', 'put')

      useJwt.updateProblemStatement(this.problem.statement[level].id, data, config)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    updateProblemStatementAudio(file, level, type) {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      data.append('lang_level', level + 1)
      data.append('statement', this.problem.statement[level].statement)
      data.append('feedback', this.problem.statement[level].feedback)
      data.append([type], file)
      if (type === 'st_image') data.append('st_type', 'audio')
      else if (type === 'fb_image') data.append('fb_type', 'audio')
      data.append('_method', 'put')

      useJwt.updateProblemStatement(this.problem.statement[level].id, data, config)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    updateQuestion(i, j, type = this.problem.questionType) {
      const question = this.problem.questions[type][i][j]
      const image = this.problem.questions.image[i][j].text
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      data.append('lang_level', `${i + 1}`)
      data.append('problem_id', this.$route.query.problem_id)
      data.append('ques_type', this.problem.questionType)
      if (type === 'image') {
        data.append('text', image || this.problem.questions.text[i][j].text)
        if (!image) {
          data.append('remove_img', true)
        }
        if (this.problem.questions.extraFile[i][j].text) {
          data.append('extra_file_blob', this.problem.questions.extraFile[i][j].text || '')
        }
      } else if (type === 'extraFile') {
        data.append('text', this.problem.questions.text[i][j].text)
        data.append('filename', this.problem.questions.image[i][j].text)
        data.append('extra_file_blob', this.problem.questions.extraFile[i][j].text || '')
      } else {
        data.append('extra_file_blob', this.problem.questions.extraFile[i][j].text || '')
        data.append('text', question.text)
      }
      data.append('_method', 'put')

      useJwt.updateQuestion(question.id, data, config)
        .then(response => {
          this.showSuccessMessage(response)
          this.$emit('fetch')
        })
        .catch(error => {
          // this.showErrorMessage(error)
          this.showError(error.response.data.errors.text[0])
        })
    },
    // k => subindex
    updateAnswer(i, j, k, type = this.problem.answerType) {
      const answer = this.problem.answers[type][i][j][k]
      console.log(answer)
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      if (answer.questionId) {
        data.append('question_id', answer.questionId)
      }
      data.append('lang_level', `${i + 1}`)
      data.append('problem_id', this.$route.query.problem_id)
      data.append('ans_type', type)
      if (type === 'image') {
        const image = this.problem.answers.image[i][j][k].text
        data.append('text', image)
        data.append('extra_file_blob', this.problem.answers.extraFile[i][j][k].text)
      } else if (type === 'extraFile') {
        data.append('text', this.problem.answers.text[i][j][k].text)
        data.append('filename', this.problem.answers.image[i][j][k].text)
        data.append('extra_file_blob', this.problem.answers.extraFile[i][j][k].text)
      } else {
        data.append('extra_file_blob', this.problem.answers.extraFile[i][j][k].text)
        data.append('text', answer.text)
      }
      data.append('is_correct', 1)
      data.append('_method', 'put')

      useJwt.updateAnswer(answer.id, data, config)
        .then(response => {
          this.showSuccessMessage(response)
          this.$emit('fetch')
        })
        .catch(error => {
          // this.showErrorMessage(error)
          this.showError(error.response.data.errors.text[0])
        })
    },
    setAudio(i, j, type, { audio, url }) {
      this.problem[type].audio[i][j].text = audio
      this.problem[type].audioUrl[i][j].text = url
      if (audio) {
        if (type === 'questions') this.updateQuestion(i, j)
        else if (type === 'answers') this.updateAnswer(i, j)
      }
    },
    setStatementAudio({ audio, url }, i, type) {
      this.problem[type].url[i] = url
      this.problem[type].file[i] = audio
      if (audio) {
        if (type === 'statementAudio') this.updateProblemStatementAudio(audio, i, 'st_image')
        else if (type === 'feedbackAudio') this.updateProblemStatementAudio(audio, i, 'fb_image')
      }
    },
    lengthChange(length) {
      this.problem.questionLength = length
    },
    addNewField() {
      this.problem.questions[this.problem.questionType].forEach(q => {
        q.push({ id: null, text: '' })
      })

      if (this.problem.answerType === 'text') {
        this.problem.answers.text.forEach(a => {
          a.push({ id: null, text: '' })
        })
      } else if (this.problem.answerType === 'image') {
        this.problem.answers.image.forEach(a => {
          a.push({ id: null, text: '' })
        })
        this.problem.answers.imageUrl.forEach(a => {
          a.push({ id: null, text: '' })
        })
      } else if (this.problem.answerType === 'audio') {
        this.problem.answers.audio.forEach(a => {
          a.push({ id: null, text: '' })
        })
        this.problem.answers.audioUrl.forEach(a => {
          a.push({ id: null, text: '' })
        })
      }
    },
    toggleDeleteModal(index) {
      this.$refs['modal-delete'].toggle()
      this.deleteIndex = index
    },
    toggleAddModal() {
      this.$refs['modal-add'].toggle()
    },
    deleteQuestion() {
      const requests = []
      this.problem.questions[this.problem.questionType].forEach(q => {
        if (q[this.deleteIndex]?.id) requests.push(useJwt.deleteQuestion(q[this.deleteIndex].id))
      })
      Promise.all(requests).then(response => {
        this.showSuccessMessage(response[0])
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.problem.questions[this.problem.questionType].forEach(q => {
          q.splice(this.deleteIndex, 1)
        })
        this.problem.answers[this.problem.answerType].forEach(a => {
          a.splice(this.deleteIndex, 1)
        })
        this.$emit('fetch')
      })
    },
    fetchStandard() {
      if (this.selectedDomain) {
        useJwt.getStandardsByDomain(this.selectedDomain).then(response => {
          this.standards = [{ value: null, text: 'Please select a standard' }, ...response.data.data.map(s => ({ value: s.id, text: s.name }))]
        })
      }
      this.problem.standard_id = null
    },
    fetchCerfDomain(index, setNull) {
      useJwt.getCefrDomain(this.problem.cerf.selectedSubjects[index]).then(response => {
        this.cerfDomainList[index] = [{ value: null, text: 'Please select a domain' }, ...response.data.data.map(s => ({ value: s.id, text: s.name }))]
        if (setNull) this.problem.cerf.selectedDomains[index] = null
        if (setNull) this.problem.cerf.selectedStandards[index] = null
        this.cerfDomainList.push('')
        this.cerfDomainList.pop()
      })
    },
    fetchCerfStandard(index, setNull) {
      useJwt.getCefrStandard(this.problem.cerf.selectedDomains[index]).then(response => {
        this.cerfStandardList[index] = [{ value: null, text: 'Please select a standard' }, ...response.data.data.map(s => ({ value: s.id, text: s.name }))]
        if (setNull) this.problem.cerf.selectedStandards[index] = null
        this.cerfStandardList.push('')
        this.cerfStandardList.pop()
      })
    },
    getDataForQuestionPreview() {
      const statementAndFeedback = this.problemData.problem_statements[this.activeTabIndex]
      const index = this.activeTabIndex
      return {
        theme: this.problem.theme,
        problemType: 'speech',
        hasAnimatedLayout: this.problemData.animated_layout,
        animatedTemplateId: this.problemData.animated_template_id,
        hideStatement: !!this.problem.hideStatement,
        hideFeedBack: !!this.problem.hideFeedBack,
        feedback: {
          type: statementAndFeedback.fb_type,
          text: statementAndFeedback.feedback,
          image: statementAndFeedback.fb_type === 'image' ? this.problem.feedbackImage?.url[index] : null,
          audio: statementAndFeedback.fb_type === 'audio' ? this.problem.feedbackAudio?.url[index] : null,
          template: this.problemTemplates[statementAndFeedback.fb_template_id],
        },
        statement: {
          type: statementAndFeedback.st_type,
          text: statementAndFeedback.statement,
          image: statementAndFeedback.st_type === 'image' ? this.problem.statementImage?.url[index] : null,
          audio: statementAndFeedback.st_type === 'audio' ? this.problem.statementAudio?.url[index] : null,
          template: this.problemTemplates[statementAndFeedback.st_template_id],
          video: this.videoTimeStamp,
        },
        // eslint-disable-next-line eqeqeq
        questions: this.problemData.questions.filter(e => e.lang_level == (this.activeTabIndex + 1).toString()),
        level: this.activeTabIndex + 1,
      }
    },
    getVoices() {
      return new Promise(resolve => {
        let id = null
        id = setInterval(() => {
          if (this.synth.getVoices().length !== 0) {
            resolve(this.synth.getVoices())
            clearInterval(id)
          }
        }, 10)
      })
    },
    copyLesson(lessonId) {
      this.$emit('copyProblem', lessonId)
    },
  },
}
</script>
