<template>
  <b-card
    v-if="data"
    no-body
    class="pb-0 mb-0"
  >
    <b-row>
      <b-col
        md="12"
        class="pt-0 pb-0 mb-0"
      >
        <div class="d-sm-flex p-1 justify-content-between align-items-center mb-1">
          <h5 class="pb-0 mb-0">
            {{ $t('student-report-module.student-efficiency') }}
          </h5>
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          type="bar"
          height="230"
          :options="pointsObtained.chartOptions"
          :series="data.pointsObtained.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueApexCharts,
    BCard,

    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        pointsObtained: {
          series: [
            {
              name: 'Points',
              data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
            },

          ],
        },
      },
      pointsObtained: {
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.primary],
          plotOptions: {
            bar: {
              columnWidth: '17%',
              endingShape: 'rounded',
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
          },
        },
      },
    }
  },
}
</script>
