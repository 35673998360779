<template>
  <div />
</template>

<script>

export default {
  mounted() {
    this.$router.push('/login')
    // axios.get('/edu/index.html').then(response => {
    //   document.open()
    //   document.write(response.data)
    //   document.body.style.display = 'none'
    //   setTimeout(() => {
    //     document.body.style.display = 'block'
    //   }, 100)
    //   document.close()
    // })
  },
  methods: {

  },
}
</script>
