<template>
  <div>
    <b-modal
      id="sidebar-add-new-event"
      ref="sidebar-add-new-event"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      hide-footer
      size="lg"
      :no-close-on-backdrop="true"
      :title="(eventLocal.id ? 'Update': 'Add')+' Event'"
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Body -->
        <validation-observer
          v-slot="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <validation-provider
              v-slot="validationContext"
              name="Message"
              rules="required"
            >
              <b-form-group
                label="Message"
                :state="getValidationState(validationContext)"
              >
                <b-form-textarea
                  id="event-lesson"
                  v-model="eventLocal.extendedProps.message"
                  rows="3"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Start Date -->
            <validation-provider
              v-slot="validationContext"
              name="Start Date"
              rules="required"
            >

              <b-form-group
                label="Start Date"
                label-for="start-date"
                :state="getValidationState(validationContext)"
              >
                <b-form-datepicker
                  v-model="eventLocal.start"
                  class="form-control"
                  :min="new Date()"
                  @input="startDateChanged"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- End Date -->
            <validation-provider
              v-slot="validationContext"
              name="End Date"
              rules="required"
            >

              <b-form-group
                label="End Date"
                label-for="end-date"
                :state="getValidationState(validationContext)"
              >
                <b-form-datepicker
                  id="example-datepicker"
                  v-model="eventLocal.end"
                  :min="eventLocal.start"
                  value-as-date
                  class="mb-1"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actins -->
            <div class="d-flex mt-2 justify-content-end">
              <b-button
                v-if="eventLocal.id"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-2"
                variant="outline-danger"
                @click="$emit('remove-event'); hide();"
              >
                Delete
              </b-button>
              <!-- <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-2"
                variant="outline-secondary"
              >
                Reset
              </b-button> -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="submitData()"
              >
                {{ eventLocal.id ? 'Update' : 'Add ' }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BModal, BForm, BFormGroup, BButton, BFormInvalidFeedback, BFormSelect, BRow, BCol, BFormDatepicker, BFormTextarea,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from 'vue'
// import flatPickr from 'vue-flatpickr-component'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    BButton,
    BFormSelect,
    BModal,
    BForm,
    BFormGroup,
    ValidationProvider,
    // flatPickr,
    BFormInvalidFeedback,
    BRow,
    BCol,
    ValidationObserver,
    BFormDatepicker,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    }
  },
  data() {
    return {
      required,
      email,
      url,
      eventTypes: ['Birthday', 'Holiday', 'Todo', 'Reminder'],
    }
  },

  computed: {
    eventData() {
      return {
        id: this.eventLocal.id || null,
        message: this.eventLocal.extendedProps.message,
        start_time: this.formatDate(this.eventLocal.start),
        end_time: this.formatDate(this.eventLocal.end),
      }
    },
  },
  watch: {
    event() {
      if (this.event.start) this.event.start = this.formatDate(this.event.start)
      if (!this.event.end) this.event.end = this.event.start
    },
  },
  methods: {
    startDateChanged() {
      if (new Date(this.eventLocal.end).getTime() < new Date(this.eventLocal.start).getTime()) {
        this.eventLocal.end = this.eventLocal.start
      }
    },
    submitData() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          if (!this.eventLocal.id) this.$emit('add-event', this.eventData)
          else this.$emit('update-event', this.eventData)
          this.$emit('update:is-event-handler-sidebar-active', false)
          this.$bvModal.hide()
        }
      })
    },
    formatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      let hour = d.getHours()
      let min = d.getMinutes()
      let second = d.getSeconds()

      if (month.length < 2) { month = `0${month}` }
      if (day.length < 2) { day = `0${day}` }
      if (hour.length < 2) { hour = `0${hour}` }
      if (min.length < 2) { min = `0${min}` }
      if (second.length < 2) { second = `0${second}` }

      return `${[year, month, day].join('-')}`
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
// @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
