<template>
  <div>
    <b-row class="first-row text-white pl-1">
      <b-col>
        <span class=" position-absolute">
          <b-button
            size="small"
            variant="flat-secondary"
            class="btn-icon text-white"
          >
            <feather-icon
              size="25"
              role="button"
              icon="ArrowLeftIcon"
              style="margin-bottom: 5px"
              @click="GO_BACK()"
            />
          </b-button>
        </span>
      </b-col>
    </b-row>
    <b-row
      class="d-flex align-items-center problem-card-header"
      align-h="between"
    >
      <b-col class="d-flex offset-md-8">
        <div class="justify-content-end align-items-center w-100">
          <span
            class="w-100 percentage-complete-bar"
          >
            <div class="text-right percentage-text">
              {{ $t('labels.passed') }}: <strong>{{ problemStat.passedPercent }}%</strong></div>
          </span>
          <span
            class="w-100"
          >
            <div
              class="progress"
            >
              <div
                class="progress-bar"
                role="progressbar"
                :style="`width: ${problemStat.passedPercent}%`"
                :aria-valuenow="0"
                aria-valuemin="0"
                :aria-valuemax="problemStat.totalProblem"
              />
            </div>
            <p
              style="margin-top: 4px"
              class="mb-2 text-right text-muted"
            >
              <!-- <strong>{{ $t('engine-page.current') }}</strong> -->
              <strong class="ml-2"> {{ problemStat.totalPass }}
              </strong>
              {{ $t("of") }} <strong>{{ problemStat.totalProblem || '' }} </strong>
            </p>
          </span>
        </div>
        <div>
          <b-avatar
            id="round-avatar"
            :variant="`success`"
            class="ml-1 mt-1"
          >
            <span class="engine-flag">{{ problemStat.round }}</span>
          </b-avatar>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BAvatar, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BButton,
  },
  props: {
    problemStat: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      self: this.AUTH_USER(),
    }
  },
}
</script>
<style lang="scss">
.student-problem .row {
  margin: 0;
}
  .go-to-chat {
    border-bottom: 1px solid #333;
    cursor: pointer;
    transition: all 0.2s ease-in;
  }
  .go-to-chat:hover {
    border-color: transparent;
  }
  .dropdown-items {
    background: rgba( 255, 255, 255, 0.75 ) !important;
    box-shadow: 0 2px 6px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    border-radius: 0 10px 0 10px;
  }
  .hoverable:first-of-type {
    border-top-right-radius: 10px;
  }
  .dropdown-items .sticker-container-wrapper {
    position: relative;
    top: 0 !important;
    width: 200px !important;
    padding: 0 8px;
  }
  .dropdown-items .sticker {
    margin-bottom: 24px !important;
  }
  .swiper-wrapper .agile__nav-button {
    position: absolute;
    top: 4px;
    border-color: transparent !important;
    background-color: transparent;
    color: #fff;
  }
  .sticker-wrapper {
    width: 200px !important;
  }
  .swiper-wrapper .agile__nav-button--prev {
    left: -24px;
  }
  .swiper-wrapper .agile__nav-button--next {
    right: -20px;
  }
  .sticker-wrapper p {
    font-weight: 500;
  }
  .swal2-container {
    min-width: 350px;
  }

  @media only screen and (max-width: 576px) {
    .swiper-wrapper .slide {
      width: 300px !important;
    }
    .swiper-wrapper {
      margin-left: -20px;
    }
    .swiper-wrapper .agile__nav-button--prev {
      left: 0;
    }
    .swiper-wrapper .agile__nav-button--next {
      // right: 0;
      right: 83px;
    }
    #pageLink {
      margin-top: 0px !important;
      position: relative !important;
      margin-right: -1em !important;
      padding-left: 10px !important;
      background: #8bc24a;
    }
  }

  @media only screen and (max-width: 992px) and (min-width: 576px) {
    .swiper-wrapper .slide {
      width: 400px;
    }
    .swiper-wrapper .agile__nav-button {
      top: 6px;
    }
    .swiper-wrapper .agile__nav-button--prev {
      left: 0;
    }
    .swiper-wrapper .agile__nav-button--next {
      right: 0;
    }
  }

  @media only screen and (max-width: 1200px) and (min-width: 992px) {
    .score-table {
      width: 100%;
    }
    .swiper-wrapper .slide {
      width: 500px;
    }
    .swiper-wrapper .score-swipe {
      width: 500px !important;
    }
    .swiper-wrapper .agile__nav-button {
      top: 6px;
    }
    .swiper-wrapper .agile__nav-button--prev {
      left: -8px;
    }
    .swiper-wrapper .agile__nav-button--next {
      right: -8px;
    }
    .slide .student-name {
      margin: 0 !important;
    }
    .center-part {
      margin: 0 18px 8px 0;
    }
  }

</style>
<style scoped lang="scss">
  .no-wrap {
    white-space: nowrap;
  }
  .first-row {
    background-color: #8bc34a;
  }
  .problem-card-header {
    margin-top: -12px;
  }
  .score {
    margin-left: -8px;
  }
  .progress {
    transition: all 500ms ease;
  }
  .progress {
    background: #fff;
  }
  .progress-bar {
    background: rgb(241, 196, 15);
  }
  .engine-flag {
    font-size: 18px;
    font-weight: 800 !important;
  }
  .percentage-complete-bar .progress {
    margin-bottom: 5px;
  }
  .percentage-complete-bar .percentage-text {
    padding: 3px 0;
  }
  .percentage-complete-bar .percentage-gradient {
    background: #ffeb3b;
  }
  .score-text {
    font-weight: bold;
    font-size: 16.5px;
  }
  .score-text.score {
    font-weight: bold;
    font-size: 18px;
  }
  .score-table {
    margin-left: 8px;
    margin: auto;
    td {
      border: none;
    }
  }
  .swiper-wrapper {
    position: relative;
  }
  .score-swipe {
    width: 400px;
    margin-left: -8px;
  }
  .slide {
    width: 400px;
  }
  .slide .student-name {
    margin: 0 4px;
  }
  .slide .text-right {
    padding: 0 8px;
  }
  .slide .text-left {
    padding-left: 0;
  }
  .dropdown-title {
    border-bottom: 1px solid;
    cursor: pointer;
  }
  .no-underline,
  .dropdown-title:hover {
    border-color: transparent;
  }
  .dropdown-items {
    position: absolute;
    width: 200px;
    top: 36px;
    right: 18px;
    background-color: #fff;
    z-index: 1;
  }
  .dropdown-items .nav-item {
    text-align: left;
    padding: 6px 12px;
    width: 100%;
  }
  .dropdown-items .hoverable {
    border-bottom: 1px solid #aaa;
    transition: all 0.3s ease-in;
  }
  .dropdown-items .hoverable:hover {
    background-color: #e6e6e6;
    cursor: pointer;
  }
  .dropdown-items .nav-link {
    color: #101010;
    font-weight: 500;
    padding: 0;
  }
  .sticker-wrapper {
    color: #333;
    height: 310px;
    width: 250px;
    padding-top: 6px;
  }
  .back-icon {
    padding: 6px;
    cursor: pointer;
  }
  .go-to-chat {
    cursor: pointer;
    text-decoration: underline;
  }
@media screen and (max-width: 992px) {
  .score-table,
  .my-points {
    margin: auto;
    td {
      border: none;
    }
  }
  .swiper-wrapper {
    position: relative;
  }
  .score-swipe {
    width: 300px;
    margin-left: -8px;
  }
  .my-points {
    margin-left: 4px;
  }
  .slide .student-name {
    margin: 0 4px;
  }
  @media screen and (max-width: 992px) {
    .score-table,
    .my-points {
      margin: auto;
      text-align: center;
    }
    .score-swipe {
      width: 400px !important;
    }
  }
  @media screen and (max-width: 1200px) {
    .score-swipe {
      width: 240px;
    }
  }
}

#pageLink {
  position:absolute;
  margin-top: 13px;
  z-index: 2;
  right:0;
  margin-right: 15px
}

.points-list{
  overflow:auto;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
