<template>
  <div id="user-profile">
    <b-card
      class="profile-header mb-2"
      alt="cover photo"
      :img-src="coverImgUrl"
      img-top
      body-class="p-0"
    >
      <!-- profile picture -->
      <div class="position-relative">
        <div class="profile-img-container d-flex align-items-center">
          <div class="profile-img overflow-hidden">
            <avatar :image="network.logo" />
          </div>
          <!-- profile title -->
          <div class="profile-title ml-1 ml-md-3">
            <h2 class="text-white">
              {{ network.name }}
            </h2>
            <p class="text-white">
              <strong>{{ $t('network-module.min-admission-rating') }}: </strong> {{ network.min_rating }}
            </p>
          </div>
        <!--/ profile title -->
        </div>
      </div>
      <!--/ profile picture -->

      <div class="profile-header-nav">
        <b-navbar
          toggleable="lg"
          type="light"
        >
          <!-- toggle button -->
          <b-navbar-toggle
            class="ml-auto"
            target="nav-text-collapse"
          >
            <feather-icon
              icon="AlignJustifyIcon"
              size="21"
            />
          </b-navbar-toggle>
          <!--/ toggle button -->

          <!-- collapse -->
          <b-collapse
            id="nav-text-collapse"
            is-nav
          >
            <b-tabs
              pills
              class="profile-tabs mt-1 mt-md-0"
              nav-class="mb-0"
            >
              <template #tabs-start>
                <b-nav-item
                  role="presentation"
                  :active="activeTab == 'academic'"
                  class="font-weight-bold"
                  @click="changeTab('academic')"
                >
                  <span>{{ $t('student-profile.academic') }}</span>
                  <!-- <feather-icon
                    icon="RssIcon"
                    class="d-block d-md-none"
                  /> -->
                </b-nav-item>
                <b-nav-item
                  role="presentation"
                  :active="activeTab == 'philanthropic'"
                  class="font-weight-bold"
                  @click="changeTab('philanthropic')"
                >
                  <span>{{ $t('student-profile.philanthropic') }}</span>
                  <!-- <feather-icon
                    icon="InfoIcon"
                    class="d-block d-md-none"
                  /> -->
                </b-nav-item>
                <b-nav-item
                  role="presentation"
                  :active="activeTab == 'project'"
                  class="font-weight-bold"
                  @click="changeTab('project')"
                >
                  <span>{{ $t('student-profile.project') }}</span>
                  <!-- <feather-icon
                    icon="ImageIcon"
                    class="d-block d-md-none"
                  /> -->
                </b-nav-item>
              </template>

              <!-- edit buttons -->
              <template #tabs-end>
                <actions />
              </template>
              <!-- edit buttons -->
            </b-tabs>

          </b-collapse>
          <!--/ collapse -->
        </b-navbar>
      </div>
      <!-- profile navbar -->

      <!--/ profile navbar -->

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Actions from '@/views/common/network/components/Actions.vue'
import { mapGetters } from 'vuex'
import Avatar from '@/views/common/components/Avatar.vue'

export default {
  components: {
    BCard,
    BTabs,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    Actions,
    BCollapse,
    Avatar,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      // eslint-disable-next-line global-require
      coverImg: require('@/assets/images/pages/study.jpg'),
    }
  },
  computed: {
    coverImgUrl() {
      return this.coverImg
    },
    ...mapGetters({
      network: 'network/network',
      myNetworkMembership: 'network/myNetworkMembership',
      activeTab: 'network/activeTab',
    }),
  },
  methods: {
    changeTab(tab) {
      if (this.activeTab === tab) return
      this.$store.commit('network/SET_ACTIVE_TAB', tab)
      this.$store.commit('network/SET_VERIFIED_ARTICLES_PAGE', 1)
      this.$store.dispatch('network/getVerifiedArticles')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';
</style>

<style scoped lang="css">
#user-profile .nav-link{
  display: flex;
  flex-direction: column-reverse;
}
@media (max-width: 576px) {
  .profile-header .profile-title{
    margin-top:-50px !important;
  }
 }
</style>
