<template>
  <div>
    <div>
      <b-col class="problem-overview-content">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe v-if="isCloudVideo"
                  :src="video.url || video.youtubeId"
          />
          <youtube
            v-else-if="video"
            ref="youtube"
            class="embed-responsive-item"
            :video-id="video.youtubeId"
            :player-vars="{
              autoplay: 1,
              start: video.startTimeInMs,
              end: video.endTimeInMs,
              rel: 0,
            }"
            player-width="100%"
            player-height="220"
            @ended="isLastStatement ? $emit('continueToProblem') : $emit('nextStatement')"
          />
        </div>

        <!-- Footer -->
        <div class="d-flex mt-2 justify-content-between align-content-center">
          <actions
            :level="level"
            mode="statement"
            :on-submit="nextStatement"
            :engine="engine"
            :button-title="isLastStatement ? $t('continue') : $t('next')"
          />
          <!-- <b-button
            variant="success"
            size="md"
            @click="isLastStatement ? $emit('continueToProblem') : $emit('nextStatement')"
          >
            {{ isLastStatement ? $t('continue') : $t('next') }}
          </b-button> -->
        <!-- Footer -->

        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import { BCol } from 'bootstrap-vue'
import Actions from './Actions.vue'

export default {
  components: {
    BCol,
    Actions,
  },
  props: {
    video: {
      type: Object,
      default: () => {},
    },
    isLastStatement: {
      type: Boolean,
      default: () => false,
    },
    level: {
      type: [Number, String],
      default: 1,
    },
    engine: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    isCloudVideo() {
      return this.video?.url?.includes('https://cloud.globalenglishcampus.com') || this.video?.youtubeId?.includes('https://cloud.globalenglishcampus.com')
    },
  },
  methods: {
    nextStatement() {
      if (this.isLastStatement) this.$emit('continueToProblem')
      else this.$emit('nextStatement')
    },
  },
}

</script>
