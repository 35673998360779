<template>
  <b-card
    no-body
    class="cursor-pointer"
    @click="$emit('on-card-click', type === 'courses' ? { courseId: item.id } : { courseId: item.course_id, lessonId: item.id })"
  >
    <b-card-header
      class="course-img-wrapper p-0 mb-1 text-center"
    >
      <b-card-img
        v-if="item.image"
        class="course-img"
        :src="item.image"
      />
      <avatar
        v-else
        class="course-img"
        :username="item.name"
        :rounded="false"
        color="#fff"
      />
    </b-card-header>

    <b-card-body>
      <!-- Course Name -->
      <b-card-title>
        {{ item.name }}
      </b-card-title>

      <!-- Grade -->
      <b-card-text
        v-if="isSearchResult && type === 'courses'"
        class="mb-0 font-weight-bold"
      >
        {{ $t('courses-module.grade') }}: {{ item.subject.standard_grade.name }}
      </b-card-text>

      <!-- Course Description -->
      <b-card-text
        :id="`course-${index}`"
        :ref="`course-${index}`"
        class="course-description"
        :class="{ 'mb-2': item.description }"
        @mouseover.prevent="checkTruncation(item.id, $event)"
      >
        <span v-if="item.description !== null && item.description !== 'null'">
          {{ item.description }}
        </span>
      </b-card-text>
      <b-popover
        v-if="isTruncated"
        :target="`course-${index}`"
        placement="bottom"
        triggers="hover"
        title="Description"
        :content="item.description"
      />
    </b-card-body>

  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardImg, BCardText, BPopover,
} from 'bootstrap-vue'
import Avatar from 'vue-avatar'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardImg,
    BCardText,
    BPopover,
    Avatar,
  },
  props: {
    item: {
      type: [Object, Array],
      required: true,
    },
    isSearchResult: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: String,
      default: () => 'courses',
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isTruncated: false,
    }
  },
  methods: {
    checkTruncation(id, event) {
      if (this.currentIndex !== id) {
        this.currentIndex = id
        this.isTruncated = event.target.offsetHeight < event.target.scrollHeight
      }
    },
  },
}
</script>

<style scoped>
.card {
  height: 85%;
}
.card:hover {
  box-shadow: 0 8px 32px 0 rgba(28, 34, 112, 0.37);
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
}
.vue-avatar--wrapper {
  width: 100% !important;
  height: 100% !important;
  font-size: 48px !important;
}
.course-list .card-title {
  font-size: 18px;
  margin-bottom: 8px;
}
.course-img-wrapper {
  height: 160px !important;
}
.course-img {
  height: 100%;
}
.course-description {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<style>
.popover .popover-body {
  max-height: 280px;
  overflow: auto;
}
</style>
