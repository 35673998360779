<template>
  <div :class="className">
    <!-- <call-info-modal-vue /> -->
    <div class="content-overlay" />
    <!-- <div class="header-navbar-shadow" /> -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <div
        class="content-area-wrapper"
        :class="contentWidth === 'boxed' ? 'container p-0' : null"
      >
        <slot name="breadcrumb">
          <app-breadcrumb />
        </slot>
        <portal-target
          name="content-renderer-sidebar-left"
          slim
        />
        <div class="content-right">
          <div class="content-wrapper">
            <div class="content-body">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
// import CallInfoModalVue from '@/views/common/chat/call/CallInfoModal.vue'
import { getUserData } from '@/auth/utils'
import {
  USER_TYPE_PARENT,
  USER_TYPE_STUDENT,
  USER_TYPE_TEACHER,
  USER_TYPE_SCHOOL,
} from '@/const/userType'

export default {
  components: {
    AppBreadcrumb,
    // CallInfoModalVue,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition,
      contentWidth,
      isAStudent: getUserData() && getUserData().usertype === USER_TYPE_STUDENT,
      isATeacher: getUserData() && getUserData().usertype === USER_TYPE_TEACHER,
      isAParent: getUserData() && getUserData().usertype === USER_TYPE_PARENT,
      isASchool: getUserData() && getUserData().usertype === USER_TYPE_SCHOOL,
    }
  },
  data() {
    return {
      showVideoCallModal: false,
    }
  },
  computed: {
    ui() {
      return this.$store.state.appConfig.layout.ui
    },
    isModernUI() {
      return this.ui === 'modern'
    },
    className() {
      let className = this.$route.meta.contentClass
      className
        += (this.isAStudent
          || this.isATeacher
          || this.isAParent
          || this.isASchool)
        && this.isModernUI
          ? ' align-items-center justify-content-center p-2 col-xl-10 col-lg-10 col-md-10 col-sm-12 mr-auto ml-auto app-content content '
          : ' app-content content '
      if (this.$store.state.app.shallShowOverlay) {
        className += ' show-overlay '
      }
      return className
    },
  },
  methods: {
    showVideoLayout() {
      this.showVideoCallModal = true
    },
    hideVideoLayout() {
      this.showVideoCallModal = false
    },
  },
}
</script>

<style>
</style>
