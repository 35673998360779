const genRandId = () => {
  const len = 8
  return Math.random().toString(36).substring(2, len + 2)
}

const addDecorationImage = ({ lastData }) => ({
  x: 8,
  y: lastData.y + 50,
  width: 218,
  height: 50,
  type: 'decoration-image',
  element: null,
  groupId: null,
})

const addMoreFields = ({ type, lastData }) => {
  switch (type) {
    case 'multiple': {
      return [{
        x: 8,
        y: lastData.y + 50,
        width: 218,
        height: 50,
        type: 'answer',
        element: null,
      }]
    }
    case 'matching': {
      const id = genRandId()
      return [
        {
          x: 8,
          y: lastData.y + 70,
          width: 218,
          height: 50,
          type: 'question',
          element: null,
          groupId: id,
        },
        {
          x: 228,
          y: lastData.y + 70,
          width: 218,
          height: 50,
          type: 'answer',
          element: null,
          groupId: id,
        },
      ]
    }

    default: {
      return []
    }
  }
}
const generateProblemData = ({ type, problemData }) => {
  switch (type) {
    case 'matching': {
      // eslint-disable-next-line no-case-declarations
      const lastIndex = problemData.value.length
      const id = genRandId()
      return {
        name: `Problem ${lastIndex + 1}`,
        lang: problemData.value[lastIndex - 1]?.lang || 'eng',
        x: 35,
        y: (problemData.value[lastIndex - 1]?.y || 0) + 210,
        width: 450,
        height: 200,
        element: null,
        type: 'matching',
        children: [{
          x: 8,
          y: 50,
          width: 218,
          height: 50,
          type: 'question',
          element: null,
          groupId: id,
          tag: 'Q1',
        },
        {
          x: 228,
          y: 50,
          width: 218,
          height: 50,
          type: 'answer',
          element: null,
          groupId: id,
          tag: 'A1',
        },
        ],
      }
    }

    case 'multiple': {
      const lastIndex = problemData.value.length
      return {
        name: `Problem ${lastIndex + 1}`,
        x: 35,
        y: (problemData.value[lastIndex - 1]?.y || 0) + 210,
        width: 247,
        height: 192,
        element: null,
        type: 'multiple',
        children: [{
          x: 8,
          y: 50,
          width: 218,
          height: 50,
          type: 'question',
          element: null,
        },
        {
          x: 7,
          y: 109,
          width: 218,
          height: 54,
          type: 'answer',
          element: null,
        },
        ],
      }
    }

    default:
      return null
  }
}

export {
  addMoreFields,
  generateProblemData,
  addDecorationImage,
}
