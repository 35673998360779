import { getCurrentInstance, reactive, ref } from 'vue'
import useJwt from '@/auth/jwt/useJwt'

export default function useDiffusion({ emits }) {
  const imgPrompt = ref(null)
  const imgUuid = ref(null)
  const generatedImage = ref(null)
  const state = reactive({
    isProcessing: false,
    isQueueRunning: false,
  })
  const artistFilter = ref([])
  const modifierFilter = ref(['4k', 'High Resolution'])
  const negativePrompt = ref('inappropriate, sensitive, offensive, violent, explicit, low resolution, blurry, offensive, repetitive, unoriginal, noisy, distorted, biased, stereotypical, extra limbs,  extra fingers, deformed, bad anatomy, disfigured, poorly drawn face')
  const modelName = ref('Deliberate')
  const baseUrlStable = 'https://stablehorde.net'
  const monsterAiStyle = ref('photographic')
  // eslint-disable-next-line no-unused-vars
  const root = getCurrentInstance().proxy.$root

  const getFinalPrompt = () => {
    let artistStyle = ''
    if (artistFilter.value.length === 0) {
      artistStyle = '' // Ensure imgPrompt is not null
    } else {
      artistStyle = `, Artists Style: ${artistFilter.value.map(artist => artist).join(', ')}`
    }
    let modifierStyle = ''
    if (modifierFilter.value.length === 0) {
      modifierStyle = ''
    } else {
      modifierStyle = `, ${modifierFilter.value.map(modifier => modifier).join('. ')}`
    }
    return `${imgPrompt.value || ''}${artistStyle}${modifierStyle}`
  }
  const getImageStatus = uuid => {
    state.isQueueRunning = true
    useJwt.getDiffusionImageStatus(uuid).then(response => {
      const res = response.data.data.response
      if (res.status === 'COMPLETED') {
        generatedImage.value = res.imgUrl
        emits('onImageGenerate', generatedImage.value)
        state.isQueueRunning = false
        state.isProcessing = false
      } else if (res.status === 'IN_QUEUE') {
        getImageStatus(uuid)
      } else if (res.status === 'IN_PROGRESS') {
        getImageStatus(uuid)
      } else {
        throw new Error(`Image generation failed ${res}`)
      }
    }).catch(error => {
      state.isProcessing = false
      state.isQueueRunning = true
      root.showErrorMessage(error)
    })
  }

  const setupImage = () => {
    state.isProcessing = true
    generatedImage.value = null
    const finalPrompt = getFinalPrompt()
    useJwt.setupImageGeneration({
      prompt: finalPrompt,
      negative_prompt: negativePrompt.value || '',
    }).then(response => {
      const uuid = response.data.data.response
      imgUuid.value = uuid
      getImageStatus(uuid)
    }).catch(error => {
      state.isProcessing = false
      root.showErrorMessage(error)
    })
  }

  const setupImageWithInput = input => {
    state.isProcessing = true
    generatedImage.value = null
    useJwt.setupImageGeneration({
      prompt: input,
    }).then(response => {
      const uuid = response.data.data.response
      imgUuid.value = uuid
      getImageStatus(uuid)
    }).catch(error => {
      state.isProcessing = false
      root.showErrorMessage(error)
    })
  }

  // stable ui with model implemented
  async function fetchNewID(parameters) {
    try {
      // console.log(parameters)
      const response = await fetch(`${baseUrlStable}/api/v2/generate/async`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          apikey: '0000000000',
        },
        body: JSON.stringify(parameters),
      })
      const resJSON = await response.json()
      return resJSON
    } catch (error) {
      console.error('Error fetching new ID:', error)
      return error
    }
  }

  async function checkImage(imageID) {
    const response = await fetch(`${baseUrlStable}/api/v2/generate/check/${imageID}`)
    const resJSON = await response.json()
    if (resJSON.done) {
      // console.log(resJSON)
      // eslint-disable-next-line consistent-return
      return resJSON
    }
    // eslint-disable-next-line consistent-return
    return checkImage(imageID)
  }

  async function getStableImageStatus(imageId) {
    const response = await fetch(`${baseUrlStable}/api/v2/generate/status/${imageId}`)
    const resJSON = await response.json()
    return resJSON
  }

  // image generation by Dall-e-3
  const generateImageByDalle = () => {
    state.isProcessing = true
    generatedImage.value = null
    const finalPrompt = getFinalPrompt()
    useJwt.setupImageByDalle({
      prompt: finalPrompt,
      negative_prompt: negativePrompt.value || '',
    }).then(response => {
      generatedImage.value = response.data.data.response
      emits('onImageGenerate', generatedImage.value)
      state.isQueueRunning = false
      state.isProcessing = false
    }).catch(error => {
      state.isProcessing = false
      root.showErrorMessage(error)
    })
  }

  // monster ai text to image
  const monsterHeader = {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImM5ZDViNzBlNTk1ZGJhMzQ4MTdlMTU0MDBlOTY5NWM2IiwiY3JlYXRlZF9hdCI6IjIwMjQtMDEtMDJUMDA6NDE6MjYuMjAzMTY2In0.RfcF3l9UUNkIcVyCwppNHg1XdmttO4-8jZoEp1cyho0',
    },
  }

  async function getMonsterAiImageStatus(uuid) {
    const options = {
      method: 'GET',
      ...monsterHeader,
    }
    const response = await fetch(`https://api.monsterapi.ai/v1/status/${uuid}`, options)
    const resJSON = await response.json()
    console.log('response', resJSON)
    if (resJSON.status === 'COMPLETED') {
      // eslint-disable-next-line prefer-destructuring
      generatedImage.value = resJSON.result.output[0]
      emits('onImageGenerate', generatedImage.value)
      state.isQueueRunning = false
      state.isProcessing = false
    } else if (resJSON.status === 'IN_QUEUE') {
      getMonsterAiImageStatus(uuid)
    } else if (resJSON.status === 'IN_PROGRESS') {
      getMonsterAiImageStatus(uuid)
    } else {
      throw new Error(`Image generation failed ${resJSON}`)
    }
  }

  async function generateImageByMonsterAi(monsterModel) {
    console.log(monsterModel)
    state.isProcessing = true
    state.isQueueRunning = true
    generatedImage.value = null
    const monsterOptions = {
      method: 'POST',
      ...monsterHeader,
      body: JSON.stringify({
        aspect_ratio: 'landscape',
        guidance_scale: 7.5,
        prompt: getFinalPrompt(),
        safe_filter: true,
        samples: 1,
        negprompt: negativePrompt.value || '',
        style: monsterAiStyle.value,
        enhance: true,
        optimize: true,
      }),
    }
    try {
      const response = await fetch(`https://api.monsterapi.ai/v1/generate/${monsterModel}`, monsterOptions)
      const resJSON = await response.json()
      getMonsterAiImageStatus(resJSON.process_id)
    } catch (error) {
      console.error('Error generating image:', error)
    }
  }

  const setupStableImage = async () => {
    state.isProcessing = true
    state.isQueueRunning = true
    generatedImage.value = null
    if (modelName.value === 'runpod') {
      setupImage()
    } else if (['txt2img', 'sdxl-base'].includes(modelName.value)) {
      generateImageByMonsterAi(modelName.value)
    } else if (modelName.value === 'dall-e-3') {
      console.log('dalle')
      generateImageByDalle()
    } else {
      const finalPrompt = getFinalPrompt()
      const parameters = {
        prompt: finalPrompt,
        negative_prompt: negativePrompt.value || '',
        models: [modelName.value],
      }
      const response = await fetchNewID(parameters)
      const jobId = response.id
      const status = await checkImage(jobId)
      if (status.done) {
        const finalImages = await getStableImageStatus(jobId)
        generatedImage.value = finalImages.generations[0].img
        emits('onImageGenerate', generatedImage.value)
        state.isQueueRunning = false
        state.isProcessing = false
      }
    }
  }

  const resetImageGeneration = () => {
    state.isProcessing = false
    state.isQueueRunning = false
    generatedImage.value = null
    root.showErrorMessage('Request Canceled by User')
    window.stop()
  }

  return {
    setupImage,
    setupStableImage,
    setupImageWithInput,
    generateImageByMonsterAi,
    generateImageByDalle,
    resetImageGeneration,
    state,
    generatedImage,
    imgPrompt,
    imgUuid,
    modelName,
    artistFilter,
    modifierFilter,
    negativePrompt,
    monsterAiStyle,
  }
}
