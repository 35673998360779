<template>
  <div class="timer-clock">
    {{ formatTime(increasingHours) }}:{{ formatTime(increasingMinutes) }}
    <!-- :{{ formatTime(increasingSeconds) }} -->
  </div>
</template>
<script>
export default {
  props: {
    timeInSecond: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      increasingHours: Math.floor(this.timeInSecond / 3600),
      increasingMinutes: Math.floor((this.timeInSecond % 3600) / 60),
      increasingSeconds: this.timeInSecond % 60,
      timer: null,
      updatingCountDown: 0,
    }
  },
  mounted() {
    this.startTimer()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.increaseTime()
      }, 1000)
    },
    increaseTime() {
      if (this.increasingMinutes === 59) {
        // eslint-disable-next-line no-plusplus
        this.increasingHours++
        this.increasingMinutes = 0
      }
      if (this.increasingSeconds === 59) {
        // eslint-disable-next-line no-plusplus
        this.increasingMinutes++
        this.increasingSeconds = 0
      } else {
        // eslint-disable-next-line no-plusplus
        this.increasingSeconds++
      }
    },
    formatTime(value) {
      return value < 10 ? `0${value}` : value
    },
  },
}
</script>
