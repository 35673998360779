<template>
  <b-overlay :show="isProcessing">
    <div class="pb-1">
      <b-button
        variant="flat-secondary"
        class="btn-icon"
        @click="$router.push({ name: `${self.usertype}-view-students`, params: {id: classId} })"
      >
        <feather-icon
          size="20"
          icon="ArrowLeftIcon"
        />
      </b-button>
      <span v-if="classRoom">
        <span class="text-capitalize">
          {{ classRoom.name }}
        </span>
      </span>
    </div>
    <b-card body-class="p-0">
      <TableStructure :skills="skills">
        <SummaryTable
          v-if="overallReport"
          :skills="skills"
          :overall-report="overallReport"
          :class-room="classRoom"
          :students="students"
        />
      </TableStructure>
    </b-card>
  </b-overlay>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard, BButton, BOverlay,
} from 'bootstrap-vue'
import SummaryTable from './components/SummaryTable.vue'
import TableStructure from './components/TableStructure.vue'

export default {
  components: {
    BCard,
    BButton,
    BOverlay,
    SummaryTable,
    TableStructure,
  },
  data() {
    return {
      classId: this.$route.params.classId,
      overallReport: null,
      students: [],
      self: this.AUTH_USER(),
      classRoom: null,
      isProcessing: false,
      skills: ['reading', 'writing', 'listening', 'speaking'],
    }
  },
  created() {
    this.getOverallReport()
  },
  methods: {
    getOverallReport() {
      this.isProcessing = true
      useJwt.getSignalClassroomOverallReport(this.classId).then(response => {
        this.overallReport = response.data.data.summary
        // this.overallReport = overallReport
        this.students = response.data.data.students
        this.classRoom = response.data.data.classroom
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
